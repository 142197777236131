/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import "./TreatmentCombination.css";
import Draggable from 'react-draggable';
import * as dialogTypes from "../../AbstractManagement/maintDialogs";  // Getting navigation tabs - 09/01/2022
import PopLabel from "../PopLabel/PopulationLabel"; //Import the Editable Pop Label 11/22/2022

class TreatmentCombination extends Component {
    clockInterval = "";
    downloadFile = false;
    state = {
        populationLabel: "",
        hospNbr: "",
        hospital_Code: "",     //This line does not work as the default for some reason
        hospital_Code_Name: "",  //This line works as the default //"ERS Test 2 Hosp"
        runTreatmentHospital: false,
        runTreatmentPhysician: false,
        runTreatmentIncidence: false,
        runTreatmentCombination: false,
        runTreatmentDistribution: false,

        treatmentHospitalSurgery: false,
        treatmentHospitalChemo: false,
        treatmentHospitalRadiation: false,
        treatmentHospitalImmuno: false,
        treatmentHospitalHormone: false,
        treatmentHospitalOtherTreatment: false,
        treatmentHospitalIncludeDxProcedures: false,
        treatmentPhysicianSurgery: false,
        treatmentPhysicianChemo: false,
        treatmentPhysicianRadiation: false,
        treatmentPhysicianImmuno: false,
        treatmentPhysicianHormone: false,
        treatmentPhysicianOtherTreatment: false,
        treatmentPhysicianIncludeDxProcedures: false,
        treatmentDistributionType: "Surgery",
        treatmentValues: "",
        courseFS: "First Course",
        includeExcludeFlag: "Include",
        outputType: "Screen",
        reportDisplayOption: "Combination",

        htmlOutput: null,
        startTime: null,
        endTime: null,
        statusBar: "Start Time: ",

        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        loaded: true,
        titleDialog: null,
        showClockTimer: false,
        reportStatus: "Not Running", //This variable is used to control whether clock timers continue, reports display/download etc. -- "Not Running" == initial state | "Cancelled" == user has clicked the cancel button after attempting to run a report | "Report Running" == Report in the C# file has been called, but no response back recognized from it | "Report Finished" == Report in the C# function has successfully completed

        uAllItemFields: [],
        fieldInFocus: "",
        focusingField: null,
        isHelpVisible: false,
    }

    updatePopLabelBackToParent = (plabel) => {
        var form = this.state.dialogForm;
        form.populationLabel = plabel;
        this.setState({
            dialogForm: form,
        });
        return false;
    }

    updateToParent = (plForm) => {
        var reportingForm = this.state.dialogForm;
        if (plForm) {
            Object.keys(plForm).map((t, k) => {
                !reportingForm[t] ? reportingForm[t] = plForm[t] : null;
            });

            let labelsSiteMvSap = plForm.labelsSiteMvSap;
            let labelsMv = plForm.labelsMv;
            let textareaEditSelect = plForm.textareaEditSelect;

            reportingForm.labelsSiteMvSap = labelsSiteMvSap;
            reportingForm.labelsMv = labelsMv;
            reportingForm.textareaEditSelect = textareaEditSelect;
        }

        this.setState({
            dialogForm: reportingForm,
        });
        return false;
    }


    setStateTreatmentDistributionType = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.treatmentDistributionType;
        var dialogFormData = this.state.dialogForm;
        console.log("Treatment Type: " + value);
        dialogFormData[name] = value;

        this.setState({
            treatmentDistributionType: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateCourseFS = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.courseFS;
        var dialogFormData = this.state.dialogForm;
        console.log("Course of Treatment: " + value);
        dialogFormData[name] = value;

        this.setState({
            courseFS: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateIncludeExcludeFlag = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.includeExcludeFlag;
        var dialogFormData = this.state.dialogForm;
        console.log("Include Exclude Flag: " + name);
        dialogFormData[name] = value;

        this.setState({
            includeExcludeFlag: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateOutputType = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.outputType;
        var dialogFormData = this.state.dialogForm;
        console.log("Output Type: " + value);
        dialogFormData[name] = value;

        this.setState({
            outputType: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }


    setStateReportDisplayOption = (event) => {
        //event.preventDefault();

        var name = event.target.name;
        var value = event.target.value;
        const oldValue = this.state.reportDisplayOption;
        var dialogFormData = this.state.dialogForm;
        console.log("Report Display: " + value);
        dialogFormData[name] = value;

        var treatmentCombinationInfo = this.props.treatmentCombinationInfo;
        var html = treatmentCombinationInfo.htmlOutput;
        if (value == "Distribution") { html = treatmentCombinationInfo.treatmentDistribution_HtmlOutput }
        if (value == "Combination") { html = treatmentCombinationInfo.treatmentCombination_HtmlOutput }
        //if (value == "Combination") { html = treatmentCombinationInfo.htmlOutput }
        if (value == "Site") { html = treatmentCombinationInfo.treatmentbySite_HtmlOutput }
        if (value == "Physician") { html = treatmentCombinationInfo.treatmentbyPhysician_HtmlOutput }
        if (value == "Hospital") { html = treatmentCombinationInfo.treatmentbyHospital_HtmlOutput }
        console.log("Report Html: " + html);

        this.setState({
            reportDisplayOption: value,
            htmlOutput: html,
            dialogForm: {
                ...this.state.dialogForm,
                [name]: value
            },
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });


        setTimeout(function () {
            document.getElementById("fakeRadioControl") ? document.getElementById("fakeRadioControl").click() : null;

            //Change which report gets displayed
            var reportDisplay = document.getElementById("displayReportIframe");
            if (reportDisplay) { //This function runs twice and in one instance this component doesn't exist, so this check needs to be there to prevent the entire screen from erroring out
                reportDisplay.src = "about:blank";

                //var reportContent = reportDisplay.contentDocument;
                //reportContent.write(html);
                var reportContent = reportDisplay.contentWindow || reportDisplay.contentDocument.document || reportDisplay.contentDocument;
                if (reportContent.write) {
                    reportContent.write("");
                } else {
                    reportContent.document.write("");
                }
            }
        }, 10);
    }

    fakeRadioControlFnc = (event) => {
        event.preventDefault();
        let reportDisplayOption = this.state.reportDisplayOption;
        let htmlOutput = this.state.htmlOutput;

        this.setState({ reportDisplayOption: reportDisplayOption });

        setTimeout(function () {
            var reportDisplay = document.getElementById("displayReportIframe");
            if (reportDisplay) { //This function runs twice and in one instance this component doesn't exist, so this check needs to be there to prevent the entire screen from erroring out
                reportDisplay.src = "about:blank";
                var reportContent = reportDisplay.contentWindow || reportDisplay.contentDocument.document || reportDisplay.contentDocument;
                if (reportContent.write) {
                    reportContent.write(htmlOutput);
                } else {
                    reportContent.document.write(htmlOutput);
                }
            }
        }, 100);

        return false;
    }

    componentDidMount() {
        var _this = this;
        _this.props.generateTreatmentCombination();

        if (!this.state.dialogForm) {
            this.setFormData(this.props.treatmentCombinationInfo);
        }

        //this.Get_Data("");


        this.setState({ isLoading: true });

    }

    componentWillMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.dialogForm) {
            this.setFormData(this.props.treatmentCombinationInfo);
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem && this.state.dialogForm && this.state.uAllItemFields) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "" },
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    //uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        var treatmentCombinationInfo = this.props.treatmentCombinationInfo;
        if (treatmentCombinationInfo) {
            //console.log("$$$ Output Type: " + treatmentCombinationInfo.outputType);
            //console.log("$$$ HTML Output: " + treatmentCombinationInfo.htmlOutput);
        }

        if (this.state.dialogForm && (prevProps.treatmentCombinationInfo && this.props.treatmentCombinationInfo && (prevProps.treatmentCombinationInfo.startTime !== this.props.treatmentCombinationInfo.startTime
            || prevProps.treatmentCombinationInfo.endTime !== this.props.treatmentCombinationInfo.endTime
        ))) {

            clearInterval(this.clockInterval); //If the report has finished, clear the clock interval
            this.setState({
                showClockTimer: false, //If the report has finished, hide the clock
                endTime: new Date().toLocaleTimeString(),
                reportStatus: "Report Finished",
            });

            if (treatmentCombinationInfo.outputType == "Screen" && treatmentCombinationInfo.htmlOutput) //If the report has finished, it was sent to the screen, and we have html data returned, display the report to the screen
                this.displayReport(treatmentCombinationInfo);
            else if (treatmentCombinationInfo.outputType == "Excel" && treatmentCombinationInfo.urlOutputFile && !this.downloadFile) { //If the report has finished, it was sent to Excel, and we have a url/path to the Excel file returned by the report, download the file
                var fileURL = this.props.clientgroup + "/DownloadFile";
                this.downloadxlsx(fileURL, treatmentCombinationInfo.urlOutputFile); //Download the Excel file produced by the report
                this.downloadFile = true;
            }
        }
    }

    downloadxlsx(fileURL, urlOutputFile) {
        axios.post(fileURL, {
            method: 'POST',
            responseType: 'blob',//important
            urlOutputFile: urlOutputFile,
            accessToken: localStorage.getItem("token")
        }).then((response) => {
            // get file name from url
            var fileName = urlOutputFile.substring(urlOutputFile.lastIndexOf('\\') + 1);
            console.log("fileName=" + fileName);
            const link = document.createElement('a');

            let excel;
            if (urlOutputFile.indexOf('/') != -1) {
                excel = urlOutputFile.substring(urlOutputFile.lastIndexOf('/') + 1);
            } else {
                excel = urlOutputFile.substring(urlOutputFile.lastIndexOf('\\') + 1);
            }
            link.href = this.props.clientgroup + "/" + excel;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return false;
        })
        .catch(function (error) {
            console.log(error);
        });

        return false;
    }

    setFormData = (treatmentCombinationInfo) => {
        this.setState({
            initialDialogForm: { ...treatmentCombinationInfo },
            dialogForm: { ...treatmentCombinationInfo },

            //["outputType"]: treatmentCombinationInfo && treatmentCombinationInfo.sendReportTo ? treatmentCombinationInfo.sendReportTo : "Screen",
            reload: false,
            changed: true,
            showClockTimer: false,
            titleDialog: null,
            isDrawingCharts: false,
        });
        console.log("Report Status: ", this.state.reportStatus);

        //Start with blank rows on the html page
        var anElement = document.getElementById('r0c1');
        var i = 1;
        for (i = 1; i < 6; i++) {
            this.addRow(anElement, 'ReportMv', 'New');
        }

        this.setState({ reportStatus: "Not Running", });
        return false;
    }



    handleOnFocus = (event, index, alias) => {

    }

    handleFormChange = (event) => {
        console.log("Firing handleFormChange")
        if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true,
            });
        } else {
            this.setState({
                //dialogForm: {
                //    ...this.state.dialogForm,
                //    [event.target.name]: event.target.value
                //},
                [event.target.name]: event.target.value, //11/07/2022 - Switching to this per REA to resolve the issue of typing in the hospital code directly
                isFormDirty: true,
            });
        }
    }

    handleLostFocus = (event) => {
        var dialogFormData = (this.state.dialogForm ? this.state.dialogForm : "Cannot Load this.state.dialogForm")
        //var hosp = (this.state.hospitalCode ? this.state.hospitalCode : "Cannot display this.state.hospitalCode")



        let name = event.target.name;
        let value = event.target.value;
        let table = event.target.getAttribute('data-table'); // 'Hospital'
        let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
        let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";

        if (name == "Hospital_Code") {
            dialogFormData.hospital_Code = event.target.value;
            this.setState({
                hospNbr: event.target.value,
                isFormDirty: this.state.hospNbr != event.target.value ? true : this.state.isFormDirty,
            });
        }

        if (name == "populationLabel") {
            dialogFormData.populationLabel = event.target.value;
            this.setState({ populationLabel: event.target.value });
        }

        this.props.checkValidInput(name, value, table, key, -1, flag);
        var checkVal = "";
        //var checkVal = this.props.checkValidInput(name, value, table, key, -1, flag);
        var hospName = this.props.hospital_Code_Name;

        console.log("###Lookup Values: " + name + " -- " + value + " -- " + table + " -- " + key + " -- " + flag + " -- " + checkVal + " -- " + hospName);

        this.setState({
            [event.target.name]: event.target.value,
            dialogForm: dialogFormData,
        });


    }

    handleOnKeyDown = (event) => {
        let _this = event.target;
        if (window.event.keyCode == 45) {
            this.addRow(_this, 'ReportMv', 'Insert')
        } else if (window.event.keyCode == 46) {
            this.deleteRow(_this, 'ReportMv')
        } else if (window.event.keyCode == 9 && event.shiftKey == false) {
            this.addRow(_this, 'ReportMv', 'Add')
        }
    }


    addRow(thisControl, tableID, Add_Insert) {
        console.log(thisControl + " - " + tableID + " -- " + Add_Insert);
        //debugger;
        var thisTable = document.getElementById(tableID);
        var thisRow = thisControl.parentNode.parentNode;
        var totalRowCount = thisTable.rows.length;
        var thisID = document.getElementById('r' + thisRow.rowIndex + 'c1').value;

        //if the ID isn't blank and this is the last row of the html table, add another row to the table
        if (((thisID != '' && thisRow.rowIndex == totalRowCount - 1) || Add_Insert == 'Insert') || Add_Insert == 'New') {
            if (Add_Insert == 'Insert') {
                var row = thisTable.insertRow(thisRow.rowIndex); //Insert a new row into the table
            } else {
                var row = thisTable.insertRow(-1); //Add a new row to the end of the table
            }

            var cell0 = row.insertCell(0);
            var cell1 = row.insertCell(1);

            cell1.innerHTML = '<input id=\"r' + row.rowIndex + 'c1\" class=\"tc1\" onKeyDown=\"this.handleOnKeyDown\" />';

            if (Add_Insert == 'Insert') {
                //Call function to reset id's of all table elements after deletions and insertions of table rows
                this.resetRows(tableID);

                var focusID = 'r' + (row.rowIndex) + 'c1';
                document.getElementById(focusID).focus();
            }

            if (Add_Insert == 'Add') {
                var focusID = 'r' + (row.rowIndex - 1) + 'c1';
                document.getElementById(focusID).focus();
            }

        } else if (thisRow.rowIndex < totalRowCount - 1) {
            var focusID = 'r' + (thisRow.rowIndex) + 'c1';
            document.getElementById(focusID).focus();
        }

    }

    deleteRow(thisControl, tableID) {
        var thisTable = document.getElementById(tableID);
        var thisRow = thisControl.parentNode.parentNode;
        var totalRowCount = thisTable.rows.length;
        var thisRowNbr = thisRow.rowIndex

        if (totalRowCount > 1) { thisTable.deleteRow(thisRow.rowIndex); }
        //Call function to reset id's of all table elements after deletions and insertions of table rows
        this.resetRows(tableID);

        //Set focus back to a row after delete
        if (thisRowNbr < totalRowCount - 1) {
            var newFocus = document.getElementById('r' + (thisRowNbr) + 'c1');
            newFocus.focus();
        } else if (thisRowNbr >= totalRowCount - 1) {
            document.getElementById('r' + (thisRowNbr - 1) + 'c1').focus();
        }
    }


    resetRows(tableID) {
        //This function resets the id's of the table subelements and should be called when rows are deleted or inserted
        var thisTable = document.getElementById(tableID);
        var totalRowCount = thisTable.rows.length;
        var i = 0;
        var j = 1;

        for (i = 0; i < totalRowCount; i++) {
            for (j = 1; j <= 1; j++) {
                thisTable.rows[i].cells[j].children[0].id = 'r' + i + 'c' + j
            }
        }
    }


    gatherRows = (event) => {
        //This function gathers the data from all the rows in the html table and compares with the previously entered data to see
        //if anything has changed.  If the Save button on the Alpha dialog is not already enabled, this function will enable it.
        //var parentName = document.getElementById('dialogName').innerText;
        //var enableSave = 'false'; //parent.document.getElementById(parentName + '.V.R1.ENABLE_SAVE_BUTTON');

        //if (enableSave.value == 'false') {
        var thisTable = document.getElementById('ReportMv');
        var totalRowCount = thisTable.rows.length;
        var reportCriteria = this.state.treatmentValues;    //document.getElementById('Report_Criteria');
        var criteria = '';
        var cellValue = '';
        var i = 0;

        for (i = 0; i < totalRowCount; i++) {
            cellValue = thisTable.rows[i].cells[1].children[0].value;

            //if (cellValue) {

            //}

            criteria += cellValue + "|";
        }
        //alert(criteria);
        console.log("--<< Check This >>--" + criteria);
        //if (reportCriteria.innerHTML != criteria) {
        //parent.document.getElementById(parentName + '.V.R1.BUTTON_ENABLE_SAVE').click();
        //reportCriteria.innerHTML = criteria;
        //}
        if (reportCriteria != criteria) {
            this.setState({
                treatmentValues: criteria,
                isFormDirty: true,
            });
        }
        //}
    }

    async Get_Data() {
        var _this = this;
        await _this.props.generateTreatmentCombination();  //The report doesn't run without this code, but why?  What does this function actually do?

        //Doesn't really seem to need any of the code below
        this.setState({
            reload: true,
            isFormDirty: false,
        });

        return false;
    }

    toggleChecked = (event) => {
        const name = event.target.name;
        var dialogFormData = this.state.dialogForm;
        //alert("Display " + name + " Checkbox Values: " + event.target.value);

        if (name == "treatmentFacility") {
            const checked = this.state.runTreatmentHospital;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                runTreatmentHospital: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "treatmentPhysician") {
            const checked = this.state.runTreatmentPhysician;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                runTreatmentPhysician: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "treatmentIncidence") {
            const checked = this.state.runTreatmentIncidence;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                runTreatmentIncidence: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "treatmentCombination") {
            const checked = this.state.runTreatmentCombination;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                runTreatmentCombination: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "treatmentDistribution") {
            const checked = this.state.runTreatmentDistribution;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                runTreatmentDistribution: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "treatmentHospitalIncludeSurgery") {
            const checked = this.state.treatmentHospitalSurgery;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                treatmentHospitalSurgery: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "treatmentHospitalIncludeChemo") {
            const checked = this.state.treatmentHospitalChemo;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                treatmentHospitalChemo: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "treatmentHospitalIncludeRadiation") {
            const checked = this.state.treatmentHospitalRadiation;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                treatmentHospitalRadiation: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "treatmentHospitalIncludeImmuno") {
            const checked = this.state.treatmentHospitalImmuno;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                treatmentHospitalImmuno: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "treatmentHospitalIncludeHormone") {
            const checked = this.state.treatmentHospitalHormone;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                treatmentHospitalHormone: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "treatmentHospitalIncludeOtherTreatment") {
            const checked = this.state.treatmentHospitalOtherTreatment;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                treatmentHospitalOtherTreatment: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });


        } else if (name == "treatmentHospitalIncludeDxProcedures") {
            const checked = this.state.treatmentHospitalIncludeDxProcedures;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                treatmentHospitalIncludeDxProcedures: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "treatmentPhysicianIncludeSurgery") {
            const checked = this.state.treatmentPhysicianSurgery;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                treatmentPhysicianSurgery: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "treatmentPhysicianIncludeChemo") {
            const checked = this.state.treatmentPhysicianChemo;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                treatmentPhysicianChemo: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "treatmentPhysicianIncludeRadiation") {
            const checked = this.state.treatmentPhysicianRadiation;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                treatmentPhysicianRadiation: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "treatmentPhysicianIncludeImmuno") {
            const checked = this.state.treatmentPhysicianImmuno;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                treatmentPhysicianImmuno: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "treatmentPhysicianIncludeHormone") {
            const checked = this.state.treatmentPhysicianHormone;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                treatmentPhysicianHormone: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "treatmentPhysicianIncludeOtherTreatment") {
            const checked = this.state.treatmentPhysicianOtherTreatment;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                treatmentPhysicianOtherTreatment: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });


        } else if (name == "treatmentPhysicianIncludeDxProcedures") {
            const checked = this.state.treatmentPhysicianIncludeDxProcedures;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                treatmentPhysicianIncludeDxProcedures: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        }
    }


    Run_Treatment_Combination = () => {
        //Collate all values needed by the report into one object, dialogFormData, and pass it to the report.
        console.log("Run Treatment Combination Report" + this.downloadFile + "" + this.state.populationLabel);
        var dialogFormData = this.state.dialogForm;

        dialogFormData.reportStatus = "Report Running";
        dialogFormData.population_Label = document.getElementById("populationLabel").value; //this.state.populationLabel;
        dialogFormData.populationLabel = document.getElementById("populationLabel").value;
        dialogFormData.hospital_Code = this.state.hospital_Code;
        dialogFormData.run_Treatment_Hospital = this.state.runTreatmentHospital;
        dialogFormData.run_Treatment_Physician = this.state.runTreatmentPhysician;
        dialogFormData.run_Treatment_Incidence = this.state.runTreatmentIncidence;
        dialogFormData.run_Treatment_Combination = this.state.runTreatmentCombination;
        dialogFormData.run_Treatment_Distribution = this.state.runTreatmentDistribution;

        console.log("Run Treatment Combination Report Surgery: " + this.state.treatmentHospitalSurgery + "     Chemo: " + this.state.treatmentHospitalChemo);

        //dialogFormData.Treatment_by_Hospital_Types = this.state.treatmentHospitalTypes;
        //dialogFormData.Treatment_by_Hospital_Types = this.state.treatmentHospitalSurgery;
        dialogFormData.Treatment_by_Hospital_Types = (this.state.treatmentHospitalSurgery == true ? "Surgery," : "") + (this.state.treatmentHospitalChemo == true ? "Chemo," : "") + (this.state.treatmentHospitalRadiation == true ? "Radiation," : "") + (this.state.treatmentHospitalHormone == true ? "Hormone," : "") + (this.state.treatmentHospitalImmuno == true ? "Immuno," : "") + (this.state.treatmentHospitalOtherTreatment == true ? "Other Treatment," : "");
        dialogFormData.Treatment_by_Physician_Types = (this.state.treatmentPhysicianSurgery == true ? "Surgery," : "") + (this.state.treatmentPhysicianChemo == true ? "Chemo," : "") + (this.state.treatmentPhysicianRadiation == true ? "Radiation," : "") + (this.state.treatmentPhysicianHormone == true ? "Hormone," : "") + (this.state.treatmentPhysicianImmuno == true ? "Immuno," : "") + (this.state.treatmentPhysicianOtherTreatment == true ? "Other Treatment," : "");

        console.log("Run Treatment Combination Report Combined Values: " + dialogFormData.Treatment_by_Hospital_Types);

        dialogFormData.Treatment_by_Hospital_Include_Dx_Procedures = this.state.treatmentHospitalIncludeDxProcedures;
        dialogFormData.Treatment_by_Physician_Include_Dx_Procedures = this.state.treatmentPhysicianIncludeDxProcedures;
        dialogFormData.treatment_Distribution_Type = this.state.treatmentDistributionType;
        dialogFormData.treatment_Values = this.state.treatmentValues;
        dialogFormData.course_FS = this.state.courseFS;
        dialogFormData.includeExcludeFlag = this.state.includeExcludeFlag;
        dialogFormData.outputType = this.state.outputType;

        dialogFormData.reportStatus = this.state.reportStatus;
        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;

        //
        var createPopulationLabel = document.getElementById("createPopulationLabel") ? document.getElementById("createPopulationLabel").value : "0";
        dialogFormData.createPopulationLabel = createPopulationLabel;

        this.props.runTreatmentCombination(dialogFormData);

        this.downloadFile = false;//reset this flag to get download new file

        return false;
    }

    //on before show event
    showClockEvent(e) {
        try {
            e.preventDefault();// This function prevents the form from being auto-submitted
        } catch (ex) {

        }

        //Code for the Editable Pop Label //
        var popLabelComponent = document.getElementById("btnCallToParentFunc");
        if (popLabelComponent) {
            popLabelComponent.click();
        }

        var _this = this;
        _this.setState({ showClockTimer: true, startTime: new Date().toLocaleTimeString() }); // set clock timer...

        ////Set Default Value for the report display radio button
        //console.log(this.state.runTreatmentDistribution);
        //console.log(this.state.runTreatmentCombination);
        //console.log(this.state.runTreatmentPhysician);
        //console.log(this.state.runTreatmentHospital);
        var value = "Distribution";
        ////if (this.state.runTreatmentDistribution == "1") { value = "Distribution" };
        //if (this.state.runTreatmentCombination == "1") { value = "Combination" };
        //if (this.state.runTreatmentPhysician == "1") { value = "Physician" };
        //if (this.state.runTreatmentHospital == "1") { value = "Hospital" };
        //console.log("Value: " + value);

        this.setState({ reportDisplayOption: value});

        this.Run_Treatment_Combination();

        this.clockInterval = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(this.clockInterval);
            } else {
                console.log("Timer Interval Running");
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
                _this.setState({ currentTime: new Date() ? new Date().toLocaleTimeString() : " " });
            }
        }, 1000);

        return false; // Returns false to stop everything after this event has been completed

    }

    Cancel_Report = (e, _this) => {
        _this.setState({ showClockTimer: false });
        clearInterval(this.clockInterval);
        return false;
    }


    displayReport = (data) => {
        //console.log("--- displayReport? Html Data: " + data.htmlOutput);

        this.setState({
            showClockTimer: false,//hide clock timer
            htmlOutput: data.htmlOutput,
            UrlOutputFile: data.UrlOutputFile,
            isDrawingCharts: true,
        });

        var reportDisplay = document.getElementById("displayReportIframe");
        if (reportDisplay) { //This function runs twice and in one instance this component doesn't exist, so this check needs to be there to prevent the entire screen from erroring out
            var reportContent = reportDisplay.contentDocument;
            reportContent.write(data.htmlOutput);
        }

        setTimeout(function () {
            var defaultRadioButton = document.getElementById("treatmentReportDefaultBtn");
            if (defaultRadioButton) {
                defaultRadioButton.click();
            }
        }, 1);

        return false;
    }


    hideDrawingCharts = (e) => {
        this.setState({ isDrawingCharts: false });
        return false;
    }


    newRecord(e) {
        try {
            e.preventDefault();
            this.props.selectAPopLabel("");  //Added to clear population label

            this.setState({
                populationLabel: "",
                hospNbr: "",
                hospital_Code: "",     //This line does not work as the default for some reason
                hospital_Code_Name: "",  //This line works as the default //"ERS Test 2 Hosp"
                runTreatmentHospital: false,
                runTreatmentPhysician: false,
                runTreatmentIncidence: false,
                runTreatmentCombination: false,
                runTreatmentDistribution: false,

                treatmentHospitalSurgery: false,
                treatmentHospitalChemo: false,
                treatmentHospitalRadiation: false,
                treatmentHospitalImmuno: false,
                treatmentHospitalHormone: false,
                treatmentHospitalOtherTreatment: false,
                treatmentHospitalIncludeDxProcedures: false,
                treatmentPhysicianSurgery: false,
                treatmentPhysicianChemo: false,
                treatmentPhysicianRadiation: false,
                treatmentPhysicianImmuno: false,
                treatmentPhysicianHormone: false,
                treatmentPhysicianOtherTreatment: false,
                treatmentPhysicianIncludeDxProcedures: false,

                treatmentDistributionType: "Surgery",
                treatmentValues: "",   //Code to clear out individual row values below.  Could alternatively re-run/re-add blank rows
                courseFS: "First Course",
                includeExcludeFlag: "Include",
                outputType: "Screen",
                reportDisplayOption: "Combination",

                htmlOutput: null,
                statusBar: "",
                reload: true,  //Added to refresh population label criteria
                isFormDirty: false,
            });

            //document.getElementById("populationLabel").value = "";

            var thisTable = document.getElementById('ReportMv');
            var totalRowCount = thisTable.rows.length;
            var i = 0;

            for (i = 0; i < totalRowCount; i++) {
                thisTable.rows[i].cells[1].children[0].value = '';  //Set all rows back to blank
            }

            var dialogFormData = (this.state.dialogForm ? this.state.dialogForm : "Cannot Load this.state.dialogForm");
            dialogFormData.hospital_Code = ""; //="test" this line of code works
            var hosp = (this.state.hospNbr ? this.state.hospNbr : "Cannot display this.state.years");

            this.setState({
                dialogForm: dialogFormData,
            });
        } catch (ex) { }
    }

    defaultRadio = (e) => {
        var defaultRadioValue = "Combination";
        if (this.state.runTreatmentHospital == true) {
            defaultRadioValue = "Hospital";
        } else if (this.state.runTreatmentPhysician == true) {
            defaultRadioValue = "Physician";
        } else if (this.state.runTreatmentIncidence == true) {
            defaultRadioValue = "Site";
        } else if (this.state.runTreatmentCombination == true) {
            defaultRadioValue = "Combination";
        } else {
            defaultRadioValue = "Distribution";
        }
        this.setState({
            reportDisplayOption: defaultRadioValue,
        });
    }


    printReport = (e) => {
        var doc = document.getElementById("displayReportIframe").contentDocument;

        var printStyle = doc.getElementById('PrintStyle');
        var printContent = doc.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Treatment Distribution</title><style type="text/css">';
        html += printStyle.innerHTML + '</style></head><body><div id="printableContent">';
        html += printContent.innerHTML + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }

    render() {
        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait <div className="waitingWaves"><div className="wave"></div><div className="wave"></div><div className="wave"></div><div className="wave"></div><div className="wave"></div><div className="wave"></div><div className="wave"></div><div className="wave"></div><div className="wave"></div><div className="wave"></div></div></div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTime}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let drawingCharts;
        {
            drawingCharts = this.state.isDrawingCharts ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="treatmentCombinationReportDisplay" className="dragableLargestWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Treatment Distribution Reports</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingCharts(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="container" id="treatmentCombinationShowingCharts">
                                <iframe id="displayReportIframe" />
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12" style={{ display: "flex" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ErsGrBlueButton" title=""
                                            onClick={(e) => { this.printReport(e) }}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print Table
                                        </button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ErsGrBlueButton" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={(e) => { this.hideDrawingCharts(e) }} value="exit" autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit
                                        </button>


                                        <div className="reportDisplayOptionRadio">
                                            <label className={this.state.runTreatmentHospital && (this.state.runTreatmentPhysician || this.state.runTreatmentIncidence || this.state.runTreatmentCombination || this.state.runTreatmentDistribution) ? "reportDisplayOptionRadioLabel" : "treatmentReportsHidden"}>
                                                <input type="radio" name="reportDisplayOption" field="reportDisplayOption" value="Hospital"
                                                    checked={this.state.reportDisplayOption === "Hospital"}
                                                    onClick={this.setStateReportDisplayOption} />
                                                Facility
                                            </label>

                                            <label className={this.state.runTreatmentPhysician && (this.state.runTreatmentHospital || this.state.runTreatmentIncidence || this.state.runTreatmentCombination || this.state.runTreatmentDistribution) ? "reportDisplayOptionRadioLabel" : "treatmentReportsHidden"}>
                                                <input type="radio" name="reportDisplayOption" field="reportDisplayOption" value="Physician"
                                                    checked={this.state.reportDisplayOption === "Physician"}
                                                    onClick={this.setStateReportDisplayOption} />
                                                Physician
                                            </label>

                                            <label id="siteDisplayOptionLabel" className={this.state.runTreatmentIncidence && (this.state.runTreatmentHospital || this.state.runTreatmentPhysician || this.state.runTreatmentCombination || this.state.runTreatmentDistribution) ? "reportDisplayOptionRadioLabel" : "treatmentReportsHidden"}>
                                                <input type="radio" name="reportDisplayOption" field="reportDisplayOption" value="Site" id="siteDisplayOptionRadio"
                                                    checked={this.state.reportDisplayOption === "Site"}
                                                    onClick={this.setStateReportDisplayOption} />
                                                Site
                                            </label>

                                            <label className={this.state.runTreatmentCombination && (this.state.runTreatmentHospital || this.state.runTreatmentPhysician || this.state.runTreatmentIncidence || this.state.runTreatmentDistribution) ? "reportDisplayOptionRadioLabel" : "treatmentReportsHidden"}>
                                                <input type="radio" name="reportDisplayOption" field="reportDisplayOption" value="Combination"
                                                    checked={this.state.reportDisplayOption === "Combination"}
                                                    onClick={this.setStateReportDisplayOption} />
                                                Combination
                                            </label>

                                            <label className={this.state.runTreatmentDistribution && (this.state.runTreatmentHospital || this.state.runTreatmentPhysician || this.state.runTreatmentIncidence || this.state.runTreatmentCombination) ? "reportDisplayOptionRadioLabel" : "treatmentReportsHidden"}>
                                                <input type="radio" name="reportDisplayOption" field="reportDisplayOption" value="Distribution"
                                                    checked={this.state.reportDisplayOption === "Distribution"}
                                                    onClick={this.setStateReportDisplayOption} />
                                                Distribution
                                            </label>

                                            <input type="radio" id="fakeRadioControl" onClick={this.fakeRadioControlFnc} className="hidden" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let dialogForm = <div className="reportDialogForm">
            <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                <div className="reportDialogFormContent">
                    <div id="popLabel">
                        {/*Adding modular "Edit Population Label" component on top of this screen*/}
                        <PopLabel plUpdFnc={this.updatePopLabelBackToParent} showToggleBtn={true} updateToParent={this.updateToParent} />
                    </div>




                    <div className="reportScreenTitle">Treatment Distribution Reports</div>
                    {this.state.dialogForm || this.state.loaded ?
                        <form onSubmit={false} id="treatmentCombinationForm" className="">
                            <div className="frameInner">
                                <div id="treatmentReportScreenLeftPanel" className={this.state.runTreatmentHospital || this.state.runTreatmentPhysician || this.state.runTreatmentIncidence || this.state.runTreatmentDistribution ? "maximize" : "minimize"}>
                                {/*<div className="col-sm-7">*/}
                                {/*    <Input type="LabelPopup" focused={this.handleOnFocus}*/}
                                {/*        changed={this.handleFormChange} width="340px"*/}
                                {/*        labelText="Population Label"*/}
                                {/*        field="populationLabel" lineStyle="oneLine"*/}
                                {/*        value={this.state.populationLabel}*/}
                                {/*        dataSource="Labels"*/}
                                {/*        titleDialog="Population Labels" labelWidth="140px"*/}
                                {/*        inValid={this.state.uAllItemFields["populationLabel"]}*/}
                                {/*        lostfocused={this.handleLostFocus}*/}
                                {/*    />*/}
                                {/*</div>*/}

                                {/*<div id="popLabel">*/}
                                {/*    <label>Population Label:</label>*/}
                                {/*    <input type="text" id="populationLabel"*/}
                                {/*        value={this.state.populationLabel}*/}
                                {/*        onChange={(e) => this.setState({ populationLabel: e.target.value })}*/}
                                {/*        labelText="Population Label: "*/}
                                {/*        field="populationLabel"*/}
                                {/*    />*/}
                                {/*</div>*/}

                                {/*<div className="col-sm-7" style={{ display: "flex" }}>*/}
                                {/*    <Input type="Hospital" focused={this.handleOnFocus} changed={this.handleFormChange}*/}
                                {/*        labelText="Treating Hospital(s)" field="hospital_Code" lostfocused={this.handleLostFocus}*/}
                                {/*        value={this.state.hospital_Code}*/}
                                {/*        dataSource="Hospital" titleDialog="Hospital Lookup"*/}
                                {/*        labelWidth="120px" width="240px"*/}
                                {/*        inValid={this.state.uAllItemFields["hospitalCode"]}*/}
                                {/*        lineStyle="oneLine" itemNbr=""*/}
                                {/*        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospitalCode"] : ""}*/}
                                {/*        flag={this.state.availableHosps ? this.state.availableHosps : ""}*/}
                                {/*        hospNbr={this.state.hospital_Code ? this.state.hospital_Code : this.state.availableHosps}*/}
                                {/*        shownType="dropdown" isMultiSelection={false}*/}
                                {/*    />*/}
                                {/*    <label type="label" id="hospital_Code_Name" name="hospital_Code_Name" >{this.state.hospital_Code_Name this.state.dialogForm.hospitalCodeName}</label>*/}
                                {/*</div>*/}

                                
                                <div id="treatmentFacilityRow" className="rows">
                                    <input type="checkbox" id="treatmentFacility" className="checkbox"
                                        name="treatmentFacility"
                                        value={this.state.runTreatmentHospital ? "1" : "0"}
                                        checked={this.state.runTreatmentHospital}
                                        onChange={this.toggleChecked} />
                                    <label for="treatmentFacility" className="checkboxLabel"> Treatment by Facility </label>
                                    <br />
                                    {/*<div id="treatmentFacilityOptions Row" className={this.state.runTreatmentHospital ? "rows" : "hidden"}>*/}
                                    <div className={this.state.runTreatmentHospital ? "show" : "hide"}>
                                        <input type="checkbox" id="treatmentHospitalIncludeDxProcedures" className="checkbox"
                                            name="treatmentHospitalIncludeDxProcedures"
                                            value={this.state.treatmentHospitalIncludeDxProcedures ? "1" : "0"}
                                            checked={this.state.treatmentHospitalIncludeDxProcedures}
                                            onChange={this.toggleChecked} />
                                        <label for="treatmentHospitalIncludeDxProcedures" className="checkboxLabel"> Dx Stage Procedure </label>

                                        <input type="checkbox" id="treatmentHospitalIncludeSurgery" className="checkbox"
                                            name="treatmentHospitalIncludeSurgery"
                                            value={this.state.treatmentHospitalSurgery ? "1" : "0"}
                                            checked={this.state.treatmentHospitalSurgery}
                                            onChange={this.toggleChecked} />
                                        <label for="treatmentHospitalIncludeSurgery" className="checkboxLabel"> Surgery </label>

                                        <input type="checkbox" id="treatmentHospitalIncludeChemo" className="checkbox"
                                            name="treatmentHospitalIncludeChemo"
                                            value={this.state.treatmentHospitalChemo ? "1" : "0"}
                                            checked={this.state.treatmentHospitalChemo}
                                            onChange={this.toggleChecked} />
                                        <label for="treatmentHospitalIncludeChemo" className="checkboxLabel"> Chemo </label>

                                        <input type="checkbox" id="treatmentHospitalIncludeRadiation" className="checkbox"
                                            name="treatmentHospitalIncludeRadiation"
                                            value={this.state.treatmentHospitalRadiation ? "1" : "0"}
                                            checked={this.state.treatmentHospitalRadiation}
                                            onChange={this.toggleChecked} />
                                        <label for="treatmentHospitalIncludeRadiation" className="checkboxLabel"> Radiation </label>

                                        <input type="checkbox" id="treatmentHospitalIncludeImmuno" className="checkbox"
                                            name="treatmentHospitalIncludeImmuno"
                                            value={this.state.treatmentHospitalImmuno ? "1" : "0"}
                                            checked={this.state.treatmentHospitalImmuno}
                                            onChange={this.toggleChecked} />
                                        <label for="treatmentHospitalIncludeImmuno" className="checkboxLabel"> Immuno </label>

                                        <input type="checkbox" id="treatmentHospitalIncludeHormone" className="checkbox"
                                            name="treatmentHospitalIncludeHormone"
                                            value={this.state.treatmentHospitalHormone ? "1" : "0"}
                                            checked={this.state.treatmentHospitalHormone}
                                            onChange={this.toggleChecked} />
                                        <label for="treatmentHospitalIncludeHormone" className="checkboxLabel"> Hormone </label>

                                        <input type="checkbox" id="treatmentHospitalIncludeOtherTreatment" className="checkbox"
                                            name="treatmentHospitalIncludeOtherTreatment"
                                            value={this.state.treatmentHospitalOtherTreatment ? "1" : "0"}
                                            checked={this.state.treatmentHospitalOtherTreatment}
                                            onChange={this.toggleChecked} />
                                        <label for="treatmentHospitalIncludeOtherTreatment" className="checkboxLabel"> Other Treatment </label>
                                    </div>
                                </div>
                                <br />
                                <div id="treatmentPhysicianRow" className="rows">
                                    <input type="checkbox" id="treatmentPhysician" className="checkbox"
                                        name="treatmentPhysician"
                                        value={this.state.runTreatmentPhysician ? "1" : "0"}
                                        checked={this.state.runTreatmentPhysician}
                                        onChange={this.toggleChecked} />
                                    <label for="treatmentPhysician" className="checkboxLabel"> Treatment by Physician </label>
                                    <br />
                                    <div className={ this.state.runTreatmentPhysician ? "show" : "hide"}>
                                        <input type="checkbox" id="treatmentPhysicianIncludeDxProcedures" className="checkbox"
                                            name="treatmentPhysicianIncludeDxProcedures"
                                            value={this.state.treatmentPhysicianIncludeDxProcedures ? "1" : "0"}
                                            checked={this.state.treatmentPhysicianIncludeDxProcedures}
                                            onChange={this.toggleChecked} />
                                        <label for="treatmentPhysicianIncludeDxProcedures" className="checkboxLabel"> Dx Stage Procedure </label>

                                        <input type="checkbox" id="treatmentPhysicianIncludeSurgery" className="checkbox"
                                            name="treatmentPhysicianIncludeSurgery"
                                            value={this.state.treatmentPhysicianSurgery ? "1" : "0"}
                                            checked={this.state.treatmentPhysicianSurgery}
                                            onChange={this.toggleChecked} />
                                        <label for="treatmentPhysicianIncludeSurgery" className="checkboxLabel"> Surgery </label>

                                        <input type="checkbox" id="treatmentPhysicianIncludeChemo" className="checkbox"
                                            name="treatmentPhysicianIncludeChemo"
                                            value={this.state.treatmentPhysicianChemo ? "1" : "0"}
                                            checked={this.state.treatmentPhysicianChemo}
                                            onChange={this.toggleChecked} />
                                        <label for="treatmentPhysicianIncludeChemo" className="checkboxLabel"> Chemo </label>

                                        <input type="checkbox" id="treatmentPhysicianIncludeRadiation" className="checkbox"
                                            name="treatmentPhysicianIncludeRadiation"
                                            value={this.state.treatmentPhysicianRadiation ? "1" : "0"}
                                            checked={this.state.treatmentPhysicianRadiation}
                                            onChange={this.toggleChecked} />
                                        <label for="treatmentPhysicianIncludeRadiation" className="checkboxLabel"> Radiation </label>

                                        <input type="checkbox" id="treatmentPhysicianIncludeImmuno" className="checkbox"
                                            name="treatmentPhysicianIncludeImmuno"
                                            value={this.state.treatmentPhysicianImmuno ? "1" : "0"}
                                            checked={this.state.treatmentPhysicianImmuno}
                                            onChange={this.toggleChecked} />
                                        <label for="treatmentPhysicianIncludeImmuno" className="checkboxLabel"> Immuno </label>

                                        <input type="checkbox" id="treatmentPhysicianIncludeHormone" className="checkbox"
                                            name="treatmentPhysicianIncludeHormone"
                                            value={this.state.treatmentPhysicianHormone ? "1" : "0"}
                                            checked={this.state.treatmentPhysicianHormone}
                                            onChange={this.toggleChecked} />
                                        <label for="treatmentPhysicianIncludeHormone" className="checkboxLabel"> Hormone </label>

                                        <input type="checkbox" id="treatmentPhysicianIncludeOtherTreatment" className="checkbox"
                                            name="treatmentPhysicianIncludeOtherTreatment"
                                            value={this.state.treatmentPhysicianOtherTreatment ? "1" : "0"}
                                            checked={this.state.treatmentPhysicianOtherTreatment}
                                            onChange={this.toggleChecked} />
                                        <label for="treatmentPhysicianIncludeOtherTreatment" className="checkboxLabel"> Other Treatment </label>
                                    </div>
                                </div>
                                <br />

                                <div id="treatmentIncidenceRow" className="rows">
                                    <input type="checkbox" id="treatmentIncidence" className="checkbox"
                                        name="treatmentIncidence"
                                        value={this.state.runTreatmentIncidence ? "1" : "0"}
                                        checked={this.state.runTreatmentIncidence}
                                        onChange={this.toggleChecked} />
                                    <label for="treatmentIncidence" className="checkboxLabel"> Treatment by Site </label>

                                    <div className={this.state.runTreatmentIncidence ? "show" : "hide"}>
                                        <div className="includeExcludeFlagRadio">
                                            <div id="includeExcludeFlagLabel">Ca in-situ cervix, squamous and basal skin cell, and intraepithelial neoplasia cases:</div>
                                            <label>
                                                <input type="radio" name="includeExcludeFlag" field="includeExcludeFlag" value="Include"
                                                    onClick={this.setStateIncludeExcludeFlag}
                                                    checked={this.state.includeExcludeFlag === "Include"}/>
                                                Include
                                            </label>
                                            <br />
                                            <label>
                                                <input type="radio" name="includeExcludeFlag" field="includeExcludeFlag" value="Exclude"
                                                    onClick={this.setStateIncludeExcludeFlag}
                                                    checked={this.state.includeExcludeFlag === "Exclude"}/>
                                                Exclude
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <br />

                                <input type="checkbox" id="treatmentCombination" className="checkbox"
                                    name="treatmentCombination"
                                    value={this.state.runTreatmentCombination ? "1" : "0"}
                                    checked={this.state.runTreatmentCombination}
                                    onChange={this.toggleChecked} />
                                <label for="treatmentCombination" className="checkboxLabel"> Treatment Combination </label>
                                <br />
                                <br />
                                <div id="treatmentDistributionRow" className="rows">
                                    <input type="checkbox" id="treatmentDistribution" className="checkbox"
                                        name="treatmentDistribution"
                                        value={this.state.runTreatmentDistribution ? "1" : "0"}
                                        checked={this.state.runTreatmentDistribution}
                                        onChange={this.toggleChecked} />
                                    <label for="treatmentDistribution" className="checkboxLabel"> Treatment Distribution </label>
                                    <br />
                                    <div className={this.state.runTreatmentDistribution ? "show" : "hide"}>
                                        <div className="treatmentDistributionTypeRadio">
                                            <div id="treatmentDistributionTypeLabel" style={{ verticalAlign: "middle", border: "none" }}>Treatment Type:</div>
                                            <label>
                                                <input type="radio" name="treatmentDistributionType" field="treatmentDistributionType" value="Dx Stage Procedure"
                                                    onClick={this.setStateTreatmentDistributionType}
                                                    checked={this.state.treatmentDistributionType === "Dx Stage Procedure"} />
                                                Dx Stage Procedure
                                            </label>
                                            <br />
                                            <label>
                                                <input type="radio" name="treatmentDistributionType" field="treatmentDistributionType" value="Surgery"
                                                    onClick={this.setStateTreatmentDistributionType}
                                                    checked={this.state.treatmentDistributionType === "Surgery"} />
                                                Surgery
                                            </label>
                                            <br />
                                            <label>
                                                <input type="radio" name="treatmentDistributionType" field="treatmentDistributionType" value="Scope of LN Surgery"
                                                    onClick={this.setStateTreatmentDistributionType}
                                                    checked={this.state.treatmentDistributionType === "Scope of LN Surgery"} />
                                                Scope of LN Surgery
                                            </label>
                                            <br />
                                            <label>
                                                <input type="radio" name="treatmentDistributionType" field="treatmentDistributionType" value="Number of LN Examined"
                                                    onClick={this.setStateTreatmentDistributionType}
                                                    checked={this.state.treatmentDistributionType === "Number of LN Examined"} />
                                                Number of LN Examined
                                            </label>
                                            <label>
                                                <input type="radio" name="treatmentDistributionType" field="treatmentDistributionType" value="Surgery Other/Regional Site"
                                                    onClick={this.setStateTreatmentDistributionType}
                                                    checked={this.state.treatmentDistributionType === "Surgery Other/Regional Site"} />
                                                Surgery Other/Regional Site
                                            </label>
                                            <label>
                                                <input type="radio" name="treatmentDistributionType" field="treatmentDistributionType" value="Radiation"
                                                    onClick={this.setStateTreatmentDistributionType}
                                                    checked={this.state.treatmentDistributionType === "Radiation"} />
                                                Radiation
                                            </label>
                                            <label>
                                                <input type="radio" name="treatmentDistributionType" field="treatmentDistributionType" value="Chemotherapy"
                                                    checked={this.state.treatmentDistributionType === "Chemotherapy"}
                                                    onClick={this.setStateTreatmentDistributionType} />
                                                Chemo
                                            </label>
                                            <label>
                                                <input type="radio" name="treatmentDistributionType" field="treatmentDistributionType" value="Hormone Therapy"
                                                    onClick={this.setStateTreatmentDistributionType}
                                                    checked={this.state.treatmentDistributionType === "Hormone Therapy"} />
                                                Hormone Therapy
                                            </label>
                                            <label>
                                                <input type="radio" name="treatmentDistributionType" field="treatmentDistributionType" value="Immunotherapy"
                                                    onClick={this.setStateTreatmentDistributionType}
                                                    checked={this.state.treatmentDistributionType === "Immunotherapy"} />
                                                Immunotherapy
                                            </label>
                                            <label>
                                                <input type="radio" name="treatmentDistributionType" field="treatmentDistributionType" value="Other Treatment"
                                                    onClick={this.setStateTreatmentDistributionType}
                                                    checked={this.state.treatmentDistributionType === "Other Treatment"} />
                                                Other Treatment
                                            </label>
                                        </div>

                                        <div className="treatmentValues" onKeyUp={this.gatherRows}>
                                            <label id="treatmentValuesLabel">Treatment Values:</label><br />
                                            {/*<div id="header"> <div id="c0">&nbsp;</div> <div id="c1">Values</div>  </div>*/}
                                            <div id="MvContent">
                                                <table id="ReportMv">
                                                    <tbody>
                                                        <tr>
                                                            <td id="tdr0c0"> </td>
                                                            <td> <input id="r0c1" className="tc1" onKeyDown={this.handleOnKeyDown} /> </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                            {/*<input type="text" id="treatmentValues"*/}
                                            {/*    value={this.state.treatmentValues}*/}
                                            {/*    onChange={(e) => this.setState({ treatmentValues: e.target.value })}*/}
                                            {/*    />*/}
                                        </div>

                                        <div id="dialogName" style={{ width: "30px", height: "5px" }}>.</div>
                                        <div id="thisRowNbr" style={{ width: "30px", height: "5px" }}> </div>
                                        <div id="Report_Criteria"> - </div>
                                    </div>
                                    <br />

                                </div>
                                </div>{/*End of treatmentReportScreenLeftPanel*/}

                                <div id="treatmentReportScreenRightPanel">
                                    <div className="col-sm-7" style={{ display: "flex" }}>
                                        <Input type="codeMultiSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Treating Hospital(s)" field="hospital_Code" lostfocused={this.handleLostFocus}
                                            value={this.state.hospital_Code}
                                            dataSource="Hospital2L" titleDialog="Hospital Lookup"
                                            labelWidth="120px" width="240px"
                                            inValid={this.state.uAllItemFields["hospitalCode"]}
                                            lineStyle="oneLine" itemNbr=""
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospitalCode"] : ""}
                                            flag={this.state.availableHosps ? this.state.availableHosps : ""}
                                            hospNbr={this.state.hospital_Code ? this.state.hospital_Code : this.state.availableHosps}
                                            shownType="dropdown" isMultiSelection={false}
                                        />
                                        <label type="label" id="hospital_Code_Name" name="hospital_Code_Name" >{this.state.hospital_Code_Name}</label>
                                    </div>
                                    <br />
                                    <div className="courseFSRadio">
                                        <div id="courseFSLabel">Course of Treatment:</div>
                                        <label>
                                            <input type="radio" name="courseFS" field="courseFS" value="First Course"
                                                onClick={this.setStateCourseFS}
                                                checked={this.state.courseFS === "First Course"} />
                                            First Course
                                        </label>
                                        <br />
                                        <label>
                                            <input type="radio" name="courseFS" field="courseFS" value="Subsequent Course"
                                                onClick={this.setStateCourseFS}
                                                checked={this.state.courseFS === "Subsequent Course"} />
                                            Subsequent
                                        </label>
                                        <br />
                                        <label>
                                            <input type="radio" name="courseFS" field="courseFS" value="All Courses"
                                                onClick={this.setStateCourseFS}
                                                checked={this.state.courseFS === "All Courses"} />
                                            All Courses
                                        </label>
                                    </div>
                                    <br /><br />
                                    <div className="outputDeviceRadio">
                                        <div id="outputDeviceLabel">Send Report To:</div>
                                        <label>
                                            <input type="radio" name="outputType" field="outputType" value="Excel"
                                                onClick={this.setStateOutputType}
                                                checked={this.state.outputType === "Excel"}/>
                                            Excel
                                        </label>
                                        <br />
                                        <label>
                                            <input type="radio" name="outputType" field="outputType" value="Screen"
                                                onClick={this.setStateOutputType}
                                                checked={this.state.outputType === "Screen"}/>
                                            Screen
                                        </label>
                                    </div>
                                <div className="row">

                                    </div>
                                </div>
                                <br />

                                <div id="CONTAINER_JS_CLOCK" className="hidden">
                                    <label>Selecting Cases</label>
                                    <div id="IMAGE_WAIT" style={{ color: "black", fontWeight: "bold" }}>
                                        Selecting Cases - Please Wait ...
                                    </div>
                                </div>
                            </div>

                            <div className="reportScreenStatusBar">
                                Start Time: {this.state.startTime} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; End Time: {this.state.endTime}
                            </div>

                            <fieldset className="reportDialogFormButtonBar">
                                <button id="BUTTON_RUN"
                                    className="ErsGrBlueButton"
                                    onClick={(e) => { this.showClockEvent(e) }}
                                //disabled={!this.state.isFormDirty}
                                >
                                    <img id="BUTTON_RUN_ICON"
                                        src="https://crstar.ers-can.com/images/$$code.action.run.png.a5image"
                                        style={{ verticalAlign: "middle", border: "none" }}></img>
                                    Run
                                </button>

                                <button id="BUTTON_NEWRECORD"
                                    className={this.state.isFormDirty ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                    onClick={this.newRecord.bind(this)}
                                    //onClick={(e) => { this.showClockEvent(e) }}
                                    disabled={!this.state.isFormDirty}
                                >
                                    <img id="BUTTON_NEWRECORD_ICON"
                                        src={this.state.isFormDirty ? "https://crstar.ers-can.com/images/$$navigate.new.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$navigate.new.png.a5image"}
                                        style={{ verticalAlign: "middle", border: "none" }}></img>
                                    New
                                </button>

                                <button type="button" id="treatmentReportDefaultBtn" className="hidden" title=""
                                    onClick={(e) => { this.defaultRadio(e) }} value="default" autoFocus>
                                    Default
                                </button>
                            </fieldset>
                        </form>
                        : <p>Treatment Combination report screen encountered an issue and cannot load ...</p>}
                </div>
            </div>
        </div>

        return (
            <React.Fragment>
                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {drawingCharts}
                </React.Fragment>

                <React.Fragment>
                    {dialogForm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

TreatmentCombination.propTypes = {

    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        treatmentCombinationInfo: state.patientAbstract.treatmentCombinationInfo,           //This holds information passed from the C# report back to the screen
        clientgroup: state.auth.clientgroup,                            //Passed to C# report to get path
        userId: state.auth.userId,                                      //Passed to C# report to get path

        initialPageVisible: state.patientAbstract.initialPageVisible,   //Controls if security restrictions are set to prevent page from loading
        isDialogLoading: state.patientAbstract.isDialogLoading,
        waitingFor: state.patientAbstract.waitingFor,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        isAddNewUser: state.patientAbstract.isAddNewUser,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        runTreatmentCombination: (dialogForm) => dispatch(actionCreators.runTreatmentCombination(dialogForm)),
        generateTreatmentCombination: (flag) => dispatch(actionCreators.generateTreatmentCombination(flag)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),


        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearAddNewUserFlag: () => dispatch(actionCreators.clearAddNewUserFlag()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),
        selectAPopLabel: (label) => dispatch(actionCreators.selectAPopLabel(label)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentCombination);