/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../../store/PatientAbstract";
import Footer from "../../Shared/Footer";
import Input from "../../../../UI/Input";
import axios from "../../../../../axios-instance";
import "../TreatmentMaintenance.css";
import "../SurgeryMaintenance/SurgeryMaintenance.css";
import "./ChemoMaintenance.css";
import moment from "moment";
import Help from "../../../../UI/Help";
import Draggable from 'react-draggable';
import * as Global from "../../../../../store/Global";

class ChemoMaintenance extends Component {

    state = {
        dAllItemFields: null,

        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'surgeryForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty surgeryForm back to what it was on load
        initialSurgeryForm: null,
        surgeryForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        fieldInFocus: "chemoIntent",
        isHelpVisible: false,
        focusingField: null,

        primaryItemFields: null,
        primaries: null,
        initialPrimaries: null,
        isPrimaryDirty: false,
        primTopo: null,
        primSite: null,

        isShowConfirmDeleteMsg: false,
        confirmed: null,
        monitoring: null,
        isSaving: false,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,
        allowSaveAnyway: true,

        primariesMaxlengthInput: null,

        selectedRowIndex: -1,

        isShowAlertMsg: false,
        rowIndex: 0,
    }

    setSelectionRow = (event, index) => {
        this.setState({ selectedRowIndex: index });
    }

    componentDidMount() {
        if (!this.state.surgeryForm && this.props.selectedSurgeryRecord) {
            this.setFormData();
        }
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.surgeryForm && this.props.selectedSurgeryRecord) || (prevProps.selectedSurgeryRecord && prevProps.selectedSurgeryRecord !== this.props.selectedSurgeryRecord)) {
            this.setFormData();
        }

        if (this.state.isFormDirty && this.state.isSaving && prevProps.isFormDirty !== this.props.isFormDirty) {
            this.setState({
                isFormDirty: this.props.isFormDirty,
                isSaving: this.props.isFormDirty,
            });
        }
        if (this.props.selectedPrimarySummary && !this.state.primaries) {
            axios.post(this.props.clientgroup + "/GetRxChemoMvList", { primarySummaryId: this.props.selectedPrimarySummary.cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ primaries: res.data.rxChemoMvList, initialPrimaries: res.data.rxChemoMvList });
                        this.setState({ surgeryForm: { ...this.state.surgeryForm, primarySummary: res.data.rxChemoMvList } });

                        // Primary List
                        if (!this.state.primaryItemFields && res.data.rxChemoMvList) {
                            let primariesArr = [];
                            res.data.rxChemoMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                primariesArr.push(fields);
                            });

                            this.setState({ primaryItemFields: primariesArr });

                            // get maxlength for Primary grid
                            //axios.post(this.props.clientgroup + "/GetTableStructure?table=Rx_Chemo_Mv", { table: "Rx_Chemo_Mv", accessToken: localStorage.getItem('token') })
                            axios.post(this.props.clientgroup + "/GetTableStructure", { table: "Rx_Chemo_Mv", accessToken: localStorage.getItem('token') })
                                .then(res => {
                                    //update new accessToken again
                                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                                    if (newAccessToken) {
                                        localStorage.setItem('token', newAccessToken);
                                    }

                                    if (res.data !== null && res.data.result !== "") {
                                        let maxLengthValue = res.data.result;
                                        let maxLengthItem = primariesArr && primariesArr[0] ? primariesArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                        this.setState({
                                            primariesMaxlengthInput: { ...maxLengthItem }
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    /*
                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }
                                    */
                                    //@TODO:...

                                    return false;
                                });
                        };
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.surgeryForm && this.state.dAllItemFields
        ) {
            console.log("[Surgery] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                // updating the grid
                if (this.props.currentSelectedIndex !== -1) {
                    // primary grid
                    this.setState(prevState => {
                        const newItems = [...prevState.primaryItemFields];

                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "";
                        }
                        return { primaryItemFields: newItems };
                    });
                } else {
                    this.setState({
                        dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "" },
                        // And also update name again
                        surgeryForm: { ...this.state.surgeryForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                    });
                }
            } else {
                // updating the grid
                if (this.props.currentSelectedIndex !== -1) {
                    // primary grid
                    this.setState(prevState => {
                        const newItems = [...prevState.primaryItemFields];
                        if (newItems[this.props.currentSelectedIndex]
                            && this.props.currentSelectedField != "chemoDrug"
                            && this.props.currentSelectedField != "chemoRegimen"
                        ) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "IN_VALID";
                        }
                        return { primaryItemFields: newItems };
                    });
                } else {
                    this.setState({
                        dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        surgeryForm: { ...this.state.surgeryForm, [this.props.currentSelectedField + "Name"]: "" },
                    });
                }
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "Rx_Summary", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    setFormData = () => {
        this.setState({
            surgeryForm: { ...this.props.selectedSurgeryRecord },
            isFormDirty: this.props.isFormDirty,

            primTopo: this.props.selectedMedicalRecord ? this.props.selectedMedicalRecord.topoCode : ""
        });

        if (this.props.selectedPrimarySummary) {
            this.setState({
                primTopo: this.props.selectedMedicalRecord ? this.props.selectedMedicalRecord.topoCode : "",
                primSite: this.props.selectedMedicalRecord.topoCode ? this.props.selectedMedicalRecord.topoCode.substring(2) : "",
            });
        }

        if (!this.state.dAllItemFields && this.props.selectedSurgeryRecord) {
            let fields = {};
            Object.keys(this.props.selectedSurgeryRecord).map((t) => {
                fields[t] = "";
            });

            this.setState({ dAllItemFields: fields, pageMaxlengthInput: fields });
        };

        return false;
    }

    // Add clear and check validate of date fields function -- START
    clearInvalidOfDateFields = (formItems, field, onGrid, index) => {
        let tYear = field + "Year";
        let tMonth = field + "Month";
        let tDay = field + "Day";
        if (onGrid && onGrid === "primaryItemList") {
            this.setState(prevState => {
                let newItems = [...prevState.primaryItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { primaryItemFields: newItems };
            });
        } else {
            this.setState({
                dAllItemFields: {
                    ...this.state.dAllItemFields,
                    [tYear]: "",
                    [tMonth]: "",
                    [tDay]: "",
                }
            });
        }
        formItems[tYear] = "";
        formItems[tMonth] = "";
        formItems[tDay] = "";
    }

    checkValidateOfDateFields = (pFormData, field, onGrid, index) => {
        let isValidDateInput = true;

        var tYear = field + "Year";
        var tMonth = field + "Month";
        var tDay = field + "Day";

        // Check valid of date in February
        var year = pFormData[tYear] ? pFormData[tYear] : "";
        var month = pFormData[tMonth] ? pFormData[tMonth] : "";
        var day = pFormData[tDay] ? pFormData[tDay] : "";

        pFormData[field] = new Date(year + "-" + month + "-" + day);

        if (year !== "" && month !== "" && day !== "") {
            var _day, A = (month + '/' + day + '/' + year).match(/[1-9][\d]*/g);
            try {
                A[0] -= 1;
                _day = new Date(+A[2], A[0], +A[1]);
                if ((_day.getMonth() == A[0] && _day.getDate() == A[1])
                                /*|| year == '9999'*/ || month == '99' || day == '99') {
                } else {
                    isValidDateInput = false;

                    if (onGrid && onGrid === "primaryList") {
                        this.setState(prevState => {
                            const newItems = [...prevState.primaryItemFields];
                            newItems[index][tYear] = "IN_VALID";
                            newItems[index][tMonth] = "IN_VALID";
                            newItems[index][tDay] = "IN_VALID";
                            return { primaryItemFields: newItems };
                        });
                    } else {
                        this.setState({
                            dAllItemFields: {
                                ...this.state.dAllItemFields,
                                [tYear]: "IN_VALID",
                                [tMonth]: "IN_VALID",
                                [tDay]: "IN_VALID",
                            }
                        });
                    }
                }
            } catch (er) {
                isValidDateInput = false;
            }
        }

        return isValidDateInput;
    }
    // -- END

    saveSurgery = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        var allowSaveAnyway = this.state.allowSaveAnyway;

        const formItems = this.state.dAllItemFields;
        Object.keys(formItems).map((t) => {
            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (isValid) {
            const pItems = this.state.primaryItemFields;
            pItems.map((prm, index) => {
                Object.keys(prm).map((field) => {
                    // clear IN_VALID flag for all date fields
                    if (field == "chemoStartDate" || field.startsWith("chemoStartDate")
                        || field === "chemoEndDate" || field.startsWith("chemoEndDate")
                    ) {
                        this.clearInvalidOfDateFields(prm, field, "primaryItemList", index);
                    }
                    /*
                    let val = this.state.primaries[index][field];
                    val === "Invalid Date" ? val = "" : "";
                    let fsFlag = this.state.primaries[index]["chemoFsFlag"];
                    if ((field === "chemoStartDateYear" || field === "chemoStartDateMonth" || field === "chemoStartDateDay" || field.startsWith("chemoStartDate")) && val !== null && val !== undefined && val.toString() !== "Invalid Date" && (fsFlag === null || fsFlag === "")) {
                        // update status invalid on view
                        this.setState(prevState => {
                            const newItems = [...prevState.primaryItemFields];
                            if (newItems[index]) {
                                newItems[index]["chemoFsFlag"] = "IN_VALID";
                            }
                            return { primaryItemFields: newItems };
                        });

                        return isValid = false;
                    }
                    */
                });
            });
        }

        let primaryList = this.state.primaries;
        var rowIdx = 0;
        var isValidFsFlag = true;
        primaryList.map((obj, index) => {
            if (obj["chemoStartDateYear"] && obj["chemoStartDateYear"] != ""
                && obj["chemoStartDateMonth"] && obj["chemoStartDateMonth"] != ""
                && obj["chemoStartDateDay"] && obj["chemoStartDateDay"] != ""
                && obj["chemoFsFlag"] != "F" && obj["chemoFsFlag"] != "S") {
                isValidFsFlag = false;
                rowIdx = index;
                return false;
            }
        });

        if (!isValidFsFlag /*&& !allowSaveAnyway*/) {
            /*
            alert('Must have a First Course/Subsequent flag if start date is present.');
            document.getElementById("chemoFsFlag" + rowIdx) ? document.getElementById("chemoFsFlag" + rowIdx).focus() : "";
            */
            this.setState({ isShowAlertMsg: true, rowIndex: rowIdx });
            this.props.setChildrenOpening(true);
            return false;
        } else {
            let surgeryFormData = this.state.surgeryForm;

            let primaryList = this.state.primaries;

            let isValidDateInput = true;

            //#20210125 - START
            primaryList.map((obj, index) => {
                Object.keys(obj).map((t) => {
                    if (t == "chemoStartDate" || t == "chemoEndDate") {
                        isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(obj, t, "primaryList", index);
                    }
                });
            });
            //#20210125 - END

            if (!isValidDateInput && !allowSaveAnyway) return false;

            surgeryFormData.rxChemoMv = primaryList;
            surgeryFormData.aliasForm = "Chemo";

            this.props.saveSurgery(surgeryFormData);

            this.setState({
                isSaving: true,
            });

            return false;
        }
    }

    resetSurgery = () => {
        this.setState({
            surgeryForm: { ...this.state.initialSurgeryForm },
            isFormDirty: false,
            primaries: null,
            primaryItemFields: null,
        });
        return false;
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
        return false;
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            this.props.checkValidInput(name, value, table, key, -1, flag);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let value = event.target.value;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState({ surgeryForm: { ...this.state.surgeryForm, [event.target.name]: value } });
                } else {
                    this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
                }
            } else {
                // clear all error style
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
            }
            //#20210125 - END
        } else {
            // check required fields input
            if (event.target.name === "suspenseRemarks" && event.target.value === "") {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
                // check validation of date field input
            } else if (event.target._element && event.target._element.className === "k-widget k-datepicker" && event.target.dateInput._element.value !== "month/day/year") {
                if (event.target.dateInput._element.validity.valid) {
                    //this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
                } else {
                    this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
                }
            } else {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
            }
        }
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ surgeryForm: { ...this.state.surgeryForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({ surgeryForm: { ...this.state.surgeryForm, [event.target.name]: event.target.value }, isFormDirty: true });
        }
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isHelpVisible: false });
        return false;
    }

    handlePrimaryLostfocus = (event, index) => {
        var name = event.target.name;
        var value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            if (name === "chemoFsFlag" && value !== "") {
                var fsVal = value.toUpperCase();
                value = fsVal;
                this.setState(prevState => {
                    const newItems = [...prevState.primaries];
                    newItems[index][name] = fsVal;

                    const iFields = [...prevState.primaryItemFields];
                    iFields[index][name] = "";
                    return {
                        primaries: newItems,
                        primaryItemFields: iFields
                    };
                });
            }

            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "";
            this.props.checkValidInput(name, value, table, key, index, flag);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let isvalid = true;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                isvalid = false;
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                isvalid = false;
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                let value = event.target.value;
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState(prevState => {
                        const newItems = [...prevState.primaries];
                        newItems[index][name] = value;
                        return { primaries: newItems };
                    });
                } else {
                    isvalid = false;
                }
            }

            if (!isvalid) {
                this.setState(prevState => {
                    const newItems = [...prevState.primaryItemFields];
                    newItems[index][name] = "IN_VALID";
                    return { primaryItemFields: newItems };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.primaryItemFields];
                    newItems[index][name] = "";
                    return { primaryItemFields: newItems };
                });
            }
            //#20210125 - END

        } else {
            this.setState(prevState => {
                const newItems = [...prevState.primaryItemFields];
                newItems[index][name] = "";
                return { primaryItemFields: newItems };
            });
        }
    }

    handlePrimaryGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.primaries];
            newItems[index][name] = value;
            newItems[index]["isPrimaryDirty"] = true;
            return { primaries: newItems, isFormDirty: true };
        });
    }

    addNewPrimary = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.primaries];
            const firstPrimary = newItems[0];

            const newPrimary = {};
            Object.keys(firstPrimary).map((t, k) => {
                if (t === "cid" || t === "pid" || t === "id") {
                    newPrimary[t] = 0;
                } else {
                    newPrimary[t] = "";
                }
            });
            newItems.push(newPrimary);

            // 
            const newFields = [...this.state.primaryItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "cid" || t === "pid" || t === "id") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { primaries: newItems, primaryItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }

    deleteSelectedItemPrimary = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.primaries.length) {
            selectedRow = this.state.primaries.length - 1;
        }

        if (this.state.primaries[selectedRow] && this.state.primaries[selectedRow]["id"] !== undefined && this.state.primaries[selectedRow]["id"] !== 0) {
            this.props.setChildrenOpening(true);
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "chemo" });
        } else {
            this.deleteItemOnGrid();
        }
        return false;
    }

    deleteItemOnGrid = () => {
        let selectedRow = this.state.selectedRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.primaries.length) {
            selectedRow = this.state.primaries.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.primaries];
            if (newItems.length === 1) {
                const _newItems = [...this.state.primaries];
                const firstPrim = _newItems[0];

                const newPrim = {};
                Object.keys(firstPrim).map((t, k) => {
                    if (t === "id" || t === "pid" || t === "cid") {
                        //newPrim[t] = 0;
                    } else {
                        newPrim[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newPrim);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.primaryItemFields];
            if (newFields.length == 1) {
                const _newFields = [...this.state.primaryItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { primaries: newItems, primaryItemFields: newFields, selectedRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }

    hideConfirmDeleteMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowConfirmDeleteMsg: false });

        this.props.setChildrenOpening(false);

        if (event.target.value === "yes" && this.state.monitoring) {
            switch (this.state.monitoring) {
                case "chemo":
                    this.deleteItemOnGrid();
                    break;
                default:
                    break;
            }
        }
        return false;
    }

    //
    hideAlertMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowAlertMsg: false });
        this.props.setChildrenOpening(false);

        var rowIdx = this.state.rowIndex;

        setTimeout(function () {
            document.getElementById("chemoFsFlag" + rowIdx) ? document.getElementById("chemoFsFlag" + rowIdx).focus() : "";
        }, 100);

        return false;
    }

    render() {
        let confirmDeleteMsg;
        {
            confirmDeleteMsg = this.state.isShowConfirmDeleteMsg ?
                /*
                <Dialog title="Delete Row"
                    onClose={this.hideConfirmDeleteMsg}
                    width={300} height={"auto"}
                    className={classes.title}>*/
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Delete Row</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>OK to delete row?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                        {/*</Dialog>*/}
                    </Draggable>
                </div>
                : null
        }

        let alertMsg;
        {
            alertMsg = this.state.isShowAlertMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Warning</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideAlertMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Must have a First Course/Subsequent flag if start date is present.</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideAlertMsg} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const surgeryMaintenance = this.state.surgeryForm && !this.props.isChildrenLoading ?
            (
                <div className="container-fluid">

                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} focusingField={this.state.focusingField} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.saveSurgery} id="surgeryForm" className="DialogForm PopupChildrenPage">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq Prim: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo">
                                    <button type="button" onClick={this.getHelp}>
                                        <img src="https://crstar.ers-can.com/images/$sys_question.png.a5image" />
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {
                                        //this.props.selectedPatient.dob != null && this.props.selectedPatient.dob !== "" ? moment(new Date(this.props.selectedPatient.dob)).format("MM/DD/YYYY") : ""
                                        this.props.selectedPatient.dobMonth + "/" + this.props.selectedPatient.dobDay + "/" + this.props.selectedPatient.dobYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {
                                        //this.props.selectedPrimarySummary.diagnosisDate != null && this.props.selectedPrimarySummary.diagnosisDate !== "" ? moment(new Date(this.props.selectedPrimarySummary.diagnosisDate)).format("MM/DD/YYYY") : ""
                                        this.props.selectedPrimarySummary.diagnosisDateMonth + "/" + this.props.selectedPrimarySummary.diagnosisDateDay + "/" + this.props.selectedPrimarySummary.diagnosisDateYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>

                        <div className="emptyLine"></div>

                        <div className="frameInner">
                            <div className="header">&nbsp;</div>
                            <div className="emptyLine"></div>
                            <div className="" style={{ display: "flex" }}>
                                <div className="" style={{ width: "245px" }}>
                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText={this.state.surgeryForm.chemoUserLblText1 ? this.state.surgeryForm.chemoUserLblText1 : "Chemo User 1"}//labelText="Chemo User 1"
                                        field="chemoUser1"
                                        value={this.state.surgeryForm.ChemoUser1 ? this.state.surgeryForm.ChemoUser1 : this.state.surgeryForm.chemoUser1}
                                        dataSource="Global_Dir_Mv" aliasNo="841" lineStyle="oneLine"
                                        titleDialog="Chemo User 1" labelWidth="115px" width="95px"
                                        inValid={this.state.dAllItemFields["chemoUser1"]} itemNbr="841"
                                        lostfocused={this.handleLostFocus} autoFocus
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["chemoUser1"] : ""}
                                        flag="ERS_UserDefinedMaintenance"
                                    />
                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText={this.state.surgeryForm.chemoUserLblText2 ? this.state.surgeryForm.chemoUserLblText2 : "Chemo User 2"}//labelText="Chemo User 2"
                                        field="chemoUser2"
                                        value={this.state.surgeryForm.chemoUser2 ? this.state.surgeryForm.chemoUser2 : this.state.surgeryForm.ChemoUser2}
                                        dataSource="Global_Dir_Mv" aliasNo="842" lineStyle="oneLine"
                                        titleDialog="Chemo User 2" labelWidth="115px" width="95px"
                                        inValid={this.state.dAllItemFields["chemoUser2"]} itemNbr="842"
                                        lostfocused={this.handleLostFocus}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["chemoUser2"] : ""}
                                        flag="ERS_UserDefinedMaintenance"
                                    />
                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText={this.state.surgeryForm.chemoUserLblText3 ? this.state.surgeryForm.chemoUserLblText3 : "Chemo User 3"}//labelText="Chemo User 3"
                                        field="chemoUser3"
                                        value={this.state.surgeryForm.chemoUser3 ? this.state.surgeryForm.chemoUser3 : this.state.surgeryForm.ChemoUser3}
                                        dataSource="Global_Dir_Mv" aliasNo="843" lineStyle="oneLine"
                                        titleDialog="Chemo User 3" labelWidth="115px" width="95px"
                                        inValid={this.state.dAllItemFields["chemoUser3"]} itemNbr="843"
                                        lostfocused={this.handleLostFocus}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["chemoUser3"] : ""}
                                        flag="ERS_UserDefinedMaintenance"
                                    />
                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText={this.state.surgeryForm.chemoUserLblText4 ? this.state.surgeryForm.chemoUserLblText4 : "Chemo User 4"}//labelText="Chemo User 4"
                                        field="chemoUser4"
                                        value={this.state.surgeryForm.chemoUser4 ? this.state.surgeryForm.chemoUser4 : this.state.surgeryForm.ChemoUser4}
                                        dataSource="Global_Dir_Mv" aliasNo="844" lineStyle="oneLine"
                                        titleDialog="Chemo User 4" labelWidth="115px" width="95px"
                                        inValid={this.state.dAllItemFields["chemoUser4"]} itemNbr="844"
                                        lostfocused={this.handleLostFocus}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["chemoUser4"] : ""}
                                        flag="ERS_UserDefinedMaintenance"
                                    />
                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText={this.state.surgeryForm.chemoUserLblText5 ? this.state.surgeryForm.chemoUserLblText5 : "Chemo User 5"}//labelText="Chemo User 5" 
                                        field="chemoUser5"
                                        value={this.state.surgeryForm.chemoUser5 ? this.state.surgeryForm.chemoUser5 : this.state.surgeryForm.ChemoUser5}
                                        dataSource="Global_Dir_Mv" aliasNo="3699" lineStyle="oneLine"
                                        titleDialog="Chemo User 5" labelWidth="115px" width="95px"
                                        inValid={this.state.dAllItemFields["chemoUser5"]} itemNbr="3699"
                                        lostfocused={this.handleLostFocus}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["chemoUser5"] : ""}
                                        flag="ERS_UserDefinedMaintenance"
                                    />
                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText={this.state.surgeryForm.chemoUserLblText6 ? this.state.surgeryForm.chemoUserLblText6 : "Chemo User 6"}//labelText="Chemo User 6"
                                        field="chemoUser6"
                                        value={this.state.surgeryForm.chemoUser6 ? this.state.surgeryForm.chemoUser6 : this.state.surgeryForm.ChemoUser6}
                                        dataSource="Global_Dir_Mv" aliasNo="3700" lineStyle="oneLine"
                                        titleDialog="Chemo User 6" labelWidth="115px" width="95px"
                                        inValid={this.state.dAllItemFields["chemoUser6"]} itemNbr="3700"
                                        lostfocused={this.handleLostFocus}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["chemoUser6"] : ""}
                                        flag="ERS_UserDefinedMaintenance"
                                    />
                                </div>

                                <div className="" style={{ width: "400px" }}>
                                    <Input type="textarea" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Chemo Text" itemNbr="537"
                                        field="chemoText" lineStyle="oneLine"
                                        value={this.state.surgeryForm.chemoText}
                                        labelWidth="90px" width="280px" height="80px" rows="3"
                                        hasDoubleClickEvent="warning" maxlen="1000" />
                                    <br /><br /><br />
                                    <Input type="textarea" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText={this.state.surgeryForm.chemoUserTextLblText1 ? this.state.surgeryForm.chemoUserTextLblText1 : "Customized Chemotherapy"}//labelText="Customized Chemotherapy"
                                        field="chemoUserText1" lineStyle="oneLine"
                                        value={this.state.surgeryForm.chemoUserText1} itemNbr="3972"
                                        labelWidth="90px" width="280px" height="80px" rows="3"
                                        hasDoubleClickEvent="warning" maxlen="1000" />
                                </div>

                                <div className="" style={{ width: "440px" }}>
                                    <Input type="textarea" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText={this.state.surgeryForm.chemoUserTextLblText2 ? this.state.surgeryForm.chemoUserTextLblText2 : "Chemotherapy User Text 2"}//labelText="Chemotherapy User Text 2"
                                        field="chemoUserText2" lineStyle="oneLine"
                                        value={this.state.surgeryForm.chemoUserText2} itemNbr="3973"
                                        labelWidth="90px" width="280px" height="80px" rows="3"
                                        hasDoubleClickEvent="warning" maxlen="1000" />
                                    <br /><br /><br />
                                    <Input type="textarea" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText={this.state.surgeryForm.chemoUserTextLblText3 ? this.state.surgeryForm.chemoUserTextLblText3 : "Chemotherapy User Text 3"}//labelText="Chemotherapy User Text 3"
                                        field="chemoUserText3" lineStyle="oneLine"
                                        value={this.state.surgeryForm.chemoUserText3} itemNbr="3974"
                                        labelWidth="90px" width="280px" height="80px" rows="3"
                                        hasDoubleClickEvent="warning" maxlen="1000" />
                                </div>
                                <div className="" style={{ width: "215px" }}>
                                    <div className="labelAreaCls">
                                        <div className="col-md-12">
                                            <Input type="label" labelText="Start Dt Sum" field="chemoStartDateSumm"
                                                labelWidth={Global.LabelWidth} width=""
                                                value={this.state.surgeryForm.chemoStartDateSumm !== null && this.state.surgeryForm.chemoStartDateSumm !== "" ? moment(new Date(this.state.surgeryForm.chemoStartDateSumm)).format("MM/DD/YYYY") : ""}
                                            />
                                            <Input type="label" labelWidth={Global.LabelWidth} labelText="Chemo Code"
                                                field="chemoCode" value={this.state.surgeryForm.chemoCode} />
                                            <Input type="label" labelWidth={Global.LabelWidth} labelText="Chemo Here"
                                                field="chemoHere" value={this.state.surgeryForm.chemoHere} />
                                            <Input type="label" labelWidth={Global.LabelWidth} labelText="Days From Dx"
                                                field="chemoDaysFromDx" value={this.state.surgeryForm.chemoDaysFromDx} />
                                            <Input type="label" labelWidth={Global.LabelWidth} labelText="Elapsed Days"
                                                field="elapsedDaysChemo" value={this.state.surgeryForm.elapsedDaysChemo} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <div className="">
                                    <div className="primaryListArea">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "15px" }}>&nbsp;</th>
                                                    <th style={{ width: "131px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="Start Date" field="chemoStartDate"
                                                        />
                                                    </th>
                                                    <th style={{ width: "131px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="End Date" field="chemoEndDate"
                                                        />
                                                    </th>
                                                    <th style={{ width: "60px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="Chemo" field="chemo"
                                                        />
                                                    </th>
                                                    <th style={{ width: "50px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="Intent" field="chemoTxIntent"
                                                        />
                                                    </th>
                                                    <th style={{ width: "120px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="Drug" field="chemoDrug"
                                                        />
                                                    </th>
                                                    <th style={{ width: "100px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="Regimen" field="chemoRegimen"
                                                        />
                                                    </th>
                                                    <th style={{ width: "50px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="Toxic" field="chemoToxicities"
                                                        />
                                                    </th>
                                                    <th style={{ width: "50px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="Resp" field="chemoResponse"
                                                        />
                                                    </th>
                                                    <th style={{ width: "60px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="Comp St" field="chemoCompStatus"
                                                        />
                                                    </th>
                                                    <th style={{ width: "60px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="Rsn Stop" field="chemoRsnStop"
                                                        />
                                                    </th>
                                                    <th style={{ width: "60px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="F/S Flag" field="chemoFsFlag"
                                                        />
                                                    </th>
                                                    <th style={{ width: "105px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="Physician" field="chemoPhysician"
                                                        />
                                                    </th>
                                                    <th style={{ width: "100px" }}>
                                                        <Input type="gridHeader" labelWidth="auto"
                                                            labelText="Hospital" field="chemoRxHosp"
                                                        />
                                                    </th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                <tr>
                                                    <td colSpan="15">&nbsp;</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className="innerBody" style={{ overflow: "auto", /*width: "1145px",*/ height: "125px" }}>
                                            <table>
                                                <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                    <tr>
                                                        <th style={{ width: "15px" }}>&nbsp;</th>
                                                        <th style={{ width: "131px" }}>Start Date</th>
                                                        <th style={{ width: "131px" }}>End Date</th>
                                                        <th style={{ width: "60px" }}>Chemo</th>
                                                        <th style={{ width: "50px" }}>Intent</th>
                                                        <th style={{ width: "120px" }}>Drug</th>
                                                        <th style={{ width: "100px" }}>Regimen</th>
                                                        <th style={{ width: "50px" }}>Toxic</th>
                                                        <th style={{ width: "50px" }}>Resp</th>
                                                        <th style={{ width: "60px" }}>Comp St</th>
                                                        <th style={{ width: "60px" }}>Rsn Stop</th>
                                                        <th style={{ width: "60px" }}>F/S Flag</th>
                                                        <th style={{ width: "105px" }}>Physician</th>
                                                        <th style={{ width: "100px" }}>Hospital</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.primaryItemFields
                                                        && this.state.primaries !== null && this.state.primaries.length > 0
                                                        ? this.state.primaries.map((prm, index) => (
                                                            <tr onClick={(e) => { this.setSelectionRow(e, index) }} className={this.state.selectedRowIndex === index ? "selectedRowCls" : ""}>
                                                                <td>
                                                                    {index + 1}
                                                                </td>
                                                                <td>
                                                                    <Input type="date" focused={this.handleOnFocus}
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="chemoStartDate" lineStyle="oneLine"
                                                                        value={prm.chemoStartDate != null && prm.chemoStartDate !== "" ? moment(new Date(prm.chemoStartDate)).format("MM/DD/YYYY") : ""}
                                                                        rowIndex={index}//keyId={"chemoStartDate_" + index}
                                                                        monthWidth="33px" dayWidth="30px" yearWidth="43px"
                                                                        //#20210125 - START
                                                                        // Add day/month/year values
                                                                        inValidDay={this.state.primaryItemFields[index]["chemoStartDateDay"]}
                                                                        inValidMonth={this.state.primaryItemFields[index]["chemoStartDateMonth"]}
                                                                        inValidYear={this.state.primaryItemFields[index]["chemoStartDateYear"]}
                                                                        dayVal={prm.chemoStartDateDay}
                                                                        monthVal={prm.chemoStartDateMonth}
                                                                        yearVal={prm.chemoStartDateYear}
                                                                        itemNbr="3612"
                                                                        //#20210125 - END
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="date" focused={this.handleOnFocus}
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="chemoEndDate" lineStyle="oneLine"
                                                                        value={prm.chemoEndDate != null && prm.chemoEndDate !== "" ? moment(new Date(prm.chemoEndDate)).format("MM/DD/YYYY") : ""}
                                                                        rowIndex={index}//keyId={"chemoEndDate_" + index}
                                                                        monthWidth="33px" dayWidth="30px" yearWidth="43px"
                                                                        //#20210125 - START
                                                                        // Add day/month/year values
                                                                        inValidDay={this.state.primaryItemFields[index]["chemoEndDateDay"]}
                                                                        inValidMonth={this.state.primaryItemFields[index]["chemoEndDateMonth"]}
                                                                        inValidYear={this.state.primaryItemFields[index]["chemoEndDateYear"]}
                                                                        dayVal={prm.chemoEndDateDay}
                                                                        monthVal={prm.chemoEndDateMonth}
                                                                        yearVal={prm.chemoEndDateYear}
                                                                        itemNbr="3714"
                                                                        //#20210125 - END
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="chemo" value={prm.chemo} keyId={"chemo_" + index}
                                                                        dataSource="Global_Dir_Mv" aliasNo="143" itemNbr="143"
                                                                        titleDialog="Chemo" labelWidth="0px" width="35px" lineStyle="oneLine"
                                                                        inValid={this.state.primaryItemFields[index]["chemo"]}
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        //maxLength="2"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["chemo"] : ""}
                                                                        keyId={"chemo" + index}
                                                                        rowIndex={index}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="chemoTxIntent" value={prm.chemoTxIntent} keyId={"chemoTxIntent" + index}
                                                                        dataSource="Global_Dir_Mv" aliasNo="3690" itemNbr="3690"
                                                                        titleDialog="Chemo Tx Intent" labelWidth="0px" width="30px" lineStyle="oneLine"
                                                                        inValid={this.state.primaryItemFields[index]["chemoTxIntent"]}
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        //maxLength="1"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["chemoTxIntent"] : ""}
                                                                        keyId={"chemoTxIntent" + index}
                                                                        rowIndex={index}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="chemoDrug" value={prm.chemoDrug} keyId={"chemoDrug" + index}
                                                                        dataSource="SEER_Drugs" aliasNo="4512" itemNbr="4512"
                                                                        titleDialog="Chemo Drug" labelWidth="0px" width="100px" lineStyle="oneLine"
                                                                        inValid={this.state.primaryItemFields[index]["chemoDrug"]}
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        //maxLength="400"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["chemoDrug"] : ""}
                                                                        keyId={"chemoDrug" + index}
                                                                        rowIndex={index}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="chemoRegimen" value={prm.chemoRegimen} keyId={"chemoRegimen_" + index}
                                                                        dataSource="SEER_Regimens" aliasNo="3936" itemNbr="3936"
                                                                        titleDialog="Chemo Regimen" labelWidth="0px" width="80px" lineStyle="oneLine"
                                                                        inValid={this.state.primaryItemFields[index]["chemoRegimen"]}
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        //maxLength="400"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["chemoRegimen"] : ""}
                                                                        keyId={"chemoRegimen" + index}
                                                                        rowIndex={index}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="chemoToxicities" value={prm.chemoToxicities} keyId={"chemoToxicities" + index}
                                                                        dataSource="Global_Dir_Mv" aliasNo="3692" itemNbr="3692"
                                                                        titleDialog="Chemo Toxicities" labelWidth="0px" width="30px" lineStyle="oneLine"
                                                                        inValid={this.state.primaryItemFields[index]["chemoToxicities"]}
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        //maxLength="10"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["chemoToxicities"] : ""}
                                                                        keyId={"chemoToxicities" + index}
                                                                        rowIndex={index}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="chemoResponse" value={prm.chemoResponse} keyId={"chemoResponse" + index}
                                                                        dataSource="Global_Dir_Mv" aliasNo="3691" itemNbr="3691"
                                                                        titleDialog="Chemo Response" labelWidth="0px" width="30px" lineStyle="oneLine"
                                                                        inValid={this.state.primaryItemFields[index]["chemoResponse"]}
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        //maxLength="1"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["chemoResponse"] : ""}
                                                                        keyId={"chemoResponse" + index}
                                                                        rowIndex={index}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }} keyId={"chemoCompStatus" + index}
                                                                        labelText="" field="chemoCompStatus" value={prm.chemoCompStatus}
                                                                        dataSource="Global_Dir_Mv" titleDialog="Chemo Comp Status" aliasNo="3616"
                                                                        labelWidth="0px" width="40px" itemNbr="3616"
                                                                        inValid={this.state.primaryItemFields[index]["chemoCompStatus"]}
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        //maxLength="2"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["chemoCompStatus"] : ""}
                                                                        keyId={"chemoCompStatus" + index}
                                                                        rowIndex={index}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="chemoRsnStop" value={prm.chemoRsnStop} keyId={"chemoRsnStop" + index}
                                                                        dataSource="Global_Dir_Mv" titleDialog="Chemo Rsn Stop" aliasNo="3679"
                                                                        labelWidth="0px" width="40px" itemNbr="3679"
                                                                        inValid={this.state.primaryItemFields[index]["chemoRsnStop"]}
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        //maxLength="2"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["chemoRsnStop"] : ""}
                                                                        keyId={"chemoRsnStop" + index}
                                                                        rowIndex={index}
                                                                    />
                                                                </td>
                                                                <td style={{ width: "150px" }}>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }}
                                                                        labelText="" field="chemoFsFlag" value={prm.chemoFsFlag} keyId={"chemoFsFlag" + index}
                                                                        dataSource="Global_Dir_Mv" titleDialog="Chemo Fs Flag" aliasNo="3934"
                                                                        labelWidth="0px" width="40px" itemNbr="3934"
                                                                        inValid={this.state.primaryItemFields[index]["chemoFsFlag"]}
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        //maxLength="1"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["chemoFsFlag"] : ""}
                                                                        keyId={"chemoFsFlag" + index}
                                                                        rowIndex={index}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="Physician" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }} keyId={"chemoPhysician" + index}
                                                                        labelText="" field="chemoPhysician" value={prm.chemoPhysician}
                                                                        dataSource="Physician" titleDialog="Chemo Physician" aliasNo="4513"
                                                                        labelWidth="0px" width="85px" itemNbr="4513"
                                                                        inValid={this.state.primaryItemFields[index]["chemoPhysician"]}
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        //maxLength="15"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["chemoPhysician"] : ""}
                                                                        keyId={"chemoPhysician" + index}
                                                                        rowIndex={index}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input type="Hospital" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                        changed={(e) => { this.handlePrimaryGridChange(e, index) }} keyId={"chemoRxHosp" + index}
                                                                        labelText="" field="chemoRxHosp" value={prm.chemoRxHosp}
                                                                        dataSource="Hospital" titleDialog="Hospital Lookup" aliasNo="320"
                                                                        labelWidth="0px" width="80px" itemNbr="3933"
                                                                        inValid={this.state.primaryItemFields[index]["chemoRxHosp"]}
                                                                        lostfocused={(e) => { this.handlePrimaryLostfocus(e, index) }}
                                                                        //maxLength="15"
                                                                        maxLength={this.state.primariesMaxlengthInput ? this.state.primariesMaxlengthInput["chemoRxHosp"] : ""}
                                                                        keyId={"chemoRxHosp" + index}
                                                                        rowIndex={index}
                                                                    />
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        )) : <tr><td colSpan="15">No records available</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="contactBtnArea right" style={{ width: "1105px" }}>
                                            <button onClick={this.addNewPrimary}>Add</button>
                                            <button onClick={this.deleteSelectedItemPrimary}>Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="emptyLine"></div>
                    </form>

                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetSurgery} formId="surgeryForm"
                        isFormDirty={this.state.isFormDirty}
                        isDisabledOnChildren={true}
                        isChildren={true}
                        focusingField={this.state.focusingField}
                    />

                </div>
            )
            : <p>Chemo data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {confirmDeleteMsg}
                </React.Fragment>
                <React.Fragment>
                    {alertMsg}
                </React.Fragment>
                <React.Fragment>
                    {surgeryMaintenance}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

ChemoMaintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedSurgeryRecord: state.patientAbstract.selectedSurgeryRecord,
        isChildrenLoading: state.patientAbstract.isChildrenLoading,

        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,

        selectedPatient: state.patientAbstract.selectedPatient,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem2,//#2
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,

        isFormDirty: state.patientAbstract.isFormDirty,
        childrenOpening: state.patientAbstract.childrenOpening,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveSurgery: (surgeryData) => dispatch(actionCreators.saveSurgery(surgeryData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem2()),//#2
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChemoMaintenance);