/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import "./ReportStyling.css";
import "./AccessionRegister.css";
import Draggable from 'react-draggable';
import * as dialogTypes from "../../AbstractManagement/maintDialogs";  // Getting navigation tabs - 09/01/2022

class AccessionRegister extends Component {
    sInVal = "";
    downloadFile = false;
    state = {
        uAllItemFields: null,
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        fieldInFocus: "",
        focusingField: null,
        isHelpVisible: false,
        loaded: false,
        selectedSortOrderOption: null,
        selectedOutputTypeOption: null,
        selectYears: "2020",
        hospNbr: "02",
        years: "", //This row works to default the year: 2021
        titleDialog: null,
        showClockTimer: false,
        startTime: null,
        currentTime: null,
        endTime: null,
        chartDatas: null,
        uAllItemFields: [],
        hospitalCode: "02",     //This line does not work as the default for some reason
        hospitalCodeName: "",  //This line works as the default //"ERS Test 2 Hosp"
        reportStatus: "Not Running",

        htmlOutput: null,
        isDrawingCharts: false,
    }

    hospitalCodeOnChange = (event) => {
        
        //this.setState({ hospNbr: event.target.value })
        console.log("###Hosp Nbr: ")
        //On Hospital Code changing, if a single code is chosen, we should get the hospital name, else, we should set the label to blank
    }

    lookupHospName(hospNbr) {
        //This is where we should do a lookup for the Hospital Name
        console.log("### Hosp: "+hospNbr)
    }

    selectionSortOrder = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedSortOrderOption: event.target.value,
            dialogForm: dialogFormData,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: this.state.selectedSortOrderOption != event.target.value ? true : this.state.isFormDirty,
        });
    }

    selectionOutputType = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedOutputTypeOption: event.target.value,
            dialogForm: dialogFormData,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: this.state.selectedOutputTypeOption != event.target.value ? true : this.state.isFormDirty,
        });
    }

    handleYearsMvGridChange = (event) => {
        return
    }

    testGetState = (event) => {
        var dialogFormData = (this.state.dialogForm ? this.state.dialogForm : "Cannot Load this.state.dialogForm")
        var yr = (this.state.years ? this.state.years : "Cannot display this.state.years")
        dialogFormData.years = event.target.value;

        this.setState({
            selectYears: event.target.value,
            dialogForm: dialogFormData,
            isFormDirty: this.state.selectYears != event.target.value ? true : this.state.isFormDirty,
        });

        console.log("~This dialog Form contains values: " + JSON.stringify(this.state.dialogForm, null, 4))
        console.log("This value is: " + yr + "   this.state.selectYears: " + this.state.selectYears + this.state.dialogForm.hospitalCode)
        //alert("This value is: " + JSON.stringify(this.state.dialogForm, null, 4))
        //alert("This value is: " + yr + "   this.state.selectYears: " + this.state.selectYears)
    }


    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isHelpVisible: false });
        return false;
    }

    initClock() {
        //var _this = this;
        setInterval(function () {
            this.setState({ currentTime: new Date().toLocaleTimeString() });
            return false;
        }, 1000);
    }

    componentDidMount() {
        this.Get_Data("");

        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);

        // Add all navigation tabs in maintDialogs.js - 09/01/2022
        dialogTypes.adminReportsTabsDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog);
            this.props.setEnabledStatus(dialog);
        });

        this.setState({ isLoading: true });

        if (!this.state.dialogForm && !this.props.accessionRegisterInfo) {
            this.setFormData(this.props.accessionRegisterInfo);
        }
    }

    componentWillMount() {
        //this.props.setChildrenOpening(false);
        //To do refactor initClock to be enabled only when running a report
        //this.initClock();
    }

    componentDidUpdate(prevProps,prevState) {

        if (this.props.currentSelectedField != null && this.props.commonLookupItem && this.state.dialogForm && this.state.uAllItemFields) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "" },
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    //uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        var hospName = ""
        //if (this.props.commonLookupItem.name) {
        //    hospName = this.props.commonLookupItem.name;
        //}
        console.log("###componentDidUpdate Hospital Name: ", this.props.hospitalCodeName + " -- " + hospName);
  
        if ((!this.state.dialogForm && this.props.accessionRegisterInfo)
            //|| (prevprops.accessionRegisterInfo && this.props.accessionRegisterInfo && (prevprops.accessionRegisterInfo.startTime !== this.props.accessionRegisterInfo.startTime || prevprops.accessionRegisterInfo.endTime !== this.props.accessionRegisterInfo.endTime))
            //|| (this.state.reload && this.props.accessionRegisterInfo) Now removing this line per REA
        ) {
            this.setFormData(this.props.accessionRegisterInfo);
        }

        //Moving all the code below from setFormData to componentDidUpdate per REA 08/19/2022
        //if (accessionRegisterInfo && accessionRegisterInfo.sendReportTo == "Screen" && this.state.reportStatus && this.state.reportStatus != "Not Running")
        if (this.state.dialogForm && (prevProps.accessionRegisterInfo && this.props.accessionRegisterInfo && (prevProps.accessionRegisterInfo.startTime !== this.props.accessionRegisterInfo.startTime
            || prevProps.accessionRegisterInfo.endTime !== this.props.accessionRegisterInfo.endTime
        ))
            && (this.props.accessionRegisterInfo.htmlOutput || this.props.accessionRegisterInfo.urlOutputFile)) {
            clearInterval(this.sInVal);
            var accessionRegisterInfo = this.props.accessionRegisterInfo;
            if (accessionRegisterInfo && accessionRegisterInfo.sendReportTo == "Screen" && accessionRegisterInfo.htmlOutput)
                this.drawingCharts(accessionRegisterInfo);
            else if (accessionRegisterInfo && accessionRegisterInfo.sendReportTo == "Excel" && accessionRegisterInfo.urlOutputFile && !this.downloadFile) {
                this.setState({ showClockTimer: false });
                var fileURL = this.props.clientgroup + "/DownloadFile";
                this.downloadxlsx(fileURL, accessionRegisterInfo.urlOutputFile); //Download the Excel file produced by the report
                this.downloadFile = true;
            }
        }
    }

    downloadxlsx(fileURL, urlOutputFile) {
        axios.post(fileURL, {
            method: 'POST',
            responseType: 'blob',//important
            urlOutputFile: urlOutputFile,
            accessToken: localStorage.getItem("token")
        }).then((response) => {
            var fileName = urlOutputFile.substring(urlOutputFile.lastIndexOf('\\') + 1);
            const link = document.createElement('a');

            let excel;
            if (urlOutputFile.indexOf('/') != -1) {
                excel = urlOutputFile.substring(urlOutputFile.lastIndexOf('/') + 1);
            } else {
                excel = urlOutputFile.substring(urlOutputFile.lastIndexOf('\\') + 1);
            }
            link.href = this.props.clientgroup + "/" + excel;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return false;
        })
        .catch(function (error) {
            console.log(error);
        });

        return false;
    }

    setFormData = (accessionRegisterInfo) => {
        console.log("###setFormData: ", accessionRegisterInfo);
        this.setState({
            initialDialogForm: { ...accessionRegisterInfo },
            dialogForm: { ...accessionRegisterInfo },

            ["selectedSortOrderOption"]: accessionRegisterInfo && accessionRegisterInfo.sortOrder ? accessionRegisterInfo.sortOrder : "Sort by Name",
            ["selectedOutputTypeOption"]: accessionRegisterInfo && accessionRegisterInfo.sendReportTo ? accessionRegisterInfo.sendReportTo : "Screen",
            reload: false,
            changed: true,
            showClockTimer: false,
            titleDialog: null,
            //loaded: true, Removing per REA 08/19/2022
            isDrawingCharts: false, //Adding per REA 08/19/2022
        });
        console.log("Report Status: ", this.state.reportStatus);

        this.setState({ reportStatus: "Not Running", });

        //{ document.getElementById("statusBar").innerHTML = "TestCode" }; //date.toLocaleTimeString();
    }

    handleOnFocus = (event, index, alias) => {
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true,
            });
        } else {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value
                },
                isFormDirty: true,
            });
        }
    }

    handleLostFocus = (event) => {
        var dialogFormData = (this.state.dialogForm ? this.state.dialogForm : "Cannot Load this.state.dialogForm")
        var hosp = (this.state.hospitalCode ? this.state.hospitalCode : "Cannot display this.state.hospitalCode")
        dialogFormData.hospitalCode = event.target.value;

        let name = event.target.name;
        let value = event.target.value;
        let table = event.target.getAttribute('data-table'); // 'Hospital'
        let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
        let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";

        this.props.checkValidInput(name, value, table, key, -1, flag);
        var checkVal = "";
        //var checkVal = this.props.checkValidInput(name, value, table, key, -1, flag);
        var hospName = this.props.hospitalCodeName;

        console.log("###Lookup Values: " + name + " -- " + value + " -- " + table + " -- " + key + " -- " + flag + " -- " + checkVal + " -- " + hospName);

        this.setState({
            hospNbr: event.target.value,
            dialogForm: dialogFormData,
            isFormDirty: this.state.hospNbr != event.target.value ? true : this.state.isFormDirty,
        });

        console.log("~This dialog Form contains values: " + JSON.stringify(this.state.dialogForm, null, 4))


    }

    async Get_Data() {
        var _this = this;
        await _this.props.generateAccessionRegister();  //The report doesn't run without this code, but why?  What does this function actually do?

        //Doesn't really seem to need any of the code below
        this.setState({
            reload: true,
            isFormDirty: false,
        });

        return false;
    }

    Run_Accession_Register = () => {
        var dialogFormData = this.state.dialogForm;

        dialogFormData.years = this.state.years;
        dialogFormData.sortOrder = this.state.selectedSortOrderOption;
        dialogFormData.sendReportTo = this.state.selectedOutputTypeOption;

        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;
        dialogFormData.reportStatus = "Running";

        //
        this.props.runAccessionRegister(dialogFormData);

        //Check why this needs to be here
        this.setState({
            reload: true,  //Added per REA 08/15/2022
            isDrawingCharts: false, //Added per REA 08/22/2022
            reportStatus: "Running",        });

        this.downloadFile = false;//reset this flag to get download new file
        return false;
    }

    //on before show event
    showEvent(e) {
        try {e.preventDefault();} catch (ex) { }

        var _this = this;
        setTimeout(function () {
            console.log("Running Accession Register ...");
            _this.Run_Accession_Register();

            _this.setState({ showClockTimer: true, startTime: new Date().toLocaleTimeString() }); // set clock timer...

            return false;
        }, 0);

        this.sInVal = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(this.sInVal);
            } else {
                console.log("Timer Interval Running");
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
                _this.setState({ currentTime: new Date() ? new Date().toLocaleTimeString() : " " });
            }
        }, 1000);
        
        return false;
    }

    Cancel_Report = (e, _this) => {
        e.preventDefault();

        //_this.props.cancelSBSReport(); //If we want a functional cancel button for this report, we will need to build out that functionality

        setTimeout(function () {
            _this.setState({
                showClockTimer: false,
                reload: false, //Added per REA 08/22/20222
            });
            return false;
        }, 0);
        clearInterval(this.sInVal);
        return false;
    }


    drawingCharts = (data) => {
        this.setState({
            showClockTimer: false,//hide clock timer
            htmlOutput: data.htmlOutput,
            isDrawingCharts: true,
            reload: false, //Added 08/22/2022 per REA
            endTime: new Date().toLocaleTimeString(),
        });
        return false;
    }


    hideDrawingCharts = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }

        var dialogFormData = (this.state.dialogForm ? this.state.dialogForm : "Cannot Load this.state.dialogForm")
        dialogFormData.htmlOutput = null;

        this.setState({
            isDrawingCharts: false,
            reload: false, //Added per REA 08/22/2022
            dialogFormdialogForm: dialogFormData,
        });
        //this.accessionRegisterInfo = null;
        //e.drawingCharts = null;
        //clearInterval(this.sInVal); //Testing only

        return false;
    }


    newRecord(e) {
        try {
            //console.log("something")
            e.preventDefault();
            //console.log("something2")
            this.setState({
                hospNbr: "",//24
                hospitalCode: "",//22
                hospitalCodeName: "",
                years: "", //: "New Rec!" this line of code works
                selectedOutputTypeOption: "Screen",
                selectedSortOrderOption: "Sort by Name"
            })

            var dialogFormData = (this.state.dialogForm ? this.state.dialogForm : "Cannot Load this.state.dialogForm")
            var hosp = (this.state.hospNbr ? this.state.hospNbr : "Cannot display this.state.years")
            dialogFormData.hospitalCode = ""; //="test" this line of code works

            this.setState({
                dialogForm: dialogFormData,
                isFormDirty: false,
            });

            console.log("New Rec: " + dialogFormData.hospitalCode ? dialogFormData.hospitalCode : "no hospital data")
            console.log("New Rec: " + dialogFormData.hospNbr ? dialogFormData.hospNbr : "no hosp data")
            //this.setState({
            //    dialogForm: {
            //        ...this.state.dialogForm,
            //        [hospitalCode]: "",//moment(new Date(thisDate)).format("MM/DD/YYYY")
            //    }
            //});

            //this.dialogForm.setState({
            //    hospitalCode: "",
            //})
        } catch (ex) {}

        //this.Get_Data("");
        //return false;

        document.getElementById("hospitalCodeName") ? document.getElementById("hospitalCodeName").innerHTML = "" : null;
    }

    //exitScreen(e) {
    //    try {
    //        e.preventDefault();
    //    } catch (ex) { }

    //    this.props.setInitialPageVisible(false);//closeCode

    //    return false;
    //}

    printReport = (e) => {
        var printStyle = document.getElementById('PrintStyle');
        var printContent = document.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Accession List</title><style type="text/css">';
        html += printStyle.innerHTML + '</style></head><body><div id="printableContent">';
        html += printContent.innerHTML + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }

    render() {
        

        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTime}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let drawingCharts;
        {
            drawingCharts = this.state.isDrawingCharts && this.state.selectedOutputTypeOption == "Screen" ?  //Added a check to see if it's == "Screen" per REA, and while this will keep the popup report from displaying when the output == "Excel" it doesn't address the real issue which is that the report variables aren't re-setting after the report window is closed
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableLargestWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Accession List</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingCharts(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="container" id="accessionRegisterShowingCharts">
                                {/*Drawing table here*/}
                                <div dangerouslySetInnerHTML={{ __html: this.state.htmlOutput ? this.state.htmlOutput : "" }} className="" />
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12" style={{ display: "flex" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ErsGrBlueButton" title=""
                                            onClick={(e) => { this.printReport(e) }}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print
                                        </button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ErsGrBlueButton" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={(e) => { this.hideDrawingCharts(e) }} value="exit" autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let dialogForm = <div className="reportDialogForm">
            <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                <div className="reportDialogFormContent">
                    <div className="reportScreenTitle">Accession Register / MPI List</div>
                    {this.state.dialogForm || this.state.loaded ?

                    <form onSubmit={false} id="accessionRegisterForm" className="">
                        <div className="frameInner">
                            <div className="col-sm-5" style={{ display: "flex" }}>
                                <Input type="codeMultiSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                    labelText="Hospital Code" field="hospitalCode" lostfocused={this.handleLostFocus}
                                    value={this.state.dialogForm.hospitalCode}
                                    dataSource="Hospital2L" titleDialog="Hospital Lookup" codeLength="2"
                                    labelWidth="100px" width="150px"
                                    inValid={this.state.uAllItemFields["hospitalCode"]}
                                    lineStyle = "oneLine" itemNbr=""
                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospitalCode"] : ""}
                                    flag={this.state.availableHosps ? this.state.availableHosps : ""}
                                    hospNbr={this.state.dialogForm.hospitalCode ? this.state.dialogForm.hospitalCode : this.state.availableHosps}
                                    shownType="dropdown" isMultiSelection={false}
                                />
                                <label type="label" id="hospitalCodeName" name="hospitalCodeName" >{/*this.state.hospitalCodeName*/ this.state.dialogForm.hospitalCodeName}</label>
                            </div>

                            <div className="col-sm-5">
                                <div id="years">
                                    <label>Years:</label>
                                    <input type="text" id="year"
                                        value={ this.state.years}
                                        onChange={(e) => this.setState({ years: e.target.value })}
                                        onBlur={this.testGetState}
                                        labelText="Years"
                                        field="year"
                                    />
                                </div>
                            </div>
                            <br /><br />
                            <div className="row">
                                <div className="col-sm-2">
                                    <div className="sortOrderRadio">
                                        <div id="sortOrderLabel" style={{ verticalAlign: "middle", border: "none" }}>Sort By:</div>
                                        <label style={{ width: "240px", height: "0px", cursor: "pointer" }}>
                                            <input type="radio" onClick={(e) => { this.selectionSortOrder(e) }}
                                                checked={this.state.selectedSortOrderOption === "Sort by Accession Number"}
                                                name="sortOrder" value="Sort by Accession Number" id="sortOrder_byAccNbr"
                                                style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                            Accession Number
                                        </label>
                                        <br />
                                        <label style={{ height: "0px", cursor: "pointer" }}>
                                            <input type="radio" onClick={(e) => { this.selectionSortOrder(e) }}
                                                checked={this.state.selectedSortOrderOption === "Sort by Name"}
                                                name="sortOrder" value="Sort by Name" id="sortOrder_byName"
                                                style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                            Name
                                        </label>
                                    </div>
                                </div>

                                <div className="col-sm-2">
                                    <div className="outputDeviceRadio">
                                        <div id="outputDeviceLabel" style={{ verticalAlign: "middle", border: "none" }}>Send Report To:</div>
                                        <label style={{ width: "240px", height: "0px", cursor: "pointer"  }}>
                                            <input type="radio" onClick={this.selectionOutputType}
                                                checked={this.state.selectedOutputTypeOption === "Excel"}
                                                name="sendReportTo" field="sendReportTo" value="Excel"
                                                style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                            Excel
                                        </label>
                                        <br />
                                        <label style={{ width: "240px", height: "0px", cursor: "pointer"  }}>
                                            <input type="radio" onClick={this.selectionOutputType}
                                                checked={this.state.selectedOutputTypeOption === "Screen"}
                                                name="sendReportTo" field="sendReportTo" value="Screen"
                                                style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                            Screen
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <br />

                            <div id="CONTAINER_JS_CLOCK" className="hidden">
                                <label>Selecting Cases</label>
                                <div id="IMAGE_WAIT" style={{ color: "black", fontWeight: "bold" }}>
                                    Selecting Cases - Please Wait ...
                                </div>
                            </div>
                        </div>

                        <div id="statusBar" className="reportScreenStatusBar">
                            {/*Start Time: {new Date() ? new Date().toLocaleTimeString() : " "} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; End Time:*/}
                            Start Time: {this.state.startTime} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; End Time: {this.state.endTime}

                            {/*<Input type="label" labelWidth="auto" style={{ fontWeight: "bold", marginLeft: "15px", paddingTop: "2px" }}*/}
                            {/*    labelText="Time" field="startTime" width="auto"*/}
                            {/*    value={new Date() ? new Date().toLocaleTimeString() : "test 0"}*/}
                            {/*/>*/}
                        </div>

                        <fieldset className="reportDialogFormButtonBar">
                            <button id="BUTTON_RUN"
                                className="ErsGrBlueButton"
                                onClick={(e) => { this.showEvent(e) }}
                            >
                                <img id="BUTTON_RUN_ICON"
                                    src="https://crstar.ers-can.com/images/$$code.action.run.png.a5image"
                                    style={{ verticalAlign: "middle", border: "none" }}></img>
                                Run
                            </button>

                            <button id="BUTTON_NEWRECORD"
                                className={this.state.isFormDirty ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                //onClick={(e) => { this.newRecord(e) }}
                                onClick={this.newRecord.bind(this)}
                                disabled={!this.state.isFormDirty}
                            >
                                <img id="BUTTON_NEWRECORD_ICON"
                                    src={this.state.isFormDirty ? "https://crstar.ers-can.com/images/$$navigate.new.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$navigate.new.png.a5image"}
                                    style={{ verticalAlign: "middle", border: "none" }}></img>
                                New
                            </button>
                        </fieldset>
                    </form>
                    : <p>Accession Register data is loading...</p>}
                </div>
            </div>
        </div>
 
        return (
            <React.Fragment>
                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {drawingCharts}
                </React.Fragment>

                <React.Fragment>
                    {dialogForm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

AccessionRegister.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        accessionRegisterInfo: state.patientAbstract.accessionRegisterInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        isAddNewUser: state.patientAbstract.isAddNewUser,
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        waitingFor: state.patientAbstract.waitingFor,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearAddNewUserFlag: () => dispatch(actionCreators.clearAddNewUserFlag()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        generateAccessionRegister: (flag) => dispatch(actionCreators.generateAccessionRegister(flag)),
        runAccessionRegister: (dialogForm) => dispatch(actionCreators.runAccessionRegister(dialogForm)),
        //storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),

        // Tabs view 09/01/2022
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessionRegister);