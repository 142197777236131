/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import Footer from "../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
import Draggable from 'react-draggable';
import './ZipCodes.css';

class ZipCodeMaint extends Component {

    state = {
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,

        pAllItemFields: null,
        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        showMessageError: false,
        hasError: false,
        savedForm: false,
        fieldInFocus: null,
        focusingField: null,
        isSaving: false,
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });

        var zipCode = this.state.dialogForm.zipCodesIdDsp;
        if (event.target.name === "county") {
            if (event.target.value === "" && zipCode === "") {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, ["zipCode"]: "IN_VALID" },
                    fieldInFocus: "zipCode"
                });
            }
        }

        return false;
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showMessageError: false, hasError: false });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector(".Footer2>#btnSubmitForm_Fake") ? document.querySelector(".Footer2>#btnSubmitForm_Fake").focus() : "";
        }, 0);

        return false;
    }

    componentDidMount() {
        if (!this.state.dialogForm && this.props.selectedZipCodes) {
            this.setFormData();
        }

        var childrenOpening = this.props.childrenOpening;
        this.props.setChildrenOpening(childrenOpening);
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.dialogForm && this.props.selectedZipCodes)
            || (prevProps.selectedZipCodes && this.props.selectedZipCodes && prevProps.selectedZipCodes.zipCode !== this.props.selectedZipCodes.zipCode)
            || (this.props.selectedZipCodes && this.props.selectedZipCodes.zipCode !== null && this.props.selectedZipCodes.zipCode !== "" && this.props.selectedZipCodes.zipCode !== this.state.dialogForm.zipCode && (this.state.dialogForm.zipCode === null || this.state.dialogForm.zipCode === ""))
            || (this.props.selectedZipCodes && this.props.selectedZipCodes.id == "0" && this.props.selectedZipCodes.id !== this.state.dialogForm.id)
            || (!prevProps.selectedZipCodes && this.props.selectedZipCodes && this.props.selectedZipCodes.zipCode !== null && this.props.selectedZipCodes.zipCode !== "" && this.props.selectedZipCodes.zipCode !== this.state.dialogForm.zipCode)
        ) {
            if (!this.state.skipLoadFromProps) {
                this.setFormData();
            }
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null && !this.state.loadedTableStructure) {
            this.setState({ loadedTableStructure: true });
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        if (this.state.savedForm && !this.props.isDialogLoading && ((prevProps.isFormDirty != this.props.isFormDirty || this.props.error) || (prevProps.error != this.props.error || !this.state.isFormDirty))) {
            if (this.props.isFormDirty) {
                if (!this.state.hasError && this.state.hasError != this.props.error) {
                    this.setState({ showMessageError: true, hasError: true, savedForm: false, isFormDirty: true });
                    this.props.setChildrenOpening(true);
                }
            } else {
                if (this.state.isFormDirty && this.state.isFormDirty != this.props.isFormDirty) {
                    this.setState({ isFormDirty: this.props.isFormDirty, savedForm: false, hasError: false });
                }
            }
        }

        if (this.state.isFormDirty && (this.state.isSaving || this.props.deletedDataSuccess)
            && (prevProps.isFormDirty !== this.props.isFormDirty || this.state.isFormDirty !== this.props.isFormDirty)) {
            this.setState({
                isFormDirty: this.props.isFormDirty,
                isSaving: false,
            });
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "Zip_Codes", accessToken: localStorage.getItem('token') };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    setFormData = () => {
        var dataForm = this.props.selectedZipCodes;

        this.setState({
            initialDialogForm: { ...dataForm },
            dialogForm: {
                ...dataForm,
                ["county"]: dataForm.county,
                ["countyName"]: dataForm.countyName,
            },
            //isFormDirty: false,
            reload: false,
            hasError: this.props.error,
            savedForm: false,
            hasError: false,
        });

        if (!this.state.pAllItemFields && this.props.selectedZipCodes) {
            let fields = {};
            Object.keys(this.props.selectedZipCodes).map((t) => {
                fields[t] = "";
            });

            this.setState({ pAllItemFields: fields, pageMaxlengthInput: fields });
        };

        /*
        if (this.state.dialogForm && this.state.dialogForm.zipCode != this.props.selectedZipCodes.zipCode) {
            let zip = this.props.selectedZipCodes.zipCode;
            var _this = this;
            var url = "https://production.shippingapis.com/ShippingAPITest.dll?API=CityStateLookup&XML=<CityStateLookupRequest USERID='968ELECT7482'><ZipCode ID= '1'><Zip5>" + zip + "</Zip5></ZipCode></CityStateLookupRequest>";
            axios.get(url)
                .then(response => {
                    const raw = response.data;
                    const parser = new DOMParser();
                    const xml = parser.parseFromString(raw, 'text/xml');//

                    var _city = xml.querySelector('City') ? xml.querySelector('City').textContent : "";
                    var _state = xml.querySelector('State') ? xml.querySelector('State').textContent : "";
                    if (zip === "88888") {
                        _city = "";
                        _state = "";
                    } else {
                        _city = _city.toUpperCase();
                        _state = _state.toUpperCase();
                    }

                    //
                    _this.setState({
                        dialogForm: {
                            ...dataForm,
                            ["city"]: _city,
                            ["state"]: _state,
                        }
                    });
                });
        } else {
            this.setState({
                initialDialogForm: { ...dataForm },
                dialogForm: {
                    ...dataForm
                },
                //isFormDirty: false,
                reload: false,
                hasError: this.props.error,
                savedForm: false,
                hasError: false,
            });
        }
        /*
        setTimeout(function () {
            document.getElementById("zipCode") ? document.getElementById("zipCode").focus() : "";
        }, 500);
        */
    }

    saveZipCodes = (event) => {
        event.preventDefault();

        let dialogFormData = this.state.dialogForm;
        this.props.saveZipCodes(dialogFormData);

        this.setState({ isSaving: true, savedForm: true, isFormDirty: false });
        return false;
    }

    resetZipCodes = () => {
        let fields = {};
        if (this.props.selectedZipCodes) {
            Object.keys(this.props.selectedZipCodes).map((t) => {
                fields[t] = "";
            });
        }        

        this.setState({
            dialogForm: {
                "id": 0,
                "zipCodesId": 0,
                "zipCode": "",
                "city": "",
                "state": "",
                "county": "",
                "countyName": ""
            },
            pAllItemFields: fields,
            isFormDirty: false,
            reload: false
        });

        setTimeout(function () {
            document.getElementById("zipCode") ? document.getElementById("zipCode").focus() : "";
        }, 500);
    }

    handleOnBlur = (event) => {
        // load if whether this value is existing
        if (event.target.name === "zipCode") {
            if (event.target.value === "") {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (this.state.dialogForm && event.target.value !== this.state.dialogForm.zipCode) {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "" },
                    skipLoadFromProps: false,
                });

                //
                var zip = event.target.value;
                //this.props.checkExistedItem(zip);
                
                var _this = this;
                var url = "https://production.shippingapis.com/ShippingAPITest.dll?API=CityStateLookup&XML=<CityStateLookupRequest USERID='968ELECT7482'><ZipCode ID= '1'><Zip5>" + zip + "</Zip5></ZipCode></CityStateLookupRequest>";
                axios.get(url)
                    .then(response => {
                        const raw = response.data;
                        const parser = new DOMParser();
                        const xml = parser.parseFromString(raw, 'text/xml');//

                        var _city = xml.querySelector('City') ? xml.querySelector('City').textContent : "";
                        var _state = xml.querySelector('State') ? xml.querySelector('State').textContent : "";
                        if (zip === "88888") {
                            _city = "";
                            _state = "";
                        } else {
                            _city = _city.toUpperCase();
                            _state = _state.toUpperCase();
                        }

                        //
                        _this.setState({
                            dialogForm: {
                                ..._this.state.dialogForm,
                                ["city"]: _city,
                                ["state"]: _state,
                            }
                        });

                        _this.props.checkExistedItem(zip);
                    });
            }
        } else {
            let countyName = this.state.dialogForm && this.state.dialogForm.countyName ? this.state.dialogForm.countyName : "";
            if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "" && countyName === "") {
                let name = event.target.name;
                let value = event.target.value;
                let table = event.target.getAttribute('data-table');
                let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
                let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
                this.props.checkValidInput(name, value, table, key, -1, flag);
            } else {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "" } });
            }
        }
    }

    handleFormChange = (event) => {
        this.props.resetDeletedDataSuccess(false);

        let name2 = "";
        let value2 = "";
        if (event.target2) {
            name2 = event.target2.name ? event.target2.name : "";
            value2 = event.target2.value ? event.target2.value : "";
        }

        //
        if (event.target.name === "zipCode"
            && this.state.dialogForm && event.target.value
            && event.target.value !== this.state.dialogForm.zipCode) {
            var zip = event.target.value;
            var _this = this;
            var url = "https://production.shippingapis.com/ShippingAPITest.dll?API=CityStateLookup&XML=<CityStateLookupRequest USERID='968ELECT7482'><ZipCode ID= '1'><Zip5>" + zip + "</Zip5></ZipCode></CityStateLookupRequest>";
            axios.get(url)
                .then(response => {
                    const raw = response.data;
                    const parser = new DOMParser();
                    const xml = parser.parseFromString(raw, 'text/xml');//

                    var _city = xml.querySelector('City') ? xml.querySelector('City').textContent : "";
                    var _state = xml.querySelector('State') ? xml.querySelector('State').textContent : "";
                    if (zip === "88888") {
                        _city = "";
                        _state = "";
                    } else {
                        _city = _city.toUpperCase();
                        _state = _state.toUpperCase();
                    }

                    //
                    if (_city || _state) {
                        _this.setState({
                            dialogForm: {
                                ..._this.state.dialogForm,
                                ["city"]: _city,
                                ["state"]: _state,
                            }
                        });
                        _this.props.checkExistedItem(zip);
                    }                    
                });
        }

        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                [event.target.name]: event.target.value,
                [name2]: value2
            },
            isFormDirty: true,
            isSaving: false,
            skipLoadFromProps: event.target.name == "zipCode" && event.target.value == "" ? true : false,
        });
    }

    render() {
        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                            <label className="invalidMsgErrorCls">Can't save data. {this.props.errorMessage ? "(" + this.props.errorMessage + ")" : ""}</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const dialogFrm = this.state.dialogForm ?
            (
                <div className="container-fluid2">
                    <form onSubmit={this.saveZipCodes} id="dialogForm" className="DialogForm2 zipcodes_maint_form">
                        <div className="_container autoFilterById">
                            <Input type="input" changed={this.handleFormChange}
                                labelText="Zip Code" labelWidth="65px" width="85px" autoFocus
                                field="zipCode" value={this.state.dialogForm.zipCode} lineStyle="oneLine"
                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["zipCode"] : ""}
                                inValid={this.state.pAllItemFields["zipCode"]}
                            />
                        </div>
                        <div id="inputAreaCls">
                            <div className="zipCodesMaintForm">
                                <div className="frameInner">
                                    <div className="header">&nbsp;</div>
                                    <div className="emptyLine"></div>

                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-6 center">
                                                <Input type="input"
                                                    labelText="City" field="city"
                                                    value={this.state.dialogForm.city} lineStyle="oneLine"
                                                    labelWidth="40px" width="180px" readOnly disabled
                                                />
                                            </div>
                                            <div className="col-sm-5 center">
                                                <Input type="input" changed={this.handleFormChange}
                                                    labelText="State" field="state"
                                                    value={this.state.dialogForm.state} lineStyle="oneLine"
                                                    labelWidth="40px" width="90px" readOnly disabled
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <label>County:</label>
                                            </div>
                                            <div className="col-sm-8">
                                                <label>County Name:</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <Input type="codeSelect" changed={this.handleFormChange}
                                                    labelText="" field="county" lineStyle="oneLine"
                                                    value={this.state.dialogForm.county} labelWidth="0px" width="80px"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["county"] : ""}
                                                    focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                    dataSource="County_Codes" titleDialog=""
                                                    inValid={this.state.pAllItemFields["county"]}
                                                    flag={this.state.dialogForm.state}
                                                    shownType="dropdown" rowIndex="0" keyId="county0"
                                                    focusingField={this.state.focusingField}
                                                />
                                            </div>
                                            <div className="col-sm-8">
                                                <Input type="input"
                                                    labelText="" field="countyName" lineStyle="oneLine" readOnly disabled
                                                    value={this.state.dialogForm.countyName} labelWidth="0px" width="108px"
                                                />
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="emptyLine"></div>

                    <Footer closeWindow={this.props.closeWindow}
                        refreshForm={this.resetZipCodes} formId="dialogForm"
                        isFormDirty={this.state.isFormDirty}
                        showBtnNew="ZipCodes"
                        showBtnDelete="ZipCodes"
                        className="Footer2"
                        isLookup={true}
                        exitFnc={this.props.closeWindow}
                        focusingField={this.state.focusingField}
                        inquiringMode={this.props.inquiringMode}
                        enabledAlways={true}
                        dataForm={this.state.dialogForm}
                    />
                </div>
            )
            : <div className="col-sm-12 makingLayoutOnFormCls">Zip Code data loading</div>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>
                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

ZipCodeMaint.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        selectedZipCodes: state.patientAbstract.selectedZipCodesOnModal,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        clientgroup: state.auth.clientgroup,
        deletedDataSuccess: state.patientAbstract.deletedDataSuccess,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveZipCodes: (dialogForm) => dispatch(actionCreators.saveZipCodes(dialogForm)),
        checkExistedItem: (id, flag) => dispatch(actionCreators.selectZipCodesRecord(id, flag)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        resetDeletedDataSuccess: (value) => dispatch(actionCreators.resetDeletedDataSuccess(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ZipCodeMaint);