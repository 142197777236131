/* eslint-disable */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const IdleTimeOutModal = ({ showModal, handleClose, handleLogout, remainingTime }) => {

    return (
        <Modal show={showModal} /*onHide={handleClose}*/ >
            <Modal.Header closeButton onClick={handleLogout}>
                <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>Session is not active. Please login.</Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleLogout}>OK</Button>
                <Button variant="primary" onClick={handleClose} style={{display: "none"}}>Stay</Button>
            </Modal.Footer>
        </Modal>
    )
}