/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import Footer from "../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
import * as Global from "../../../store/Global";
import Draggable from 'react-draggable';
import "./AccNbrsMaint.css";

class AccNbrsMaint extends Component {

    state = {
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,

        pAllItemFields: null,
        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        showMessageError: false,
        hasError: false,
        savedForm: false,
        fieldInFocus: null,
        focusingField: null,
        isSaving: false,
        loaded: false,
    }
    initialPage = true;

    handleOnFocus = (event) => {
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showMessageError: false, hasError: false });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector(".Footer2>#btnSubmitForm_Fake") ? document.querySelector(".Footer2>#btnSubmitForm_Fake").focus() : "";
        }, 0);

        return false;
    }

    componentDidMount() {
        if (!this.state.dialogForm && this.props.accNbrsMaintInfo) {
            this.setFormData();
        } else {
            //this.setState({ dialogForm: null });
        }

        var childrenOpening = this.props.childrenOpening;
        this.props.setChildrenOpening(childrenOpening);
        this.props.loadAccNbrsMaintInit();

        /*
        if (this.initialPage) {
            this.setState({ dialogForm: null });
            this.initialPage = false;
        }
        */
    }

    _componentWillUnmount() {
        this.setState({ dialogForm: null });
        this.props.loadAccNbrsMaintInit();
        this.initialPage = true;
    }

    componentDidUpdate(prevProps) {
        if (!this.props.accNbrsMaintInfo) {
            this.initialPage = true;
            return false;
        }

        if ((!this.state.dialogForm && this.props.accNbrsMaintInfo)
            //|| (prevProps.accNbrsMaintInfo && this.props.accNbrsMaintInfo && prevProps.accNbrsMaintInfo.hosp !== this.props.accNbrsMaintInfo.hosp)
            //|| (this.props.accNbrsMaintInfo && this.props.accNbrsMaintInfo.hosp !== null && this.props.accNbrsMaintInfo.hosp !== "" && this.props.accNbrsMaintInfo.hosp !== this.state.dialogForm.hosp)
            || this.initialPage
        ) {
            this.setFormData();
            this.initialPage = false;
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null && !this.state.loadedTableStructure) {
            this.setState({ loadedTableStructure: true });
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        if (this.state.savedForm && !this.props.isDialogLoading && ((prevProps.isFormDirty != this.props.isFormDirty || this.props.error) || (prevProps.error != this.props.error || !this.state.isFormDirty))) {
            if (this.props.isFormDirty) {
                if (!this.state.hasError && this.state.hasError != this.props.error) {
                    this.setState({ showMessageError: true, hasError: true, savedForm: false, isFormDirty: true });
                    this.props.setChildrenOpening(true);
                }
            } else {
                if (this.state.isFormDirty && this.state.isFormDirty != this.props.isFormDirty) {
                    this.setState({ isFormDirty: this.props.isFormDirty, savedForm: false, hasError: false });
                }
            }
        }

        if (this.state.isFormDirty && (this.state.isSaving || this.props.deletedDataSuccess)
            && (prevProps.isFormDirty !== this.props.isFormDirty || this.state.isFormDirty !== this.props.isFormDirty)) {
            this.setState({
                isFormDirty: this.props.isFormDirty,
                isSaving: false,//this.props.isFormDirty,
            });
        }
    }

    async getTableStructure() {
        //let url = this.props.clientgroup + "/GetTableStructure?table=Acc_Nbrs";
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            //.get(url)
            .post(url, { table: "Acc_Nbrs", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    setFormData = () => {
        let dataForm = this.props.accNbrsMaintInfo;

        this.setState({
            initialDialogForm: { ...dataForm },
            dialogForm: { ...dataForm },
            isFormDirty: false,
            reload: false,
            hasError: this.props.error,
            savedForm: false,
            hasError: false,
            loaded: true,
        });

        if (!this.state.pAllItemFields && this.props.accNbrsMaintInfo) {
            let fields = {};
            Object.keys(this.props.accNbrsMaintInfo).map((t) => {
                fields[t] = "";
            });

            this.setState({ pAllItemFields: fields, pageMaxlengthInput: fields });
        };

        this.initialPage = false;

        //
        setTimeout(function () {
            document.getElementById("hosp0") ? document.getElementById("hosp0").focus() : "";
        }, 500);
    }

    saveAccNbrsMaint = (event) => {
        event.preventDefault();

        let dialogFormData = this.state.dialogForm;
        this.props.saveAccNbrsMaint(dialogFormData);

        this.setState({ isSaving: true, savedForm: true, isFormDirty: false });
        return false;
    }

    _handleOnBlur = (event) => {
        // load if whether this value is existing
        if (event.target.name === "hosp") {
            if (event.target.value === "") {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "IN_VALID" } });
            } else {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "" } });
            }

            if (this.state.dialogForm && this.state.dialogForm.initials) {
                this.props.addNewItem(event.target.value, this.props.isNotExist ? true : false);
            }

            return false;
        } else {
            if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
                let name = event.target.name;
                let value = event.target.value;
                let table = event.target.getAttribute('data-table');
                let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
                let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
                this.props.checkValidInput(name, value, table, key, -1, flag);
            } else {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "" } });
            }
        }
    }

    handleFormChange = (event) => {
        this.props.resetDeletedDataSuccess(false);
        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                [event.target.name]: event.target.value
            },
            isFormDirty: true,
            isSaving: false,
        });
    }

    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";

            this.props.checkValidInput(name, value, table, key, -1, flag);
        } else {
            this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "" } });
        }

        var _this = this;
        if (event.target.name === "hosp") {
            let val = event.target.value;
            //axios.get(this.props.clientgroup + "/LinkToHospitalAndGetCode?hospNbr=" + val)
            axios.post(this.props.clientgroup + "/LinkToHospitalAndGetCode",
                { hospNbr: val, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    var hospName = null;
                    //var accYear = null;
                    //var id = null;
                    if (res.data.error && res.data.message !== null && res.data.message !== "") {
                    } else {
                        hospName = res.data.accNbrs.hospName;
                        //accYear = res.data.accNbrs.accYear;
                        //id = res.data.accNbrs.id;
                    }

                    _this.setState({
                        dialogForm: {
                            ..._this.state.dialogForm,
                            ["hospName"]: hospName,
                            //["accYear"]: accYear,
                            //["id"]: id,
                        }
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;

                });

        } else if (event.target.name === "accYear") {
            //
            let hospNbr = this.state.dialogForm.hosp;
            let accNbr = event.target.value;
            //axios.get(this.props.clientgroup + "/GetNextAccNbrs?hospNbr=" + hospNbr + "&accNbr=" + accNbr)
            axios.post(this.props.clientgroup + "/GetNextAccNbrs",
                { hospNbr: hospNbr, accNbr: accNbr, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    var nextAccNbr = null;
                    var id = null;
                    if (res.data.error && res.data.message !== null && res.data.message !== "") {
                    } else {
                        nextAccNbr = res.data.accNbrs.nextAccNbr;
                        id = res.data.accNbrs.id;
                    }

                    _this.setState({
                        dialogForm: {
                            ..._this.state.dialogForm,
                            ["nextAccNbr"]: nextAccNbr,
                            ["id"]: id,
                        }
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;

                });
        } else if (event.target.name === "nextAccNbr") {
            if (event.target.value == "" || event.target.value.length != 11) {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "IN_VALID" } });
            }
        }
    }

    refreshFormFnc = () => {
        let fields = {};
        if (!this.state.pAllItemFields && this.props.accNbrsMaintInfo) {
            Object.keys(this.props.accNbrsMaintInfo).map((t) => {
                fields[t] = "";
            });
        };

        this.setState({
            dialogForm: { ...this.state.initialDialogForm },
            pAllItemFields: fields,
            pageMaxlengthInput: fields,
            isFormDirty: false,
            reload: false,
            loaded: true
        });
    }

    render() {
        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">Can't save data.</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let loading = (
            !this.state.loaded || this.props.waitingFor ? <div className="col-sm-12 showLoadingOnTopPageCls">Working...</div> : ""
        );

        const dialogFrm = <div className="limitedInGridCls">
            <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                {loading}
                <div className="parentWindow">
                    <div className="patientAbstractGridView" id="accNbrsMainPage" style={{ height: "580px" }}>
                        {
                            this.state.dialogForm ?
                                <div className="container-fluid2">
                                    <form onSubmit={this.saveAccNbrsMaint} id="dialogForm" className="DialogForm2 accNbrs_maint_form">
                                        <div id="inputAreaCls">
                                            <div>
                                                <div className="frameInner">
                                                    <div className="row">
                                                        <div className="col-sm-12" style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Hosp Code" field="hosp" lostfocused={this.handleLostFocus}
                                                                value={this.state.dialogForm.hosp} lineStyle="oneLine"
                                                                dataSource="Acc_Nbrs_Code" titleDialog="" labelWidth={Global.LabelWidth} width="50px"
                                                                inValid={this.state.pAllItemFields["hosp"]} autoFocus
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hosp"] : ""}
                                                                shownType="dropdown" displayingCodeOnly={true} rowIndex="0" keyId="hosp0"
                                                                focusingField={this.state.focusingField}
                                                                //Linking Fields: Hosp_Code<Hosp_Nbr | Hosp_Name<Hosp_Name | Hospital Table
                                                                limitByWidth={true}
                                                            />
                                                            <Input type="label" labelText="" field="hospName" labelWidth="2px"
                                                                style={{ color: "#05004c", fontWeight: "bold" }} width="2in"
                                                                value={this.state.dialogForm.hospName}//Hospital->Hosp_Name
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Acc Year" field="accYear" lineStyle="oneLine"
                                                                value={this.state.dialogForm.accYear}
                                                                dataSource="Acc_Nbrs_Year" titleDialog="" labelWidth={Global.LabelWidth} width="65px"
                                                                inValid={this.state.pAllItemFields["accYear"]}
                                                                lostfocused={this.handleLostFocus}
                                                                disabled={!this.state.dialogForm.hosp || this.state.dialogForm.hosp == null || this.state.dialogForm.hosp == ""}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["accYear"] : ""}
                                                                flag={this.state.dialogForm.hosp}
                                                                shownType="dropdown" displayingCodeOnly={true} rowIndex="0" keyId="accYear0"
                                                                focusingField={this.state.focusingField}
                                                                //Linking Fields: Hosp_Code<Hosp,Acc_Year<Acc_Year
                                                                limitByWidth={true}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Next Acc Nbr" field="nextAccNbr" lineStyle="oneLine"
                                                                value={this.state.dialogForm.nextAccNbr} labelWidth={Global.LabelWidth} width="1in"
                                                                inValid={this.state.pAllItemFields["nextAccNbr"]}
                                                                lostfocused={this.handleLostFocus}
                                                                disabled={!(this.state.dialogForm.hosp && this.state.dialogForm.hosp != "" && this.state.dialogForm.accYear && this.state.dialogForm.accYear != "")}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nextAccNbr"] : ""}
                                                                //Linking Fields: Hosp_Code<Hosp,Acc_Year<Acc_Year,Next_Acc_Nbr<Next_Acc_Nbr
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="emptyLine"></div>

                                    <Footer closeWindow={this.props.closeWindow}
                                        refreshForm={this.refreshFormFnc} formId="dialogForm"
                                        isFormDirty={this.state.isFormDirty}
                                        showBtnNew="AccNbrs"
                                        showBtnDelete="AccNbrs"//confirm('Are you sure you want to delete the Acc Nbr that has these attributes:\nHosp: ' + hospVar +'\nAcc Year: ' + accYear + '\nNext Acc Nbr: ' + nextAcc );
                                        className="Footer2"
                                        initialPageVisible={true}
                                        exitFnc={this.props.closeWindow}
                                        focusingField={this.state.focusingField}
                                        inquiringMode={this.props.inquiringMode}
                                        accNbrsData={this.state.dialogForm}
                                        enabledAlways={true}
                                    />
                                </div>
                                : <p>Initialize Accession Numbers data loading...</p>
                        }
                    </div>
                </div>
            </div>
        </div>;

        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>
                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

AccNbrsMaint.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        accNbrsMaintInfo: state.patientAbstract.accNbrsMaintInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        deletedDataSuccess: state.patientAbstract.deletedDataSuccess,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        waitingFor: state.patientAbstract.waitingFor,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveAccNbrsMaint: (dialogForm) => dispatch(actionCreators.saveAccNbrsMaint(dialogForm)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        resetDeletedDataSuccess: (value) => dispatch(actionCreators.resetDeletedDataSuccess(value)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        setHandlerControlItem: (item) => dispatch(actionCreators.setHandlerControlItem(item)),
        showPageLoading: (flag) => dispatch(actionCreators.showPageLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
        loadAccNbrsMaintInit: () => dispatch(actionCreators.loadAccNbrsMaintInit()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccNbrsMaint);