/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import Draggable from 'react-draggable';
import PropTypes from "prop-types";
import { ListBox, processListBoxData, ListBoxToolbar } from "@progress/kendo-react-listbox";
import { actionCreators } from "../../../../store/PatientAbstract";
import ConfigData from "../../../../store/config.json";
//import * as dialogTypes from "../../../../components/AbstractManagement/maintDialogs";
//import * as actions from '../../../../store/actions/index';
import Footer from "../../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
import axios from "axios";
import "../../SystemAdmin.css";
//import "./UserSecurity.css";

class UserSecurity extends Component {
    state = {
        allItemFields: null,
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        loaded: false,
        loading: false,
        reload: false,
        //isSaving: false,
        savedForm: false,
        
        fieldInFocus: "HospModAllow",
        //fieldInFocus: null,
        //isHelpVisable: false,
        //changed: false,

        hospModAllow: [],
        hospModDeny: [],
        hospRptAllow: [],
        hospRptDeny: [],
        menuItemsAllow: [],
        menuItemsDeny: [],

        allMenuItems: [],
        allPosHosp: [],
        userClientGroup: null,
        
        allHospLoaded: false,
        allowDenyHospComplete: false,
        allowDenyMenuComplete: false,
        menuItemsLoaded: false,

        tools: ["transferTo",
            "transferAllTo",
            "transferAllFrom",
            "transferFrom",],

        lastSelectedIndex: null,

        showMessageError: false,
        hasError: false,
    };
    SELECTED_FIELD = "selected";

    componentDidMount() {
        if (this.state.userClientGroup == null && this.props.userId) {
            this.getUserClientGroup();
        }
        
        this.setState({ loading: true })
    }
    
    componentDidUpdate(prevProps) {
        if (this.state.userClientGroup == null && this.props.userId) {
            this.getUserClientGroup();
        }

        if (!this.state.allHospLoaded && this.props.userId) {
            this.getAllPosHosp();
        }

        if (!this.state.menuItemsLoaded) {
            this.getAllMenuItemsList();
        }

        if ((!this.state.dialogForm && this.props.selectedUserSecurityInfo)
            /**/
            || (prevProps.selectedUserSecurityInfo && this.props.selectedUserSecurityInfo && prevProps.selectedUserSecurityInfo.email !== this.props.selectedUserSecurityInfo.email)
            || (this.props.selectedUserSecurityInfo && this.props.selectedUserSecurityInfo.email !== this.state.dialogForm.email)
            || (!prevProps.selectedUserSecurityInfo && this.props.selectedUserSecurityInfo && this.props.selectedUserSecurityInfo.email === this.state.dialogForm.email)
        ) {
            this.setFormData(true);
        }

        if (this.state.allHospLoaded && !this.state.allowDenyHospComplete) {

            let hospModEmpty = this.props.selectedUserSecurityInfo.availableHospsData === "||" ? true : false;
            let hospModAllowStg = this.props.selectedUserSecurityInfo.availableHospsData != null ? this.props.selectedUserSecurityInfo.availableHospsData : "";
            hospModAllowStg = hospModAllowStg.slice(1,(hospModAllowStg.length-1));
            let hospModAllowArr = hospModAllowStg.length === 0 ? [] : hospModAllowStg.split("|");
            let modAllowFiltered = ( hospModAllowArr.length === 0 && !hospModEmpty )
                    ? this.state.allPosHosp : hospModAllowArr.filter(x => this.state.allPosHosp.includes(x));

            let modDenyFiltered = modAllowFiltered.filter(x => !this.state.allPosHosp.includes(x))
                                  .concat(this.state.allPosHosp.filter(x => !modAllowFiltered.includes(x)));
            //let modExclude = hospModAllowArr.filter(x => !this.state.allPosHosp.includes(x));
            //console.log(modExclide);
            
            let hospRptEmpty = this.props.selectedUserSecurityInfo.availableHospsRpt === "||" ? true : false
            let hospRptAllowStg = this.props.selectedUserSecurityInfo.availableHospsRpt != null ? this.props.selectedUserSecurityInfo.availableHospsRpt : "";
            hospRptAllowStg = hospRptAllowStg.slice(1,(hospRptAllowStg.length-1));
            let hospRptAllowArr = hospRptAllowStg.length === 0 ? [] : hospRptAllowStg.split("|");
            let rptAllowFiltered = ( hospRptAllowArr.length === 0 && !hospRptEmpty )
                    ? this.state.allPosHosp : hospRptAllowArr.filter(x => this.state.allPosHosp.includes(x));

            let rptDenyFiltered = rptAllowFiltered.filter(x => !this.state.allPosHosp.includes(x))
                                  .concat(this.state.allPosHosp.filter(x => !rptAllowFiltered.includes(x)));
            //let rptExclude = hospRptAllowArr.filter(x => !this.state.allPosHosp.includes(x));
            //console.log(rptExclude);
            
            let modAllow = [];
            let modDeny = [];
            let rptAllow = [];
            let rptDeny = [];

            for ( var index=0; index<modAllowFiltered.length; index++ ) {
                modAllow[index] = {hosp: modAllowFiltered[index], selected: !index ? true : false}
            };
            for ( var index=0; index<modDenyFiltered.length; index++ ) {
                modDeny[index] = {hosp: modDenyFiltered[index], selected: false}
            };
            for ( var index=0; index<rptAllowFiltered.length; index++ ) {
                rptAllow[index] = {hosp: rptAllowFiltered[index], selected: false}
            };
            for ( var index=0; index<rptDenyFiltered.length; index++ ) {
                rptDeny[index] = {hosp: rptDenyFiltered[index], selected: false}
            };

            this.setState({
                hospModAllow: modAllow,
                hospModDeny: modDeny,
                hospRptAllow: rptAllow,
                hospRptDeny: rptDeny,
                allowDenyHospComplete: true,
            });
        }

        if ( this.state.allHospLoaded && this.state.menuItemsLoaded && !this.state.allowDenyMenuComplete) {
            let allMenuItems = this.state.allMenuItems
            let allMenuItemsNames = this.state.allMenuItems.map(a => a.controlName)

            let menuItemsEmpty = this.props.selectedUserSecurityInfo.availableItems === "||" ? true : false;
            let menuItemsAllowStg = this.props.selectedUserSecurityInfo.availableItems != null ? this.props.selectedUserSecurityInfo.availableItems : "";
            menuItemsAllowStg = menuItemsAllowStg.slice(1,(menuItemsAllowStg.length-1));
            let menuItemsAllowArr = menuItemsAllowStg.length === 0 ? [] : menuItemsAllowStg.split("|");
            let menuItemsAllowName = ( menuItemsAllowArr.length === 0 && !menuItemsEmpty ) ? allMenuItemsNames : menuItemsAllowArr;
            let menuItemsDenyName = allMenuItemsNames.filter(x => !menuItemsAllowName.includes(x));

            const menuAllow = menuItemsAllowName.map(e => {
                let match = allMenuItems.find(all => all.controlName === e);
                match.selected = false;
                return match
            });

            const menuDeny = menuItemsDenyName.map(e => {
                let match = allMenuItems.find(all => all.controlName === e);
                match.selected = false;
                return match
            });

            this.setState({
                menuItemsAllow: menuAllow.sort((a,b) => {return a.id - b.id}),
                menuItemsDeny: menuDeny.sort((a,b) => {return a.id - b.id}),
                allowDenyMenuComplete: true,
            });
        }


        // if (this.state.isFormDirty && this.state.isSaving && this.state.isSaving != this.props.isFormDirty) {
        //     this.setState({
        //         isFormDirty: this.props.isFormDirty,
        //         isSaving: this.props.isFormDirty,
        //     });
        // }

        if (this.state.savedForm && !this.props.isDialogLoading && ((prevProps.isFormDirty != this.props.isFormDirty || this.props.error) || (prevProps.error != this.props.error || !this.state.isFormDirty))) {
            if (this.props.isFormDirty) {
                if (!this.state.hasError && this.state.hasError != this.props.error) {
                    this.setState({ showMessageError: true, hasError: true, savedForm: false, isFormDirty: true });
                    this.props.setChildrenOpening(true);
                }
            } else {
                if (this.state.isFormDirty && this.state.isFormDirty != this.props.isFormDirty) {
                    this.setState({ isFormDirty: this.props.isFormDirty, savedForm: false, hasError: false });
                }
            }
        }

    }

    setFormData = (initial) => {
        this.setState({
            initialDialogForm: { ...this.props.selectedUserSecurityInfo },
            dialogForm: { ...this.props.selectedUserSecurityInfo },
            isFormDirty: this.props.isFormDirty,
            savedForm: false,
            reload: false,
            changed: true,
            loaded: true,
        });
        
        if ((!this.state.allItemFields && this.props.selectedUserSecurityInfo) || initial) {
            let fields = {};
            Object.keys(this.props.selectedUserSecurityInfo).map((t) => {
                fields[t] = "";
            });
            
            if (fields["Userid"] == undefined) {
                fields["Userid"] = "";
            }
            this.setState({ allItemFields: fields});
        };
        
        return false;
    }
    
    //#991111 pulling ClientGroup may not be needed on the this screen as this check is perfomed on the lookup screen. 
    async getUserClientGroup() {
        let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetUserClientGroup"; //?email=" + this.props.userId;
        let params = {email:this.props.userId , accessToken: localStorage.getItem('token')};

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        let newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            userClientGroup: res && res.data ? res.data.result : "ers"
        });
    }

    async getAllMenuItemsList() {
        //let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetAllMenuItemsList";
        let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetAllMenuItemsList";
        let params = { accessToken: localStorage.getItem('token') };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            })

        let newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            allMenuItems: res && res.data ? res.data.menuItemsList : [],
            menuItemsLoaded: true
        })
    }

    async getAllPosHosp() {
        //const url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetHospitalList";
        const url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetHospitalList";
        let res = await axios
            //.get(url)
            .post(url, {
                uid: this.props.userId,                
                accessToken: localStorage.getItem('token')
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            })

        // needs to be a unique list of posible hospitals, no duplicates!
        const hospList = [...new Set(res.data.hospitalList.map(a => a.hospNbr))]

        let newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            allPosHosp: res && res.data.hospitalList ? hospList : [],
            allHospLoaded: true
        })
    }

    saveUserSecurity = (event) => {
        event.preventDefault();

        if (this.state.isFormDirty) {

            //let _this = this

            //  - Convert Allow & Deny back to strings.
            let dialogForm = this.state.dialogForm;
            let allPosHosp = "|" + this.state.allPosHosp.join("|") + "|";
            let allMenuItems = "|" + this.state.allMenuItems.map(e => e.controlName).join("|") + "|";

            let hospModAllow = "|" + this.state.hospModAllow.map(e => e.hosp).join("|") + "|";
            let hospRptAllow = "|" + this.state.hospRptAllow.map(e => e.hosp).join("|") + "|";
            let menuItemsAllow = "|" + this.state.menuItemsAllow.map(e => e.controlName).join("|") + "|";

            //  - Comapare converted string againts original strings to see if anything has changed.
            //  - Place changed strings into dialogForm
            dialogForm.availableHospsData = allPosHosp !== hospModAllow ? hospModAllow : null;
            dialogForm.availableHospsRpt = allPosHosp !== hospRptAllow ? hospRptAllow : null;
            dialogForm.availableItems = allMenuItems !== menuItemsAllow ? menuItemsAllow : null;

            this.props.saveUserSecurity(dialogForm);
            //this.props.setInitialPageVisible(true);

            //  - reset local dirty status to false.
            this.setState({
                isFormDirty: false,
            });
            //this.props.closeWindow
        };

        return false
    }

    resetUserSecurityInfo = () => {
        this.setState({
            dialogForm: { ...this.state.initialDialogForm },
            isFormDirty: false,
            reload: false,
            savedForm: true,
        });
    }

    handleItemClick = (event, data, connectedData) => {
        
        //#991111 this may need to be expanded to cover each list box separately
        let last = this.state.lastSelectedIndex;

        const newData = [...this.state[data]];
        const current = newData.findIndex((dataItem) => dataItem === event.dataItem);

        if (!event.nativeEvent.shiftKey) {
            this.state.lastSelectedIndex = last = current;
        }
        if (!event.nativeEvent.ctrlKey) {
            newData.forEach((item) => (item.selected = false));
        }

        const select = !event.dataItem.selected;
        if (last <= newData.length - 1) {
            for (let i = Math.min(last, current); i <= Math.max(last,current); i++) {
                newData[i].selected = select;
            }
        }

        if (data != 'hospModAllow' && data != 'hospModDeny' ) {
            this.clearSelect('hospModAllow');
            this.clearSelect('hospModDeny')
        }

        if (data != 'hospRptAllow' && data != 'hospRptDeny' ) {
            this.clearSelect('hospRptAllow');
            this.clearSelect('hospRptDeny')
        }

        if (data != 'menuItemsAllow' && data != 'menuItemsDeny' ) {
            this.clearSelect('menuItemsAllow');
            this.clearSelect('menuItemsDeny')
        }

        this.setState({
            [data]: newData,
            [connectedData]: this.state[connectedData].map((item) => {
                item[this.SELECTED_FIELD] = false;
                return item;
            }),
        });
    };

    clearSelect = (clearData) => {
        this.setState({
            [clearData]: this.state[clearData].map((item) => {
                item[this.SELECTED_FIELD] = false;
                return item;
            }),
        });
    }

    handleToolBarClick = (event) => {
        let toolName = event.toolName || "";

        switch (event.target.props.id) {
            case "modHospToolbar" :{
                let results = processListBoxData(
                    this.state.hospModAllow,
                    this.state.hospModDeny,
                    toolName,
                    this.SELECTED_FIELD
                );

                results.listBoxOneData.sort((a,b) => {return a.hosp - b.hosp })
                results.listBoxTwoData.sort((a,b) => {return a.hosp - b.hosp })
                
                this.setState({
                    hospModAllow: results.listBoxOneData.map((item) => {
                        item.selected = false;
                        return item;
                    }),
                    hospModDeny: results.listBoxTwoData.map((item) =>{
                        item.selected = false;
                        return item;
                    }),
                    isFormDirty: true,
                })
                break;
            }
            case "rptHospToolbar" :{
                let results = processListBoxData(
                    this.state.hospRptAllow,
                    this.state.hospRptDeny,
                    toolName,
                    this.SELECTED_FIELD
                );

                results.listBoxOneData.sort((a,b) => {return a.hosp - b.hosp })
                results.listBoxTwoData.sort((a,b) => {return a.hosp - b.hosp })

                this.setState({
                    hospRptAllow: results.listBoxOneData.map((item) => {
                        item.selected = false;
                        return item;
                    }),
                    hospRptDeny: results.listBoxTwoData.map((item) =>{
                        item.selected = false;
                        return item;
                    }),
                    isFormDirty: true,
                })
                break;
            }
            case "menuItemsToolbar" :{
                let results = processListBoxData(
                    this.state.menuItemsAllow,
                    this.state.menuItemsDeny,
                    toolName,
                    this.SELECTED_FIELD
                );

                results.listBoxOneData.sort((a,b) => {return a.id - b.id })
                results.listBoxTwoData.sort((a,b) => {return a.id - b.id })
                
                this.setState({
                    menuItemsAllow: results.listBoxOneData.map((item) => {
                        item.selected = false;
                        return item;
                    }),
                    menuItemsDeny: results.listBoxTwoData.map((item) =>{
                        item.selected = false;
                        return item;
                    }),
                    isFormDirty: true,
                })
                break;
            }
            default:
                break;
        };
        return true;
    };

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showMessageError: false, hasError: false });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector(".Footer2>#btnSubmitForm_Fake") ? document.querySelector(".Footer2>#btnSubmitForm_Fake").focus() : "";
        }, 0);

        return false;
    }

    render () {
        let showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
            <div className="CRStar_Window">
                <div className="fake_popup_cls"></div>
                <Draggable handle=".k-window-titlebar">
                    <div className="dragableSmallWindowCls">
                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                            <div className="k-window-title k-dialog-title">Error</div>
                            <div className="k-window-actions k-dialog-actions">
                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                    <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                </a>
                            </div>
                        </div>
                        <div className="emptyLine"></div>
                        <div className="container">
                        <label className="invalidMsgErrorCls">Can't save data. {this.props.errorMessage ? "(" + this.props.errorMessage + ")" : ""}</label>
                        </div>
                        <div className="emptyLine"></div>
                        <div className="container right">
                            <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                        </div>
                        <div className="emptyLine"></div>
                    </div>
                </Draggable>
            </div>
        : null
            
        const dialogFrm = this.state.dialogForm && this.state.loaded ? (
            <div className="container-fluid2" id="userSecurity">
                <form onSubmit={this.saveUserSecurity} className="userSecurityForm" id="userSecurityForm">
                    <div className="userSecurityHead">
                        <div id="email">UserId: {this.props.selectedUserSecurityInfo.email}</div>
                        <div id="userName">Name: {this.props.selectedUserSecurityInfo.userName}</div>
                    </div>
                    <div className="emptyLine hidden"></div>
                    <div className="userSecurity">
                        {/* <div className="row"> */}
                            <div className="hospAllowDeny">
                                <fieldset>
                                    <legend>Modify Hospital Data</legend>
                                    <div className="listBoxAllowDeny">
                                        <div className="listBoxAllow" id="modAllow">
                                            <h7>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Allow</h7>
                                            <ListBox 
                                                id="modAllow"
                                                data={this.state.hospModAllow}
                                                textField="hosp"
                                                selectedField={this.SELECTED_FIELD}
                                                onItemClick={(e) => this.handleItemClick(e, "hospModAllow", "hospModDeny")}
                                            />
                                        </div>
                                        <div className="toolBar">
                                            <ListBoxToolbar
                                                id="modHospToolbar"
                                                tools={this.state.tools}
                                                data={this.state.hospModAllow}
                                                dataConnected={this.state.hospModDeny}
                                                onToolClick={this.handleToolBarClick}
                                            />
                                        </div>
                                        <div className="listBoxDeny" id="modDeny">
                                            <h7>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deny</h7>
                                            <ListBox
                                                id="modDeny"
                                                data={this.state.hospModDeny}
                                                textField="hosp"
                                                selectedField={this.SELECTED_FIELD}
                                                onItemClick={(e) => this.handleItemClick(e, "hospModDeny", "hospModAllow")}
                                            />
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset>
                                    <legend>Report Hospital Data</legend>
                                    <div className="listBoxAllowDeny">
                                        <div className="listBoxAllow" id="rptAllow">
                                            <h7>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Allow</h7>
                                            <ListBox 
                                                id="rptAllow"
                                                data={this.state.hospRptAllow}
                                                textField="hosp"
                                                selectedField={this.SELECTED_FIELD}
                                                onItemClick={(e) => this.handleItemClick(e, "hospRptAllow", "hospRptDeny")}
                                            />
                                        </div>
                                        <div className="toolBar">
                                            <ListBoxToolbar
                                                id="rptHospToolbar"
                                                tools={this.state.tools}
                                                data={this.state.hospRptAllow}
                                                dataConnected={this.state.hospRptDeny}
                                                onToolClick={this.handleToolBarClick}
                                            />
                                        </div>
                                        <div className="listBoxDeny" id="rptDeny">
                                            <h7>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deny</h7>
                                            <ListBox
                                                id="rptDeny"
                                                data={this.state.hospRptDeny}
                                                textField="hosp"
                                                selectedField={this.SELECTED_FIELD}
                                                onItemClick={(e) => this.handleItemClick(e, "hospRptDeny", "hospRptAllow")}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>

                            <div className="menuItems">
                                <fieldset>
                                    <legend>Menu Items</legend>
                                    <div className="listBoxAllowDeny">
                                        <div className="listBoxAllow" id="menuAllow">
                                            <h7>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Allow</h7>
                                            <ListBox 
                                                id="menuAllow"
                                                data={this.state.menuItemsAllow}
                                                textField="controlDesc"
                                                selectedField={this.SELECTED_FIELD}
                                                onItemClick={(e) => this.handleItemClick(e, "menuItemsAllow", "menuItemsDeny")}
                                            />
                                        </div>
                                        <div className="toolBar">
                                            <ListBoxToolbar
                                                id="menuItemsToolbar"
                                                tools={this.state.tools}
                                                data={this.state.menuItemsAllow}
                                                dataConnected={this.state.menuItemsDeny}
                                                onToolClick={this.handleToolBarClick}
                                            />
                                        </div>
                                        <div className="listBoxDeny" id="menuDeny">
                                            <h7>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deny</h7>
                                            <ListBox
                                                id="menuDeny"
                                                data={this.state.menuItemsDeny}
                                                textField="controlDesc"
                                                selectedField={this.SELECTED_FIELD}
                                                onItemClick={(e) => this.handleItemClick(e, "menuItemsDeny", "menuItemsAllow")}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        {/* </div> */}
                    </div>
                </form>

                <Footer closeWindow={this.props.closeWindow}
                    refreshForm={this.resetUserSecurityInfo} formId="userSecurityForm"
                    isFormDirty={this.state.isFormDirty}
                    // showBtnNew=""
                    // showBtnDelete=""
                    className="Footer2"
                    isLookup={true}
                    exitFnc={this.props.closeWindow}
                    focusingField={this.state.focusingField}
                    inquiringMode={this.props.inquiringMode}
                    enabledAlways={true}
                    //dataForm={this.state.dialogForm}
                    //ignoreExit={true}
                />
            </div>

        ) : <div className="col-sm-12 showLoadingOnTopPageCls">Working...</div>;

        return (
            <React.Fragment>
                <React.Fragment>
                    {/* {showMessageError} */}
                </React.Fragment>
                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

UserSecurity.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        selectedUserSecurityInfo: state.patientAbstract.selectedUserSecurityInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,

        currentDialog: state.patientAbstract.currentDialog,

        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId, // <=== userId of current user accessing the screen.
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveUserSecurity: (dialogForm) => dispatch(actionCreators.saveUserSecurity(dialogForm)),
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        //clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSecurity);

