/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import Footer from "../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
import Draggable from 'react-draggable';
import * as dialogTypes from "../../AbstractManagement/maintDialogs";
import "./EditsSettingsMaint.css";

class EditsSettingsMaint extends Component {

    state = {
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,

        pAllItemFields: null,
        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        showMessageError: false,
        hasError: false,
        savedForm: false,
        fieldInFocus: null,
        focusingField: null,
        isSaving: false,
        enabledDeletedBtn: false,
        loaded: false,

        metafilesMv: null,
        initialMetafilesMv: null,
        metafilesMvItemFields: null,
        metafilesMvMaxlengthInput: null,
        selectedItemRowIndex: 0,
    }

    handleOnFocus = (event) => {
        event.preventDefault();

        var selectedRowIndex = this.state.selectedItemRowIndex;
        if (event.target.getAttribute("data-index") != "0") {
            selectedRowIndex = event.target.getAttribute("data-index");
        }

        this.setState({
            fieldInFocus: event.target.name,
            focusingField: event.target,
            selectedItemRowIndex: selectedRowIndex
        });

        return false;
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showMessageError: false
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector(".Footer2>#btnSubmitForm_Fake") ? document.querySelector(".Footer2>#btnSubmitForm_Fake").focus() : "";
        }, 0);

        return false;
    }

    componentDidMount() {
        this.setState({ selectedItemRowIndex: 0 });

        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);

        // Add all tabs in maintDialogs.js
        dialogTypes.systemConfigTabsDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog.name, dialog.id);
            this.props.setEnabledStatus(dialog.name);
        });

        this.setState({ isLoading: true, loaded: false });//#End

        if (!this.state.dialogForm && this.props.genEditsInfo) {
            this.setFormData(this.props.genEditsInfo);
        }

        var childrenOpening = this.props.childrenOpening;
        this.props.setChildrenOpening(childrenOpening);
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.dialogForm && this.props.genEditsInfo)
            || (prevProps.genEditsInfo && this.props.genEditsInfo && prevProps.genEditsInfo.ncdbMetafile !== this.props.genEditsInfo.ncdbMetafile)
            || (prevProps.genEditsInfo && this.props.genEditsInfo && prevProps.genEditsInfo.clinicalMetafile !== this.props.genEditsInfo.clinicalMetafile)
            || (prevProps.genEditsInfo && this.props.genEditsInfo && prevProps.genEditsInfo.additionalMetafile !== this.props.genEditsInfo.additionalMetafile)
            || (this.state.dialogForm && this.props.genEditsInfo && this.state.dialogForm.lastAccessTime !== this.props.genEditsInfo.lastAccessTime)
        ) {
            this.setFormData(this.props.genEditsInfo);
        }

        //
        if (this.state.tableStructureList === null && !this.state.loadedTableStructure) {
            this.setState({ loadedTableStructure: true });
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        //
        if (this.props.genEditsInfo && !this.state.metafilesMv && this.props.genEditsInfo.id) {
            axios.post(this.props.clientgroup + "/GetMetafilesMvList", { uid: this.props.genEditsInfo.id, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ metafilesMv: res.data.metafilesMvList, initialMetafilesMv: res.data.metafilesMvList, selectedItemRowIndex: 0 });

                        // State Metafiles List
                        if (!this.state.metafilesMvItemFields && res.data.metafilesMvList) {
                            let metafilesMvArr = [];
                            res.data.metafilesMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                metafilesMvArr.push(fields);
                            });

                            this.setState({ metafilesMvItemFields: metafilesMvArr });

                            // get maxlength for State Metafiles grid
                            //axios.post(this.props.clientgroup + "/GetTableStructure", { table: "user_settings_state_metafiles_Mv", accessToken: localStorage.getItem('token') })

                            let url = this.props.clientgroup + "/GetTableStructure";
                            let params = { table: "user_settings_state_metafiles_Mv", accessToken: localStorage.getItem('token') };

                            axios.post(url, params)
                                .then(res => {
                                    //update new accessToken again
                                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                                    if (newAccessToken) {
                                        localStorage.setItem('token', newAccessToken);
                                    }

                                    if (res.data !== null && res.data.result !== "") {
                                        let maxLengthValue = res.data.result;
                                        let maxLengthItem = metafilesMvArr && metafilesMvArr[0] ? metafilesMvArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                        this.setState({
                                            metafilesMvMaxlengthInput: { ...maxLengthItem }
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);

                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }
                                    return false;
                                });
                        };
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;
                });
        }

        if (this.state.savedForm && !this.props.isDialogLoading && ((prevProps.isFormDirty != this.props.isFormDirty || this.props.error) || (prevProps.error != this.props.error || !this.state.isFormDirty))) {
            console.log("GenEdits::save::" + this.props.isFormDirty);
            if (this.props.isFormDirty) {
                if (!this.state.hasError && this.state.hasError != this.props.error) {
                    this.setState({ showMessageError: true, hasError: true, isFormDirty: true });
                    this.props.setChildrenOpening(true);
                }
            } else {
                if (this.state.isFormDirty && this.state.isFormDirty != this.props.isFormDirty) {
                    this.setState({
                        savedForm: false,
                        isFormDirty: true,//isFormDirty: this.props.isFormDirty,
                        hasError: false
                    });
                }
            }

            /*
            if (this.props.genEditsInfo && this.props.genEditsInfo.id && !this.props.error) {
                document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "Record Saved" : null;
            } else {
                document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "" : null;
            }
            */
        }

        /*
        if (this.state.isFormDirty && (this.state.isSaving || this.props.deletedDataSuccess)
            && (prevProps.isFormDirty !== this.props.isFormDirty || this.state.isFormDirty !== this.props.isFormDirty)) {
            this.setState({
                isFormDirty: this.props.isFormDirty,
                isSaving: false,
            });
        }
        */
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "user_settings", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res.data ? res.data.result : null
        });
    }

    setFormData = (dataForm) => {
        let fields = {};
        Object.keys(dataForm).map((t) => {
            fields[t] = "";
        });

        this.setState({
            initialDialogForm: { ...fields },
            pAllItemFields: { ...fields },
            dialogForm: { ...dataForm },
            isFormDirty: false,
            reload: false,
            savedForm: false,
            hasError: false,
            loaded: true,
            selectedItemRowIndex: 0
        });

        //
        setTimeout(function () {
            document.getElementById("state0") ? document.getElementById("state0").focus() : "";
        }, 500);
    }

    saveEditsSettingsMaint = (event) => {
        event.preventDefault();

        let dialogFormData = this.state.dialogForm;

        let metafilesMv = this.state.metafilesMv;
        dialogFormData.metafilesMv = metafilesMv;
        dialogFormData.userSettingsStateMetafilesMv = metafilesMv;

        dialogFormData.userName = this.props.user_id;
        dialogFormData.userHosp = this.props.selectedHospital;

        this.props.saveEditsSettingsMaint(dialogFormData);

        this.setState({ isSaving: true, savedForm: true, isFormDirty: false, hasError: false });
        return false;
    }

    handleFormChange = (event) => {
        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                [event.target.name]: event.target.value,
            },
            isFormDirty: true,
        });
    }

    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            let index = event.target.getAttribute('data-index') ? event.target.getAttribute('data-index') : "-1";

            var _this = this;
            setTimeout(function () {
                _this.props.checkValidInput(name, value, table, key, index, flag);
            }, 1000);
        } else {
            this.setState({ uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "" } });
        }
    }

    refreshFormFnc = () => {
        let fields = {};
        if (!this.state.pAllItemFields && this.props.genEditsInfo) {
            Object.keys(this.props.genEditsInfo).map((t) => {
                fields[t] = "";
            });
        };

        this.setState({
            dialogForm: { ...this.state.initialDialogForm },
            pAllItemFields: fields,
            isFormDirty: false,
            reload: false
        });

        //
        setTimeout(function () {
            document.getElementById("state0") ? document.getElementById("state0").focus() : "";
        }, 500);
    }

    setSelectionRow = (event, index) => {
        this.setState({ selectedItemRowIndex: index });
    }

    async browseToStateMetafile(event, index) {
        event.preventDefault();

        var alias = event.target.getAttribute("dataalias") ? event.target.getAttribute("dataalias") : "";
        //
        var filter = "";
        if (alias && alias !== "Additional State") {
            filter = alias;

            if (alias === "State") {
                filter = event.target.getAttribute("datastate") ? event.target.getAttribute("datastate") : "";
            }
        }

        let url = this.props.clientgroup + "/GetListFilesByFilter";
        let res = await axios
            .post(url, { filter: filter, ext: 0, state: (alias === "State" ? "1" : "0"), accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        //
        this.setState({
            browsedItem: alias,
            browsedIndex: index,
            listFiles: res.data ? res.data.listFiles : null
        });

        return false;
    }

    handleStateMetafileChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.metafilesMv];
            newItems[index][name] = value;
            return { metafilesMv: newItems, isFormDirty: true };
        });
    }

    addNewStateMetafile = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.metafilesMv];
            const firstItem = newItems[0];

            const newItem = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "pid") {
                    newItem[t] = 0;
                } else {
                    newItem[t] = "";
                }
            });
            newItems.push(newItem);

            //
            const newFields = [...this.state.metafilesMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                newField[t] = "";
            });
            newFields.push(newField);
            return { metafilesMv: newItems, metafilesMvItemFields: newFields, isFormDirty: true };
        });
    }
    deleteSelectedStateMetafile = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedItemRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.metafilesMv.length) {
            selectedRow = this.state.metafilesMv.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.metafilesMv];
            if (newItems.length === 1) {
                const _newItems = [...this.state.metafilesMv];
                const firstItem = _newItems[0];

                const newItem = {};
                Object.keys(firstItem).map((t, k) => {
                    if (t === "id" || t === "pid") {
                        newItem[t] = 0;
                    } else {
                        newItem[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newItem);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.metafilesMvItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.metafilesMvItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { metafilesMv: newItems, metafilesMvItemFields: newFields, isFormDirty: true, selectedItemRowIndex: selectedRow };
        });
    }

    closeWindow = (e) => {
        e.preventDefault();

        this.setState({
            browsedItem: "",
            browsedIndex: null,
            listFiles: null
        });

        return false;
    }

    selectedFile = (e, _index) => {
        e.preventDefault();

        var index = this.state.browsedIndex || this.state.browsedIndex == "0" ? this.state.browsedIndex : -1;

        if (index > -1) {
            var fName = e.target.innerHTML;
            this.setState(prevState => {
                const newItems = [...prevState.metafilesMv];
                newItems[index]["stateMetafile"] = fName;

                return {
                    metafilesMv: newItems,
                    browsedItem: "",
                    browsedIndex: null,
                    listFiles: null,
                    isFormDirty: true,
                };
            });
        } else {
            var _browsedItem;
            if (this.state.browsedItem == "RCRS") {
                _browsedItem = "clinicalMetafile";
            } else if (this.state.browsedItem == "NCDB") {
                _browsedItem = "ncdbMetafile";
            } else if (this.state.browsedItem == "Additional State") {
                _browsedItem = "additionalMetafile";
            }
            var _fName = e.target.innerHTML;

            this.setState({
                browsedItem: "",
                browsedIndex: null,
                listFiles: null,
                dialogForm: {
                    ...this.state.dialogForm,
                    [_browsedItem]: _fName,
                },
                isFormDirty: true,
            });
        }

        return false;
    }

    setHighlight = (e, flag) => {
        if (flag) {
            e.target.setAttribute("class", "ErsGrBlueDialogRSRowHover");
        } else {
            e.target.setAttribute("class", "");
        }
    }

    render() {
        let listFiles;
        {
            listFiles = this.state.listFiles ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                            <div className="k-window-title k-dialog-title">{this.state.browsedItem ? this.state.browsedItem + " Metafile" : "Display Metafile Dates"}</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x hidden" onClick={(e) => { this.closeWindow(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container listMetafilesArea">
                            {
                                this.state.listFiles.map((f, index) => (
                                    <div className={index % 2 == 0 ? "row oddRowCls" : "row evenRowCls"}>
                                        <div className="col-sm-12">
                                            <div className="" onClick={e => { this.selectedFile(e, index) }}
                                                onMouseOver={e => { this.setHighlight(e, true) }}
                                                onMouseOut={e => { this.setHighlight(e, false) }}>
                                                {f}
                                            </div>
                                        </div>
                                    </div>
                                    )
                                )
                            }
                            </div>
                            <div className="emptyLine"></div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={(e) => { this.closeWindow(e) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">Can't save data.</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let loading = (
            !this.state.loaded || this.props.waitingFor ? <div className="col-sm-12 showLoadingOnTopPageCls">Working...</div> : ""
        );

        const dialogFrm = <div className="limitedInGridCls">
            <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                {loading}
                <div className="parentWindow">
                    <div className="patientAbstractGridView" id="editsSettingsMaintMainPage" style={{ height: "600px" }}>
                        {
                            this.state.dialogForm ?
                                <div className="container-fluid2">
                                    <form onSubmit={this.saveEditsSettingsMaint} id="editsSettingsMaintForm" className="editsSettingsMaintForm">

                                        <div id="inputAreaCls">
                                            <div style={{ width: "750px" }}>
                                                <div className="frameInner">
                                                    <div className="header">Genedits Settings</div>

                                                    <div className="row">
                                                        <div className="col-sm-11">
                                                            <fieldset style={{ maxHeight: "375px", width: "305px" }}>
                                                                <legend>State Metafiles</legend>
                                                                <div style={{ width: "280px" }}>
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ width: "10px" }}>&nbsp;</th>
                                                                                <th style={{ width: "60px" }}>State</th>
                                                                                <th style={{ width: "130px" }}>Metafile</th>
                                                                                <th>&nbsp;</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody></tbody>
                                                                    </table>
                                                                </div>
                                                                <div style={{ display: "flex", overflow: "auto", maxHeight: "283px", width: "285px" }}>
                                                                    <table id="stateMetafilesTbl">
                                                                        <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                            <tr>
                                                                                <th style={{ width: "10px" }}>&nbsp;</th>
                                                                                <th style={{ width: "60px" }}>State</th>
                                                                                <th style={{ width: "130px" }}>Metafile</th>
                                                                                <th>&nbsp;</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                this.state.metafilesMv !== null && this.state.metafilesMv.length > 0 ? this.state.metafilesMv.map((fi, index) => (
                                                                                    <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionRow(e, index) }} className={this.state.selectedItemRowIndex == index ? "selectedRowCls" : ""}>
                                                                                        <td style={{ width: "10px", textAlign: "right", paddingRight: "2px" }}></td>
                                                                                        <td>
                                                                                            <select onFocus={this.handleOnFocus} onChange={(e) => { this.handleStateMetafileChange(e, index) }}
                                                                                                name="state" onBlur={this.handleLostFocus}
                                                                                                value={fi.state ? fi.state : ""} width="55px"
                                                                                                id={"state" + index}
                                                                                                style={{ height: "26px", width: "100%" }}
                                                                                            >
                                                                                                <option value=""></option>
                                                                                                <option value="AK">AK   -   ALASKA</option>
                                                                                                <option value="AL">AL   -   ALABAMA</option>
                                                                                                <option value="AR">AR   -   ARKANSAS</option>
                                                                                                <option value="AZ">AZ   -   ARIZONA</option>
                                                                                                <option value="CA">CA   -   CALIFORNIA</option>
                                                                                                <option value="CO">CO   -   COLORADO</option>
                                                                                                <option value="CT">CT   -   CONNECTICUT</option>
                                                                                                <option value="DE">DE   -   DELAWARE</option>
                                                                                                <option value="FL">FL   -   FLORIDA</option>
                                                                                                <option value="GA">GA   -   GEORGIA</option>
                                                                                                <option value="HI">HI   -   HAWAII</option>
                                                                                                <option value="IA">IA   -   IOWA</option>
                                                                                                <option value="ID">ID   -   IDAHO</option>
                                                                                                <option value="IL">IL   -   ILLINOIS</option>
                                                                                                <option value="IN">IN   -   INDIANA</option>
                                                                                                <option value="KS">KS   -   KANSAS</option>
                                                                                                <option value="KY">KY   -   KENTUCKY</option>
                                                                                                <option value="LA">LA   -   LOUISIANA</option>
                                                                                                <option value="MA">MA   -   MASSACHUSETTS</option>
                                                                                                <option value="MD">MD   -   MARYLAND</option>
                                                                                                <option value="ME">ME   -   MAINE</option>
                                                                                                <option value="MI">MI   -   MICHIGAN</option>
                                                                                                <option value="MN">MN   -   MINNESOTA</option>
                                                                                                <option value="MO">MO   -   MISSOURI</option>
                                                                                                <option value="MS">MS   -   MISSISSIPPI</option>
                                                                                                <option value="MT">MT   -   MONTANA</option>
                                                                                                <option value="NC">NC   -   NORTH CAROLINA</option>
                                                                                                <option value="ND">ND   -   NORTH DAKOTA</option>
                                                                                                <option value="NE">NE   -   NEBRASKA</option>
                                                                                                <option value="NH">NH   -   NEW HAMPSHIRE</option>
                                                                                                <option value="NJ">NJ   -   NEW JERSEY</option>
                                                                                                <option value="NM">NM   -   NEW MEXICO</option>
                                                                                                <option value="NV">NV   -   NEVADA</option>
                                                                                                <option value="NY">NY   -   NEW YORK</option>
                                                                                                <option value="OH">OH   -   OHIO</option>
                                                                                                <option value="OK">OK   -   OKLAHOMA</option>
                                                                                                <option value="OR">OR   -   OREGON</option>
                                                                                                <option value="PA">PA   -   PENNSYLVANIA</option>
                                                                                                <option value="RI">RI   -   RHODE ISLAND</option>
                                                                                                <option value="SC">SC   -   SOUTH CAROLINA</option>
                                                                                                <option value="SD">SD   -   SOUTH DAKOTA</option>
                                                                                                <option value="TN">TN   -   TENNESSEE</option>
                                                                                                <option value="TX">TX   -   TEXAS</option>
                                                                                                <option value="UT">UT   -   UTAH</option>
                                                                                                <option value="VA">VA   -   VIRGINIA</option>
                                                                                                <option value="VT">VT   -   VERMONT</option>
                                                                                                <option value="WA">WA   -   WASHINGTON</option>
                                                                                                <option value="WI">WI   -   WISCONSIN</option>
                                                                                                <option value="WV">WV   -   WEST VIRGINIA</option>
                                                                                                <option value="WY">WY   -   WYOMING</option>
                                                                                            </select>
                                                                                        </td>
                                                                                        <td>
                                                                                            <Input type="input" focused={this.handleOnFocus} changed={(e) => { this.handleStateMetafileChange(e, index) }}
                                                                                                labelText="" field="stateMetafile" lostfocused={this.handleLostFocus}
                                                                                                value={fi.stateMetafile} width="125px" labelWidth="0px"
                                                                                                lineStyle="oneLine"
                                                                                                maxLength={this.state.metafilesMvMaxlengthInput ? this.state.metafilesMvMaxlengthInput["statemetafile"] : ""}
                                                                                                keyId={"stateMetafile" + index}
                                                                                                rowIndex={index}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <button type="button" dataAlias="State" dataState={fi.state ? fi.state : ""} onClick={(e) => { this.browseToStateMetafile(e, index) }}>Browse</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                )) : '...'
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                                <div className="contactBtnArea right" style={{ marginTop: "10px", width: "280px" }}>
                                                                    <button className="ErsGrBlueButton" onClick={this.addNewStateMetafile}>Add</button>
                                                                    <button className="ErsGrBlueButton" id="deleteSelectedAliasBtn" onClick={this.deleteSelectedStateMetafile}>Delete</button>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    </div>

                                                    <div className="emptyLine"></div>

                                                    <div className="col-sm-12">
                                                        <div className="oneLine">
                                                            <Input type="input" changed={this.handleFormChange}
                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                labelText="RCRS Metafile" field="clinicalMetafile"
                                                                width="105px" labelWidth="125px"
                                                                value={this.state.dialogForm.clinicalMetafile}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["clinicalmetafile"] : ""}
                                                                inValid={this.state.pAllItemFields["clinicalMetafile"]} />
                                                            &nbsp;<button type="button" dataAlias="RCRS" style={{ height: "26px" }} onClick={(e) => { this.browseToStateMetafile(e, -1) }}>Browse</button>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12">
                                                        <div className="oneLine">
                                                            <Input type="input" changed={this.handleFormChange}
                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                labelText="NCDB Metafile" field="ncdbMetafile"
                                                                width="105px" labelWidth="125px"
                                                                value={this.state.dialogForm.ncdbMetafile}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ncdbmetafile"] : ""}
                                                                inValid={this.state.pAllItemFields["ncdbMetafile"]} />
                                                            &nbsp;<button type="button" dataAlias="NCDB" style={{ height: "26px" }} onClick={(e) => { this.browseToStateMetafile(e, -1) }}>Browse</button>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12">
                                                        <div className="oneLine">
                                                            <Input type="input" changed={this.handleFormChange}
                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                labelText="Add. State Metafile" field="additionalMetafile"
                                                                width="105px" labelWidth="125px"
                                                                value={this.state.dialogForm.additionalMetafile}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["additionalmetafile"] : ""}
                                                                inValid={this.state.pAllItemFields["additionalMetafile"]} />
                                                            &nbsp;<button type="button" style={{ height: "26px" }} dataAlias="Additional State" onClick={(e) => { this.browseToStateMetafile(e, -1) }}>Browse</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="emptyLine"></div>

                                    <Footer closeWindow={this.props.closeWindow}
                                        refreshForm={this.refreshFormFnc} formId="editsSettingsMaintForm"
                                        isFormDirty={this.state.isFormDirty}
                                        showBtnNew={false}
                                        showBtnDelete={false}
                                        className="Footer2"
                                        initialPageVisible={true}
                                        exitFnc={this.props.closeWindow}
                                        focusingField={this.state.focusingField}
                                        ignoreExit={true}
                                        showExtra={[{ name: "Check Dates", action: "showStateMetafileInfos" }]}
                                    />

                                </div>
                                : <p>Genedits Settings data loading...</p>
                        }
                    </div>
                </div>
            </div>
        </div>;

        return (
            <React.Fragment>
                <React.Fragment>
                    {listFiles}
                </React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>
                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

EditsSettingsMaint.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        genEditsInfo: state.patientAbstract.genEditsInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        deletedDataSuccess: state.patientAbstract.deletedDataSuccess,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        waitingFor: state.patientAbstract.waitingFor,

        //
        selectedHospital: state.patientAbstract.selectedHospital,
        user_id: state.auth.userId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveEditsSettingsMaint: (dialogForm) => dispatch(actionCreators.saveGenEdits(dialogForm)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        resetDeletedDataSuccess: (value) => dispatch(actionCreators.resetDeletedDataSuccess(value)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        setHandlerControlItem: (item) => dispatch(actionCreators.setHandlerControlItem(item)),
        showPageLoading: (flag) => dispatch(actionCreators.showPageLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        loadEditsSettingsMaint: (val) => dispatch(actionCreators.loadEditsSettingsMaint(val)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),

        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditsSettingsMaint);