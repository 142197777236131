/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Footer from "../Shared/Footer";
import Input from "../../../UI/Input";
import "../PatientMaintenance/PatientMaintenance.css";
import "./StagingMaintenance.css";
import "../DiagnosisMaintenance/DiagnosisMaintenance.css";
import HelpV21 from "../../../UI/HelpV21";
import axios from "../../../../axios-instance";
import { TabContent, TabPane, Nav, NavLink } from "reactstrap";
import classnames from "classnames";

class V21CompatibilityFeatureForm extends Component {

    state = {
        activeTab: "1",
        sAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'stagingForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty stagingForm back to what it was on load
        initialStagingForm: null,
        stagingForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        fieldInFocus: "v21TnmEdNbr",
        fieldItemNbr: "",
        isHelpVisible: false,
        isDialogVisible: true,

        determinedSchemaId: false,

        schemaId: "",
        schemaIdDisplaying: "",
        schemaName: "",
        ajccId: "",
        ajccDiseaseId: "",
        fn_schema_id: "",
        fn_disc_schema_id: "",
        fn_disc_enabled: "",

        dynamicItemFields: null,
        lookupOfItemFields: null,
        itemNoLookupOnPopup: null,
        showDynamicItemFields: null,

        // set max-length for input fields
        getTableStructure: false,
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        //
        tableStructureListForSsdis: null,
        pageMaxlengthInputForSsdis: null,
        hasSetMaxLengthForSsdis: false,

        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,
        //
        setPrimValue: false,
        primTopo: null,
        primHist: null,
        primSite: null,
        primKey: null,
        schemaNbr: "",
        fnSchemaNbr: "",
        confirmingAllCsSsf: false,
        loadedPage: false,
        focusingField: null,
        savingData: false,
        ssdiTitle: null,
        ssdiHasChanged: false,
        setFirstLoadingPage: false,
    }

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: true });
    }

    closeWarningDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isDialogVisible: false });
        return false;
    }

    //capitalize all words of a string. 
    capitalizeWords(string) {
        return string.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
    };

    getFieldNameByIndex = (index) => {
        var name = "v21CsSsf" + index;
        if (this.state.lookupOfItemFields && this.state.lookupOfItemFields[name]) {
            var fieldName = this.state.lookupOfItemFields[name];

            fieldName = fieldName.toLowerCase();

            fieldName = fieldName.split(/_/g).map(word =>
                `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`)
                .join(" ");

            fieldName = fieldName.replace(/ /g, "");
            if (fieldName.indexOf("V21") == -1) {
                name = "v21" + fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
            } else {
                name = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
            }
        }
        return name;
    }

    // #20210125 - START
    showConfirmationMsgOrContinue = (event) => {
        if (this.props.isFormDirty) {
            if (event.target.value === "yes") {
                if (this.props.isChildren) {
                    this.props.setChildrenWindowVisible(false);
                } else {
                    this.props.setParentWindowVisible(false);
                    this.setState({ isDialogVisible: false });

                    // set this tab to disabled
                    this.props.setEnabledStatus(this.props.currentDialog, false);
                    // move to first tab is LOOKUP
                    this.props.removeTabItems(this.props.currentDialog);
                }
                this.setState({ isShowConfirmationMsg: false });
            } else {
                this.setState({ isShowConfirmationMsg: true });
            }
        } else {
            if (this.props.isChildren) {
                this.props.setChildrenWindowVisible(false);
            } else if (this.props.isLookup) {
                this.props.closeAddNewDialog(false);
            } else {
                this.props.setParentWindowVisible(false);
                this.setState({ isDialogVisible: false });

                // set this tab to disabled
                this.props.setEnabledStatus(this.props.currentDialog, false);
                // move to first tab is LOOKUP
                this.props.removeTabItems(this.props.currentDialog);
            }
            return false;
        }
    }
    // #20210125 - END

    showTextDialog = () => {
        this.setState({ isShowTextVisible: true });
    }

    closeTextDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowTextVisible: false });
        this.props.closeTextDialog(false);

        return false;
    }

    toggleTab = (event, tab) => {
        this.setState({ activeTab: tab });
        if (tab === "1") {
            setTimeout(function () {
                document.getElementById("v21TnmEdNbr") ? document.getElementById("v21TnmEdNbr").focus() : '';
            }, 0);
        } else if (tab === "2") {
            var schemaDiscr1Visible = this.state.fn_disc_enabled === "1" ? true : false;
            setTimeout(function () {
                schemaDiscr1Visible && document.getElementById("v21SchemaDiscrim1") ? document.getElementById("v21SchemaDiscrim1").focus()
                    :
                    document.getElementById("V21_SSDI_USER_1") ? document.getElementById("V21_SSDI_USER_1").focus() : "";
            }, 0);
        }
        return false;
    }

    async reloadPrimInfo(cid, siteCode, topoCode, histCode) {
        let site = topoCode;
        let hist = histCode;

        let Use_Screen_Values = "No";

        var schemaDiscrim1 = this.state.stagingForm != null && this.state.stagingForm.v21SchemaDiscrim1 != null && this.state.stagingForm.v21SchemaDiscrim1 != undefined ? this.state.stagingForm.v21SchemaDiscrim1 : "";
        var schemaDiscrim2 = this.state.stagingForm != null && this.state.stagingForm.v21SchemaDiscrim2 != null && this.state.stagingForm.v21SchemaDiscrim2 != undefined ? this.state.stagingForm.v21SchemaDiscrim2 : this.props.schemaDiscrim2;

        //DetermineNecessaryDiscriminators
        await axios.post(this.props.clientgroup + "/DetermineNecessaryDiscriminatorsV21", {
            "site": site,
            "hist:": hist,
            "sex": this.props.selectedPatient.sex,
            "disc1": schemaDiscrim1,
            "disc2": schemaDiscrim2,
            "dxYear": (this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.diagnosisDateYear : ""),
            "useScreenValue": Use_Screen_Values,
            accessToken: localStorage.getItem('token')
        })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    let schemaId = res.data.commonLookupItem ? res.data.commonLookupItem.code : "";
                    let totalRows = res.data.commonLookupItem ? res.data.commonLookupItem.id : 0;

                    this.setState({
                        fn_disc_schema_id: schemaId != "" ? schemaId : this.state.fn_disc_schema_id,
                        schemaId: schemaId,
                    });

                    if (totalRows > 0 && Use_Screen_Values == "No") {
                        this.setState({ fn_disc_enabled: "1" });
                    } else if (totalRows == 0 && Use_Screen_Values == "No") {
                        this.setState({ fn_disc_enabled: "" });
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //DetermineSchemaId
        axios.post(this.props.clientgroup + "/DetermineSchemaIdV21", {
            "cid": cid,
            "site": site,
            "hist": hist,
            "sex": this.props.selectedPatient.sex,
            "ageDx": this.props.selectedMedicalRecord.ageDx,
            "disc1": schemaDiscrim1,
            "disc2": schemaDiscrim2,
            "dxYear": (this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.diagnosisDateYear : ""),
            accessToken: localStorage.getItem('token')
        })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    let schemaId = res.data.commonLookupItem && res.data.commonLookupItem.code && res.data.commonLookupItem.code !== "" ? res.data.commonLookupItem.code : "";
                    let schemaName = res.data.commonLookupItem ? res.data.commonLookupItem.name : "";
                    let totalRows = res.data.commonLookupItem ? res.data.commonLookupItem.id : 0;

                    var schemaIdDisplaying = schemaId;
                    if (totalRows > 1) {
                        schemaIdDisplaying = "Discriminator Check";
                    }

                    let ajccId = res.data.commonLookupItem ? res.data.commonLookupItem.ajccId : "";
                    let ajccDiseaseId = res.data.commonLookupItem ? res.data.commonLookupItem.ajccDiseaseId : "";

                    var _schemaId = this.state.schemaId ? this.state.schemaId : null;
                    var _ajccId = this.state.ajccId ? this.state.ajccId : null;

                    //var msgOut = "when visiting the staging screen, if schema_id or ajcc_id change from a previous value, the save button should become enabled even though these fields are behind the scenes and not displayed.";
                    //console.log("===PR00335===", msgOut);

                    this.setState({
                        schemaId: schemaId,
                        schemaName: schemaName,
                        ajccId: ajccId,
                        ajccDiseaseId: ajccDiseaseId,

                        schemaIdDisplaying: "Schema ID: " + schemaIdDisplaying,
                        fn_schema_id: schemaId,

                        isFormDirty: this.state.isFormDirty || (_schemaId && _schemaId != schemaId) || (_ajccId && _ajccId != ajccId)
                    });

                    var stg2018 = this;
                    axios.post(this.props.clientgroup + "/GetSsdiItemListsV21", { schemaId: schemaId, accessToken: localStorage.getItem('token') })
                        .then(res => {
                            //update new accessToken again
                            var newAccessToken = res && res.data ? res.data.accessToken : null;
                            if (newAccessToken) {
                                localStorage.setItem('token', newAccessToken);
                            }

                            if (!res.data.error) {
                                let ssdiItemLists = res.data.commonLookupList;
                                if (ssdiItemLists != null && ssdiItemLists.length > 0) {
                                    // first hide all fields...
                                    let dif = this.state.dynamicItemFields;
                                    let sdi = this.state.showDynamicItemFields;
                                    let loi = this.state.lookupOfItemFields;
                                    let iNo = this.state.itemNoLookupOnPopup;

                                    ssdiItemLists.map((t, index) => {
                                        // then show it by order/name
                                        try {
                                            dif["v21CsSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["description"];
                                        } catch (exps) {
                                        }
                                        try {
                                            loi["v21CsSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["name"];
                                        } catch (exps) {
                                        }
                                        try {
                                            iNo["v21CsSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["code"];
                                        } catch (exps) {
                                        }
                                        try {
                                            sdi["v21CsSsf" + ssdiItemLists[index]["order"]] = "";
                                        } catch (exps) {
                                        }
                                    });

                                    var startFromIndex = 1;
                                    if (schemaIdDisplaying.indexOf("Discriminator Check") != -1) {
                                        startFromIndex = 1;
                                    } else {
                                        startFromIndex = ssdiItemLists.length + 1;
                                    }

                                    for (var idx = startFromIndex; idx <= 25; idx++) {
                                        try {
                                            dif["v21CsSsf" + idx] = null;//label
                                        } catch (exps) { }
                                        try {
                                            sdi["v21CsSsf" + idx] = "hidden";//show or hide field
                                        } catch (exps) { }
                                    }

                                    setTimeout(function () {
                                        stg2018.setState({
                                            dynamicItemFields: dif,
                                            showDynamicItemFields: sdi,
                                            lookupOfItemFields: loi,
                                            itemNoLookupOnPopup: iNo,
                                        });
                                        return false;
                                    }, 500);
                                } else {
                                    // when initial is hidden all fields
                                    if (stg2018.state.dynamicItemFields && stg2018.props.v21CompatibilityFeature) {
                                        let fields = {};
                                        Object.keys(stg2018.props.v21CompatibilityFeature).map((t) => {
                                            fields[t] = " ";
                                        });

                                        stg2018.setState({ dynamicItemFields: { ...fields } });
                                    };
                                    if (stg2018.state.showDynamicItemFields && stg2018.props.v21CompatibilityFeature) {
                                        let fields = {};
                                        Object.keys(stg2018.props.v21CompatibilityFeature).map((t) => {
                                            fields[t] = "hidden";
                                        });

                                        stg2018.setState({ showDynamicItemFields: { ...fields } });
                                    };
                                }
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            /*
                            var btnLogout = document.getElementById("logoutButtonArea");
                            if (btnLogout) {
                                btnLogout.querySelector("a").click();
                            }
                            */
                            //@TODO:...

                            return false;
                        });
                }
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });
    }

    async getPrimInfo(cid) {
        let url = this.props.clientgroup + "/ReloadPrimInfo?cId=" + cid;
        let result = await axios
            .post(url, { key: cid, accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = result && result.data ? result.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            setPrimValue: true,
            primSite: result.data.split('|')[0],
            primTopo: result.data.split('|')[1],
            primHist: result.data.split('|')[2],
            primKey: cid
        });
    }

    //
    async Check_Schema_ID_Has_Changed(Topo_Code, Hist_Code, Topo_Code2, Hist_Code2) {
        var _this = this;
        var _schemaId;
        var _ajccId;
        var _schemaId2;
        var _ajccId2;

        var Use_Screen_Values = "No";

        var schemaDiscrim1 = this.state.stagingForm.v21SchemaDiscrim1 != null && this.state.stagingForm.v21SchemaDiscrim1 != undefined ? this.state.stagingForm.v21SchemaDiscrim1 : "";
        var schemaDiscrim2 = this.state.stagingForm.v21SchemaDiscrim2 != null && this.state.stagingForm.v21SchemaDiscrim2 != undefined ? this.state.stagingForm.v21SchemaDiscrim2 : this.props.schemaDiscrim2;

        if (Topo_Code !== "" && Hist_Code !== "") {
            await axios.post(this.props.clientgroup + "/DetermineSchemaIdV21", {
                "site": Topo_Code,
                "hist": Hist_Code,
                "sex": this.props.selectedPatient.sex,
                "ageDx": this.props.selectedMedicalRecord.ageDx,
                "disc1": schemaDiscrim1,
                "disc2": schemaDiscrim2,
                "dxYear": (this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.diagnosisDateYear : ""),
                "useScreenValue": Use_Screen_Values,
                accessToken: localStorage.getItem('token')
            })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        _schemaId = res.data.commonLookupItem && res.data.commonLookupItem.code && res.data.commonLookupItem.code !== "" ? res.data.commonLookupItem.code : "";//Code = SeerapiId
                        _ajccId = res.data.commonLookupItem ? res.data.commonLookupItem.ajccId : "";
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }

        if (Topo_Code2 !== "" && Hist_Code2 !== "") {
            await axios.post(this.props.clientgroup + "/DetermineSchemaIdV21", {
                "site": Topo_Code2,
                "hist": Hist_Code2,
                "sex": this.props.selectedPatient.sex,
                "ageDx": this.props.selectedMedicalRecord.ageDx,
                "disc1": schemaDiscrim1,
                "disc2": schemaDiscrim2,
                "dxYear": (this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.diagnosisDateYear : ""),
                "useScreenValue": Use_Screen_Values,
                accessToken: localStorage.getItem('token')
            })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        _schemaId2 = res.data.commonLookupItem && res.data.commonLookupItem.code && res.data.commonLookupItem.code !== "" ? res.data.commonLookupItem.code : "";//Code = SeerapiId
                        _ajccId2 = res.data.commonLookupItem ? res.data.commonLookupItem.ajccId : "";
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }

        //
        if ((_schemaId && _schemaId2 && _schemaId != _schemaId2)
            || (_ajccId && _ajccId2 && _ajccId != _ajccId2)) {
            _this.setState({ isFormDirty: true });
        }
    }

    componentWillUnmount() {
        this.setState({ stagingForm: null, loadedPage: false, fn_disc_enabled: "" });
    }

    componentDidMount() {
        this.props.setChildrenOpening(false);

        if (!this.state.stagingForm && this.props.v21CompatibilityFeature) {
            this.setFormData();
        }

        if (this.props.selectedMedicalRecord && this.props.selectedPrimarySummary) {
            let siteCode = this.props.selectedPrimarySummary.siteCode;
            let topoCode = this.props.selectedMedicalRecord.topoCode;
            let histCode = this.props.selectedMedicalRecord.histCode;

            let topoCodeBak = this.props.selectedMedicalRecord["topoCode_bak"];
            let histCodeBak = this.props.selectedMedicalRecord["histCode_bak"];

            var ssdiHasChanged = false;
            if ((topoCodeBak && topoCode !== topoCodeBak) || (histCodeBak && histCode !== histCodeBak)) {
                ssdiHasChanged = true;
            }

            // 1) brand new primary (blank --> values)
            if (!topoCodeBak && !histCodeBak) {
                this.setState({ isFormDirty: true });
            } else {
                // 2) Change TOPO code to different site (schema AND AJCCID change)
                this.setState({
                    primSite: siteCode,
                    primTopo: topoCode,
                    primHist: histCode,

                    ssdiHasChanged: ssdiHasChanged,
                });
            }

            console.log("ssdiHasChanged", ssdiHasChanged);
        } else {
            let cid = this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.cid : "0";
            this.getPrimInfo(cid);
        }
    }

    //@TODO: add this function [initializeStaging]
    componentDidUpdate(prevProps) {
        if (!this.props.selectedMedicalRecord) return false;

        if (this.state.stagingForm && !this.state.loadedPage) {
            console.clear();
            console.log('[0] componentDidUpdate...');
            this.Determine_Necessary_Discriminators("No");
            this.Determine_Schema_ID("No");

            this.setState({ loadedPage: true });
        }

        if ((this.props.selectedPrimarySummary.siteCode === null || this.props.selectedPrimarySummary.siteCode === "")
            || (this.props.selectedMedicalRecord.topoCode === null || this.props.selectedMedicalRecord.topoCode === "")
            || (this.props.selectedMedicalRecord.histCode === null || this.props.selectedMedicalRecord.histCode === "")
            || this.props.selectedPrimarySummary.siteCode !== this.state.primSite
            || this.props.selectedMedicalRecord.topoCode !== this.state.primTopo
            || this.props.selectedMedicalRecord.histCode !== this.state.primHist
        ) {
            if (!this.state.setPrimValue && this.props.selectedMedicalRecord && this.props.selectedMedicalRecord.cid != null) {
                let cid = this.props.selectedMedicalRecord.cid;

                let siteCode = this.props.selectedPrimarySummary.siteCode;
                let topoCode = this.props.selectedMedicalRecord.topoCode
                let histCode = this.props.selectedMedicalRecord.histCode;

                this.setState({
                    setPrimValue: true,
                    primSite: siteCode,
                    primTopo: topoCode,
                    primHist: histCode
                });

                this.reloadPrimInfo(cid, siteCode, topoCode, histCode);
            }
        } else {
            if (!this.state.setPrimValue && (this.state.primSite === null || this.state.primTopo === null || this.state.primHist === null)) {
                let cid = this.props.selectedMedicalRecord.cid;
                let siteCode = this.props.selectedPrimarySummary.siteCode;
                let topoCode = this.props.selectedMedicalRecord.topoCode
                let histCode = this.props.selectedMedicalRecord.histCode;

                this.setState({
                    setPrimValue: true,
                    primSite: siteCode,
                    primTopo: topoCode,
                    primHist: histCode
                });

                this.reloadPrimInfo(cid, siteCode, topoCode, histCode);
            }
        }

        if ((!this.state.stagingForm && this.props.v21CompatibilityFeature)
            || (prevProps.v21CompatibilityFeature && this.props.v21CompatibilityFeature && prevProps.v21CompatibilityFeature !== this.props.v21CompatibilityFeature)
        ) {
            this.setFormData();
        }
        if (prevProps.v21CompatibilityFeature && prevProps.v21CompatibilityFeature !== this.props.v21CompatibilityFeature) {
            let _isFormDirty = this.state.isFormDirty ? this.state.isFormDirty : false;
            this.setState({ isFormDirty: this.props.isFormDirty ? this.props.isFormDirty : _isFormDirty });
        }

        if (this.state.ssdiHasChanged && this.state.stagingForm) {
            let topoCode = this.props.selectedMedicalRecord.topoCode;
            let histCode = this.props.selectedMedicalRecord.histCode;

            let topoCodeBak = this.props.selectedMedicalRecord["topoCode_bak"];
            let histCodeBak = this.props.selectedMedicalRecord["histCode_bak"];

            if (!topoCode || !histCode) {
                this.setState({ isFormDirty: true });
            } else {
                this.Check_Schema_ID_Has_Changed(topoCode, histCode, topoCodeBak, histCodeBak);//Topo_Code, Hist_Code, Topo_Code2, Hist_Code2
            }

            this.setState({ ssdiHasChanged: false });
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    stagingForm: { ...this.state.stagingForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                if (this.props.currentSelectedField.indexOf("v21CsSsf") != -1) {
                    this.setState({
                        sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        stagingForm: { ...this.state.stagingForm, [this.props.currentSelectedField + "Name"]: "" },
                    });
                } else {
                    this.setState({
                        sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        stagingForm: { ...this.state.stagingForm, [this.props.currentSelectedField + "Name"]: "" },
                    });
                }
            }
            this.props.clearCommonLookupItem();
        }

        // set maxlength for all fields
        if (this.state.tableStructureList === null && !this.state.getTableStructure) {
            this.getTableStructure();
            this.setState({ getTableStructure: true });
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    //Ssdi fields
                    pageMaxlengthInputForSsdis: {
                        ...this.state.pageMaxlengthInputForSsdis,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        if (!this.state.confirmingAllCsSsf && this.state.schemaName !== "" && this.props.v21CompatibilityFeature) {
            this.setFormData();
            this.setState({ confirmingAllCsSsf: true });
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "Staging", table2: "Ssdi", table3: "v21_to_v22_Backup_Fields", table4: "v21_to_v22_SSDI_Backup", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res.data ? res.data.result : null
        });
    }

    setFormData = () => {
        var dataForm = this.props.v21CompatibilityFeature;
        var siteCode = this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.siteCode : "";
        var schemaName = this.state.schemaName;

        const _isFormDirty = this.state.isFormDirty;

        Object.keys(dataForm).map((t) => {
            let dataVal = dataForm[t];

            var defaultValue = "08";
            if (siteCode === "42") {
                defaultValue = "88";
            } else if (siteCode === "53") {
                defaultValue = "09";
            }

            if (t === "v21TnmEdNbr" && (dataVal === null || dataVal === "" || dataVal === undefined)) {
                dataVal = defaultValue;
            } else if ((t === "pStgDescriptor" || t === "cStgDescriptor") && (dataVal === null || dataVal === "" || dataVal === undefined)) {
                dataVal = "0";

                // IMPORTANT!!!
            } else if (t.indexOf("v21CsSsf") !== -1 && schemaName !== "") {
                if (dataForm["v21SchemaId"] !== schemaName) {
                    dataVal = " ";
                }
            }

            dataForm[t] = dataVal;
        });

        // only make below objects when they're not existed
        let dynamicItemFields = this.state.dynamicItemFields ? this.state.dynamicItemFields : {};
        let showDynamicItemFields = this.state.showDynamicItemFields ? this.state.showDynamicItemFields : {};
        if (!this.state.dynamicItemFields && !this.state.showDynamicItemFields) {
            Object.keys(this.props.v21CompatibilityFeature).map((t) => {
                dynamicItemFields[t] = " ";
                showDynamicItemFields[t] = "hidden";
            });
        }

        let lookupOfItemFields = this.state.lookupOfItemFields;
        let itemNoLookupOnPopup = this.state.itemNoLookupOnPopup;

        this.setState({
            stagingForm: {
                ...dataForm,
                v21SchemaDiscrim2: this.props.schemaDiscrim2
            },
            dynamicItemFields: { ...dynamicItemFields },
            showDynamicItemFields: { ...showDynamicItemFields },
            lookupOfItemFields: (lookupOfItemFields ? lookupOfItemFields : { ...dataForm }),
            itemNoLookupOnPopup: (itemNoLookupOnPopup ? itemNoLookupOnPopup : { ...dataForm }),
            isFormDirty: _isFormDirty || this.props.isFormDirty
        });

        if (!this.state.sAllItemFields && this.props.v21CompatibilityFeature) {
            let fields = {};
            Object.keys(this.props.v21CompatibilityFeature).map((t) => {
                fields[t] = " ";
            });

            this.setState({
                sAllItemFields: fields,
                pageMaxlengthInput: fields,
                pageMaxlengthInputForSsdis: fields
            });
        };

        return false;
    }

    saveV21CompatibilityFeature = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        const formItems = this.state.sAllItemFields;
        Object.keys(formItems).map((t) => {
            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let stagingFormData = this.state.stagingForm;
            stagingFormData.cid = this.props.selectedMedicalRecord.cid;

            stagingFormData.schemaId = this.state.schemaName;
            stagingFormData.ajccId = this.state.ajccId;

            stagingFormData.v21SchemaId = this.state.schemaName;
            stagingFormData.v21AjccId = this.state.ajccId;

            this.props.saveV21CompatibilityFeature(stagingFormData);

            /*---
            // Add 2022/04/25 - START
            if (this.state.schemaId != this.props.schemaId || this.state.ajccId != this.props.ajccId) {
                this.props.reflectFnc(this.state.ajccId, this.state.schemaId, this.state.schemaName);
            }
            // Add 2022/04/25 - END
            ---*/

            // clear this flag to update the status of page after saved!
            this.setState({ determinedSchemaId: false, savingData: true, isFormDirty: false });

            return false;
        }
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        if (event.target.getAttribute("data-help-naming") && event.target.getAttribute("data-help-naming") != undefined) {
            var helpTitle = event.target.getAttribute("data-help-title") && event.target.getAttribute("data-help-title") != undefined ? event.target.getAttribute("data-help-title") : "";
            this.setState({
                fieldInFocus: event.target.name,
                fieldItemNbr: event.target.name,//event.target.getAttribute("data-help-id"),
                focusingField: event.target,
                savingData: false,
                ssdiTitle: helpTitle
            });
        } else {
            this.setState({
                fieldInFocus: event.target.name,
                fieldItemNbr: event.target.name,//event.target.getAttribute("data-help-id"),
                focusingField: event.target,
                savingData: false,
                ssdiTitle: null
            });
        }

        //
        let itemNbr = event.target.getAttribute("data-help-id");
        if (itemNbr == null || itemNbr == undefined) {
            itemNbr = "";
        }
        this.props.setItemNbr(itemNbr);

        // 
        if (event.target.name == "v21TnmEdNbr" && !this.state.setFirstLoadingPage) {
            this.props.setChildrenOpening(false);
            this.setState({ setFirstLoadingPage: true });
        }
    }

    helpFocusingField = (field) => {
        this.setState({ fieldInFocus: field });
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isHelpVisible: false });
        return false;
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "";
            let pcy = event.target.getAttribute('data-pcy') ? event.target.getAttribute('data-pcy') : "";
            let cid = this.props.selectedPrimarySummary.cid;

            this.props.checkValidInput(name, value, table, key, -1, flag, cid, pcy);
        } else {
            this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "" } });
        }

        // add onchange event on alpha system
        var stg2018 = this;
        //let schemaIdOnState = stg2018.state.schemaId;
        if (event.target.name === "v21SchemaDiscrim1") {
            stg2018.Determine_Necessary_Discriminators("Yes");
            stg2018.Determine_Schema_ID("Yes");
        } else if (event.target.name === "v21SchemaDiscrim2") {
            stg2018.Determine_Schema_ID("Yes");
        }

        return false;
    }
    // -- END

    async Determine_Necessary_Discriminators(Use_Screen_Values) {
        var Topo_Code = this.state.primTopo;
        var Hist_Code = this.state.primHist;
        if (Topo_Code !== "" && Hist_Code !== "") {
            await axios.post(this.props.clientgroup + "/DetermineNecessaryDiscriminatorsV21", {
                "site": Topo_Code,
                "hist": Hist_Code,
                "sex": this.props.selectedPatient.sex,
                "disc1": (this.state.stagingForm.v21SchemaDiscrim1 != null && this.state.stagingForm.v21SchemaDiscrim1 != undefined ? this.state.stagingForm.v21SchemaDiscrim1 : ""),
                "disc2": (this.state.stagingForm.v21SchemaDiscrim2 != null && this.state.stagingForm.v21SchemaDiscrim2 != undefined ? this.state.stagingForm.v21SchemaDiscrim2 : this.props.schemaDiscrim2),
                "dxYear": (this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.diagnosisDateYear : ""),
                "useScreenValue": Use_Screen_Values,
                accessToken: localStorage.getItem('token')
            })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        let schemaId = res.data.commonLookupItem && res.data.commonLookupItem.code && res.data.commonLookupItem.code !== "" ? res.data.commonLookupItem.code : "";//SEERAPI_id
                        let totalRows = res.data.commonLookupItem ? res.data.commonLookupItem.id : 0;//Total_Rows

                        this.setState({
                            fn_disc_schema_id: schemaId != "" ? schemaId : this.state.fn_disc_schema_id,
                        });

                        if (totalRows > 0 && Use_Screen_Values == "No") {
                            this.setState({ fn_disc_enabled: "1" });
                        } else if (totalRows == 0 && Use_Screen_Values == "No") {
                            this.setState({ fn_disc_enabled: "" });
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }
    }

    async Determine_Schema_ID(Use_Screen_Values) {
        var Topo_Code = this.state.primTopo;
        var Hist_Code = this.state.primHist;

        var _this = this;

        var schemaDiscrim1 = this.state.stagingForm.v21SchemaDiscrim1 != null && this.state.stagingForm.v21SchemaDiscrim1 != undefined ? this.state.stagingForm.v21SchemaDiscrim1 : "";
        var schemaDiscrim2 = this.state.stagingForm.v21SchemaDiscrim2 != null && this.state.stagingForm.v21SchemaDiscrim2 != undefined ? this.state.stagingForm.v21SchemaDiscrim2 : this.props.schemaDiscrim2;

        if (Topo_Code !== "" && Hist_Code !== "") {
            await axios.post(this.props.clientgroup + "/DetermineSchemaIdV21", {
                "site": Topo_Code,
                "hist": Hist_Code,
                "sex": this.props.selectedPatient.sex,
                "ageDx": this.props.selectedMedicalRecord.ageDx,
                "disc1": schemaDiscrim1,
                "disc2": schemaDiscrim2,
                "dxYear": (this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.diagnosisDateYear : ""),
                "useScreenValue": Use_Screen_Values,
                accessToken: localStorage.getItem('token')
            })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        let schemaId = res.data.commonLookupItem && res.data.commonLookupItem.code && res.data.commonLookupItem.code !== "" ? res.data.commonLookupItem.code : "";//Code = SeerapiId
                        let schemaName = res.data.commonLookupItem ? res.data.commonLookupItem.name : "";//Name = SchemaName
                        let totalRows = res.data.commonLookupItem ? res.data.commonLookupItem.id : 0;//Total_Rows

                        var schemaIdDisplaying = schemaId;
                        if (totalRows > 1) {
                            schemaIdDisplaying = "Discriminator Check";
                        }

                        let ajccId = res.data.commonLookupItem ? res.data.commonLookupItem.ajccId : "";
                        let ajccDiseaseId = res.data.commonLookupItem ? res.data.commonLookupItem.ajccDiseaseId : "";

                        //
                        var _schemaId = _this.state.schemaId ? _this.state.schemaId : null;
                        var _ajccId = _this.state.ajccId ? _this.state.ajccId : null;

                        this.setState({
                            schemaId: schemaId,
                            schemaIdDisplaying: "Schema ID: " + schemaIdDisplaying,
                            schemaName: schemaName,
                            fn_schema_id: schemaId,
                            ajccId: ajccId,
                            ajccDiseaseId: ajccDiseaseId,
                            isFormDirty: this.state.isFormDirty || (_schemaId && _schemaId != schemaId) || (_ajccId && _ajccId != ajccId)
                        });

                        var stg2018 = this;

                        axios.post(this.props.clientgroup + "/GetSsdiItemListsV21", { schemaId: schemaId, accessToken: localStorage.getItem('token') })
                            .then(res => {
                                //update new accessToken again
                                var newAccessToken = res && res.data ? res.data.accessToken : null;
                                if (newAccessToken) {
                                    localStorage.setItem('token', newAccessToken);
                                }

                                if (!res.data.error) {
                                    let ssdiItemLists = res.data.commonLookupList;
                                    if (ssdiItemLists != null && ssdiItemLists.length > 0) {
                                        // first hide all fields...
                                        let dif = this.state.dynamicItemFields;
                                        let sdi = this.state.showDynamicItemFields;
                                        let loi = this.state.lookupOfItemFields;
                                        let iNo = this.state.itemNoLookupOnPopup;

                                        ssdiItemLists.map((t, index) => {
                                            if (!ssdiItemLists[index] || !ssdiItemLists[index]["order"]) return false;
                                            // then show it by order/name
                                            try {
                                                dif["v21CsSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["description"];
                                            } catch (exps) {
                                            }
                                            try {
                                                loi["v21CsSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["name"];
                                            } catch (exps) {
                                            }
                                            try {
                                                iNo["v21CsSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["code"];
                                            } catch (exps) {
                                            }
                                            try {
                                                sdi["v21CsSsf" + ssdiItemLists[index]["order"]] = "";
                                            } catch (exps) {
                                            }
                                        });

                                        var startFromIndex = 1;
                                        if (schemaIdDisplaying.indexOf("Discriminator Check") != -1) {
                                            startFromIndex = 1;
                                        } else {
                                            startFromIndex = ssdiItemLists.length + 1;
                                        }

                                        for (var idx = startFromIndex; idx <= 25; idx++) {
                                            try {
                                                dif["v21CsSsf" + idx] && dif["v21CsSsf" + idx] != " " ? "" : dif["v21CsSsf" + idx] = "";//label
                                            } catch (exps) { }

                                            try {
                                                sdi["v21CsSsf" + idx] ? sdi["v21CsSsf" + idx] = "hidden" : "";//show or hide field
                                            } catch (exps) { }
                                        }

                                        setTimeout(function () {
                                            stg2018.setState({
                                                dynamicItemFields: dif,
                                                showDynamicItemFields: sdi,
                                                lookupOfItemFields: loi,
                                                itemNoLookupOnPopup: iNo,
                                            });
                                            return false;
                                        }, 500);
                                    } else {
                                        // when initial is hidden all fields
                                        if (stg2018.state.dynamicItemFields && stg2018.props.v21CompatibilityFeature) {
                                            let fields = {};
                                            Object.keys(stg2018.props.v21CompatibilityFeature).map((t) => {
                                                fields[t] = " ";
                                            });

                                            stg2018.setState({ dynamicItemFields: { ...fields } });
                                        };
                                        if (stg2018.state.showDynamicItemFields && stg2018.props.v21CompatibilityFeature) {
                                            let fields = {};
                                            Object.keys(stg2018.props.v21CompatibilityFeature).map((t) => {
                                                fields[t] = "hidden";
                                            });

                                            stg2018.setState({
                                                showDynamicItemFields: { ...fields },
                                            });
                                        };
                                    }
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                                /*
                                var btnLogout = document.getElementById("logoutButtonArea");
                                if (btnLogout) {
                                    btnLogout.querySelector("a").click();
                                }
                                */
                                //@TODO:...

                                return false;
                            });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }
    }

    handleFormChange = (event) => {
        // SSDI fields
        var fid = event.target.id;
        if (fid && fid.indexOf("V21_SSDI_USER_") != -1) {
            var index = fid.replace("V21_SSDI_USER_", "");
            var fieldName = this.getFieldNameByIndex(index);
            this.setState({
                stagingForm: {
                    ...this.state.stagingForm,
                    [fieldName]: event.target.value,
                    [event.target.name]: event.target.value,
                    ["ssdiHasChanged"]: "1"
                },
                isFormDirty: true
            });

            return false;
        }

        if (event.target2) {
            var fname = event.target.name;
            if (fname && fname.indexOf("v21CsSsf") != -1) {
                var index = fname.replace("v21CsSsf", "");
                var fieldName = this.getFieldNameByIndex(index);
                this.setState({
                    stagingForm: {
                        ...this.state.stagingForm,
                        [fieldName]: event.target.value,
                        [event.target.name]: event.target.value,
                        [event.target2.name]: event.target2.value,
                        ["ssdiHasChanged"]: "1"
                    },
                    isFormDirty: true
                });

                return false;
            }

            this.setState({ stagingForm: { ...this.state.stagingForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            var fname = event.target.name;
            if (fname && fname.indexOf("v21CsSsf") != -1) {
                var index = fname.replace("v21CsSsf", "");
                var fieldName = this.getFieldNameByIndex(index);
                this.setState({
                    stagingForm: {
                        ...this.state.stagingForm,
                        [fieldName]: event.target.value,
                        [event.target.name]: event.target.value,
                        ["ssdiHasChanged"]: "1"
                    },
                    isFormDirty: true
                });

                return false;
            }

            this.setState({ stagingForm: { ...this.state.stagingForm, [event.target.name]: event.target.value }, isFormDirty: true });
        }
    }

    render() {
        const stagingMaintenance = this.state.stagingForm && !this.props.isPatientLoading && this.state.loadedPage ?
            (
                <div className="container-fluid">
                    <div className="row hidden">
                        <div className="col-md-1 helpInfo">
                            <button type="button" onClick={this.getHelp}>
                                <img src="https://crstar.ers-can.com/images/$sys_question.png.a5image" />
                            </button>
                        </div>
                    </div>

                    {
                        this.state.isHelpVisible && <HelpV21 fieldInFocus={this.state.fieldInFocus}
                            focusingField={this.state.focusingField}
                            fieldItemNbr={this.state.fieldItemNbr} closeCode={this.closeCodeDialog}
                            ssdiTitle={this.state.ssdiTitle} />
                    }

                    <form onSubmit={this.saveV21CompatibilityFeature} id="v21CompatibilityFeatureForm" className="_DialogForm0 TabParentPage" autoComplete="off">
                        <div>
                            <div className="row" id="v21CompatibilityFeatureInputArea">
                                <div className="col-sm-12">
                                    <div style={{ border: "1px solid #eee", marginBottom: "15px" }}>
                                        <Nav tabs tabIndex={0} autoFocus name="navTabOnV21CompatibilityFeatureForm">
                                            <NavLink className={classnames({ active: this.state.activeTab === "1" })} onClick={(e) => { this.toggleTab(e, "1") }} href="#" id="linkOfV21CompatibilityFeatureTab1">
                                                Staging
                                            </NavLink>
                                            <NavLink className={classnames({ active: this.state.activeTab === "2" })} onClick={(e) => { this.toggleTab(e, "2") }} href="#" id="linkOfV21CompatibilityFeatureTab2">
                                                Site-Specific Data Items
                                            </NavLink>
                                        </Nav>
                                        <TabContent activeTab={this.state.activeTab}>
                                            <TabPane tabId="1">
                                                <div className="frameInner">
                                                    <div className="header">Staging AJCC ID: {this.state.ajccId}</div>
                                                    <div className="row tab1">
                                                        <div className="col-sm-3">
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="TNM Edition Nbr" field="v21TnmEdNbr" lineStyle="oneLine onTopFrame"
                                                                value={this.state.stagingForm.v21TnmEdNbr}
                                                                dataSource="Global_Dir_Mv" aliasNo="5026"
                                                                titleDialog="Tnm Ed Nbr" labelWidth="140px" width="35px"
                                                                inValid={this.state.sAllItemFields["v21TnmEdNbr"]} itemNbr="5026"
                                                                lostfocused={this.handleLostFocus} autoFocus
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21TnmEdNbr"] : ""}
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="SEER Sum Stage" field="v21GenStage" lineStyle="oneLine"
                                                                value={this.state.stagingForm.v21GenStage}
                                                                dataSource="Schema_SSDI_Codes" aliasNo="5030"
                                                                titleDialog="General Stage SS2018" labelWidth="110px" width="30px"
                                                                inValid={this.state.sAllItemFields["v21GenStage"]} itemNbr="5030"
                                                                flag={this.state.schemaId}
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21GenStage"] : ""}
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Bone" field="v21CsMetsDxBone" lineStyle="oneLine"
                                                                value={this.state.stagingForm.v21CsMetsDxBone}
                                                                dataSource="Global_Dir_Mv" aliasNo="5034"
                                                                titleDialog="Cs Mets Dx Bone"
                                                                inValid={this.state.sAllItemFields["v21CsMetsDxBone"]} itemNbr="5034"
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21CsMetsDxBone"] : ""}
                                                                labelWidth="55px" width="25px"
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Brain" field="v21CsMetsDxBrain" lineStyle="oneLine"
                                                                value={this.state.stagingForm.v21CsMetsDxBrain}
                                                                dataSource="Global_Dir_Mv" aliasNo="5035"
                                                                titleDialog="Cs Mets Dx Brain"
                                                                inValid={this.state.sAllItemFields["v21CsMetsDxBrain"]} itemNbr="5035"
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21CsMetsDxBrain"] : ""}
                                                                labelWidth="45px" width="25px"
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Liver" field="v21CsMetsDxLiver" lineStyle="oneLine"
                                                                value={this.state.stagingForm.v21CsMetsDxLiver}
                                                                dataSource="Global_Dir_Mv" aliasNo="5037"
                                                                titleDialog="Cs Mets Dx Liver"
                                                                inValid={this.state.sAllItemFields["v21CsMetsDxLiver"]} itemNbr="5037"
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21CsMetsDxLiver"] : ""}
                                                                labelWidth="55px" width="25px"
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Lung" field="v21CsMetsDxLung" lineStyle="oneLine"
                                                                value={this.state.stagingForm.v21CsMetsDxLung}
                                                                dataSource="Global_Dir_Mv" aliasNo="5038"
                                                                titleDialog="Cs Mets Dx Lung"
                                                                inValid={this.state.sAllItemFields["v21CsMetsDxLung"]} itemNbr="5038"
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21CsMetsDxLung"] : ""}
                                                                labelWidth="45px" width="25px"
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Dist Ln" field="v21MetsDxDistantLn" lineStyle="oneLine"
                                                                value={this.state.stagingForm.v21MetsDxDistantLn}
                                                                dataSource="Global_Dir_Mv" aliasNo="5036"
                                                                titleDialog="Mets Dx Distant Ln"
                                                                inValid={this.state.sAllItemFields["v21MetsDxDistantLn"]} itemNbr="5036"
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21MetsDxDistantLn"] : ""}
                                                                labelWidth="55px" width="25px"
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Other Mets" field="v21MetsDxOther" lineStyle="oneLine"
                                                                value={this.state.stagingForm.v21MetsDxOther}
                                                                dataSource="Global_Dir_Mv" aliasNo="5039"
                                                                titleDialog="Mets Dx Other"
                                                                inValid={this.state.sAllItemFields["v21MetsDxOther"]} itemNbr="5039"
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21MetsDxOther"] : ""}
                                                                labelWidth="45px" width="25px"
                                                            />
                                                            <Input type="input" focused={this.handleOnFocus}
                                                                changed={this.handleFormChange}
                                                                labelText="Size Summary" field="v21TumorSizeSummary" lineStyle="oneLine"
                                                                value={this.state.stagingForm.v21TumorSizeSummary}
                                                                labelWidth="100px" width="30px"
                                                                inValid={this.state.sAllItemFields["v21TumorSizeSummary"]} itemNbr="5029"
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21TumorSizeSummary"] : ""}
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="EOD Prim Tumor" field="v21EodPrimTumor" lineStyle="oneLine"
                                                                value={this.state.stagingForm.v21EodPrimTumor}
                                                                dataSource="Schema_SSDI_Codes" aliasNo="5031"
                                                                titleDialog="EOD Primary Tumor (size)" labelWidth="110px" width="45px"
                                                                inValid={this.state.sAllItemFields["v21EodPrimTumor"]} itemNbr="5031"
                                                                flag={this.state.schemaId}
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21EodPrimTumor"] : ""}
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="EOD Reg Nodes" field="v21EodRegNodes" lineStyle="oneLine"
                                                                value={this.state.stagingForm.v21EodRegNodes}
                                                                dataSource="Schema_SSDI_Codes" aliasNo="5032"
                                                                titleDialog="EOD Regional Nodes" labelWidth="110px" width="45px"
                                                                inValid={this.state.sAllItemFields["v21EodRegNodes"]} itemNbr="5032"
                                                                flag={this.state.schemaId}
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21EodRegNodes"] : ""}
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="EOD Mets" field="v21EodMets" lineStyle="oneLine"
                                                                value={this.state.stagingForm.v21EodMets}
                                                                dataSource="Schema_SSDI_Codes" aliasNo="5033"
                                                                titleDialog="EOD Mets" labelWidth="110px" width="35px"
                                                                inValid={this.state.sAllItemFields["v21EodMets"]} itemNbr="5033"
                                                                flag={this.state.schemaId}
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21EodMets"] : ""}
                                                            />
                                                        </div>
                                                        <div className="col-sm-4" style={{ minWidth: "275px", padding: "0px" }}>
                                                            <fieldset>
                                                                <legend>AJCC Clinical</legend>
                                                                <div>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="Grade" field="v21GradeClinical" lineStyle="oneLine"
                                                                        value={this.state.stagingForm.v21GradeClinical}
                                                                        dataSource="Schema_SSDI_Codes" aliasNo="5040"
                                                                        titleDialog="Grade Clinical" labelWidth="50px" width="30px"
                                                                        inValid={this.state.sAllItemFields["v21GradeClinical"]} itemNbr="5040"
                                                                        flag={this.state.schemaId}
                                                                        lostfocused={this.handleLostFocus}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21GradeClinical"] : ""}
                                                                    />
                                                                    <Input type="label" labelText="" labelWidth="200px" width="45px" value="Suffix" lineStyle="lblSuffixCls" />
                                                                    <div className="" style={{ display: "flex" }}>
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                            labelText="c T" field="v21AjccClinT" lineStyle="oneLine"
                                                                            value={this.state.stagingForm.v21AjccClinT}
                                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="T" pcy="C" pc2="C"
                                                                            topoCode={this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}
                                                                            histCode={this.props.selectedMedicalRecord.HistCode ? this.props.selectedMedicalRecord.HistCode : this.props.selectedMedicalRecord.histCode}
                                                                            titleDialog="AJCC TNM Clinical T" labelWidth="30px" width="120px"
                                                                            inValid={this.state.sAllItemFields["v21AjccClinT"]} itemNbr="5001"
                                                                            lostfocused={this.handleLostFocus}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccClinT"] : ""}
                                                                            flag={this.state.ajccId}
                                                                        />
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                            labelText="" field="v21AjccClinTSuffix" lineStyle="oneLine"
                                                                            value={this.state.stagingForm.v21AjccClinTSuffix}
                                                                            dataSource="Global_Dir_Mv" aliasNo="5004"
                                                                            titleDialog="AJCC TNM Clin T Suffix" labelWidth="0px" width="60px"
                                                                            inValid={this.state.sAllItemFields["v21AjccClinTSuffix"]} itemNbr="5004"
                                                                            lostfocused={this.handleLostFocus}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccClinTSuffix"] : ""}
                                                                        />
                                                                    </div>
                                                                    <div className="" style={{ display: "flex" }}>
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                            labelText="c N" field="v21AjccClinN" lineStyle="oneLine"
                                                                            value={this.state.stagingForm.v21AjccClinN}
                                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="N" pcy="C" pc2="C"
                                                                            topoCode={this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}
                                                                            histCode={this.props.selectedMedicalRecord.HistCode ? this.props.selectedMedicalRecord.HistCode : this.props.selectedMedicalRecord.histCode}
                                                                            titleDialog="AJCC TNM Clinical N" labelWidth="30px" width="120px"
                                                                            inValid={this.state.sAllItemFields["v21AjccClinN"]} itemNbr="5002"
                                                                            lostfocused={this.handleLostFocus}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccClinN"] : ""}
                                                                            flag={this.state.ajccId}
                                                                        />
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                            labelText="" field="v21AjccClinNSuffix" lineStyle="oneLine"
                                                                            value={this.state.stagingForm.v21AjccClinNSuffix}
                                                                            dataSource="Global_Dir_Mv" aliasNo="5005"
                                                                            titleDialog="AJCC TNM Clin N Suffix" labelWidth="0px" width="60px"
                                                                            inValid={this.state.sAllItemFields["v21AjccClinNSuffix"]} itemNbr="5005"
                                                                            lostfocused={this.handleLostFocus}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccClinNSuffix"] : ""}
                                                                        />
                                                                    </div>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="c M" field="v21AjccClinM" lineStyle="oneLine"
                                                                        value={this.state.stagingForm.v21AjccClinM}
                                                                        dataSource="Tnm_Lookup8th_Mv" aliasNo="M" pcy="C" pc2="C"
                                                                        topoCode={this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}
                                                                        histCode={this.props.selectedMedicalRecord.HistCode ? this.props.selectedMedicalRecord.HistCode : this.props.selectedMedicalRecord.histCode}
                                                                        titleDialog="AJCC TNM Clinical M" labelWidth="30px" width="120px"
                                                                        inValid={this.state.sAllItemFields["v21AjccClinM"]} itemNbr="5003"
                                                                        lostfocused={this.handleLostFocus}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccClinM"] : ""}
                                                                        flag={this.state.ajccId}
                                                                    />
                                                                    <Input type="codeTnmHtml" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="c Group" field="v21AjccClinStageGroup" lineStyle="oneLine"
                                                                        value={this.state.stagingForm.v21AjccClinStageGroup}
                                                                        dataSource="Tnm_Html" aliasNo="G" pcy="C" //pc2="C"
                                                                        titleDialog="AJCC TNM Clinical Stage Group" labelWidth="30px" width="120px"
                                                                        inValid={this.state.sAllItemFields["v21AjccClinStageGroup"]} itemNbr="5006"
                                                                        lostfocused={this.handleLostFocus}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccClinStageGroup"] : ""}
                                                                        flag={this.state.ajccDiseaseId}
                                                                    />
                                                                </div>
                                                                <br />
                                                                <div>
                                                                    <div style={{ display: "flex" }}>
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                            labelText="yc T" field="v21AjccPostClinT" lineStyle="oneLine"
                                                                            value={this.state.stagingForm.v21AjccPostClinT}
                                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="T" pcy="YC" pc2="YC"
                                                                            topoCode={this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}
                                                                            histCode={this.props.selectedMedicalRecord.HistCode ? this.props.selectedMedicalRecord.HistCode : this.props.selectedMedicalRecord.histCode}
                                                                            titleDialog="AJCC TNM Post Therapy Clinical T" labelWidth="30px" width="120px"
                                                                            inValid={this.state.sAllItemFields["v21AjccPostClinT"]} itemNbr="5019"
                                                                            lostfocused={this.handleLostFocus}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccPostClinT"] : ""}
                                                                            flag={this.state.ajccId}
                                                                        />
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                            labelText="" field="v21AjccPostClinTSuffix" lineStyle="oneLine"
                                                                            value={this.state.stagingForm.v21AjccPostClinTSuffix}
                                                                            dataSource="Global_Dir_Mv" aliasNo="5022"
                                                                            titleDialog="AJCC TNM Post Therapy Clinical T Suffix" labelWidth="0px" width="60px"
                                                                            inValid={this.state.sAllItemFields["v21AjccPostClinTSuffix"]} itemNbr="5022"
                                                                            lostfocused={this.handleLostFocus}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccPostClinTSuffix"] : ""}
                                                                        />
                                                                    </div>
                                                                    <div style={{ display: "flex" }}>
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                            labelText="yc N" field="v21AjccPostClinN" lineStyle="oneLine"
                                                                            value={this.state.stagingForm.v21AjccPostClinN}
                                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="N" pcy="YC" pc2="YC"
                                                                            topoCode={this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}
                                                                            histCode={this.props.selectedMedicalRecord.HistCode ? this.props.selectedMedicalRecord.HistCode : this.props.selectedMedicalRecord.histCode}
                                                                            titleDialog="AJCC TNM Post Therapy Clinical N" labelWidth="30px" width="120px"
                                                                            inValid={this.state.sAllItemFields["v21AjccPostClinN"]} itemNbr="5020"
                                                                            lostfocused={this.handleLostFocus}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccPostClinN"] : ""}
                                                                            flag={this.state.ajccId}
                                                                        />
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                            labelText="" field="v21AjccPostClinNSuffix" lineStyle="oneLine"
                                                                            value={this.state.stagingForm.v21AjccPostClinNSuffix}
                                                                            dataSource="Global_Dir_Mv" aliasNo="5023"
                                                                            titleDialog="AJCC TNM Post Therapy Clinical N Suffix" labelWidth="0px" width="60px"
                                                                            inValid={this.state.sAllItemFields["v21AjccPostClinNSuffix"]} itemNbr="5023"
                                                                            lostfocused={this.handleLostFocus}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccPostClinNSuffix"] : ""}
                                                                        />
                                                                    </div>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="yc M" field="v21AjccPostClinM" lineStyle="oneLine"
                                                                        value={this.state.stagingForm.v21AjccPostClinM}
                                                                        dataSource="Tnm_Lookup8th_Mv" aliasNo="M" pcy="YC" pc2="YC"
                                                                        topoCode={this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}
                                                                        histCode={this.props.selectedMedicalRecord.HistCode ? this.props.selectedMedicalRecord.HistCode : this.props.selectedMedicalRecord.histCode}
                                                                        titleDialog="AJCC TNM Post Therapy Clinical M" labelWidth="30px" width="120px"
                                                                        inValid={this.state.sAllItemFields["v21AjccPostClinM"]} itemNbr="5021"
                                                                        lostfocused={this.handleLostFocus}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccPostClinM"] : ""}
                                                                        flag={this.state.ajccId}
                                                                    />
                                                                    <Input type="codeTnmHtml" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="yc Group" field="v21AjccPostClinStageGroup" lineStyle="oneLine"
                                                                        value={this.state.stagingForm.v21AjccPostClinStageGroup}
                                                                        dataSource="Tnm_Html" aliasNo="G" pcy="YC" //pc2="YC"
                                                                        titleDialog="AJCC TNM Post Therapy Clinical Stage Group" labelWidth="30px" width="120px"
                                                                        inValid={this.state.sAllItemFields["v21AjccPostClinStageGroup"]} itemNbr="5024"
                                                                        lostfocused={this.handleLostFocus}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccPostClinStageGroup"] : ""}
                                                                        flag={this.state.ajccDiseaseId}
                                                                    />
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-sm-4" style={{ minWidth: "275px", padding: "0px" }}>
                                                            <fieldset>
                                                                <legend>AJCC Pathological</legend>
                                                                <div>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="Grade" field="v21GradePathological" lineStyle="oneLine"
                                                                        value={this.state.stagingForm.v21GradePathological}
                                                                        dataSource="Schema_SSDI_Codes" aliasNo="5041"
                                                                        titleDialog="Grade Pathological" labelWidth="50px" width="30px"
                                                                        inValid={this.state.sAllItemFields["v21GradePathological"]} itemNbr="5041"
                                                                        flag={this.state.schemaId}
                                                                        lostfocused={this.handleLostFocus}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21GradePathological"] : ""}
                                                                    />
                                                                    <Input type="label" labelText="" labelWidth="200px" width="45px" value="Suffix" lineStyle="lblSuffixCls" />
                                                                    <div style={{ display: "flex" }}>
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                            labelText="p T" field="v21AjccPathT" lineStyle="oneLine"
                                                                            value={this.state.stagingForm.v21AjccPathT}
                                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="T" pcy="P" pc2="P"
                                                                            topoCode={this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}
                                                                            histCode={this.props.selectedMedicalRecord.HistCode ? this.props.selectedMedicalRecord.HistCode : this.props.selectedMedicalRecord.histCode}
                                                                            titleDialog="AJCC TNM Path T" labelWidth="30px" width="120px"
                                                                            inValid={this.state.sAllItemFields["v21AjccPathT"]} itemNbr="5007"
                                                                            lostfocused={this.handleLostFocus}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccPathT"] : ""}
                                                                            flag={this.state.ajccId}
                                                                        />
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                            labelText="" field="v21AjccPathTSuffix" lineStyle="oneLine"
                                                                            value={this.state.stagingForm.v21AjccPathTSuffix}
                                                                            dataSource="Global_Dir_Mv" aliasNo="4154"
                                                                            titleDialog="AJCC TNM Path T Suffix" labelWidth="0px" width="60px"
                                                                            inValid={this.state.sAllItemFields["v21AjccPathTSuffix"]} itemNbr="5010"
                                                                            lostfocused={this.handleLostFocus}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccPathTSuffix"] : ""}
                                                                        />
                                                                    </div>
                                                                    <div style={{ display: "flex" }}>
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                            labelText="p N" field="v21AjccPathN" lineStyle="oneLine"
                                                                            value={this.state.stagingForm.v21AjccPathN}
                                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="N" pcy="P" pc2="P"
                                                                            topoCode={this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}
                                                                            histCode={this.props.selectedMedicalRecord.HistCode ? this.props.selectedMedicalRecord.HistCode : this.props.selectedMedicalRecord.histCode}
                                                                            titleDialog="AJCC TNM Path N" labelWidth="30px" width="120px"
                                                                            inValid={this.state.sAllItemFields["v21AjccPathN"]} itemNbr="5008"
                                                                            lostfocused={this.handleLostFocus}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccPathN"] : ""}
                                                                            flag={this.state.ajccId}
                                                                        />
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                            labelText="" field="v21AjccPathNSuffix" lineStyle="oneLine"
                                                                            value={this.state.stagingForm.v21AjccPathNSuffix}
                                                                            dataSource="Global_Dir_Mv" aliasNo="4156"
                                                                            titleDialog="AJCC TNM Path N Suffix" labelWidth="0px" width="60px"
                                                                            inValid={this.state.sAllItemFields["v21AjccPathNSuffix"]} itemNbr="5011"
                                                                            lostfocused={this.handleLostFocus}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccPathNSuffix"] : ""}
                                                                        />
                                                                    </div>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="p M" field="v21AjccPathM" lineStyle="oneLine"
                                                                        value={this.state.stagingForm.v21AjccPathM}
                                                                        dataSource="Tnm_Lookup8th_Mv" aliasNo="M" pcy="P" pc2="P"
                                                                        topoCode={this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}
                                                                        histCode={this.props.selectedMedicalRecord.HistCode ? this.props.selectedMedicalRecord.HistCode : this.props.selectedMedicalRecord.histCode}
                                                                        titleDialog="AJCC TNM Path M" labelWidth="30px" width="120px"
                                                                        inValid={this.state.sAllItemFields["v21AjccPathM"]} itemNbr="5009"
                                                                        lostfocused={this.handleLostFocus}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccPathM"] : ""}
                                                                        flag={this.state.ajccId}
                                                                    />
                                                                    <Input type="codeTnmHtml" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="p Group" field="v21AjccPathStageGroup" lineStyle="oneLine"
                                                                        value={this.state.stagingForm.v21AjccPathStageGroup}
                                                                        dataSource="Tnm_Html" aliasNo="G" pcy="P" //pc2="P"
                                                                        titleDialog="AJCC TNM Path Stage Group" labelWidth="30px" width="120px"
                                                                        inValid={this.state.sAllItemFields["v21AjccPathStageGroup"]} itemNbr="5012"
                                                                        lostfocused={this.handleLostFocus}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccPathStageGroup"] : ""}
                                                                        flag={this.state.ajccDiseaseId}
                                                                    />
                                                                </div>
                                                                <br />
                                                                <div>
                                                                    <div style={{ display: "flex" }}>
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                            labelText="yp T" field="v21AjccPostT" lineStyle="oneLine"
                                                                            value={this.state.stagingForm.v21AjccPostT}
                                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="T" pcy="Y" pc2="Y"
                                                                            topoCode={this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}
                                                                            histCode={this.props.selectedMedicalRecord.HistCode ? this.props.selectedMedicalRecord.HistCode : this.props.selectedMedicalRecord.histCode}
                                                                            titleDialog="AJCC TNM Post Therapy Pathological T" labelWidth="30px" width="120px"
                                                                            inValid={this.state.sAllItemFields["v21AjccPostT"]} itemNbr="5013"
                                                                            lostfocused={this.handleLostFocus}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccPostT"] : ""}
                                                                            flag={this.state.ajccId}
                                                                        />
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                            labelText="" field="v21AjccPostTSuffix" lineStyle="oneLine"
                                                                            value={this.state.stagingForm.v21AjccPostTSuffix}
                                                                            dataSource="Global_Dir_Mv" aliasNo="5016"
                                                                            titleDialog="AJCC TNM Post Therapy Pathological T Suffix" labelWidth="0px" width="60px"
                                                                            inValid={this.state.sAllItemFields["v21AjccPostTSuffix"]} itemNbr="5016"
                                                                            lostfocused={this.handleLostFocus}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccPostTSuffix"] : ""}
                                                                        />
                                                                    </div>
                                                                    <div style={{ display: "flex" }}>
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                            labelText="yp N" field="v21AjccPostN" lineStyle="oneLine"
                                                                            value={this.state.stagingForm.v21AjccPostN}
                                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="N" pcy="Y" pc2="Y"
                                                                            topoCode={this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}
                                                                            histCode={this.props.selectedMedicalRecord.HistCode ? this.props.selectedMedicalRecord.HistCode : this.props.selectedMedicalRecord.histCode}
                                                                            titleDialog="AJCC TNM Post Therapy Pathological N" labelWidth="30px" width="120px"
                                                                            inValid={this.state.sAllItemFields["v21AjccPostN"]} itemNbr="5014"
                                                                            lostfocused={this.handleLostFocus}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccPostN"] : ""}
                                                                            flag={this.state.ajccId}
                                                                        />
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                            labelText="" field="v21AjccPostNSuffix" lineStyle="oneLine"
                                                                            value={this.state.stagingForm.v21AjccPostNSuffix}
                                                                            dataSource="Global_Dir_Mv" aliasNo="5017"
                                                                            titleDialog="AJCC TNM Post Therapy Pathological N Suffix" labelWidth="0px" width="60px"
                                                                            inValid={this.state.sAllItemFields["v21AjccPostNSuffix"]} itemNbr="5017"
                                                                            lostfocused={this.handleLostFocus}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccPostNSuffix"] : ""}
                                                                        />
                                                                    </div>
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="yp M" field="v21AjccPostM" lineStyle="oneLine"
                                                                        value={this.state.stagingForm.v21AjccPostM}
                                                                        dataSource="Tnm_Lookup8th_Mv" aliasNo="M" pcy="Y" pc2="Y"
                                                                        topoCode={this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}
                                                                        histCode={this.props.selectedMedicalRecord.HistCode ? this.props.selectedMedicalRecord.HistCode : this.props.selectedMedicalRecord.histCode}
                                                                        titleDialog="AJCC TNM Post Therapy Pathological M" labelWidth="30px" width="120px"
                                                                        inValid={this.state.sAllItemFields["v21AjccPostM"]} itemNbr="5015"
                                                                        lostfocused={this.handleLostFocus}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccPostM"] : ""}
                                                                        flag={this.state.ajccId}
                                                                    />
                                                                    <Input type="codeTnmHtml" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="yp Group" field="v21AjccPostStageGroup" lineStyle="oneLine"
                                                                        value={this.state.stagingForm.v21AjccPostStageGroup}
                                                                        dataSource="Tnm_Html" aliasNo="G" pcy="Y" //pc2="Y"
                                                                        titleDialog="AJCC TNM Post Therapy Pathological Stage Group" labelWidth="30px" width="120px"
                                                                        inValid={this.state.sAllItemFields["v21AjccPostStageGroup"]} itemNbr="5018"
                                                                        lostfocused={this.handleLostFocus}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21AjccPostStageGroup"] : ""}
                                                                        flag={this.state.ajccDiseaseId}
                                                                    />
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <div className="frameInner">
                                                    <div className="header">Schema ID: {this.state.schemaName}</div>
                                                    <div className="row tab2">
                                                        <div className="col-sm-6">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div style={{ display: (this.state.fn_disc_enabled !== "1" ? "" : "none") }}>
                                                                        <p>(discriminators appear if needed)</p>
                                                                    </div>
                                                                    <div style={{ display: (this.state.fn_disc_enabled === "1" ? "" : "none") }}>
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                            labelText="Schema Discriminator 1" field="v21SchemaDiscrim1" lineStyle="oneLine onTopFrame"
                                                                            value={this.state.stagingForm.v21SchemaDiscrim1}
                                                                            dataSource="SSDI_Codes_Popup" aliasNo="5042"//Staging:4167
                                                                            titleDialog="Schema Discriminator 1" labelWidth="300px" width="55px"
                                                                            inValid={this.state.sAllItemFields["v21SchemaDiscrim1"]} itemNbr="5042"
                                                                            flag={this.state.fn_disc_schema_id}
                                                                            lostfocused={this.handleLostFocus}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["v21SchemaDiscrim1"] : ""}
                                                                        />
                                                                        {/*}
                                                                        <Input type="codeSelect" changed={this.handleFormChange}
                                                                            labelText="Schema Discriminator 2" field="v21SchemaDiscrim2" lineStyle="oneLine"
                                                                            value={this.state.stagingForm.v21SchemaDiscrim2}
                                                                            dataSource="SSDI_Codes_Popup" aliasNo="4168"
                                                                            titleDialog="Schema Discriminator 2" labelWidth="300px" width="55px"
                                                                            inValid={this.state.sAllItemFields["v21SchemaDiscrim2"]} itemNbr="4168"
                                                                            flag={this.state.fn_disc_schema_id}
                                                                            lostfocused={this.handleLostFocus}
                                                                        />
                                                                        {*/}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className={this.state.schemaIdDisplaying && this.state.schemaIdDisplaying.indexOf("Discriminator Check") == -1 ? "" : " emptySsdiFieldsCls"}>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf1"] ? this.state.dynamicItemFields["v21CsSsf1"] : "SSDI User 1"}
                                                                            field="v21CsSsf1" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf1"] + " oneLine onTopFrame" : "oneLine onTopFrame"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('1')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf1"] ? this.state.itemNoLookupOnPopup["v21CsSsf1"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf1"] ? this.state.lookupOfItemFields["v21CsSsf1"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf1"] ? this.state.dynamicItemFields["v21CsSsf1"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf1"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_1"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('1')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf2"] ? this.state.dynamicItemFields["v21CsSsf2"] : "SSDI User 2"}
                                                                            field="v21CsSsf2" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf2"] + " oneLine" : "oneLine"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('2')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf2"] ? this.state.itemNoLookupOnPopup["v21CsSsf2"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf2"] ? this.state.lookupOfItemFields["v21CsSsf2"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf2"] ? this.state.dynamicItemFields["v21CsSsf2"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf2"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_2"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('2')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf3"] ? this.state.dynamicItemFields["v21CsSsf3"] : "SSDI User 3"}
                                                                            field="v21CsSsf3" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf3"] + " oneLine" : "oneLine"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('3')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf3"] ? this.state.itemNoLookupOnPopup["v21CsSsf3"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf3"] ? this.state.lookupOfItemFields["v21CsSsf3"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf3"] ? this.state.dynamicItemFields["v21CsSsf3"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf3"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_3"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('3')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf4"] ? this.state.dynamicItemFields["v21CsSsf4"] : "SSDI User 4"}
                                                                            field="v21CsSsf4" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf4"] + " oneLine" : "oneLine"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('4')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf4"] ? this.state.itemNoLookupOnPopup["v21CsSsf4"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf4"] ? this.state.lookupOfItemFields["v21CsSsf4"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf4"] ? this.state.dynamicItemFields["v21CsSsf4"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf4"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_4"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('4')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf5"] ? this.state.dynamicItemFields["v21CsSsf5"] : "SSDI User 5"}
                                                                            field="v21CsSsf5" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf5"] + " oneLine" : "oneLine"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('5')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf5"] ? this.state.itemNoLookupOnPopup["v21CsSsf5"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf5"] ? this.state.lookupOfItemFields["v21CsSsf5"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf5"] ? this.state.dynamicItemFields["v21CsSsf5"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf5"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_5"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('5')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf6"] ? this.state.dynamicItemFields["v21CsSsf6"] : "SSDI User 6"}
                                                                            field="v21CsSsf6" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf6"] + " oneLine" : "oneLine"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('6')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf6"] ? this.state.itemNoLookupOnPopup["v21CsSsf6"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf6"] ? this.state.lookupOfItemFields["v21CsSsf6"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf6"] ? this.state.dynamicItemFields["v21CsSsf6"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf6"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_6"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('6')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf7"] ? this.state.dynamicItemFields["v21CsSsf7"] : "SSDI User 7"}
                                                                            field="v21CsSsf7" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf7"] + " oneLine" : "oneLine"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('7')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf7"] ? this.state.itemNoLookupOnPopup["v21CsSsf7"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf7"] ? this.state.lookupOfItemFields["v21CsSsf7"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf7"] ? this.state.dynamicItemFields["v21CsSsf7"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf7"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_7"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('7')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf8"] ? this.state.dynamicItemFields["v21CsSsf8"] : "SSDI User 8"}
                                                                            field="v21CsSsf8" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf8"] + " oneLine" : "oneLine"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('8')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf8"] ? this.state.itemNoLookupOnPopup["v21CsSsf8"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf8"] ? this.state.lookupOfItemFields["v21CsSsf8"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf8"] ? this.state.dynamicItemFields["v21CsSsf8"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf8"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_8"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('8')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf9"] ? this.state.dynamicItemFields["v21CsSsf9"] : "SSDI User 9"}
                                                                            field="v21CsSsf9" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf9"] + " oneLine onTopFrame" : "oneLine onTopFrame"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('9')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf9"] ? this.state.itemNoLookupOnPopup["v21CsSsf9"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf9"] ? this.state.lookupOfItemFields["v21CsSsf9"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf9"] ? this.state.dynamicItemFields["v21CsSsf9"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf9"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_9"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('9')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf10"] ? this.state.dynamicItemFields["v21CsSsf10"] : "SSDI User 10"}
                                                                            field="v21CsSsf10" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf10"] + " oneLine" : "oneLine"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('10')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf10"] ? this.state.itemNoLookupOnPopup["v21CsSsf10"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf10"] ? this.state.lookupOfItemFields["v21CsSsf10"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf10"] ? this.state.dynamicItemFields["v21CsSsf10"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf10"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_10"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('10')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf11"] ? this.state.dynamicItemFields["v21CsSsf11"] : "SSDI User 11"}
                                                                            field="v21CsSsf11" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf11"] + " oneLine" : "oneLine"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('11')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf11"] ? this.state.itemNoLookupOnPopup["v21CsSsf11"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf11"] ? this.state.lookupOfItemFields["v21CsSsf11"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf11"] ? this.state.dynamicItemFields["v21CsSsf11"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf11"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_11"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('11')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div className="col-sm-6" style={{ borderLeft: "1px solid #ddd" }}>
                                                            <div className={this.state.schemaIdDisplaying && this.state.schemaIdDisplaying.indexOf("Discriminator Check") == -1 ? "" : " emptySsdiFieldsCls"}>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields ? this.state.dynamicItemFields["v21CsSsf12"] : "SSDI User 12"}
                                                                            field="v21CsSsf12" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf12"] + " oneLine onTopFrame" : "oneLine onTopFrame"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('12')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf12"] ? this.state.itemNoLookupOnPopup["v21CsSsf12"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf12"] ? this.state.lookupOfItemFields["v21CsSsf12"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf12"] ? this.state.dynamicItemFields["v21CsSsf12"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf12"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_12"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('12')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf13"] ? this.state.dynamicItemFields["v21CsSsf13"] : "SSDI User 13"}
                                                                            field="v21CsSsf13" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf13"] + " oneLine" : "oneLine"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('13')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf13"] ? this.state.itemNoLookupOnPopup["v21CsSsf13"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf13"] ? this.state.lookupOfItemFields["v21CsSsf13"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf13"] ? this.state.dynamicItemFields["v21CsSsf13"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf13"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_13"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('13')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf14"] ? this.state.dynamicItemFields["v21CsSsf14"] : "SSDI User 14"}
                                                                            field="v21CsSsf14" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf14"] + " oneLine" : "oneLine"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('14')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf14"] ? this.state.itemNoLookupOnPopup["v21CsSsf14"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf14"] ? this.state.lookupOfItemFields["v21CsSsf14"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf14"] ? this.state.dynamicItemFields["v21CsSsf14"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf14"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_14"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('14')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf15"] ? this.state.dynamicItemFields["v21CsSsf15"] : "SSDI User 15"}
                                                                            field="v21CsSsf15" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf15"] + " oneLine" : "oneLine"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('15')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf15"] ? this.state.itemNoLookupOnPopup["v21CsSsf15"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf15"] ? this.state.lookupOfItemFields["v21CsSsf15"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf15"] ? this.state.dynamicItemFields["v21CsSsf15"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf15"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_15"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('15')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf16"] ? this.state.dynamicItemFields["v21CsSsf16"] : "SSDI User 16"}
                                                                            field="v21CsSsf16" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf16"] + " oneLine" : "oneLine"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('16')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf16"] ? this.state.itemNoLookupOnPopup["v21CsSsf16"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf16"] ? this.state.lookupOfItemFields["v21CsSsf16"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf16"] ? this.state.dynamicItemFields["v21CsSsf16"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf16"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_16"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('16')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf17"] ? this.state.dynamicItemFields["v21CsSsf17"] : "SSDI User 17"}
                                                                            field="v21CsSsf17" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf17"] + " oneLine onTopFrame" : "oneLine onTopFrame"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('17')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf17"] ? this.state.itemNoLookupOnPopup["v21CsSsf17"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf17"] ? this.state.lookupOfItemFields["v21CsSsf17"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf17"] ? this.state.dynamicItemFields["v21CsSsf17"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf17"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_17"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('17')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf18"] ? this.state.dynamicItemFields["v21CsSsf18"] : "SSDI User 18"}
                                                                            field="v21CsSsf18" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf18"] + " oneLine" : "oneLine"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('18')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf18"] ? this.state.itemNoLookupOnPopup["v21CsSsf18"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf18"] ? this.state.lookupOfItemFields["v21CsSsf18"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf18"] ? this.state.dynamicItemFields["v21CsSsf18"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf18"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_18"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('18')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf19"] ? this.state.dynamicItemFields["v21CsSsf19"] : "SSDI User 19"}
                                                                            field="v21CsSsf19" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf19"] + " oneLine" : "oneLine"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('19')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf19"] ? this.state.itemNoLookupOnPopup["v21CsSsf19"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf19"] ? this.state.lookupOfItemFields["v21CsSsf19"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf19"] ? this.state.dynamicItemFields["v21CsSsf19"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf19"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_19"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('19')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf20"] ? this.state.dynamicItemFields["v21CsSsf20"] : "SSDI User 20"}
                                                                            field="v21CsSsf20" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf20"] + " oneLine" : "oneLine"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('20')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf20"] ? this.state.itemNoLookupOnPopup["v21CsSsf20"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf20"] ? this.state.lookupOfItemFields["v21CsSsf20"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf20"] ? this.state.dynamicItemFields["v21CsSsf20"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf20"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_20"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('20')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange}
                                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf21"] ? this.state.dynamicItemFields["v21CsSsf21"] : "SSDI User 21"}
                                                                            field="v21CsSsf21" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["v21CsSsf21"] + " oneLine" : "oneLine"}
                                                                            value={this.state.stagingForm[this.getFieldNameByIndex('21')]}
                                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["v21CsSsf21"] ? this.state.itemNoLookupOnPopup["v21CsSsf21"] : ""}
                                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["v21CsSsf21"] ? this.state.lookupOfItemFields["v21CsSsf21"] : ""}
                                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["v21CsSsf21"] ? this.state.dynamicItemFields["v21CsSsf21"] : "Lookup"}
                                                                            inValid={this.state.sAllItemFields["v21CsSsf21"]} itemNbr=""
                                                                            lostfocused={this.handleLostFocus} keyId="V21_SSDI_USER_21"
                                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('21')] : ""}
                                                                            labelWidth="300px" width="55px"
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "none" }}>
                            <input type="hidden" id="Definition_Contains_Fn_ajcc_id_name" value={this.state.ajccId} />
                        </div>
                    </form>

                    <Footer closeWindow={this.props.closeWindow} formId="v21CompatibilityFeatureForm"
                        isFormDirty={this.state.isFormDirty}
                        saveText="Save & Exit" exitText="Cancel"
                        exitFnc={this.props.closeWindow}
                        focusingField={this.state.focusingField}
                        noShowLoader={true}
                        v22NaaccrConversionPoints={this.state.stagingForm ? this.state.stagingForm.v22NaaccrConversionPoints : ""}
                    />
                </div>
            )
            : <p className="data_loading_page">[Staging and SSDI v21 Compatibility Mode] data loading...</p>;
        return (
            <React.Fragment>
                {stagingMaintenance}
            </React.Fragment>
        );
    }
}

V21CompatibilityFeatureForm.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        v21CompatibilityFeature: state.patientAbstract.v21CompatibilityFeature,
        isPatientLoading: state.patientAbstract.isPatientLoading,
        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        v21CompatibilityFeature: state.patientAbstract.v21CompatibilityFeature,
        selectedDiagnosisRecord: state.patientAbstract.selectedDiagnosisRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
        selectedPatient: state.patientAbstract.selectedPatient,
        isShowTextDialogVisible: state.patientAbstract.isShowTextDialogVisible,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        currentDialog: state.patientAbstract.currentDialog,
        clientgroup: state.auth.clientgroup,
        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,
        isFormDirty: state.patientAbstract.isFormDirty,
        inquiringMode: state.auth.inquiringMode,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveV21CompatibilityFeature: (stagingData) => dispatch(actionCreators.saveV21CompatibilityFeature(stagingData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),
        closeV21CompatibilityFeature: () => dispatch(actionCreators.closeV21CompatibilityFeature()),
        checkValidInput: (field, value, table, key, index, flag, cid, pcy) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag, cid, pcy)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        setChildrenWindowVisible: (flag) => dispatch(actionCreators.setChildDialogVisible(flag)),
        // Hospital and Physician lookup
        closeAddNewDialog: (flag) => dispatch(actionCreators.closeAddNewDialog(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        selectTabItems: (dialogType) => dispatch(actionCreators.selectTabItems(dialogType)),
        removeTabItems: (dialogType) => dispatch(actionCreators.removeTabItems(dialogType)),
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setItemNbr: (value) => dispatch(actionCreators.setItemNbr(value)),
        setChildrenOpening: (flag) => dispatch(actionCreators.setChildrenOpening(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(V21CompatibilityFeatureForm);