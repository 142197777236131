/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import axios from "../../../../axios-instance";
import Footer from "../../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
//import * as dialogTypes from "../../../AbstractManagement/maintDialogs";
import "../AbstractUtils.css";
import Patient from "../../../UI/Lookup/Patient";
import ConfigData from '../../../../store/config.json';
import moment from "moment";
//import Input from "../../../UI/Input";
import Draggable from 'react-draggable';

class MergePatients extends Component {

    state = {
        initialDialogForm: null,
        dialogForm: null,
        primaries: null,
        primaries2: null,
        isFormDirty: false,
        reload: false,
        showMessageError: false,
        hasError: false,
        loaded: false,
        showPatientsLookup: false,
        completedSettingPatient: false,
        selectedPatientIndex: 0,
        isNewFlg: false,
    }

    hidePatientsLookup = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showPatientsLookup: false,
            completedSettingPatient: false,
            selectedPatientIndex: 0
        });
        this.props.setChildrenOpening(false);
        return false;
    }

    componentWillUnmount() {
        this.setState({
            initialDialogForm: null,
            dialogForm: null,
            isFormDirty: false,
            reload: false,
            showMessageError: false,
            hasError: false,
            loaded: false,
            showPatientsLookup: false,
            completedSettingPatient: false,
            selectedPatientIndex: 0,
            isNewFlg: false,
        });
    }

    componentDidMount() {
        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);
        /*
        // Add all tabs in maintDialogs.js
        dialogTypes.absUtilsTabsDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog.name, dialog.id);
            this.props.setEnabledStatus(dialog.name);
        });
        */
        this.setState({ isLoading: true, loaded: false });//#End

        if (!this.state.dialogForm && this.props.patientAbsInfo) {
            this.setFormData();
        }

        var childrenOpening = this.props.childrenOpening;
        this.props.setChildrenOpening(childrenOpening);

        setTimeout(function () {
            document.getElementById("btnAddNewItem0") ? document.getElementById("btnAddNewItem0").click() : null;
        }, 1000);
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.dialogForm && this.props.patientAbsInfo)
            || (prevProps.patientAbsInfo && this.props.patientAbsInfo && prevProps.patientAbsInfo.pid !== this.props.patientAbsInfo.pid)
            || (prevProps.patientAbsInfo && this.props.patientAbsInfo && prevProps.patientAbsInfo.firstName !== this.props.patientAbsInfo.firstName)
            || (prevProps.patientAbsInfo && this.props.patientAbsInfo && prevProps.patientAbsInfo.lastName !== this.props.patientAbsInfo.lastName)
            //
        ) {
            this.setFormData();
        } else if (this.state.dialogForm && !this.props.patientAbsInfo && !this.state.reload) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    pid: "0",
                    firstName: "",
                    ["middleName"]: "",
                    lastName: "",
                    dob: "",
                    socSecNbr: "",
                    pid2: "0",
                    firstName2: "",
                    ["middleName2"]: "",
                    lastName2: "",
                    dob2: "",
                    socSecNbr2: "",
                    isFormDirty: false,
                    isNewFlg: false,
                },
                reload: true,
                completedSettingPatient: false,
            });
        }

        if (this.state.dialogForm && this.props.selectedPatientOnPopup && this.props.selectedPatientIndex && !this.state.completedSettingPatient) {
            if (this.props.selectedPatientIndex == "1") {
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        pid: this.props.selectedPatientOnPopup.pid,
                        /*
                        firstName: this.props.selectedPatientOnPopup.firstName,
                        middleName: this.props.selectedPatientOnPopup.middleName,
                        lastName: this.props.selectedPatientOnPopup.lastName,
                        dob: this.props.selectedPatientOnPopup.dob,
                        socSecNbr: this.props.selectedPatientOnPopup.socSecNbr,
                        */
                    },
                    completedSettingPatient: true,
                });

                this.Lookup_Patient(this.props.selectedPatientOnPopup.pid, "1");
            } else if (this.props.selectedPatientIndex == "2") {
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        pid2: this.props.selectedPatientOnPopup.pid,
                        /*
                        firstName2: this.props.selectedPatientOnPopup.firstName,
                        middleName2: this.props.selectedPatientOnPopup.middleName,
                        lastName2: this.props.selectedPatientOnPopup.lastName,
                        dob2: this.props.selectedPatientOnPopup.dob,
                        socSecNbr2: this.props.selectedPatientOnPopup.socSecNbr,
                        */
                    },
                    completedSettingPatient: true,
                });

                this.Lookup_Patient(this.props.selectedPatientOnPopup.pid, "2");
            }
        } else if (this.state.dialogForm && !this.props.selectedPatientOnPopup && !this.state.reload) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    pid: "0",
                    firstName: "",
                    ["middleName"]: "",
                    lastName: "",
                    dob: "",
                    socSecNbr: "",
                    pid2: "0",
                    firstName2: "",
                    ["middleName2"]: "",
                    lastName2: "",
                    dob2: "",
                    socSecNbr2: "",
                    isFormDirty: false,
                    isNewFlg: false,
                },
                reload: true,
                completedSettingPatient: false,
            });
        }
    }

    setFormData = () => {
        let dataForm = this.props.patientAbsInfo;

        let fields = {};
        Object.keys(dataForm).map((t) => {
            fields[t] = "";
        });

        this.setState({
            initialDialogForm: { ...fields },
            dialogForm: { ...fields },
            isFormDirty: false,
            reload: true,
            savedForm: false,
            hasError: false,
            loaded: true,
            isNewFlg: false,
            completedSettingPatient: false,
        });

        //
        setTimeout(function () {
            document.getElementById("ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP") ? document.getElementById("ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP").focus() : "";
        }, 500);
    }

    //
    handleFormChange = (event) => {
        if (event.target.name == "pid" || event.target.name == "pid2") {
            this.Lookup_Patient(event.target.value);
        }
    }

    //Lookup_Patient
    async Lookup_Patient(pid, selectedIndex) {
        var _this = this;
        await setTimeout(function () {
            axios.post(ConfigData.CRStarAPI_URL + _this.props.clientgroup + "/LookupPatient",
                { pId: pid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error && res.data.patient) {
                        var pat = res.data.patient;
                        var psList = res.data.patient.primarySummary;

                        if (selectedIndex == "1") {
                            _this.setState({
                                dialogForm: {
                                    ..._this.state.dialogForm,
                                    firstName: pat.firstName,
                                    ["middleName"]: pat.middleName,
                                    lastName: pat.lastName,
                                    dob: pat.dob != null && pat.dob != "" ? moment(new Date(pat.dob)).toDate() : null,
                                    socSecNbr: pat.socSecNbr,
                                    primaries: psList
                                },
                            });
                        } else if (selectedIndex == "2") {
                            _this.setState({
                                dialogForm: {
                                    ..._this.state.dialogForm,
                                    firstName2: pat.firstName,
                                    ["middleName2"]: pat.middleName,
                                    lastName2: pat.lastName,
                                    dob2: pat.dob != null && pat.dob != "" ? moment(new Date(pat.dob)).toDate() : null,
                                    socSecNbr2: pat.socSecNbr,
                                    primaries2: psList
                                },
                            });
                        }

                        setTimeout(function () {
                            document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "" : null;
                        }, 0);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    return false;
                });
        }, 0);
    }

    refreshFormFnc = () => {
        let fields = {};
        if (!this.state.pAllItemFields && this.props.patientAbsInfo) {
            Object.keys(this.props.patientAbsInfo).map((t) => {
                fields[t] = "";
            });
        };

        this.setState({
            dialogForm: {
                ...this.state.initialDialogForm,
                ["pid"]: "",//1
                ["firstName"]: "",
                ["middleName"]: "",
                ["lastName"]: "",
                ["dob"]: "",
                ["socSecNbr"]: "",
                ["pid2"]: "",//2
                ["firstName2"]: "",
                ["middleName2"]: "",
                ["lastName2"]: "",
                ["dob2"]: "",
                ["socSecNbr2"]: "",
            },
            pAllItemFields: fields,
            isFormDirty: false,
            reload: false,
            isNewFlg: true,
            primaries: null,
            primaries2: null,
        });

        return false;
    }

    closeWindow = (e) => {
        e.preventDefault();

        this.setState({
            browsedItem: "",
            browsedIndex: null,
            listFiles: null
        });

        return false;
    }

    showPatientsLookup = (event, index) => {
        event.preventDefault();
        this.setState({
            showPatientsLookup: true,
            completedSettingPatient: false,
            selectedPatientIndex: index
        });
        return false;
    }

    hideConfirmationMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showConfirmationMsg: false
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            var selBtn = document.getElementById("ABSTRACTUT_MERGEPATIE_UUBG-BUTTON_MERGE");
            selBtn.disabled = false;
            document.getElementById("ABSTRACTUT_MERGEPATIE_UUBG-BUTTON_MERGE") ? document.getElementById("ABSTRACTUT_MERGEPATIE_UUBG-BUTTON_MERGE").focus() : null;
        }, 0);

        return false;
    }

    mergePatientAbs = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showConfirmationMsg: false
        });
        this.props.setChildrenOpening(false);

        var pid = this.state.dialogForm.pid;
        var pid2 = this.state.dialogForm.pid2;
        var _this = this;

        var selBtn = document.getElementById("ABSTRACTUT_MERGEPATIE_UUBG-BUTTON_MERGE");
        
        if (pid != pid2) {
            let url = this.props.clientgroup + "/MergePatientAbs";
            axios.post(url, { pid: pid, pid2: pid2, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (res && res.data && !res.data.error) {
                        // reload all info of Patient 1
                        //_this.Lookup_Patient(pid, "1");

                        var psList = _this.state.dialogForm.primaries;
                        var psList2 = _this.state.dialogForm.primaries2;

                        // clear all info of Patient 2
                        _this.setState({
                            dialogForm: {
                                ..._this.state.dialogForm,
                                ["pid2"]: "",//2
                                ["firstName2"]: "",
                                ["middleName2"]: "",
                                ["lastName2"]: "",
                                ["dob2"]: "",
                                ["socSecNbr2"]: "",
                                primaries2: null,

                                //merging all primary_summary list of Patient 2 into Patient 1
                                primaries: [...psList, ...psList2]
                            },
                        });

                        document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "Merge Complete" : null;
                        return false;
                    }
                    selBtn.disabled = false;
                }).catch(error => {
                    selBtn.disabled = false;
                    console.log(error);
                });
            return false;
        }

        return false;
    }

    confirmMergeFnc(selBtn) {
        var pid = this.state.dialogForm.pid;
        var pid2 = this.state.dialogForm.pid2;
        var _this = this;
        if (pid != pid2) {
            let url = this.props.clientgroup + "/MergePatientAbs";
            axios.post(url, { pid: pid, pid2: pid2, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (res && res.data && !res.data.error) {
                        // clear all info of Patient 2
                        _this.setState({
                            dialogForm: {
                                ..._this.state.initialDialogForm,
                                ["pid2"]: "",//2
                                ["firstName2"]: "",
                                ["middleName2"]: "",
                                ["lastName2"]: "",
                                ["dob2"]: "",
                                ["socSecNbr2"]: "",
                            },
                            primaries2: null,
                        });

                        document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "Merge Complete" : null;
                        return false;
                    }
                    selBtn.disabled = false;
                }).catch(error => {
                    selBtn.disabled = false;
                    console.log(error);
                });
            return false;
        }
        return false;
    }

    mergePatientFnc = (event) => {
        event.preventDefault();

        var selBtn = event.target;
        selBtn.disabled = true;

        var pid = this.state.dialogForm.pid;
        var pid2 = this.state.dialogForm.pid2;

        const fn = this.state.dialogForm.firstName ? this.state.dialogForm.firstName : "";
        const ln = this.state.dialogForm.lastName ? this.state.dialogForm.lastName : "";
        const dob = this.state.dialogForm.dob ? this.state.dialogForm.dob : null;
        const ssn = this.state.dialogForm.socSecNbr ? this.state.dialogForm.socSecNbr : "";

        const fn2 = this.state.dialogForm.firstName2 ? this.state.dialogForm.firstName2 : "";
        const ln2 = this.state.dialogForm.lastName2 ? this.state.dialogForm.lastName2 : "";
        const dob2 = this.state.dialogForm.dob2 ? this.state.dialogForm.dob2 : null;
        const ssn2 = this.state.dialogForm.socSecNbr2 ? this.state.dialogForm.socSecNbr2 : "";

        var _this = this;
        if (pid > 0 && pid2 > 0) {
            if (fn.toUpperCase() != fn2.toUpperCase() || ln.toUpperCase() != ln2.toUpperCase() || (dob != null && dob2 != null && dob.getTime() != dob2.getTime()) || ssn != ssn2) {
                //confirm('These Patients do not match. Merge them anyway?');
                this.setState({ showConfirmationMsg: true });
                return false;
            } else {
                if (pid != pid2) {
                    let url = this.props.clientgroup + "/MergePatientAbs";
                    axios.post(url, { pid: pid, pid2: pid2, accessToken: localStorage.getItem('token') })
                        .then(res => {
                            //update new accessToken again
                            var newAccessToken = res && res.data ? res.data.accessToken : null;
                            if (newAccessToken) {
                                localStorage.setItem('token', newAccessToken);
                            }

                            if (res && res.data && !res.data.error) {
                                // clear all info of Patient 2
                                _this.setState({
                                    dialogForm: {
                                        ..._this.state.dialogForm,
                                        ["pid2"]: "",//2
                                        ["firstName2"]: "",
                                        ["middleName2"]: "",
                                        ["lastName2"]: "",
                                        ["dob2"]: "",
                                        ["socSecNbr2"]: "",
                                    },
                                    primaries2: null,
                                });

                                document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "Merge Complete" : null;
                                return false;
                            }
                            selBtn.disabled = false;
                        }).catch(error => {
                            selBtn.disabled = false;
                            console.log(error);
                        });
                    return false;
                } else {
                    selBtn.disabled = false;
                    document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "Same Patient Records" : null;
                    return false;
                }
            }
        } else {
            selBtn.disabled = false;
            document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "Two Patient Records Must be Selected" : null;
            return false;
        }
        return false;
    }

    render() {
        let showConfirmationMsg;
        {
            showConfirmationMsg = this.state.showConfirmationMsg ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Confirmation</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmationMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">These Patients do not match. Merge them anyway?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.mergePatientAbs(e) }} value="yes" autoFocus>OK</button>
                                <button onClick={(e) => { this.hideConfirmationMsg(e) }} value="no">Cancel</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let loading = (
            !this.state.loaded || this.props.waitingFor ? <div className="col-sm-12 showLoadingOnTopPageCls">Working...</div> : ""
        );

        let showPatientsLookup;
        {
            //Window Height/Width: 600px/1250px
            showPatientsLookup = this.state.showPatientsLookup ? <Patient field="firstName" closeCode={this.hidePatientsLookup} titleDialog="Patient Lookup" itemIndex={this.state.selectedPatientIndex} /> : null;
        }

        let tbodyHtml;
        {
            const primariesSize = this.state.dialogForm && this.state.dialogForm.primaries ? this.state.dialogForm.primaries.length : 0;
            tbodyHtml = <tbody>
                {[...Array(50)].map((x, i) =>
                    <tr>
                        <td>{i + 1}</td>
                        <td>{i < primariesSize ? this.state.dialogForm.primaries[i]["abstSunaFlag"] : ""}</td>
                        <td>{i < primariesSize ? this.state.dialogForm.primaries[i]["siteCode"] : ""}</td>
                        <td>{i < primariesSize ? this.state.dialogForm.primaries[i]["seqPrim"] : ""}</td>
                        <td>{i < primariesSize ? this.state.dialogForm.primaries[i]["hosp"] : ""}</td>
                        <td>{i < primariesSize ? this.state.dialogForm.primaries[i]["class"] : ""}</td>
                        <td>{i < primariesSize ? this.state.dialogForm.primaries[i]["medRecNbr"] : ""}</td>
                    </tr>
                )}
                </tbody>
        }

        let tbodyHtml2;
        {
            const primaries2Size = this.state.dialogForm && this.state.dialogForm.primaries2 ? this.state.dialogForm.primaries2.length : 0;
            tbodyHtml2 = <tbody>
                {[...Array(50)].map((x, i) =>
                    <tr>
                        <td>{i + 1}</td>
                        <td>{i < primaries2Size ? this.state.dialogForm.primaries2[i]["abstSunaFlag"] : ""}</td>
                        <td>{i < primaries2Size ? this.state.dialogForm.primaries2[i]["siteCode"] : ""}</td>
                        <td>{i < primaries2Size ? this.state.dialogForm.primaries2[i]["seqPrim"] : ""}</td>
                        <td>{i < primaries2Size ? this.state.dialogForm.primaries2[i]["hosp"] : ""}</td>
                        <td>{i < primaries2Size ? this.state.dialogForm.primaries2[i]["class"] : ""}</td>
                        <td>{i < primaries2Size ? this.state.dialogForm.primaries2[i]["medRecNbr"] : ""}</td>
                    </tr>
                )}
            </tbody>
        }

        const dialogFrm = <div className="limitedInGridCls">
            <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                {loading}
                <div className="parentWindow">
                    <div className="patientAbstractGridView" id="mergePatientAbstactMainPage" style={{ height: "600px" }}>
                        {
                            this.state.dialogForm ?
                                <div className="container-fluid2">
                                    <form id="mergePatientAbstactMainForm" className="mergePatientAbstactMainForm">
                                        <div id="inputAreaCls">
                                            <div style={{ width: "1260px" }}>
                                                <div className="frameInner">
                                                    <div className="header">Merge Patients</div>

                                                    <div className="row" style={{ minWidth: "1260px" }}>
                                                        <div className="col-sm-4 leftAreaMergingPatient1">
                                                            <div className="oneLine">
                                                                <button type="button" id="ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP"
                                                                    className="ErsGrBlueButton _ScreenButton" title=""
                                                                    style={{ _width: "100%", height: "26px", boxSizing: "border-box" }}
                                                                    onClick={(e) => { this.showPatientsLookup(e, '1') }}
                                                                >
                                                                    <img id="ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP-ICON" src="https://crstar.ers-can.com/images/$$edit.find.png.a5image" style={{ verticalAlign: "middle", border: "none" }}></img>
                                                                    Select Patient 1
                                                                </button>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <label>Name:&nbsp;</label>
                                                                    <span id="patient1_name_dsp">{this.state.dialogForm ? this.state.dialogForm.firstName + " " + this.state.dialogForm.middleName + " " + this.state.dialogForm.lastName : ""}</span>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                    <label>Dob:&nbsp;&nbsp;</label>
                                                                    <span id="patient1_dob_dsp">{this.state.dialogForm && this.state.dialogForm.dob ? moment(new Date(this.state.dialogForm.dob)).format("MM/DD/YYYY") : ""}</span>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                    <label>SSN:&nbsp;&nbsp;</label>
                                                                    <span id="patient1_ssn_dsp">{this.state.dialogForm ? this.state.dialogForm.socSecNbr : ""}</span>
                                                                </div>
                                                            </div>

                                                            <div className="emptyLine"></div>

                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <table>
                                                                        <colgroup>
                                                                            <col width="30px"></col>
                                                                            <col width="50px"></col>
                                                                            <col width="50px"></col>
                                                                            <col width="50px"></col>
                                                                            <col width="50px"></col>
                                                                            <col width="50px"></col>
                                                                            <col width="auto"></col>
                                                                        </colgroup>
                                                                        <thead>
                                                                            <tr>
                                                                                <th></th>
                                                                                <th>S/A</th>
                                                                                <th>Site</th>
                                                                                <th>Seq</th>
                                                                                <th>Hosp</th>
                                                                                <th>Class</th>
                                                                                <th>MRN</th>
                                                                            </tr>
                                                                        </thead>
                                                                    </table>
                                                                    <div className="primariesListMergingArea">
                                                                        <table>
                                                                            <colgroup>
                                                                                <col width="30px"></col>
                                                                                <col width="50px"></col>
                                                                                <col width="50px"></col>
                                                                                <col width="50px"></col>
                                                                                <col width="50px"></col>
                                                                                <col width="50px"></col>
                                                                                <col width="auto"></col>
                                                                            </colgroup>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th></th>
                                                                                    <th>S/A</th>
                                                                                    <th>Site</th>
                                                                                    <th>Seq</th>
                                                                                    <th>Hosp</th>
                                                                                    <th>Class</th>
                                                                                    <th>MRN</th>
                                                                                </tr>
                                                                            </thead>
                                                                            {tbodyHtml}
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-2 middleAreaMergingBtn">
                                                            <div className="">
                                                                <span>Merge Primaries into Patient 1 and Delete Patient 2</span>
                                                                <br/>
                                                                <button type="button" id="ABSTRACTUT_MERGEPATIE_UUBG-BUTTON_MERGE"
                                                                    className="ErsGrBlueButton _ScreenButton" title=""
                                                                    style={{ _width: "100%", height: "26px", boxSizing: "border-box" }}
                                                                    onClick={(e) => { this.mergePatientFnc(e) }}
                                                                >
                                                                    <img id="ABSTRACTUT_MERGEPATIE_UUBG-BUTTON_MERGE-ICON" src="https://crstar.ers-can.com/images/$$large.table.import.png.a5image" style={{ verticalAlign: "middle", border: "none" }}></img>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-4 rightAreaMergingPatient2">
                                                            <div className="oneLine">
                                                                <button type="button" id="ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP2"
                                                                    className="ErsGrBlueButton _ScreenButton" title=""
                                                                    style={{ _width: "100%", height: "26px", boxSizing: "border-box" }}
                                                                    onClick={(e) => { this.showPatientsLookup(e, '2') }}
                                                                >
                                                                    <img id="ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP-ICON2" src="https://crstar.ers-can.com/images/$$edit.find.png.a5image" style={{ verticalAlign: "middle", border: "none" }}></img>
                                                                    Select Patient 2
                                                                </button>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <label>Name:&nbsp;</label>
                                                                    <span id="patient2_name_dsp">{this.state.dialogForm ? this.state.dialogForm.firstName2 + " " + this.state.dialogForm.middleName2 + " " + this.state.dialogForm.lastName2 : ""}</span>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                    <label>Dob:&nbsp;&nbsp;</label>
                                                                    <span id="patient2_dob_dsp">{this.state.dialogForm && this.state.dialogForm.dob2 ? moment(new Date(this.state.dialogForm.dob2)).format("MM/DD/YYYY") : ""}</span>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                    <label>SSN:&nbsp;&nbsp;</label>
                                                                    <span id="patient2_ssn_dsp">{this.state.dialogForm ? this.state.dialogForm.socSecNbr2 : ""}</span>
                                                                </div>
                                                            </div>

                                                            <div className="emptyLine"></div>

                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <table>
                                                                        <colgroup>
                                                                            <col width="30px"></col>
                                                                            <col width="50px"></col>
                                                                            <col width="50px"></col>
                                                                            <col width="50px"></col>
                                                                            <col width="50px"></col>
                                                                            <col width="50px"></col>
                                                                            <col width="auto"></col>
                                                                        </colgroup>
                                                                        <thead>
                                                                            <tr>
                                                                                <th></th>
                                                                                <th>S/A</th>
                                                                                <th>Site</th>
                                                                                <th>Seq</th>
                                                                                <th>Hosp</th>
                                                                                <th>Class</th>
                                                                                <th>MRN</th>
                                                                            </tr>
                                                                        </thead>
                                                                    </table>
                                                                    <div className="primariesListMergingArea">
                                                                        <table>
                                                                            <colgroup>
                                                                                <col width="30px"></col>
                                                                                <col width="50px"></col>
                                                                                <col width="50px"></col>
                                                                                <col width="50px"></col>
                                                                                <col width="50px"></col>
                                                                                <col width="50px"></col>
                                                                                <col width="auto"></col>
                                                                            </colgroup>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th></th>
                                                                                    <th>S/A</th>
                                                                                    <th>Site</th>
                                                                                    <th>Seq</th>
                                                                                    <th>Hosp</th>
                                                                                    <th>Class</th>
                                                                                    <th>MRN</th>
                                                                                </tr>
                                                                            </thead>
                                                                            {tbodyHtml2}
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="emptyLine"></div>

                                    <Footer closeWindow={this.props.closeWindow}
                                        refreshForm={this.refreshFormFnc} formId="mergePatientAbstactMainForm"
                                        isFormDirty={this.state.isFormDirty}
                                        hideSaveBtn={true}
                                        showBtnNew="MergePatientAbs"
                                        showBtnDelete={false}
                                        className="Footer2"
                                        initialPageVisible={true}
                                        exitFnc={this.props.closeWindow}
                                        focusingField={this.state.focusingField}
                                        patientAbsData={this.state.dialogForm}
                                        ignoreExit={true}
                                    />

                                </div>
                                : <p>Merge Patients data loading...</p>
                        }
                    </div>
                </div>
            </div>
        </div>;

        return (
            <React.Fragment>
                <React.Fragment>
                    {showConfirmationMsg}
                </React.Fragment>
                <React.Fragment>
                    {showPatientsLookup}
                </React.Fragment>
                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

MergePatients.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        patientAbsInfo: state.patientAbstract.patientAbsInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        deletedDataSuccess: state.patientAbstract.deletedDataSuccess,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        waitingFor: state.patientAbstract.waitingFor,
        user_id: state.auth.userId,
        selectedPatientOnPopup: state.patientAbstract.selectedPatientOnGrid,
        selectedPatientIndex: state.patientAbstract.selectedPatientIndex,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        resetDeletedDataSuccess: (value) => dispatch(actionCreators.resetDeletedDataSuccess(value)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        setHandlerControlItem: (item) => dispatch(actionCreators.setHandlerControlItem(item)),
        showPageLoading: (flag) => dispatch(actionCreators.showPageLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),

        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MergePatients);