/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Footer from "../Shared/Footer";
import Input from "../../../UI/Input";
import "../PatientMaintenance/PatientMaintenance.css";
import "./TreatmentMaintenance.css";
import moment from "moment";
import TextForm from "../DiagnosisMaintenance/TextForm";
//import { Dialog } from "@progress/kendo-react-dialogs";
//import classes from "../PatientAbstract.css";
import Help from "../../../UI/Help";
import axios from "../../../../axios-instance";
import Draggable from 'react-draggable';
import * as TextPopup from "../TitleOfTextPopup";
import * as Global from "../../../../store/Global";

class TreatmentMaintenance extends Component {

    state = {
        activeTab: "1",
        dAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'treatmentForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty treatmentForm back to what it was on load
        initialTreatmentForm: null,
        treatmentForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        _fieldInFocus: "initialRxDate",
        _fieldItemNbr: "",
        _focusingField: null,
        isHelpVisible: false,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,

        showConfirmationMsg: false,
        targetChild: null,
    }

    hideConfirmationMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ showConfirmationMsg: false });
        return false;
    }
    editChildForm = () => {
        // first,  hide confirm message
        this.setState({ showConfirmationMsg: false });
        // second, save values on treatment form
        let treatmentFormData = this.state.treatmentForm;
        this.props.saveTreatment(treatmentFormData);

        // then, show children popup
        let child = this.state.targetChild;
        let medical = this.props.selectedMedicalRecord;
        this.props.selectSurgeryRecord(medical);
        /*
        switch (child) {
            case "Surgery":
            case "Radiation":
            case "Chemo":
            case "Hormone":
            case "Immunotherapy":
            case "Hematopoietic":
            case "Palliative":
            case "Other":
                this.props.selectSurgeryRecord(medical);
                break;
            default:
                break;
        }
        */
        this.setChildDialog(child);

        return false;
    }

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: flag });
    }

    showTextDialog = () => {
        this.setState({ isShowTextVisible: true });
        return false;
    }

    closeTextDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowTextVisible: false });
        this.props.closeTextDialog(false);

        return false;
    }

    // --- START
    /*
    selectChildren = event => {
        //this.props.selectChildren(event.dataItem.pid);
        this.props.setChildDialog(dialogTypes.SURGERY);
        //this.props.setChildDialogVisible({ isChildDialogVisible: true });
    }
    */
    setChildDialog = (childDialog) => {
        this.props.setChildDialog(childDialog);
        this.props.setChildDialogVisible(true);
    }
    // END ---

    editItem = (event) => {
        event.preventDefault();

        let child = event.target.name;
        if (this.state.isFormDirty) {
            this.setState({ showConfirmationMsg: true, targetChild: child });
            return false;
        }

        let medical = this.props.selectedMedicalRecord;
        switch (child) {
            case "Surgery":
            case "Radiation":
            case "Chemo":
            case "Hormone":
            case "Immunotherapy":
            case "Hematopoietic":
            case "Palliative":
            case "Other":
                this.props.selectSurgeryRecord(medical);
                break;
            default:
                break;
        }

        this.setChildDialog(child);
    }

    componentDidMount() {
        if (!this.state.treatmentForm && this.props.selectedTreatmentRecord) {
            this.setFormData();
        }
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.treatmentForm && this.props.selectedTreatmentRecord)
            || (prevProps.selectedTreatmentRecord && prevProps.selectedTreatmentRecord !== this.props.selectedTreatmentRecord)
            || (this.props.initialAbstractPages && this.props.selectedTreatmentRecord)) {
            this.setFormData();
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.treatmentForm && this.state.dAllItemFields
        ) {
            console.log("[Treatment] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    treatmentForm: { ...this.state.treatmentForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    treatmentForm: { ...this.state.treatmentForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "Rx_Summary", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res.data ? res.data.result : null
        });
    }

    setFormData = () => {
        this.setState({
            treatmentForm: { ...this.props.selectedTreatmentRecord },
            isFormDirty: this.props.isFormDirty
        });

        if (!this.state.dAllItemFields && this.props.selectedTreatmentRecord) {
            let fields = {};
            Object.keys(this.props.selectedTreatmentRecord).map((t) => {
                fields[t] = "";
            });

            this.setState({ dAllItemFields: fields, pageMaxlengthInput: fields });
        };

        this.props.setInitialAbstractPages(false);

        return false;
    }

    toggleTab = (tab) => {
        this.setState({ activeTab: tab });
        return false;
    }

    saveTreatment = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        let formItems = this.state.dAllItemFields;
        Object.keys(formItems).map((t) => {
            // clear IN_VALID flag for all date fields
            if (t === "initialRxDate" || t === "InitialRxDate") {
                this.clearInvalidOfDateFields(formItems, t);
            }

            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let treatmentFormData = this.state.treatmentForm;

            //#20210125 - START
            // append date fields from m/d/y
            let isValidDateInput = true;
            Object.keys(treatmentFormData).map((t, k) => {
                if (t === "initialRxDate" || t === "InitialRxDate") {
                    isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(treatmentFormData, t);
                }
            });
            //#20210125 - END
            if (!isValidDateInput && !allowSaveAnyway) return false;

            this.props.saveTreatment(treatmentFormData);

            return false;
        }
    }

    saveTextDialog = (event) => {
        event.preventDefault();

        // TODO Form validation
        if (true) {
            let textDialogData = this.state.textDialogForm;
            this.props.saveTextDialog(textDialogData);
        }
        return false;
    }

    resetTreatment = () => {
        this.setState({
            treatmentForm: { ...this.state.initialTreatmentForm },
            isFormDirty: false,
        });
        return false;
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({
            _fieldInFocus: event.target.name,
            _fieldItemNbr: event.target.getAttribute("data-help-id"),
            _focusingField: event.target
        });
        return false;
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            this.props.checkValidInput(name, value, table, key, -1, flag);

        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let value = event.target.value;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState({ treatmentForm: { ...this.state.treatmentForm, [event.target.name]: value } });
                } else {
                    this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
                }
            } else if (mdy == 'year' && value.length == 2) {
                let currentYear = (new Date().getFullYear() + "");
                let yyVal = currentYear.substr(2, 2);
                if (yyVal < value) {
                    value = (currentYear.substr(0, 2) - 1) + value;
                } else {
                    value = currentYear.substr(0, 2) + value;
                }

                this.setState({ treatmentForm: { ...this.state.treatmentForm, [event.target.name]: value } });
            }

        } else {
            this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
        }
    }
    // --END

    // Add clear and check validate of date fields function -- START
    clearInvalidOfDateFields = (formItems, field, onGrid, index) => {
        let tYear = field + "Year";
        let tMonth = field + "Month";
        let tDay = field + "Day";
        if (onGrid && onGrid === "primaryItemList") {
            this.setState(prevState => {
                let newItems = [...prevState.primaryItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { primaryItemFields: newItems };
            });
        } else {
            this.setState({
                dAllItemFields: {
                    ...this.state.dAllItemFields,
                    [tYear]: "",
                    [tMonth]: "",
                    [tDay]: "",
                },
                isFormDirty: true
            });
        }
        formItems[tYear] = "";
        formItems[tMonth] = "";
        formItems[tDay] = "";
    }

    checkValidateOfDateFields = (pFormData, field, onGrid, index) => {
        let isValidDateInput = true;

        var tYear = field + "Year";
        var tMonth = field + "Month";
        var tDay = field + "Day";

        // Check valid of date in February
        var year = pFormData[tYear] ? pFormData[tYear] : "";
        var month = pFormData[tMonth] ? pFormData[tMonth] : "";
        var day = pFormData[tDay] ? pFormData[tDay] : "";

        pFormData[field] = new Date(year + "-" + month + "-" + day);

        if (year !== "" && month !== "" && day !== "") {
            var _day, A = (month + '/' + day + '/' + year).match(/[1-9][\d]*/g);
            try {
                A[0] -= 1;
                _day = new Date(+A[2], A[0], +A[1]);
                if ((_day.getMonth() == A[0] && _day.getDate() == A[1])
                                /*|| year == '9999'*/ || month == '99' || day == '99') {
                } else {
                    isValidDateInput = false;

                    if (onGrid && onGrid === "primaryList") {
                        this.setState(prevState => {
                            const newItems = [...prevState.primaryItemFields];
                            newItems[index][tYear] = "IN_VALID";
                            newItems[index][tMonth] = "IN_VALID";
                            newItems[index][tDay] = "IN_VALID";
                            return { primaryItemFields: newItems };
                        });
                    } else {
                        this.setState({
                            dAllItemFields: {
                                ...this.state.dAllItemFields,
                                [tYear]: "IN_VALID",
                                [tMonth]: "IN_VALID",
                                [tDay]: "IN_VALID",
                            },
                            isFormDirty: true
                        });
                    }
                }
            } catch (er) {
                isValidDateInput = false;
            }
        }

        return isValidDateInput;
    }
    // -- END

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ treatmentForm: { ...this.state.treatmentForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({ treatmentForm: { ...this.state.treatmentForm, [event.target.name]: event.target.value }, isFormDirty: true });
        }
    }

    helpFocusingField = (field) => {
        this.setState({ fieldInFocus: field });
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isHelpVisible: false });
        return false;
    }

    render() {
        const treatmentSummary = this.state.treatmentForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">
                    {
                        this.state.isHelpVisible && <Help fieldInFocus={this.state._fieldInFocus} focusingField={this.state._focusingField} fieldItemNbr={this.state._fieldItemNbr} closeCode={this.closeCodeDialog} />
                    }
                    <form onSubmit={this.saveTreatment} id="treatmentForm" className="DialogForm TabParentPage">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq Prim: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo0" style={{ textAlign: "right", display: "none" }}>
                                    <button type="button" onClick={this.getHelp} name="btnHelp">Help</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {
                                        //this.props.selectedPatient.dob != null && this.props.selectedPatient.dob !== "" ? moment(new Date(this.props.selectedPatient.dob)).format("MM/DD/YYYY") : ""
                                        this.props.selectedPatient.dobMonth + "/" + this.props.selectedPatient.dobDay + "/" + this.props.selectedPatient.dobYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {
                                        //this.props.selectedPrimarySummary.diagnosisDate != null && this.props.selectedPrimarySummary.diagnosisDate !== "" ? moment(new Date(this.props.selectedPrimarySummary.diagnosisDate)).format("MM/DD/YYYY") : ""
                                        this.props.selectedPrimarySummary.diagnosisDateMonth + "/" + this.props.selectedPrimarySummary.diagnosisDateDay + "/" + this.props.selectedPrimarySummary.diagnosisDateYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>

                        <div className="emptyLine"></div>

                        <div style={{ display: "flex" }}>
                            <div className="A5CWLayout TreatmentScreen">
                                <div className="frameInner" style={{ width: "240px", height: "502px" }}>
                                    <div className="header">&nbsp;</div>
                                    <div className="">
                                        <div style={{ display: (this.state.treatmentForm.rxSummRxStatus === "0" || this.state.treatmentForm.rxSummRxStatus === "2" || this.state.treatmentForm.rxSummRxStatus === "9" ? "" : "none") }}>
                                            <Input type="date" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Date First Crs Tx" field="initialRxDate" lineStyle="oneLine"
                                                autoFocus={(this.state.treatmentForm.rxSummRxStatus === "0" || this.state.treatmentForm.rxSummRxStatus === "2" || this.state.treatmentForm.rxSummRxStatus === "9" ? true : false) }
                                                value={this.state.treatmentForm.initialRxDate != null && this.state.treatmentForm.initialRxDate !== "" && this.state.treatmentForm.initialRxDate.toString() != 'Invalid date' ? moment(new Date(this.state.treatmentForm.initialRxDate)).format("MM/DD/YYYY") : ""}
                                                labelWidth={Global.LabelWidth} monthWidth="34px" dayWidth="34px" yearWidth="42px"
                                                //#20210125 - START
                                                // Add day/month/year values
                                                inValidDay={this.state.dAllItemFields["initialRxDateDay"]}
                                                inValidMonth={this.state.dAllItemFields["initialRxDateMonth"]}
                                                inValidYear={this.state.dAllItemFields["initialRxDateYear"]}
                                                dayVal={this.state.treatmentForm.initialRxDateDay}
                                                monthVal={this.state.treatmentForm.initialRxDateMonth}
                                                yearVal={this.state.treatmentForm.initialRxDateYear}
                                                //#20210125 - END
                                                lostfocused={this.handleLostFocus}
                                            />
                                        </div>
                                        <div style={{ display: (this.state.treatmentForm.rxSummRxStatus !== "0" && this.state.treatmentForm.rxSummRxStatus !== "2" && this.state.treatmentForm.rxSummRxStatus !== "9" ? "" : "none") }}>
                                            <Input type="label" labelWidth={Global.LabelWidth} width="125px"//100px
                                                labelText="Date First Crs Tx" field="initialRxDate"
                                                value={this.state.treatmentForm.initialRxDate !== null && this.state.treatmentForm.initialRxDate !== "" ? moment(new Date(this.state.treatmentForm.initialRxDate)).format("MM/DD/YYYY") : ""}
                                            />
                                        </div>

                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Tx Summ Status" field="rxSummRxStatus"
                                            value={this.state.treatmentForm.RxSummRxStatus ? this.state.treatmentForm.RxSummRxStatus : this.state.treatmentForm.rxSummRxStatus}
                                            dataSource="Global_Dir_Mv" aliasNo="3160" titleDialog="Rx Summ Rx Status"
                                            labelWidth="180px" width="25px"
                                            inValid={this.state.dAllItemFields["rxSummRxStatus"]} itemNbr="3160"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rxSummRxStatus"] : ""}
                                            autoFocus={(this.state.treatmentForm.rxSummRxStatus !== "0" && this.state.treatmentForm.rxSummRxStatus !== "2" && this.state.treatmentForm.rxSummRxStatus !== "9" ? true : false)}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Surg Margins" field="surgMargins"
                                            value={this.state.treatmentForm.SurgMargins ? this.state.treatmentForm.SurgMargins : this.state.treatmentForm.surgMargins}
                                            dataSource="Global_Dir_Mv" aliasNo="161" titleDialog="Surg Margins"
                                            labelWidth="180px" width="25px"
                                            inValid={this.state.dAllItemFields["surgMargins"]} itemNbr="161"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["surgMargins"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Surg App 2010" field="rxHospSurgApp2010"
                                            value={this.state.treatmentForm.RxHospSurgApp2010 ? this.state.treatmentForm.RxHospSurgApp2010 : this.state.treatmentForm.rxHospSurgApp2010}
                                            dataSource="Global_Dir_Mv" aliasNo="3158" titleDialog="Rx Hosp Surg App 2010"
                                            labelWidth="180px" width="25px"
                                            inValid={this.state.dAllItemFields["rxHospSurgApp2010"]} itemNbr="3158"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rxHospSurgApp2010"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Radiation Seq Surg" field="radSeqSurg"
                                            value={this.state.treatmentForm.RadSeqSurg ? this.state.treatmentForm.RadSeqSurg : this.state.treatmentForm.radSeqSurg}
                                            dataSource="Global_Dir_Mv" aliasNo="130" titleDialog="Rad Seq Surg"
                                            labelWidth="180px" width="25px"
                                            inValid={this.state.dAllItemFields["radSeqSurg"]} itemNbr="130"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["radSeqSurg"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Radiation Location" field="radLocSumm"
                                            value={this.state.treatmentForm.RadLocSumm ? this.state.treatmentForm.RadLocSumm : this.state.treatmentForm.radLocSumm}
                                            dataSource="Global_Dir_Mv" aliasNo="3961" titleDialog="Rad Loc Summ"
                                            labelWidth="180px" width="25px"
                                            inValid={this.state.dAllItemFields["radLocSumm"]} itemNbr="3961"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["radLocSumm"] : ""}
                                        />
                                        <Input type="label" labelWidth="145px" width="60px"
                                            field="dtSysRxStart" labelText="Date Systemic Tx Start"                                            
                                            value={this.state.treatmentForm.dtSysRxStartDsp != null && this.state.treatmentForm.dtSysRxStartDsp !== "" ? moment(new Date(this.state.treatmentForm.dtSysRxStartDsp)).format("MM/DD/YYYY") : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Systemic Surgery Seq" field="sysSurgSeq"
                                            value={this.state.treatmentForm.SysSurgSeq ? this.state.treatmentForm.SysSurgSeq : this.state.treatmentForm.sysSurgSeq}
                                            dataSource="Global_Dir_Mv" aliasNo="2202" titleDialog="Sys Surg Seq"
                                            labelWidth="180px" width="25px"
                                            inValid={this.state.dAllItemFields["sysSurgSeq"]} itemNbr="2202"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["sysSurgSeq"] : ""}
                                        />

                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Neoadjuvant Therapy" field="neoadjTherapy"
                                            value={this.state.treatmentForm.neoadjTherapy ? this.state.treatmentForm.neoadjTherapy : this.state.treatmentForm.NeoadjTherapy}
                                            dataSource="Global_Dir_Mv" aliasNo="4461" titleDialog="Neoadjuvant Therapy"
                                            labelWidth="180px" width="25px"
                                            inValid={this.state.dAllItemFields["neoadjTherapy"]} itemNbr="4461"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["neoadjTherapy"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Neoadjuvant Therapy Clin Resp" field="neoadjTherapyClinicalResponse"
                                            value={this.state.treatmentForm.neoadjTherapyClinicalResponse ? this.state.treatmentForm.neoadjTherapyClinicalResponse : this.state.treatmentForm.NeoadjTherapyClinicalResponse}
                                            dataSource="Global_Dir_Mv" aliasNo="4462" titleDialog="Neoadj Therapy Clinical Response"
                                            labelWidth="180px" width="25px"
                                            inValid={this.state.dAllItemFields["neoadjTherapyClinicalResponse"]} itemNbr="4462"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["neoadjTherapyClinicalResponse"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Neoadjuvant Therapy Tx Effect" field="neoadjTherapyTxEffect"
                                            value={this.state.treatmentForm.neoadjTherapyTxEffect ? this.state.treatmentForm.neoadjTherapyTxEffect : this.state.treatmentForm.NeoadjTherapyTxEffect}

                                            dataSource="SSDI_Codes_Popup" aliasNo="4463"
                                            //Dynamic filters: Schema_id (C)|Equals|Schema_id
                                            flag={this.state.treatmentForm.schemaId ? this.state.treatmentForm.schemaId : this.state.treatmentForm.SchemaId}

                                            titleDialog="Neoadjuvant Therapy Treatment Effect"
                                            labelWidth="180px" width="25px"
                                            inValid={this.state.dAllItemFields["neoadjTherapyTxEffect"]} itemNbr="4463"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["neoadjTherapyTxEffect"] : ""}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "grid" }}>
                                <div style={{ display: "flex" }}>
                                    <div className="A5CWLayout TreatmentScreen">
                                        <div className="frameInner" style={{ width: "350px", height: "330px" }}>
                                            <div className="header">Surgery</div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="Dx Stg Date"
                                                        labelWidth="100px" width="80px"
                                                        field="ncdSurgDtSumm" lineStyle="oneLine"
                                                        value={this.state.treatmentForm.ncdSurgeryDate !== null && this.state.treatmentForm.ncdSurgeryDate !== "" ? moment(new Date(this.state.treatmentForm.ncdSurgeryDate)).format("MM/DD/YYYY") : ""}
                                                        itemNbr=""
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="Scp LN Surg"
                                                        labelWidth="100px" width="30px"
                                                        field="scopeLymphSurgSum" lineStyle="oneLine"
                                                        value={this.state.treatmentForm.scopeLymphSurgSum} itemNbr=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="Dx Stg Code"
                                                        labelWidth="100px" width="30px"
                                                        field="ncdSurgeryCode" lineStyle="oneLine"
                                                        value={this.state.treatmentForm.ncdSurgeryCode} itemNbr=""
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="Scp LN Surg Here"
                                                        labelWidth="100px" width="30px"
                                                        field="scopeLymphSurgHere" lineStyle="oneLine"
                                                        value={this.state.treatmentForm.scopeLymphSurgHere} itemNbr=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="Dx Stg Here"
                                                        labelWidth="100px" width="30px"
                                                        field="ncdSurgeryHere" lineStyle="oneLine"
                                                        value={this.state.treatmentForm.ncdSurgeryHere} itemNbr=""
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="Oth Reg Sites"
                                                        labelWidth="100px" width="30px"
                                                        field="surgRegionSitesSum" lineStyle="oneLine"
                                                        value={this.state.treatmentForm.surgRegionSitesSum} itemNbr=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="Surgery Date"
                                                        labelWidth="100px" width="30px"
                                                        field="surgDtSumm" lineStyle="oneLine"
                                                        value={this.state.treatmentForm.surgeryDate}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="Oth Reg Here"
                                                        labelWidth="100px" width="30px"
                                                        field="surgRegionSitesHere" lineStyle="oneLine"
                                                        value={this.state.treatmentForm.surgRegionSitesHere} itemNbr=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="Def Surg Dt"
                                                        labelWidth="100px" width="30px"
                                                        field="mostDefSurgDate" lineStyle="oneLine"
                                                        value={this.state.treatmentForm.mostDefSurgDateSumm}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="LN Removed"
                                                        labelWidth="100px" width="30px"
                                                        field="nbrNodesRemovedSumm" lineStyle="oneLine"
                                                        value={this.state.treatmentForm.nbrNodesRemovedSumm} itemNbr=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="Surgery Code"
                                                        labelWidth="100px" width="30px"
                                                        field={this.props.selectedPrimarySummary.diagnosisDateYear < 2023 ? "surgeryCode" : "surgSumm2023"}
                                                        lineStyle="oneLine"
                                                        value={this.props.selectedPrimarySummary.diagnosisDateYear < 2023 ? this.state.treatmentForm.surgeryCode : this.state.treatmentForm.surgSumm2023}
                                                        itemNbr=""
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="Days from Dx"
                                                        labelWidth="100px" width="30px"
                                                        field="daysDxToSurg" lineStyle="oneLine"
                                                        value={this.state.treatmentForm.daysDxToSurg}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="Surgery Here"
                                                        labelWidth="100px" width="30px"
                                                        field={this.props.selectedPrimarySummary.diagnosisDateYear < 2023 ? "cdSurgHere" : "surgHere2023"}
                                                        lineStyle="oneLine"
                                                        value={this.props.selectedPrimarySummary.diagnosisDateYear < 2023 ? this.state.treatmentForm.cdSurgHere : this.state.treatmentForm.surgHere2023}
                                                        itemNbr=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="Surg Disch Dt"
                                                        labelWidth="100px" width="30px"
                                                        field="surgDischDtSumm" lineStyle="oneLine"
                                                        value={this.state.treatmentForm.surgDischDtSummDsp}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="Readmit 30 Days"
                                                        labelWidth="100px" width="30px"
                                                        field="surgReadmit30DaysSum" lineStyle="oneLine"
                                                        value={this.state.treatmentForm.surgReadmit30DaysSum} itemNbr=""
                                                    />
                                                </div>
                                                <div className="col-md-6">                                                    
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                </div>
                                                <div className="col-md-6">
                                                    <button onClick={this.editItem} name="Surgery">Edit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="A5CWLayout TreatmentScreen">
                                        <div className="frameInner" style={{ width: "480px", height: "330px" }}>
                                            <div className="header">Radiation</div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="Rad Start Date"
                                                        labelWidth={Global.LabelWidth} width="auto"
                                                        field="radStartDateSumm" lineStyle="oneLine"
                                                        value={this.state.treatmentForm.radStartDateSummDsp} />
                                                </div>
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="Elapsed Days" lineStyle="oneLine"
                                                        labelWidth={Global.LabelWidth} width="45px"
                                                        field="radElapDays" value={this.state.treatmentForm.radElapDays} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Input type="label" labelWidth={Global.LabelWidth} labelText="Rad End Date"
                                                        field="radEndDateSumm" lineStyle="oneLine"
                                                        value={this.state.treatmentForm.radEndDateSummDsp} />
                                                </div>
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="Days From Dx"
                                                        labelWidth={Global.LabelWidth} width="45px" lineStyle="oneLine"
                                                        field="radDaysFromDx" value={this.state.treatmentForm.radDaysFromDx} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Input type="label" labelText="Total Dose"
                                                        labelWidth={Global.LabelWidth} width="60px" lineStyle="oneLine"
                                                        field="totalDose" value={this.state.treatmentForm.totalDose} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: "60px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Phase" field="phase"
                                                                    />
                                                                </th>
                                                                <th style={{ width: "60px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Prim Vol" field="radPrimTxVol"
                                                                    />
                                                                </th>
                                                                <th style={{ width: "60px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Drain LN" field="radDrainLn"
                                                                    />
                                                                </th>
                                                                <th style={{ width: "60px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Modality" field="radTxModality"
                                                                    />
                                                                </th>
                                                                <th style={{ width: "60px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Ext Tech" field="radExtBeamTech"
                                                                    />
                                                                </th>
                                                                <th style={{ width: "60px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Tot Dose" field="radTotalDose"
                                                                    />
                                                                </th>
                                                                <th style={{ width: "60px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Nbr Fract" field="radNbrFractions"
                                                                    />
                                                                </th>
                                                                <th style={{ width: "60px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Dose/F" field="radDoseFraction"
                                                                    />
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>I</td>
                                                                <td>{this.state.treatmentForm.phaseIRadPrimTxVol}</td>
                                                                <td>{this.state.treatmentForm.phaseIRadDrainLn}</td>
                                                                <td>{this.state.treatmentForm.phaseIRadTxModality}</td>
                                                                <td>{this.state.treatmentForm.phaseIRadExtBeamTech}</td>
                                                                <td>{this.state.treatmentForm.phaseIRadTotalDose}</td>
                                                                <td>{this.state.treatmentForm.phaseIRadNbrFractions}</td>
                                                                <td>{this.state.treatmentForm.phaseIRadDoseFraction}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>II</td>
                                                                <td>{this.state.treatmentForm.phaseIiRadPrimTxVol}</td>
                                                                <td>{this.state.treatmentForm.phaseIiRadDrainLn}</td>
                                                                <td>{this.state.treatmentForm.phaseIiRadTxModality}</td>
                                                                <td>{this.state.treatmentForm.phaseIiRadExtBeamTech}</td>
                                                                <td>{this.state.treatmentForm.phaseIiRadTotalDose}</td>
                                                                <td>{this.state.treatmentForm.phaseIiRadNbrFractions}</td>
                                                                <td>{this.state.treatmentForm.phaseIiRadDoseFraction}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>III</td>
                                                                <td>{this.state.treatmentForm.phaseIiiRadPrimTxVol}</td>
                                                                <td>{this.state.treatmentForm.phaseIiiRadDrainLn}</td>
                                                                <td>{this.state.treatmentForm.phaseIiiRadTxModality}</td>
                                                                <td>{this.state.treatmentForm.phaseIiiRadExtBeamTech}</td>
                                                                <td>{this.state.treatmentForm.phaseIiiRadTotalDose}</td>
                                                                <td>{this.state.treatmentForm.phaseIiiRadNbrFractions}</td>
                                                                <td>{this.state.treatmentForm.phaseIiiRadDoseFraction}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <br /><br /><br /><br />
                                            </div>
                                            <div className="emptyLine"></div>

                                            <div style={{ marginTop: "4px" }}>
                                                <button onClick={this.editItem} name="Radiation">Edit</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="A5CWLayout TreatmentScreen">
                                        <div className="frameInner" style={{ width: "186px", height: "330px" }}>
                                            <div className="header">Chemo</div>
                                            <div className="">
                                                <Input type="label" labelText="Chemo Start Dt"
                                                    labelWidth="95px" width="80px"
                                                    field="chemoStartDateSumm"
                                                    value={this.state.treatmentForm.chemoStartDateSummDsp}
                                                />
                                                <Input type="label" labelText="Chemo Code"
                                                    labelWidth="95px" width="45px"
                                                    field="chemoCode" value={this.state.treatmentForm.chemoCode} />
                                                <Input type="label" labelText="Chemo Here"
                                                    labelWidth="95px" width="45px"
                                                    field="chemoHere" value={this.state.treatmentForm.chemoHere} />
                                                <Input type="label" labelText="Days From Dx"
                                                    labelWidth="95px" width="50px"
                                                    field="chemoDaysFromDx" value={this.state.treatmentForm.chemoDaysFromDx} />
                                                <Input type="label" labelText="Elapsed Days"
                                                    labelWidth="95px" width="50px"
                                                    field="elapsedDaysChemo" value={this.state.treatmentForm.elapsedDaysChemo} />
                                            </div>

                                            <div className="row"><br/><br/><br/><br/><br/><br/></div>
                                            <div className="emptyLine"></div>
                                            <div className="emptyLine"></div>

                                            <div className="">
                                                <button onClick={this.editItem} name="Chemo">Edit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div className="A5CWLayout TreatmentScreen">
                                        <div className="frameInner" style={{ width: "203px", height: "168px" }}>
                                            <div className="header">Hormone</div>
                                            <div className="">
                                                <Input type="label" labelText="Horm Start Dt"
                                                    labelWidth="85px" width="80px"
                                                    field="hormStartDateSumm"
                                                    value={this.state.treatmentForm.hormStartDateSummDsp}
                                                />
                                                <Input type="label" labelText="Horm Code"
                                                    labelWidth="85px" width="45px"
                                                    field="hormCode" value={this.state.treatmentForm.hormCode} />
                                                <Input type="label" labelText="Horm Here"
                                                    labelWidth="85px" width="45px"
                                                    field="hormHere" value={this.state.treatmentForm.hormHere} />
                                                <Input type="label" labelText="Days From Dx"
                                                    labelWidth="85px" width="50px"
                                                    field="hormDaysFromDx" value={this.state.treatmentForm.hormDaysFromDx} />
                                            </div>
                                            <div className="">
                                                <button onClick={this.editItem} name="Hormone">Edit</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="A5CWLayout TreatmentScreen">
                                        <div className="frameInner" style={{ width: "203px", height: "168px" }}>
                                            <div className="header">Immunotherapy</div>
                                            <div className="">
                                                <Input type="label" labelText="Immuno Start Dt"
                                                    labelWidth="110px" width="80px"
                                                    field="immunoStartDateSumm"
                                                    value={this.state.treatmentForm.immunoStartDateSummDsp}
                                                />
                                                <Input type="label" labelText="Immuno Code"
                                                    labelWidth="110px" width="30px"
                                                    field="immunoCode" value={this.state.treatmentForm.immunoCode} />
                                                <Input type="label" labelText="Immuno Here"
                                                    labelWidth="110px" width="30px"
                                                    field="immunoHere" value={this.state.treatmentForm.immunoHere} />
                                                <Input type="label" labelText="Days From Dx"
                                                    labelWidth="110px" width="45px"
                                                    field="daysDxToImmuno" value={this.state.treatmentForm.daysDxToImmuno} />
                                            </div>
                                            <div className="">
                                                <button onClick={this.editItem} name="Immunotherapy">Edit</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="A5CWLayout TreatmentScreen">
                                        <div className="frameInner" style={{ width: "208px", height: "168px" }}>
                                            <div className="header">Hematopoietic</div>
                                            <div className="">
                                                <Input type="label" labelText="Hema Date"
                                                    labelWidth={Global.LabelWidth} width="auto"
                                                    field="hemaDateSumm"
                                                    value={this.state.treatmentForm.hemaDateSummDsp}
                                                />
                                                <Input type="label" labelText="Hema Code"
                                                    labelWidth={Global.LabelWidth} width="30px"
                                                    field="hemaCodeSumm" value={this.state.treatmentForm.hemaCodeSumm} />
                                                <Input type="label" labelText="Days From Dx"
                                                    labelWidth={Global.LabelWidth} width="45px"
                                                    field="daysDxToHema" value={this.state.treatmentForm.daysDxToHema} />

                                                <div className="emptyLine"></div>
                                                <Input type="label" labelText=""
                                                    labelWidth={Global.LabelWidth} width="45px"
                                                    field="id" value="" />
                                            </div>
                                            <div className="">
                                                <button onClick={this.editItem} name="Hematopoietic">Edit</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="A5CWLayout TreatmentScreen">
                                        <div className="frameInner" style={{ width: "208px", height: "168px" }}>
                                            <div className="header">Palliative</div>
                                            <div className="">
                                                <Input type="label" labelText="Palliative Dt"
                                                    labelWidth="100px" width="80px"
                                                    field="palDateSumm"
                                                    value={this.state.treatmentForm.palDateSummDsp}
                                                />
                                                <Input type="label" labelText="Palliative Here"
                                                    labelWidth={Global.LabelWidth} width="30px"
                                                    field="palliativeHere" value={this.state.treatmentForm.palliativeHere} />
                                                <Input type="label" labelText="Palliative Sum"
                                                    labelWidth={Global.LabelWidth} width="30px"
                                                    field="palliativeSum" value={this.state.treatmentForm.palliativeSum} />
                                                <Input type="label" labelText="Days From Dx"
                                                    labelWidth="100px" width="45px"
                                                    field="daysDxToPall" value={this.state.treatmentForm.daysDxToPall} />
                                            </div>
                                            <div className="">
                                                <button onClick={this.editItem} name="Palliative">Edit</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="A5CWLayout TreatmentScreen">
                                        <div className="frameInner" style={{ width: "186px", height: "168px" }}>
                                            <div className="header">Other</div>
                                            <div className="">
                                                <Input type="label" labelText="Oth Tx Start Dt"
                                                    labelWidth="95px" width="80px"
                                                    field="othRxStartDateSumm"
                                                    value={this.state.treatmentForm.othRxStartDateSummDsp}
                                                />
                                                <Input type="label" labelText="Oth Tx"
                                                    labelWidth="95px" width="45px"
                                                    field="othRxCode" value={this.state.treatmentForm.othRxCode} />
                                                <Input type="label" labelText="Oth Tx Here"
                                                    labelWidth="95px" width="45px"
                                                    field="othRxCodeHere" value={this.state.treatmentForm.othRxCodeHere} />
                                                <Input type="label" labelText="Days From Dx"
                                                    labelWidth="95px" width="50px"
                                                    field="daysDxToOth" value={this.state.treatmentForm.daysDxToOth} />
                                            </div>
                                            <div className="">
                                                <button onClick={this.editItem} name="Other">Edit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>

                        <div className="emptyLine"></div>

                        <div className="row">                            
                        </div>

                        <div className="emptyLine"></div>
                    </form>

                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetTreatment} formId="treatmentForm"
                        isFormDirty={this.state.isFormDirty}
                        showExtra={[{ name: "Text", action: "showDiagnosisText" }]}
                        callbackToParent={this.updateSaveFlagToProcess}
                        focusingField={this.state._focusingField}
                    />

                </div>
            )
            : <p className="data_loading_page">Treatment data loading...</p>;
        return (
            <React.Fragment>
                {treatmentSummary}

                <div>
                    {
                        this.state.showConfirmationMsg ?
                            /*
                            <Dialog title="Warning"
                                onClose={this.hideConfirmationMsg}
                                width={450} height={"auto"}
                                //className={classes.title}>
                            */
                            <div className="CRStar_Window">
                                <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar">
                                    <div className="dragableWindowCls">
                                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                                            <div className="k-window-title k-dialog-title">Warning</div>
                                            <div className="k-window-actions k-dialog-actions">
                                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                    <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmationMsg(e) }}></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="emptyLine"></div>
                                        <div className="container">
                                            <label>The current values on the Treatment Summary screen will be saved prior to opening an additional screen.</label>
                                        </div>
                                        <div className="emptyLine"></div>
                                        <div className="container right">
                                            <button onClick={this.editChildForm} value="yes" autoFocus>OK</button>
                                            <button onClick={this.hideConfirmationMsg} value="no">Cancel</button>
                                        </div>
                                        <div className="emptyLine"></div>
                                    </div>
                                    {/*</Dialog>*/}
                                </Draggable>
                            </div>
                        : null
                    }
                </div>

                <div id="textareaEditorForItemsCls">
                    {
                        this.props.isShowTextDialogVisible ?
                            /*
                            <Dialog
                                title="Diagnosis Text &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Treatment &amp; Other Text"
                                onClose={this.props.closeTextDialog}
                                width={950} height={"auto"}
                            className={classes.title}>
                            */

                            <div className="CRStar_Window">
                                <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar">
                                    <div className="dragableWindowCls">
                                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                                            <div className="k-window-title k-dialog-title">
                                                <pre className="textPopupPreTitleCls">{TextPopup.Title}</pre>
                                            </div>
                                            <div className="k-window-actions k-dialog-actions">
                                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeTextDialog(e) }}></span>
                                                </a>
                                            </div>
                                        </div>
                                        <TextForm closeWindow={this.closeTextDialog} setFocusingField={this.helpFocusingField} />
                                    </div>
                                    {/*</Dialog>*/}
                                </Draggable>
                            </div>
                            : null
                    }
                </div>

            </React.Fragment>
        );
    }
}

TreatmentMaintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedTreatmentRecord: state.patientAbstract.selectedTreatmentRecord,
        isPatientLoading: state.patientAbstract.isPatientLoading,

        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,

        selectedPatient: state.patientAbstract.selectedPatient,
        isShowTextDialogVisible: state.patientAbstract.isShowTextDialogVisible,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,

        initialAbstractPages: state.patientAbstract.initialAbstractPages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        setChildDialogVisible: (flag) => dispatch(actionCreators.setChildDialogVisible(flag)),

        selectSurgeryRecord: (medical) => dispatch(actionCreators.selectSurgeryRecord(medical)),
        //selectRadiationRecord: (medical) => dispatch(actionCreators.selectRadiationRecord(medical)),

        saveTreatment: (treatmentData) => dispatch(actionCreators.saveTreatment(treatmentData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),

        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentMaintenance);