/* eslint-disable */
import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import axios from "../../../axios-instance";
import Draggable from 'react-draggable';
import "./RequestLog.css";

class RequestLog extends Component {
    clockInterval = "";
    downloadFile = false;
    state = {
        beginDate: "", //01/01/2022
        endDate: "", //08/12/2022
        outputType: "Screen",

        htmlOutput: null,
        startTime: null,
        endTime: null,
        statusBar: "Start Time: ",

        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        loaded: true,
        titleDialog: null,
        showClockTimer: false,
        reportStatus: "Not Running", //This variable is used to control whether clock timers continue, reports display/download etc. -- "Not Running" == initial state | "Cancelled" == user has clicked the cancel button after attempting to run a report | "Report Running" == Report in the C# file has been called, but no response back recognized from it | "Report Finished" == Report in the C# function has successfully completed
    }

    RequestLog_Date_Format(myDate) {
        //This code tries to fill in slashes and the two digit century when a user doesn't enter them
        var fmtDate = "";

        myDate = myDate.replace(/[-]/gi, '/');

        if ((myDate.length == 6 || myDate.length == 7 || myDate.length == 8 || myDate.length == 9) && myDate.split('/').length == 3) {
            var mm = myDate.split('/')[0];
            var dd = myDate.split('/')[1];
            var yy = myDate.split('/')[2];

            myDate = ('00' + mm).substr(mm.length, 2) + '/' + ('00' + dd).substr(dd.length, 2) + '/' + yy;
        }

        myDate = myDate.replace(/[^0-9-/]/gi, '');

        if (myDate.length == 8 && myDate.indexOf('/') == -1) {
            fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/" + myDate.substr(4, 4);
        } else if (myDate.length == 8 && myDate.indexOf('/') >= 1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(6, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 6) + "19" + myDate.substr(6, 2);
            } else {
                fmtDate = myDate.substr(0, 6) + "20" + myDate.substr(6, 2);
            }
        } else if (myDate.length == 6 && myDate.indexOf('/') == -1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(4, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/19" + myDate.substr(4, 2);
            } else {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/20" + myDate.substr(4, 2);
            }
        } else {
            fmtDate = myDate;
        }

        return fmtDate;
    }

    setStateBeginDate = (event) => {
        const name = event.target.name;
        var value = event.target.value;
        const oldValue = this.state.beginDate;

/*        value = this.RequestLog_Date_Format(value);*/

        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;

        this.setState({
            beginDate: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateEndDate = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.endDate;
        //var dialogFormData = this.state.dialogForm;
        //dialogFormData[name] = value;

        this.setState({
            endDate: value,
            //dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateOutputType = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.outputType;
        var dialogFormData = this.state.dialogForm;
        console.log("Output Type: " + name);
        dialogFormData[name] = value;

        this.setState({
            outputType: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    componentDidMount() {
        var _this = this;
        //await _this.props.generateRequestLog();  //The report doesn't run without this code, but why?  What does this function actually do?
        _this.props.generateRequestLog();

        if (!this.state.dialogForm) { // && !this.props.requestLogInfo
            this.setFormData(this.props.requestLogInfo);
        }
    }

    componentWillMount() {

    }
 
    componentDidUpdate(prevProps, prevState) {
        var requestLogInfo = this.props.requestLogInfo;
        const prevNotExists = !prevProps.requestLogInfo || !prevProps.requestLogInfo.reportStatus || prevProps.requestLogInfo.reportStatus != "Report Finished";
        const exists = requestLogInfo && requestLogInfo.reportStatus == "Report Finished";
        //const exists = (requestLogInfo && requestLogInfo.reportStatus == "Report Finished") || this.state.reportStatus == "Report Finished";
        console.log('### prevNotExists', prevNotExists);
        console.log('### exists', exists);
        if (this.state.dialogForm && (prevProps.requestLogInfo && this.props.requestLogInfo && (prevProps.requestLogInfo.startTime !== this.props.requestLogInfo.startTime
            || prevProps.requestLogInfo.endTime !== this.props.requestLogInfo.endTime
        ))){
        /*if (prevNotExists && exists) {*/
            console.log("### DID EXECUTE");
            clearInterval(this.clockInterval); //If the report has finished, clear the clock interval
            this.setState({
                showClockTimer: false, //If the report has finished, hide the clock
                reportStatus: "Report Finished",
            });
            console.log(requestLogInfo.outputType + "### Output: " + requestLogInfo.urlOutputFile);
            if (requestLogInfo.outputType == "Screen" && requestLogInfo.htmlOutput) //If the report has finished, it was sent to the screen, and we have html data returned, display the report to the screen
                this.displayReport(requestLogInfo);
            else if (requestLogInfo.outputType == "Excel" && requestLogInfo.urlOutputFile) { //If the report has finished, it was sent to Excel, and we have a url/path to the Excel file returned by the report, download the file
                var fileURL = this.props.clientgroup + "/DownloadFile";
                this.downloadxlsx(fileURL, requestLogInfo.urlOutputFile); //Download the Excel file produced by the report
                this.downloadFile = true;
            }
        }
    }

    downloadxlsx(fileURL, urlOutputFile) {
        axios.post(fileURL, {
            method: 'POST',
            responseType: 'blob',//important
            urlOutputFile: urlOutputFile,
            accessToken: localStorage.getItem("token")
        }).then((response) => {
            var fileName = urlOutputFile.substring(urlOutputFile.lastIndexOf('\\') + 1);
            const link = document.createElement('a');

            let excel;
            if (urlOutputFile.indexOf('/') != -1) {
                excel = urlOutputFile.substring(urlOutputFile.lastIndexOf('/') + 1);
            } else {
                excel = urlOutputFile.substring(urlOutputFile.lastIndexOf('\\') + 1);
            }
            link.href = this.props.clientgroup + "/" + excel;

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return false;
        })
        .catch(function (error) {
            console.log(error);
        });

        return false;
    }

    setFormData = (requestLogInfo) => {
        this.setState({
            initialDialogForm: { ...requestLogInfo },
            dialogForm: { ...requestLogInfo },

            //["outputType"]: requestLogInfo && requestLogInfo.sendReportTo ? requestLogInfo.sendReportTo : "Screen",
            reload: false,
            changed: true,
            showClockTimer: false,
            titleDialog: null,
            isDrawingCharts: false,
        });
        console.log("Report Status: ", this.state.reportStatus);

        this.setState({ reportStatus: "Not Running", });
        return false;
    }

    handleOnFocus = (event, index, alias) => {

    }

    handleFormChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.outputType
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;

        this.setState({
            beginDate: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });


        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                [event.target.name]: event.target.value
            },
            isFormDirty: true,
         });
    }

    handleLostFocus = (event) => {
        const name = event.target.name;
        console.log("This value of beginDate is: " + event.target.value + " Name: " + name);
        console.log("Reformatted beginDate is: " + this.RequestLog_Date_Format(event.target.value));

        if (event.target.name == "beginDate") {
            this.setState({
                beginDate: this.RequestLog_Date_Format(event.target.value),
            });
        }
        if (event.target.name == "endDate") {
            this.setState({
                endDate: this.RequestLog_Date_Format(event.target.value),
            });
        }
    }

    Run_Request_Log = () => {
        //Collate all values needed by the report into one object, dialogFormData, and pass it to the report.
        var dialogFormData = this.state.dialogForm;

        dialogFormData.reportStatus = "Report Running";
        dialogFormData.beginDate = this.state.beginDate; //Putting this code here, since the onChange events won't set this for default values
        dialogFormData.endDate = this.state.endDate;
        dialogFormData.outputType = this.state.outputType;

        dialogFormData.reportStatus = this.state.reportStatus;
        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;

        //alert("Running Request Log with 'This dialog' values of: " + JSON.stringify(this.state.dialogForm, null, 4))
        console.log("Running Request Log with 'This' values of: " + JSON.stringify(this.state.dialogForm, null, 4));

        this.props.runRequestLog(dialogFormData);
        
        this.downloadFile = false;//reset this flag to get download new file

        return false;
    }

    //on before show event
    showClockEvent(e) {
        try {
            e.preventDefault();// This function prevents the form from being auto-submitted
        } catch (ex) {

        }

        let beginDate = this.state.beginDate;
        let endDate = this.state.endDate;
        if ((beginDate && !moment(beginDate, "MM/DD/YYYY", true).isValid()) || (endDate && !moment(endDate, "MM/DD/YYYY", true).isValid())) {
            alert('Please input a valid date');
            if (!moment(beginDate, "MM/DD/YYYY", true).isValid() && beginDate) {
                document.getElementById("beginDate") ? document.getElementById("beginDate").focus() : null;
            } else if (!moment(endDate, "MM/DD/YYYY", true).isValid() && endDate) {
                document.getElementById("endDate") ? document.getElementById("endDate").focus() : null;
            }
            return false;
        }

        var _this = this;
        _this.setState({ showClockTimer: true, startTime: new Date().toLocaleTimeString() }); // set clock timer...

        this.Run_Request_Log();
        //alert("Clock causing reload!");

        this.sInVal = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(this.sInVal);
            } else {
                console.log("Timer Interval Running");
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
                _this.setState({ currentTime: new Date() ? new Date().toLocaleTimeString() : " " });
            }
        }, 1000);

        return false; // Returns false to stop everything after this event has been completed

    }

    Cancel_Report = (e, _this) => {
        _this.setState({ showClockTimer: false });
        clearInterval(this.clockInterval);
        return false;
    }


    displayReport = (data) => {
        console.log("Display: " + this.isDrawingCharts);
        this.setState({
            showClockTimer: false,//hide clock timer
            endTime: new Date().toLocaleTimeString(),
            htmlOutput: data.htmlOutput, //Commenting out because I think this already gets set in the data tunnel, but will have to test
            isDrawingCharts: true //Eventually can change this variable name to "displayReport" after the RequestLog is working
        });
        return false;
    }


    hideDrawingCharts = (e) => {
        //try {
        //    e.preventDefault();  //What "default" action is being prevented by having this code here?
        //} catch (ex) { }
        this.setState({ isDrawingCharts: false });
        return false;
    }


    newRecord(e) {
        try {
            this.setState({
                beginDate: "",
                endDate: "",
                outputType: "Screen",

                htmlOutput: null,
                startTime: null,
                endTime: null,
                statusBar: "",

                isFormDirty: false,
            })
        } catch (ex) {}
    }


    printReport = (e) => {
        var printStyle = document.getElementById('PrintStyle');
        var printContent = document.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Request Log</title><style type="text/css">';
        html += printStyle.innerHTML + '</style></head><body><div id="printableContent">';
        html += printContent.innerHTML + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }

    render() {
        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTime}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let drawingCharts;
        {
            drawingCharts = this.state.isDrawingCharts ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="requestLogReportDisplay" className="dragableLargestWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Request Log</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingCharts(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="container" id="requestLogShowingCharts">
                                {/*Drawing table here*/}
                                <div dangerouslySetInnerHTML={{ __html: this.state.htmlOutput ? this.state.htmlOutput : "" }} className="" />
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12" style={{ display: "flex" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ScreenButton ScreenButtonHover" title=""
                                            onClick={(e) => { this.printReport(e) }}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print
                                        </button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ScreenButton ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={(e) => { this.hideDrawingCharts(e) }} value="exit" autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let dialogForm = <div className="reportDialogForm">
            <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                <div className="reportDialogFormContent">
                    <div className="reportScreenTitle">Request Log</div>
                    {this.state.dialogForm || this.state.loaded ?

                    <form onSubmit={false} id="requestLogForm" className="">
                        <div className="frameInner">
                            <div id="beginDateDiv">
                                <label>Begin Date:</label>
                                    <input type="text" id="beginDate" name="beginDate"
                                        placeholder="mm/dd/yyyy"
                                        value={this.state.beginDate}
                                        onChange={(e) => this.setState({ beginDate: e.target.value })}
                                        onBlur={this.handleLostFocus}
                                    labelText="Begin Date: "
                                    field="beginDate"
                                />
                                </div>

                                <div id="endDateDiv">
                                    <label>End Date:</label>
                                    <input type="text" id="endDate" name="endDate"
                                        placeholder="mm/dd/yyyy"
                                        value={this.state.endDate}
                                        onChange={(e) => this.setState({ endDate: e.target.value })}
                                        onBlur={this.handleLostFocus}
                                    />
                                </div>

                                <br />
                        <div className="row">

                            <div className="outputDeviceRadio">
                                <div id="outputDeviceLabel" style={{ verticalAlign: "middle", border: "none" }}>Send Report To:</div>
                                <label style={{ width: "240px", height: "0px", cursor: "pointer"  }}>
                                            <input type="radio" onClick={this.setStateOutputType}
                                            checked={this.state.outputType === "Excel"}
                                            name="outputType" field="outputType" value="Excel"
                                            style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                        Excel
                                    </label>
                                    <br />
                                <label style={{ width: "240px", height: "0px", cursor: "pointer"  }}>
                                            <input type="radio" onClick={this.setStateOutputType}
                                            checked={this.state.outputType === "Screen"}
                                            name="outputType" field="outputType" value="Screen"
                                            style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                        Screen
                                    </label>
                                </div>
                        </div>
                        <br />

                        <div id="CONTAINER_JS_CLOCK" className="hidden">
                            <label>Selecting Cases</label>
                            <div id="IMAGE_WAIT" style={{ color: "black", fontWeight: "bold" }}>
                                Selecting Cases - Please Wait ...
                            </div>
                        </div>
                    </div>

                        <div className="reportScreenStatusBar">
                                Start Time: {this.state.startTime} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; End Time: {this.state.endTime}
                        </div>

                        <fieldset className="reportDialogFormButtonBar">
                            <button id="BUTTON_RUN"
                                className="ErsGrBlueButton"
                                onClick={(e) => { this.showClockEvent(e) }}
                                //disabled={!this.state.isFormDirty}
                            >
                                <img id="BUTTON_RUN_ICON"
                                    src="https://crstar.ers-can.com/images/$$code.action.run.png.a5image"
                                    style={{ verticalAlign: "middle", border: "none" }}></img>
                                Run
                            </button>

                            <button id="BUTTON_NEWRECORD"
                                className={this.state.isFormDirty ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                onClick={this.newRecord.bind(this)}
                                //onClick={(e) => { this.showClockEvent(e) }}
                                disabled={!this.state.isFormDirty}
                            >
                                <img id="BUTTON_NEWRECORD_ICON"
                                    src={this.state.isFormDirty ? "https://crstar.ers-can.com/images/$$navigate.new.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$navigate.new.png.a5image"}
                                    style={{ verticalAlign: "middle", border: "none" }}></img>
                                New
                            </button>
                        </fieldset>
                    </form>
                    : <p>Request Log report screen encountered an issue and cannot load ...</p>}
                </div>
            </div>
        </div>
 
        return (
            <React.Fragment>
                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {drawingCharts}
                </React.Fragment>

                <React.Fragment>
                    {dialogForm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        requestLogInfo: state.patientAbstract.requestLogInfo,           //This holds information passed from the C# report back to the screen
        clientgroup: state.auth.clientgroup,                            //Passed to C# report to get path
        userId: state.auth.userId,                                      //Passed to C# report to get path

        initialPageVisible: state.patientAbstract.initialPageVisible,   //Controls if security restrictions are set to prevent page from loading
        isDialogLoading: state.patientAbstract.isDialogLoading,
        waitingFor: state.patientAbstract.waitingFor,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        runRequestLog: (dialogForm) => dispatch(actionCreators.runRequestLog(dialogForm)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
        generateRequestLog: (flag) => dispatch(actionCreators.generateRequestLog(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestLog);