/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import "./TextForm.css";
import Input from "../../../UI/Input";
import "../PatientMaintenance/PatientMaintenance.css";
import "./DiagnosisMaintenance.css";

class TextForm extends Component {

    state = {
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        currentLength: 0,
        maxLength: 1000,
        warning: false,
        focusedItem: "primSiteText",
    }

    closeWindow = (event) => {
        try {
            event.preventDefault();
        } catch (ex) { }
                
        this.props.closeTextDialog();

        // Fixing #PR00292
        if (this.props.setFocusingField) {
            this.props.setFocusingField(null);
        }

        // set focus back into parent page
        setTimeout(function () {
            document.getElementById("extra_btn_0") ? (document.getElementById("extra_btn_0").nextSibling ? document.getElementById("extra_btn_0").nextSibling.focus() : document.getElementById("extra_btn_0").focus()) : "";
        }, 100);
        return false;
    }

    doubleClickHandler = (event) => {
        event.preventDefault();

        var focusingField = event.target;

        var currHeight = focusingField.style.height.replace(/[^0-9]/gi, '');
        var expandHeight = 320;//{ dialog.object }.stateInfo.Text_Expand_Height;
        var minimizeHeight = 320;//{ dialog.object }.stateInfo.Text_Minimize_Height;

        if (currHeight >= expandHeight) {
            focusingField.style.height = minimizeHeight + 'px';
        } else {
            focusingField.style.height = expandHeight + 'px';
        }

        return false;
    }

    componentDidMount() {
        if (!this.state.dialogForm && this.props.selectedDiagnosisText) {
            this.setFormData();
        }

        // set style to expander
        var focusedItem = this.state.focusedItem ? this.state.focusedItem : "primSiteText";
        var textPadForm = document.getElementById("diagnosisTextForm");
        if (textPadForm) {
            const ele = textPadForm.querySelectorAll('textarea');
            for (let i = 0; i <= ele.length - 1; i++) {
                if (ele[i].name !== focusedItem)
                    ele[i].setAttribute("class", "");
            }
            focusedItem = textPadForm.querySelector(focusedItem);// ? textPadForm.querySelector(focusedItem) : document.getElementById(focusedItem);
            if (focusedItem && focusedItem.getAttribute("class").indexOf("expanderCls") == -1) {
                setTimeout(function () {
                    focusedItem.setAttribute("class", "expanderCls");
                }, 100);
            }
        }
        return false;
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.dialogForm && this.props.selectedDiagnosisText)
            || (prevProps.selectedDiagnosisText !== this.props.selectedDiagnosisText)) {
            this.setFormData();
        }

        // set style to expander
        var focusedItem = this.state.focusedItem ? this.state.focusedItem : "primSiteText";
        var textPadForm = document.getElementById("diagnosisTextForm");
        if (textPadForm) {
            const ele = textPadForm.querySelectorAll('textarea');
            for (let i = 0; i <= ele.length - 1; i++) {
                if (ele[i].name !== focusedItem)
                    ele[i].setAttribute("class", "");
            }
            focusedItem = textPadForm.querySelector(focusedItem);// ? textPadForm.querySelector(focusedItem) : document.getElementById(focusedItem);
            if (focusedItem && focusedItem.getAttribute("class").indexOf("expanderCls") === -1) {
                setTimeout(function () {
                    focusedItem.setAttribute("class", "expanderCls");
                    focusedItem.style.height = "320";
                    console.log("focusedItem=" + focusedItem);
                }, 100);
            }
        }
        return false;
    }

    setFormData = () => {
        this.setState({
            initialDialogForm: { ...this.props.selectedDiagnosisText },
            dialogForm: { ...this.props.selectedDiagnosisText },
            reload: false
        });
    }

    saveTextDialog = (event) => {
        event.preventDefault();

        // TODO Form validation
        var dialogFormData = this.state.dialogForm;
        if (true) {
            this.props.saveTextDialog(dialogFormData);
        }

        // Fixing #PR00292
        if (this.props.setFocusingField) {
            this.props.setFocusingField(null);
        }

        // set focus back into parent page
        setTimeout(function () {
            document.getElementById("extra_btn_0") ? (document.getElementById("extra_btn_0").nextSibling ? document.getElementById("extra_btn_0").nextSibling.focus() : document.getElementById("extra_btn_0").focus()) : "";

            //
            var stagingPre2018Form = document.getElementById("stagingPre2018Form");
            if (stagingPre2018Form) {
                if (stagingPre2018Form.querySelector("#ajccStagingText")) {
                    stagingPre2018Form.querySelector("#ajccStagingText").value = dialogFormData["ajccStagingText"] ? dialogFormData["ajccStagingText"] : "";
                }
                if (stagingPre2018Form.querySelector("#stageText")) {
                    stagingPre2018Form.querySelector("#stageText").value = dialogFormData["stageText"] ? dialogFormData["stageText"] : "";
                }
            }
        }, 100);
        return false;
    }

    resetForm = () => {
        this.setState({
            dialogForm: { ...this.state.initialDialogForm },
            isFormDirty: false,
            reload: false
        });
    }

    collapseText(componentName, currentField) {
        var minimizeHeight = 50;
        var textFields = [
            'dxComments', 'primSiteText', 'histologyText', 'placeDxText', 'phyExamText',
            'xrayScanText', 'scopesText', 'labsText', 'opText', 'pathText', 'surgeryText',
            'beamRadText', 'othRadText', 'chemoText', 'hormText', 'immunoText', 'hemaText',
            'stageText', 'ajccStagingText', 'folComments', 'transferText', 'importedText'
        ];
        for (var i = 0; i < textFields.length; i++) {
            var textField = textFields[i];
            if (textField != currentField) {
                var minHeight = minimizeHeight;
                if (textField == 'primSiteText' || textField == 'histologyText') { minHeight = 40 };
                if (textField == 'placeDxText') { minHeight = 30 };

                document.getElementById(textField).style.transition = 'height 0.3s';
                document.getElementById(textField).style.height = minHeight + 'px';
            }
        }
    }

    handleOnFocus = (event) => {
        event.preventDefault();

        const currentInput = event.target.name;
        const currentValue = event.target.value;
        const currentLength = currentValue.length;
        let maxLength = event.target.getAttribute('data-maxlen');
        maxLength = (maxLength === null || maxLength === "") ? 1000 : maxLength;

        let isOverLength = false;
        if (currentLength > maxLength) {
            isOverLength = true;
        }

        this.setState({
            currentLength: currentLength,
            maxLength: maxLength,
            warning: isOverLength,
            focusedItem: currentInput,
        });

        // Fixing #PR00292
        if (this.props.setFocusingField) {
            this.props.setFocusingField(currentInput);

            //
            let itemNbr = event.target.getAttribute("data-help-id");
            if (itemNbr == null || itemNbr == undefined) {
                itemNbr = event.target.getAttribute("data-key");
            }
            this.props.setItemNbr(itemNbr ? itemNbr : "");
        }

        // clear expander class for other fields
        var textPadForm = document.getElementById("diagnosisTextForm");
        if (textPadForm && textPadForm.querySelectorAll("textarea")) {
            textPadForm.querySelectorAll("textarea").forEach((ta, index) => {
                ta.getAttribute("class").indexOf("expanderCls") !== -1 ? ta.setAttribute("class", "") : "";
            });
        }

        // set style to expander
        if (event.target.getAttribute("class").indexOf("expanderCls") === -1) {
            var taField = event.target;
            setTimeout(function () {
                taField.setAttribute("class", "expanderCls");
                textPadForm.querySelector("#LBL-" + taField.name) ? textPadForm.querySelector("#LBL-" + taField.name).scrollIntoView() : document.getElementById("#LBL-" + taField.name).scrollIntoView();
                return false;
            }, 500);
        }
        return false;
    }

    handleOnBlur = (event) => {
        return false;
    }

    handleFormChange = (event) => {
        const currentInput = event.target.name;
        const currentValue = event.target.value;
        const currentLength = currentValue.length;
        let maxLength = event.target.getAttribute('data-maxlen');
        maxLength = (maxLength === null || maxLength === "") ? 1000 : maxLength;

        let isOverLength = false;
        if (currentLength > maxLength) {
            isOverLength = true;
        }

        this.setState({
            dialogForm: { ...this.state.dialogForm, [event.target.name]: event.target.value },
            isFormDirty: true,
            currentLength: currentLength,
            maxLength: maxLength,
            warning: isOverLength,
            focusedItem: currentInput,
        });
    }

    showImportTextFieldName = (name) => {
        let result = name ? name.replace(/(([A-Z0-9])+[0-9]|[A-Z0-9])/g, "_$1") : "";
        return result.toUpperCase();
    }

    render() {
        const dialogFrm = this.state.dialogForm ?
            (
                <div className="container-fluid2">
                    <form onSubmit={this.saveTextDialog} id="diagnosisTextForm" className="DialogForm2">
                        <div className="row">
                            <div className="col-md-6 leftSideTextForm">
                                <Input type="textarea" labelText="Prim Site Text" field="primSiteText" taClsNm="expanderCls"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.primSiteText}
                                    rows="2" maxlen="100" itemNbr="486" autoFocus
                                    textPadFields={true} labelStyle="TextPadLbl" keyIdTa="primSiteText_TextPad"
                                />
                                <Input type="textarea" labelText="Histology Text" field="histologyText" keyIdTa="histologyText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.histologyText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="100" itemNbr="487" />
                                <Input type="textarea" labelText="Place Dx Text" field="placeDxText" keyIdTa="placeDxText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.placeDxText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="60" itemNbr="506" />
                                <Input type="textarea" labelText="Exam Text" field="phyExamText" keyIdTa="phyExamText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.phyExamText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="4000" itemNbr="515" />
                                <Input type="textarea" labelText="Xray Text" field="xrayScanText" keyIdTa="xrayScanText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.xrayScanText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="4000" itemNbr="516" />
                                <Input type="textarea" labelText="Scopes Text" field="scopesText" keyIdTa="scopesText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.scopesText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="4000" itemNbr="517" />
                                <Input type="textarea" labelText="Labs Text" field="labsText" keyIdTa="labsText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.labsText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="4000" itemNbr="855" />
                                <Input type="textarea" labelText="Operative Text" field="opText" keyIdTa="opText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.opText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="4000" itemNbr="514" />
                                <Input type="textarea" labelText="Path Text" field="pathText" keyIdTa="pathText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.pathText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="4000" itemNbr="518" />
                                <Input type="textarea" labelText="Dx Comments" field="dxComments" keyIdTa="dxComments_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.dxComments}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="4000" itemNbr="199" />
                            </div>
                            <div className="col-md-6 rightSideTextForm">
                                <Input type="textarea" labelText="Surgery Text" field="surgeryText" keyIdTa="surgeryText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.surgeryText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="4000" itemNbr="534" />
                                <Input type="textarea" labelText="Beam Rad Text" field="beamRadText" keyIdTa="beamRadText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.beamRadText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="4000" itemNbr="535" />
                                <Input type="textarea" labelText="Oth Rad Text" field="othRadText" keyIdTa="othRadText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.othRadText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="4000" itemNbr="536" />
                                <Input type="textarea" labelText="Chemo Text" field="chemoText" keyIdTa="chemoText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.chemoText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="4000" itemNbr="537" />
                                <Input type="textarea" labelText="Horm Text" field="hormText" keyIdTa="hormText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.hormText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="4000" itemNbr="812" />
                                <Input type="textarea" labelText="Immuno Text" field="immunoText" keyIdTa="immunoText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.immunoText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="4000" itemNbr="814" />
                                <Input type="textarea" labelText="Hema / Other / Palliative Text" field="hemaText" keyIdTa="hemaText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.hemaText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="4000" itemNbr="3970" />
                                <Input type="textarea" labelText="Staging Text" field="stageText" keyIdTa="stageText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.stageText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="4000" itemNbr="824" />
                                <Input type="textarea" labelText="Follow Up Comments" field="folComments" keyIdTa="folComments_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.folComments}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="0" itemNbr="33" />
                                <Input type="textarea" labelText="Case Status Text" field="transferText" keyIdTa="transferText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.transferText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="0" itemNbr="3105" />
                                <Input type="textarea" labelText="User Defined Text" field="ajccStagingText" keyIdTa="ajccStagingText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.ajccStagingText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="0" itemNbr="3975" />
                                <Input type="textarea" labelText="Imported Text" field="importedText" keyIdTa="importedText_TextPad"
                                    changed={this.handleFormChange} focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    value={this.state.dialogForm.importedText}
                                    textPadFields={true} labelStyle="TextPadLbl"
                                    rows="2" maxlen="0" itemNbr="4519" />
                            </div>
                        </div>

                        <div id="showLengthInfoCls" className="row">
                            <div className="col-md-6">
                                {this.showImportTextFieldName(this.state.focusedItem)}
                            </div>
                            <div className="col-md-2">
                                Current Length: <span style={{ "background": this.state.warning ? "rgb(162, 0, 0)" : "", "color": this.state.warning ? "white" : "" }}> {this.state.currentLength} </span>
                            </div>
                            <div className="col-md-3">
                                Max Length: {this.state.maxLength}
                            </div>
                        </div>

                        <div className="Footer TextForm">
                            <button onClick={(e) => { this.closeWindow(e) }} name="btnCancelTextDialog">Cancel</button>
                            <button type="submit" value="Save & Exit" disabled={this.props.inquiringMode || !this.state.isFormDirty} name="btnSaveAndExitTextDialog">Save & Exit</button>
                        </div>
                    </form>
                </div>
            )
            : <p>Diagnosis Text data loading...</p>;
        return (
            <React.Fragment>
                {dialogFrm}
            </React.Fragment>
        );
    }
}

TextForm.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        selectedDiagnosisText: state.patientAbstract.selectedDiagnosisText,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        inquiringMode: state.auth.inquiringMode,
        itemNbr: state.patientAbstract.itemNbr,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveTextDialog: (dialogForm) => dispatch(actionCreators.saveTextDialog(dialogForm)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),
        setItemNbr: (value) => dispatch(actionCreators.setItemNbr(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TextForm);