/* eslint-disable */
import React, { Component } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { connect } from "react-redux";
import * as actions from '../../../../store/actions/index';
import { actionCreators } from "../../../../store/PatientAbstract";
import ConfigData from '../../../../store/config.json';
import axios from "axios";
import Draggable from 'react-draggable';
import * as dialogTypes from "../../../AbstractManagement/maintDialogs";

class AccRosterMpi extends Component {

    componentDidMount() {
        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);

        // Add all tabs in maintDialogs.js
        dialogTypes.adminReportsTabsDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog.name, dialog.id);
            this.props.setEnabledStatus(dialog.name);
        });

        this.setState({ isLoading: true });
    }

    render() {
        let formView = null;
        {
            formView = <div className="col-sm-12">{this.props.initialPageVisible ? "AccRosterMpi Page Here!!!" : null}</div>;
        }

        return (
            <React.Fragment>
                {formView}
            </React.Fragment>
        );
    }
}

AccRosterMpi.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        clientgroup: state.auth.clientgroup,
        pageLoaded: state.patientAbstract.pageLoaded,
        childrenOpening: state.patientAbstract.childrenOpening,
        inquiringMode: state.auth.inquiringMode,
        handlerControlItem: state.patientAbstract.handlerControlItem,
        currentDialog: state.patientAbstract.currentDialog,
        childDialog: state.patientAbstract.childDialog,
        isChildDialogVisible: state.patientAbstract.isChildDialogVisible,
        isParentWindowVisible: state.patientAbstract.isParentWindowVisible,
        isAuthenticated: state.auth.token !== null,
        userId: state.auth.userId,
        initialPageVisible: state.patientAbstract.initialPageVisible,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addNewItem: (number) => dispatch(actionCreators.selectHospitalRecord(number)),
        closeAddNewDialog: () => dispatch(actionCreators.closeAddNewDialog()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccRosterMpi);