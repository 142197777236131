/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Footer from "../Shared/Footer";
import { TabContent, TabPane, Nav, NavLink } from "reactstrap";
import classnames from "classnames";
import Input from "../../../UI/Input";
import axios from "../../../../axios-instance";
import "../PatientMaintenance/PatientMaintenance.css";
import "./RetiredMaintenance.css";
import moment from "moment";
import TextForm from "../DiagnosisMaintenance/TextForm";
import Help from "../../../UI/Help";
import Draggable from 'react-draggable';
import * as TextPopup from "../TitleOfTextPopup";

class RetiredMaintenance extends Component {

    state = {
        activeTab: "1",
        activeTabInner: "os",
        dAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'retiredForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty retiredForm back to what it was on load
        initialRetiredForm: null,
        retiredForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        fieldInFocus: "extnProstPath",
        isHelpVisible: false,

        // Recurrence_Mv
        recurrenceMv: null,
        recurrenceMvItemFields: null,
        recurrenceMvLoading: false,
        selectedRecurrenceRowIndex: -1,

        // User_Drg_Mv
        userDrgMv: null,
        userDrgMvItemFields: null,
        userDrgMvLoading: false,
        selectedUserDrgRowIndex: 0,//#PR00237 - because when initial page, it's focusing first row

        // User_Chemo_Mv
        userChemoMv: null,
        userChemoMvItemFields: null,
        userChemoMvLoading: false,
        selectedUserChemoRowIndex: -1,

        // User_Radiation_Mv
        userRadiationMv: null,
        userRadiationMvItemFields: null,
        userRadiationMvLoading: false,
        selectedUserRadiationRowIndex: -1,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,

        recurrenceMvMaxlengthInput: null,
        userDrgMvMaxlengthInput: null,
        userChemoMvMaxlengthInput: null,
        userRadiationMvMaxlengthInput: null,

        fnExtnId: null,//Fixing issue #PR00171
        loadedTableStructure: false,
        focusingField: null,
    }
    
    setSelectionRecurrenceRow = (event, index) => {
        this.setState({ selectedRecurrenceRowIndex: index });
    }

    setSelectionUserDrgRow = (event, index) => {
        this.setState({ selectedUserDrgRowIndex: index });
    }

    setSelectionUserChemoRow = (event, index) => {
        this.setState({ selectedUserChemoRowIndex: index });
    }

    setSelectionUserRadiationRow = (event, index) => {
        this.setState({ selectedUserRadiationRowIndex: index });
    }

    keydownHandler = (event) => {
        if (event.key === "Tab" && !event.shiftKey) {
            document.getElementById("linkOfRetiredTab2").click();
        }
    }

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: true });
    }

    toggleTab = (event, tab) => {
        this.setState({ activeTab: tab });
        var _this = this;
        if (tab === "1") {
            setTimeout(function () {
                document.getElementById("extnProstPath").focus();
            }, 0);
        } else if (tab === "2") {
            setTimeout(function () {
                document.getElementById("drgCode0") ? document.getElementById("drgCode0").focus() : '';
                // also reset the index for first focusing row
                _this.setState({ selectedUserDrgRowIndex: 0 });
            }, 0);
        }
        return false;
    }

    toggleTabInner = (event, tab) => {
        this.setState({ activeTabInner: tab });
        if (tab === "os") {
            setTimeout(function () {
                document.getElementById("oStageDesc").focus();
            }, 0);
        } else if (tab === "cs") {
            setTimeout(function () {
                document.getElementById("cStageDesc").focus();
            }, 0);
        } else if (tab === "ps") {
            setTimeout(function () {
                document.getElementById("pStageDesc").focus();
            }, 0);
        }
        return false;
    }

    showTextDialog = () => {
        this.setState({ isShowTextVisible: true });
        return false;
    }

    closeTextDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowTextVisible: false });
        this.props.closeTextDialog(false);

        // set focus back into parent page
        setTimeout(function () {
            document.getElementById("extra_btn_0") ? (document.getElementById("extra_btn_0").nextSibling ? document.getElementById("extra_btn_0").nextSibling.focus() : document.getElementById("extra_btn_0").focus()) : "";
        }, 100);

        return false;
    }

    hideConfirmDeleteMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isShowConfirmDeleteMsg: false });

        if (event.target.value === "yes" && this.state.monitoring) {
            switch (this.state.monitoring) {
                case "RecurrenceMv":
                    this.deleteRecurrenceMvGrid();
                    break;
                case "UserDrgMv":
                    this.deleteUserDrgMvGrid();
                    break;
                case "UserChemoMv":
                    this.deleteUserChemoMvGrid();
                    break;
                case "UserRadiationMv":
                    this.deleteUserRadiationMvGrid();
                    break;
                default:
                    break;
            }
        }
        return false;
    }

    componentDidMount() {
        if (!this.state.retiredForm && this.props.selectedRetiredRecord) {
            this.setFormData();
        }
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.retiredForm && this.props.selectedRetiredRecord)
            || (prevProps.selectedRetiredRecord && prevProps.selectedRetiredRecord !== this.props.selectedRetiredRecord)
            || (this.props.initialAbstractPages && this.props.selectedRetiredRecord)
        ) {
            this.setFormData();
        }

        // RecurrenceMvList
        if (this.props.selectedPrimarySummary && !this.state.recurrenceMv && !this.state.recurrenceMvLoading) {
            this.setState({ recurrenceMvLoading: true });
            axios.post(this.props.clientgroup + "/GetRecurrenceMvList", { cId: this.props.selectedPrimarySummary.cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ recurrenceMv: res.data.recurrenceMvList });

                        if (!this.state.recurrenceMvItemFields && res.data.recurrenceMvList) {
                            let objArr = [];
                            res.data.recurrenceMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                objArr.push(fields);
                            });

                            this.setState({ recurrenceMvItemFields: objArr });

                            // get maxlength for recurrenceMv grid
                            axios.post(this.props.clientgroup + "/GetTableStructure", { table: "Recurrence_Mv", accessToken: localStorage.getItem('token') })
                                .then(res => {
                                    //update new accessToken again
                                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                                    if (newAccessToken) {
                                        localStorage.setItem('token', newAccessToken);
                                    }

                                    if (res.data !== null && res.data.result !== "") {
                                        let maxLengthValue = res.data.result;
                                        let maxLengthItem = objArr && objArr[0] ? objArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                        this.setState({
                                            recurrenceMvMaxlengthInput: { ...maxLengthItem }
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    /*
                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }
                                    */
                                    //@TODO:...

                                    return false;
                                });
                        };
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }

        // UserDrgMvList
        if (this.props.selectedPrimarySummary && !this.state.userDrgMv && !this.state.userDrgMvLoading) {
            this.setState({ userDrgMvLoading: true });
            axios.post(this.props.clientgroup + "/GetUserDrgMvList", { cId: this.props.selectedPrimarySummary.cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ userDrgMv: res.data.userDrgMvList });

                        if (!this.state.userDrgMvItemFields && res.data.userDrgMvList) {
                            let objArr = [];
                            res.data.userDrgMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                objArr.push(fields);
                            });

                            this.setState({ userDrgMvItemFields: objArr });

                            // get maxlength for userDrgMv grid
                            axios.post(this.props.clientgroup + "/GetTableStructure", { table: "User_Drg_Mv", accessToken: localStorage.getItem('token') })
                                .then(res => {
                                    //update new accessToken again
                                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                                    if (newAccessToken) {
                                        localStorage.setItem('token', newAccessToken);
                                    }

                                    if (res.data !== null && res.data.result !== "") {
                                        let maxLengthValue = res.data.result;
                                        let maxLengthItem = objArr && objArr[0] ? objArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                        this.setState({
                                            userDrgMvMaxlengthInput: { ...maxLengthItem }
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    /*
                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }
                                    */
                                    //@TODO:...

                                    return false;
                                });
                        };
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }

        // UserChemoMvList
        if (this.props.selectedPrimarySummary && !this.state.userChemoMv && !this.state.userChemoMvLoading) {
            this.setState({ userChemoMvLoading: true });
            axios.post(this.props.clientgroup + "/GetUserChemoMvList", { cId: this.props.selectedPrimarySummary.cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ userChemoMv: res.data.userChemoMvList });

                        if (!this.state.userChemoMvItemFields && res.data.userChemoMvList) {
                            let objArr = [];
                            res.data.userChemoMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                objArr.push(fields);
                            });

                            this.setState({ userChemoMvItemFields: objArr });

                            // get maxlength for userChemoMv grid
                            axios.post(this.props.clientgroup + "/GetTableStructure", { table: "User_Chemo_Mv", accessToken: localStorage.getItem('token') })
                                .then(res => {
                                    //update new accessToken again
                                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                                    if (newAccessToken) {
                                        localStorage.setItem('token', newAccessToken);
                                    }

                                    if (res.data !== null && res.data.result !== "") {
                                        let maxLengthValue = res.data.result;
                                        let maxLengthItem = objArr && objArr[0] ? objArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                        this.setState({
                                            userChemoMvMaxlengthInput: { ...maxLengthItem }
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    /*
                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }
                                    */
                                    //@TODO:...

                                    return false;
                                });
                        };
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }

        // UserRadiationMvList
        if (this.props.selectedPrimarySummary && !this.state.userRadiationMv && !this.state.userRadiationMvLoading) {
            this.setState({ userRadiationMvLoading: true });
            axios.post(this.props.clientgroup + "/GetUserRadiationMvList", { cId: this.props.selectedPrimarySummary.cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ userRadiationMv: res.data.userRadiationMvList });

                        if (!this.state.userRadiationMvItemFields && res.data.userRadiationMvList) {
                            let objArr = [];
                            res.data.userRadiationMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                objArr.push(fields);
                            });

                            this.setState({ userRadiationMvItemFields: objArr });

                            // get maxlength for userRadiationMv grid
                            axios.post(this.props.clientgroup + "/GetTableStructure", { table: "User_Radiation_Mv", accessToken: localStorage.getItem('token') })
                                .then(res => {
                                    //update new accessToken again
                                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                                    if (newAccessToken) {
                                        localStorage.setItem('token', newAccessToken);
                                    }

                                    if (res.data !== null && res.data.result !== "") {
                                        let maxLengthValue = res.data.result;
                                        let maxLengthItem = objArr && objArr[0] ? objArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                        this.setState({
                                            userRadiationMvMaxlengthInput: { ...maxLengthItem }
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    /*
                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }
                                    */
                                    //@TODO:...

                                    return false;
                                });
                        };
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.retiredForm && this.state.dAllItemFields
        ) {
            console.log("[Retired] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    retiredForm: { ...this.state.retiredForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    retiredForm: { ...this.state.retiredForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null && !this.state.loadedTableStructure) {
            this.setState({ loadedTableStructure: true });
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async getTableStructure() {
        //let url = this.props.clientgroup + "/GetTableStructure?table=Retired&table2=Diagnosis&table3=Rx_Summary&table4=User_Defined";
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "Retired", table2: "Diagnosis", table3: "Rx_Summary", table4: "User_Defined", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    // Add lostfocus event for all input fields on screen - START
    handleRecurrenceMvLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, index);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let isvalid = true;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                isvalid = false;
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                isvalid = false;
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                let value = event.target.value;
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState(prevState => {
                        const newItems = [...prevState.recurrenceMv];
                        newItems[index][name] = value;
                        return { recurrenceMv: newItems };
                    });
                } else {
                    isvalid = false;
                }
            }

            if (!isvalid) {
                this.setState(prevState => {
                    const newItems = [...prevState.recurrenceMvItemFields];
                    newItems[index][name] = "IN_VALID";
                    return { recurrenceMvItemFields: newItems };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.recurrenceMvItemFields];
                    newItems[index][name] = "";
                    return { recurrenceMvItemFields: newItems };
                });
            }
            //#20210125 - END
        } else {
            this.setState(prevState => {
                const newItems = [...prevState.recurrenceMvItemFields];
                newItems[index][name] = "";
                return { recurrenceMvItemFields: newItems };
            });
        }
    }
    // --END

    // RecurrenceMv grid
    handleRecurrenceMvGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.recurrenceMv];
            newItems[index][name] = value;
            return { recurrenceMv: newItems, isFormDirty: true };
        });
    }

    addNewRecurrenceMv= (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.recurrenceMv];
            const firstItem = newItems[0];

            const newObj = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newObj[t] = 0;
                } else {
                    newObj[t] = "";
                }
            });
            newItems.push(newObj);

            // 
            const newFields = [...this.state.recurrenceMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { recurrenceMv: newItems, recurrenceMvItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }

    // deleteLastRecurrenceMv= (event) => {
    //     event.preventDefault();
    //     if (this.state.recurrenceMv[this.state.recurrenceMv.length - 1]["id"] !== 0) {
    //         this.setState({ isShowConfirmDeleteMsg: true, monitoring: "RecurrenceMv" });
    //     } else {
    //         this.deleteRecurrenceMvGrid();
    //     }
    //     return false;
    // }

    deleteSelectedRecurrenceMv = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedRecurrenceRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.recurrenceMv.length) {
            selectedRow = this.state.recurrenceMv.length - 1;
        }

        if (this.state.recurrenceMv[selectedRow] && this.state.recurrenceMv[selectedRow]["id"] !== undefined && this.state.recurrenceMv[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "RecurrenceMv" });
        } else {
            this.deleteRecurrenceMvGrid();
        }
        return false;
    }

    deleteRecurrenceMvGrid = () => {
        let selectedRow = this.state.selectedRecurrenceRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.recurrenceMv.length) {
            selectedRow = this.state.recurrenceMv.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.recurrenceMv];
            if (newItems.length === 1) {
                const _newItems = [...this.state.recurrenceMv];
                const firstItems = _newItems[0];

                const newObj = {};
                Object.keys(firstItems).map((t, k) => {
                    if (t === "id" || t === "pid" || t === "cid") {
                        //newObj[t] = 0;
                    } else {
                        newObj[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObj);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.recurrenceMvItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.recurrenceMvItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { recurrenceMv: newItems, recurrenceMvItemFields: newFields, selectedRecurrenceRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }

    // UserDrgMv grid - lostfocus
    handleUserDrgMvLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, index);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let isvalid = true;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                isvalid = false;
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                isvalid = false;
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                let value = event.target.value;
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState(prevState => {
                        const newItems = [...prevState.userDrgMv];
                        newItems[index][name] = value;
                        return { userDrgMv: newItems };
                    });
                } else {
                    isvalid = false;
                }
            }

            if (!isvalid) {
                this.setState(prevState => {
                    const newItems = [...prevState.userDrgMvItemFields];
                    newItems[index][name] = "IN_VALID";
                    return { userDrgMvItemFields: newItems };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.userDrgMvItemFields];
                    newItems[index][name] = "";
                    return { userDrgMvItemFields: newItems };
                });
            }
            //#20210125 - END

        } else {
            this.setState(prevState => {
                const newItems = [...prevState.userDrgMvItemFields];
                newItems[index][name] = "";
                return { userDrgMvItemFields: newItems };
            });
        }
    }
    // onchange
    handleUserDrgMvGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.userDrgMv];
            newItems[index][name] = value;
            return { userDrgMv: newItems, isFormDirty: true };
        });
    }

    addNewUserDrgMv = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.userDrgMv];
            const firstItem = newItems[0];

            const newObj = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newObj[t] = 0;
                } else {
                    newObj[t] = "";
                }
            });
            newItems.push(newObj);

            // 
            const newFields = [...this.state.userDrgMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { userDrgMv: newItems, userDrgMvItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }

    // deleteLastUserDrgMv = (event) => {
    //     event.preventDefault();
    //     if (this.state.userDrgMv[this.state.userDrgMv.length - 1]["id"] !== 0) {
    //         this.setState({ isShowConfirmDeleteMsg: true, monitoring: "UserDrgMv" });
    //     } else {
    //         this.deleteUserDrgMvGrid();
    //     }
    //     return false;
    // }

    deleteSelectedUserDrgMv = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedUserDrgRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.userDrgMv.length) {
            selectedRow = this.state.userDrgMv.length - 1;
        }

        if (this.state.userDrgMv[selectedRow] && this.state.userDrgMv[selectedRow]["id"] !== undefined && this.state.userDrgMv[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "UserDrgMv" });
        } else {
            this.deleteUserDrgMvGrid();
        }
        return false;
    }


    deleteUserDrgMvGrid = () => {
        let selectedRow = this.state.selectedUserDrgRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.userDrgMv.length) {
            selectedRow = this.state.userDrgMv.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.userDrgMv];
            if (newItems.length === 1) {
                const _newItems = [...this.state.userDrgMv];
                const firstItems = _newItems[0];

                const newObj = {};
                Object.keys(firstItems).map((t, k) => {
                    if (t === "id" || t === "pid" || t === "cid") {
                        //newObj[t] = 0;
                    } else {
                        newObj[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObj);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.userDrgMvItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.userDrgMvItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { userDrgMv: newItems, userDrgMvItemFields: newFields, selectedUserDrgRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }

    // UserChemoMv grid - lostfocus
    handleUserChemoMvLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, index);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let isvalid = true;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                isvalid = false;
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                isvalid = false;
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                let value = event.target.value;
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState(prevState => {
                        const newItems = [...prevState.userChemoMv];
                        newItems[index][name] = value;
                        return { userChemoMv: newItems };
                    });
                } else {
                    isvalid = false;
                }
            }

            if (!isvalid) {
                this.setState(prevState => {
                    const newItems = [...prevState.userChemoMvItemFields];
                    newItems[index][name] = "IN_VALID";
                    return { userChemoMvItemFields: newItems };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.userChemoMvItemFields];
                    newItems[index][name] = "";
                    return { userChemoMvItemFields: newItems };
                });
            }
            //#20210125 - END

        } else {
            this.setState(prevState => {
                const newItems = [...prevState.userChemoMvItemFields];
                newItems[index][name] = "";
                return { userChemoMvItemFields: newItems };
            });
        }
    }
    // onchange
    handleUserChemoMvGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.userChemoMv];
            newItems[index][name] = value;
            return { userChemoMv: newItems, isFormDirty: true };
        });
    }

    addNewUserChemoMv = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.userChemoMv];
            const firstItem = newItems[0];

            const newObj = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newObj[t] = 0;
                } else {
                    newObj[t] = "";
                }
            });
            newItems.push(newObj);

            // 
            const newFields = [...this.state.userChemoMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { userChemoMv: newItems, userChemoMvItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }

    // deleteLastUserChemoMv = (event) => {
    //     event.preventDefault();
    //     if (this.state.userChemoMv[this.state.userChemoMv.length - 1]["id"] !== 0) {
    //         this.setState({ isShowConfirmDeleteMsg: true, monitoring: "UserChemoMv" });
    //     } else {
    //         this.deleteUserChemoMvGrid();
    //     }
    //     return false;
    // }

    deleteSelectedUserChemoMv = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedUserChemoRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.userChemoMv.length) {
            selectedRow = this.state.userChemoMv.length - 1;
        }

        if (this.state.userChemoMv[selectedRow] && this.state.userChemoMv[selectedRow]["id"] !== undefined && this.state.userChemoMv[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "UserChemoMv" });
        } else {
            this.deleteUserChemoMvGrid();
        }
        return false;
    }

    deleteUserChemoMvGrid = () => {
        let selectedRow = this.state.selectedUserChemoRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.userChemoMv.length) {
            selectedRow = this.state.userChemoMv.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.userChemoMv];
            if (newItems.length === 1) {
                const _newItems = [...this.state.userChemoMv];
                const firstItems = _newItems[0];

                const newObj = {};
                Object.keys(firstItems).map((t, k) => {
                    if (t === "id" || t === "pid" || t === "cid") {
                        //newObj[t] = 0;
                    } else {
                        newObj[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObj);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.userChemoMvItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.userChemoMvItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { userChemoMv: newItems, userChemoMvItemFields: newFields, selectedUserChemoRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }

    // UserRadiationMv grid - lostfocus
    handleUserRadiationMvLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, index);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let isvalid = true;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                isvalid = false;
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                isvalid = false;
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                let value = event.target.value;
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState(prevState => {
                        const newItems = [...prevState.userRadiationMv];
                        newItems[index][name] = value;
                        return { userRadiationMv: newItems };
                    });
                } else {
                    isvalid = false;
                }
            }

            if (!isvalid) {
                this.setState(prevState => {
                    const newItems = [...prevState.userRadiationMvItemFields];
                    newItems[index][name] = "IN_VALID";
                    return { userRadiationMvItemFields: newItems };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.userRadiationMvItemFields];
                    newItems[index][name] = "";
                    return { userRadiationMvItemFields: newItems };
                });
            }
            //#20210125 - END

        } else {
            this.setState(prevState => {
                const newItems = [...prevState.userRadiationMvItemFields];
                newItems[index][name] = "";
                return { userRadiationMvItemFields: newItems };
            });
        }
    }
    // onchange
    handleUserRadiationMvGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.userRadiationMv];
            newItems[index][name] = value;
            return { userRadiationMv: newItems, isFormDirty: true };
        });
    }

    addNewUserRadiationMv = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.userRadiationMv];
            const firstItem = newItems[0];

            const newObj = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newObj[t] = 0;
                } else {
                    newObj[t] = "";
                }
            });
            newItems.push(newObj);

            // 
            const newFields = [...this.state.userRadiationMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { userRadiationMv: newItems, userRadiationMvItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }

    // deleteLastUserRadiationMv = (event) => {
    //     event.preventDefault();
    //     if (this.state.userRadiationMv[this.state.userRadiationMv.length - 1]["id"] !== 0) {
    //         this.setState({ isShowConfirmDeleteMsg: true, monitoring: "UserRadiationMv" });
    //     } else {
    //         this.deleteUserRadiationMvGrid();
    //     }
    //     return false;
    // }

    deleteSelectedUserRadiationMv = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedUserRadiationRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.userRadiationMv.length) {
            selectedRow = this.state.userRadiationMv.length - 1;
        }

        if (this.state.userRadiationMv[selectedRow] && this.state.userRadiationMv[selectedRow]["id"] !== undefined && this.state.userRadiationMv[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "UserRadiationMv" });
        } else {
            this.deleteUserRadiationMvGrid();
        }
        return false;
    }

    deleteUserRadiationMvGrid = () => {
        let selectedRow = this.state.selectedUserRadiationRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.userRadiationMv.length) {
            selectedRow = this.state.userRadiationMv.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.userRadiationMv];
            if (newItems.length === 1) {
                const _newItems = [...this.state.userRadiationMv];
                const firstItems = _newItems[0];

                const newObj = {};
                Object.keys(firstItems).map((t, k) => {
                    if (t === "id" || t === "pid" || t === "cid") {
                        //newObj[t] = 0;
                    } else {
                        newObj[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObj);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.userRadiationMvItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.userRadiationMvItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }
            return { userRadiationMv: newItems, userRadiationMvItemFields: newFields, selectedUserRadiationRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }

    setFormData = () => {
        this.setState({
            retiredForm: { ...this.props.selectedRetiredRecord },
            isFormDirty: this.props.isFormDirty,
            //primTopo: this.props.selectedMedicalRecord ? this.props.selectedMedicalRecord.topoCode : ""
        });

        if (this.props.selectedPrimarySummary) {
            this.setState({
                primTopo: this.props.selectedMedicalRecord ? this.props.selectedMedicalRecord.topoCode : "",
                primSite: this.props.selectedMedicalRecord.topoCode ? this.props.selectedMedicalRecord.topoCode.substring(2) : "",
            });
        }

        if (!this.state.dAllItemFields && this.props.selectedRetiredRecord) {
            let fields = {};
            Object.keys(this.props.selectedRetiredRecord).map((t) => {
                fields[t] = "";
            });

            this.setState({ dAllItemFields: fields, pageMaxlengthInput: fields });
        };

        this.props.setInitialAbstractPages(false);

        return false;
    }

    // Add clear and check validate of date fields function -- START
    clearInvalidOfDateFields = (formItems, field, onGrid, index) => {
        let tYear = field + "Year";
        let tMonth = field + "Month";
        let tDay = field + "Day";
        if (onGrid && onGrid === "recurrenceMvList") {
            this.setState(prevState => {
                let newItems = [...prevState.recurrenceMvItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { recurrenceMvItemFields: newItems };
            });
        } else if (onGrid && onGrid === "userChemoMvList") {
            this.setState(prevState => {
                let newItems = [...prevState.userChemoMvItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { userChemoMvItemFields: newItems };
            });
        } else if (onGrid && onGrid === "userRadiationMvList") {
            this.setState(prevState => {
                let newItems = [...prevState.userRadiationMvItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { userRadiationMvItemFields: newItems };
            });
        } else {
            this.setState({
                dAllItemFields: {
                    ...this.state.dAllItemFields,
                    [tYear]: "",
                    [tMonth]: "",
                    [tDay]: "",
                }
            });
        }
        formItems[tYear] = "";
        formItems[tMonth] = "";
        formItems[tDay] = "";
    }

    checkValidateOfDateFields = (pFormData, field, onGrid, index) => {
        let isValidDateInput = true;

        var tYear = field + "Year";
        var tMonth = field + "Month";
        var tDay = field + "Day";

        // Check valid of date in February
        var year = pFormData[tYear] ? pFormData[tYear] : "";
        var month = pFormData[tMonth] ? pFormData[tMonth] : "";
        var day = pFormData[tDay] ? pFormData[tDay] : "";

        pFormData[field] = new Date(year + "-" + month + "-" + day);

        if (year !== "" && month !== "" && day !== "") {
            var _day, A = (month + '/' + day + '/' + year).match(/[1-9][\d]*/g);
            try {
                A[0] -= 1;
                _day = new Date(+A[2], A[0], +A[1]);
                if ((_day.getMonth() == A[0] && _day.getDate() == A[1])
                                /*|| year == '9999'*/ || month == '99' || day == '99') {
                } else {
                    isValidDateInput = false;

                    if (onGrid && onGrid === "recurrenceMvList") {
                        this.setState(prevState => {
                            const newItems = [...prevState.recurrenceMvItemFields];
                            newItems[index][tYear] = "IN_VALID";
                            newItems[index][tMonth] = "IN_VALID";
                            newItems[index][tDay] = "IN_VALID";
                            return { recurrenceMvItemFields: newItems };
                        });
                    } else if (onGrid && onGrid === "userChemoMvList") {
                        this.setState(prevState => {
                            const newItems = [...prevState.userChemoMvItemFields];
                            newItems[index][tYear] = "IN_VALID";
                            newItems[index][tMonth] = "IN_VALID";
                            newItems[index][tDay] = "IN_VALID";
                            return { userChemoMvItemFields: newItems };
                        });
                    } else if (onGrid && onGrid === "userRadiationMvList") {
                        this.setState(prevState => {
                            const newItems = [...prevState.userRadiationMvItemFields];
                            newItems[index][tYear] = "IN_VALID";
                            newItems[index][tMonth] = "IN_VALID";
                            newItems[index][tDay] = "IN_VALID";
                            return { userRadiationMvItemFields: newItems };
                        });
                    } else {
                        this.setState({
                            dAllItemFields: {
                                ...this.state.dAllItemFields,
                                [tYear]: "IN_VALID",
                                [tMonth]: "IN_VALID",
                                [tDay]: "IN_VALID",
                            }
                        });
                    }
                }
            } catch (er) {
                isValidDateInput = false;
            }
        }

        return isValidDateInput;
    }
    // -- END

    saveRetired = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        const formItems = this.state.dAllItemFields;
        Object.keys(formItems).map((t) => {
            // clear IN_VALID flag for all date fields
            if (t === "posBxDate" || t === "dateConclusive" || t === "dateMultTum" || t === "dateEntered") {
                this.clearInvalidOfDateFields(formItems, t);
            }

            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        // RecurrenceMv grid
        if (isValid) {
            const pItems = this.state.recurrenceMvItemFields;
            pItems.map((prm, index) => {
                Object.keys(prm).map((field) => {
                    // clear IN_VALID flag for all date fields
                    if (field === "recurDate") {
                        this.clearInvalidOfDateFields(prm, field, "recurrenceMvList", index);
                    }

                    if (prm[field] === "IN_VALID") {
                        return isValid = false;
                    }
                });
            });
        }

        // UserChemoMv grid
        if (isValid) {
            const pItems = this.state.userChemoMvItemFields;
            pItems.map((prm, index) => {
                Object.keys(prm).map((field) => {
                    // clear IN_VALID flag for all date fields
                    if (field === "chemoDate") {
                        this.clearInvalidOfDateFields(prm, field, "userChemoMvList", index);
                    }

                    if (prm[field] === "IN_VALID") {
                        return isValid = false;
                    }
                });
            });
        }

        // UserRadiationMv grid
        if (isValid) {
            const pItems = this.state.userRadiationMvItemFields;
            pItems.map((prm, index) => {
                Object.keys(prm).map((field) => {
                    // clear IN_VALID flag for all date fields
                    if (field === "radDate") {
                        this.clearInvalidOfDateFields(prm, field, "userRadiationMvList", index);
                    }

                    if (prm[field] === "IN_VALID") {
                        return isValid = false;
                    }
                });
            });
        }

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let retiredFormData = this.state.retiredForm;
            let recurrenceMvList = this.state.recurrenceMv;
            let userDrgMvList = this.state.userDrgMv;
            let userChemoMvList = this.state.userChemoMv;
            let userRadiationMvList = this.state.userRadiationMv;

            //
            let isValidDateInput = true;

            Object.keys(retiredFormData).map((t, k) => {
                if (t === "posBxDate" || t === "dateConclusive" || t === "dateMultTum" || t === "dateEntered") {
                    isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(retiredFormData, t);
                }
            });

            recurrenceMvList.map((obj, index) => {
                Object.keys(obj).map((t) => {
                    if (t === "recurDate") {
                        isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(obj, t, "recurrenceMvList", index);
                    }
                });
            });

            userChemoMvList.map((obj, index) => {
                Object.keys(obj).map((t) => {
                    if (t === "chemoDate") {
                        isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(obj, t, "userChemoMvList", index);
                    }
                });
            });

            userRadiationMvList.map((obj, index) => {
                Object.keys(obj).map((t) => {
                    if (t === "radDate") {
                        isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(obj, t, "userRadiationMvList", index);
                    }
                });
            });

            if (!isValidDateInput && !allowSaveAnyway) return false;

            retiredFormData.recurrenceMv = recurrenceMvList;
            retiredFormData.userDrgMv = userDrgMvList;
            retiredFormData.userChemoMv = userChemoMvList;
            retiredFormData.userRadiationMv = userRadiationMvList;

            this.props.saveRetired(retiredFormData);

            return false;
        }
    }

    resetRetired = () => {
        let fields = {};
        Object.keys(this.state.dAllItemFields).map((t) => {
            fields[t] = "";
        });
        this.setState({
            retiredForm: { ...this.state.initialRetiredForm },
            dAllItemFields: fields,
            isFormDirty: false,
        });
        return false;
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";

            if (name === "extnProstPath") {
                key = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            }

            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            let cid = this.props.selectedPrimarySummary.cid;

            this.props.checkValidInput(name, value, table, key, -1, flag, cid);

        //#20210911 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let value = event.target.value;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState({ retiredForm: { ...this.state.retiredForm, [event.target.name]: value } });
                } else {
                    this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
                }
            } else {
                // clear all error style
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
            }
        //#20210911 - END

        } else {
            this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
        }
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ retiredForm: { ...this.state.retiredForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({ retiredForm: { ...this.state.retiredForm, [event.target.name]: event.target.value }, isFormDirty: true });
        }
    }

    helpFocusingField = (field) => {
        this.setState({ fieldInFocus: field });
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = () => {
        this.setState({ isHelpVisible: false });
        return false;
    }

    render() {
        let confirmDeleteMsg;
        {
            confirmDeleteMsg = this.state.isShowConfirmDeleteMsg ?
                /*
                <Dialog title="Delete Row"
                    onClose={this.hideConfirmDeleteMsg}
                    width={300} height={"auto"}
                    className={classes.title}>
                */
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Delete Row</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>OK to delete row?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                        {/*</Dialog>*/}
                    </Draggable>
                </div>
                : null
        }

        const retiredMaintenance = this.state.retiredForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">
                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} focusingField={this.state.focusingField} closeCode={this.closeCodeDialog} />}
                    <form onSubmit={this.saveRetired} id="retiredForm" className="DialogForm TabParentPage">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo">
                                    <button type="button" onClick={this.getHelp} name="btnHelp"><img src="https://crstar.ers-can.com/images/$sys_question.png.a5image" /></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {
                                        //this.props.selectedPatient.dob != null && this.props.selectedPatient.dob !== "" ? moment(new Date(this.props.selectedPatient.dob)).format("MM/DD/YYYY") : ""
                                        this.props.selectedPatient.dobMonth + "/" + this.props.selectedPatient.dobDay + "/" + this.props.selectedPatient.dobYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {
                                        //this.props.selectedPrimarySummary.diagnosisDate != null && this.props.selectedPrimarySummary.diagnosisDate !== "" ? moment(new Date(this.props.selectedPrimarySummary.diagnosisDate)).format("MM/DD/YYYY") : ""
                                        this.props.selectedPrimarySummary.diagnosisDateMonth + "/" + this.props.selectedPrimarySummary.diagnosisDateDay + "/" + this.props.selectedPrimarySummary.diagnosisDateYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>

                        <div className="emptyLine"></div>
                        <div className="row" id="retiredInputArea">
                            <div className="col-sm-12">
                                <div style={{ border: "1px solid #eee", marginBottom: "15px" }}>
                                    <Nav tabs tabIndex={0} autoFocus name="navTabOnRetiredMaint">
                                        <NavLink className={classnames({ active: this.state.activeTab === "1" })} onClick={(e) => { this.toggleTab(e, "1") }} href="#" id="linkOfRetiredTab1">
                                            Retired 1
                                        </NavLink>
                                        <NavLink className={classnames({ active: this.state.activeTab === "2" })} onClick={(e) => { this.toggleTab(e, "2") }} href="#" id="linkOfRetiredTab2">
                                            Retired 2
                                        </NavLink>
                                    </Nav>
                                    <TabContent activeTab={this.state.activeTab}>
                                        <TabPane tabId="1">
                                            <div className="row tab1">
                                                <div className="col-md-2 col1">
                                                    <div className="frameInner">
                                                        <div className="header">&nbsp;</div>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText="Extn Prost Path"
                                                            field="extnProstPath" itemNbr="1822"
                                                            value={this.state.retiredForm.extnProstPath}
                                                            dataSource="ExtnCodesPopup"
                                                            titleDialog="Extn Prost Path"
                                                            labelWidth="100px" width="25px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["extnProstPath"]}
                                                            lostfocused={this.handleLostFocus} autoFocus
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["extnProstPath"] : ""}
                                                            flag={this.state.retiredForm.fnExtnId}//flag={this.state.fnExtnId}
                                                            aliasNo={this.state.retiredForm.fnExtnId}//aliasNo="61"
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Tumor Marker 1" field="tumorMarker1" itemNbr="523"
                                                            value={this.state.retiredForm.tumorMarker1}
                                                            dataSource="Global_Dir_Mv" aliasNo="523" titleDialog="Tumor Marker 1"
                                                            labelWidth="100px" width="25px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["tumorMarker1"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tumorMarker1"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Tumor Marker 2" field="tumorMarker2" itemNbr="523"
                                                            value={this.state.retiredForm.tumorMarker2}
                                                            dataSource="Global_Dir_Mv" aliasNo="523" titleDialog="Tumor Marker 2"
                                                            labelWidth="100px" width="25px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["tumorMarker2"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tumorMarker2"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Tumor Marker 3" field="tumorMarker3" itemNbr="523"
                                                            value={this.state.retiredForm.tumorMarker3}
                                                            dataSource="Global_Dir_Mv" aliasNo="523" titleDialog="Tumor Marker 3"
                                                            labelWidth="100px" width="25px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["tumorMarker3"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tumorMarker3"] : ""}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Tum Rec Nbr" field="tumRecNbr" lineStyle="oneLine"
                                                            value={this.state.retiredForm.tumRecNbr}
                                                            labelWidth="100px" width="25px" itemNbr="504"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tumRecNbr"] : ""}
                                                            inValid={this.state.dAllItemFields["tumRecNbr"]}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Ref To Support" field="refToSupport" itemNbr="785"
                                                            value={this.state.retiredForm.refToSupport}
                                                            dataSource="Global_Dir_Mv" aliasNo="785" titleDialog="Ref To Support"
                                                            labelWidth="100px" width="25px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["refToSupport"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["refToSupport"] : ""}
                                                        />
                                                        <Input type="date" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Pos Bx Date" field="posBxDate" lineStyle="oneLine"
                                                            value={this.state.retiredForm.posBxDate} labelWidth="100px" width="130px"

                                                            inValidDay={this.state.dAllItemFields["posBxDateDay"]}
                                                            inValidMonth={this.state.dAllItemFields["posBxDateMonth"]}
                                                            inValidYear={this.state.dAllItemFields["posBxDateYear"]}
                                                            dayVal={this.state.retiredForm.posBxDateDay}
                                                            monthVal={this.state.retiredForm.posBxDateMonth}
                                                            yearVal={this.state.retiredForm.posBxDateYear}
                                                            lostfocused={this.handleLostFocus}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Surg Approach" field="surgApproach" itemNbr="775"
                                                            value={this.state.retiredForm.surgApproach}
                                                            dataSource="SiteApprCodesPopup" aliasNo="775" titleDialog="Surg Approach"
                                                            labelWidth="100px" width="25px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["surgApproach"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["surgApproach"] : ""}
                                                            flag=""//Site_Appr_ID (N)|Equals|Fn_SurgApproachId
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Rsn No Chemo" field="rsnNoChemo" itemNbr="550"
                                                            value={this.state.retiredForm.rsnNoChemo}
                                                            dataSource="Global_Dir_Mv" aliasNo="550" titleDialog="Rsn No Chemo"
                                                            labelWidth="100px" width="25px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["rsnNoChemo"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rsnNoChemo"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Rsn No Horm" field="rsnNoHorm" itemNbr="551"
                                                            value={this.state.retiredForm.rsnNoHorm}
                                                            dataSource="Global_Dir_Mv" aliasNo="551" titleDialog="Rsn No Horm"
                                                            labelWidth="100px" width="25px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["rsnNoHorm"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rsnNoHorm"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Rad Br Cs" field="radBrCs" itemNbr="129"
                                                            value={this.state.retiredForm.radBrCs}
                                                            dataSource="Global_Dir_Mv" aliasNo="129" titleDialog="Rad Br Cs"
                                                            labelWidth="100px" width="25px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["radBrCs"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["radBrCs"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Recon Surg" field="reconSurg" itemNbr="774"
                                                            value={this.state.retiredForm.reconSurg}
                                                            dataSource="SiteApprCodesPopup" aliasNo="774" titleDialog="Recon Surg"
                                                            labelWidth="100px" width="25px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["reconSurg"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["reconSurg"] : ""}
                                                            flag=""//Site_Appr_ID (N)|Equals|Fn_ReconImmediateId
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Recon Delayed" field="reconDelayed" itemNbr="1416"
                                                            value={this.state.retiredForm.reconDelayed}
                                                            dataSource="SiteApprCodesPopup" aliasNo="1416" titleDialog="Recon Delayed"
                                                            labelWidth="100px" width="25px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["reconDelayed"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["reconDelayed"] : ""}
                                                            flag=""//Site_Appr_ID (N)|Equals|Fn_recondelayedid
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 extendDiv col2">
                                                    <div className="frameInner">
                                                        <div className="header">&nbsp;</div>
                                                        <Input type="date" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Date Conclusive" field="dateConclusive" lineStyle="oneLine"
                                                            value={this.state.retiredForm.dateConclusive} labelWidth="100px" width="140px"

                                                            inValidDay={this.state.dAllItemFields["dateConclusiveDay"]}
                                                            inValidMonth={this.state.dAllItemFields["dateConclusiveMonth"]}
                                                            inValidYear={this.state.dAllItemFields["dateConclusiveYear"]}
                                                            dayVal={this.state.retiredForm.dateConclusiveDay}
                                                            monthVal={this.state.retiredForm.dateConclusiveMonth}
                                                            yearVal={this.state.retiredForm.dateConclusiveYear}
                                                            lostfocused={this.handleLostFocus}
                                                        />
                                                        <Input type="date" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Date Mult Tumor" field="dateMultTum" lineStyle="oneLine"
                                                            value={this.state.retiredForm.dateMultTum} labelWidth="100px" width="140px"

                                                            inValidDay={this.state.dAllItemFields["dateMultTumDay"]}
                                                            inValidMonth={this.state.dAllItemFields["dateMultTumMonth"]}
                                                            inValidYear={this.state.dAllItemFields["dateMultTumYear"]}
                                                            dayVal={this.state.retiredForm.dateMultTumDay}
                                                            monthVal={this.state.retiredForm.dateMultTumMonth}
                                                            yearVal={this.state.retiredForm.dateMultTumYear}
                                                            lostfocused={this.handleLostFocus}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Mult Tumor Report As One" field="multTumReportedAsOne" itemNbr="3039"
                                                            value={this.state.retiredForm.multTumReportedAsOne}
                                                            dataSource="Global_Dir_Mv" aliasNo="3039"
                                                            titleDialog="Mult Tumor Report As One"
                                                            labelWidth="160px" width="30px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["multTumReportedAsOne"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["multTumReportedAsOne"] : ""}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Multiplicity Counter" field="multiplicityCounter" lineStyle="oneLine"
                                                            value={this.state.retiredForm.multiplicityCounter}
                                                            labelWidth="160px" width="30px" itemNbr="3041"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["multiplicityCounter"] : ""}
                                                            inValid={this.state.dAllItemFields["multiplicityCounter"]}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Ambig Term" field="ambigTerminology" itemNbr="3037"
                                                            value={this.state.retiredForm.ambigTerminology}
                                                            dataSource="Global_Dir_Mv" aliasNo="3037"
                                                            titleDialog="Ambig_terminology"
                                                            labelWidth="100px" width="30px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["ambigTerminology"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ambigTerminology"] : ""}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Size" field="size" lineStyle="oneLine"
                                                            value={this.state.retiredForm.size}
                                                            labelWidth="100px" width="60px" itemNbr="119"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["size"] : ""}
                                                            inValid={this.state.dAllItemFields["size"]}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Rx Intent" field="radRxIntent" itemNbr="778"
                                                            value={this.state.retiredForm.radRxIntent}
                                                            dataSource="Global_Dir_Mv" aliasNo="778"
                                                            titleDialog="Rad Rx Intent"
                                                            labelWidth="100px" width="30px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["radRxIntent"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["radRxIntent"] : ""}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Reg Dose Cgy" field="regRxDoseCgySumm" lineStyle="oneLine"
                                                            value={this.state.retiredForm.regRxDoseCgySumm}
                                                            labelWidth="100px" width="60px" itemNbr="835"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["regRxDoseCgySumm"] : ""}
                                                            inValid={this.state.dAllItemFields["regRxDoseCgySumm"]}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Boost Dose Cgy" field="boostDoseCgySumm" lineStyle="oneLine"
                                                            value={this.state.retiredForm.boostDoseCgySumm}
                                                            labelWidth="100px" width="60px" itemNbr="2121"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["boostDoseCgySumm"] : ""}
                                                            inValid={this.state.dAllItemFields["boostDoseCgySumm"]}
                                                        />

                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Rad Comp St" field="radRxCmplSt" itemNbr="3958"
                                                            value={this.state.retiredForm.radRxCmplSt}
                                                            dataSource="Global_Dir_Mv" aliasNo="3958"
                                                            titleDialog="Rad Rx Cmpl St"
                                                            labelWidth="100px" width="30px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["radRxCmplSt"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["radRxCmplSt"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Rad Vol" field="radVolSumm" itemNbr="3960"
                                                            value={this.state.retiredForm.radVolSumm}
                                                            dataSource="Global_Dir_Mv" aliasNo="3960"
                                                            titleDialog="Rad Vol Summ"
                                                            labelWidth="100px" width="40px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["radVolSumm"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["radVolSumm"] : ""}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Rad Nbr Vol" field="radNbrVolSumm" lineStyle="oneLine"
                                                            value={this.state.retiredForm.radNbrVolSumm}
                                                            labelWidth="100px" width="60px" itemNbr="836"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["radNbrVolSumm"] : ""}
                                                            inValid={this.state.dAllItemFields["radNbrVolSumm"]}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Loc Cntr St" field="radLocCntrSt" itemNbr="781"
                                                            value={this.state.retiredForm.radLocCntrSt}
                                                            dataSource="Global_Dir_Mv" aliasNo="781"
                                                            titleDialog="Rad Loc Cntr St"
                                                            labelWidth="100px" width="30px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["radLocCntrSt"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["radLocCntrSt"] : ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2 extendRightDiv col3">
                                                    <div className="frameInner">
                                                        <div className="header">&nbsp;</div>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="oT" field="ot" lineStyle="oneLine"
                                                            value={this.state.retiredForm.ot}

                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="T" pcy="YC" pc2="YC"
                                                            topoCode={this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}
                                                            histCode={this.props.selectedMedicalRecord.HistCode ? this.props.selectedMedicalRecord.HistCode : this.props.selectedMedicalRecord.histCode}

                                                            titleDialog="Other T of TNM" labelWidth="100px" width="25px"
                                                            inValid={this.state.dAllItemFields["ot"]} itemNbr="800"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ot"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="oN" field="on" lineStyle="oneLine"
                                                            value={this.state.retiredForm.on}

                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="N" pcy="YC" pc2="YC"
                                                            topoCode={this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}
                                                            histCode={this.props.selectedMedicalRecord.HistCode ? this.props.selectedMedicalRecord.HistCode : this.props.selectedMedicalRecord.histCode}

                                                            titleDialog="Other N of Tnm" labelWidth="100px" width="25px"
                                                            inValid={this.state.dAllItemFields["on"]} itemNbr="801"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["on"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="oM" field="om" lineStyle="oneLine"
                                                            value={this.state.retiredForm.om}

                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="M" pcy="YC" pc2="YC"
                                                            topoCode={this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}
                                                            histCode={this.props.selectedMedicalRecord.HistCode ? this.props.selectedMedicalRecord.HistCode : this.props.selectedMedicalRecord.histCode}

                                                            titleDialog="Other M of Tnm" labelWidth="100px" width="25px"
                                                            inValid={this.state.dAllItemFields["om"]} itemNbr="802"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["om"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="o Ajcc Grp" field="oAjccGrp" lineStyle="oneLine"
                                                            value={this.state.retiredForm.oAjccGrp}

                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="G" pcy="YC" pc2="YC"
                                                            topoCode={this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}
                                                            histCode={this.props.selectedMedicalRecord.HistCode ? this.props.selectedMedicalRecord.HistCode : this.props.selectedMedicalRecord.histCode}

                                                            titleDialog="Other Ajcc Group of Tnm" labelWidth="100px" width="25px"
                                                            inValid={this.state.dAllItemFields["oAjccGrp"]} itemNbr="803"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["oAjccGrp"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="o Staged By" field="oStagedBy" lineStyle="oneLine"
                                                            value={this.state.retiredForm.oStagedBy}
                                                            dataSource="Global_Dir_Mv" aliasNo="804"
                                                            titleDialog="O Staged By" labelWidth="100px" width="25px"
                                                            inValid={this.state.dAllItemFields["oStagedBy"]} itemNbr="804"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["oStagedBy"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="o Stg Descriptor" field="oStgDescriptor" lineStyle="oneLine"
                                                            value={this.state.retiredForm.oStgDescriptor}
                                                            dataSource="Global_Dir_Mv" aliasNo="807"
                                                            titleDialog="O Stg Descriptor" labelWidth="100px" width="25px"
                                                            inValid={this.state.dAllItemFields["oStgDescriptor"]} itemNbr="807"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["oStgDescriptor"] : ""}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Oth Stg Sys" field="othStgSys" lineStyle="oneLine"
                                                            value={this.state.retiredForm.othStgSys}
                                                            labelWidth="100px" width="25px" itemNbr="41"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["othStgSys"] : ""}
                                                            inValid={this.state.dAllItemFields["othStgSys"]}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Oth Stg" field="othStg" lineStyle="oneLine"
                                                            value={this.state.retiredForm.othStg}
                                                            labelWidth="100px" width="25px" itemNbr="42"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["othStg"] : ""}
                                                            inValid={this.state.dAllItemFields["othStg"]}
                                                        />
                                                    </div>
                                                        <div id="multiTabShowByVertical">
                                                            <Nav tabs tabIndex={0} autoFocus name="navTabOnInnerRetired" vertical>
                                                                <NavLink className={classnames({ active: this.state.activeTabInner === "os" })} onClick={(e) => { this.toggleTabInner(e, "os") }} href="#" id="linkOfOStageDescTab">
                                                                    O Stage Desc
                                                                </NavLink>
                                                                <NavLink className={classnames({ active: this.state.activeTabInner === "cs" })} onClick={(e) => { this.toggleTabInner(e, "cs") }} href="#" id="linkOfCStageDescTab">
                                                                    C Stage Desc
                                                                </NavLink>
                                                                <NavLink className={classnames({ active: this.state.activeTabInner === "ps" })} onClick={(e) => { this.toggleTabInner(e, "ps") }} href="#" id="linkOfPStageDescTab">
                                                                    P Stage Desc
                                                                </NavLink>
                                                            </Nav>
                                                            <TabContent activeTab={this.state.activeTabInner}>
                                                                <TabPane tabId="os">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <Input type="textarea" focused={this.handleOnFocus}
                                                                                changed={this.handleFormChange}
                                                                                inValid={this.state.dAllItemFields["oStageDesc"]}
                                                                                labelText="" field="oStageDesc"
                                                                                value={this.state.retiredForm.oStageDesc}
                                                                                labelWidth="120px" width="100px" rows="4" itemNbr="823"
                                                                                hasDoubleClickEvent="warning"
                                                                                maxlen="1000" mainLabelText="O Stage Desc"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </TabPane>
                                                                <TabPane tabId="cs">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <Input type="textarea" focused={this.handleOnFocus}
                                                                                changed={this.handleFormChange}
                                                                                inValid={this.state.dAllItemFields["cStageDesc"]}
                                                                                labelText="" field="cStageDesc"
                                                                                value={this.state.retiredForm.cStageDesc}
                                                                                labelWidth="120px" width="100px" rows="4" itemNbr="821"
                                                                                hasDoubleClickEvent="warning"
                                                                                maxlen="1000" mainLabelText="C Stage Desc"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </TabPane>
                                                                <TabPane tabId="ps">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <Input type="textarea" focused={this.handleOnFocus}
                                                                                changed={this.handleFormChange}
                                                                                inValid={this.state.dAllItemFields["pStageDesc"]}
                                                                                labelText="" field="pStageDesc"
                                                                                value={this.state.retiredForm.pStageDesc}
                                                                                labelWidth="120px" width="100px" rows="4" itemNbr="822"
                                                                                hasDoubleClickEvent="warning"
                                                                                maxlen="1000" mainLabelText="P Stage Desc"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </TabPane>
                                                            </TabContent>
                                                        </div>
                                                    {/* </div> */}
                                                </div>
                                                <div className="col-md-2 extendRightDiv col4">
                                                    <div className="frameInner">
                                                        <div className="header">&nbsp;</div>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Ssn Suffix" field="ssnSuffix" lineStyle="oneLine"
                                                            value={this.state.retiredForm.ssnSuffix}
                                                            dataSource="Global_Dir_Mv" aliasNo="793"
                                                            titleDialog="Ssn Suffix" labelWidth="100px" width="25px"
                                                            inValid={this.state.dAllItemFields["ssnSuffix"]} itemNbr="793"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ssnSuffix"] : ""}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Dx Census Tract" field="dxCensusTract" lineStyle="oneLine"
                                                            value={this.state.retiredForm.dxCensusTract}
                                                            labelWidth="100px" width="45px" itemNbr="201"
                                                            lostfocused={this.handleLostFocus}
                                                            inValid={this.state.dAllItemFields["dxCensusTract"]}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dxCensusTract"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Census Code Sys" field="censusCodeSys" lineStyle="oneLine"
                                                            value={this.state.retiredForm.censusCodeSys}
                                                            dataSource="Global_Dir_Mv" aliasNo="202"
                                                            titleDialog="Census Code Sys" labelWidth="100px" width="25px"
                                                            inValid={this.state.dAllItemFields["censusCodeSys"]} itemNbr="202"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["censusCodeSys"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Census Poverty Indicator" field="censusPovertyInd" lineStyle="oneLine"
                                                            value={this.state.retiredForm.censusPovertyInd}
                                                            dataSource="Global_Dir_Mv" aliasNo="4013"
                                                            titleDialog="Census Poverty Indicator" labelWidth="100px" width="25px"
                                                            inValid={this.state.dAllItemFields["censusPovertyInd"]} itemNbr="4013"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["censusPovertyInd"] : ""}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Dx Occupation" field="dxOccupation" lineStyle="oneLine"
                                                            value={this.state.retiredForm.dxOccupation}
                                                            labelWidth="100px" width="175px" itemNbr="810"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dxOccupation"] : ""}
                                                            inValid={this.state.dAllItemFields["dxOccupation"]}
                                                            hasDoubleClickEvent="warning"
                                                            maxlen="1000"
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Dx Industry" field="dxIndustry" lineStyle="oneLine"
                                                            value={this.state.retiredForm.dxIndustry}
                                                            labelWidth="100px" width="175px" itemNbr="811"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dxIndustry"] : ""}
                                                            inValid={this.state.dAllItemFields["dxIndustry"]}
                                                            hasDoubleClickEvent="warning"
                                                            maxlen="1000"
                                                        />
                                                    </div>

                                                    <div className="retiredScreenOnTab1Col4_5">
                                                        <div className="row">
                                                            <div className="col-sm-12 recurrenceMvListArea">
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ width: "15px" }}>&nbsp;</th>
                                                                            <th style={{ width: "180px" }}>
                                                                                <Input type="gridHeader" labelWidth="180px"
                                                                                    labelText="Recur Date" field="RecurDate"
                                                                                /></th>
                                                                            <th style={{ width: "40px" }}>
                                                                                <Input type="gridHeader" labelWidth="40px"
                                                                                    labelText="Met 1" field="RecurMet1"
                                                                                /></th>
                                                                            <th style={{ width: "40px" }}>
                                                                                <Input type="gridHeader" labelWidth="40px"
                                                                                    labelText="Met 2" field="RecurMet2"
                                                                                /></th>
                                                                            <th style={{ width: "40px" }}>
                                                                                <Input type="gridHeader" labelWidth="40px"
                                                                                    labelText="Met 3" field="RecurMet3"
                                                                                /></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                        <tr>
                                                                            <th colSpan="5">&nbsp;</th>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <div className="innerBody">
                                                                    <table>
                                                                        <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                            <tr>
                                                                                <th style={{ width: "15px" }}>&nbsp;</th>
                                                                                <th style={{ width: "150px" }}></th>
                                                                                <th style={{ width: "40px" }}></th>
                                                                                <th style={{ width: "40px" }}></th>
                                                                                <th style={{ width: "40px" }}></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.recurrenceMvItemFields
                                                                                && this.state.recurrenceMv !== null && this.state.recurrenceMv.length > 0
                                                                                ? this.state.recurrenceMv.map((prm, index) => (
                                                                                    <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionRecurrenceRow(e, index) }} className={this.state.selectedRecurrenceRowIndex === index ? "selectedRowCls" : ""}>
                                                                                        <td style={{ width: "15px" }}>
                                                                                            {index + 1}
                                                                                        </td>
                                                                                        <td>
                                                                                            <Input type="date" focused={this.handleOnFocus} labelWidth="0px"
                                                                                                changed={(e) => { this.handleRecurrenceMvGridChange(e, index) }}
                                                                                                labelText="" field="recurDate" width="90px" lineStyle="oneLine"
                                                                                                value={prm.recurDate != null && prm.recurDate !== "" ? moment(new Date(prm.recurDate)).format("MM/DD/YYYY") : ""}
                                                                                                rowIndex={index}//keyId={"recurDate" + index}

                                                                                                inValidDay={this.state.recurrenceMvItemFields[index]["recurDateDay"]}
                                                                                                inValidMonth={this.state.recurrenceMvItemFields[index]["recurDateMonth"]}
                                                                                                inValidYear={this.state.recurrenceMvItemFields[index]["recurDateYear"]}
                                                                                                dayVal={prm.recurDateDay}
                                                                                                monthVal={prm.recurDateMonth}
                                                                                                yearVal={prm.recurDateYear}
                                                                                                lostfocused={(e) => { this.handleRecurrenceMvLostfocus(e, index) }}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                                changed={(e) => { this.handleRecurrenceMvGridChange(e, index) }}
                                                                                                labelText="" field="recurMet1" value={prm.recurMet1} labelWidth="0px"
                                                                                                dataSource="Global_Dir_Mv"
                                                                                                titleDialog="Recur Met 1" aliasNo="125" width="30px"
                                                                                                inValid={this.state.recurrenceMvItemFields[index]["recurMet1"]}
                                                                                                itemNbr="125" keyId={"recurMet1" + index}
                                                                                                //maxLength="1"
                                                                                                maxLength={this.state.recurrenceMvMaxlengthInput ? this.state.recurrenceMvMaxlengthInput["recurMet1"] : ""}
                                                                                                lostfocused={(e) => { this.handleRecurrenceMvLostfocus(e, index) }}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                                changed={(e) => { this.handleRecurrenceMvGridChange(e, index) }}
                                                                                                labelText="" field="recurMet2" value={prm.recurMet2} labelWidth="0px"
                                                                                                dataSource="Global_Dir_Mv"
                                                                                                titleDialog="Recur Met 2" aliasNo="125" width="30px"
                                                                                                inValid={this.state.recurrenceMvItemFields[index]["recurMet2"]}
                                                                                                itemNbr="125" keyId={"recurMet2" + index}
                                                                                                //maxLength="1"
                                                                                                maxLength={this.state.recurrenceMvMaxlengthInput ? this.state.recurrenceMvMaxlengthInput["recurMet2"] : ""}
                                                                                                lostfocused={(e) => { this.handleRecurrenceMvLostfocus(e, index) }}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                                changed={(e) => { this.handleRecurrenceMvGridChange(e, index) }}
                                                                                                labelText="" field="recurMet3" value={prm.recurMet3} labelWidth="0px"
                                                                                                dataSource="Global_Dir_Mv"
                                                                                                titleDialog="Recur Met 3" aliasNo="125" width="30px"
                                                                                                inValid={this.state.recurrenceMvItemFields[index]["recurMet3"]}
                                                                                                itemNbr="125" keyId={"recurMet3" + index}
                                                                                                //maxLength="1"
                                                                                                maxLength={this.state.recurrenceMvMaxlengthInput ? this.state.recurrenceMvMaxlengthInput["recurMet3"] : ""}
                                                                                                lostfocused={(e) => { this.handleRecurrenceMvLostfocus(e, index) }}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                )) : <tr><td colSpan="5">No records available</td></tr>
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="contactBtnArea right" style={{ width: "155px" }}>
                                                                    <button onClick={this.addNewRecurrenceMv}>Add</button>
                                                                    <button onClick={this.deleteSelectedRecurrenceMv} id="btnSelectedRecurrenceMv" onKeyDown={(e) => { this.keydownHandler(e) }}>Delete</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col5">
                                                    <div className="frameInner">
                                                        <div className="header">&nbsp;</div>
                                                        <Input type="date" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Date Entered" field="dateEntered" lineStyle="oneLine"
                                                            value={this.state.retiredForm.dateEntered} labelWidth="100px" width="140px"

                                                            inValidDay={this.state.dAllItemFields["dateEnteredDay"]}
                                                            inValidMonth={this.state.dAllItemFields["dateEnteredMonth"]}
                                                            inValidYear={this.state.dAllItemFields["dateEnteredYear"]}
                                                            dayVal={this.state.retiredForm.dateEnteredDay}
                                                            monthVal={this.state.retiredForm.dateEnteredMonth}
                                                            yearVal={this.state.retiredForm.dateEnteredYear}
                                                            lostfocused={this.handleLostFocus}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Entered By" field="enteredBy" lineStyle="oneLine"
                                                            value={this.state.retiredForm.enteredBy}
                                                            labelWidth="100px" width="80px" itemNbr="367"
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["enteredBy"] : ""}
                                                            inValidYear={this.state.dAllItemFields["enteredBy"]}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Site Dist 1" field="siteDist" lineStyle="oneLine"
                                                            value={this.state.retiredForm.siteDist}
                                                            dataSource="Global_Dir_Mv" aliasNo="157"
                                                            titleDialog="Site Dist" labelWidth="100px" width="25px"
                                                            inValid={this.state.dAllItemFields["siteDist"]} itemNbr="157"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["siteDist"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Site Dist 2" field="siteDist2" lineStyle="oneLine"
                                                            value={this.state.retiredForm.siteDist2}
                                                            dataSource="Global_Dir_Mv" aliasNo="4067"
                                                            titleDialog="Site Dist" labelWidth="100px" width="25px"
                                                            inValid={this.state.dAllItemFields["siteDist2"]} itemNbr="157"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["siteDist2"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Site Dist 3" field="siteDist3" lineStyle="oneLine"
                                                            value={this.state.retiredForm.siteDist3}
                                                            dataSource="Global_Dir_Mv" aliasNo="4068"
                                                            titleDialog="Site Dist" labelWidth="100px" width="25px"
                                                            inValid={this.state.dAllItemFields["siteDist3"]} itemNbr="157"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["siteDist3"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Recur Other" field="recurOther" lineStyle="oneLine"
                                                            value={this.state.retiredForm.recurOther}
                                                            dataSource="Global_Dir_Mv" aliasNo="787"
                                                            titleDialog="Recur Other" labelWidth="100px" width="30px"
                                                            inValid={this.state.dAllItemFields["recurOther"]} itemNbr="787"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["recurOther"] : ""}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="frameInner">
                                                        <div className="header">&nbsp;</div>
                                                        <div className="row tab2">
                                                            <div className="col-md-3 col1">
                                                                <fieldset>
                                                                    <legend>Drug</legend>
                                                                    <div className="retiredScreenOnTab2Col1">
                                                                        <div className="row">
                                                                            <div className="col-sm-12 userDrgMvListArea">
                                                                                <table>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th style={{ width: "80px" }}>
                                                                                                <Input type="gridHeader" labelWidth="auto"
                                                                                                    labelText="Drg Code" field="DrgCode"
                                                                                                /></th>
                                                                                            <th style={{ width: "80px" }}>
                                                                                                <Input type="gridHeader" labelWidth="auto"
                                                                                                    labelText="Amt Bld" field="AmtBld"
                                                                                                /></th>
                                                                                            <th style={{ width: "80px" }}>
                                                                                                <Input type="gridHeader" labelWidth="auto"
                                                                                                    labelText="Len Stay" field="LenStay"
                                                                                                /></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                                        <tr>
                                                                                            <th colSpan="3">&nbsp;</th>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                <div className="innerBody">
                                                                                    <table>
                                                                                        <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                                            <tr>
                                                                                                <th style={{ width: "80px" }}></th>
                                                                                                <th style={{ width: "80px" }}></th>
                                                                                                <th style={{ width: "80px" }}></th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {this.state.userDrgMvItemFields
                                                                                                && this.state.userDrgMv !== null && this.state.userDrgMv.length > 0
                                                                                                ? this.state.userDrgMv.map((prm, index) => (
                                                                                                    <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionUserDrgRow(e, index) }} className={this.state.selectedUserDrgRowIndex === index ? "selectedRowCls" : ""}>
                                                                                                        <td>
                                                                                                            <Input type="input" focused={this.handleOnFocus}
                                                                                                                changed={(e) => { this.handleUserDrgMvGridChange(e, index) }}
                                                                                                                labelWidth="0px" lineStyle="oneLine"
                                                                                                                labelText="" field="drgCode" keyId={"drgCode" + index}
                                                                                                                value={prm.drgCode} width="100%"
                                                                                                                //maxLength="7"
                                                                                                                maxLength={this.state.userDrgMvMaxlengthInput ? this.state.userDrgMvMaxlengthInput["drgCode"] : ""}
                                                                                                                inValid={this.state.userDrgMvItemFields[index]["drgCode"]}
                                                                                                                rowIndex={index}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Input type="input" focused={this.handleOnFocus}
                                                                                                                changed={(e) => { this.handleUserDrgMvGridChange(e, index) }}
                                                                                                                labelWidth="0px" lineStyle="oneLine"
                                                                                                                labelText="" field="amtBld" keyId={"amtBld" + index}
                                                                                                                value={prm.amtBld} width="100%"
                                                                                                                //maxLength="11"
                                                                                                                maxLength={this.state.userDrgMvMaxlengthInput ? this.state.userDrgMvMaxlengthInput["amtBld"] : ""}
                                                                                                                inValid={this.state.userDrgMvItemFields[index]["amtBld"]}
                                                                                                                rowIndex={index}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Input type="input" focused={this.handleOnFocus}
                                                                                                                changed={(e) => { this.handleUserDrgMvGridChange(e, index) }}
                                                                                                                labelWidth="0px" lineStyle="oneLine"
                                                                                                                labelText="" field="lenStay" keyId={"lenStay" + index}
                                                                                                                value={prm.lenStay} width="100%"
                                                                                                                //maxLength="3"
                                                                                                                maxLength={this.state.userDrgMvMaxlengthInput ? this.state.userDrgMvMaxlengthInput["lenStay"] : ""}
                                                                                                                inValid={this.state.userDrgMvItemFields[index]["lenStay"]}
                                                                                                                rowIndex={index}
                                                                                                            />
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )) : <tr><td colSpan="3">No records available</td></tr>
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                                <div className="contactBtnArea right">
                                                                                    <button onClick={this.addNewUserDrgMv}>Add</button>
                                                                                    <button onClick={this.deleteSelectedUserDrgMv}>Delete</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                            <div className="col-md-3 extendDiv col2">
                                                                <fieldset>
                                                                    <legend>Chemotherapy</legend>
                                                                    <div className="retiredScreenOnTab2Col2">
                                                                        <div className="row">
                                                                            <div className="col-sm-12 userChemoMvListArea">
                                                                                <table>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th style={{ width: "160px" }}>
                                                                                                <Input type="gridHeader" labelWidth="auto"
                                                                                                    labelText="Chemo Date" field="ChemoDate"
                                                                                                /></th>
                                                                                            <th style={{ width: "80px" }}>
                                                                                                <Input type="gridHeader" labelWidth="auto"
                                                                                                    labelText="Chemo Type" field="ChemoType"
                                                                                                /></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                                        <tr>
                                                                                            <th colSpan="2">&nbsp;</th>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                <div className="innerBody">
                                                                                    <table>
                                                                                        <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                                            <tr>
                                                                                                <th style={{ width: "160px" }}></th>
                                                                                                <th style={{ width: "80px" }}></th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {this.state.userChemoMvItemFields
                                                                                                && this.state.userChemoMv !== null && this.state.userChemoMv.length > 0
                                                                                                ? this.state.userChemoMv.map((prm, index) => (
                                                                                                    <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionUserChemoRow(e, index) }} className={this.state.selectedUserChemoRowIndex === index ? "selectedRowCls" : ""}>
                                                                                                        <td>
                                                                                                            <Input type="date" focused={this.handleOnFocus} labelWidth="0px"
                                                                                                                changed={(e) => { this.handleUserChemoMvGridChange(e, index) }}
                                                                                                                labelText="" field="chemoDate" width="90px" lineStyle="oneLine"
                                                                                                                value={prm.chemoDate != null && prm.chemoDate !== "" ? moment(new Date(prm.chemoDate)).format("MM/DD/YYYY") : ""}
                                                                                                                rowIndex={index}//keyId={"chemoDate" + index}

                                                                                                                inValidDay={this.state.userChemoMvItemFields[index]["chemoDateDay"]}
                                                                                                                inValidMonth={this.state.userChemoMvItemFields[index]["chemoDateMonth"]}
                                                                                                                inValidYear={this.state.userChemoMvItemFields[index]["chemoDateYear"]}
                                                                                                                dayVal={prm.chemoDateDay}
                                                                                                                monthVal={prm.chemoDateMonth}
                                                                                                                yearVal={prm.chemoDateYear}
                                                                                                                lostfocused={(e) => { this.handleUserChemoMvLostfocus(e, index) }}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Input type="input" focused={this.handleOnFocus}
                                                                                                                changed={(e) => { this.handleUserChemoMvGridChange(e, index) }}
                                                                                                                labelWidth="0px" lineStyle="oneLine"
                                                                                                                labelText="" field="chemoType"
                                                                                                                value={prm.chemoType} width="100%"
                                                                                                                //maxLength="50"
                                                                                                                maxLength={this.state.userChemoMvMaxlengthInput ? this.state.userChemoMvMaxlengthInput["chemoType"] : ""}
                                                                                                                inValid={this.state.userChemoMvItemFields[index]["chemoType"]}
                                                                                                                rowIndex={index}
                                                                                                            />
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )) : <tr><td colSpan="2">No records available</td></tr>
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                                <div className="contactBtnArea right">
                                                                                    <button onClick={this.addNewUserChemoMv}>Add</button>
                                                                                    <button onClick={this.deleteSelectedUserChemoMv}>Delete</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                            <div className="col-md-5 extendRightDiv col3">
                                                                <fieldset>
                                                                    <legend>Radiation</legend>
                                                                    <div className="retiredScreenOnTab2Col3">
                                                                        <div className="row">
                                                                            <div className="col-sm-12 userRadiationMvListArea">
                                                                                <table>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th style={{ width: "160px" }}>
                                                                                                <Input type="gridHeader" labelWidth="auto"
                                                                                                    labelText="Rad Date" field="RadDate"
                                                                                                /></th>
                                                                                            <th style={{ width: "80px" }}>
                                                                                                <Input type="gridHeader" labelWidth="auto"
                                                                                                    labelText="Rad Sites" field="RadSites"
                                                                                                /></th>
                                                                                            <th style={{ width: "80px" }}>
                                                                                                <Input type="gridHeader" labelWidth="auto"
                                                                                                    labelText="Nbr Rads" field="NbrRads"
                                                                                                /></th>
                                                                                            <th style={{ width: "80px" }}>
                                                                                                <Input type="gridHeader" labelWidth="auto"
                                                                                                    labelText="Fxs" field="Fxs"
                                                                                                /></th>
                                                                                            <th style={{ width: "80px" }}>
                                                                                                <Input type="gridHeader" labelWidth="auto"
                                                                                                    labelText="Modality" field="Modality"
                                                                                                /></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                                        <tr>
                                                                                            <th colSpan="5">&nbsp;</th>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                <div className="innerBody">
                                                                                    <table>
                                                                                        <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                                            <tr>
                                                                                                <th style={{ width: "160px" }}></th>
                                                                                                <th style={{ width: "80px" }}></th>
                                                                                                <th style={{ width: "80px" }}></th>
                                                                                                <th style={{ width: "80px" }}></th>
                                                                                                <th style={{ width: "80px" }}></th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {this.state.userRadiationMvItemFields
                                                                                                && this.state.userRadiationMv !== null && this.state.userRadiationMv.length > 0
                                                                                                ? this.state.userRadiationMv.map((prm, index) => (
                                                                                                    <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionUserRadiationRow(e, index) }} className={this.state.selectedUserRadiationRowIndex === index ? "selectedRowCls" : ""}>
                                                                                                        <td>
                                                                                                            <Input type="date" focused={this.handleOnFocus} labelWidth="0px"
                                                                                                                changed={(e) => { this.handleUserRadiationMvGridChange(e, index) }}
                                                                                                                labelText="" field="radDate" width="90px" lineStyle="oneLine"
                                                                                                                value={prm.radDate != null && prm.radDate !== "" ? moment(new Date(prm.radDate)).format("MM/DD/YYYY") : ""}
                                                                                                                rowIndex={index}//keyId={"radDate" + index}

                                                                                                                inValidDay={this.state.userRadiationMvItemFields[index]["radDateDay"]}
                                                                                                                inValidMonth={this.state.userRadiationMvItemFields[index]["radDateMonth"]}
                                                                                                                inValidYear={this.state.userRadiationMvItemFields[index]["radDateYear"]}
                                                                                                                dayVal={prm.radDateDay}
                                                                                                                monthVal={prm.radDateMonth}
                                                                                                                yearVal={prm.radDateYear}
                                                                                                                lostfocused={(e) => { this.handleUserRadiationMvLostfocus(e, index) }}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Input type="input" focused={this.handleOnFocus}
                                                                                                                changed={(e) => { this.handleUserRadiationMvGridChange(e, index) }}
                                                                                                                labelWidth="0px" lineStyle="oneLine"
                                                                                                                labelText="" field="radSites"
                                                                                                                value={prm.radSites} width="100%"
                                                                                                                keyId={"radSites" + index}
                                                                                                                //maxLength="50"
                                                                                                                maxLength={this.state.userRadiationMvMaxlengthInput ? this.state.userRadiationMvMaxlengthInput["radSites"] : ""}
                                                                                                                inValidDay={this.state.userRadiationMvItemFields[index]["radSites"]}
                                                                                                                rowIndex={index}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Input type="input" focused={this.handleOnFocus}
                                                                                                                changed={(e) => { this.handleUserRadiationMvGridChange(e, index) }}
                                                                                                                labelWidth="0px" lineStyle="oneLine"
                                                                                                                labelText="" field="nbrRads"
                                                                                                                value={prm.nbrRads} width="100%"
                                                                                                                keyId={"nbrRads" + index}
                                                                                                                //maxLength="6"
                                                                                                                maxLength={this.state.userRadiationMvMaxlengthInput ? this.state.userRadiationMvMaxlengthInput["nbrRads"] : ""}
                                                                                                                inValidDay={this.state.userRadiationMvItemFields[index]["nbrRads"]}
                                                                                                                rowIndex={index}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Input type="input" focused={this.handleOnFocus}
                                                                                                                changed={(e) => { this.handleUserRadiationMvGridChange(e, index) }}
                                                                                                                labelWidth="0px" lineStyle="oneLine"
                                                                                                                labelText="" field="fxs"
                                                                                                                value={prm.fxs} width="100%"
                                                                                                                keyId={"fxs" + index}
                                                                                                                //maxLength="3"
                                                                                                                maxLength={this.state.userRadiationMvMaxlengthInput ? this.state.userRadiationMvMaxlengthInput["fxs"] : ""}
                                                                                                                inValidDay={this.state.userRadiationMvItemFields[index]["fxs"]}
                                                                                                                rowIndex={index}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Input type="input" focused={this.handleOnFocus}
                                                                                                                changed={(e) => { this.handleUserRadiationMvGridChange(e, index) }}
                                                                                                                labelWidth="0px" lineStyle="oneLine"
                                                                                                                labelText="" field="modality"
                                                                                                                value={prm.modality} width="100%"
                                                                                                                keyId={"modality" + index}
                                                                                                                //maxLength="6"
                                                                                                                maxLength={this.state.userRadiationMvMaxlengthInput ? this.state.userRadiationMvMaxlengthInput["modality"] : ""}
                                                                                                                inValidDay={this.state.userRadiationMvItemFields[index]["modality"]}
                                                                                                                rowIndex={index}
                                                                                                            />
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )) : <tr><td colSpan="5">No records available</td></tr>
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                                <div className="contactBtnArea right">
                                                                                    <button onClick={this.addNewUserRadiationMv}>Add</button>
                                                                                    <button onClick={this.deleteSelectedUserRadiationMv}>Delete</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                            <div className="row" style={{ margin: "15px" }}>
                                                                <div className="col-md-2">
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="Alcohol Usage" field="alch" lineStyle="oneLine"
                                                                        value={this.state.retiredForm.alch}
                                                                        dataSource="Global_Dir_Mv" aliasNo="38" itemNbr="38"
                                                                        titleDialog="Alch" labelWidth="150px" width="50px"
                                                                        inValid={this.state.dAllItemFields["alch"]}
                                                                        lostfocused={this.handleLostFocus}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["alch"] : ""}
                                                                    />
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="Family History" field="famHist" lineStyle="oneLine"
                                                                        value={this.state.retiredForm.famHist}
                                                                        dataSource="Global_Dir_Mv" aliasNo="172"
                                                                        titleDialog="Fam Hist" labelWidth="150px" width="50px"
                                                                        inValid={this.state.dAllItemFields["famHist"]} itemNbr="172"
                                                                        lostfocused={this.handleLostFocus}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["famHist"] : ""}
                                                                    />
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="Smoking" field="smoking" lineStyle="oneLine"
                                                                        value={this.state.retiredForm.smoking}
                                                                        dataSource="Global_Dir_Mv" aliasNo="171"
                                                                        titleDialog="Smoking" labelWidth="70px" width="50px"
                                                                        inValid={this.state.dAllItemFields["smoking"]} itemNbr="171"
                                                                        lostfocused={this.handleLostFocus}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["smoking"] : ""}
                                                                    />
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="Pack Yrs" field="packYrs" lineStyle="oneLine"
                                                                        value={this.state.retiredForm.packYrs}
                                                                        labelWidth="70px" width="50px" itemNbr="177"
                                                                        lostfocused={this.handleLostFocus}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["packYrs"] : ""}
                                                                        inValid={this.state.dAllItemFields["packYrs"]}
                                                                    />
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="Diagnosis of AIDS" field="aidsDx" lineStyle="oneLine"
                                                                        value={this.state.retiredForm.aidsDx}
                                                                        dataSource="Global_Dir_Mv" aliasNo="329"
                                                                        titleDialog="Aids Dx" labelWidth="120px" width="40px"
                                                                        inValid={this.state.dAllItemFields["aidsDx"]} itemNbr="329"
                                                                        lostfocused={this.handleLostFocus}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["aidsDx"] : ""}
                                                                    />
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="AIDS Dx Date" field="aidsDxDate" lineStyle="oneLine"
                                                                        value={this.state.retiredForm.aidsDxDate}
                                                                        labelWidth="100px" width="90px" itemNbr="330"
                                                                        lostfocused={this.handleLostFocus}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["aidsDxDate"] : ""}
                                                                        inValid={this.state.dAllItemFields["aidsDxDate"]}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </div>
                        </div>
                    </form>

                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetRetired} formId="retiredForm"
                        isFormDirty={this.state.isFormDirty}
                        showExtra={[{ name: "Text", action: "showDiagnosisText" }]}
                        callbackToParent={this.updateSaveFlagToProcess}
                        focusingField={this.state.focusingField}
                    />

                </div>
            )
            : <p className="data_loading_page">Retired data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {confirmDeleteMsg}
                </React.Fragment>

                {retiredMaintenance}

                <div id="textareaEditorForItemsCls">
                    {
                        this.props.isShowTextDialogVisible ?
                            /*
                            <Dialog
                                title="Diagnosis Text &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Treatment &amp; Other Text"
                                onClose={this.props.closeTextDialog}
                                width={950} height={"auto"}
                                className={classes.title}>*/
                            <div className="CRStar_Window">
                                <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar">
                                    <div className="dragableWindowCls">
                                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                                            <div className="k-window-title k-dialog-title">
                                                <pre className="textPopupPreTitleCls">{TextPopup.Title}</pre>
                                            </div>
                                            <div className="k-window-actions k-dialog-actions">
                                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeTextDialog(e) }}></span>
                                                </a>
                                            </div>
                                        </div>
                                        <TextForm closeWindow={this.closeTextDialog} setFocusingField={this.helpFocusingField} />
                                    </div>
                                    {/*</Dialog>*/}
                                </Draggable>
                            </div>
                            : null
                    }
                </div>

            </React.Fragment>
        );
    }
}

RetiredMaintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        isPatientLoading: state.patientAbstract.isPatientLoading,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
        selectedPatient: state.patientAbstract.selectedPatient,
        isShowTextDialogVisible: state.patientAbstract.isShowTextDialogVisible,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        selectedRetiredRecord: state.patientAbstract.selectedRetiredRecord,
        //
        clientgroup: state.auth.clientgroup,
        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,

        initialAbstractPages: state.patientAbstract.initialAbstractPages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveRetired: (formData) => dispatch(actionCreators.saveRetired(formData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index, flag, cid) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag, cid)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),

        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RetiredMaintenance);