/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Input from "../../../UI/Input";
import "./Hospital.css";
import axios from "../../../../axios-instance";
import Footer from "../../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
import * as Global from "../../../../store/Global";
import Draggable from 'react-draggable';

class RegistrarForm extends Component {

    state = {
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,

        pAllItemFields: null,
        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        showMessageError: false,
        hasError: false,
        savedForm: false,
        fieldInFocus: null,
        focusingField: null,
        isSaving: false,
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });

        var initials = this.state.dialogForm.registrarsIdDsp;
        if (event.target.name === "firstName") {
            if (event.target.value === "" && initials === "") {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, ["initials"]: "IN_VALID" },
                    fieldInFocus: "initials"
                });
            }
        }

        return false;
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showMessageError: false, hasError: false });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector(".Footer2>#btnSubmitForm_Fake") ? document.querySelector(".Footer2>#btnSubmitForm_Fake").focus() : "";
        }, 0);

        return false;
    }

    componentDidMount() {
        if (!this.state.dialogForm && this.props.selectedRegistrar) {
            this.setFormData();
        }

        var childrenOpening = this.props.childrenOpening;
        this.props.setChildrenOpening(childrenOpening);
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.dialogForm && this.props.selectedRegistrar)
            || (prevProps.selectedRegistrar && this.props.selectedRegistrar && prevProps.selectedRegistrar.initials !== this.props.selectedRegistrar.initials)
            || (this.props.selectedRegistrar && this.props.selectedRegistrar.initials !== null && this.props.selectedRegistrar.initials !== "" && this.props.selectedRegistrar.initials !== this.state.dialogForm.initials && (this.state.dialogForm.initials === null || this.state.dialogForm.initials === ""))
            || (this.props.selectedRegistrar && this.props.selectedRegistrar.id == "0" && this.props.selectedRegistrar.id !== this.state.dialogForm.id)
            || (!prevProps.selectedRegistrar && this.props.selectedRegistrar && this.props.selectedRegistrar.initials !== null && this.props.selectedRegistrar.initials !== "" && this.props.selectedRegistrar.initials !== this.state.dialogForm.initials)
        ) {
            if (!this.state.skipLoadFromProps) {
                this.setFormData();
            }
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null && !this.state.loadedTableStructure) {
            this.setState({ loadedTableStructure: true });
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        if (this.state.savedForm && !this.props.isDialogLoading && ((prevProps.isFormDirty != this.props.isFormDirty || this.props.error) || (prevProps.error != this.props.error || !this.state.isFormDirty))) {
            if (this.props.isFormDirty) {
                if (!this.state.hasError && this.state.hasError != this.props.error) {
                    this.setState({ showMessageError: true, hasError: true, savedForm: false, isFormDirty: true });
                    this.props.setChildrenOpening(true);
                }
            } else {
                if (this.state.isFormDirty && this.state.isFormDirty != this.props.isFormDirty) {
                    this.setState({ isFormDirty: this.props.isFormDirty, savedForm: false, hasError: false });
                }
            }
        }

        if (this.state.isFormDirty && (this.state.isSaving || this.props.deletedDataSuccess)
            && (prevProps.isFormDirty !== this.props.isFormDirty || this.state.isFormDirty !== this.props.isFormDirty)) {
            this.setState({
                isFormDirty: this.props.isFormDirty,
                isSaving: false,//this.props.isFormDirty,
            });
        }
    }

    async getTableStructure() {
        //let url = this.props.clientgroup + "/GetTableStructure?table=Registrars";
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "Registrars", accessToken: localStorage.getItem('token') };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    setFormData = () => {
        let dataForm = this.props.selectedRegistrar;

        Object.keys(dataForm).map((t) => {
            let dataVal = dataForm[t];
            if ((t === "phoneNbr") && dataVal !== null && dataVal !== "") {
                // Format_Phone
                var region = '';//FN_Region
                var oldVal = dataVal;
                var newVal = oldVal;

                if (region == 'USA' || region == '') {
                    newVal = oldVal.replace(/[^0-9]/gi, '');
                    newVal = newVal.substring(0, 10);

                    if (newVal.length >= 1 && newVal.length <= 3) {
                        newVal = "(" + newVal.substring(0, 3) + ") "
                    } else if (newVal.length >= 4 && newVal.length <= 7) {
                        newVal = "() " + newVal.substring(0, 3) + "-" + newVal.substring(3, 7);
                    } else if (newVal.length >= 8 && newVal.length <= 10) {
                        newVal = "(" + newVal.substring(0, 3) + ") " + newVal.substring(3, 6) + "-" + newVal.substring(6, 10);
                    }
                }

                dataVal = newVal;
            }

            dataForm[t] = dataVal;
        });

        this.setState({
            initialDialogForm: { ...dataForm },
            dialogForm: { ...dataForm },
            //isFormDirty: false,
            reload: false,
            hasError: this.props.error,
            savedForm: false,
            hasError: false,
        });

        if (!this.state.pAllItemFields && this.props.selectedRegistrar) {
            let fields = {};
            Object.keys(this.props.selectedRegistrar).map((t) => {
                fields[t] = "";
            });

            this.setState({ pAllItemFields: fields, pageMaxlengthInput: fields });
        };

        //
        setTimeout(function () {
            document.getElementById("initials") ? document.getElementById("initials").focus() : "";
        }, 500);
    }

    saveRegistrar = (event) => {
        event.preventDefault();

        let dialogFormData = this.state.dialogForm;
        this.props.saveRegistrar(dialogFormData);

        this.setState({ isSaving: true, savedForm: true, isFormDirty: false });
        return false;
    }

    resetRegistrar = () => {
        this.setState({
            dialogForm: { ...this.state.initialDialogForm },
            isFormDirty: false,
            reload: false
        });
    }

    handleOnBlur = (event) => {
        // load if whether this value is existing
        if (event.target.name === "initials") {
            if (event.target.value === "") {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "IN_VALID" } });
            } else {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "" } });
            }

            /*
            if (this.state.dialogForm && this.state.dialogForm.initials) {
                this.props.addNewItem(event.target.value, this.props.isNotExist ? true : false);
            }
            */

            return false;
        } else {
            if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
                let name = event.target.name;
                let value = event.target.value;
                let table = event.target.getAttribute('data-table');
                let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
                let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
                this.props.checkValidInput(name, value, table, key, -1, flag);
            } else {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "" } });

                let name = event.target.name;
                let value = event.target.value;
                if ((name === "phoneNbr") && value !== "") {
                    //var region = { dialog.Object }.getValue('FN_Region');
                    var oldVal = value;
                    var newVal = "";

                    //if (region == 'USA') {
                    newVal = oldVal.replace(/[^0-9]/gi, '');
                    newVal = newVal.substring(0, 10);

                    if (newVal.length >= 1 && newVal.length <= 3) {
                        newVal = "(" + newVal.substring(0, 3) + ") "
                    } else if (newVal.length >= 4 && newVal.length <= 7) {
                        newVal = "() " + newVal.substring(0, 3) + "-" + newVal.substring(3, 7);
                    } else if (newVal.length >= 8 && newVal.length <= 10) {
                        newVal = "(" + newVal.substring(0, 3) + ") " + newVal.substring(3, 6) + "-" + newVal.substring(6, 10);
                    }
                    //value = newVal;
                    //}

                    this.setState({
                        dialogForm: { ...this.state.dialogForm, [name]: newVal }
                    });
                } else {
                    this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [name]: "" } });
                }
            }
        }
    }

    handleFormChange = (event) => {
        this.props.resetDeletedDataSuccess(false);
        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                [event.target.name]: event.target.value
            },
            isFormDirty: true,
            isSaving: false,
            skipLoadFromProps: event.target.name == "initials" && event.target.value == "" ? true : false,
        });
    }

    render() {
        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                            <label className="invalidMsgErrorCls">Can't save data. {this.props.errorMessage ? "(" + this.props.errorMessage + ")" : ""}</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const dialogFrm = this.state.dialogForm ?
            (
                <div className="container-fluid2">
                    <form onSubmit={this.saveRegistrar} id="dialogForm" className="DialogForm2 registrars_maint_form">
                        <div className="_container autoFilterById">
                            <Input type="input" changed={this.handleFormChange}
                                labelText="Initials" labelWidth="60px" width="80px" autoFocus
                                field="initials" value={this.state.dialogForm.initials} lineStyle="oneLine"
                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["initials"] : ""}
                                maxLength="3"//#PR00555 - NOT allows more than 3 digits to save
                                inValid={this.state.pAllItemFields["initials"]}
                            />
                        </div>
                        <div id="inputAreaCls">
                            <div>
                                <div className="frameInner">
                                    <div className="header">&nbsp;</div>
                                    <div className="emptyLine"></div>
                                    <Input type="input" changed={this.handleFormChange}
                                        labelText="First Name" field="firstName"
                                        value={this.state.dialogForm.firstName} lineStyle="oneLine"
                                        labelWidth={Global.LabelWidth} width="200px"
                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["firstName"] : ""}
                                        inValid={this.state.pAllItemFields["firstName"]} />
                                    <Input type="input" changed={this.handleFormChange}
                                        labelText="Last Name" field="lastName"
                                        value={this.state.dialogForm.lastName} lineStyle="oneLine"
                                        labelWidth={Global.LabelWidth} width="200px"
                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["lastName"] : ""}
                                        inValid={this.state.pAllItemFields["lastName"]} />
                                    <Input type="input" changed={this.handleFormChange}
                                        labelText="Dept" field="dept" lineStyle="oneLine"
                                        value={this.state.dialogForm.dept} labelWidth={Global.LabelWidth} width="300px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dept"] : ""}
                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                        inValid={this.state.pAllItemFields["dept"]} />
                                    <Input type="input" changed={this.handleFormChange}
                                        labelText="Phone Nbr" field="phoneNbr" lineStyle="oneLine"
                                        value={this.state.dialogForm.phoneNbr} labelWidth={Global.LabelWidth} width="100px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["phoneNbr"] : ""}
                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                        inValid={this.state.pAllItemFields["phoneNbr"]} />
                                    <Input type="input" changed={this.handleFormChange}
                                        labelText="State Id" field="stateId" lineStyle="oneLine"
                                        value={this.state.dialogForm.stateId} labelWidth={Global.LabelWidth} width="175px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["stateId"] : ""}
                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                        inValid={this.state.pAllItemFields["stateId"]} />
                                    <Input type="input" changed={this.handleFormChange}
                                        labelText="CRStar Userid" field="email" lineStyle="oneLine"
                                        value={this.state.dialogForm.email} labelWidth={Global.LabelWidth} width="325px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["email"] : ""}
                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                        inValid={this.state.pAllItemFields["email"]} />
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="emptyLine"></div>

                    <Footer closeWindow={this.props.closeWindow}
                        refreshForm={this.resetRegistrar} formId="dialogForm"
                        isFormDirty={this.state.isFormDirty}
                        showBtnNew="Registrar"
                        showBtnDelete="Registrar"
                        className="Footer2"
                        isLookup={true}
                        exitFnc={this.props.closeWindow}
                        focusingField={this.state.focusingField}
                        inquiringMode={this.props.inquiringMode}
                        enabledAlways={true}
                        dataForm={this.state.dialogForm}
                    />
                </div>
            )
            : <div className="col-sm-12 makingLayoutOnFormCls">Registrar data loading</div>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>
                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

RegistrarForm.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        selectedRegistrar: state.patientAbstract.selectedRegistrarOnModal,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        deletedDataSuccess: state.patientAbstract.deletedDataSuccess,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveRegistrar: (dialogForm) => dispatch(actionCreators.saveRegistrar(dialogForm)),
        addNewItem: (id, flag) => dispatch(actionCreators.selectRegistrarRecord(id, flag)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        resetDeletedDataSuccess: (value) => dispatch(actionCreators.resetDeletedDataSuccess(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrarForm);