/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavLink } from "reactstrap";
import classnames from "classnames";
import { actionCreators } from "../../../../store/PatientAbstract";
import Input from "../../../UI/Input";
import "./Hospital.css";
import axios from "../../../../axios-instance";
import Footer from "../../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
import "../../../../components/AbstractManagement/PatientAbstract/TreatmentMaintenance/TreatmentMaintenance.css";
import * as Global from "../../../../store/Global";
import Draggable from 'react-draggable';

class HospitalForm extends Component {

    state = {
        activeTab: "1",
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        aliases: null,
        counties: null,
        initialAliases: null,
        initialCounties: null,
        isCheckedTextPopup: false,

        pAllItemFields: null,
        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        countyItemFields: null,
        countyMaxlengthInput: null,
        selectedCountyRowIndex: 0,

        aliasesItemFields: null,
        aliasesMaxlengthInput: null,
        selectedAliasRowIndex: 0,

        hasErrorOccurred: false,
        // State
        hasNotificationStateMsg: false,
        updatedDataStateInfo: null,
        updatingItemState: null,
        updatingStateValue: null,
        updatingStateIndex: 0,
        // County
        hasNotificationCountyMsg: false,
        updatedDataCountyInfo: null,
        updatingItemCounty: null,
        updatingCountyValue: null,
        updatingCountyIndex: 0,
        // City
        hasNotificationCityMsg: false,
        updatedDataCityInfo: null,
        updatingItemCity: null,
        updatingCityValue: null,
        updatingCityIndex: 0,

        fieldInFocus: "hospNbrDsp",
        fieldInFocusByIndex: null,
        isHelpVisible: false,
        focusingField: null,
        isSaving: false,
        resetAllFlag: false,
    }

    hideNotificationStateMsg = (event) => {
        try {
            event ? event.preventDefault() : "";

            // focus into previous field
            if (this.state.fieldInFocus !== "") {
                let focusingField = this.state.fieldInFocus;
                setTimeout(function () {
                    document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
                }, 0);
            }
        } catch (ex) { }
        this.setState({ hasNotificationStateMsg: false });

        if (!this.state.hasNotificationCityMsg && !this.state.hasNotificationCountyMsg && !this.state.hasNotificationStateMsg)
            this.props.setChildrenOpening(false);

        return false;
    }
    updateDataStateInfo = () => {
        const newState = this.state.updatedDataStateInfo;
        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                state: newState
            },
            hasNotificationStateMsg: false,
            updatedDataStateInfo: null,
            isFormDirty: true,
            // clear IN_VALID flag if it's existing
            pAllItemFields: {
                ...this.state.pAllItemFields,
                state: "",
            },
        });

        // focus into previous field
        if (this.state.fieldInFocus !== "") {
            let focusingField = this.state.fieldInFocus;
            setTimeout(function () {
                document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
            }, 0);
        }

        if (!this.state.hasNotificationCityMsg && !this.state.hasNotificationCountyMsg && !this.state.hasNotificationStateMsg)
            this.props.setChildrenOpening(false);
    }

    hideNotificationCountyMsg = (event) => {
        try {
            event ? event.preventDefault() : "";

            // focus into previous field
            if (this.state.fieldInFocus !== "") {
                let focusingField = this.state.fieldInFocus;
                setTimeout(function () {
                    document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
                }, 0);
            }
        } catch (ex) { }
        this.setState({ hasNotificationCountyMsg: false });

        if (!this.state.hasNotificationCityMsg && !this.state.hasNotificationCountyMsg && !this.state.hasNotificationStateMsg)
            this.props.setChildrenOpening(false);

        return false;
    }
    updateDataCountyInfo = () => {
        const newCounty = this.state.updatedDataCountyInfo;
        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                country: newCounty
            },
            hasNotificationCountyMsg: false,
            updatedDataCountyInfo: null,
            isFormDirty: true,
            // clear IN_VALID flag if it's existing
            pAllItemFields: {
                ...this.state.pAllItemFields,
                country: "",
            },
        });

        // focus into previous field
        if (this.state.fieldInFocus !== "") {
            let focusingField = this.state.fieldInFocus;
            setTimeout(function () {
                document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
            }, 0);
        }

        if (!this.state.hasNotificationCityMsg && !this.state.hasNotificationCountyMsg && !this.state.hasNotificationStateMsg)
            this.props.setChildrenOpening(false);
    }

    hideNotificationCityMsg = (event) => {
        try {
            event ? event.preventDefault() : "";

            // focus into previous field
            if (this.state.fieldInFocus !== "") {
                let focusingField = this.state.fieldInFocus;
                setTimeout(function () {
                    document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
                }, 0);
            }
        } catch (ex) { }
        this.setState({ hasNotificationCityMsg: false });

        if (!this.state.hasNotificationCityMsg && !this.state.hasNotificationCountyMsg && !this.state.hasNotificationStateMsg)
            this.props.setChildrenOpening(false);

        return false;
    }
    updateDataCityInfo = () => {
        const newCity = this.state.updatedDataCityInfo;
        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                city: newCity
            },
            hasNotificationCityMsg: false,
            updatedDataCityInfo: null,
            isFormDirty: true,
            // clear IN_VALID flag if it's existing
            pAllItemFields: {
                ...this.state.pAllItemFields,
                city: "",
            },
        });

        // focus into previous field
        if (this.state.fieldInFocus !== "") {
            let focusingField = this.state.fieldInFocus;
            setTimeout(function () {
                document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
            }, 0);
        }

        if (!this.state.hasNotificationCityMsg && !this.state.hasNotificationCountyMsg && !this.state.hasNotificationStateMsg)
            this.props.setChildrenOpening(false);
    }

    setSelectionCountyRow = (event, index) => {
        this.setState({ selectedCountyRowIndex: index });
    }

    setSelectionAliasRow = (event, index) => {
        this.setState({ selectedAliasRowIndex: index });
    }

    resetAllFields() {
        var currentForm = document.querySelector(".hospital_maint_form");
        if (currentForm) {
            if (currentForm && currentForm.querySelectorAll("input")) {
                currentForm.querySelectorAll("input").forEach((ip, index) => {
                    ip.removeAttribute("disabled");
                    ip.style.backgroundColor = "";
                });
            }
            if (currentForm && currentForm.querySelectorAll("textarea")) {
                currentForm.querySelectorAll("textarea").forEach((ta, index) => {
                    ta.removeAttribute("disabled");
                    ta.style.backgroundColor = "";
                });
            }

            this.setState({ resetAllFields: true });
        }
    }

    componentDidMount() {
        if (!this.state.dialogForm && this.props.selectedHospital) {
            this.setFormData();
        }

        var childrenOpening = this.props.childrenOpening;
        this.props.setChildrenOpening(childrenOpening);

        //Fixing #PR00574 - Rapid Abstract
        this.resetAllFields();
    }

    componentDidUpdate(prevProps) {
        var updatedForm = false;
        if ((!this.state.dialogForm && this.props.selectedHospital)
            || (prevProps.selectedHospital && this.props.selectedHospital && prevProps.selectedHospital.hospNbr !== this.props.selectedHospital.hospNbr)
            || (this.props.selectedHospital && this.props.selectedHospital.hospNbr !== null && this.props.selectedHospital.hospNbr !== "" && this.props.selectedHospital.hospNbr !== this.state.dialogForm.hospNbr && (this.state.dialogForm.hospNbr === null || this.state.dialogForm.hospNbr === ""))
            || (this.props.selectedHospital && (this.props.selectedHospital.hospNbr === null || this.props.selectedHospital.hospNbr === "") && this.props.selectedHospital.hospNbr !== this.state.dialogForm.hospNbr && this.state.dialogForm.hospNbr !== null && this.state.dialogForm.hospNbr !== "")
            || (!prevProps.selectedHospital && this.props.selectedHospital && this.props.selectedHospital.hospNbr !== null && this.props.selectedHospital.hospNbr !== "" && this.props.selectedHospital.hospNbr !== this.state.dialogForm.hospNbr)
        ) {
            updatedForm = true;
            this.setFormData();
        }

        if ((this.props.selectedHospital && !this.state.aliases) || updatedForm) {
            //axios.get(this.props.clientgroup + "/GetHospitalAliasesMvList?hospitalId=" + this.props.selectedHospital.hospitalId)
            axios.post(this.props.clientgroup + "/GetHospitalAliasesMvList",
                { hospitalId: this.props.selectedHospital.hospitalId, accessToken: localStorage.getItem('token') })
                .then(res => {
		            var newAccessToken = res && res.data ? res.data.accessToken : null;
		            if (newAccessToken) {
		                localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ aliases: res.data.hospitalAliasesMvList, initialAliases: res.data.hospitalAliasesMvList });
                        this.setState({ dialogForm: { ...this.state.dialogForm, hospitalAliasesMv: res.data.hospitalAliasesMvList } });

                        // Aliases List
                        if (!this.state.aliasesItemFields && res.data.hospitalAliasesMvList) {
                            let aliasesArr = [];
                            res.data.hospitalAliasesMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                aliasesArr.push(fields);
                            });

                            this.setState({ aliasesItemFields: aliasesArr, resetAllFields: false });

                            // get maxlength for Aliases grid
                            //axios.get(this.props.clientgroup + "/GetTableStructure?table=Hospital_Aliases_Mv")

                            let url = this.props.clientgroup + "/GetTableStructure";
                            let params = { table: "Hospital_Aliases_Mv", accessToken: localStorage.getItem('token') };

                            axios.post(url, params)
                                .then(res => {
                                    if (res.data !== null && res.data.result !== "") {
                                        let maxLengthValue = res.data.result;
                                        let maxLengthItem = aliasesArr && aliasesArr[0] ? aliasesArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                        this.setState({
                                            aliasesMaxlengthInput: { ...maxLengthItem }
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);

                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }
                                    return false;

                                });
                        };
                    }
                })
	            .catch(function (error) {
	                console.log(error);

	                var btnLogout = document.getElementById("logoutButtonArea");
	                if (btnLogout) {
	                    btnLogout.querySelector("a").click();
	                }
	                return false;
                });
        }

        if ((this.props.selectedHospital && !this.state.counties) || updatedForm) {
            //axios.get(this.props.clientgroup + "/GetHospitalCountiesMvList?hospitalId=" + this.props.selectedHospital.hospitalId)
            axios.post(this.props.clientgroup + "/GetHospitalCountiesMvList",
                { hospitalId: this.props.selectedHospital.hospitalId, accessToken: localStorage.getItem('token') })
                .then(res => {
		            var newAccessToken = res && res.data ? res.data.accessToken : null;
		            if (newAccessToken) {
		                localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ counties: res.data.hospitalCountiesMvList, initialCounties: res.data.hospitalCountiesMvList });
                        this.setState({ dialogForm: { ...this.state.dialogForm, hospitalCountiesMv: res.data.hospitalCountiesMvList } });

                        // Counties List
                        if (!this.state.countyItemFields && res.data.hospitalCountiesMvList) {
                            let countiesArr = [];
                            res.data.hospitalCountiesMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                countiesArr.push(fields);
                            });

                            this.setState({ countyItemFields: countiesArr, resetAllFields: false });

                            // get maxlength for Counties grid
                            axios.post(this.props.clientgroup + "/GetTableStructure", { table: "Hospital_Counties_Mv", accessToken: localStorage.getItem('token') })
			                .then(res => {
                                if (res.data !== null && res.data.result !== "") {
                                    let maxLengthValue = res.data.result;
                                    let maxLengthItem = countiesArr && countiesArr[0] ? countiesArr[0] : [];

                                    maxLengthValue = maxLengthValue.split(',');
                                    for (var i = 0; i < maxLengthValue.length; i++) {
                                        let obj = maxLengthValue[i].split(':');
                                        maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                    }

                                    this.setState({
                                        countyMaxlengthInput: { ...maxLengthItem }
                                    });
                                }
                            })
		                    .catch(function (error) {
		                        console.log(error);

		                        var btnLogout = document.getElementById("logoutButtonArea");
		                        if (btnLogout) {
		                            btnLogout.querySelector("a").click();
		                        }
		                        return false;
                            });
                    };
                }
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
        }

        //
        if (this.state.tableStructureList === null && !this.state.loadedTableStructure) {
            this.setState({ loadedTableStructure: true });
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.dialogForm && this.state.pAllItemFields
        ) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "" }
                });
            } else {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "IN_VALID" }
                });
            }
            this.props.clearCommonLookupItem();
        }

        if (this.state.isFormDirty && (this.state.isSaving || this.props.deletedDataSuccess)
            && (prevProps.isFormDirty !== this.props.isFormDirty || this.state.isFormDirty !== this.props.isFormDirty)) {
            this.setState({
                isFormDirty: this.props.isFormDirty,
                isSaving: false,//this.props.isFormDirty,
            });
        }

        if (this.props.childrenOpening && !this.state.resetAllFlag
            && !this.state.hasNotificationCityMsg && !this.state.hasNotificationCountyMsg && !this.state.hasNotificationStateMsg) {
            this.props.setChildrenOpening(false);
            this.setState({ resetAllFlag: true });
        }

        if (!this.state.resetAllFields) {
            //Fixing #PR00574 - Rapid Abstract
            this.resetAllFields();
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "Hospital", accessToken: localStorage.getItem('token') };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    setFormData = () => {
        let dataForm = this.props.selectedHospital;

        Object.keys(dataForm).map((t) => {
            let dataVal = dataForm[t];
            if (t === "zip" && dataVal !== null && dataVal !== "") {
                var oldVal = dataVal;
                var newVal = oldVal.replace(/[^A-Za-z0-9]/gi, '').toUpperCase();
                if (oldVal != '') {
                    //Canadian
                    if (isNaN(newVal) == true) {
                        if (newVal.length >= 4) {
                            newVal = newVal.substring(0, 3) + " " + newVal.substring(3, 6);
                        }

                        //US
                    } else {
                        newVal = oldVal.replace(/[^0-9]/gi, '');
                        if (newVal.length >= 6) {
                            newVal = newVal.substring(0, 5) + "-" + newVal.substring(5, 9);
                        }
                    }

                    dataVal = newVal;
                }
            } else if ((t === "phoneNbr") && dataVal !== null && dataVal !== "") {
                // Format_Phone
                var region = '';//FN_Region
                var oldVal = dataVal;
                var newVal = oldVal;

                if (region == 'USA' || region == '') {
                    newVal = oldVal.replace(/[^0-9]/gi, '');
                    newVal = newVal.substring(0, 10);

                    if (newVal.length >= 1 && newVal.length <= 3) {
                        newVal = "(" + newVal.substring(0, 3) + ") "
                    } else if (newVal.length >= 4 && newVal.length <= 7) {
                        newVal = "() " + newVal.substring(0, 3) + "-" + newVal.substring(3, 7);
                    } else if (newVal.length >= 8 && newVal.length <= 10) {
                        newVal = "(" + newVal.substring(0, 3) + ") " + newVal.substring(3, 6) + "-" + newVal.substring(6, 10);
                    }
                }

                dataVal = newVal;
            }

            dataForm[t] = dataVal;
        });

        this.setState({
            initialDialogForm: { ...dataForm },
            dialogForm: { ...dataForm },
            //isFormDirty: false,
            reload: false,
        });

        if (!this.state.pAllItemFields && this.props.selectedHospital) {
            let fields = {};
            Object.keys(this.props.selectedHospital).map((t) => {
                fields[t] = "";
            });

            this.setState({ pAllItemFields: fields, pageMaxlengthInput: fields });
        };

        if (this.props.selectedHospital["textPopup"] === "1") {
            this.setState({ isCheckedTextPopup: true });
        } else {
            this.setState({ isCheckedTextPopup: false });
        }
    }

    toggleChecked = () => {
        const checked = this.state.isCheckedTextPopup;
        this.setState({
            isCheckedTextPopup: !checked,
            dialogForm: { ...this.state.dialogForm, ["textPopup"]: (!checked ? "1" : "0") },
            isFormDirty: true,
        });
    }

    toggleTab = (e, tab) => {
        if (tab === "1") {
            this.setState({ activeTab: tab, selectedAliasRowIndex: 0 });
            setTimeout(function () {
                document.getElementById("rxHospAliases0") ? document.getElementById("rxHospAliases0").focus() : "";
            }, 0);
        } else if (tab === "2") {
            this.setState({ activeTab: tab, selectedCountyRowIndex: 0 });
            setTimeout(function () {
                document.getElementById("counties0") ? document.getElementById("counties0").focus() : "";
            }, 0);
        }
    }

    saveHospital = (event) => {
        event.preventDefault();

        // TODO Form validation
        if (true) {
            let dialogFormData = this.state.dialogForm;
            dialogFormData["hospNbrId"] = dialogFormData["hospNbrDsp"];

            let aliasList = this.state.aliases;
            let countyList = this.state.counties;

            dialogFormData.hospitalAliasesMv = aliasList;
            dialogFormData.hospitalCountiesMv = countyList;

            this.props.saveHospital(dialogFormData);

            //this.setState({ /*isFormDirty: false,*/ reload: false });
            this.setState({
                isSaving: true,
                reload: false
            });
        }
        return false;
    }

    resetHospital = () => {
        this.setState({
            dialogForm: { ...this.state.initialDialogForm },
            isFormDirty: false,
            reload: false
        });
    }

    handleOnBlur = (event) => {
        // load if whether this value is existing
        if (event.target.name === "hospNbrDsp") {
            if (event.target.value === "") {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "IN_VALID" } });
            } else {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "" } });
            }
            this.props.addNewItem(event.target.value, this.props.isNotExist ? true : false);
            return false;
        }
    }

    handleFormChange = (event) => {
        this.props.resetDeletedDataSuccess(false);
        this.setState({ dialogForm: { ...this.state.dialogForm, [event.target.name]: event.target.value }, isFormDirty: true });
        // load if whether this value is existing
        if (event.target.name === "__hospNbrDsp__") {
            this.props.addNewItem(event.target.value);
            return false;
        }
    }

    handleOnFocus = (event, index) => {
        this.setState({
            fieldInFocus: event.target.name,
            fieldInFocusByIndex: event.target.id,
            focusingField: event.target
        });
    }

    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            this.props.checkValidInput(name, value, table, key, -1, flag);
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let value = event.target.value;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState({ patientForm: { ...this.state.patientForm, [event.target.name]: value } });
                } else {
                    this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "IN_VALID" } });
                }
            } else if (mdy == 'year' && value.length == 2) {
                let currentYear = (new Date().getFullYear() + "");
                let yyVal = currentYear.substr(2, 2);
                if (yyVal < value) {
                    value = (currentYear.substr(0, 2) - 1) + value;
                } else {
                    value = currentYear.substr(0, 2) + value;
                }

                this.setState({ dialogForm: { ...this.state.dialogForm, [event.target.name]: value } });
            }
        } else {
            this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "" } });

            var name = event.target.name;
            var value = event.target.value;
            if (name === "zip" && value !== "") {
                var oldVal = value;
                var newVal = oldVal.replace(/[^A-Za-z0-9]/gi, '').toUpperCase();
                var regionUSA = false;
                if (oldVal != '') {
                    //Canadian
                    if (isNaN(newVal) == true) {
                        if (newVal.length >= 4) {
                            newVal = newVal.substring(0, 3) + " " + newVal.substring(3, 6);
                        }

                        //US
                    } else {
                        newVal = oldVal.replace(/[^0-9]/gi, '');
                        if (newVal.length >= 6) {
                            newVal = newVal.substring(0, 5) + "-" + newVal.substring(5, 9);
                        }
                        regionUSA = true;
                    }
                }

                this.setState({ dialogForm: { ...this.state.dialogForm, [name]: newVal } });

                // @TODO: zip code entry should tell me the city/state to let me select it
                var state = this.state.dialogForm.state !== null ? this.state.dialogForm.state.toUpperCase() : "";
                var city = this.state.dialogForm.city !== null ? this.state.dialogForm.city.toUpperCase() : "";
                var county = this.state.dialogForm.country;

                // ERSValidateCityStateZip/ers_citystate_from_zip
                //if (regionUSA) {
                var zip = newVal;
                // 
                //var url = "https://ziptasticapi.com/" + value;
                var url = "https://production.shippingapis.com/ShippingAPITest.dll?API=CityStateLookup&XML=<CityStateLookupRequest USERID='968ELECT7482'><ZipCode ID= '1'><Zip5>" + zip + "</Zip5></ZipCode></CityStateLookupRequest>";
                axios.get(url)
                    .then(response => {
                        const raw = response.data;
                        const parser = new DOMParser();
                        const xml = parser.parseFromString(raw, 'text/xml');//

                        var _city = xml.querySelector('City') ? xml.querySelector('City').textContent : "";
                        var _state = xml.querySelector('State') ? xml.querySelector('State').textContent : "";
                        if (zip === "88888") {
                            _city = "";
                            _state = "";
                        } else {
                            _city = _city.toUpperCase();
                            _state = _state.toUpperCase();
                        }

                        // City
                        if (_city !== "" && _city !== city && city !== null && city !== "") {
                            this.setState({
                                hasNotificationCityMsg: true,
                                updatedDataCityInfo: _city,
                                updatingItemCity: "city",
                                resetAllFlag: false
                            });
                            this.props.setChildrenOpening(true);
                        } else if (_city !== "" && _city !== city && (city === null || city === "")) {
                            this.setState({
                                dialogForm: { ...this.state.dialogForm, ["city"]: _city }
                            });
                        }

                        // State
                        if (_state !== "" && _state !== state && state !== null && state !== "") {
                            this.setState({
                                hasNotificationStateMsg: true,
                                updatedDataStateInfo: _state,
                                updatingItemState: "state",
                                resetAllFlag: false
                            });
                            this.props.setChildrenOpening(true);
                        } else if (_state !== "" && _state !== state && (state === null || state === "")) {
                            this.setState({
                                dialogForm: { ...this.state.dialogForm, ["state"]: _state }
                            });
                        }

                        //axios.get(this.props.clientgroup + "/InquireRelatedInfo?zip=" + value)
                        axios.post(this.props.clientgroup + "/InquireRelatedInfo",
                            { zip: value, accessToken: localStorage.getItem('token') })
                            .then(res => {
			                    var newAccessToken = res && res.data ? res.data.accessToken : null;
			                    if (newAccessToken) {
			                        localStorage.setItem('token', newAccessToken);
			                    }

                                if (!res.data.error && res.data.message !== null && res.data.message !== "") {
                                    const info = res.data.message;
                                    let arr = info.split('|');
                                    // County
                                    if (arr[1] !== "" && arr[1] !== county && county !== null && county !== "") {
                                        this.setState({
                                            hasNotificationCountyMsg: true,
                                            updatedDataCountyInfo: arr[1],
                                            updatingItemCounty: "country",
                                            resetAllFlag: false
                                        });
                                        this.props.setChildrenOpening(true);
                                    } else if (arr[1] !== "" && arr[1] !== county && (county === null || county === "")) {
                                        this.setState({
                                            dialogForm: { ...this.state.dialogForm, ["country"]: arr[1] }
                                        });
                                    }

                                    return false;
                                }
                            })
		                    .catch(function (error) {
		                        console.log(error);

		                        var btnLogout = document.getElementById("logoutButtonArea");
		                        if (btnLogout) {
		                            btnLogout.querySelector("a").click();
		                        }
		                        return false;
		                    });
                    });

                //return false;
                //}
            } else if (name === "emailAddress" && value !== "") {
                // 
                let regPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!regPattern.test(value)) {
                    this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [name]: "IN_VALID" } });
                } else {
                    this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [name]: "" } });
                }
            } else if ((name === "phoneNbr") && value !== "") {
                //var region = { dialog.Object }.getValue('FN_Region');
                var oldVal = value;
                var newVal = "";

                //if (region == 'USA') {
                newVal = oldVal.replace(/[^0-9]/gi, '');
                newVal = newVal.substring(0, 10);

                if (newVal.length >= 1 && newVal.length <= 3) {
                    newVal = "(" + newVal.substring(0, 3) + ") "
                } else if (newVal.length >= 4 && newVal.length <= 7) {
                    newVal = "() " + newVal.substring(0, 3) + "-" + newVal.substring(3, 7);
                } else if (newVal.length >= 8 && newVal.length <= 10) {
                    newVal = "(" + newVal.substring(0, 3) + ") " + newVal.substring(3, 6) + "-" + newVal.substring(6, 10);
                }
                //value = newVal;
                //}

                this.setState({
                    dialogForm: { ...this.state.dialogForm, [name]: newVal }
                });
            } else {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [name]: "" } });
            }
        }
    }

    /* Aliases */
    handleAliasTabChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.aliases];
            newItems[index][name] = value;
            return { aliases: newItems, isFormDirty: true };
        });
    }
    addNewAlias = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.aliases];
            const firstItem = newItems[0];

            const newItem = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "pid") {
                    newItem[t] = 0;
                } else {
                    newItem[t] = "";
                }
            });
            newItems.push(newItem);

            //
            const newFields = [...this.state.aliasesItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                newField[t] = "";
            });
            newFields.push(newField);
            return { aliases: newItems, aliasesItemFields: newFields, isFormDirty: true };
        });
    }
    deleteSelectedAlias = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedAliasRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.aliases.length) {
            selectedRow = this.state.aliases.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.aliases];
            if (newItems.length === 1) {
                const _newItems = [...this.state.aliases];
                const firstItem = _newItems[0];

                const newItem = {};
                Object.keys(firstItem).map((t, k) => {
                    if (t === "id" || t === "pid") {
                        newItem[t] = 0;
                    } else {
                        newItem[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newItem);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.aliasesItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.aliasesItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { aliases: newItems, aliasesItemFields: newFields, isFormDirty: true, selectedAliasesRowIndex: selectedRow };
        });
    }

    /* Counties */
    handleCountyTabChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.counties];
            newItems[index][name] = value;
            return { counties: newItems, isFormDirty: true };
        });
    }
    addNewCounty = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.counties];
            const firstItem = newItems[0];

            const newItem = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "pid") {
                    newItem[t] = 0;
                } else {
                    newItem[t] = "";
                }
            });
            newItems.push(newItem);

            //
            const newFields = [...this.state.countyItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                newField[t] = "";
            });
            newFields.push(newField);
            return { counties: newItems, countyItemFields: newFields, isFormDirty: true };
        });
    }
    deleteSelectedCounty = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedCountyRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.counties.length) {
            selectedRow = this.state.counties.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.counties];
            if (newItems.length === 1) {
                const _newItems = [...this.state.counties];
                const firstItem = _newItems[0];

                const newItem = {};
                Object.keys(firstItem).map((t, k) => {
                    if (t === "id" || t === "hospitalId") {
                        newItem[t] = 0;
                    } else {
                        newItem[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newItem);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.countyItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.countyItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { counties: newItems, countyItemFields: newFields, isFormDirty: true, selectedCountyRowIndex: selectedRow };
        });
    }

    render() {

        let hasNotificationStateMsg;
        {
            hasNotificationStateMsg = this.state.hasNotificationStateMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Notification</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideNotificationStateMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>
                                    Suggested state for this zip code is '{this.state.updatedDataStateInfo}'.<br />
                                    Would you like to update the state to {this.state.updatedDataStateInfo}?
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={this.updateDataStateInfo} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideNotificationStateMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }
        let hasNotificationCountyMsg;
        {
            hasNotificationCountyMsg = this.state.hasNotificationCountyMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Notification</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideNotificationCountyMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>
                                    Suggested county for this zip code is '{this.state.updatedDataCountyInfo}'.<br />
                                    Would you like to update the county to {this.state.updatedDataCountyInfo}?
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={this.updateDataCountyInfo} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideNotificationCountyMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }
        let hasNotificationCityMsg;
        {
            hasNotificationCityMsg = this.state.hasNotificationCityMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Notification</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideNotificationCityMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>
                                    Suggested city for this zip code is '{this.state.updatedDataCityInfo}'.<br />
                                    Would you like to update the city to {this.state.updatedDataCityInfo}?
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={this.updateDataCityInfo} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideNotificationCityMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const dialogFrm = this.state.dialogForm ?
            (
                <div className="container-fluid2">
                    <form onSubmit={this.saveHospital} id="dialogForm" className="DialogForm2 hospital_maint_form">
                        <div className="_container autoFilterById">
                            <Input type="input" changed={this.handleFormChange}
                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                labelText="Hospital Number" field="hospNbrDsp"
                                labelWidth={Global.LabelWidth} width="100px"
                                value={this.state.dialogForm.hospNbrDsp} autoFocus
                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospNbr"] : ""}
                                inValid={this.state.pAllItemFields["hospNbrDsp"]} />
                        </div>
                        <div className="emptyLine"></div>
                        <div id="inputAreaCls">
                            <div className="" style={{ width: "335px", height: "440px" }}>
                                <div className="frameInner">
                                    <div className="header">&nbsp;</div>
                                    <Input type="input" changed={this.handleFormChange} lineStyle="oneLine onTopFrame"
                                        labelText="Hospital Name" field="hospName"
                                        value={this.state.dialogForm.hospName}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        labelWidth={Global.LabelWidth2} width="200px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospName"] : ""}
                                        inValid={this.state.pAllItemFields["hospName"]} />
                                    <Input type="input" changed={this.handleFormChange} lineStyle="oneLine"
                                        labelText="Contact" field="contact"
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        labelWidth={Global.LabelWidth2} width="200px"
                                        value={this.state.dialogForm.contact}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["contact"] : ""}
                                        inValid={this.state.pAllItemFields["contact"]} />
                                    <Input type="input" changed={this.handleFormChange} lineStyle="oneLine"
                                        labelText="Phone" field="phoneNbr"
                                        labelWidth={Global.LabelWidth2} width="100px"
                                        value={this.state.dialogForm.phoneNbr}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["phoneNbr"] : ""}
                                        inValid={this.state.pAllItemFields["phoneNbr"]} />
                                    <Input type="input" changed={this.handleFormChange} lineStyle="oneLine"
                                        labelText="St Name Add" field="stNameAdd"
                                        value={this.state.dialogForm.stNameAdd}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        labelWidth={Global.LabelWidth2} width="150px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["stNameAdd"] : ""}
                                        inValid={this.state.pAllItemFields["stNameAdd"]} />
                                    <Input type="input" changed={this.handleFormChange} lineStyle="oneLine"
                                        labelText="Reporting Hosp ID" field="rptHospId"
                                        labelWidth={Global.LabelWidth2} width="100px"
                                        value={this.state.dialogForm.rptHospId}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rptHospId"] : ""}
                                        inValid={this.state.pAllItemFields["rptHospId"]} />
                                    <Input type="input" changed={this.handleFormChange} lineStyle="oneLine"
                                        labelText="ACoS Hosp ID" field="acosHospId"
                                        value={this.state.dialogForm.acosHospId}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        labelWidth={Global.LabelWidth2} width="100px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["acosHospId"] : ""}
                                        inValid={this.state.pAllItemFields["acosHospId"]} />
                                    <Input type="input" changed={this.handleFormChange}
                                        labelWidth={Global.LabelWidth2} width="100px" lineStyle="oneLine"
                                        labelText="NPI" field="npi" value={this.state.dialogForm.npi}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["npi"] : ""}
                                        inValid={this.state.pAllItemFields["npi"]} />
                                    <Input type="input" changed={this.handleFormChange}
                                        labelWidth={Global.LabelWidth2} width="50px"
                                        lineStyle="oneLine" labelText="Region" field="region"
                                        value={this.state.dialogForm.region}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["region"] : ""}
                                        inValid={this.state.pAllItemFields["region"]} />
                                </div>
                            </div>
                            <div className="" style={{ width: "315px", height: "440px" }}>
                                <div className="frameInner">
                                    <div className="header">&nbsp;</div>
                                    <Input type="input" changed={this.handleFormChange} lineStyle="oneLine onTopFrame"
                                        labelText="Address" field="address"
                                        labelWidth={Global.LabelWidth} width="200px"
                                        value={this.state.dialogForm.address}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["address"] : ""}
                                        inValid={this.state.pAllItemFields["address"]} />
                                    <Input type="input" changed={this.handleFormChange}
                                        labelWidth={Global.LabelWidth} width="125px"
                                        lineStyle="oneLine" labelText="City" field="city"
                                        value={this.state.dialogForm.city}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["city"] : ""}
                                        inValid={this.state.pAllItemFields["city"]} />
                                    <Input type="codeSelect" changed={this.handleFormChange} lineStyle="oneLine"
                                        labelText="State" field="state"
                                        labelWidth={Global.LabelWidth} width="30px"
                                        value={this.state.dialogForm.state}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        dataSource="State_Codes" titleDialog="State"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["state"] : ""}
                                        inValid={this.state.pAllItemFields["state"]} />
                                    <Input type="input" changed={this.handleFormChange} lineStyle="oneLine"
                                        labelText="Zip" field="zip" labelWidth={Global.LabelWidth} width="80px"
                                        value={this.state.dialogForm.zip}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["zip"] : ""}
                                        inValid={this.state.pAllItemFields["zip"]} />
                                    <Input type="codeSelect" changed={this.handleFormChange} lineStyle="oneLine"
                                        labelText="Country" field="country"
                                        labelWidth={Global.LabelWidth} width="80px"
                                        value={this.state.dialogForm.country}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        dataSource="Country_Codes" titleDialog="Country"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["country"] : ""}
                                        inValid={this.state.pAllItemFields["country"]} />
                                    <Input type="input" changed={this.handleFormChange} lineStyle="oneLine"
                                        labelText="Term Digit Sort" field="termDigitSort"
                                        labelWidth={Global.LabelWidth} width="50px"
                                        value={this.state.dialogForm.termDigitSort}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["termDigitSort"] : ""}
                                        inValid={this.state.pAllItemFields["termDigitSort"]} />
                                    <Input type="input" changed={this.handleFormChange} lineStyle="oneLine"
                                        labelText="Fan" field="fan"
                                        labelWidth={Global.LabelWidth} width="100px"
                                        value={this.state.dialogForm.fan}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["fan"] : ""}
                                        inValid={this.state.pAllItemFields["fan"]} />
                                    <Input type="codeSelect" changed={this.handleFormChange} lineStyle="oneLine"
                                        labelText="ACoS Approved" field="acosApproved"
                                        labelWidth={Global.LabelWidth} width="30px"
                                        value={this.state.dialogForm.acosApproved}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        dataSource="Global_Dir_Mv" titleDialog="Acos Approved" aliasNo="2496"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["acosApproved"] : ""}
                                        inValid={this.state.pAllItemFields["acosApproved"]} />
                                    <Input type="codeSelect" changed={this.handleFormChange} lineStyle="oneLine"
                                        labelText="ICD Revision" field="icdoRevComorbid"
                                        labelWidth={Global.LabelWidth} width="30px"
                                        value={this.state.dialogForm.icdoRevComorbid}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        dataSource="ICD_Revision" aliasNo="" titleDialog="ICD Revision"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["icdoRevComorbid"] : ""}
                                        inValid={this.state.pAllItemFields["icdoRevComorbid"]} />
                                    <Input type="input" changed={this.handleFormChange} lineStyle="oneLine"
                                        labelText="NAACCR Version" field="version"
                                        labelWidth={Global.LabelWidth} width="40px"
                                        value={this.state.dialogForm.version}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["version"] : ""}
                                        inValid={this.state.pAllItemFields["version"]} />
                                    <Input type="checkbox" lineStyle="oneLine"
                                        labelText="Text Popups" field="textPopup"
                                        labelWidth={Global.LabelWidth} labelStyle="lblOnCheckbox" width="auto"
                                        value={this.state.isCheckedTextPopup ? "1" : "0"}
                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                        checked={this.state.isCheckedTextPopup}
                                        clicked={this.toggleChecked}
                                    />
                                </div>
                            </div>
                            <div className="" style={{ width: "296px", height: "440px" }}>
                                <div className="frameInner">
                                    <div className="header">&nbsp;</div>
                                    <div style={{
                                        overflowX: "hidden",
                                        overflowY: "auto",
                                        border: "1px solid #eee",
                                        marginBottom: "15px",
                                    }}>
                                        <Nav tabs>
                                            <NavLink className={classnames({ active: this.state.activeTab === "1" })} onClick={(e) => { this.toggleTab(e, "1") }} href="#" id="linkOfAliasesTab">
                                                Aliases
                                            </NavLink>
                                            <NavLink className={classnames({ active: this.state.activeTab === "2" })} onClick={(e) => { this.toggleTab(e, "2") }} href="#" id="linkOfCountiesTab">
                                                Counties
                                            </NavLink>
                                        </Nav>
                                        <TabContent activeTab={this.state.activeTab}>
                                            <TabPane tabId="1">
                                                <div className="aliasesListArea">
                                                    <div className="row">
                                                        <div className="col-sm-1">
                                                        </div>
                                                        <div className="col-sm-10">
                                                            <label>Treating Hosp Aliases:</label>
                                                        </div>
                                                    </div>
                                                    <div className="bodyArea">
                                                        {
                                                            this.state.aliases !== null && this.state.aliases.length > 0 ? this.state.aliases.map((ali, index) => (
                                                                <React.Fragment key={index}>
                                                                    <div onClick={(e) => { this.setSelectionAliasRow(e, index) }} key={index}
                                                                        className={(this.state.selectedAliasRowIndex === index ? "row selectedRowCls" : "row")}>
                                                                        <div className="col-sm-1">
                                                                            <label className="indexCls">{index + 1}</label>
                                                                        </div>
                                                                        <div className="col-sm-10" style={{ display: "flex" }}>
                                                                            <Input type="input" changed={(e) => { this.handleAliasTabChange(e, index) }}
                                                                                labelText="" labelWidth="0px" width="100%" labelStyle="zeroCls" lineStyle="oneLine twoInCls"
                                                                                field="rxHospAliases" value={ali.rxHospAliases}
                                                                                maxLength={this.state.aliasesMaxlengthInput ? this.state.aliasesMaxlengthInput["rxHospAliases"] : ""}
                                                                                keyId={"rxHospAliases" + index}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                latestItem={index == (this.state.aliases.length - 1) ? true : false}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            )) : '...'
                                                        }
                                                    </div>
                                                </div>
                                                <div className="contactBtnArea right" style={{ marginTop: "10px" }}>
                                                    <button className="ErsGrBlueButton" onClick={this.addNewAlias}>Add</button>
                                                    <button className="ErsGrBlueButton" id="deleteSelectedAliasBtn" onClick={this.deleteSelectedAlias}>Delete</button>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <div className="countiesListArea">
                                                    <div className="row">
                                                        <div className="col-sm-1">
                                                        </div>
                                                        <div className="col-sm-10">
                                                            <label>Counties:</label>
                                                        </div>
                                                    </div>
                                                    <div className="bodyArea">
                                                        {
                                                            this.state.counties !== null && this.state.counties.length > 0 ? this.state.counties.map((ctr, index) => (
                                                                <React.Fragment key={index}>
                                                                    <div onClick={(e) => { this.setSelectionCountyRow(e, index) }} key={index}
                                                                        className={(this.state.selectedCountyRowIndex === index ? "row selectedRowCls" : "row")}>
                                                                        <div className="col-sm-1">
                                                                            <label className="indexCls">{index + 1}</label>
                                                                        </div>
                                                                        <div className="col-sm-10" style={{ display: "flex" }}>
                                                                            <Input type="input" changed={(e) => { this.handleCountyTabChange(e, index) }}
                                                                                labelText="" labelWidth="0px" width="100%" labelStyle="zeroCls" lineStyle="oneLine twoInCls"
                                                                                field="counties" value={ctr.counties}
                                                                                maxLength={this.state.countyMaxlengthInput ? this.state.countyMaxlengthInput["counties"] : ""}
                                                                                keyId={"counties" + index}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                latestItem={index == (this.state.counties.length - 1) ? true : false}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            )) : '...'
                                                        }
                                                    </div>
                                                </div>
                                                <div className="contactBtnArea right" style={{ marginTop: "10px" }}>
                                                    <button className="ErsGrBlueButton" onClick={this.addNewCounty}>Add</button>
                                                    <button className="ErsGrBlueButton" onClick={this.deleteSelectedCounty}>Delete</button>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="emptyLine"></div>
                    <Footer closeWindow={this.props.closeWindow}
                        refreshForm={this.resetHospital} formId="dialogForm"
                        isFormDirty={this.state.isFormDirty}
                        showBtnNew="Hospital"
                        showBtnDelete="Hospital"
                        className="Footer2"
                        isLookup={true}
                        exitFnc={this.props.closeWindow}
                        focusingField={this.state.focusingField}
                        inquiringMode={this.props.inquiringMode}
                        enabledAlways={true}
                        dataForm={this.state.dialogForm}
                    />
                </div>
            )
            : <div className="col-sm-12 makingLayoutOnFormCls">Hospital data loading</div>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {hasNotificationStateMsg}
                </React.Fragment>
                <React.Fragment>
                    {hasNotificationCountyMsg}
                </React.Fragment>
                <React.Fragment>
                    {hasNotificationCityMsg}
                </React.Fragment>
                
                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

HospitalForm.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
    //hospNbr: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
        selectedHospital: state.patientAbstract.selectedHospitalOnModal,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        clientgroup: state.auth.clientgroup,
        inquiringMode: state.auth.inquiringMode,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        childrenOpening: state.patientAbstract.childrenOpening,
        deletedDataSuccess: state.patientAbstract.deletedDataSuccess,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveHospital: (dialogForm) => dispatch(actionCreators.saveHospital(dialogForm)),
        addNewItem: (number, flag) => dispatch(actionCreators.selectHospitalRecord(number, flag)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        resetDeletedDataSuccess: (value) => dispatch(actionCreators.resetDeletedDataSuccess(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HospitalForm);