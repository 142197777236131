/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import Draggable from 'react-draggable';
import moment from "moment";
import "../SelectAPop/SelectAPop.css";
import * as Global from "../../../store/Global";

class PopulationLabel extends Component {

    state = {
        uAllItemFields: null,
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        fieldInFocus: "createPopulationLabel",
        focusingField: null,
        loaded: false,
        isCheckedCreatePopulationLabel: false,
        //isCheckedIncludeDuplicatesSharedPatients: false,
        selectedCaseOption: null,
        isCheckedAnalytic: false,
        isCheckedComplete: false,
        isCheckedInComplete: false,
        isCheckedNonReportable: false,

        // LabelsSiteMvSap
        labelsSiteMvSapSap: null,
        labelsSiteMvSapSapItemFields: null,
        labelsSiteMvSapSapLoading: false,
        selectedLabelsSiteMvSapSapRowIndex: 0,
        labelsSiteMvSapSapGridFocusing: false,
        setEventHandlerForLabelsSiteMvSapSapGrid: false,

        // LabelsMv
        labelsMv: null,
        labelsMvItemFields: null,
        labelsMvLoading: false,
        selectedLabelsMvRowIndex: 0,
        labelsMvGridFocusing: false,
        setEventHandlerForLabelsMvGrid: false,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        isShowConfirmDeleteMsg: false,
        isShowConfirmDeleteLabelMsg: false,
        confirmed: null,
        monitoring: null,
        isShowEditSelection: false,

        //Registrars -> Initials
        userInitials: "",
        labelsSiteMvSapMaxlengthInput: null,
        labelsMvMaxlengthInput: null,
        allowSaveAnyway: true,
        availableHosps: null,
        getAvailableHosps: false,
        Label_RESERVED: null,
        Textarea_EditSelect: null,
        hospitalCodeMsgErr: null,

        //
        titleDialog: null,
        showClockTimer: false,
        startTimeFrom: null,
        currentTime: null,
    }
    populationLabelBk = null;

    keydownHandler = (event) => {
        if (event.target.getAttribute("class") === "copyRowBtnOnGrid") {
            if (event.key === "Tab" && !event.shiftKey) {
                if (event.target.parentNode.parentNode.nextSibling !== null) {
                    let rindex = event.target.getAttribute("data-index");
                    rindex = parseInt(rindex) + 1;
                    setTimeout(function () {
                        document.getElementById("globalItemNumberStr" + rindex) ? document.getElementById("globalItemNumberStr" + rindex).focus() : "";
                    }, 100);
                } else {
                    setTimeout(function () {
                        document.getElementById("btnAddNewLatestLabelsMv") ? document.getElementById("btnAddNewLatestLabelsMv").focus() : "";
                    }, 100);
                }
            }
        }
    }

    //
    copyRowLabelsMvGrid = (event, selectedRow) => {
        event.preventDefault();

        var labelsMvList = [...this.state.labelsMv];
        if (selectedRow == labelsMvList.length - 1) {
            this.addNewLabelsMv(event);
            return false;
        }

        this.setState(prevState => {
            const newItems = [...this.state.labelsMv];
            const selectedItem = newItems[selectedRow];

            const newLabels = {};
            Object.keys(selectedItem).map((t, k) => {
                if (t === "labelMvId" || t === "labelId" || t === "globalItemNumber") {
                    newLabels[t] = 0;
                } else {
                    newLabels[t] = selectedItem[t];
                }
            });
            newItems.splice(selectedRow + 1, 1);
            newItems.splice(selectedRow + 1, 0, newLabels);

            // 
            const newFields = [...this.state.labelsMvItemFields];
            const selectedFields = newFields[selectedRow];

            const newField = {};
            Object.keys(selectedFields).map((t, k) => {
                if (t === "labelMvId" || t === "labelId" || t === "globalItemNumber") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.splice(selectedRow + 1, 1);
            newFields.splice(selectedRow + 1, 0, newField);

            return {
                labelsMv: newItems,
                labelsMvItemFields: newFields,
                isFormDirty: true,
                enableBtnSubmit: true,
            };
        });

        return false;
    }

    toggleChecked = (event) => {
        const name = event.target.name;
        var dialogFormData = this.state.dialogForm;
        if (name == "createPopulationLabel") {
            const checked = this.state.isCheckedCreatePopulationLabel;
            dialogFormData[name] = !checked ? "1" : "0";
            /*
            if (!checked) {
                dialogFormData["newSubpopLabel"] = "";
            }
            */
            this.setState({
                isCheckedCreatePopulationLabel: !checked,
                dialogForm: dialogFormData,
                //isFormDirty: true,
            });
            /*
        } else if (name == "includeDuplicatesSharedPatients") {
            const checked = this.state.isCheckedIncludeDuplicatesSharedPatients;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                isCheckedIncludeDuplicatesSharedPatients: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
            */
        } else if (name == "analyticOnly") {
            const checked = this.state.isCheckedAnalytic;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                isCheckedAnalytic: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        } else if (name == "completeOnly") {
            const checked = this.state.isCheckedComplete;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                isCheckedComplete: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        } else if (name == "incompleteOnly") {
            const checked = this.state.isCheckedInComplete;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                isCheckedInComplete: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        } else if (name == "nonReportableOnly") {
            const checked = this.state.isCheckedNonReportable;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                isCheckedNonReportable: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        }

        this.Build_SQL_Where();
    }

    //
    showBubbleHelp = (e, flag) => {
        if (flag) {
            e.target.setAttribute("class", "PopLabelCheckboxHover");
        } else {
            e.target.setAttribute("class", "");
        }
        /*
        if (e.target && e.target.id && e.target.id.indexOf("includeDuplicatesSharedPatients") != -1) {
            var title = "If this is NOT selected then the class of case order that is used when determining which shared primary should be selected for the report is: 14,13,12,11,10,22,21,20,00,40,32,31,30,37,35,33,38,43,49,34,36,41,42,99";
            e.target.setAttribute("title", title);
        }
        */
    }

    selectionCases = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedCaseOption: event.target.value,
            dialogForm: dialogFormData
        });

        this.Build_SQL_Where();
    }

    selectionIncludePrimariesOpt = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectionIncludePrimariesOpt: event.target.value,
            dialogForm: dialogFormData
        });

        this.Build_SQL_Where();
    }

    selectionDxDateCompleteOpt = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;

        if (value == 'none') {
            dialogFormData.fromDate = null;
            dialogFormData.toDate = null;
        }

        this.setState({
            selectionDxDateCompleteOpt: event.target.value,
            dialogForm: dialogFormData
        });

        this.Build_SQL_Where();
    }

    //-->
    deleteLabelsSiteMvSapGrid = (flg) => {
        let selectedRow = this.state.selectedLabelsSiteMvSapRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.labelsSiteMvSap.length) {
            selectedRow = this.state.labelsSiteMvSap.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.labelsSiteMvSap];
            if (newItems.length === 1) {
                const _newItems = [...this.state.labelsSiteMvSap];
                const firstItem = _newItems[0];

                const newItem = {};
                Object.keys(firstItem).map((t, k) => {
                    if (t === "id" || t === "labelId") {
                        //newItem[t] = 0;
                    } else {
                        newItem[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newItem);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.labelsSiteMvSapItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.labelsSiteMvSapItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return {
                labelsSiteMvSap: newItems,
                labelsSiteMvSapItemFields: newFields,
                selectedLabelsSiteMvSapRowIndex: selectedRow,
                //isFormDirty: flg === "1" ? true : this.state.isFormDirty,
                //enableBtnSubmit: true,
            };
        });

        flg === "1" ? this.setState({ enableBtnSubmit: true, isFormDirty: true }) : "";
    }

    deleteLabelsMvGrid = (flg) => {
        let selectedRow = this.state.selectedLabelsMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.labelsMv.length) {
            selectedRow = this.state.labelsMv.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.labelsMv];
            if (newItems.length === 1) {
                const _newItems = [...this.state.labelsMv];
                const firstItem = _newItems[0];

                const newItem = {};
                Object.keys(firstItem).map((t, k) => {
                    if (t === "labelId" || t === "labelMvId" || t === "globalItemNumber") {
                        //newItem[t] = 0;
                    } else {
                        newItem[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newItem);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.labelsMvItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.labelsMvItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return {
                labelsMv: newItems,
                labelsMvItemFields: newFields,
                selectedLabelsMvRowIndex: selectedRow,
                //isFormDirty: flg === "1" ? true : this.state.isFormDirty,
                //enableBtnSubmit: true,
            };
        });

        flg === "1" ? this.setState({ enableBtnSubmit: true, isFormDirty: true }) : "";
    }

    hideConfirmDeleteMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isShowConfirmDeleteMsg: false });

        if (event.target.value === "yes" && this.state.monitoring) {
            switch (this.state.monitoring) {
                case "labelsSiteMvSap":
                    this.deleteLabelsSiteMvSapGrid("1");
                    break;
                case "labelsMv":
                    this.deleteLabelsMvGrid("1");
                    break;
                default:
                    break;
            }
        }
        return false;
    }

    hideConfirmDeleteLabelMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isShowConfirmDeleteLabelMsg: false });

        if (event.target.value === "yes") {
            this.deleteLabel();
        }
        return false;
    }
    hideEditSelection = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isShowEditSelection: false });

        return false;
    }

    insertNewLabelsSiteMvSapGrid = (insertedRow) => {
        this.setState(prevState => {
            const newItems = [...this.state.labelsSiteMvSap];
            const firstItems = newItems[0];

            const newLabelsSite = {};
            Object.keys(firstItems).map((t, k) => {
                if (t === "id" || t === "labelId") {
                    newLabelsSite[t] = 0;
                } else {
                    newLabelsSite[t] = "";
                }
            });
            newItems.splice(insertedRow, 0, newLabelsSite);

            // 
            const newFields = [...this.state.labelsSiteMvSapItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "labelId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.splice(insertedRow, 0, newField);

            return {
                labelsSiteMvSap: newItems,
                labelsSiteMvSapItemFields: newFields,
            };
        });
        return false;
    }

    insertNewLabelsMvGrid = (insertedRow) => {
        this.setState(prevState => {
            const newItems = [...this.state.labelsMv];
            const firstItems = newItems[0];

            const newLabels = {};
            Object.keys(firstItems).map((t, k) => {
                if (t === "labelMvId" || t === "labelId" || t === "globalItemNumber") {
                    newLabels[t] = 0;
                } else {
                    newLabels[t] = "";
                }
            });
            newItems.splice(insertedRow, 0, newLabels);

            // 
            const newFields = [...this.state.labelsMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "labelMvId" || t === "labelId" || t === "globalItemNumber") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.splice(insertedRow, 0, newField);

            return {
                labelsMv: newItems,
                labelsMvItemFields: newFields,
            };
        });
        return false;
    }

    addEventListenerFnc = (om) => {
        var labelsSiteMvSapGrid = document.getElementById('labelsSiteMvSapListArea');
        labelsSiteMvSapGrid.addEventListener('keydown', function (e) {
            if (e.keyCode == 45 && e.target.getAttribute("data-index")) {// Insert key
                var insertedRowIdx = om.state.selectedLabelsSiteMvSapRowIndex;
                om.insertNewLabelsSiteMvSapGrid(insertedRowIdx);
                setTimeout(function () {
                    var selectedIndex = e.target.getAttribute("data-index");
                    document.getElementById('siteCode' + selectedIndex) ? document.getElementById('siteCode' + selectedIndex).focus() : "";//e.target.focus();
                    return false;
                }, 10);
                return false;
            } else if (e.keyCode == 46 && e.target.getAttribute("data-index")) {// Delete key
                //om.deleteSelectedLabelsSiteMvSap();//btnDeleteSelectedLabelsSiteMvSap
                return false;
            }
            return false;
        });

        var labelsMvGrid = document.getElementById('labelsMvListArea');
        labelsMvGrid.addEventListener('keydown', function (e) {
            if (e.keyCode == 45 && e.target.getAttribute("data-index")) {// Insert key
                var insertedRowIdx = om.state.selectedLabelsMvRowIndex;
                om.insertNewLabelsMvGrid(insertedRowIdx);
                setTimeout(function () {
                    var selectedIndex = e.target.getAttribute("data-index");
                    document.getElementById('globalItemNumberStr' + selectedIndex) ? document.getElementById('globalItemNumberStr' + selectedIndex).focus() : "";//e.target.focus();
                    return false;
                }, 10);
                return false;
            } else if (e.keyCode == 46 && e.target.getAttribute("data-index")) {// Delete key
                //om.deleteSelectedLabelsMv();//btnDeleteSelectedLabelsMv
                return false;
            }
            return false;
        });

        return false;
    }

    // LabelsSiteMvSap:: Site Codes grid
    handleLabelsSiteMvSapLostfocus = (event, index) => {
        try {
            event.preventDefault();
        } catch (ex) { }

        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";

            this.props.checkValidInput(name, value, table, key, index);
        } else {
            this.setState(prevState => {
                const newItems = [...prevState.labelsSiteMvSapItemFields];
                newItems[index][name] = "";
                return { labelsSiteMvSapItemFields: newItems };
            });
        }

        this.Build_SQL_Where();

        return false;
    }
    handleLabelsSiteMvSapGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        let name2 = null;
        let value2 = null;
        if (event.target2) {
            name2 = event.target2.name;
            value2 = event.target2.value;
        }

        this.setState(prevState => {
            const newItems = [...prevState.labelsSiteMvSap];
            newItems[index][name] = value;

            if (name2 && value2) {
                newItems[index][name2] = value2;
            }

            return { labelsSiteMvSap: newItems, isFormDirty: true };
        });

        return false;
    }

    addNewLabelsSiteMvSap = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.labelsSiteMvSap];
            const firstLabels = newItems[0];

            const newLabels = {};
            Object.keys(firstLabels).map((t, k) => {
                if (t === "id" || t === "labelId") {
                    newLabels[t] = 0;
                } else {
                    newLabels[t] = "";
                }
            });
            newItems.push(newLabels);

            // 
            const newFields = [...this.state.labelsSiteMvSapItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "labelId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { labelsSiteMvSap: newItems, labelsSiteMvSapItemFields: newFields };
        });
    }

    deleteSelectedLabelsSiteMvSap = (event) => {
        event ? event.preventDefault() : null;

        let selectedRow = this.state.selectedLabelsSiteMvSapRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.labelsSiteMvSap.length) {
            selectedRow = this.state.labelsSiteMvSap.length - 1;
        }

        if (this.state.labelsSiteMvSap[selectedRow] && this.state.labelsSiteMvSap[selectedRow]["id"] !== undefined && this.state.labelsSiteMvSap[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "labelsSiteMvSap" });
        } else {
            this.deleteLabelsSiteMvSapGrid("0");
        }
        return false;
    }

    // LabelsMv:: Selection Creteria grid
    handleLabelsMvLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";

            //this.props.checkValidInput(name, value, table, key, index);
        } else {
            this.setState(prevState => {
                const newItems = [...prevState.labelsMvItemFields];
                newItems[index][name] = "";
                return { labelsMvItemFields: newItems };
            });
        }

        let currentValue = value;
        if (name == "relation" && currentValue != "=" && currentValue != ">=" && currentValue != "<=" && currentValue != ">" && currentValue != "<" && currentValue != "<>") {
            this.setState(prevState => {
                const newItems = [...prevState.labelsMv];
                newItems[index][name] = "=";
                return { labelsMv: newItems };
            });
        } else if (name == "logic" && currentValue == "") {
            this.setState(prevState => {
                const newItems = [...prevState.labelsMv];
                newItems[index][name] = "And";
                return { labelsMv: newItems };
            });

            //Fill_Dictionary_Name
        } else if (name == "globalItemNumberStr") {
            if (value && value != "") {
                var isIntVal = false;
                try {
                    parseInt(value);
                    isIntVal = true;
                } catch (ex) {
                }

                if (isIntVal) {
                    //let url = this.props.clientgroup + "/FillDictionaryName?itemNbr=" + value;
                    let url = this.props.clientgroup + "/FillDictionaryName";
                    var _this = this;
                    axios.post(url, { itemNbr: value, accessToken: localStorage.getItem('token') })
                        .then(res => {
                            //update new accessToken again
                            var newAccessToken = res && res.data ? res.data.accessToken : null;
                            if (newAccessToken) {
                                localStorage.setItem('token', newAccessToken);
                            }

                            const globalDir = res.data.globalDir;
                            if (!res.data.error && globalDir && globalDir.itemNbr) {
                                //
                                _this.setState(prevState => {
                                    const newItems = [...prevState.labelsMv];
                                    newItems[index]["itemDescription"] = globalDir.itemDesc;
                                    newItems[index]["fieldName"] = globalDir.columnName;
                                    newItems[index]["fileName"] = globalDir.fileName;
                                    newItems[index]["dataType"] = globalDir.dataType;

                                    return { labelsMv: newItems };
                                });
                            } else {
                                //clear all set values
                                _this.setState(prevState => {
                                    const newItems = [...prevState.labelsMv];
                                    newItems[index]["itemDescription"] = "";
                                    newItems[index]["fieldName"] = "";
                                    newItems[index]["fileName"] = "";
                                    newItems[index]["dataType"] = "";

                                    return { labelsMv: newItems };
                                });
                            }
                            _this.Build_SQL_Where();//#!
                            return false;
                        })
                        .catch(function (error) {
                            console.log(error);

                            var btnLogout = document.getElementById("logoutButtonArea");
                            if (btnLogout) {
                                btnLogout.querySelector("a").click();
                            }
                            return false;
                        });
                    return false;
                }

            } else {
                //clear all set values
                _this.setState(prevState => {
                    const newItems = [...prevState.labelsMv];
                    newItems[index]["itemDescription"] = "";
                    newItems[index]["fieldName"] = "";
                    newItems[index]["fileName"] = "";
                    newItems[index]["dataType"] = "";

                    return { labelsMv: newItems };
                });
            }
        } else {
            //
            this.Build_SQL_Where();
        }

        return false;
    }
    handleLabelsMvGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        var name2 = null;
        var value2 = null;
        if (event.target2) {
            name2 = event.target2.name;
            value2 = event.target2.value;
        }

        this.setState(prevState => {
            const newItems = [...prevState.labelsMv];
            newItems[index][name] = value;

            if (name2 && value2) {
                newItems[index][name2] = value2;
            }

            return { labelsMv: newItems, isFormDirty: true };
        });

        //Fill_Dictionary_Name
        if (name == "globalItemNumberStr") {
            if (value && value != "") {
                var isIntVal = false;
                try {
                    parseInt(value);
                    isIntVal = true;
                } catch (ex) {
                }

                if (isIntVal) {
                    //let url = this.props.clientgroup + "/FillDictionaryName?itemNbr=" + value;
                    let url = this.props.clientgroup + "/FillDictionaryName";
                    var _this = this;
                    axios.post(url, { itemNbr: value, accessToken: localStorage.getItem('token') })
                        .then(res => {
                            //update new accessToken again
                            var newAccessToken = res && res.data ? res.data.accessToken : null;
                            if (newAccessToken) {
                                localStorage.setItem('token', newAccessToken);
                            }

                            const globalDir = res.data.globalDir;
                            if (!res.data.error && globalDir && globalDir.itemNbr) {
                                //
                                _this.setState(prevState => {
                                    const newItems = [...prevState.labelsMv];
                                    newItems[index]["itemDescription"] = globalDir.itemDesc;
                                    newItems[index]["fieldName"] = globalDir.columnName;
                                    newItems[index]["fileName"] = globalDir.fileName;
                                    newItems[index]["dataType"] = globalDir.dataType;

                                    return { labelsMv: newItems };
                                });
                            } else {
                                //clear all set values
                                _this.setState(prevState => {
                                    const newItems = [...prevState.labelsMv];
                                    newItems[index]["itemDescription"] = "";
                                    newItems[index]["fieldName"] = "";
                                    newItems[index]["fileName"] = "";
                                    newItems[index]["dataType"] = "";

                                    return { labelsMv: newItems };
                                });
                            }
                            _this.Build_SQL_Where();//#!
                            return false;
                        })
                        .catch(function (error) {
                            console.log(error);

                            var btnLogout = document.getElementById("logoutButtonArea");
                            if (btnLogout) {
                                btnLogout.querySelector("a").click();
                            }
                            return false;
                        });
                    return false;
                }

            } else {
                //clear all set values
                this.setState(prevState => {
                    const newItems = [...prevState.labelsMv];
                    newItems[index]["itemDescription"] = "";
                    newItems[index]["fieldName"] = "";
                    newItems[index]["fileName"] = "";
                    newItems[index]["dataType"] = "";

                    return { labelsMv: newItems };
                });
            }

            //Fixing #PR00646 START
        } else if (name == "itemDescription" && name2 == "globalItemNumberStr" && value2 != "") {
            var isIntVal = false;
            try {
                parseInt(value2);
                isIntVal = true;
            } catch (ex) {
            }

            if (isIntVal) {
                //let url = this.props.clientgroup + "/FillDictionaryName?itemNbr=" + value2;
                let url = this.props.clientgroup + "/FillDictionaryName";
                var _this = this;
                axios.post(url, { itemNbr: value2, accessToken: localStorage.getItem('token') })
                    .then(res => {
                        //update new accessToken again
                        var newAccessToken = res && res.data ? res.data.accessToken : null;
                        if (newAccessToken) {
                            localStorage.setItem('token', newAccessToken);
                        }

                        const globalDir = res.data.globalDir;
                        if (!res.data.error && globalDir && globalDir.itemNbr) {
                            //
                            _this.setState(prevState => {
                                const newItems = [...prevState.labelsMv];
                                newItems[index]["itemDescription"] = globalDir.itemDesc;
                                newItems[index]["fieldName"] = globalDir.columnName;
                                newItems[index]["fileName"] = globalDir.fileName;
                                newItems[index]["dataType"] = globalDir.dataType;

                                return { labelsMv: newItems };
                            });
                        } else {
                            //clear all set values
                            _this.setState(prevState => {
                                const newItems = [...prevState.labelsMv];
                                newItems[index]["itemDescription"] = "";
                                newItems[index]["fieldName"] = "";
                                newItems[index]["fileName"] = "";
                                newItems[index]["dataType"] = "";

                                return { labelsMv: newItems };
                            });
                        }
                        _this.Build_SQL_Where();//#!
                        return false;
                    })
                    .catch(function (error) {
                        console.log(error);

                        var btnLogout = document.getElementById("logoutButtonArea");
                        if (btnLogout) {
                            btnLogout.querySelector("a").click();
                        }
                        return false;
                    });
                return false;
            }
            //Fixing #PR00646 END
        } else if (name == "dataValues") {
            if (!value || value == "" || value.trim().lastIndexOf(",")) {

            } else {

            }
        }

        return false;
    }

    addNewLabelsMv = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.labelsMv];
            const firstLabels = newItems[0];

            const newLabels = {};
            Object.keys(firstLabels).map((t, k) => {
                if (t === "labelId" || t === "labelMvId" || t === "globalItemNumber") {
                    newLabels[t] = 0;
                } else {
                    newLabels[t] = "";
                }
            });
            newItems.push(newLabels);

            // 
            const newFields = [...this.state.labelsMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "labelId" || t === "labelMvId" || t === "globalItemNumber") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { labelsMv: newItems, labelsMvItemFields: newFields };
        });
    }

    deleteSelectedLabelsMv = (event) => {
        event ? event.preventDefault() : null;

        let selectedRow = this.state.selectedLabelsMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.labelsMv.length) {
            selectedRow = this.state.labelsMv.length - 1;
        }

        if (this.state.labelsMv[selectedRow] && this.state.labelsMv[selectedRow]["labelMvId"] !== undefined && this.state.labelsMv[selectedRow]["labelMvId"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "labelsMv" });
        } else {
            this.deleteLabelsMvGrid("0");
        }
        return false;
    }

    setSelectionLabelsSiteMvSapRow = (event, index) => {
        this.setState({ selectedLabelsSiteMvSapRowIndex: index });
    }

    setSelectionLabelsMvRow = (event, index) => {
        this.setState({ selectedLabelsMvRowIndex: index });
    }

    getHospSecurity = () => {
        let url = this.props.clientgroup + "/GetUserByEmail";
        var _this = this;
        axios.post(url, { email: this.props.userId, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                const availableHospsRpt = res.data.user !== null && res.data.user.availableHospsRpt !== null ? res.data.user.availableHospsRpt : "";
                if (!res.data.error && res.data.user !== null
                    && availableHospsRpt !== null && availableHospsRpt !== "") {
                    var _availableHospsRptArr = availableHospsRpt.split('|');
                    _availableHospsRptArr = _availableHospsRptArr.filter((obj, idx) => {
                        return obj && obj != "";
                    });
                    _this.setState({ availableHosps: _availableHospsRptArr && _availableHospsRptArr.length > 0 ? _availableHospsRptArr.join(',') : ",," });
                } else {
                    _this.setState({ availableHosps: ",All," });//#Full control
                }
                return false;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
        return false;
    }

    initClock() {
        var _this = this;
        setInterval(function () {
            var startTime = _this.state.currentTime ? _this.state.currentTime : (_this.state.startTimeFrom ? _this.state.startTimeFrom : "");
            var time = "";
            if (startTime && startTime.split(" ").length > 0) {
                var hms = startTime.split(" ")[0];
                var ap = startTime.split(" ")[1];

                var hour = parseInt(hms.split(":")[0]);
                var min = parseInt(hms.split(":")[1]);
                var sec = parseInt(hms.split(":")[2]);
                sec = 1 + sec;
                if (sec == 60) {
                    sec = 0;
                    min = 1 + min;
                }

                if (min == 60) {
                    min = 0;
                    hour = 1 + hour;
                }

                if (hour == 12) {
                    hour = 0;
                    ap == "PM" ? ap = "AM" : ap = "PM";
                }

                time = (hour < 12 ? hour : hour - 12)
                    + ':' + (min < 10 ? "0" + min : min)
                    + ':' + (sec < 10 ? "0" + sec : sec)
                    + " " + ap;
            }

            _this.setState({ currentTime: time });

            return false;
        }, 1000);
    }

    //
    init = () => {
        var listPop = document.getElementById("listForAPopForm");
        if (!listPop) {
            return false;
        }

        var _plComp = this;
        var internalAlias = setInterval(function () {
            if (_plComp.state.enableBtnSubmit) {
                var saveBtn = listPop.querySelector("#BUTTON_SUBMIT");
                var rptLabel = listPop.querySelector("#reportLabel");
                if (saveBtn && rptLabel && rptLabel.value && rptLabel.value != "") {
                    saveBtn.disabled = false;
                    saveBtn.setAttribute("class", "ErsGrBlueButton");//ScreenButtonDisabled

                    clearInterval(internalAlias);
                }
            }
        }, 1000);
    }

    componentWillUnmount() {
        this.setState({ Label_RESERVED: null });
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidMount() {
        if (!this.state.dialogForm && this.props.selectedLabelPopInfo) {
            this.setFormData(this.props.selectedLabelPopInfo);
            this.init();
        }

        if (!this.state.isCheckedCreatePopulationLabel) {
            this.setState({
                isCheckedCreatePopulationLabel: !this.props.showToggleBtn ? true : false,
            });
        }

        this.setState({ Label_RESERVED: null });
    }

    componentWillMount() {
        this.props.setChildrenOpening(false);
        this.setState({ setEventHandlerForGrid: false, availableHosps: null });

        this.initClock();
    }

    componentDidUpdate(prevProps) {
        /*
        if (this.props.error) {
            this.setState({ showClockTimer: false, showMessageError: true, errorMessage: this.props.errorMessage });
            document.querySelector('#BUTTON_SELECT_POPULATION') ? document.querySelector('#BUTTON_SELECT_POPULATION').focus() : null;
            this.props.clearErrorMessage();
            return false;
        }
        */

        if (!this.state.setEventHandlerForGrid) {
            if (document.getElementById('labelsSiteMvSapListArea')) {
                this.addEventListenerFnc(this);
                this.setState({ setEventHandlerForGrid: true });
            }
        }

        if (!this.state.getAvailableHosps) {
            this.getHospSecurity();
            this.setState({ getAvailableHosps: true });
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.dialogForm && this.state.uAllItemFields
        ) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                // updating the grid
                if (this.props.currentSelectedIndex !== -1) {
                    // LabelsSiteMvSap grid
                    this.setState(prevState => {
                        const newItems = [...prevState.labelsSiteMvSap];
                        const newFields = [...prevState.labelsSiteMvSapItemFields];

                        if (newItems[this.props.currentSelectedIndex] && this.props.currentSelectedField == "siteCode" && this.props.commonLookupItem.name) {
                            newItems[this.props.currentSelectedIndex]["siteDesc"] = this.props.commonLookupItem.name;
                        }
                        if (newFields[this.props.currentSelectedIndex]) {
                            newFields[this.props.currentSelectedIndex][this.props.currentSelectedField] = "";
                        }

                        return { labelsSiteMvSap: newItems, labelsSiteMvSapItemFields: newFields };
                    });

                    // LabelsMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.labelsMvItemFields];
                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "";
                        }
                        return { labelsMvItemFields: newItems };
                    });
                } else {
                    this.setState({
                        uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "" },
                        // And also update name again
                        dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                    });
                }
            } else {
                // updating the grid
                if (this.props.currentSelectedIndex != -1) {
                    // LabelsSiteMvSap grid
                    this.setState(prevState => {
                        const newItems = [...prevState.labelsSiteMvSap];
                        const newFields = [...prevState.labelsSiteMvSapItemFields];

                        if (newItems && newItems[this.props.currentSelectedIndex] && this.props.currentSelectedField == "siteCode") {
                            newItems[this.props.currentSelectedIndex]["siteDesc"] = "";
                        }

                        if (newFields && newFields[this.props.currentSelectedIndex]) {
                            //newFields[this.props.currentSelectedIndex][this.props.currentSelectedField] = "IN_VALID";
                        }

                        return { labelsSiteMvSap: newItems, labelsSiteMvSapItemFields: newFields };
                    });

                    // LabelsMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.labelsMvItemFields];

                        if (newItems && newItems[this.props.currentSelectedIndex]) {
                            //newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "IN_VALID";
                        }
                        return { labelsMvItemFields: newItems };
                    });
                } else {
                    this.setState({
                        //uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        //dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                    });
                }
            }
            this.props.clearCommonLookupItem();
        }

        if ((!this.state.dialogForm && this.props.selectedLabelPopInfo)
            || (prevProps.selectedLabelPopInfo && this.props.selectedLabelPopInfo && (prevProps.selectedLabelPopInfo.labelId !== this.props.selectedLabelPopInfo.labelId || prevProps.selectedLabelPopInfo.populationLabel !== this.props.selectedLabelPopInfo.populationLabel))
            || (prevProps.selectedLabelPopInfo && this.props.selectedLabelPopInfo && (prevProps.selectedLabelPopInfo.startTimeSAP !== this.props.selectedLabelPopInfo.startTimeSAP || prevProps.selectedLabelPopInfo.endTimeSAP !== this.props.selectedLabelPopInfo.endTimeSAP))
            || (this.state.reload && this.props.selectedLabelPopInfo)
            || (!this.props.isSelectAPop && this.state.dialogForm && this.props.selectedLabelPopInfo && this.state.dialogForm.requestDate != this.props.selectedLabelPopInfo.requestDate)
        ) {
            this.setFormData(this.props.selectedLabelPopInfo);
            this.init();
        }

        if (this.state.userInitials === "") {
            var logedOnUser = this.props.userId;
            var _this = this;

            //axios.get(this.props.clientgroup + "/GetUserInitials?uid=" + logedOnUser)
            let url = this.props.clientgroup + "/GetUserInitials";
            let params = { uid: logedOnUser, accessToken: localStorage.getItem('token') };
            axios.post(url, params)
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        let userInitials = "";
                        if (res.data.message !== null && res.data.message !== "") {
                            userInitials = res.data.message;
                        } else {
                            userInitials = logedOnUser.substr(0, 20);
                        }
                        _this.setState({ userInitials: userInitials });
                    } else {
                        let userInitials = logedOnUser.substr(0, 20);
                        _this.setState({ userInitials: userInitials });
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;

                });
        }

        // labelsSiteMvSap List
        if (this.props.selectedLabelPopInfo && !this.state.labelsSiteMvSap && !this.state.labelsSiteMvSapLoading) {
            this.setState({ labelsSiteMvSapLoading: true });
            var _this = this;
            //axios.get(this.props.clientgroup + "/GetLabelsSiteMvList?labelId=" + this.props.selectedLabelPopInfo.labelId)
            axios.post(this.props.clientgroup + "/GetLabelsSiteMvList",
                { labelId: this.props.selectedLabelPopInfo.labelId, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        _this.setState({ labelsSiteMvSap: res.data.labelsSiteMvList });

                        //
                        let maxLengthItem = [];//#1
                        if (!_this.state.labelsSiteMvSapItemFields && res.data.labelsSiteMvList) {
                            let _labelsSiteMvSapArr = [];
                            res.data.labelsSiteMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                _labelsSiteMvSapArr.push(fields);
                                maxLengthItem.length == 0 ? maxLengthItem.push(fields) : null;//#1
                            });

                            _this.setState({ labelsSiteMvSapItemFields: _labelsSiteMvSapArr });
                        };

                        // get maxlength for Labels_Site_Mv grid
                        //axios.post(_this.props.clientgroup + "/GetTableStructure?table=Labels_Site_Mv")

                        let url = _this.props.clientgroup + "/GetTableStructure";
                        let params = { table: "Labels_Site_Mv", accessToken: localStorage.getItem('token') };

                        axios.post(url, params)
                            .then(res => {
                                //update new accessToken again
                                var newAccessToken = res && res.data ? res.data.accessToken : null;
                                if (newAccessToken) {
                                    localStorage.setItem('token', newAccessToken);
                                }
                                if (res.data !== null && res.data.result !== "") {
                                    let maxLengthValue = res.data.result;

                                    maxLengthValue = maxLengthValue.split(',');
                                    for (var i = 0; i < maxLengthValue.length; i++) {
                                        let obj = maxLengthValue[i].split(':');
                                        maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                    }

                                    _this.setState({
                                        labelsSiteMvSapMaxlengthInput: { ...maxLengthItem }
                                    });
                                }
                            })
                            .catch(function (error) {
                                console.log(error);

                                var btnLogout = document.getElementById("logoutButtonArea");
                                if (btnLogout) {
                                    btnLogout.querySelector("a").click();
                                }
                                return false;
                            });
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;

                });
        }
        // LabelsMv List
        if (this.props.selectedLabelPopInfo && !this.state.labelsMv && !this.state.labelsMvLoading) {
            this.setState({ labelsMvLoading: true });
            var _this = this;
            //axios.get(this.props.clientgroup + "/GetLabelsMvList?labelId=" + this.props.selectedLabelPopInfo.labelId)
            axios.post(this.props.clientgroup + "/GetLabelsMvList",
                { labelId: this.props.selectedLabelPopInfo.labelId, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        var labelsMvList = res.data.labelsMvList;
                        let labelsMvArr = [];
                        //
                        let maxLengthItem = [];//#2
                        labelsMvList.map((obj) => {
                            let fields = {};
                            Object.keys(obj).map((t) => {
                                fields[t] = "";
                            });
                            labelsMvArr.push(fields);
                            maxLengthItem.length == 0 ? maxLengthItem.push(fields) : null;//#2
                        });

                        // get maxlength for LabelsMv grid
                        //axios.get(_this.props.clientgroup + "/GetTableStructure?table=Labels_Mv")

                        let url = _this.props.clientgroup + "/GetTableStructure";
                        let params = { table: "Labels_Mv", accessToken: localStorage.getItem('token') };

                        axios.post(url, params)
                            .then(res => {
                                //update new accessToken again
                                var newAccessToken = res && res.data ? res.data.accessToken : null;
                                if (newAccessToken) {
                                    localStorage.setItem('token', newAccessToken);
                                }

                                if (res.data !== null && res.data.result !== "") {
                                    let maxLengthValue = res.data.result;

                                    maxLengthValue = maxLengthValue.split(',');
                                    for (var i = 0; i < maxLengthValue.length; i++) {
                                        let obj = maxLengthValue[i].split(':');
                                        maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                    }
                                }
                            })
                            .catch(function (error) {
                                console.log(error);

                                var btnLogout = document.getElementById("logoutButtonArea");
                                if (btnLogout) {
                                    btnLogout.querySelector("a").click();
                                }
                                return false;
                            });

                        _this.setState({
                            labelsMv: labelsMvList,
                            labelsMvItemFields: labelsMvArr,
                            labelsMvMaxlengthInput: { ...maxLengthItem },
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;
                });
        }
        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
        //<--
    }

    async getTableStructure() {
        //let url = this.props.clientgroup + "/GetTableStructure?table=Labels";

        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "Labels", accessToken: localStorage.getItem('token') };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    setFormData = (selectedLabelPopInfo) => {
        this.setState({
            initialDialogForm: {
                ...selectedLabelPopInfo,
                ["requestDateSAP"]: (selectedLabelPopInfo != null && selectedLabelPopInfo.requestDate !== null && selectedLabelPopInfo.requestDate !== "" ? moment(new Date(selectedLabelPopInfo.requestDate)).format("MM/DD/YYYY") : ""),
                ["fromDate"]: (selectedLabelPopInfo != null && selectedLabelPopInfo.fromDate !== null && selectedLabelPopInfo.fromDate !== "" ? moment(new Date(selectedLabelPopInfo.fromDate)).format("MM/DD/YYYY") : ""),
                ["toDate"]: (selectedLabelPopInfo != null && selectedLabelPopInfo.toDate !== null && selectedLabelPopInfo.toDate !== "" ? moment(new Date(selectedLabelPopInfo.toDate)).format("MM/DD/YYYY") : ""),
            },
            dialogForm: {
                ...selectedLabelPopInfo,
                ["requestDateSAP"]: (selectedLabelPopInfo != null && selectedLabelPopInfo.requestDate !== null && selectedLabelPopInfo.requestDate !== "" ? moment(new Date(selectedLabelPopInfo.requestDate)).format("MM/DD/YYYY") : ""),
                ["fromDate"]: (selectedLabelPopInfo != null && selectedLabelPopInfo.fromDate !== null && selectedLabelPopInfo.fromDate !== "" ? moment(new Date(selectedLabelPopInfo.fromDate)).format("MM/DD/YYYY") : ""),
                ["toDate"]: (selectedLabelPopInfo != null && selectedLabelPopInfo.toDate !== null && selectedLabelPopInfo.toDate !== "" ? moment(new Date(selectedLabelPopInfo.toDate)).format("MM/DD/YYYY") : ""),

                newSubpopLabel: this.state.dialogForm && this.state.dialogForm.newSubpopLabel && this.state.Label_RESERVED ? this.state.dialogForm.newSubpopLabel : null,
            },
            //isFormDirty: this.props.isFormDirty,
            reload: false,
            changed: true,
            loaded: true,
            bypass: true,

            showClockTimer: false,
            titleDialog: null,

            selectionIncludePrimariesOpt: selectedLabelPopInfo && selectedLabelPopInfo.includeDuplicatesSharedPatients ? selectedLabelPopInfo.includeDuplicatesSharedPatients : "All",
            selectionDxDateCompleteOpt: selectedLabelPopInfo && selectedLabelPopInfo.dxDateCompleteOpt ? selectedLabelPopInfo.dxDateCompleteOpt : "none",
            selectedCaseOption: selectedLabelPopInfo && selectedLabelPopInfo.abstSuspAll ? selectedLabelPopInfo.abstSuspAll : "Abstract Cases Only",

            //["isCheckedIncludeDuplicatesSharedPatients"]: selectedLabelPopInfo && selectedLabelPopInfo.includeDuplicatesSharedPatients == 'true' ? true : false,

            ["isCheckedAnalytic"]: selectedLabelPopInfo && selectedLabelPopInfo.analyticOnly == 'true' ? true : false,
            ["isCheckedComplete"]: selectedLabelPopInfo && selectedLabelPopInfo.completeOnly == 'true' ? true : false,
            ["isCheckedInComplete"]: selectedLabelPopInfo && selectedLabelPopInfo.incompleteOnly == 'true' ? true : false,
            ["isCheckedNonReportable"]: selectedLabelPopInfo && selectedLabelPopInfo.nonReportableOnly == 'true' ? true : false,
            /*
            // LabelsSiteMvSap
            labelsSiteMvSap: null,
            labelsSiteMvSapItemFields: null,
            labelsSiteMvSapLoading: false,
            selectedLabelsSiteMvSapRowIndex: 0,
            labelsSiteMvSapGridFocusing: false,

            // LabelsMv
            labelsMv: null,
            labelsMvItemFields: null,
            labelsMvLoading: false,
            selectedLabelsMvRowIndex: 0,
            labelsMvGridFocusing: false,

            isShowConfirmDeleteMsg: false,
            isShowConfirmDeleteLabelMsg: false,
            confirmed: null,
            monitoring: null,
            isShowEditSelection: false,

            labelsSiteMvSapMaxlengthInput: null,
            labelsMvMaxlengthInput: null,
            Textarea_EditSelect: null,
            hospitalCodeMsgErr: null,
            */
            Label_RESERVED: this.state.Label_RESERVED,
        });

        if (!selectedLabelPopInfo.bypass) {
            this.setState({
                // LabelsSiteMvSap
                labelsSiteMvSap: null,
                labelsSiteMvSapItemFields: null,
                labelsSiteMvSapLoading: false,
                selectedLabelsSiteMvSapRowIndex: 0,
                labelsSiteMvSapGridFocusing: false,

                // LabelsMv
                labelsMv: null,
                labelsMvItemFields: null,
                labelsMvLoading: false,
                selectedLabelsMvRowIndex: 0,
                labelsMvGridFocusing: false,

                isShowConfirmDeleteMsg: false,
                isShowConfirmDeleteLabelMsg: false,
                confirmed: null,
                monitoring: null,
                isShowEditSelection: false,

                labelsSiteMvSapMaxlengthInput: null,
                labelsMvMaxlengthInput: null,
                Textarea_EditSelect: null,
                hospitalCodeMsgErr: null,
            });
        }

        if ((!this.state.uAllItemFields && selectedLabelPopInfo)) {
            let fields = {};
            Object.keys(selectedLabelPopInfo).map((t) => {
                fields[t] = "";
            });

            this.setState({ uAllItemFields: fields, pageMaxlengthInput: fields });
        };
        return false;
    }

    savePopLabelInfo = (event) => {
        event.preventDefault();

        this.props.setChildrenOpening(false);

        let dialogFormData = this.state.dialogForm;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        // TODO - check form validation
        let isValid = true;
        Object.keys(dialogFormData).map((t) => {
            if (dialogFormData[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let labelsSiteMvSap = this.state.labelsSiteMvSap;
            let labelsMv = this.state.labelsMv;

            if (!dialogFormData.requestedBySAP && dialogFormData.requestedBy) {
                dialogFormData.requestedBySAP = dialogFormData.requestedBy;
            }
            if (!dialogFormData.requestDateSAP && dialogFormData.requestDate) {
                dialogFormData.requestDateSAP = dialogFormData.requestDate;
            }

            dialogFormData.labelsSiteMvSap = labelsSiteMvSap;
            dialogFormData.labelsMv = labelsMv;

            this.setState({ bypass: false });

            this.props.savePopLabelInfo(dialogFormData);
            return false;
        }
    }

    handleOnFocus = (event, index, alias) => {
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
        if (alias) {
            switch (alias) {
                case "selectedLabelsSiteMvSapRowIndex":
                    this.setState({ selectedLabelsSiteMvSapRowIndex: index });
                    break;
                case "selectedLabelsMvRowIndex":
                    this.setState({ selectedLabelsMvRowIndex: index });
                    break;
            }
        }
    }

    // USING FOR REPORTING PAGES: !IMPORTANT
    callToParent = (event) => {
        event.preventDefault();

        if (this.props.updateToParent) {
            var plForm = this.state.dialogForm;

            if (plForm.populationLabel == "") {
                return false;
            }

            if (plForm.newSubpopLabel && plForm.newSubpopLabel != "") {
                //this.Set_Label_ID();

                var populationLabel = plForm.populationLabel ? plForm.populationLabel : "";
                var newLabel = plForm.newSubpopLabel ? plForm.newSubpopLabel : "";

                var labelReserved = this.state.Label_RESERVED ? this.state.Label_RESERVED : "";
                var label_IDReserved = plForm.labelIdReserved ? plForm.labelIdReserved : 0;

                //This is not redundant code, and each of the conditions below must be covered whether the user is
                //leaving the pop label control or leaving the new pop label control
                if (newLabel == populationLabel) { newLabel = ""; }	//Prevent duplicate labels

                if (newLabel != "") {
                    plForm.labelSAP = newLabel;
                    //plForm.label = newLabel;
                    plForm.labelId = '0';
                } else {
                    if (populationLabel != labelReserved) {
                        plForm.labelSAP = populationLabel;
                        //plForm.label = populationLabel;
                        plForm.labelId = '0';
                    } else {
                        plForm.labelSAP = labelReserved;
                        //plForm.label = labelReserved;
                        plForm.labelId = label_IDReserved;
                    }
                }
            }

            //start-->
            //this.Build_SQL_Where();
            var dateFormat = plForm.dateFormat;

            var labelsMvList = this.state.labelsMv;
            var rows = labelsMvList ? labelsMvList.length : 0;

            var sqlWhere = "";
            var logic = "";

            var row = 0;

            for (var i = 1; i < rows + 1; i++) {
                row = i - 1;
                var itemNbr = labelsMvList[row] && labelsMvList[row]["globalItemNumberStr"] ? labelsMvList[row]["globalItemNumberStr"] : "";
                var fieldName = labelsMvList[row] && labelsMvList[row]["fieldName"] ? labelsMvList[row]["fieldName"] : "";
                var relation = labelsMvList[row] && labelsMvList[row]["relation"] ? labelsMvList[row]["relation"] : "=";
                var dataValues = labelsMvList[row] && labelsMvList[row]["dataValues"] ? labelsMvList[row]["dataValues"] : "";
                var tableName = labelsMvList[row] && labelsMvList[row]["fileName"] ? labelsMvList[row]["fileName"] : "";
                var dataType = labelsMvList[row] && labelsMvList[row]["dataType"] ? labelsMvList[row]["dataType"] : "";

                if (itemNbr != "" && tableName != "" && fieldName != "" && dataType != "") {
                    dataValues = dataValues.replace(/\x2a/g, '%');	//Replace * with sql % sign
                    if (dataValues.indexOf('%') > -1 || dataValues.indexOf('_') > -1) {
                        if (relation == '<>') { relation = 'Not Like'; }
                        else { relation = 'Like'; }
                    }

                    if (dataValues.slice(-1) == ' ') //This code is just here until Alpha adopts js 1.8 which includes a trim function
                    {
                        dataValues = dataValues.substring(0, dataValues.length - 1);
                    }

                    if (dataValues.slice(-1) == ',')	//Strip off last comma
                    {
                        dataValues = dataValues.substring(0, dataValues.length - 1);
                    }

                    if (dataType == "Date" && dataValues.indexOf("/") > -1)		//This code allows international clients to insert their dates into dd/mm/yyyy and convert to sql's yyyy/mm/dd
                    {
                        var dateArray = dataValues.split("/");
                        if (dateArray[0].length == 1 && dateArray[0] != '%') { dateArray[0] = '0' + dateArray[0]; } //Convert single digit dd/mm to double digit
                        if (dateArray[1].length == 1 && dateArray[1] != '%') { dateArray[1] = '0' + dateArray[1]; } //Convert single digit dd/mm to double digit
                        if (dateFormat == "Int") { dataValues = dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]; }
                        else { dataValues = dateArray[2] + "-" + dateArray[0] + "-" + dateArray[1]; }
                    }

                    dataValues = dataValues.replace(/,/g, '\',\'');	//Insert apostrophes around the commas by replacing , with ','
                    if (relation == '<>') {
                        dataValues = dataValues.replace(/,/g, ' AND ' + tableName + ".[" + fieldName + '] ' + relation + " ");
                    } else {
                        dataValues = dataValues.replace(/,/g, ' OR ' + tableName + ".[" + fieldName + '] ' + relation + " ");
                    }

                    if (dataType == "Date" && (relation == "Like" || relation == "Not Like")) {
                        dataValues = dataValues.replace(/,%/g, '%,');
                        if (dataValues.slice(0, 1) == '%' && isNaN(dataValues.slice(-1)) == false) {
                            dataValues = dataValues.substring(1, dataValues.length) + "%";
                        } //If the user put the wildcard in front of the date, move it to the end
                    }

                    if (dataType != "Numeric" || relation == '=' || relation == '<>' || relation == 'Like' || relation == 'Not Like' || dataValues == '') { dataValues = " '" + dataValues + "'"; } //Put apostrophes around the data values, unless we are using the following symbols with Numeric values: >, >=, <, <=,

                    if (row == 0 || i == 1) {
                        sqlWhere = sqlWhere + " Where ((IsNull(" + tableName + ".[" + fieldName + "],'') " + relation + " " + dataValues;
                    } else {
                        sqlWhere = sqlWhere + " " + logic + " (IsNull(" + tableName + ".[" + fieldName + "],'') " + relation + " " + dataValues;
                    }

                    sqlWhere = sqlWhere + ") ";

                    logic = labelsMvList[row] && labelsMvList[row]["logic"] ? labelsMvList[row]["logic"] : "";
                    if (logic == "") { logic = "And"; }
                }
            }
            if (sqlWhere != '') { sqlWhere = sqlWhere + ")"; }

            var hospCode = plForm.hospitalCode ? plForm.hospitalCode : "";
            var analyticCases = plForm.analyticOnly;
            var completeCases = plForm.completeOnly;
            var abstractCases = plForm.abstSuspAll ? plForm.abstSuspAll : "Abstract Cases Only";

            var incompleteOnly = plForm.incompleteOnly;
            var nonReportableOnly = plForm.nonReportableOnly;

            var labelsSiteMvSapList = this.state.labelsSiteMvSap;
            var siteArray = labelsSiteMvSapList ? labelsSiteMvSapList : [];
            var siteList = '';

            for (var i = 0; i < siteArray.length; i++) {
                if (siteArray[i] && siteArray[i]["siteCode"] && siteArray[i]["siteCode"] != '') {
                    if (siteList == '') {
                        siteList += "'" + (siteArray[i]["siteCode"] ? siteArray[i]["siteCode"] : '') + "'";
                    } else {
                        siteList += ",'" + (siteArray[i]["siteCode"] ? siteArray[i]["siteCode"] : '') + "'";
                    }
                }
            }

            if (siteList != '') {
                if (sqlWhere == '') {
                    sqlWhere = " Where Site_Code In (" + siteList + ")";
                } else {
                    sqlWhere = sqlWhere + " And Site_Code In (" + siteList + ")";
                }
            }

            if (hospCode != '') {
                if (sqlWhere == '') {
                    sqlWhere = " Where Hosp In ('" + hospCode.replace(/,/g, '\',\'') + "')";
                } else {
                    sqlWhere = sqlWhere + " And Hosp In ('" + hospCode.replace(/,/g, '\',\'') + "')";
                }
            }

            /*
            var includeDuplicatesSharedPatients = plForm.includeDuplicatesSharedPatients;
            if (!(includeDuplicatesSharedPatients == true || includeDuplicatesSharedPatients == 'true' || includeDuplicatesSharedPatients == '1')) {
                if (sqlWhere == '') {
                    sqlWhere = " Where Primary_Summary.HOSP = [dbo].Best_Class_Hosp(Primary_Summary.CID,'" + hospCode + "')";
                } else {
                    sqlWhere = sqlWhere + " And Primary_Summary.HOSP = [dbo].Best_Class_Hosp(Primary_Summary.CID,'" + hospCode + "')";
                }
            }
            */

            if (analyticCases == true || analyticCases == 'true' || analyticCases == '1') {
                if (sqlWhere == '') {
                    sqlWhere = " Where Class In ('00','10','11','12','13','14','20','21','22')";
                } else {
                    sqlWhere = sqlWhere + " And Class In ('00','10','11','12','13','14','20','21','22')";
                }
            }
            if (completeCases == true || completeCases == 'true' || completeCases == '1') {
                if (sqlWhere == '') {
                    sqlWhere = " Where State_Rpt In ('T','Y','C','F')";
                } else {
                    sqlWhere = sqlWhere + " And State_Rpt In ('T','Y','C','F')";
                }
            }

            if (abstractCases == 'Abstract Cases Only' || abstractCases == 'abstract') {
                if (sqlWhere == '') {
                    sqlWhere = " Where Abst_Suna_Flag = 'A'";
                } else {
                    sqlWhere = sqlWhere + " And Abst_Suna_Flag = 'A'";
                }
            } else if (abstractCases == 'Suspense Cases Only' || abstractCases == 'suspense') {
                if (sqlWhere == '') {
                    sqlWhere = " Where Abst_Suna_Flag = 'S'";
                } else {
                    sqlWhere = sqlWhere + " And Abst_Suna_Flag = 'S'";
                }

                //#PR00601: Exclude primaries with susp_abst_flag = 'P' when "ALL CASES" is chosen
            } else if (abstractCases == 'All Cases' || abstractCases == 'all') {
                if (sqlWhere == '') {
                    sqlWhere = " Where Abst_Suna_Flag != 'P'";
                } else {
                    sqlWhere = sqlWhere + " And Abst_Suna_Flag != 'P'";
                }
            }

            if (incompleteOnly == true || incompleteOnly == 'true' || incompleteOnly == '1') {
                if (sqlWhere == '') {
                    sqlWhere = " Where State_Rpt = 'I'";
                } else {
                    sqlWhere = sqlWhere + " And State_Rpt = 'I'";
                }
            }
            if (nonReportableOnly == true || nonReportableOnly == 'true' || nonReportableOnly == '1') {
                if (sqlWhere == '') {
                    sqlWhere = " Where State_Rpt = 'N'";
                } else {
                    sqlWhere = sqlWhere + " And State_Rpt = 'N'";
                }
            }

            var dxDateCompleteOpt = plForm.dxDateCompleteOpt;
            var fromDate = plForm.fromDate ? plForm.fromDate : "";
            var toDate = plForm.toDate ? plForm.toDate : "";
            if (dxDateCompleteOpt == 'dxdate') {
                if (sqlWhere == '') {
                    sqlWhere = " Where (IsNull(Primary_Summary.[Diagnosis_Date],'') BETWEEN '" + fromDate + "' AND '" + toDate + "') ";
                } else {
                    sqlWhere = sqlWhere + " And (IsNull(Primary_Summary.[Diagnosis_Date],'') BETWEEN '" + fromDate + "' AND '" + toDate + "') ";
                }
            } else if (dxDateCompleteOpt == 'complete') {
                if (sqlWhere == '') {
                    sqlWhere = " Where (IsNull(Diagnosis.[First_Cont_Date],'') BETWEEN '" + fromDate + "' AND '" + toDate + "') ";
                } else {
                    sqlWhere = sqlWhere + " And (IsNull(Diagnosis.[First_Cont_Date],'') BETWEEN '" + fromDate + "' AND '" + toDate + "') ";
                }
            }

            plForm.textareaEditSelect = sqlWhere;
            //<--end


            let labelsSiteMvSap = this.state.labelsSiteMvSap;
            let labelsMv = this.state.labelsMv;

            plForm.labelsSiteMvSap = labelsSiteMvSap;
            plForm.labelsMv = labelsMv;

            if (!plForm.fromDate || plForm.fromDate == "") {
                plForm.fromDate = null;
            }
            if (!plForm.toDate || plForm.toDate == "") {
                plForm.toDate = null;
            }

            this.props.updateToParent(plForm);
        }
        return false;
    }

    //
    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true,
                Label_RESERVED: event.target.value,//the original value is population label
            });

            if (event.target.name == "populationLabel") {
                var plItem = document.getElementById("populationLabel");
                var plLookup = plItem ? plItem.nextSibling : null;
                setTimeout(function () {
                    plLookup ? plLookup.focus() : null;
                }, 1000);
                setTimeout(function () {
                    //plItem ? plItem.focus() : null;
                    let createPopulationLabel = document.getElementById("createPopulationLabel");
                    if (createPopulationLabel && createPopulationLabel.checked) {
                        document.getElementById("requestedBySAP") ? document.getElementById("requestedBySAP").focus() : null;
                    } else {
                        if (document.getElementById("Tabid_Graph_Pop") && document.getElementById("Tabid_Graph_Pop").getAttribute("class") && document.getElementById("Tabid_Graph_Pop").getAttribute("class").indexOf("tab-list-active") != -1) {
                            document.getElementById("itemNbr0") ? document.getElementById("itemNbr0").focus() : null;
                        } else if (document.getElementById("reportLabel")) {
                            document.getElementById("reportLabel").focus();
                        } else if (document.getElementById("itemNbr0")) {
                            document.getElementById("itemNbr0").focus();
                        } else {
                            document.getElementById("BUTTON_DELETE_POP").focus();
                        }
                    }
                }, 1500);

                // updatePopLabelBackToParent
                if (this.props.plUpdFnc) {
                    this.props.plUpdFnc(event.target.value);
                }

                return false;
            }
        } else {
            if (event.target.name === "textareaEditSelect") {
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        [event.target.name]: event.target.value
                    },
                    Textarea_EditSelect: event.target.value,
                    isFormDirty: true,
                });
            } else {
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        [event.target.name]: event.target.value
                    },
                    isFormDirty: true,
                });
            }

            // updatePopLabelBackToParent
            if (event.target.name == "populationLabel" && this.props.plUpdFnc) {
                this.props.plUpdFnc(event.target.value);
            }
        }
    }

    async Get_Data(pLabel) {
        var _this = this;
        //Labels
        await _this.props.selectAPopLabel(pLabel);

        this.setState({
            reload: true,

            // LabelsSiteMvSap
            labelsSiteMvSap: null,
            labelsSiteMvSapItemFields: null,
            labelsSiteMvSapLoading: false,
            selectedLabelsSiteMvSapRowIndex: 0,
            labelsSiteMvSapGridFocusing: false,

            // LabelsMv
            labelsMv: null,
            labelsMvItemFields: null,
            labelsMvLoading: false,
            selectedLabelsMvRowIndex: 0,
            labelsMvGridFocusing: false,

            isShowConfirmDeleteMsg: false,
            isShowConfirmDeleteLabelMsg: false,
            confirmed: null,
            monitoring: null,
            isShowEditSelection: false,

            labelsSiteMvSapMaxlengthInput: null,
            labelsMvMaxlengthInput: null,
            Textarea_EditSelect: null,
            hospitalCodeMsgErr: null,

            isCheckedCreatePopulationLabel: _this.state.isCheckedCreatePopulationLabel,

            //
            selectionIncludePrimariesOpt: "All",
            selectionDxDateCompleteOpt: "none",
            selectedCaseOption: "Abstract Cases Only",
            //
        });

        return false;
    }

    No_Quotes(Pop_Label) {
        var changed = false;
        if (!Pop_Label || Pop_Label == "") {
            return false;
        } else if (Pop_Label.indexOf("\"") != -1) {
            Pop_Label = Pop_Label.replace("\"", "");
            alert('Population Label cannot contain double quotes \"\"');
            //set focus into this population label field
            changed = true;
        } else if (Pop_Label.indexOf("'") != -1) {
            Pop_Label = Pop_Label.replace("'", "");
            alert("Population Label cannot contain apostrophes '");
            //set focus into this population label field
            changed = true;
        } else if (Pop_Label.indexOf("/") != -1 || Pop_Label.indexOf("\\") != -1) {
            Pop_Label = Pop_Label.replace("/", "").replace("\\", "");
            alert("Population Label cannot contain slashes /'\"");
            //set focus into this population label field
            changed = true;
        } else if (Pop_Label.indexOf("*") != -1) {
            Pop_Label = Pop_Label.replace("*", "");
            alert('Population Label cannot contain asterisks *');
            //set focus into this population label field
            changed = true;
        } else if (Pop_Label.indexOf("&") != -1) {
            Pop_Label = Pop_Label.replace("&", "and");
            alert('Population Label cannot contain ampersands &');
            //set focus into this population label field
            changed = true;
        }

        if (changed) {
            var dialogFormData = this.state.dialogForm;
            dialogFormData.populationLabel = Pop_Label;
            this.setState({ dialogForm: dialogFormData });
        }

        return false;
    }

    User_Defined_Date_Format(myDate) {
        //This code tries to fill in slashes and the two digit century when a user doesn't enter them
        var fmtDate = "";

        myDate = myDate.replace(/[-]/gi, '/');

        if (myDate.length > 10) {
            myDate = myDate.substr(0, 10);
        }

        if ((myDate.length == 6 || myDate.length == 7 || myDate.length == 8 || myDate.length == 9) && myDate.split('/').length == 3) {
            var mm = myDate.split('/')[0];
            var dd = myDate.split('/')[1];
            var yy = myDate.split('/')[2];

            myDate = ('00' + mm).substr(mm.length, 2) + '/' + ('00' + dd).substr(dd.length, 2) + '/' + yy;
        }

        myDate = myDate.replace(/[^0-9-/]/gi, '');

        if (myDate.length == 8 && myDate.indexOf('/') == -1) {
            fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/" + myDate.substr(4, 4);
        } else if (myDate.length == 8 && myDate.indexOf('/') >= 1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(6, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 6) + "19" + myDate.substr(6, 2);
            } else {
                fmtDate = myDate.substr(0, 6) + "20" + myDate.substr(6, 2);
            }
        } else if (myDate.length == 6 && myDate.indexOf('/') == -1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(4, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/19" + myDate.substr(4, 2);
            } else {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/20" + myDate.substr(4, 2);
            }
        } else {
            fmtDate = myDate;
        }

        return fmtDate;
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== ""
            && event.target.name === "hospitalCode") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";

            this.props.checkValidInput(name, value, table, key, -1, flag);
        } else if (event.target.name === "hospitalCode" && event.target.value == "") {
            this.setState({
                uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "" },
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name + "Name"]: "",
                }
            });

            setTimeout(function () {
                document.getElementById("hospitalCodeName") ? document.getElementById("hospitalCodeName").innerText = "" : null;
            }, 0);
        } else {
            this.setState({
                uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "" }
            });
        }

        //
        if (event.target.name === "populationLabel" && event.target.value != "") {
            //Remove any single or double quotes or ampersands from pop label
            //{ Dialog.Object }.ajaxCallback('', '', 'No_Quotes', '', '');
            this.No_Quotes(event.target.value);

            //setTimeout(Get_Data,200);
            var _this = this;
            var pLabel = event.target.value;

            var _populationLabelOrg = this.state.initialDialogForm["populationLabel"];

            if ((_populationLabelOrg == pLabel && this.populationLabelBk) || (_populationLabelOrg && this.populationLabelBk && this.populationLabelBk == pLabel)) {
                return false;
            }
            this.populationLabelBk = pLabel;

            // ---------------------------------------------------------------->
            // updatePopLabelBackToParent
            if (event.target.name == "populationLabel" && this.props.plUpdFnc) {
                this.props.plUpdFnc(event.target.value);
            }
            // <----------------------------------------------------------------

            //
            //let url = this.props.clientgroup + "/CheckThisLabelHasExisted?label=" + pLabel;
            let url = this.props.clientgroup + "/CheckThisLabelHasExisted";
            axios.post(url, { label: pLabel, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error && _populationLabelOrg != pLabel) {
                        setTimeout(function () {
                            _this.Get_Data(pLabel);
                            _this.setState({ Label_RESERVED: pLabel });
                        }, 0);
                    } else if ((res.data.error && pLabel == "") || (this.populationLabelBk === pLabel)) {
                        _this.Get_Data(pLabel);
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;
                });
            return false;

        } else if (event.target.name === "newSubpopLabel") {
            this.Set_Label_ID();
        } else if (event.target.name === "hospitalCode") {
            let _fName = event.target.name;
            let _fValue = event.target.value;
            var result = this.validationHospCodes(_fValue);
            if (result && result != '') {
                //@TODO: ...display warning/error message when mouse is hover on this field
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [_fName]: "IN_VALID" },
                    // And also update name again
                    //dialogForm: { ...this.state.dialogForm, [_fName + "Name"]: "" },
                    hospitalCodeMsgErr: result,
                });
            } else {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [_fName]: "" },
                    // And also update name again
                    //dialogForm: { ...this.state.dialogForm, [_fName + "Name"]: hospitalName },
                    hospitalCodeMsgErr: "",
                });
            }

            this.Build_SQL_Where();
        } else if (event.target.name === "requestedBySAP" && event.target.value === "") {
            //{Dialog.Object}.ajaxCallback('','','Check_User_Name','','');
        } else if (event.target.name === "requestDateSAP" || event.target.name === "fromDate" || event.target.name === "toDate") {
            //Check_Date_Format
            var fName = event.target.name;
            let thisValue = event.target.value;
            let thisDate = this.User_Defined_Date_Format(thisValue.replace(/[^0-9-/]/gi, ''));
            if (thisDate != thisValue) {
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        [fName]: thisDate,//moment(new Date(thisDate)).format("MM/DD/YYYY")
                    }
                });
            }

            if (event.target.name == "fromDate" || event.target.name == "toDate") {
                var _this = this;
                setTimeout(function () {
                    _this.Build_SQL_Where();
                    return false;
                }, 1000);
            }
        } else if (event.target.name === "populationLabel" && event.target.value === "") {
            this.populationLabelBk = "";
        }
    }
    // --END

    validationHospCodes(data) {
        var allowedHospsString = this.state.availableHosps ? this.state.availableHosps : ",All,";//{ dialog.Object }.getValue('Allowed_Hosps');
        var allowedHosps = allowedHospsString.split(',');
        var enteredHosps = data.split(',');
        var found = 'false';
        var allowed = 'true';
        var hospNotFound = '';

        for (var i = 0; i < enteredHosps.length; i++) {
            found = 'false';
            for (var j = 0; j < allowedHosps.length; j++) {
                if (enteredHosps[i] == allowedHosps[j]) {
                    found = 'true';
                }
            }
            if (found != 'true' && allowed == 'true') {
                allowed = 'false';
                hospNotFound = enteredHosps[i];
            }
        }

        if (allowedHospsString.indexOf(',All,') > -1 || (data != '' && hospNotFound == '')) {
            return false;
        } else {
            if (data == '') {
                //return 'A hospital code must be filled in to select data';
                return data;
            } else {
                return 'You do not have permission to run reports for hospital ' + hospNotFound + '';
            }
        }
    }

    Set_Label_ID() {
        var form = this.state.dialogForm;

        var populationLabel = form.populationLabel ? form.populationLabel : "";//{ dialog.Object }.getValue('Population_Label')
        var newLabel = form.newSubpopLabel ? form.newSubpopLabel : "";//{ dialog.Object }.getValue('New_Subpop_Label')

        var labelReserved = this.state.Label_RESERVED ? this.state.Label_RESERVED : "";//{ dialog.Object }.getValue('Label_RESERVED')	//DO NOT Ever overwrite the contents of this control. This variable holds the original label that was retrieved via the lookup.
        var label_IDReserved = form.labelIdReserved ? form.labelIdReserved : 0;//{ dialog.Object }.getValue('Label_ID_RESERVED')	//DO NOT Ever overwrite the contents of this control. This variable holds the original label ID that was retrieved via the lookup.

        //This is not redundant code, and each of the conditions below must be covered whether the user is
        //leaving the pop label control or leaving the new pop label control
        if (newLabel == populationLabel) { newLabel = ""; }	//Prevent duplicate labels

        if (newLabel != "") {
            form.labelSAP = newLabel;
            //form.label = newLabel;//{ dialog.Object }.setValue('Label', newLabel)
            form.labelId = '0';//{ dialog.Object }.setValue('Label_ID', '')
        } else {
            if (populationLabel != labelReserved) {
                form.labelSAP = populationLabel;
                //form.label = populationLabel;//{ dialog.Object }.setValue('Label', populationLabel)
                form.labelId = '0';//{ dialog.Object }.setValue('Label_ID', '')
            } else {
                form.labelSAP = labelReserved;
                //form.label = labelReserved;//{ dialog.Object }.setValue('Label', labelReserved)
                form.labelId = label_IDReserved;//{ dialog.Object }.setValue('Label_ID', label_IDReserved)
            }
        }

        // apply these changes into view
        this.setState({
            dialogForm: form,//{ ...form}
        });
    }

    validateHosp_SelectAPop() {
        var form = this.state.dialogForm;
        var allowedHospsString = this.state.availableHosps ? this.state.availableHosps : ",All,";//{ dialog.Object }.getValue('Allowed_Hosps');
        var allowedHosps = allowedHospsString ? allowedHospsString.split(',') : [];
        var enteredHospsString = form.hospitalCode;//{ dialog.Object }.getValue('Hospital_Code');
        var enteredHosps = enteredHospsString ? enteredHospsString.split(',') : [];//{ dialog.Object }.getValue('Hospital_Code').split(',');
        var found = 'false';
        var allowed = 'true';
        var hospNotFound = '';

        for (var i = 0; i < enteredHosps.length; i++) {
            found = 'false';
            for (var j = 0; j < allowedHosps.length; j++) {
                if (enteredHosps[i] == allowedHosps[j]) {
                    found = 'true';
                }
            }
            if (found != 'true' && allowed == 'true') {
                allowed = 'false';
                hospNotFound = enteredHosps[i];
            }
        }

        if (allowedHospsString.indexOf(',All,') > -1 || (enteredHospsString && enteredHospsString != '' && hospNotFound == '')) {
            return 'Y';
        } else {
            return 'N';
        }
    }

    //Main func
    Build_SQL_Where() {
        var form = this.state.dialogForm;
        var dateFormat = form.dateFormat;

        var labelsMvList = this.state.labelsMv;
        var rows = labelsMvList ? labelsMvList.length : 0;

        var sqlWhere = "";
        var logic = "";

        var row = 0;

        for (var i = 1; i < rows + 1; i++) {
            row = i - 1;//{ dialog.object }._repeatingSectionLogicalToPhysicalRow('SELECTION_CRITERIA', i)
            var itemNbr = labelsMvList[row] && labelsMvList[row]["globalItemNumberStr"] ? labelsMvList[row]["globalItemNumberStr"] : "";//{ dialog.Object }.getValue('Item_Nbr:' + row)
            //var itemDesc = labelsMvList[row] && labelsMvList[row]["itemDescription"] ? labelsMvList[row]["itemDescription"] : "";//{ dialog.Object }.getValue('item_Desc:' + row)

            var fieldName = labelsMvList[row] && labelsMvList[row]["fieldName"] ? labelsMvList[row]["fieldName"] : "";//{ dialog.Object }.getValue('field_Name:' + row)
            var relation = labelsMvList[row] && labelsMvList[row]["relation"] ? labelsMvList[row]["relation"] : "=";//{ dialog.Object }.getValue('relation:' + row)
            var dataValues = labelsMvList[row] && labelsMvList[row]["dataValues"] ? labelsMvList[row]["dataValues"] : "";//{ dialog.Object }.getValue('data_Values:' + row)
            var tableName = labelsMvList[row] && labelsMvList[row]["fileName"] ? labelsMvList[row]["fileName"] : "";//{ dialog.Object }.getValue('file_Name:' + row)
            var dataType = labelsMvList[row] && labelsMvList[row]["dataType"] ? labelsMvList[row]["dataType"] : "";//{ dialog.Object }.getValue('Data_type:' + row)

            if (itemNbr != "" && tableName != "" && fieldName != "" && dataType != "") {
                dataValues = dataValues.replace(/\x2a/g, '%');	//Replace * with sql % sign
                if (dataValues.indexOf('%') > -1 || dataValues.indexOf('_') > -1) {
                    if (relation == '<>') { relation = 'Not Like'; }
                    else { relation = 'Like'; }
                }

                if (dataValues.slice(-1) == ' ') //This code is just here until Alpha adopts js 1.8 which includes a trim function
                { dataValues = dataValues.substring(0, dataValues.length - 1); }

                if (dataValues.slice(-1) == ',')	//Strip off last comma
                { dataValues = dataValues.substring(0, dataValues.length - 1); }

                if (dataType == "Date" && dataValues.indexOf("/") > -1)		//This code allows international clients to insert their dates into dd/mm/yyyy and convert to sql's yyyy/mm/dd
                {
                    var dateArray = dataValues.split("/");
                    if (dateArray[0].length == 1 && dateArray[0] != '%') { dateArray[0] = '0' + dateArray[0]; } //Convert single digit dd/mm to double digit
                    if (dateArray[1].length == 1 && dateArray[1] != '%') { dateArray[1] = '0' + dateArray[1]; } //Convert single digit dd/mm to double digit
                    if (dateFormat == "Int") { dataValues = dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]; }
                    else { dataValues = dateArray[2] + "-" + dateArray[0] + "-" + dateArray[1]; }
                }

                dataValues = dataValues.replace(/,/g, '\',\'');	//Insert apostrophes around the commas by replacing , with ','
                if (relation == '<>') {
                    dataValues = dataValues.replace(/,/g, ' AND ' + tableName + ".[" + fieldName + '] ' + relation + " ");
                } else {
                    dataValues = dataValues.replace(/,/g, ' OR ' + tableName + ".[" + fieldName + '] ' + relation + " ");
                }

                if (dataType == "Date" && (relation == "Like" || relation == "Not Like")) {
                    dataValues = dataValues.replace(/,%/g, '%,');
                    if (dataValues.slice(0, 1) == '%' && isNaN(dataValues.slice(-1)) == false) { dataValues = dataValues.substring(1, dataValues.length) + "%"; } //If the user put the wildcard in front of the date, move it to the end
                }

                if (dataType != "Numeric" || relation == '=' || relation == '<>' || relation == 'Like' || relation == 'Not Like' || dataValues == '') { dataValues = " '" + dataValues + "'"; } //Put apostrophes around the data values, unless we are using the following symbols with Numeric values: >, >=, <, <=,

                if (row == 0 || i == 1) { sqlWhere = sqlWhere + " Where ((IsNull(" + tableName + ".[" + fieldName + "],'') " + relation + " " + dataValues; }
                else { sqlWhere = sqlWhere + " " + logic + " (IsNull(" + tableName + ".[" + fieldName + "],'') " + relation + " " + dataValues; }

                sqlWhere = sqlWhere + ") ";

                logic = labelsMvList[row] && labelsMvList[row]["logic"] ? labelsMvList[row]["logic"] : "";//{ dialog.Object }.getValue('logic:' + row)
                if (logic == "") { logic = "And"; }
            }
        }
        if (sqlWhere != '') { sqlWhere = sqlWhere + ")"; }

        var hospCode = form.hospitalCode ? form.hospitalCode : "";//{ dialog.Object }.getValue('Hospital_Code')
        var analyticCases = form.analyticOnly;//{ dialog.Object }.getValue('Analytic_Cases')
        var completeCases = form.completeOnly;//{ dialog.Object }.getValue('Complete_Cases')
        var abstractCases = form.abstSuspAll ? form.abstSuspAll : "Abstract Cases Only";//{ dialog.Object }.getValue('Abstract_Cases')

        //New Functions
        var incompleteOnly = form.incompleteOnly;
        var nonReportableOnly = form.nonReportableOnly;

        var labelsSiteMvSapList = this.state.labelsSiteMvSap;
        var siteArray = labelsSiteMvSapList ? labelsSiteMvSapList : [];//{ dialog.Object }.harvestRepeatingSectionColumn('Site_Code').split(',');
        var siteList = '';

        for (var i = 0; i < siteArray.length; i++) {
            if (siteArray[i] && siteArray[i]["siteCode"] && siteArray[i]["siteCode"] != '') {
                if (siteList == '') {
                    siteList += "'" + (siteArray[i]["siteCode"] ? siteArray[i]["siteCode"] : '') + "'";
                } else {
                    siteList += ",'" + (siteArray[i]["siteCode"] ? siteArray[i]["siteCode"] : '') + "'";
                }
            }
        }

        if (siteList != '') {
            if (sqlWhere == '') {
                sqlWhere = " Where Site_Code In (" + siteList + ")";
            } else {
                sqlWhere = sqlWhere + " And Site_Code In (" + siteList + ")";
            }
        }

        //if (hospCode != '' && hospCode != 'All' && hospCode != 'ALL'){ - We could use this line of code if we wanted to allow this functionality suggested by Bonnie, but it would conflict with OI
        if (hospCode != '') {
            if (sqlWhere == '') {
                sqlWhere = " Where Hosp In ('" + hospCode.replace(/,/g, '\',\'') + "')";
            } else {
                sqlWhere = sqlWhere + " And Hosp In ('" + hospCode.replace(/,/g, '\',\'') + "')";
            }
        }

        //Fixing #PR00515 - START
        /*
        if (hospCode == '') {
            if (sqlWhere == '') {
                sqlWhere = " Where All_Flag = 'Y'"; //Check for Multi-hosp unique cases
            } else {
                sqlWhere = sqlWhere + " And All_Flag = 'Y'";
            }
        }
        * /
        var includeDuplicatesSharedPatients = form.includeDuplicatesSharedPatients;
        if (!(includeDuplicatesSharedPatients == true || includeDuplicatesSharedPatients == 'true' || includeDuplicatesSharedPatients == '1')) {
            if (sqlWhere == '') {
                sqlWhere = " Where Primary_Summary.HOSP = [dbo].Best_Class_Hosp(Primary_Summary.CID,'" + hospCode + "')";
            } else {
                sqlWhere = sqlWhere + " And Primary_Summary.HOSP = [dbo].Best_Class_Hosp(Primary_Summary.CID,'" + hospCode + "')";
            }
        }
        */
        //Fixing #PR00515 - END

        if (analyticCases == true || analyticCases == 'true' || analyticCases == '1') {
            if (sqlWhere == '') {
                sqlWhere = " Where Class In ('00','10','11','12','13','14','20','21','22')";
            } else {
                sqlWhere = sqlWhere + " And Class In ('00','10','11','12','13','14','20','21','22')";
            }
        }
        if (completeCases == true || completeCases == 'true' || completeCases == '1') {
            if (sqlWhere == '') {
                sqlWhere = " Where State_Rpt In ('T','Y','C','F')";
            } else {
                sqlWhere = sqlWhere + " And State_Rpt In ('T','Y','C','F')";
            }
        }

        if (abstractCases == 'Abstract Cases Only' || abstractCases == 'abstract') {
            if (sqlWhere == '') {
                sqlWhere = " Where Abst_Suna_Flag = 'A'";
            } else {
                sqlWhere = sqlWhere + " And Abst_Suna_Flag = 'A'";
            }
        } else if (abstractCases == 'Suspense Cases Only' || abstractCases == 'suspense') {
            if (sqlWhere == '') {
                sqlWhere = " Where Abst_Suna_Flag = 'S'";
            } else {
                sqlWhere = sqlWhere + " And Abst_Suna_Flag = 'S'";
            }

            //#PR00601: Exclude primaries with susp_abst_flag = 'P' when "ALL CASES" is chosen
        } else if (abstractCases == 'All Cases' || abstractCases == 'all') {
            if (sqlWhere == '') {
                sqlWhere = " Where Abst_Suna_Flag != 'P'";
            } else {
                sqlWhere = sqlWhere + " And Abst_Suna_Flag != 'P'";
            }
        }

        //#newfunction
        if (incompleteOnly == true || incompleteOnly == 'true' || incompleteOnly == '1') {
            if (sqlWhere == '') {
                sqlWhere = " Where State_Rpt = 'I'";
            } else {
                sqlWhere = sqlWhere + " And State_Rpt = 'I'";
            }
        }
        if (nonReportableOnly == true || nonReportableOnly == 'true' || nonReportableOnly == '1') {
            if (sqlWhere == '') {
                sqlWhere = " Where State_Rpt = 'N'";
            } else {
                sqlWhere = sqlWhere + " And State_Rpt = 'N'";
            }
        }

        //
        var includeDuplicatesSharedPatients = form.includeDuplicatesSharedPatients;
        if (includeDuplicatesSharedPatients == 'Among All Hospital') {
            if (sqlWhere == '') {
                sqlWhere = " Where All_Flag = 'Y'";
            } else {
                sqlWhere = sqlWhere + " And All_Flag = 'Y'";
            }
        }

        // #PR00499
        var dxDateCompleteOpt = form.dxDateCompleteOpt;
        var fromDate = form.fromDate ? form.fromDate : "";
        var toDate = form.toDate ? form.toDate : "";
        if (dxDateCompleteOpt == 'dxdate') {
            if (sqlWhere == '') {
                sqlWhere = " Where (IsNull(Primary_Summary.[Diagnosis_Date],'') BETWEEN '" + fromDate + "' AND '" + toDate + "') ";
            } else {
                sqlWhere = sqlWhere + " And (IsNull(Primary_Summary.[Diagnosis_Date],'') BETWEEN '" + fromDate + "' AND '" + toDate + "') ";
            }
        } else if (dxDateCompleteOpt == 'complete') {
            if (sqlWhere == '') {
                sqlWhere = " Where (IsNull(Diagnosis.[First_Cont_Date],'') BETWEEN '" + fromDate + "' AND '" + toDate + "') ";
            } else {
                sqlWhere = sqlWhere + " And (IsNull(Diagnosis.[First_Cont_Date],'') BETWEEN '" + fromDate + "' AND '" + toDate + "') ";
            }
        }

        form.textareaEditSelect = sqlWhere;

        //{ dialog.Object }.setValue('Textarea_EditSelect', sqlWhere)
        this.setState({
            Textarea_EditSelect: sqlWhere,
            dialogForm: form,
        });
    }

    //Delete_Pop_Label - call to server
    deleteLabel() {
        var dialogFormData = this.state.dialogForm;
        if (dialogFormData.populationLabel == "") {
            return false;
        }
        this.props.deleteAPopLabel(dialogFormData);
        this.setState({
            Label_RESERVED: null,
            reload: true,
        });
        return false;
    }

    //Save_To_Syslists - call to server
    Select_Cases() {
        var dialogFormData = this.state.dialogForm;
        if (dialogFormData.populationLabel == "") {
            return false;
        }

        if (dialogFormData.newSubpopLabel && dialogFormData.newSubpopLabel != "") {
            this.Set_Label_ID();
        }

        // first run this function
        this.Build_SQL_Where();

        let labelsSiteMvSap = this.state.labelsSiteMvSap;
        let labelsMv = this.state.labelsMv;

        dialogFormData.labelsSiteMvSap = labelsSiteMvSap;
        dialogFormData.labelsMv = labelsMv;

        if (!dialogFormData.fromDate || dialogFormData.fromDate == "") {
            dialogFormData.fromDate = null;
        }
        if (!dialogFormData.toDate || dialogFormData.toDate == "") {
            dialogFormData.toDate = null;
        }

        this.props.savePopLabelInfo(dialogFormData);
        return false;
    }

    deleteLabelFnc(e) {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.setState({ isShowConfirmDeleteLabelMsg: true });
        return false;
    }

    //on before show event
    showEvent(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        var dialogFormData = this.state.dialogForm;

        const requestDate = document.getElementById("requestDateSAP") ? document.getElementById("requestDateSAP").value : "";//dialogFormData.requestDateSAP;
        const fromDate = dialogFormData.fromDate;
        const toDate = dialogFormData.toDate;
        //

        if (dialogFormData.populationLabel == "") {
            return false;
        }

        if ((requestDate && !moment(requestDate, "MM/DD/YYYY", true).isValid())
            || (fromDate && !moment(fromDate, "MM/DD/YYYY", true).isValid())
            || (toDate && !moment(toDate, "MM/DD/YYYY", true).isValid())) {
            alert('Please input a valid date');
            if (!moment(requestDate, "MM/DD/YYYY", true).isValid() && requestDate) {
                document.getElementById("requestDateSAP") ? document.getElementById("requestDateSAP").focus() : null;
            } else if (!moment(fromDate, "MM/DD/YYYY", true).isValid() && fromDate) {
                document.getElementById("fromDate") ? document.getElementById("fromDate").focus() : null;
            } else if (!moment(toDate, "MM/DD/YYYY", true).isValid() && toDate) {
                document.getElementById("toDate") ? document.getElementById("toDate").focus() : null;
            }
            return false;
        }

        var _this = this;
        setTimeout(function () {
            _this.Select_Cases();

            let url = _this.props.clientgroup + "/GetStartTimeOnServer";
            axios.post(url, { accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (res && res.data && res.data.result) {
                        // set clock timer...
                        _this.setState({
                            showClockTimer: true,
                            startTimeFrom: res.data.result,
                            currentTime: null,
                        });
                    }
                }).catch(error => {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;
                });

            return false;
        }, 200);

        //
        var sInVal = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(sInVal);
            } else {
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
            }
        }, 750);

        return false;
    }

    Cancel_Report = (e, _this) => {
        e.preventDefault();

        _this.props.cancelSBSReport();

        setTimeout(function () {
            _this.setState({
                showClockTimer: false,
                //currentTime: null
            });
            return false;
        }, 0);

        return false;
    }

    //show Edit Selection::CONTAINER_Edit_Select
    editSelection(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        // first run this function
        this.Build_SQL_Where();

        this.setState({ isShowEditSelection: true });
        return false;
    }

    newRecord(e) {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.Get_Data("");
        this.setState({ Label_RESERVED: null });
        return false;
    }

    toggleSelectAPopView(e, pc) {
        try {
            e.preventDefault();

            if (!this.props.showToggleBtn) return false;

            var sts = e.target.innerText;
            if (pc == 'c') {
                if (sts == "-") {
                    e.target.innerText = "+";
                    e.target.setAttribute("title", "Expand");
                } else {
                    e.target.innerText = "-";
                    e.target.setAttribute("title", "Collapse");
                }
            }

            var mainDiv = (pc == 'p' ? e.target.parentNode : e.target.parentNode.parentNode.parentNode);
            var mainCls = mainDiv.getAttribute("class");
            if (pc == 'p') {
                var toggleBtn = mainDiv.querySelector("#toggleSelectAPopView");
                if (mainCls.indexOf("expander") == -1) {
                    mainDiv.setAttribute("class", mainCls.replace(/ /g, "") + " expander");
                    toggleBtn ? toggleBtn.innerText = "-" : null;
                    toggleBtn ? toggleBtn.setAttribute("title", "Collapse") : null;
                } else {
                    mainDiv.setAttribute("class", mainCls.replace("expander", ""));
                    toggleBtn ? toggleBtn.innerText = "+" : null;
                    toggleBtn ? toggleBtn.setAttribute("title", "Expand") : null;
                    sts = "-";
                }
            }

            if (mainDiv.getAttribute("class") && mainDiv.getAttribute("class").indexOf("frameInner") != -1) {
                var fi = false;
                mainDiv.childNodes.forEach((el) => {
                    if (!fi) {
                        fi = true;
                    } else {
                        let curCls = el.getAttribute("class") ? el.getAttribute("class") : " ";
                        if (sts == "-") {
                            el.setAttribute("class", curCls.replace(/ /g, "") + " hidden");
                        } else {
                            el.setAttribute("class", curCls.replace("hidden", ""));
                        }
                    }
                });
            }

        } catch (ex) { }

        return false;
    }

    javascript_void_fnc(event) {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        return false;
    }

    render() {
        var clockJs;
        {
            // Only show this popup in Select A Pop screen
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTimeFrom}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let confirmDeleteMsg;
        {
            confirmDeleteMsg = this.state.isShowConfirmDeleteMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Delete Row</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>OK to delete row?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let confirmDeleteLabelMsg;
        {
            confirmDeleteLabelMsg = this.state.isShowConfirmDeleteLabelMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Confirm Deletion</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteLabelMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Are you sure you want to Delete this Label?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteLabelMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteLabelMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let editSelectionTextarea;
        {
            editSelectionTextarea = this.state.isShowEditSelection ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Edit Selection</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideEditSelection(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <div id="CONTAINER_Edit_Select_ID">
                                    <Input type="textarea" rows="15"
                                        labelText="" labelWidth="0px" field="textareaEditSelect"
                                        value={this.state.Textarea_EditSelect ? this.state.Textarea_EditSelect : this.state.dialogForm.textareaEditSelect}
                                        width="100%" height="100%"
                                        textPadFields={true}//set this property to avoid show TextEdit popup when double click on it
                                        changed={this.handleFormChange} //focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                    />
                                </div>
                            </div>
                            <div className="emptyLine hidden"></div>
                            <div className="container center hidden">
                                <button onClick={this.hideConfirmDeleteLabelMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteLabelMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine hidden"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const popLabelComponent = this.state.dialogForm && this.state.loaded ?
            (
                <div className="container-fluid2">
                    <form onSubmit={this.savePopLabelInfo} id="selectAPopForm" className={this.props.isPopup ? "DialogForm2" : ""}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="frameInner" style={{ marginRight: "15px" }}>
                                    <div className="_header hidden" style={{ cursor: this.props.showToggleBtn ? "pointer" : "auto" }} onClick={(e) => { this.toggleSelectAPopView(e, 'p') }}>
                                        <div style={{ float: "left" }}>Population</div>
                                        <div style={{ float: "right" }}>
                                            <button id="toggleSelectAPopView" className={this.props.showToggleBtn ? "toggleSelectAPopView" : "hidden"} onClick={(e) => { this.toggleSelectAPopView(e, 'c') }} title="Collapse">-</button>
                                        </div>
                                    </div>
                                    <fieldset>
                                        <legend></legend>
                                        <div className="row">
                                            <div className={this.props.showToggleBtn ? "col-sm-3" : "hidden"}>
                                                <div className={this.props.showToggleBtn ? "row" : "hidden"}>
                                                    <div className="col-sm-12 checkboxTypeCls">
                                                        <Input type="checkbox" lineStyle="oneLine"
                                                            labelText="Create a Population Label" field="createPopulationLabel"
                                                            labelWidth={Global.LabelWidth} //labelWidth="200px"
                                                            labelStyle="lblOnCheckbox" width="50px"
                                                            value={this.state.isCheckedCreatePopulationLabel ? "1" : "0"}
                                                            checked={this.state.isCheckedCreatePopulationLabel}
                                                            clicked={this.toggleChecked}
                                                            style={{ cursor: "pointer", fontWeight: "bold" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4" _style={{ display: !this.props.showToggleBtn || !this.state.isCheckedCreatePopulationLabel ? "" : "none" }}>
                                                <Input type="LabelPopup" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="320px"
                                                    labelText="Population Label" autoFocus
                                                    field="populationLabel" lineStyle="oneLine"
                                                    value={this.state.dialogForm.populationLabel}
                                                    dataSource="Labels"
                                                    titleDialog="Population Labels" labelWidth="140px"
                                                    inValid={this.state.uAllItemFields["populationLabel"]}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["populationLabel"] : ""}
                                                    lostfocused={this.handleLostFocus}
                                                />
                                                <input type="hidden" id="populationLabelHidden" />
                                            </div>
                                            <div className="col-sm-4">
                                                <div style={{ display: (this.state.Label_RESERVED && this.state.Label_RESERVED != "" && this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" && this.state.isCheckedCreatePopulationLabel ? "" : "none") }}>
                                                    <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine" labelWidth="80px"
                                                        changed={this.handleFormChange} labelText="New Label" width="260px"
                                                        field="newSubpopLabel" value={this.state.dialogForm.newSubpopLabel}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["label"] : ""}
                                                        inValid={this.state.uAllItemFields["label"]}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row" style={{ display: this.state.isCheckedCreatePopulationLabel ? "" : "none" }}>
                                            <div className="col-sm-3">
                                                <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine" labelWidth="90px"
                                                    changed={this.handleFormChange} labelText="Requested By" width="130px"
                                                    field="requestedBySAP" value={this.state.dialogForm.requestedBySAP ? this.state.dialogForm.requestedBySAP : this.state.dialogForm.requestedBy} itemNbr=""
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["requestedBy"] : ""}
                                                    inValid={this.state.uAllItemFields["requestedBy"]}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Request Date" field="requestDateSAP"
                                                    value={this.state.dialogForm.requestDateSAP /*? this.state.dialogForm.requestDateSAP : this.state.dialogForm.requestDate*/}
                                                    maxLength="10" labelWidth="90px" width="85px"
                                                    lineStyle="oneLine" itemNbr="" labelStyle=""
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy"
                                                    inValid={this.state.uAllItemFields["requestDate"]}
                                                />
                                            </div>
                                        </div>

                                        <div className="row" style={{ display: this.state.isCheckedCreatePopulationLabel ? "" : "none" }}>
                                            <div className="col-sm-5" style={{ display: "flex" }}>
                                                <Input type="codeMultiSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Hospitals" field="hospitalCode" lostfocused={this.handleLostFocus}
                                                    value={this.state.dialogForm.hospitalCode}
                                                    dataSource="Hospital2L" titleDialog="Hospital Lookup" labelWidth="90px" width="130px"
                                                    inValid={this.state.uAllItemFields["hospitalCode"]} lineStyle="oneLine" itemNbr=""
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospitalCode"] : ""}
                                                    flag={this.state.availableHosps ? this.state.availableHosps : ""}
                                                    hospNbr={this.state.dialogForm.hospitalCode ? this.state.dialogForm.hospitalCode : this.state.availableHosps}
                                                    shownType="dropdown" isMultiSelection={true} codeLength="2"
                                                />
                                                <Input type="label" labelText="" field="hospitalCodeName" labelWidth="2px"
                                                    style={{ color: "#05004c", fontWeight: "bold" }} width="285px" lineStyle="oneLine"
                                                    value={this.state.dialogForm.hospitalCodeName} />
                                            </div>
                                        </div>
                                        <div className="row" style={{ display: this.state.isCheckedCreatePopulationLabel ? "" : "none" }}>
                                            <div className="col-sm-6">
                                                <label className={this.state.hospitalCodeMsgErr && this.state.hospitalCodeMsgErr != "" ? "hospitalCodeMsgErr shown" : "hospitalCodeMsgErr"}>{this.state.hospitalCodeMsgErr && this.state.hospitalCodeMsgErr != "" ? this.state.hospitalCodeMsgErr : ""}</label>
                                            </div>
                                        </div>

                                        <div className="row" style={{ display: this.state.isCheckedCreatePopulationLabel ? "" : "none" }}>
                                            {/*}
                                            <div className="col-sm-4 checkboxTypeCls">
                                                <Input type="checkbox" lineStyle="oneLine"
                                                    labelText="Include Duplicates of Shared Patients" field="includeDuplicatesSharedPatients"
                                                    labelWidth={Global.LabelWidth} labelWidth="200px"
                                                    labelStyle="lblOnCheckbox" width="50px"
                                                    value={this.state.isCheckedIncludeDuplicatesSharedPatients ? "true" : "false"}
                                                    checked={this.state.isCheckedIncludeDuplicatesSharedPatients}
                                                    clicked={this.toggleChecked}
                                                    style={{ cursor: "pointer", fontWeight: "bold" }}
                                                    mouseOver={e => { this.showBubbleHelp(e, true) }}
                                                    mouseOut={e => { this.showBubbleHelp(e, false) }}
                                                    title="If this is NOT selected then the class of case order that is used when
determining which shared primary should be selected for the report is:
14,13,12,11,10,22,21,20,00,40,32,31,30,37,35,33,38,43,49,34,36,41,42,99"
                                                />
                                            </div>
                                            {*/}

                                            <div className="col-sm-2">
                                                <label //style={{ width: "240px" }}
                                                    title="Includes all primaries from all hospitals" >
                                                    <input type="radio" onClick={this.selectionIncludePrimariesOpt}
                                                        checked={this.state.selectionIncludePrimariesOpt === "All" || this.state.selectionIncludePrimariesOpt === "All Primaries"}
                                                        name="includeDuplicatesSharedPatients" value="All Primaries" width="240px"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                    Include All Primaries
                                                </label>
                                            </div>
                                            <div className="col-sm-4">
                                                <label //style={{ width: "240px" }}
                                                    //title="Matches patients on primary site and sequence. Then includes only the best class of case primary in population"
                                                    title="Begins with population selection criteria, then includes only the best class of case primary in this population.">
                                                    <input type="radio" onClick={this.selectionIncludePrimariesOpt}
                                                        checked={this.state.selectionIncludePrimariesOpt === "Unique" || this.state.selectionIncludePrimariesOpt === "Unique Primaries"}
                                                        name="includeDuplicatesSharedPatients" value="Unique" width="240px"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                    Include Unique Primaries from this population criteria
                                                </label>
                                            </div>

                                            <div className="col-sm-4">
                                                <label //style={{ width: "240px" }}
                                                    title="Begins with the primary with best class of case from among all hospital’s primaries per patient, then applies the rest of the population selection criteria.">
                                                    <input type="radio" onClick={this.selectionIncludePrimariesOpt} checked={this.state.selectionIncludePrimariesOpt === "Among All Hospital"}
                                                        name="includeDuplicatesSharedPatients" value="Among All Hospital" width="240px"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                    Include Unique Primaries from among all hospital’s primaries
                                                </label>
                                            </div>
                                        </div>

                                        <div className="row" style={{ display: this.state.isCheckedCreatePopulationLabel ? "" : "none" }}>
                                            <div className="col-sm-1">
                                                <label style={{ width: "240px" }}>
                                                    <input type="radio" onClick={this.selectionDxDateCompleteOpt} checked={this.state.selectionDxDateCompleteOpt === "none"}
                                                        name="dxDateCompleteOpt" field="dxDateCompleteOpt" value="none"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                    None
                                                </label>
                                            </div>
                                            <div className="col-sm-1">
                                                <label style={{ width: "240px" }}>
                                                    <input type="radio" onClick={this.selectionDxDateCompleteOpt} checked={this.state.selectionDxDateCompleteOpt === "dxdate"}
                                                        name="dxDateCompleteOpt" field="dxDateCompleteOpt" value="dxdate"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                    Dx Date
                                                </label>
                                            </div>
                                            <div className="col-sm-2">
                                                <label style={{ width: "240px" }}>
                                                    <input type="radio" onClick={this.selectionDxDateCompleteOpt} checked={this.state.selectionDxDateCompleteOpt === "complete"}
                                                        name="dxDateCompleteOpt" field="dxDateCompleteOpt" value="complete" width="240px"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                    1st Contact Date
                                                </label>
                                            </div>
                                            <div className="col-sm-2" style={{ visibility: (this.state.selectionDxDateCompleteOpt === "none" ? "hidden" : "") }}>
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="From" field="fromDate"
                                                    value={this.state.dialogForm.fromDate}
                                                    maxLength="10" labelWidth="40px" width="85px"
                                                    lineStyle="oneLine" itemNbr="" labelStyle=""
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy"
                                                    inValid={this.state.uAllItemFields["fromDate"]}
                                                />
                                            </div>
                                            <div className="col-sm-2" style={{ visibility: (this.state.selectionDxDateCompleteOpt === "none" ? "hidden" : "") }}>
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="To" field="toDate"
                                                    value={this.state.dialogForm.toDate}
                                                    maxLength="10" labelWidth="40px" width="85px"
                                                    lineStyle="oneLine" itemNbr="" labelStyle=""
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy"
                                                    inValid={this.state.uAllItemFields["toDate"]}
                                                />
                                            </div>
                                        </div>

                                        <div className="row" style={{ display: this.state.isCheckedCreatePopulationLabel ? "" : "none" }}>
                                            <div className="col-sm-3">
                                                <div className="row">
                                                    <div className="col-sm-12">Abstract/Suspense Cases</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <label style={{ width: "240px", height: "0px" }}>
                                                            <input type="radio" onClick={this.selectionCases} checked={this.state.selectedCaseOption === "Abstract Cases Only"}
                                                                name="abstSuspAll" field="abstSuspAll" value="Abstract Cases Only"
                                                                style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                Abstract Cases Only
                                                        </label>
                                                        <br />
                                                        <label style={{ width: "240px", height: "0px" }}>
                                                            <input type="radio" onClick={this.selectionCases} checked={this.state.selectedCaseOption === "Suspense Cases Only"}
                                                                name="abstSuspAll" field="abstSuspAll" value="Suspense Cases Only" width="240px"
                                                                style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                Suspense Cases Only
                                                        </label>
                                                        <br />
                                                        <label style={{ width: "240px", height: "0px" }}>
                                                            <input type="radio" onClick={this.selectionCases} checked={this.state.selectedCaseOption === "All Cases"}
                                                                name="abstSuspAll" field="abstSuspAll" value="All Cases" width="240px"
                                                                style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                All Cases
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="row">
                                                    <div className="col-sm-12 PopLabelCheckboxHover"
                                                        onMouseOver={e => { this.showBubbleHelp(e, true) }}
                                                        onMouseOut={e => { this.showBubbleHelp(e, false) }}
                                                        title="&#8226; Analytic cases selection will include class of cases: 00,10,11,12,13,14, 20, 21, or 22
&#8226; Complete cases selection will include State Report Flags of cases with: T, Y, C, or F
&#8226; Incomplete cases selection will include State Report Flags of &#34;I&#34;
&#8226; Non-Reportable selection will only include cases with State Report Flags of &#34;N&#34;"
                                                    >Case Type</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 checkboxTypeCls">
                                                        <Input type="checkbox" lineStyle="oneLine"
                                                            labelText="Analytic Cases Only" field="analyticOnly"
                                                            labelWidth={Global.LabelWidth} //labelWidth="200px"
                                                            labelStyle="lblOnCheckbox" width="50px"
                                                            value={this.state.isCheckedAnalytic ? "true" : "false"}
                                                            checked={this.state.isCheckedAnalytic}
                                                            clicked={this.toggleChecked}
                                                            style={{ cursor: "pointer", fontWeight: "bold" }}
                                                            mouseOver={e => { this.showBubbleHelp(e, true) }}
                                                            mouseOut={e => { this.showBubbleHelp(e, false) }}
                                                            title="Analytic cases selection will include class of cases: 00,10,11,12,13,14, 20, 21, or 22"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 checkboxTypeCls">
                                                        <Input type="checkbox" lineStyle="oneLine"
                                                            labelText="Complete Cases Only" field="completeOnly"
                                                            labelWidth={Global.LabelWidth} //labelWidth="200px"
                                                            labelStyle="lblOnCheckbox" width="50px"
                                                            value={this.state.isCheckedComplete ? "true" : "false"}
                                                            checked={this.state.isCheckedComplete}
                                                            clicked={this.toggleChecked}
                                                            style={{ cursor: "pointer", fontWeight: "bold" }}
                                                            mouseOver={e => { this.showBubbleHelp(e, true) }}
                                                            mouseOut={e => { this.showBubbleHelp(e, false) }}
                                                            title="Complete cases selection will include State Report Flags of cases with: T, Y, C, or F"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 checkboxTypeCls">
                                                        <Input type="checkbox" lineStyle="oneLine"
                                                            labelText="Incomplete Cases Only" field="incompleteOnly"
                                                            labelWidth={Global.LabelWidth} //labelWidth="200px"
                                                            labelStyle="lblOnCheckbox" width="50px"
                                                            value={this.state.isCheckedInComplete ? "true" : "false"}
                                                            checked={this.state.isCheckedInComplete}
                                                            clicked={this.toggleChecked}
                                                            style={{ cursor: "pointer", fontWeight: "bold" }}
                                                            mouseOver={e => { this.showBubbleHelp(e, true) }}
                                                            mouseOut={e => { this.showBubbleHelp(e, false) }}
                                                            title="Incomplete cases selection will include State Report Flags of &#34;I&#34;"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 checkboxTypeCls">
                                                        <Input type="checkbox" lineStyle="oneLine"
                                                            labelText="Non-Reportable State Flag" field="nonReportableOnly"
                                                            labelWidth={Global.LabelWidth} //labelWidth="200px"
                                                            labelStyle="lblOnCheckbox" width="50px"
                                                            value={this.state.isCheckedNonReportable ? "true" : "false"}
                                                            checked={this.state.isCheckedNonReportable}
                                                            clicked={this.toggleChecked}
                                                            style={{ cursor: "pointer", fontWeight: "bold" }}
                                                            mouseOver={e => { this.showBubbleHelp(e, true) }}
                                                            mouseOut={e => { this.showBubbleHelp(e, false) }}
                                                            title="Non-Reportable selection will only include cases with State Report Flags of &#34;N&#34;"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row" style={{ display: this.state.isCheckedCreatePopulationLabel ? "" : "none" }}>
                                            <div className="col-sm-6">
                                                <div style={{ display: "flex" }}>
                                                    <div className="frameInner">
                                                        <div className="header">Site Codes</div>
                                                        <div>
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: "18px" }}>&nbsp;</th>
                                                                        <th style={{ width: "80px" }}>Site</th>
                                                                        <th style={{ width: "300px" }}>Site Description</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody></tbody>
                                                            </table>
                                                        </div>
                                                        <div id="labelsSiteMvSapListArea" className="labelsSiteMvSapListArea">
                                                            <div className="labelsSiteMvSapChildrenArea innerBody" style={{ height: "93px", overflow: "auto" }}>
                                                                <table>
                                                                    <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                        <tr>
                                                                            <th style={{ width: "18px" }}>&nbsp;</th>
                                                                            <th style={{ width: "80px" }}>Site</th>
                                                                            <th style={{ width: "300px" }}>Site Description</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.labelsSiteMvSapItemFields && this.state.labelsSiteMvSap.length == this.state.labelsSiteMvSapItemFields.length
                                                                            && this.state.labelsSiteMvSap !== null && this.state.labelsSiteMvSap.length > 0
                                                                            ? this.state.labelsSiteMvSap.map((mv, index) => (
                                                                                <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionLabelsSiteMvSapRow(e, index) }} className={this.state.selectedLabelsSiteMvSapRowIndex === index ? "selectedRowCls" : ""}>
                                                                                    <td style={{ width: "18px" }}>
                                                                                        {index + 1}
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedLabelsSiteMvSapRowIndex") }}
                                                                                            changed={(e) => { this.handleLabelsSiteMvSapGridChange(e, index) }}
                                                                                            labelText="" field="siteCode" value={mv.siteCode} lineStyle="oneLine"
                                                                                            dataSource="Site_Codes" titleDialog="Sites Code" setNameAlias="code+name" fieldExt2="siteDesc"
                                                                                            width="85px" labelWidth="0px"
                                                                                            inValid={this.state.labelsSiteMvSapItemFields[index]["siteCode"]}
                                                                                            maxLength={this.state.labelsSiteMvSapMaxlengthInput ? this.state.labelsSiteMvSapMaxlengthInput["siteCode"] : ""}
                                                                                            lostfocused={(e) => { this.handleLabelsSiteMvSapLostfocus(e, index) }}
                                                                                            keyId={"siteCode" + index} focusingField={this.state.focusingField}
                                                                                            rowIndex={index} shownType="dropdown"
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedLabelsSiteMvSapRowIndex") }}
                                                                                            changed={(e) => { this.handleLabelsSiteMvSapGridChange(e, index) }}
                                                                                            labelText="" field="siteDesc" value={mv.siteDesc} lineStyle="oneLine"
                                                                                            dataSource="Site_Codes" titleDialog="Sites Description" setNameAlias="name+code" fieldExt2="siteCode"
                                                                                            width="280px" labelWidth="0px"
                                                                                            inValid={this.state.labelsSiteMvSapItemFields[index]["siteDesc"]}
                                                                                            maxLength={this.state.labelsSiteMvSapMaxlengthInput ? this.state.labelsSiteMvSapMaxlengthInput["siteDesc"] : ""}
                                                                                            lostfocused={(e) => { this.handleLabelsSiteMvSapLostfocus(e, index) }}
                                                                                            keyId={"siteDesc" + index} focusingField={this.state.focusingField}
                                                                                            rowIndex={index} shownType="dropdown"
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            )) : <tr><td colSpan="3">No records available</td></tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="contactBtnArea right">
                                                                <button onClick={this.addNewLabelsSiteMvSap}>Add</button>
                                                                <button id="btnDeleteSelectedLabelsSiteMvSap" onClick={this.deleteSelectedLabelsSiteMvSap}>Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6"></div>
                                        </div>

                                        <div className="emptyLine" style={{ display: this.state.isCheckedCreatePopulationLabel ? "" : "none" }}></div>

                                        <div className="row" style={{ display: this.state.isCheckedCreatePopulationLabel ? "" : "none" }}>
                                            <div className="col-sm-10">
                                                <div style={{ display: "flex" }}>
                                                    <div className="frameInner" style={{ width: "790px" }}>
                                                        <div className="header">Selection Criteria</div>
                                                        <div>
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: "18px" }}>&nbsp;</th>
                                                                        <th style={{ width: "100px" }}>Item Nbr</th>
                                                                        <th style={{ width: "auto" }}>Item Description</th>
                                                                        <th style={{ width: "80px" }}>Relation</th>
                                                                        <th style={{ width: "120px" }}>Data Values</th>
                                                                        <th style={{ width: "100px" }}>Logic</th>
                                                                        <th style={{ width: "70px" }}>&nbsp;</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody></tbody>
                                                            </table>
                                                        </div>
                                                        <div id="labelsMvListArea" className="labelsMvListArea">
                                                            <div className="labelsMvChildrenArea innerBody" style={{ height: "93px", overflow: "auto" }}>
                                                                <table>
                                                                    <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                        <tr>
                                                                            <th style={{ width: "18px" }}>&nbsp;</th>
                                                                            <th style={{ width: "100px" }}>Item Nbr</th>
                                                                            <th style={{ width: "auto" }}>Item Description</th>
                                                                            <th style={{ width: "80px" }}>Relation</th>
                                                                            <th style={{ width: "120px" }}>Data Values</th>
                                                                            <th style={{ width: "100px" }}>Logic</th>
                                                                            <th style={{ width: "50px" }}>&nbsp;</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.labelsMvItemFields
                                                                            && this.state.labelsMv !== null && this.state.labelsMv.length > 0
                                                                            ? this.state.labelsMv.map((mv, index) => (
                                                                                <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionLabelsMvRow(e, index) }} className={this.state.selectedLabelsMvRowIndex === index ? "selectedRowCls" : ""}>
                                                                                    <td style={{ width: "18px" }}>
                                                                                        {index + 1}
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedLabelsMvRowIndex") }}
                                                                                            changed={(e) => { this.handleLabelsMvGridChange(e, index) }}
                                                                                            labelText="" field="globalItemNumberStr"//Item_Desc<Item_Desc,Field_Name<Column_Name,File_Name<File_Name,Data_Type<Data_Type
                                                                                            value={mv.globalItemNumberStr} lineStyle="oneLine"
                                                                                            dataSource="Global_Dir" titleDialog="Item Numbers"//filter: Column_Name <> "" | Display: Item_Nbr,Item_Desc
                                                                                            width="80px" labelWidth="0px" setNameAlias="code+name" fieldExt2="itemDescription"
                                                                                            inValid={this.state.labelsMvItemFields[index]["globalItemNumberStr"]}
                                                                                            maxLength={this.state.labelsMvMaxlengthInput ? this.state.labelsMvMaxlengthInput["globalItemNumber"] : ""}
                                                                                            lostfocused={(e) => { this.handleLabelsMvLostfocus(e, index) }}//Fill_Dictionary_Name
                                                                                            keyId={"globalItemNumberStr" + index}
                                                                                            rowIndex={index} shownType="dropdown"
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedLabelsMvRowIndex") }}
                                                                                            changed={(e) => { this.handleLabelsMvGridChange(e, index) }}
                                                                                            labelText="" field="itemDescription"//Item_Nbr<Item_Nbr,Field_Name<Column_Name,File_Name<File_Name,Data_Type<Data_Type
                                                                                            value={mv.itemDescription} lineStyle="oneLine"
                                                                                            dataSource="Global_Dir" titleDialog="Item Descriptions"//filter: Column_Name <> "" | Item_Desc (C)|Contains any of|Item_Desc
                                                                                            width="99%" labelWidth="0px" setNameAlias="name+code" fieldExt2="globalItemNumberStr"
                                                                                            inValid={this.state.labelsMvItemFields[index]["itemDescription"]}
                                                                                            maxLength={this.state.labelsMvMaxlengthInput ? this.state.labelsMvMaxlengthInput["itemDescription"] : ""}
                                                                                            lostfocused={(e) => { this.handleLabelsMvLostfocus(e, index) }}
                                                                                            keyId={"itemDescription" + index}
                                                                                            rowIndex={index} shownType="dropdown"
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedLabelsMvRowIndex") }}
                                                                                            changed={(e) => { this.handleLabelsMvGridChange(e, index) }}
                                                                                            labelText="" field="relation"//<Code
                                                                                            value={mv.relation} lineStyle="oneLine" aliasNo="2"
                                                                                            dataSource="Lookup_Mv" titleDialog="Relations"//filter: Item_Nbr = 2 | Display: Code,Code_Desc
                                                                                            width="60px" labelWidth="0px"
                                                                                            inValid={this.state.labelsMvItemFields[index]["relation"]}
                                                                                            maxLength={this.state.labelsMvMaxlengthInput ? this.state.labelsMvMaxlengthInput["relation"] : ""}
                                                                                            lostfocused={(e) => { this.handleLabelsMvLostfocus(e, index) }}
                                                                                            keyId={"relation" + index}
                                                                                            rowIndex={index} shownType="dropdown"
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedLabelsMvRowIndex") }}
                                                                                            changed={(e) => { this.handleLabelsMvGridChange(e, index) }}
                                                                                            labelText="" field="dataValues"//<Code
                                                                                            value={mv.dataValues} lineStyle="oneLine"
                                                                                            dataSource="Global_Dir_Mv" titleDialog="Data Values"//dynamic filter: Item_Nbr (N)|Equals|Item_Nbr | Display: Code,Code_Desc
                                                                                            aliasNo={mv.globalItemNumberStr} flag={mv.globalItemNumberStr}
                                                                                            width="100px" labelWidth="0px"
                                                                                            inValid={this.state.labelsMvItemFields[index]["dataValues"]}
                                                                                            maxLength={this.state.labelsMvMaxlengthInput ? this.state.labelsMvMaxlengthInput["dataValues"] : ""}
                                                                                            lostfocused={(e) => { this.handleLabelsMvLostfocus(e, index) }}
                                                                                            keyId={"dataValues" + index}
                                                                                            rowIndex={index} shownType="dropdown" isMultiSelection={true} concatAllValues={true}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedLabelsMvRowIndex") }}
                                                                                            changed={(e) => { this.handleLabelsMvGridChange(e, index) }}
                                                                                            labelText="" field="logic"//<Code
                                                                                            value={mv.logic} lineStyle="oneLine" aliasNo="1"
                                                                                            dataSource="Lookup_Mv" titleDialog="Logics"//filter: Item_Nbr = 1 | Display: Code
                                                                                            width="80px" labelWidth="0px"
                                                                                            inValid={this.state.labelsMvItemFields[index]["logic"]}
                                                                                            maxLength={this.state.labelsMvMaxlengthInput ? this.state.labelsMvMaxlengthInput["logic"] : ""}
                                                                                            lostfocused={(e) => { this.handleLabelsMvLostfocus(e, index) }}
                                                                                            keyId={"logic" + index}
                                                                                            rowIndex={index} shownType="dropdown" displayingCodeOnly={true}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <button className="copyRowBtnOnGrid"
                                                                                            style={{ width: "40px", top: "0px", position: "relative", padding: "1px" }}
                                                                                            id={"copyRowBtnOnGrid" + index} name={"copyRowBtnOnGrid" + index}
                                                                                            onKeyDown={this.keydownHandler} data-index={index}
                                                                                            onClick={(event) => { this.copyRowLabelsMvGrid(event, index) }}>Copy</button>
                                                                                    </td>
                                                                                </tr>
                                                                            )) : <tr><td colSpan="7">No records available</td></tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="contactBtnArea right">
                                                                <button id="btnAddNewLatestLabelsMv" onClick={this.addNewLabelsMv}>Add</button>
                                                                <button id="btnDeleteSelectedLabelsMv" onClick={this.deleteSelectedLabelsMv}>Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="emptyLine" style={{ display: this.state.isCheckedCreatePopulationLabel ? "" : "none" }}></div>
                                    </fieldset>

                                    <div className={this.props.showToggleBtn ? "emptyLine hidden" : "emptyLine"}></div>

                                    <div className={this.props.showToggleBtn ? "row hidden" : "row"}>
                                        <div className="col-sm-3">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", marginLeft: "15px", paddingTop: "2px" }}
                                                labelText="Start Time" field="startTime" width="auto"
                                                value={this.state.dialogForm.startTimeSAP ? this.state.dialogForm.startTimeSAP : "-"}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", paddingTop: "2px" }}
                                                labelText="End Time" field="endTime" width="auto"
                                                value={this.state.dialogForm.endTimeSAP ? this.state.dialogForm.endTimeSAP : "-"}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", borderStyle: "none", paddingTop: "2px" }}
                                                labelText="Records Selected" field="numberOfRecords" width="auto"
                                                value={this.state.dialogForm.numberOfRecords ? this.state.dialogForm.numberOfRecords : "0"}
                                            />
                                        </div>
                                    </div>

                                    <fieldset className={this.props.showToggleBtn ? "bottomAreaButtonCls _hidden" : "bottomAreaButtonCls"}>
                                        <legend></legend>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="A5CWLayout" style={{ display: this.props.showToggleBtn ? "none" : "" }}>
                                                    <button id="BUTTON_SELECT_POPULATION"
                                                        className={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" && this.validateHosp_SelectAPop() == 'Y' ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.showEvent(e) }}
                                                        //if(Population_Label <> '' And validateHosp_SelectAPop() = 'Y',1=1,1=2)
                                                        disabled={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" && this.validateHosp_SelectAPop() == 'Y' ? false : true}
                                                    >
                                                        <img id="BUTTON_SELECT_POPULATION_ICON"
                                                            src={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" && this.validateHosp_SelectAPop() == 'Y' ? "https://crstar.ers-can.com/images/$$code.execute.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$code.execute.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Select
                                                    </button>
                                                </div>

                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_DELETE_POP"
                                                        className={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.deleteLabelFnc(e) }}
                                                        disabled={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? false : true}
                                                    >
                                                        <img id="BUTTON_DELETE_ICON"
                                                            src={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "https://crstar.ers-can.com/images/$$edit.delete.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$edit.delete.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Delete Label
                                                    </button>
                                                </div>

                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_EDIT_SELECT"
                                                        className={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.editSelection(e) }}
                                                        disabled={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? false : true}//Population_Label <> ""
                                                    >
                                                        <img id="BUTTON_EDIT_SELECT_ICON"
                                                            src={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "https://crstar.ers-can.com/images/$$control.action.selectText.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$control.action.selectText.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Edit Selection
                                                    </button>
                                                </div>

                                                <div className="A5CWLayout" style={{ width: "100px", display: this.props.showToggleBtn ? "none" : "" }}>
                                                    <button id="BUTTON_NEWRECORD"
                                                        className="ErsGrBlueButton _ScreenButton"
                                                        onClick={(e) => { this.newRecord(e) }}
                                                    >
                                                        <img id="BUTTON_NEWRECORD_ICON" src="https://crstar.ers-can.com/images/$$navigate.new.png.a5image" style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        New
                                                    </button>
                                                </div>

                                                <div className="hidden">
                                                    <button id="btnCallToParentFunc" onClick={(e) => { this.callToParent(e) }} />
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div className={this.props.showToggleBtn ? "hidden" : "emptyLine"}></div>
                        </div>
                    </form>
                    <div className={this.props.showToggleBtn ? "hidden" : "emptyLine"}></div>
                </div>
            ) : null;

        return (
            <React.Fragment>
                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {confirmDeleteMsg}
                </React.Fragment>

                <React.Fragment>
                    {confirmDeleteLabelMsg}
                </React.Fragment>

                <div>
                    {editSelectionTextarea}
                </div>
                <React.Fragment>
                    {popLabelComponent}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

PopulationLabel.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        selectedLabelPopInfo: state.patientAbstract.selectedLabelPopInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        isAddNewUser: state.patientAbstract.isAddNewUser,
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        savePopLabelInfo: (dialogForm) => dispatch(actionCreators.saveAPopLabel(dialogForm)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearAddNewUserFlag: () => dispatch(actionCreators.clearAddNewUserFlag()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        selectAPopLabel: (label) => dispatch(actionCreators.selectAPopLabel(label)),
        deleteAPopLabel: (dialogForm) => dispatch(actionCreators.deleteAPopLabel(dialogForm)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopulationLabel);