/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
/*
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { GridColumn as Column } from '@progress/kendo-react-grid';
import { filterBy, orderBy } from "@progress/kendo-data-query";
import ConfigData from "../../../store/config.json";
import * as actions from '../../../store/actions/index';
import axios from "axios";
import { formatDate } from '@telerik/kendo-intl';
*/
import { actionCreators } from "../../../store/PatientAbstract";
import * as dialogTypes from "../../AbstractManagement/maintDialogs";
import Footer from "../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
import "../SystemAdmin.css";
import "./UserMaintenance.css";
import moment from "moment";

class BtnCommandCell extends Component {
    render() {
        const uid = this.props.dataItem[this.props.userId];
        return (
            <td>
                <button onClick={this.props.clearLockedUser(uid)}>Clear Lock</button>
            </td>
        );
    }
}

class DisplayLockedUsers extends Component {
    state = {
        users: [],
        isLoading: false,
        filter: {
            logic: "",
            filters: []
        },
        filterOperators: {
            'text': [
                { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
                { text: 'grid.filterEndsWithOperator', operator: 'endswith' },
                { text: 'grid.filterContainsOperator', operator: 'contains' }
            ],
        },
        sort: [],
        total: 0,
        skip: 0,
        take: 10,
        isDialogVisible: false,
        isChildDialogVisible: false,
        errorMsg: '',
        loaded: false,
    }

    componentDidMount() {
        if (this.props.lockedUsers && this.state.users.length == 0 && !this.state.loaded) {
            let userList = Object.values(this.props.lockedUsers);
            this.setState({
                users: userList,
                loaded: true
            });
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.lockedUsers && this.state.users.length == 0 && !this.state.loaded)
            || prevProps.lockedUsers != this.props.lockedUsers && this.props.lockedUsers != null && !this.state.loaded) {
            let userList = Object.values(this.props.lockedUsers);
            this.setState({
                users: userList,
                loaded: true
            });
        }
    }

    setChildDialog = (childDialog) => {
        this.props.setChildDialog(childDialog);
        this.props.setChildDialogVisible(true);
    }

    closeWindow = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.props.setParentWindowVisible(false);
        // @TODO: Tabs component...
        this.props.routingPage(dialogTypes.USER_LOGINS);
        this.props.addTabItems(dialogTypes.USER_LOGINS);
    }

    clearLockedUser = user => {
        this.props.clearLockedUser(user.userId);
        this.setState({
            loaded: false
        });
        return false;
    }

    render() {
        return (
            <div className="container-fluid2">
                <div className="row">
                    <div className="col-sm-12">System Time: {moment(new Date()).format("MM/DD/YYYY hh:mm:ss SS a") }</div>
                </div>
                <div className="frameInner" id="lockedUserID">
                    <div className="header">&nbsp;</div>
                    <div className="row">
                        <div className="col-sm-12">
                            <table style={{
                                tableLayout: "fixed", display: (this.state.users && this.state.users.length > 0 ? "" : "none") }}>
                                <thead>
                                    <tr>
                                        <th style={{ width: "20%" }}>User Id</th>
                                        <th style={{ width: "10%" }}>Locked Util</th>
                                        <th style={{ width: "5%" }}>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                    <tr>
                                        <td colSpan="3">&nbsp;</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className="innerBody">
                                <table>
                                    <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                        <tr>
                                            <th style={{ width: "20%" }}>User Id</th>
                                            <th style={{ width: "10%" }}>Locked Util</th>
                                            <th style={{ width: "5%" }}>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.users
                                            && this.state.users.length > 0
                                            ? this.state.users.map((usr, index) => (
                                                <tr>
                                                    <td style={{ width: "20%" }}>
                                                        {usr.email}
                                                    </td>
                                                    <td style={{ width: "10%" }}>
                                                        { moment(new Date(usr.locked)).format("MM/DD/YYYY hh:mm:ss SS a") }
                                                    </td>
                                                    <td style={{ width: "5%" }}>
                                                        <button className="clearLockBtnOnGrid" onClick={() => { this.clearLockedUser(usr) }}>Clear Lock</button>
                                                    </td>
                                                </tr>
                                            )) : <tr><td colSpan="3">There are currently 0 locked UserIds.</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="emptyLine"></div>

                <div style={{ display: "none" }}>
                    <Footer closeWindow={this.props.closeWindow}
                        formId="noForm"
                        isFormDirty={this.state.isFormDirty}
                        className="Footer2"
                        menuIdx="2"
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        clientgroup: state.auth.clientgroup,
        lockedUsers: state.patientAbstract.lockedUsers,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearLockedUser: (uId) => dispatch(actionCreators.clearLockedUser(uId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayLockedUsers);
