/* eslint-disable */
import React, { Component } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { formatDate } from '@telerik/kendo-intl';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import { connect } from "react-redux";
import * as actions from '../../../store/actions/index';
import { actionCreators } from "../../../store/PatientAbstract";
import ConfigData from '../../../store/config.json';
import axios from "axios";
import Draggable from 'react-draggable';
import * as dialogTypes from "../../AbstractManagement/maintDialogs";
import { WqCommandCell } from "./WqCommandCell";
import "./NlpWorkQueue.css";
import PatientDetail from "./PatientDetail";

class KendoGridDateCell extends React.Component {
    render() {
        const value = this.props.dataItem[this.props.field];
        return (
            <td>
                {value ? formatDate(new Date(value), "MM/dd/yyyy") : ""}
            </td>
        );
    }
}

class WrapTextCell extends React.Component {
    render() {
        const value = this.props.dataItem[this.props.field];
        return (
            <td style={{ wordBreak: "break-all" }} title={value ? value : ""}>
                {value ? value : ""}
            </td>
        );
    }
}

//
const WrapTextHeader = (props) => {
    return (
        <a className="k-link" onClick={props.onClick}>
            <span
                style={{
                    whiteSpace: "break-spaces", wordWrap: "break-word"
                }}
            >
                {props.title}
            </span>
            {props.children}
        </a>
    );
};

const updateStatusFnc = (dataItem) => {
    //alert(dataItem.id);
};

const RadioCell = (props) => (
    <WqCommandCell
        {...props}
        updateStatus={updateStatusFnc}
        cellType="radio"
    />
);

const viewDetailsFnc = (dataItem) => {
    //alert(dataItem.pid);
    //this.selectPatient(event);
}
const CommandCell = (props) => (
    <WqCommandCell
        {...props}
        viewDetails={viewDetailsFnc}
        cellType="button"
    />
);
//https://www.telerik.com/kendo-react-ui/components/knowledge-base/grid-disable-selection-for-rows-conditionally/
const CheckboxCell = (props) => (
    <WqCommandCell
        {...props}
        updateStatus={updateStatusFnc}
        cellType="checkbox"
    />
);

class NlpWorkQueue extends Component {

    state = {
        codes: [],
        filter: {
            logic: "",
            filters: []
        },
        filterOperators: {
            'text': [
                { text: 'grid.filterContainsOperator', operator: 'contains' }
            ],
        },
        pageable: {
            pageSizes: [25, 50, 100]
        },
        selectable: {
            enabled: true,
            drag: false,
            cell: false,
            mode: "multiple"
        },
        sort: [],
        total: 0,
        skip: 0,
        take: 25,

        enterKeyPressed: false,
        shiftKeyPressed: false,
        tabKeyPressed: false,
        focusedItem: null,
        filterIndexNo: 0,

        isShowConfirmDeleteLabelMsg: false,

        dataItemKey: "",
        selectedField: "",

        selectedIds: [],
        reload: false
    }

    checkAllReviewed = (e) => {
        var popup = document.getElementById("WorkQueueTable");
        if (popup && popup.querySelector("tbody")) {
            var tbody = popup.querySelector("tbody");
            var listItems = tbody.querySelectorAll(".k-master-row td:last-child input");
            var chbxItems;
            var enabled = false;
            for (var idx = 0; idx < listItems.length; idx++) {
                chbxItems = listItems[idx];
                enabled = chbxItems.getAttribute("disabled") != null ? false : true;
                if (enabled) {
                    if (!chbxItems.getAttribute("checked")) {
                        chbxItems.click();
                    }
                    chbxItems.setAttribute("checked", "true");
                } else {
                    chbxItems.removeAttribute("checked");
                }
            }
        }

        this.setState(prevState => {
            const newItems = [...prevState.codes];
            for (var rowIdx = 0; rowIdx < newItems.length; rowIdx++) {
                if (newItems[rowIdx]["nlpStatus"] || newItems[rowIdx]["userAssessmentStatus"]) {
                    newItems[rowIdx]["selected"] = "true";
                }
            }
            return { codes: newItems };
        });

        this.state.codes.forEach(item => item.nlpStatus || item.userAssessmentStatus ? item.selected = "true" : null);
        this.forceUpdate();

        return false;
    }

    async approveReviewed(e) {
        e.preventDefault();
        this.props.showOrHideLoading(true);

        var queueList = this.state.codes;

        this.props.setPageLoaded(true);
        let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/ApproveReviewedNlp";
        var params = { workQueueList: queueList, accessToken: localStorage.getItem('token') };

        let data = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        var newAccessToken = data && data.data ? data.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        var res = data && data.data && data.data.error;
        if (!res) {
            document.getElementById("redrawGrid").click();
        }

        return false;
    }

    redrawGrid = (e) => {
        e.preventDefault();
        this.props.showOrHideLoading(true);
        this.getDataLookupView();
        return false;
    }

    //
    async updateSelectedReview(e) {
        var wqid = e.target.getAttribute("data-wqid");
        var selected = e.target.getAttribute("data-selected");
        var rowIdx = e.target.getAttribute("data-rowIdx");

        if (wqid && selected) {
            this.setState(prevState => {
                const newItems = [...prevState.codes];
                newItems[rowIdx]["selected"] = selected;

                return { codes: newItems };
            });
        }
    }

    async updateAssessmentStatus(e) {
        var wqid = e.target.getAttribute("data-wqid");
        var status = e.target.getAttribute("data-status");
        var rowIdx = e.target.getAttribute("data-rowIdx");

        if (wqid && status) {
            this.setState(prevState => {
                const newItems = [...prevState.codes];
                newItems[rowIdx]["userAssessmentStatus"] = status;

                return { codes: newItems };
            });
        }
        return false;
    }

    updateGrid(e, obj) {
        obj.forceUpdate();
    }

    componentDidMount() {
        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);

        // Add all tabs in maintDialogs.js
        dialogTypes.nlpWorkQueueTabsDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog);
            this.props.setEnabledStatus(dialog);
        });

        this.setState({ isLoading: true });

        //this.getDataLookupView();
        document.getElementById("redrawGrid") ? document.getElementById("redrawGrid").click() : null;

        return false;
    }

    async getDataLookupView() {
        this.props.setPageLoaded(true);

        const skipNo = this.state.skip;
        const takeNo = this.state.take;
        //
        let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/LoadWorkQueueData";
        var access_token = localStorage.getItem('token');
        var params = {
            skip: skipNo,
            take: takeNo,
            accessToken: access_token
        };

        var _filter = {};
        if (this.state.filter !== null) {
            _filter = this.state.filter;
            this.state.filter.filters.map((f, i) => {
                params[f["field"]] = ("%" + f["value"] + "%");
            });
        }

        // adding sort options
        var _sorter = [];
        if (this.state.sort !== null && this.state.sort.length > 0) {
            _sorter = this.state.sort;
            this.state.sort.map((s, i) => {
                params["sortby"] = s["field"];
                params["orderby"] = s["dir"];
            });
        }

        let data = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        var newAccessToken = data && data.data ? data.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        var pItems = data && data.data && data.data.workQueueData ? data.data.workQueueData : [];
        Object.keys(pItems).map((idx) => {
            let item = pItems[idx];
            Object.keys(item).map((t) => {
                let dataVal = item[t];
                if (t === "socSecNbr" && dataVal !== null && dataVal !== "") {
                    dataVal = dataVal.replace(/-/g, '');
                    if (dataVal.length >= 4 && dataVal.length <= 5) {
                        dataVal = dataVal.substr(0, 3) + "-" + dataVal.substr(3, 2);
                    } else {
                        dataVal = dataVal.substr(0, 3) + "-" + dataVal.substr(3, 2) + "-" + dataVal.substr(5);
                    }
                    item[t] = dataVal;
                }
            });
        });

        this.setState({
            codes: pItems,
            total: data && data.data ? data.data.total : 0,
            filter: _filter,
            sort: _sorter,
            enterKeyPressed: false,
        });

        this.props.setPageLoaded(true);
        this.props.showOrHideLoading(false);
        this.forceUpdate();

        //
        var popup = document.getElementById("WorkQueueTable");

        if (popup && popup.querySelector("tbody")) {
            var tbody = popup.querySelector("tbody");
            var listItems = tbody.querySelectorAll(".k-master-row td:last-child input");
            var chbxItems;
            var enabled = false;
            for (var idx = 0; idx < listItems.length; idx++) {
                chbxItems = listItems[idx];
                enabled = chbxItems.getAttribute("disabled") != null ? false : true;
                if (enabled) {
                    if (chbxItems.getAttribute("checked")) {
                        chbxItems.click();
                    }
                    chbxItems.removeAttribute("checked");
                } else {
                    if (chbxItems.getAttribute("checked")) {
                        chbxItems.click();
                    }
                    chbxItems.removeAttribute("checked");
                }
            }
        }

        var cl = this;
        setTimeout(function () {
            //
            if (popup && popup.querySelectorAll(".k-master-row")) {
                var rowItems = popup.querySelectorAll(".k-master-row");
                for (var idx = 0; idx < rowItems.length; idx++) {
                    let rowItm = rowItems[idx];
                    rowItm.addEventListener('click', function (e) {
                        var pid = rowItm.getAttribute("data-pid") ? rowItm.getAttribute("data-pid") : 0;
                        var cid = rowItm.getAttribute("data-cid") ? rowItm.getAttribute("data-cid") : 0;
                        if (!pid || pid == 0) return false;
                        cl.props.selectPatient(pid, false);
                        cl.setState({ isDialogVisible: true, selectedPid: pid, selectedCid: cid });
                    });

                    if (rowItm.querySelector("td:nth-child(11)") && rowItm.querySelector("td:nth-child(11)").getAttribute("dataitem")
                        && rowItm.querySelector("td:nth-child(11)").getAttribute("dataitem").indexOf("reportable") != -1) {
                        //
                    } else {
                        rowItm.querySelector("td:last-child input").setAttribute("disabled", true);
                        //
                    }
                }
            }

            return false;
        }, 0);
        return false;
    }

    componentDidUpdate(prevProps) {
        if (!this.props.pageLoaded || this.state.codes == null || (!this.props.pageLoaded && this.state.sort !== null && this.state.sort.length > 0)) {
            this.props.showOrHideLoading(true);
            this.props.setPageLoaded(true);

            //this.getDataLookupView();
            setTimeout(function () {
                document.getElementById("redrawGrid") ? document.getElementById("redrawGrid").click() : null;
            }, 1000);
            return false;
        }
    }

    pageChange = (event, isFilter) => {
        if (isFilter === "sorting") {
            let _sorter = event.sort;

            this.setState({
                sort: _sorter,
                enterKeyPressed: false
            });
            this.props.setPageLoaded(false);
            return false;
        }
        //

        let _filter = {
            logic: "",//and
            filters: []
        };
        let enterKeyPressed = this.state.enterKeyPressed;
        if (event && event.filter) {
            _filter = event.filter;

            let allowSearch = event.nativeEvent.target.value != "";

            this.setState({
                filter: _filter,
                enterKeyPressed: allowSearch
            });
        } else {
            // click a page number on paging area
            _filter = event.target.props.filter && !isFilter ? event.target.props.filter : {
                logic: "",//and
                filters: []
            };

            let allowSearch = event.nativeEvent === undefined ? true : event.nativeEvent.target.value != "";
            this.setState({
                skip: event.page ? event.page.skip : this.state.skip,
                take: event.page ? event.page.take : this.state.take,
                filter: _filter,
                enterKeyPressed: allowSearch
            });
            enterKeyPressed = allowSearch;
        }
        this.props.setPageLoaded(!enterKeyPressed);
        return false;
    }

    // To get more reference at https://www.telerik.com/kendo-react-ui/components/grid/accessibility/
    handleNavigationAction = (event) => {
        const { focusElement } = event;
        const data = this.state.codes;

        const rowId = focusElement.parentElement.getAttribute("data-id");

        if (rowId) {
            const newData = data.map((item) => ({
                ...item,
                selected: String(item.id) === rowId,
            }));
            this.setState({ codes: newData });
        }
    };

    rowRender = (trElement, rowProps) => {
        const trProps = {
            ...trElement.props,
            ["data-id"]: rowProps.dataItem.id,
        };
        return React.cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    };

    handleKeyDownAction = (props) => {
        var event = props.nativeEvent;
        if (event.key == "Enter") {
            event.target.click();
        }
    }

    closeCode = (e) => {
        try {
            e ? e.preventDefault() : "";
        } catch (e) { }
        this.props.closeCode();
        return false;
    }

    closeDetail = (e) => {
        try {
            e ? e.preventDefault() : "";
        } catch (e) { }
        this.setState({ isDialogVisible: false });
        return false;
    }

    resetFilterInputFnc = (e) => {
        try {
            e ? e.preventDefault() : "";
        } catch (e) { }

        var _value = e.target.value;

        this.setState({
            searchByFilterInput: _value,
        });

        return false;
    }

    // View Detail Patient popup
    selectPatient = (event) => {
        this.props.selectPatient(event.dataItem.pid, false);
        this.setState({ isDialogVisible: true, selectedPid: event.dataItem.pid, selectedCid: event.dataItem.cid });
    }

    // and https://stackblitz.com/edit/react-xmw9xs?file=app/main.js
    selectionChange = (event) => {
        event.dataItem.selected = !event.dataItem.selected;
        this.forceUpdate();
    }

    headerSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        this.state.codes.forEach(item => item.nlpStatus || item.userAssessmentStatus ? item.selected = checked : null);
        this.forceUpdate();
    }

    render() {
        let loading = (
            this.props.isPatientSaving ? <div className="loader"></div> : ""
        );

        let viewDetails;
        {
            viewDetails = this.state.isDialogVisible ?
                <div className="CRStar_Window_Popup">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableLargestWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Patient Details</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.closeDetail(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>

                            {/*<PatientMaintenance readonly={true} />*/}
                            <PatientDetail pid={this.state.selectedPid} cid={this.state.selectedCid} />

                            <div className="container center">
                                <button onClick={this.closeDetail} className="validationErr_btnOk_Cls" autoFocus>&nbsp;Exit&nbsp;</button>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const dialogForm = this.state.filter ?
            (
                <div>
                    {loading}
                    <div className="CRStar_Window" id="WorkQueueTable">
                        <div className="col-sm-12">
                            <Grid
                                style={{ height: "650px", _width: "850px" }}
                                data={this.state.codes != undefined && this.state.codes.length !== 0 ? this.state.codes : null}
                                pageable={this.state.pageable}
                                onPageChange={e => { this.pageChange(e, false) }}
                                scrollable="scrollable"
                                sortable
                                sort={this.state.sort}
                                onSortChange={e => { this.pageChange(e, "sorting") }}
                                skip={this.state.skip}
                                take={this.state.take}
                                total={this.state.total}
                                //selectedField="selected"
                                rowRender={this.rowRender}
                                navigatable={true}
                                onNavigationAction={this.handleNavigationAction}
                                onKeyDown={this.handleKeyDownAction}

                                //onSelectionChange={this.selectionChange}
                                //onHeaderSelectionChange={this.headerSelectionChange}
                            >
                                <GridColumn field="socSecNbr" filterable={false} filter="text" title="SSN" width="85px" />
                                <GridColumn field="medRecNbr" filterable={false} filter="text" title="MRN" width="70px" />
                                <GridColumn field="lastName" filterable={false} filter="text" title="Last Name" width="70px" />
                                <GridColumn field="firstName" filterable={false} filter="text" title="First Name" width="110px" />
                                <GridColumn field="middleName" filterable={false} title="Middle Name" width="50px"
                                    //headerAttributes={{ rowspan: "2", class: "breakWord20" }}
                                    headerCell={WrapTextHeader} cell={WrapTextCell}
                                />
                                <Column field="dob" filterable={false} filter="text" title="DOB" width="78px" cell={KendoGridDateCell} format="{MM/dd/yyyy}" />

                                {/*
                                  * Update NLP Work Queue Screen to remove Suspense_text column and add Hospital_Code and Path_Nbr to lookup screen. 
                                  * Hospital_Code will come from the primary_summary table, Path_nbr with come from Diagnosis table.
                                <GridColumn field="suspenseRemarks" filterable={false} filter="text" title="Suspense Remarks" width="150px" />
                                */}
                                <GridColumn field="hospitalCode" filterable={false} filter="text" title="Hospital Code" width="55px"
                                    headerCell={WrapTextHeader} cell={WrapTextCell}
                                />
                                <GridColumn field="pathNbr" filterable={false} filter="text" title="Path Nbr" width="82px" />

                                <GridColumn field="finalDxText" filterable={false} filter="text" title="Final Diagnosis" width="264px" />
                                <GridColumn field="nlpStatus" filterable={false} filter="text" title="NLP Status" width="75px" />
                                <GridColumn field="userAssessmentStatus" filterable={false} filter="text" cell={RadioCell} title="Assessment Status" width="180px" />
                                <GridColumn field="viewPatientDetails" filterable={false} filter="text" cell={CommandCell} title=" " width="55px" />

                                <GridColumn field="selected" filterable={false} width="64px" cell={CheckboxCell} title="Reviewed" style={{ textAlign: "center", verticalAlign: "bottom" }}
                                    //headerSelectionValue={false/*this.state.codes.findIndex(dataItem => dataItem.selected === false) === -1*/}
                                />
                            </Grid>
                        </div>

                        <div className="hidden">
                            <button onClick={(e) => { this.updateGrid(e, this)}} id="updateGridHiddenBtn" />
                            <button onClick={(e) => { this.updateSelectedReview(e) }} id="updateSelectedReviewHandler" />
                            <button onClick={(e) => { this.updateAssessmentStatus(e) }} id="updateAssessmentStatusHandler" />
                        </div>

                        <div className="emptyLine"></div>
                        <div className="row bottomAreaBtn">
                            <div className="col-sm-6">
                                <button onClick={(e) => { this.redrawGrid(e) }} id="redrawGrid">Refresh</button>
                            </div>
                            <div className="col-sm-6 right">
                                <button onClick={(e) => { this.checkAllReviewed(e) }} id="btnReviewedAll">Reviewed All</button>
                                <button onClick={(e) => { this.approveReviewed(e) }} id="btnApproveReviewed">Approve Reviewed</button>
                            </div>
                        </div>

                    </div>
                </div>
            )
            : <p className="data_loading_page">Work Queue data loading...</p>;

        return (
            <React.Fragment>
                {dialogForm}

                <div>{viewDetails}</div>
            </React.Fragment>
        );
    }
}

NlpWorkQueue.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        clientgroup: state.auth.clientgroup,
        pageLoaded: state.patientAbstract.pageLoaded,
        childrenOpening: state.patientAbstract.childrenOpening,
        inquiringMode: state.auth.inquiringMode,
        handlerControlItem: state.patientAbstract.handlerControlItem,
        currentDialog: state.patientAbstract.currentDialog,
        childDialog: state.patientAbstract.childDialog,
        isChildDialogVisible: state.patientAbstract.isChildDialogVisible,
        isParentWindowVisible: state.patientAbstract.isParentWindowVisible,
        isAuthenticated: state.auth.token !== null,
        userId: state.auth.userId,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        workQueueData: state.patientAbstract.workQueueData,
        isPatientSaving: state.patientAbstract.isPatientSaving,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addNewItem: (number) => dispatch(actionCreators.selectHospitalRecord(number)),
        closeAddNewDialog: () => dispatch(actionCreators.closeAddNewDialog()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
        loadWorkQueueData: () => dispatch(actionCreators.loadWorkQueueData()),
        selectPatient: (patientId, isAddNew) => dispatch(actionCreators.selectPatient(patientId, isAddNew)),
        selectedPatientOnGrid: (patient) => dispatch(actionCreators.selectedPatientOnGrid(patient)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NlpWorkQueue);