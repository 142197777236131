/* eslint-disable */
import React from "react";
import "../../../assets/css/default-ocean-blue.css";

import { EditorTools, EditorToolsSettings } from "@progress/kendo-react-editor";

const formatBlockToolSettings = {
    ...EditorToolsSettings.formatBlock,
    defaultItem: {
        text: 'Paragragh',
        value: 'p',
    },
    items: [
        {
            text: 'Paragragh',
            value: 'p',
        },
        {
            text: 'Heading 1',
            value: 'h1',
            style: { fontSize: "2em", fontWeight: "bold" }
        },
        {
            text: 'Heading 2',
            value: 'h2',
            style: { fontSize: "1.5em", fontWeight: "bold" }
        },
        {
            text: 'Heading 3',
            value: 'h3',
            style: { fontSize: "1.17em", fontWeight: "bold" }
        },
        {
            text: 'Heading 4',
            value: 'h4',
            style: { fontSize: "1em", fontWeight: "bold" }
        },
        {
            text: 'Heading 5',
            value: 'h5',
            style: { fontSize: "0.83em", fontWeight: "bold" }
        },
        {
            text: 'Heading 6',
            value: 'h6',
            style: { fontSize: "0.67em", fontWeight: "bold" }
        },/*
        {
            text: 'Formatted',
            value: 'pre',
        },
        {
            text: 'Address',
            value: 'address',
        }*/
    ],
};

const CustomFormatBlock = EditorTools.createFormatBlockDropDownList(formatBlockToolSettings);//FormatBlockDropDownListSettings

const FormatBlock = (props) => <CustomFormatBlock {...props} />;

export default FormatBlock;