//
export const leftSideOptions = [
    { name: "printPatient", text: "Patient Demographics" },//Patient
    { name: "printCareTeam", text: "Care Team" },//Care_Team
    { name: "printDiagnosis", text: "Diagnosis" },
    { name: "printStaging", text: "Staging" },
    { name: "printTxSumm", text: "Treatment Summary" },//Treatment_Summary
    { name: "printSurgery", text: "Surgery" },
    { name: "printRadiation", text: "Radiation" },
    { name: "printChemo", text: "Chemo" },
    { name: "printHormone", text: "Hormone" },
    { name: "printImmuno", text: "Immunotherapy" },//Immuno
    { name: "printHema", text: "Hematapoietic" },//Hema
    { name: "printPall", text: "Palliative" },//Pall
    { name: "printOtherTx", text: "Other Treatment" },//Other_Treatment
];
//
export const rightSideOptions = [
    { name: "printOutcomes", text: "Outcomes" },
    { name: "printCaseAdmin", text: "Case Administration" },//Case_Admin
    { name: "printCpm", text: "Cancer Program Info" },//CPI
    { name: "printBpm", text: "Breast Program Info" },//BPI
    { name: "printRpm", text: "Rectal Program Info" },//RPI
    { name: "printUserDefined", text: "User Defined" },//User_Defined
    { name: "printClinical", text: "Clinical" },
    { name: "printResearch", text: "Research" },
    { name: "printStateSpecific", text: "State Specific" },//State_Specific
    { name: "printRetired", text: "Retired" },
];
