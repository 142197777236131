/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Footer from "../Shared/Footer";
import Input from "../../../UI/Input";
import "../PatientMaintenance/PatientMaintenance.css";
import "./ResearchMaintenance.css";
import "../DiagnosisMaintenance/DiagnosisMaintenance.css";
import moment from "moment";
import TextForm from "../DiagnosisMaintenance/TextForm";
import Help from "../../../UI/Help";
import axios from "../../../../axios-instance";
import Draggable from 'react-draggable';
import * as TextPopup from "../TitleOfTextPopup";

class ResearchMaintenance extends Component {

    // At Research page, before initial page -> run this store procedure to get data:
    // Exec Abstract_Select 'value of CID'

    state = {
        activeTab: "1",
        sAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'researchForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty researchForm back to what it was on load
        initialResearchForm: null,
        researchForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        mode: "1",//only for test
        fieldInFocus: "genStage",
        fieldItemNbr: "",
        focusingField: null,
        isHelpVisible: false,
        isDialogVisible: true,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,
    }

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: true });
    }

    closeWarningDialog = () => {
        this.setState({ isDialogVisible: false });
        return false;
    }

    showTextDialog = () => {
        this.setState({ isShowTextVisible: true });
    }

    closeTextDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowTextVisible: false });
        this.props.closeTextDialog(false);

        return false;
    }

    componentDidMount() {
        if (!this.state.researchForm && this.props.selectedResearchRecord) {
            this.setFormData();
        }
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.researchForm && this.props.selectedResearchRecord)
            || (prevProps.selectedResearchRecord && prevProps.selectedResearchRecord !== this.props.selectedResearchRecord)
            || (this.props.initialAbstractPages && this.props.selectedResearchRecord)
        ) {
            this.setFormData();
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.researchForm && this.state.sAllItemFields
        ) {
            console.log("[Research] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    researchForm: { ...this.state.researchForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    researchForm: { ...this.state.researchForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "Research", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res.data ? res.data.result : null
        });
    }

    // Declare this function is only temporality
    ERSCs_get_schema_number = (/*site, hist, discriminator*/) => {
        return "15";
    }

    fnEnableAllSsf = (event) => {
        this.setState({ researchForm: { ...this.state.researchForm, [event.target.name]: "1" } });
    }

    setFormData = () => {
        this.setState({
            researchForm: { ...this.props.selectedResearchRecord },
            isFormDirty: this.props.isFormDirty
        });

        if (!this.state.sAllItemFields && this.props.selectedResearchRecord) {
            let fields = {};
            Object.keys(this.props.selectedResearchRecord).map((t) => {
                fields[t] = "";
            });

            this.setState({ sAllItemFields: fields, pageMaxlengthInput: fields });
        };

        this.props.setInitialAbstractPages(false);

        return false;
    }

    toggleTab = (tab) => {
        this.setState({ activeTab: tab });
    }

    // Add clear and check validate of date fields function -- START
    clearInvalidOfDateFields = (formItems, field, onGrid, index) => {
        let tYear = field + "Year";
        let tMonth = field + "Month";
        let tDay = field + "Day";
        if (onGrid && onGrid === "primaryItemList") {
            this.setState(prevState => {
                let newItems = [...prevState.primaryItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { primaryItemFields: newItems };
            });
        } else {
            this.setState({
                sAllItemFields: {
                    ...this.state.sAllItemFields,
                    [tYear]: "",
                    [tMonth]: "",
                    [tDay]: "",
                }
            });
        }
        formItems[tYear] = "";
        formItems[tMonth] = "";
        formItems[tDay] = "";
    }

    checkValidateOfDateFields = (pFormData, field, onGrid, index) => {
        let isValidDateInput = true;

        var tYear = field + "Year";
        var tMonth = field + "Month";
        var tDay = field + "Day";

        // Check valid of date in February
        var year = pFormData[tYear] ? pFormData[tYear] : "";
        var month = pFormData[tMonth] ? pFormData[tMonth] : "";
        var day = pFormData[tDay] ? pFormData[tDay] : "";

        pFormData[field] = new Date(year + "-" + month + "-" + day);

        if (year !== "" && month !== "" && day !== "") {
            var _day, A = (month + '/' + day + '/' + year).match(/[1-9][\d]*/g);
            try {
                A[0] -= 1;
                _day = new Date(+A[2], A[0], +A[1]);
                if ((_day.getMonth() == A[0] && _day.getDate() == A[1])
                                /*|| year == '9999'*/ || month == '99' || day == '99') {
                } else {
                    isValidDateInput = false;

                    if (onGrid && onGrid === "primaryList") {
                        this.setState(prevState => {
                            const newItems = [...prevState.primaryItemFields];
                            newItems[index][tYear] = "IN_VALID";
                            newItems[index][tMonth] = "IN_VALID";
                            newItems[index][tDay] = "IN_VALID";
                            return { primaryItemFields: newItems };
                        });
                    } else {
                        this.setState({
                            sAllItemFields: {
                                ...this.state.sAllItemFields,
                                [tYear]: "IN_VALID",
                                [tMonth]: "IN_VALID",
                                [tDay]: "IN_VALID",
                            }
                        });
                    }
                }
            } catch (er) {
                isValidDateInput = false;
            }
        }

        return isValidDateInput;
    }
    // -- END

    saveResearch = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        let formItems = this.state.sAllItemFields;
        Object.keys(formItems).map((t) => {
            // clear IN_VALID flag for all date fields
            if (t === "dateSiteMapping" || t === "DateSiteMapping") {
                this.clearInvalidOfDateFields(formItems, t);
            }

            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let researchFormData = this.state.researchForm;
            researchFormData.cid = this.props.selectedMedicalRecord.cid;

            //#20210125 - START
            // append date fields from m/d/y
            let isValidDateInput = true;
            Object.keys(researchFormData).map((t, k) => {
                if (t === "dateSiteMapping" || t === "DateSiteMapping") {
                    isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(researchFormData, t);
                }
            });
            //#20210125 - END

            if (!isValidDateInput && !allowSaveAnyway) return false;

            this.props.saveResearch(researchFormData);

            return false;
        }
    }

    saveTextDialog = (event) => {
        event.preventDefault();

        // TODO Form validation
        if (true) {
            let textDialogData = this.state.textDialogForm;
            this.props.saveTextDialog(textDialogData);
        }
    }

    extraAction = (index) => {
        switch (index) {
            case 1:
                alert(1);
                break;
            case 2:
                alert(2);
                break;
            default:
                break;
        }
    }

    resetResearch = () => {
        this.setState({
            researchForm: { ...this.state.initialresearchForm },
            isFormDirty: false,
        });
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({
            fieldInFocus: event.target.name,
            fieldItemNbr: event.target.getAttribute("data-help-id"),
            focusingField: event.target
        });
    }

    helpFocusingField = (field) => {
        this.setState({ fieldInFocus: field });
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = () => {
        this.setState({ isHelpVisible: false });
        return false;
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, -1);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let value = event.target.value;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState({ researchForm: { ...this.state.researchForm, [event.target.name]: value } });
                } else {
                    this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "IN_VALID" } });
                }
            } else {
                // clear all error style
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "" } });
            }
            //#20210125 - END

        } else {
            this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "" } });
        }
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ researchForm: { ...this.state.researchForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({ researchForm: { ...this.state.researchForm, [event.target.name]: event.target.value }, isFormDirty: true });
        }
    }

    render() {
        const ResearchMaintenance = this.state.researchForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">
                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} focusingField={this.state.focusingField} fieldItemNbr={this.state.fieldItemNbr} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.saveResearch} id="researchForm" className="DialogForm TabParentPage">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq Prim: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo">
                                    <button type="button" onClick={this.getHelp}><img src="https://crstar.ers-can.com/images/$sys_question.png.a5image"/></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {this.props.selectedPatient.dob != null && this.props.selectedPatient.dob !== "" ? moment(new Date(this.props.selectedPatient.dob)).format("MM/DD/YYYY") : ""}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {this.props.selectedPrimarySummary.diagnosisDate != null && this.props.selectedPrimarySummary.diagnosisDate !== "" ? moment(new Date(this.props.selectedPrimarySummary.diagnosisDate)).format("MM/DD/YYYY") : ""}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>

                        <div className="emptyLine"></div>

                        <div>
                            <div className="row">
                                <div className="col-md-3 extendRightDiv researchCol1">
                                    <div className="frameInner">
                                        <div className="header">&nbsp;</div>

                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="History and Physical" field="historyAndPhysical" lineStyle="oneLine"
                                            value={this.state.researchForm.historyAndPhysical ? this.state.researchForm.historyAndPhysical : this.state.researchForm.historyAndPhysical}
                                            dataSource="Global_Dir_Mv" aliasNo="3051" titleDialog="History and Physical" labelWidth="140px" width="35px"
                                            inValid={this.state.sAllItemFields["historyAndPhysical"]} itemNbr="3051"
                                            lostfocused={this.handleLostFocus} autoFocus
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["historyAndPhysical"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Pathology Review" field="pathologyReview" lineStyle="oneLine"
                                            value={this.state.researchForm.pathologyReview ? this.state.researchForm.pathologyReview : this.state.researchForm.pathologyReview}
                                            dataSource="Global_Dir_Mv" aliasNo="3054" titleDialog="Pathology Review" labelWidth="140px" width="35px"
                                            inValid={this.state.sAllItemFields["pathologyReview"]} itemNbr="3054"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pathologyReview"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Pathologic Confirmation" field="pathologicConfirmation" lineStyle="oneLine"
                                            value={this.state.researchForm.pathologicConfirmation ? this.state.researchForm.pathologicConfirmation : this.state.researchForm.pathologicConfirmation}
                                            dataSource="Global_Dir_Mv" aliasNo="3091" titleDialog="Pathologic Confirmation" labelWidth="140px" width="35px"
                                            inValid={this.state.sAllItemFields["pathologicConfirmation"]} itemNbr="3091"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pathologicConfirmation"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Multi Team Eval" field="multiTeamEval" lineStyle="oneLine"
                                            value={this.state.researchForm.multiTeamEval ? this.state.researchForm.multiTeamEval : this.state.researchForm.multiTeamEval}
                                            dataSource="Global_Dir_Mv" aliasNo="3136" titleDialog="Multi Team Eval" labelWidth="140px" width="35px"
                                            inValid={this.state.sAllItemFields["multiTeamEval"]} itemNbr="3136"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["multiTeamEval"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Xrays" field="xrays" lineStyle="oneLine"
                                            value={this.state.researchForm.xrays ? this.state.researchForm.xrays : this.state.researchForm.xrays}
                                            dataSource="Global_Dir_Mv" aliasNo="3069" titleDialog="Xrays" labelWidth="140px" width="35px"
                                            inValid={this.state.sAllItemFields["xrays"]} itemNbr="3069"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["xrays"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="MRI" field="mri" lineStyle="oneLine"
                                            value={this.state.researchForm.mri ? this.state.researchForm.mri : this.state.researchForm.mri}
                                            dataSource="Global_Dir_Mv" aliasNo="3064" titleDialog="MRI" labelWidth="140px" width="35px"
                                            inValid={this.state.sAllItemFields["mri"]} itemNbr="3064"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["mri"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Abdomen Mri" field="abdomenMri" lineStyle="oneLine"
                                            value={this.state.researchForm.abdomenMri ? this.state.researchForm.abdomenMri : this.state.researchForm.abdomenMri}
                                            dataSource="Global_Dir_Mv" aliasNo="3068" titleDialog="Abdomen Mri" labelWidth="140px" width="35px"
                                            inValid={this.state.sAllItemFields["abdomenMri"]} itemNbr="3068"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["abdomenMri"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Brain Mri" field="brainMri" lineStyle="oneLine"
                                            value={this.state.researchForm.brainMri ? this.state.researchForm.brainMri : this.state.researchForm.brainMri}
                                            dataSource="Global_Dir_Mv" aliasNo="3084" titleDialog="Brain Mri" labelWidth="140px" width="35px"
                                            inValid={this.state.sAllItemFields["brainMri"]} itemNbr="3084"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["brainMri"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Spine Mri" field="spineMri" lineStyle="oneLine"
                                            value={this.state.researchForm.spineMri ? this.state.researchForm.spineMri : this.state.researchForm.spineMri}
                                            dataSource="Global_Dir_Mv" aliasNo="3085" titleDialog="Spine Mri" labelWidth="140px" width="35px"
                                            inValid={this.state.sAllItemFields["spineMri"]} itemNbr="3085"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["spineMri"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="US" field="us" lineStyle="oneLine"
                                            value={this.state.researchForm.us ? this.state.researchForm.us : this.state.researchForm.us}
                                            dataSource="Global_Dir_Mv" aliasNo="3061" titleDialog="US" labelWidth="140px" width="35px"
                                            inValid={this.state.sAllItemFields["us"]} itemNbr="3061"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["us"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Abdomen Us" field="abdomenUs" lineStyle="oneLine"
                                            value={this.state.researchForm.abdomenUs ? this.state.researchForm.abdomenUs : this.state.researchForm.abdomenUs}
                                            dataSource="Global_Dir_Mv" aliasNo="3067" titleDialog="Abdomen Us" labelWidth="140px" width="35px"
                                            inValid={this.state.sAllItemFields["abdomenUs"]} itemNbr="3067"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["abdomenUs"] : ""}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2 researchCol2">
                                    <div className="frameInner">
                                        <div className="header">&nbsp;</div>
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Abd/Pelvic Ct" field="abdomenCt" lineStyle="oneLine"
                                            value={this.state.researchForm.abdomenCt ? this.state.researchForm.abdomenCt : this.state.researchForm.abdomenCt}
                                            dataSource="Global_Dir_Mv" titleDialog="Abd/Pelvic Ct" aliasNo="3066" labelWidth="105px" width="35px"
                                            inValid={this.state.sAllItemFields["abdomenCt"]} itemNbr="3066"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["abdomenCt"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Chest CT" field="chestCt" lineStyle="oneLine"
                                            value={this.state.researchForm.chestCt ? this.state.researchForm.chestCt : this.state.researchForm.chestCt}
                                            dataSource="Global_Dir_Mv" titleDialog="Chest CT" aliasNo="3074" labelWidth="105px" width="35px"
                                            inValid={this.state.sAllItemFields["chestCt"]} itemNbr="3074"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["chestCt"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Pelvis CT" field="pelvisCt" lineStyle="oneLine"
                                            value={this.state.researchForm.pelvisCt ? this.state.researchForm.pelvisCt : this.state.researchForm.pelvisCt}
                                            dataSource="Global_Dir_Mv" titleDialog="Pelvis CT" aliasNo="3075" labelWidth="105px" width="35px"
                                            inValid={this.state.sAllItemFields["pelvisCt"]} itemNbr="3075"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pelvisCt"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Chest Imaging" field="chestImaging" lineStyle="oneLine"
                                            value={this.state.researchForm.chestImaging ? this.state.researchForm.chestImaging : this.state.researchForm.chestImaging}
                                            dataSource="Global_Dir_Mv" titleDialog="Chest Imaging" aliasNo="3060" labelWidth="105px" width="35px"
                                            inValid={this.state.sAllItemFields["chestImaging"]} itemNbr="3060"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["chestImaging"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Bone Scan" field="boneScan" lineStyle="oneLine"
                                            value={this.state.researchForm.boneScan ? this.state.researchForm.boneScan : this.state.researchForm.boneScan}
                                            dataSource="Global_Dir_Mv" titleDialog="Bone Scan" aliasNo="3065" labelWidth="105px" width="35px"
                                            inValid={this.state.sAllItemFields["boneScan"]} itemNbr="3065"//#PR00278
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["boneScan"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="PET Scan" field="petScan" lineStyle="oneLine"
                                            value={this.state.researchForm.petScan}
                                            dataSource="Global_Dir_Mv" titleDialog="Pet Scan" aliasNo="3070" labelWidth="105px" width="35px"
                                            inValid={this.state.sAllItemFields["petScan"]} itemNbr="3070"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["petScan"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="CBC" field="cbc" lineStyle="oneLine"
                                            value={this.state.researchForm.cbc ? this.state.researchForm.cbc : this.state.researchForm.cbc}
                                            dataSource="Global_Dir_Mv" titleDialog="CBC" aliasNo="3056" labelWidth="105px" width="35px"
                                            inValid={this.state.sAllItemFields["cbc"]} itemNbr="3056"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["cbc"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Platelets" field="platelets" lineStyle="oneLine"
                                            value={this.state.researchForm.platelets ? this.state.researchForm.platelets : this.state.researchForm.platelets}
                                            dataSource="Global_Dir_Mv" titleDialog="Platelets" aliasNo="3057" labelWidth="105px" width="35px"
                                            inValid={this.state.sAllItemFields["platelets"]} itemNbr="3057"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["platelets"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="LFT" field="lft" lineStyle="oneLine"
                                            value={this.state.researchForm.lft ? this.state.researchForm.lft : this.state.researchForm.lft}
                                            dataSource="Global_Dir_Mv" titleDialog="LFT" aliasNo="3058" labelWidth="105px" width="35px"
                                            inValid={this.state.sAllItemFields["lft"]} itemNbr="3058"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["lft"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Chemistry Profile" field="chemistryProfile" lineStyle="oneLine"
                                            value={this.state.researchForm.chemistryProfile ? this.state.researchForm.chemistryProfile : this.state.researchForm.chemistryProfile}
                                            dataSource="Global_Dir_Mv" titleDialog="Chemistry Profile" aliasNo="3072" labelWidth="105px" width="35px"
                                            inValid={this.state.sAllItemFields["chemistryProfile"]} itemNbr="3072"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["chemistryProfile"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="CEA" field="cea" lineStyle="oneLine"
                                            value={this.state.researchForm.cea ? this.state.researchForm.cea : this.state.researchForm.cea}
                                            dataSource="Global_Dir_Mv" titleDialog="CEA" aliasNo="3073" labelWidth="105px" width="35px"
                                            inValid={this.state.sAllItemFields["cea"]} itemNbr="3073"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["cea"] : ""}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 extendDiv researchCol3">
                                    <div className="frameInner">
                                        <div className="header">&nbsp;</div>
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Alkaline Phos" field="alkaPhos" lineStyle="oneLine"
                                            value={this.state.researchForm.alkaPhos ? this.state.researchForm.alkaPhos : this.state.researchForm.alkaPhos}
                                            dataSource="Global_Dir_Mv" titleDialog="Alkaline Phos" aliasNo="3090" labelWidth="135px" width="35px"
                                            inValid={this.state.sAllItemFields["alkaPhos"]} itemNbr="3090"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["alkaPhos"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="ERA" field="era" lineStyle="oneLine"
                                            value={this.state.researchForm.ers ? this.state.researchForm.ers : this.state.researchForm.era}
                                            dataSource="Global_Dir_Mv" titleDialog="ERA" aliasNo="3055" labelWidth="135px" width="35px"
                                            inValid={this.state.sAllItemFields["era"]} itemNbr="3055"//#PR00278
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["era"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="PRA" field="pra" lineStyle="oneLine"
                                            value={this.state.researchForm.pra ? this.state.researchForm.pra : this.state.researchForm.pra}
                                            dataSource="Global_Dir_Mv" titleDialog="PRA" aliasNo="3062" labelWidth="135px" width="35px"
                                            inValid={this.state.sAllItemFields["pra"]} itemNbr="3062"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pra"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="HER2" field="her2" lineStyle="oneLine"
                                            value={this.state.researchForm.her2 ? this.state.researchForm.her2 : this.state.researchForm.her2}
                                            dataSource="Global_Dir_Mv" titleDialog="HER2" aliasNo="3063" labelWidth="135px" width="35px"
                                            inValid={this.state.sAllItemFields["her2"]} itemNbr="3063"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["her2"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Colonoscopy" field="colonoscopy" lineStyle="oneLine"
                                            value={this.state.researchForm.colonoscopy ? this.state.researchForm.colonoscopy : this.state.researchForm.colonoscopy}
                                            dataSource="Global_Dir_Mv" titleDialog="Colonoscopy" aliasNo="3071" labelWidth="135px" width="35px"
                                            inValid={this.state.sAllItemFields["colonoscopy"]} itemNbr="3071"//#PR00278
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["colonoscopy"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Kras Gene Status" field="krasGeneStatus" lineStyle="oneLine"
                                            value={this.state.researchForm.krasGeneStatus ? this.state.researchForm.krasGeneStatus : this.state.researchForm.krasGeneStatus}
                                            dataSource="Global_Dir_Mv" titleDialog="Kras Gene Status" aliasNo="3135" labelWidth="135px" width="35px"
                                            inValid={this.state.sAllItemFields["krasGeneStatus"]} itemNbr="3135"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["krasGeneStatus"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Site Mapping" field="siteMapping" lineStyle="oneLine"
                                            value={this.state.researchForm.siteMapping ? this.state.researchForm.siteMapping : this.state.researchForm.siteMapping}
                                            dataSource="Global_Dir_Mv" titleDialog="Site Mapping" aliasNo="3089" labelWidth="135px" width="35px"
                                            inValid={this.state.sAllItemFields["siteMapping"]} itemNbr="3089"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["siteMapping"] : ""}
                                        />
                                        <Input type="date" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Date Site Mapping" field="dateSiteMapping" lineStyle="oneLine"
                                            value={this.state.researchForm.dateSiteMapping != null && this.state.researchForm.dateSiteMapping !== "" ? moment(new Date(this.state.researchForm.dateSiteMapping)).format("MM/DD/YYYY") : ""}
                                            labelWidth="135px" width="140px"
                                            //#20210125 - START
                                            // Add day/month/year values
                                            inValidDay={this.state.sAllItemFields["dateSiteMappingDay"]}
                                            inValidMonth={this.state.sAllItemFields["dateSiteMappingMonth"]}
                                            inValidYear={this.state.sAllItemFields["dateSiteMappingYear"]}
                                            dayVal={this.state.researchForm.dateSiteMappingDay}
                                            monthVal={this.state.researchForm.dateSiteMappingMonth}
                                            yearVal={this.state.researchForm.dateSiteMappingYear}
                                            lostfocused={this.handleLostFocus}
                                            //#20210125 - END
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Bx First Recur" field="bxFirstRecurrence" lineStyle="oneLine"
                                            value={this.state.researchForm.bxFirstRecurrence ? this.state.researchForm.bxFirstRecurrence : this.state.researchForm.bxFirstRecurrence}
                                            dataSource="Global_Dir_Mv" titleDialog="Bx First Recurrence" aliasNo="3140" labelWidth="135px" width="35px"
                                            inValid={this.state.sAllItemFields["bxFirstRecurrence"]} itemNbr="3140"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["bxFirstRecurrence"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Proctoscopy" field="proctoscopy" lineStyle="oneLine"
                                            value={this.state.researchForm.proctoscopy ? this.state.researchForm.proctoscopy : this.state.researchForm.proctoscopy}
                                            dataSource="Global_Dir_Mv" titleDialog="Proctoscopy" aliasNo="3078" labelWidth="135px" width="35px"
                                            inValid={this.state.sAllItemFields["proctoscopy"]} itemNbr="3078"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["proctoscopy"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Enterostomal Therapist" field="enterostomalTherapist" lineStyle="oneLine"
                                            value={this.state.researchForm.enterostomalTherapist ? this.state.researchForm.enterostomalTherapist : this.state.researchForm.enterostomalTherapist}
                                            dataSource="Global_Dir_Mv" titleDialog="Enterostomal Therapist" aliasNo="3079" labelWidth="135px" width="35px"
                                            inValid={this.state.sAllItemFields["enterostomalTherapist"]} itemNbr="3079"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["enterostomalTherapist"] : ""}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 researchCol4">
                                    <div className="frameInner">
                                        <div className="header">&nbsp;</div>
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Needle BX" field="needleBx" lineStyle="oneLine"
                                            value={this.state.researchForm.needleBx ? this.state.researchForm.needleBx : this.state.researchForm.needleBx}
                                            dataSource="Global_Dir_Mv" titleDialog="Needle BX" aliasNo="3076" labelWidth="175px" width="35px"
                                            inValid={this.state.sAllItemFields["needleBx"]} itemNbr="3076"//#PR00278
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["needleBx"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="DRE" field="dre" lineStyle="oneLine"
                                            value={this.state.researchForm.dre ? this.state.researchForm.dre : this.state.researchForm.dre}
                                            dataSource="Global_Dir_Mv" titleDialog="DRE" aliasNo="3086" labelWidth="175px" width="35px"
                                            inValid={this.state.sAllItemFields["dre"]} itemNbr="3086"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dre"] : ""}
                                        />
                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Gleason Scoring" field="gleason" lineStyle="oneLine"
                                            value={this.state.researchForm.gleason} labelWidth="175px" width="35px"
                                            //dataSource="Global_Dir_Mv" titleDialog="Gleason" aliasNo="3087"
                                            itemNbr="3087"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["gleason"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="PSA" field="psa" lineStyle="oneLine"
                                            value={this.state.researchForm.psa ? this.state.researchForm.psa : this.state.researchForm.psa}
                                            dataSource="Global_Dir_Mv" titleDialog="PSA" aliasNo="3088" labelWidth="175px" width="35px"
                                            inValid={this.state.sAllItemFields["psa"]} itemNbr="3088"//#PR00278
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["psa"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Smoking Cessation Counseling" field="smokingCessationCounseling" lineStyle="oneLine"
                                            value={this.state.researchForm.smokingCessationCounseling ? this.state.researchForm.smokingCessationCounseling : this.state.researchForm.smokingCessationCounseling}
                                            dataSource="Global_Dir_Mv" titleDialog="Smoking Cessation Counseling" aliasNo="3080" labelWidth="175px" width="35px"
                                            inValid={this.state.sAllItemFields["smokingCessationCounseling"]} itemNbr="3080"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["smokingCessationCounseling"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="PFT" field="pft" lineStyle="oneLine"
                                            value={this.state.researchForm.pft ? this.state.researchForm.pft : this.state.researchForm.pft}
                                            dataSource="Global_Dir_Mv" titleDialog="PFT" aliasNo="3088" labelWidth="175px" width="35px"
                                            inValid={this.state.sAllItemFields["pft"]} itemNbr="3088"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pft"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Bronchoscopy" field="bronchoscopy" lineStyle="oneLine"
                                            value={this.state.researchForm.bronchoscopy ? this.state.researchForm.bronchoscopy : this.state.researchForm.bronchoscopy}
                                            dataSource="Global_Dir_Mv" titleDialog="Bronchoscopy" aliasNo="3082" labelWidth="175px" width="35px"
                                            inValid={this.state.sAllItemFields["bronchoscopy"]} itemNbr="3082"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["bronchoscopy"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Mediastinoscopy" field="mediastinoscopy" lineStyle="oneLine"
                                            value={this.state.researchForm.mediastinoscopy ? this.state.researchForm.mediastinoscopy : this.state.researchForm.mediastinoscopy}
                                            dataSource="Global_Dir_Mv" titleDialog="Mediastinoscopy" aliasNo="3083" labelWidth="175px" width="35px"
                                            inValid={this.state.sAllItemFields["mediastinoscopy"]} itemNbr="3083"//#PR00278
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["mediastinoscopy"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Thoracentesis" field="thoracentesis" lineStyle="oneLine"
                                            value={this.state.researchForm.thoracentesis ? this.state.researchForm.thoracentesis : this.state.researchForm.thoracentesis}
                                            dataSource="Global_Dir_Mv" titleDialog="Thoracentesis" aliasNo="3138" labelWidth="175px" width="35px"
                                            inValid={this.state.sAllItemFields["thoracentesis"]} itemNbr="3138"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["thoracentesis"] : ""}
                                        />
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Thoracoscopy" field="thoracoscopy" lineStyle="oneLine"
                                            value={this.state.researchForm.thoracoscopy ? this.state.researchForm.thoracoscopy : this.state.researchForm.thoracoscopy}
                                            dataSource="Global_Dir_Mv" titleDialog="Thoracoscopy" aliasNo="3139" labelWidth="175px" width="35px"
                                            inValid={this.state.sAllItemFields["thoracoscopy"]} itemNbr="3139"
                                            lostfocused={this.handleLostFocus}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["thoracoscopy"] : ""}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetResearch} formId="researchForm"
                        isFormDirty={this.state.isFormDirty}
                        showExtra={[{ name: "Text", action: "showDiagnosisText" }]}
                        callbackToParent={this.updateSaveFlagToProcess}
                        focusingField={this.state.focusingField}
                    />

                </div>
            )
            : <p className="data_loading_page">Research data loading...</p>;
        return (
            <React.Fragment>
                {ResearchMaintenance}

                <div id="textareaEditorForItemsCls">
                    {
                        this.props.isShowTextDialogVisible ?
                            /*
                            <Dialog
                                title="Diagnosis Text &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Treatment &amp; Other Text"
                                onClose={this.props.closeTextDialog}
                                width={950} height={"auto"}
                                className={classes.title}>*/
                            <div className="CRStar_Window">
                                <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar">
                                    <div className="dragableWindowCls">
                                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                                            <div className="k-window-title k-dialog-title">
                                                <pre className="textPopupPreTitleCls">{TextPopup.Title}</pre>
                                            </div>
                                            <div className="k-window-actions k-dialog-actions">
                                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeTextDialog(e) }}></span>
                                                </a>
                                            </div>
                                        </div>
                                        <TextForm closeWindow={this.closeTextDialog} setFocusingField={this.helpFocusingField} />
                                    </div>
                                    {/*</Dialog>*/}
                                </Draggable>
                            </div>
                            : null
                    }
                </div>

            </React.Fragment>
        );
    }
}

ResearchMaintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedResearchRecord: state.patientAbstract.selectedResearchRecord,
        isPatientLoading: state.patientAbstract.isPatientLoading,

        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,

        selectedPatient: state.patientAbstract.selectedPatient,
        isShowTextDialogVisible: state.patientAbstract.isShowTextDialogVisible,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,

        initialAbstractPages: state.patientAbstract.initialAbstractPages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveResearch: (researchData) => dispatch(actionCreators.saveResearch(researchData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index) => dispatch(actionCreators.checkValidInput(field, value, table, key, index)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),

        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResearchMaintenance);