/* eslint-disable */
import React, { Component } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import HospitalForm from "../../UI/Lookup/AddNew/HospitalForm";
import { connect } from "react-redux";
import * as actions from '../../../store/actions/index';
import { actionCreators } from "../../../store/PatientAbstract";
import ConfigData from '../../../store/config.json';
import axios from "axios";
import Draggable from 'react-draggable';
import * as dialogTypes from "../../AbstractManagement/maintDialogs";

class HospitalsMaintLookup extends Component {

    state = {
        codes: [],
        isAddNewDialogVisible: false,
        filter: {
            logic: "",
            filters: []
        },
        filterOperators: {
            'text': [
                { text: 'grid.filterContainsOperator', operator: 'contains' }
            ],
        },
        sort: [],
        total: 0,
        skip: 0,
        take: 10,

        enterKeyPressed: false,
        shiftKeyPressed: false,
        tabKeyPressed: false,
        focusedItem: null,
        filterIndexNo: 0,
        titleDialog: null,
        loaded: false,
    }
    resetPageNumber = false;

    addEventListener = () => {
        var cl = this;
        var currentLookupModal = document.querySelector('#HospitalsMaintLookup');
        currentLookupModal.addEventListener('keydown', function (e) {
            if (e.keyCode == 40) {// down arrow
                var filterInputFocusing = e.target.getAttribute("class") === "k-input-inner" ? true : false;

                var trs = currentLookupModal.getElementsByClassName("k-master-row");
                if (filterInputFocusing && trs && trs[0] && trs[0].firstChild) {
                    trs[0].firstChild.setAttribute('tabindex', '0');
                    trs[0].firstChild.focus();

                    setTimeout(function () {
                        trs[0].scrollIntoView();
                        trs[0].scrollTop = 0;
                    }, 100);

                    let currentClasses = trs[0].getAttribute("class");
                    if (currentClasses.indexOf('k-state-selected') == -1)
                        trs[0].setAttribute("class", currentClasses + " k-state-selected");
                }

                var itemInRowFocusing = e.target.getAttribute("class") === "" ? true : false;
                if (itemInRowFocusing && e.target.parentNode.getAttribute("data-grid-row-index") === "0") {
                    let currentClasses = e.target.parentNode.getAttribute("class");
                    if (currentClasses.indexOf('k-state-selected') != -1)
                        e.target.parentNode.setAttribute("class", currentClasses.replace("k-state-selected", ""));
                }
            } else if (e.key === "Tab" || (e.key === "Shift" && e.keyCode == 9) || (e.key === "Tab" && e.shiftKey)) {// shift + tab
                if (e.key === "Tab" && e.shiftKey) {
                    if (e.target.nodeName === "BUTTON") {
                        if (e.target.nextSibling) {
                            setTimeout(function () {
                                currentLookupModal.querySelector('input.k-input-inner:last-child') ? currentLookupModal.querySelector('input.k-input-inner:last-child').focus() : "";
                            }, 0);
                        }
                        return false;
                    } else if (e.target.nodeName === "TH") {//Don't allow press shift+tab on these items
                        e.preventDefault();
                        return false;
                    } else if (e.target.nodeName === "INPUT" && e.target.getAttribute('class') === "k-input-inner") {
                        if (!e.target.parentNode.parentNode.parentNode.previousSibling || e.target.parentNode.parentNode.parentNode.previousSibling === null) {
                            // set focus into Exit button
                            setTimeout(function () {
                                document.getElementById('btnExitLookupHospital') ? document.getElementById('btnExitLookupHospital').focus() : "";
                            }, 0);
                        }
                        return false;
                    }
                } else {
                    if (e.target.nodeName === "BUTTON") {
                        if (!e.target.nextSibling) {
                            setTimeout(function () {
                                currentLookupModal.querySelectorAll('input')[0] ? currentLookupModal.querySelectorAll('input')[0].focus() : "";
                            }, 0);
                        }
                    }
                }
            } else if (e.keyCode == 38) {// up arrow
                var pagingLinkFocusing = e.target.getAttribute("class") ? (e.target.getAttribute("class").indexOf("k-link") !== -1 ? true : false) : false;
                let lastItemOnGrid = currentLookupModal.querySelector(".k-grid-table") &&
                    currentLookupModal.querySelector(".k-grid-table").lastChild &&
                    currentLookupModal.querySelector(".k-grid-table").lastChild.childElementCount ? currentLookupModal.querySelector(".k-grid-table").lastChild.childElementCount : 10;
                lastItemOnGrid = lastItemOnGrid - 1;

                var trs = currentLookupModal.getElementsByClassName("k-master-row");
                if (pagingLinkFocusing) {
                    for (var len = 0; len < trs.length; len++) {
                        trs[len] ? trs[len].setAttribute("class", len % 2 == 0 ? "k-master-row" : "k-master-row k-alt") : "";
                    }

                    if (trs && trs[lastItemOnGrid] && trs[lastItemOnGrid].firstChild) {
                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].firstChild.setAttribute('tabindex', '0') : "";
                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].firstChild.focus() : "";

                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].setAttribute("class", "k-master-row k-alt k-state-selected") : "";

                        setTimeout(function () {
                            trs[lastItemOnGrid] ? trs[lastItemOnGrid].scrollIntoView() : "";
                            trs[lastItemOnGrid] ? trs[lastItemOnGrid].scrollTop = 10000 : "";//scroll to bottom of grid
                        }, 100);
                    }
                } else if (e.target.parentNode.getAttribute("data-grid-row-index") === "0") {
                    trs[0] ? trs[0].setAttribute("class", "k-master-row") : "";
                } else {
                    trs[lastItemOnGrid] ? trs[lastItemOnGrid].setAttribute("class", "k-master-row k-alt") : "";
                }

            } else if (e.keyCode == 13 && e.target.getAttribute("class") === "k-input-inner" && e.target.value != "") {//enter
                !cl.enterKeyPressed ? cl.setState({
                    enterKeyPressed: true,
                    focusedItem: e.target,
                    filterIndexNo: e.target.getAttribute("data-index")
                }) : "";
                cl.props.setPageLoaded(false);
                e.preventDefault();
                return false;
            } else if (e.keyCode == 13 && e.target.getAttribute("class") === "k-input-inner" && e.target.value == "") {//enter
                cl.setState({
                    enterKeyPressed: false,
                });
                e.preventDefault();
                return false;
            } else {
                cl.setState({
                    enterKeyPressed: false,
                });
            }
        });
    }

    init = () => {
        var hospopup = document.getElementById("HospitalsMaintLookup");
        var cl = this;
        var filterIndexNo = cl.state.filterIndexNo;
        if (hospopup && hospopup.querySelectorAll(".k-input-inner")) {
            var inputs = hospopup.querySelectorAll(".k-input-inner");
            for (var idx = 0; idx < inputs.length; idx++) {
                let input = inputs[idx];
                input.setAttribute("id", "hosp-lookup_filter-k-input-inner-" + idx);
                input.setAttribute("data-index", idx);
                input.addEventListener('focus', function (e) {
                    cl.setState({ focusedItem: input });
                });
            }
        }

        setTimeout(function () {
            if (document.querySelector(".hospital_maint_form") && document.querySelector(".hospital_maint_form").length > 0) return false;

            if (cl.state.focusedItem && document.getElementById(cl.state.focusedItem.id)) {
                document.getElementById(cl.state.focusedItem.id).focus();
            } else if (filterIndexNo > 0) {
                document.getElementById("hosp-lookup_filter-k-input-inner-" + filterIndexNo) ? document.getElementById("hosp-lookup_filter-k-input-inner-" + filterIndexNo).focus() : "";
            } else {
                hospopup && hospopup.querySelector(".k-input-inner") ? hospopup.querySelector(".k-input-inner").focus() : "";
            }
            return false;
        }, 0);
    }

    componentDidMount() {
        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);

        // Add all tabs in maintDialogs.js
        dialogTypes.systemMgmtTabsDisplay.map((dialog, index) => {
            if (dialog.name) {
                this.props.addTabItems(dialog.name, dialog.id);
                this.props.setEnabledStatus(dialog.name);
            } else {
                this.props.addTabItems(dialog);
                this.props.setEnabledStatus(dialog);
            }
        });

        this.setState({ isLoading: true, loaded: false });//#End

        // must close add new dialog before show it
        this.closeAddNewDialog();

        // Fixing item #PR00111
        this.addEventListener();

        this.props.setPageLoaded(false);

        this.getHospitals();

        // must close add new dialog before show it
        this.closeAddNewDialog();

        this.props.setChildrenOpening(true);

        this.init();
    }

    async getHospitals() {
        this.props.setPageLoaded(true);

        const enterKeyPressed = this.resetPageNumber;
        let skipNo = enterKeyPressed ? 0 : this.state.skip;
        let takeNo = enterKeyPressed ? 10 : this.state.take;

        //let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetHospitalLookupViewMain?skip=" + skipNo + "&take=" + takeNo;
        let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetHospitalLookupViewMain";

        //
        var access_token = localStorage.getItem('token');
        var params = {
            skip: skipNo,
            take: takeNo,
            accessToken: access_token
        };

        var _filter = {};
        if (this.state.filter !== null) {
            _filter = this.state.filter;
            this.state.filter.filters.map((f, i) => {
                //url += "&" + f["field"] + "=" + encodeURIComponent("%" + f["value"] + "%");
                params[f["field"]] = ("%" + f["value"] + "%");
            });
        }

        // adding sort options
        var _sorter = [];
        if (this.state.sort !== null && this.state.sort.length > 0) {
            _sorter = this.state.sort;
            this.state.sort.map((s, i) => {
                //url += "&sortby=" + s["field"] + "&orderby=" + s["dir"];
                params["sortby"] = s["field"];
                params["orderby"] = s["dir"];
            });
        }
        var _this = this;
        let data = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);

                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;

            });

        //update new accessToken again
        var newAccessToken = data && data.data ? data.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        var pItems = data && data.data ? data.data.commonLookupList : null;

        this.setState({
            codes: pItems,
            total: data && data.data ? data.data.total : 0,
            filter: _filter,
            sort: _sorter,
            enterKeyPressed: false,
            skip: skipNo,
            take: takeNo,
            loaded: true,
        });

        this.props.setPageLoaded(true);

        //this.init();

        return false;
    }

    componentDidUpdate(prevProps) {
        if (!this.props.pageLoaded || this.state.codes == null || (!this.props.pageLoaded && this.state.sort !== null && this.state.sort.length > 0)) {
            this.getHospitals();
        }
    }

    pageChange = (event, isFilter) => {
        //
        if (isFilter === "sorting") {
            let _sorter = event.sort;

            this.setState({
                sort: _sorter,
                enterKeyPressed: false
            });
            this.props.setPageLoaded(false);
            this.resetPageNumber = false;
            return false;
        }
        //

        let _filter = {
            logic: "",//and
            filters: []
        };
        let enterKeyPressed = this.state.enterKeyPressed;
        if (event && event.filter) {
            _filter = event.filter;

            let allowSearch = event.nativeEvent.target.value != "";

            this.setState({
                filter: _filter,
                enterKeyPressed: allowSearch
            });
            this.resetPageNumber = true;
        } else {
            // click a page number on paging area
            _filter = event.target.props.filter && !isFilter ? event.target.props.filter : {
                logic: "",//and
                filters: []
            };

            let allowSearch = event.nativeEvent.target.value != "";

            this.setState({
                skip: event.page ? event.page.skip : this.state.skip,
                take: event.page ? event.page.take : this.state.take,
                filter: _filter,
                enterKeyPressed: allowSearch
            });
            enterKeyPressed = allowSearch;

            if (event.page) {
                this.resetPageNumber = false;
            } else {
                this.resetPageNumber = true;
            }
        }
        this.props.setPageLoaded(!enterKeyPressed);
    }

    addNewItem = (event) => {
        event.preventDefault();
        this.props.addNewItem("");
        this.props.setChildrenOpening(false);
        this.setState({ titleDialog: null });
        return false;
    }

    closeAddNewDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.props.closeAddNewDialog();
        this.props.setChildrenOpening(false);
        return false;
    }

    resetFilter = () => {
        let _filter = {
            logic: "",//and
            filters: []
        };
        this.setState({
            skip: 0,
            take: 10,
            filter: _filter,
            enterKeyPressed: true,
            sort: [] // Also clear all sorter
        });
        this.resetPageNumber = true;
        this.props.setPageLoaded(false);
    }

    selectCode = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.props.addNewItem(event.dataItem.hospNbr);
        this.setState({ titleDialog: "Hospital Maintenance" });

        this.setState({ isDialogVisible: true });
        return false;
    }

    // To get more reference at https://www.telerik.com/kendo-react-ui/components/grid/accessibility/
    handleNavigationAction = (event) => {
        const { focusElement } = event;
        const data = this.state.codes;

        const rowId = focusElement.parentElement.getAttribute("data-id");

        if (rowId) {
            const newData = data.map((item) => ({
                ...item,
                selected: String(item.hospitalId) === rowId,
            }));
            this.setState({ codes: newData });
        }
    };

    rowRender = (trElement, rowProps) => {
        const trProps = {
            ...trElement.props,
            ["data-id"]: rowProps.dataItem.hospitalId,
        };
        return React.cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    };

    handleKeyDownAction = (props) => {
        var event = props.nativeEvent;
        if (event.key == "Enter") {
            event.target.click();
        }
    }

    render() {
        let loading = (
            !this.state.loaded || this.props.waitingFor ? <div className="col-sm-12 showLoadingOnTopPageCls">Working...</div> : ""
        );

        return (
            <div className="limitedInGridCls">
                <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                    {loading}
                    <div className="parentWindow" id="HospitalsMaintLookup">
                        <div className="patientAbstractGridView" id="hospitalsMaintLookupGridView">
                            <Grid
                                style={{ height: '500px' }}
                                data={this.state.codes != undefined && this.state.codes.length !== 0 ? this.state.codes : null}
                                filter={this.state.filter}
                                filterable
                                filterOperators={this.state.filterOperators}
                                onFilterChange={e => { this.pageChange(e, true) }}
                                onRowClick={this.selectCode}
                                pageable
                                onPageChange={e => { this.pageChange(e, false) }}
                                scrollable="scrollable"
                                sortable
                                sort={this.state.sort}
                                onSortChange={e => { this.pageChange(e, "sorting") }}
                                skip={this.state.skip}
                                take={this.state.take}
                                total={this.state.total}
                                selectedField="selected"
                                rowRender={this.rowRender}
                                navigatable={true}
                                onNavigationAction={this.handleNavigationAction}
                                onKeyDown={this.handleKeyDownAction}
                            >
                                <GridColumn field="hospNbr" filter="text" title="Hosp Nbr" width="100px" />
                                <GridColumn field="hospName" filter="text" title="Hosp Name" width="500px" />
                                <GridColumn field="city" filter="text" title="City" width="150px" />
                                <GridColumn field="state" filter="text" title="State" width="50px" />
                            </Grid>

                            <div className="addNewHospital">
                                <button id="btnAddNewHospital" onClick={this.addNewItem}>New Hospital</button>
                                <button id="btnClearResults" onClick={this.resetFilter}>Clear Results</button>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.props.isAddNewDialogVisible ?
                        <div className="CRStar_Window">
                            <div className="fake_popup_cls"></div>
                            <Draggable handle=".k-window-titlebar_notallow_move" id={"NewHospital_" + this.props.field}
                                disabled={this.props.childrenOpening}
                                axis={this.props.childrenOpening ? "none" : "both"}>
                                <div className="dragableLargerWindowCls">
                                    <div className="k-window-titlebar k-dialog-titlebar k-header">
                                        <div className="k-window-title_x k-dialog-title">{this.state.titleDialog ? this.state.titleDialog : "New Hospital"}</div>
                                        <div className="k-window-actions k-dialog-actions hidden">
                                            <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                <span className="k-icon k-i-x hidden" onClick={(e) => { this.closeAddNewDialog(e) }}></span>
                                            </a>
                                        </div>
                                    </div>
                                    <HospitalForm closeWindow={this.closeAddNewDialog} />
                                </div>
                            </Draggable>
                        </div>
                        : null
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAddNewDialogVisible: state.patientAbstract.isAddNewDialogVisible,
        clientgroup: state.auth.clientgroup,
        pageLoaded: state.patientAbstract.pageLoaded,
        childrenOpening: state.patientAbstract.childrenOpening,
        inquiringMode: state.auth.inquiringMode,
        handlerControlItem: state.patientAbstract.handlerControlItem,
        isV21CompatibilityVisible: state.patientAbstract.isV21CompatibilityVisible,
        currentDialog: state.patientAbstract.currentDialog,
        childDialog: state.patientAbstract.childDialog,
        isChildDialogVisible: state.patientAbstract.isChildDialogVisible,
        isParentWindowVisible: state.patientAbstract.isParentWindowVisible,
        isPatientSelected: state.patientAbstract.selectedPatient !== null,
        isAuthenticated: state.auth.token !== null,
        userId: state.auth.userId,
        waitingFor: state.patientAbstract.waitingFor,
        initialPageVisible: state.patientAbstract.initialPageVisible,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addNewItem: (number) => dispatch(actionCreators.selectHospitalRecord(number)),
        closeAddNewDialog: () => dispatch(actionCreators.closeAddNewDialog()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),

        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HospitalsMaintLookup);