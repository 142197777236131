/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import "./GenPhysicianRoster.css";
import Help from "../../UI/Help";
import Draggable from 'react-draggable';

class GenPhysicianRoster extends Component {
    downloadFile = false;
    state = {
        uAllItemFields: null,
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        fieldInFocus: "",
        focusingField: null,
        isHelpVisible: false,
        loaded: false,
        selectedSortOrderOption: null,
        selectedOutputTypeOption: null,
        titleDialog: null,
        showClockTimer: false,
        startTimeFrom: null,
        currentTime: null,
        chartDatas: null,

        htmlOutput: null,
        isDrawingCharts: false,
        loaded: false,
    }

    selectionSortOrder = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedSortOrderOption: event.target.value,
            dialogForm: dialogFormData,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: this.state.selectedSortOrderOption != event.target.value ? true : this.state.isFormDirty,
        });

        //this.props.storeToGlobal(null);
    }

    selectionOutputType = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedOutputTypeOption: event.target.value,
            dialogForm: dialogFormData,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: this.state.selectedOutputTypeOption != event.target.value ? true : this.state.isFormDirty,
        });

        //this.props.storeToGlobal(null);
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isHelpVisible: false });
        return false;
    }

    initClock() {
        var _this = this;
        setInterval(function () {
            var today = new Date(),
                time = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            //d.toLocaleTimeString();
            _this.setState({ currentTime: time });

            return false;
        }, 1000);
    }

    componentDidMount() {
        if (!this.state.dialogForm && this.props.physiciansRosterInfo) {
            this.setFormData();
        }
    }

    componentWillMount() {
        this.props.setChildrenOpening(false);
        this.initClock();
    }

    componentDidUpdate(prevProps) {
        if (this.props.errorMessage) {
            alert(this.props.errorMessage);
            this.setState({ showClockTimer: false });
            this.props.clearErrorMessage();
        }

        if ((!this.state.dialogForm && this.props.physiciansRosterInfo)
            || (prevProps.physiciansRosterInfo && this.props.physiciansRosterInfo && (prevProps.physiciansRosterInfo.startTime !== this.props.physiciansRosterInfo.startTime || prevProps.physiciansRosterInfo.endTime !== this.props.physiciansRosterInfo.endTime))
            || (this.state.reload && this.props.physiciansRosterInfo)
        ) {
            this.setFormData(this.props.physiciansRosterInfo);
        }
    }

    setFormData = (physiciansRosterInfo) => {
        this.setState({
            initialDialogForm: { ...physiciansRosterInfo },
            dialogForm: { ...physiciansRosterInfo },
            reload: false,
            changed: true,
            loaded: true,
            ["selectedSortOrderOption"]: physiciansRosterInfo && physiciansRosterInfo.sortOrder ? physiciansRosterInfo.sortOrder : "Name",
            ["selectedOutputTypeOption"]: physiciansRosterInfo && physiciansRosterInfo.sendReportTo ? physiciansRosterInfo.sendReportTo : "Screen",
            showClockTimer: false,
            titleDialog: null,
            loaded: true,
        });

        if (physiciansRosterInfo && physiciansRosterInfo.sendReportTo == "Screen" && physiciansRosterInfo.endTime)
            //@TODO...
            this.drawingCharts(physiciansRosterInfo);
        else if (physiciansRosterInfo && physiciansRosterInfo.sendReportTo == "Excel" && physiciansRosterInfo.urlOutputFile && !this.downloadFile) {
            var _this = this;
            var url = this.props.clientgroup + "/DownloadFile";
            axios.post(url, {
                urlOutputFile: physiciansRosterInfo.urlOutputFile,
                method: 'POST',
                responseType: 'blob',//important
                accessToken: localStorage.getItem('token')
            }).then((response) => {
                // get file name from url
                var filename = physiciansRosterInfo.urlOutputFile.substring(physiciansRosterInfo.urlOutputFile.lastIndexOf('\\') + 1);
                console.log("filename=" + filename);

                const link = document.createElement('a');

                let excel;
                if (physiciansRosterInfo.urlOutputFile.indexOf('/') != -1) {
                    excel = physiciansRosterInfo.urlOutputFile.substring(physiciansRosterInfo.urlOutputFile.lastIndexOf('/') + 1);
                } else {
                    excel = physiciansRosterInfo.urlOutputFile.substring(physiciansRosterInfo.urlOutputFile.lastIndexOf('\\') + 1);
                }
                link.href = this.props.clientgroup + "/" + excel;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click(); 
                link.remove();
                _this.downloadFile = true;
                return false;
            })
            .catch(function (error) {  
                console.log(error);
            });

            return false;
        }

        return false;
    }

    handleOnFocus = (event, index, alias) => {
        //this.props.storeToGlobal(null);
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true,
            });
        } else {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value
                },
                isFormDirty: true,
            });
        }
    }

    async Get_Data(pLabel) {
        var _this = this;
        //Labels
        await _this.props.generatePhysiciansRoster();

        this.setState({
            reload: true,
            isFormDirty: false,
        });

        return false;
    }

    Run_Physician_Roster = () => {
        var dialogFormData = this.state.dialogForm;

        dialogFormData.sortOrder = this.state.selectedSortOrderOption;
        dialogFormData.sendReportTo = this.state.selectedOutputTypeOption;

        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;

        //
        this.props.runPhysicianRoster(dialogFormData);

        this.downloadFile = false;//reset this flag to get download new file
        return false;
    }
    //--

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    //on before show event
    showEvent(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        var _this = this;
        setTimeout(function () {
            _this.Run_Physician_Roster();

            var today = new Date(),
                timeFrom = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            // set clock timer...
            _this.setState({ showClockTimer: true, startTimeFrom: timeFrom });

            return false;
        }, 0);

        //
        var sInVal = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(sInVal);
            } else {
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
            }
        }, 750);

        return false;
    }

    Cancel_Report = (e, _this) => {
        e.preventDefault();

        _this.props.cancelSBSReport();

        setTimeout(function () {
            _this.setState({ showClockTimer: false });
            return false;
        }, 0);

        return false;
    }

    //
    drawingCharts = (data) => {
        this.setState({
            showClockTimer: false,//hide clock timer
            htmlOutput: data.htmlOutput,
            printStyle: data.printStyle,
            isDrawingCharts: true
        });
        return false;
    }

    hideDrawingCharts = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.setState({ isDrawingCharts: false });
        return false;
    }

    newRecord(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        this.Get_Data("");
        return false;
    }

    exitScreen(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        this.props.setInitialPageVisible(false);//closeCode

        return false;
    }

    printTable = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }

        var printStyle = document.getElementById('PrintStyle') ? document.getElementById('PrintStyle').innerHTML : this.state.printStyle;
        var printContent = document.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Physician Roster</title><style type="text/css">';
        html += printStyle + '</style></head><body><div id="printableContent">';
        html += printContent.innerHTML + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
        return false;
    }

    render() {
        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTimeFrom}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let drawingCharts;
        {
            drawingCharts = this.state.isDrawingCharts ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableLargestWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Physician Roster</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingCharts(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container" id="GenPhysicianRosterShowingCharts">
                                {/*Drawing table here*/}
                                <div dangerouslySetInnerHTML={{ __html: this.state.htmlOutput ? this.state.htmlOutput : "" }} className="" />
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12" style={{ display: "flex" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ScreenButton ScreenButtonHover" title=""
                                            onClick={(e) => { this.printTable(e) }}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print
                                            </button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ScreenButton ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={(e) => { this.hideDrawingCharts(e) }} value="exit" autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit
                                            </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let loading = (
            !this.state.loaded || this.props.waitingFor ? <div className="col-sm-12 showLoadingOnTopPageCls" style={{ marginLeft: "0px" }}>Working...</div> : ""
        );

        let dialogForm = <div className="limitedInGridCls">
            <div className="container-fluid2">
                {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} focusingField={this.state.focusingField} closeCode={this.closeCodeDialog} />}

                <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                    {loading}
                    <div className="parentWindow">
                        <div className="patientAbstractGridView" id="followUpOrderMainPage" style={{ height: "580px" }}>
                            {
                                this.state.dialogForm && this.state.loaded ?
                                    <form onSubmit={false} id="genPhysicianRosterForm" className={this.props.isPopup ? "DialogForm2" : ""}>
                                        <div id="inputAreaCls">
                                            <div>
                                                <div className="outerBoxCls frameInner">
                                                    <div className="row">
                                                        <div className="col-sm-6">Sort Order</div>
                                                        <div className="col-sm-6">Send Report To</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <label style={{ marginTop: "5px", height: "15px" }}>
                                                                <input type="radio" onClick={(e) => { this.selectionSortOrder(e) }}
                                                                    checked={this.state.selectedSortOrderOption === "Name"}
                                                                    name="sortOrder" value="Name" id="sortOrder_byName"
                                                                    style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                                        Sort by Name
                                                                                                                </label>
                                                            <br />
                                                            <label style={{ width: "240px", height: "15px" }}>
                                                                <input type="radio" onClick={(e) => { this.selectionSortOrder(e) }}
                                                                    checked={this.state.selectedSortOrderOption === "PhysId"}
                                                                    name="sortOrder" value="PhysId" id="sortOrder_byPhysId"
                                                                    style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                                        Sort by Physician Id
                                                                                                                </label>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <label style={{ width: "240px", height: "0px" }}>
                                                                <input type="radio" onClick={this.selectionOutputType}
                                                                    checked={this.state.selectedOutputTypeOption === "Excel"}
                                                                    name="sendReportTo" field="sendReportTo" value="Excel"
                                                                    style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                Excel
                                                                                        </label>
                                                            <br />
                                                            <label style={{ width: "240px", height: "0px" }}>
                                                                <input type="radio" onClick={this.selectionOutputType}
                                                                    checked={this.state.selectedOutputTypeOption === "Screen"}
                                                                    name="sendReportTo" field="sendReportTo" value="Screen"
                                                                    style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                Screen
                                                                                        </label>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div id="CONTAINER_JS_CLOCK" className="hidden">
                                                        <label>Selecting Cases</label>
                                                        <div id="IMAGE_WAIT" style={{ color: "#05004c", fontWeight: "bold" }}>
                                                            Selecting Cases - Please Wait ...
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr className="hidden" />

                                        <div className="outerBoxCls">
                                            <div className="row">
                                                <div className="col-sm-5">
                                                    <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", marginLeft: "15px", paddingTop: "2px" }}
                                                        labelText="Start Time" field="startTime" width="auto"
                                                        value={this.state.dialogForm.startTime ? this.state.dialogForm.startTime : "-"}
                                                    />
                                                </div>
                                                <div className="col-sm-7">
                                                    <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", paddingTop: "2px" }}
                                                        labelText="End Time" field="endTime" width="auto"
                                                        value={this.state.dialogForm.endTime ? this.state.dialogForm.endTime : "-"}
                                                    />
                                                </div>
                                                <div className="col-sm-3 hidden">
                                                    <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", borderStyle: "none", paddingTop: "2px" }}
                                                        labelText="Records Selected" field="numberOfRecords" width="auto"
                                                        value={this.state.dialogForm.numberOfRecords ? this.state.dialogForm.numberOfRecords : "0"}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <fieldset className="bottomAreaButtonCls">
                                            <legend></legend>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="A5CWLayout">
                                                        <button id="BUTTON_RUN"
                                                            className="ErsGrBlueButton"//{this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                            onClick={(e) => { this.showEvent(e) }}
                                                            //disabled={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? false : true}
                                                        >
                                                            <img id="BUTTON_RUN_ICON"
                                                                src={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "https://crstar.ers-can.com/images/$$code.action.run.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$code.action.run.png.a5image"}
                                                                style={{ verticalAlign: "middle", border: "none" }}></img>
                                                                    Run
                                                                </button>
                                                    </div>

                                                    <div className="A5CWLayout">
                                                        <button id="BUTTON_NEWRECORD"
                                                            className={this.state.isFormDirty ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                            onClick={(e) => { this.newRecord(e) }}
                                                            disabled={!this.state.isFormDirty}
                                                        >
                                                            <img id="BUTTON_NEWRECORD_ICON"
                                                                src={this.state.isFormDirty ? "https://crstar.ers-can.com/images/$$navigate.new.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$navigate.new.png.a5image"}
                                                                style={{ verticalAlign: "middle", border: "none" }}></img>
                                                                    New
                                                                </button>
                                                    </div>

                                                    <div className="A5CWLayout">
                                                        <button id="BUTTON_EXIT" className="ErsGrBlueButton"
                                                            style={{ display: this.state.onlyShowOnModal ? "" : "" }}
                                                            onClick={(e) => { this.exitScreen(e) }}
                                                        >
                                                            <img id="BUTTON_EXIT_ICON" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" style={{ verticalAlign: "middle", border: "none" }}></img>
                                                                        Exit
                                                                </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                    : <p>Generate Physicians Roster data loading...</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

        return (
            <React.Fragment>
                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {drawingCharts}
                </React.Fragment>

                <React.Fragment>
                    {dialogForm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

GenPhysicianRoster.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        physiciansRosterInfo: state.patientAbstract.physiciansRosterInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        isAddNewUser: state.patientAbstract.isAddNewUser,
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        waitingFor: state.patientAbstract.waitingFor,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearAddNewUserFlag: () => dispatch(actionCreators.clearAddNewUserFlag()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        generatePhysiciansRoster: (flag) => dispatch(actionCreators.generatePhysiciansRoster(flag)),
        runPhysicianRoster: (dialogForm) => dispatch(actionCreators.runPhysicianRoster(dialogForm)),
        //storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GenPhysicianRoster);