/* eslint-disable */
import React, { Component } from 'react';
import "./SidebarOnRight.css";
import { connect } from "react-redux";
import { actionCreators } from "../../store/PatientAbstract";
import * as actions from '../../store/actions/index';
import leftArrow from "../../assets/images/PanelLeftCollapse.png";//left.svg
import rightArrow from "../../assets/images/PanelLeftExpand.png";//right.svg
import QuickLinks from "./QuickLinks";
/*
import * as dialogTypes from "../../components/AbstractManagement/maintDialogs";
import { Nav, NavItem, NavLink, Navbar, Collapse } from "reactstrap";
import { Route, Switch, Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Login from '../../components/UI/Login/Login';
import Auth from '../Auth/Auth';
import ForgotPassword from '../../components/UI/ForgotPassword/ForgotPassword';
*/

class SidebarOnRight extends Component {

    // isXOpen is used to open/collapse top level menu items
    state = {
        importedJsScript: false
    }

    init = () => {
        const script = document.createElement("script");

        script.src = "https://niutech.github.io/x-frame-bypass/x-frame-bypass.js";
        script.async = true;

        document.body.appendChild(script);

        this.setState({ importedJsScript: true });
    }

    componentDidMount() {
        //this.init();
    }

    //
    componentDidUpdate(prevProps) {
        //if (!this.state.importedJsScript) this.init();
    }

    render() {
        var leftSidebarStatus = this.props.leftSidebarOpen ? "" : "leftIsClosed";
        var sidebarClass = !this.props.isClose ? "sidebar closed" : "sidebar open";

        return (
            <div id="RightSidebar" className={sidebarClass + " " + leftSidebarStatus}>
                <div className="ErsGrBlueGridHeader">
                    <button onClick={this.props.toggleSidebar} className="sidebar-toggle">
                        <img src={this.props.isClose ? rightArrow : leftArrow} alt="arrow" style={{ verticalAlign: "text-top" }} />
                    </button>
                </div>
                <div id="tbi_MENU_Right">
                    {this.props.selectedPatient != null ? <QuickLinks /> : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        alphaUrl: window.location ? window.location.pathname : "",
        routingPage: state.patientAbstract.routingPage,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
        selectedPatient: state.patientAbstract.selectedPatient,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        setTabName: (name) => dispatch(actionCreators.setTabName(name)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        closeOtherTabItems: (flag) => dispatch(actionCreators.closeOtherTabItems(flag)),
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        getTokenOnSession: (uid) => dispatch(actionCreators.getTokenOnSession(uid)),
        //
        onLogout: (uid) => dispatch(actions.logout(uid)),
        setAlphaUrl: (url) => dispatch(actionCreators.setAlphaUrl(url)),
        setTwoFactorAuthDialogVisible: (flg) => dispatch(actionCreators.setTwoFactorAuthDialogVisible(flg)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarOnRight);