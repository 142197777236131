/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Footer from "../Shared/Footer";
import Input from "../../../UI/Input";
import "../PatientMaintenance/PatientMaintenance.css";
import "./DiagnosisMaintenance.css";
import "./AdjustLayout.css";
import moment from "moment";
import TextForm from "./TextForm";
import Help from "../../../UI/Help";
import axios from "../../../../axios-instance";
import Draggable from 'react-draggable';
import * as TextPopup from "../TitleOfTextPopup";
import PhysicianForm from "../../../UI/Lookup/AddNew/PhysicianForm";
import HospitalForm from "../../../UI/Lookup/AddNew/HospitalForm";

class DiagnosisMaintenance extends Component {

    state = {
        activeTab: "1",
        dAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'diagnosisForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty diagnosisForm back to what it was on load
        initialDiagnosisForm: null,
        diagnosisForm: null,
        isFormDirty: false,
        resetFormDirtyFlag: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        fieldInFocus: "dxAddress",
        isHelpVisible: false,
        icd10Opt: "ICD10",

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,//always allow save anyway

        hasErrorOccurred: false,
        hasNotificationSaveMsg: false,
        // State
        hasNotificationStateMsg: false,
        updatedDataStateInfo: null,
        updatingItemState: null,
        updatingStateValue: null,
        // County
        hasNotificationCountyMsg: false,
        updatedDataCountyInfo: null,
        updatingItemCounty: null,
        updatingCountyValue: null,
        // City
        hasNotificationCityMsg: false,
        updatedDataCityInfo: null,
        updatingItemCity: null,
        updatingCityValue: null,
        // show Site Code Confirmation when TopoCode has changed
        hasNotificationSiteCodeMsg: false,
        currentOldValueSiteCode: null,
        updatingSiteCodeNewValue: null,
        updatedSiteCodeFlag: false,

        // Fixing #PR00272
        showConfirmationToAddNew: false,
        newValueToAddNew: "",
        selectedFieldNoExistingVal: null,
        focusingField: null,

        calledByMe: false,
        reloadPage: false,
        fieldInFocusOnTextPad: null,

        confirmedCodesChange: false,
        ageDx: null,
        reloadAgeDx: false,
    }

    hideConfirmationToAddNew = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        if (event.target.value === "yes") {
            if (this.state.selectedFieldNoExistingVal === "hospRefFrom" || this.state.selectedFieldNoExistingVal === "hospRefTo") {
                this.props.addNewHospital(this.state.newValueToAddNew);
            } else {
                this.props.addNewPhysiciansNotExist(this.state.newValueToAddNew);
            }
        } else {
            var selectedField = this.state.selectedFieldNoExistingVal ? this.state.selectedFieldNoExistingVal : "NotFoundAny";

            this.setState({
                diagnosisForm: {
                    ...this.state.diagnosisForm,
                    [selectedField]: "",
                    [selectedField + "Name"]: "",
                },
                calledByMe: false,
            });

            setTimeout(function () {
                document.getElementById(selectedField) ? document.getElementById(selectedField).focus() : '';
            }, 100);
        }

        this.setState({ showConfirmationToAddNew: false, newValueToAddNew: "", /*selectedFieldNoExistingVal: null*/ });

        return false;
    }

    closeAddNewDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.props.closeAddNewDialog();
        this.setState({ calledByMe: false });

        // focus into previous field
        if (this.state.fieldInFocus !== "") {
            let focusingField = this.state.fieldInFocus;
            setTimeout(function () {
                document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
            }, 0);
        }

        return false;
    }

    helpFocusingField = (field) => {
        this.setState({
            fieldInFocus: field,
            isHelpVisible: false,
            //fieldInFocusOnTextPad: field
        });
        return false;
    }

    hideNotificationSaveMsg = async (event) => {
        try {
            event ? event.preventDefault() : "";
            /*
            // focus into previous field
            if (this.state.fieldInFocus !== "") {
                let focusingField = this.state.fieldInFocus;
                setTimeout(function () {
                    document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
                }, 0);
            }
            */
        } catch (ex) { }

        //this.setState({ hasNotificationSaveMsg: false, confirmedCodesChange: true });

        // Continue to process saving form
        let diagnosisFormData = this.state.diagnosisForm;

        if (diagnosisFormData["ncdbSarsCov2PositiveDate"] === "Invalid date") {
            diagnosisFormData["ncdbSarsCov2PositiveDate"] = null;
        }

        diagnosisFormData.pid = (this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.pid : 0);

        await this.props.saveDiagnosis(diagnosisFormData);

        this.setState({ isFormDirty: false, resetFormDirtyFlag: false, hasNotificationSaveMsg: false, confirmedCodesChange: true });

        if (this.props.handlerControlItem) {
            var ctlItem = this.props.handlerControlItem;
            console.log("ctlItem=" + ctlItem);

            await setTimeout(function () {
                document.getElementById(ctlItem) ? document.getElementById(ctlItem).click() : "";
                return false;
            }, 1000);

            await this.props.setHandlerControlItem(null);
        }

        return false;
    }
    hideNotificationStateMsg = (event) => {
        try {
            event ? event.preventDefault() : "";

            // focus into previous field
            if (this.state.fieldInFocus !== "") {
                let focusingField = this.state.fieldInFocus;
                setTimeout(function () {
                    document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
                }, 0);
            }
        } catch (ex) { }
        this.setState({ hasNotificationStateMsg: false });
        return false;
    }
    updateDataStateInfo = () => {
        const newState = this.state.updatedDataStateInfo;
        this.setState({
            diagnosisForm: {
                ...this.state.diagnosisForm,
                dxState: newState
            },
            hasNotificationStateMsg: false,
            updatedDataStateInfo: null,
            isFormDirty: true,
            // clear IN_VALID flag if it's existing
            dAllItemFields: {
                ...this.state.dAllItemFields,
                dxState: "",
            },
        });

        // focus into previous field
        if (this.state.fieldInFocus !== "") {
            let focusingField = this.state.fieldInFocus;
            setTimeout(function () {
                document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
            }, 0);
        }
    }

    hideNotificationCountyMsg = (event) => {
        try {
            event ? event.preventDefault() : "";

            // focus into previous field
            if (this.state.fieldInFocus !== "") {
                let focusingField = this.state.fieldInFocus;
                setTimeout(function () {
                    document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
                }, 0);
            }
        } catch (ex) { }
        this.setState({ hasNotificationCountyMsg: false });
        return false;
    }
    updateDataCountyInfo = () => {
        const newCounty = this.state.updatedDataCountyInfo;
        this.setState({
            diagnosisForm: {
                ...this.state.diagnosisForm,
                dxCounty: newCounty
            },
            hasNotificationCountyMsg: false,
            updatedDataCountyInfo: null,
            isFormDirty: true,
            // clear IN_VALID flag if it's existing
            dAllItemFields: {
                ...this.state.dAllItemFields,
                dxCounty: "",
            },
        });

        // focus into previous field
        if (this.state.fieldInFocus !== "") {
            let focusingField = this.state.fieldInFocus;
            setTimeout(function () {
                document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
            }, 0);
        }
    }

    hideNotificationCityMsg = (event) => {
        try {
            event ? event.preventDefault() : "";

            // focus into previous field
            if (this.state.fieldInFocus !== "") {
                let focusingField = this.state.fieldInFocus;
                setTimeout(function () {
                    document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
                }, 0);
            }
        } catch (ex) { }
        this.setState({ hasNotificationCityMsg: false });
        return false;
    }
    updateDataCityInfo = () => {
        const newCity = this.state.updatedDataCityInfo;
        this.setState({
            diagnosisForm: {
                ...this.state.diagnosisForm,
                dxCity: newCity
            },
            hasNotificationCityMsg: false,
            updatedDataCityInfo: null,
            isFormDirty: true,
            // clear IN_VALID flag if it's existing
            dAllItemFields: {
                ...this.state.dAllItemFields,
                dxCity: "",
            },
        });

        // focus into previous field
        if (this.state.fieldInFocus !== "") {
            let focusingField = this.state.fieldInFocus;
            setTimeout(function () {
                document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
            }, 0);
        }
    }

    hideNotificationSiteCodeMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        setTimeout(function () {
            document.getElementById("topoCode") ? document.getElementById("topoCode").focus() : "";
        }, 100);

        this.setState({ hasNotificationSiteCodeMsg: false });
        return false;
    }
    updateDataSiteCodeFnc = () => {
        const newSiteCode = this.state.updatingSiteCodeNewValue;
        this.setState({
            diagnosisForm: {
                ...this.state.diagnosisForm,
                siteCode: newSiteCode
            },
            hasNotificationSiteCodeMsg: false,
            currentOldValueSiteCode: newSiteCode,
            //updatedSiteCodeFlag: true,
            isFormDirty: true,
        });

        var ps = this.props.selectedPrimarySummary;
        var _this = this;
        // Also update primary summary record
        var _param = { pid: ps.pid, cid: ps.cid, val: newSiteCode, accessToken: localStorage.getItem('token') };
        axios.post(this.props.clientgroup + "/UpdateSiteCode", _param)
            .then(res => {
                if (res.data.error && res.data.message !== null && res.data.message !== "") {
                    console.log(res.data.message);
                    return false;
                } else {
                    //ps["siteCode"] = newSiteCode;
                    // reload all changes of selectedPrimarySummary again
                    this.props.selectPatient(ps.pid, false, true);
                }

                //update new accessToken again
                var newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }
            })
            .catch(function (error) {
                console.log(error);
                _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        setTimeout(function () {
            document.getElementById("topoCode") ? document.getElementById("topoCode").focus() : "";
        }, 100);
    }

    // Fixing #PR00066: Non-Analytic default functionality - should only default blank fields
    setDiagnosisDataDefault = () => {
        // default values
        const objDefault = {
            dxAddress: "UNKNOWN",
            dxCity: "UNKNOWN",
            dxState: "ZZ",
            dxZip: "99999",
            dxCounty: "999",
            dxCountry: "999",
            grade: "9",
            primPayerDx: "99",
            dxMaritalStatus: "9"
        };

        let diagnosisFormData = this.state.diagnosisForm;

        Object.keys(diagnosisFormData).map((t, k) => {
            if (t === "dxAddress" || t === "dxCity") {
                if (diagnosisFormData[t] === null || diagnosisFormData[t] === "") {
                    diagnosisFormData[t] = "UNKNOWN";
                }
            } else if (t === "dxState") {
                if (diagnosisFormData[t] === null || diagnosisFormData[t] === "") {
                    diagnosisFormData[t] = "ZZ";
                }
            } else if (t === "dxZip") {
                if (diagnosisFormData[t] === null || diagnosisFormData[t] === "") {
                    diagnosisFormData[t] = "99999";
                }
            } else if (t === "dxCounty" || t === "dxCountry") {
                if (diagnosisFormData[t] === null || diagnosisFormData[t] === "") {
                    diagnosisFormData[t] = "999";
                }
            } else if (t === "primPayerDx") {
                if (diagnosisFormData[t] === null || diagnosisFormData[t] === "") {
                    diagnosisFormData[t] = "99";
                }
            } else if (t === "grade" || t === "dxMaritalStatus") {
                if (diagnosisFormData[t] === null || diagnosisFormData[t] === "") {
                    diagnosisFormData[t] = "9";
                }
            }
        });

        diagnosisFormData["naDefault"] = "Yes";

        this.setState({ diagnosisForm: { ...diagnosisFormData }, isFormDirty: true });
    }

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: true });
    }

    setIDCOption = (event) => {
        this.setState({
            icd10Opt: event.target.value
        });
    }

    showTextDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isShowTextVisible: true });
        return false;
    }

    closeTextDialog = (event) => {
        try {
            event ? event.preventDefault() : "";

            // focus into previous field
            if (this.state.fieldInFocus !== "") {
                let focusingField = this.state.fieldInFocus;
                setTimeout(function () {
                    document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
                }, 0);
            }
        } catch (ex) { }

        this.setState({ isShowTextVisible: false });
        this.props.closeTextDialog(false);

        return false;
    }

    componentWillUnmmount() {
        this.setState({
            diagnosisForm: null,
            resetFormDirtyFlag: false,
            isFormDirty: false,
            reloadPage: false,
            confirmedCodesChange: false,
            reloadAgeDx: false,
            ageDx: null,
        });
    }

    componentWillMmount() {
        this.setState({
            diagnosisForm: null,
            resetFormDirtyFlag: false,
            isFormDirty: false,
            reloadPage: false,
            confirmedCodesChange: false,
            reloadAgeDx: false,
            ageDx: null,
        });
    }

    componentDidMount() {
        this.props.closeAddNewDialog();
        if (!this.state.diagnosisForm && this.props.selectedDiagnosisRecord) {
            this.setFormData(this.props.selectedDiagnosisRecord);
        }
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.diagnosisForm && this.props.selectedDiagnosisRecord)
            || (prevProps.selectedDiagnosisRecord && this.props.selectedDiagnosisRecord && prevProps.selectedDiagnosisRecord !== this.props.selectedDiagnosisRecord)
        ) {
            this.setFormData(this.props.selectedDiagnosisRecord);
            this.props.showPageLoading(false);
        } else if (this.state.diagnosisForm && !this.state.reloadPage && this.props.selectedMedicalRecord && prevProps.selectedMedicalRecord && this.props.selectedDiagnosisRecord && prevProps.selectedMedicalRecord != this.props.selectedDiagnosisRecord) {
            this.setFormData(this.props.selectedMedicalRecord, true);
        } else if (this.props.initialAbstractPages && this.props.selectedDiagnosisRecord) {
            this.setFormData(this.props.selectedDiagnosisRecord);
        }

        if (!this.state.resetFormDirtyFlag && this.props.isFormDirty && this.state.isFormDirty != this.props.isFormDirty) {
            this.setState({ resetFormDirtyFlag: true, isFormDirty: this.props.isFormDirty });
        }

        if (!this.state.reloadAgeDx && this.props.selectedPatient && this.props.selectedPrimarySummary) {
            // This calculation is referenced by [EXEC uspCalcAgeDx @CID, @Dob, @Diagnosis_Date] from db
            var _MonthDob = this.props.selectedPatient && this.props.selectedPatient.dobMonth ? this.props.selectedPatient.dobMonth : null;
            if (_MonthDob) _MonthDob = parseInt(_MonthDob);
            var _DayDob = this.props.selectedPatient && this.props.selectedPatient.dobDay ? this.props.selectedPatient.dobDay : null;
            if (_DayDob) _DayDob = parseInt(_DayDob);

            var _MonthDayDob = 0;
            if (_MonthDob && _DayDob) {
                _MonthDayDob = _MonthDob * 100 + _DayDob;
            }

            var _MonthDx = this.props.selectedPrimarySummary && this.props.selectedPrimarySummary.diagnosisDateMonth ? this.props.selectedPrimarySummary.diagnosisDateMonth : null;
            if (_MonthDx) _MonthDx = parseInt(_MonthDx);
            var _DayDx = this.props.selectedPrimarySummary && this.props.selectedPrimarySummary.diagnosisDateDay ? this.props.selectedPrimarySummary.diagnosisDateDay : null;
            if (_DayDx) _DayDx = parseInt(_DayDx);

            var _MonthDayDx = 0;
            if (_MonthDx && _DayDx) {
                _MonthDayDx = _MonthDx * 100 + _DayDx;
            }

            var _Dob = moment(this.props.selectedPatient.dobMonth + "/" + this.props.selectedPatient.dobDay + "/" + this.props.selectedPatient.dobYear, "MM/DD/YYYY");
            var _DiagnosisDate = moment(this.props.selectedPrimarySummary.diagnosisDateMonth + "/" + this.props.selectedPrimarySummary.diagnosisDateDay + "/" + this.props.selectedPrimarySummary.diagnosisDateYear, "MM/DD/YYYY");

            //var _ageDx = Math.floor(_DiagnosisDate.diff(_Dob, 'years', true));
            //this.calcAgeDxVal(_Dob.format("MM/DD/YYYY"), _DiagnosisDate.format("MM/DD/YYYY"));

            let sd = _Dob.format("MM/DD/YYYY");
            let ed = _DiagnosisDate.format("MM/DD/YYYY");

            let url = this.props.clientgroup + "/CalcAgeDxVal";
            var _this = this;
            axios.post(url, { sd: sd, ed: ed, accessToken: localStorage.getItem('token') })
                .then(function (response) {
                    //update new accessToken again
                    var newAccessToken = response.data && response.data.accessToken ? response.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    var _ageDx = response && response.data && response.data.ageDx ? response.data.ageDx : "";
                    if (_MonthDayDob > _MonthDayDx) {
                        _ageDx = _ageDx - 1;
                    }

                    if (_ageDx > 999 || _ageDx < 0) _ageDx = 0;

                    _this.setState({ reloadAgeDx: true, ageDx: _ageDx });
                    return;
                })
                .catch(function (error) {
                    console.log(error);
                    _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);
                    /*
                    //redirect to Login page by clicking the Logout button
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }

        /*
        // Purpose to set process flag to retrieve the value of allowSaveAnyway from store
        if (this.props.allowSaveAnyway != prevProps.allowSaveAnyway && this.props.allowSaveAnyway != this.state.allowSaveAnyway) {
            this.setState({ allowSaveAnyway: this.props.allowSaveAnyway });
        }
        */

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.diagnosisForm && this.state.dAllItemFields
        ) {
            //console.log("[Diagnosis] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    diagnosisForm: { ...this.state.diagnosisForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {

                // Fixing #PR00272
                if (this.props.currentSelectedField === "primSurgeon"
                    || this.props.currentSelectedField === "mngPhysician"
                    || this.props.currentSelectedField === "folPhysician"
                    || this.props.currentSelectedField === "radOncologist"
                    || this.props.currentSelectedField === "medOncologist"
                    || this.props.currentSelectedField === "qaPhysician"
                    || this.props.currentSelectedField === "refPhysician"
                    || this.props.currentSelectedField === "othPhys1"
                    || this.props.currentSelectedField === "othPhys2"
                    || this.props.currentSelectedField === "othPhys3"
                    || this.props.currentSelectedField === "othPhys4"
                    // Hospital fields
                    || this.props.currentSelectedField === "hospRefFrom"
                    || this.props.currentSelectedField === "hospRefTo"
                ) {
                    let currentSelectedField = this.props.currentSelectedField;
                    this.setState({ showConfirmationToAddNew: true, selectedFieldNoExistingVal: currentSelectedField, calledByMe: true });
                } else {
                    this.setState({
                        dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        diagnosisForm: { ...this.state.diagnosisForm, [this.props.currentSelectedField + "Name"]: "" },
                        calledByMe: false,
                    });
                }
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null && !this.state.hasSetMaxLength) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async calcAgeDxVal(sd, ed) {
        let url = this.props.clientgroup + "/CalcAgeDxVal";
        var _this = this;
        let res = await axios
            .post(url, { sd: sd, ed: ed, accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        this.setState({
            reloadAgeDx: true,
            ageDx: res && res.data && res.data.ageDx ? res.data.ageDx : ""
        });

        //update new accessToken again
        var newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        return false;
    }

    async reloadAgeDxFnc(cid) {
        let url = this.props.clientgroup + "/GetDiagnosisAgeDx";
        var _this = this;
        let res = await axios
            .post(url, { cid: cid, accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        this.setState({
            reloadAgeDx: true,
            ageDx: res && res.data && res.data.ageDx ? res.data.ageDx : ""
        });

        //update new accessToken again
        var newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        return false;
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "Diagnosis", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res.data && res.data.result ? res.data.result : null
        });
    }

    pathChartLookup = async () => {
        var Dx_Year = this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.diagnosisDateYear : 0;
        if (Dx_Year < 2024 || this.state.shownMsg) {
            return false;
        }

        let url = this.props.clientgroup + "/PathChartLookup";
        let diagnosisFormData = this.state.diagnosisForm;

        let topo = diagnosisFormData ? diagnosisFormData["topoCode"] : null;
        let hist = diagnosisFormData ? diagnosisFormData["histCode"] : null;
        if (!topo || !hist) return false;
        let res = await axios
            .post(url, { topo: topo, hist: hist, accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            statusPc: res.data && res.data.code ? res.data.code : null,
            isShowValidationPcError: res.data && res.data.code ? res.data.code == "2" || res.data.code == "3" : null,
            shownMsg: true
        });
    }

    hideValidationPcError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            isShowValidationPcError: false
        });
        this.props.setChildrenOpening(false);

        //
        setTimeout(function () {
            // set focus to selected field
            let input = document.getElementById("topoCode");
            input ? input.focus() : "";
            return false;
        }, 100);

        return false;
    }

    setFormData = (diagnosisFormData, reloaded) => {
        //let diagnosisFormData = this.props.selectedDiagnosisRecord;

        // store ids into session
        // Session.currentPID = current selected Patient id
        const currentPID = this.props.patientId;
        const sessionPID = sessionStorage.getItem('currentPID');
        if (currentPID !== undefined && currentPID !== null
            && (sessionPID === null || sessionPID !== currentPID)) {
            sessionStorage.setItem('currentPID', currentPID);
        }
        // Session.currentCID = current selected Patient Diagnosis Primary id
        const currentCID = diagnosisFormData.cid;
        const sessionCID = sessionStorage.getItem('currentCID');
        if (currentCID !== undefined && currentCID !== null
            && (sessionCID === null || sessionCID !== currentCID)) {
            sessionStorage.setItem('currentCID', currentCID);
        }

        Object.keys(diagnosisFormData).map((t, k) => {
            if (diagnosisFormData[t] === null) {
                diagnosisFormData[t] = "";
            }
        });

        this.setState({
            diagnosisForm: {
                ...diagnosisFormData,
                ["ncdbSarsCov2PositiveDate"]: (this.props.selectedDiagnosisRecord != null && this.props.selectedDiagnosisRecord.ncdbSarsCov2PositiveDate !== null && this.props.selectedDiagnosisRecord.ncdbSarsCov2PositiveDate !== "" ? moment(new Date(this.props.selectedDiagnosisRecord.ncdbSarsCov2PositiveDate)).format("MM/DD/YYYY") : ""),
            },
            isFormDirty: false,//this.props.isFormDirty,
            resetFormDirtyFlag: false,
            reloadPage: reloaded,
            ageDx: diagnosisFormData ? diagnosisFormData.ageDx : ""
        });

        if (!this.state.dAllItemFields && this.props.selectedDiagnosisRecord) {
            let fields = {};
            Object.keys(this.props.selectedDiagnosisRecord).map((t) => {
                fields[t] = "";
            });

            this.setState({ dAllItemFields: fields, pageMaxlengthInput: fields });
        };

        this.props.setInitialAbstractPages(false);

        var Dx_Year = this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.diagnosisDateYear : "";
        var Previous_Topo = this.props.selectedDiagnosisRecord["topoCode"];
        var Previous_Hist = this.props.selectedDiagnosisRecord["histCode"];
        var Topo = diagnosisFormData["topoCode"];
        var Hist = diagnosisFormData["histCode"];
        if (((Previous_Topo != '' && Previous_Topo != Topo)
            || (Previous_Hist != '' && Previous_Hist != Hist)
            || (Previous_Topo != '' && Previous_Topo.length == 4 && Previous_Hist != '' && Previous_Hist.length == 5))
            && Dx_Year >= 2024 && diagnosisFormData) {
            this.pathChartLookup(false);
        }

        return false;
    }

    // Add clear and check validate of date fields function -- START
    clearInvalidOfDateFields = (formItems, field, onGrid, index) => {
        let tYear = field + "Year";
        let tMonth = field + "Month";
        let tDay = field + "Day";
        if (onGrid && onGrid === "__thisPageNoHasList__") {
            this.setState(prevState => {
                let newItems = [...prevState.primaryItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { primaryItemFields: newItems };
            });
        } else {
            this.setState({
                dAllItemFields: {
                    ...this.state.dAllItemFields,
                    [tYear]: "",
                    [tMonth]: "",
                    [tDay]: "",
                }
            });
        }
        formItems[tYear] = "";
        formItems[tMonth] = "";
        formItems[tDay] = "";
    }

    checkValidateOfDateFields = (pFormData, field, onGrid, index) => {
        let isValidDateInput = true;

        var tYear = field + "Year";
        var tMonth = field + "Month";
        var tDay = field + "Day";

        // Check valid of date in February
        var year = pFormData[tYear] ? pFormData[tYear] : "";
        var month = pFormData[tMonth] ? pFormData[tMonth] : "";
        var day = pFormData[tDay] ? pFormData[tDay] : "";

        pFormData[field] = new Date(year + "-" + month + "-" + day);

        if (year !== "" && month !== "" && day !== "") {
            var _day, A = (month + '/' + day + '/' + year).match(/[1-9][\d]*/g);
            try {
                A[0] -= 1;
                _day = new Date(+A[2], A[0], +A[1]);
                if ((_day.getMonth() == A[0] && _day.getDate() == A[1]) || month == '99' || day == '99') {
                } else {
                    isValidDateInput = false;

                    this.setState({
                        dAllItemFields: {
                            ...this.state.dAllItemFields,
                            [tYear]: "IN_VALID",
                            [tMonth]: "IN_VALID",
                            [tDay]: "IN_VALID",
                        }
                    });
                }
            } catch (er) {
                isValidDateInput = false;
            }
        }

        return isValidDateInput;
    }
    // -- END

    saveDiagnosis = async (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        if (allowSaveAnyway) {
            let diagnosisFormData = this.state.diagnosisForm;
            var Dx_Year = this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.diagnosisDateYear : "";
            var Previous_Topo = this.props.selectedDiagnosisRecord["topoCode"];
            var Previous_Hist = this.props.selectedDiagnosisRecord["histCode"];
            var Topo = diagnosisFormData["topoCode"];
            var Hist = diagnosisFormData["histCode"];
            if (((Previous_Topo != '' && Previous_Topo != Topo) || (Previous_Hist != '' && Previous_Hist != Hist)) && Dx_Year >= 2018) {
                if (!this.props.waitingFor && !this.state.confirmedCodesChange) {
                    this.setState({ hasNotificationSaveMsg: true });
                    return false;
                }
            }
        }

        let formItems = this.state.dAllItemFields;
        Object.keys(formItems).map((t) => {// clear IN_VALID flag for all date fields
            if (t === "firstContDate" || t === "admitDate" || t === "dischDate") {
                this.clearInvalidOfDateFields(formItems, t);
            }

            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let diagnosisFormData = this.state.diagnosisForm;

            if (diagnosisFormData["ncdbSarsCov2PositiveDate"] == "Invalid date") {
                diagnosisFormData["ncdbSarsCov2PositiveDate"] = null;
            }

            //#20210125 - START
            // append date fields from m/d/y
            let isValidDateInput = true;
            Object.keys(diagnosisFormData).map((t, k) => {
                if (t === "firstContDate" || t === "admitDate" || t === "dischDate") {
                    isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(diagnosisFormData, t);
                }
            });
            //#20210125 - END

            //Abstract Date that need validation adjustments.
            let posDate = diagnosisFormData["ncdbSarsCov2PositiveDate"];
            if ((posDate && !moment(posDate, "MM/DD/YYYY", true).isValid())) {
                alert('Please input a valid date');
                document.getElementById("ncdbSarsCov2PositiveDate") ? document.getElementById("ncdbSarsCov2PositiveDate").focus() : null;
                return false;
            }

            if (!isValidDateInput && !allowSaveAnyway) return false;

            //
            var Dx_Year = this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.diagnosisDateYear : "";
            var Previous_Topo = this.props.selectedDiagnosisRecord["topoCode"];
            var Previous_Hist = this.props.selectedDiagnosisRecord["histCode"];
            var Topo = diagnosisFormData["topoCode"];
            var Hist = diagnosisFormData["histCode"];
            if (((Previous_Topo != '' && Previous_Topo != Topo) || (Previous_Hist != '' && Previous_Hist != Hist)) && Dx_Year >= 2018 && !this.state.confirmedCodesChange) {
                this.setState({ hasNotificationSaveMsg: true });
                return false;
            }

            diagnosisFormData.pid = (this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.pid : 0);

            await this.props.saveDiagnosis(diagnosisFormData);

            this.setState({ isFormDirty: false, resetFormDirtyFlag: false });

            return false;
        }
    }

    resetDiagnosis = () => {
        let fields = {};
        Object.keys(this.state.dAllItemFields).map((t) => {
            fields[t] = "";
        });
        this.setState({
            diagnosisForm: { ...this.state.initialDiagnosisForm },
            dAllItemFields: fields,
            isFormDirty: false,
        });
        return false;
    }

    handleOnFocus = (event) => {
        event.preventDefault();

        let name = event.target.name;
        if (name === "psOrder" || name === "mpOrder" || name === "fpOrder"
            || name === "roOrder" || name === "moOrder" || name === "qpOrder"
            || name === "rpOrder" || name === "op1Order" || name === "op2Order"
            || name === "op3Order" || name === "op4Order") {
            // Fixing #PR00293
            this.setState({ fieldInFocus: null, focusingField: event.target, isHelpVisible: false });

        } else {
            this.setState({ fieldInFocus: event.target.name, focusingField: event.target, isHelpVisible: false });
        }
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            this.props.checkValidInput(name, value, table, key, -1, flag);

            if (name === "primSurgeon" || name === "mngPhysician" || name === "folPhysician" || name === "radOncologist"
                || name === "medOncologist" || name === "qaPhysician" || name === "refPhysician"
                || name === "othPhys1" || name === "othPhys2" || name === "othPhys3" || name === "othPhys4"
                || name === "hospRefFrom" || name === "hospRefTo"
            ) {
                this.setState({ newValueToAddNew: value });
            }

            if (name === "topoCode" || name === "histCode") {
                this.props.updateDiagnosisInfo(event.target.name, event.target.value);

                //prj_v24
                this.pathChartLookup(!this.state.shownMsg);
            }

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            var value = event.target.value ? event.target.value : event.target.defaultValue;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState({
                        diagnosisForm: { ...this.state.diagnosisForm, [event.target.name]: value },
                        dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" }
                    });
                } else {
                    this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
                }
            } else if (mdy == 'year' && value.length == 2) {
                let currentYear = (new Date().getFullYear() + "");
                let yyVal = currentYear.substr(2, 2);
                if (yyVal < value) {
                    value = (currentYear.substr(0, 2) - 1) + value;
                } else {
                    value = currentYear.substr(0, 2) + value;
                }

                this.setState({ diagnosisForm: { ...this.state.diagnosisForm, [event.target.name]: value } });
            } else {
                // clear all error style
                this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
            }

            // Fixing issue of #PR00062
            if (event.target.name === "firstContDateYear") {
                var _this = this;
                var _fName = event.target.name;
                setTimeout(function () {
                    //let diagnosisFormData = _this.state.diagnosisForm;
                    //if (_this.checkValidateOfDateFields(diagnosisFormData, "firstContDate") /*&& diagnosisFormData["accYr"] === "" && event.target.value !== "9999"*/) {
                    //let value = event.target.value;
                    if (value.length == 2) {
                        let currentYear = (new Date().getFullYear() + "");
                        let yyVal = currentYear.substr(2, 2);
                        if (yyVal < value) {
                            value = (currentYear.substr(0, 2) - 1) + value;
                        } else {
                            value = currentYear.substr(0, 2) + value;
                        }
                    }

                    _this.setState({
                        diagnosisForm: {
                            ..._this.state.diagnosisForm,
                            ["accYr"]: value,
                            [_fName]: value
                        }
                    });
                    //}
                }, 100);
            }
            //#20210125 - END

        } else {
            this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });

            //
            var name = event.target.name;
            var value = event.target.value;

            if (value === "" &&
                (name === "primSurgeon" || name === "mngPhysician" || name === "folPhysician" || name === "radOncologist"
                    || name === "medOncologist" || name === "qaPhysician" || name === "refPhysician"
                    || name === "othPhys1" || name === "othPhys2" || name === "othPhys3" || name === "othPhys4"
                    || name === "hospRefFrom" || name === "hospRefTo")) {
                this.setState({
                    diagnosisForm: {
                        ...this.state.diagnosisForm,
                        [event.target.name + "Name"]: "",//Also need to clear name fields
                    }
                });
            }

            /*---------------------------------------------------------------------------------------------------------*/
            //#PR00058: Dx Address does not pull from Patient screen when tabbed through
            // format text again
            if (name === "dxAddress" && value === "") {
                const newVal = this.props.selectedPatient.currAdd;
                this.setState({
                    diagnosisForm: { ...this.state.diagnosisForm, [name]: newVal },
                    isFormDirty: (newVal !== null && newVal !== "") ? true : this.state.isFormDirty
                });
            } else if (name === "dxAddress2" && value === "") {
                const currAdd = this.props.selectedPatient.currAdd;
                const dxAdd = this.state.diagnosisForm.dxAddress;
                if (currAdd === dxAdd) {
                    const newVal = this.props.selectedPatient.currAdd2;
                    this.setState({
                        diagnosisForm: { ...this.state.diagnosisForm, [name]: newVal },
                        isFormDirty: (newVal !== null && newVal !== "") ? true : this.state.isFormDirty
                    });
                }
            } else if (name === "dxCity" && value === "") {
                const newVal = this.props.selectedPatient.currCity;
                this.setState({
                    diagnosisForm: { ...this.state.diagnosisForm, [name]: newVal },
                    isFormDirty: (newVal !== null && newVal !== "") ? true : this.state.isFormDirty
                });
            } else if (name === "dxState" && value === "") {
                const newVal = this.props.selectedPatient.currSt;
                this.setState({
                    diagnosisForm: { ...this.state.diagnosisForm, [name]: newVal },
                    isFormDirty: (newVal !== null && newVal !== "") ? true : this.state.isFormDirty
                });
            } else if (name === "dxZip") {
                if (value === "") {
                    const newVal = this.props.selectedPatient.currZip;
                    this.setState({
                        diagnosisForm: { ...this.state.diagnosisForm, [name]: newVal },
                        isFormDirty: (newVal !== null && newVal !== "") ? true : this.state.isFormDirty
                    });
                } else {
                    var oldVal = value;
                    var newVal = oldVal.replace(/[^A-Za-z0-9]/gi, '').toUpperCase();
                    var regionUSA = false;
                    if (oldVal != '') {
                        //Canadian
                        if (isNaN(newVal) == true) {
                            if (newVal.length >= 4) {
                                newVal = newVal.substring(0, 3) + " " + newVal.substring(3, 6);
                            }

                            //US
                        } else {
                            newVal = oldVal.replace(/[^0-9]/gi, '');
                            if (newVal.length >= 6) {
                                newVal = newVal.substring(0, 5) + "-" + newVal.substring(5, 9);
                            }
                            regionUSA = true;
                        }
                    }

                    this.setState({ diagnosisForm: { ...this.state.diagnosisForm, [name]: newVal } });

                    // @TODO: zip code entry should tell me the city/state to let me select it
                    var city = this.state.diagnosisForm.dxCity !== null ? this.state.diagnosisForm.dxCity.toUpperCase() : "";
                    var state = this.state.diagnosisForm.dxState !== null ? this.state.diagnosisForm.dxState.toUpperCase() : "";
                    var county = this.state.diagnosisForm.dxCounty;

                    // ERSValidateCityStateZip/ers_citystate_from_zip
                    //if (regionUSA) {
                    // 
                    var zip = newVal;
                    var url = "https://production.shippingapis.com/ShippingAPITest.dll?API=CityStateLookup&XML=<CityStateLookupRequest USERID='968ELECT7482'><ZipCode ID= '1'><Zip5>" + zip + "</Zip5></ZipCode></CityStateLookupRequest>";
                    axios.get(url)
                        .then(response => {
                            const raw = response.data;
                            const parser = new DOMParser();
                            const xml = parser.parseFromString(raw, 'text/xml');//

                            var _city = xml.querySelector('City') ? xml.querySelector('City').textContent : "";
                            var _state = xml.querySelector('State') ? xml.querySelector('State').textContent : "";
                            if (zip === "88888") {
                                _city = "";
                                _state = "";
                            } else {
                                _city = _city.toUpperCase();
                                _state = _state.toUpperCase();
                            }

                            // City
                            if (_city !== "" && _city !== city && city !== null && city !== "") {
                                this.setState({
                                    hasNotificationCityMsg: true,
                                    updatedDataCityInfo: _city,
                                    updatingItemCity: "dxCity",
                                });
                            } else if (_city !== "" && _city !== city && (city === null || city === "")) {
                                this.setState({
                                    diagnosisForm: { ...this.state.diagnosisForm, ["dxCity"]: _city }
                                });
                            }

                            // State
                            if (_state !== "" && _state !== state && state !== null && state !== "") {
                                this.setState({
                                    hasNotificationStateMsg: true,
                                    updatedDataStateInfo: _state,
                                    updatingItemState: "dxState",
                                });
                            } else if (_state !== "" && _state !== state && (state === null || state === "")) {
                                this.setState({
                                    diagnosisForm: { ...this.state.diagnosisForm, ["dxState"]: _state }
                                });
                            }

                            var _param = { zip: value, accessToken: localStorage.getItem('token') };
                            axios.post(this.props.clientgroup + "/InquireRelatedInfo", _param)
                                .then(res => {
                                    //update new accessToken again
                                    var newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
                                    if (newAccessToken) {
                                        localStorage.setItem('token', newAccessToken);
                                    }

                                    if (!res.data.error && res.data.message !== null && res.data.message !== "") {
                                        const info = res.data.message;
                                        let arr = info.split('|');

                                        // County
                                        if (arr[1] !== "" && arr[1] !== county && county !== null && county !== "") {
                                            this.setState({
                                                hasNotificationCountyMsg: true,
                                                updatedDataCountyInfo: arr[1],
                                                updatingItemCounty: "dxCounty",
                                            });
                                        } else if (arr[1] !== "" && arr[1] !== county && (county === null || county === "")) {
                                            this.setState({
                                                diagnosisForm: { ...this.state.diagnosisForm, ["currCnty"]: arr[1] }
                                            });
                                        }

                                        return false;
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    /*
                                    //redirect to Login page by clicking the Logout button
                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }
                                    */
                                    //@TODO:...

                                    return false;
                                });
                        });

                    //return false;
                    //}
                }
            } else if (name === "dxCounty" && value === "") {
                //const newVal = this.props.selectedPatient.currCnty;
                //this.setState({ diagnosisForm: { ...this.state.diagnosisForm, [name]: newVal } });

                const newVal = this.props.selectedPatient.currCnty;
                this.setState({
                    diagnosisForm: { ...this.state.diagnosisForm, [name]: newVal },
                    isFormDirty: (newVal !== null && newVal !== "") ? true : this.state.isFormDirty
                });

            } else if (name === "dxCountry" && value === "") {
                //const newVal = this.props.selectedPatient.currCountry;
                //this.setState({ diagnosisForm: { ...this.state.diagnosisForm, [name]: newVal } });

                const newVal = this.props.selectedPatient.currCountry;
                this.setState({
                    diagnosisForm: { ...this.state.diagnosisForm, [name]: newVal },
                    isFormDirty: (newVal !== null && newVal !== "") ? true : this.state.isFormDirty
                });

            } else if (name === "firstContDateYear" /*&& value !== ""*/) {
                //let diagnosisFormData = this.state.diagnosisForm;
                //if (this.checkValidateOfDateFields(diagnosisFormData, "firstContDate")/* && diagnosisFormData["accYr"] === "" && value !== "9999"*/) {
                this.setState({ diagnosisForm: { ...this.state.diagnosisForm, ["accYr"]: value } });
                //}
            } else if ((name === "psOrder" || name === "mpOrder" || name === "fpOrder"
                || name === "roOrder" || name === "moOrder" || name === "qpOrder"
                || name === "rpOrder" || name === "op1Order" || name === "op2Order"
                || name === "op3Order" || name === "op4Order") && value !== "") {
                this.checkUniqueOrder(name, value);

            } else if (name === "ncdbSarsCov2PositiveDate") {
                //This code tries to fill in slashes and the two digit century when a user doesn't enter them
                var myDate = value;
                var fmtDate = "";

                if (myDate.length == 8 && myDate.indexOf('/') == -1) {
                    fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/" + myDate.substr(4, 4);
                } else if (myDate.length == 8 && myDate.indexOf('/') >= 1) {
                    var d = new Date().toLocaleDateString();
                    if (myDate.substr(6, 2) > d.substr(d.length - 2, 2)) {
                        fmtDate = myDate.substr(0, 6) + "19" + myDate.substr(6, 2);
                    } else {
                        fmtDate = myDate.substr(0, 6) + "20" + myDate.substr(6, 2);
                    }
                } else if (myDate.length == 6 && myDate.indexOf('/') == -1) {
                    var d = new Date().toLocaleDateString();
                    if (myDate.substr(4, 2) > d.substr(d.length - 2, 2)) {
                        fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/19" + myDate.substr(4, 2);
                    } else {
                        fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/20" + myDate.substr(4, 2);
                    }
                } else {
                    fmtDate = myDate;
                }

                if (myDate != fmtDate) {
                    this.setState({ diagnosisForm: { ...this.state.diagnosisForm, [name]: fmtDate } });
                }

            } else if (event.target.name === "topoCode" || event.target.name === "histCode") {
                this.props.updateDiagnosisInfo(event.target.name, event.target.value);

                //prj_v24
                this.pathChartLookup(!this.state.shownMsg);

            } else if (event.target.name === "accYr") {
                var isValid = 'true';
                var firstTwo = value.substring(0, 2);
                var isNotNum = isNaN(value);
                var currentYear = new Date().getFullYear();
                var accYearInt = parseInt(value);

                if (isNotNum) {
                    isValid = 'false';
                }
                if (value.length != 4) {
                    isValid = 'false';
                }
                if (firstTwo != '20' && firstTwo != '19') {
                    isValid = 'false';
                }
                if (accYearInt > currentYear) {
                    isValid = 'false';
                }

                if (isValid == 'false') {
                    this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "IN_VALID" } });
                }
            }
            /*---------------------------------------------------------------------------------------------------------*/
        }
    }
    // --END

    checkUniqueOrder = (fName, fValue) => {
        let diagnosisForm = this.state.diagnosisForm;
        let isValid = true;
        Object.keys(diagnosisForm).map((name) => {
            if (name === "psOrder" || name === "mpOrder" || name === "fpOrder"
                || name === "roOrder" || name === "moOrder" || name === "qpOrder"
                || name === "rpOrder" || name === "op1Order" || name === "op2Order"
                || name === "op3Order" || name === "op4Order") {
                if (fName !== name && fValue === diagnosisForm[name]) {
                    return isValid = false;
                }
            }
        });

        if (!isValid) {
            alert('Order values must be unique. ' + fValue + ' has been used.');
            this.setState({ diagnosisForm: { ...this.state.diagnosisForm, [fName]: "" } });

            setTimeout(function () {
                document.getElementById(fName).focus();
            }, 0);
            return false;
        }
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                diagnosisForm: {
                    ...this.state.diagnosisForm, [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                confirmedCodesChange: false,
                isFormDirty: true
            });
        } else {
            this.setState({
                diagnosisForm: { ...this.state.diagnosisForm, [event.target.name]: event.target.value },
                confirmedCodesChange: false,
                isFormDirty: true
            });
        }

        // Fixing #PR00064: TOPO code change - does not ask to change site code
        var siteCode = this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.siteCode : "";
        if (siteCode === null || siteCode === undefined) {
            siteCode = "";
        }
        if (event.target.name === "topoCode" && event.target.value !== "" && event.target.value.length == 4) {
            var newSite = event.target.value.substring(1, 3);
            if (siteCode !== newSite) {
                this.setState({
                    hasNotificationSiteCodeMsg: true,
                    updatingSiteCodeNewValue: newSite
                });
            }
        }

        //prj_v24
        //@TODO:...
        if ((event.target.name === "topoCode" && event.target.value !== "") || (event.target.name === "histCode" && event.target.value !== "")) {
            this.setState({ shownMsg: false });
        }
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";

            if (this.state.fieldInFocus !== "") {
                let focusingField = this.state.fieldInFocus;
                setTimeout(function () {
                    document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
                }, 0);
            }
        } catch (ex) { }
        this.setState({ isHelpVisible: false });
        return false;
    }

    render() {
        //prj_v24
        let validationPcError;
        {
            validationPcError = !this.props.inquiringMode && this.state.isShowValidationPcError ? /*this.props.setChildrenOpening(true) &&*/
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className={this.props.globalMsg && this.props.globalMsg !== "" ? "dragableWindowCls" : "dragableSmallWindowCls"}>
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Cancer PathCHART Validation</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideValidationPcError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">{this.state.statusPc == "2" ? "This is an unlikely combination or unlisted. Please verify" : "This is an impossible combination."}</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideValidationPcError(e, this) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        // Fixing #PR00272
        let newForm = "Physicians";
        if (this.state.selectedFieldNoExistingVal === "hospRefFrom" || this.state.selectedFieldNoExistingVal === "hospRefTo") {
            newForm = "Hospital";
        }

        let showConfirmationToAddNew;
        {
            showConfirmationToAddNew = this.state.showConfirmationToAddNew ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Confirmation</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmationToAddNew(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>{newForm} does not exist. Choose OK to add a new {newForm}.</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmationToAddNew} value="yes" autoFocus>OK</button>
                                <button onClick={this.hideConfirmationToAddNew} value="no">Cancel</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let hasNotificationSaveMsg;
        {
            hasNotificationSaveMsg = this.state.hasNotificationSaveMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Notification</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideNotificationSaveMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>
                                    Saving this change may result in a different schema classification.<br />
                                    Please visit the staging screen and ensure applicable SSDI fields have been completed.
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button className="validationErr_btnOk_Cls" onClick={this.hideNotificationSaveMsg} autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let hasNotificationStateMsg;
        {
            hasNotificationStateMsg = this.state.hasNotificationStateMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Notification</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideNotificationStateMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>
                                    Suggested state for this zip code is '{this.state.updatedDataStateInfo}'.<br />
                                    Would you like to update the state to {this.state.updatedDataStateInfo}?
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={this.updateDataStateInfo} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideNotificationStateMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }
        let hasNotificationCountyMsg;
        {
            hasNotificationCountyMsg = this.state.hasNotificationCountyMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Notification</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideNotificationCountyMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>
                                    Suggested county for this zip code is '{this.state.updatedDataCountyInfo}'.<br />
                                    Would you like to update the county to {this.state.updatedDataCountyInfo}?
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={this.updateDataCountyInfo} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideNotificationCountyMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }
        let hasNotificationCityMsg;
        {
            hasNotificationCityMsg = this.state.hasNotificationCityMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Notification</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideNotificationCityMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>
                                    Suggested city for this zip code is '{this.state.updatedDataCityInfo}'.<br />
                                    Would you like to update the city to {this.state.updatedDataCityInfo}?
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={this.updateDataCityInfo} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideNotificationCityMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let hasNotificationSiteCodeMsg;
        {
            let siteCode = this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.siteCode : "";//this.state.currentOldValueSiteCode ? this.state.currentOldValueSiteCode : (this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.siteCode : "");
            if (siteCode === null || siteCode === undefined) {
                siteCode = "";
            }

            hasNotificationSiteCodeMsg = this.state.hasNotificationSiteCodeMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Site Code Confirmation</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideNotificationSiteCodeMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>
                                    The current 2 digit site code = '{siteCode}'.<br />
                                    Would you like CRStar to update the site code to '{this.state.updatingSiteCodeNewValue}'?
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={this.updateDataSiteCodeFnc} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideNotificationSiteCodeMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const diagnosisMaintenance = this.state.diagnosisForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">
                    {this.state.isHelpVisible && this.state.fieldInFocus && <Help fieldInFocus={this.state.fieldInFocus} focusingField={this.state.focusingField} closeCode={this.closeCodeDialog} />}
                    <form onSubmit={this.saveDiagnosis} id="diagnosisForm" className="DialogForm TabParentPage">
                        <div className="hidden">
                            <Input type="hidden" labelText="" width="0px" labelWidth="0" field="siteCode" />
                            <Input type="hidden" labelText="" width="0px" labelWidth="0" field="naDefault" />
                        </div>
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo">
                                    <button type="button" onClick={this.getHelp} name="btnHelp"><img src="https://crstar.ers-can.com/images/$sys_question.png.a5image" /></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.state.diagnosisForm.TopoCode ? this.state.diagnosisForm.TopoCode : this.state.diagnosisForm.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {
                                        //this.props.selectedPatient.dob != null && this.props.selectedPatient.dob !== "" ? moment(new Date(this.props.selectedPatient.dob)).format("MM/DD/YYYY") : ""
                                        this.props.selectedPatient.dobMonth + "/" + this.props.selectedPatient.dobDay + "/" + this.props.selectedPatient.dobYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {
                                        //this.props.selectedPrimarySummary.diagnosisDate != null && this.props.selectedPrimarySummary.diagnosisDate !== "" ? moment(new Date(this.props.selectedPrimarySummary.diagnosisDate)).format("MM/DD/YYYY") : ""
                                        this.props.selectedPrimarySummary.diagnosisDateMonth + "/" + this.props.selectedPrimarySummary.diagnosisDateDay + "/" + this.props.selectedPrimarySummary.diagnosisDateYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.state.diagnosisForm.HistCodeName ? this.state.diagnosisForm.HistCodeName : this.state.diagnosisForm.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>
                        <div className="emptyLine"></div>
                        <div className="row">
                            <div className="col-md-3 diagCol1">
                                <div className="frameInner">
                                    <div className="header">&nbsp;</div>
                                    <Input type="label" labelText="Dx Address Info" width="0px" labelWidth="100px" field="Dx_Address_Info_lbl" ignoreRapid />
                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange} lostfocused={this.handleLostFocus}
                                        labelText="Address" field="dxAddress" value={this.state.diagnosisForm.dxAddress} itemNbr="3" tabIndex="1" labelWidth="100px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dxAddress"] : ""} width="220px" autoFocus />
                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange} lostfocused={this.handleLostFocus}
                                        labelText="Address 2" field="dxAddress2" value={this.state.diagnosisForm.dxAddress2} itemNbr="2101" tabIndex="2" labelWidth="100px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dxAddress2"] : ""} width="220px" />
                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange} lostfocused={this.handleLostFocus}
                                        labelText="City" field="dxCity" value={this.state.diagnosisForm.dxCity} itemNbr="16" tabIndex="3" labelWidth="100px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dxCity"] : ""} width="125px" />

                                    <div style={{ display: "flex" }}>
                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="State" field="dxState" lostfocused={this.handleLostFocus} labelWidth="100px"
                                            value={this.state.diagnosisForm.dxState ? this.state.diagnosisForm.dxState : this.state.diagnosisForm.DxState}
                                            dataSource="State_Codes" titleDialog="Diagnosis State"
                                            inValid={this.state.dAllItemFields["dxState"]} itemNbr="21" tabIndex="4"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dxState"] : ""}
                                        />
                                        <Input type="label" width="9px" labelWidth="15px" labelText="" field="" />
                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange} lostfocused={this.handleLostFocus}
                                            labelText="Zip" field="dxZip" value={this.state.diagnosisForm.dxZip} width="80px" labelWidth="30px" itemNbr="4"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dxZip"] : ""} tabIndex="5" />
                                    </div>

                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="County" field="dxCounty" lostfocused={this.handleLostFocus}
                                        value={this.state.diagnosisForm.dxCounty ? this.state.diagnosisForm.dxCounty : this.state.diagnosisForm.DxCounty}
                                        dataSource="County_Codes" titleDialog="Diagnosis County" aliasNo="393"
                                        inValid={this.state.dAllItemFields["dxCounty"]} itemNbr="481" tabIndex="6" labelWidth="100px" width="55px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dxCounty"] : ""}
                                        flag={this.state.diagnosisForm.dxState ? this.state.diagnosisForm.dxState : this.state.diagnosisForm.DxState}
                                    />
                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Country" field="dxCountry" lostfocused={this.handleLostFocus}
                                        value={this.state.diagnosisForm.dxCountry ? this.state.diagnosisForm.dxCountry : this.state.diagnosisForm.DxCountry}
                                        dataSource="Country_Codes" titleDialog="Diagnosis Country"
                                        inValid={this.state.dAllItemFields["dxCountry"]} itemNbr="809" tabIndex="7" labelWidth="100px" width="55px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dxCountry"] : ""}
                                    />

                                    <div className="splitterCls"></div>

                                    <label>Secondary Diagnosis / Comorbidity and Complications</label>
                                    <div style={{ textAlign: "right" }}>
                                        <label>
                                            <input type="radio" onClick={this.setIDCOption} onChange={this.setIDCOption}
                                                checked={this.state.icd10Opt === "ICD10"} tabIndex="8"
                                                name="comorbCompOpt" field="comorbCompOpt" value="ICD10" style={{ marginRight: "10px" }} />
                                                ICD10
                                        </label>
                                        <label style={{ width: "40px" }}> </label>
                                        <label>
                                            <input type="radio" onClick={this.setIDCOption} onChange={this.setIDCOption}
                                                checked={this.state.icd10Opt === "ICD9"} tabIndex="8"
                                                name="comorbCompOpt" field="comorbCompOpt" value="ICD9" style={{ marginRight: "10px" }} />
                                                ICD9
                                        </label>
                                        <label style={{ width: "40px" }}> </label>
                                    </div>

                                    {this.state.icd10Opt === "ICD9" ?
                                        <div id="icd09OptAlias" className="icd09OptAlias">
                                            <div className="row secondaryDiagnosisComorbidityAndComplications" style={{ display: "flex" }}>
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="1" field="comorbComp1" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.ComorbComp1 ? this.state.diagnosisForm.ComorbComp1 : this.state.diagnosisForm.comorbComp1}
                                                    dataSource="Icd9_Codes" aliasNo="2102" tabIndex="9"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 1"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["comorbComp1"]} lineStyle="oneLine" itemNbr="2102"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["comorbComp1"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="4" field="comorbComp4" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.ComorbComp4 ? this.state.diagnosisForm.ComorbComp4 : this.state.diagnosisForm.comorbComp4}
                                                    dataSource="Icd9_Codes" aliasNo="2105" tabIndex="12"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 4"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["comorbComp4"]} lineStyle="oneLine" itemNbr="2105"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["comorbComp4"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="7" field="comorbComp7" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.ComorbComp7 ? this.state.diagnosisForm.ComorbComp7 : this.state.diagnosisForm.comorbComp7}
                                                    dataSource="Icd9_Codes" aliasNo="2203" tabIndex="15"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 7"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["comorbComp7"]} lineStyle="oneLine" itemNbr="2203"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["comorbComp7"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="10" field="comorbComp10" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.ComorbComp10 ? this.state.diagnosisForm.ComorbComp10 : this.state.diagnosisForm.comorbComp10}
                                                    dataSource="Icd9_Codes" aliasNo="2206" tabIndex="18"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 10"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["comorbComp10"]} lineStyle="oneLine" itemNbr="2206"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["comorbComp10"] : ""}
                                                />
                                            </div>
                                            <div className="row secondaryDiagnosisComorbidityAndComplications" style={{ display: "flex" }}>
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="2" field="comorbComp2" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.ComorbComp2 ? this.state.diagnosisForm.ComorbComp2 : this.state.diagnosisForm.comorbComp2}
                                                    dataSource="Icd9_Codes" aliasNo="2103" tabIndex="10"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 2"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["comorbComp2"]} lineStyle="oneLine" itemNbr="2103"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["comorbComp2"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="5" field="comorbComp5" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.ComorbComp5 ? this.state.diagnosisForm.ComorbComp5 : this.state.diagnosisForm.comorbComp5}
                                                    dataSource="Icd9_Codes" aliasNo="2106" tabIndex="13"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 5"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["comorbComp5"]} lineStyle="oneLine" itemNbr="2106"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["comorbComp5"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="8" field="comorbComp8" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.ComorbComp8 ? this.state.diagnosisForm.ComorbComp8 : this.state.diagnosisForm.comorbComp8}
                                                    dataSource="Icd9_Codes" aliasNo="2204" tabIndex="16"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 8"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["comorbComp8"]} lineStyle="oneLine" itemNbr="2204"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["comorbComp8"] : ""}
                                                />
                                            </div>
                                            <div className="row secondaryDiagnosisComorbidityAndComplications" style={{ display: "flex" }}>
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="3" field="comorbComp3" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.ComorbComp3 ? this.state.diagnosisForm.ComorbComp3 : this.state.diagnosisForm.comorbComp3}
                                                    dataSource="Icd9_Codes" aliasNo="2104" tabIndex="11"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 3"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["comorbComp3"]} lineStyle="oneLine" itemNbr="2104"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["comorbComp3"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="6" field="comorbComp6" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.ComorbComp6 ? this.state.diagnosisForm.ComorbComp6 : this.state.diagnosisForm.comorbComp6}
                                                    dataSource="Icd9_Codes" aliasNo="2107" tabIndex="14"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 6"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["comorbComp6"]} lineStyle="oneLine" itemNbr="2107"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["comorbComp6"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="9" field="comorbComp9" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.ComorbComp9 ? this.state.diagnosisForm.ComorbComp9 : this.state.diagnosisForm.comorbComp9}
                                                    dataSource="Icd9_Codes" aliasNo="2205" tabIndex="17"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 9"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["comorbComp9"]} lineStyle="oneLine" itemNbr="2205"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["comorbComp9"] : ""}
                                                />
                                            </div>
                                        </div>
                                        :
                                        <div id="icd10OptAlias" className="icd10OptAlias">
                                            <div className="row secondaryDiagnosisComorbidityAndComplications" style={{ display: "flex" }}>
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="1" field="secondaryDx1" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.SecondaryDx1 ? this.state.diagnosisForm.SecondaryDx1 : this.state.diagnosisForm.secondaryDx1}
                                                    dataSource="Icd10_Codes" aliasNo="2102" tabIndex="9"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 1"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["secondaryDx1"]} lineStyle="oneLine" itemNbr="4003"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["secondaryDx1"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="4" field="secondaryDx4" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.secondaryDx4 ? this.state.diagnosisForm.secondaryDx4 : this.state.diagnosisForm.SecondaryDx4}
                                                    dataSource="Icd10_Codes" aliasNo="2105" tabIndex="12"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 4"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["secondaryDx4"]} lineStyle="oneLine" itemNbr="4006"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["secondaryDx4"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="7" field="secondaryDx7" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.secondaryDx7 ? this.state.diagnosisForm.secondaryDx7 : this.state.diagnosisForm.SecondaryDx7}
                                                    dataSource="Icd10_Codes" aliasNo="2203" tabIndex="15"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 7"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["secondaryDx7"]} lineStyle="oneLine" itemNbr="4009"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["secondaryDx7"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="10" field="secondaryDx10" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.secondaryDx10 ? this.state.diagnosisForm.secondaryDx10 : this.state.diagnosisForm.SecondaryDx10}
                                                    dataSource="Icd10_Codes" aliasNo="2206" tabIndex="18"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 10"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["secondaryDx10"]} lineStyle="oneLine" itemNbr="4012"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["secondaryDx10"] : ""}
                                                />
                                            </div>
                                            <div className="row secondaryDiagnosisComorbidityAndComplications" style={{ display: "flex" }}>
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="2" field="secondaryDx2" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.secondaryDx2 ? this.state.diagnosisForm.secondaryDx2 : this.state.diagnosisForm.SecondaryDx2}
                                                    dataSource="Icd10_Codes" aliasNo="2103" tabIndex="10"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 2"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["secondaryDx2"]} lineStyle="oneLine" itemNbr="4004"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["secondaryDx2"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="5" field="secondaryDx5" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.secondaryDx5 ? this.state.diagnosisForm.secondaryDx5 : this.state.diagnosisForm.SecondaryDx5}
                                                    dataSource="Icd10_Codes" aliasNo="2106" tabIndex="13"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 5"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["secondaryDx5"]} lineStyle="oneLine" itemNbr="4007"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["secondaryDx5"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="8" field="secondaryDx8" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.secondaryDx8 ? this.state.diagnosisForm.secondaryDx8 : this.state.diagnosisForm.SecondaryDx8}
                                                    dataSource="Icd10_Codes" aliasNo="2204" tabIndex="16"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 8"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["secondaryDx8"]} lineStyle="oneLine" itemNbr="4010"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["secondaryDx8"] : ""}
                                                />
                                            </div>
                                            <div className="row secondaryDiagnosisComorbidityAndComplications" style={{ display: "flex" }}>
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="3" field="secondaryDx3" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.secondaryDx3 ? this.state.diagnosisForm.secondaryDx3 : this.state.diagnosisForm.SecondaryDx3}
                                                    dataSource="Icd10_Codes" aliasNo="2104" tabIndex="11"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 3"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["secondaryDx3"]} lineStyle="oneLine" itemNbr="4005"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["secondaryDx3"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="6" field="secondaryDx6" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.secondaryDx6 ? this.state.diagnosisForm.secondaryDx6 : this.state.diagnosisForm.SecondaryDx6}
                                                    dataSource="Icd10_Codes" aliasNo="2107" tabIndex="14"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 6"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["secondaryDx6"]} lineStyle="oneLine" itemNbr="4008"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["secondaryDx6"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="9" field="secondaryDx9" lostfocused={this.handleLostFocus}
                                                    value={this.state.diagnosisForm.secondaryDx9 ? this.state.diagnosisForm.secondaryDx9 : this.state.diagnosisForm.SecondaryDx9}
                                                    dataSource="Icd10_Codes" aliasNo="2205" tabIndex="17"
                                                    titleDialog={this.state.icd10Opt + " Comorbidity Complication 9"} labelWidth="15px" width="43px" labelStyle="center"
                                                    inValid={this.state.dAllItemFields["secondaryDx9"]} lineStyle="oneLine" itemNbr="4011"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["secondaryDx9"] : ""}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div className="splitterCls"></div>

                                    <div style={{ display: "flex" }}>
                                        <Input type="Hospital" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Hosp Ref From" field="hospRefFrom" lostfocused={this.handleLostFocus}
                                            value={this.state.diagnosisForm.hospRefFrom ? this.state.diagnosisForm.hospRefFrom : this.state.diagnosisForm.HospRefFrom}
                                            dataSource="Hospital" titleDialog="Hospital Referred From" labelWidth="90px" width="63px"
                                            inValid={this.state.dAllItemFields["hospRefFrom"]} lineStyle="oneLine" itemNbr="148" tabIndex="19"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospRefFrom"] : ""}
                                        />
                                        <Input type="label" changed={this.handleFormChange} labelText="" field="hospRefFromName"
                                            value={this.state.diagnosisForm.hospRefFromName} width="200px" labelWidth="5px" lineStyle="oneLine" itemNbr="" />
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <Input type="Hospital" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Hosp Ref To" field="hospRefTo" lostfocused={this.handleLostFocus}
                                            value={this.state.diagnosisForm.hospRefTo ? this.state.diagnosisForm.hospRefTo : this.state.diagnosisForm.HospRefTo}
                                            dataSource="Hospital" titleDialog="Hospital Referred To" labelWidth="90px" width="63px"
                                            inValid={this.state.dAllItemFields["hospRefTo"]} lineStyle="oneLine" itemNbr="149" tabIndex="20"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospRefTo"] : ""}
                                        />
                                        <Input type="label" changed={this.handleFormChange} labelText="" field="hospRefToName"
                                            value={this.state.diagnosisForm.hospRefToName} width="200px" labelWidth="5px" lineStyle="oneLine" itemNbr="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 extendDiv diagCol2">
                                <div className="frameInner">
                                    <div className="header">&nbsp;</div>

                                    <Input type="date" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="First Contact Date" field="firstContDate" lineStyle="oneLine onTopFrame"
                                        value={this.state.diagnosisForm.firstContDate} labelWidth="110px" width="185px"
                                        //value={this.state.diagnosisForm.firstContDate != null && this.state.diagnosisForm.firstContDate !== "" ? moment(new Date(this.state.diagnosisForm.firstContDate)).format("MM/DD/YYYY") : ""}
                                        //#20210125 - START
                                        // Add day/month/year values
                                        inValidDay={this.state.dAllItemFields["firstContDateDay"]}
                                        inValidMonth={this.state.dAllItemFields["firstContDateMonth"]}
                                        inValidYear={this.state.dAllItemFields["firstContDateYear"]}
                                        dayVal={this.state.diagnosisForm.firstContDateDay}
                                        monthVal={this.state.diagnosisForm.firstContDateMonth}
                                        yearVal={this.state.diagnosisForm.firstContDateYear}
                                        //#20210125 - END
                                        lostfocused={this.handleLostFocus} tabIndex="21"
                                    />
                                    <Input type="date" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Admit Date" field="admitDate" lineStyle="oneLine"
                                        value={this.state.diagnosisForm.admitDate} labelWidth="110px" width="185px"
                                        //value={this.state.diagnosisForm.admitDate != null && this.state.diagnosisForm.admitDate !== "" ? moment(new Date(this.state.diagnosisForm.admitDate)).format("MM/DD/YYYY") : ""}
                                        //#20210125 - START
                                        // Add day/month/year values
                                        inValidDay={this.state.dAllItemFields["admitDateDay"]}
                                        inValidMonth={this.state.dAllItemFields["admitDateMonth"]}
                                        inValidYear={this.state.dAllItemFields["admitDateYear"]}
                                        dayVal={this.state.diagnosisForm.admitDateDay}
                                        monthVal={this.state.diagnosisForm.admitDateMonth}
                                        yearVal={this.state.diagnosisForm.admitDateYear}
                                        //#20210125 - END
                                        lostfocused={this.handleLostFocus} tabIndex="22"
                                    />
                                    <Input type="date" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Discharge Date" field="dischDate" lineStyle="oneLine"
                                        value={this.state.diagnosisForm.dischDate} labelWidth="110px" width="185px"
                                        //value={this.state.diagnosisForm.dischDate != null && this.state.diagnosisForm.dischDate !== "" ? moment(new Date(this.state.diagnosisForm.dischDate)).format("MM/DD/YYYY") : ""}
                                        //#20210125 - START
                                        // Add day/month/year values
                                        inValidDay={this.state.dAllItemFields["dischDateDay"]}
                                        inValidMonth={this.state.dAllItemFields["dischDateMonth"]}
                                        inValidYear={this.state.dAllItemFields["dischDateYear"]}
                                        dayVal={this.state.diagnosisForm.dischDateDay}
                                        monthVal={this.state.diagnosisForm.dischDateMonth}
                                        yearVal={this.state.diagnosisForm.dischDateYear}
                                        //#20210125 - END
                                        lostfocused={this.handleLostFocus} tabIndex="23"
                                    />

                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Admit Class" field="admitClass" lineStyle="oneLine"
                                        value={this.state.diagnosisForm.AdmitClass ? this.state.diagnosisForm.AdmitClass : this.state.diagnosisForm.admitClass}
                                        dataSource="Global_Dir_Mv" titleDialog="Admit Class" labelWidth="110px" width="65px"
                                        inValid={this.state.dAllItemFields["admitClass"]} itemNbr="168" aliasNo="168"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["admitClass"] : ""}
                                        lostfocused={this.handleLostFocus} tabIndex="24"
                                    />
                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Inpatient Status" field="inpatientStatus" labelWidth="110px" width="65px"
                                        value={this.state.diagnosisForm.InpatientStatus ? this.state.diagnosisForm.InpatientStatus : this.state.diagnosisForm.inpatientStatus}
                                        dataSource="Global_Dir_Mv" titleDialog="Inpatient Status" aliasNo="3157"
                                        inValid={this.state.dAllItemFields["inpatientStatus"]} lineStyle="oneLine" itemNbr="3157"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["inpatientStatus"] : ""}
                                        lostfocused={this.handleLostFocus} tabIndex="25"
                                    />

                                    <Input type="label" labelText="Age Dx" field="ageDx" lineStyle="oneLine"
                                        value={this.state.ageDx ? this.state.ageDx : ""} labelWidth="110px" width="30px" itemNbr="" />

                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Acc Year" field="accYr" lineStyle="oneLine"
                                        value={this.state.diagnosisForm.accYr} labelWidth="110px" width="50px" itemNbr="151"
                                        inValid={this.state.dAllItemFields["accYr"]}
                                        lostfocused={this.handleLostFocus}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["accYr"] : ""} />

                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Dx Confirm" field="dxConfirm" labelWidth="110px" width="30px"
                                        value={this.state.diagnosisForm.DxConfirm ? this.state.diagnosisForm.DxConfirm : this.state.diagnosisForm.dxConfirm}
                                        dataSource="Global_Dir_Mv" titleDialog="Dx Confirm" aliasNo="154"
                                        inValid={this.state.dAllItemFields["dxConfirm"]} lineStyle="oneLine" itemNbr="154"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dxConfirm"] : ""}
                                        lostfocused={this.handleLostFocus}
                                    />

                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Dx Marital Status" field="dxMaritalStatus"
                                        value={this.state.diagnosisForm.DxMaritalStatus ? this.state.diagnosisForm.DxMaritalStatus : this.state.diagnosisForm.dxMaritalStatus}
                                        dataSource="Global_Dir_Mv" titleDialog="Dx Marital Status" aliasNo="22" labelWidth="110px" width="30px"
                                        inValid={this.state.dAllItemFields["dxMaritalStatus"]} lineStyle="oneLine" itemNbr="22"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dxMaritalStatus"] : ""}
                                        lostfocused={this.handleLostFocus}
                                    />
                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Topo Code" field="topoCode"
                                        value={this.state.diagnosisForm.TopoCode ? this.state.diagnosisForm.TopoCode : this.state.diagnosisForm.topoCode}
                                        dataSource="Topo_Codes" titleDialog="Topography Code" labelWidth="110px" width="50px"
                                        inValid={this.state.dAllItemFields["topoCode"]} lineStyle="oneLine" itemNbr="133"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["topoCode"] : ""}
                                        lostfocused={this.handleLostFocus}
                                        formattedValueFnc={true}
                                        pathchart={this.state.statusPc == "1" || this.state.statusPc == "2" || this.state.statusPc == "3"}
                                        clazz={this.state.statusPc == "1" ? "pathchart-topo" : (this.state.statusPc == "2" ? "pathchart-topo unlikely" : (this.state.statusPc == "3" ? "pathchart-topo impossible" : ""))}
                                    />
                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Lat Code" field="latCode"
                                        value={this.state.diagnosisForm.LatCode ? this.state.diagnosisForm.LatCode : this.state.diagnosisForm.latCode}
                                        dataSource="Global_Dir_Mv" titleDialog="Lat Code" aliasNo="152" labelWidth="110px" width="30px"
                                        inValid={this.state.dAllItemFields["latCode"]} lineStyle="oneLine" itemNbr="152"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["latCode"] : ""}
                                        lostfocused={this.handleLostFocus}
                                    />
                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Hist Code" field="histCode" aliasNo="153"
                                        value={this.state.diagnosisForm.HistCode ? this.state.diagnosisForm.HistCode : this.state.diagnosisForm.histCode}
                                        dataSource="Morpho_Codes" titleDialog="Histology" labelWidth="110px" width="60px"
                                        inValid={this.state.dAllItemFields["histCode"]} lineStyle="oneLine" itemNbr="153"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["histCode"] : ""}
                                        lostfocused={this.handleLostFocus}
                                        pathchart={this.state.statusPc == "1" || this.state.statusPc == "2" || this.state.statusPc == "3"}
                                        clazz={this.state.statusPc == "1" ? "pathchart-histo" : (this.state.statusPc == "2" ? "pathchart-histo unlikely" : (this.state.statusPc == "3" ? "pathchart-histo impossible" : ""))}
                                    />
                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText={this.state.diagnosisForm.diagnosisUserLblText1 ? this.state.diagnosisForm.diagnosisUserLblText1 : "Dx User 1"}
                                        field="diagnosisUser1"
                                        value={this.state.diagnosisForm.DiagnosisUser1 ? this.state.diagnosisForm.DiagnosisUser1 : this.state.diagnosisForm.diagnosisUser1}
                                        dataSource="Global_Dir_Mv" titleDialog="Diagnosis User 1" aliasNo="3916" labelWidth="110px" width="105px"
                                        inValid={this.state.dAllItemFields["diagnosisUser1"]} lineStyle="oneLine" itemNbr="3916"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["diagnosisUser1"] : ""}
                                        lostfocused={this.handleLostFocus}
                                        flag="ERS_UserDefinedMaintenance"//ONLY_CHECK_IF_DEFINED_IN_PRODUCE
                                    />
                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText={this.state.diagnosisForm.diagnosisUserLblText2 ? this.state.diagnosisForm.diagnosisUserLblText2 : "Dx User 2"}
                                        field="diagnosisUser2"
                                        value={this.state.diagnosisForm.diagnosisUser2 ? this.state.diagnosisForm.diagnosisUser2 : this.state.diagnosisForm.DiagnosisUser2}
                                        dataSource="Global_Dir_Mv" aliasNo="3917" titleDialog="Diagnosis User 2" labelWidth="110px" width="105px"
                                        inValid={this.state.dAllItemFields["diagnosisUser2"]} lineStyle="oneLine" itemNbr="3917"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["diagnosisUser2"] : ""}
                                        lostfocused={this.handleLostFocus}
                                        flag="ERS_UserDefinedMaintenance"
                                    />
                                </div>
                            </div>
                            <div className="col-md-2 diagCol3">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="frameInner">
                                            <div className="header">&nbsp;</div>
                                            <Input type="codeSelect" focused={this.handleOnFocus}
                                                changed={this.handleFormChange} field="tobaccoUseSmokingStatus"
                                                labelText="Tobacco 22+" labelWidth="115px" lineStyle="oneLine onTopFrame"
                                                value={this.state.diagnosisForm.tobaccoUseSmokingStatus ? this.state.diagnosisForm.tobaccoUseSmokingStatus : this.state.diagnosisForm.TobaccoUseSmokingStatus}
                                                dataSource="Global_Dir_Mv" titleDialog="Tobacco Use Smoking Status 2022+" aliasNo="4521" width="56px"
                                                inValid={this.state.dAllItemFields["tobaccoUseSmokingStatus"]} itemNbr="4521"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tobaccoUseSmokingStatus"] : ""}
                                                lostfocused={this.handleLostFocus}
                                            />
                                            <Input type="codeSelect" focused={this.handleOnFocus}
                                                changed={this.handleFormChange} field="primPayerDx"
                                                labelText="Prim Payer Dx" labelWidth="115px" lineStyle="oneLine"
                                                value={this.state.diagnosisForm.PrimPayerDx ? this.state.diagnosisForm.PrimPayerDx : this.state.diagnosisForm.primPayerDx}
                                                dataSource="Global_Dir_Mv" titleDialog="Prim Payer Dx" aliasNo="387" width="56px"
                                                inValid={this.state.dAllItemFields["primPayerDx"]} itemNbr="387"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["primPayerDx"] : ""}
                                                lostfocused={this.handleLostFocus}
                                            />
                                            <Input type="codeSelect" focused={this.handleOnFocus}
                                                changed={this.handleFormChange} field="caCasefindingSource"
                                                labelText="Casefinding Source" labelWidth="115px" lineStyle="oneLine"
                                                value={this.state.diagnosisForm.CaCasefindingSource ? this.state.diagnosisForm.CaCasefindingSource : this.state.diagnosisForm.caCasefindingSource}
                                                dataSource="Global_Dir_Mv" titleDialog="Ca Casefinding Source" aliasNo="509" width="56px"
                                                inValid={this.state.dAllItemFields["caCasefindingSource"]} itemNbr="509"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["caCasefindingSource"] : ""}
                                                lostfocused={this.handleLostFocus}
                                            />
                                            <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                changed={this.handleFormChange} labelText="Reporting Source" labelWidth="115px"
                                                field="rptgSource" value={this.state.diagnosisForm.RptgSource ? this.state.diagnosisForm.RptgSource : this.state.diagnosisForm.rptgSource}
                                                dataSource="Global_Dir_Mv" titleDialog="Reporting Source" aliasNo="783" width="56px"
                                                inValid={this.state.dAllItemFields["rptgSource"]} itemNbr="783"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rptgSource"] : ""}
                                                lostfocused={this.handleLostFocus}
                                            />

                                            <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine" labelWidth="115px"
                                                changed={this.handleFormChange} labelText="Sat Code" width="76px"
                                                field="satCode" value={this.state.diagnosisForm.satCode} itemNbr="40"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["satCode"] : ""} />
                                            <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine" labelWidth="115px"
                                                changed={this.handleFormChange} labelText="Path Nbr" width="91px"
                                                field="pathNbr" value={this.state.diagnosisForm.pathNbr} itemNbr="197"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pathNbr"] : ""} />

                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Grade Path" labelWidth="115px" field="gradePath" lineStyle="oneLine"
                                                value={this.state.diagnosisForm.GradePath ? this.state.diagnosisForm.GradePath : this.state.diagnosisForm.gradePath}
                                                dataSource="Global_Dir_Mv" titleDialog="Grade Path" aliasNo="3155" width="56px"
                                                inValid={this.state.dAllItemFields["gradePath"]} itemNbr="3155"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["gradePath"] : ""}
                                                lostfocused={this.handleLostFocus}
                                            />
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Grade Path Sys" labelWidth="115px" field="gradePathSys"
                                                value={this.state.diagnosisForm.GradePathSys ? this.state.diagnosisForm.GradePathSys : this.state.diagnosisForm.gradePathSys}
                                                dataSource="Global_Dir_Mv" titleDialog="Grade Path Sys" aliasNo="3156" width="56px"
                                                inValid={this.state.dAllItemFields["gradePathSys"]} lineStyle="oneLine" itemNbr="3156"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["gradePathSys"] : ""}
                                                lostfocused={this.handleLostFocus}
                                            />
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Grade (pre 2018)" labelWidth="115px" field="grade"
                                                value={this.state.diagnosisForm.Grade ? this.state.diagnosisForm.Grade : this.state.diagnosisForm.grade}
                                                dataSource="Global_Dir_Mv" titleDialog="Grade" aliasNo="134" width="56px"
                                                inValid={this.state.dAllItemFields["grade"]} lineStyle="oneLine" itemNbr="134"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["grade"] : ""}
                                                lostfocused={this.handleLostFocus}
                                            />
                                            <div className="Cov2Div">NCDB COVID-19 Fields 2020-21 only</div>
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="CoV-2 Test" labelWidth="115px" field="ncdbSarsCov2Test" lineStyle="oneLine"
                                                value={this.state.diagnosisForm.ncdbSarsCov2Test ? this.state.diagnosisForm.ncdbSarsCov2Test : this.state.diagnosisForm.NcdbSarsCov2Test}
                                                dataSource="Global_Dir_Mv" titleDialog="NCDB SARS COV-2 Test" aliasNo="4475" width="56px"
                                                inValid={this.state.dAllItemFields["ncdbSarsCov2Test"]} itemNbr="4475"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ncdbSarsCov2Test"] : ""}
                                                lostfocused={this.handleLostFocus} labelStyle="CoV2Area"
                                            />
                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="CoV-2 Positive" labelWidth="115px" field="ncdbSarsCov2Positive"
                                                value={this.state.diagnosisForm.ncdbSarsCov2Positive ? this.state.diagnosisForm.ncdbSarsCov2Positive : this.state.diagnosisForm.NcdbSarsCov2Positive}
                                                dataSource="Global_Dir_Mv" titleDialog="NCDB SARS COV-2 Positive" aliasNo="4476" width="56px"
                                                inValid={this.state.dAllItemFields["ncdbSarsCov2Positive"]} lineStyle="oneLine" itemNbr="4476"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ncdbSarsCov2Positive"] : ""}
                                                lostfocused={this.handleLostFocus} labelStyle="CoV2Area"
                                            />

                                            {
                                                /*
                                                <Input type="datePicker" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="CoV-2 Pos Date" field="ncdbSarsCov2PositiveDate"
                                                    //value={this.state.diagnosisForm.ncdbSarsCov2PositiveDate != null && this.state.diagnosisForm.ncdbSarsCov2PositiveDate !== "" ? moment(new Date(this.state.diagnosisForm.ncdbSarsCov2PositiveDate)).format("MM/DD/YYYY") : ""}
                                                    value={this.state.diagnosisForm.ncdbSarsCov2PositiveDate}
                                                    width="85px" lineStyle="oneLine" labelWidth="auto" itemNbr="4477" labelStyle="CoV2Area"
                                                />
                                                */
                                            }
                                            <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="CoV-2 Pos Date" field="ncdbSarsCov2PositiveDate"
                                                value={this.state.diagnosisForm.ncdbSarsCov2PositiveDate}
                                                maxLength="10" aliasNo="4477" labelWidth="115px" width="85px"
                                                lineStyle="oneLine" itemNbr="4477" labelStyle="CoV2Area"
                                                lostfocused={this.handleLostFocus}
                                                placeholder="mm/dd/yyyy"
                                            />

                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                labelText="Tx Impact" labelWidth="115px" field="ncdbCovid19TxImpact"
                                                value={this.state.diagnosisForm.ncdbCovid19TxImpact ? this.state.diagnosisForm.ncdbCovid19TxImpact : this.state.diagnosisForm.NcdbCovid19TxImpact}
                                                dataSource="Global_Dir_Mv" titleDialog="NCDB COVID-19 Treatment Impact" aliasNo="4478" width="56px"
                                                inValid={this.state.dAllItemFields["ncdbCovid19TxImpact"]} lineStyle="oneLine" itemNbr="4478"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ncdbCovid19TxImpact"] : ""}
                                                lostfocused={this.handleLostFocus} labelStyle="CoV2Area"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">

                                </div>
                            </div>
                            <div className="col-md-4 extendDiv FuPhysOrder diagCol4">
                                <div className="frameInner">
                                    <div className="header">&nbsp;</div>
                                    <div style={{ textAlign: "right", marginRight: "0px" }}>
                                        <label>FU Phys<br />Order</label>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <Input type="Physician" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Prim Surg" field="primSurgeon" lineStyle="oneLine"
                                            value={this.state.diagnosisForm.primSurgeon ? this.state.diagnosisForm.primSurgeon : this.state.diagnosisForm.PrimSurgeon}
                                            dataSource="Physicians" titleDialog="Primary Surgeon" labelWidth="70px" width="68px"
                                            inValid={this.state.dAllItemFields["primSurgeon"]} itemNbr="23"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["primSurgeon"] : ""}
                                            lostfocused={this.handleLostFocus}
                                        />
                                        <Input type="label" changed={this.handleFormChange} labelText="" field="primSurgeonName"
                                            value={this.state.diagnosisForm.primSurgeonName} width="145px" labelWidth="5px" lineStyle="oneLine"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["primSurgeonName"] : ""}
                                            labelMainStyle="AllPhysicianNameLbl" />
                                        <Input type="input" changed={this.handleFormChange} labelText="" field="psOrder"
                                            value={this.state.diagnosisForm.psOrder} width="25px" labelWidth="5px" lineStyle="oneLine"
                                            maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["psOrder"] !== null && this.state.pageMaxlengthInput["psOrder"] !== "" ? this.state.pageMaxlengthInput["psOrder"] : "2"}
                                            lostfocused={this.handleLostFocus} ignoreRapid
                                            focused={this.handleOnFocus}
                                        />
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <Input type="Physician" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Mng Phys" field="mngPhysician" lineStyle="oneLine"
                                            value={this.state.diagnosisForm.MngPhysician ? this.state.diagnosisForm.MngPhysician : this.state.diagnosisForm.mngPhysician}
                                            dataSource="Physicians" titleDialog="Managing Physician" labelWidth="70px" width="68px"
                                            inValid={this.state.dAllItemFields["mngPhysician"]} itemNbr="815"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["mngPhysician"] : ""}
                                            lostfocused={this.handleLostFocus}
                                        />
                                        <Input type="label" changed={this.handleFormChange} labelText="" field="mngPhysicianName"
                                            value={this.state.diagnosisForm.mngPhysicianName} width="145px" labelWidth="5px" lineStyle="oneLine"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["mngPhysicianName"] : ""}
                                            labelMainStyle="AllPhysicianNameLbl" />
                                        <Input type="input" changed={this.handleFormChange} labelText="" field="mpOrder"
                                            value={this.state.diagnosisForm.mpOrder} width="25px" labelWidth="5px" lineStyle="oneLine"
                                            maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["mpOrder"] !== null && this.state.pageMaxlengthInput["mpOrder"] !== "" ? this.state.pageMaxlengthInput["mpOrder"] : "2"}
                                            lostfocused={this.handleLostFocus} ignoreRapid
                                            focused={this.handleOnFocus}
                                        />
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <Input type="Physician" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Fol Phys" field="folPhysician" lineStyle="oneLine"
                                            value={this.state.diagnosisForm.FolPhysician ? this.state.diagnosisForm.FolPhysician : this.state.diagnosisForm.folPhysician}
                                            dataSource="Physicians" titleDialog="Following Physician" labelWidth="70px" width="68px"
                                            inValid={this.state.dAllItemFields["folPhysician"]} itemNbr="816"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["folPhysician"] : ""}
                                            lostfocused={this.handleLostFocus}
                                        />
                                        <Input type="label" changed={this.handleFormChange} labelText="" field="folPhysicianName"
                                            value={this.state.diagnosisForm.folPhysicianName} width="145px" labelWidth="5px" lineStyle="oneLine"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["folPhysicianName"] : ""}
                                            labelMainStyle="AllPhysicianNameLbl" />
                                        <Input type="input" changed={this.handleFormChange} labelText="" field="fpOrder"
                                            value={this.state.diagnosisForm.fpOrder} width="25px" labelWidth="5px" lineStyle="oneLine"
                                            maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["fpOrder"] !== null && this.state.pageMaxlengthInput["fpOrder"] !== "" ? this.state.pageMaxlengthInput["fpOrder"] : "2"}
                                            lostfocused={this.handleLostFocus} ignoreRapid
                                            focused={this.handleOnFocus}
                                        />
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <Input type="Physician" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Rad Oncol" field="radOncologist" lineStyle="oneLine"
                                            value={this.state.diagnosisForm.RadOncologist ? this.state.diagnosisForm.RadOncologist : this.state.diagnosisForm.radOncologist}
                                            dataSource="Physicians" titleDialog="Radiation Oncologist" labelWidth="70px" width="68px"
                                            inValid={this.state.dAllItemFields["radOncologist"]} itemNbr="817"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["radOncologist"] : ""}
                                            lostfocused={this.handleLostFocus}
                                        />
                                        <Input type="label" changed={this.handleFormChange} labelText="" field="radOncologistName"
                                            value={this.state.diagnosisForm.radOncologistName} width="145px" labelWidth="5px" lineStyle="oneLine"
                                            labelMainStyle="AllPhysicianNameLbl" />
                                        <Input type="input" changed={this.handleFormChange} labelText="" field="roOrder"
                                            value={this.state.diagnosisForm.roOrder} width="25px" labelWidth="5px" lineStyle="oneLine"
                                            maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["roOrder"] !== null && this.state.pageMaxlengthInput["roOrder"] !== "" ? this.state.pageMaxlengthInput["roOrder"] : "2"}
                                            lostfocused={this.handleLostFocus} ignoreRapid
                                            focused={this.handleOnFocus}
                                        />
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <Input type="Physician" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Med Oncol" field="medOncologist" lineStyle="oneLine"
                                            value={this.state.diagnosisForm.MedOncologist ? this.state.diagnosisForm.MedOncologist : this.state.diagnosisForm.medOncologist}
                                            dataSource="Physicians" titleDialog="Medical Oncologist" labelWidth="70px" width="68px"
                                            inValid={this.state.dAllItemFields["medOncologist"]} itemNbr="818"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["medOncologist"] : ""}
                                            lostfocused={this.handleLostFocus}
                                        />
                                        <Input type="label" changed={this.handleFormChange} labelText="" field="medOncologistName"
                                            value={this.state.diagnosisForm.medOncologistName} width="145px" labelWidth="5px" lineStyle="oneLine"
                                            labelMainStyle="AllPhysicianNameLbl" />
                                        <Input type="input" changed={this.handleFormChange} labelText="" field="moOrder"
                                            value={this.state.diagnosisForm.moOrder} width="25px" labelWidth="5px" lineStyle="oneLine"
                                            maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["moOrder"] !== null && this.state.pageMaxlengthInput["moOrder"] !== "" ? this.state.pageMaxlengthInput["moOrder"] : "2"}
                                            lostfocused={this.handleLostFocus} ignoreRapid
                                            focused={this.handleOnFocus}
                                        />
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <Input type="Physician" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Qa Phys" field="qaPhysician" lineStyle="oneLine"
                                            value={this.state.diagnosisForm.QaPhysician ? this.state.diagnosisForm.QaPhysician : this.state.diagnosisForm.qaPhysician}
                                            dataSource="Physicians" titleDialog="QA Physician" labelWidth="70px" width="68px"
                                            inValid={this.state.dAllItemFields["qaPhysician"]} itemNbr="819"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["qaPhysician"] : ""}
                                            lostfocused={this.handleLostFocus}
                                        />
                                        <Input type="label" changed={this.handleFormChange} labelText="" field="qaPhysicianName"
                                            value={this.state.diagnosisForm.qaPhysicianName} width="145px" labelWidth="5px" lineStyle="oneLine"
                                            labelMainStyle="AllPhysicianNameLbl" />
                                        <Input type="input" changed={this.handleFormChange} labelText="" field="qpOrder"
                                            value={this.state.diagnosisForm.qpOrder} width="25px" labelWidth="5px" lineStyle="oneLine"
                                            maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["qpOrder"] !== null && this.state.pageMaxlengthInput["qpOrder"] !== "" ? this.state.pageMaxlengthInput["qpOrder"] : "2"}
                                            lostfocused={this.handleLostFocus} ignoreRapid
                                            focused={this.handleOnFocus}
                                        />
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <Input type="Physician" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Ref Phys" field="refPhysician" lineStyle="oneLine"
                                            value={this.state.diagnosisForm.RefPhysician ? this.state.diagnosisForm.RefPhysician : this.state.diagnosisForm.refPhysician}
                                            dataSource="Physicians" titleDialog="Referring Physician" labelWidth="70px" width="68px"
                                            inValid={this.state.dAllItemFields["refPhysician"]} itemNbr=""
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["refPhysician"] : ""}
                                            lostfocused={this.handleLostFocus}
                                        />
                                        <Input type="label" changed={this.handleFormChange} labelText="" field="refPhysicianName"
                                            value={this.state.diagnosisForm.refPhysicianName} width="145px" labelWidth="5px" lineStyle="oneLine"
                                            labelMainStyle="AllPhysicianNameLbl" />
                                        <Input type="input" changed={this.handleFormChange} labelText="" field="rpOrder"
                                            value={this.state.diagnosisForm.rpOrder} width="25px" labelWidth="5px" lineStyle="oneLine"
                                            maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["rpOrder"] !== null && this.state.pageMaxlengthInput["rpOrder"] !== "" ? this.state.pageMaxlengthInput["rpOrder"] : "2"}
                                            lostfocused={this.handleLostFocus} ignoreRapid
                                            focused={this.handleOnFocus}
                                        />
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <Input type="Physician" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Oth Phys 1" field="othPhys1" lineStyle="oneLine"
                                            value={this.state.diagnosisForm.OthPhys1 ? this.state.diagnosisForm.OthPhys1 : this.state.diagnosisForm.othPhys1}
                                            dataSource="Physicians" titleDialog="Other Physician 1" labelWidth="70px" width="68px"
                                            inValid={this.state.dAllItemFields["othPhys1"]} itemNbr=""
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["othPhys1"] : ""}
                                            lostfocused={this.handleLostFocus}
                                        />
                                        <Input type="label" changed={this.handleFormChange} labelText="" field="othPhys1Name"
                                            value={this.state.diagnosisForm.othPhys1Name} width="145px" labelWidth="5px" lineStyle="oneLine"
                                            labelMainStyle="AllPhysicianNameLbl" />
                                        <Input type="input" changed={this.handleFormChange} labelText="" field="op1Order"
                                            value={this.state.diagnosisForm.op1Order} width="25px" labelWidth="5px" lineStyle="oneLine"
                                            maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["op1Order"] !== null && this.state.pageMaxlengthInput["op1Order"] !== "" ? this.state.pageMaxlengthInput["op1Order"] : "2"}
                                            lostfocused={this.handleLostFocus} ignoreRapid
                                            focused={this.handleOnFocus}
                                        />
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <Input type="Physician" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Oth Phys 2" field="othPhys2" lineStyle="oneLine"
                                            value={this.state.diagnosisForm.othPhys2 ? this.state.diagnosisForm.othPhys2 : this.state.diagnosisForm.OthPhys2}
                                            dataSource="Physicians" titleDialog="Other Physician 2" labelWidth="70px" width="68px"
                                            inValid={this.state.dAllItemFields["othPhys2"]} itemNbr=""
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["othPhys2"] : ""}
                                            lostfocused={this.handleLostFocus}
                                        />
                                        <Input type="label" changed={this.handleFormChange} labelText="" field="othPhys2Name"
                                            value={this.state.diagnosisForm.othPhys2Name} width="145px" labelWidth="5px" lineStyle="oneLine"
                                            labelMainStyle="AllPhysicianNameLbl" />
                                        <Input type="input" changed={this.handleFormChange} labelText="" field="op2Order"
                                            value={this.state.diagnosisForm.op2Order} width="25px" labelWidth="5px" lineStyle="oneLine"
                                            maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["op2Order"] !== null && this.state.pageMaxlengthInput["op2Order"] !== "" ? this.state.pageMaxlengthInput["op2Order"] : "2"}
                                            lostfocused={this.handleLostFocus} ignoreRapid
                                            focused={this.handleOnFocus}
                                        />
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <Input type="Physician" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Oth Phys 3" field="othPhys3" lineStyle="oneLine"
                                            value={this.state.diagnosisForm.othPhys3 ? this.state.diagnosisForm.othPhys3 : this.state.diagnosisForm.OthPhys3}
                                            dataSource="Physicians" titleDialog="Other Physician 3" labelWidth="70px" width="68px"
                                            inValid={this.state.dAllItemFields["othPhys3"]} itemNbr=""
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["othPhys3"] : ""}
                                            lostfocused={this.handleLostFocus}
                                        />
                                        <Input type="label" changed={this.handleFormChange} labelText="" field="othPhys3Name"
                                            value={this.state.diagnosisForm.othPhys3Name} width="145px" labelWidth="5px" lineStyle="oneLine"
                                            labelMainStyle="AllPhysicianNameLbl" />
                                        <Input type="input" changed={this.handleFormChange} labelText="" field="op3Order"
                                            value={this.state.diagnosisForm.op3Order} width="25px" labelWidth="5px" lineStyle="oneLine"
                                            maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["op3Order"] !== null && this.state.pageMaxlengthInput["op3Order"] !== "" ? this.state.pageMaxlengthInput["op3Order"] : "2"}
                                            lostfocused={this.handleLostFocus} ignoreRapid
                                            focused={this.handleOnFocus}
                                        />
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <Input type="Physician" focused={this.handleOnFocus} changed={this.handleFormChange}
                                            labelText="Oth Phys 4" field="othPhys4" lineStyle="oneLine"
                                            value={this.state.diagnosisForm.othPhys4 ? this.state.diagnosisForm.othPhys4 : this.state.diagnosisForm.OthPhys4}
                                            dataSource="Physicians" titleDialog="Other Physician 4" labelWidth="70px" width="68px"
                                            inValid={this.state.dAllItemFields["othPhys4"]} itemNbr=""
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["othPhys4"] : ""}
                                            lostfocused={this.handleLostFocus}
                                        />
                                        <Input type="label" changed={this.handleFormChange} labelText="" field="othPhys4Name"
                                            value={this.state.diagnosisForm.othPhys4Name} width="145px" labelWidth="5px" lineStyle="oneLine"
                                            labelMainStyle="AllPhysicianNameLbl" />
                                        <Input type="input" changed={this.handleFormChange} labelText="" field="op4Order"
                                            value={this.state.diagnosisForm.op4Order} width="25px" labelWidth="5px" lineStyle="oneLine"
                                            maxLength={this.state.pageMaxlengthInput && this.state.pageMaxlengthInput["op4Order"] !== null && this.state.pageMaxlengthInput["op4Order"] !== "" ? this.state.pageMaxlengthInput["op4Order"] : "2"}
                                            lostfocused={this.handleLostFocus} ignoreRapid
                                            focused={this.handleOnFocus}
                                        />
                                    </div>

                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Rad Onc Consult" field="radoncConsult" lineStyle="oneLine"
                                        value={this.state.diagnosisForm.RadoncConsult ? this.state.diagnosisForm.RadoncConsult : this.state.diagnosisForm.radoncConsult}
                                        dataSource="Global_Dir_Mv" titleDialog="Radonc Consult" aliasNo="236" labelWidth="110px" width="28px"
                                        inValid={this.state.dAllItemFields["radoncConsult"]} itemNbr="236"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["radoncConsult"] : ""}
                                        lostfocused={this.handleLostFocus}
                                    />
                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Med Onc Consult" field="medoncConsult" lineStyle="oneLine"
                                        value={this.state.diagnosisForm.MedoncConsult ? this.state.diagnosisForm.MedoncConsult : this.state.diagnosisForm.medoncConsult}
                                        dataSource="Global_Dir_Mv" titleDialog="Medonc Consult" aliasNo="293" labelWidth="110px" width="28px"
                                        inValid={this.state.dAllItemFields["medoncConsult"]} itemNbr="293"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["medoncConsult"] : ""}
                                        lostfocused={this.handleLostFocus}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>

                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetDiagnosis} formId="diagnosisForm"
                        isFormDirty={this.state.isFormDirty}
                        showExtra={[{ name: "Non-Analytic Defaults", action: "setDefaultDiagnosis" }, { name: "Text", action: "showDiagnosisText" }]}
                        //onSubmit={this.saveDiagnosis}
                        callbackToParent={this.updateSaveFlagToProcess}
                        fncNonAnalyticDefault={this.setDiagnosisDataDefault}
                        diagnosisForm={this.state.diagnosisForm}
                        focusingField={this.state.focusingField}
                    />

                </div>
            )
            : <p className="data_loading_page">Diagnosis data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>{validationPcError}</React.Fragment>

                <React.Fragment>
                    {showConfirmationToAddNew}
                </React.Fragment>

                <React.Fragment>
                    {hasNotificationSaveMsg}
                </React.Fragment>

                <React.Fragment>
                    {hasNotificationStateMsg}
                </React.Fragment>
                <React.Fragment>
                    {hasNotificationCountyMsg}
                </React.Fragment>
                <React.Fragment>
                    {hasNotificationCityMsg}
                </React.Fragment>
                <React.Fragment>
                    {hasNotificationSiteCodeMsg}
                </React.Fragment>

                <React.Fragment>
                    {diagnosisMaintenance}
                </React.Fragment>

                <div id="textareaEditorForItemsCls">
                    {
                        this.props.isShowTextDialogVisible ?
                            /*
                            <Dialog
                                title="Diagnosis Text &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Treatment &amp; Other Text"
                                onClose={this.props.closeTextDialog}
                                width={950} height={"auto"}
                                className={classes.title}>
                            */
                            <div className="CRStar_Window">
                                <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar">
                                    <div className="dragableLargerWindowCls">
                                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                                            <div className="k-window-title k-dialog-title">
                                                <pre className="textPopupPreTitleCls">{TextPopup.Title}</pre>
                                            </div>
                                            <div className="k-window-actions k-dialog-actions">
                                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeTextDialog(e) }}></span>
                                                </a>
                                            </div>
                                        </div>
                                        <TextForm closeWindow={this.closeTextDialog} setFocusingField={this.helpFocusingField} />
                                    </div>
                                    {/*</Dialog>*/}
                                </Draggable>
                            </div>
                            : null
                    }
                </div>

                <div>
                    {
                        this.props.isAddNewDialogVisible2 || (this.props.isAddNewDialogVisible && this.state.calledByMe) ?
                            <div className="CRStar_Window">
                                <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar" disabled={this.props.childrenOpening}>
                                    <div className="dragableLargerWindowCls">
                                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                                            <div className="k-window-title k-dialog-title">
                                                {
                                                    this.state.selectedFieldNoExistingVal !== "hospRefFrom" && this.state.selectedFieldNoExistingVal !== "hospRefTo" ? "New Physician" : "New Hospital"
                                                }
                                            </div>
                                            <div className="k-window-actions k-dialog-actions">
                                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeAddNewDialog(e) }}></span>
                                                </a>
                                            </div>
                                        </div>
                                        {
                                            this.state.selectedFieldNoExistingVal !== "hospRefFrom" && this.state.selectedFieldNoExistingVal !== "hospRefTo" ?
                                                <PhysicianForm closeWindow={this.closeAddNewDialog} isNotExist={true} /> :
                                                <HospitalForm closeWindow={this.closeAddNewDialog} isNotExist={true} />
                                        }
                                    </div>
                                </Draggable>
                            </div>
                            : null
                    }
                </div>

            </React.Fragment>
        );
    }
}

DiagnosisMaintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedDiagnosisRecord: state.patientAbstract.selectedDiagnosisRecord,
        isPatientLoading: state.patientAbstract.isPatientLoading,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
        selectedPatient: state.patientAbstract.selectedPatient,
        isShowTextDialogVisible: state.patientAbstract.isShowTextDialogVisible,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,
        patientId: state.patientAbstract.patientId,
        isAddNewDialogVisible: state.patientAbstract.isAddNewDialogVisible,
        isAddNewDialogVisible2: state.patientAbstract.isAddNewDialogVisible2,
        waitingFor: state.patientAbstract.waitingFor,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        handlerControlItem: state.patientAbstract.handlerControlItem,

        initialAbstractPages: state.patientAbstract.initialAbstractPages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveDiagnosis: (diagnosisData) => dispatch(actionCreators.saveDiagnosis(diagnosisData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        selectPatient: (patientId, isAddNew, onTab) => dispatch(actionCreators.selectPatient(patientId, isAddNew, onTab)),
        // show add new Physicians/Hospital popup
        addNewPhysicians: (newVal, flag) => dispatch(actionCreators.selectPhysicianRecord(newVal, flag)),
        addNewPhysiciansNotExist: (newVal) => dispatch(actionCreators.selectPhysicianRecord(newVal, true)),
        addNewHospital: (newVal) => dispatch(actionCreators.selectHospitalRecord(newVal)),
        closeAddNewDialog: () => dispatch(actionCreators.closeAddNewDialog()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        updateDiagnosisInfo: (name, value) => dispatch(actionCreators.updateDiagnosisInfo(name, value)),
        setHandlerControlItem: (item) => dispatch(actionCreators.setHandlerControlItem(item)),
        showPageLoading: (flag) => dispatch(actionCreators.showPageLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),

        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosisMaintenance);