/* eslint-disable */
import React, { Component } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import Dashboard from '../containers/Dashboard/Dashboard';
import RightPanel from '../containers/Panel/SidebarOnRight';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Login from './UI/Login/Login';
import Auth from '../containers/Auth/Auth';
import ForgotPassword from './UI/ForgotPassword/ForgotPassword';
import Tabs from "./Tabs";
import * as dialogTypes from "./AbstractManagement/maintDialogs";
import PatientMaintenance from "./AbstractManagement/PatientAbstract/PatientMaintenance/PatientMaintenance";
import DiagnosisMaintenance from "./AbstractManagement/PatientAbstract/DiagnosisMaintenance/DiagnosisMaintenance";
import StagingMaintenance from "./AbstractManagement/PatientAbstract/StagingMaintenance/StagingMaintenance";
import TreatmentMaintenance from "./AbstractManagement/PatientAbstract/TreatmentMaintenance/TreatmentMaintenance";
import OutcomesMaintenance from "./AbstractManagement/PatientAbstract/OutcomesMaintenance/OutcomesMaintenance";
import CancerProgramMaintenance from "./AbstractManagement/PatientAbstract/CancerProgramMaintenance/CancerProgramMaintenance";
import BreastProgramMaintenance from "./AbstractManagement/PatientAbstract/BreastProgramMaintenance/BreastProgramMaintenance";
import RectalProgramMaintenance from "./AbstractManagement/PatientAbstract/RectalProgramMaintenance/RectalProgramMaintenance";
import CaseAdminMaintenance from "./AbstractManagement/PatientAbstract/CaseAdminMaintenance/CaseAdminMaintenance";
import ClinicalMaintenance from "./AbstractManagement/PatientAbstract/ClinicalMaintenance/ClinicalMaintenance";
import ResearchMaintenance from "./AbstractManagement/PatientAbstract/ResearchMaintenance/ResearchMaintenance";
import SurgeryMaintenance from "./AbstractManagement/PatientAbstract/TreatmentMaintenance/SurgeryMaintenance/SurgeryMaintenance";
import RadiationMaintenance from "./AbstractManagement/PatientAbstract/TreatmentMaintenance/RadiationMaintenance/RadiationMaintenance";
import ChemoMaintenance from "./AbstractManagement/PatientAbstract/TreatmentMaintenance/ChemoMaintenance/ChemoMaintenance";
import HormoneMaintenance from "./AbstractManagement/PatientAbstract/TreatmentMaintenance/HormoneMaintenance/HormoneMaintenance";
import ImmunotherapyMaintenance from "./AbstractManagement/PatientAbstract/TreatmentMaintenance/ImmunotherapyMaintenance/ImmunotherapyMaintenance";
import HematopoieticMaintenance from "./AbstractManagement/PatientAbstract/TreatmentMaintenance/HematopoieticMaintenance/HematopoieticMaintenance";
import OtherMaintenance from "./AbstractManagement/PatientAbstract/TreatmentMaintenance/OtherMaintenance/OtherMaintenance";
import PalliativeMaintenance from "./AbstractManagement/PatientAbstract/TreatmentMaintenance/PalliativeMaintenance/PalliativeMaintenance";
import UserDefinedMaintenance from "./AbstractManagement/PatientAbstract/UserDefinedMaintenance/UserDefinedMaintenance";
import StateSpecificMaintenance from "./AbstractManagement/PatientAbstract/StateSpecificMaintenance/StateSpecificMaintenance";
import RetiredMaintenance from "./AbstractManagement/PatientAbstract/RetiredMaintenance/RetiredMaintenance";
import Aframe from './Aframe';
import { actionCreators } from "../store/PatientAbstract";
import * as actions from '../store/actions/index';
import ChangePwdDialog from "./SystemAdministration/SecurityMaintenance/ChangePwdDialog";
import TwoFactorAuthDialog from "./SystemAdministration/SecurityMaintenance/TwoFactorAuthDialog";
import UserMaintenance from './SystemAdministration/SecurityMaintenance/UserMaintenance';
import PasswordPolicyMaintenance from './SystemAdministration/SecurityMaintenance/PasswordPolicyMaintenance';
import DisplayLockedUsers from './SystemAdministration/SecurityMaintenance/DisplayLockedUsers';
import UserSecurityLookup from './SystemAdministration/SecurityMaintenance/UserSecurityLookup';
import Draggable from 'react-draggable';
import UtilityPage from './SystemAdministration/UtilityPage';
import SelectAPop from './Reporting/SelectAPop/SelectAPop';

//import GraphAnItem from "./Reporting/GraphAnItem/GraphAnItem";
//import SurvivalByStage from "./Reporting/SurvivalByStage/SurvivalByStage";

class Layout extends Component {

    state = {
        isAuthenticated: false,
        sidebarOpen: true,
        showChangePwdPopup: false,
        showTwoFactorAuthPopup: false,
        // Panel on right
        rightSidebar: false,//default
    }

    componentDidMount() {
        this.props.onTryAutoSignup();
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.showChangePwdPopup && !this.props.isChangePwdDialogVisible)
            || prevProps.isChangePwdDialogVisible != this.props.isChangePwdDialogVisible) {
            this.setState({ showChangePwdPopup: !this.props.isChangePwdDialogVisible });
        }

        if ((!this.state.showTwoFactorAuthPopup && this.props.isTwoFactorAuthDialogVisible)
            || prevProps.isTwoFactorAuthDialogVisible != this.props.isTwoFactorAuthDialogVisible
            || (!this.state.showTwoFactorAuthPopup && this.props.lastResult === "All" && this.props.isTwoFactorAuthDialogVisible)) {
            this.setState({ showTwoFactorAuthPopup: this.props.lastResult === "All" ? true : this.props.isTwoFactorAuthDialogVisible });
        }
    }

    handleViewSidebar = () => this.setState({ sidebarOpen: !this.state.sidebarOpen });
    handleToggleRightSidebar = () => this.setState({ rightSidebar: !this.state.rightSidebar });

    toTitleCase = (string) => {
        let title = string[0].toUpperCase() + string.slice(1).toLowerCase();
        if (title === "Treatment") {
            title += " Summary";
        } else {
            if (string === "User Maintenance" || string === "Password Policy Maintenance" || string === "Locked Users") {
                title = string;
            } else {
                title += " Maintenance";
            }
        }
        return title;
    }

    closeChildWindow = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.props.setChildDialogVisible(false);
        this.props.showingHelpInfoFunc(false);
        this.props.setChildrenOpening(false);

        //btnDisplayAllLockUsers
        setTimeout(function () {
            document.getElementById("btnDisplayAllLockUsers") ? document.getElementById("btnDisplayAllLockUsers").focus() : "";
        }, 100);

        return false;
    }

    // 
    closeChangePwdPopup = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ showChangePwdPopup: false });

        //this.props.setChangePwdDialogVisible(true);#PR00524

        this.props.setTab(dialogTypes.LOGIN);
        this.props.onLogout(this.props.userId);

        return false;
    }

    closeTwoFactorAuthPopup = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.props.setTab(dialogTypes.LOGIN);
        this.props.onLogout(this.props.userId);

        this.setState({ showTwoFactorAuthPopup: false });        

        return false;
    }

    render() {

        // Based on the 'current dialog' in the redux store (this prop is mapped from state)
        // We will render the respective Maintenance screen in the window
        let dialog;
        switch (this.props.currentDialog) {
            case dialogTypes.PATIENT:
                dialog = <PatientMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.DIAGNOSIS:
                dialog = <DiagnosisMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.STAGING:
                dialog = <StagingMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.TREATMENT:
                dialog = <TreatmentMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.OUTCOMES:
                dialog = <OutcomesMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.CPM:
                dialog = <CancerProgramMaintenance closeWindow={this.closeWindow} />
                break;
            case dialogTypes.BPM:
                dialog = <BreastProgramMaintenance closeWindow={this.closeWindow} />
                break;
            case dialogTypes.RPM:
                dialog = <RectalProgramMaintenance closeWindow={this.closeWindow} />
                break;
            case dialogTypes.CASE_ADMIN:
                dialog = <CaseAdminMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.CLINICAL:
                dialog = <ClinicalMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.RESEARCH:
                dialog = <ResearchMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.USER_DEFINED:
                dialog = <UserDefinedMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.STATE_SPECIFIC:
                dialog = <StateSpecificMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.RETIRED:
                dialog = <RetiredMaintenance closeWindow={this.closeWindow} />;
                break;

            case dialogTypes.ALPHA_MENU:
                dialog = <Aframe closeWindow={this.closeWindow} url={this.props.alphaUrl} />;
                break;

            case dialogTypes.LOGIN:
                return <Login>
                    <Switch>
                        <Route path="/login" component={Auth} />
                        <Route path="/forgot-password" component={ForgotPassword} />
                        <Redirect to="/login" />
                    </Switch>
                </Login>;
                break;

            // CRStar Reporting
            case dialogTypes.REPORTING_SELECT_A_POPULATION:
                this.props.selectAPopLabel();
                dialog = <SelectAPop closeWindow={this.closeWindow} />;
                break;

                {/*}
            case dialogTypes.REPORTING_GRAPH_AN_ITEM:
                this.props.selectAPopLabel();
                dialog = <GraphAnItem closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.REPORTING_SURVIVAL_BY_STAGE:
                this.props.selectAPopLabel();
                dialog = <SurvivalByStage closeWindow={this.closeWindow} />;
                break;
                {*/}

            case dialogTypes.USER_LOGINS:
                dialog = <UserMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.PASSWORD_POLICY:
                dialog = <PasswordPolicyMaintenance closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.LOCKED_USER:
                dialog = <DisplayLockedUsers closeWindow={this.closeWindow} />;
                break;
            case dialogTypes.USER_SECURITY:
                dialog = <UserSecurityLookup closeWindow={this.closeWindow} />
                break;
            /*---------------------------------------------------------------//COMMENT OUT THIS FOR TEST ONLY!!!
            case dialogTypes.USER_SECURITY:
                let url = "/UserSecurityMaintLookup";
                this.props.setAlphaUrl(url);
                dialog = <Aframe closeWindow={this.closeWindow} url={url} />;
                break;
            case dialogTypes.FOLLOW_UP_QA_LETTERS:
                let url = "/LettersMaint";
                this.props.setAlphaUrl(url);
                dialog = <Aframe closeWindow={this.closeWindow} url={url} />;
                break;
            case dialogTypes.CUSTOM_FORMS:
                let url = "/CustomFormsMaint";
                this.props.setAlphaUrl(url);
                dialog = <Aframe closeWindow={this.closeWindow} url={url} />;
                break;
            */

            case dialogTypes.UTILITY_PAGE:
                dialog = <UtilityPage closeWindow={this.closeWindow} />;
                break;

            default:
                dialog = null;
                break;
        }

        // Passwords are created/reset, requiring the user to change their password upon initial sign-in.
        let changePwdDialog;
        if (this.props.changedPwd) {
            this.props.setChangePwdDialogVisible(false);
            changePwdDialog = <ChangePwdDialog userId={this.props.userId}
                passExpired={this.props.passExpired}
                closeWindow={this.closeChildWindow} />;
        }

        // Two factor authentication
        let twoFactorAuthDialog;
        if (this.props.enable2fa === "1" && !this.props.changedPwd && this.props.isTwoFactorAuthDialogVisible
            && (this.props.lastAuth === null || this.props.lastResult === "All")) {
            console.log('[Layout] Two factor authentication...');

            // set this flag to sure must show QRCode image...
            if (this.props.lastAuth === null && !this.props.tfaConfigTitle) {
                this.props.setTfaPopupTitle("Configure ", null);
            }

            twoFactorAuthDialog = <TwoFactorAuthDialog
                userId={this.props.userId}
                key2FA={this.props.key2FA}
                lastAuth={this.props.lastAuth}
                closeWindow={this.closeChildWindow} />;
        }

        let childDialog;
        switch (this.props.childDialog) {
            case dialogTypes.SURGERY:
                childDialog = <SurgeryMaintenance closeWindow={this.closeChildWindow} />;
                break;
            case dialogTypes.RADIATION:
                childDialog = <RadiationMaintenance closeWindow={this.closeChildWindow} />;
                break;
            case dialogTypes.CHEMO:
                childDialog = <ChemoMaintenance closeWindow={this.closeChildWindow} />;
                break;
            case dialogTypes.HORMONE:
                childDialog = <HormoneMaintenance closeWindow={this.closeChildWindow} />;
                break;
            case dialogTypes.IMMUNOTHERAPY:
                childDialog = <ImmunotherapyMaintenance closeWindow={this.closeChildWindow} />;
                break;
            case dialogTypes.HEMATOPOIETIC:
                childDialog = <HematopoieticMaintenance closeWindow={this.closeChildWindow} />;
                break;
            case dialogTypes.PALLIATIVE:
                childDialog = <PalliativeMaintenance closeWindow={this.closeChildWindow} />;
                break;
            case dialogTypes.OTHER:
                childDialog = <OtherMaintenance closeWindow={this.closeChildWindow} />;
                break;

            // System Administration / Security Maintenance
            case dialogTypes.USER_MAINTENANCE:
                childDialog = <UserMaintenance closeWindow={this.closeChildWindow} />;
                break;
            case dialogTypes.LOCKED_USER:
                childDialog = <DisplayLockedUsers closeWindow={this.closeChildWindow} />;
                break;
            case dialogTypes.USER_SECURITY:
                childDialog = <UserSecurityLookup closeWindow={this.closeChildWindow} />
                break;

            case dialogTypes.UTILITY_PAGE:
                childDialog = <UtilityPage closeWindow={this.closeChildWindow} />;
                break;

            default:
                childDialog = null;
                break;
        }

        var contentClass = !this.state.sidebarOpen ? "content open" : "content";

        return (
            <React.Fragment >
                <div>
                    <NavMenu userId={this.props.userId} />

                    <Dashboard isOpen={this.state.sidebarOpen}
                        toggleSidebar={this.handleViewSidebar}
                        userId={this.props.userId}
                    />

                    <RightPanel isClose={this.state.rightSidebar}
                        leftSidebarOpen={this.state.sidebarOpen}
                        toggleSidebar={this.handleToggleRightSidebar}
                    />

                    <Container style={{ marginLeft: 0 }} className={contentClass}>
                        {
                            this.props.isPatientMenu ?
                                <div style={{ width: "1280px" }}>
                                    <Tabs activeTab={this.props.currentDialog} changedPwd={this.props.changedPwd}>
                                        {
                                            this.props.currentDialog == dialogTypes.ALPHA_MENU ?
                                                <div label={dialogTypes.ALPHA_MENU} style={{ display: (this.props.currentDialog === dialogTypes.ALPHA_MENU ? 'block' : 'none') }}>
                                                    {this.props.children}
                                                </div>
                                                :
                                                <div label={dialogTypes.LOOKUP} >
                                                    {this.props.children}
                                                </div>
                                        }

                                        {
                                            this.props.isParentWindowVisible && dialog ?
                                                <div label={this.props.currentDialog}>
                                                    {dialog}
                                                </div>
                                                :
                                                <div label=""></div>
                                        }
                                    </Tabs>
                                </div>
                                :
                                this.props.children
                        }
                    </Container>

                </div>

                <div className={"childrenWindow"
                    + (this.props.childDialog === dialogTypes.USER_MAINTENANCE ? "_2" : "")
                    + (this.props.isChildDialogVisible && childDialog ? "" : " hidden")}>
                    {
                        this.props.isChildDialogVisible && childDialog ?
                            (
                                this.props.childDialog === dialogTypes.USER_MAINTENANCE
                                    || this.props.childDialog === dialogTypes.LOCKED_USER ?
                                    <div className="CRStar_Window">
                                        <div className="fake_popup_cls"></div>
                                        <Draggable handle=".k-window-titlebar"
                                            disabled={this.props.childrenOpening}
                                            axis={this.props.childrenOpening ? "none" : "both"}>
                                            <div className="dragableLargestWindowCls">
                                                <div className="k-window-titlebar k-dialog-titlebar k-header">
                                                    <div className="k-window-title k-dialog-title">
                                                        {this.toTitleCase(this.props.childDialog)}
                                                    </div>
                                                    <div className="k-window-actions k-dialog-actions">
                                                        <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                            <span className="k-icon k-i-x" onClick={(e) => { this.closeChildWindow(e) }}></span>
                                                        </a>
                                                    </div>
                                                </div>
                                                {childDialog}
                                            </div>
                                        </Draggable>
                                    </div>
                                    :
                                    <div className="CRStar_Window">
                                        <div className="fake_popup_cls"></div>
                                        <Draggable handle=".k-window-titlebar"
                                            disabled={this.props.childrenOpening}
                                            axis={this.props.childrenOpening ? "none" : "both"}>
                                            <div className="dragableLargestWindowCls">
                                                <div className="k-window-titlebar k-dialog-titlebar k-header">
                                                    <div className="k-window-title k-dialog-title">
                                                        {this.toTitleCase(this.props.childDialog)}
                                                    </div>
                                                    <div className="k-window-actions k-dialog-actions">
                                                        <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                            <span className="k-icon k-i-x" onClick={(e) => { this.closeChildWindow(e) }}></span>
                                                        </a>
                                                    </div>
                                                </div>
                                                {childDialog}
                                            </div>
                                        </Draggable>
                                    </div>
                            )
                            : null
                    }
                </div>

                <div className={"fakeUIOnTop" + (this.state.showChangePwdPopup && changePwdDialog ? "" : " hidden")}>
                    <div className={"childrenWindow change_pwd_popup" + (this.state.showChangePwdPopup && changePwdDialog ? "" : " hidden")}>
                        {
                            this.state.showChangePwdPopup && changePwdDialog ?
                                <div className="CRStar_Window">
                                    <div className="fake_popup_cls"></div>
                                    <Draggable handle=".k-window-titlebar"
                                        disabled={this.props.childrenOpening}
                                        axis={this.props.childrenOpening ? "none" : "both"}>
                                        <div className="dragableLargerWindowCls" style={{ minWidth: "600px", left: "35%", top: "25%" }}>
                                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                                <div className="k-window-title k-dialog-title">Change Password</div>
                                                <div className="k-window-actions k-dialog-actions">
                                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                        <span className="k-icon k-i-x" onClick={(e) => { this.closeChangePwdPopup(e) }}></span>
                                                    </a>
                                                </div>
                                            </div>
                                            {changePwdDialog}
                                        </div>
                                    </Draggable>
                                </div>
                                : null
                        }
                    </div>
                </div>
                <div className={"fakeUIOnTop" + (this.state.showTwoFactorAuthPopup && twoFactorAuthDialog && this.props.isTwoFactorAuthDialogVisible ? "" : " hidden")}>
                    <div className={"childrenWindow two_factor_auth_popup" + (this.state.showTwoFactorAuthPopup && twoFactorAuthDialog && this.props.isTwoFactorAuthDialogVisible ? "" : " hidden")}>
                        {
                            this.state.showTwoFactorAuthPopup && twoFactorAuthDialog ?
                                <div className="CRStar_Window">
                                    <div className="fake_popup_cls"></div>
                                    <Draggable handle=".k-window-titlebar"
                                        disabled={this.props.childrenOpening}
                                        axis={this.props.childrenOpening ? "none" : "both"}>
                                        <div className="dragableLargerWindowCls" style={{ minWidth: "600px" }}>
                                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                                <div className="k-window-title k-dialog-title">
                                                    {this.props.tfaPopupTitle + "Two Factor Authentication"}
                                                </div>
                                                <div className="k-window-actions k-dialog-actions">
                                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                        <span className="k-icon k-i-x" onClick={(e) => { this.closeTwoFactorAuthPopup(e) }}></span>
                                                    </a>
                                                </div>
                                            </div>
                                            {twoFactorAuthDialog}
                                        </div>
                                    </Draggable>
                                </div>
                                : null
                        }
                    </div>
                </div>

            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
        userId: state.auth.userId,
        isPatientMenu: state.patientAbstract.isPatientMenu,
        currentDialog: state.patientAbstract.currentDialog,
        childDialog: state.patientAbstract.childDialog,
        isParentWindowVisible: state.patientAbstract.isParentWindowVisible,
        isChildDialogVisible: state.patientAbstract.isChildDialogVisible,
        isChangePwdDialogVisible: state.patientAbstract.isChangePwdDialogVisible,
        isTwoFactorAuthDialogVisible: state.patientAbstract.isTwoFactorAuthDialogVisible,
        tabItems: state.patientAbstract.tabItems,
        tfaPopupTitle: state.patientAbstract.tfaPopupTitle,
        tfaConfigTitle: state.patientAbstract.tfaConfigTitle,
        childrenOpening: state.patientAbstract.childrenOpening,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState()),
        onLogout: (uid) => dispatch(actions.logout(uid)),
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        setChangePwdDialogVisible: (flg) => dispatch(actionCreators.setChangePwdDialogVisible(flg)),
        setTwoFactorAuthDialogVisible: (flg) => dispatch(actionCreators.setTwoFactorAuthDialogVisible(flg)),
        setTfaPopupTitle: (title, form) => dispatch(actionCreators.setTfaPopupTitle(title, form)),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
        closeOtherTabItems: (flag) => dispatch(actionCreators.closeOtherTabItems(flag)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setAlphaUrl: (url) => dispatch(actionCreators.setAlphaUrl(url)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        showingHelpInfoFunc: (value) => dispatch(actionCreators.showingHelpInfo(value)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        selectAPopLabel: (label) => dispatch(actionCreators.selectAPopLabel(label)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);