/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../../../../store/PatientAbstract";
import Footer from "../../Shared/Footer";
import Input from "../../../../UI/Input";
import axios from "../../../../../axios-instance";
import "../../PatientMaintenance/PatientMaintenance.css";
import "../StateSpecificMaintenance.css";
import "../../DiagnosisMaintenance/DiagnosisMaintenance.css";
import moment from "moment";
import TextForm from "../../DiagnosisMaintenance/TextForm";
import Help from "../../../../UI/Help";
import Draggable from 'react-draggable';
import * as TextPopup from "../../TitleOfTextPopup";

class StateFieldsNYMaint extends Component {
    state = {
        //activeTab: "1",
        sAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'stateFieldsForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty stateFieldsForm back to what it was on load
        initialStateFieldsForm: null,
        stateFieldsForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        fieldInFocus: "nyFacType",
        isHelpVisible: false,
        isDialogVisible: true,

        isShowConfirmDeleteMsg: false,
        confirmed: null,
        monitoring: null,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,
        focusingField: null,
    }

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: flag });
    }

    closeWarningDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isDialogVisible: false });
        return false;
    }

    showTextDialog = () => {
        this.setState({ isShowTextVisible: true });
    }

    closeTextDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowTextVisible: false });
        this.props.closeTextDialog(false);

        return false;
    }

    componentDidMount() {
        if (!this.state.stateFieldsForm && this.props.selectedStateFieldsRecord) {
            this.setFormData();
        }
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.stateFieldsForm && this.props.selectedStateFieldsRecord)
            || (prevProps.selectedStateFieldsRecord && prevProps.selectedStateFieldsRecord !== this.props.selectedStateFieldsRecord)
            || (this.props.initialAbstractPages && this.props.selectedStateFieldsRecord)
        ) {
            this.setFormData();
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.stateFieldsForm && this.state.sAllItemFields
        ) {
            console.log("[StateFields] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    stateFieldsForm: { ...this.state.stateFieldsForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name }
                });
            } else {
                this.setState({
                    sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    stateFieldsForm: { ...this.state.stateFieldsForm, [this.props.currentSelectedField + "Name"]: "" }
                });
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = {table: "State_Fields", accessToken: localStorage.getItem('token')};

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                let btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    setFormData = () => {
        this.setState({
            stateFieldsForm: { ...this.props.selectedStateFieldsRecord },
            isFormDirty: this.props.isFormDirty
        });

        if (!this.state.sAllItemFields && this.props.selectedStateFieldsRecord) {
            let fields = {};
            Object.keys(this.props.selectedStateFieldsRecord).map((t) => {
                fields[t] = "";
            });

            this.setState({ sAllItemFields: fields, pageMaxlengthInput: fields });
        };

        this.props.setInitialAbstractPages(false);

        return false;
    }

    // Add clear and check validate of date fields function -- START
    clearInvalidOfDateFields = (formItems, field, onGrid, index) => {
        let tYear = field + "Year";
        let tMonth = field + "Month";
        let tDay = field + "Day";
        if (onGrid && onGrid === "primaryItemList") {
            this.setState(prevState => {
                let newItems = [...prevState.primaryItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { primaryItemFields: newItems };
            });
        } else {
            this.setState({
                sAllItemFields: {
                    ...this.state.sAllItemFields,
                    [tYear]: "",
                    [tMonth]: "",
                    [tDay]: "",
                },
                isFormDirty: true
            });
        }
        formItems[tYear] = "";
        formItems[tMonth] = "";
        formItems[tDay] = "";
    }

    checkValidateOfDateFields = (pFormData, field, onGrid, index) => {
        let isValidDateInput = true;

        var tYear = field + "Year";
        var tMonth = field + "Month";
        var tDay = field + "Day";

        // Check valid of date in February
        var year = pFormData[tYear] ? pFormData[tYear] : "";
        var month = pFormData[tMonth] ? pFormData[tMonth] : "";
        var day = pFormData[tDay] ? pFormData[tDay] : "";

        pFormData[field] = new Date(year + "-" + month + "-" + day);

        if (year !== "" && month !== "" && day !== "") {
            var _day, A = (month + '/' + day + '/' + year).match(/[1-9][\d]*/g);
            try {
                A[0] -= 1;
                _day = new Date(+A[2], A[0], +A[1]);
                if ((_day.getMonth() == A[0] && _day.getDate() == A[1])
                                /*|| year == '9999'*/ || month == '99' || day == '99') {
                } else {
                    isValidDateInput = false;

                    if (onGrid && onGrid === "primaryList") {
                        this.setState(prevState => {
                            const newItems = [...prevState.primaryItemFields];
                            newItems[index][tYear] = "IN_VALID";
                            newItems[index][tMonth] = "IN_VALID";
                            newItems[index][tDay] = "IN_VALID";
                            return { primaryItemFields: newItems };
                        });
                    } else {
                        this.setState({
                            sAllItemFields: {
                                ...this.state.sAllItemFields,
                                [tYear]: "IN_VALID",
                                [tMonth]: "IN_VALID",
                                [tDay]: "IN_VALID",
                            },
                            isFormDirty: true
                        });
                    }
                }
            } catch (er) {
                isValidDateInput = false;
            }
        }

        return isValidDateInput;
    }
    // -- END

    saveStateFields = (event) => {
        event.preventDefault();

        let isValid = true;
        let formItems = this.state.sAllItemFields;
        const allowSaveAnyway = this.state.allowSaveAnyway;
        
        Object.keys(formItems).map((t) => {
            if (t === "noStateDate" ) {
                this.clearInvalidOfDateFields(formItems, t);
            }

            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let stateFieldsFormData = this.state.stateFieldsForm;
            //stateFieldsFormData.cid = this.props.selectedMedicalRecord.cid;

            // append date fields from m/d/y
            let isValidDateInput = true;
            Object.keys(stateFieldsFormData).map((t, k) => {
                if (t === "noStateDate" ) {
                    isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(stateFieldsFormData, t);
                }
            });

            if (!isValidDateInput && !allowSaveAnyway) return false;

            this.props.saveStateFields(stateFieldsFormData);
            return false;
        }
    }

    resetStateFields = () => {
        this.setState({
            stateFieldsForm: { ...this.state.initialStateFieldsForm },
            isFormDirty: false,
        });
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    helpFocusingField = (field) => {
        this.setState({ fieldInFocus: field });
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isHelpVisible: false });
        return false;
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, -1);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let value = event.target.value;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "IN_VALID" } });
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState({ stateFieldsForm: { ...this.state.stateFieldsForm, [event.target.name]: value } });
                } else {
                    this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "IN_VALID" } });
                }
            } else {
                // clear all error style
                this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "" } });
            }
            //#20210125 - END

        } else {
            this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "" } });
        }
    }
    // --END

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ stateFieldsForm: { ...this.state.stateFieldsForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({ stateFieldsForm: { ...this.state.stateFieldsForm, [event.target.name]: event.target.value }, isFormDirty: true });
        }
    }

    render() {

        const stateSpecificMaintenance = this.state.stateFieldsForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">
                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} focusingField={this.state.focusingField} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.saveStateFields} id="stateFieldsForm" className="DialogForm TabParentPage">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq Prim: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo">
                                    <button type="button" onClick={this.getHelp}><img src="https://crstar.ers-can.com/images/$sys_question.png.a5image"/></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {this.props.selectedPatient.dob != null && this.props.selectedPatient.dob !== "" ? moment(new Date(this.props.selectedPatient.dob)).format("MM/DD/YYYY") : ""}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {this.props.selectedPrimarySummary.diagnosisDate != null && this.props.selectedPrimarySummary.diagnosisDate !== "" ? moment(new Date(this.props.selectedPrimarySummary.diagnosisDate)).format("MM/DD/YYYY") : ""}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>

                        <div className="emptyLine"></div>

                        <div>
                            <div className="row">
                                <div className="col-md-3 NYStMaint1">
                                    <div className="frameInner">
                                        <div className="header">&nbsp;</div>
                                        <div style={{ border: "1px solid #eee", marginBottom: "15px" }}>
                                            {/* <div className="header">&nbsp;</div> */}
                                            <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus} autoFocus
                                                changed={this.handleFormChange} width="30px"
                                                labelText="Facility Type" field="nyFacType" lineStyle="oneLine"
                                                value={this.state.stateFieldsForm.nyFacType ? this.state.stateFieldsForm.nyFacType : this.state.stateFieldsForm.NyFacType}
                                                dataSource="Global_Dir_Mv" aliasNo="1778" titleDialog="NY Facility Type" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["nyFacType"]} itemNbr="1778"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nyFacType"] : ""}
                                            />
                                            <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                changed={this.handleFormChange} width="30px"
                                                labelText="Service Type" field="nySource" lineStyle="oneLine"
                                                value={this.state.stateFieldsForm.nySource ? this.state.stateFieldsForm.nySource : this.state.stateFieldsForm.NySource}
                                                dataSource="Global_Dir_Mv" aliasNo="1779" titleDialog="NY Service Type" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["nySource"]} itemNbr="1779"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nySource"] : ""}
                                            />
                                            <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                changed={this.handleFormChange} labelWidth="135px" width="135px"
                                                labelText="Patient Control Nbr" field="nyPatControlNbr"
                                                value={this.state.stateFieldsForm.nyPatControlNbr} itemNbr="1780"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nyPatControlNbr"] : ""}
                                            />
                                            <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                changed={this.handleFormChange} labelWidth="135px" width="135px"
                                                labelText="PFI Number" field="nyPfiNumber"
                                                value={this.state.stateFieldsForm.nyPfiNumber} itemNbr="2191"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nyPfiNumber"] : ""}
                                            />
                                            <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                changed={this.handleFormChange} width="30px"
                                                labelText="Path Report Available" field="nyPathReport" lineStyle="oneLine"
                                                value={this.state.stateFieldsForm.nyPathReport ? this.state.stateFieldsForm.nyPathReport : this.state.stateFieldsForm.NyPathReport}
                                                dataSource="Global_Dir_Mv" aliasNo="2184" titleDialog="NY Path Report" labelWidth="135px"
                                                inValid={this.state.sAllItemFields["nyPathReport"]} itemNbr="2184"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nyPathReport"] : ""}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 NYStMaint2">
                                    <div className="frameInner">
                                        <div className="header">Retired Fields</div>
                                        <fieldset>
                                            <legend>Requesting Provider/Referring Facility</legend>
                                            <div style={{ display: "flex" }}>
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} labelWidth="100px" width="100px"
                                                    labelText="First Name" field="nyFirstName"
                                                    value={this.state.stateFieldsForm.nyFirstName} itemNbr="2185"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nyFirstName"] : ""}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} labelWidth="100px" width="100px"
                                                    labelText="Last Name" field="nyLastName" labelStyle="right"
                                                    value={this.state.stateFieldsForm.nyLastName} itemNbr="2186"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nyLastName"] : ""}
                                                />
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} labelWidth="100px" width="325px"
                                                    labelText="Address" field="nyAddress"
                                                    value={this.state.stateFieldsForm.nyAddress} itemNbr="2187"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nyAddress"] : ""}
                                                />
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} labelWidth="100px" width="125px"
                                                    labelText="City" field="nyCity"
                                                    value={this.state.stateFieldsForm.nyCity} itemNbr="2188"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nyCity"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    changed={this.handleFormChange} width="35px"
                                                    labelText="State" field="nyState" lineStyle="oneLine" labelStyle="right"
                                                    value={this.state.stateFieldsForm.nyState ? this.state.stateFieldsForm.nyState : this.state.stateFieldsForm.NyState}
                                                    dataSource="State_Codes" aliasNo="2189" titleDialog="State" labelWidth="40px"
                                                    inValid={this.state.sAllItemFields["nyState"]} itemNbr="2184"
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nyState"] : ""}
                                                />
                                                <div style={{ marginLeft: "5px"}}>
                                                    <Input type="input" focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                        changed={this.handleFormChange} labelWidth="30px" width="85px"
                                                        labelText="Zip" field="nyZipCode" labelStyle="right"
                                                        value={this.state.stateFieldsForm.nyZipCode} itemNbr="2190"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["nyZipCode"] : ""}
                                                    />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetStateFields} formId="stateFieldsForm"
                        isFormDirty={this.state.isFormDirty}
                        showExtra={[{ name: "Text", action: "showDiagnosisText" }]}
                        callbackToParent={this.updateSaveFlagToProcess}
                        focusingField={this.state.focusingField}
                    />
                </div>
            )
            : <p className="data_loading_page">State Specific data loading...</p>;
        return (
            <React.Fragment>

                <React.Fragment>
                    {stateSpecificMaintenance}
                </React.Fragment>

                <div id="textareaEditorForItemsCls">
                    {
                        this.props.isShowTextDialogVisible ?
                            /*
                            <Dialog
                                title="Diagnosis Text &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Treatment &amp; Other Text"
                                onClose={this.props.closeTextDialog}
                                width={950} height={"auto"}
                                className={classes.title}>*/
                            <div className="CRStar_Window">
                                <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar">
                                    <div className="dragableWindowCls">
                                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                                            <div className="k-window-title k-dialog-title">
                                                <pre className="textPopupPreTitleCls">{TextPopup.Title}</pre>
                                            </div>
                                            <div className="k-window-actions k-dialog-actions">
                                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeTextDialog(e) }}></span>
                                                </a>
                                            </div>
                                        </div>
                                        <TextForm closeWindow={this.closeTextDialog} setFocusingField={this.helpFocusingField} />
                                    </div>
                                    {/*</Dialog>*/}
                                </Draggable>
                            </div>
                            : null
                    }
                </div>

            </React.Fragment>
        );
    }
}

StateFieldsNYMaint.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedStateFieldsRecord: state.patientAbstract.selectedStateFieldsRecord,
        isPatientLoading: state.patientAbstract.isPatientLoading,

        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,

        selectedPatient: state.patientAbstract.selectedPatient,
        isShowTextDialogVisible: state.patientAbstract.isShowTextDialogVisible,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,

        currentDialog: state.patientAbstract.currentDialog,
        selectedHospital: state.patientAbstract.selectedHospital,
        currentShowingState: state.patientAbstract.currentShowingState,

        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,

        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,

        initialAbstractPages: state.patientAbstract.initialAbstractPages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveStateFields: (formData) => dispatch(actionCreators.saveStateFields(formData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index) => dispatch(actionCreators.checkValidInput(field, value, table, key, index)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),

        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        setChildrenWindowVisible: (flag) => dispatch(actionCreators.setChildDialogVisible(flag)),

        // Hospital and Physician lookup
        closeAddNewDialog: (flag) => dispatch(actionCreators.closeAddNewDialog(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        selectTabItems: (dialogType) => dispatch(actionCreators.selectTabItems(dialogType)),
        removeTabItems: (dialogType) => dispatch(actionCreators.removeTabItems(dialogType)),

        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StateFieldsNYMaint);