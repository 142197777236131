/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Input from "../../../UI/Input";
import axios from "../../../../axios-instance";
import Help from "../../../UI/Help";
import Draggable from 'react-draggable';
import "../DataExportsStyle.css";
import ConfigData from '../../../../store/config.json';
//import Iframe from 'react-iframe';
import * as actions from '../../../../store/actions/index';
import * as dialogTypes from "../../../AbstractManagement/maintDialogs";
import PrintAndDownloadSE from "../../../AbstractManagement/PatientAbstract/Shared/PrintAndDownloadSE";

class StateExport extends Component {

    downloadFile = false;
    populationLabel = null;
    state = {
        uAllItemFields: null,
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        fieldInFocus: "populationLabel",
        focusingField: null,
        isHelpVisible: false,
        loaded: false,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        isShowConfirmDeleteMsg: false,
        isShowConfirmDeleteLabelMsg: false,
        confirmed: null,
        monitoring: null,

        allowSaveAnyway: true,

        //
        titleDialog: null,
        showClockTimer: false,
        startTimeFrom: null,
        currentTime: null,
        showMessageError: false,
        selectedVersionOption: "22",
        selectedCaseOption: "0",
        setEventHandlerForGrid: false
    }

    toggleChecked = (event) => {
        const name = event.target.name;
        var dialogFormData = this.state.dialogForm;

        if (name == "updateFlagDate") {
            const checked = this.state.isCheckedUpdateFlagDate;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                isCheckedUpdateFlagDate: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        } else if (name == "runGenEdits") {
            const checked = this.state.isCheckedRunGenEdits;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                isCheckedRunGenEdits: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        } else {
            const checked = this.state.isCheckedZipFile;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                isCheckedZipFile: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        }
    }

    keydownHandler = (event) => {
        if (event.target.getAttribute("class") === "copyRowBtnOnGrid") {
            if (event.key === "Tab" && !event.shiftKey) {
                if (event.target.parentNode.parentNode.nextSibling !== null) {
                    let rindex = event.target.getAttribute("data-index");
                    rindex = parseInt(rindex) + 1;
                    setTimeout(function () {
                        document.getElementById("selectColumn" + rindex) ? document.getElementById("selectColumn" + rindex).focus() : "";
                    }, 100);
                } else {
                    setTimeout(function () {
                        document.getElementById("btnAddNewLatestReportLabelsMv") ? document.getElementById("btnAddNewLatestReportLabelsMv").focus() : "";
                    }, 100);
                }
            }
        }
    }

    selectionVersions = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedVersionOption: event.target.value,
            dialogForm: dialogFormData,
            isFormDirty: true
        });
    }
    selectionCases = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedCaseOption: event.target.value,
            dialogForm: dialogFormData,
            isFormDirty: true
        });
    }
    selectionExportType = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedExportTypeSelection: event.target.value,
            dialogForm: dialogFormData,
            isFormDirty: true
        });
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isHelpVisible: false });
        return false;
    }

    addEventListenerFnc = (om) => {
        var reportLabelsMvGrid = document.getElementById('reportLabelsMvListArea');
        reportLabelsMvGrid.addEventListener('keydown', function (e) {
            if (e.keyCode == 45 && e.target.getAttribute("data-index")) {// Insert key
                var insertedRowIdx = om.state.selectedReportLabelsMvRowIndex;
                om.insertNewReportLabelsMvGrid(insertedRowIdx);
                setTimeout(function () {
                    var selectedIndex = e.target.getAttribute("data-index");
                    document.getElementById('globalItemNumber' + selectedIndex) ? document.getElementById('globalItemNumber' + selectedIndex).focus() : "";//e.target.focus();
                    return false;
                }, 10);
                return false;
            } else if (e.keyCode == 46 && e.target.getAttribute("data-index")) {// Delete key
                om.deleteSelectedReportLabelsMv();//btnDeleteSelectedReportLabelsMv
                return false;
            } else if (e.key === "Tab" && !e.shiftKey && e.target.name == "limitingValues") {
                let rindex = e.target.getAttribute("data-index");
                var currentRow = e.target.parentNode && e.target.parentNode.parentNode && e.target.parentNode.parentNode.parentNode ? e.target.parentNode.parentNode.parentNode : null;
                var globalItemNumber = currentRow && currentRow.childNodes[1] && currentRow.childNodes[1].querySelector("#globalItemNumber" + rindex)
                    && currentRow.childNodes[1].querySelector("#globalItemNumber" + rindex).value ? currentRow.childNodes[1].querySelector("#globalItemNumber" + rindex).value : "";
                if (e.target.name == "limitingValues" && globalItemNumber != "" && currentRow && !currentRow.nextSibling) {
                    document.getElementById("btnAddNewLatestReportLabelsMv") ? document.getElementById("btnAddNewLatestReportLabelsMv").click() : "";
                    rindex = parseInt(rindex) + 1;
                    setTimeout(function () {
                        document.getElementById("globalItemNumber" + rindex) ? document.getElementById("globalItemNumber" + rindex).focus() : "";
                    }, 100);
                } else if (e.target.name == "limitingValues" && currentRow && !currentRow.nextSibling) {
                    setTimeout(function () {
                        document.getElementById("btnAddNewLatestReportLabelsMv") ? document.getElementById("btnAddNewLatestReportLabelsMv").focus() : "";
                    }, 100);
                }
            }
            return false;
        });

        return false;
    }

    initClock() {
        var _this = this;
        setInterval(function () {
            var today = new Date(),
                time = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            //d.toLocaleTimeString();
            _this.setState({ currentTime: time });

            return false;
        }, 1000);
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showMessageError: false,
            hasError: false,
            confirmed: true
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector("#BUTTON_RUN_REPORT") ? document.querySelector("#BUTTON_RUN_REPORT").focus() : "";
        }, 0);

        return false;
    }

    componentDidMount() {
        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);

        // Add all tabs in maintDialogs.js
        dialogTypes.dataExportsTabsDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog.name, dialog.id);
            this.props.setEnabledStatus(dialog.name);
        });

        if (!this.state.dialogForm && this.props.selectedReportLabelsInfo) {
            this.setFormData(this.props.selectedReportLabelsInfo);
        }

        /*
        // Loading form data...
        this.props.selectReportLabels("");
        if (document.getElementById("BUTTON_NEWRECORD")) {
            document.getElementById("BUTTON_NEWRECORD").click();
        }
        */

        this.setState({ isLoading: true, reload: true });
    }

    componentDidUnmount() {
        this.setState({ reload: true });
        this.props.selectReportLabels("");
    }

    componentWillMount() {
        this.props.setChildrenOpening(false);
        this.setState({ setEventHandlerForGrid: false, availableHosps: null });

        this.initClock();
    }

    componentDidUpdate(prevProps) {
        if (this.props.error && this.props.errorMessage) {
            this.setState({
                showClockTimer: false,
                showMessageError: true,
                dialogForm: {
                    ...this.state.dialogForm,
                    startTime: this.props.selectedReportLabelsInfo ? this.props.selectedReportLabelsInfo.startTime : "",
                    endTime: this.props.selectedReportLabelsInfo ? this.props.selectedReportLabelsInfo.endTime : "",
                    numberOfRecords: this.props.selectedReportLabelsInfo ? this.props.selectedReportLabelsInfo.numberOfRecords : "",
                },
                errorMessage: this.props.errorMessage,
            });

            document.querySelector('#BUTTON_RUN_REPORT') ? document.querySelector('#BUTTON_RUN_REPORT').focus() : null;

            this.props.clearErrorMessage();
            return false;
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.dialogForm && this.state.uAllItemFields
        ) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                /*
                this.setState({
                    //uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    //dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                });
                */
            }
            this.props.clearCommonLookupItem();
        }

        if ((!this.state.dialogForm && this.props.selectedReportLabelsInfo)
            || (prevProps.selectedReportLabelsInfo && this.props.selectedReportLabelsInfo && (prevProps.selectedReportLabelsInfo.reportLabelId !== this.props.selectedReportLabelsInfo.reportLabelId || prevProps.selectedReportLabelsInfo.reportLabel !== this.props.selectedReportLabelsInfo.reportLabel))
            || (prevProps.selectedReportLabelsInfo && this.props.selectedReportLabelsInfo && (prevProps.selectedReportLabelsInfo.startTime !== this.props.selectedReportLabelsInfo.startTime || prevProps.selectedReportLabelsInfo.endTime !== this.props.selectedReportLabelsInfo.endTime))
            || (this.state.reload && this.props.selectedReportLabelsInfo)
        ) {
            this.setFormData(this.props.selectedReportLabelsInfo);
        }

        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
        //<--
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "Report_Labels", accessToken: localStorage.getItem('token') };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res && res.data ? res.data.result : null
        });
    }

    drawingHtmlTable(dataForm) {
        this.setState({
            isDrawingHtmlTable: true,
            Fn_Html_Display_URL: dataForm.htmlDisplay,//fnHtmlDisplayUrl
            publicFolder: dataForm.publicFolder,
            showClockTimer: false,//hide clock timer
        });
        return false;
    }

    hideDrawingHtmlTable = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.setState({ isDrawingHtmlTable: false });
        /*
        //Deleting output file at public folder...
        const fileName = encodeURIComponent(this.state.publicFolder);
        var _this = this;
        axios.get(ConfigData.CRStarAPI_URL + _this.props.clientgroup + "/DeleteOutputFile?file=" + fileName)
            .then(res => {
                if (!res.data.error) {
                    _this.setState({ publicFolder: null });
                }
            });
        */
        return false;
    }

    acceptedHosp = ['CA', 'CO', 'GA', 'IL', 'MA', 'MI', 'MN', 'NC', 'ND', 'NJ', 'OH', 'PA', 'RI', 'SC', 'TN', 'TX', 'WI']; //08/26/2024 - Added MA

    setFormData = (selectedReportLabelsInfo) => {
        //const populationLabel = this.populationLabel ? this.populationLabel : "";
        this.setState({
            initialDialogForm: {
                ...selectedReportLabelsInfo,
                //populationLabel: populationLabel,
                filename: !selectedReportLabelsInfo["filename"] || selectedReportLabelsInfo["filename"] == "" ? "State Export" : selectedReportLabelsInfo["filename"],
            },
            dialogForm: {
                ...selectedReportLabelsInfo,
                //populationLabel: populationLabel,
                filename: !selectedReportLabelsInfo["filename"] || selectedReportLabelsInfo["filename"] == "" ? "State Export" : selectedReportLabelsInfo["filename"],
            },
            isFormDirty: this.props.isFormDirty,
            reload: false,
            changed: true,
            loaded: true,

            showClockTimer: false,
            titleDialog: null,

            isCheckedUpdateFlagDate: selectedReportLabelsInfo && selectedReportLabelsInfo.updateFlagDate == '1' ? true : false,
            isCheckedRunGenEdits: selectedReportLabelsInfo && selectedReportLabelsInfo.runGenEdits == '1' ? true : false,
            isCheckedZipFile: selectedReportLabelsInfo && selectedReportLabelsInfo.zipFile == '1' ? true : false,

            ["selectedVersionOption"]: selectedReportLabelsInfo && selectedReportLabelsInfo.naaccrVersion ? selectedReportLabelsInfo.naaccrVersion : "22",//default value
            ["selectedCaseOption"]: selectedReportLabelsInfo && selectedReportLabelsInfo.exportType ? selectedReportLabelsInfo.exportType : "0",//default value
            ["selectedExportTypeSelection"]: selectedReportLabelsInfo && selectedReportLabelsInfo.exportTypeSelection ? selectedReportLabelsInfo.exportTypeSelection : "State Export",//
        });

        if ((!this.state.uAllItemFields && selectedReportLabelsInfo)) {
            let fields = {};
            Object.keys(selectedReportLabelsInfo).map((t) => {
                fields[t] = "";
            });

            this.setState({ uAllItemFields: fields, pageMaxlengthInput: fields });
        };

        // Processing output...
        if (selectedReportLabelsInfo.endTime && selectedReportLabelsInfo.endTime != "" && selectedReportLabelsInfo.exitCode != "-1") {
            let paramsInAspxPage = selectedReportLabelsInfo.genEditsAspxPageUrl
                + "?Edits_Path=" + (selectedReportLabelsInfo.editsPath ? selectedReportLabelsInfo.editsPath : "")
                + "?MetaFile=" + (selectedReportLabelsInfo.metaFile ? selectedReportLabelsInfo.metaFile : "")
                + "?Record_Layout=" + (selectedReportLabelsInfo.recordLayout ? selectedReportLabelsInfo.recordLayout : "")
                + "?Edit_Set=" + (selectedReportLabelsInfo.editSet ? selectedReportLabelsInfo.editSet : "")
                + "?Datafile_Name=" + (selectedReportLabelsInfo.csFilename ? selectedReportLabelsInfo.csFilename.substring(0, selectedReportLabelsInfo.csFilename.lastIndexOf('.')) : "")
                + ".txt?Output_Path=" + (selectedReportLabelsInfo.folder + "\\")
                + "?Output_File_Name=" + (selectedReportLabelsInfo.outputFilename)
                + "?State=" + (this.props.currentShowingState);

            this.setState({
                editsUrl: selectedReportLabelsInfo.editsUrl,
                editsPath: selectedReportLabelsInfo.editsPath,
                dataFileName: selectedReportLabelsInfo.csFilename,
                outputPath: selectedReportLabelsInfo.folder,
                outputFilename: selectedReportLabelsInfo.outputFilename,
                showGeneditPopup: selectedReportLabelsInfo.runGenEdits == "1" && selectedReportLabelsInfo.fnGenEditsStartFile ? true : false,
                fnGenEditsStartFile: selectedReportLabelsInfo.fnGenEditsStartFile && selectedReportLabelsInfo.fnGenEditsStartFile != "" ? selectedReportLabelsInfo.fnGenEditsStartFile : null,
                MetaFile: selectedReportLabelsInfo.metaFile,
                IIS_Path: selectedReportLabelsInfo.iisPath,
                hospState: selectedReportLabelsInfo.hospState,
                radioOptions: selectedReportLabelsInfo.radioOptions,
                //
                isDrawingHtmlTable: selectedReportLabelsInfo.fnDisplayURL1 && selectedReportLabelsInfo.fnDisplayURL1 != "",
                selectedStateExport: "State Export",//selectedRadioOptions: "State Export",
                fnDisplayURL1: selectedReportLabelsInfo.fnDisplayURL1,
                fnDisplayURL2: selectedReportLabelsInfo.fnDisplayURL2,
                fnDisplayURL3: selectedReportLabelsInfo.fnDisplayURL3,
                fnDisplayURL4: selectedReportLabelsInfo.fnDisplayURL4,
                fnDownloadFile1: selectedReportLabelsInfo.fnDownloadFile1,
                fnDownloadFile2: selectedReportLabelsInfo.fnDownloadFile2,
                fnDownloadFile3: selectedReportLabelsInfo.fnDownloadFile3,
                fnDownloadFile4: selectedReportLabelsInfo.fnDownloadFile4,

                //
                fnHtmlDisplayURL1: selectedReportLabelsInfo.fnHtmlDisplayURL1,
                fnHtmlDisplayURL2: selectedReportLabelsInfo.fnHtmlDisplayURL2,
                fnHtmlDisplayURL3: selectedReportLabelsInfo.fnHtmlDisplayURL3,
                fnHtmlDisplayURL4: selectedReportLabelsInfo.fnHtmlDisplayURL4,

                //#PR00636: Interactive Edits
                iEfieldControls: selectedReportLabelsInfo.fnIeItemNbrs ? selectedReportLabelsInfo.fnIeItemNbrs : "",
                iElookupControls: selectedReportLabelsInfo.fnIeListNbrs ? selectedReportLabelsInfo.fnIeListNbrs : "",//#0
                iEnaaccrNumbers: selectedReportLabelsInfo.fnIeNaaccrNbrs ? selectedReportLabelsInfo.fnIeNaaccrNbrs : "",
                iEscreenNames: selectedReportLabelsInfo.fnIeScrnJmpNames ? selectedReportLabelsInfo.fnIeScrnJmpNames : "",
                iEscreenControls: selectedReportLabelsInfo.fnIeScrnJmpControls ? selectedReportLabelsInfo.fnIeScrnJmpControls : "",

                //js = js + "{dialog.Object}.setValue('FN_IELISTCODES','"+js_escape(ieListCodes)+"');"
                fnIeListCodes: selectedReportLabelsInfo.fnIeListCodes ? selectedReportLabelsInfo.fnIeListCodes : "",
                ieListCodes: selectedReportLabelsInfo.fnIeListCodes ? selectedReportLabelsInfo.fnIeListCodes : "",

                //FN_ieItemNbrs->onChange()::#!
                iEFileName: selectedReportLabelsInfo.fnIeFileName ? selectedReportLabelsInfo.fnIeFileName : "",
                iEColumnName: selectedReportLabelsInfo.fnIeColumnName ? selectedReportLabelsInfo.fnIeColumnName : "",
                iEItemNbrs: selectedReportLabelsInfo.fnIeListNbrs ? selectedReportLabelsInfo.fnIeListNbrs : "",//#0
                iELengths: selectedReportLabelsInfo.fnIeLength ? selectedReportLabelsInfo.fnIeLength : "",
                iETypes: selectedReportLabelsInfo.fnIeType ? selectedReportLabelsInfo.fnIeType : "",

                fnShowButtons: selectedReportLabelsInfo.fnShowButtons == "1",
                fnSaveAndRerun: false,
                fnDownload: selectedReportLabelsInfo.fnDownload,
                completed: false,
                linkToEditsFile: null,

                //
                paramsInAspxPage: paramsInAspxPage,
            });

            if (selectedReportLabelsInfo.runGenEdits == "1" && selectedReportLabelsInfo.fnGenEditsStartFile) {
                /*
                let _url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/RunEdits?Edits_Path=" + encodeURIComponent(selectedReportLabelsInfo.editsPath ? selectedReportLabelsInfo.editsPath : "")
                    + "?MetaFile=" + (selectedReportLabelsInfo.metaFile ? selectedReportLabelsInfo.metaFile : "")
                    + "?Datafile_Name=" + encodeURIComponent(selectedReportLabelsInfo.csFilename.substring(0, selectedReportLabelsInfo.csFilename.lastIndexOf('.')))
                    + ".txt?Output_Path=" + encodeURIComponent(selectedReportLabelsInfo.folder + "\\") + "?Output_File_Name=" + encodeURIComponent(selectedReportLabelsInfo.outputFilename)
                    + "?State=" + encodeURIComponent(this.props.currentShowingState);
                var _this = this;

                axios.get(_url)
                    .then(res => {
                        if (res && res.data) {
                            var bodyContentHtml = res.data.bodyContent;
                            var hadError = res.data.error;
                            _this.setState({ bodyContentOfEdits: bodyContentHtml, linkToEditsFile: res.data.fileUrl, completed: true });
                            setTimeout(function () {
                                var reportDisplay = document.getElementById("bodyContentOfEdits");
                                if (reportDisplay && (hadError == true || hadError == "true")) {
                                    reportDisplay.contentDocument.write(bodyContentHtml);
                                }

                                if (_this.state.fnDownload != "1") {
                                    _url = ConfigData.CRStarAPI_URL + _this.props.clientgroup + "/TestDownload?userid=" + _this.props.userId + "&outputFilename=" + selectedReportLabelsInfo.outputFilename;
                                    axios.get(_url)
                                        .then(res2 => {
                                            if (res2 && res2.data && res2.data == "1") {
                                                _this.setState({ fnDownload: true });
                                            }
                                        }).catch(error2 => {
                                            console.log(error2);
                                        });
                                }
                            }, 1000);
                        }
                    }).catch(error => {
                        console.log(error);
                    });
                }
                */
                return false;
            }

            return false;
        }
        return false;
    }

    selectionStateExport = (event) => {
        event.preventDefault();

        const name = event.target.name;
        const value = event.target.value;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedStateExport: event.target.value,
            dialogForm: dialogFormData
        });

        return false;
    }

    hideGeneditPopup = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }

        this.setState({ showGeneditPopup: false, paramsInAspxPage: null });
        /*
        //Also stopping the process of current genedits
        var _this = this;
        let url = ConfigData.CRStarAPI_URL + _this.props.clientgroup + "/StopRunEdits";
        axios.get(url)
            .then(res => {
                _this.setState({ showGeneditPopup: false });
            }).catch(error => {
                console.log(error);
            });
        */
        return false;
    }

    handleOnFocus = (event) => {
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true,
            });
            /*
            if (event.target.name === "populationLabel") {
                this.populationLabel = event.target.value;
            }
            */
        } else {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value
                },
                isFormDirty: true,
            });
        }
    }

    async Get_Data(reportLabel) {
        var _this = this;
        //Labels
        await _this.props.selectReportLabels(reportLabel);

        if (_this.props.selectedReportLabelsInfo) {
            setTimeout(function () {
                _this.setState({
                    reload: true,
                    isShowConfirmDeleteMsg: false,
                    isShowConfirmDeleteLabelMsg: false,
                    confirmed: null,
                    monitoring: null,
                });
            }, 1000);
        }

        return false;
    }

    handleLostFocus = (event) => {
        /*
        if (event.target.name === "populationLabel") {
            this.populationLabel = event.target.value;
        }
        */
    }

    //NAACCR_Export - call to server
    NAACCR_Export(e, flag = false) {
        try {
            e.preventDefault();
        } catch (ex) { }

        var dialogFormData = this.state.dialogForm;
        if (dialogFormData.populationLabel == "") {
            return false;
        }

        //
        if (document.getElementById("REPORTDATA_STATEEXPOR_XDRU_BUTTON_DOWNLOAD_FILE")) {
            document.getElementById("REPORTDATA_STATEEXPOR_XDRU_BUTTON_DOWNLOAD_FILE").setAttribute('disabled', true);
        }

        const currentShowingHospitalVersion = this.props.currentShowingHospitalVersion;

        dialogFormData.naaccrVersion = currentShowingHospitalVersion ? currentShowingHospitalVersion : "22";//this.state.selectedVersionOption ? this.state.selectedVersionOption : "22";
        dialogFormData.exportType = this.state.selectedCaseOption ? this.state.selectedCaseOption : "0";
        dialogFormData.exportTypeSelection = this.state.selectedExportTypeSelection ? this.state.selectedExportTypeSelection : "State Export";

        dialogFormData.userHosp = this.props.selectedHospital;
        dialogFormData.hospState = this.props.currentShowingState;

        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;
        dialogFormData.urlOutputFileZip = null;

        dialogFormData["incompleteOnly"] = flag ? "true" : "";

        //
        this.props.runStateExportForm(dialogFormData);

        this.downloadFile = false;//reset this flag to get download new file
        return false;
    }

    hideExportTypeOption = async (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isShowExportTypeOption: false });

        if (event.target.value === "yes") {
            //
            var today = new Date(),
                timeFrom = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            // set clock timer...
            this.setState({ showClockTimer: true, startTimeFrom: timeFrom, bodyContentOfEdits: null });

            //this.NAACCR_Export(event);
            var _this = this;
            var dialogFormData = this.state.dialogForm;
            let url = _this.props.clientgroup + "/ResetStateExport";
            await axios.post(url, { printForm: dialogFormData, accessToken: localStorage.getItem('token') })
                .then(res => {
                    _this.NAACCR_Export(event);
                    return false;
                }).catch(error => {
                    console.log(error);
                    return false;
                });
        }
        return false;
    }

    //on before show event
    async showEvent(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        var dialogFormData = this.state.dialogForm;
        if (dialogFormData.populationLabel == "") {
            return false;
        }

        var _this = this;
        //setTimeout(function () {
        if (_this.props.currentShowingState == "FL") {
            //BUTTON_EXPORT_METHOD
            _this.setState({ isShowExportTypeOption: true });
            return false;
        } else {
            //BUTTON_RUN_EXPORT
            //_this.NAACCR_Export(e);

            let url = _this.props.clientgroup + "/ResetStateExport";
            await axios.post(url, { printForm: dialogFormData, accessToken: localStorage.getItem('token') })
                .then(res => {
                    _this.NAACCR_Export(e);
                    return false;
                }).catch(error => {
                    console.log(error);
                    return false;
                });
        }
        var today = new Date(),
            timeFrom = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                + " " + (today.getHours() < 12 ? "AM" : "PM");

        // set clock timer...
        _this.setState({ showClockTimer: true, startTimeFrom: timeFrom, bodyContentOfEdits: null, linkToEditsFile: null });

        //return false;
        //}, 200);

        //
        var sInVal = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(sInVal);
            } else {
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
            }
        }, 750);

        return false;
    }

    Check_Status = (e, _this) => {
        e.preventDefault();

        let url = this.props.clientgroup + "/CheckStateExportStatus";
        axios.post(url, { printForm: _this.state.dialogForm, accessToken: localStorage.getItem('token') })
            .then(res => {
                if (res && res.data && !res.data.error) {
                    let status = res.data.result;
                    if (status && status.indexOf("Finished") != -1) {
                        _this.NAACCR_Export(e, true);
                    }
                    eval(res.data.result);
                }
            }).catch(error => {
                console.log(error);
            });

        return false;
    }

    Cancel_Report = (e, _this) => {
        e.preventDefault();

        _this.props.cancelSBSReport();

        setTimeout(function () {
            _this.setState({ showClockTimer: false });
            return false;
        }, 0);

        return false;
    }

    newRecord(e) {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.Get_Data("");
        this.setState({ reload: true });
        return false;
    }

    async printReport(e, obj) {
        //var iframe = document.getElementById("bodyContentOfEdits");
        //var iframeBody = iframe.contentDocument;
        //var printStyle = iframeBody.getElementById('PrintStyle');
        //var printContent = iframeBody.getElementById('PrintableContent');

        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        /*
        var html = '<!DOCTYPE html><html><head><title>GenEdits Report</title><style type="text/css">';
        html += printStyle.innerHTML + '</style></head><body><div id="printableContent">';
        html += printContent.innerHTML + '</div></body></html>';
        */

        var url = window.location.origin + "/" + obj.props.clientgroup + "/" + obj.state.outputFilename + ".html";
        let html = await axios
            .get(url)
            .then(function (response) {
                return response ? response.data : "";
            })
            .catch(function (error) {
                console.log(error);
            });

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }

    downloadReport = (event) => {
        event.preventDefault();

        //if (!this.state.outputFilename && this.state.fnDownload != "1") return false;

        var filename = this.state.outputFilename + ".rtf";

        const link = document.createElement('a');
        link.href = this.props.clientgroup + "/" + filename;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();

        return false;
    }

    //Copying from alpha's sources
    insertIeControls(iframeDoc, dObj) {
        var reactEnv = "QUICKLINKS";//{ dialog.Object }.getParentObject().dialogId;
        var scriptElement = iframeDoc.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.id = 'MyScript';

        var scriptSection = "function ChangeColorClick(tableRow, highLight){ ";
        scriptSection = scriptSection + "var ele=document.getElementById('MyScript'); ";
        scriptSection = scriptSection + "	var highlightedColor = 'rgb(220, 250, 201)'; ";
        scriptSection = scriptSection + "	var defaultColor = 'rgb(245, 245, 245)'; ";
        scriptSection = scriptSection + "	var currentColor = tableRow.style.backgroundColor; ";
        scriptSection = scriptSection + "    if (currentColor == highlightedColor) { ";
        scriptSection = scriptSection + "      tableRow.style.backgroundColor = defaultColor; ";
        scriptSection = scriptSection + "    } ";
        scriptSection = scriptSection + "    else { ";
        scriptSection = scriptSection + "     tableRow.style.backgroundColor = highlightedColor; ";
        scriptSection = scriptSection + "    } ";
        scriptSection = scriptSection + "  } ";

        scriptSection = scriptSection + "function ChangeColor(tableRow, highLight) {";
        scriptSection = scriptSection + "	var highlightedColor = 'rgb(220, 250, 201)'; ";
        scriptSection = scriptSection + "	var onColor = 'rgb(245, 245, 245)'; ";
        scriptSection = scriptSection + "	var defaultColor = 'white'; ";
        scriptSection = scriptSection + "	var currentColor = tableRow.style.backgroundColor; ";
        scriptSection = scriptSection + "	if (currentColor !== highlightedColor) { ";
        scriptSection = scriptSection + "		if (currentColor == onColor ) { ";
        scriptSection = scriptSection + "			tableRow.style.backgroundColor = defaultColor; ";
        scriptSection = scriptSection + "		} ";
        scriptSection = scriptSection + "		else { ";
        scriptSection = scriptSection + "			tableRow.style.backgroundColor = onColor; ";
        scriptSection = scriptSection + "		} ";
        scriptSection = scriptSection + "    } ";
        scriptSection = scriptSection + "  } ";

        scriptSection = scriptSection + "function GotoClick(thisObj) {";
        scriptSection = scriptSection + "var screenNameArray = thisObj.innerHTML.split(':');	 ";
        //scriptSection = scriptSection + "	parent.document.getElementById('GENEDITSMAINT.V.R1.FN_IESCREENJUMP').value = screenNameArray[1]; ";
        //scriptSection = scriptSection + "	parent.document.getElementById('GENEDITSMAINT.V.R1.FN_IESCREENJEMPCONTROL').value = thisObj.value; ";
        //scriptSection = scriptSection + "	parent.document.getElementById('GENEDITSMAINT.V.R1.BUTTON_5').click(); ";

        scriptSection = scriptSection + "	this.FN_IESCREENJUMP = screenNameArray[1]; ";
        scriptSection = scriptSection + "	this.FN_IESCREENJEMPCONTROL = thisObj.value; ";
        scriptSection = scriptSection + "	document.getElementById('TBI_REPORTADHO_BUTTON_SAVE_AND_RERUN').click(); ";

        scriptSection = scriptSection + "  } ";

        scriptElement.innerHTML = scriptSection;
        iframeDoc.head.appendChild(scriptElement);

        var iEfieldControls = this.state.iEfieldControls;//dObj.getValue('FN_IEITEMNBRS');
        var iElookupControls = this.state.iElookupControls;//dObj.getValue('FN_IELISTNBRS');
        var iEnaaccrNumbers = this.state.iEnaaccrNumbers;//dObj.getValue('FN_IENAACCRNBRS');
        var iEscreenNames = this.state.iEscreenNames;//dObj.getValue('FN_IESCRNJMPNAMES');
        var iEscreenControls = this.state.iEscreenControls;//dObj.getValue('FN_ieScrnJmpControls');
        var jmpNbrsArray = iEnaaccrNumbers.split(';');
        var jmpNames = iEscreenNames.split(';');
        var jmpControls = iEscreenControls.split(';');

        var newListNbrs = '(';
        var newItemNbrs = '(';
        var editsDetails = iframeDoc.getElementsByClassName('editDetails');
        for (var i = 0; i < editsDetails.length; i++) {
            var currEditsDetails = editsDetails[i];
            var rows = currEditsDetails.getElementsByTagName('tr');
            for (var r = 0; r < rows.length; r++) {
                var cells = rows[r].getElementsByTagName('td');
                rows[r].setAttribute("style", "table-layout:fixed;");
                rows[r].setAttribute("onmouseover", "ChangeColor(this,true);");
                rows[r].setAttribute("onmouseout", "ChangeColor(this,true);");
                var ieWorkCell = rows[r].getElementsByClassName('ieWork');
                var naaccrItemNbr = cells[0].innerHTML.substring(cells[0].innerHTML.indexOf('#') + 1, cells[0].innerHTML.length);

                var idNames = cells[3].getElementsByClassName('hiddenData');
                cells[2].setAttribute("style", "width:240px; overflow:hidden;");
                cells[3].setAttribute("style", "width:1px;");
                cells[4].setAttribute("style", "width:1px;");
                var idNameF = idNames[0].id.substring(0, idNames[0].id.indexOf('k')) + 'F' + idNames[0].id.substring(idNames[0].id.indexOf('k') + 1, idNames[0].id.length);
                var idNameL = idNames[0].id.substring(0, idNames[0].id.indexOf('k')) + 'L' + idNames[0].id.substring(idNames[0].id.indexOf('k') + 1, idNames[0].id.length);
                var idNameE = idNames[0].id.substring(0, idNames[0].id.indexOf('k')) + 'E' + idNames[0].id.substring(idNames[0].id.indexOf('k') + 1, idNames[0].id.length);

                if (iEfieldControls.indexOf(';' + naaccrItemNbr + ';') > -1) {

                    if (iElookupControls.indexOf(';' + naaccrItemNbr + ';') > -1) {
                        newListNbrs = newListNbrs + "'" + naaccrItemNbr + "',";
                        newItemNbrs = newItemNbrs + "'" + naaccrItemNbr + "',";
                        input = document.createElement('select');
                        input.id = idNameL;
                        input.class = 'dropdownClass';
                        input.setAttribute("style", "width:198px; border:#1581B3; border-style:solid; border-width:1px;");
                        var option = document.createElement('option');
                        option.text = '--Current Value--';
                        input.add(option);
                        ieWorkCell[0].appendChild(input);

                    } else {
                        newItemNbrs = newItemNbrs + "'" + naaccrItemNbr + "',";
                        var input = document.createElement('input');
                        input.id = idNameF;
                        input.class = 'fieldClass';
                        input.setAttribute("style", "border:#1581B3; border-style:solid; border-width:1px;");
                        ieWorkCell[0].appendChild(input);

                        var td = document.createElement('td');
                        td.id = idNameE
                        td.class = 'errorMsgClass';
                        td.setAttribute("style", "color:red;");
                        ieWorkCell[0].appendChild(td);
                    }
                } else {
                    if (iEnaaccrNumbers.indexOf(';' + naaccrItemNbr + ';') > -1) {
                        var jmpName = '';
                        var jmpControl = '';
                        for (var n = 0; n < jmpNbrsArray.length; n++) {
                            if (naaccrItemNbr == jmpNbrsArray[n]) {
                                jmpName = jmpNames[n];
                                jmpControl = jmpControls[n];
                            }
                        }

                        var btn = document.createElement('BUTTON');
                        btn.innerHTML = 'GoTo:' + jmpName;
                        btn.value = jmpControl;
                        btn.setAttribute("onclick", "GotoClick(this);");

                        if (reactEnv.substring(0, 10) == 'QUICKLINKS') {
                            btn = document.createElement('td');
                            btn.innerHTML = 'Located on ' + jmpName;
                        }

                        ieWorkCell[0].appendChild(btn);
                    }
                }
            }
        }
        newListNbrs = newListNbrs + "'end')";
        newItemNbrs = newItemNbrs + "'end')";

        //dObj.setValue('FN_IEITEMNBRS', newItemNbrs);
        //dObj.setValue('FN_IELISTNBRS', newListNbrs);
        this.FN_IEITEMNBRS = newItemNbrs;
        this.FN_IELISTNBRS = newListNbrs;

        //Reactjs -> also call addIeDropDowns
        var ieListCodes = this.state.ieListCodes;
        this.addIeDropDowns(iframeDoc, null, ieListCodes);
    }

    FN_IESCREENJUMP = null;
    FN_IESCREENJEMPCONTROL = null;

    FN_SaveString = "";
    FN_IEITEMNBRS = "";
    FN_IELISTNBRS = "";
    FN_SHOWBUTTONS = "";
    modifyReport = (event) => {
        /*
        {dialog.Object}.setValue('FN_SHOWBUTTONS','');

        var ifrm = document.getElementById('{dialog.componentname}.V.R1.IFRAME_1');
        var iframeDoc = ifrm.contentDocument;

        var iEfieldControls = {dialog.Object}.getValue('FN_IEITEMNBRS');
        var iElookupControls = {dialog.Object}.getValue('FN_IELISTNBRS');
        var dobj = {dialog.Object};
        insertIeControls(iframeDoc,dobj);
         */
        this.FN_showButtons = "";

        this.setState({ fnSaveAndRerun: true });

        var ifrm = document.getElementById('bodyContentOfEdits');
        var iframeDoc = ifrm.contentDocument;

        this.insertIeControls(iframeDoc, this);
    }
    fnSaveString = (event) => {
        //var ifrm = document.getElementById('{dialog.componentname}.V.R1.IFRAME_1');
        var ifrm = document.getElementById('bodyContentOfEdits');
        var iframeDoc = ifrm.contentDocument;

        var iEfieldControls = this.state.iEfieldControls;//{dialog.Object}.getValue('FN_IEITEMNBRS');
        var iEFileName = this.state.iEFileName;//{dialog.Object}.getValue('FN_IEFILENAME');
        var iEColumnName = this.state.iEColumnName;//{dialog.Object}.getValue('FN_IECOLUMNNAME');
        var iElookupControls = this.state.iElookupControls;//{dialog.Object}.getValue('FN_IELISTNBRS');

        var iEItemNbrs = this.state.iEItemNbrs;//{dialog.Object}.getValue('FN_IELISTNBRS');
        var iELengths = this.state.iELengths;//{dialog.Object}.getValue('FN_IELENGTH');
        var iETypes = this.state.iETypes;//{dialog.Object}.getValue('FN_IETYPE');

        var saveString = this.createSaveItems(iframeDoc, iEfieldControls, iElookupControls, iEFileName, iEColumnName);

        //{dialog.Object}.setValue('FN_SaveString', saveString);
        this.FN_SaveString = saveString;
        console.log("FN_SaveString", saveString);

        var _url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/SaveAndReRunEdits";
        var _this = this;
        axios.post(_url, { sql: saveString })
            .then(res => {
                if (res && res.data) {
                    /*
                    var bodyContentHtml = res.data.bodyContent;
                    var hadError = res.data.error;
                    _this.setState({ bodyContentOfEdits: bodyContentHtml, linkToEditsFile: res.data.fileUrl, completed: true });
                    setTimeout(function () {
                        var reportDisplay = document.getElementById("bodyContentOfEdits");
                        if (reportDisplay && (hadError == true || hadError == "true")) {
                            reportDisplay.contentDocument.write(bodyContentHtml);
                        }
                    }, 1000);
                    */
                    let datafileName = _this.state.dataFileName ? _this.state.dataFileName : "";
                    if (datafileName.lastIndexOf(".") != -1) {
                        datafileName = _this.state.dataFileName.substring(0, _this.state.dataFileName.lastIndexOf('.'));
                    }
                    _url = ConfigData.CRStarAPI_URL + _this.props.clientgroup + "/RunEdits?Edits_Path=" + encodeURIComponent(_this.state.editsPath ? _this.state.editsPath : "")
                        + "?MetaFile=" + (_this.state.MetaFile ? _this.state.MetaFile : "")
                        + "?Datafile_Name=" + encodeURIComponent(datafileName)
                        + ".txt?Output_Path=" + encodeURIComponent(_this.state.outputPath + "\\") + "?Output_File_Name=" + encodeURIComponent(_this.state.outputFilename)
                        + "?State=" + encodeURIComponent(_this.state.hospState ? _this.state.hospState : _this.props.currentShowingState);

                    axios.get(_url)
                        .then(res => {
                            if (res && res.data) {
                                var bodyContentHtml = res.data.bodyContent;
                                var hadError = res.data.error;
                                _this.setState({ bodyContentOfEdits: bodyContentHtml, linkToEditsFile: res.data.fileUrl, completed: true });
                                setTimeout(function () {
                                    var reportDisplay = document.getElementById("bodyContentOfEdits");
                                    if (reportDisplay && (hadError == true || hadError == "true")) {
                                        reportDisplay.contentDocument.write(bodyContentHtml);
                                    }
                                }, 1000);
                            }
                        }).catch(error => {
                            console.log(error);
                        });

                    return false;
                }
            }).catch(error => {
                console.log(error);
            });
        return false;
    }

    //
    createSaveItems(iframeDoc, iEfieldControls, iElookupControls, iEFileName, iEColumnName) {
        var saveString = ';';
        var editsDetails = iframeDoc.getElementsByClassName('editDetails');
        var itemNbrArray = iEfieldControls.split(';');
        var fileNameArray = iEFileName.split(';');
        var columnNameArray = iEColumnName.split(';');

        for (var i = 0; i < editsDetails.length; i++) {
            var fileName = '';
            var columnName = '';
            var currEditsDetails = editsDetails[i];
            var rows = currEditsDetails.getElementsByTagName('tr');
            for (var r = 0; r < rows.length; r++) {
                var cells = rows[r].getElementsByTagName('td');
                var ieWorkCell = rows[r].getElementsByClassName('ieWork');
                var naaccrItemNbr = cells[4].getElementsByClassName('hiddenData')[0].value;

                for (var n = 0; n < itemNbrArray.length; n++) {
                    if (naaccrItemNbr == itemNbrArray[n]) {
                        fileName = fileNameArray[n];
                        columnName = columnNameArray[n];
                    }
                }
                var idNames = cells[3].getElementsByClassName('hiddenData');
                var idNameF = idNames[0].id.substring(0, idNames[0].id.indexOf('k')) + 'F' + idNames[0].id.substring(idNames[0].id.indexOf('k') + 1, idNames[0].id.length);
                var lenMsg = '';
                if (iEfieldControls.indexOf(";" + naaccrItemNbr + ";") > -1) {
                    try {
                        var fieldValue = ieWorkCell[0].getElementsByTagName('input')[0].value;
                    }
                    catch (err) {
                        var fieldValue = ieWorkCell[0].getElementsByTagName('select')[0].value;
                        fieldValue.substring(0, fieldValue.indexOf('-') - 1);
                    }
                    if (fieldValue !== '' && fieldValue !== '--Current Value--') {
                        var cidKey = cells[3].getElementsByClassName('hiddenData')[0].value;
                        var NewSaveString = "UPDATE " + fileName + " SET " + columnName + " = '" + fieldValue + "' WHERE CID = '" + cidKey + "';";
                        if (fileName == 'PATIENT' || fileName == 'Patient') {
                            NewSaveString = "UPDATE " + fileName + " SET " + columnName + " = '" + fieldValue + "' WHERE PID IN (SELECT PID from PRIMARY_SUMMARY WHERE  CID = '" + cidKey + "');";
                        }

                        if (NewSaveString.length + saveString.length >= 65500) {
                            lenMsg = 'Save string exceeds maximum length';
                        } else {
                            saveString = saveString + NewSaveString;
                        }
                    }
                }
            }
        }
        return saveString;
    }
    addIeDropDowns(iframeDoc, iElookupControls, ieListCodes) {
        var editsDetails = iframeDoc.getElementsByClassName('editDetails');
        ieListCodes = ieListCodes.replace(/'/g, '');
        ieListCodes = ieListCodes.replace(/\(|\)/g, '');

        var ieListCodesArray = ieListCodes.split('!');
        for (var i = 0; i < editsDetails.length; i++) {
            var currEditsDetails = editsDetails[i];
            var rows = currEditsDetails.getElementsByTagName('tr');

            for (var r = 0; r < rows.length; r++) {
                var cells = rows[r].getElementsByTagName('td');
                var ieWorkCell = rows[r].getElementsByClassName('ieWork');
                var naaccrItemNbr = cells[4].getElementsByClassName('hiddenData')[0].value;
                if (ieListCodes.indexOf('!' + naaccrItemNbr + '|') > -1) {
                    for (var l = 0; l < ieListCodesArray.length; l++) {
                        var curListCodesRow = ieListCodesArray[l];
                        try {
                            var curListCodesArray = curListCodesRow.split('|');
                            var curNaaccrItemNbr = curListCodesArray[0];
                            if (curNaaccrItemNbr == naaccrItemNbr) {
                                var selObj = ieWorkCell[0].getElementsByTagName('select')[0];
                                var opt = document.createElement('option');
                                opt.text = curListCodesArray[1] + ' - ' + curListCodesArray[2];
                                opt.value = curListCodesArray[1];
                                selObj.appendChild(opt);
                            }
                        }
                        catch (err) {
                        }
                    }
                }
            }
        }
    }
    validateSaveItems(iframeDoc, ieItemNbrs, ieLengths, ieTypes) {
        var saveString = ';';
        var editsDetails = iframeDoc.getElementsByClassName('editDetails');
        var itemNbrArray = ieItemNbrs.split(';');
        var lengthArray = ieLengths.split(';');
        var typesArray = ieTypes.split(';');

        for (var i = 0; i < editsDetails.length; i++) {
            var validLength = '';
            var validType = '';
            var currEditsDetails = editsDetails[i];
            var rows = currEditsDetails.getElementsByTagName('tr');
            for (var r = 0; r < rows.length; r++) {
                var cells = rows[r].getElementsByTagName('td');
                var ieWorkCell = rows[r].getElementsByClassName('ieWork');
                var naaccrItemNbr = cells[4].getElementsByClassName('hiddenData')[0].value;

                for (var n = 0; n < itemNbrArray.length; n++) {
                    if (naaccrItemNbr == itemNbrArray[n]) {
                        validLength = lengthArray[n];
                        validType = typesArray[n];
                    }
                }
                var idNames = cells[3].getElementsByClassName('hiddenData');
                var idNameF = idNames[0].id.substring(0, idNames[0].id.indexOf('k')) + 'F' + idNames[0].id.substring(idNames[0].id.indexOf('k') + 1, idNames[0].id.length);
                var idNameN = idNames[0].id.substring(0, idNames[0].id.indexOf('k')) + 'N' + idNames[0].id.substring(idNames[0].id.indexOf('k') + 1, idNames[0].id.length);
                var idNameE = idNames[0].id.substring(0, idNames[0].id.indexOf('k')) + 'E' + idNames[0].id.substring(idNames[0].id.indexOf('k') + 1, idNames[0].id.length);

                var dataEntered = getElementById(idNameF).value;

                getElementById(idNameE).value = 'value entered is ' + dataEntered + '.  Length should be ' + validLength + '. Data Type should be ' + validType;
            }
        }
        return saveString;
    }


    render() {
        let showMessageError;
        {
            showMessageError = this.state.showMessageError && this.state.dialogForm ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">{this.state.errorMessage && this.state.errorMessage.indexOf("You do not have permission to export data for this population.") != -1 ? "Access Denied" : "Error"}</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">{this.state.errorMessage ? this.state.errorMessage : "Can't update this label."}</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTimeFrom}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Check_Status(e, this) }} className="ErsGrBlueButton" value="yes" autoFocus>Check Status</button>
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="ErsGrBlueButton hidden" value="no">Cancel</button>
                                    {/*}
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                    {*/}
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let showGeneditPopup;
        {
            showGeneditPopup = this.state.showGeneditPopup && this.state.fnGenEditsStartFile ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableAlphaWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">GenEdits Report</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideGeneditPopup(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>

                            <div className="container printOnQaForm" id="printAbtractsMainTable_GenEdits" style={{ padding: "0px !important", overflowY: "hidden !important" }}>
                                <div dangerouslySetInnerHTML={{ __html: this.state.bodyContentOfEdits }} className="hidden"></div>
                                <iframe id="bodyContentOfEdits" style={{ height: "100% !important" }}
                                    //src={this.state.linkToEditsFile ? (this.props.clientgroup + "/" + this.state.linkToEditsFile) : "loading.html"}
                                    src={this.state.paramsInAspxPage ? this.state.paramsInAspxPage : "loading.html"}
                                />
                            </div>

                            { /**/}
                            <div className="emptyLine"></div>
                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-sm-8" style={{ display: "flex", margin: "15px -5px" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ScreenButton _ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={(e) => { this.printReport(e, this) }} value="print" autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$print.png.a5image" className="" />
                                            Print</button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_DOWNLOAD" className="ScreenButton _ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px" }} //disabled={this.state.fnDownload != "1"}
                                            onClick={this.downloadReport} value="download">
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$application.ms.word.png.a5image" className="" />
                                            Download</button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ScreenButton _ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={this.hideGeneditPopup} value="exit">
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit</button>
                                    </div>
                                    { /* }
                                    <div className="col-sm-4 hidden" style={{ display: "none", margin: "15px -5px" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_MODIFY" className="ScreenButton _ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px", display: this.state.fnShowButtons ? "" : "none" }}
                                            disabled={this.state.fnSaveAndRerun}
                                            onClick={this.modifyReport} value="modify">Modify</button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_SAVE_AND_RERUN" className="ScreenButton _ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px", display: this.state.fnSaveAndRerun ? "" : "none" }}
                                            onClick={this.fnSaveString} value="save_and_rerun">Save & Rerun</button>
                                    </div>
                                    { */ }
                                </div>
                            </div>
                            { /**/}
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let showExportTypeOption;
        {
            showExportTypeOption = this.state.isShowExportTypeOption ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Choose Export Type</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideExportTypeOption(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <label>Choose the type of export to run:</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <label style={{ width: "240px", height: "0px" }}>
                                            <input type="radio" onClick={this.selectionExportType} checked={this.state.selectedExportTypeSelection === "State Export"}
                                                name="exportTypeSelection" field="exportTypeSelection" value="State Export"
                                                style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                State Export</label>
                                        <br />
                                        <label style={{ width: "240px", height: "0px" }}>
                                            <input type="radio" onClick={this.selectionExportType} checked={this.state.selectedExportTypeSelection === "Follow-Up Only Export"}
                                                name="exportTypeSelection" field="exportTypeSelection" value="Follow-Up Only Export" width="240px"
                                                style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                Follow-Up Only Export</label>
                                    </div>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button id="BUTTON_RUN_EXPORT" onClick={this.hideExportTypeOption} value="yes" autoFocus>Ok</button>
                                <button id="BUTTON_DELETE_CANCEL" onClick={this.hideExportTypeOption} value="no">Cancel</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const dialogForm = this.state.dialogForm && this.state.loaded && this.props.initialPageVisible ?
            (
                <div className="container-fluid2">
                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} focusingField={this.state.focusingField} closeCode={this.closeCodeDialog} />}

                    <form id="StateExportForm" className={this.props.isPopup ? "DialogForm2" : ""}>
                        <div className="emptyLine"></div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="_frameInner" style={{ marginRight: "15px" }}>
                                    <fieldset style={{ padding: "0", border: "none", borderBottom: "1px solid #ccc" }}>
                                        <legend></legend>
                                        <div className="row">
                                            <div className="col-sm-10">
                                                <Input type="LabelPopup" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="320px"
                                                    labelText="Population Label"
                                                    field="populationLabel" lineStyle="oneLine"
                                                    value={this.state.dialogForm.populationLabel}
                                                    dataSource="Labels"
                                                    titleDialog="Population Lookup" labelWidth="110px"
                                                    inValid={this.state.uAllItemFields["populationLabel"]}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["populationLabel"] : ""}
                                                    lostfocused={this.handleLostFocus} autoFocus
                                                />
                                            </div>
                                        </div>

                                        <div className="emptyLine"></div>

                                        <div className="row">
                                            <div className="col-sm-9">
                                                <Input type="input" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange}
                                                    labelText="Filename" labelWidth="110px" width="190px"
                                                    field="filename" lineStyle="oneLine" keyId="filename0"
                                                    value={this.state.dialogForm.filename}
                                                    lostfocused={this.handleLostFocus} rowIndex="0"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-3 checkboxTypeCls">
                                                <Input type="checkbox" lineStyle="oneLine"
                                                    labelText="Update State Report Flag and Transmit Date" field="updateFlagDate"
                                                    labelWidth="200px"
                                                    labelStyle="lblOnCheckbox" width="20px"
                                                    value={this.state.isCheckedUpdateFlagDate ? "1" : "0"}
                                                    checked={this.state.isCheckedUpdateFlagDate ? true : false}
                                                    clicked={this.toggleChecked}
                                                    style={{ cursor: "pointer", fontWeight: "bold" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3 checkboxTypeCls">
                                                <Input type="checkbox" lineStyle="oneLine"
                                                    labelText="Run GenEdits after Export" field="runGenEdits"
                                                    labelWidth="200px"
                                                    labelStyle="lblOnCheckbox" width="20px"
                                                    value={this.state.isCheckedRunGenEdits ? "1" : "0"}
                                                    checked={this.state.isCheckedRunGenEdits ? true : false}
                                                    clicked={this.toggleChecked}
                                                    style={{ cursor: "pointer", fontWeight: "bold" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3 checkboxTypeCls">
                                                <Input type="checkbox" lineStyle="oneLine"
                                                    labelText="Zip Data File" field="zipFile"
                                                    labelWidth="200px"
                                                    labelStyle="lblOnCheckbox" width="20px"
                                                    value={this.state.isCheckedZipFile ? "1" : "0"}
                                                    checked={this.state.isCheckedZipFile ? true : false}
                                                    clicked={this.toggleChecked}
                                                    style={{ cursor: "pointer", fontWeight: "bold" }}
                                                />
                                            </div>
                                        </div>

                                        <div className="emptyLine"></div>

                                        <div className="row hidden">
                                            <div className="col-sm-12">
                                                <label>Export Data Format:</label>
                                            </div>
                                        </div>
                                        <div className="row hidden">
                                            <div className="col-sm-12">
                                                <label style={{ width: "240px", height: "0px" }}>
                                                    <input type="radio" onClick={this.selectionVersions} checked={this.state.selectedVersionOption === "21"}
                                                        name="naaccrVersion" field="naaccrVersion" value="21"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                Version 21 (xml layout)</label>
                                                <br />
                                                <label style={{ width: "240px", height: "0px" }}>
                                                    <input type="radio" onClick={this.selectionVersions} checked={this.state.selectedVersionOption === "18"}
                                                        name="naaccrVersion" field="naaccrVersion" value="18" width="240px"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                Version 18 (fixed-width layout)</label>
                                            </div>
                                        </div>

                                        <div className="emptyLine"></div>
                                        <div className="row" style={{ display: this.acceptedHosp.includes(this.props.currentShowingState) ? "" : "none" }}>
                                            <div className="col-sm-12">
                                                <label>Export Type:</label>
                                            </div>
                                        </div>
                                        <div className="row" style={{ display: this.acceptedHosp.includes(this.props.currentShowingState) ? "" : "none" }}>
                                            <div className="col-sm-12">
                                                <label style={{ width: "240px", height: "0px" }}>
                                                    <input type="radio" onClick={this.selectionCases} checked={this.state.selectedCaseOption === "0"}
                                                        name="exportType" field="exportType" value="0"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                New</label>
                                                <br />
                                                <label style={{ width: "240px", height: "0px" }}>
                                                    <input type="radio" onClick={this.selectionCases} checked={this.state.selectedCaseOption === "1"}
                                                        name="exportType" field="exportType" value="1" width="240px"
                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                Corrections</label>
                                            </div>
                                        </div>

                                        <div className="emptyLine"></div>
                                    </fieldset>

                                    <div className="emptyLine"></div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", marginLeft: "15px", paddingTop: "2px" }}
                                                labelText="Start Time" field="startTime" width="auto"
                                                value={this.state.dialogForm.startTime ? this.state.dialogForm.startTime : "-"}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", paddingTop: "2px" }}
                                                labelText="End Time" field="endTime" width="auto"
                                                value={this.state.dialogForm.endTime ? this.state.dialogForm.endTime : "-"}
                                            />
                                        </div>
                                        <div className="col-sm-6 hidden">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", borderStyle: "none", paddingTop: "2px" }}
                                                labelText="Records Processed" field="numberOfRecords" width="auto"
                                                value={this.state.dialogForm.numberOfRecords ? this.state.dialogForm.numberOfRecords : "0"}
                                            />
                                        </div>
                                    </div>

                                    <fieldset className="bottomAreaButtonCls">
                                        <legend></legend>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_RUN_REPORT"
                                                        className={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.showEvent(e) }}
                                                        disabled={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? false : true}
                                                    >
                                                        <img id="BUTTON_RUN_REPORT_ICON"
                                                            src={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "https://crstar.ers-can.com/images/$$code.action.run.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$code.action.run.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Run
                                                    </button>
                                                </div>

                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_NEWRECORD"
                                                        className={(this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" || this.state.isFormDirty) ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.newRecord(e) }}
                                                    >
                                                        <img id="BUTTON_NEWRECORD_ICON"
                                                            src={(this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" || this.state.isFormDirty) ? "https://crstar.ers-can.com/images/$$navigate.new.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$navigate.new.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        New
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            )
            : <p className="">State Export data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>

                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {showExportTypeOption}
                </React.Fragment>

                <React.Fragment>
                    <div className={this.props.initialPageVisible ? "" : "hidden"}>
                        {dialogForm}
                    </div>
                </React.Fragment>

                <React.Fragment>
                    {
                        this.state.isDrawingHtmlTable && <PrintAndDownloadSE
                            pTitle="Data Exported"
                            selectedReportLabelsInfo={this.props.selectedReportLabelsInfo}
                            closeWindow={this.hideDrawingHtmlTable}
                        />
                    }
                </React.Fragment>

                <React.Fragment>
                    {showGeneditPopup}
                </React.Fragment>

            </React.Fragment>
        );
    }
}

StateExport.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        selectedReportLabelsInfo: state.patientAbstract.selectedReportLabelsInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
        initialPageVisible: state.patientAbstract.initialPageVisible,

        selectedHospital: state.patientAbstract.selectedHospital,
        currentShowingState: state.patientAbstract.currentShowingState,
        currentShowingHospitalVersion: state.patientAbstract.currentShowingHospitalVersion,

        //link to alpha pages
        alphaSystemUrlBaseUrl: state.patientAbstract.alphaSystemUrlBaseUrl,
        alphaToken: state.patientAbstract.tokenOnSession,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearAddNewUserFlag: () => dispatch(actionCreators.clearAddNewUserFlag()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        selectReportLabels: (label) => dispatch(actionCreators.selectReportLabels(label)),
        deleteReportLabels: (dialogForm) => dispatch(actionCreators.deleteReportLabels(dialogForm)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),

        addNewItem: (number) => dispatch(actionCreators.selectHospitalRecord(number)),
        closeAddNewDialog: () => dispatch(actionCreators.closeAddNewDialog()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
        runStateExportForm: (dialogForm) => dispatch(actionCreators.runStateExportForm(dialogForm)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StateExport);