/* eslint-disable */
import * as React from "react";

export const WqCommandCell = (props) => {
    const {
        dataItem
    } = props;

    //
    const selectedReview = (data, e) => {
        var updFnc = document.getElementById("updateSelectedReviewHandler");
        if (updFnc) {
            updFnc.setAttribute("data-wqid", dataItem.id);
            updFnc.setAttribute("data-selected", e.target.checked ? "true" : "false");
            updFnc.setAttribute("data-rowIdx", e.target.parentNode.parentNode.getAttribute("data-grid-row-index"));
            updFnc.click();
        }

        if (!dataItem.selected) {
            e.target.setAttribute("checked", "true");
        } else {
            e.target.removeAttribute("checked");
        }

        document.getElementById("updateGridHiddenBtn").click();
        return false;
    }

    //
    const updateStatus = (data, e) => {
        var updFnc = document.getElementById("updateAssessmentStatusHandler");
        if (updFnc) {
            updFnc.setAttribute("data-wqid", dataItem.id);
            updFnc.setAttribute("data-status", e.target.value);
            updFnc.setAttribute("data-rowIdx", e.target.parentNode.parentNode.parentNode.parentNode.getAttribute("data-grid-row-index"));
            updFnc.click();
        }

        if (e.target.parentNode.parentNode.parentNode.parentNode.querySelector("td:last-child input")) {
            e.target.parentNode.parentNode.parentNode.parentNode.querySelector("td:last-child input").removeAttribute("disabled");
        }
    }

    //
    const viewDetails = (data, e) => {
        document.getElementById('inquiryModeNotification') ? document.getElementById('inquiryModeNotification').setAttribute("class", "hidden") : null;

        //props.viewDetails(data);
        e.target.parentNode.parentNode.setAttribute("data-pid", data.pid);
        e.target.parentNode.parentNode.setAttribute("data-cid", data.cid);
        e.target.parentNode.parentNode.click();
        e.target.parentNode.parentNode.setAttribute("data-pid", "0");
        e.target.parentNode.parentNode.setAttribute("data-cid", "0");
    }

    const workItemId = dataItem.id;
    //const userAssessmentStatus = dataItem.userAssessmentStatus;

    if (props.cellType == "button") {
        return <td className="k-command-cell">
            <button className="k-button validationErr_btnOk_Cls" onClick={(e) => { viewDetails(dataItem, e) }}>
                {/*
                <span className="k-icon k-i-grid-layout" title="View Patient Details" onClick={(e) => { viewDetails(dataItem, e) }}></span>
                */}
                View<br />Patient<br />Details
            </button>
        </td>;
    } else if (props.cellType == "checkbox") {
        if (props.selected) {
            return <td className="k-command-cell">
                <input type="checkbox" name={"reviewed_" + workItemId} id={"reviewed_" + workItemId} checked="true"
                    onClick={(e) => { selectedReview(dataItem, e) }} onChange={(e) => { selectedReview(dataItem, e) }}
                />
            </td>;
        } else {
            return <td className="k-command-cell">
                <input type="checkbox" name={"reviewed_" + workItemId} id={"reviewed_" + workItemId}
                    onClick={(e) => { selectedReview(dataItem, e) }} onChange={(e) => { selectedReview(dataItem, e) }}
                />
            </td>;
        }
    } else {
        return <td className="k-command-cell" style={{ _display: "contents" }} dataItem={dataItem.userAssessmentStatus}>
            <div className="row">
                <label>
                    <input type="radio" onClick={(e) => { updateStatus(dataItem, e) }} onChange={(e) => { updateStatus(dataItem, e) }}
                        checked={dataItem.userAssessmentStatus === "reportable"} id={"nlp_userAssessmentStatus_reportable" + workItemId}
                        name={"userAssessmentStatus" + workItemId} value="reportable" style={{ marginRight: "5px" }} />Reportable</label>
            </div>
            <div className="row">
                <label>
                    <input type="radio" onClick={(e) => { updateStatus(dataItem, e) }} onChange={(e) => { updateStatus(dataItem, e) }}
                        checked={dataItem.userAssessmentStatus === "non_reportable"} id={"nlp_userAssessmentStatus_non_reportable" + workItemId}
                        name={"userAssessmentStatus" + workItemId} value="non_reportable" style={{ marginRight: "5px" }} />Non Reportable</label>
            </div>
                <div className="row">
                    <label>
                    <input type="radio" onClick={(e) => { updateStatus(dataItem, e) }} onChange={(e) => { updateStatus(dataItem, e) }}
                        checked={dataItem.userAssessmentStatus === "reportable_with_deletion"} id={"nlp_userAssessmentStatus_reportable_with_deletion" + workItemId}
                        name={"userAssessmentStatus" + workItemId} value="reportable_with_deletion" style={{ marginRight: "5px" }} />Reportable with Deletion</label>
                </div>
        </td>;
    }
};

//More refer at https://www.telerik.com/kendo-react-ui/components/grid/editing/editing-inline/