/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import Draggable from 'react-draggable';
import "./QualityMeasures.css";
import * as dialogTypes from "../../AbstractManagement/maintDialogs";

class QualityMeasures extends Component {
    clockInterval = "";
    downloadFile = false;
    state = {
        beginDate: "", //01/01/2022
        endDate: "", //08/12/2022
        hospNbr: "",
        hospital_Code: "",     //This line does not work as the default
        hospital_Code_Name: "",  //This line works as the default //"ERS Test 2 Hosp"
        outputType: "Screen",
        excludeSharedCases: false,
        includePatientList: false,

        coc: "",
        napbc: "",
        naprc: "",

        breastBCSRT: false,
        breastMAC: false,
        breastHT: false,
        breastMASTRT: false,
        breastBCSdx: false,
        breastnBx: false,
        colonACT: false,
        colon12RLN: false,
        gastricG15RLN: false,
        gastricGCTRT: false,
        headandneckHadjRT: false,
        lungLCT: false,
        lungLNoSurg: false,
        melanomaMadjRx: false,
        rectumRECRTCT: false,
        rectumRCRM: false,

        htmlOutput: null,
        startTime: null,
        endTime: null,
        statusBar: "Start Time: ",

        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        loaded: true,
        titleDialog: null,
        showClockTimer: false,
        reportStatus: "Not Running", //This variable is used to control whether clock timers continue, reports display/download etc. -- "Not Running" == initial state | "Cancelled" == user has clicked the cancel button after attempting to run a report | "Report Running" == Report in the C# file has been called, but no response back recognized from it | "Report Finished" == Report in the C# function has successfully completed

    }


    Quality_Measures_Date_Format(myDate) {
        //This code tries to fill in slashes and the two digit century when a user doesn't enter them
        var fmtDate = "";

        myDate = myDate.replace(/[-]/gi, '/');

        if ((myDate.length == 6 || myDate.length == 7 || myDate.length == 8 || myDate.length == 9) && myDate.split('/').length == 3) {
            var mm = myDate.split('/')[0];
            var dd = myDate.split('/')[1];
            var yy = myDate.split('/')[2];

            myDate = ('00' + mm).substr(mm.length, 2) + '/' + ('00' + dd).substr(dd.length, 2) + '/' + yy;
        }

        myDate = myDate.replace(/[^0-9-/]/gi, '');

        if (myDate.length == 8 && myDate.indexOf('/') == -1) {
            fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/" + myDate.substr(4, 4);
        } else if (myDate.length == 8 && myDate.indexOf('/') >= 1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(6, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 6) + "19" + myDate.substr(6, 2);
            } else {
                fmtDate = myDate.substr(0, 6) + "20" + myDate.substr(6, 2);
            }
        } else if (myDate.length == 6 && myDate.indexOf('/') == -1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(4, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/19" + myDate.substr(4, 2);
            } else {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/20" + myDate.substr(4, 2);
            }
        } else {
            fmtDate = myDate;
        }

        return fmtDate;

    }


    setStateBeginDate = (event) => {
        var value = event.target.value;
        const oldValue = this.state.beginDate;

        this.setState({
            beginDate: value,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateEndDate = (event) => {
        const value = event.target.value;
        const oldValue = this.state.endDate;

        this.setState({
            endDate: value,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateOutputType = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.outputType;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;

        this.setState({
            outputType: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    componentDidMount() {
        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);

        // Add all tabs in maintDialogs.js
        dialogTypes.accreditationReportsTabsDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog.name, dialog.id);
            this.props.setEnabledStatus(dialog.name);
            this.props.setControlName(dialog.id);
        });

        var _this = this;
        //await _this.props.generateRequestLog();  //The report doesn't run without this code, but why?  What does this function do?
        _this.props.generateQualityMeasures();
        console.log("$$$ - Returned Values from Screen loading/generating: " + this.props.qualityMeasuresInfo);

        if (!this.state.dialogForm) { // && !this.props.requestLogInfo
            this.setFormData(this.props.qualityMeasuresInfo);
        }

        this.setState({ isLoading: true });
    }

    componentWillMount() {

    }

 
    componentDidUpdate(prevProps, prevState) {
        if (!this.state.dialogForm) {
            this.setFormData(this.props.qualityMeasuresInfo);
        }

        //Trying to get hospital control to work//
        if (this.props.currentSelectedField != null && this.props.commonLookupItem && this.state.dialogForm && this.state.uAllItemFields) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "" },
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    //uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        var qualityMeasuresInfo = this.props.qualityMeasuresInfo;
        if (this.state.dialogForm && (prevProps.qualityMeasuresInfo && this.props.qualityMeasuresInfo && (prevProps.qualityMeasuresInfo.startTime !== this.props.qualityMeasuresInfo.startTime
            || prevProps.qualityMeasuresInfo.endTime !== this.props.qualityMeasuresInfo.endTime
        ))) {
            console.log("Display Report");
        //if (this.state.dialogForm && (prevProps.productivityInfo && this.props.productivityInfo)) {
        //    if (prevStartTime !== currentStartTime || prevEndTime !== currentEndTime) {
                clearInterval(this.clockInterval); //If the report has finished, clear the clock interval
                this.setState({
                    showClockTimer: false, //If the report has finished, hide the clock
                    endTime: new Date().toLocaleTimeString(),
                    reportStatus: "Report Finished",
                });

            if (qualityMeasuresInfo.output_Type == "Screen" && qualityMeasuresInfo.htmlOutput) //If the report has finished, it was sent to the screen, and we have html data returned, display the report to the screen
                this.displayReport(qualityMeasuresInfo);
            else if (qualityMeasuresInfo.output_Type == "Excel" && qualityMeasuresInfo.urlOutputFile && !this.downloadFile) { //If the report has finished, it was sent to Excel, and we have a url/path to the Excel file returned by the report, download the file
                var fileURL = this.props.clientgroup + "/DownloadFile";
                this.downloadxlsx(fileURL, qualityMeasuresInfo.urlOutputFile); //Download the Excel file produced by the report
                this.downloadFile = true;
            }
        }
       // }
    }

    downloadxlsx(fileURL, urlOutputFile) {
        axios.post(fileURL, {
            method: 'POST',
            responseType: 'blob',//important
            urlOutputFile: urlOutputFile,
            accessToken: localStorage.getItem("token")
        }).then((response) => {
            var fileName = urlOutputFile.substring(urlOutputFile.lastIndexOf('\\') + 1);
            const link = document.createElement('a');

            let excel;
            if (urlOutputFile.indexOf('/') != -1) {
                excel = urlOutputFile.substring(urlOutputFile.lastIndexOf('/') + 1);
            } else {
                excel = urlOutputFile.substring(urlOutputFile.lastIndexOf('\\') + 1);
            }
            link.href = this.props.clientgroup + "/" + excel;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return false;
        })
        .catch(function (error) {
            console.log(error);
        });

        return false;
    }

    setFormData = (qualityMeasuresInfo) => {
        this.setState({
            initialDialogForm: { ...qualityMeasuresInfo },
            dialogForm: { ...qualityMeasuresInfo },

            //["outputType"]: requestLogInfo && requestLogInfo.sendReportTo ? requestLogInfo.sendReportTo : "Screen",
            reload: false,
            changed: true,
            showClockTimer: false,
            titleDialog: null,
            isDrawingCharts: false,
            reportStatus: null,
        });
        console.log("Report Status: ", this.state.reportStatus);

        this.setState({ reportStatus: "Not Currently Running", });
        return false;
    }

    handleOnFocus = (event, index, alias) => {

    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true,
            });
        } else {
            this.setState({
                //dialogForm: {
                //    ...this.state.dialogForm,
                //    [event.target.name]: event.target.value
                //},
                [event.target.name]: event.target.value, //11/07/2022 - Switching to this per REA to resolve the issue of typing in the hospital code directly
                isFormDirty: true,
            });
        }
    }

    handleLostFocus = (event) => {
        const name = event.target.name;

        if (event.target.name == "beginDate") {
            this.setState({
                beginDate: this.Quality_Measures_Date_Format(event.target.value),
            });
        }
        if (event.target.name == "endDate") {
            this.setState({
                endDate: this.Quality_Measures_Date_Format(event.target.value),
            });
        }

        if (event.target.name == "hospital_Code") {
            var dialogFormData = (this.state.dialogForm ? this.state.dialogForm : "Cannot Load this.state.dialogForm")

            dialogFormData.hospital_Code = event.target.value;
            this.setState({
                hospNbr: event.target.value,
                hospital_Code: event.target.value,
                isFormDirty: this.state.hospNbr != event.target.value ? true : this.state.isFormDirty,
                dialogForm: dialogFormData,
            });

            //this.props.checkValidInput(name, value, table, key, -1, flag);

        }
    }


    toggleChecked = (event) => {
        const name = event.target.name;
        var dialogFormData = this.state.dialogForm;

        if (name == "patientList") {
            const checked = this.state.includePatientList;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                includePatientList: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "excludeSharedCases") {
            const checked = this.state.excludeSharedCases;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                excludeSharedCases: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "coc") {
            const checked = this.state.coc;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                coc: !checked,
                breastBCSRT: !checked,
                breastMAC: !checked,
                colonACT: !checked,
                colon12RLN: !checked,
                gastricG15RLN: !checked,
                headandneckHadjRT: !checked,
                lungLCT: !checked,
                melanomaMadjRx: !checked,
                rectumRECRTCT: !checked,
                rectumRCRM: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "napbc") {
            const checked = this.state.napbc;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                napbc: !checked,
                breastBCSRT: !checked,
                breastMAC: !checked,
                breastHT: !checked,
                breastMASTRT: !checked,
                breastBCSdx: !checked,
                breastnBx: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "naprc") {
            const checked = this.state.naprc;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                naprc: !checked,
                rectumRECRTCT: !checked,
                rectumRCRM: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "breastBCSRT") {
            const checked = this.state.breastBCSRT;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                breastBCSRT: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "breastMAC") {
            const checked = this.state.breastMAC;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                breastMAC: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "breastHT") {
            const checked = this.state.breastHT;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                breastHT: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "breastMASTRT") {
            const checked = this.state.breastMASTRT;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                breastMASTRT: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "breastBCSdx") {
            const checked = this.state.breastBCSdx;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                breastBCSdx: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "breastnBx") {
            const checked = this.state.breastnBx;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                breastnBx: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "colonACT") {
            const checked = this.state.colonACT;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                colonACT: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "colon12RLN") {
            const checked = this.state.colon12RLN;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                colon12RLN: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "gastricG15RLN") {
            const checked = this.state.gastricG15RLN;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                gastricG15RLN: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "gastricGCTRT") {
            const checked = this.state.gastricGCTRT;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                gastricGCTRT: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "headandneckHadjRT") {
            const checked = this.state.headandneckHadjRT;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                headandneckHadjRT: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "lungLCT") {
            const checked = this.state.lungLCT;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                lungLCT: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "lungLNoSurg") {
            const checked = this.state.lungLNoSurg;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                lungLNoSurg: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "melanomaMadjRx") {
            const checked = this.state.melanomaMadjRx;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                melanomaMadjRx: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "rectumRECRTCT") {
            const checked = this.state.rectumRECRTCT;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                rectumRECRTCT: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
             
        } else if (name == "rectumRCRM") {
            const checked = this.state.rectumRCRM;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                rectumRCRM: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        }


    }


    Run_Quality_Measures = () => {
        //Collate all values needed by the report into one object, dialogFormData, and pass it to the report.
        var dialogFormData = this.state.dialogForm;

        dialogFormData.reportStatus = "Report In Progress";
        dialogFormData.Begin_Date = this.state.beginDate; //Putting this code here, since the onChange events won't set this for default values
        dialogFormData.End_Date = this.state.endDate;
        dialogFormData.Hospital_Code = this.state.hospNbr;
        dialogFormData.Breast_BCSRT = this.state.breastBCSRT;
        dialogFormData.Breast_MAC = this.state.breastMAC;
        dialogFormData.Breast_HT = this.state.breastHT;
        dialogFormData.Breast_MASTRT = this.state.breastMASTRT;
        dialogFormData.Breast_BCSdx = this.state.breastBCSdx;
        dialogFormData.Breast_nBx = this.state.breastnBx;
        dialogFormData.Colon_ACT = this.state.colonACT;
        dialogFormData.Colon_12RLN = this.state.colon12RLN;
        dialogFormData.Gastric_G15RLN = this.state.gastricG15RLN;
        dialogFormData.Gastric_GCTRT = this.state.gastricGCTRT;
        dialogFormData.Head_and_Neck_HadjRT = this.state.headandneckHadjRT;
        dialogFormData.Lung_LCT = this.state.lungLCT;
        dialogFormData.Lung_LNoSurg = this.state.lungLNoSurg;
        dialogFormData.Melanoma_MadjRx = this.state.melanomaMadjRx;
        dialogFormData.Rectum_RECRTCT = this.state.rectumRECRTCT;
        dialogFormData.Rectum_RCRM = this.state.rectumRCRM;

        dialogFormData.Output_Type = this.state.outputType;
        dialogFormData.Exclude_Shared_Cases = this.state.excludeSharedCases;
        dialogFormData.Include_Patient_List = this.state.includePatientList;

        dialogFormData.reportStatus = this.state.reportStatus;
        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;
        

        //alert("Running Request Log with 'This dialog' values of: " + JSON.stringify(this.state.dialogForm, null, 4))
        console.log("Running Quality Measures with 'This' values of: " + JSON.stringify(this.state.dialogForm, null, 4));

        this.props.runQualityMeasures(dialogFormData);
        
        this.downloadFile = false;//reset this flag to get download new file

        return false;
    }

    //on before show event
    showClockEvent(e) {
        try {
            e.preventDefault();// This function prevents the form from being auto-submitted
        } catch (ex) {

        }

        var _this = this;
        _this.setState({ showClockTimer: true, startTime: new Date().toLocaleTimeString(), reportStatus: "Report Running" }); // set clock timer...

        this.Run_Quality_Measures();
        //alert("Clock causing reload!");

        this.clockInterval = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(this.clockInterval);
            } else {
                console.log("Timer Interval Running");
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
                _this.setState({ currentTime: new Date() ? new Date().toLocaleTimeString() : " " });
            }
        }, 1000);

        return false; // Returns false to stop everything after this event has been completed

    }

    Cancel_Report = (e, _this) => {
        _this.setState({ showClockTimer: false });
        clearInterval(this.clockInterval);
        return false;
    }


    displayReport = (data) => {
        this.setState({
            showClockTimer: false,//hide clock timer
            endTime: new Date().toLocaleTimeString(),
            htmlOutput: data.htmlOutput, //Commenting out because I think this already gets set in the data tunnel, but will have to test
            isDrawingCharts: true
        });

        var reportDisplay = document.getElementById("displayReportIframe");
        if (reportDisplay) { //This function runs twice and in one instance this component doesn't exist, so this check needs to be there to prevent the entire screen from erroring out
            var reportContent = reportDisplay.contentDocument;
            reportContent.write(data.htmlOutput);
        }

        return false;
    }


    hideDrawingCharts = (e) => {
        //try {
        //    e.preventDefault();  //What "default" action is being prevented by having this code here?
        //} catch (ex) { }
        this.setState({ isDrawingCharts: false });
        return false;
    }


    newRecord(e) {
        try {
            this.setState({
                beginDate: "",
                endDate: "",
                hospNbr: "",   //Not necessary here?
                hospital_Code: "", //This clears visual value on the screen
                hospital_Code_Name: "", //This clears the visual name on the screen
                outputType: "Screen",
                excludeSharedCases: false,
                includePatientList: false,

                coc: "",
                napbc: "",
                naprc: "",

                breastBCSRT: false,
                breastMAC: false,
                breastHT: false,
                breastMASTRT: false,
                breastBCSdx: false,
                breastnBx: false,
                colonACT: false,
                colon12RLN: false,
                gastricG15RLN: false,
                gastricGCTRT: false,
                headandneckHadjRT: false,
                lungLCT: false,
                lungLNoSurg: false,
                melanomaMadjRx: false,
                rectumRECRTCT: false,
                rectumRCRM: false,

                htmlOutput: null,
                startTime: null,
                endTime: null,
                statusBar: "",

                isFormDirty: false,
            })
        } catch (ex) {}
    }


    printReport = (e) => {
        var doc = document.getElementById("displayReportIframe").contentDocument;

        var printStyle = doc.getElementById('PrintStyle');
        var printContent = doc.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Quality Measures</title><style type="text/css">';
        html += printStyle.innerHTML + '</style></head><body><div id="printableContent">';
        html += printContent.innerHTML + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }

    render() {
        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window" style={{ cursor: "progress" }}>
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait <div class="waitingWaves"><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div></div></div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTime}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let drawingCharts;
        {
            drawingCharts = this.state.isDrawingCharts ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="qualityMeasuresReportDisplay" className="dragableLargestWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Quality Measures </div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingCharts(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="container" id="qualityMeasuresShowingCharts">
                                <iframe id="displayReportIframe" />
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12" style={{ display: "flex" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ErsGrBlueButton" title=""
                                            onClick={(e) => { this.printReport(e) }}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print
                                        </button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ErsGrBlueButton" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={(e) => { this.hideDrawingCharts(e) }} value="exit" autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let dialogForm = <div className="reportDialogForm">
            <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                <div className="reportDialogFormContent">
                    <div className="reportScreenTitle">Quality Measures </div>
                    {this.state.dialogForm || this.state.loaded ?

                    <form onSubmit={false} id="qualityMeasuresForm" className="">
                        <div className="frameInner">

                            
                                {/*<div className="col-sm-5" style={{ display: "flex" }}>*/}
                                {/*    <Input type="codeMultiSelect" focused={this.handleOnFocus} changed={this.handleFormChange}*/}
                                {/*        labelText="Hospital Code" field="hospitalCode" lostfocused={this.handleLostFocus}*/}
                                {/*        value={this.state.dialogForm.hospitalCode}*/}
                                {/*        dataSource="Hospital2L" titleDialog="Hospital Lookup" codeLength="2"*/}
                                {/*        labelWidth="100px" width="150px"*/}
                                {/*        inValid={this.state.uAllItemFields["hospitalCode"]}*/}
                                {/*        lineStyle="oneLine" itemNbr=""*/}
                                {/*        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospitalCode"] : ""}*/}
                                {/*        flag={this.state.availableHosps ? this.state.availableHosps : ""}*/}
                                {/*        hospNbr={this.state.dialogForm.hospitalCode ? this.state.dialogForm.hospitalCode : this.state.availableHosps}*/}
                                {/*        shownType="dropdown" isMultiSelection={false}*/}
                                {/*    />*/}
                                {/*    <label type="label" id="hospitalCodeName" name="hospitalCodeName" >}{this.state.hospitalCodeName}{/*this.state.dialogForm.hospitalCodeName}</label>
                                {/*</div>*/}

                            <div className="" style={{ display: "flex" }}>
                                <Input type="codeMultiSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                    labelText="Hospital" field="hospital_Code" lostfocused={this.handleLostFocus} name="hospital_Code"
                                    value={this.state.hospital_Code}
                                    dataSource="Hospital2L" titleDialog="Hospital Lookup" codeLength="2"
                                    labelWidth="90px" width="240px"
                                    lineStyle="oneLine" itemNbr=""
                                    flag={this.state.availableHosps ? this.state.availableHosps : ""}
                                    hospNbr={this.state.hospital_Code ? this.state.hospital_Code : this.state.availableHosps}
                                    shownType="dropdown" isMultiSelection={false}
                                />
                                <label type="label" id="hospital_Code_Name" name="hospital_Code_Name" >{this.state.hospital_Code_Name}</label>
                            </div>
                            <div className="upperLeftBox">
                                <div id="beginDate">
                                    <label>Begin Date:</label>
                                    <input type="text" id="beginDate" name="beginDate"
                                        placeholder="mm/dd/yyyy"
                                        value={this.state.beginDate}
                                        onChange={(e) => this.setState({ beginDate: e.target.value })}
                                        onBlur={this.handleLostFocus}
                                        labelText="Begin Date: "
                                        field="beginDate"
                                    />
                                </div>

                                <div id="endDate">
                                    <label>End Date:</label>
                                    <input type="text" id="endDate" name="endDate"
                                        placeholder="mm/dd/yyyy"
                                        value={this.state.endDate}
                                        onChange={(e) => this.setState({ endDate: e.target.value })}
                                        onBlur={this.handleLostFocus}
                                    />
                                    </div>
<br />
                            <input type="checkbox" id="excludeSharedCases" className={this.state.hospital_Code.length > 1 ? "hidden" : "checkbox"}
                                name="excludeSharedCases"
                                value={this.state.excludeSharedCases ? "true" : "false"}
                                checked={this.state.excludeSharedCases}
                                onChange={this.toggleChecked} />
                            <label for="excludeSharedCases" className={this.state.hospital_Code.length > 1 ? "hidden" : "checkboxLabel"}> Exclude Shared Cases </label>

                            <br />

                            
                                <input type="checkbox" id="patientList" className="checkbox"
                                    name="patientList"
                                    value={this.state.includePatientList ? "true" : "false"}
                                    checked={this.state.includePatientList}
                                    onChange={this.toggleChecked} />
                                <label for="patientList" className="checkboxLabel"> Include Patient List </label>

                                <div className="outputDeviceRadio">
                                    <div id="outputDeviceLabel">Send Report To:</div>
                                    <label>
                                        <input type="radio" name="outputType" field="outputType" value="Excel"
                                            onClick={this.setStateOutputType}
                                            checked={this.state.outputType === "Excel"} />
                                        Excel
                                    </label>
                                    <br />
                                    <label>
                                        <input type="radio" name="outputType" field="outputType" value="Screen"
                                            onClick={this.setStateOutputType}
                                            checked={this.state.outputType === "Screen"} />
                                        Screen
                                    </label>
                                </div>

                            </div>



                            <div className="upperRightBox">

                                <input type="checkbox" id="coc" className="checkbox"
                                    name="coc"
                                    value={this.state.coc ? "true" : "false"}
                                    checked={this.state.coc}
                                    onChange={this.toggleChecked} />
                                <label for="coc" className="checkboxLabel"> CoC </label>
                                
                                <input type="checkbox" id="napbc" className="checkbox"
                                    name="napbc"
                                    value={this.state.napbc ? "true" : "false"}
                                    checked={this.state.napbc}
                                    onChange={this.toggleChecked} />
                                <label for="napbc" className="checkboxLabel"> NAPBC </label>
                                
                                <input type="checkbox" id="naprc" className="checkbox"
                                    name="naprc"
                                    value={this.state.naprc ? "true" : "false"}
                                    checked={this.state.naprc}
                                    onChange={this.toggleChecked} />
                                <label for="naprc" className="checkboxLabel"> NAPRC </label>

                                <br /><br />

                                <input type="checkbox" id="breastBCSRT" className="checkbox"
                                    name="breastBCSRT"
                                    value={this.state.breastBCSRT ? "true" : "false"}
                                    checked={this.state.breastBCSRT}
                                    onChange={this.toggleChecked} />
                                <label for="breastBCSRT" className="checkboxLabel"> Breast - Radiation with Conservation Surgery (BCSRT)</label>
                                <br />
                                <input type="checkbox" id="breastMAC" className="checkbox"
                                    name="breastMAC"
                                    value={this.state.breastMAC ? "true" : "false"}
                                    checked={this.state.breastMAC}
                                    onChange={this.toggleChecked} />
                                <label for="breastMAC" className="checkboxLabel"> Breast - Chemo (MAC)</label>
                                <br />
                                <input type="checkbox" id="breastHT" className="checkbox"
                                    name="breastHT"
                                    value={this.state.breastHT ? "true" : "false"}
                                    checked={this.state.breastHT}
                                    onChange={this.toggleChecked} />
                                <label for="breastHT" className="checkboxLabel"> Breast - Hormone (HT)</label>
                                <br />
                                <input type="checkbox" id="breastMASTRT" className="checkbox"
                                    name="breastMASTRT"
                                    value={this.state.breastMASTRT ? "true" : "false"}
                                    checked={this.state.breastMASTRT}
                                    onChange={this.toggleChecked} />
                                <label for="breastMASTRT" className="checkboxLabel"> Breast - Radiation with Mastectomy (MASTRT)</label>
                                <br />
                                <input type="checkbox" id="breastBCSdx" className="checkbox"
                                    name="breastBCSdx"
                                    value={this.state.breastBCSdx ? "true" : "false"}
                                    checked={this.state.breastBCSdx}
                                    onChange={this.toggleChecked} />
                                    <label for="breastBCSdx" className="checkboxLabel"> Breast - Surgery (BCSdx)</label>
                                <br />
                                <input type="checkbox" id="breastnBx" className="checkbox"
                                    name="breastnBx"
                                    value={this.state.breastnBx ? "true" : "false"}
                                    checked={this.state.breastnBx}
                                    onChange={this.toggleChecked} />
                                <label for="breastnBx" className="checkboxLabel"> Breast - Needle Biopsy (nBx)</label>
                                <br />
                                <input type="checkbox" id="colonACT" className="checkbox"
                                    name="colonACT"
                                    value={this.state.colonACT ? "true" : "false"}
                                    checked={this.state.colonACT}
                                    onChange={this.toggleChecked} />
                                <label for="colonACT" className="checkboxLabel"> Colon - Chemo (ACT)</label>
                                <br />
                                <input type="checkbox" id="colon12RLN" className="checkbox"
                                    name="colon12RLN"
                                    value={this.state.colon12RLN ? "true" : "false"}
                                    checked={this.state.colon12RLN}
                                    onChange={this.toggleChecked} />
                                <label for="colon12RLN" className="checkboxLabel"> Colon - Lymph Nodes (C12RLN)</label>
                                <br />
                                <input type="checkbox" id="gastricG15RLN" className="checkbox"
                                    name="gastricG15RLN"
                                    value={this.state.gastricG15RLN ? "true" : "false"}
                                    checked={this.state.gastricG15RLN}
                                    onChange={this.toggleChecked} />
                                <label for="gastricG15RLN" className="checkboxLabel"> Gastric - Lymph Nodes (G16RLN)</label>
                                <br />
                                <input type="checkbox" id="gastricGCTRT" className="checkbox"
                                    name="gastricGCTRT"
                                    value={this.state.gastricGCTRT ? "true" : "false"}
                                    checked={this.state.gastricGCTRT}
                                    onChange={this.toggleChecked} />
                                <label for="gastricGCTRT" className="checkboxLabel"> Gastric - Chemo (GCTRT)</label>
                                <br />
                                <input type="checkbox" id="headandneckHadjRT" className="checkbox"
                                    name="headandneckHadjRT"
                                    value={this.state.headandneckHadjRT ? "true" : "false"}
                                    checked={this.state.headandneckHadjRT}
                                    onChange={this.toggleChecked} />
                                <label for="headandneckHadjRT" className="checkboxLabel"> Head and Neck - Radiation (HadjRT)</label>
                                <br />
                                <input type="checkbox" id="lungLCT" className="checkbox"
                                    name="lungLCT"
                                    value={this.state.lungLCT ? "true" : "false"}
                                    checked={this.state.lungLCT}
                                    onChange={this.toggleChecked} />
                                <label for="lungLCT" className="checkboxLabel"> Lung - Chemo (LCT)</label>
                                <br />
                                <input type="checkbox" id="lungLNoSurg" className="checkbox"
                                    name="lungLNoSurg"
                                    value={this.state.lungLNoSurg ? "true" : "false"}
                                    checked={this.state.lungLNoSurg}
                                    onChange={this.toggleChecked} />
                                <label for="lungLNoSurg" className="checkboxLabel"> Lung - No Surgery (LNoSurg)</label>
                                <br />
                                <input type="checkbox" id="melanomaMadjRx" className="checkbox"
                                    name="melanomaMadjRx"
                                    value={this.state.melanomaMadjRx ? "true" : "false"}
                                    checked={this.state.melanomaMadjRx}
                                    onChange={this.toggleChecked} />
                                <label for="melanomaMadjRx" className="checkboxLabel"> Melanoma - Adjuvant Therapy (MadjRx)</label>
                                <br />
                                <input type="checkbox" id="rectumRECRTCT" className="checkbox"
                                    name="rectumRECRTCT"
                                    value={this.state.rectumRECRTCT ? "true" : "false"}
                                    checked={this.state.rectumRECRTCT}
                                    onChange={this.toggleChecked} />
                                <label for="rectumRECRTCT" className="checkboxLabel"> Rectum - Radiation and Chemo (RECRTCT)</label>
                                <br />
                                <input type="checkbox" id="rectumRCRM" className="checkbox"
                                    name="rectumRCRM"
                                    value={this.state.rectumRCRM ? "true" : "false"}
                                    checked={this.state.rectumRCRM}
                                    onChange={this.toggleChecked} />
                                <label for="rectumRCRM" className="checkboxLabel"> Rectum - Resection (RCRM)</label>
                                </div>
                            


                            <div className="hidden">
                            </div>
                            <br />

                            <div id="CONTAINER_JS_CLOCK" className="hidden">
                                <label>Selecting Cases</label>
                                <div id="IMAGE_WAIT" style={{ color: "black", fontWeight: "bold" }}>
                                    Selecting Cases - Please Wait ...
                                </div>
                            </div>
                        </div>

                        <div className="reportScreenStatusBar">
                                Start Time: {this.state.startTime} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; End Time: {this.state.endTime}
                        </div>

                        <fieldset className="reportDialogFormButtonBar">
                            <button id="BUTTON_RUN"
                                className="ErsGrBlueButton"
                                onClick={(e) => { this.showClockEvent(e) }}
                                //disabled={!this.state.isFormDirty}
                            >
                                <img id="BUTTON_RUN_ICON"
                                    src="https://crstar.ers-can.com/images/$$code.action.run.png.a5image"
                                    style={{ verticalAlign: "middle", border: "none" }}></img>
                                Run
                            </button>

                            <button id="BUTTON_NEWRECORD"
                                className={this.state.isFormDirty ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                onClick={this.newRecord.bind(this)}
                                //onClick={(e) => { this.showClockEvent(e) }}
                                disabled={!this.state.isFormDirty}
                            >
                                <img id="BUTTON_NEWRECORD_ICON"
                                    src={this.state.isFormDirty ? "https://crstar.ers-can.com/images/$$navigate.new.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$navigate.new.png.a5image"}
                                    style={{ verticalAlign: "middle", border: "none" }}></img>
                                New
                            </button>

                            <button type="button" id="reportDefaultBtn" className="hidden" title=""
                                onClick={(e) => { this.defaultRadio(e) }} value="default" autoFocus>
                                Default
                            </button>
                        </fieldset>
                    </form>
                        : <p>Quality Measures report screen encountered an issue and cannot load ...</p>}
                </div>
            </div>
        </div>
 
        return (
            <React.Fragment>
                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {drawingCharts}
                </React.Fragment>

                <React.Fragment>
                    {dialogForm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        qualityMeasuresInfo: state.patientAbstract.qualityMeasuresInfo, //This holds information passed from the C# report back to the screen
        clientgroup: state.auth.clientgroup,                            //Passed to C# report to get path
        userId: state.auth.userId,                                      //Passed to C# report to get path

        initialPageVisible: state.patientAbstract.initialPageVisible,   //Controls if security restrictions are set to prevent page from loading
        isDialogLoading: state.patientAbstract.isDialogLoading,
        waitingFor: state.patientAbstract.waitingFor,

        //Trying to get hospital control to work//
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        isAddNewUser: state.patientAbstract.isAddNewUser,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
        generateQualityMeasures: (flag) => dispatch(actionCreators.generateQualityMeasures(flag)),
        runQualityMeasures: (dialogForm) => dispatch(actionCreators.runQualityMeasures(dialogForm)),       

        //Trying to get hospital control to work//
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearAddNewUserFlag: () => dispatch(actionCreators.clearAddNewUserFlag()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),
        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setControlName: (name) => dispatch(actionCreators.setControlName(name)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QualityMeasures);