/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import Draggable from 'react-draggable';
import "./SiteDistribution.css";
import * as dialogTypes from "../../AbstractManagement/maintDialogs";  // Getting navigation tabs - 10/25/2022
import PopLabel from "../PopLabel/PopulationLabel"; //Import the Editable Pop Label 11/22/2022

class SiteDistribution extends Component {
    clockInterval = "";
    downloadFile = false;
    state = {
        beginDate: "", //01/01/2022
        endDate: "", //08/12/2022

        populationLabel: "",
        displaySex: true,
        displayStage: true,
        displayAge: false,
        displayPrimaryPayer: false,
        displayRace: false,
        displayEthnicity: false,
        displayZip: false,
        displayPercentages: false,
        stageType: "AJCC",
        zipCodes: "",
        includeExcludeZip: "Include",
        includeExcludeFlag: "Include",
        outputType: "Screen",

        htmlOutput: null,
        startTime: null,
        endTime: null,
        statusBar: "Start Time: ",

        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        loaded: true,
        titleDialog: null,
        showClockTimer: false,
        reportStatus: "Not Running", //This variable is used to control whether clock timers continue, reports display/download etc. -- "Not Running" == initial state | "Cancelled" == user has clicked the cancel button after attempting to run a report | "Report Running" == Report in the C# file has been called, but no response back recognized from it | "Report Finished" == Report in the C# function has successfully completed
        uAllItemFields: [],

    }


    updatePopLabelBackToParent = (plabel) => {
        var form = this.state.dialogForm;
        form.populationLabel = plabel;
        this.setState({
            dialogForm: form,
        });
        return false;
    }

    updateToParent = (plForm) => {
        var reportingForm = this.state.dialogForm;
        if (plForm) {
            Object.keys(plForm).map((t, k) => {
                !reportingForm[t] ? reportingForm[t] = plForm[t] : null;
            });

            let labelsSiteMvSap = plForm.labelsSiteMvSap;
            let labelsMv = plForm.labelsMv;
            let textareaEditSelect = plForm.textareaEditSelect;

            reportingForm.labelsSiteMvSap = labelsSiteMvSap;
            reportingForm.labelsMv = labelsMv;
            reportingForm.textareaEditSelect = textareaEditSelect;
        }

        this.setState({
            dialogForm: reportingForm,
        });
        return false;
    }


    setStateBeginDate = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.beginDate;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;

        this.setState({
            beginDate: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateEndDate = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.endDate;
        //var dialogFormData = this.state.dialogForm;
        //dialogFormData[name] = value;

        this.setState({
            endDate: value,
            //dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateStageType = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            stageType: event.target.value,
            dialogForm: dialogFormData,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: this.state.stageType != event.target.value ? true : this.state.isFormDirty,
        });

    }

    setStateIncludeExcludeZip = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.includeExcludeZip;
        var dialogFormData = this.state.dialogForm;
        console.log("Include Exclude Zip: " + name);
        dialogFormData[name] = value;

        this.setState({
            includeExcludeZip: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateIncludeExcludeFlag = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.includeExcludeFlag;
        var dialogFormData = this.state.dialogForm;
        console.log("Include Exclude Flag: " + name);
        dialogFormData[name] = value;

        this.setState({
            includeExcludeFlag: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }


    setStateOutputType = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.outputType;
        var dialogFormData = this.state.dialogForm;
        console.log("Output Type: " + name);
        dialogFormData[name] = value;

        this.setState({
            outputType: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }


    componentDidMount() {
        //this.props.setPatientMenu(true);
        //this.props.setPageLoaded(false);

        // Add all navigation tabs in maintDialogs.js - 10/25/2022
        dialogTypes.cannedReportsTabsDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog);
            this.props.setEnabledStatus(dialog);
        });

        var _this = this;
        //await _this.props.generateSiteDistribution();  //The report doesn't run without this code, but why?  What does this function actually do?
        _this.props.generateSiteDistribution();

        if (!this.state.dialogForm) { // && !this.props.siteDistributionInfo
            this.setFormData(this.props.siteDistributionInfo);
        }
        this.props.selectAPopLabel("");
    }

    componentWillMount() {

    }
 
    componentDidUpdate(prevProps, prevState) {
        if (!this.state.dialogForm) {
            this.setFormData(this.props.siteDistributionInfo);
        }

        ////<< Population Label Lookup >>
        //if (this.props.currentSelectedField != null && this.props.commonLookupItem && this.state.dialogForm && this.state.uAllItemFields) {
        //    if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
        //        this.setState({
        //            uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "" },
        //            dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
        //        });
        //    } else {
        //        this.setState({
        //            //uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
        //            dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
        //        });
        //    }
        //    this.props.clearCommonLookupItem();
        //}

        var siteDistributionInfo = this.props.siteDistributionInfo;
        const prevNotExists = !prevProps.siteDistributionInfo || !prevProps.siteDistributionInfo.reportStatus || prevProps.siteDistributionInfo.reportStatus != "Report Finished";
        const exists = siteDistributionInfo && siteDistributionInfo.reportStatus == "Report Finished";
        //const exists = (siteDistributionInfo && siteDistributionInfo.reportStatus == "Report Finished") || this.state.reportStatus == "Report Finished";
        //console.log('### prevNotExists', prevNotExists);
        //console.log('### exists', exists);
        if (this.state.dialogForm && (prevProps.siteDistributionInfo && this.props.siteDistributionInfo && (prevProps.siteDistributionInfo.startTime !== this.props.siteDistributionInfo.startTime
            || prevProps.siteDistributionInfo.endTime !== this.props.siteDistributionInfo.endTime
        ))){
        /*if (prevNotExists && exists) {*/
            console.log("### DID EXECUTE");
            clearInterval(this.clockInterval); //If the report has finished, clear the clock interval
            this.setState({
                showClockTimer: false, //If the report has finished, hide the clock
                endTime: new Date().toLocaleTimeString(),
                reportStatus: "Report Finished",
            });
            console.log(siteDistributionInfo.outputType + "### Output: " + siteDistributionInfo.urlOutputFile);
            if (siteDistributionInfo.outputType == "Screen" && siteDistributionInfo.htmlOutput) //If the report has finished, it was sent to the screen, and we have html data returned, display the report to the screen
                this.displayReport(siteDistributionInfo);
            else if (siteDistributionInfo.outputType == "Excel" && siteDistributionInfo.urlOutputFile) { //If the report has finished, it was sent to Excel, and we have a url/path to the Excel file returned by the report, download the file
                var fileURL = this.props.clientgroup + "/DownloadFile";
                this.downloadxlsx(fileURL, siteDistributionInfo.urlOutputFile); //Download the Excel file produced by the report
            }
        }
    }

    downloadxlsx(fileURL, urlOutputFile) {
        axios.post(fileURL, {
            method: 'POST',
            responseType: 'blob',//important
            urlOutputFile: urlOutputFile,
            accessToken: localStorage.getItem("token")
        }).then((response) => {
            var fileName = urlOutputFile.substring(urlOutputFile.lastIndexOf('\\') + 1);
            const link = document.createElement('a');

            let excel;
            if (urlOutputFile.indexOf('/') != -1) {
                excel = urlOutputFile.substring(urlOutputFile.lastIndexOf('/') + 1);
            } else {
                excel = urlOutputFile.substring(urlOutputFile.lastIndexOf('\\') + 1);
            }
            link.href = this.props.clientgroup + "/" + excel;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return false;
        })
        .catch(function (error) {
            console.log(error);
        });

        return false;
    }

    setFormData = (siteDistributionInfo) => {
        this.setState({
            initialDialogForm: { ...siteDistributionInfo },
            dialogForm: { ...siteDistributionInfo },

            //["stageType"]: siteDistributionInfo.stageType ? siteDistributionInfo.stageType : "General",
            //["includeExcludeZip"]: siteDistributionInfo.includeExcludeZip ? siteDistributionInfo.includeExcludeZip : "Include",
            //["includeExcludeFlag"]: siteDistributionInfo.includeExcludeFlag ? siteDistributionInfo.includeExcludeFlag : "Include",
            //["outputType"]: siteDistributionInfo && siteDistributionInfo.sendReportTo ? siteDistributionInfo.sendReportTo : "Screen",
            reload: false,
            changed: true,
            showClockTimer: false,
            titleDialog: null,
            isDrawingCharts: false,
        });
        console.log("Report Status: ", this.state.reportStatus);

        this.setState({ reportStatus: "Not Running", });
        return false;
    }

    handleOnFocus = (event, index, alias) => {

    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true,
            });
        } else {
            this.setState({
                [event.target.name]: event.target.value, //Resolve the issue of typing in the control directly
                isFormDirty: true,
            });
        }
    }

    handleLostFocus = (event) => {
        var dialogFormData = (this.state.dialogForm ? this.state.dialogForm : "Cannot Load this.state.dialogForm")
        let name = event.target.name;

        if (name == "populationLabel") {
            dialogFormData.populationLabel = event.target.value;
            this.setState({
                populationLabel: event.target.value,
/*                isFormDirty: this.state.populationLabel != event.target.value ? true : this.state.isFormDirty,*/
            });
        }

        this.setState({
            [event.target.name]: event.target.value,
            dialogForm: dialogFormData,
        });
    }


    toggleChecked = (event) => {
        const name = event.target.name;
        var dialogFormData = this.state.dialogForm;
        //alert("Display " + name + " Checkbox Values: " + event.target.value);

        if (name == "displayAge") {
            const checked = this.state.displayAge;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                displayAge: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "displayEthnicity") {
            const checked = this.state.displayEthnicity;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                displayEthnicity: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "displayPrimaryPayer") {
            const checked = this.state.displayPrimaryPayer;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                displayPrimaryPayer: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "displayRace") {
            const checked = this.state.displayRace;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                displayRace: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "displaySex") {
            const checked = this.state.displaySex;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                displaySex: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "displayStage") {
            const checked = this.state.displayStage;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                displayStage: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "displayZip") {
            const checked = this.state.displayZip;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                displayZip: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "displayPercentages") {
            const checked = this.state.displayPercentages;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                displayPercentages: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        }
    }



    Run_Site_Distribution = () => {
        //Collate all values needed by the report into one object, dialogFormData, and pass it to the report.
        var dialogFormData = this.state.dialogForm;

        dialogFormData.reportStatus = "Report Running";

        dialogFormData.population_Label = document.getElementById("populationLabel").value; //this.state.populationLabel;
        dialogFormData.populationLabel = document.getElementById("populationLabel").value;
        dialogFormData.stage_Type = this.state.stageType;
        dialogFormData.display_Age_Dx = this.state.displayAge;
        dialogFormData.display_Ethnicity = this.state.displayEthnicity;
        dialogFormData.display_Primary_Payer = this.state.displayPrimaryPayer;
        dialogFormData.display_Race = this.state.displayRace;
        dialogFormData.display_Sex = this.state.displaySex;
        dialogFormData.display_Stage = this.state.displayStage;
        dialogFormData.display_Zip = this.state.displayZip;
        dialogFormData.zip_Codes = this.state.zipCodes;
        dialogFormData.display_Percentages = this.state.displayPercentages;
        dialogFormData.includeExcludeZip =  this.state.includeExcludeZip;
        dialogFormData.includeExcludeFlag = this.state.includeExcludeFlag;
        dialogFormData.outputType = this.state.outputType;

        dialogFormData.reportStatus = this.state.reportStatus;
        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;
        

        //alert("Running Request Log with 'This dialog' values of: " + JSON.stringify(this.state.dialogForm, null, 4))
        console.log("Running Site Distribution with 'This' values of: " + JSON.stringify(this.state.dialogForm, null, 4));
        console.log("Running Site Distribution with 'dialogFormData' values of: " + JSON.stringify(dialogFormData, null, 4));

        //
        var createPopulationLabel = document.getElementById("createPopulationLabel") ? document.getElementById("createPopulationLabel").value : "0";
        dialogFormData.createPopulationLabel = createPopulationLabel;

        try {
            this.props.runSiteDistribution(dialogFormData);
        } catch (ex) {
            console.log(ex.stringify);
        }
        
        
        this.downloadFile = false;//reset this flag to get download new file

        return false;
    }

    //on before show event
    showClockEvent(e) {
        try {
            e.preventDefault();// This function prevents the form from being auto-submitted
        } catch (ex) {

        }

        //Code for the Editable Pop Label //
        var popLabelComponent = document.getElementById("btnCallToParentFunc");
        if (popLabelComponent) {
            popLabelComponent.click();
        }

        var _this = this;
        _this.setState({ showClockTimer: true, startTime: new Date().toLocaleTimeString() }); // set clock timer...

        this.Run_Site_Distribution();
        //alert("Clock causing reload!");

        this.clockInterval = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(this.clockInterval);
            } else {
                console.log("Timer Interval Running");
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
                _this.setState({ currentTime: new Date() ? new Date().toLocaleTimeString() : " " });
            }
        }, 1000);

        return false; // Returns false to stop everything after this event has been completed

    }

    Cancel_Report = (e, _this) => {
        _this.setState({ showClockTimer: false });
        clearInterval(this.clockInterval);
        return false;
    }


    displayReport = (data) => {
        console.log("Display: " + this.isDrawingCharts);
        this.setState({
            showClockTimer: false,//hide clock timer
            //endTime: new Date().toLocaleTimeString(),
            htmlOutput: data.htmlOutput, //Commenting out because I think this already gets set in the data tunnel, but will have to test
            isDrawingCharts: true //Eventually can change this variable name to "displayReport" after the SiteDistribution is working
        });
        return false;
    }


    hideDrawingCharts = (e) => {
        //try {
        //    e.preventDefault();  //What "default" action is being prevented by having this code here?
        //} catch (ex) { }
        this.setState({ isDrawingCharts: false });
        return false;
    }


    newRecord(e) {
        try {
            this.setState({
                populationLabel: "",
                displaySex: true,
                displayStage: true,
                displayAge: false,
                displayPrimaryPayer: false,
                displayRace: false,
                displayEthnicity: false,
                displayZip: false,
                displayPercentages: false,
                stageType: "AJCC",
                zipCodes: "",
                includeExcludeZip: "Include",
                includeExcludeFlag: "Include",
                outputType: "Screen",

                htmlOutput: null,
                startTime: null,
                endTime: null,
                statusBar: "",

                isFormDirty: false,
            })
        } catch (ex) {}
    }


    printReport = (e) => {
        var printStyle = document.getElementById('PrintStyle');
        var printContent = document.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Site Distribution</title><style type="text/css">';
        html += printStyle.innerHTML + '</style></head><body><div id="printableContent">';
        html += printContent.innerHTML + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }

    render() {
        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTime}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let drawingCharts;
        {
            drawingCharts = this.state.isDrawingCharts ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="siteDistributionReportDisplay" className="dragableLargestWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Site Distribution</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingCharts(e) }}></span>
                                    </a>
                                </div>
                            </div>
{/*                            <div className="emptyLine"></div>*/}
                            <div className="container" id="siteDistributionShowingCharts">
                                {/*Drawing table here*/}
                                <div dangerouslySetInnerHTML={{ __html: this.state.htmlOutput ? this.state.htmlOutput : "" }} className="" />
                            </div>
{/*                            <div className="emptyLine"></div>*/}
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12" style={{ display: "flex" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ScreenButton ScreenButtonHover" title=""
                                            onClick={(e) => { this.printReport(e) }}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print
                                        </button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ScreenButton ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={(e) => { this.hideDrawingCharts(e) }} value="exit" autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let dialogForm = <div className="reportDialogForm">
            <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                <div className="reportDialogFormContent">
                    <div id="popLabel">
                        {/*Adding modular "Edit Population Label" component on top of this screen*/}
                        <PopLabel plUpdFnc={this.updatePopLabelBackToParent} showToggleBtn={true} updateToParent={this.updateToParent} />
                    </div>

                    <div className="reportScreenTitle">Site Distribution</div>
                    {this.state.dialogForm || this.state.loaded ?

                        <form onSubmit={false} id="siteDistributionForm" className="">
                            <div className="frameInner">
                                {/*<div className="col-sm-7">*/}
                                {/*    <Input type="LabelPopup" focused={this.handleOnFocus}*/}
                                {/*        changed={this.handleFormChange} width="340px"*/}
                                {/*        labelText="Population Label"*/}
                                {/*        field="populationLabel" lineStyle="oneLine"*/}
                                {/*        value={this.state.populationLabel}*/}
                                {/*        dataSource="Labels"*/}
                                {/*        titleDialog="Population Labels" labelWidth="140px"*/}
                                {/*        inValid={this.state.uAllItemFields["populationLabel"]}*/}
                                {/*        lostfocused={this.handleLostFocus}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                {/*<br />*/}
                                {/*<div id="popLabel">*/}
                                {/*    <label>Population Label:</label>*/}
                                {/*    <input type="text" id="populationLabel"*/}
                                {/*        value={this.state.populationLabel}*/}
                                {/*        onChange={(e) => this.setState({ populationLabel: e.target.value })}*/}
                                {/*        labelText="Population Label: "*/}
                                {/*        field="populationLabel"*/}
                                {/*/>*/}
                                {/*</div>*/}

                                {/*<div className="col-sm-4 checkboxTypeCls">*/}
                                {/*    <Input type="checkbox" lineStyle="oneLine"*/}
                                {/*        labelText="Include Duplicates of Shared Patients" field="includeDuplicatesSharedPatients"*/}
                                {/*        labelWidth={Global.LabelWidth} labelWidth="200px"*/}
                                {/*        labelStyle="lblOnCheckbox" width="50px"*/}
                                {/*        value={this.state.isCheckedIncludeDuplicatesSharedPatients ? "1" : "0"}*/}
                                {/*        checked={this.state.isCheckedIncludeDuplicatesSharedPatients}*/}
                                {/*        clicked={this.toggleChecked}*/}
                                {/*        style={{ cursor: "pointer", fontWeight: "bold" }}*/}
                                {/*    />*/}
                                {/*</div>*/}

                                {/*<div className="col-sm-4 checkboxTypeCls">*/}
                                {/*    <Input type="checkbox" lineStyle="oneLine"*/}
                                {/*        labelText="Stage" field="displayStage"*/}
                                {/*        labelWidth="200px"*/}
                                {/*        labelStyle="lblOnCheckbox" width="40px"*/}
                                {/*        value={this.state.displayStage ? "1" : "0"}*/}
                                {/*        checked={this.state.displayStage}*/}
                                {/*        clicked={this.toggleChecked}*/}
                                {/*        style={{ cursor: "pointer", fontWeight: "bold" }}*/}
                                {/*    />*/}
                                {/*</div>*/}

                                {/*<input type="checkbox"*/}
                                {/*    value={this.state.displayStage ? "1" : "0"}*/}
                                {/*    checked={this.state.displayStage}*/}
                                {/*    clicked={this.toggleChecked}*/}
                                {/*    style={{ cursor: "pointer", fontWeight: "bold" }}*/}
                                {/*>Stage Type</input>*/}

                                <input type="checkbox" id="displayAge" className="checkbox"
                                    name="displayAge"
                                    value={this.state.displayAge ? "1" : "0"}
                                    checked={this.state.displayAge}
                                    onChange={this.toggleChecked} />
                                <label for="displayAge" className="checkboxLabel"> Age at Diagnosis </label>
                                <br />
                                <input type="checkbox" id="displayEthnicity" className="checkbox"
                                    name="displayEthnicity"
                                    value={this.state.displayEthnicity ? "1" : "0"}
                                    checked={this.state.displayEthnicity}
                                    onChange={this.toggleChecked} />
                                <label for="displayEthnicity" className="checkboxLabel"> Ethnicity </label>
                                <br />
                                <input type="checkbox" id="displayPrimaryPayer" className="checkbox"
                                    name="displayPrimaryPayer"
                                    value={this.state.displayPrimaryPayer ? "1" : "0"}
                                    checked={this.state.displayPrimaryPayer}
                                    onChange={this.toggleChecked} />
                                <label for="displayPrimaryPayer" className="checkboxLabel"> Insurance </label>
                                <br />
                                <input type="checkbox" id="displayRace" className="checkbox"
                                    name="displayRace"
                                    value={this.state.displayRace ? "1" : "0"}
                                    checked={this.state.displayRace}
                                    onChange={this.toggleChecked} />
                                <label for="displayRace" className="checkboxLabel"> Race </label>
                                <br />
                                <input type="checkbox" id="displaySex" className="checkbox"
                                    name="displaySex"
                                    value={this.state.displaySex ? "1" : "0"}
                                    checked={this.state.displaySex}
                                    onChange={this.toggleChecked} />
                                <label for="displaySex" className="checkboxLabel"> Sex </label>
                                <br />


                                <div id="stageTypeRow" className="rows">
                                    <input type="checkbox" id="displayStage" className="checkbox"
                                        name="displayStage"
                                        value={this.state.displayStage ? "1" : "0"}
                                        checked={this.state.displayStage}
                                        onChange={this.toggleChecked} />
                                    <label id="displayStageLabel" for="displayStage" className="checkboxLabel"> Stage </label>

                                    <div className={this.state.displayStage ? "stageType" : "hidden"}>
                                        <div id="stageTypeLabel" style={{ verticalAlign: "middle", border: "none" }}>Stage Type:</div>
                                        <div className="stageTypeRadio">

                                        <label style={{ width: "100px", height: "15px", cursor: "pointer" }}>
                                            <input type="radio" onClick={this.setStateStageType}
                                                checked={this.state.stageType === "General"}
                                                name="stageType" field="stageType" value="General"
                                                style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                            General
                                        </label>
                                        <label style={{ width: "100px", height: "15px", cursor: "pointer" }}>
                                            <input type="radio" onClick={this.setStateStageType}
                                                checked={this.state.stageType === "AJCC"}
                                                name="stageType" field="stageType" value="AJCC"
                                                style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                            AJCC
                                        </label>
                                        </div>
                                     </div>
                                </div>

                                <br />
                                <div id="zipCodeRow" className="rows">

                                    <input type="checkbox" id="displayZip" className="checkbox"
                                    name="displayZip"
                                    value={this.state.displayZip ? "1" : "0"}
                                    checked={this.state.displayZip}
                                    onChange={this.toggleChecked} />
                                    <label id="displayZipLabel" for="displayZip" className="checkboxLabel"> Zip Code </label>


                                    <div id="zip" className={this.state.displayZip ? "zip" : "hidden"}>
                                        <label id="zipCodesLabel">Zip Codes:</label>
                                        <input type="text" id="zipCodes"
                                            value={this.state.zipCodes}
                                            onChange={(e) => this.setState({ zipCodes: e.target.value })}
                                        />

                                    <div className="includeExcludeZipRadio">
                                        {/*<div id="includeExcludeZipLabel" style={{ verticalAlign: "middle", border: "none" }}>Ca in-situ cervix, squamous and basal skin cell, and intraepithelial neoplasia cases:</div>*/}
                                        <label style={{ width: "80px", height: "0px", cursor: "pointer" }}>
                                            <input type="radio" onClick={this.setStateIncludeExcludeZip}
                                                checked={this.state.includeExcludeZip === "Include"}
                                                name="includeExcludeZip" field="includeExcludeZip" value="Include"
                                                    style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginTop: "1px", marginRight: "1px"}} />
                                            Include
                                        </label>
                                        <label style={{ width: "80px", height: "0px", cursor: "pointer" }}>
                                            <input type="radio" onClick={this.setStateIncludeExcludeZip}
                                                checked={this.state.includeExcludeZip === "Exclude"}
                                                name="includeExcludeZip" field="includeExcludeZip" value="Exclude"
                                                style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                            Exclude
                                        </label>
                                    </div>
                                </div>



                                </div>

                                <br />
                                <input type="checkbox" id="displayPercentages" className="checkbox"
                                    name="displayPercentages"
                                    value={this.state.displayPercentages ? "1" : "0"}
                                    checked={this.state.displayPercentages}
                                    onChange={this.toggleChecked} />
                                <label for="displayPercentages" className="checkboxLabel"> Display Percentages </label>
                                <br />
                                    

                                {/*<div className="col-sm-3">*/}
                                {/*    <label style={{ marginTop: "5px", height: "15px" }}>*/}
                                {/*        <input type="radio" onClick={(e) => { this.setStateStageType(e) }}*/}
                                {/*            checked={this.state.stageType === "General"}*/}
                                {/*            name="stageType" value="General" id="stageType_General"*/}
                                {/*            style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />*/}
                                {/*        General*/}
                                {/*    </label>*/}
                                {/*    <br />*/}
                                {/*    <label style={{ width: "240px", height: "15px" }}>*/}
                                {/*        <input type="radio" onClick={(e) => { this.setStateStageType(e) }}*/}
                                {/*            checked={this.state.stageType === "AJCC"}*/}
                                {/*            name="stageType" value="AJCC" id="stageType_AJCC"*/}
                                {/*            style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />*/}
                                {/*        AJCC*/}
                                {/*    </label>*/}
                                {/*</div>*/}






                                <div className="row">  
                                    <div className="includeExcludeFlagRadio">
                                        <div id="includeExcludeFlagLabel" style={{ verticalAlign: "middle", border: "none" }}>Ca in-situ cervix, squamous and basal skin cell, and intraepithelial neoplasia cases:</div>
                                        <label style={{ width: "100px", height: "0px", cursor: "pointer" }}>
                                            <input type="radio" onClick={this.setStateIncludeExcludeFlag}
                                                checked={this.state.includeExcludeFlag === "Include"}
                                                name="includeExcludeFlag" field="includeExcludeFlag" value="Include"
                                                style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                            Include
                                        </label>
                                        <br />
                                        <label style={{ width: "100px", height: "0px", cursor: "pointer" }}>
                                            <input type="radio" onClick={this.setStateIncludeExcludeFlag}
                                                checked={this.state.includeExcludeFlag === "Exclude"}
                                                name="includeExcludeFlag" field="includeExcludeFlag" value="Exclude"
                                                style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                            Exclude
                                        </label>
                                    </div>
                                </div>

                                <br />
                        <div className="row">

                            <div className="outputDeviceRadio">
                                <div id="outputDeviceLabel" style={{ verticalAlign: "middle", border: "none" }}>Send Report To:</div>
                                <label style={{ width: "240px", height: "0px", cursor: "pointer"  }}>
                                            <input type="radio" onClick={this.setStateOutputType}
                                            checked={this.state.outputType === "Excel"}
                                            name="outputType" field="outputType" value="Excel"
                                            style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                        Excel
                                    </label>
                                    <br />
                                <label style={{ width: "240px", height: "0px", cursor: "pointer"  }}>
                                            <input type="radio" onClick={this.setStateOutputType}
                                            checked={this.state.outputType === "Screen"}
                                            name="outputType" field="outputType" value="Screen"
                                            style={{ cursor: "pointer", fontWeight: "bold", width: "20px", marginRight: "1px" }} />
                                        Screen
                                    </label>
                                </div>
                        </div>
                        <br />

                        <div id="CONTAINER_JS_CLOCK" className="hidden">
                            <label>Selecting Cases</label>
                            <div id="IMAGE_WAIT" style={{ color: "black", fontWeight: "bold" }}>
                                Selecting Cases - Please Wait ...
                            </div>
                        </div>
                    </div>

                        <div className="reportScreenStatusBar">
                                Start Time: {this.state.startTime} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; End Time: {this.state.endTime}
                        </div>

                        <fieldset className="reportDialogFormButtonBar">
                            <button id="BUTTON_RUN"
                                className="ErsGrBlueButton"
                                onClick={(e) => { this.showClockEvent(e) }}
                                //disabled={!this.state.isFormDirty}
                            >
                                <img id="BUTTON_RUN_ICON"
                                    src="https://crstar.ers-can.com/images/$$code.action.run.png.a5image"
                                    style={{ verticalAlign: "middle", border: "none" }}></img>
                                Run
                            </button>

                            <button id="BUTTON_NEWRECORD"
                                className={this.state.isFormDirty ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                onClick={this.newRecord.bind(this)}
                                //onClick={(e) => { this.showClockEvent(e) }}
                                disabled={!this.state.isFormDirty}
                            >
                                <img id="BUTTON_NEWRECORD_ICON"
                                    src={this.state.isFormDirty ? "https://crstar.ers-can.com/images/$$navigate.new.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$navigate.new.png.a5image"}
                                    style={{ verticalAlign: "middle", border: "none" }}></img>
                                New
                            </button>
                        </fieldset>
                    </form>
                    : <p>Site Distribution report screen encountered an issue and cannot load ...</p>}
                </div>
            </div>
        </div>
 
        return (
            <React.Fragment>
                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {drawingCharts}
                </React.Fragment>

                <React.Fragment>
                    {dialogForm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        siteDistributionInfo: state.patientAbstract.siteDistributionInfo,   //This holds information passed from the C# report back to the screen
        clientgroup: state.auth.clientgroup,                                //Passed to C# report to get path
        userId: state.auth.userId,                                          //Passed to C# report to get path

        initialPageVisible: state.patientAbstract.initialPageVisible,       //Controls if security restrictions are set to prevent page from loading
        isDialogLoading: state.patientAbstract.isDialogLoading,
        waitingFor: state.patientAbstract.waitingFor,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        isAddNewUser: state.patientAbstract.isAddNewUser,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        runSiteDistribution: (dialogForm) => dispatch(actionCreators.runSiteDistribution(dialogForm)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
        generateSiteDistribution: (flag) => dispatch(actionCreators.generateSiteDistribution(flag)),

        // Tabs view 10/25/2022
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        //setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        //setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),

        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearAddNewUserFlag: () => dispatch(actionCreators.clearAddNewUserFlag()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
        selectAPopLabel: (label) => dispatch(actionCreators.selectAPopLabel(label)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteDistribution);