/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navbar, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import { actionCreators } from "../store/PatientAbstract";
import * as actions from '../store/actions/index';
import './NavMenu.css';
import * as dialogTypes from "./AbstractManagement/maintDialogs";
import * as stateMaint from "./AbstractManagement/StateMaint";
import ConfigData from '../store/config.json';
import Draggable from 'react-draggable';
import axios from "../axios-instance";

class NavMenu extends Component {

    state = {
        firstItem: '',
        stateAlias: '',
        versionNbr: '',
        hospitals: null,
        userHosp: null,
        referStateOfHospital: "",
        identificationSystemAlive: null,
        showModal: false,
    }
    interval = 0;

    handleLogout(event, msg) {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showModal: false });
        
        // @TODO - call logout action to api server
        var uid = this.props.userId ? this.props.userId : this.props.user_id;
        var cg = this.props.clientgroup;

        var sessionTimeout = this.props.crstarSessionTimeout;
        console.log("[Logging] crstarSessionTimeout = " + sessionTimeout);

        var publicFolder = document.getElementById("usr_public_folder") ? document.getElementById("usr_public_folder").value : "";

        var _this = this;
        let _url = ConfigData.CRStarAPI_URL + cg + "/preLogout";
        let params = { path: publicFolder, accessToken: localStorage.getItem('token') };

        axios.post(_url, params)
            .then(response => {
                var newAccessToken = response && response.data ? response.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                _this.props.onLogout(uid, cg, sessionTimeout, msg);
            })
            .catch(err => {
                console.log(err);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        // clear all previous session
        this.props.resetAllStoringOfUser();

        localStorage.removeItem('identificationSystemAlive');
        return false;
    }

    //
    getHospSecurity = () => {
        var _this = this;
        let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetUserByEmail";
        axios.post(url, { email: this.props.userId, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                const availableHospsData = res.data.user !== null && res.data.user.availableHospsData !== null ? res.data.user.availableHospsData : "";
                const userPublicFolder = res.data.user !== null && res.data.user.publicFolder !== null ? res.data.user.publicFolder : "";
                if (!res.data.error && res.data.user !== null && availableHospsData !== null && availableHospsData !== "") {
                    document.getElementById("usr_AvailableHospsData") ? (document.getElementById("usr_AvailableHospsData").value = availableHospsData) : null;
                    _this.setState({ availableHospsData: availableHospsData });
                    //
                    _this.props.setAvailableHospsDataOfUser(availableHospsData);
                }

                _this.setState({ userPublicFolder: userPublicFolder});

                document.getElementById("usr_public_folder") ? (document.getElementById("usr_public_folder").value = userPublicFolder) : "";
                return false;
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }*/
                return false;
            });
        return false;
    }

    storeUserInfoToSession = () => {
        var userId = this.props.userId;
        var crstarToken = this.props.crstarToken;
        var expiresIn = this.props.expiresIn;
        var enable2fa = this.props.enable2fa;
        var passExpired = this.props.passExpired;
        var lastAuth = this.props.lastAuth;
        var lastResult = this.props.lastResult;
        var key2FA = this.props.key2FA;
        var clientgroup = this.props.clientgroup;

        if (sessionStorage.user !== null && sessionStorage.user !== undefined) {
            var user = JSON.parse(sessionStorage.user);

            userId = user.userId;
            crstarToken = user.token;
            expiresIn = user.expires_in;
            enable2fa = user.enable2fa;
            passExpired = user.passExpired;
            lastAuth = user.lastAuth;
            lastResult = user.lastResult;
            key2FA = user.key2FA;
        }

        var isAuthenticated = this.props.isAuthenticated;
        if (isAuthenticated) {
            //sessionStorage.clear();

            var user = {
                // check logged on
                'userId': this.props.userId ? this.props.userId : userId,
                'token': this.props.crstarToken ? this.props.crstarToken : crstarToken,
                'expires_in': this.props.expiresIn !== 0 ? this.props.expiresIn : expiresIn,
                'created_at': new Date(),

                // check more than others
                'hasChangePass': this.props.hasChangePass,
                'enable2fa': this.props.enable2fa !== null ? this.props.enable2fa : enable2fa,
                'passExpired': this.props.passExpired ? this.props.passExpired : passExpired,
                'lastAuth': this.props.lastAuth ? this.props.lastAuth : lastAuth,
                'lastResult': this.props.lastResult ? this.props.lastResult : lastResult,
                'key2FA': this.props.key2FA ? this.props.key2FA : key2FA
            };
            sessionStorage.setItem('user', JSON.stringify(user));
            sessionStorage.setItem('clientgroup', clientgroup);
        }
    }

    init() {
        this.storeUserInfoToSession();

        this.getHospSecurity();

        //
        var _this = this;
        this.interval = setInterval(function () {
            const url = ConfigData.CRStarAPI_URL + _this.props.clientgroup + "/CheckSystemIsAlive";
            axios.post(url, { accessToken: localStorage.getItem('token') })
                .then(res => {
                    var newAccessToken = res && res.data && res.data.accessToken ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (res.data.error || res.data.message == "Die") {
                        clearInterval(_this.interval);
                        _this.setState({ showModal: true });
                        return false;
                    } else {
                        var identificationSystemAlive = localStorage.getItem('identificationSystemAlive');
                        if (!identificationSystemAlive) {
                            localStorage.setItem('identificationSystemAlive', res.data.message);
                        } else if (identificationSystemAlive != res.data.message) {
                            clearInterval(_this.interval);
                            _this.setState({ showModal: true });
                            return false;
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    clearInterval(_this.interval);
                    return false;
                });
        }, 60000);//check every 5 mins (300000) / 1min (60000)
    }

    componentDidMount = () => {
        this.init();
        {
            var thisMe = this;
            const url = ConfigData.CRStarAPI_URL + this.props.clientgroup  + "/CheckSystemIsAlive";
            axios.post(url, { accessToken: localStorage.getItem('token') })
                .then(res => {
                    var newAccessToken = res && res.data && res.data.accessToken ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (res.data.error || res.message == "Die") {
                        thisMe.setState({ showModal: true });
                        return false;
                    } else {
                        var identificationSystemAlive = localStorage.getItem('identificationSystemAlive');
                        if (!identificationSystemAlive) {
                            localStorage.setItem('identificationSystemAlive', res.data.message);
                        } else if (identificationSystemAlive != res.data.message) {
                            thisMe.setState({ showModal: true });
                            return false;
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    return false;
                });
        }

        //
        this.props.setUserId(this.props.userId);
        this.props.setErsClientGroup(this.props.clientgroup);

        if (this.state.userHosp === null) {
            const url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetUserHospitalFromSetting";
            axios.post(url, { uid: this.props.userId, accessToken: localStorage.getItem('token') })
                .then(res => {
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error && res.data.userSettings) {
                        var userHosp = res.data.userSettings.userHosp && res.data.userSettings.userHosp != "" ? res.data.userSettings.userHosp : "01";
                        var hospState = res.data.userSettings.hospitalState;
                        var referState = res.data.userSettings.referStateOfHospital;
                        var versionNbr = res.data.userSettings.versionNbr;

                        this.setState({
                            userHosp: userHosp,
                            referStateOfHospital: referState,
                        });

                        if (this.state.hospitals === null) {
                            const url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetHospitalList";
                            axios.post(url, { uid: this.props.userId, accessToken: localStorage.getItem('token') })
                                .then(res => {
                                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                                    if (newAccessToken) {
                                        localStorage.setItem('token', newAccessToken);
                                    }

                                    if (res.data && !res.data.error && res.data.hospitalList && res.data.hospitalList.length > 0) {
                                        this.setState({
                                            hospitals: res.data.hospitalList,
                                            firstItem: userHosp ? userHosp : res.data.hospitalList[0]["hospNbr"],
                                            stateAlias: hospState ? hospState : res.data.hospitalList[0]["state"],
                                            versionNbr: versionNbr ? versionNbr : res.data.hospitalList[0]["version"],
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    /*
                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }*/
                                    return false;
                                });
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }*/
                    return false;
                });
        }        
    }

    componentDidUpdate(prevProps) {
        this.props.setUserId(this.props.userId);
        this.props.setErsClientGroup(this.props.clientgroup);

        if (this.state.userHosp === null) {
            const url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetUserHospitalFromSetting";
            axios.post(url, { uid: this.props.userId, accessToken: localStorage.getItem('token') })
                .then(res => {
                    var newAccessToken = res && res.data && res.data.accessToken ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error && res.data.userSettings) {
                        var userHosp = res.data.userSettings.userHosp && res.data.userSettings.userHosp != "" ? res.data.userSettings.userHosp : "01";
                        var hospState = res.data.userSettings.hospitalState;
                        var referState = res.data.userSettings.referStateOfHospital;
                        var versionNbr = res.data.userSettings.versionNbr;

                        this.setState({
                            userHosp: userHosp,
                            referStateOfHospital: referState,
                        });

                        if (this.state.hospitals === null) {
                            const url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetHospitalList";
                            axios.post(url, { uid: this.props.userId, accessToken: localStorage.getItem('token') })
                                .then(res => {
                                    var newAccessToken = res && res.data && res.data.accessToken ? res.data.accessToken : null;
                                    if (newAccessToken) {
                                        localStorage.setItem('token', newAccessToken);
                                    }

                                    if (res.data && !res.data.error && res.data.hospitalList && res.data.hospitalList.length > 0) {
                                        this.setState({
                                            hospitals: res.data.hospitalList,
                                            firstItem: userHosp ? userHosp : res.data.hospitalList[0]["hospNbr"],
                                            stateAlias: hospState ? hospState : res.data.hospitalList[0]["state"],
                                            versionNbr: versionNbr ? versionNbr : res.data.hospitalList[0]["version"],
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    /*
                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }*/
                                    return false;
                                });
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }*/
                    return false;
                });
        }

        if (this.state.firstItem !== "" && !prevProps.selectedHospital) {
            this.props.resetHospitalInfo(this.state.firstItem);
        }
        if (this.state.stateAlias !== "" && !prevProps.selectedHospital) {
            this.props.currentShowingState(this.state.stateAlias);
        }
        if (this.state.versionNbr !== "" && !prevProps.selectedHospital) {
            this.props.currentShowingHospitalVersion(this.state.versionNbr);
        }
    }

    resetHospitalInfo = (event) => {
        this.props.resetHospitalInfo(event.target.value);

        // saving the selected hospital into user_settings table
        let hospNbr = event.target.selectedOptions[0].value;
        const url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/SetUserHospitalIntoSettings";
        axios.post(url, { uid: this.props.userId, hospNbr: hospNbr, accessToken: localStorage.getItem('token')})
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    console.log('SetUserHospitalIntoSettings::success');

                    // Fixing #PR00365
                    var referState = res.data.userSettings.referStateOfHospital;
                    this.setState({
                        referStateOfHospital: referState,
                    });

                    //Reload Dashboard Setting screen again!
                    this.props.loadDashboardSettingsInfo(this.props.user_id, this.props.selectedHospital);
                } else {
                    console.log('SetUserHospitalIntoSettings::failed');
                }
            })
            .catch(function (error) {
                console.log(error);
                _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }*/
                return false;
            });

        if (event.target.selectedOptions[0].getAttribute('data-state') && event.target.selectedOptions[0].getAttribute('data-state') !== "") {
            this.props.currentShowingState(event.target.selectedOptions[0].getAttribute('data-state'));
            this.props.currentShowingHospitalVersion(event.target.selectedOptions[0].getAttribute('data-version'));

            if (this.props.selectedMedicalRecord === null) {
                return false;
            }

            // 
            if (stateMaint.stateSpecificScreens.includes(event.target.selectedOptions[0].getAttribute('data-state'))) {
                // set this tab to enabled
                this.props.setEnabledStatus(dialogTypes.STATE_SPECIFIC);
            } else {
                // set this tab to disabled
                this.props.setEnabledStatus(dialogTypes.STATE_SPECIFIC, false);
            }
        }

        return false;
    }

    gotoUserManagement = () => {
        console.log('calling gotoUserManagement...');
    }

    render() {
        const userHosp = this.state.userHosp;

        let sessionTimeoutMsg;
        {
            const msg = "The server has been rebooted, please re-login.";
            sessionTimeoutMsg = this.state.showModal ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Warning</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.handleLogout(e, msg) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">{msg}</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.handleLogout(e, msg) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        return (
            <React.Fragment>
                <React.Fragment>
                    <header>
                        <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                            <React.Fragment>
                                <NavbarBrand tag={Link} to="/" style={{ marginLeft: "-15px" }} tabIndex="-1">CRStar Version 24.0</NavbarBrand>

                                <select className="hospitalListOpt" onChange={this.resetHospitalInfo}>
                                    {
                                        this.state.hospitals !== null && this.state.hospitals.length > 0
                                            ? this.state.hospitals.map((prm, index) => (
                                                <option value={prm.hospNbr ? prm.hospNbr : "01"} key={"hospitalOnTopItem"+index}
                                                    data-state={prm.state} data-version={prm.version}
                                                    selected={userHosp == prm.hospNbr}>
                                                    {prm.hospNbr + " - " + prm.hospName}
                                                </option>
                                            ))
                                            : <option value="">Loading...</option>
                                    }
                                </select>
                                <div style={{ display: 'none' }}>
                                    <input type="hidden" id="ReferStateOfSelectedHospital" value={this.state.referStateOfHospital ? this.state.referStateOfHospital : ""} />
                                    <input type="hidden" id="usr_AvailableHospsData" value={this.state.availableHospsData ? this.state.availableHospsData : ""} />
                                    <input type="hidden" id="usr_public_folder" value={this.state.userPublicFolder ? this.state.userPublicFolder : ""} />
                                </div>
                                <h5 style={{ minWidth: "21%", position: "absolute", right: "0", textAlign: "right", top: "2px" }} onClick={this.gotoUserManagement}>User ID: {this.props.userId}</h5>
                            </React.Fragment>
                        </Navbar>
                    </header>

                    <footer className="footer-Layout">
                        <div style={{ position: "absolute", left: "38%", bottom: "0" }}>  
                            © 2024 CRStar by Health Catalyst. Confidential and Proprietary.
                        </div>
                    </footer>
                </React.Fragment>

                <React.Fragment>
                    {sessionTimeoutMsg}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedHospital: state.patientAbstract.selectedHospital,
        currentShowingState: state.patientAbstract.currentShowingState,
        currentShowingHospitalVersion: state.patientAbstract.currentShowingHospitalVersion,
        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        //
        clientgroup: state.auth.clientgroup,
        user_id: state.auth.userId,
        crstarSessionTimeout: state.auth.expiresIn,

        //
        isAuthenticated: state.auth.token !== null,
        crstarToken: state.auth.token,
        userId: state.auth.userId,
        hasChangePass: state.auth.hasChangePass,
        passExpired: state.auth.passExpired,
        expiresIn: state.auth.expiresIn,
        enable2fa: state.auth.enable2fa,
        lastAuth: state.auth.lastAuth,
        lastResult: state.auth.lastResult,
        key2FA: state.auth.key2FA,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        resetHospitalInfo: (hospNbr) => dispatch(actionCreators.resetHospitalInfo(hospNbr)),
        currentShowingState: (state) => dispatch(actionCreators.currentShowingState(state)),
        currentShowingHospitalVersion: (version) => dispatch(actionCreators.currentShowingHospitalVersion(version)),
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setUserId: (uid) => dispatch(actionCreators.setUserId(uid)),
        setErsClientGroup: (clientGroup) => dispatch(actionCreators.setErsClientGroup(clientGroup)),
        //
        onLogout: (uid, cg, sval, msg) => dispatch(actions.logout(uid, cg, sval, msg)),
        resetAllStoringOfUser: () => dispatch(actionCreators.resetAllStoringOfUser()),
        loadDashboardSettingsInfo: (name, hosp) => dispatch(actionCreators.loadDashboardSettingsInfo(name, hosp)),
        setAvailableHospsDataOfUser: (hosp) => dispatch(actions.setAvailableHospsDataOfUser(hosp)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);