/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { actionCreators } from "../../../store/PatientAbstract";
import ConfigData from "../../../store/config.json";
import moment from "moment";
import * as dialogTypes from "../maintDialogs";
import * as actions from '../../../store/actions/index';
import { formatDate } from '@telerik/kendo-intl';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import axios from "axios";
import "./Shared/Shared.css";
import Draggable from 'react-draggable';

class KendoGridDateCell extends React.Component {
    render() {
        const value = this.props.dataItem[this.props.field];
        return (
            <td>
                {value ? formatDate(new Date(value), "MM/dd/yyyy") : ""}
            </td>
        );
    }
}

class PatientAbstract extends Component {
    state = {
        patients: [],
        isLoading: false,
        filter: {
            logic: "",
            filters: []
        },
        filterOperators: {
            'text': [
                //{ text: 'grid.filterStartsWithOperator', operator: 'startswith' },
                //{ text: 'grid.filterEndsWithOperator', operator: 'endswith' },
                { text: 'grid.filterContainsOperator', operator: 'contains' }
            ],
            'date': [
                { text: 'grid.filterEqOperator', operator: 'eq', format: 'mm/dd/yyyy' }
            ]
        },
        sort: [],
        total: 0,
        skip: 0,
        take: 10,
        isDialogVisible: false,
        isChildDialogVisible: false,
        errorMsg: '',

        enterKeyPressed: false,
        shiftKeyPressed: false,
        tabKeyPressed: false,
    }
    resetPageNumber = false;
    focusingItemIndexNo = 0;

    hideValidationError = (event, obj) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            isShowValidationError: false, errorMsg: null
        });

        setTimeout(function () {
            let input = document.getElementById("dobFilter");
            input ? input.focus() : null;
        }, 100);

        return false;
    }

    addEventListener = () => {
        var pa = this;
        var patientLookupPage = document.querySelector('#patientAbstractGridView');
        patientLookupPage.addEventListener('keydown', function (e) {

            if (e.keyCode == 40) {// down arrow
                let filterInputFocusing = e.target.getAttribute("class") === "k-input-inner" ? true : false;

                var trs = patientLookupPage.getElementsByClassName("k-master-row");
                if (filterInputFocusing) {
                    for (var len = 0; len < trs.length; len++) {
                        trs[len] ? trs[len].setAttribute("class", len % 2 == 0 ? "k-master-row" : "k-master-row k-alt") : "";
                    }

                    if (trs && trs[0] && trs[0].firstChild) {
                        trs[0] ? trs[0].firstChild.setAttribute('tabindex', '0') : "";
                        trs[0] ? trs[0].firstChild.focus() : "";

                        trs[0] ? trs[0].setAttribute("class", "k-master-row k-selected") : "";

                        setTimeout(function () {
                            trs[0] ? trs[0].scrollIntoView() : "";
                            trs[0] ? trs[0].scrollTop = 0 : "";
                        }, 100);
                    }
                } else if (e.target.parentNode.getAttribute("class") !== "k-filter-row") {
                    trs[0] ? trs[0].setAttribute("class", "k-master-row") : "";
                } else if (e.target.parentNode.getAttribute("class") === "k-filter-row") {
                    trs[0] ? trs[0].setAttribute("class", "k-master-row k-selected") : "";
                }
            } else if (e.keyCode == 38) {//up arrow
                var pagingLinkFocusing = e.target.getAttribute("class") ? (e.target.getAttribute("class").indexOf("k-link") !== -1 || e.target.getAttribute("class").indexOf("k-button") !== -1 ? true : false) : false;

                //const lastItemOnGrid = 9;
                let lastItemOnGrid = patientLookupPage.querySelector(".k-grid-table") &&
                    patientLookupPage.querySelector(".k-grid-table").lastChild &&
                    patientLookupPage.querySelector(".k-grid-table").lastChild.childElementCount ? patientLookupPage.querySelector(".k-grid-table").lastChild.childElementCount : 10;
                lastItemOnGrid = lastItemOnGrid - 1;

                var trs = patientLookupPage.getElementsByClassName("k-master-row");
                if (pagingLinkFocusing) {
                    for (var len = 0; len < trs.length; len++) {
                        trs[len] ? trs[len].setAttribute("class", len % 2 == 0 ? "k-master-row" : "k-master-row k-alt") : "";
                    }

                    if (trs && trs[lastItemOnGrid] && trs[lastItemOnGrid].firstChild) {
                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].firstChild.setAttribute('tabindex', '0') : "";
                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].firstChild.focus() : "";

                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].setAttribute("class", "k-master-row k-alt k-selected") : "";

                        setTimeout(function () {
                            trs[lastItemOnGrid] ? trs[lastItemOnGrid].scrollIntoView() : "";
                            trs[lastItemOnGrid] ? trs[lastItemOnGrid].scrollTop = 10000 : "";//scroll to bottom of grid
                        }, 100);
                    }
                } else if (e.target.parentNode.getAttribute("data-grid-row-index") === "0") {
                    trs[0] ? trs[0].setAttribute("class", "k-master-row") : "";
                } else {
                    trs[lastItemOnGrid] ? trs[lastItemOnGrid].setAttribute("class", "k-master-row k-alt") : "";
                }
            } else if (e.keyCode == 13 && !pa.enterKeyPressed && e.target.getAttribute("class") === "k-input-inner" && e.target.value != "") {//enter
                pa.setState({ enterKeyPressed: true });
                pa.props.setPageLoaded(false);
                localStorage.setItem("Searching", null);
            } else {
                pa.setState({ enterKeyPressed: false });
            }
        });
    }

    //
    init = () => {
        /* Fixing #PR00527: Patient Lookup Grid */
        setTimeout(function () {
            document.getElementsByClassName("k-input-inner")[0] ? document.getElementsByClassName("k-input-inner")[0].focus() : "";
        }, 0);

        var patientAbstract = document.getElementById("patientAbstractGridView");
        if (patientAbstract && patientAbstract.querySelectorAll("input.k-input-inner")) {
            var inputs = patientAbstract.querySelectorAll("input.k-input-inner");
            for (var idx = 0; idx < inputs.length; idx++) {
                let input = inputs[idx];
                if (idx === 4) {
                    input.setAttribute("placeholder", "mm/dd/yyyy");
                    input.setAttribute("id", "dobFilter");
                }
            }
        }
    }

    componentDidMount = () => {
        console.log('[componentDidMount] Drawing PatientAbstract...');

        this.addEventListener();

        // first close all current existing tabs
        this.props.closeOtherTabItems();

        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);

        // Add all tabs in maintDialogs.js
        var pa = this;
        dialogTypes.dialogDisplays.map((dialog, index) => {
            pa.props.addTabItems(dialog);
            if (index === 0) pa.props.setEnabledStatus(dialog);
            else if (index === 1) {
                if (pa.props.isPatientSelected) {
                    pa.props.setEnabledStatus(dialog);
                } else {
                    pa.props.setEnabledStatus(dialog, false);
                }
            } else {
                if (pa.props.isMedicalRecordSelected) {
                    pa.props.setEnabledStatus(dialog);
                } else {
                    pa.props.setEnabledStatus(dialog, false);
                }
            }
        });

        this.setState({ isLoading: true });
        this.props.showOrHideLoading(true);

        this.getPatients(true);

        this.init();
    }

    User_Defined_Date_Format(myDate) {
        //This code tries to fill in slashes and the two digit century when a user doesn't enter them
        var fmtDate = "";

        myDate = myDate.replace(/[-]/gi, '/');

        // adding rather
        if (myDate.length > 10) {
            myDate = myDate.substr(0, 10);
        }
        //

        if ((myDate.length == 6 || myDate.length == 7 || myDate.length == 8 || myDate.length == 9) && myDate.split('/').length == 3) {
            var mm = myDate.split('/')[0];
            var dd = myDate.split('/')[1];
            var yy = myDate.split('/')[2];

            myDate = ('00' + mm).substr(mm.length, 2) + '/' + ('00' + dd).substr(dd.length, 2) + '/' + yy;
        }

        myDate = myDate.replace(/[^0-9-/]/gi, '');

        if (myDate.length == 8 && myDate.indexOf('/') == -1) {
            fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/" + myDate.substr(4, 4);
        } else if (myDate.length == 8 && myDate.indexOf('/') >= 1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(6, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 6) + "19" + myDate.substr(6, 2);
            } else {
                fmtDate = myDate.substr(0, 6) + "20" + myDate.substr(6, 2);
            }
        } else if (myDate.length == 6 && myDate.indexOf('/') == -1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(4, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/19" + myDate.substr(4, 2);
            } else {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/20" + myDate.substr(4, 2);
            }
        } else {
            fmtDate = myDate;
        }

        return fmtDate;
    }

    async getPatients(init = false) {
        this.props.showOrHideLoading(true);

        const enterKeyPressed = this.resetPageNumber;//this.state.enterKeyPressed;
        let skipNo = enterKeyPressed ? 0 : this.state.skip;
        let takeNo = enterKeyPressed ? 10 : this.state.take;

        const isPaging = this.state.isPaging;
        if (isPaging) {
            skipNo = this.state.skip;
            takeNo = this.state.take;

            this.setState({ pagingLoaded: true });
        }

        let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetPatientLookupView";
        //
        var access_token = localStorage.getItem('token');
        var params = {
            skip: skipNo,
            take: takeNo,
            accessToken: access_token
        };

        var hasFilter = false;
        var thjs = this;
        var _filter = {};
        if (this.state.filter !== null) {
            _filter = this.state.filter;
            this.state.filter.filters.map((f, i) => {
                if (f["field"] === "dob" || f["field"] === "combinedLastContactDate") {
                    let dateVal = f["value"];
                    if (dateVal) {
                        dateVal = thjs.User_Defined_Date_Format(dateVal.replace(/[^0-9-/]/gi, ''));
                    }

                    params[f["field"]] = moment(new Date(dateVal)).format("YYYY-MM-DD");

                    //#Fixing #PR00427 - Search boxes for last name, first name should do "starts with" search
                } else if (f["field"] === "lastName" || f["field"] === "firstName") {
                    params[f["field"]] = (f["value"] + "%");
                } else {
                    params[f["field"]] = ("%" + f["value"] + "%");
                }

                hasFilter = true;
            });
        }

        // adding sort options
        var hasSorter = false;
        var _sorter = [];
        if (this.state.sort !== null && this.state.sort.length > 0) {
            _sorter = this.state.sort;
            this.state.sort.map((s, i) => {
                params["sortby"] = s["field"];
                params["orderby"] = s["dir"];
            });
            hasSorter = true;
        }

        if (!hasSorter && !hasFilter && !init && !this.resetPageNumber && !isPaging) {
            this.props.showOrHideLoading(false);
            this.props.setPageLoaded(true);
            localStorage.setItem("Searching", null);
            return false;
        }

        //
        if (params && params["dob"] == "Invalid date") {
            this.props.showOrHideLoading(false);
            this.props.setPageLoaded(true);
            localStorage.setItem("Searching", null);
            this.setState({ isShowValidationError: true, errorMsg: "Dob: Invalid date" });
            return false;
        }

        var _this = this;
        let data = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                
                var inValidMsg = error.response && error.response.data && error.response.data.ErrorMessage ? error.response.data.ErrorMessage : null;
                if (inValidMsg) {
                    _this.setState({ isShowValidationError: true, errorMsg: inValidMsg });
                }                
                return false;
            });

        var pItems = data && data.data ? data.data.patientLookupViewList : null;
        var newAccessToken = data && data.data ? data.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        var _patients = [];
        if (pItems !== null) {
            await pItems.map((prm, index) => {
                Object.keys(prm).map((t) => {
                    if (t === "dob") {
                        //Filter by Date Only: https://docs.telerik.com/kendo-ui/knowledge-base/filter-by-date
                        prm[t] = new Date(prm[t]);
                    } /*else if (t !== "firstName" && t !== "lastName" && t !== "middleName" && ("" + prm[t]).trim().indexOf(" ") != -1) {
                        //prm[t] = ("" + prm[t]).trim().replace(/ /g, "<br/>");
                    }*/
                });
                _patients[index] = prm;
            });

            await this.setState({
                patients: _patients,
                total: data && data.data ? data.data.total : 0,
                filter: _filter,
                sort: _sorter,
                enterKeyPressed: false,
                pagingLoaded: true,
                //#PR00390
                skip: skipNo,
                take: takeNo
            });

            var _pa = this;
            if (this.props.pageLoaded) {
                setTimeout(function () {
                    _pa.props.showOrHideLoading(false);
                    localStorage.setItem("Searching", null);
                    _pa.props.setPageLoaded(true);
                    return false;
                }, 3000);
            }
        } else {
            await this.setState({
                patients: _patients,
                total: data && data.data ? data.data.total : 0,
                filter: _filter,
                sort: _sorter,
                enterKeyPressed: false,
                pagingLoaded: true,
                //#PR00390
                skip: skipNo,
                take: takeNo
            });

            var _pa = this;
            setTimeout(function () {
                _pa.props.showOrHideLoading(false);
                localStorage.setItem("Searching", null);
                _pa.props.setPageLoaded(true);
                return false;
            }, 1000);
        }

        this.props.showOrHideLoading(false);
        this.props.setPageLoaded(true);

        return false;
    }

    componentDidUpdate(prevProps) {
        console.log('[componentDidUpdate] Drawing PatientAbstract...');

        if (this.props.tabItems && this.props.tabItems.length == 0
            && (this.props.authRedirectPath === "/patient-abstract" || this.props.authRedirectPath === "/rapid-abstract")) {
            //
            if (this.props.authRedirectPath === "/rapid-abstract") {
                this.props.setRapidAbstract(true);
            } else {
                this.props.setRapidAbstract(false);
            }

            var pa = this;
            dialogTypes.dialogDisplays.map((dialog, index) => {
                if (index === 0) pa.props.setEnabledStatus(dialog);
                else if (index === 1) {
                    if (pa.props.isPatientSelected) {
                        pa.props.setEnabledStatus(dialog);
                    } else {
                        pa.props.setEnabledStatus(dialog, false);
                    }
                } else {
                    if (pa.props.isMedicalRecordSelected) {
                        pa.props.setEnabledStatus(dialog);
                    } else {
                        pa.props.setEnabledStatus(dialog, false);
                    }
                }
            });
        }

        if (
            ((!this.props.pageLoaded || this.state.patients == null) && this.state.enterKeyPressed)
            || (!this.props.pageLoaded && this.state.sort !== null && this.state.sort.length > 0)
            || (this.state.isPaging && !this.state.pagingLoaded)
        ) {
            var searching = localStorage.getItem("Searching");
            if (searching == "true") {
                return false;
            } else {
                localStorage.setItem("Searching", "true");
            }

            var pa = this;
            setTimeout(function () {
                pa.getPatients();
            }, 10);
        }
    }

    selectPatient = event => {
        this.props.addTabItems(dialogTypes.PATIENT);
        this.props.setEnabledStatus(dialogTypes.PATIENT);//#ADD - 2021/02/19

        this.props.selectedPatientOnGrid(event.dataItem);
        this.props.selectPatient(event.dataItem.pid, false);
        this.props.setDialog(dialogTypes.PATIENT);

        // Fixing issue of #PR00114 --START
        // Disabled all tabs in maintDialogs.js
        var pa = this;
        dialogTypes.dialogDisplays.map((dialog, index) => {
            if (dialog !== dialogTypes.LOOKUP && dialog !== dialogTypes.PATIENT) {
                pa.props.setEnabledStatus(dialog, false);
            }
        });
        //--END

        this.props.setParentWindowVisible(true);

        // @TODO: Tabs component...
        this.props.setTab(dialogTypes.PATIENT);
        this.props.routingPage(dialogTypes.PATIENT);

        document.getElementById('inquiryModeNotification') ? document.getElementById('inquiryModeNotification').setAttribute("class", "") : null;
    }

    addNewPatient = event => {
        this.props.addTabItems(dialogTypes.PATIENT);
        this.props.setEnabledStatus(dialogTypes.PATIENT);//#ADD - 2021/02/19

        this.props.selectPatient(0, true);
        this.props.setDialog(dialogTypes.PATIENT);
        this.props.setTab(dialogTypes.PATIENT);

        // Fixing issue of #PR00114 --START
        // Disabled all tabs in maintDialogs.js
        var pa = this;
        dialogTypes.dialogDisplays.map((dialog, index) => {
            if (dialog !== dialogTypes.LOOKUP && dialog !== dialogTypes.PATIENT) {
                pa.props.setEnabledStatus(dialog, false);
            }
        });
        //--END

        this.props.setParentWindowVisible(true);

        // @TODO: Tabs component...
        this.props.routingPage(dialogTypes.PATIENT);
    }

    closeWindow = () => {
        this.props.setParentWindowVisible(false);
        // @TODO: Tabs component...
        this.props.routingPage(dialogTypes.LOOKUP);
        this.props.addTabItems(dialogTypes.LOOKUP);
    }

    setDialog = (dialog) => {
        this.props.setDialog(dialog);
        this.props.setTab(dialog);
        // @TODO: Tabs component...
        this.props.routingPage(dialog);
        this.props.addTabItems(dialog);

        this.props.setParentWindowVisible(true);

        // Must edit these process at 2 other places: GotoMaintDialog and Footer
        if (dialog === "Staging")
            this.props.selectStagingRecord(this.props.selectedPrimarySummary);
        else if (dialog === "Treatment")
            this.props.selectTreatmentRecord(this.props.selectedPrimarySummary);
        else if (dialog === "Outcomes")
            this.props.selectOutcomesRecord(this.props.selectedPrimarySummary);
        else if (dialog === "CPM")
            this.props.selectCocRecord(this.props.selectedPrimarySummary);
        else if (dialog === "BPM")
            this.props.selectNapbcRecord(this.props.selectedPrimarySummary);
        else if (dialog === "RPM")
            this.props.selectRectalProgramRecord(this.props.selectedPrimarySummary);
        else if (dialog === "Case Admin")
            this.props.selectCaseAdminRecord(this.props.selectedPrimarySummary, this.props.username);
        else if (dialog === "Research")
            this.props.selectResearchRecord(this.props.selectedPrimarySummary);
        else if (dialog === "User Defined")
            this.props.selectUserDefinedRecord(this.props.selectedPrimarySummary);
        else if (dialog === "Retired")
            this.props.selectRetiredRecord(this.props.selectedPrimarySummary);
    }

    toTitleCase = (string) => {
        let title = string[0];
        if (title === "Treatment") {
            title += " Summary";
        } else {
            title += " Maintenance";
        }
        return title;
    }

    closeChildWindow = () => {
        this.props.setChildDialogVisible(false);
    }

    pageChange = (event, isFilter) => {
        //
        if (isFilter === "sorting") {
            let _sorter = event.sort;

            this.setState({
                sort: _sorter,
                enterKeyPressed: false
            });
            this.props.setPageLoaded(false);
            this.resetPageNumber = false;
            localStorage.setItem("Searching", null);
            return false;
        }
        //

        let _filter = {
            logic: "",
            filters: []
        };
        let enterKeyPressed = this.state.enterKeyPressed;
        if (event && event.filter) {
            _filter = event.filter;

            let allowSearch = event.nativeEvent.target.value != "";

            this.setState({
                filter: _filter,
                enterKeyPressed: allowSearch
            });
            this.resetPageNumber = true;
        } else {
            // click a page number on paging area
            _filter = event.target.props.filter && !isFilter ? event.target.props.filter : {
                logic: "",
                filters: []
            };

            let allowSearch = event.nativeEvent.target.value != "";

            this.setState({
                skip: event.page ? event.page.skip : this.state.skip,
                take: event.page ? event.page.take : this.state.take,
                filter: _filter,
                enterKeyPressed: allowSearch
            });
            enterKeyPressed = allowSearch;

            if (event.page) {
                this.resetPageNumber = false;
                this.setState({ isPaging: true, pagingLoaded: false });
            } else {
                this.resetPageNumber = true;
            }
        }
        this.props.setPageLoaded(!enterKeyPressed);
        localStorage.setItem("Searching", null);
    }

    searchByFilter = () => {
        this.resetPageNumber = true;
        this.setState({ enterKeyPressed: true });
        this.props.setPageLoaded(false);
        localStorage.setItem("Searching", null);
    }

    resetFilter = () => {
        let _filter = {
            logic: "",
            filters: []
        };
        this.setState({
            skip: 0,
            take: 10,
            filter: _filter,
            enterKeyPressed: true,
            sort: [] // Also clear all sorter
        });
        this.resetPageNumber = true;
        this.props.setPageLoaded(false);
        localStorage.setItem("Searching", null);
    }

    //------------------------------------------------------------------------
    //https://www.telerik.com/kendo-react-ui/components/grid/accessibility/
    handleRowClick = (props) => {
        const data = this.state.patients;
        const newData = data.map((item) => ({
            ...item,
            selected: item.id === props.dataItem.id,
        }));
        this.setState({ patients: newData });
    };

    handleNavigationAction = (event) => {
        const { focusElement } = event;
        const data = this.state.patients;

        const rowId = focusElement.parentElement.getAttribute("data-id");

        if (rowId) {
            const newData = data.map((item) => ({
                ...item,
                selected: String(item.id) === rowId,
            }));
            this.setState({ patients: newData });
        }
    };

    rowRender = (trElement, rowProps) => {
        const trProps = {
            ...trElement.props,
            ["data-id"]: rowProps.dataItem.id,
        };
        return React.cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    };

    handleKeyDownAction = (props) => {
        var event = props.nativeEvent;
        if (event.key == "Enter") {
            event.target.click();
        }
    }
    //------------------------------------------------------------------------

    render() {
        let loading = (
            this.props.isPatientSaving ? <div className="loader"></div> : ""
        );

        let validationError;
        {
            validationError = this.state.isShowValidationError ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Validation Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideValidationError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">{this.state.errorMsg}</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideValidationError(e, this) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        return (
            <div className="mainPage">
                <div className="parentWindow">
                    <React.Fragment>
                        {loading}
                    </React.Fragment>
                    <React.Fragment>
                        {validationError}
                    </React.Fragment>

                    <div style={{ display: "none" }}>Patient Abstract</div>
                    <div className="patientAbstractGridView" id="patientAbstractGridView">
                        <Grid
                            style={{ height: '600px', width: '1600px' }}
                            data={this.state.patients != undefined && this.state.patients.length !== 0 ? this.state.patients : null}
                            filter={this.state.filter}
                            filterable
                            filterOperators={this.state.filterOperators}
                            onFilterChange={e => { this.pageChange(e, true) }}
                            onRowClick={this.selectPatient}
                            pageable
                            onPageChange={e => { this.pageChange(e, false) }}
                            scrollable="scrollable"
                            sortable
                            sort={this.state.sort}
                            onSortChange={e => { this.pageChange(e, "sorting") }}
                            skip={this.state.skip}
                            take={this.state.take}
                            total={this.state.total}

                            selectedField="selected"
                            rowRender={this.rowRender}
                            navigatable={true}
                            onNavigationAction={this.handleNavigationAction}

                            onKeyDown={this.handleKeyDownAction}
                        >
                            <GridColumn field="abstSunaFlag" filterable={false} title="S/A" width="24px" />
                            <GridColumn field="socSecNbr" filter="text" title="SSN" width="75px" />
                            <GridColumn field="medRecNbr" filter="text" title="MRN" width="105px" />
                            <GridColumn field="lastName" filter="text" title="Last Name" width="110px" />
                            <GridColumn field="firstName" filter="text" title="First Name" width="110px" />
                            <GridColumn field="middleName" filterable={false} title="Middle Name" width="85px" headerAttributes={{ rowspan: "2", class: "breakWord20" }} />
                            <Column field="dob" filter="text" title="DOB" width="84px" cell={KendoGridDateCell} format="{MM/dd/yyyy}" />
                            <GridColumn field="siteCode" filter="text" title="Site" width="34px" />
                            <GridColumn field="seqPrim" filterable={false} title="Seq" width="34px" />
                            <GridColumn field="topoCode" filterable={false} title="Topo" width="39px" />
                            <GridColumn field="histology" filterable={false} title="Histology" width="62px" />
                            <GridColumn field="laterality" filterable={false} title="Laterality" width="64px" />
                            <GridColumn field="diagnosisDate" filterable={false} title="Dx Date" width="80px" template="<pre>#=diagnosisDate#</pre>" />
                            <GridColumn field="hosp" filter="text" title="Hosp" width="36px" />
                            <GridColumn field="class" filterable={false} title="Class" width="36px" />
                            <GridColumn field="combinedLastPtStatus" filterable={false} title="Patient Status" width="88px" headerAttributes={{ rowspan: "2", class: "breakWord20" }} />
                            <Column field="combinedLastContactDate" filterable={false} title="Last Contact" width="80px" cell={KendoGridDateCell} />
                            <GridColumn field="accNbr" filter="text" title="Acc Nbr" width="100px" />
                        </Grid>

                        <div className="addNewPatientBtn_2">
                            <button className="ErsGrBlueButton" onClick={this.addNewPatient} style={{ display: "" }}>New Patient</button>
                            <button className="ErsGrBlueButton" onClick={this.resetFilter} style={{ display: "" }}>Clear Results</button>
                        </div>
                        <div className="addNewPatientBtn_3">
                            <button className="ErsGrBlueButton" onClick={this.searchByFilter} style={{ display: "" }} id="btnSearchPA">Search</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentDialog: state.patientAbstract.currentDialog,
        childDialog: state.patientAbstract.childDialog,
        isChildDialogVisible: state.patientAbstract.isChildDialogVisible,
        isParentWindowVisible: state.patientAbstract.isParentWindowVisible,
        isPatientSelected: state.patientAbstract.selectedPatient !== null,
        isMedicalRecordSelected: state.patientAbstract.selectedMedicalRecord !== null,
        isAuthenticated: state.auth.token !== null,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
        pageLoaded: state.patientAbstract.pageLoaded,
        tabItems: state.patientAbstract.tabItems,
        authRedirectPath: state.auth.authRedirectPath,
        //
        clientgroup: state.auth.clientgroup,
        selectedPatient: state.patientAbstract.selectedPatient,
        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        isPatientSaving: state.patientAbstract.isPatientSaving,
        username: state.auth.userId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectPatient: (patientId, isAddNew) => dispatch(actionCreators.selectPatient(patientId, isAddNew)),
        selectedPatientOnGrid: (patient) => dispatch(actionCreators.selectedPatientOnGrid(patient)),
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
        // add new Patient
        addNewPatient: (patientId, isAddNew) => dispatch(actionCreators.selectPatient(patientId, isAddNew)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        selectStagingRecord: (medial) => dispatch(actionCreators.selectStagingRecord(medial)),
        selectTreatmentRecord: (medial) => dispatch(actionCreators.selectTreatmentRecord(medial)),
        selectOutcomesRecord: (medial) => dispatch(actionCreators.selectOutcomesRecord(medial)),
        selectCocRecord: (medial) => dispatch(actionCreators.selectCocRecord(medial)),
        selectNapbcRecord: (medial) => dispatch(actionCreators.selectNapbcRecord(medial)),
        selectRectalProgramRecord: (medial) => dispatch(actionCreators.selectRectalProgramRecord(medial)),
        selectCaseAdminRecord: (medial, uid) => dispatch(actionCreators.selectCaseAdminRecord(medial, uid)),
        selectResearchRecord: (medial) => dispatch(actionCreators.selectResearchRecord(medial)),
        selectUserDefinedRecord: (medial) => dispatch(actionCreators.selectUserDefinedRecord(medial)),
        selectRetiredRecord: (medial) => dispatch(actionCreators.selectRetiredRecord(medial)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        closeOtherTabItems: () => dispatch(actionCreators.closeOtherTabItems()),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
        setRapidAbstract: (value) => dispatch(actionCreators.setRapidAbstract(value)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        onLogout: (uid, cg, sval, msg) => dispatch(actions.logout(uid, cg, sval, msg)),
        resetAllStoringOfUser: () => dispatch(actionCreators.resetAllStoringOfUser()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientAbstract);
