/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import Draggable from 'react-draggable';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import axios from "axios";
import { actionCreators } from "../../../store/PatientAbstract";
import ConfigData from "../../../store/config.json";
import * as actions from '../../../store/actions/index';
import UserSecurity from "../SecurityMaintenance/UserSecurity/UserSecurity"
import "../SystemAdmin.css";

class UserSecurityLookup extends Component {
    state = {
        users: null,
        userList: null,
        isLoading: false,
        filter: {
            logic: "",
            filters: []
        },
        filterOperators: {
            'text': [
                { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
                //{ text: 'grid.filterEndsWithOperator', operator: 'endswith' },
                //{ text: 'grid.filterContainsOperator', operator: 'contains' }
            ],
        },
        pageable: {
            pageSizes: [15, 20, 30, 40, 50, 100, 1000]
        },
        sort: [],
        total: 0,
        skip: 0,
        take: 15,
        isDialogVisible: false,
        isChildDialogVisible: false,
        errorMsg: '',

        enterKeyPressed: false,
        shiftKeyPressed: false,
        tabKeyPressed: false,
        userClientGroup: null,
        userSelected: false,

        loaded: false,
    }
    resetPageNumber = false;

    addEventListener = () => {
        var usl = this;
        var currentLookupModal = document.querySelector('#userSecurityLookupGridView');
        currentLookupModal.addEventListener('keydown', function (e) {
            if (e.keyCode == 40) {// down arrow
                var filterInputFocusing = e.target.getAttribute("class") === "k-textbox" ? true : false;
                var trs = currentLookupModal.getElementsByClassName("k-master-row");
                if (filterInputFocusing && trs && trs[0] && trs[0].firstChild) {
                    trs[0].firstChild.setAttribute('tabindex', '0');
                    trs[0].firstChild.focus();
    
                    setTimeout(function () {
                        trs[0].scrollIntoView();
                        trs[0].scrollTop = 0;
                    }, 100);
    
                    let currentClasses = trs[0].getAttribute("class");
                    if (currentClasses.indexOf('k-state-selected') == -1)
                        trs[0].setAttribute("class", currentClasses + " k-state-selected");
                }
    
                var itemInRowFocusing = e.target.getAttribute("class") === "" ? true : false;
                if (itemInRowFocusing && e.target.parentNode.getAttribute("data-grid-row-index") === "0") {
                    let currentClasses = e.target.parentNode.getAttribute("class");
                    if (currentClasses.indexOf('k-state-selected') != -1)
                        e.target.parentNode.setAttribute("class", currentClasses.replace("k-state-selected", ""));
                }
            } else if (e.key === "Tab" || (e.key === "Shift" && e.keyCode == 9) || (e.key === "Tab" && e.shiftKey)) {// shift + tab
                if (e.key === "Tab" && e.shiftKey) {
                    if (e.target.nodeName === "BUTTON") {
                        if (e.target.nextSibling) {
                            setTimeout(function () {
                                currentLookupModal.querySelector('input.k-textbox:last-child') ? currentLookupModal.querySelector('input.k-textbox:last-child').focus() : "";
                            }, 0);
                        }
                        return false;
                    } else if (e.target.nodeName === "TH") {//Don't allow press shift+tab on these items
                        e.preventDefault();
                        return false;
                    } else if (e.target.nodeName === "INPUT" && e.target.getAttribute('class') === "k-textbox") {
                        if (!e.target.parentNode.parentNode.parentNode.previousSibling || e.target.parentNode.parentNode.parentNode.previousSibling === null) {
                            // set focus into Exit button
                            setTimeout(function () {
                                document.getElementById('btnExitLookupRegistrar') ? document.getElementById('btnExitLookupRegistrar').focus() : "";
                            }, 0);
                        }
                        return false;
                    }
                } else {
                    if (e.target.nodeName === "BUTTON") {
                        if (!e.target.nextSibling) {
                            setTimeout(function () {
                                currentLookupModal.querySelectorAll('input')[0] ? currentLookupModal.querySelectorAll('input')[0].focus() : "";
                            }, 0);
                        }
                    }
                }
            } else if (e.keyCode == 38) {// up arrow
                var pagingLinkFocusing = e.target.getAttribute("class") ? (e.target.getAttribute("class").indexOf("k-link") !== -1 ? true : false) : false;
                let lastItemOnGrid = currentLookupModal.querySelector(".k-grid-table") &&
                    currentLookupModal.querySelector(".k-grid-table").lastChild &&
                    currentLookupModal.querySelector(".k-grid-table").lastChild.childElementCount ? currentLookupModal.querySelector(".k-grid-table").lastChild.childElementCount : 10;
                lastItemOnGrid = lastItemOnGrid - 1;

                var trs = currentLookupModal.getElementsByClassName("k-master-row");
                if (pagingLinkFocusing) {
                    for (var len = 0; len < trs.length; len++) {
                        trs[len] ? trs[len].setAttribute("class", len % 2 == 0 ? "k-master-row" : "k-master-row k-alt") : "";
                    }

                    if (trs && trs[lastItemOnGrid] && trs[lastItemOnGrid].firstChild) {
                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].firstChild.setAttribute('tabindex', '0') : "";
                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].firstChild.focus() : "";

                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].setAttribute("class", "k-master-row k-alt k-state-selected") : "";

                        setTimeout(function () {
                            trs[lastItemOnGrid] ? trs[lastItemOnGrid].scrollIntoView() : "";
                            trs[lastItemOnGrid] ? trs[lastItemOnGrid].scrollTop = 10000 : "";//scroll to bottom of grid
                        }, 100);
                    }
                } else if (e.target.parentNode.getAttribute("data-grid-row-index") === "0") {
                    trs[0] ? trs[0].setAttribute("class", "k-master-row") : "";
                } else {
                    trs[lastItemOnGrid] ? trs[lastItemOnGrid].setAttribute("class", "k-master-row k-alt") : "";
                }
                
            } else if (e.keyCode == 13 && e.target.getAttribute("class") === "k-textbox" && e.target.value != "") {//enter
                !usl.enterKeyPressed ? usl.setState({
                    enterKeyPressed: true,
                    focusedItem: e.target,
                    filterIndexNo: e.target.getAttribute("data-index")
                }) : "";
                usl.props.setPageLoaded(false);
                e.preventDefault();
                return false;
            } else if (e.keyCode == 13 && e.target.getAttribute("class") === "k-textbox" && e.target.value == "") {//enter
                usl.setState({
                    enterKeyPressed: false,
                });
                e.preventDefault();
                return false;
            } else {
                usl.setState({
                    enterKeyPressed: false,
                });
            }
        });
    }

    //
    init = () => {
        document.getElementsByClassName("k-textbox")[0] ? document.getElementsByClassName("k-textbox")[0].focus() : "";
        setTimeout(function () {
            document.getElementById("usr_sec_lop_btn") ? document.getElementById("usr_sec_lop_btn").click() : null;
            document.getElementsByClassName("k-textbox")[0] ? document.getElementsByClassName("k-textbox")[0].focus() : "";
        }, 1000);
    }

    componentDidMount = () => {
        this.props.setPatientMenu(true);

        /*
        // Add all tabs in maintDialogs.js
        dialogTypes.sysAdminTabsDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog);
            this.props.setEnabledStatus(dialog);
        });
        */
        
        this.setState({ isLoading: true, userSelected: false, loading: false });
        this.closeAddNewDialog();
        this.addEventListener();
        this.props.setPageLoaded(false);

        if (this.state.userClientGroup == null && this.props.userId) {
            this.getUserClientGroup();
        }

        this.closeAddNewDialog();
        this.props.setChildrenOpening(true);
        this.props.setInitialPageVisible(true);

        this.init();
    }

    async getUserClientGroup() {
        let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetUserClientGroup";
        let params = {email: this.props.userId, accessToken: localStorage.getItem('token')};

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        let newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        let cligrp = res && res.data ? res.data.result : "ers";

        this.setState({
            userClientGroup: cligrp
        });

        this.getUserLogins(cligrp);

        return false;
    }

    async getUserLogins(cligrp) {
        this.props.setPageLoaded(true);

        let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetUserLookupView";

        var skipNo = this.state.skip;
        var takeNo = this.state.take;

        var access_token = localStorage.getItem('token');
        var params = {
            cliGrp: cligrp,
            skip: skipNo,
            take: takeNo,
            accessToken: access_token
        };

        var _filter = {};
        if (this.state.filter !== null) {
            _filter = this.state.filter;
            this.state.filter.filters.map((f, i) => {
                params[f["field"]] = "%" + f["value"] + "%";
            });
        }

        // adding sort options
        var _sorter = [];
        if (this.state.sort !== null && this.state.sort.length > 0) {
            _sorter = this.state.sort;
            this.state.sort.map((s, i) => {
                params["sortby"] = s["field"];
                params["orderby"] = s["dir"];
            });
        }

        var _this = this;
        let res = await axios
            .post(url, params)
            .then(function (response) {
                //update new accessToken again
                var newAccessToken = response && response.data ? response.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                return response;
            })
            .catch(function (error) {
                console.log(error);
                _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);

                let btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        console.clear();
        console.log(res && res.data ? res.data.userList : []);

        var userList = res && res.data ? res.data.userList : [];
        var total = res && res.data ? res.data.total : 0;

        _this.setState({
            users: userList,
            userList: userList,
            total: total,
            filter: _filter,
            sort: _sorter,
            enterKeyPressed: false,
            skip: skipNo,
            take: takeNo,
            loaded: true
        });
        _this.forceUpdate();

        _this.props.setPageLoaded(true);

        setTimeout(function () {
            _this.props.showOrHideLoading(false);
            return false;
        }, 1500);

        return false;
    }

    componentDidUpdate(prevProps) {
        if (this.state.userClientGroup == null && this.props.userId) {
            this.getUserClientGroup();
        }

        if (this.state.userClientGroup && !this.props.pageLoaded) {
            this.getUserLogins(this.state.userClientGroup);
        }

        if (this.state.userList && this.state.users && this.state.userList.length != this.state.users.length) {
            this.setState({ users: this.state.userList });
        }
    }

    selectUser = event => {
        try {
            event.dataItem.email ? event.preventDefault() : "";
        } catch (ex) { }
        
        this.props.selectedUserOnGrid(event.dataItem);
        this.props.selectUserSecurityRecord(event.dataItem.email)
        this.setState({isDialogVisible: true, userSelected: true});

        return false;
    }

    closeAddNewDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.props.closeAddNewDialog();
        this.props.setChildrenOpening(false);
        return false;
    }

    pageChange = (event, isFilter) => {
        //
        if (isFilter === "sorting") {
            let _sorter = event.sort;

            this.setState({
                sort: _sorter,
                enterKeyPressed: false
            });
            this.props.setPageLoaded(false);
            this.resetPageNumber = false;
            return false;
        }
        //

        let _filter = {
            logic: "",
            filters: []
        };
        let enterKeyPressed = this.state.enterKeyPressed;
        if (event && event.filter) {
            _filter = event.filter;

            let allowSearch = event.nativeEvent.target.value != "";

            this.setState({
                filter: _filter,
                enterKeyPressed: allowSearch
            });
            this.resetPageNumber = true;
        } else {
            // click a page number on paging area
            _filter = event.target.props.filter && !isFilter ? event.target.props.filter : {
                logic: "",
                filters: []
            };

            let allowSearch = event.nativeEvent === undefined ? true : event.nativeEvent.target.value != "";

            this.setState({
                skip: event.page ? event.page.skip : this.state.skip,
                take: event.page ? event.page.take : this.state.take,
                filter: _filter,
                enterKeyPressed: allowSearch
            });
            enterKeyPressed = allowSearch;

            if (event.page) {
                this.resetPageNumber = false;
            } else {
                this.resetPageNumber = true;
            }
        }
        this.props.setPageLoaded(!enterKeyPressed);
    }

    searchByFilter = () => {
        this.resetPageNumber = true;
        this.setState({ enterKeyPressed: true });
        this.props.setPageLoaded(false);
    }

    resetFilter = () => {
        let _filter = {
            logic: "",//and
            filters: []
        };
        this.setState({
            userSelected: false,
            skip: 0,
            take: 15,
            filter: _filter,
            enterKeyPressed: true,
            sort: [] // Also clear all sorter
        });
        this.resetPageNumber = true;
        this.props.setPageLoaded(false);
    }

    //------------------------------------------------------------------------
    //https://www.telerik.com/kendo-react-ui/components/grid/accessibility/
    handleRowClick = (props) => {
        const data = this.state.users;
        const newData = data.map((item) => ({
            ...item,
            selected: item.userId === props.dataItem.userId,
        }));
        this.setState({ users: newData });
    };

    handleNavigationAction = (event) => {
        const { focusElement } = event;
        const data = this.state.users;

        const rowId = focusElement.parentElement.getAttribute("data-id");

        if (rowId) {
            const newData = data.map((item) => ({
                ...item,
                selected: String(item.userId) === rowId,
            }));
            this.setState({ users: newData });
        }
    };

    rowRender = (trElement, rowProps) => {
        const trProps = {
            ...trElement.props,
            ["data-id"]: rowProps.dataItem.userId,
        };
        return React.cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    };

    handleKeyDownAction = (props) => {
        var event = props.nativeEvent;
        if (event.key == "Enter") {
            event.target.click();
        }
    }
    //------------------------------------------------------------------------

    render() {
        let loading = (
             !this.props.pageLoaded ? <div className="col-sm-12 showLoadingOnTopPageCls">Working...</div> : ""
        );

        return (
            <div className="limitedInGridCls">
                <div className="mainPage">
                    {loading}
                    <div className="parentWindow" id="userSecurityLookup">
                        <div className="patientAbstractGridView" id="userSecurityLookupGridView">
                            <Grid
                                style={{ height: '600px'}}
                                data={this.state.users != undefined && this.state.users.length !== 0 ? this.state.users : null}
                                filter={this.state.filter}
                                filterable
                                filterOperators={this.state.filterOperators}
                                onFilterChange={e => { this.pageChange(e, true) }}
                                onRowClick={this.selectUser}
                                pageable={this.state.pageable}
                                onPageChange={e => { this.pageChange(e, false) }}
                                scrollable="scrollable"
                                sortable
                                sort={this.state.sort}
                                onSortChange={e => { this.pageChange(e, "sorting") }}
                                skip={this.state.skip}
                                take={this.state.take}
                                total={this.state.total}
                                selectedField="selected"
                                rowRender={this.rowRender}
                                navigatable={true}
                                onNavigationAction={this.handleNavigationAction}
                                onKeyDown={this.handleKeyDownAction}
                            >
                                <GridColumn field="userName" filter="text" title="User Name" />
                                <GridColumn field="email" filter="text" title="User ID" />
                                <GridColumn field="securityGroup" filter="text" title="Security Group" />
                                <GridColumn field="clientGroup" filter="text" title="Client Group" />
                            </Grid>

                            <fieldset className="bottomAreaButtonCls">
                                <legend></legend>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="A5CWLayout">
                                            <button id="usr_sec_lop_btn"
                                                className={!this.state.filter.filters.length ? "ErsGrBlueButton Screen_Button_Disabled" : "ErsGrBlueButton"}
                                                //disabled={!this.state.filter.filters.length ? true : false}
                                                onClick={this.resetFilter}>
                                                Clear Results
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                {
                    // this.state.userSelected ?
                    this.props.isAddNewDialogVisible ?
                        <div className="CRStar_Window" id="userSecurityLoad">
                            <div className="fake_popup_cls"></div>
                            <Draggable 
                                //id="UserSecurityLoad"
                                handle=".k-window-titlebar_notallow_move"
                                disabled={this.props.childrenOpening}
                                axis={this.props.childrenOpening ? "none" : "both"}>

                                <div className="dragableSmallWindowCls">
                                    <div className="k-window-titlebar k-dialog-titlebar k-header">
                                        <div className="k-window-title k-dialog-title">User Security</div>
                                        <div className="k-window-actions k-dialog-actions hidden">
                                            <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                <span className="k-icon k-i-x hidden" onClick={(e) => { this.closeAddNewDialog(e) }}></span>
                                            </a>
                                        </div>
                                    </div>
                                    <UserSecurity closeWindow={this.closeAddNewDialog} />
                                </div>
                            </Draggable>
                        </div>
                    : null
                }

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAddNewDialogVisible: state.patientAbstract.isAddNewDialogVisible,

        currentDialog: state.patientAbstract.currentDialog,
        childDialog: state.patientAbstract.childDialog,
        isChildDialogVisible: state.patientAbstract.isChildDialogVisible,
        isParentWindowVisible: state.patientAbstract.isParentWindowVisible,
        isPatientSelected: state.patientAbstract.selectedPatient !== null,
        isAuthenticated: state.auth.token !== null,
        pageLoaded: state.patientAbstract.pageLoaded,
        //
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        childrenOpening: state.patientAbstract.childrenOpening,
        isPatientSaving: state.patientAbstract.isPatientSaving,
        waitingFor: state.patientAbstract.waitingFor,
        initialPageVisible: state.patientAbstract.initialPageVisible,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectUserSecurityRecord: (email) => dispatch(actionCreators.selectUserSecurityRecord(email)),
        selectedUserOnGrid: (userInfo) => dispatch(actionCreators.selectedUserOnGrid(userInfo)),

        closeAddNewDialog: () => dispatch(actionCreators.closeAddNewDialog()),

        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        //setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),

        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),

        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),

        //
        //setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),

        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSecurityLookup);
