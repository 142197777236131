/* eslint-disable */
import React from "react";
import "../../../assets/css/default-ocean-blue.css";

import { EditorTools, EditorToolsSettings } from "@progress/kendo-react-editor";

const fontNameToolSettings = {
    ...EditorToolsSettings.fontName,
    defaultItem: {
        text: 'Courier New',
        value: 'Courier New',
    },
    items: [
        /*{
            text: 'Calibri',
            value: 'Calibri',
            style: { font: "Calibri" }
        },*/
        {
            text: 'Arial',
            value: 'Arial',
            style: { font: "Arial" }
        },
        {
            text: 'Courier New',
            value: 'Courier New',
            style: { font: "Courier New" }
        },
        {
            text: "Georgia",
            value: "Georgia",
            style: { font: "Georgia" }
        },
        {
            text: "Impact",
            value: "Impact",
            style: { font: "Impact", fontWeight: "bolder" }
        },
        {
            text: 'Tahoma',
            value: 'Tahoma',
            style: { font: "Tahoma" }
        },
        {
            text: 'Times New Roman',
            value: 'Times New Roman',
            style: { font: "Times New Roman" }
        },
        {
            text: "Trebuchet MS",
            value: "Trebuchet MS",
            style: { font: "Trebuchet MS" }
        },
        {
            text: "Verdana",
            value: "Verdana",
            style: { font: "Verdana" }
        },
    ],
};

const CustomFontName = EditorTools.createStyleDropDownList(fontNameToolSettings);

const FontName = (props) => <CustomFontName {...props} />;

export default FontName;