/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavLink } from "reactstrap";
import { actionCreators } from "../../../../store/PatientAbstract";
import Footer from "../Shared/Footer";
import Input from "../../../UI/Input";
import "../PatientMaintenance/PatientMaintenance.css";
import "./RectalProgramMaintenance.css";
import "../DiagnosisMaintenance/DiagnosisMaintenance.css";
import moment from "moment";
import TextForm from "../DiagnosisMaintenance/TextForm";
import Help from "../../../UI/Help";
import axios from "../../../../axios-instance";
import classnames from "classnames";
import Draggable from 'react-draggable';
import * as TextPopup from "../TitleOfTextPopup";

class RectalProgramMaintenance extends Component {

    // At Breast Program page, before initial page -> run this store procedure to get data:
    // Exec Abstract_Select 'value of CID'

    state = {
        activeTab: "1",
        rAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'rectalProgramForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty rectalProgramForm back to what it was on load
        initialRectalProgramForm: null,
        rectalProgramForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        mode: "1",//only for test
        fieldInFocus: "rpmStd51Field1_no",
        isHelpVisible: false,
        isDialogVisible: true,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,
        focusingField: null,
    }

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: true });
    }

    closeWarningDialog = () => {
        this.setState({ isDialogVisible: false });
        return false;
    }

    showTextDialog = () => {
        this.setState({ isShowTextVisible: true });
    }

    closeTextDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowTextVisible: false });
        this.props.closeTextDialog(false);

        return false;
    }

    componentDidMount() {
        if (!this.state.rectalProgramForm && this.props.selectedRectalProgramRecord) {
            this.setFormData();
        }
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.rectalProgramForm && this.props.selectedRectalProgramRecord)
            || (prevProps.selectedRectalProgramRecord && prevProps.selectedRectalProgramRecord !== this.props.selectedRectalProgramRecord)
            || (this.props.initialAbstractPages && this.props.selectedRectalProgramRecord)
        ) {
            this.setFormData();
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.rectalProgramForm && this.state.rAllItemFields
        ) {
            console.log("[RPM] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    rAllItemFields: { ...this.state.rAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    rectalProgramForm: { ...this.state.rectalProgramForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    rAllItemFields: { ...this.state.rAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    rectalProgramForm: { ...this.state.rectalProgramForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async getTableStructure() {
        //let url = this.props.clientgroup + "/GetTableStructure?table=Rectal_Program";
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "Rectal_Program", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    // Declare this function is only temporality
    ERSCs_get_schema_number = (/*site, hist, discriminator*/) => {
        return "15";
    }

    fnEnableAllSsf = (event) => {
        this.setState({ rectalProgramForm: { ...this.state.rectalProgramForm, [event.target.name]: "1" } });
    }

    setFormData = () => {
        this.setState({
            rectalProgramForm: {
                ...this.props.selectedRectalProgramRecord,
                ["rpmMriDate"]: (this.props.selectedRectalProgramRecord != null && this.props.selectedRectalProgramRecord.rpmMriDate !== null && this.props.selectedRectalProgramRecord.rpmMriDate !== "" ? moment(new Date(this.props.selectedRectalProgramRecord.rpmMriDate)).format("MM/DD/YYYY") : ""),
                ["rpmPetCtDate"]: (this.props.selectedRectalProgramRecord != null && this.props.selectedRectalProgramRecord.rpmPetCtDate !== null && this.props.selectedRectalProgramRecord.rpmPetCtDate !== "" ? moment(new Date(this.props.selectedRectalProgramRecord.rpmPetCtDate)).format("MM/DD/YYYY") : ""),
                ["rpmDateInitEval"]: (this.props.selectedRectalProgramRecord != null && this.props.selectedRectalProgramRecord.rpmDateInitEval !== null && this.props.selectedRectalProgramRecord.rpmDateInitEval !== "" ? moment(new Date(this.props.selectedRectalProgramRecord.rpmDateInitEval)).format("MM/DD/YYYY") : ""),
                ["rpmDateTxOutcomeDiscussed"]: (this.props.selectedRectalProgramRecord != null && this.props.selectedRectalProgramRecord.rpmDateTxOutcomeDiscussed !== null && this.props.selectedRectalProgramRecord.rpmDateTxOutcomeDiscussed !== "" ? moment(new Date(this.props.selectedRectalProgramRecord.rpmDateTxOutcomeDiscussed)).format("MM/DD/YYYY") : ""),
                ["rpmDateTxOutcomeProvided"]: (this.props.selectedRectalProgramRecord != null && this.props.selectedRectalProgramRecord.rpmDateTxOutcomeProvided !== null && this.props.selectedRectalProgramRecord.rpmDateTxOutcomeProvided !== "" ? moment(new Date(this.props.selectedRectalProgramRecord.rpmDateTxOutcomeProvided)).format("MM/DD/YYYY") : ""),
            },
            isFormDirty: this.props.isFormDirty
        });

        if (!this.state.rAllItemFields && this.props.selectedRectalProgramRecord) {
            let fields = {};
            Object.keys(this.props.selectedRectalProgramRecord).map((t) => {
                fields[t] = "";
            });

            this.setState({ rAllItemFields: fields, pageMaxlengthInput: fields });
        };

        this.props.setInitialAbstractPages(false);

        return false;
    }

    toggleTab = (event, tab) => {
        event.preventDefault();

        this.setState({ activeTab: tab });
        var _this = this;
        if (tab === "1") {
            setTimeout(function () {
                document.getElementById("rpmStd51Field1_no") ? document.getElementById("rpmStd51Field1_no").focus() : "";
            }, 0);
        } else if (tab === "2") {
            setTimeout(function () {
                document.getElementById("rpmConfDxDocumentedMr") ? document.getElementById("rpmConfDxDocumentedMr").focus() : "";
            }, 0);
        }

        return false;
    }

    saveRectalProgram = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        const formItems = this.state.rAllItemFields;
        Object.keys(formItems).map((t) => {
            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let rectalProgramFormData = this.state.rectalProgramForm;
            rectalProgramFormData.cid = this.props.selectedMedicalRecord.cid;

            this.props.saveRectalProgram(rectalProgramFormData);

            return false;
        }
    }

    saveTextDialog = (event) => {
        event.preventDefault();

        // TODO Form validation
        if (true) {
            let textDialogData = this.state.textDialogForm;
            this.props.saveTextDialog(textDialogData);
        }
    }

    extraAction = (index) => {
        switch (index) {
            case 1:
                alert(1);
                break;
            case 2:
                alert(2);
                break;
            default:
                break;
        }
    }

    resetRectalProgram = () => {
        this.setState({
            rectalProgramForm: { ...this.state.initialRectalProgramForm },
            isFormDirty: false,
        });
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    helpFocusingField = (field) => {
        this.setState({ fieldInFocus: field });
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = () => {
        this.setState({ isHelpVisible: false });
        return false;
    }

    setChartOption = (event) => {
        this.setState({
            rectalProgramForm: {
                ...this.state.rectalProgramForm,
                [event.target.name]: event.target.value
            },
            isFormDirty: true,
        });
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            this.props.checkValidInput(name, value, table, key, -1, flag);
        } else {
            this.setState({ rAllItemFields: { ...this.state.rAllItemFields, [event.target.name]: "" } });
        }

        let fName = event.target.name;
        if (fName === "rpmMriDate" || fName === "rpmPetCtDate" || fName === "rpmDateInitEval"
            || fName === "rpmDateTxOutcomeDiscussed" || fName === "rpmDateTxOutcomeProvided") {
            let thisValue = event.target.value;
            let thisDate = this.RPM_Date_Format(thisValue.replace(/[^0-9-/]/gi, ''));
            if (thisDate != thisValue) {
                this.setState({
                    rectalProgramForm: {
                        ...this.state.rectalProgramForm,
                        [fName]: moment(new Date(thisDate)).format("MM/DD/YYYY")
                    }
                });
            }
        }
    }
    // --END

    RPM_Date_Format(myDate) {
        //This code tries to fill in slashes and the two digit century when a user doesn't enter them
        var fmtDate = "";

        myDate = myDate.replace(/[-]/gi, '/');

        // adding rather
        if (myDate.length > 10) {
            myDate = myDate.substr(0, 10);
        }
        //

        if ((myDate.length == 6 || myDate.length == 7 || myDate.length == 8 || myDate.length == 9) && myDate.split('/').length == 3) {
            var mm = myDate.split('/')[0];
            var dd = myDate.split('/')[1];
            var yy = myDate.split('/')[2];

            myDate = ('00' + mm).substr(mm.length, 2) + '/' + ('00' + dd).substr(dd.length, 2) + '/' + yy;
        }

        myDate = myDate.replace(/[^0-9-/]/gi, '');

        if (myDate.length == 8 && myDate.indexOf('/') == -1) {
            fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/" + myDate.substr(4, 4);
        } else if (myDate.length == 8 && myDate.indexOf('/') >= 1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(6, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 6) + "19" + myDate.substr(6, 2);
            } else {
                fmtDate = myDate.substr(0, 6) + "20" + myDate.substr(6, 2);
            }
        } else if (myDate.length == 6 && myDate.indexOf('/') == -1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(4, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/19" + myDate.substr(4, 2);
            } else {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/20" + myDate.substr(4, 2);
            }
        } else {
            fmtDate = myDate;
        }

        return fmtDate;
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ rectalProgramForm: { ...this.state.rectalProgramForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({ rectalProgramForm: { ...this.state.rectalProgramForm, [event.target.name]: event.target.value }, isFormDirty: true });
        }
    }

    render() {
        const rectalProgramMaintenance = this.state.rectalProgramForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">
                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} focusingField={this.state.focusingField} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.saveRectalProgram} id="rectalProgramForm" className="DialogForm TabParentPage">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq Prim: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo">
                                    <button type="button" onClick={this.getHelp}><img src="https://crstar.ers-can.com/images/$sys_question.png.a5image"/></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {this.props.selectedPatient.dob != null && this.props.selectedPatient.dob !== "" ? moment(new Date(this.props.selectedPatient.dob)).format("MM/DD/YYYY") : ""}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {this.props.selectedPrimarySummary.diagnosisDate != null && this.props.selectedPrimarySummary.diagnosisDate !== "" ? moment(new Date(this.props.selectedPrimarySummary.diagnosisDate)).format("MM/DD/YYYY") : ""}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>

                        <div className="emptyLine"></div>

                        <div className="_row">
                            <div className="__frameInner">
                                {/* <div className="header">&nbsp;</div> */}
                                <Nav tabs>
                                    <NavLink className={classnames({ active: this.state.activeTab === "1" })} onClick={(event) => { this.toggleTab(event, "1") }} href="#" id="linkOfRPMChartAuditTab">
                                        RPM Chart Audit
                                    </NavLink>
                                    <NavLink className={classnames({ active: this.state.activeTab === "2" })} onClick={(event) => { this.toggleTab(event, "2") }} href="#" id="linkOfRPMServicesTab">
                                        Continuum of Care Services
                                    </NavLink>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <div className="_row" id="rpmTab1Row">
                                            <div className="emptyLine"></div>
                                            <div className="col-sm-6">
                                                <div>
                                                    <label className="stdHeader">No&nbsp;&nbsp;Yes&nbsp;&nbsp;N/A&nbsp;&nbsp;Unk</label>
                                                </div>
                                                <div>
                                                    <label className="stdID" id="rpmStd51">Std 5.1</label>
                                                    <label className="stdDesc" id="rpmStd51Desc">Review of Diagnostic Procedures</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="rpmStd51Lb1" >Biopsy Path Slides/Rpt Reviewed</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd51Field1_no" //* 4556 *
                                                        checked={this.state.rectalProgramForm.rpmBiopsyReviewed === "No"} onFocus={this.handleOnFocus}
                                                        name="rpmBiopsyReviewed" value="No" autoFocus />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd51Field1_yes"
                                                        checked={this.state.rectalProgramForm.rpmBiopsyReviewed === "Yes"} onFocus={this.handleOnFocus}
                                                        name="rpmBiopsyReviewed" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd51Field1_na"
                                                        checked={this.state.rectalProgramForm.rpmBiopsyReviewed === "N/A"} onFocus={this.handleOnFocus}
                                                        name="rpmBiopsyReviewed" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd51Field1_unk"
                                                        checked={this.state.rectalProgramForm.rpmBiopsyReviewed === "Unk"} onFocus={this.handleOnFocus}
                                                        name="rpmBiopsyReviewed" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="rpmStd51Lb2" >Re-biopsy performed on site</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd51Field2_no" //* 4557 *
                                                        checked={this.state.rectalProgramForm.rpmRebiopsyPerformed === "No"} onFocus={this.handleOnFocus}
                                                        name="rpmRebiopsyPerformed" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd51Field2_yes"
                                                        checked={this.state.rectalProgramForm.rpmRebiopsyPerformed === "Yes"} onFocus={this.handleOnFocus}
                                                        name="rpmRebiopsyPerformed" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd51Field2_na"
                                                        checked={this.state.rectalProgramForm.rpmRebiopsyPerformed === "N/A"} onFocus={this.handleOnFocus}
                                                        name="rpmRebiopsyPerformed" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd51Field2_unk"
                                                        checked={this.state.rectalProgramForm.rpmRebiopsyPerformed === "Unk"} onFocus={this.handleOnFocus}
                                                        name="rpmRebiopsyPerformed" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="rpmStd52">Std 5.2</label>
                                                    <label className="stdDesc" id="rpmStd52Desc">Staging before Definitive Treatment</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="rpmStd52Lb" >Clinical staging in MR prior to treatment</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd52Field_no" //* 4425 *
                                                            checked={this.state.rectalProgramForm.rpmStagedBeforeDefTx === "No"} onFocus={this.handleOnFocus}
                                                            name="rpmStagedBeforeDefTx" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd52Field_yes"
                                                        checked={this.state.rectalProgramForm.rpmStagedBeforeDefTx === "Yes"} onFocus={this.handleOnFocus}
                                                        name="rpmStagedBeforeDefTx" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd52Field_na"
                                                        checked={this.state.rectalProgramForm.rpmStagedBeforeDefTx === "N/A"} onFocus={this.handleOnFocus}
                                                        name="rpmStagedBeforeDefTx" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd52Field_unk"
                                                        checked={this.state.rectalProgramForm.rpmStagedBeforeDefTx === "Unk"} onFocus={this.handleOnFocus}
                                                        name="rpmStagedBeforeDefTx" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="rpmStd53">Std 5.3</label>
                                                    <label className="stdDesc" id="rpmStd53Desc">Standardized Staging Reporting for MRI Results</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="rpmStd53Lb1" >Pre-treatment MRI read by member of RC-MDT</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd53Field1_no" //* 4558 *
                                                        checked={this.state.rectalProgramForm.rpmPreTreatmentMriRCMDT === "No"} onFocus={this.handleOnFocus}
                                                        name="rpmPreTreatmentMriRCMDT" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd53Field1_yes"
                                                        checked={this.state.rectalProgramForm.rpmPreTreatmentMriRCMDT === "Yes"} onFocus={this.handleOnFocus}
                                                        name="rpmPreTreatmentMriRCMDT" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd53Field1_na"
                                                        checked={this.state.rectalProgramForm.rpmPreTreatmentMriRCMDT === "N/A"} onFocus={this.handleOnFocus}
                                                        name="rpmPreTreatmentMriRCMDT" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd53Field1_unk"
                                                        checked={this.state.rectalProgramForm.rpmPreTreatmentMriRCMDT === "Unk"} onFocus={this.handleOnFocus}
                                                        name="rpmPreTreatmentMriRCMDT" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="rpmStd53Lb2" >MRI staging including required elements in synoptic format</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd53Field2_no" //* 4559 *
                                                        checked={this.state.rectalProgramForm.rpmMriStagingResults === "No"} onFocus={this.handleOnFocus}
                                                        name="rpmMriStagingResults" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd53Field2_yes"
                                                        checked={this.state.rectalProgramForm.rpmMriStagingResults === "Yes"} onFocus={this.handleOnFocus}
                                                        name="rpmMriStagingResults" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd53Field2_na"
                                                        checked={this.state.rectalProgramForm.rpmMriStagingResults === "N/A"} onFocus={this.handleOnFocus}
                                                        name="rpmMriStagingResults" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd53Field2_unk"
                                                        checked={this.state.rectalProgramForm.rpmMriStagingResults === "Unk"} onFocus={this.handleOnFocus}
                                                        name="rpmMriStagingResults" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="rpmStd54">Std 5.4</label>
                                                    <label className="stdDesc" id="rpmStd54Desc">Carcinoembryonic Antigen Level</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="rpmStd54Lb" >CEA performed prior to definitive treatment</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd54Field_no" //* 4560 *
                                                        checked={this.state.rectalProgramForm.rpmCeaBeforeTreatment === "No"} onFocus={this.handleOnFocus}
                                                        name="rpmCeaBeforeTreatment" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd54Field_yes"
                                                        checked={this.state.rectalProgramForm.rpmCeaBeforeTreatment === "Yes"} onFocus={this.handleOnFocus}
                                                        name="rpmCeaBeforeTreatment" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd54Field_na"
                                                        checked={this.state.rectalProgramForm.rpmCeaBeforeTreatment === "N/A"} onFocus={this.handleOnFocus}
                                                        name="rpmCeaBeforeTreatment" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd54Field_unk"
                                                        checked={this.state.rectalProgramForm.rpmCeaBeforeTreatment === "Unk"} onFocus={this.handleOnFocus}
                                                        name="rpmCeaBeforeTreatment" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="rpmStd55">Std 5.5</label>
                                                    <label className="stdDesc" id="rpmStd55Desc">Rectal Cancer Multidiciplinary Team Tx Planning Discussion</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="rpmStd55Lb" >Tx planning discussed with RC-MDT prior to tx</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd55Field_no" //* 4434 *
                                                        checked={this.state.rectalProgramForm.rpmTxPlanDiscussion === "No"} onFocus={this.handleOnFocus}
                                                        name="rpmTxPlanDiscussion" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd55Field_yes"
                                                        checked={this.state.rectalProgramForm.rpmTxPlanDiscussion === "Yes"} onFocus={this.handleOnFocus}
                                                        name="rpmTxPlanDiscussion" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd55Field_na"
                                                        checked={this.state.rectalProgramForm.rpmTxPlanDiscussion === "N/A"} onFocus={this.handleOnFocus}
                                                        name="rpmTxPlanDiscussion" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd55Field_unk"
                                                        checked={this.state.rectalProgramForm.rpmTxPlanDiscussion === "Unk"} onFocus={this.handleOnFocus}
                                                        name="rpmTxPlanDiscussion" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="rpmStd56">Std 5.6</label>
                                                    <label className="stdDesc" id="rpmStd56Desc">Treatment Evaluation and Recommendation Summary</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="rpmStd56Lb" >Tx eval and recommendation provided to treating physician</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd56Field_no" //* 4435 *
                                                        checked={this.state.rectalProgramForm.rpmTxEvalSummComplete === "No"} onFocus={this.handleOnFocus}
                                                        name="rpmTxEvalSummComplete" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd56Field_yes"
                                                        checked={this.state.rectalProgramForm.rpmTxEvalSummComplete === "Yes"} onFocus={this.handleOnFocus}
                                                        name="rpmTxEvalSummComplete" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd56Field_na"
                                                        checked={this.state.rectalProgramForm.rpmTxEvalSummComplete === "N/A"} onFocus={this.handleOnFocus}
                                                        name="rpmTxEvalSummComplete" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd56Field_unk"
                                                        checked={this.state.rectalProgramForm.rpmTxEvalSummComplete === "Unk"} onFocus={this.handleOnFocus}
                                                        name="rpmTxEvalSummComplete" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="rpmStd58">Std 5.8</label>
                                                    <label className="stdDesc" id="rpmStd58Desc">Surgical Resection and Standardized Operative Reporting</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="rpmStd56Lb" >Operative report in required format</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd58Field_no" //* 4438 *
                                                        checked={this.state.rectalProgramForm.rpmOpRptSynopticFormat === "No"} onFocus={this.handleOnFocus}
                                                        name="rpmOpRptSynopticFormat" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd58Field_yes"
                                                        checked={this.state.rectalProgramForm.rpmOpRptSynopticFormat === "Yes"} onFocus={this.handleOnFocus}
                                                        name="rpmOpRptSynopticFormat" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd58Field_na"
                                                        checked={this.state.rectalProgramForm.rpmOpRptSynopticFormat === "N/A"} onFocus={this.handleOnFocus}
                                                        name="rpmOpRptSynopticFormat" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd58Field_unk"
                                                        checked={this.state.rectalProgramForm.rpmOpRptSynopticFormat === "Unk"} onFocus={this.handleOnFocus}
                                                        name="rpmOpRptSynopticFormat" value="Unk" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6" id="rpmTab1RightColumn">
                                                <div>
                                                    <label className="stdHeader">No&nbsp;&nbsp;Yes&nbsp;&nbsp;N/A&nbsp;&nbsp;Unk</label>
                                                </div>
                                                <div>
                                                    <label className="stdID" id="rpmStd59">Std 5.9</label>
                                                    <label className="stdDesc" id="cpmStd59Desc">Pathology Reports after Surgical Resection</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="rpmStd59Lb1" >Path reviewed by team member</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd59Field1_no" //* 4561 *
                                                        checked={this.state.rectalProgramForm.rpmPathReviewed === "No"} onFocus={this.handleOnFocus}
                                                        name="rpmPathReviewed" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd59Field1_yes"
                                                        checked={this.state.rectalProgramForm.rpmPathReviewed === "Yes"} onFocus={this.handleOnFocus}
                                                        name="rpmPathReviewed" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd59Field1_na"
                                                        checked={this.state.rectalProgramForm.rpmPathReviewed === "N/A"} onFocus={this.handleOnFocus}
                                                        name="rpmPathReviewed" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd50Field1_unk"
                                                        checked={this.state.rectalProgramForm.rpmPathReviewed === "Unk"} onFocus={this.handleOnFocus}
                                                        name="rpmPathReviewed" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="rpmStd59Lb2" >Path report w/ correct components w/in 2wks</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd59Field2_no" //* 4562 *
                                                        checked={this.state.rectalProgramForm.rpmPathReport === "No"} onFocus={this.handleOnFocus}
                                                        name="rpmPathReport" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd59Field2_yes"
                                                        checked={this.state.rectalProgramForm.rpmPathReport === "Yes"} onFocus={this.handleOnFocus}
                                                        name="rpmPathReport" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd59Field2_na"
                                                        checked={this.state.rectalProgramForm.rpmPathReport === "N/A"} onFocus={this.handleOnFocus}
                                                        name="rpmPathReport" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd50Field2_unk"
                                                        checked={this.state.rectalProgramForm.rpmPathReport === "Unk"} onFocus={this.handleOnFocus}
                                                        name="rpmPathReport" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="rpmStd510">Std 5.10</label>
                                                    <label className="stdDesc" id="rpmStd510Desc">Photographs of Surgical Specimen</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="rpmStd510Lb" >Photo appropriate and discussed</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd510Field_no" //* 4440 *
                                                        checked={this.state.rectalProgramForm.rpmSurgSpecimenPhotographs === "No"} onFocus={this.handleOnFocus}
                                                        name="rpmSurgSpecimenPhotographs" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd510Field_yes"
                                                        checked={this.state.rectalProgramForm.rpmSurgSpecimenPhotographs === "Yes"} onFocus={this.handleOnFocus}
                                                        name="rpmSurgSpecimenPhotographs" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd510Field_na"
                                                        checked={this.state.rectalProgramForm.rpmSurgSpecimenPhotographs === "N/A"} onFocus={this.handleOnFocus}
                                                        name="rpmSurgSpecimenPhotographs" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd510Field_unk"
                                                        checked={this.state.rectalProgramForm.rpmSurgSpecimenPhotographs === "Unk"} onFocus={this.handleOnFocus}
                                                        name="rpmSurgSpecimenPhotographs" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="rpmStd511">Std 5.11</label>
                                                    <label className="stdDesc" id="rpmStd511Desc">Multdisciplinary Team Post-Surgical Tx Outcome Discussion</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="rpmStd511Lb" >Post-Surg RC-MDT w/in 4wk</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd511Field_no" //* 4563 *
                                                        checked={this.state.rectalProgramForm.rpmPostSurgRCMDT === "No"} onFocus={this.handleOnFocus}
                                                        name="rpmPostSurgRCMDT" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd511Field_yes"
                                                        checked={this.state.rectalProgramForm.rpmPostSurgRCMDT === "Yes"} onFocus={this.handleOnFocus}
                                                        name="rpmPostSurgRCMDT" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd511Field_na"
                                                        checked={this.state.rectalProgramForm.rpmPostSurgRCMDT === "N/A"} onFocus={this.handleOnFocus}
                                                        name="rpmPostSurgRCMDT" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd511Field_unk"
                                                        checked={this.state.rectalProgramForm.rpmPostSurgRCMDT === "Unk"} onFocus={this.handleOnFocus}
                                                        name="rpmPostSurgRCMDT" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="rpmStd512">Std 5.12</label>
                                                    <label className="stdDesc" id="rpmStd512Desc">Post-Surg Treatment Outcome Discussion Summary</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="rpmStd512Lb" >Post-Surg Tx summary to tx physician</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd512Field_no" //* 4441 *
                                                        checked={this.state.rectalProgramForm.rpmTxOutcomeDiscussed === "No"} onFocus={this.handleOnFocus}
                                                        name="rpmTxOutcomeDiscussed" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd512Field_yes"
                                                        checked={this.state.rectalProgramForm.rpmTxOutcomeDiscussed === "Yes"} onFocus={this.handleOnFocus}
                                                        name="rpmTxOutcomeDiscussed" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd512Field_na"
                                                        checked={this.state.rectalProgramForm.rpmTxOutcomeDiscussed === "N/A"} onFocus={this.handleOnFocus}
                                                        name="rpmTxOutcomeDiscussed" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd512Field_unk"
                                                        checked={this.state.rectalProgramForm.rpmTxOutcomeDiscussed === "Unk"} onFocus={this.handleOnFocus}
                                                        name="rpmTxOutcomeDiscussed" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="rpmStd513">Std 5.13</label>
                                                    <label className="stdDesc" id="rpmStd513Desc">Adjuvant Therapy after Surgical Resection</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="rpmStd513Lb" >Adj Tx begun w/in 8 wk of def resection</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd513Field_no" //* 4564 *
                                                        checked={this.state.rectalProgramForm.rpmAdjTx8wk === "No"} onFocus={this.handleOnFocus}
                                                        name="rpmAdjTx8wk" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd513Field_yes"
                                                        checked={this.state.rectalProgramForm.rpmAdjTx8wk === "Yes"} onFocus={this.handleOnFocus}
                                                        name="rpmAdjTx8wk" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd513Field_na"
                                                        checked={this.state.rectalProgramForm.rpmAdjTx8wk === "N/A"} onFocus={this.handleOnFocus}
                                                        name="rpmAdjTx8wk" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="rpmStd513Field_unk"
                                                        checked={this.state.rectalProgramForm.rpmAdjTx8wk === "Unk"} onFocus={this.handleOnFocus}
                                                        name="rpmAdjTx8wk" value="Unk" />
                                                </div>
                                                <div className="emptyLine"></div>
                                                <div>
                                                    <label className="RPMAudit">RPM Audit Notes</label>
                                                    <Input type="textarea" focused={this.handleOnFocus}
                                                        changed={this.handleFormChange} field="rpmAuditNotes" lineStyle="oneLine"
                                                        labelText="" labelWidth="0px" width="500px" height="100px"
                                                        value={this.state.rectalProgramForm.rpmAuditNotes} itemNbr="4565"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>

                                    <TabPane tabId="2">
                                        <div className="row">
                                            <div className="col-md-4 rpmCol1">
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Confirmation of Dx Documented in Med Rec" field="rpmConfDxDocumentedMr" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmConfDxDocumentedMr ? this.state.rectalProgramForm.rpmConfDxDocumentedMr : this.state.rectalProgramForm.RpmConfDxDocumentedMr}
                                                    dataSource="Global_Dir_Mv" aliasNo="4423" titleDialog="Confirmation of Dx Documented in Med Rec" labelWidth="270px"
                                                    inValid={this.state.rAllItemFields["rpmConfDxDocumentedMr"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus} 
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmConfDxDocumentedMr"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Review of Dx Pathology" field="rpmReviewDxPathology" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmReviewDxPathology ? this.state.rectalProgramForm.rpmReviewDxPathology : this.state.rectalProgramForm.RpmReviewDxPathology}
                                                    dataSource="Global_Dir_Mv" aliasNo="4424" titleDialog="Review of Dx Pathology" labelWidth="270px"
                                                    inValid={this.state.rAllItemFields["rpmReviewDxPathology"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmReviewDxPathology"] : ""}
                                                />
                                                {/* <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Staged Before Definitive Treatment" field="rpmStagedBeforeDefTx" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmStagedBeforeDefTx ? this.state.rectalProgramForm.rpmStagedBeforeDefTx : this.state.rectalProgramForm.RpmStagedBeforeDefTx}
                                                    dataSource="Global_Dir_Mv" aliasNo="4425" titleDialog="Staged Before Definitive Treatment" labelWidth="270px"
                                                    inValid={this.state.rAllItemFields["rpmStagedBeforeDefTx"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmStagedBeforeDefTx"] : ""}
                                                /> */}
                                                {/*
                                                <Input type="datePicker" focused={this.handleOnFocus} changed={this.handleFormChange} width="200px"
                                                    labelText="Date of Pretreatment MRI" field="rpmMriDate" lineStyle="oneLine"
                                                    //value={this.state.rectalProgramForm.rpmMriDate != null && this.state.rectalProgramForm.rpmMriDate !== "" ? moment(new Date(this.state.rectalProgramForm.rpmMriDate)).format("MM/DD/YYYY") : ""}
                                                    value={this.state.rectalProgramForm.rpmMriDate}
                                                    labelWidth="270px" itemNbr=""
                                                />
                                                */}
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Date of Pretreatment MRI" field="rpmMriDate"
                                                    value={this.state.rectalProgramForm.rpmMriDate}
                                                    maxLength="10" aliasNo="4426" labelWidth="270px" width="100px"
                                                    lineStyle="oneLine" itemNbr="4426"
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Pretreatment MRI Performed" field="rpmPreTxMri" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmPreTxMri ? this.state.rectalProgramForm.rpmPreTxMri : this.state.rectalProgramForm.RpmPreTxMri}
                                                    dataSource="Global_Dir_Mv" aliasNo="4427" titleDialog="Pretreatment MRI Performed" labelWidth="270px"
                                                    inValid={this.state.rAllItemFields["rpmPreTxMri"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmPreTxMri"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Contraindications to MRI" field="rpmContraindicationsMri" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmContraindicationsMri ? this.state.rectalProgramForm.rpmContraindicationsMri : this.state.rectalProgramForm.RpmContraindicationsMri}
                                                    dataSource="Global_Dir_Mv" aliasNo="4428" titleDialog="Contraindications to MRI" labelWidth="270px"
                                                    inValid={this.state.rAllItemFields["rpmContraindicationsMri"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmContraindicationsMri"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Stage Reported on MRI" field="rpmStageRptMri" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmStageRptMri ? this.state.rectalProgramForm.rpmStageRptMri : this.state.rectalProgramForm.RpmStageRptMri}
                                                    dataSource="Global_Dir_Mv" aliasNo="4429" titleDialog="Stage Reported on MRI" labelWidth="270px"
                                                    inValid={this.state.rAllItemFields["rpmStageRptMri"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmStageRptMri"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Systemic Staging" field="rpmSystemicStaging" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmSystemicStaging ? this.state.rectalProgramForm.rpmSystemicStaging : this.state.rectalProgramForm.RpmSystemicStaging}
                                                    dataSource="Global_Dir_Mv" aliasNo="4430" titleDialog="Systemic Staging" labelWidth="270px"
                                                    inValid={this.state.rAllItemFields["rpmSystemicStaging"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmSystemicStaging"] : ""}
                                                />
                                                {/*
                                                <Input type="datePicker" focused={this.handleOnFocus} changed={this.handleFormChange} width="200px"
                                                    labelText="Date of PET/CT" field="rpmPetCtDate" lineStyle="oneLine"
                                                    //value={this.state.rectalProgramForm.rpmPetCtDate != null && this.state.rectalProgramForm.rpmPetCtDate !== "" ? moment(new Date(this.state.rectalProgramForm.rpmPetCtDate)).format("MM/DD/YYYY") : ""}
                                                    value={this.state.rectalProgramForm.rpmPetCtDate}
                                                    labelWidth="270px" itemNbr=""
                                                />
                                                */}
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Date of PET/CT" field="rpmPetCtDate"
                                                    value={this.state.rectalProgramForm.rpmPetCtDate}
                                                    maxLength="10" aliasNo="4431" labelWidth="270px" width="100px"
                                                    lineStyle="oneLine" itemNbr="4431"
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Standardized MRI Report" field="rpmStandardMriReport" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmStandardMriReport ? this.state.rectalProgramForm.rpmStandardMriReport : this.state.rectalProgramForm.RpmStandardMriReport}
                                                    dataSource="Global_Dir_Mv" aliasNo="4432" titleDialog="Standardized MRI Report" labelWidth="270px"
                                                    inValid={this.state.rAllItemFields["rpmStandardMriReport"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmStandardMriReport"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Pretreatment CEA" field="rpmPreTxCea" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmPreTxCea ? this.state.rectalProgramForm.rpmPreTxCea : this.state.rectalProgramForm.RpmPreTxCea}
                                                    dataSource="Global_Dir_Mv" aliasNo="4433" titleDialog="Pretreatment CEA" labelWidth="270px"
                                                    inValid={this.state.rAllItemFields["rpmPreTxCea"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmPreTxCea"] : ""}
                                                />
                                            </div>
                                            <div className="col-md-4 extentDiv rpmCol2">
                                                {/* <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Treatment Planning Discussion" field="rpmTxPlanDiscussion" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmTxPlanDiscussion ? this.state.rectalProgramForm.rpmTxPlanDiscussion : this.state.rectalProgramForm.RpmTxPlanDiscussion}
                                                    dataSource="Global_Dir_Mv" aliasNo="4434" titleDialog="Treatment Planning Discussion" labelWidth="270px"
                                                    inValid={this.state.rAllItemFields["rpmTxPlanDiscussion"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmTxPlanDiscussion"] : ""}
                                                /> */}
                                                {/* <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Treatment Eval and Summary Completed" field="rpmTxEvalSummComplete" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmTxEvalSummComplete ? this.state.rectalProgramForm.rpmTxEvalSummComplete : this.state.rectalProgramForm.RpmTxEvalSummComplete}
                                                    dataSource="Global_Dir_Mv" aliasNo="4435" titleDialog="Treatment Eval and Summary Completed" labelWidth="270px"
                                                    inValid={this.state.rAllItemFields["rpmTxEvalSummComplete"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmTxEvalSummComplete"] : ""}
                                                /> */}
                                                {/*
                                                <Input type="datePicker" focused={this.handleOnFocus} changed={this.handleFormChange} width="200px"
                                                    labelText="Date of Initial Eval at RCP" field="rpmDateInitEval" lineStyle="oneLine"
                                                    //value={this.state.rectalProgramForm.rpmDateInitEval != null && this.state.rectalProgramForm.rpmDateInitEval !== "" ? moment(new Date(this.state.rectalProgramForm.rpmDateInitEval)).format("MM/DD/YYYY") : ""}
                                                    value={this.state.rectalProgramForm.rpmDateInitEval}
                                                    labelWidth="270px" itemNbr=""
                                                />
                                                */}
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Date of Initial Eval at RCP" field="rpmDateInitEval"
                                                    value={this.state.rectalProgramForm.rpmDateInitEval}
                                                    maxLength="10" aliasNo="4436" labelWidth="270px" width="100px"
                                                    lineStyle="oneLine" itemNbr="4436"
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy"
                                                />
                                                <Input type="label" changed={this.handleFormChange} labelWidth="270px" width="50px"
                                                    labelText="Days from Initial Eval to Initial Treatment"
                                                    field="rpmDaysInitEvaltoInitTx" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmDaysInitEvaltoInitTx} itemNbr=""
                                                />
                                                {/* <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Op Report in Synoptic Format" field="rpmOpRptSynopticFormat" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmOpRptSynopticFormat ? this.state.rectalProgramForm.rpmOpRptSynopticFormat : this.state.rectalProgramForm.RpmOpRptSynopticFormat}
                                                    dataSource="Global_Dir_Mv" aliasNo="4438" titleDialog="Op Report in Synoptic Format" labelWidth="270px"
                                                    inValid={this.state.rAllItemFields["rpmOpRptSynopticFormat"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmOpRptSynopticFormat"] : ""}
                                                /> */}
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="CAP Guidelines Followed" field="rpmCapGuidelinesFol" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmCapGuidelinesFol ? this.state.rectalProgramForm.rpmCapGuidelinesFol : this.state.rectalProgramForm.RpmCapGuidelinesFol}
                                                    dataSource="Global_Dir_Mv" aliasNo="4439" titleDialog="CAP Guidelines Followed" labelWidth="270px"
                                                    inValid={this.state.rAllItemFields["rpmCapGuidelinesFol"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmCapGuidelinesFol"] : ""}
                                                />
                                                {/* <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Surgical Specimen Photographs" field="rpmSurgSpecimenPhotographs" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmSurgSpecimenPhotographs ? this.state.rectalProgramForm.rpmSurgSpecimenPhotographs : this.state.rectalProgramForm.rpmSurgSpecimenPhotographs}
                                                    dataSource="Global_Dir_Mv" aliasNo="4440" titleDialog="Surgical Specimen Photographs" labelWidth="270px"
                                                    inValid={this.state.rAllItemFields["rpmSurgSpecimenPhotographs"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmSurgSpecimenPhotographs"] : ""}
                                                /> */}
                                                {/* <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Post Surg Treatment Outcome Discussed" field="rpmTxOutcomeDiscussed" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmTxOutcomeDiscussed ? this.state.rectalProgramForm.rpmTxOutcomeDiscussed : this.state.rectalProgramForm.rpmTxOutcomeDiscussed}
                                                    dataSource="Global_Dir_Mv" aliasNo="4441" titleDialog="Post Surg Treatment Outcome Discussed" labelWidth="270px"
                                                    inValid={this.state.rAllItemFields["rpmTxOutcomeDiscussed"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmTxOutcomeDiscussed"] : ""}
                                                /> */}
                                                {/*
                                                <Input type="datePicker" focused={this.handleOnFocus} changed={this.handleFormChange} width="200px"
                                                    labelText="Date Post Surg Tx Outcome Discussed" field="rpmDateTxOutcomeDiscussed" lineStyle="oneLine"
                                                    //value={this.state.rectalProgramForm.rpmDateTxOutcomeDiscussed != null && this.state.rectalProgramForm.rpmDateTxOutcomeDiscussed !== "" ? moment(new Date(this.state.rectalProgramForm.rpmDateTxOutcomeDiscussed)).format("MM/DD/YYYY") : ""}
                                                    value={this.state.rectalProgramForm.rpmDateTxOutcomeDiscussed}
                                                    labelWidth="270px" itemNbr=""
                                                />
                                                */}
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Date Post Surg Tx Outcome Discussed" field="rpmDateTxOutcomeDiscussed"
                                                    value={this.state.rectalProgramForm.rpmDateTxOutcomeDiscussed}
                                                    maxLength="10" aliasNo="4442" labelWidth="270px" width="100px"
                                                    lineStyle="oneLine" itemNbr="4442"
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Post Surg Treatment Outcome Summ Provided" field="rpmTxOutcomeSummProvided" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmTxOutcomeSummProvided ? this.state.rectalProgramForm.rpmTxOutcomeSummProvided : this.state.rectalProgramForm.rpmTxOutcomeSummProvided}
                                                    dataSource="Global_Dir_Mv" aliasNo="4443" titleDialog="Post Surg Treatment Outcome Summ Provided" labelWidth="270px"
                                                    inValid={this.state.rAllItemFields["rpmTxOutcomeSummProvided"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmTxOutcomeSummProvided"] : ""}
                                                />
                                                {/*
                                                <Input type="datePicker" focused={this.handleOnFocus} changed={this.handleFormChange} width="200px"
                                                    labelText="Date Post Surg Tx Outcome Provided" field="rpmDateTxOutcomeProvided" lineStyle="oneLine"
                                                    //value={this.state.rectalProgramForm.rpmDateTxOutcomeProvided != null && this.state.rectalProgramForm.rpmDateTxOutcomeProvided !== "" ? moment(new Date(this.state.rectalProgramForm.rpmDateTxOutcomeProvided)).format("MM/DD/YYYY") : ""}
                                                    value={this.state.rectalProgramForm.rpmDateTxOutcomeProvided}
                                                    labelWidth="270px" itemNbr=""
                                                />
                                                */}
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Date Post Surg Tx Outcome Provided" field="rpmDateTxOutcomeProvided"
                                                    value={this.state.rectalProgramForm.rpmDateTxOutcomeProvided}
                                                    maxLength="10" aliasNo="4444" labelWidth="270px" width="100px"
                                                    lineStyle="oneLine" itemNbr="4444"
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy"
                                                />
                                                <Input type="label" changed={this.handleFormChange} labelWidth="270px" width="50px"
                                                    labelText="Days from Def Surg to Systemic Treatment"
                                                    field="rpmDaysDefSurgtoSystemicTx" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmDaysDefSurgtoSystemicTx} itemNbr=""
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Adjuvant Treatment after Surgical Resection" field="rpmAdjTxAfterSurgResection" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmAdjTxAfterSurgResection ? this.state.rectalProgramForm.rpmAdjTxAfterSurgResection : this.state.rectalProgramForm.rpmAdjTxAfterSurgResection}
                                                    dataSource="Global_Dir_Mv" aliasNo="4446" titleDialog="Adjuvant Treatment after Surgical Resection" labelWidth="270px"
                                                    inValid={this.state.rAllItemFields["rpmAdjTxAfterSurgResection"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmAdjTxAfterSurgResection"] : ""}
                                                />
                                            </div>
                                            <div className="col-md-4 rpmCol3">
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="120px"
                                                    labelText={this.state.rectalProgramForm.rpmUserLblText1 ? this.state.rectalProgramForm.rpmUserLblText1 : "RPM User Defined 1"}
                                                    field="rpmUser1" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmUser1 ? this.state.rectalProgramForm.rpmUser1 : this.state.rectalProgramForm.rpmUser1}
                                                    dataSource="Global_Dir_Mv" aliasNo="4447" titleDialog="RPM User Defined 1" labelWidth="150px"
                                                    inValid={this.state.rAllItemFields["rpmUser1"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmUser1"] : ""}
                                                    flag="ERS_UserDefinedMaintenance"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="120px"
                                                    labelText={this.state.rectalProgramForm.rpmUserLblText2 ? this.state.rectalProgramForm.rpmUserLblText2 : "RPM User Defined 2"}
                                                    field="rpmUser2" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmUser2 ? this.state.rectalProgramForm.rpmUser2 : this.state.rectalProgramForm.rpmUser2}
                                                    dataSource="Global_Dir_Mv" aliasNo="4448" titleDialog="RPM User Defined 2" labelWidth="150px"
                                                    inValid={this.state.rAllItemFields["rpmUser2"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmUser2"] : ""}
                                                    flag="ERS_UserDefinedMaintenance"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="120px"
                                                    labelText={this.state.rectalProgramForm.rpmUserLblText3 ? this.state.rectalProgramForm.rpmUserLblText3 : "RPM User Defined 3"}
                                                    field="rpmUser3" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmUser3 ? this.state.rectalProgramForm.rpmUser3 : this.state.rectalProgramForm.rpmUser3}
                                                    dataSource="Global_Dir_Mv" aliasNo="4449" titleDialog="RPM User Defined 3" labelWidth="150px"
                                                    inValid={this.state.rAllItemFields["rpmUser3"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmUser3"] : ""}
                                                    flag="ERS_UserDefinedMaintenance"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="120px"
                                                    labelText={this.state.rectalProgramForm.rpmUserLblText4 ? this.state.rectalProgramForm.rpmUserLblText4 : "RPM User Defined 4"}
                                                    field="rpmUser4" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmUser4 ? this.state.rectalProgramForm.rpmUser4 : this.state.rectalProgramForm.rpmUser4}
                                                    dataSource="Global_Dir_Mv" aliasNo="4450" titleDialog="RPM User Defined 4" labelWidth="150px"
                                                    inValid={this.state.rAllItemFields["rpmUser4"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmUser4"] : ""}
                                                    flag="ERS_UserDefinedMaintenance"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="120px"
                                                    labelText={this.state.rectalProgramForm.rpmUserLblText5 ? this.state.rectalProgramForm.rpmUserLblText5 : "RPM User Defined 5"}
                                                    field="rpmUser5" lineStyle="oneLine"
                                                    value={this.state.rectalProgramForm.rpmUser5 ? this.state.rectalProgramForm.rpmUser5 : this.state.rectalProgramForm.rpmUser5}
                                                    dataSource="Global_Dir_Mv" aliasNo="4451" titleDialog="RPM User Defined 5" labelWidth="150px"
                                                    inValid={this.state.rAllItemFields["rpmUser5"]} itemNbr=""
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rpmUser5"] : ""}
                                                    flag="ERS_UserDefinedMaintenance"
                                                />
                                                <Input type="textarea" focused={this.handleOnFocus} changed={this.handleFormChange} labelWidth="auto"
                                                    labelText="RPM Text" field="rpmText" value={this.state.rectalProgramForm.rpmText} itemNbr=""
                                                />
                                            </div>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </form>

                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetRectalProgram} formId="rectalProgramForm"
                        isFormDirty={this.state.isFormDirty}
                        showExtra={[{ name: "Text", action: "showDiagnosisText" }]}
                        callbackToParent={this.updateSaveFlagToProcess}
                        focusingField={this.state.focusingField}
                    />

                </div>
            )
            : <p className="data_loading_page">Rectal Program data loading...</p>;
        return (
            <React.Fragment>
                {rectalProgramMaintenance}

                <div id="textareaEditorForItemsCls">
                    {
                        this.props.isShowTextDialogVisible ?
                            /*
                            <Dialog
                                title="Diagnosis Text &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Treatment &amp; Other Text"
                                onClose={this.props.closeTextDialog}
                                width={950} height={"auto"}
                                className={classes.title}>*/
                            <div className="CRStar_Window">
                                <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar">
                                    <div className="dragableWindowCls">
                                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                                            <div className="k-window-title k-dialog-title">
                                                <pre className="textPopupPreTitleCls">{TextPopup.Title}</pre>
                                            </div>
                                            <div className="k-window-actions k-dialog-actions">
                                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeTextDialog(e) }}></span>
                                                </a>
                                            </div>
                                        </div>
                                        <TextForm closeWindow={this.closeTextDialog} setFocusingField={this.helpFocusingField} />
                                    </div>
                                    {/*</Dialog>*/}
                                </Draggable>
                            </div>
                            : null
                    }
                </div>

            </React.Fragment>
        );
    }
}

RectalProgramMaintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedRectalProgramRecord: state.patientAbstract.selectedRectalProgramRecord,
        isPatientLoading: state.patientAbstract.isPatientLoading,

        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,

        selectedPatient: state.patientAbstract.selectedPatient,
        isShowTextDialogVisible: state.patientAbstract.isShowTextDialogVisible,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,

        initialAbstractPages: state.patientAbstract.initialAbstractPages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveRectalProgram: (rectalProgramData) => dispatch(actionCreators.saveRectalProgram(rectalProgramData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),

        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RectalProgramMaintenance);