/* eslint-disable */
import React, { Component } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { filterBy } from "@progress/kendo-data-query";
import ConfigData from '../../store/config.json';
import { connect } from "react-redux";
import { actionCreators } from "../../store/PatientAbstract";
import Draggable from 'react-draggable';
import axios from "axios";

class CommonLookup2 extends Component {

    lastSelectedIndex = 0;

    state = {
        codes: [],
        isLoaded: false,
        filter: {
            logic: "",
            filters: []
        },
        filterOperators: {
            'text': [
                { text: 'grid.filterContainsOperator', operator: 'contains' }
            ],
        },
        sort: [],
        total: 0,
        skip: 0,
        take: 50,

        enterKeyPressed: false,
        shiftKeyPressed: false,
        tabKeyPressed: false,
        focusedItem: null,
        filterIndexNo: 0,

        titleDialog: null,
    }

    addEventListener = () => {
        var cl = this;
        var currentLookupModal = document.querySelector('#commonLookup_' + cl.props.field);
        currentLookupModal.addEventListener('keydown', function (e) {
            // Disabled up/down event on grid of this popup
            if (e.keyCode == 40 && false) {// down arrow /TAB
                var filterInputFocusing = e.target.getAttribute("class") === "k-input-inner" || e.target.getAttribute("class") === "k-table-th" ? true : false;

                var trs = currentLookupModal.getElementsByClassName("k-master-row");
                if (filterInputFocusing && trs && trs[0] && trs[0].firstChild) {
                    trs[0].firstChild.setAttribute('tabindex', '0');
                    trs[0].firstChild.focus();

                    setTimeout(function () {
                        trs[0].scrollIntoView();
                        trs[0].scrollTop = 0;
                    }, 0);

                    let currentClasses = trs[0].getAttribute("class");
                    if (currentClasses && currentClasses.indexOf('k-selected') == -1)
                        trs[0].setAttribute("class", currentClasses + " k-selected");
                }

                var itemInRowFocusing = e.target.getAttribute("class") === "k-table-td" ? true : false;
                if (itemInRowFocusing && e.target.parentNode.getAttribute("data-grid-row-index") === "0") {
                    let currentClasses = e.target.parentNode.getAttribute("class");
                    if (currentClasses && currentClasses.indexOf('k-selected') != -1)
                        e.target.parentNode.setAttribute("class", currentClasses.replace("k-selected", ""));
                }

            } else if (e.key === "Tab" || (e.key === "Shift" && e.keyCode == 9) || (e.key === "Tab" && e.shiftKey)) {// shift + tab
                // Shift + Tab
                if (e.key === "Tab" && e.shiftKey) {
                    if (e.target.nodeName === "BUTTON") {
                        if (e.target.previousSibling) {
                            var previousSibling = e.target.previousSibling;
                            setTimeout(function () {
                                try {
                                    previousSibling ? previousSibling.focus() : "";
                                } catch (ex) { }
                                return false;
                            }, 0);
                        } else {
                            var inputFitler = currentLookupModal.querySelectorAll('input') && currentLookupModal.querySelectorAll('input').length > 0 ? currentLookupModal.querySelectorAll('input') : null;
                            if (inputFitler) {
                                setTimeout(function () {
                                    inputFitler[inputFitler.length - 1].focus();
                                    return false;
                                }, 0);
                            } else {
                                setTimeout(function () {
                                    currentLookupModal.querySelectorAll('input') && currentLookupModal.querySelectorAll('input')[0] ? currentLookupModal.querySelectorAll('input')[0].focus() : "";
                                    return false;
                                }, 0);
                            }
                        }
                        return false;

                    } else if (e.target.nodeName === "TH" || (e.target.nodeName === "INPUT" && e.target.getAttribute('class') === "k-input-inner")) {//Don't allow press shift+tab on these filter input fields
                        //
                        if (e.target.nodeName === "TH") {
                            // should set focus into button
                            e.preventDefault();
                            return false;
                        }
                        // @TODO - ???
                        else if (!e.target.parentNode.parentNode.parentNode.previousSibling || e.target.parentNode.parentNode.parentNode.previousSibling === null) {
                            var btnCancel = currentLookupModal.querySelectorAll('button') && currentLookupModal.querySelectorAll('button').length > 0 ? currentLookupModal.querySelectorAll('button') : null;
                            if (btnCancel) {
                                setTimeout(function () {
                                    btnCancel[btnCancel.length - 1] ? btnCancel[btnCancel.length - 1].focus() : "";
                                }, 0);
                            } else {
                                setTimeout(function () {
                                    currentLookupModal.querySelectorAll('button') && currentLookupModal.querySelectorAll('button')[0] ? currentLookupModal.querySelectorAll('button')[0].focus() : "";
                                }, 0);
                            }
                        } else {
                            setTimeout(function () {
                                e.target.parentNode.parentNode.parentNode.previousSibling.querySelector('input') ? e.target.parentNode.parentNode.parentNode.previousSibling.querySelector('input').focus() : "";
                            }, 0);
                        }
                    } else if (e.target.getAttribute("class") === "k-checkbox" && e.target.parentNode.nodeName === "TH") {//is check all
                        var btnCancel = currentLookupModal.querySelectorAll('button') && currentLookupModal.querySelectorAll('button').length > 0 ? currentLookupModal.querySelectorAll('button') : null;
                        if (btnCancel) {
                            setTimeout(function () {
                                btnCancel[btnCancel.length - 1] ? btnCancel[btnCancel.length - 1].focus() : "";
                            }, 0);
                        } else {
                            setTimeout(function () {
                                currentLookupModal.querySelectorAll('button') && currentLookupModal.querySelectorAll('button')[0] ? currentLookupModal.querySelectorAll('button')[0].focus() : "";
                            }, 0);
                        }
                    }

                // Only Tab
                } else {
                    if (e.target.nodeName === "BUTTON") {
                        if (e.target.nextSibling) {
                            var nextSibling = e.target.nextSibling;
                            setTimeout(function () {
                                nextSibling ? nextSibling.focus() : "";
                            }, 0);
                        } else {
                            setTimeout(function () {
                                currentLookupModal.querySelectorAll('input')[0] ? currentLookupModal.querySelectorAll('input')[0].focus() : "";
                            }, 0);
                        }
                    } else if (e.target.nodeName === "INPUT") {
                        if (e.target.getAttribute("class") === "k-checkbox") {
                            if (e.target.parentNode.parentNode.parentNode.nodeName === "THEAD") {
                                setTimeout(function () {
                                    currentLookupModal.querySelectorAll('input[class="k-input-inner"]')[0] ? currentLookupModal.querySelectorAll('[class="k-input-inner"]')[0].focus() : "";
                                }, 0);
                            } else {
                                if (e.target.parentNode.parentNode.nextSibling) {
                                    var _nextSibling = e.target.parentNode.parentNode.nextSibling;
                                    setTimeout(function () {
                                        _nextSibling.querySelector('input')[0] ? _nextSibling.querySelector('input')[0].focus() : "";
                                    }, 0);
                                } else {
                                    setTimeout(function () {
                                        currentLookupModal.querySelectorAll('button')[0] ? currentLookupModal.querySelectorAll('button')[0].focus() : "";
                                    }, 0);
                                }
                            }
                        } else {
                            //k-textbox
                            if (e.target.parentNode.parentNode.nextSibling) {
                                var _nextSibling = e.target.parentNode.parentNode.nextSibling;
                                setTimeout(function () {
                                    _nextSibling.querySelector('input') ? _nextSibling.querySelector('input').focus() : "";
                                }, 0);
                            } else {
                                //
                            }
                        }
                    }
                }

            // Disabled up/down event on grid of this popup
            } else if (e.keyCode == 38 && false) {// up arrow
                var pagingLinkFocusing = e.target.getAttribute("class") ? (e.target.getAttribute("class").indexOf("k-link") !== -1 ? true : false) : false;
                let lastItemOnGrid = currentLookupModal.querySelector(".k-grid-table") &&
                    currentLookupModal.querySelector(".k-grid-table").lastChild &&
                    currentLookupModal.querySelector(".k-grid-table").lastChild.childElementCount ? currentLookupModal.querySelector(".k-grid-table").lastChild.childElementCount : 10;
                lastItemOnGrid = lastItemOnGrid - 1;

                var trs = currentLookupModal.getElementsByClassName("k-master-row");
                if (pagingLinkFocusing) {
                    for (var len = 0; len < trs.length; len++) {
                        trs[len] ? trs[len].setAttribute("class", len % 2 == 0 ? "k-master-row" : "k-master-row k-alt") : "";
                    }

                    if (trs && trs[lastItemOnGrid] && trs[lastItemOnGrid].firstChild) {
                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].firstChild.setAttribute('tabindex', '0') : "";
                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].firstChild.focus() : "";

                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].setAttribute("class", "k-master-row k-alt k-selected") : "";

                        setTimeout(function () {
                            trs[lastItemOnGrid] ? trs[lastItemOnGrid].scrollIntoView() : "";
                            trs[lastItemOnGrid] ? trs[lastItemOnGrid].scrollTop = 10000 : "";//scroll to bottom of grid
                        }, 0);
                    }
                } else if (e.target.parentNode.getAttribute("data-grid-row-index") === "0") {
                    trs[0] ? trs[0].setAttribute("class", "k-master-row") : "";
                } else {
                    trs[lastItemOnGrid] ? trs[lastItemOnGrid].setAttribute("class", "k-master-row k-alt") : "";
                }
            } else {
                cl.setState({ enterKeyPressed: false, focusedItem: null });
            }
        });
    }

    init = () => {
        var currentLookupModal = document.getElementById("commonLookup_" + this.props.field);
        var cl = this;

        if (currentLookupModal && currentLookupModal.querySelectorAll(".k-input-inner")) {
            var inputs = currentLookupModal.querySelectorAll(".k-input-inner");
            for (var idx = 0; idx < inputs.length; idx++) {
                let input = inputs[idx];
                input.setAttribute("id", "multi-selection-lookup_filter-k-textbox-" + idx);
                input.setAttribute("data-index", idx);
                input.setAttribute("disabled", true);

                input.addEventListener('focus', function (e) {
                    cl.setState({ focusedItem: input });
                });
            }
        }

        setTimeout(function () {
            currentLookupModal && currentLookupModal.querySelector(".k-input-inner") ? currentLookupModal.querySelector(".k-input-inner").focus() : "";
            return false;
        }, 0);
    }

    enabledAllFilter() {
        var currentLookupModal = document.getElementById("commonLookup_" + this.props.field);
        if (currentLookupModal && currentLookupModal.querySelectorAll(".k-input-inner")) {
            var inputs = currentLookupModal.querySelectorAll(".k-input-inner");
            for (var idx = 0; idx < inputs.length; idx++) {
                let input = inputs[idx];
                input.removeAttribute("disabled");
                idx == 0 ? input.focus() : null;
            }
        }
    }

    componentDidMount() {
        // Fixing item #PR00111
        this.addEventListener();

        this.props.setPageLoaded(false);
        this.props.setChildrenOpening(true);

        this.init();

        let table = this.props.dataSource;
        if (table === undefined || table === "") {
            return false;
        }

        let code = this.props.aliasNo !== undefined ? this.props.aliasNo : "0";
        let flag = this.props.flag !== undefined ? this.props.flag : "";
        let codeLen = this.props.codeLength ? this.props.codeLength : "";

        var _this = this;
        let url = ConfigData.CRStarAPI_URL + (table === "Client_Group" ? "ers" : this.props.clientgroup) + "/GetCommonLookupView";
        var _params = {
            table: table, code: code, flag: flag, len: codeLen, accessToken: localStorage.getItem('token'), clientAlias: this.props.clientgroup
        };
        axios.post(url, _params)
            //.then(res => res.json())
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                _this.enabledAllFilter();
                if (res && res.data.StatusCode == "408") {
                    console.log(res.data.ErrorMessage);
                    _this.props.showSessionTimeoutMsg(true);
                }
                return res.data && res.data.commonLookupList ? _this.setState({ codes: res.data.commonLookupList }) : "";
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    }

    componentDidUpdate(prevProps) {
        let table = this.props.dataSource;
        if (table === undefined || table === "") {
            return false;
        }

        if (this.state.codes && this.state.codes.length > 0 && !this.state.isLoaded) {
            let splitter = (this.props.dataSource == "Hospital2L" ? "," : ";");
            let values = this.props.values ? this.props.values.split(splitter) : [];
            let codes = this.state.codes;
            var cl2 = this;
            setTimeout(function () {
                codes.forEach(item => item.selected = values.includes(item.code));

                for (let i = 0; i < codes.length; i++) {
                    if (codes[i] && values.includes(codes[i])) {
                        codes[i].selected = true;
                    }
                }
                cl2.forceUpdate();

                cl2.setState({ isLoaded: true });
            }, 0);
        }
        
        return false;
    }

    pageChange = (event, isFilter) => {
        //
        if (isFilter === "sorting") {
            let _sorter = event.sort;

            this.setState({
                sort: _sorter,
                enterKeyPressed: false
            });

            this.props.setPageLoaded(false);
            return false;
        }
        //

        let _filter = {
            logic: "",//and
            filters: []
        };
        let enterKeyPressed = this.state.enterKeyPressed;
        if (event && event.filter) {
            _filter = event.filter;

            this.setState({
                filter: _filter,
                enterKeyPressed: true
            });
        } else {
            // click a page number on paging area
            _filter = event.target.props.filter && !isFilter ? event.target.props.filter : {
                logic: "",//and
                filters: []
            };

            this.setState({
                skip: event.page ? event.page.skip : this.state.skip,
                take: event.page ? event.page.take : this.state.take,
                filter: _filter,
                enterKeyPressed: true
            });
            enterKeyPressed = true;
        }
        this.props.setPageLoaded(!enterKeyPressed);
        return false;
    }

    // inquire all checked items to set for parent input
    selectMultiCodes = (e, codes) => {
        try {
            e ? e.preventDefault() : "";
        } catch (e) { }

        var _code = "";
        for (let i = 0; i < codes.length; i++) {
            if (codes[i] && codes[i].selected) {
                _code += (this.props.dataSource == "Hospital2L" ? "," : ";") + codes[i]["code"];
            }
        }

        if (_code !== "") _code = _code.substr(1);

        this.props.setCode(_code, null);

        this.setState({ isDialogVisible: true });

        this.props.setChildrenOpening(false);

        return false;
    }

    handleRowClick = (props) => {
        const data = this.state.codes;
        const newData = data.map((item) => ({
            ...item,
            selected: item.code === props.dataItem.code,
        }));
        this.setState({ codes: newData });
    };

    rowClick = (event) => {
        let last = this.lastSelectedIndex;
        const current = this.state.codes.findIndex(dataItem => dataItem === event.dataItem);

        this.lastSelectedIndex = last = current;

        const select = !event.dataItem.selected;
        for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
            this.state.codes[i].selected = select;
        }

        this.forceUpdate();
    }

    // To get more reference at https://www.telerik.com/kendo-react-ui/components/grid/accessibility/
    // and https://www.telerik.com/kendo-react-ui/getting-started/
    handleNavigationAction = (event) => {
        const { focusElement } = event;
        const data = this.state.codes;

        const rowId = focusElement.parentElement.getAttribute("data-id");

        if (rowId) {
            const newData = data.map((item) => ({
                ...item,
                selected: String(item.code) === rowId,
            }));
            this.setState({ codes: newData });
        }

    };

    rowRender = (trElement, rowProps) => {
        const trProps = {
            ...trElement.props,
            ["data-id"]: rowProps.dataItem.code,
        };
        return React.cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    };

    handleKeyDownAction = (props) => {
        var event = props.nativeEvent;
        if (event.key === "Enter") {
            event.target.click();
            return false;
        }
    }

    // and https://stackblitz.com/edit/react-xmw9xs?file=app/main.js
    selectionChange = (event) => {
        event.dataItem.selected = !event.dataItem.selected;
        this.forceUpdate();
    }

    headerSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        this.state.codes.forEach(item => item.selected = checked);
        this.forceUpdate();
    }

    closeCode = (e) => {
        try {
            e ? e.preventDefault() : "";
        } catch (e) { }

        this.props.closeCode();
        this.props.setChildrenOpening(false);

        return false;
    }

    render() {
        let commonLookup = null;
        {
            commonLookup = (
                <Draggable handle=".dragableDialogCls">
                    <div className="dragableDialogCls multiSelectionValuesPopup" id={"commonLookup_" + this.props.field}>
                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                            <div className="k-window-title k-dialog-title">
                                {this.state.titleDialog && this.state.titleDialog !== " " && this.state.titleDialog !== undefined ? this.state.titleDialog : (this.props.titleDialog ? this.props.titleDialog : " ")}
                            </div>
                            <div className="k-window-actions k-dialog-actions">
                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeCode(e) }}></span>
                                </a>
                            </div>
                        </div>
                        <Grid
                            style={{ height: '500px' }}
                            data={this.state.codes.length !== 0 ? filterBy(this.state.codes, this.state.filter) : null}
                            filter={this.state.filter}
                            filterable
                            onFilterChange={e => { this.setState({ filter: e.filter }) }}
                            onRowClick={this.rowClick}//handleRowClick
                            selectedField="selected"
                            rowRender={this.rowRender}
                            navigatable={true}
                            //onNavigationAction={this.handleNavigationAction}
                            onSelectionChange={this.selectionChange}
                            onHeaderSelectionChange={this.headerSelectionChange}
                            //onKeyDown={this.handleKeyDownAction}
                        >
                            <GridColumn field="selected" filterable={false} width="50px" style={{ textAlign: "center" }}
                                headerSelectionValue={this.state.codes.findIndex(dataItem => dataItem.selected === false) === -1}
                            />
                            <GridColumn field="code" filter="text" title={this.props.dataSource == "Hospital2L" ? "Hosp Nbr" : "Code"} style={{ width: "20%" }} />
                            <GridColumn field="name" filter="text" title={this.props.dataSource == "Hospital2L" ? "Hosp Name" : "Description"} style={{ width: "70%" }} />
                        </Grid>
                        <div style={{ padding: "10px", textAlign: "center", backgroundColor: "#c5ddfe" }}>
                            {this.props.addNew && this.props.addNew !== "" ? <button onClick={this.addNewItem}>{this.props.addNew}</button> : ""}
                            <button className="ErsGrBlueButton" onClick={e => { this.selectMultiCodes(e, this.state.codes) }}>OK</button>
                            <button className="ErsGrBlueButton" onClick={this.props.closeCode}>Cancel</button>
                        </div>
                    </div>
                </Draggable>
            );
        }

        return <div>
            <div className="fake_popup_cls"></div>
            {commonLookup}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        clientgroup: state.auth.clientgroup,
        pageLoaded: state.patientAbstract.pageLoaded,
        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(CommonLookup2);