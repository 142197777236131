/* eslint-disable */
import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Iframe from 'react-iframe';
import './NavMenu.css';
import * as dialogTypes from "./AbstractManagement/maintDialogs";
import { actionCreators } from "../store/PatientAbstract";

class Aframe extends Component {

    state = {
        loaded: false,
        alphaUrl: null
    }

    componentDidMount() {
        if (this.state.alphaUrl == null && this.props.alphaToken != null) {
            this.setState({ alphaUrl: this.props.alphaToken });
        }
        if (!this.state.loaded) {
            const tabItem = (this.props.alphaUrl !== null && this.props.alphaUrl !== "" && this.props.alphaUrl === "/UserSecurityMaintLookup"
                ? dialogTypes.USER_SECURITY
                : (
                    this.props.alphaUrl === "/LettersMaint"
                        ? dialogTypes.FOLLOW_UP_QA_LETTERS
                        : (this.props.alphaUrl === "/CustomFormsMaint"
                            ? dialogTypes.CUSTOM_FORMS
                            : dialogTypes.ALPHA_MENU)
                )
            );

            if (this.props.alphaUrl !== null && this.props.alphaUrl !== "" && this.props.alphaUrl === "/alpha-dashboard") {
                this.props.closeOtherTabItems();

                /**/
                this.props.setPatientMenu(true);
                this.props.setPageLoaded(false);

                const tabItem = dialogTypes.ALPHA_MENU;
                this.props.addTabItems(tabItem);
                this.props.routingPage(tabItem);
                this.props.setTab(tabItem);

                this.props.setTabName("Dashboard");

                this.props.getTokenOnSession(this.props.userId);
            } else {
                //this.props.addTabItems(tabItem);
                this.props.setAlphaUrl(this.props.url);
                this.props.setDialog(tabItem);
            }

            this.setState({ loaded: true });
            return false;
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.state.loaded) {
            const tabItem = dialogTypes.ALPHA_MENU;

            //this.props.addTabItems(tabItem);
            this.props.setAlphaUrl(this.props.url);
            this.props.setDialog(tabItem);

            this.setState({ loaded: true });
            return false;
        }
        if (this.state.alphaUrl == null) {
            this.props.getTokenOnSession(this.props.userId);
        }
    }

    render() {
        let _url = null;
        {
            _url = this.props.url === "/" || this.props.url === "/login" ? "/alpha-dashboard" : this.props.url;
        }

        return this.props.alphaToken === null ? null : (
            <React.Fragment>
                <Iframe url={this.props.alphaSystemUrlBaseUrl + "/sso.a5w?tk=" + this.props.alphaToken + "&r=" + _url.replace("Launch", "") + ".a5w"}
                    id="iFrameToAlphaSystem"
                    className="aFrameCls"
                    display="initial"
                    position="relative"
                    frameborder="0" allowfullscreen
                    frameBorder="0"
                    target="_self"
                />
            </React.Fragment>
        );
    }
}

Aframe.propTypes = {
    url: PropTypes.string.isRequired
}

const mapStateToProps = state => {
    return {
        alphaSystemUrlBaseUrl: state.patientAbstract.alphaSystemUrlBaseUrl,
        alphaUrl: state.patientAbstract.alphaUrl,
        alphaToken: state.patientAbstract.tokenOnSession,
        userId: state.auth.userId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        setAlphaUrl: (url) => dispatch(actionCreators.setAlphaUrl(url)),
        getTokenOnSession: (uid) => dispatch(actionCreators.getTokenOnSession(uid)),
        closeOtherTabItems: () => dispatch(actionCreators.closeOtherTabItems()),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),

        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setTabName: (name) => dispatch(actionCreators.setTabName(name)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Aframe);
