/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { actionCreators } from "../../../store/PatientAbstract";
import ConfigData from "../../../store/config.json";
import moment from "moment";
import * as actions from '../../../store/actions/index';
import { formatDate } from '@telerik/kendo-intl';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import axios from "axios";
import "../../AbstractManagement/PatientAbstract/Shared/Shared.css";
import Draggable from 'react-draggable';

class KendoGridDateCell extends React.Component {
    render() {
        const value = this.props.dataItem[this.props.field];
        return (
            <td>
                {value ? formatDate(new Date(value), "MM/dd/yyyy") : ""}
            </td>
        );
    }
}

class Patient extends Component {
    state = {
        patients: [],
        isLoading: false,
        filter: {
            logic: "",
            filters: []
        },
        filterOperators: {
            'text': [
                { text: 'grid.filterContainsOperator', operator: 'contains' }
            ],
            'date': [
                { text: 'grid.filterEqOperator', operator: 'eq', format: 'mm/dd/yyyy' }
            ]
        },
        sort: [],
        total: 0,
        skip: 0,
        take: 10,
        isDialogVisible: false,
        isChildDialogVisible: false,
        errorMsg: '',

        enterKeyPressed: false,
        shiftKeyPressed: false,
        tabKeyPressed: false,
    }
    resetPageNumber = false;

    addEventListener = () => {
        var pa = this;
        var patientLookupPage = document.querySelector('#patientAbstractGridView');
        patientLookupPage.addEventListener('keydown', function (e) {

            if (e.keyCode == 40) {// down arrow
                let filterInputFocusing = e.target.getAttribute("class") === "k-input-inner" || e.target.getAttribute("class") === "k-table-th" ? true : false;

                var trs = patientLookupPage.getElementsByClassName("k-master-row");
                if (filterInputFocusing) {
                    for (var len = 0; len < trs.length; len++) {
                        trs[len] ? trs[len].setAttribute("class", len % 2 == 0 ? "k-master-row" : "k-master-row k-alt") : "";
                    }

                    if (trs && trs[0] && trs[0].firstChild) {
                        trs[0] ? trs[0].firstChild.setAttribute('tabindex', '0') : "";
                        trs[0] ? trs[0].firstChild.focus() : "";

                        trs[0] ? trs[0].setAttribute("class", "k-master-row k-state-selected") : "";

                        setTimeout(function () {
                            trs[0] ? trs[0].scrollIntoView() : "";
                            trs[0] ? trs[0].scrollTop = 0 : "";
                        }, 100);
                    }
                } else if (e.target.parentNode.getAttribute("class") !== "k-filter-row") {
                    trs[0] ? trs[0].setAttribute("class", "k-master-row") : "";
                } else if (e.target.parentNode.getAttribute("class") === "k-filter-row") {
                    trs[0] ? trs[0].setAttribute("class", "k-master-row k-state-selected") : "";
                }
            } else if (e.keyCode == 38) {//up arrow
                var pagingLinkFocusing = e.target.getAttribute("class") ? (e.target.getAttribute("class").indexOf("k-link") !== -1 || e.target.getAttribute("class").indexOf("k-button") !== -1 ? true : false) : false;

                let lastItemOnGrid = patientLookupPage.querySelector(".k-grid-table") &&
                    patientLookupPage.querySelector(".k-grid-table").lastChild &&
                    patientLookupPage.querySelector(".k-grid-table").lastChild.childElementCount ? patientLookupPage.querySelector(".k-grid-table").lastChild.childElementCount : 10;
                lastItemOnGrid = lastItemOnGrid - 1;

                var trs = patientLookupPage.getElementsByClassName("k-master-row");
                if (pagingLinkFocusing) {
                    for (var len = 0; len < trs.length; len++) {
                        trs[len] ? trs[len].setAttribute("class", len % 2 == 0 ? "k-master-row" : "k-master-row k-alt") : "";
                    }

                    if (trs && trs[lastItemOnGrid] && trs[lastItemOnGrid].firstChild) {
                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].firstChild.setAttribute('tabindex', '0') : "";
                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].firstChild.focus() : "";

                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].setAttribute("class", "k-master-row k-alt k-state-selected") : "";

                        setTimeout(function () {
                            trs[lastItemOnGrid] ? trs[lastItemOnGrid].scrollIntoView() : "";
                            trs[lastItemOnGrid] ? trs[lastItemOnGrid].scrollTop = 10000 : "";//scroll to bottom of grid
                        }, 100);
                    }
                } else if (e.target.parentNode.getAttribute("data-grid-row-index") === "0") {
                    trs[0] ? trs[0].setAttribute("class", "k-master-row") : "";
                } else {
                    trs[lastItemOnGrid] ? trs[lastItemOnGrid].setAttribute("class", "k-master-row k-alt") : "";
                }
            } else if (e.keyCode == 13 && !pa.enterKeyPressed && e.target.getAttribute("class") === "k-input-inner" && e.target.value != "") {//enter
                pa.setState({ enterKeyPressed: true });
                pa.props.setPageLoaded(false);
            } else {
                pa.setState({ enterKeyPressed: false });
            }
        });
    }

    //
    init = () => {
        document.getElementsByClassName("k-input-inner")[0] ? document.getElementsByClassName("k-input-inner")[0].focus() : "";
        setTimeout(function () {
            document.getElementsByClassName("k-input-inner")[0] ? document.getElementsByClassName("k-input-inner")[0].focus() : "";
        }, 100);

        var patientAbstract = document.getElementById("patientAbstractGridView");
        if (patientAbstract && patientAbstract.querySelectorAll("input.k-input-inner")) {
            var inputs = patientAbstract.querySelectorAll("input.k-input-inner");
            for (var idx = 0; idx < inputs.length; idx++) {
                let input = inputs[idx];
                // disabled all the filter input fields but except for the first one
                idx > 0 ? input.setAttribute("disabled", true) : null;
                if (idx === 4) input.setAttribute("placeholder", "mm/dd/yyyy");
            }
        }

        setTimeout(function () {
            document.getElementsByClassName("k-input-inner")[0] ? document.getElementsByClassName("k-input-inner")[0].focus() : "";
        }, 150);
    }

    componentDidMount = () => {
        this.init();
        this.addEventListener();

        this.getPatients();

        this.props.setPageLoaded(false);
        this.setState({ isLoading: true });
    }

    async getPatients() {
        this.props.setPageLoaded(true);

        const enterKeyPressed = this.resetPageNumber;
        let skipNo = enterKeyPressed ? 0 : this.state.skip;
        let takeNo = enterKeyPressed ? 10 : this.state.take;

        let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetPatientLookupView";

        //
        var access_token = localStorage.getItem('token');
        var params = {
            skip: skipNo,
            take: takeNo,
            accessToken: access_token
        };

        var _filter = {};
        if (this.state.filter !== null) {
            _filter = this.state.filter;
            this.state.filter.filters.map((f, i) => {
                if (f["field"] === "dob" || f["field"] === "combinedLastContactDate") {
                    //url += "&" + f["field"] + "=" + moment(new Date(f["value"])).format("YYYY-MM-DD");
                    params[f["field"]] = moment(new Date(f["value"])).format("YYYY-MM-DD");

                    //#Fixing #PR00427 - Search boxes for last name, first name should do "starts with" search
                } else if (f["field"] === "lastName" || f["field"] === "firstName") {
                    //url += "&" + f["field"] + "=" + encodeURIComponent(f["value"] + "%");
                    params[f["field"]] = (f["value"] + "%");

                } else {
                    //url += "&" + f["field"] + "=" + encodeURIComponent("%" + f["value"] + "%");
                    params[f["field"]] = ("%" + f["value"] + "%");
                }
            });
        }

        // adding sort options
        var _sorter = [];
        if (this.state.sort !== null && this.state.sort.length > 0) {
            _sorter = this.state.sort;
            this.state.sort.map((s, i) => {
                //url += "&sortby=" + s["field"] + "&orderby=" + s["dir"];
                params["sortby"] = s["field"];
                params["orderby"] = s["dir"];
            });
        }
        var _this = this;
        let data = await axios
            .post(url, params)
            .then(function (response) {
                /**/
                var patientAbstract = document.getElementById("patientAbstractGridView");
                if (patientAbstract && patientAbstract.querySelectorAll(".k-input-inner")) {
                    var inputs = patientAbstract.querySelectorAll(".k-input-inner");
                    for (var idx = 0; idx < inputs.length; idx++) {
                        let input = inputs[idx];
                        input.removeAttribute("disabled");
                    }
                }
                /**/
                return response;
            })
            .catch(function (error) {
                console.log(error);
                _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });

        var newAccessToken = data && data.data ? data.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        var pItems = data && data.data ? data.data.patientLookupViewList : null;
        var _patients = [];
        if (pItems !== null) {
            pItems.map((prm, index) => {
                Object.keys(prm).map((t) => {
                    if (t === "dob") {
                        //Filter by Date Only: https://docs.telerik.com/kendo-ui/knowledge-base/filter-by-date
                        prm[t] = new Date(prm[t]);
                    }
                });
                _patients[index] = prm;
            });
        }

        this.setState({
            patients: _patients,
            total: data && data.data ? data.data.total : 0,
            filter: _filter,
            sort: _sorter,
            enterKeyPressed: false,
            //#PR00390
            skip: skipNo,
            take: takeNo
        });

        this.props.setPageLoaded(true);

        var pa = this;
        setTimeout(function () {
            pa.props.showOrHideLoading(false);
            return false;
        }, 1500);

        return false;
    }

    componentDidUpdate(prevProps) {
        console.log('[componentDidUpdate] Drawing PatientAbstract...');

        if (this.props.tabItems && this.props.tabItems.length == 0
            && (this.props.authRedirectPath === "/patient-abstract" || this.props.authRedirectPath === "/rapid-abstract")) {
            //
            if (this.props.authRedirectPath === "/rapid-abstract") {
                this.props.setRapidAbstract(true);
            } else {
                this.props.setRapidAbstract(false);
            }
        }

        if (((!this.props.pageLoaded || this.state.patients == null) && this.state.enterKeyPressed)
            || (!this.props.pageLoaded && this.state.sort !== null && this.state.sort.length > 0)) {
            this.props.showOrHideLoading(true);
            var pa = this;
            setTimeout(function () {
                pa.getPatients();
            }, 0);
        }
    }

    selectPatient = event => {
        this.props.selectedPatientOnGrid(event.dataItem, this.props.itemIndex);
        this.props.setParentWindowVisible(true);
        // close Dialog screen
        this.props.closeCode();
    }

    closeWindow = () => {
        this.props.setParentWindowVisible(false);
    }

    closeChildWindow = () => {
        this.props.setChildDialogVisible(false);
    }

    pageChange = (event, isFilter) => {
        //
        if (isFilter === "sorting") {
            let _sorter = event.sort;

            this.setState({
                sort: _sorter,
                enterKeyPressed: false
            });
            this.props.setPageLoaded(false);
            this.resetPageNumber = false;
            return false;
        }
        //

        let _filter = {
            logic: "",
            filters: []
        };
        let enterKeyPressed = this.state.enterKeyPressed;
        if (event && event.filter) {
            _filter = event.filter;

            let allowSearch = event.nativeEvent.target.value != "";

            this.setState({
                filter: _filter,
                enterKeyPressed: allowSearch
            });
            this.resetPageNumber = true;
        } else {
            // click a page number on paging area
            _filter = event.target.props.filter && !isFilter ? event.target.props.filter : {
                logic: "",
                filters: []
            };

            let allowSearch = event.nativeEvent.target.value != "";

            this.setState({
                skip: event.page ? event.page.skip : this.state.skip,
                take: event.page ? event.page.take : this.state.take,
                filter: _filter,
                enterKeyPressed: allowSearch
            });
            enterKeyPressed = allowSearch;

            if (event.page) {
                this.resetPageNumber = false;
            } else {
                this.resetPageNumber = true;
            }
        }
        this.props.setPageLoaded(!enterKeyPressed);
    }

    searchByFilter = () => {
        this.resetPageNumber = true;
        this.setState({ enterKeyPressed: true });
        this.props.setPageLoaded(false);
    }

    resetFilter = () => {
        let _filter = {
            logic: "",
            filters: []
        };
        this.setState({
            skip: 0,
            take: 10,
            filter: _filter,
            enterKeyPressed: true,
            sort: [] // Also clear all sorter
        });
        this.resetPageNumber = true;
        this.props.setPageLoaded(false);
    }

    //------------------------------------------------------------------------
    //https://www.telerik.com/kendo-react-ui/components/grid/accessibility/
    handleRowClick = (props) => {
        const data = this.state.patients;
        const newData = data.map((item) => ({
            ...item,
            selected: item.id === props.dataItem.id,
        }));
        this.setState({ patients: newData });
    };

    handleNavigationAction = (event) => {
        const { focusElement } = event;
        const data = this.state.patients;

        const rowId = focusElement.parentElement.getAttribute("data-id");

        if (rowId) {
            const newData = data.map((item) => ({
                ...item,
                selected: String(item.id) === rowId,
            }));
            this.setState({ patients: newData });
        }
    };

    rowRender = (trElement, rowProps) => {
        const trProps = {
            ...trElement.props,
            ["data-id"]: rowProps.dataItem.id,
        };
        return React.cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    };

    handleKeyDownAction = (props) => {
        var event = props.nativeEvent;
        if (event.key == "Enter") {
            event.target.click();
        }
    }
    //------------------------------------------------------------------------
    closeCode = (e) => {
        try {
            e ? e.preventDefault() : "";
        } catch (e) { }
        this.props.closeCode();
        return false;
    }

    render() {
        return (
            <div className="CRStar_Window">
                <div className="fake_popup_cls"></div>
                <Draggable handle=".k-window-titlebar">
                    <div className="dragableLargestWindowCls" id="patientAbstractGridView" style={{ zIndex: this.props.isMaint ? "2" : "3" }}>
                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                            <div className="k-window-title k-dialog-title">{this.props.titleDialog}</div>
                            <div className="k-window-actions k-dialog-actions">
                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeCode(e) }}></span>
                                </a>
                            </div>
                        </div>
                        <Grid
                            //style={{ height: '600px', width: '1600px' }}
                            data={this.state.patients != undefined && this.state.patients.length !== 0 ? this.state.patients : null}
                            filter={this.state.filter}
                            filterable
                            filterOperators={this.state.filterOperators}
                            onFilterChange={e => { this.pageChange(e, true) }}
                            onRowClick={this.selectPatient}
                            pageable
                            onPageChange={e => { this.pageChange(e, false) }}
                            scrollable="scrollable"
                            sortable
                            sort={this.state.sort}
                            onSortChange={e => { this.pageChange(e, "sorting") }}
                            skip={this.state.skip}
                            take={this.state.take}
                            total={this.state.total}

                            selectedField="selected"
                            rowRender={this.rowRender}
                            navigatable={true}
                            onNavigationAction={this.handleNavigationAction}

                            onKeyDown={this.handleKeyDownAction}
                        >
                            <GridColumn field="abstSunaFlag" filterable={false} title="S/A" width="24px" />
                            <GridColumn field="socSecNbr" filter="text" title="SSN" width="75px" />
                            <GridColumn field="medRecNbr" filter="text" title="MRN" width="105px" />
                            <GridColumn field="lastName" filter="text" title="Last Name" width="110px" />
                            <GridColumn field="firstName" filter="text" title="First Name" width="110px" />
                            <GridColumn field="middleName" filterable={false} title="Middle Name" width="85px" headerAttributes={{ rowspan: "2", class: "breakWord20" }} />
                            <Column field="dob" filter="text" title="DOB" width="84px" cell={KendoGridDateCell} format="{MM/dd/yyyy}" />
                            <GridColumn field="siteCode" filter="text" title="Site" width="34px" />
                            <GridColumn field="seqPrim" filterable={false} title="Seq" width="34px" />
                            <GridColumn field="topoCode" filterable={false} title="Topo" width="39px" />
                            <GridColumn field="histology" filterable={false} title="Histology" width="62px" />
                            <GridColumn field="laterality" filterable={false} title="Laterality" width="64px" />
                            <GridColumn field="diagnosisDate" filterable={false} title="Dx Date" width="80px" template="<pre>#=diagnosisDate#</pre>" />
                            <GridColumn field="hosp" filter="text" title="Hosp" width="36px" />
                            <GridColumn field="class" filterable={false} title="Class" width="36px" />
                            <GridColumn field="combinedLastPtStatus" filterable={false} title="Patient Status" width="88px" headerAttributes={{ rowspan: "2", class: "breakWord20" }} />
                            <Column field="combinedLastContactDate" filterable={false} title="Last Contact" width="80px" cell={KendoGridDateCell} />
                            <GridColumn field="accNbr" filter="text" title="Acc Nbr" width="100px" />
                        </Grid>

                        <div style={{ marginLeft: "-1px", marginRight: "-1px", marginBottom: "-1px", padding: "10px", textAlign: "center", backgroundColor: "#c5ddfe" }}>
                            <button id="btnExitPatientsLookupView" onClick={this.props.closeCode}>Exit</button>
                        </div>
                    </div>
                </Draggable>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentDialog: state.patientAbstract.currentDialog,
        childDialog: state.patientAbstract.childDialog,
        isChildDialogVisible: state.patientAbstract.isChildDialogVisible,
        isParentWindowVisible: state.patientAbstract.isParentWindowVisible,
        isAuthenticated: state.auth.token !== null,
        pageLoaded: state.patientAbstract.pageLoaded,
        authRedirectPath: state.auth.authRedirectPath,
        //
        clientgroup: state.auth.clientgroup,
        username: state.auth.userId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        selectedPatientOnGrid: (obj, idx) => dispatch(actionCreators.selectedPatientOnGrid(obj, idx)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Patient);
