/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import "../SelectAPop/SelectAPop.css";
import Draggable from 'react-draggable';
import { TabContent, TabPane, Nav, NavLink } from "reactstrap";
import classnames from "classnames";
//import moment from "moment";
import PopLabel from "../PopLabel/PopulationLabel";

// import bootstrap css  
import 'bootstrap/dist/css/bootstrap.css';

// Step 1 - Include react
//import React from "react";

// Step 2 - Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Step 3 - Include the fusioncharts library
import FusionCharts from "fusioncharts";
// Step 4 - Include the chart type
import Charts from "fusioncharts/fusioncharts.charts";

// Step 5 - Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

// Step 6 - Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

class GraphAnItem extends Component {
    downloadFile = false;
    state = {
        uAllItemFields: null,
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        fieldInFocus: "populationLabel",
        focusingField: null,
        isHelpVisible: false,
        loaded: false,

        isCheckedCreatePopulationLabel: false,
        isCheckedIncludeDuplicatesSharedPatients: false,

        isCheckedAnalytic: false,
        isCheckedComplete: false,
        isCheckedInComplete: false,
        isCheckedNonReportable: false,

        selectedDisplayItemAsOption: null,
        selectedCaseOption: null,
        selectedOutputTypeOption: null,

        // LabelsSiteMv
        labelsSiteMv: null,
        labelsSiteMvItemFields: null,
        labelsSiteMvLoading: false,
        selectedLabelsSiteMvRowIndex: 0,
        labelsSiteMvGridFocusing: false,
        setEventHandlerForLabelsSiteMvGrid: false,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        isShowConfirmDeleteMsg: false,
        isShowConfirmDeleteLabelMsg: false,
        confirmed: null,
        monitoring: null,
        isShowEditSelection: false,

        //Registrars -> Initials
        userInitials: "",
        labelsSiteMvMaxlengthInput: null,
        allowSaveAnyway: true,
        availableHosps: null,
        getAvailableHosps: false,
        hospitalCodeMsgErr: null,

        //Chart configs
        isDrawingCharts: false,
        showingByGraphType: "",
        setValueByGraphTypeCompleted: false,

        listOfItems: null,
        chartDatas: null,
        trHtml: null,
        activeTab: "0",
        printStyle: null,

        //
        titleDialog: null,
        showClockTimer: false,
        startTimeFrom: null,
        currentTime: null,
        showMessageError: false,
    }
    initialPage = true;

    toggleTab = (event, tabIndex) => {
        var graphType = this.state.listOfItems && this.state.listOfItems[tabIndex] && this.state.listOfItems[tabIndex]["graphType"] ? this.state.listOfItems[tabIndex]["graphType"] : "";

        var _this = this;
        setTimeout(function () {
            _this.setState({ activeTab: tabIndex, showingByGraphType: graphType });

            // reload PieCharts by URL with latest version again!!!
            var listOfItems = _this.state.listOfItems ? _this.state.listOfItems : [];
            if (graphType == "pie" && listOfItems && listOfItems.length > 0 && listOfItems[tabIndex] && listOfItems[tabIndex]["graphAnItemPieChart"]) {
                var chartUrl = listOfItems[tabIndex]["graphAnItemPieChart"];
                var currTime = new Date().getTime();
                if (document.getElementById("areaShowingFusionCharts_" + tabIndex) && chartUrl) {
                    //document.getElementById("areaShowingFusionCharts_"+tabIndex).contentWindow.location.reload(true);
                    document.getElementById("areaShowingFusionCharts_" + tabIndex).src = chartUrl + "?alias=" + _this.props.clientgroup + "&version=" + currTime;
                    document.getElementById("areaShowingFusionCharts_" + tabIndex).contentWindow.location.href = chartUrl + "?alias=" + _this.props.clientgroup + "&version=" + currTime;
                }
            }
            return false;

        }, 0);

        return false;
    }

    changeEventFnc = (event) => {
    }

    componentWillUnmount = () => {
        this.setState({
            isDrawingCharts: false,
            showingByGraphType: "",
            setValueByGraphTypeCompleted: false,
            listOfItems: null,
            chartDatas: null,
            trHtml: null,
            activeTab: "0",
            printStyle: null,
        });
    }

    //
    changeGraphType = (event) => {
        var index = this.state.activeTab;//tabs index
        var value = event.target.value;

        //
        setTimeout(function () {
            document.getElementById("linkOfItemOnGraph_tab" + index) ? document.getElementById("linkOfItemOnGraph_tab" + index).click() : "";
        }, 500);

        //
        var _this = this;
        setTimeout(function () {
            _this.setState(prevState => {
                const newItems = [...prevState.listOfItems];
                newItems[index] && newItems[index]["graphType"] ? newItems[index]["graphType"] = value : null;

                return {
                    listOfItems: newItems,
                    showingByGraphType: value,
                };
            });
        }, 100);

        return false;
    }

    selectionDisplayItemAs = (event, index) => {
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        this.setState(prevState => {
            const newItems = [...prevState.labelsSiteMv];
            newItems[index]["displayItemAs"] = value;

            return {
                labelsSiteMv: newItems,
                isFormDirty: true,
                fieldInFocus: fc,
                focusingField: fs
            };
        });

        this.props.storeToGlobal(null);

        return false;
    }
    selectionGraphType = (event, index) => {
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        this.setState(prevState => {
            const newItems = [...prevState.labelsSiteMv];
            newItems[index]["graphType"] = value;

            return {
                labelsSiteMv: newItems,
                isFormDirty: true,
                fieldInFocus: fc,
                focusingField: fs
            };
        });

        this.props.storeToGlobal(null);

        return false;
    }

    selectionOutputType = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedOutputTypeOption: event.target.value,
            dialogForm: dialogFormData,
            fieldInFocus: fc,
            focusingField: fs
        });

        this.props.storeToGlobal(null);
    }

    toggleChecked = (event, index) => {
        var fs = event.target;
        var fc = fs ? fs.name : null;

        const checked = this.state.labelsSiteMv[index][fs.name] == "1" ? true : false;
        this.setState(prevState => {
            const newItems = [...prevState.labelsSiteMv];
            newItems[index][fs.name] = !checked ? "1" : "0";

            return {
                labelsSiteMv: newItems,
                isFormDirty: true,
                fieldInFocus: fc,
                focusingField: fs
            };
        });

        this.props.storeToGlobal(null);

        return false;
    }

    keydownHandler = (event) => {
        if (event.target.getAttribute("class") === "copyRowBtnOnGrid") {
            if (event.key === "Tab" && !event.shiftKey) {
                if (event.target.parentNode.parentNode.nextSibling !== null) {
                    let rindex = event.target.getAttribute("data-index");
                    rindex = parseInt(rindex) + 1;
                    setTimeout(function () {
                        document.getElementById("globalItemNumber" + rindex) ? document.getElementById("globalItemNumber" + rindex).focus() : "";
                    }, 100);
                } else {
                    setTimeout(function () {
                        document.getElementById("btnAddNewLatestLabelsMv") ? document.getElementById("btnAddNewLatestLabelsMv").focus() : "";
                    }, 100);
                }
            }
        }
    }

    //
    copyRowLabelsMvGrid = (event, selectedRow) => {
        event.preventDefault();

        var labelsMvList = [...this.state.labelsMv];
        if (selectedRow == labelsMvList.length - 1) {
            this.addNewLabelsMv(event);
            return false;
        }

        this.setState(prevState => {
            const newItems = [...this.state.labelsMv];
            const selectedItem = newItems[selectedRow];

            const newLabels = {};
            Object.keys(selectedItem).map((t, k) => {
                if (t === "labelMvId" || t === "labelId" || t == "globalItemNumber") {
                    newLabels[t] = 0;
                } else {
                    newLabels[t] = selectedItem[t];
                }
            });
            newItems.splice(selectedRow + 1, 1);
            newItems.splice(selectedRow + 1, 0, newLabels);

            // 
            const newFields = [...this.state.labelsMvItemFields];
            const selectedFields = newFields[selectedRow];

            const newField = {};
            Object.keys(selectedFields).map((t, k) => {
                if (t === "labelMvId" || t === "labelId" || t == "globalItemNumber") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.splice(selectedRow + 1, 1);
            newFields.splice(selectedRow + 1, 0, newField);

            return {
                labelsMv: newItems,
                labelsMvItemFields: newFields,
                isFormDirty: true,
            };
        });

        return false;
    }

    //-->
    deleteLabelsSiteMvGrid = (flg) => {
        let selectedRow = this.state.selectedLabelsSiteMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.labelsSiteMv.length) {
            selectedRow = this.state.labelsSiteMv.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.labelsSiteMv];
            if (newItems.length === 1) {
                const _newItems = [...this.state.labelsSiteMv];
                const firstItem = _newItems[0];

                const newItem = {};
                Object.keys(firstItem).map((t, k) => {
                    if (t === "id" || t === "labelId") {
                        //newItem[t] = 0;
                    } else {
                        newItem[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newItem);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.labelsSiteMvItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.labelsSiteMvItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { labelsSiteMv: newItems, labelsSiteMvItemFields: newFields, selectedLabelsSiteMvRowIndex: selectedRow };
        });

        flg === "1" ? this.setState({ isFormDirty: true }) : "";
    }

    hideConfirmDeleteMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isShowConfirmDeleteMsg: false });

        if (event.target.value === "yes" && this.state.monitoring) {
            switch (this.state.monitoring) {
                case "labelsSiteMv":
                    this.deleteLabelsSiteMvGrid("1");
                    break;
                default:
                    break;
            }
        }
        return false;
    }

    insertNewLabelsSiteMvGrid = (insertedRow) => {
        this.setState(prevState => {
            const newItems = [...this.state.labelsSiteMv];
            const firstItems = newItems[0];

            const newLabelsSite = {};
            Object.keys(firstItems).map((t, k) => {
                if (t === "id" || t === "labelId") {
                    newLabelsSite[t] = 0;
                } else {
                    newLabelsSite[t] = "";
                }
            });
            newItems.splice(insertedRow, 0, newLabelsSite);

            // 
            const newFields = [...this.state.labelsSiteMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "labelId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.splice(insertedRow, 0, newField);

            return {
                labelsSiteMv: newItems,
                labelsSiteMvItemFields: newFields,
                //isFormDirty: true,
            };
        });
        return false;
    }

    addEventListenerFnc = (om) => {
        var labelsSiteMvGrid = document.getElementById('labelsSiteMvListArea');
        labelsSiteMvGrid.addEventListener('keydown', function (e) {
            if (e.keyCode == 45 && e.target.getAttribute("data-index")) {// Insert key
                var insertedRowIdx = om.state.selectedLabelsSiteMvRowIndex;
                om.insertNewLabelsSiteMvGrid(insertedRowIdx);
                setTimeout(function () {
                    var selectedIndex = e.target.getAttribute("data-index");
                    document.getElementById('itemNbr' + selectedIndex) ? document.getElementById('itemNbr' + selectedIndex).focus() : "";//e.target.focus();
                    return false;
                }, 10);
                return false;
            } else if (e.keyCode == 46 && e.target.getAttribute("data-index")) {// Delete key
                //om.deleteSelectedLabelsSiteMv();//btnDeleteSelectedLabelsSiteMv
                return false;
            }
            return false;
        });
        return false;
    }

    // LabelsSiteMv:: Site Codes grid
    handleLabelsSiteMvLostfocus = (event, index) => {
        try {
            event.preventDefault();
        } catch (ex) { }

        const name = event.target.name;
        var value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            //this.props.checkValidInput(name, value, table, key, index);
        } else {
            this.setState(prevState => {
                const newItems = [...prevState.labelsSiteMvItemFields];
                newItems[index][name] = "";
                return { labelsSiteMvItemFields: newItems };
            });
        }

        //Fill_Dictionary_Name
        if (name == "itemNbr") {
            if (value && value != "") {
                var isIntVal = false;
                try {
                    parseInt(value);
                    isIntVal = true;
                } catch (ex) {
                }

                if (isIntVal) {
                    //let url = this.props.clientgroup + "/FillDictionaryName?itemNbr=" + value;
                    let url = this.props.clientgroup + "/FillDictionaryName";
                    var _this = this;
                    axios.post(url, { itemNbr: value, accessToken: localStorage.getItem('token') })
                        .then(res => {
		                    //update new accessToken again
		                    var newAccessToken = res && res.data ? res.data.accessToken : null;
		                    if (newAccessToken) {
		                        localStorage.setItem('token', newAccessToken);
		                    }

                            const globalDir = res.data.globalDir;
                            if (!res.data.error && globalDir && globalDir.itemNbr) {
                                try {
                                    _this.setState(prevState => {
                                        const newItems = [...prevState.labelsSiteMv];
                                        newItems[index]["itemDesc"] = globalDir.itemDesc;
                                        newItems[index]["columnName"] = globalDir.columnName;
                                        newItems[index]["fileName"] = globalDir.fileName;
                                        newItems[index]["codeFile"] = globalDir.codeFile;
                                        newItems[index]["dataType"] = globalDir.dataType;

                                        return { labelsSiteMv: newItems };
                                    });
                                } catch (ex) { }
                            } else {
                                //clear all set values
                                try {
                                    _this.setState(prevState => {
                                        const newItems = [...prevState.labelsSiteMv];
                                        newItems[index]["itemDesc"] = "";
                                        newItems[index]["columnName"] = "";
                                        newItems[index]["fileName"] = "";
                                        newItems[index]["codeFile"] = "";
                                        newItems[index]["dataType"] = "";

                                        return { labelsSiteMv: newItems };
                                    });
                                } catch (ex) { }
                            }
                            return false;
                        })
	                    .catch(function (error) {
	                        console.log(error);

	                        var btnLogout = document.getElementById("logoutButtonArea");
	                        if (btnLogout) {
	                            btnLogout.querySelector("a").click();
	                        }
	                        return false;
	                    });
                    return false;
                }
            } else {
                //clear all set values
                try {
                    this.setState(prevState => {
                        const newItems = [...prevState.labelsSiteMv];
                        newItems[index]["itemDesc"] = "";
                        newItems[index]["columnName"] = "";
                        newItems[index]["fileName"] = "";
                        newItems[index]["codeFile"] = "";
                        newItems[index]["dataType"] = "";

                        return { labelsSiteMv: newItems };
                    });
                } catch (ex) { }
            }
        } else if (name == "itemDesc") {
            let itemNo = event.target.getAttribute("data-index");
            let value = document.getElementById("itemNbr" + itemNo).value;
            if (value && value != "") {
                var isIntVal = false;
                try {
                    parseInt(value);
                    isIntVal = true;
                } catch (ex) {
                }

                if (isIntVal) {
                    //let url = this.props.clientgroup + "/FillDictionaryName?itemNbr=" + value;
                    let url = this.props.clientgroup + "/FillDictionaryName";
                    var _this = this;
                    axios.post(url, { itemNbr: value, accessToken: localStorage.getItem('token') })
                        .then(res => {
		                    //update new accessToken again
		                    var newAccessToken = res && res.data ? res.data.accessToken : null;
		                    if (newAccessToken) {
		                        localStorage.setItem('token', newAccessToken);
		                    }

                            const globalDir = res.data.globalDir;
                            if (!res.data.error && globalDir && globalDir.itemNbr) {
                                try {
                                    _this.setState(prevState => {
                                        const newItems = [...prevState.labelsSiteMv];
                                        newItems[index]["itemDesc"] = globalDir.itemDesc;
                                        newItems[index]["columnName"] = globalDir.columnName;
                                        newItems[index]["fileName"] = globalDir.fileName;
                                        newItems[index]["codeFile"] = globalDir.codeFile;
                                        newItems[index]["dataType"] = globalDir.dataType;

                                        return { labelsSiteMv: newItems };
                                    });
                                } catch (ex) { }
                            } else {
                                //clear all set values
                                try {
                                    _this.setState(prevState => {
                                        const newItems = [...prevState.labelsSiteMv];
                                        newItems[index]["itemDesc"] = "";
                                        newItems[index]["columnName"] = "";
                                        newItems[index]["fileName"] = "";
                                        newItems[index]["codeFile"] = "";
                                        newItems[index]["dataType"] = "";

                                        return { labelsSiteMv: newItems };
                                    });
                                } catch (ex) { }
                            }
                            return false;
                        })
                        .catch(function (error) {
                            console.log(error);

                            var btnLogout = document.getElementById("logoutButtonArea");
                            if (btnLogout) {
                                btnLogout.querySelector("a").click();
                            }
                            return false;
                        });
                    return false;
                }
            } else {
                //clear all set values
                try {
                    this.setState(prevState => {
                        const newItems = [...prevState.labelsSiteMv];
                        newItems[index]["itemDesc"] = "";
                        newItems[index]["columnName"] = "";
                        newItems[index]["fileName"] = "";
                        newItems[index]["codeFile"] = "";
                        newItems[index]["dataType"] = "";

                        return { labelsSiteMv: newItems };
                    });
                } catch (ex) { }
            }
        }

        return false;
    }
    handleLabelsSiteMvGridChange = (event, index) => {
        const name = event.target.name;
        var value = event.target.value;

        let name2 = null;
        let value2 = null;
        if (event.target2) {
            name2 = event.target2.name;
            value2 = event.target2.value;
        }

        this.setState(prevState => {
            const newItems = [...prevState.labelsSiteMv];
            newItems[index][name] = value;

            if (name2 && value2) {
                newItems[index][name2] = value2;
            }

            return { labelsSiteMv: newItems, isFormDirty: true };
        });

        //Fill_Dictionary_Name
        if (name == "itemNbr" || name2 == "itemNbr") {
            if (name2 == "itemNbr") value = value2;
            if (value && value != "") {
                var isIntVal = false;
                try {
                    parseInt(value);
                    isIntVal = true;
                } catch (ex) {
                }

                if (isIntVal) {
                    //let url = this.props.clientgroup + "/FillDictionaryName?itemNbr=" + value;
                    let url = this.props.clientgroup + "/FillDictionaryName";
                    var _this = this;
                    axios.post(url, { itemNbr: value, accessToken: localStorage.getItem('token') })
                        .then(res => {
		                    //update new accessToken again
		                    var newAccessToken = res && res.data ? res.data.accessToken : null;
		                    if (newAccessToken) {
		                        localStorage.setItem('token', newAccessToken);
		                    }

                            const globalDir = res.data.globalDir;
                            if (!res.data.error && globalDir && globalDir.itemNbr) {
                                try {
                                    _this.setState(prevState => {
                                        const newItems = [...prevState.labelsSiteMv];
                                        //Item_Desc<Item_Desc,Column_Name<Column_Name,File_Name<File_Name,Code_File<Code_File,Data_Type<Data_Type
                                        newItems[index]["itemDesc"] = globalDir.itemDesc;
                                        newItems[index]["columnName"] = globalDir.columnName;
                                        newItems[index]["fileName"] = globalDir.fileName;
                                        newItems[index]["codeFile"] = globalDir.codeFile;
                                        newItems[index]["dataType"] = globalDir.dataType;

                                        return { labelsSiteMv: newItems };
                                    });
                                } catch (ex) { }
                            } else {
                                //clear all set values
                                try {
                                    _this.setState(prevState => {
                                        const newItems = [...prevState.labelsSiteMv];
                                        //newItems[index]["itemNbr"] = "0";
                                        newItems[index]["itemDesc"] = "";
                                        newItems[index]["columnName"] = "";
                                        newItems[index]["fileName"] = "";
                                        newItems[index]["codeFile"] = "";
                                        newItems[index]["dataType"] = "";

                                        return { labelsSiteMv: newItems };
                                    });
                                } catch (ex) { }
                            }
                            return false;
                        })
	                    .catch(function (error) {
	                        console.log(error);

	                        var btnLogout = document.getElementById("logoutButtonArea");
	                        if (btnLogout) {
	                            btnLogout.querySelector("a").click();
	                        }
	                        return false;
	                    });
                    return false;
                }
            } else {
                //clear all set values
                try {
                    this.setState(prevState => {
                        const newItems = [...prevState.labelsSiteMv];
                        //newItems[index]["itemNbr"] = "0";
                        newItems[index]["itemDesc"] = "";
                        newItems[index]["columnName"] = "";
                        newItems[index]["fileName"] = "";
                        newItems[index]["codeFile"] = "";
                        newItems[index]["dataType"] = "";

                        return { labelsSiteMv: newItems };
                    });
                } catch (ex) { }
            }
        } else if (name == "_itemDesc_") {
            let itemNo = event.target.getAttribute("data-index");
            let value = document.getElementById("itemNbr" + itemNo).value;
            if (value && value != "") {
                var isIntVal = false;
                try {
                    parseInt(value);
                    isIntVal = true;
                } catch (ex) {
                }

                if (isIntVal) {
                    //let url = this.props.clientgroup + "/FillDictionaryName?itemNbr=" + value;
                    let url = this.props.clientgroup + "/FillDictionaryName";
                    var _this = this;
                    axios.post(url, { itemNbr: value, accessToken: localStorage.getItem('token') })
                        .then(res => {
		                    //update new accessToken again
		                    var newAccessToken = res && res.data ? res.data.accessToken : null;
		                    if (newAccessToken) {
		                        localStorage.setItem('token', newAccessToken);
		                    }

                            const globalDir = res.data.globalDir;
                            if (!res.data.error && globalDir && globalDir.itemNbr) {
                                try {
                                    _this.setState(prevState => {
                                        const newItems = [...prevState.labelsSiteMv];
                                        newItems[index]["itemDesc"] = globalDir.itemDesc;
                                        newItems[index]["columnName"] = globalDir.columnName;
                                        newItems[index]["fileName"] = globalDir.fileName;
                                        newItems[index]["codeFile"] = globalDir.codeFile;
                                        newItems[index]["dataType"] = globalDir.dataType;

                                        return { labelsSiteMv: newItems };
                                    });
                                } catch (ex) { }
                            } else {
                                //clear all set values
                                try {
                                    _this.setState(prevState => {
                                        const newItems = [...prevState.labelsSiteMv];
                                        newItems[index]["itemDesc"] = "";
                                        newItems[index]["columnName"] = "";
                                        newItems[index]["fileName"] = "";
                                        newItems[index]["codeFile"] = "";
                                        newItems[index]["dataType"] = "";

                                        return { labelsSiteMv: newItems };
                                    });
                                } catch (ex) { }
                            }
                            return false;
                        })
	                    .catch(function (error) {
	                        console.log(error);

	                        var btnLogout = document.getElementById("logoutButtonArea");
	                        if (btnLogout) {
	                            btnLogout.querySelector("a").click();
	                        }
	                        return false;
	                    });
                    return false;
                }
            } else {
                //clear all set values
                try {
                    this.setState(prevState => {
                        const newItems = [...prevState.labelsSiteMv];
                        newItems[index]["itemDesc"] = "";
                        newItems[index]["columnName"] = "";
                        newItems[index]["fileName"] = "";
                        newItems[index]["codeFile"] = "";
                        newItems[index]["dataType"] = "";

                        return { labelsSiteMv: newItems };
                    });
                } catch (ex) { }
            }
        }

        return false;
    }

    addNewLabelsSiteMv = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.labelsSiteMv];
            const firstLabels = newItems[0];

            const newLabels = {};
            Object.keys(firstLabels).map((t, k) => {
                if (t === "id" || t === "itemNbr" || t == "itemNumber" || t == "labelId") {
                    newLabels[t] = 0;
                } else {
                    newLabels[t] = null;
                }
            });
            newLabels.displayItemAs = "code";
            newLabels.graphType = "pie";
            newItems.push(newLabels);

            // 
            const newFields = [...this.state.labelsSiteMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "itemNbr" || t == "itemNumber" || t == "labelId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { labelsSiteMv: newItems, labelsSiteMvItemFields: newFields };
        });
    }

    deleteSelectedLabelsSiteMv = (event) => {
        event ? event.preventDefault() : null;

        let selectedRow = this.state.selectedLabelsSiteMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.labelsSiteMv.length) {
            selectedRow = this.state.labelsSiteMv.length - 1;
        }

        if (this.state.labelsSiteMv[selectedRow] && this.state.labelsSiteMv[selectedRow]["id"] !== undefined && this.state.labelsSiteMv[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "labelsSiteMv" });
        } else {
            this.deleteLabelsSiteMvGrid("0");
        }
        return false;
    }

    setSelectionLabelsSiteMvRow = (event, index) => {
        this.setState({ selectedLabelsSiteMvRowIndex: index });
    }

    getHospSecurity = () => {
        let url = this.props.clientgroup + "/GetUserByEmail?email=" + this.props.userId;
        var _this = this;
        //axios.get(url)
        axios.post(url, { email: this.props.userId, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }
                const availableHospsRpt = res.data.user !== null && res.data.user.availableHospsRpt !== null ? res.data.user.availableHospsRpt : "";
                if (!res.data.error && res.data.user !== null
                    && availableHospsRpt !== null && availableHospsRpt !== "") {
                    var _availableHospsRptArr = availableHospsRpt.split('|');
                    _availableHospsRptArr = _availableHospsRptArr.filter((obj, idx) => {
                        return obj && obj != "";
                    });
                    _this.setState({ availableHosps: _availableHospsRptArr.join(',') });
                } else {
                    _this.setState({ availableHosps: ",All," });//#Full control
                }
                return false;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
        return false;
    }

    initClock() {
        var _this = this;
        setInterval(function () {
            var today = new Date(),
                time = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            //d.toLocaleTimeString();
            _this.setState({ currentTime: time });

            return false;
        }, 1000);
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showMessageError: false,
            hasError: false,
            confirmed: true
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector("#BUTTON_SELECT_POPULATION") ? document.querySelector("#BUTTON_SELECT_POPULATION").focus() : "";
        }, 0);

        return false;
    }

    componentDidMount() {
        if (!this.state.dialogForm && this.props.selectedLabelPopInfo) {
            this.setFormData(this.props.selectedLabelPopInfo);
        }
    }

    componentWillMount() {
        this.props.setChildrenOpening(false);
        this.setState({ setEventHandlerForGrid: false, availableHosps: null });

        this.initClock();
    }

    componentDidUpdate(prevProps) {
        //
        if (!this.props.selectedLabelPopInfo) {
            this.initialPage = true;
            return false;
        }

        if (this.props.error && this.props.errorMessage) {
            this.setState({ showClockTimer: false, showMessageError: true, errorMessage: this.props.errorMessage });

            document.querySelector('#BUTTON_SELECT_POPULATION') ? document.querySelector('#BUTTON_SELECT_POPULATION').focus() : null;

            this.props.clearErrorMessage();
            return false;
        }

        if (!this.state.setEventHandlerForGrid) {
            if (document.getElementById('labelsSiteMvListArea')) {
                this.addEventListenerFnc(this);
                this.setState({ setEventHandlerForGrid: true });
            }
            //Create_Js_Clock()
        }

        /**/
        if (!this.state.getAvailableHosps) {
            this.getHospSecurity();
            this.setState({ getAvailableHosps: true });
        }
        /**/

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.dialogForm && this.state.uAllItemFields
        ) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                // updating the grid
                if (this.props.currentSelectedIndex !== -1) {
                    // LabelsSiteMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.labelsSiteMvItemFields];

                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "";
                        }
                        return { labelsSiteMvItemFields: newItems };
                    });
                } else {
                    this.setState({
                        uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "" },
                        // And also update name again
                        dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                    });
                }
            } else {
                // updating the grid
                if (this.props.currentSelectedIndex != -1) {
                    // LabelsSiteMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.labelsSiteMvItemFields];

                        if (newItems && newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "IN_VALID";
                        }
                        return { labelsSiteMvItemFields: newItems };
                    });
                } else {
                    this.setState({
                        uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                    });
                }
            }
            this.props.clearCommonLookupItem();
        }

        if ((!this.state.dialogForm && this.props.selectedLabelPopInfo)
            || (prevProps.selectedLabelPopInfo && this.props.selectedLabelPopInfo && (prevProps.selectedLabelPopInfo.labelId !== this.props.selectedLabelPopInfo.labelId || prevProps.selectedLabelPopInfo.populationLabel !== this.props.selectedLabelPopInfo.populationLabel))
            || (prevProps.selectedLabelPopInfo && this.props.selectedLabelPopInfo && (prevProps.selectedLabelPopInfo.startTime !== this.props.selectedLabelPopInfo.startTime || prevProps.selectedLabelPopInfo.endTime !== this.props.selectedLabelPopInfo.endTime))
            || (this.state.reload && this.props.selectedLabelPopInfo)
            || this.initialPage
        ) {
            this.setFormData(this.props.selectedLabelPopInfo);
            this.initialPage = false;
        }

        if (this.state.userInitials === "") {
            var logedOnUser = this.props.userId;
            var _this = this;
            
            let url = this.props.clientgroup + "/GetUserInitials";
            let params = { uid: logedOnUser, accessToken: localStorage.getItem('token') };

            axios.post(url, params)
                .then(res => {
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        let userInitials = "";
                        if (res.data.message !== null && res.data.message !== "") {
                            userInitials = res.data.message;
                        } else {
                            userInitials = logedOnUser.substr(0, 20);
                        }
                        _this.setState({ userInitials: userInitials });
                    } else {
                        let userInitials = logedOnUser.substr(0, 20);
                        _this.setState({ userInitials: userInitials });
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;
                });
        }

        // labelsSiteMv List
        if (this.props.selectedLabelPopInfo && !this.state.labelsSiteMv && !this.state.labelsSiteMvLoading) {
            this.setState({ labelsSiteMvLoading: true });
            var _this = this;
            //axios.get(this.props.clientgroup + "/GetGlobalDirEmptyList")
            axios.post(this.props.clientgroup + "/GetGlobalDirEmptyList",
                { accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        let labelsSiteMvArr = [];
                        res.data.labelsSiteMvList.map((obj) => {
                            obj["displayItemAs"] = "code";
                            obj["graphType"] = "pie";
                            labelsSiteMvArr.push(obj);
                        });

                        _this.setState({ labelsSiteMv: labelsSiteMvArr });

                        //
                        let maxLengthItem = [];//#1
                        if (!_this.state.labelsSiteMvItemFields && res.data.labelsSiteMvList) {
                            let _labelsSiteMvArr = [];
                            res.data.labelsSiteMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                _labelsSiteMvArr.push(fields);
                                maxLengthItem.length == 0 ? maxLengthItem.push(fields) : null;//#1
                            });

                            _this.setState({ labelsSiteMvItemFields: _labelsSiteMvArr });
                        };

                        // get maxlength for Labels_Site_Mv grid
                        //axios.post(_this.props.clientgroup + "/GetTableStructure?table=Global_Dir")
                        let url = _this.props.clientgroup + "/GetTableStructure";
                        let params = { table: "Global_Dir", accessToken: localStorage.getItem('token') };
                        
                        axios.post(url, params)
                            .then(res => {
                                //update new accessToken again
                                var newAccessToken = res && res.data ? res.data.accessToken : null;
                                if (newAccessToken) {
                                    localStorage.setItem('token', newAccessToken);
                                }

                                if (res.data !== null && res.data.result !== "") {
                                    let maxLengthValue = res.data.result;

                                    maxLengthValue = maxLengthValue.split(',');
                                    for (var i = 0; i < maxLengthValue.length; i++) {
                                        let obj = maxLengthValue[i].split(':');
                                        maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                    }

                                    _this.setState({
                                        labelsSiteMvMaxlengthInput: { ...maxLengthItem }
                                    });
                                }
                            })
                            .catch(function (error) {
                                console.log(error);

                                var btnLogout = document.getElementById("logoutButtonArea");
                                if (btnLogout) {
                                    btnLogout.querySelector("a").click();
                                }
                                return false;
                            });
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;
                });
        }

        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
        //<--
    }

    async getTableStructure() {
        //let url = this.props.clientgroup + "/GetTableStructure?table=Labels";
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "Labels", accessToken: localStorage.getItem('token') };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res && res.data ? res.data.result : null
        });
    }

    setFormData = (selectedLabelPopInfo) => {
        this.initialPage = false;

        var graphType = this.state.showingByGraphType ? this.state.showingByGraphType : "";
        if (!graphType && selectedLabelPopInfo.globalDirs && selectedLabelPopInfo.globalDirs[0] && selectedLabelPopInfo.globalDirs[0]["graphType"]) {
            graphType = selectedLabelPopInfo.globalDirs && selectedLabelPopInfo.globalDirs[0] && selectedLabelPopInfo.globalDirs[0]["graphType"] ? selectedLabelPopInfo.globalDirs[0]["graphType"] : "";
        }

        this.setState({
            isDrawingCharts: false,
            initialDialogForm: {
                ...selectedLabelPopInfo,
            },
            dialogForm: {
                ...selectedLabelPopInfo,
                //startTimeSAP: this.state.startTimeFrom ? this.state.startTimeFrom : selectedLabelPopInfo.startTimeSAP,
                //endTimeSAP: this.state.currentTime ? this.state.currentTime : selectedLabelPopInfo.endTimeSAP,
            },
            reload: false,
            changed: true,
            loaded: true,
            activeTab: 0,

            ["selectedOutputTypeOption"]: selectedLabelPopInfo.sendReportTo ? selectedLabelPopInfo.sendReportTo : "excel",
            listOfItems: selectedLabelPopInfo.globalDirs,
            versionCharts: this.state.versionCharts ? this.state.versionCharts : new Date().getTime(),
            //showingByGraphType: graphType,

            showClockTimer: false,
            titleDialog: null,
        });

        if ((!this.state.uAllItemFields && selectedLabelPopInfo)) {
            let fields = {};
            Object.keys(selectedLabelPopInfo).map((t) => {
                fields[t] = "";
            });

            this.setState({ uAllItemFields: fields, pageMaxlengthInput: fields });
        };

        if (selectedLabelPopInfo.sendReportTo == "screen" && selectedLabelPopInfo.endTime != null) {
            const gt = selectedLabelPopInfo.globalDirs && selectedLabelPopInfo.globalDirs[0] && selectedLabelPopInfo.globalDirs[0]["graphType"] ? selectedLabelPopInfo.globalDirs[0]["graphType"] : graphType;
            this.drawingCharts(gt);
        } else if (selectedLabelPopInfo.sendReportTo == "excel" && selectedLabelPopInfo.urlOutputFile && !this.downloadFile) {
            var _this = this;
            var url = this.props.clientgroup + "/DownloadFile";
            axios.post(url, {
                urlOutputFile: selectedLabelPopInfo.urlOutputFile,
                method: 'POST',
                responseType: 'blob',//important
                accessToken: localStorage.getItem('token')
            }).then((response) => {
                const link = document.createElement('a');
                let excel;
                if (selectedLabelPopInfo.urlOutputFile.indexOf('/') != -1) {
                    excel = selectedLabelPopInfo.urlOutputFile.substring(selectedLabelPopInfo.urlOutputFile.lastIndexOf('/') + 1);
                } else {
                    excel = selectedLabelPopInfo.urlOutputFile.substring(selectedLabelPopInfo.urlOutputFile.lastIndexOf('\\') + 1);
                }
                link.href = this.props.clientgroup + "/" + excel;
                
                link.setAttribute('download', `Graph For ` + selectedLabelPopInfo.populationLabel + `.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.remove();

                _this.downloadFile = true;
                return false;
            })
            .catch(function (error) {
                console.log(error);
            });

            return false;
        }

        if (!this.state.setValueByGraphTypeCompleted) {
            this.setState({ setValueByGraphTypeCompleted: true, showingByGraphType: graphType });
        }

        return false;
    }

    savePopLabelInfo = (event) => {
        event.preventDefault();
        return false;
    }

    handleOnFocus = (event, index, alias) => {
        this.props.storeToGlobal(null);
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
        if (alias) {
            switch (alias) {
                case "selectedLabelsSiteMvRowIndex":
                    this.setState({ selectedLabelsSiteMvRowIndex: index });
                    break;
            }
        }
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true,
            });
        } else {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value
                },
                isFormDirty: true,
            });
        }
    }

    async Get_Data(pLabel) {
        var _this = this;
        //Labels
        await _this.props.selectAPopLabel(pLabel);

        this.setState({
            reload: true,

            // LabelsSiteMv
            labelsSiteMv: null,
            labelsSiteMvItemFields: null,
            labelsSiteMvLoading: false,
            selectedLabelsSiteMvRowIndex: 0,
            labelsSiteMvGridFocusing: false,
            labelsSiteMvMaxlengthInput: null,

            isShowConfirmDeleteMsg: false,
            isShowConfirmDeleteLabelMsg: false,
            confirmed: null,
            monitoring: null,

            hospitalCodeMsgErr: null,
            isFormDirty: false,
        });

        return false;
    }

    updatePopLabelBackToParent = (plabel) => {
        var form = this.state.dialogForm;
        form.populationLabel = plabel;
        this.setState({
            dialogForm: form,
        });
        return false;
    }

    updateToParent = (plForm) => {
        var reportingForm = this.state.dialogForm;
        if (plForm) {
            Object.keys(plForm).map((t, k) => {
                !reportingForm[t] ? reportingForm[t] = plForm[t] : null;
            });

            let labelsSiteMvSap = plForm.labelsSiteMvSap;
            let labelsMv = plForm.labelsMv;
            let textareaEditSelect = plForm.textareaEditSelect;

            reportingForm.labelsSiteMvSap = labelsSiteMvSap;
            reportingForm.labelsMv = labelsMv;
            reportingForm.textareaEditSelect = textareaEditSelect;
        }

        this.setState({
            dialogForm: reportingForm,
            /*
            dialogForm: {
                ...this.state.dialogForm,
                reportingForm
            },
            */
        });
        return false;
    }

    Run_Graph_For_Population = () => {
        var dialogFormData = this.state.dialogForm;

        var populationLabel = document.getElementById("populationLabel") ? document.getElementById("populationLabel").value : "";
        dialogFormData.populationLabel = populationLabel;
        dialogFormData.labelSAP = populationLabel;

        if (dialogFormData.populationLabel == "") {
            return false;
        }

        var foundData = false;
        let labelsSiteMv = this.state.labelsSiteMv;
        labelsSiteMv.map((mv, index) => {
            if (mv.itemNbr && mv.itemDesc != "") {
                foundData = true;
            }
        });

        if (!foundData) return false;

        dialogFormData.labelsSiteMv = labelsSiteMv;
        dialogFormData.globalDirs = labelsSiteMv;

        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;

        let availableHosps = this.state.availableHosps;
        dialogFormData.availableHospsRpt = availableHosps;
        console.log("runGraphForPopulation::availableHospsRpt=" + availableHosps);

        var createPopulationLabel = document.getElementById("createPopulationLabel") ? document.getElementById("createPopulationLabel").value : "0";
        dialogFormData.createPopulationLabel = createPopulationLabel;

        //
        this.props.runGraphForPopulation(dialogFormData);

        this.downloadFile = false;//reset this flag to get download new file

        return false;
    }
    //--

    //on before show event
    showEvent(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        var popLabelComponent = document.getElementById("btnCallToParentFunc");
        if (popLabelComponent) {
            popLabelComponent.click();
        }

        var _this = this;
        setTimeout(function () {
            var dialogFormData = _this.state.dialogForm;

            var populationLabel = document.getElementById("populationLabel") ? document.getElementById("populationLabel").value : "";
            dialogFormData.populationLabel = populationLabel;
            if (dialogFormData.populationLabel == "") {
                return false;
            }
            console.log("populationLabel = " + populationLabel, dialogFormData.populationLabel);

            var foundData = false;
            let labelsSiteMv = _this.state.labelsSiteMv;
            labelsSiteMv.map((mv, index) => {
                if (mv.itemNbr && mv.itemDesc != "") {
                    foundData = true;
                }
            });

            if (!foundData) return false;


            _this.Run_Graph_For_Population();
            var today = new Date(),
                timeFrom = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            // set clock timer...
            _this.setState({ showClockTimer: true, startTimeFrom: timeFrom });

            return false;
        }, 1000);

        return false;
    }

    Cancel_Report = (e, _this) => {
        e.preventDefault();

        _this.props.cancelSBSReport();

        setTimeout(function () {
            _this.setState({ showClockTimer: false });
            return false;
        }, 0);

        return false;
    }

    //
    drawingCharts = (type) => {
        this.setState({
            isDrawingCharts: true,
            showClockTimer: false,//hide clock timer
            //showingByGraphType: type
        });

        setTimeout(function () {
            document.getElementById("linkOfItemOnGraph_tab0") ? document.getElementById("linkOfItemOnGraph_tab0").click() : null;
            return false;
        }, 500);

        return false;
    }

    hideDrawingCharts = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.setState({ isDrawingCharts: false });
        return false;
    }

    newRecord(e) {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.Get_Data("");
        return false;
    }

    exitScreen(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        this.props.setInitialPageVisible(false);

        this.setState({
            uAllItemFields: null,
            initialDialogForm: null,
            dialogForm: null,
            isFormDirty: false,
            reload: false,
            fieldInFocus: "populationLabel",
            focusingField: null,
            isHelpVisible: false,
            loaded: false,

            // LabelsSiteMv
            labelsSiteMv: null,
            labelsSiteMvItemFields: null,
            labelsSiteMvLoading: false,
            selectedLabelsSiteMvRowIndex: 0,
            labelsSiteMvGridFocusing: false,
            setEventHandlerForLabelsSiteMvGrid: false,

            // set max-length for input fields
            tableStructureList: null,
            pageMaxlengthInput: null,
            hasSetMaxLength: false,

            isShowConfirmDeleteMsg: false,
            isShowConfirmDeleteLabelMsg: false,
            confirmed: null,
            monitoring: null,
            isShowEditSelection: false,

            //Registrars -> Initials
            userInitials: "",
            labelsSiteMvMaxlengthInput: null,
            allowSaveAnyway: true,
            availableHosps: null,
            getAvailableHosps: false,
            hospitalCodeMsgErr: null,

            //Chart configs
            isDrawingCharts: false,
            showingByGraphType: "",
            setValueByGraphTypeCompleted: false,

            listOfItems: null,
            chartDatas: null,
            trHtml: null,
            activeTab: "0",
            printStyle: null,

            //
            titleDialog: null,
            showClockTimer: false,
            startTimeFrom: null,
            currentTime: null,
        });

        return false;
    }

    printTable = () => {
        var printStyle = document.getElementById('printStyle') ? document.getElementById('printStyle').innerHTML : (document.getElementById('PrintStyle') ? document.getElementById('PrintStyle').innerHTML : this.state.printStyle);

        var tabIndex = this.state.activeTab;

        var printContent = document.getElementById('printableContent' + tabIndex);
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Graph An Item</title><style type="text/css">';
        //html += printStyle.innerHTML + '</style></head><body><div id="printableContent">';
        html += printStyle + '</style></head><body><div id="printableContent">';

        html += printContent.innerHTML + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
        return false;
    }

    javascript_void_fnc(event) {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        return false;
    }

    render() {
        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">{this.props.errorMessage ? this.props.errorMessage : this.state.errorMessage}</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTimeFrom}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
                : null;
        }

        var listOfItems = this.state.listOfItems ? this.state.listOfItems : [];
        let navLinkTag;
        {
            navLinkTag = listOfItems.map((item, index) => {
                return (
                    <NavLink className={classnames({ active: this.state.activeTab == index })} key={index}
                        onClick={(event) => { this.toggleTab(event, index) }} href="#" id={"linkOfItemOnGraph_tab" + index}>
                        {item.itemDesc}
                    </NavLink>
                );
            });
        }

        var shownChartType = listOfItems && listOfItems[this.state.activeTab] && listOfItems[this.state.activeTab]["graphType"] ? listOfItems[this.state.activeTab]["graphType"] : "";
        if (shownChartType == "pie") shownChartType = "pie3d";
        else if (shownChartType == "bar") shownChartType = "bar3d";

        let tabPaneTag;
        {
            tabPaneTag = listOfItems.map((item, index) => {
                // Preparing the chart data                
                var chartDatas = item.chartDatas ? [...item.chartDatas] : [];

                // Create a JSON object to store the chart configurations
                var chartConfigs = {
                    type: shownChartType,// The chart type
                    width: "600",// Width of the chart
                    height: "400",// Height of the chart
                    dataFormat: "json", // Data type
                    dataSource: {
                        // Chart Configuration
                        chart: {
                            caption: item.itemDesc + " for " + (this.state.dialogForm ? this.state.dialogForm.populationLabel : ""),//Set the chart caption
                            /*
                            subCaption: "",//Set the chart subcaption
                            xAxisName: "x",//Set the x-axis name
                            yAxisName: "y",//Set the y-axis name
                            numberSuffix: "k",
                            */
                            theme: "fusion",//Set the theme for your chart
                            //color: this.state.colorSelection ? this.state.colorSelection : "initial",

                            // Fixing #PR00545
                            paletteColors: shownChartType !== "pie3d" && item.colorSelection ? item.colorSelection : '',

                            showPrintMenuItem: '1',
                        },
                        // Chart Data - from step 2
                        data: [
                            //eval(JSON.stringify(chartDatas))
                            ...chartDatas
                        ]
                    }
                };

                var chartUrl = "";
                if (shownChartType == "pie3d") {
                    chartUrl = item.graphAnItemPieChart;
                } else if (shownChartType == "bar3d") {
                    chartUrl = item.graphAnItemBarChart;
                } else {
                    chartUrl = item.graphAnItemLineChart;
                }

                var versionByTime = "";//this.state.versionCharts ? this.state.versionCharts : new Date().getTime();

                return (
                    <TabPane tabId={this.state.activeTab} key={index}>
                        <div className="hidden">
                            <iframe src={item.graphAnItemPieChart} />
                        </div>
                        <div className={this.state.activeTab == index ? "row" : "row hidden"}>
                            <div className="col-md-7">
                                { /* Drawing chart here * / }
                                <ReactFC {...chartConfigs} />
                                { */ }
                                <iframe id={"areaShowingFusionCharts_" + index}
                                    src={chartUrl ? (chartUrl + "?alias=" + this.props.clientgroup + "&version=" + versionByTime) : ""}
                                    key={chartUrl ? (chartUrl + "?alias=" + this.props.clientgroup + "&version=" + versionByTime) : ""}
                                />
                            </div>
                            <div className="col-md-5">
                                {/*Drawing table here*/}
                                <div>
                                    <style id="PrintStyle" type="text/css"></style>
                                </div>
                                <div id={"printableContent" + index}>
                                    <div id="header">
                                        <table id="dataTable" style={{ width: "100%", tableLayout: "fixed" }}>
                                            <colgroup>
                                                <col width="10%" />
                                                <col width="50%" />
                                                <col width="15%" />
                                                <col width="20%" />
                                                <col width="5%" />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <td></td>
                                                    <td>{item.itemDesc}</td>
                                                    <td align="right">Cases</td>
                                                    <td align="right">Percent</td>
                                                    <td></td>
                                                </tr>
                                                <tr className="hidden"></tr>
                                            </thead>
                                        </table>
                                    </div>

                                    <div id="scrollableContent" style={{ overflow: "auto", maxHeight: "calc(100vh - 456px)" }}>
                                        <table id={this.state.showingByGraphType == "pie" ? "dataTable_Pie" : "dataTable"} style={{ width: "100%", tableLayout: "fixed" }}>
                                            <colgroup>
                                                <col width="10%" />
                                                <col width="50%" />
                                                <col width="20%" />
                                                <col width="20%" />
                                                <col width="5%" />
                                            </colgroup>
                                            <tbody dangerouslySetInnerHTML={{ __html: item.trHtml }}>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-sm-12">
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ScreenButton ScreenButtonHover" title=""
                                            onClick={this.printTable}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print Table
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                );
            });
        }

        let drawingCharts;
        {
            drawingCharts = this.state.isDrawingCharts && this.state.listOfItems ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableLargestWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Graph for: {this.state.dialogForm.populationLabel}</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingCharts(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container on_same_page" id="graphAnItemShowingCharts">
                                <Nav tabs tabIndex={this.state.activeTab} autoFocus name="navTabOnGraph">
                                    {navLinkTag}
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    {tabPaneTag}
                                </TabContent>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-1">
                                        <button onClick={this.hideDrawingCharts} value="exit" autoFocus>Exit</button>
                                    </div>
                                    <div className="col-sm-11" /*style={{ display: "flex" }}*/>
                                        <label style={{ width: "100px", height: "15px" }}>
                                            <input type="radio" onClick={(e) => { this.changeGraphType(e) }}
                                                checked={this.state.showingByGraphType == "pie" ? true : false}
                                                name="graphType" value="pie" id="graphType_PieChart"
                                                style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                            Pie Chart
                                        </label>
                                        <label style={{ width: "100px", height: "15px" }}>
                                            <input type="radio" onClick={(e) => { this.changeGraphType(e) }}
                                                checked={this.state.showingByGraphType == "bar" ? true : false}
                                                name="graphType" value="bar" id="graphType_BarChart"
                                                style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                            Bar Chart
                                        </label>
                                        <label style={{ width: "100px", height: "15px" }}>
                                            <input type="radio" onClick={(e) => { this.changeGraphType(e) }}
                                                checked={this.state.showingByGraphType == "line" ? true : false}
                                                name="graphType" value="line" id="graphType_LineChart"
                                                style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                            Line Chart
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }
        let confirmDeleteMsg;
        {
            confirmDeleteMsg = this.state.isShowConfirmDeleteMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Delete Row</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>OK to delete row?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const dialogForm = this.state.dialogForm && this.state.loaded ?
            (
                <div className="container-fluid2">

                    {/* Adding "Population Label" component on top this screen */}
                    <PopLabel plUpdFnc={this.updatePopLabelBackToParent} showToggleBtn={true} updateToParent={this.updateToParent} />

                    <form onSubmit={this.savePopLabelInfo} id="graphAnItemForm" className={this.props.isPopup ? "DialogForm2" : ""}>
                        <div className="emptyLine"></div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="frameInner" style={{ marginRight: "15px" }}>
                                    <fieldset>
                                        <legend></legend>

                                        {/* PR00495: Remove the "extra" pop label drop-down selection * / }
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <Input type="LabelPopup" focused={this.handleOnFocus}
                                                    changed={this.handleFormChange} width="320px"
                                                    labelText="Population Label"
                                                    field="populationLabel" lineStyle="oneLine"
                                                    value={this.state.dialogForm.populationLabel}
                                                    dataSource="Labels" autoFocus
                                                    titleDialog="Population Labels" labelWidth="110px"
                                                    inValid={this.state.uAllItemFields["populationLabel"]}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["label"] : ""}
                                                    lostfocused={this.handleLostFocus}
                                                />
                                            </div>
                                        </div>
                                        <div className="emptyLine"></div>
                                        {*/}

                                        <div className="row">
                                            <div className="col-sm-10">
                                                <div style={{ display: "flex" }}>
                                                    <div className="frameInner">
                                                        <div className="header">Report Elements</div>
                                                        <div style={{ height: "0px", display: "none" }}>
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: "20%" }}>&nbsp;</th>
                                                                        <th style={{ width: "40%" }}>&nbsp;</th>
                                                                        <th style={{ width: "20%" }}>&nbsp;</th>
                                                                        <th style={{ width: "20%" }}>&nbsp;</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody></tbody>
                                                            </table>
                                                        </div>
                                                        <div id="labelsSiteMvListArea" className="labelsSiteMvListArea">
                                                            <div className="labelsSiteMvChildrenArea innerBody" style={{ /*height: "500px",*/ overflowX: "hidden", overflowY: "auto" }}>
                                                                <table>
                                                                    <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                        <tr>
                                                                            <th style={{ width: "20%" }}>&nbsp;</th>
                                                                            <th style={{ width: "40%" }}>&nbsp;</th>
                                                                            <th style={{ width: "20%" }}>&nbsp;</th>
                                                                            <th style={{ width: "20%" }}>&nbsp;</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.labelsSiteMvItemFields
                                                                            && this.state.labelsSiteMv !== null && this.state.labelsSiteMv.length > 0
                                                                            ? this.state.labelsSiteMv.map((mv, index) => (
                                                                                <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionLabelsSiteMvRow(e, index) }} className={this.state.selectedLabelsSiteMvRowIndex === index ? "_selectedRowCls" : ""}>
                                                                                    <td>
                                                                                        <div>
                                                                                            <p>Item Number</p>
                                                                                            <p>Description/Title</p>
                                                                                            <p>Display Items As</p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedLabelsSiteMvRowIndex") }}
                                                                                            changed={(e) => { this.handleLabelsSiteMvGridChange(e, index) }}
                                                                                            labelText="" field="itemNbr"//Item_Desc<Item_Desc,Column_Name<Column_Name,File_Name<File_Name,Code_File<Code_File,Data_Type<Data_Type
                                                                                            value={mv.itemNbr == "0" ? "" : mv.itemNbr} lineStyle="oneLine"
                                                                                            dataSource="Global_Dir" titleDialog="Item Numbers"//filter: Column_Name <> "" | Display: Item_Nbr,Item_Desc | Order: Item_Nbr
                                                                                            width="80px" labelWidth="0px" setNameAlias="code+name" fieldExt2="itemDesc"
                                                                                            inValid={this.state.labelsSiteMvItemFields[index]["itemNbr"]}
                                                                                            maxLength={this.state.labelsSiteMvMaxlengthInput ? this.state.labelsSiteMvMaxlengthInput["itemNbr"] : ""}
                                                                                            lostfocused={(e) => { this.handleLabelsSiteMvLostfocus(e, index) }}//Fill_Dictionary_Name
                                                                                            keyId={"itemNbr" + index}
                                                                                            rowIndex={index} shownType="dropdown"
                                                                                        />
                                                                                        <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedLabelsSiteMvRowIndex") }}
                                                                                            changed={(e) => { this.handleLabelsSiteMvGridChange(e, index) }}
                                                                                            labelText="" field="itemDesc"//Item_Number<Item_Nbr,Column_Name<Column_Name,File_Name<File_Name,Code_File<Code_File,Data_Type<Data_Type
                                                                                            value={mv.itemDesc} lineStyle="oneLine"
                                                                                            dataSource="Global_Dir" titleDialog="Item Descriptions"//filter: Column_Name <> "" | Item_Desc (C)|Contains any of|Item_Desc
                                                                                            width="99%" labelWidth="0px" setNameAlias="name+code" fieldExt2="itemNbr"
                                                                                            inValid={this.state.labelsSiteMvItemFields[index]["itemDesc"]}
                                                                                            maxLength={this.state.labelsSiteMvMaxlengthInput ? this.state.labelsSiteMvMaxlengthInput["itemDesc"] : ""}
                                                                                            lostfocused={(e) => { this.handleLabelsSiteMvLostfocus(e, index) }}
                                                                                            keyId={"itemDesc" + index} flag={mv.itemNbr}
                                                                                            rowIndex={index} shownType="dropdown"
                                                                                        />
                                                                                        <div className="oneLine">
                                                                                            <label style={{ marginLeft: "-15px", height: "0px" }}>
                                                                                                <input type="radio" onClick={(e) => { this.selectionDisplayItemAs(e, index) }}
                                                                                                    checked={mv.displayItemAs === "code"}
                                                                                                    name={"displayItemAs" + index} value="code" id={"displayItemAsCode" + index}
                                                                                                    style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                Codes
                                                                                            </label>
                                                                                            <br />
                                                                                            <label style={{ width: "240px", height: "0px" }}>
                                                                                                <input type="radio" onClick={(e) => { this.selectionDisplayItemAs(e, index) }}
                                                                                                    checked={mv.displayItemAs === "desp"}
                                                                                                    name={"displayItemAs" + index} value="desp" id={"displayItemAsDesp" + index}
                                                                                                    style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                Descriptions
                                                                                            </label>

                                                                                            <span style={{ width: "50px", padding: "4px" }}>
                                                                                                <label style={{ width: "240px", height: "0px" }}>
                                                                                                    <input type="checkbox" onClick={(e) => { this.toggleChecked(e, index) }}
                                                                                                        checked={mv.sorter === "1"} value={mv.sorter ? mv.sorter : "0"}
                                                                                                        name="sorter" id={"sorter" + index}
                                                                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                    &nbsp;Sort
                                                                                                </label>
                                                                                            </span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="row">
                                                                                            <div className="col-sm-12">Graph Type</div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-sm-12">
                                                                                                <label style={{ marginTop: "5px", height: "15px" }}>
                                                                                                    <input type="radio" onClick={(e) => { this.selectionGraphType(e, index) }}
                                                                                                        checked={mv.graphType === "pie"}
                                                                                                        name={"graphType" + index} value="pie" id={"graphType_Pie" + index}
                                                                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                    Pie Chart
                                                                                                </label>
                                                                                                <br />
                                                                                                <label style={{ width: "240px", height: "15px" }}>
                                                                                                    <input type="radio" onClick={(e) => { this.selectionGraphType(e, index) }}
                                                                                                        checked={mv.graphType === "bar"}
                                                                                                        name={"graphType" + index} value="bar" id={"graphType_Bar" + index}
                                                                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                    Bar Chart
                                                                                                </label>
                                                                                                <br />
                                                                                                <label style={{ width: "240px", height: "15px" }}>
                                                                                                    <input type="radio" onClick={(e) => { this.selectionGraphType(e, index) }}
                                                                                                        checked={mv.graphType === "line"}
                                                                                                        name={"graphType" + index} value="line" id={"graphType_Line" + index}
                                                                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                                    Line Chart
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>

                                                                                    <td style={{ visibility: ((this.state.sendReportTo == "Screen" || this.state.selectedOutputTypeOption === "screen") && mv.graphType !== "pie" ? "" : "hidden") }}>
                                                                                        <br /><br />
                                                                                        <div className="row">
                                                                                            <div className="col-sm-10">Bar & Line Color Selection:</div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-sm-10">
                                                                                                <select onFocus={(e) => { this.handleOnFocus(e, index, "selectedLabelsSiteMvRowIndex") }}
                                                                                                    onChange={(e) => { this.handleLabelsSiteMvGridChange(e, index) }}
                                                                                                    name="paletteColors" onBlur={(e) => { this.handleLabelsSiteMvLostfocus(e, index) }}
                                                                                                    value={mv.paletteColors ? mv.paletteColors : "Blue"}//set default as alpha sources
                                                                                                    id={"paletteColors" + index}
                                                                                                    style={{ height: "26px", width: "100%" }}
                                                                                                >
                                                                                                    <option value="Red">Red</option>
                                                                                                    <option value="Orange">Orange</option>
                                                                                                    <option value="Gold">Gold</option>
                                                                                                    <option value="Green">Green</option>
                                                                                                    <option value="Blue">Blue</option>
                                                                                                    <option value="Purple">Purple</option>
                                                                                                    <option value="Pink">Pink</option>
                                                                                                    <option value="Black">Black</option>
                                                                                                    <option value="Silver">Silver</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )) : <tr><td colSpan="4"></td></tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="contactBtnArea right">
                                                                <button onClick={this.addNewLabelsSiteMv}>Add</button>
                                                                <button id="btnDeleteSelectedLabelsSiteMv" onClick={this.deleteSelectedLabelsSiteMv}>Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <table>
                                                            <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                <tr>
                                                                    <th style={{ width: "20%" }}>&nbsp;</th>
                                                                    <th style={{ width: "30%" }}>&nbsp;</th>
                                                                    <th style={{ width: "50%" }}>&nbsp;</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr style={{ border: "none" }}>
                                                                    <td style={{ verticalAlign: "middle", border: "none" }}>Send Report To</td>
                                                                    <td style={{ border: "none" }}>
                                                                        <div className="oneLine" style={{ marginLeft: "-15px" }}>
                                                                            <label style={{ width: "240px", height: "0px" }}>
                                                                                <input type="radio" onClick={this.selectionOutputType}
                                                                                    checked={this.state.selectedOutputTypeOption === "excel"}
                                                                                    name="sendReportTo" field="sendReportTo" value="excel"
                                                                                    style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                Excel
                                                                            </label>
                                                                            <label style={{ width: "240px", height: "0px" }}>
                                                                                <input type="radio" onClick={this.selectionOutputType}
                                                                                    checked={this.state.selectedOutputTypeOption === "screen"}
                                                                                    name="sendReportTo" field="sendReportTo" value="screen"
                                                                                    style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                Screen
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ border: "none", visibility: "hidden" }}>
                                                                        <label style={{ margin: "5px" }}>
                                                                            <select type="radio" onChange={this.handleFormChange} width="100px"
                                                                                value={this.state.dialogForm.colorSelection ? this.state.dialogForm.colorSelection : "Blue"}//Alpha: Default value is Blue
                                                                                name="colorSelection" field="colorSelection"
                                                                                className="colorSelection">
                                                                                <option value="Red">Red</option>
                                                                                <option value="Orange">Orange</option>
                                                                                <option value="Gold">Gold</option>
                                                                                <option value="Green">Green</option>
                                                                                <option value="Blue">Blue</option>
                                                                                <option value="Purple">Purple</option>
                                                                                <option value="Pink">Pink</option>
                                                                                <option value="Black">Black</option>
                                                                                <option value="Silver">Silver</option>
                                                                            </select>
                                                                            Bar & Line Color Selection:
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>

                                    <div className="emptyLine"></div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", marginLeft: "15px", paddingTop: "2px" }}
                                                labelText="Start Time" field="startTime" width="auto"
                                                value={this.state.dialogForm.startTime ? this.state.dialogForm.startTime : "-"}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", paddingTop: "2px" }}
                                                labelText="End Time" field="endTime" width="auto"
                                                value={this.state.dialogForm.endTime ? this.state.dialogForm.endTime : "-"}
                                            />
                                        </div>
                                        <div className="col-sm-3 _hidden">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", borderStyle: "none", paddingTop: "2px" }}
                                                labelText="Records Selected" field="numberOfRecords" width="auto"
                                                value={this.state.dialogForm.numberOfRecords ? this.state.dialogForm.numberOfRecords : "0"}
                                            />
                                        </div>
                                    </div>

                                    <div id="CONTAINER_JS_CLOCK" className="hidden">
                                        <label>Selecting Cases</label>
                                        <div id="IMAGE_WAIT" style={{ color: "#05004c", fontWeight: "bold" }}>
                                            Selecting Cases - Please Wait ...
                                        </div>
                                    </div>

                                    <div id="CONTAINER_EDIT_SELECT" className="hidden">
                                        <label>Edit Selection</label>
                                        <div id="CONTAINER_Edit_Select_Body">
                                            <Input type="textarea" changed={this.changeEventFnc}
                                                labelText="Edit Selection" labelWidth="auto" field="comments"
                                                value={this.state.Textarea_EditSelect}
                                                width="440px" height="255px"
                                                textPadFields={true}//set this property to avoid show TextEdit popup when double click on it
                                            />
                                        </div>
                                    </div>

                                    <fieldset className="bottomAreaButtonCls">
                                        <legend></legend>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_SELECT_POPULATION"
                                                        className={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.showEvent(e) }}
                                                        disabled={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? false : true}
                                                    >
                                                        <img id="BUTTON_SELECT_POPULATION_ICON"
                                                            src={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "https://crstar.ers-can.com/images/$$code.action.run.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$code.action.run.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Run
                                                    </button>
                                                </div>

                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_NEWRECORD"
                                                        className={this.state.isFormDirty || (this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "") ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.newRecord(e) }}
                                                        disabled={/*!this.state.isFormDirty ||*/ !this.state.dialogForm.populationLabel || this.state.dialogForm.populationLabel == ""}
                                                    >
                                                        <img id="BUTTON_NEWRECORD_ICON"
                                                            src={this.state.isFormDirty ? "https://crstar.ers-can.com/images/$$navigate.new.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$navigate.new.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        New
                                                    </button>
                                                </div>

                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_EXIT" className="ErsGrBlueButton"
                                                        style={{ display: this.state.onlyShowOnModal ? "" : "" }}
                                                        onClick={(e) => { this.exitScreen(e) }}
                                                    >
                                                        <img id="BUTTON_EXIT_ICON" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Exit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="emptyLine"></div>
                </div>
            )
            : <p>Graph an Item data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>

                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {drawingCharts}
                </React.Fragment>

                <React.Fragment>
                    {confirmDeleteMsg}
                </React.Fragment>

                <React.Fragment>
                    {dialogForm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

GraphAnItem.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        selectedLabelPopInfo: state.patientAbstract.selectedLabelPopInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        isAddNewUser: state.patientAbstract.isAddNewUser,
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
        showToggleBtn: true,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        savePopLabelInfo: (dialogForm) => dispatch(actionCreators.saveAPopLabel(dialogForm)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearAddNewUserFlag: () => dispatch(actionCreators.clearAddNewUserFlag()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        selectAPopLabel: (label) => dispatch(actionCreators.selectAPopLabel(label)),
        deleteAPopLabel: (dialogForm) => dispatch(actionCreators.deleteAPopLabel(dialogForm)),
        runGraphForPopulation: (dialogForm) => dispatch(actionCreators.runGraphForPopulation(dialogForm)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GraphAnItem);