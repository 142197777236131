/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Input from "../../../UI/Input";
import axios from "../../../../axios-instance";
import "../../../AbstractManagement/PatientAbstract/PatientMaintenance/PatientMaintenance.css";
import "../../SystemAdmin.css";
import moment from "moment";
import Draggable from 'react-draggable';
import * as actions from '../../../../store/actions/index';

class CrstarImport extends Component {

    importNameBk = null;
    fileNameBk = null;
    state = {
        dAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'crstarImportForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty crstarImportForm back to what it was on load
        initialImportForm: null,
        crstarImportForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        fieldInFocus: "importName",

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,

        loadedTableStructure: false,
        focusingField: null,

        // Initially, no file is selected
        selectedFile: null,
        showUploadSampleFile: false,

        // ReportLabelsMv
        reportLabelsMv: null,
        reportLabelsMvLoading: false,
        selectedReportLabelsMvRowIndex: 0,
        reportLabelsMvGridFocusing: false,
        setEventHandlerForReportLabelsMvGrid: false,
        reloadData: true
    }

    // ReportLabelsMv:: Selection Creteria grid
    handleReportLabelsMvLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value ? event.target.value : "";

        let currentValue = value;
        if (name == "breakSortList" && currentValue == "") {
            this.setState(prevState => {
                const newItems = [...prevState.reportLabelsMv];
                newItems[index][name] = "L";
                return { reportLabelsMv: newItems };
            });
        } else if (name == "codeDescription" && currentValue == "") {
            this.setState(prevState => {
                const newItems = [...prevState.reportLabelsMv];
                newItems[index][name] = "C";
                return { reportLabelsMv: newItems };
            });
        } else if (name == "displayLength" && currentValue == "") {
            this.setState(prevState => {
                const newItems = [...prevState.reportLabelsMv];
                newItems[index][name] = "20";
                return { reportLabelsMv: newItems };
            });

            //Fill_Item_Desc
        } else if (name == "globalItemNumber") {
            let table = "Global_Dir";
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";

            this.props.checkValidInput(name, value, table, key, index);
        } else {
            //
        }
        return false;
    }

    handleReportLabelsMvGridChange = (event, index) => {
        var selectedItem = event.target2;
        if (selectedItem) {
            this.setState(prevState => {
                const newItems = [...prevState.reportLabelsMv];

                Object.keys(selectedItem).map((t) => {
                    newItems[index][t] = selectedItem[t];
                });

                return { reportLabelsMv: newItems, isFormDirty: true };
            });
        } else {
            const name = event.target.name;
            const value = event.target.value;

            let name2 = null;
            let value2 = null;
            if (event.target2) {
                name2 = event.target2.name;
                value2 = event.target2.value;
            }

            this.setState(prevState => {
                const newItems = [...prevState.reportLabelsMv];
                newItems[index][name] = value;

                if (name2 && value2) {
                    newItems[index][name2] = value2;

                    if (name2 == "globalColumnName") newItems[index]["dataType"] = value2;
                }

                return { reportLabelsMv: newItems, isFormDirty: true };
            });
        }
        return false;
    }

    addNewReportLabelsMv = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.reportLabelsMv];
            const firstLabels = newItems[0];

            const newLabels = {};
            Object.keys(firstLabels).map((t, k) => {
                if (t === "reportLabelMvId" || t === "reportLabelId") {
                    newLabels[t] = 0;
                } else {
                    newLabels[t] = "";
                }
            });
            newItems.push(newLabels);

            return { reportLabelsMv: newItems };
        });
    }

    deleteSelectedReportLabelsMv = (event) => {
        event ? event.preventDefault() : null;

        let selectedRow = this.state.selectedReportLabelsMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.reportLabelsMv.length) {
            selectedRow = this.state.reportLabelsMv.length - 1;
        }

        //if (this.state.reportLabelsMv[selectedRow] && this.state.reportLabelsMv[selectedRow]["id"] !== undefined && this.state.reportLabelsMv[selectedRow]["id"] !== 0) {
        //    this.setState({ isShowConfirmDeleteMsg: true, monitoring: "reportLabelsMv" });
        //} else {
        this.deleteReportLabelsMvGrid("0");
        //}
        return false;
    }

    setSelectionReportLabelsMvRow = (event, index) => {
        this.setState({ selectedReportLabelsMvRowIndex: index });
    }

    deleteReportLabelsMvGrid = (flg) => {
        let selectedRow = this.state.selectedReportLabelsMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.reportLabelsMv.length) {
            selectedRow = this.state.reportLabelsMv.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.reportLabelsMv];
            if (newItems.length === 1) {
                const _newItems = [...this.state.reportLabelsMv];
                const firstItem = _newItems[0];

                const newItem = {};
                Object.keys(firstItem).map((t, k) => {
                    if (t === "reportLabelId" || t === "reportLabelMvId") {
                        //newItem[t] = 0;
                    } else {
                        newItem[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newItem);
            } else {
                newItems.splice(selectedRow, 1);
            }

            return { reportLabelsMv: newItems, selectedReportLabelsMvRowIndex: selectedRow };
        });

        flg === "1" ? this.setState({ isFormDirty: true }) : "";
    }

    insertNewReportLabelsMvGrid = (insertedRow) => {
        this.setState(prevState => {
            const newItems = [...this.state.reportLabelsMv];
            const firstItems = newItems[0];

            const newLabels = {};
            Object.keys(firstItems).map((t, k) => {
                if (t === "reportLabelMvId" || t === "reportLabelId" /*|| t === "globalItemNumber" || t === "displayLength"*/) {
                    newLabels[t] = 0;
                } else {
                    newLabels[t] = "";
                }
            });
            newItems.splice(insertedRow, 0, newLabels);

            return { reportLabelsMv: newItems };
        });
        return false;
    }
    addEventListenerFnc = (om) => {
        var reportLabelsMvGrid = document.getElementById('reportLabelsMvListArea');
        reportLabelsMvGrid.addEventListener('keydown', function (e) {
            if (e.keyCode == 45 && e.target.getAttribute("data-index")) {// Insert key
                var insertedRowIdx = om.state.selectedReportLabelsMvRowIndex;
                om.insertNewReportLabelsMvGrid(insertedRowIdx);
                setTimeout(function () {
                    var selectedIndex = e.target.getAttribute("data-index");
                    document.getElementById('globalItemNumber' + selectedIndex) ? document.getElementById('globalItemNumber' + selectedIndex).focus() : "";//e.target.focus();
                    return false;
                }, 10);
                return false;
            } else if (e.keyCode == 46 && e.target.getAttribute("data-index")) {// Delete key
                om.deleteSelectedReportLabelsMv();//btnDeleteSelectedReportLabelsMv
                return false;
            } else if (e.key === "Tab" && !e.shiftKey && e.target.name == "limitingValues") {
                let rindex = e.target.getAttribute("data-index");
                var currentRow = e.target.parentNode && e.target.parentNode.parentNode && e.target.parentNode.parentNode.parentNode ? e.target.parentNode.parentNode.parentNode : null;
                var globalItemNumber = currentRow && currentRow.childNodes[1] && currentRow.childNodes[1].querySelector("#globalItemNumber" + rindex)
                    && currentRow.childNodes[1].querySelector("#globalItemNumber" + rindex).value ? currentRow.childNodes[1].querySelector("#globalItemNumber" + rindex).value : "";
                if (e.target.name == "limitingValues" && globalItemNumber != "" && currentRow && !currentRow.nextSibling) {
                    document.getElementById("btnAddNewLatestReportLabelsMv") ? document.getElementById("btnAddNewLatestReportLabelsMv").click() : "";
                    rindex = parseInt(rindex) + 1;
                    setTimeout(function () {
                        document.getElementById("globalItemNumber" + rindex) ? document.getElementById("globalItemNumber" + rindex).focus() : "";
                    }, 100);
                } else if (e.target.name == "limitingValues" && currentRow && !currentRow.nextSibling) {
                    setTimeout(function () {
                        document.getElementById("btnAddNewLatestReportLabelsMv") ? document.getElementById("btnAddNewLatestReportLabelsMv").focus() : "";
                    }, 100);
                }
            }
            return false;
        });

        return false;
    }

    showUploadFile = (event, flag) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        var fileName = document.getElementById("fileNameDialog");
        if (fileName) fileName.click();
        return false;
    }

    // On file select (from the pop up)
    onFileChange = event => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        // Update the state
        this.setState({ selectedFile: event.target.files[0] });

        // Continue to submit this file to server???
        this.onFileUpload(event.target.files[0]);
        return false;
    };
    // On file upload (click the upload button)
    async onFileUpload(file) {
        const form = document.getElementById("uploadTemplateSampleFile");
        // Create an object of formData
        const formData = new FormData(form);

        // Update the formData object
        formData.append(
            "file",
            file,
            file.name
        );

        // Request made to the backend api
        // Send formData object
        try {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    maxBodyLength: 10000000,
                    maxContentLength: 10000000,
                    emulateJSON: true
                },
            };
            const res = await axios.post(this.props.clientgroup + "/UploadFile", formData, config);
            let crstarImportFormData = this.state.crstarImportForm;
            crstarImportFormData["fileName"] = res && res.data ? res.data.filename : null;
            crstarImportFormData["previewRecord"] = res && res.data ? res.data.count : null;
            crstarImportFormData["previewRecordNumber"] = res && res.data ? res.data.count : null;
            crstarImportFormData["totalRecords"] = res && res.data ? res.data.count : null;

            this.fileNameBk = crstarImportFormData["fileName"];

            this.setState({
                crstarImportForm: crstarImportFormData,
                showUploadSampleFile: false
            });
            return false;
        } catch (ex) {
            console.log(ex);
        }
        return false;
    };

    async hideUploadSampleFile(event, _this) {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        var crstarImportFormData = _this.state.crstarImportForm;
        crstarImportFormData.userId = _this.props.userId;
        crstarImportFormData.clientAlias = _this.props.clientgroup;

        let url = _this.props.clientgroup + "/CancelReport";
        let res = await axios
            .post(url, { xMergeOptions: crstarImportFormData, accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });

        if (res && res.data && res.data.xMergeOptions) {
            crstarImportFormData.endTime = res.data.xMergeOptions.endTime;
            crstarImportFormData.unlockReturn = res.data.xMergeOptions.unlockReturn ? res.data.xMergeOptions.unlockReturn : "";
        }

        _this.setState({
            showUploadSampleFile: false,
            crstarImportForm: crstarImportFormData
        });
        return false;
    }

    printTable = () => {
        var doc = document.getElementById("previewHtmlIframe") ? document.getElementById("previewHtmlIframe").contentDocument : document;

        var printStyle = doc.getElementById('PrintStyle');
        var printContent = doc.getElementById('PrintableContent');

        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Imported Records</title><style type="text/css">';
        html += (printStyle ? printStyle.innerHTML : "") + '</style></head><body><div id="printableContent">';
        html += (printContent ? printContent.innerHTML : "") + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
        return false;
    }

    showPreviewData = () => {
        this.setState({
            previewed: true,
            showUploadSampleFile: false,
            showingPreviewData: true,
            showCopyDialog: false,
            processing: false
        });
        var data = this.state.crstarImportForm;
        var htmlOutput = data && data.previewHtml ? data.previewHtml : "";//data.htmlDisplay

        var previewHtmlIframe = document.getElementById("previewHtmlIframe");
        if (previewHtmlIframe) { //This function runs twice and in one instance this component doesn't exist, so this check needs to be there to prevent the entire screen from erroring out
            previewHtmlIframe.src = "about:blank";
            var previewHtmlContent = previewHtmlIframe.contentWindow || previewHtmlIframe.contentDocument.document || previewHtmlIframe.contentDocument;
            if (previewHtmlContent.write) {
                previewHtmlContent.write(htmlOutput);
            } else {
                previewHtmlContent.document.write(htmlOutput);
            }
        }

        setTimeout(function () {
            var previewHtmlIframe = document.getElementById("previewHtmlIframe");
            if (previewHtmlIframe) { //This function runs twice and in one instance this component doesn't exist, so this check needs to be there to prevent the entire screen from erroring out
                previewHtmlIframe.src = "about:blank";
                var previewHtmlContent = previewHtmlIframe.contentWindow || previewHtmlIframe.contentDocument.document || previewHtmlIframe.contentDocument;
                if (previewHtmlContent.write) {
                    previewHtmlContent.write(htmlOutput);
                } else {
                    previewHtmlContent.document.write(htmlOutput);
                }
            }
            return false;
        }, 500);

        return false;
    }
    hidePreviewData = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showingPreviewData: false });
        this.props.setChildrenOpening(false);

        //
        setTimeout(function () {
            document.getElementById('BUTTON_VIEW_PAST_IMPORTS') ? document.getElementById('BUTTON_VIEW_PAST_IMPORTS').focus() : "";
            return false;
        }, 100);

        return false;
    }

    downloadExcel = () => {
        var data = this.state.crstarImportForm;

        if (!data.fnExcel) return false;

        var url = this.props.clientgroup + "/DownloadFile";
        var _this = this;
        axios.post(url, {
            urlOutputFile: data.fnExcel,
            method: 'POST',
            responseType: 'blob',//important
            accessToken: localStorage.getItem('token')
        }).then((response) => {
            const link = document.createElement('a');

            let excel;
            if (data.fnExcel.indexOf('/') != -1) {
                excel = data.fnExcel.substring(data.fnExcel.lastIndexOf('/') + 1);
            } else {
                excel = data.fnExcel.substring(data.fnExcel.lastIndexOf('\\') + 1);
            }
            link.href = this.props.clientgroup + "/" + excel;
            link.setAttribute('download', `Imported Records_` + new Date().getTime() + `.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            _this.downloadFile = true;
            return false;
        }).
            catch(function (error) {
                console.log(error);
            });

        return false;
    }

    initClock() {
        var _this = this;
        setInterval(function () {
            /*
            var today = new Date(),
                time = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");
            */
            //d.toLocaleTimeString();

            var startTime = _this.state.currentTime ? _this.state.currentTime : (_this.state.startTimeFrom ? _this.state.startTimeFrom : "");
            var time = "";
            if (startTime && startTime.split(" ").length > 0) {
                var hms = startTime.split(" ")[0];
                var ap = startTime.split(" ")[1];

                var hour = parseInt(hms.split(":")[0]);
                var min = parseInt(hms.split(":")[1]);
                var sec = parseInt(hms.split(":")[2]);
                sec = 1 + sec;
                if (sec == 60) {
                    sec = 0;
                    min = 1 + min;
                }

                if (min == 60) {
                    min = 0;
                    hour = 1 + hour;
                }

                if (hour == 12) {
                    hour = 0;
                    ap == "PM" ? ap = "AM" : ap = "PM";
                }

                time = (hour < 12 ? hour : hour - 12)
                    + ':' + (min < 10 ? "0" + min : min)
                    + ':' + (sec < 10 ? "0" + sec : sec)
                    + " " + ap;
            }

            _this.setState({ currentTime: time });

            return false;
        }, 1000);
    }

    componentDidMount() {
        this.setState({ reloadPage: true, crstarImportForm: null, showingPreviewData: false });
        document.getElementById("fileName") ? document.getElementById("fileName").value = "" : null;
        document.getElementById("importName") ? document.getElementById("importName").value = "" : null;
        document.getElementById("requiresCustomProgram") ? document.getElementById("requiresCustomProgram").checked = false : null;
    }

    componentDidUnmount() {
        //this.props.loadImportConfig("Initial_Import_Screen");

        this.setState({
            reloadPage: true,
            reloadData: true,
            crstarImportForm: null,
            // ReportLabelsMv
            reportLabelsMv: null,
            reportLabelsMvLoading: false,
            selectedReportLabelsMvRowIndex: 0,
            reportLabelsMvGridFocusing: false,
            isShowConfirmDeleteMsg: false,
            isShowConfirmDeleteLabelMsg: false,
            confirmed: null,
            monitoring: null,
            showingPreviewData: false,
        });
    }

    componentWillMount() {
        this.props.setChildrenOpening(false);
        this.setState({ setEventHandlerForGrid: false, availableHosps: null, isDrawingHtmlTable: false, loadedPage: false, showingPreviewData: false });

        //this.initClock();
    }

    componentDidUpdate(prevProps) {
        /*
        if (this.props.error && this.props.errorMessage) {
            this.setState({
                showClockTimer: false,
                showMessageError: true,
                crstarImportForm: {
                    ...this.state.crstarImportForm,
                    startTime: this.props.selectedReportLabelsInfo ? (this.props.selectedReportLabelsInfo.startTime ? this.props.selectedReportLabelsInfo.startTime : this.props.selectedReportLabelsInfo.startTimeSAP) : "",
                    endTime: this.props.selectedReportLabelsInfo ? (this.props.selectedReportLabelsInfo.endTime ? this.props.selectedReportLabelsInfo.endTime : this.props.selectedReportLabelsInfo.endTimeSAP) : "",
                    numberOfRecords: this.props.selectedReportLabelsInfo ? this.props.selectedReportLabelsInfo.numberOfRecords : "",
                },
                errorMessage: this.props.errorMessage,
            });

            document.querySelector('#BUTTON_RUN_REPORT') ? document.querySelector('#BUTTON_RUN_REPORT').focus() : null;

            this.props.clearErrorMessage();
            return false;
        }

        if (!this.state.setEventHandlerForGrid) {
            if (document.getElementById('reportLabelsMvListArea')) {
                this.addEventListenerFnc(this);
                this.setState({ setEventHandlerForGrid: true });
            }
        }
        */

        if ((!this.state.crstarImportForm && this.props.importConfigInfo)
            || (prevProps.importConfigInfo && this.props.importConfigInfo && prevProps.importConfigInfo.mergeOptionsId !== this.props.importConfigInfo.mergeOptionsId)
            || (prevProps.importConfigInfo && this.props.importConfigInfo && prevProps.importConfigInfo.importName !== this.props.importConfigInfo.importName)
            //|| (prevProps.importConfigInfo && this.props.importConfigInfo && prevProps.importConfigInfo.endTime !== this.props.importConfigInfo.endTime)
            || (this.state.reloadData && this.props.importConfigInfo)
            || (prevProps.importConfigInfo && this.props.importConfigInfo && prevProps.importConfigInfo.lastTimeAccess !== this.props.importConfigInfo.lastTimeAccess)
        ) {
            this.setFormData();
        }

        if (!this.state.previewed && this.state.crstarImportForm && this.state.crstarImportForm.fnDisplay) {
            this.showPreviewData();
        }

        /*
        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.crstarImportForm && this.state.dAllItemFields
        ) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    crstarImportForm: { ...this.state.crstarImportForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    crstarImportForm: { ...this.state.crstarImportForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null && !this.state.loadedTableStructure) {
            this.setState({ loadedTableStructure: true });
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
        */

        // ReportLabelsMv List
        if (this.props.importConfigInfo && !this.state.reportLabelsMv && !this.state.reportLabelsMvLoading) {
            let reportLabelsMvList = [];
            let fields = {
                importedId: "",
                pastImport: "",
                dateImported: "",
                timeImported: ""
            };
            reportLabelsMvList.push(fields);

            this.setState({
                reportLabelsMvLoading: true,
                reportLabelsMv: reportLabelsMvList
            });
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "x_Merge_Options", accessToken: localStorage.getItem('token') };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res && res.data ? res.data.result : null
        });

        return false;
    }

    // Set data into form
    setFormData = () => {
        var importConfigInfo = this.props.importConfigInfo;

        this.setState({
            initialImportForm: {
                ...importConfigInfo,
            },
            crstarImportForm: {
                ...importConfigInfo,
                fileName: this.fileNameBk ? this.fileNameBk : importConfigInfo.fileName,
                importName: this.importNameBk ? this.importNameBk : importConfigInfo.importName,
                importLabel: this.importNameBk ? this.importNameBk : importConfigInfo.importLabel,
            },
            isFormDirty: this.props.isFormDirty,
            reloadedImportName: importConfigInfo && importConfigInfo.mergeOptionsId ? true : false,
            reloadData: false,
        });

        if (!this.state.dAllItemFields && importConfigInfo) {
            let fields = {};
            Object.keys(this.props.importConfigInfo).map((t) => {
                fields[t] = "";
            });

            this.setState({ dAllItemFields: fields, pageMaxlengthInput: fields });
        };

        return false;
    }

    saveImportConfig = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let crstarImportFormData = this.state.crstarImportForm;
            let isValidDateInput = true;

            if (!isValidDateInput && !allowSaveAnyway) return false;

            crstarImportFormData.action = "Save";
            crstarImportFormData.userId = this.props.userId;

            this.props.saveImportConfig(crstarImportFormData);
            this.setState({ isFormDirty: false });

            return false;
        }
    }

    reloadPage = () => {
        this.setState({ reloadData: true });
    }

    refreshFormFnc = () => {
        let fields = {};
        if (!this.state.dAllItemFields && this.props.importConfigInfo) {
            Object.keys(this.props.importConfigInfo).map((t) => {
                fields[t] = "";
            });
        };

        this.setState({
            crstarImportForm: { ...this.state.initialImportForm },
            dAllItemFields: fields,
            isFormDirty: false,
            reload: false
        });

        //
        setTimeout(function () {
            document.getElementById("importName0") ? document.getElementById("importName0").focus() : "";
        }, 500);
    }

    resetImportForm = () => {
        let fields = {};
        Object.keys(this.state.dAllItemFields).map((t) => {
            fields[t] = "";
        });
        this.setState({
            crstarImportForm: { ...this.state.initialImportForm },
            dAllItemFields: fields,
            isFormDirty: false,
        });
        return false;
    }

    offset(el) {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        return {
            top: rect.top + scrollTop,
            left: rect.left + scrollLeft,
            width: rect.width,
            height: rect.height
        }
    }

    copyFuncHandler = () => {
        var target = document.getElementById("BUTTON_VIEW_PAST_IMPORTS");
        var divOffset = this.offset(target);

        this.setState({ showCopyDialog: true, xPos: divOffset.left, yPos: divOffset.top - 260 });
        this.props.storeToGlobal(null);
    }

    exitImportScreen = (event) => {
        event.preventDefault();
        this.props.setInitialPageVisible(false);

        this.setState({
            reloadPage: true,
            crstarImportForm: {
                ...this.state.crstarImportForm,
                ["fileName"]: "",
                ["importName"]: "",
                ["requiresCustomProgram"]: "",
            },
        });

        document.getElementById("fileName") ? document.getElementById("fileName").value = "" : null;
        document.getElementById("importName") ? document.getElementById("importName").value = "" : null;
        document.getElementById("requiresCustomProgram") ? document.getElementById("requiresCustomProgram").checked = false : null;

        document.getElementById("startTime") ? document.getElementById("startTime").innerHTML = "" : null;
        document.getElementById("endTime") ? document.getElementById("endTime").innerHTML = "" : null;

        setTimeout(function () {
            document.getElementById("fileName") ? document.getElementById("fileName").value = "" : null;
            document.getElementById("importName") ? document.getElementById("importName").value = "" : null;
            document.getElementById("requiresCustomProgram") ? document.getElementById("requiresCustomProgram").checked = false : null;

            document.getElementById("startTime") ? document.getElementById("startTime").innerHTML = "" : null;
            document.getElementById("endTime") ? document.getElementById("endTime").innerHTML = "" : null;
        }, 1000);

        return false;
    }

    Update_Progress_Bar() {
        if (!this.state || !this.state.crstarImportForm) {
            return false;
        }

        var File_Rec_Count = this.state.crstarImportForm && this.state.crstarImportForm.fileRecCount ? this.state.crstarImportForm.fileRecCount : 0;//{dialog.Object}.getValue('File_Rec_Count')
        if (File_Rec_Count < 2000) { File_Rec_Count = 2000 }
        if (File_Rec_Count > 10000) { File_Rec_Count = 10000 }
        var unlock = this.state.crstarImportForm && this.state.crstarImportForm.unlockReturn ? this.state.crstarImportForm.unlockReturn : "";//{dialog.Object}.getValue('Unlock_Return');
        if (unlock == '') {
            setTimeout(this.myTimer(), File_Rec_Count * 2);
        }
    }
    async myTimer() {
        var unlock = this.state.crstarImportForm && this.state.crstarImportForm.unlockReturn ? this.state.crstarImportForm.unlockReturn : "";//{dialog.Object}.getValue('Unlock_Return');
        if (unlock == '') {
            //{Dialog.Object}.ajaxCallback('','','Update_Progress_Bar','','');
            try {
                var crstarImportFormData = this.state.crstarImportForm;
                crstarImportFormData.userId = this.props.userId;
                crstarImportFormData.clientAlias = this.props.clientgroup;

                const formData = {
                    xMergeOptions: crstarImportFormData,
                    accessToken: localStorage.getItem('token')
                };
                const res = await axios.post(this.props.clientgroup + "/UpdateProgressBar", formData);

                //
                var data = res && res.data && res.data.xMergeOptions ? res.data.xMergeOptions : null;

                if (data && data.endTime) {
                    crstarImportFormData["endTime"] = data.endTime;
                    crstarImportFormData["fnDisplay"] = data.fnDisplay;
                    crstarImportFormData["fnExcel"] = data.fnExcel;
                }
                crstarImportFormData["recsProcessed"] = data && data.recsProcessed ? data.recsProcessed : 0;
                crstarImportFormData["totalRecs"] = data && data.totalRecs ? data.totalRecs : 0;
                crstarImportFormData["unlockReturn"] = data && data.unlockReturn ? data.unlockReturn : "";

                crstarImportFormData["jsScript"] = data && data.jsScript ? data.jsScript : null;
                crstarImportFormData["percentage"] = data && data.percentage ? data.percentage : 0;
                crstarImportFormData["width"] = data && data.width ? data.width : 0;

                crstarImportFormData["programStatus"] = data && data.programStatus ? data.programStatus : "";
                crstarImportFormData["previewHtml"] = data && data.previewHtml ? data.previewHtml : "";

                var doc = document.getElementById('IFRAME_PROGRESS_BAR_IFRAME') ? document.getElementById('IFRAME_PROGRESS_BAR_IFRAME').contentDocument : null;
                if (doc && crstarImportFormData["percentage"]) {
                    let percentage = crstarImportFormData["percentage"];
                    doc.getElementById('percentage').innerHTML = percentage + "%";
                }
                if (doc && crstarImportFormData["width"]) {
                    let width = crstarImportFormData["width"];
                    doc.getElementById('topstatus').style.width = width + "px";
                    doc.getElementById('topinner').style.width = width + "px";
                    doc.getElementById('innerstatus').style.width = width + "px";
                    doc.getElementById('bottominner').style.width = width + "px";
                    doc.getElementById('bottomstatus').style.width = width + "px";
                }

                this.setState({
                    crstarImportForm: crstarImportFormData,
                    //showUploadSampleFile: crstarImportFormData["programStatus"] && crstarImportFormData["programStatus"].indexOf("Import Complete") != -1 ? false : true,
                });

                if (crstarImportFormData["programStatus"] && crstarImportFormData["programStatus"].indexOf("Import Complete") != -1) {
                    var _this = this;
                    setTimeout(function () {
                        _this.setState({
                            showUploadSampleFile: false,
                            previewed: false
                        });
                    }, 1000);
                } else {
                    setTimeout(this.Update_Progress_Bar(), 1000);
                }

                return false;
            } catch (ex) {
                console.log(ex);
            }
        }
        return false;
    }

    //
    async showEvent(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        await this.Check_Import_Status();
        var importRunning = this.state.crstarImportForm && this.state.crstarImportForm.importStatus ? this.state.crstarImportForm.importStatus : "";//{dialog.object}.stateInfo.Import_Status;

        if (importRunning == 'Incomplete') {
            //A5.msgBox.show("Warning","<div style='padding: 5px 15px; width: 425px; height: 100px; font-size: 11pt; font-weight:bold; font-family: Calibri,sans-serif ;' align='center'><br>A previous import of data did not complete and may still be running.  If you are not aware of other users importing data, please contact ERS to verify before continuing.</div>",[{html: 'OK', value: 'ok'}],function(button) {return;});
            this.setState({ importRunningMsg: true });
            return false;
        } else {
            //{dialog.object}.buttonClick('BUTTON_RUN');
            this.setState({
                showUploadSampleFile: true,
            });

            //Create_Progress_Bar();//show in CONTAINER_PROGRESS_BAR
            try {
                var crstarImportFormData = this.state.crstarImportForm;
                crstarImportFormData.userId = this.props.userId;
                crstarImportFormData.clientAlias = this.props.clientgroup;

                // clear all values of the previous process
                crstarImportFormData.endTime = null;
                crstarImportFormData.unlockReturn = null;
                crstarImportFormData.programStatus = null;
                crstarImportFormData.fnDisplay = null;
                crstarImportFormData.fnExcel = null;
                crstarImportFormData.recsProcessed = 0;
                crstarImportFormData.totalRecs = 0;
                crstarImportFormData.jsScript = null;
                crstarImportFormData.percentage = 0;
                crstarImportFormData.width = 0;
                crstarImportFormData.previewHtml = null;
                crstarImportFormData.topstatus = 0;
                crstarImportFormData.topinner = 0;
                crstarImportFormData.innerstatus = 0;
                crstarImportFormData.bottominner = 0;
                crstarImportFormData.bottomstatus = 0;
                crstarImportFormData.callNextToFnc = null;

                this.setState({
                    crstarImportForm: crstarImportFormData
                });

                var doc = document.getElementById('IFRAME_PROGRESS_BAR_IFRAME') ? document.getElementById('IFRAME_PROGRESS_BAR_IFRAME').contentDocument : null;
                if (doc) {
                    let percentage = crstarImportFormData["percentage"];
                    let width = crstarImportFormData["width"];
                    doc.getElementById('percentage') ? (doc.getElementById('percentage').innerHTML = percentage + "%") : null;
                    doc.getElementById('topstatus') ? (doc.getElementById('topstatus').style.width = width + "px") : null;
                    doc.getElementById('topinner') ? (doc.getElementById('topinner').style.width = width + "px") : null;
                    doc.getElementById('innerstatus') ? (doc.getElementById('innerstatus').style.width = width + "px") : null;
                    doc.getElementById('bottominner') ? (doc.getElementById('bottominner').style.width = width + "px") : null;
                    doc.getElementById('bottomstatus') ? (doc.getElementById('bottomstatus').style.width = width + "px") : null;
                }

                const formData = {
                    xMergeOptions: crstarImportFormData,
                    accessToken: localStorage.getItem('token')
                };
                const res = await axios.post(this.props.clientgroup + "/CreateProgressBar", formData);

                //
                var data = res && res.data && res.data.xMergeOptions ? res.data.xMergeOptions : null;
                crstarImportFormData["startTime"] = data && data.startTime ? data.startTime : null;
                crstarImportFormData["programStatus"] = data && data.programStatus ? data.programStatus : null;
                crstarImportFormData["progressBarTrigger"] = data && data.progressBarTrigger ? data.progressBarTrigger : null;

                var showProgressBarTrigger = false;
                if (data && data.progressBarTrigger) {
                    showProgressBarTrigger = true;
                    /*
                    var ifr = '{dialog.ComponentName}.V.R1.IFRAME_PROGRESS_BAR';
                    $(ifr).src = { dialog.Object }.getValue('Progress_Bar_Trigger');

                    //After starting the progress bar above, launch the report
                    setTimeout(Begin_Import, 200);

                    Update_Progress_Bar();
                    */
                    var _this = this;
                    await setTimeout(function () {
                        _this.Update_Progress_Bar();
                        try {
                            var crstarImportFormData = _this.state.crstarImportForm;
                            crstarImportFormData.userId = _this.props.userId;
                            crstarImportFormData.clientAlias = _this.props.clientgroup;
                            crstarImportFormData.importLabel = crstarImportFormData.importName;

                            const formData = {
                                xMergeOptions: crstarImportFormData,
                                accessToken: localStorage.getItem('token')
                            };
                            axios.post(_this.props.clientgroup + "/CRStarImport", formData).then(res => {
                                // ---------------------------------------------------------------------------------------------------------
                                if (res && res.data && res.data.xMergeOptions && res.data.xMergeOptions.callNextToFnc) {
                                    axios.post(_this.props.clientgroup + "/" + res.data.xMergeOptions.callNextToFnc, formData).then(res => {
                                        crstarImportFormData["unlockReturn"] = res.data.xMergeOptions && res.data.xMergeOptions.unlockReturn ? res.data.xMergeOptions.unlockReturn : "";
                                        _this.setState({ crstarImportForm: crstarImportFormData });

                                        //update progress bar to complete...
                                        var data = res && res.data && res.data.xMergeOptions ? res.data.xMergeOptions : null;

                                        if (data && data.endTime) {
                                            crstarImportFormData["endTime"] = data.endTime;
                                            crstarImportFormData["fnDisplay"] = data.fnDisplay;
                                            crstarImportFormData["fnExcel"] = data.fnExcel;
                                        }
                                        crstarImportFormData["recsProcessed"] = data && data.recsProcessed ? data.recsProcessed : 0;
                                        crstarImportFormData["totalRecs"] = data && data.totalRecs ? data.totalRecs : 0;
                                        crstarImportFormData["unlockReturn"] = data && data.unlockReturn ? data.unlockReturn : "";

                                        crstarImportFormData["percentage"] = data && data.percentage ? data.percentage : 0;
                                        crstarImportFormData["width"] = data && data.width ? data.width : 0;

                                        crstarImportFormData["programStatus"] = data && data.programStatus ? data.programStatus : "";
                                        crstarImportFormData["previewHtml"] = data && data.previewHtml ? data.previewHtml : "";

                                        var doc = document.getElementById('IFRAME_PROGRESS_BAR_IFRAME') ? document.getElementById('IFRAME_PROGRESS_BAR_IFRAME').contentDocument : null;
                                        if (doc && crstarImportFormData["percentage"]) {
                                            let percentage = crstarImportFormData["percentage"];
                                            doc.getElementById('percentage').innerHTML = percentage + "%";
                                        }
                                        if (doc && crstarImportFormData["width"]) {
                                            let width = crstarImportFormData["width"];
                                            doc.getElementById('topstatus').style.width = width + "px";
                                            doc.getElementById('topinner').style.width = width + "px";
                                            doc.getElementById('innerstatus').style.width = width + "px";
                                            doc.getElementById('bottominner').style.width = width + "px";
                                            doc.getElementById('bottomstatus').style.width = width + "px";
                                        }

                                        this.setState({
                                            crstarImportForm: crstarImportFormData,
                                            showUploadSampleFile: false,
                                            previewed: false
                                        });

                                        return false;
                                    }).catch(error => {
                                        console.log(error);
                                        return false;
                                    });
                                } else {
                                    _this.Update_Progress_Bar();
                                }
                                // ---------------------------------------------------------------------------------------------------------
                            }).catch(error => {
                                console.log(error);
                                return false;
                            });
                            return false;
                        } catch (ex) {
                            console.log(ex);
                        }
                        return false;
                    }, 1500);
                }

                this.setState({
                    //crstarImportForm: crstarImportFormData,
                    showUploadSampleFile: showProgressBarTrigger,
                    importRunningMsg: false,
                    previewed: false,
                });

                //
                var htmlOutput2 = data && data.progressBarTrigger ? data.progressBarTrigger : "";
                var progressBarHtmlIframe = document.getElementById("IFRAME_PROGRESS_BAR_IFRAME");

                setTimeout(function () {
                    if (progressBarHtmlIframe) {
                        progressBarHtmlIframe.src = "about:blank";
                        var previewHtmlContent2 = progressBarHtmlIframe.contentWindow || progressBarHtmlIframe.contentDocument.document || progressBarHtmlIframe.contentDocument;
                        if (previewHtmlContent2.write) {
                            previewHtmlContent2.write(htmlOutput2);
                        } else {
                            previewHtmlContent2.document.write(htmlOutput2);
                        }
                    }
                    return false;
                }, 0);
                return false;
            } catch (ex) {
                console.log(ex);
            }
            return false;

            //{Dialog.Object}.ajaxCallback('','','Cancel_Report','','');
            //{Dialog.Object}.ajaxCallback('','','Cancel_CLR','','');
        }
    }

    async Check_Import_Status() {
        try {
            var crstarImportFormData = this.state.crstarImportForm;
            crstarImportFormData.userId = this.props.userId;
            crstarImportFormData.clientAlias = this.props.clientgroup;

            const formData = {
                xMergeOptions: crstarImportFormData,
                accessToken: localStorage.getItem('token')
            };
            const res = await axios.post(this.props.clientgroup + "/CheckImportStatus", formData);

            //
            var data = res && res.data && res.data.xMergeOptions ? res.data.xMergeOptions : null;
            crstarImportFormData["importStatus"] = data && data.importStatus ? data.importStatus : null;

            crstarImportFormData["endTime"] = null;
            crstarImportFormData["fnDisplay"] = null;
            crstarImportFormData["fnExcel"] = null;
            crstarImportFormData["recsProcessed"] = 0;
            crstarImportFormData["totalRecs"] = 0;
            crstarImportFormData["unlockReturn"] = "";
            crstarImportFormData["jsScript"] = null;
            crstarImportFormData["percentage"] = 0;
            crstarImportFormData["width"] = 0;
            crstarImportFormData["programStatus"] = "";
            crstarImportFormData["previewHtml"] = "";

            this.setState({
                crstarImportForm: crstarImportFormData
            });
            return false;
        } catch (ex) {
            console.log(ex);
        }
        return false;
    }

    javascript_void_fnc(event) {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        return false;
    }

    //
    hideCopyDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showCopyDialog: false });
        this.props.setChildrenOpening(false);

        //
        setTimeout(function () {
            document.getElementById('BUTTON_VIEW_PAST_IMPORTS') ? document.getElementById('BUTTON_VIEW_PAST_IMPORTS').focus() : "";
            return false;
        }, 100);

        return false;
    }
    async copyImportOptions(event) {
        event.preventDefault();

        if (this.state.reportLabelsMv !== null && this.state.reportLabelsMv.length > 0) {
            var dialogFormData = this.state.crstarImportForm;

            dialogFormData.userId = this.props.userId;
            dialogFormData.clientAlias = this.props.clientgroup;

            //
            dialogFormData.reportLabelsMv = this.state.reportLabelsMv;
            dialogFormData.action = "View_Past_Reports";

            const formData = {
                xMergeOptions: dialogFormData,
                accessToken: localStorage.getItem('token')
            };
            //'View_Past_Reports'
            const res = await axios.post(this.props.clientgroup + "/ViewPastReports", formData);

            //
            var data = res && res.data && res.data.xMergeOptions ? res.data.xMergeOptions : null;
            if (data && data.endTime) {
                dialogFormData["endTime"] = data.endTime;
                dialogFormData["fnDisplay"] = data.fnDisplay;
                dialogFormData["previewHtml"] = data.previewHtml;
                dialogFormData["fnExcel"] = data.fnExcel;
            } else if (res.data.error) {
                alert(res.data.internalMessage);
                return false;
            }

            this.setState({ crstarImportForm: dialogFormData, previewed: false, processing: true });
        }

        this.props.setChildrenOpening(false);
        this.props.storeToGlobal(null);

        return false;
    }

    handleOnFocus = (event) => {
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.getAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            let cid = this.props.selectedPrimarySummary.cid;

            this.props.checkValidInput(name, value, table, key, -1, flag, cid);
        } else if (event.target.name == "importName" && event.target.value != "" && !this.state.reloadedImportName) {
            this.setState({ reloadData: true });
            this.props.loadImportConfig(event.target.value);
        } else {
            this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
        }
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                crstarImportForm: {
                    ...this.state.crstarImportForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                }, isFormDirty: true
            });

            if (event.target2.name == "importName" && event.target2.value != "") {
                this.setState({
                    reloadData: true,
                    xMergeOptionsImpoItemFields: null,
                });
                this.props.loadImportConfig(event.target2.value);
            }
        } else {
            this.setState({
                crstarImportForm: {
                    ...this.state.crstarImportForm,
                    [event.target.name]: event.target.value
                }, isFormDirty: true
            });

            if (event.target.name == "importName" && event.target.value != "") {
                // check if whether has existed label?
                var _this = this;
                var iLabel = event.target.value;
                var _importNameOrg = this.state.initialImportForm["importName"];
                var fileName = document.getElementById("fileName").value;

                if (_importNameOrg == iLabel || (this.importNameBk && this.importNameBk == iLabel)) {
                    return false;
                }
                this.importNameBk = iLabel;
                this.fileNameBk = fileName;

                //populationLabelBk
                let url = this.props.clientgroup + "/CheckImportNameHasExisted";
                let params = { name: iLabel, accessToken: localStorage.getItem('token') };

                axios.post(url, params)
                    .then(res => {
                        //update new accessToken again
                        var newAccessToken = res && res.data ? res.data.accessToken : null;
                        if (newAccessToken) {
                            localStorage.setItem('token', newAccessToken);
                        }

                        if (!res.data.error && _importNameOrg != iLabel) {
                            _this.props.loadImportConfig(iLabel);
                            setTimeout(function () {
                                _this.setState({
                                    reloadData: true,
                                    xMergeOptionsImpoItemFields: null,
                                });
                            }, 500);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);

                        var btnLogout = document.getElementById("logoutButtonArea");
                        if (btnLogout) {
                            btnLogout.querySelector("a").click();
                        }
                        return false;
                    });

                return false;
            }
        }
    }

    toggleChecked = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            crstarImportForm: { ...this.state.crstarImportForm, [name]: (value == "false" ? "true" : "false") },
            isFormDirty: true,
        });
    }

    hideNotificationImportMsg = (event) => {
        try {
            event.preventDefault();
        } catch (ex) { }
        this.setState({ importRunningMsg: false });
        return false;
    }

    render() {
        let importRunningMsg;
        {
            importRunningMsg = this.state.importRunningMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Notification</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideNotificationImportMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>
                                    A previous import of data did not complete and may still be running. <br />
                                    If you are not aware of other users importing data, please contact ERS to verify before continuing.
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={this.hideNotificationImportMsg} value="ok" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let showUploadSampleFile;
        {
            showUploadSampleFile = this.state.showUploadSampleFile ?
                <div className="CRStar_Window" id="progress_bar_md">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header hidden">
                                <div className="k-window-title k-dialog-title">Progress Bar</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideUploadSampleFile(e, this) }}></span>
                                    </a>
                                </div>
                            </div>

                            <div className="emptyLine"></div>
                            <div className="container row">
                                <div className="col-sm-12">
                                    {this.state.crstarImportForm && this.state.crstarImportForm.programStatus ? this.state.crstarImportForm.programStatus : "Processing the File ... Please Wait"}
                                </div>
                            </div>

                            <div className="emptyLine"></div>

                            {/* Showing IFRAME_PROGRESS_BAR */}
                            <div className="_container" id="IFRAME_PROGRESS_BAR_HTML">
                                <iframe id="IFRAME_PROGRESS_BAR_IFRAME" />
                            </div>

                            <div className="emptyLine"></div>

                            <div className="container row">
                                <div className="col-sm-5">
                                    <label>Start Time:</label>&nbsp;{this.state.crstarImportForm && this.state.crstarImportForm.startTime ? this.state.crstarImportForm.startTime : ""}
                                </div>
                                <div className="col-sm-7">
                                    <label>End Time:</label>&nbsp;{this.state.crstarImportForm && this.state.crstarImportForm.endTime ? this.state.crstarImportForm.endTime : ""}
                                </div>
                            </div>
                            <div className="container row">
                                <div className="col-sm-5">
                                    <label>Records Processed:</label>&nbsp;{this.state.crstarImportForm && this.state.crstarImportForm.recsProcessed ? this.state.crstarImportForm.recsProcessed : ""}
                                </div>
                                <div className="col-sm-7">
                                    <label>Total Records:</label>&nbsp;{this.state.crstarImportForm && this.state.crstarImportForm.totalRecs ? this.state.crstarImportForm.totalRecs : ""}
                                </div>
                            </div>

                            <div className="emptyLine"></div>
                            <div className="emptyLine"></div>

                            <div className="container center">
                                <button id="BTN_CANCEL_PRG_BAR" onClick={(e) => { this.hideUploadSampleFile(e, this) }} value="cancel" autoFocus>Cancel</button>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let copyDialog = (
            this.state.showCopyDialog ?
                <div className="CRStar_Window" id="copyImportConfigForm">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls" style={{ left: this.state.xPos + "px", top: this.state.yPos + "px" }}>
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Select Past Import Reports to View</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close hidden">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideCopyDialog(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container row">
                                <div className="col-sm-12">
                                    <div>
                                        <table className="Past_Import_Report_View">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "80px" }}>Id</th>
                                                    <th style={{ width: "240px" }}>Import</th>
                                                    <th style={{ width: "80px" }}>Date</th>
                                                    <th style={{ width: "80px" }}>Time</th>
                                                    <th style={{ width: "auto" }}>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                    <div id="reportLabelsMvListArea" className="reportLabelsMvListArea">
                                        <div className="reportLabelsMvChildrenArea innerBody" style={{ height: "288px", maxHeight: "288px", overflow: "auto" }}>
                                            <table className="Report_Labels_Mv">
                                                <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                    <tr>
                                                        <th style={{ width: "80px" }}>Id</th>
                                                        <th style={{ width: "240px" }}>Import</th>
                                                        <th style={{ width: "80px" }}>Date</th>
                                                        <th style={{ width: "80px" }}>Time</th>
                                                        <th style={{ width: "auto" }}>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.reportLabelsMv !== null && this.state.reportLabelsMv.length > 0
                                                        ? this.state.reportLabelsMv.map((mv, index) => (
                                                            <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionReportLabelsMvRow(e, index) }} className={this.state.selectedReportLabelsMvRowIndex === index ? "selectedRowCls" : ""}>
                                                                <td>{mv.importedRecordsId ? mv.importedRecordsId : mv.importedId}</td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedReportLabelsMvRowIndex") }}
                                                                        changed={(e) => { this.handleReportLabelsMvGridChange(e, index) }}
                                                                        labelText="" field="pastImport"
                                                                        value={mv.importName ? mv.importName : mv.pastImport} lineStyle="oneLine"
                                                                        dataSource="x_Imported_Records" titleDialog="" //flag={this.state.crstarImportForm.pastImport}
                                                                        width="99%" labelWidth="0px"
                                                                        //lostfocused={(e) => { this.handleReportLabelsMvLostfocus(e, index) }}
                                                                        keyId={"pastImport" + index} //flag={this.state.crstarImportForm.id}
                                                                        rowIndex={index} shownType="dropdown"
                                                                        //displayingCodeOnly={true}
                                                                        //limitByWidth={true}
                                                                        setNameAlias="name+code" //fieldExt2="globalItemNumber"
                                                                        //codeLabel="Item Nbr"
                                                                        //descriptionLabel="Item Description"
                                                                        hideFilter={true}
                                                                    />
                                                                </td>
                                                                <td>{mv.date ? moment(new Date(mv.date)).format("MM/DD/YYYY") : mv.dateImported}</td>
                                                                <td>{mv.time ? mv.time : mv.timeImported}</td>
                                                                <td></td>
                                                            </tr>
                                                        )) : <tr><td colSpan="5"></td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="contactBtnArea right">
                                            <button id="btnAddNewLatestReportLabelsMv" onClick={this.addNewReportLabelsMv}>Add</button>
                                            <button id="btnDeleteSelectedReportLabelsMv" onClick={this.deleteSelectedReportLabelsMv}>Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <button id="BUTTON_COPY_OK" onClick={(e) => { this.copyImportOptions(e) }} value="yes">OK</button>
                                <button id="BUTTON_COPY_CANCEL" onClick={(e) => { this.hideCopyDialog(e) }} value="no">Cancel</button>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        );

        //showingPreviewData
        let showingPreviewData = (
            this.state.showingPreviewData && this.state.crstarImportForm && this.state.crstarImportForm.fnDisplay ?
                <div className="CRStar_Window" id="previewImportDataForm">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableLargestWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">{this.state.crstarImportForm && this.state.crstarImportForm.importName ? this.state.crstarImportForm.importName : "Imported Records"}</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hidePreviewData(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>

                            {/* Displaying report... */}
                            <div className="_container" id="Import_Record_Preview_Html">
                                <iframe id="previewHtmlIframe" />
                            </div>

                            <div className="emptyLine"></div>

                            <div className="_container">
                                <div className="row">
                                    <div className="col-sm-12" style={{ display: "flex", margin: "15px" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ScreenButton" title=""
                                            onClick={this.printTable} autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print
                                            </button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_DOWNLOAD" className="ScreenButton" title="Download Excel Data"
                                            style={{ marginLeft: "5px" }}
                                            disabled={!this.state.crstarImportForm || !this.state.crstarImportForm.fnExcel}
                                            onClick={this.downloadExcel}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src={!this.state.crstarImportForm || !this.state.crstarImportForm.fnExcel ? "https://crstar.ers-can.com/images/disabled/$$file.export.excel.png.a5image" : "https://crstar.ers-can.com/images/$$file.export.excel.png.a5image"} className="" />
                                            Excel
                                            </button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ScreenButton" title=""//ScreenButtonHover
                                            style={{ marginLeft: "5px" }}
                                            onClick={this.hidePreviewData} value="exit">
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit
                                            </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        );

        const crstarImport = this.state.crstarImportForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">
                    <form onSubmit={this.saveImportConfig} id="crstarImportForm" className="DialogForm_TabParentPage">
                        <div className="hidden">
                            <Input type="hidden" field="mergeOptionsId" value={this.state.crstarImportForm.mergeOptionsId} />
                        </div>
                        <div className="emptyLine"></div>
                        <div className="row" style={{ height: "500px" }}>
                            <div className="col-sm-4" style={{ display: "flex" }}>
                                {/**/}
                                <Input type="input" lineStyle="oneLine"
                                    focused={this.handleOnFocus} changed={this.handleFormChange}
                                    labelText="File" field="fileName"
                                    value={this.state.crstarImportForm.fileName}
                                    labelWidth="40px" width="260px" disabled={true}
                                //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["fileName"] : ""}
                                />
                                <div style={{ marginLeft: "4px" }}>
                                    <button id="BUTTON_SELECT_POPULATION"
                                        className="ErsGrBlueButton" autoFocus
                                        onClick={(e) => { this.showUploadFile(e, true) }}>
                                        <img id="BUTTON_SELECT_POPULATION_ICON"
                                            src="https://crstar.ers-can.com/images/$$web.upload.png.a5image"
                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Upload File
                                                    </button>
                                </div>
                                {/**/}
                                <div className="hidden" style={{ display: "none" }}>
                                    <form id="uploadTemplateSampleFile" encType="multipart/form-data" method="post">
                                        <div className="container">
                                            <Input type="file"
                                                field="fileNameDialog" changed={this.onFileChange}
                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                labelText="" labelWidth="0px" width="150px" />
                                        </div>
                                        <div className="container" id="waitingForUploadFile" style={{ display: this.state.selectedFile ? "" : "none" }}>
                                            <span>Uploading Files...</span>
                                        </div>
                                        <div className="emptyLine"></div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <Input type="codeSelect" focused={this.handleOnFocus}
                                    changed={this.handleFormChange}
                                    labelText="Select An Import Option"
                                    field="importName" keyId="importName0" rowIndex="0"
                                    value={this.state.crstarImportForm.importName}
                                    dataSource="x_Merge_Options" titleDialog="" shownType="dropdown"
                                    labelWidth="150px" width="180px" lineStyle="oneLine"
                                    inValid={this.state.dAllItemFields["importName"]}
                                    lostfocused={this.handleLostFocus}
                                    codeLabel="Import" descriptionLabel="Import Type"
                                //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["importName"] : ""}
                                />
                            </div>
                            <div className="col-sm-4">
                                <Input type="checkbox"
                                    focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                    labelText="Create Population Label of New Cases Added to Suspense"
                                    title=""
                                    field="requiresCustomProgram"
                                    labelWidth="370px" width="30px"
                                    value={this.state.crstarImportForm.requiresCustomProgram && this.state.crstarImportForm.requiresCustomProgram == "true" ? "true" : "false"}
                                    checked={this.state.crstarImportForm.requiresCustomProgram && this.state.crstarImportForm.requiresCustomProgram == "true" ? true : false}
                                    clicked={this.toggleChecked} />
                            </div>
                        </div>
                        <hr />

                        <div className="row">
                            <div className="col-sm-3">
                                <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", marginLeft: "15px", paddingTop: "2px" }}
                                    labelText="Start Time" field="startTime" width="auto"
                                    value={this.state.crstarImportForm.startTime ? this.state.crstarImportForm.startTime : "-"}
                                />
                            </div>
                            <div className="col-sm-3">
                                <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", paddingTop: "2px" }}
                                    labelText="End Time" field="endTime" width="auto"
                                    value={this.state.crstarImportForm.endTime ? this.state.crstarImportForm.endTime : "-"}
                                />
                            </div>
                        </div>

                        <fieldset className="bottomAreaButtonCls">
                            <legend></legend>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="A5CWLayout">
                                        <button id="BUTTON_WARNING_CHECK"
                                            className={this.state.crstarImportForm.fileName && this.state.crstarImportForm.importName ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                            onClick={(e) => { this.showEvent(e) }}
                                            onMouseOver={(e) => { this.Check_Import_Status(e) }}
                                            disabled={this.state.crstarImportForm.fileName && this.state.crstarImportForm.importName ? false : true}
                                        >
                                            <img id="BUTTON_WARNING_CHECK_ICON"
                                                src={this.state.crstarImportForm.fileName && this.state.crstarImportForm.importName ? "https://crstar.ers-can.com/images/$$file.import.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$file.import.png.a5image"}
                                                style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Import Data File
                                                    </button>
                                    </div>

                                    <div className="A5CWLayout">
                                        <button id="BUTTON_VIEW_PAST_IMPORTS"
                                            className="ErsGrBlueButton"
                                            onClick={(e) => { this.copyFuncHandler(e) }}
                                        >
                                            <img id="BUTTON_DELETE_ICON"
                                                src="https://crstar.ers-can.com/images/$$generic.preview.png.a5image"
                                                style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        View Past Imports
                                                    </button>
                                    </div>

                                    <div className="A5CWLayout">
                                        <button id="BUTTON_EDIT_SELECT"
                                            className="ErsGrBlueButton"
                                            onClick={(e) => { this.exitImportScreen(e) }}
                                        >
                                            <img id="BUTTON_EDIT_SELECT_ICON"
                                                src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image"
                                                style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Exit
                                                    </button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            )
            : <p className="data_loading_page">Import data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>{showUploadSampleFile}</React.Fragment>
                <React.Fragment>{importRunningMsg}</React.Fragment>
                <React.Fragment>{copyDialog}</React.Fragment>
                <React.Fragment>{showingPreviewData}</React.Fragment>
                <div className={this.props.initialPageVisible ? "" : "hidden"}>
                    {crstarImport}
                </div>
            </React.Fragment>
        );
    }
}

CrstarImport.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        importConfigInfo: state.patientAbstract.importConfigInfo,
        isPatientLoading: state.patientAbstract.isPatientLoading,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,

        //
        clientgroup: state.auth.clientgroup,
        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,
        userId: state.auth.userId,
        initialPageVisible: state.patientAbstract.initialPageVisible,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveImportConfig: (formData) => dispatch(actionCreators.saveImportConfig(formData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index, flag, cid) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag, cid)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),

        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
        loadImportConfig: (label) => dispatch(actionCreators.loadImportConfigInfo(label)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CrstarImport);