/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import "./ClinicalMaintenance.css";

import Clinical00Maint from "./00/Clinical00Maint";
import Clinical11Maint from "./11/Clinical11Maint";
import Clinical15Maint from "./15/Clinical15Maint";
import Clinical16Maint from "./16/Clinical16Maint";
import Clinical18Maint from "./18/Clinical18Maint";
import Clinical20Maint from "./20/Clinical20Maint";
import Clinical25Maint from "./25/Clinical25Maint";
import Clinical33Maint from "./33/Clinical33Maint";
import Clinical34Maint from "./34/Clinical34Maint";
import Clinical37Maint from "./37/Clinical37Maint";
import Clinical38Maint from "./38/Clinical38Maint";
import Clinical44Maint from "./44/Clinical44Maint";
import Clinical47Maint from "./47/Clinical47Maint";
import Clinical50Maint from "./50/Clinical50Maint";
import Clinical51Maint from "./51/Clinical51Maint";
import Clinical54Maint from "./54/Clinical54Maint";
import Clinical56Maint from "./56/Clinical56Maint";
import Clinical58Maint from "./58/Clinical58Maint";
import Clinical61Maint from "./61/Clinical61Maint";
import Clinical62Maint from "./62/Clinical62Maint";
import Clinical64Maint from "./64/Clinical64Maint";
import Clinical65Maint from "./65/Clinical65Maint";
import Clinical67Maint from "./67/Clinical67Maint";
import Clinical71Maint from "./71/Clinical71Maint";
import Clinical72Maint from "./72/Clinical72Maint";

class ClinicalMaintenance extends Component {
    render() {

        let clinicalSiteMaint = null;
        const currSite = this.props.selectedPrimarySummary.siteCode;
        //console.log('Site check: ' + this.props.selectedPrimarySummary.siteCode);

        switch (currSite) {
            case "00": case "01": case "02":
            case "03": case "04": case "05":
            case "06": case "07": case "08":
            case "09": case "10":
                clinicalSiteMaint = <Clinical00Maint />;
                break;
            case "11": case "12": case "13":
            case "14": case "30": case "31":
            case "32":
                clinicalSiteMaint = <Clinical11Maint />;
                break;
            case "15":
                clinicalSiteMaint = <Clinical15Maint />;
                break;
            case "16": case "17": case "22":
            case "23": case "24": case "26":
            case "39": case "40": case "41":
            case "42": case "48": case "49":
            case "60": case "63": case "69":
            case "70": case "73": case "75":
            case "76": case "77": case "80":
                clinicalSiteMaint = <Clinical16Maint />;
                break;
            case "18": case "19":
                clinicalSiteMaint = <Clinical18Maint />;
                break;
            case "20": case "21":
                clinicalSiteMaint = <Clinical20Maint />;
                break;
            case "25":
                clinicalSiteMaint = <Clinical25Maint />;
                break;
            case "33":
                clinicalSiteMaint = <Clinical33Maint />;
                break;
            case "34":
                clinicalSiteMaint = <Clinical34Maint />;
                break;
            case "37":
                clinicalSiteMaint = <Clinical37Maint />;
                break;
            case "38":
                clinicalSiteMaint = <Clinical38Maint />;
                break;
            case "44":
                clinicalSiteMaint = <Clinical44Maint />;
                break;
            case "47": case "74":
                clinicalSiteMaint = <Clinical47Maint />;
                break;
            case "50":
                clinicalSiteMaint = <Clinical50Maint />;
                break;
            case "51": case "52": case "53":
            case "57":
                clinicalSiteMaint = <Clinical51Maint />;
                break;
            case "54": case "55":
                clinicalSiteMaint = <Clinical54Maint />;
                break;
            case "56":
                clinicalSiteMaint = <Clinical56Maint />;
                break;
            case "58":
                clinicalSiteMaint = <Clinical58Maint />;
                break;
            case "61":
                clinicalSiteMaint = <Clinical61Maint />;
                break;
            case "62":
                clinicalSiteMaint = <Clinical62Maint />;
                break;
            case "64":
                clinicalSiteMaint = <Clinical64Maint />;
                break;
            case "65": case "66": case "68":
                clinicalSiteMaint = <Clinical65Maint />;
                break;
            case "67":
                clinicalSiteMaint = <Clinical67Maint />;
                break;
            case "71":
                clinicalSiteMaint = <Clinical71Maint />;
                break;
            case "72":
                clinicalSiteMaint = <Clinical72Maint />;
                break;

            default:
                break;
        }

        // console.log(clinicalSiteMaint)
        return (
            <React.Fragment>
                <React.Fragment>
                    {clinicalSiteMaint}
                </React.Fragment>
            </React.Fragment>
        );
    }   
}

ClinicalMaintenance.propTypes = {
// This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
// And is expected to be passed in from this component's parent
//closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
    }
}

export default connect(mapStateToProps, null)(ClinicalMaintenance);