/* eslint-disable */
import React, { Component } from "react";
import Draggable from 'react-draggable';
import ConfigData from '../../../store/config.json';
import { connect } from "react-redux";
import axios from "axios";

class WebLinks extends Component {

    state = {
        loaded: false,
        links: []
    }

    closeWindow = (e) => {
        try {
            e ? e.preventDefault() : "";
        } catch (e) { }
        this.props.closeWindow();
        return false;
    }

    componentDidMount() {
        let url = ConfigData.CRStarAPI_URL + "ers/GetWebLinksInfo";
        axios.post(url, { cg: this.props.clientgroup, accessToken: localStorage.getItem('token') })
            //.then(res => res.json())
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                return res.data.commonLookupList ? this.setState({ links: res.data.commonLookupList }) : "";
            })
            .catch(function (error) {
                console.log(error);
                _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    }

    openWindow = (link, event) => {
        event.preventDefault();
        window.open(link, "_blank");
        return false;
    }

    render() {
        let webLinks = null;

        {
            webLinks = <Draggable handle=".k-window-titlebar">
                <div className="dragableLargerDialogCls" id="webLinksModal">
                    <div className="k-window-titlebar k-dialog-titlebar k-header">
                        <div className="k-window-title k-dialog-title">Window</div>
                        <div className="k-window-actions k-dialog-actions">
                            <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                <span className="k-icon k-i-x" onClick={(e) => { this.closeWindow(e) }}></span>
                            </a>
                        </div>
                    </div>

                    <div className="_container" style={{ margin: "-10px 0px 0px 20px" }}>
                        <div className="row center">
                            <div className="col-sm-11">
                                <h4 style={{ fontWeight: "bolder" }}>CRStar Quick Links</h4>
                                <span>Please Make Your Selection:</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-5">
                                {
                                    this.state.links !== null && this.state.links.length > 0
                                        ? this.state.links.map((o, i) => (
                                            i < 10 ? (
                                                <div className="row">
                                                    <div className="A5CWLayout">
                                                        <button type="button" className="ScreenButton" onClick={e => { this.openWindow(o.name, e) }}>
                                                            <img src="https://crstar.ers-can.com/images/a5_htmled_hyperlink.png.a5image"></img>
                                                        </button>
                                                    </div>
                                                    <div className="A5CWLayout">
                                                        <span className="ErsGrBlueGridData">{o.code}</span>
                                                    </div>
                                                </div>) : ""
                                        )) : null
                                }
                            </div>
                            <div className="col-sm-1"></div>
                            <div className="col-sm-5">
                                {
                                    this.state.links !== null && this.state.links.length > 0
                                        ? this.state.links.map((o, i) => (
                                        i >= 10 ? (
                                        <div className="row">
                                            <div className="A5CWLayout">
                                                <button type="button" className="ScreenButton" onClick={e => { this.openWindow(o.name, e) }}>
                                                    <img src="https://crstar.ers-can.com/images/a5_htmled_hyperlink.png.a5image"></img>
                                                </button>
                                            </div>
                                            <div className="A5CWLayout">
                                                <span className="ErsGrBlueGridData">{o.code}</span>
                                            </div>
                                        </div>) : ""
                                        )) : null
                                }
                            </div>
                        </div>

                        <br/>
                        <div className="row">
                            <div className="col-sm-11 center">
                                <button id="btnWebLinksExit" className="ScreenButton" onClick={(e) => { this.closeWindow(e) }} autoFocus>Exit</button>
                            </div>
                        </div>

                    </div>
                </div>
            </Draggable>
        }

        return (
            <div>
                <div className="fake_popup_cls"></div>
                {webLinks}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        clientgroup: state.auth.clientgroup,
    }
}

export default connect(mapStateToProps, null)(WebLinks);