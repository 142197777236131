/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import Draggable from 'react-draggable';
import "./Timeliness.css";
import PopLabel from "../PopLabel/PopulationLabel"; //Import the Editable Pop Label 02/10/2022


class Timeliness extends Component {
    clockInterval = "";
    downloadFile = false;
    state = {
        dateOption: "Date Abstracted",
        timeIncrement: "1 Month",
        includePatientList: "",
        outputType: "Screen",
        
        htmlOutput: null,
        startTime: null,
        endTime: null,
        statusBar: "Start Time: ",

        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        loaded: true,
        titleDialog: null,
        showClockTimer: false,
        reportStatus: "Not Running", //This variable is used to control whether clock timers continue, reports display/download etc. -- "Not Running" == initial state | "Cancelled" == user has clicked the cancel button after attempting to run a report | "Report Running" == Report in the C# file has been called, but no response back recognized from it | "Report Finished" == Report in the C# function has successfully completed

    }


    setStateDateOption = (event) => {
        const value = event.target.value;
        const oldValue = this.state.dateOption;

        this.setState({
            dateOption: value,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateTimeIncrement = (event) => {
        const value = event.target.value;
        const oldValue = this.state.timeIncrement;

        this.setState({
            timeIncrement: value,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateOutputType = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.outputType;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;

        this.setState({
            outputType: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    toggleChecked = (event) => {
        const name = event.target.name;
        var dialogFormData = this.state.dialogForm;

        if (name == "patientList") {
            const checked = this.state.includePatientList;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                includePatientList: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        }
    }

    updatePopLabelBackToParent = (plabel) => {
        var form = this.state.dialogForm;
        form.populationLabel = plabel;
        this.setState({
            dialogForm: form,
        });
        return false;
    }

    updateToParent = (plForm) => {
        var reportingForm = this.state.dialogForm;
        if (plForm) {
            Object.keys(plForm).map((t, k) => {
                !reportingForm[t] ? reportingForm[t] = plForm[t] : null;
            });

            let labelsSiteMvSap = plForm.labelsSiteMvSap;
            let labelsMv = plForm.labelsMv;

            reportingForm.labelsSiteMvSap = labelsSiteMvSap;
            reportingForm.labelsMv = labelsMv;
        }

        this.setState({
            dialogForm: reportingForm,
        });
        return false;
    }

    componentDidMount() {
        var _this = this;
        //await _this.props.generateRequestLog();  //The report doesn't run without this code, but why?  What does this function do?
        _this.props.generateTimeliness();

        if (!this.state.dialogForm) { // && !this.props.requestLogInfo
            this.setFormData(this.props.TimelinessInfo);
        }
        this.setState({ isLoading: true });
    }

    componentWillMount() {

    }
 
    componentDidUpdate(prevProps, prevState) {
        if (!this.state.dialogForm) {
            this.setFormData(this.props.timelinessInfo);
        }

        var timelinessInfo = this.props.timelinessInfo;
        if (this.state.dialogForm && (prevProps.timelinessInfo && this.props.timelinessInfo && (prevProps.timelinessInfo.startTime !== this.props.timelinessInfo.startTime
            || prevProps.timelinessInfo.endTime !== this.props.timelinessInfo.endTime))) {
        
            clearInterval(this.clockInterval); //If the report has finished, clear the clock interval
            this.setState({
                showClockTimer: false, //If the report has finished, hide the clock
                endTime: new Date().toLocaleTimeString(),
                reportStatus: "Report Finished",
            });

            if (timelinessInfo.output_Type == "Screen" && timelinessInfo.htmlOutput) //If the report has finished, it was sent to the screen, and we have html data returned, display the report to the screen
                this.displayReport(timelinessInfo);
            else if (timelinessInfo.output_Type == "Excel" && timelinessInfo.urlOutputFile && !this.downloadFile) { //If the report has finished, it was sent to Excel, and we have a url/path to the Excel file returned by the report, download the file
                var fileURL = this.props.clientgroup + "/DownloadFile";
                this.downloadxlsx(fileURL, timelinessInfo.urlOutputFile); //Download the Excel file produced by the report
                this.downloadFile = true;
            }
        }
    }

    downloadxlsx(fileURL, urlOutputFile) {
        axios.post(fileURL, {
            method: 'POST',
            responseType: 'blob',//important
            urlOutputFile: urlOutputFile,
            accessToken: localStorage.getItem("token")
        }).then((response) => {
            var fileName = urlOutputFile.substring(urlOutputFile.lastIndexOf('\\') + 1);
            const link = document.createElement('a');

            let excel;
            if (urlOutputFile.indexOf('/') != -1) {
                excel = urlOutputFile.substring(urlOutputFile.lastIndexOf('/') + 1);
            } else {
                excel = urlOutputFile.substring(urlOutputFile.lastIndexOf('\\') + 1);
            }
            link.href = this.props.clientgroup + "/" + excel;

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return false;
        })
        .catch(function (error) {
            console.log(error);
        });

        return false;
    }


    setFormData = (timelinessInfo) => {
        this.setState({
            initialDialogForm: { ...timelinessInfo },
            dialogForm: { ...timelinessInfo },

            //["outputType"]: requestLogInfo && requestLogInfo.sendReportTo ? requestLogInfo.sendReportTo : "Screen",
            reload: false,
            changed: true,
            showClockTimer: false,
            titleDialog: null,
            isDrawingCharts: false,
            reportStatus: null,
        });
        console.log("Report Status: ", this.state.reportStatus);

        this.setState({ reportStatus: "Not Currently Running", });
        return false;
    }

    handleOnFocus = (event, index, alias) => {

    }

    handleFormChange = (event) => {
if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true,
            });
        } else {
            this.setState({
                //dialogForm: {
                //    ...this.state.dialogForm,
                //    [event.target.name]: event.target.value
                //},
                [event.target.name]: event.target.value, //11/07/2022 - Switching to this per REA to resolve the issue of typing in the hospital code directly
                isFormDirty: true,
            });
        }
    }

    handleLostFocus = (event) => {

    }




    Run_Timeliness = () => {
        //Collate all values needed by the report into one object, dialogFormData, and pass it to the report.
        var dialogFormData = this.state.dialogForm;

        dialogFormData.reportStatus = "Report In Progress";
        dialogFormData.populationLabel = document.getElementById("populationLabel").value;
        dialogFormData.date_Option = this.state.dateOption;
        dialogFormData.time_Increment = this.state.timeIncrement;
        dialogFormData.output_Type = this.state.outputType;
        dialogFormData.Include_Patient_List = this.state.includePatientList;

        dialogFormData.reportStatus = this.state.reportStatus;
        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;
        

        //alert("Running Request Log with 'This dialog' values of: " + JSON.stringify(this.state.dialogForm, null, 4))
        console.log("Running Timeliness with 'This' values of: " + JSON.stringify(this.state.dialogForm, null, 4));

        this.props.runTimeliness(dialogFormData);
        
        this.downloadFile = false;//reset this flag to get download new file

        return false;
    }

    //on before show event
    showClockEvent(e) {
        try {
            e.preventDefault();// This function prevents the form from being auto-submitted
        } catch (ex) {}

        var popLabelComponent = document.getElementById("btnCallToParentFunc");
        if (popLabelComponent) {
            popLabelComponent.click();
        }


        var _this = this;
        _this.setState({ showClockTimer: true, startTime: new Date().toLocaleTimeString(), reportStatus: "Report Running" }); // set clock timer...

        this.Run_Timeliness();

        this.clockInterval = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(this.clockInterval);
            } else {
                console.log("Timer Interval Running");
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
                _this.setState({ currentTime: new Date() ? new Date().toLocaleTimeString() : " " });
            }
        }, 1000);

        return false; // Returns false to stop everything after this event has been completed

    }

    Cancel_Report = (e, _this) => {
        _this.setState({ showClockTimer: false });
        clearInterval(this.clockInterval);
        return false;
    }


    displayReport = (data) => {
        this.setState({
            showClockTimer: false,//hide clock timer
            endTime: new Date().toLocaleTimeString(),
            htmlOutput: data.htmlOutput, //Commenting out because I think this already gets set in the data tunnel, but will have to test
            isDrawingCharts: true
        });

        var reportDisplay = document.getElementById("displayReportIframe");
        if (reportDisplay) { //This function runs twice and in one instance this component doesn't exist, so this check needs to be there to prevent the entire screen from erroring out
            var reportContent = reportDisplay.contentDocument;
            reportContent.write(data.htmlOutput);
        }

        return false;
    }


    hideDrawingCharts = (e) => {
        this.setState({ isDrawingCharts: false });
        return false;
    }


    newRecord(e) {
        try {
            e.preventDefault();  //Added to prevent the entire window from refreshing when reload is set to "true" below
            this.props.selectAPopLabel("");  //Added to clear population label

            this.setState({
                dateOption: "Date Abstracted",
                timeIncrement: "1 Month",
                includePatientList: "",
                outputType: "Screen",

                htmlOutput: null,
                startTime: null,
                endTime: null,
                statusBar: "",
                reload: true,  //Added to refresh population label criteria
                isFormDirty: false,
            })
        } catch (ex) {}
    }


    printReport = (e) => {
        var doc = document.getElementById("displayReportIframe").contentDocument;

        var printStyle = doc.getElementById('PrintStyle');
        var printContent = doc.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Timeliness</title><style type="text/css">';
        html += printStyle.innerHTML + '</style></head><body><div id="printableContent">';
        html += printContent.innerHTML + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }

    render() {
        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window" style={{ cursor: "progress" }}>
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait <div class="waitingWaves"><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div></div></div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTime}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let drawingCharts;
        {
            drawingCharts = this.state.isDrawingCharts ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="timelinessReportDisplay" className="dragableLargestWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Timeliness Report</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingCharts(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="container" id="timelinessShowingCharts">
                                <iframe id="displayReportIframe" />
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12" style={{ display: "flex" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ErsGrBlueButton" title=""
                                            onClick={(e) => { this.printReport(e) }}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print
                                        </button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ErsGrBlueButton" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={(e) => { this.hideDrawingCharts(e) }} value="exit" autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let dialogForm = <div className="reportDialogForm">
            <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                <div className="reportDialogFormContent">
                    <div id="popLabel">
                        {/*Adding modular "Population Label" component on top of this screen*/}
                        <PopLabel plUpdFnc={this.updatePopLabelBackToParent} showToggleBtn={true} updateToParent={this.updateToParent} />
                    </div>

                    <div className="reportScreenTitle">Timeliness Reports</div>
                    {this.state.dialogForm || this.state.loaded ?

                    <form onSubmit={false} id="timelinessForm" className="">
                        <div className="frameInner">
                            <br />
                            <div className="row">
                                <div className="dateOptionRadio">
                                    <div id="dateOptionLabel">Date Used for Calculation:</div>
                                    <label>
                                        <input type="radio" name="dateOption" field="dateOption" value="Date Abstracted"
                                            onClick={this.setStateDateOption}
                                            checked={this.state.dateOption === "Date Abstracted"} />
                                        Date Abstracted
                                    </label>
                                    <br />
                                    <label>
                                        <input type="radio" name="dateOption" field="dateOption" value="Date Completed"
                                            onClick={this.setStateDateOption}
                                            checked={this.state.dateOption === "Date Completed"} />
                                        CoC Date Case Completed
                                    </label>
                                </div>
                            </div>

                            <div className="timeIncrementRadio">
                                <div id="timeIncrementLabel">Time Increment:</div>
                                <label>
                                    <input type="radio" name="timeIncrement" field="timeIncrement" value="1 Month"
                                        onClick={this.setStateTimeIncrement}
                                        checked={this.state.timeIncrement === "1 Month"} />
                                    1 Month
                                </label>
                                <br />
                                <label>
                                    <input type="radio" name="timeIncrement" field="timeIncrement" value="2 Months"
                                        onClick={this.setStateTimeIncrement}
                                        checked={this.state.timeIncrement === "2 Months"} />
                                    2 Months
                                </label>
                                <br />
                                <label>
                                    <input type="radio" name="timeIncrement" field="timeIncrement" value="3 Months"
                                        onClick={this.setStateTimeIncrement}
                                        checked={this.state.timeIncrement === "3 Months"} />
                                    3 Months
                                </label>
                            </div>
                            <br />
                            <input type="checkbox" id="patientList" className="checkbox"
                                name="patientList"
                                value={this.state.includePatientList ? "true" : "false"}
                                checked={this.state.includePatientList}
                                onChange={this.toggleChecked} />
                            <label for="patientList" className="checkboxLabel"> Include Patient List </label>

                            <div className="row">
                                <div className="outputDeviceRadio">
                                    <div id="outputDeviceLabel">Send Report To:</div>
                                    <label>
                                        <input type="radio" name="outputType" field="outputType" value="Excel"
                                            onClick={this.setStateOutputType}
                                            checked={this.state.outputType === "Excel"} />
                                        Excel
                                    </label>
                                    <br />
                                    <label>
                                        <input type="radio" name="outputType" field="outputType" value="Screen"
                                            onClick={this.setStateOutputType}
                                            checked={this.state.outputType === "Screen"} />
                                        Screen
                                    </label>
                                </div>
                            </div>
                            <br />

                            <div id="CONTAINER_JS_CLOCK" className="hidden">
                                <label>Selecting Cases</label>
                                <div id="IMAGE_WAIT" style={{ color: "black", fontWeight: "bold" }}>
                                    Selecting Cases - Please Wait ...
                                </div>
                            </div>
                        </div>

                        <div className="reportScreenStatusBar">
                                Start Time: {this.state.startTime} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; End Time: {this.state.endTime}
                        </div>

                        <fieldset className="reportDialogFormButtonBar">
                            <button id="BUTTON_RUN"
                                className="ErsGrBlueButton"
                                onClick={(e) => { this.showClockEvent(e) }}
                                //disabled={!this.state.isFormDirty}
                            >
                                <img id="BUTTON_RUN_ICON"
                                    src="https://crstar.ers-can.com/images/$$code.action.run.png.a5image"
                                    style={{ verticalAlign: "middle", border: "none" }}></img>
                                Run
                            </button>

                            <button id="BUTTON_NEWRECORD"
                                className={this.state.isFormDirty ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                onClick={this.newRecord.bind(this)}
                                //onClick={(e) => { this.showClockEvent(e) }}
                                disabled={!this.state.isFormDirty}
                            >
                                <img id="BUTTON_NEWRECORD_ICON"
                                    src={this.state.isFormDirty ? "https://crstar.ers-can.com/images/$$navigate.new.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$navigate.new.png.a5image"}
                                    style={{ verticalAlign: "middle", border: "none" }}></img>
                                New
                            </button>
                        </fieldset>
                    </form>
                        : <p>Timeliness report screen encountered an issue and cannot load ...</p>}
                </div>
            </div>
        </div>
 
        return (
            <React.Fragment>
                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {drawingCharts}
                </React.Fragment>

                <React.Fragment>
                    {dialogForm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        timelinessInfo: state.patientAbstract.timelinessInfo,           //This holds information passed from the C# report back to the screen
        clientgroup: state.auth.clientgroup,                            //Passed to C# report to get path
        userId: state.auth.userId,                                      //Passed to C# report to get path

        initialPageVisible: state.patientAbstract.initialPageVisible,   //Controls if security restrictions are set to prevent page from loading
        isDialogLoading: state.patientAbstract.isDialogLoading,
        waitingFor: state.patientAbstract.waitingFor,

        //Trying to get hospital control to work//
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        isAddNewUser: state.patientAbstract.isAddNewUser,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
        generateTimeliness: (flag) => dispatch(actionCreators.generateTimeliness(flag)),
        runTimeliness: (dialogForm) => dispatch(actionCreators.runTimeliness(dialogForm)),
        

        //Trying to get hospital control to work//
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearAddNewUserFlag: () => dispatch(actionCreators.clearAddNewUserFlag()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),
        selectAPopLabel: (label) => dispatch(actionCreators.selectAPopLabel(label)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Timeliness);