/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Input from "../../../UI/Input";
import "./Hospital.css";
import axios from "../../../../axios-instance";
import Footer from "../../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
import "../../../../components/AbstractManagement/PatientAbstract/TreatmentMaintenance/TreatmentMaintenance.css";
import * as Global from "../../../../store/Global";
import Draggable from 'react-draggable';

class PhysicianForm extends Component {

    state = {
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        isCheckedDoNotContact: false,
        pAllItemFields: null,
        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        showMessageError: false,
        hasError: false,
        savedForm: false,
        fieldInFocus: null,
        focusingField: null,
        // State
        hasNotificationStateMsg: false,
        updatedDataStateInfo: null,
        updatingItemState: null,
        updatingStateValue: null,
        // City
        hasNotificationCityMsg: false,
        updatedDataCityInfo: null,
        updatingItemCity: null,
        updatingCityValue: null,

        fieldInFocus: "hospNbrDsp",
        fieldInFocusByIndex: null,
        isHelpVisible: false,
        focusingField: null,
        isSaving: false,
        resetAllFlag: false,
        onBlurEventAndNotFocus: false,
    }

    hideNotificationStateMsg = (event) => {
        try {
            event ? event.preventDefault() : "";

            // focus into previous field
            if (this.state.fieldInFocus !== "") {
                let focusingField = this.state.fieldInFocus;
                setTimeout(function () {
                    document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
                }, 0);
            }
        } catch (ex) { }
        this.setState({ hasNotificationStateMsg: false });

        if (!this.state.hasNotificationCityMsg && !this.state.hasNotificationCountyMsg && !this.state.hasNotificationStateMsg)
            this.props.setChildrenOpening(false);

        return false;
    }
    updateDataStateInfo = () => {
        const newState = this.state.updatedDataStateInfo;
        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                state: newState
            },
            hasNotificationStateMsg: false,
            updatedDataStateInfo: null,
            isFormDirty: true,
            // clear IN_VALID flag if it's existing
            pAllItemFields: {
                ...this.state.pAllItemFields,
                state: "",
            },
        });

        // focus into previous field
        if (this.state.fieldInFocus !== "") {
            let focusingField = this.state.fieldInFocus;
            setTimeout(function () {
                document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
            }, 0);
        }

        if (!this.state.hasNotificationCityMsg && !this.state.hasNotificationCountyMsg && !this.state.hasNotificationStateMsg)
            this.props.setChildrenOpening(false);
    }

    hideNotificationCityMsg = (event) => {
        try {
            event ? event.preventDefault() : "";

            // focus into previous field
            if (this.state.fieldInFocus !== "") {
                let focusingField = this.state.fieldInFocus;
                setTimeout(function () {
                    document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
                }, 0);
            }
        } catch (ex) { }
        this.setState({ hasNotificationCityMsg: false });

        if (!this.state.hasNotificationCityMsg && !this.state.hasNotificationCountyMsg && !this.state.hasNotificationStateMsg)
            this.props.setChildrenOpening(false);

        return false;
    }
    updateDataCityInfo = () => {
        const newCity = this.state.updatedDataCityInfo;
        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                city: newCity
            },
            hasNotificationCityMsg: false,
            updatedDataCityInfo: null,
            isFormDirty: true,
            // clear IN_VALID flag if it's existing
            pAllItemFields: {
                ...this.state.pAllItemFields,
                city: "",
            },
        });

        // focus into previous field
        if (this.state.fieldInFocus !== "") {
            let focusingField = this.state.fieldInFocus;
            setTimeout(function () {
                document.getElementById(focusingField) ? document.getElementById(focusingField).focus() : "";
            }, 0);
        }

        if (!this.state.hasNotificationCityMsg && !this.state.hasNotificationCountyMsg && !this.state.hasNotificationStateMsg)
            this.props.setChildrenOpening(false);
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });

        var phyId = this.state.dialogForm.physiciansIdDsp;
        if (event.target.name === "firstName") {
            if (event.target.value === "" && phyId === "") {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, ["physiciansIdDsp"]: "IN_VALID" },
                    fieldInFocus: "physiciansIdDsp"
                });
            }
        }

        return false;
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showMessageError: false, hasError: false });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector(".Footer2>#btnSubmitForm_Fake") ? document.querySelector(".Footer2>#btnSubmitForm_Fake").focus() : "";
        }, 0);

        return false;
    }

    resetAllFields() {
        var currentPhysForm = document.querySelector(".physicians_maint_form");
        if (currentPhysForm) {
            if (currentPhysForm && currentPhysForm.querySelectorAll("input")) {
                currentPhysForm.querySelectorAll("input").forEach((ip, index) => {
                    ip.removeAttribute("disabled");
                    ip.style.backgroundColor = "";
                });
            }
            if (currentPhysForm && currentPhysForm.querySelectorAll("textarea")) {
                currentPhysForm.querySelectorAll("textarea").forEach((ta, index) => {
                    ta.removeAttribute("disabled");
                    ta.style.backgroundColor = "";
                });
            }

            this.setState({ resetAllFields: true });
        }
    }

    componentDidMount() {
        if (!this.state.dialogForm && this.props.selectedPhysician) {
            this.setFormData();
        }

        var childrenOpening = this.props.childrenOpening;
        this.props.setChildrenOpening(childrenOpening);

        //Fixing #PR00574 - Rapid Abstract
        this.resetAllFields();
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.dialogForm && this.props.selectedPhysician)
            || (prevProps.selectedPhysician && this.props.selectedPhysician && prevProps.selectedPhysician.physiciansId !== this.props.selectedPhysician.physiciansId)
            || (this.props.selectedPhysician && this.props.selectedPhysician.physiciansId !== null && this.props.selectedPhysician.physiciansId !== "" && this.props.selectedPhysician.physiciansId !== this.state.dialogForm.physiciansId && (this.state.dialogForm.physiciansId === null || this.state.dialogForm.physiciansId === ""))
            || (this.props.selectedPhysician && (this.props.selectedPhysician.physiciansId === null || this.props.selectedPhysician.physiciansId === "") && this.props.selectedPhysician.physiciansId !== this.state.dialogForm.physiciansId && this.state.dialogForm.physiciansId !== null && this.state.dialogForm.physiciansId !== "")
            || (!prevProps.selectedPhysician && this.props.selectedPhysician && this.props.selectedPhysician.physiciansId !== null && this.props.selectedPhysician.physiciansId !== "" && this.props.selectedPhysician.physiciansId !== this.state.dialogForm.physiciansId)
        ) {
            this.setFormData();
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null && !this.state.loadedTableStructure) {
            this.setState({ loadedTableStructure: true });
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        if (this.state.savedForm && !this.props.isDialogLoading && ((prevProps.isFormDirty != this.props.isFormDirty || this.props.error) || (prevProps.error != this.props.error || !this.state.isFormDirty))) {
            if (this.props.isFormDirty) {
                if (!this.state.hasError && this.state.hasError != this.props.error) {
                    this.setState({ showMessageError: true, hasError: true, savedForm: false, isFormDirty: true });
                    this.props.setChildrenOpening(true);
                }
            } else {
                if (this.state.isFormDirty && this.state.isFormDirty != this.props.isFormDirty) {
                    this.setState({ isFormDirty: this.props.isFormDirty, savedForm: false, hasError: false });
                }
            }
        }

        if (this.state.isFormDirty && (this.state.isSaving || this.props.deletedDataSuccess)
            && (prevProps.isFormDirty !== this.props.isFormDirty || this.state.isFormDirty !== this.props.isFormDirty)) {
            this.setState({
                isFormDirty: this.props.isFormDirty,
                isSaving: false,//this.props.isFormDirty,
            });
        }

        if (this.props.childrenOpening && !this.state.resetAllFlag
            && !this.state.hasNotificationCityMsg && !this.state.hasNotificationStateMsg) {
            this.props.setChildrenOpening(false);
            this.setState({ resetAllFlag: true });
        }

        if (!this.state.resetAllFields) {
            //Fixing #PR00574 - Rapid Abstract
            this.resetAllFields();
        }
    }

    async getTableStructure() {
        //let url = this.props.clientgroup + "/GetTableStructure?table=Physicians";
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "Physicians", accessToken: localStorage.getItem('token') };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    setFormData = () => {
        let dataForm = this.props.selectedPhysician;

        Object.keys(dataForm).map((t) => {
            let dataVal = dataForm[t];
            if (t === "zip" && dataVal !== null && dataVal !== "") {
                var oldVal = dataVal;
                var newVal = oldVal.replace(/[^A-Za-z0-9]/gi, '').toUpperCase();
                if (oldVal != '') {
                    //Canadian
                    if (isNaN(newVal) == true) {
                        if (newVal.length >= 4) {
                            newVal = newVal.substring(0, 3) + " " + newVal.substring(3, 6);
                        }

                        //US
                    } else {
                        newVal = oldVal.replace(/[^0-9]/gi, '');
                        if (newVal.length >= 6) {
                            newVal = newVal.substring(0, 5) + "-" + newVal.substring(5, 9);
                        }
                    }

                    dataVal = newVal;
                }
            } else if ((t === "phoneNbr" || t === "faxNbr") && dataVal !== null && dataVal !== "") {
                // Format_Phone
                var region = '';//FN_Region
                var oldVal = dataVal;
                var newVal = oldVal;

                if (region == 'USA' || region == '') {
                    newVal = oldVal.replace(/[^0-9]/gi, '');
                    newVal = newVal.substring(0, 10);

                    if (newVal.length >= 1 && newVal.length <= 3) {
                        newVal = "(" + newVal.substring(0, 3) + ") "
                    } else if (newVal.length >= 4 && newVal.length <= 7) {
                        newVal = "() " + newVal.substring(0, 3) + "-" + newVal.substring(3, 7);
                    } else if (newVal.length >= 8 && newVal.length <= 10) {
                        newVal = "(" + newVal.substring(0, 3) + ") " + newVal.substring(3, 6) + "-" + newVal.substring(6, 10);
                    }
                }

                dataVal = newVal;
            }

            dataForm[t] = dataVal;
        });

        //
        let checked = false;
        if (this.props.selectedPhysician["doNotContact"] == "1") {
            checked = true;
        }

        this.setState({
            initialDialogForm: { ...dataForm },
            dialogForm: { ...dataForm },
            reload: false,
            hasError: this.props.error,
            savedForm: false,
            hasError: false,
            isCheckedDoNotContact: checked,
        });

        if (!this.state.pAllItemFields && this.props.selectedPhysician) {
            let fields = {};
            Object.keys(this.props.selectedPhysician).map((t) => {
                fields[t] = "";
            });

            this.setState({ pAllItemFields: fields, pageMaxlengthInput: fields });
        };

        /*
        var _this = this;
        setTimeout(function () {
            document.getElementById("physiciansIdDsp") && _this.state.onBlurEventAndNotFocus ? document.getElementById("physiciansIdDsp").focus() : "";
            _this.setState({ onBlurEventAndNotFocus: false });
        }, 500);
        */
    }

    toggleChecked = () => {
        const checked = this.state.isCheckedDoNotContact;
        this.setState({
            isCheckedDoNotContact: !checked,
            isFormDirty: true,
        });
    }

    savePhysician = (event) => {
        event.preventDefault();

        // TODO Form validation
        if (true) {
            const checked = this.state.isCheckedDoNotContact;

            let dialogFormData = this.state.dialogForm;
            dialogFormData["physiciansId"] = dialogFormData["physiciansIdDsp"];
            dialogFormData["doNotContact"] = checked ? "1" : "0";

            this.props.savePhysician(dialogFormData);

            this.setState({ savedForm: true, isSaving: true, reload: false, /*isFormDirty: false*/ });
        }
        return false;
    }

    resetPhysician = () => {
        this.setState({
            dialogForm: { ...this.state.initialDialogForm },
            isFormDirty: false,
            reload: false
        });
    }

    handleOnBlur = (event) => {
        // load if whether this value is existing
        if (event.target.name === "physiciansIdDsp") {
            if (event.target.value === "") {
                //this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "IN_VALID" } });
            } else {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "" } });
            }

            if (this.state.dialogForm && this.state.dialogForm.physiciansIdDsp) {
                this.props.addNewItem(event.target.value, this.props.isNotExist ? true : false);
                this.setState({ onBlurEventAndNotFocus: true });
            }

            return false;

        } else if (event.target.name === "zip") {
            let name = event.target.name;
            let value = event.target.value;
            if (value === "") {
                const newVal = this.props.selectedPatient.currZip;
                this.setState({
                    dialogForm: { ...this.state.dialogForm, [name]: newVal },
                    isFormDirty: (newVal !== null && newVal !== "") ? true : this.state.isFormDirty
                });
            } else {
                var oldVal = value;
                var newVal = oldVal.replace(/[^A-Za-z0-9]/gi, '').toUpperCase();
                var regionUSA = false;
                if (oldVal != '') {
                    //Canadian
                    if (isNaN(newVal) == true) {
                        if (newVal.length >= 4) {
                            newVal = newVal.substring(0, 3) + " " + newVal.substring(3, 6);
                        }

                        //US
                    } else {
                        newVal = oldVal.replace(/[^0-9]/gi, '');
                        if (newVal.length >= 6) {
                            newVal = newVal.substring(0, 5) + "-" + newVal.substring(5, 9);
                        }
                        regionUSA = true;
                    }
                }

                this.setState({ dialogForm: { ...this.state.dialogForm, [name]: newVal } });

                // @TODO: zip code entry should tell me the city/state to let me select it
                var city = this.state.dialogForm.city !== null ? this.state.dialogForm.city.toUpperCase() : "";
                var state = this.state.dialogForm.state !== null ? this.state.dialogForm.state.toUpperCase() : "";

                // ERSValidateCityStateZip/ers_citystate_from_zip
                //if (regionUSA) {
                // 
                var zip = newVal;
                var url = "https://production.shippingapis.com/ShippingAPITest.dll?API=CityStateLookup&XML=<CityStateLookupRequest USERID='968ELECT7482'><ZipCode ID= '1'><Zip5>" + zip + "</Zip5></ZipCode></CityStateLookupRequest>";
                axios.get(url)
                    .then(response => {
                        const raw = response.data;
                        const parser = new DOMParser();
                        const xml = parser.parseFromString(raw, 'text/xml');//

                        var _city = xml.querySelector('City') ? xml.querySelector('City').textContent : "";
                        var _state = xml.querySelector('State') ? xml.querySelector('State').textContent : "";
                        if (zip === "88888") {
                            _city = "";
                            _state = "";
                        } else {
                            _city = _city.toUpperCase();
                            _state = _state.toUpperCase();
                        }

                        // City
                        if (_city !== "" && _city !== city && city !== null && city !== "") {
                            this.setState({
                                hasNotificationCityMsg: true,
                                updatedDataCityInfo: _city,
                                updatingItemCity: "city",
                                resetAllFlag: false
                            });
                            this.props.setChildrenOpening(true);
                        } else if (_city !== "" && _city !== city && (city === null || city === "")) {
                            this.setState({
                                dialogForm: { ...this.state.dialogForm, ["city"]: _city }
                            });
                        }

                        // State
                        if (_state !== "" && _state !== state && state !== null && state !== "") {
                            this.setState({
                                hasNotificationStateMsg: true,
                                updatedDataStateInfo: _state,
                                updatingItemState: "state",
                                resetAllFlag: false
                            });
                            this.props.setChildrenOpening(true);
                        } else if (_state !== "" && _state !== state && (state === null || state === "")) {
                            this.setState({
                                dialogForm: { ...this.state.dialogForm, ["state"]: _state }
                            });
                        }
                    });
            }
        } else if ((event.target.name === "phoneNbr" || event.target.name === "faxNbr") && event.target.value !== "") {
            //var region = { dialog.Object }.getValue('FN_Region');
            var oldVal = event.target.value;
            var newVal = "";
            let name = event.target.name;

            //if (region == 'USA') {
            newVal = oldVal.replace(/[^0-9]/gi, '');
            newVal = newVal.substring(0, 10);

            if (newVal.length >= 1 && newVal.length <= 3) {
                newVal = "(" + newVal.substring(0, 3) + ") "
            } else if (newVal.length >= 4 && newVal.length <= 7) {
                newVal = "() " + newVal.substring(0, 3) + "-" + newVal.substring(3, 7);
            } else if (newVal.length >= 8 && newVal.length <= 10) {
                newVal = "(" + newVal.substring(0, 3) + ") " + newVal.substring(3, 6) + "-" + newVal.substring(6, 10);
            }
            //value = newVal;
            //}

            this.setState({
                dialogForm: { ...this.state.dialogForm, [name]: newVal }
            });
        } else {
            if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
                let name = event.target.name;
                let value = event.target.value;
                let table = event.target.getAttribute('data-table');
                let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
                let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
                this.props.checkValidInput(name, value, table, key, -1, flag);
            } else {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "" } });
            }
        }
    }

    handleFormChange = (event) => {
        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                [event.target.name]: event.target.value
            },
            isFormDirty: true,
        });
    }

    render() {
        let hasNotificationStateMsg;
        {
            hasNotificationStateMsg = this.state.hasNotificationStateMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Notification</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideNotificationStateMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>
                                    Suggested state for this zip code is '{this.state.updatedDataStateInfo}'.<br />
                                    Would you like to update the state to {this.state.updatedDataStateInfo}?
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={this.updateDataStateInfo} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideNotificationStateMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let hasNotificationCityMsg;
        {
            hasNotificationCityMsg = this.state.hasNotificationCityMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Notification</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideNotificationCityMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>
                                    Suggested city for this zip code is '{this.state.updatedDataCityInfo}'.<br />
                                    Would you like to update the city to {this.state.updatedDataCityInfo}?
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={this.updateDataCityInfo} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideNotificationCityMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">Can't save data.</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const dialogFrm = this.state.dialogForm ?
            (
                <div className="container-fluid2">
                    <form onSubmit={this.savePhysician} id="dialogForm" className="DialogForm2 physicians_maint_form">
                        <div className="_container autoFilterById">
                            <Input type="input" changed={this.handleFormChange}
                                labelText="Physicians Id" labelWidth={Global.LabelWidth} width="100px" autoFocus
                                field="physiciansIdDsp" value={this.state.dialogForm.physiciansIdDsp} lineStyle="oneLine"
                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["physiciansId"] : ""}
                                inValid={this.state.pAllItemFields["physiciansIdDsp"]}
                            />
                        </div>
                        <div className="row moveFrameToLeft">
                            <div className="col-md-12">
                                <div className="frameInner">
                                    <div className="header">&nbsp;</div>
                                    <div className="emptyLine"></div>
                                    <div style={{ display: "flex" }}>
                                        <Input type="input" changed={this.handleFormChange} labelText="First Name" field="firstName"
                                            value={this.state.dialogForm.firstName} lineStyle="oneLine"
                                            labelWidth={Global.LabelWidth} width="100px"
                                            focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["firstName"] : ""}
                                            inValid={this.state.pAllItemFields["firstName"]} />
                                        <Input type="input" changed={this.handleFormChange} labelText="Middle Name" field="middleName"
                                            value={this.state.dialogForm.middleName} lineStyle="oneLine"
                                            labelWidth={Global.LabelWidth} width="100px" labelStyle="center"
                                            focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["middleName"] : ""}
                                            inValid={this.state.pAllItemFields["middleName"]} />
                                        <Input type="input" changed={this.handleFormChange} labelText="Last Name" field="lastName" lineStyle="oneLine"
                                            value={this.state.dialogForm.lastName} labelWidth={Global.LabelWidth} width="100px" labelStyle="center"
                                            focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["lastName"] : ""}
                                            inValid={this.state.pAllItemFields["lastName"]} />
                                    </div>
                                    <Input type="input" changed={this.handleFormChange} labelText="Title" field="title" lineStyle="oneLine"
                                        value={this.state.dialogForm.title} labelWidth={Global.LabelWidth} width="200px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["title"] : ""}
                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                        inValid={this.state.pAllItemFields["title"]} />
                                    <Input type="input" changed={this.handleFormChange} labelText="Address 1" field="address1" lineStyle="oneLine"
                                        value={this.state.dialogForm.address1} labelWidth={Global.LabelWidth} width="200px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["address1"] : ""}
                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                        inValid={this.state.pAllItemFields["address1"]} />
                                    <Input type="input" changed={this.handleFormChange} labelText="Address 2" field="address2" lineStyle="oneLine"
                                        value={this.state.dialogForm.address2} labelWidth={Global.LabelWidth} width="200px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["address2"] : ""}
                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                        inValid={this.state.pAllItemFields["address2"]} />
                                    <div style={{ display: "flex" }}>
                                        <Input type="input" changed={this.handleFormChange} labelText="City" field="city" lineStyle="oneLine"
                                            value={this.state.dialogForm.city} labelWidth={Global.LabelWidth} width="125px"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["city"] : ""}
                                            focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                            inValid={this.state.pAllItemFields["city"]} />
                                        <Input type="codeSelect" changed={this.handleFormChange} labelText="State" field="state" lineStyle="oneLine"
                                            value={this.state.dialogForm.state ? this.state.dialogForm.state : this.state.dialogForm.State}
                                            dataSource="State_Codes" titleDialog="State" labelStyle="center"
                                            labelWidth="50px" width="40px"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["state"] : ""}
                                            focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                            inValid={this.state.pAllItemFields["state"]} />
                                        <Input type="input" changed={this.handleFormChange} labelText="Zip" field="zip" lineStyle="oneLine"
                                            value={this.state.dialogForm.zip} labelWidth="50px" width="80px" labelStyle="center"
                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["zip"] : ""}
                                            focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                            inValid={this.state.pAllItemFields["zip"]} />
                                    </div>
                                    <Input type="input" changed={this.handleFormChange} labelText="Phone Nbr" field="phoneNbr" lineStyle="oneLine"
                                        value={this.state.dialogForm.phoneNbr} labelWidth={Global.LabelWidth} width="100px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["phoneNbr"] : ""}
                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                        inValid={this.state.pAllItemFields["phoneNbr"]} />
                                    <Input type="input" changed={this.handleFormChange} labelText="Fax Nbr" field="faxNbr" lineStyle="oneLine"
                                        value={this.state.dialogForm.faxNbr} labelWidth={Global.LabelWidth} width="100px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["faxNbr"] : ""}
                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                        inValid={this.state.pAllItemFields["faxNbr"]} />
                                    <Input type="input" changed={this.handleFormChange} labelText="Email" field="email" lineStyle="oneLine"
                                        value={this.state.dialogForm.email} labelWidth={Global.LabelWidth} width="200px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["email"] : ""}
                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                        inValid={this.state.pAllItemFields["email"]} />
                                    <Input type="input" changed={this.handleFormChange} labelText="Specialty" field="specialty" lineStyle="oneLine"
                                        value={this.state.dialogForm.specialty} labelWidth={Global.LabelWidth} width="200px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["specialty"] : ""}
                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                        inValid={this.state.pAllItemFields["specialty"]} />
                                    <Input type="input" changed={this.handleFormChange} labelText="NPI" field="npi" lineStyle="oneLine"
                                        value={this.state.dialogForm.npi} labelWidth={Global.LabelWidth} width="125px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["npi"] : ""}
                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                        inValid={this.state.pAllItemFields["npi"]} />
                                    <Input type="textarea" changed={this.handleFormChange} labelText="Remarks" field="remarks" lineStyle="oneLine"
                                        value={this.state.dialogForm.remarks} labelWidth={Global.LabelWidth} width="500px" height="88px"
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["remarks"] : ""}
                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                        inValid={this.state.pAllItemFields["remarks"]} />
                                    <br /><br /><br /><br />
                                    <Input type="checkbox" lineStyle="oneLine"
                                        labelText="Do Not Contact" field="doNotContact"
                                        value={this.state.isCheckedDoNotContact ? "1" : "0"}
                                        labelWidth={Global.LabelWidth} width="auto"
                                        checked={this.state.isCheckedDoNotContact}
                                        clicked={this.toggleChecked}
                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    />

                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="emptyLine"></div>

                    <Footer closeWindow={this.props.closeWindow}
                        refreshForm={this.resetPhysician} formId="dialogForm"
                        isFormDirty={this.state.isFormDirty}
                        showBtnNew="Physician"
                        showBtnDelete="Physician"
                        className="Footer2"
                        isLookup={true}
                        exitFnc={this.props.closeWindow}
                        focusingField={this.state.focusingField}
                        inquiringMode={this.props.inquiringMode}
                        enabledAlways={true}
                        dataForm={this.state.dialogForm}
                    />
                </div>
            )
            : <div className="col-sm-12 makingLayoutOnFormCls">Physician data loading</div>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>
                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>

                <React.Fragment>
                    {hasNotificationStateMsg}
                </React.Fragment>
                <React.Fragment>
                    {hasNotificationCityMsg}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

PhysicianForm.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
    //PhysiciansId: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
        selectedPhysician: state.patientAbstract.selectedPhysicianOnModal,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        deletedDataSuccess: state.patientAbstract.deletedDataSuccess,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        savePhysician: (dialogForm) => dispatch(actionCreators.savePhysician(dialogForm)),
        addNewItem: (id, flag) => dispatch(actionCreators.selectPhysicianRecord(id, flag)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianForm);