/* eslint-disable */
import React, { Component } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { formatDate } from '@telerik/kendo-intl';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import ConfigData from '../../../store/config.json';
import axios from "axios";
import Draggable from 'react-draggable';

class KendoGridDateCell extends React.Component {
    render() {
        const value = this.props.dataItem[this.props.field];
        return (
            <td>
                {value ? formatDate(new Date(value), "MM/dd/yyyy") : ""}
            </td>
        );
    }
}

class ReportLabels extends Component {

    state = {
        codes: [],
        filter: {
            logic: "",
            filters: []
        },
        filterOperators: {
            'text': [
                { text: 'grid.filterContainsOperator', operator: 'contains' }
            ],
        },
        sort: [],
        total: 0,
        skip: 0,
        take: 50,

        enterKeyPressed: false,
        shiftKeyPressed: false,
        tabKeyPressed: false,
        focusedItem: null,
        filterIndexNo: 0,
    }

    addEventListener = () => {
        var cl = this;
        var currentLookupModal = document.querySelector('#ReportLabelsLookup_' + cl.props.field);
        currentLookupModal.addEventListener('keydown', function (e) {
            if (e.keyCode == 40) {// down arrow
                var filterInputFocusing = e.target.getAttribute("class") === "k-input-inner" ? true : false;

                var trs = currentLookupModal.getElementsByClassName("k-master-row");
                if (filterInputFocusing && trs && trs[0] && trs[0].firstChild) {
                    trs[0].firstChild.setAttribute('tabindex', '0');
                    trs[0].firstChild.focus();

                    setTimeout(function () {
                        trs[0].scrollIntoView();
                        trs[0].scrollTop = 0;
                    }, 100);

                    let currentClasses = trs[0].getAttribute("class");
                    if (currentClasses.indexOf('k-state-selected') == -1)
                        trs[0].setAttribute("class", currentClasses + " k-state-selected");
                }

                var itemInRowFocusing = e.target.getAttribute("class") === "" ? true : false;
                if (itemInRowFocusing && e.target.parentNode.getAttribute("data-grid-row-index") === "0") {
                    let currentClasses = e.target.parentNode.getAttribute("class");
                    if (currentClasses.indexOf('k-state-selected') != -1)
                        e.target.parentNode.setAttribute("class", currentClasses.replace("k-state-selected", ""));
                }
            } else if (e.key === "Tab" || (e.key === "Shift" && e.keyCode == 9) || (e.key === "Tab" && e.shiftKey)) {// shift + tab
                if (e.key === "Tab" && e.shiftKey) {
                    if (e.target.nodeName === "BUTTON") {
                        if (e.target.nextSibling) {
                            setTimeout(function () {
                                currentLookupModal.querySelector('input.k-input-inner:last-child') ? currentLookupModal.querySelector('input.k-input-inner:last-child').focus() : "";
                            }, 0);
                        }
                        return false;
                    } else if (e.target.nodeName === "TH") {//Don't allow press shift+tab on these items
                        e.preventDefault();
                        return false;
                    } else if (e.target.nodeName === "INPUT" && e.target.getAttribute('class') === "k-input-inner") {
                        if (!e.target.parentNode.parentNode.parentNode.previousSibling || e.target.parentNode.parentNode.parentNode.previousSibling === null) {
                            // set focus into Exit button
                            setTimeout(function () {
                                document.getElementById('btnExitLookupReportLabels') ? document.getElementById('btnExitLookupReportLabels').focus() : "";
                            }, 0);
                        }
                        return false;
                    } else if (e.target.nodeName === "INPUT" && e.target.id === "popLabel_searchByFilterInput") {
                        // set focus into Exit button
                        setTimeout(function () {
                            document.getElementById('btnExitLookupReportLabels') ? document.getElementById('btnExitLookupReportLabels').focus() : "";
                        }, 0);
                        return false;
                    }
                } else {
                    if (e.target.nodeName === "BUTTON" && e.target.id == "btnExitLookupReportLabels") {
                        if (!e.target.nextSibling) {
                            setTimeout(function () {
                                currentLookupModal.querySelectorAll('input')[0] ? currentLookupModal.querySelectorAll('input')[0].focus() : "";//popLabel_searchByFilterInput
                            }, 0);
                        }
                    }
                }
            } else if (e.keyCode == 38) {// up arrow
                var pagingLinkFocusing = e.target.getAttribute("class") ? (e.target.getAttribute("class").indexOf("k-link") !== -1 ? true : false) : false;
                let lastItemOnGrid = currentLookupModal.querySelector(".k-grid-table") &&
                    currentLookupModal.querySelector(".k-grid-table").lastChild &&
                    currentLookupModal.querySelector(".k-grid-table").lastChild.childElementCount ? currentLookupModal.querySelector(".k-grid-table").lastChild.childElementCount : 10;
                lastItemOnGrid = lastItemOnGrid - 1;

                var trs = currentLookupModal.getElementsByClassName("k-master-row");
                if (pagingLinkFocusing) {
                    for (var len = 0; len < trs.length; len++) {
                        trs[len] ? trs[len].setAttribute("class", len % 2 == 0 ? "k-master-row" : "k-master-row k-alt") : "";
                    }

                    if (trs && trs[lastItemOnGrid] && trs[lastItemOnGrid].firstChild) {
                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].firstChild.setAttribute('tabindex', '0') : "";
                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].firstChild.focus() : "";

                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].setAttribute("class", "k-master-row k-alt k-state-selected") : "";

                        setTimeout(function () {
                            trs[lastItemOnGrid] ? trs[lastItemOnGrid].scrollIntoView() : "";
                            trs[lastItemOnGrid] ? trs[lastItemOnGrid].scrollTop = 10000 : "";//scroll to bottom of grid
                        }, 100);
                    }
                } else if (e.target.parentNode.getAttribute("data-grid-row-index") === "0") {
                    trs[0] ? trs[0].setAttribute("class", "k-master-row") : "";
                } else {
                    trs[lastItemOnGrid] ? trs[lastItemOnGrid].setAttribute("class", "k-master-row k-alt") : "";
                }

            } else if (e.keyCode == 13 && e.target.getAttribute("class") === "k-input-inner" && e.target.value != "") {//enter
                !cl.enterKeyPressed ? cl.setState({
                    enterKeyPressed: true,
                    focusedItem: e.target,
                    filterIndexNo: e.target.getAttribute("data-index")
                }) : "";
                cl.props.setPageLoaded(false);
                e.preventDefault();
                return false;
            } else if (e.keyCode == 13 && e.target.getAttribute("class") === "k-input-inner" && e.target.value == "") {//enter
                cl.setState({
                    enterKeyPressed: false,
                });
                e.preventDefault();
                return false;
            } else if (e.keyCode == 13 && e.target.id === "popLabel_searchByFilterInput" && e.target.value != "") {//enter
                cl.searchByFunc(e);
                return false;
            } else {
                cl.setState({
                    enterKeyPressed: false,
                });
            }
        });
    }

    init = () => {
        var hospopup = document.getElementById("ReportLabelsLookup_" + this.props.field);
        var cl = this;
        var filterIndexNo = cl.state.filterIndexNo;
        if (hospopup && hospopup.querySelectorAll(".k-input-inner")) {
            var inputs = hospopup.querySelectorAll(".k-input-inner");
            for (var idx = 0; idx < inputs.length; idx++) {
                let input = inputs[idx];
                input.setAttribute("id", "hosp-lookup_filter-k-input-inner-" + idx);
                input.setAttribute("data-index", idx);
                input.addEventListener('focus', function (e) {
                    cl.setState({ focusedItem: input });
                });
            }
        }

        setTimeout(function () {
            if (cl.state.focusedItem && document.getElementById(cl.state.focusedItem.id)) {
                document.getElementById(cl.state.focusedItem.id).focus();
            } else if (filterIndexNo > 0) {
                document.getElementById("hosp-lookup_filter-k-input-inner-" + filterIndexNo) ? document.getElementById("hosp-lookup_filter-k-input-inner-" + filterIndexNo).focus() : "";
            } else {
                hospopup && hospopup.querySelector(".k-input-inner") ? hospopup.querySelector(".k-input-inner").focus() : "";
            }
            return false;
        }, 1000);
    }

    componentDidMount() {
        // must close add new dialog before show it
        this.closeAddNewDialog();

        // Fixing item #PR00111
        this.addEventListener();

        this.props.setPageLoaded(false);

        this.getLabelLookupView();

        // must close add new dialog before show it
        this.closeAddNewDialog();

        this.props.setChildrenOpening(true);
    }

    async getLabelLookupView() {
        this.props.setPageLoaded(true);
        let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetReportLabelsLookupView";

        var params = {
            skip: this.state.skip,
            take: this.state.take,
            accessToken: localStorage.getItem('token')
        };

        var _filter = {};
        if (this.state.filter !== null) {
            _filter = this.state.filter;
            this.state.filter.filters.map((f, i) => {
                //url += "&" + f["field"] + "=" + encodeURIComponent("%" + f["value"] + "%");
                params[f["field"]] = "%" + f["value"] + "%";
            });
        }

        // adding sort options
        var _sorter = [];
        if (this.state.sort !== null && this.state.sort.length > 0) {
            _sorter = this.state.sort;
            this.state.sort.map((s, i) => {
                //url += "&sortby=" + s["field"] + "&orderby=" + s["dir"];
                params["sortby"] = s["field"];
                params["orderby"] = s["dir"];
            });
        }

        let data = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        var newAccessToken = data && data.data ? data.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        var pItems = data && data.data ? data.data.commonLookupList : null;

        this.setState({
            codes: pItems,
            total: data && data.data ? data.data.total : 0,
            filter: _filter,
            sort: _sorter,
            enterKeyPressed: false,
        });

        this.props.setPageLoaded(true);

        this.init();

        return false;
    }

    componentDidUpdate(prevProps) {
        if (!this.props.pageLoaded || this.state.codes == null || (!this.props.pageLoaded && this.state.sort !== null && this.state.sort.length > 0)) {
            this.getLabelLookupView();
        }
    }

    //
    async searchByFilter(_filter='') {
        this.props.setPageLoaded(true);

        let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetReportLabelsLookupView2";
        //url += "&filter=" + encodeURIComponent("%" + _filter + "%");
        var params = {
            skip: this.state.skip,
            take: this.state.take,
            filter: _filter,
            accessToken: localStorage.getItem('token')
        };

        let data = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }*/
                return false;
            });

        var newAccessToken = data && data.data ? data.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        var pItems = data && data.data ? data.data.commonLookupList : null;

        this.setState({
            codes: pItems,
            total: data && data.data ? data.data.total : 0,
            enterKeyPressed: false,
        });

        this.props.setPageLoaded(true);

        this.init();

        return false;
    }

    searchByFunc = (event, flag) => {
        event.preventDefault();

        var _filter = this.state.searchByFilterInput;
        if (flag == "all") {
            _filter = "";
            this.setState({
                searchByFilterInput: _filter
            });
        }
        this.searchByFilter(_filter);

        return false;
    }

    pageChange = (event, isFilter) => {
        //
        if (isFilter === "sorting") {
            let _sorter = event.sort;

            this.setState({
                sort: _sorter,
                enterKeyPressed: false
            });
            this.props.setPageLoaded(false);
            return false;
        }
        //

        let _filter = {
            logic: "",//and
            filters: []
        };
        let enterKeyPressed = this.state.enterKeyPressed;
        if (event && event.filter) {
            _filter = event.filter;

            let allowSearch = event.nativeEvent.target.value != "";

            this.setState({
                filter: _filter,
                enterKeyPressed: allowSearch
            });
        } else {
            // click a page number on paging area
            _filter = event.target.props.filter && !isFilter ? event.target.props.filter : {
                logic: "",//and
                filters: []
            };

            let allowSearch = event.nativeEvent.target.value != "";

            this.setState({
                skip: event.page ? event.page.skip : this.state.skip,
                take: event.page ? event.page.take : this.state.take,
                filter: _filter,
                enterKeyPressed: allowSearch
            });
            enterKeyPressed = allowSearch;
        }
        this.props.setPageLoaded(!enterKeyPressed);
    }

    addNewItem = (event) => {
        event.preventDefault();
        this.props.addNewItem("");
        this.props.setChildrenOpening(false);
        return false;
    }

    closeAddNewDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.props.closeAddNewDialog();
        this.props.setChildrenOpening(false);
        return false;
    }

    selectCode = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.props.setCode(event.dataItem.reportLabel, event.dataItem.reportLabel);//ReportLabelId

        this.setState({ isDialogVisible: true });
        return false;
    }

    // To get more reference at https://www.telerik.com/kendo-react-ui/components/grid/accessibility/
    handleNavigationAction = (event) => {
        const { focusElement } = event;
        const data = this.state.codes;

        const rowId = focusElement.parentElement.getAttribute("data-id");

        if (rowId) {
            const newData = data.map((item) => ({
                ...item,
                selected: String(item.reportLabelId) === rowId,
            }));
            this.setState({ codes: newData });
        }
    };

    rowRender = (trElement, rowProps) => {
        const trProps = {
            ...trElement.props,
            ["data-id"]: rowProps.dataItem.reportLabelId,
        };
        return React.cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    };

    handleKeyDownAction = (props) => {
        var event = props.nativeEvent;
        if (event.key == "Enter") {
            event.target.click();
        }
    }

    closeCode = (e) => {
        try {
            e ? e.preventDefault() : "";
        } catch (e) { }
        this.props.closeCode();
        return false;
    }

    resetFilterInputFnc = (e) => {
        try {
            e ? e.preventDefault() : "";
        } catch (e) { }

        var _value = e.target.value;

        this.setState({
            searchByFilterInput: _value,
        });

        return false;
    }

    render() {
        let loading = (
            this.props.isPatientSaving ? <div className="loader"></div> : ""
        );

        return (
            <div>
                {loading}
                <div className="CRStar_Window">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableLargestWindowCls" id={"ReportLabelsLookup_" + this.props.field}>
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">{this.props.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.closeCode(e) }}></span>
                                    </a>
                                </div>
                            </div>

                            <div className="_row">
                                <div className="col-sm-11" style={{ display: "flex", padding: "10px" }}>
                                    <div>
                                        <label>Search:</label>
                                    </div>
                                    <div style={{ width: "30%" }}>
                                        <input id="popLabel_searchByFilterInput" className="" name="popLabel_searchByFilterInput"
                                            value={this.state.searchByFilterInput}
                                            onChange={this.resetFilterInputFnc} autoFocus
                                            style={{ width: "90%", margin: "0px 12px" }}
                                        />
                                    </div>
                                    <div style={{ paddingRight: "8px" }}>
                                        <button onClick={(e) => { this.searchByFunc(e) }} id="btnSearchByFilter">Search</button>
                                    </div>
                                    <div>
                                        <button onClick={(e) => { this.searchByFunc(e, 'all') }} id="btnSearchAll">All</button>
                                    </div>
                                </div>
                            </div>

                            <Grid
                                style={{ height: '500px' }}
                                data={this.state.codes != undefined && this.state.codes.length !== 0 ? this.state.codes : null}
                                filter={this.state.filter}
                                filterable
                                filterOperators={this.state.filterOperators}
                                onFilterChange={e => { this.pageChange(e, true) }}
                                onRowClick={this.selectCode}
                                pageable
                                onPageChange={e => { this.pageChange(e, false) }}
                                scrollable="scrollable"
                                sortable
                                sort={this.state.sort}
                                onSortChange={e => { this.pageChange(e, "sorting") }}
                                skip={this.state.skip}
                                take={this.state.take}
                                total={this.state.total}
                                selectedField="selected"
                                rowRender={this.rowRender}
                                navigatable={true}
                                onNavigationAction={this.handleNavigationAction}
                                onKeyDown={this.handleKeyDownAction}
                            >
                                <GridColumn field="reportLabel" filter="text" title="Report Label" filterable={false} />
                                <Column field="requestDate" filter="text" title="Request Date" filterable={false} width="105px" cell={KendoGridDateCell} format="{MM/dd/yyyy}" />
                                <GridColumn field="requestedBy" filter="text" title="Requested By" filterable={false} />
                            </Grid>
                            <div style={{ marginLeft: "-1px", marginRight: "-1px", marginBottom: "-1px", padding: "10px", textAlign: "center", backgroundColor: "#c5ddfe" }}>
                                <button id="btnExitLookupReportLabels" onClick={this.props.closeCode}>Cancel</button>
                            </div>
                        </div>
                    </Draggable>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAddNewDialogVisible: state.patientAbstract.isAddNewDialogVisible,
        //
        clientgroup: state.auth.clientgroup,
        pageLoaded: state.patientAbstract.pageLoaded,
        childrenOpening: state.patientAbstract.childrenOpening,
        inquiringMode: state.auth.inquiringMode,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        closeAddNewDialog: () => dispatch(actionCreators.closeAddNewDialog()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportLabels);