/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Input from "../../../UI/Input";
import "./GlobalDir.css";
import axios from "../../../../axios-instance";
import Footer from "../../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
import * as Global from "../../../../store/Global";
import { ColorPicker } from "@progress/kendo-react-inputs";
import Draggable from "react-draggable";
import { Label } from "reactstrap";

class GlobalDirForm extends Component {

    state = {
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,

        isCheckedRequired: false,
        isCheckedReportable: false,
        isCheckedRapidAbst: false,

        colorText: null,
        colorBackground: null,

        pAllItemFields: null,
        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        showMessageError: false,
        hasError: false,
        savedForm: false,
        fieldInFocus: "itemDesc",
        focusingField: null,
        isSaving: false,
        monitoring: null,
        isShowConfirmDeleteMsg: false,

        globalDirMvMaxlengthInput: null,
        initialGlobalDirMv: null,

        globalDirMvItems: null,
        globalDirMvItemFields: null,
        globalDirMvLoading: false,
        selectedGlobalDirMvRowIndex: 0,

        colorPalette: {
            palette: [
                "#000000",
                "#FFFFFF",
                "#808080",
                "#C0C0C0",
                "#800000",
                "#FF0000",
                "#800080",
                "#FF00FF",
                "#008000",
                "#00FF00",
                "#808000",
                "#FFFF00",
                "#000080",
                "#0000FF",
                "#008080",
                "#00FFFF",
            ],
            columns: 2,
            tileSize: 30,
            colorRGBa: [
                "rgba(0, 0, 0, 1)",
                "rgba(255, 255, 255, 1)",
                "rgba(128, 128, 128, 1)",
                "rgba(192, 192, 192, 1)",
                "rgba(128, 0, 0, 1)",
                "rgba(255, 0, 0, 1)",
                "rgba(128, 0, 128, 1)",
                "rgba(255, 0, 255, 1)",
                "rgba(0, 128, 0, 1)",
                "rgba(0, 255, 0, 1)",
                "rgba(128, 128, 0, 1)",
                "rgba(255, 255, 0, 1)",
                "rgba(0, 0, 128, 1)",
                "rgba(0, 0, 255, 1)",
                "rgba(0, 128, 128, 1)",
                "rgba(0, 255, 255, 1)",
            ]
        },
    }

    setSelectionGlobalDirMvRow = (event, index) => {
        this.setState({ selectedGlobalDirMvRowIndex: index });
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });

        var itemNbr = this.state.dialogForm.itemNbr;
        if (event.target.name === "itemDesc") {
            if (event.target.value === "" && itemNbr === "") {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, ["itemNbr"]: "IN_VALID" },
                    fieldInFocus: "itemNbr"
                });
            }
        }

        return false;
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showMessageError: false, hasError: false });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector(".Footer2>#btnSubmitForm_Fake") ? document.querySelector(".Footer2>#btnSubmitForm_Fake").focus() : "";
        }, 0);

        return false;
    }

    componentDidMount() {
        if (!this.state.dialogForm && this.props.selectedGlobalDir) {
            this.setFormData();
        }

        var childrenOpening = this.props.childrenOpening;
        this.props.setChildrenOpening(childrenOpening);
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.dialogForm && this.props.selectedGlobalDir)
            || (prevProps.selectedGlobalDir && this.props.selectedGlobalDir && prevProps.selectedGlobalDir.itemNbr !== this.props.selectedGlobalDir.itemNbr)
            || (this.props.selectedGlobalDir && this.props.selectedGlobalDir.itemNbr !== null && this.props.selectedGlobalDir.itemNbr !== "" && this.props.selectedGlobalDir.itemNbr !== this.state.dialogForm.itemNbr && (this.state.dialogForm.itemNbr === null || this.state.dialogForm.itemNbr === ""))
            || (this.props.selectedGlobalDir && (this.props.selectedGlobalDir.itemNbr === null || this.props.selectedGlobalDir.itemNbr === "") && this.props.selectedGlobalDir.itemNbr !== this.state.dialogForm.itemNbr && this.state.dialogForm.itemNbr !== null && this.state.dialogForm.itemNbr !== "")
            || (!prevProps.selectedGlobalDir && this.props.selectedGlobalDir && this.props.selectedGlobalDir.itemNbr !== null && this.props.selectedGlobalDir.itemNbr !== "" && this.props.selectedGlobalDir.itemNbr !== this.state.dialogForm.itemNbr)
        ) {
            this.setFormData();
        }

        // GlobalDirMvList
        if ((this.props.selectedGlobalDir && !this.state.globalDirMvItems)) {
            //axios.get(this.props.clientgroup + "/GetGlobalDirMvList?itemNbr=" + this.props.selectedGlobalDir.itemNbr)
            axios.post(this.props.clientgroup + "/GetGlobalDirMvList", {itemNbr: this.props.selectedGlobalDir.itemNbr, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    let newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ globalDirMvItems: res.data.globalDirMvList, initialGlobalDirMv: res.data.globalDirMvList, globalDirMvLoading: true });
                        this.setState({ dialogForm: { ...this.state.dialogForm, globalDirMv: res.data.globalDirMvList } });

                        // Global Dir Mv List
                        if (!this.state.globalDirMvItemFields && res.data.globalDirMvList) {
                            let globalDirMvArr = [];
                            res.data.globalDirMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                globalDirMvArr.push(fields);
                            });

                            this.setState({ globalDirMvItemFields: globalDirMvArr });

                            // get maxlength for Global Dir Mv grid
                            //axios.get(this.props.clientgroup + "/GetTableStructure?table=Global_Dir_Mv").then(res => {
                            axios.post(this.props.clientgroup + "/GetTableStructure", { table: "Global_Dir_Mv", accessToken: localStorage.getItem('token') })
                            .then(res => {
                                //update new accessToken again
                                let newAccessToken = res && res.data ? res.data.accessToken : null;
                                if (newAccessToken) {
                                    localStorage.setItem('token', newAccessToken);
                                }

                                if (res.data !== null && res.data.result !== "") {
                                    let maxLengthValue = res.data.result;

                                    let maxLengthItem = globalDirMvArr && globalDirMvArr[0] ? globalDirMvArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                    this.setState({
                                        globalDirMvMaxlengthInput: { ...maxLengthItem }
                                    });
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                                let btnLogout = document.getElementById("logoutButtonArea");
                                if (btnLogout) {
                                    btnLogout.querySelector("a").click();
                                }
    
                                return false;
                            });

                        };
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;
                });
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        if (this.state.tableStructureList === null && !this.state.loadedTableStructure) {
            this.setState({ loadedTableStructure: true });
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        if (this.state.savedForm && !this.props.isDialogLoading && ((prevProps.isFormDirty != this.props.isFormDirty || this.props.error) || (prevProps.error != this.props.error || !this.state.isFormDirty))) {
            if (this.props.isFormDirty) {
                if (!this.state.hasError && this.state.hasError != this.props.error) {
                    this.setState({ showMessageError: true, hasError: true, savedForm: false, isFormDirty: true });
                    this.props.setChildrenOpening(true);
                }
            } else {
                if (this.state.isFormDirty && this.state.isFormDirty != this.props.isFormDirty) {
                    this.setState({ isFormDirty: this.props.isFormDirty, savedForm: false, hasError: false });
                }
            }
        }

        if (this.state.isFormDirty && (this.state.isSaving || this.props.deletedDataSuccess)
            && (prevProps.isFormDirty !== this.props.isFormDirty || this.state.isFormDirty !== this.props.isFormDirty)) {
            this.setState({
                isFormDirty: this.props.isFormDirty,
                isSaving: false,//this.props.isFormDirty,
            });
        }
    }

    async getTableStructure() {
        //table is real name in database
        //let url = this.props.clientgroup + "/GetTableStructure?table=Global_Dir&table2=Global_Dir_Help";

        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "Global_Dir", table2: "Global_Dir_Help", accessToken: localStorage.getItem('token') };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;

            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    setFormData = () => {
        let dataForm = this.props.selectedGlobalDir;

        Object.keys(dataForm).map((t) => {
            let dataVal = dataForm[t];
            dataForm[t] = dataVal;
        });

        // let colorTextHx = null;
        let colorTextHx = "#000000";
        if (this.props.selectedGlobalDir["color"] !== null && this.props.selectedGlobalDir["color"] !== "") {
            colorTextHx = this.props.selectedGlobalDir["color"];
        };
        let colorBgHx = "#FFFFFF";
        if (this.props.selectedGlobalDir["bgColor"] !== null && this.props.selectedGlobalDir["bgColor"] !== "") {
            colorBgHx = this.props.selectedGlobalDir["bgColor"];
        }
        let checkedRequired = false;
        if (this.props.selectedGlobalDir["required"] == "1") {
            checkedRequired = true;
        };
        let checkedReportable = false;
        if (this.props.selectedGlobalDir["reportable"] == "1") {
            checkedReportable = true;
        };
        let checkedRapidAbst = false;
        if (this.props.selectedGlobalDir["rapidAbst"] == "1") {
            checkedRapidAbst = true;
        };

        this.setState({
            initialDialogForm: { ...dataForm },
            dialogForm: { ...dataForm },
            //isFormDirty: false,
            reload: false,
            hasError: this.props.error,
            savedForm: false,
            hasError: false,
            isCheckedRequired: checkedRequired,
            isCheckedReportable: checkedReportable,
            isCheckedRapidAbst: checkedRapidAbst,
            colorText: colorTextHx,
            colorBackground: colorBgHx,
        });

        if (!this.state.pAllItemFields && this.props.selectedGlobalDir) {
            let fields = {};
            Object.keys(this.props.selectedGlobalDir).map((t) => {
                fields[t] = "";
            });

            this.setState({ pAllItemFields: fields, pageMaxlengthInput: fields });
        };

        //
        setTimeout(function () {
            document.getElementById("itemNbr") ? document.getElementById("itemNbr").focus() : "";
        }, 500);
    }

    toggleChecked = (event) => {

        if (event.target.name === "required") {
            const checkedRequired = this.state.isCheckedRequired;
            this.setState({isCheckedRequired: !checkedRequired})
        };
        if (event.target.name === "reportable") {
            const checkedReportable = this.state.isCheckedReportable;
            this.setState({isCheckedReportable: !checkedReportable})
        };
        if (event.target.name === "rapidAbst") {
            const checkedRapidAbst = this.state.isCheckedRapidAbst;
            this.setState({isCheckedRapidAbst: !checkedRapidAbst})
        };
        this.setState({isFormDirty: true});
    }

    changeTextColor = (event) => {
        let index = this.state.colorPalette.colorRGBa.indexOf(event.value)
        let colorHex = this.state.colorPalette.palette[index]
        this.setState({
            colorText: colorHex,
            isFormDirty: true
        });
    }

    changeBgColor = (event) => {
        let index = this.state.colorPalette.colorRGBa.indexOf(event.value)
        let colorHex = this.state.colorPalette.palette[index]
        this.setState({
            colorBackground: colorHex,
            isFormDirty: true
        });
    }

    // rgbaToHex = (value) => {
    //     let rgba = value.replace("rgba(","").replace(")","").split(",");
    //     let rChan = (rgba[0] * 1), gChan = (rgba[1] * 1), bChan = (rgba[2] * 1), alpha = Math.round(rgba[3] * 255);

    //     return hexValue = 
    //         "#" + 
    //         ("0" + rChan.toString(16)).slice(1) +
    //         ("0" + gChan.toString(16)).slice(1) +
    //         ("0" + bChan.toString(16)).slice(1) +
    //         ("0" + alpha.toString(16)).slice(1);
    // }

    handleGlobalDirMvLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, index);

        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let isvalid = true;

            if (!isvalid) {
                this.setState(prevState => {
                    const newItems = [...prevState.globalDirMvItemFields];
                    newItems[index][name] = "IN_VALID";
                    return { globalDirMvItemFields: newItems };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.globalDirMvItemFields];
                    newItems[index][name] = "";
                    return { globalDirMvItemFields: newItems };
                });
            }

        } else {
            this.setState(prevState => {
                const newItems = [...prevState.globalDirMvItemFields];
                newItems[index][name] = "";
                return { globalDirMvItemFields: newItems };
            });
        }
    }

    handleGlobalDirMvGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.globalDirMvItems];
            newItems[index][name] = value;
            return { globalDirMvItems: newItems, isFormDirty: true };
        });
    }

    addNewGlobalDirMv = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.globalDirMvItems];
            const firstItem = newItems[0];

            const newObj = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "itemNbr") {
                    newObj[t] = 0;
                } else {
                    newObj[t] = "";
                }
            });
            newItems.push(newObj);

            // 
            const newFields = [...this.state.globalDirMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "itemNbr") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { globalDirMvItems: newItems, globalDirMvItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }

    deleteSelectedGlobalDirMv = (event) => {
        event.preventDefault();

        let selectedRow = this.state.selectedGlobalDirMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.globalDirMvItems.length) {
            selectedRow = this.state.globalDirMvItems.length - 1;
        }
        if (this.state.globalDirMvItems[selectedRow] && this.state.globalDirMvItems[selectedRow]["id"] !== undefined && this.state.globalDirMvItems[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "globalDirMvItems" });
        } else {
            this.deleteGlobalDirMvGrid();
        }
        return false;
    }


    deleteGlobalDirMvGrid = () => {
        let selectedRow = this.state.selectedGlobalDirMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.globalDirMvItems.length) {
            selectedRow = this.state.globalDirMvItems.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.globalDirMvItems];
            if (newItems.length === 1) {
                const _newItems = [...this.state.globalDirMvItems];
                const firstItems = _newItems[0];

                const newObj = {};
                Object.keys(firstItems).map((t, k) => {
                    if (t === "itemNbr") {
                        //newObj[t] = 0;
                    } else {
                        newObj[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObj);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.globalDirMvItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.globalDirMvItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { globalDirMvItems: newItems, globalDirMvItemFields: newFields, selectedGlobalDirMvRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }

    saveGlobalDir = (event) => {
        event.preventDefault();

        const checkedRequired = this.state.isCheckedRequired;
        const checkedReportable = this.state.isCheckedReportable;
        const checkedRapidAbst = this.state.isCheckedRapidAbst;
        let globalDirMvList = this.state.globalDirMvItems;
        let dialogFormData = this.state.dialogForm;

        dialogFormData["required"] = checkedRequired ? "1" : "0";
        dialogFormData["reportable"] = checkedReportable ? "1" : "0";
        dialogFormData["rapidAbst"] = checkedRapidAbst ? "1" : "0";

        dialogFormData["color"] = this.state.colorText;
        dialogFormData["bgColor"] = this.state.colorBackground;

        dialogFormData.globalDirMv = globalDirMvList;

        this.props.saveGlobalDir(dialogFormData);

        this.setState({ isSaving: true, savedForm: true, isFormDirty: false });
        return false;
    }

    hideConfirmDeleteMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isShowConfirmDeleteMsg: false });

        if (event.target.value === "yes" && this.state.monitoring) {
            switch (this.state.monitoring) {
                case "globalDirMvItems":
                    this.deleteGlobalDirMvGrid();
                    break;
                default:
                    break;
            }
        }
        return false;
    }

    resetGlobalDir = () => {
        this.setState({
            dialogForm: { ...this.state.initialDialogForm },
            isFormDirty: false,
            reload: false
        });
    }

    handleOnBlur = (event) => {
        // load if whether this value is existing
        if (event.target.name === "itemNbr") {
            if (event.target.value === "") {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "IN_VALID" } });
            } else {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "" } });
            }

            if (this.state.dialogForm && this.state.dialogForm.itemNbr) {
                this.props.addNewItem(event.target.value, this.props.isNotExist ? true : false);
            }

            return false;
        } else {
            if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
                let name = event.target.name;
                let value = event.target.value;
                let table = event.target.getAttribute('data-table');
                let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
                let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
                this.props.checkValidInput(name, value, table, key, -1, flag);
            } else {
                this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "" } });

                let name = event.target.name;
                let value = event.target.value;
                if ((name === "phoneNbr") && value !== "") {
                    //var region = { dialog.Object }.getValue('FN_Region');
                    var oldVal = value;
                    var newVal = "";

                    //if (region == 'USA') {
                    newVal = oldVal.replace(/[^0-9]/gi, '');
                    newVal = newVal.substring(0, 10);

                    if (newVal.length >= 1 && newVal.length <= 3) {
                        newVal = "(" + newVal.substring(0, 3) + ") "
                    } else if (newVal.length >= 4 && newVal.length <= 7) {
                        newVal = "() " + newVal.substring(0, 3) + "-" + newVal.substring(3, 7);
                    } else if (newVal.length >= 8 && newVal.length <= 10) {
                        newVal = "(" + newVal.substring(0, 3) + ") " + newVal.substring(3, 6) + "-" + newVal.substring(6, 10);
                    }
                    //value = newVal;
                    //}

                    this.setState({
                        dialogForm: { ...this.state.dialogForm, [name]: newVal }
                    });
                } else {
                    this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [name]: "" } });
                }
            }
        }
    }

    handleFormChange = (event) => {
        this.props.resetDeletedDataSuccess(false);
        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                [event.target.name]: event.target.value
            },
            isFormDirty: true,
            isSaving: false,
        });
        //console.log("DFCheck: " + this.state.dialogForm)
    }

    render() {
        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">Can't save data.</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let confirmDeleteMsg;
        {
            confirmDeleteMsg = this.state.isShowConfirmDeleteMsg ?
                /*
                <Dialog title="Delete Row"
                    onClose={this.hideConfirmDeleteMsg}
                    width={300} height={"auto"}
                    className={classes.title}>
                */
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Delete Row</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>OK to delete row?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                        {/*</Dialog>*/}
                    </Draggable>
                </div>
                : null
        }

        const dialogFrm = this.state.dialogForm ?
            (
                <div className="container-fluid2" id="globalDir">
                    <form onSubmit={this.saveGlobalDir} id="dialogForm" className="DialogForm2 globaldir_maint_form">
                        <div className="_container autoFilterById">
                            <Input type="input" changed={this.handleFormChange}
                                labelText="Item Nbr" labelWidth={Global.LabelWidth} width="50px" //autoFocus
                                field="itemNbr" value={this.state.dialogForm.itemNbr} lineStyle="oneLine"
                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["itemNbr"] : ""}
                                inValid={this.state.pAllItemFields["itemNbr"]} disabled="true"
                            />
                        </div>
                        <div id="inputAreaCls">
                            <div className="frameInner">
                                <div className="header">&nbsp;</div>
                                <div className="emptyLine"></div>
                                <div className="globalDirFormR1">
                                    <div style={{ display: "flex" }}>
                                        <div className="" style={{ width: "400px", height: "60px" }}>
                                            <Input type="input" changed={this.handleFormChange}
                                                labelText="Item Desc" field="itemDesc" autoFocus
                                                value={this.state.dialogForm.itemDesc} lineStyle="oneLine"
                                                labelWidth={Global.LabelWidth} width="280px"
                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["itemDesc"] : ""}
                                                inValid={this.state.pAllItemFields["itemDesc"]} />

                                            <Input type="input" changed={this.handleFormChange}
                                                labelText="Column Name" field="columnName"
                                                value={this.state.dialogForm.columnName} lineStyle="oneLine"
                                                labelWidth={Global.LabelWidth} width="280px"
                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["columnName"] : ""}
                                                inValid={this.state.pAllItemFields["columnName"]} />
                                        </div>
                                        <div className="" style={{ width: "400px", height: "60px" }}>
                                            <div style={{ display: "flex" }}>
                                                <Input type="checkbox" lineStyle="oneLine"
                                                    labelText="Rapid Abst" field="rapidAbst"
                                                    labelWidth={Global.LabelWidth} labelStyle="lblOnCheckbox" width="auto"
                                                    value={this.state.isCheckedRapidAbst ? "1" : "0"}
                                                    focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    checked={this.state.isCheckedRapidAbst}
                                                    clicked={this.toggleChecked}/>
                                                <div className="" style={{ paddingLeft: "25px" }}>
                                                    <Input type="input" changed={this.handleFormChange}
                                                        labelText="State Corrections" field="stateCorrections" lineStyle="oneLine"
                                                        value={this.state.dialogForm.stateCorrections} labelWidth={Global.LabelWidth} width="150px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["stateCorrections"] : ""}
                                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                        inValid={this.state.pAllItemFields["stateCorrections"]} />
                                                    <Input type="input" changed={this.handleFormChange}
                                                        labelText="State Field Nbr" field="stateFieldNbr" lineStyle="oneLine"
                                                        value={this.state.dialogForm.stateFieldNbr} labelWidth={Global.LabelWidth} width="150px"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["stateFieldNbr"] : ""}
                                                        focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                        inValid={this.state.pAllItemFields["stateFieldNbr"]} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="globalDirFormR2">
                                    <div style={{ display: "flex" }}>
                                        <div className="" style={{ width: "275px", height: "375px" }}>
                                            <Input type="input" changed={this.handleFormChange} keyId="gdFileName"
                                                labelText="Table name" field="fileName" lineStyle="oneLine"
                                                value={this.state.dialogForm.fileName} labelWidth={Global.LabelWidth} width="150px"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["fileName"] : ""}
                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                inValid={this.state.pAllItemFields["fileName"]} />

                                            <Input type="input" changed={this.handleFormChange}
                                                labelText="Code File" field="codeFile" lineStyle="oneLine"
                                                value={this.state.dialogForm.codeFile} labelWidth={Global.LabelWidth} width="150px"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["codeFile"] : ""}
                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                inValid={this.state.pAllItemFields["codeFile"]} />

                                            <Input type="input" changed={this.handleFormChange}
                                                labelText="Data Type" field="dataType" lineStyle="oneLine"
                                                value={this.state.dialogForm.dataType} labelWidth={Global.LabelWidth} width="150px"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["dataType"] : ""}
                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                inValid={this.state.pAllItemFields["dataType"]} />

                                            <Input type="input" changed={this.handleFormChange}
                                                labelText="Format Data" field="formatData" lineStyle="oneLine"
                                                value={this.state.dialogForm.formatData} labelWidth={Global.LabelWidth} width="150px"
                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["formatData"] : ""}
                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                inValid={this.state.pAllItemFields["formatData"]} />

                                            <fieldset style={{ height: "175px", width: "250px" }}>
                                                <legend>Label Colors</legend>
                                                <div style={{ display: "flex" }}>
                                                    <p className="pickerLbl" style={{ width: "100px", paddingLeft: "0px", marginLeft: "0px" }}>Text</p>
                                                    <ColorPicker view="palette" //defaultValue={"rgba(0, 0, 0, 1)"}
                                                        paletteSettings={this.state.colorPalette} id="color"
                                                        value={this.state.colorText}
                                                        onChange={this.changeTextColor}
                                                    />
                                                </div>
                                                <div className="emptyLine"></div>
                                                <div style={{ display: "flex" }}>
                                                    <p className="pickerLbl" style={{ width: "100px", paddingLeft: "0px", marginLeft: "0px" }}>Background</p>
                                                    <ColorPicker view="palette" //defaultValue={"rgba(255, 255, 255, 1)"}
                                                        paletteSettings={this.state.colorPalette} id="bgColor"
                                                        value={this.state.colorBackground}
                                                        onChange={this.changeBgColor}
                                                    />
                                                </div>
                                                <Label name="sampleText"
                                                    style={{ color: this.state.colorText, backgroundColor: this.state.colorBackground }}
                                                >Sample Text</Label>
                                            </fieldset>
                                            <div className="emptyLine"></div>
                                            <div style={{ display: "flex" }}>
                                                <div>
                                                <Input type="checkbox" lineStyle="oneLine"
                                                    labelText="Required" field="required"
                                                    labelWidth={Global.LabelWidth} labelStyle="lblOnCheckbox" width="auto"
                                                    value={this.state.isCheckedRequired ? "1" : "0"}
                                                    focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    checked={this.state.isCheckedRequired}
                                                    clicked={this.toggleChecked}
                                                />
                                                <Input type="checkbox" lineStyle="oneLine"
                                                    labelText="Reportable" field="reportable"
                                                    labelWidth={Global.LabelWidth} labelStyle="lblOnCheckbox" width="auto"
                                                    value={this.state.isCheckedReportable ? "1" : "0"}
                                                    focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                    checked={this.state.isCheckedReportable}
                                                    clicked={this.toggleChecked}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="" style={{ width: "500px", height: "365px" }}>
                                            <div className="emptyLine"></div>
                                            <fieldset>
                                                <legend>Codes & Descriptions</legend>
                                                <div className="_codeDescCol1">
                                                    <div className="row">
                                                        {/* <div className="col-sm-12 globalDirMvListArea"> */}
                                                        <div className="globalDirMvListArea">
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: "50px" }}>
                                                                            <Input type="gridHeader" labelWidth="auto"
                                                                                labelText="Code" field="Code"
                                                                            /></th>
                                                                        <th style={{ width: "400px" }}>
                                                                            <Input type="gridHeader" labelWidth="auto"
                                                                                labelText="Code Desc" field="CodeDesc"
                                                                            /></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                    <tr>
                                                                        <th colSpan="2">&nbsp;</th>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div className="innerBody">
                                                                <table>
                                                                    <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                        <tr>
                                                                            <th style={{ width: "50px" }}></th>
                                                                            <th style={{ width: "400px" }}></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.globalDirMvItemFields
                                                                            && this.state.globalDirMvItems !== null && this.state.globalDirMvItems.length > 0
                                                                            ? this.state.globalDirMvItems.map((gdmv, index) => (
                                                                                <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionGlobalDirMvRow(e, index) }} className={this.state.selectedGlobalDirMvRowIndex === index ? "selectedRowCls" : ""}>
                                                                                    <td>
                                                                                        <Input type="input" focused={this.handleOnFocus}
                                                                                            changed={(e) => { this.handleGlobalDirMvGridChange(e, index) }}
                                                                                            labelWidth="0px" lineStyle="oneLine"
                                                                                            labelText="" field="code" keyId={"code" + index}
                                                                                            value={gdmv.code} width="100%"
                                                                                            maxLength={this.state.globalDirMvMaxlengthInput ? this.state.globalDirMvMaxlengthInput["code"] : ""}
                                                                                            inValid={this.state.globalDirMvItemFields[index]["code"]}
                                                                                            lostfocused={(e) => { this.handleGlobalDirMvLostfocus(e, index) }}
                                                                                            rowIndex={index}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="input" focused={this.handleOnFocus}
                                                                                            changed={(e) => { this.handleGlobalDirMvGridChange(e, index) }}
                                                                                            labelWidth="0px" lineStyle="oneLine"
                                                                                            labelText="" field="codeDesc" keyId={"codeDesc" + index}
                                                                                            value={gdmv.codeDesc} width="100%"
                                                                                            maxLength={this.state.globalDirMvMaxlengthInput ? this.state.globalDirMvMaxlengthInput["codeDesc"] : ""}
                                                                                            inValid={this.state.globalDirMvItemFields[index]["codeDesc"]}
                                                                                            lostfocused={(e) => { this.handleGlobalDirMvLostfocus(e, index) }}
                                                                                            rowIndex={index}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            )) : <tr><td colSpan="2">No records available</td></tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="contactBtnArea right">
                                                                <button onClick={this.addNewGlobalDirMv}>Add</button>
                                                                <button onClick={this.deleteSelectedGlobalDirMv}>Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="helpTxtCl1" style={{ width: "400px", height: "440px" }}>
                                <div className="header">Help Text:</div>
                                <Input type="textarea" changed={this.handleFormChange}
                                    labelText="" field="helptext" lineStyle="oneLine"
                                    value={this.state.dialogForm.helptext}
                                    labelWidth="0px" width="350px" height="440px"
                                    //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["helptext"] : ""}
                                    focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                    inValid={this.state.pAllItemFields["helptext"]} 
                                />
                            </div>
                        </div>
                    </form>

                    <div className="emptyLine"></div>

                    <Footer closeWindow={this.props.closeWindow}
                        refreshForm={this.resetGlobalDir} formId="dialogForm"
                        isFormDirty={this.state.isFormDirty}
                        // showBtnNew="GlobalDir"
                        // showBtnDelete="GlobalDir"
                        className="Footer2"
                        isLookup={true}
                        exitFnc={this.props.closeWindow}
                        focusingField={this.state.focusingField}
                        inquiringMode={this.props.inquiringMode}
                        enabledAlways={true}
                    />
                </div>
            )
            : <div className="col-sm-12 makingLayoutOnFormCls">Global Dir data loading</div>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>
                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>
                <React.Fragment>
                    {confirmDeleteMsg}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

GlobalDirForm.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        selectedGlobalDir: state.patientAbstract.selectedGlobalDirOnModal,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        deletedDataSuccess: state.patientAbstract.deletedDataSuccess,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveGlobalDir: (dialogForm) => dispatch(actionCreators.saveGlobalDir(dialogForm)),
        addNewItem: (id, flag) => dispatch(actionCreators.selectGlobalDirRecord(id, flag)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        resetDeletedDataSuccess: (value) => dispatch(actionCreators.resetDeletedDataSuccess(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalDirForm);