/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
//import { filterBy, orderBy } from "@progress/kendo-data-query";
import { actionCreators } from "../../store/PatientAbstract";
import ConfigData from "../../store/config.json";
import * as dialogTypes from "../AbstractManagement/maintDialogs";
import * as actions from '../../store/actions/index';
import axios from "axios";
import "./SystemAdmin.css";

class UserSecurityMaintLookup extends Component {
    state = {
        users: null,
        userList: null,
        isLoading: false,
        filter: {
            logic: "",
            filters: []
        },
        filterOperators: {
            'text': [
                { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
                //{ text: 'grid.filterEndsWithOperator', operator: 'endswith' },
                //{ text: 'grid.filterContainsOperator', operator: 'contains' }
            ],
        },
        sort: [],
        total: 0,
        skip: 0,
        take: 10,
        isDialogVisible: false,
        isChildDialogVisible: false,
        errorMsg: '',

        enterKeyPressed: false,
        shiftKeyPressed: false,
        tabKeyPressed: false,
        userClientGroup: null
    }
    resetPageNumber = false;

    addEventListener = () => {
        var usml = this;
        var currentLookupModal = document.querySelector('#userSecurityMaintLookupGridView');
        currentLookupModal.addEventListener('keydown', function (e) {
            if (usml.props.childrenOpening) {
                //e.preventDefault();
                return false;
            }

            if (e.keyCode == 40) {// down arrow
                let filterInputFocusing = e.target.getAttribute("class") === "k-textbox" ? true : false;

                var trs = currentLookupModal.getElementsByClassName("k-master-row");
                if (filterInputFocusing) {
                    for (var len = 0; len < trs.length; len++) {
                        trs[len] ? trs[len].setAttribute("class", len % 2 == 0 ? "k-master-row" : "k-master-row k-alt") : "";
                    }

                    if (trs && trs[0] && trs[0].firstChild) {
                        trs[0] ? trs[0].firstChild.setAttribute('tabindex', '0') : "";
                        trs[0] ? trs[0].firstChild.focus() : "";

                        trs[0] ? trs[0].setAttribute("class", "k-master-row k-state-selected") : "";

                        setTimeout(function () {
                            trs[0] ? trs[0].scrollIntoView() : "";
                            trs[0] ? trs[0].scrollTop = 0 : "";
                        }, 100);
                    }
                } else if (e.target.parentNode.getAttribute("class") !== "k-filter-row") {
                    trs[0] ? trs[0].setAttribute("class", "k-master-row") : "";
                } else if (e.target.parentNode.getAttribute("class") === "k-filter-row") {
                    trs[0] ? trs[0].setAttribute("class", "k-master-row k-state-selected") : "";
                }
            } else if (e.keyCode == 38) {//up arrow
                var pagingLinkFocusing = e.target.getAttribute("class") ? (e.target.getAttribute("class").indexOf("k-link") !== -1 ? true : false) : false;

                //const lastItemOnGrid = 9;
                let lastItemOnGrid = currentLookupModal.querySelector(".k-grid-table") &&
                    currentLookupModal.querySelector(".k-grid-table").lastChild &&
                    currentLookupModal.querySelector(".k-grid-table").lastChild.childElementCount ? currentLookupModal.querySelector(".k-grid-table").lastChild.childElementCount : 10;
                lastItemOnGrid = lastItemOnGrid - 1;

                var trs = currentLookupModal.getElementsByClassName("k-master-row");
                if (pagingLinkFocusing) {
                    for (var len = 0; len < trs.length; len++) {
                        trs[len] ? trs[len].setAttribute("class", len % 2 == 0 ? "k-master-row" : "k-master-row k-alt") : "";
                    }

                    if (trs && trs[lastItemOnGrid] && trs[lastItemOnGrid].firstChild) {
                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].firstChild.setAttribute('tabindex', '0') : "";
                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].firstChild.focus() : "";

                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].setAttribute("class", "k-master-row k-alt k-state-selected") : "";

                        setTimeout(function () {
                            trs[lastItemOnGrid] ? trs[lastItemOnGrid].scrollIntoView() : "";
                            trs[lastItemOnGrid] ? trs[lastItemOnGrid].scrollTop = 10000 : "";//scroll to bottom of grid
                        }, 100);
                    }
                } else if (e.target.parentNode.getAttribute("data-grid-row-index") === "0") {
                    trs[0] ? trs[0].setAttribute("class", "k-master-row") : "";
                } else {
                    trs[lastItemOnGrid] ? trs[lastItemOnGrid].setAttribute("class", "k-master-row k-alt") : "";
                }
            } else if (e.keyCode == 13) {//enter
                !usml.enterKeyPressed ? usml.setState({ enterKeyPressed: true }) : "";
                usml.props.setPageLoaded(false);
            } /*else if (e.keyCode == 9) {//tab
                usml.setState({ tabKeyPressed: false });
            } else if (e.keyCode == 16) {//shift
                usml.setState({ shiftKeyPressed: false });
            }*/ else {
                usml.setState({ enterKeyPressed: false });
            }
        });
    }

    //
    init = () => {
        document.getElementsByClassName("k-textbox")[0] ? document.getElementsByClassName("k-textbox")[0].focus() : "";
        setTimeout(function () {
            document.getElementById("usml_cls_slt_btn") ? document.getElementById("usml_cls_slt_btn").click() : null;
            document.getElementsByClassName("k-textbox")[0] ? document.getElementsByClassName("k-textbox")[0].focus() : "";
        }, 1000);
    }

    componentDidMount = () => {
        this.addEventListener();

        if (this.state.userClientGroup == null && this.props.userId) {
            this.getUserClientGroup();
        }

        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);

        this.init();

        // Add all tabs in maintDialogs.js
        dialogTypes.sysAdminTabsDisplay.map((dialog, index) => {
            if (dialog.name) {
                this.props.addTabItems(dialog.name, dialog.id);
                this.props.setEnabledStatus(dialog.name);
            } else {
                this.props.addTabItems(dialog);
                this.props.setEnabledStatus(dialog);
            }
        });

        this.setState({ isLoading: true });

        this.props.setInitialPageVisible(true);
    }

    async getUserClientGroup() {
        let url = ConfigData.CRStarAPI_URL + "ers/GetUserClientGroup";
        let params = { email: this.props.userId, accessToken: localStorage.getItem('token'), clientAlias: this.props.clientgroup };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        this.setState({
            userClientGroup: res && res.data ? res.data.result : "ers"
        });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }
    }

    async getUserLogins(cligrp) {
        this.props.setPageLoaded(true);

        const enterKeyPressed = this.resetPageNumber;
        var skipNo = enterKeyPressed ? 0 : this.state.skip;
        var takeNo = enterKeyPressed ? 10 : this.state.take;

        let url = ConfigData.CRStarAPI_URL + "ers/GetUserLookupView";

        //
        var access_token = localStorage.getItem('token');
        var params = {
            cliGrp: cligrp,
            skip: skipNo,
            take: takeNo,
            accessToken: access_token,
            clientAlias: this.props.clientgroup
        };

        var _filter = {};
        if (this.state.filter !== null) {
            _filter = this.state.filter;
            this.state.filter.filters.map((f, i) => {
                params[f["field"]] = ("%" + f["value"] + "%");
            });
        }

        // adding sort options
        var _sorter = [];
        if (this.state.sort !== null && this.state.sort.length > 0) {
            _sorter = this.state.sort;
            this.state.sort.map((s, i) => {
                params["sortby"] = s["field"];
                params["orderby"] = s["dir"];
            });
        }
        var _this = this;
        let res = await axios
            .post(url, params)
            .then(function (response) {
                //update new accessToken again
                var newAccessToken = response && response.data ? response.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);

                let btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        console.clear();
        console.log(res && res.data ? res.data.userList : []);
        var userList = res && res.data ? res.data.userList : [];
        var total = res && res.data ? res.data.total : 0;

        _this.setState({
            users: userList,
            userList: userList,
            total: total,
            filter: _filter,
            sort: _sorter,
            enterKeyPressed: false,
            skip: skipNo,
            take: takeNo
        });
        _this.forceUpdate();

        _this.props.setPageLoaded(true);

        setTimeout(function () {
            _this.props.showOrHideLoading(false);
            return false;
        }, 1500);

        return false;
    }

    componentDidUpdate(prevProps) {
        if (this.state.userClientGroup == null && this.props.userId) {
            this.getUserClientGroup();
        }

        if (this.state.userClientGroup && !this.props.pageLoaded) {
            this.getUserLogins(this.state.userClientGroup);
        }

        if (this.state.userList && this.state.users && this.state.userList.length != this.state.users.length) {
            this.setState({ users: this.state.userList });
        }
    }

    setChildDialog = (childDialog) => {
        this.props.setChildDialog(childDialog);
        this.props.setChildDialogVisible(true);
    }

    selectUser = event => {
        this.props.selectedUserOnGrid(event.dataItem);
        this.props.selectUserInfoRecord(event.dataItem.email);
        this.setChildDialog(dialogTypes.USER_MAINTENANCE);
    }

    addNewUser = event => {
        this.props.selectUserInfoRecord("", this.props.clientgroup);
        this.setChildDialog(dialogTypes.USER_MAINTENANCE);
    }

    pageChange = (event, isFilter) => {
        //
        if (isFilter === "sorting") {
            let _sorter = event.sort;

            this.setState({
                sort: _sorter,
                enterKeyPressed: false
            });
            this.props.setPageLoaded(false);
            this.resetPageNumber = false;
            return false;
        }
        //

        let _filter = {
            logic: "",
            filters: []
        };
        let enterKeyPressed = this.state.enterKeyPressed;
        if (event && event.filter) {
            _filter = event.filter;

            let allowSearch = event.nativeEvent.target.value != "";

            this.setState({
                filter: _filter,
                enterKeyPressed: allowSearch
            });
            this.resetPageNumber = true;
        } else {
            // click a page number on paging area
            _filter = event.target.props.filter && !isFilter ? event.target.props.filter : {
                logic: "",
                filters: []
            };

            let allowSearch = event.nativeEvent.target.value != "";

            this.setState({
                skip: event.page ? event.page.skip : this.state.skip,
                take: event.page ? event.page.take : this.state.take,
                filter: _filter,
                enterKeyPressed: allowSearch
            });
            enterKeyPressed = allowSearch;

            if (event.page) {
                this.resetPageNumber = false;
            } else {
                this.resetPageNumber = true;
            }
        }
        this.props.setPageLoaded(!enterKeyPressed);
    }

    searchByFilter = () => {
        this.resetPageNumber = true;
        this.setState({ enterKeyPressed: true });
        this.props.setPageLoaded(false);
    }

    resetFilter = () => {
        let _filter = {
            logic: "",//and
            filters: []
        };
        this.setState({
            skip: 0,
            take: 10,
            filter: _filter,
            enterKeyPressed: true,
            sort: [] // Also clear all sorter
        });
        this.resetPageNumber = true;
        this.props.setPageLoaded(false);
    }

    //------------------------------------------------------------------------
    //https://www.telerik.com/kendo-react-ui/components/grid/accessibility/
    handleRowClick = (props) => {
        const data = this.state.users;
        const newData = data.map((item) => ({
            ...item,
            selected: item.userId === props.dataItem.userId,
        }));
        this.setState({ users: newData });
    };

    handleNavigationAction = (event) => {
        const { focusElement } = event;
        const data = this.state.users;

        const rowId = focusElement.parentElement.getAttribute("data-id");

        if (rowId) {
            const newData = data.map((item) => ({
                ...item,
                selected: String(item.userId) === rowId,
            }));
            this.setState({ users: newData });
        }
    };

    rowRender = (trElement, rowProps) => {
        const trProps = {
            ...trElement.props,
            ["data-id"]: rowProps.dataItem.userId,
        };
        return React.cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    };

    handleKeyDownAction = (props) => {
        var event = props.nativeEvent;
        if (event.key == "Enter") {
            event.target.click();
        }
    }
    //------------------------------------------------------------------------

    render() {
        return (
            <div className="mainPage">
                <div className="parentWindow">
                    <div style={{ display: "none" }}>User Lookup</div>
                    <div className="patientAbstractGridView" id="userSecurityMaintLookupGridView">
                        <Grid
                            style={{ height: '600px', width: '1600px' }}
                            data={this.state.users}
                            filter={this.state.filter}
                            filterable
                            filterOperators={this.state.filterOperators}
                            onFilterChange={e => { this.pageChange(e, true) }}
                            onRowClick={this.selectUser}
                            pageable
                            onPageChange={e => { this.pageChange(e, false) }}
                            scrollable="scrollable"
                            sortable
                            sort={this.state.sort}
                            onSortChange={e => { this.pageChange(e, "sorting") }}
                            skip={this.state.skip}
                            take={this.state.take}
                            total={this.state.total}
                            selectedField="selected"
                            rowRender={this.rowRender}
                            navigatable={true}
                            onNavigationAction={this.handleNavigationAction}
                            onKeyDown={this.handleKeyDownAction}
                        >
                            <GridColumn field="userName" filter="text" title="User Name" />
                            <GridColumn field="email" filter="text" title="User ID" />
                            <GridColumn field="securityGroup" filter="text" title="Security Group" />
                            <GridColumn field="clientGroup" filter="text" title="Client Group" />
                        </Grid>

                        <div className="addNewUser">
                            <button onClick={this.addNewUser}>New User</button>
                            <button onClick={this.resetFilter} id="usml_cls_slt_btn">Clear Results</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentDialog: state.patientAbstract.currentDialog,
        childDialog: state.patientAbstract.childDialog,
        isChildDialogVisible: state.patientAbstract.isChildDialogVisible,
        isParentWindowVisible: state.patientAbstract.isParentWindowVisible,
        isPatientSelected: state.patientAbstract.selectedPatient !== null,
        isAuthenticated: state.auth.token !== null,
        pageLoaded: state.patientAbstract.pageLoaded,
        //
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        childrenOpening: state.patientAbstract.childrenOpening,
        isPatientSaving: state.patientAbstract.isPatientSaving,
        initialPageVisible: state.patientAbstract.initialPageVisible,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //selectUser: (uId) => dispatch(actionCreators.selectUser(uId)),
        selectUserInfoRecord: (email, cligrp) => dispatch(actionCreators.selectUserInfoRecord(email, cligrp)),
        selectedUserOnGrid: (userInfo) => dispatch(actionCreators.selectedUserOnGrid(userInfo)),
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),

        // add new User
        //addNewUser: (uId) => dispatch(actionCreators.selectUser(uId)),

        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),

        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),

        //
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSecurityMaintLookup);
