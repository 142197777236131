/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import CommonLookup from "./CommonLookup";
import CommonLookup2 from "./CommonLookup2";//is multi-selection popup
import CommonLookup3 from "./CommonLookup3";//is TNM_HTML popup
import Physician from "./Lookup/Physician";
import Hospital from "./Lookup/Hospital";
import LabelPopup from "./Lookup/LabelPopup";
import ReportLabels from "./Lookup/ReportLabels";
import Patient from "./Lookup/Patient";
import { connect } from "react-redux";
import Draggable from 'react-draggable';
import { actionCreators } from "../../store/PatientAbstract";
import ConfigData from '../../store/config.json';
import axios from "axios";
import moment from "moment";

class Input extends Component {

    state = {
        initialSetVal: false,
        isDialogVisible: false,
        invalidField: null,
        isShowValidationError: false,
        lblColor: '',
        bgColor: '',
        formatData: null,
        setColor: false,
        failedData: false,
        // Text Editor popup
        textEditorSetup: false,
        showTextEditorPopup: false,
        textVal: this.props.value,
        currentLength: 0,
        maxLength: this.props.maxlen,
        warning: false,
        isShowConfirmationMsg: false,

        isRapidAbstract: false,//#PR00212
        setRapidMode: false,
        labelText: this.props.labelText,
        setItemDesc: false,
        addEventListener: false,

        iLeftPos: null,
        iTopPos: null,
        iWidthLen: null,
        reDrawWithDynamicFields: false,
        research: true,
        futureDateVal: null,
        primaries: null,
    }

    async changedFnc(event, fnc) {
        try {
            let drawVal = event.target.value;

            // Adding format data for field
            var _value = "";
            if (this.state.formatData && this.state.formatData != "") {
                let fmt = this.state.formatData;
                try {
                    _value = eval(fmt.replaceAll("dataEntered", "\"" + drawVal + "\""));
                } catch (exceptionJs) {
                    alert("[Global_Dir::format_data] " + fmt + ".\n[Javascript Error] " + exceptionJs);
                }
            } else {
                _value = drawVal;
            }

            event.target.value = _value;
            var _focusingField = event.target.name;

            await fnc(event);//this.props.changed(event);

            var focusedField = this.props.focusingField ? this.props.focusingField.name : this.props.field;

            // hide lookup modal when lostfocus on the input field            
            if (this.props.shownType == "dropdown" && focusedField == _focusingField) {
                //this.setState({ isDialogVisible: true, research: true });
                event.target.nextSibling.click();
            } else {
                this.setState({ isDialogVisible: false, research: false, futureDateVal: null });
            }
        } catch (ex) {
        }

        return fnc;
    }

    async changedCodeSelectFnc(event, fnc) {
        var _currentField = event.target;
        if (!this.props.formattedValueFnc && this.props.shownType != "dropdown") {
            let drawVal = event.target.value;

            // Adding format data for field
            var _value = "";
            if (this.state.formatData && this.state.formatData != "") {
                let fmt = this.state.formatData;
                try {
                    _value = eval(fmt.replaceAll("dataEntered", "\"" + drawVal + "\""));
                } catch (exceptionJs) {
                    alert("[Global_Dir::format_data] " + fmt + ".\n[Javascript Error] " + exceptionJs);
                }
            } else {
                _value = drawVal;
            }

            event.target.value = _value;
            return fnc(event);
        }

        try {
            let drawVal = event.target.value;

            // Adding format data for field
            var _value = "";
            if (this.state.formatData && this.state.formatData != "") {
                let fmt = this.state.formatData;
                try {
                    _value = eval(fmt.replaceAll("dataEntered", "\"" + drawVal + "\""));
                } catch (exceptionJs) {
                    alert("[Global_Dir::format_data] " + fmt + ".\n[Javascript Error] " + exceptionJs);
                }
            } else {
                _value = drawVal;
            }

            event.target.value = _value;
            await fnc(event);

            //
            var focusedField = this.props.focusingField ? this.props.focusingField.name : this.props.field;

            // hide lookup modal when lostfocus on the input field
            var _focusingField = _currentField.name;//event.target.name;
            if (this.props.shownType == "dropdown" && focusedField == _focusingField) {
                await this.setState({ calledFrom: "input" });
                _currentField.nextSibling.setAttribute("value", "input");

                axios.post(ConfigData.CRStarAPI_URL + this.props.clientgroup + "/PreSetState", { accessToken: localStorage.getItem('token') })
                    .then(res => {
                        _currentField.nextSibling.click();
                    })
                    .catch(function (error) {
                        console.log(error);
                        /*
                        //redirect to Login page by clicking the Logout button
                        var btnLogout = document.getElementById("logoutButtonArea");
                        if (btnLogout) {
                            btnLogout.querySelector("a").click();
                        }
                        */
                        //@TODO:...

                        return false;
                    });
            } else {
                this.setState({ isDialogVisible: false, research: false, futureDateVal: null });
            }

        } catch (ex) {
        }

        return fnc;
    }

    stopResearchFnc = () => {
        this.setState({ research: false });
    }

    lostfocused = (event) => {
        // Ignore the lostfocus event with dropdown fields
        if (this.props.shownType == "dropdown") {
            var currentFieldName = event.target.name;
            var isShowingDropdown = document.getElementById("commonLookup_" + currentFieldName);
            if (isShowingDropdown) {
                return true;
            }
        }

        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.props.storeToGlobal(null);//clear stored everything

        let fvalue = event.target.value;
        let iml = event.target.getAttribute("data-maxlength") !== null ? event.target.getAttribute("data-maxlength") : "";

        if (fvalue !== null && iml !== "" && fvalue.length > iml) {
            this.setState({ failedData: true });
        } else {
            this.setState({ failedData: false });
        }

        try {
            this.props.lostfocused(event);

            // Fixing #PR00446
            var isDateField = false;
            var _nField = event.target.name;
            var _indexNoField = event.target.getAttribute("data-index");
            if (event.target.getAttribute("placeholder") && event.target.getAttribute("placeholder") === "mm/dd/yyyy") {
                isDateField = true;
                var obj = this;
                setTimeout(function () {
                    var fVal = document.getElementById(_nField) ? document.getElementById(_nField).value : null;
                    if (!fVal) {
                        fVal = document.getElementById(_nField + _indexNoField) ? document.getElementById(_nField + _indexNoField).value : null;
                    }

                    if (fVal && fVal != "99/99/9999") {
                        var dArr = fVal.split("/");
                        if (dArr.length == 3) {
                            if (dArr[0] == 99 && dArr[1] == 99 && dArr[2] >= 1800 && dArr[2] <= new Date().getFullYear()) {
                                obj.setState({
                                    failedData: false,
                                    futureDateVal: null,
                                });
                            } else if (dArr[0] != 99 && (dArr[0] < 1 || dArr[0] > 12)) {
                                obj.setState({
                                    failedData: true,
                                    futureDateVal: fVal,
                                });
                            } else if (dArr[1] != 99 && (dArr[1] < 1 || dArr[1] > 31)) {
                                obj.setState({
                                    failedData: true,
                                    futureDateVal: fVal,
                                });
                            } else if (dArr[2] != 9999 && (dArr[2] < 1800 || dArr[2] > new Date().getFullYear())) {
                                //Fixing #PR00464 - Date fields on the User_Defined screen should allow dates in the future (#41-50)
                                if (dArr[2] > new Date().getFullYear() && obj.props.acceptedFutureDate) {
                                    obj.setState({
                                        failedData: false,
                                        futureDateVal: null,
                                    });
                                } else {
                                    obj.setState({
                                        failedData: true,
                                        futureDateVal: fVal,
                                    });
                                }
                            } else {
                                const currentMDY = new Date();
                                const mdyInput = new Date(fVal);
                                if (currentMDY < mdyInput || mdyInput == "Invalid Date") {
                                    //Fixing #PR00464 - Date fields on the User_Defined screen should allow dates in the future (#41-50)
                                    if (currentMDY < mdyInput && obj.props.acceptedFutureDate) {
                                        obj.setState({
                                            failedData: false,
                                            futureDateVal: null,
                                        });
                                    } else {
                                        obj.setState({
                                            failedData: true,
                                            futureDateVal: fVal,
                                        });
                                    }
                                } else {
                                    obj.setState({ failedData: false, futureDateVal: null });
                                }
                            }
                        } else {
                            const currentMDY = new Date();
                            const mdyInput = new Date(fVal);
                            if (currentMDY < mdyInput || mdyInput == "Invalid Date") {
                                obj.setState({
                                    failedData: true,
                                    futureDateVal: fVal,//mdyInput == "Invalid Date" ? fVal : mdyInput
                                });
                            } else {
                                obj.setState({ failedData: false, futureDateVal: null });
                            }
                        }
                    }
                }, 100);

            } else if (event.target.getAttribute("data-field") && event.target.getAttribute("data-field") === "mm/yyyy") {
                isDateField = true;
                var obj = this;
                var currentDateInputField = event.target;
                var name = currentDateInputField.name;
                var field = currentDateInputField.getAttribute("data-field");
                var index = currentDateInputField.getAttribute("data-index");

                setTimeout(function () {
                    if (name.indexOf("Month") !== -1) {
                        field = name.replace("Month" + index, "");
                    } else if (name.indexOf("Year") !== -1) {
                        field = name.replace("Year" + index, "");
                    }

                    var monthVal = document.getElementById(field + "Month" + index) ? document.getElementById(field + "Month" + index).value : null;
                    var yearVal = document.getElementById(field + "Year" + index) ? document.getElementById(field + "Year" + index).value : null;
                    const dayVal = new Date().getDay();

                    if (monthVal && yearVal && yearVal != "9999") {
                        if (monthVal == "99" && yearVal && yearVal > new Date().getFullYear()) {
                            obj.setState({
                                failedData: true,
                                futureDateVal: yearVal
                            });
                            return false;
                        } else if (monthVal && monthVal != "99" && (monthVal < 1 || monthVal > 12)) {
                            obj.setState({
                                failedData: true,
                                futureDateVal: monthVal
                            });
                            return false;
                        } else {
                            const currentMDY = new Date();
                            const mdyInput = new Date(yearVal, monthVal - 1, dayVal);
                            if (monthVal && monthVal != "99" && currentMDY < mdyInput) {
                                obj.setState({
                                    failedData: true,
                                    futureDateVal: yearVal
                                });
                                return false;
                            } else {
                                obj.setState({ failedData: false, futureDateVal: null });
                            }
                        }
                    }
                }, 1000);

            } else if (event.target.getAttribute("placeholder") && event.target.getAttribute("placeholder") === "yyyy") {
                isDateField = true;
                var obj = this;
                var currentDateInputField = event.target;
                var name = currentDateInputField.name;

                setTimeout(function () {
                    var yearVal = document.getElementById(name) ? document.getElementById(name).value : null;
                    if (yearVal && yearVal != "9999") {
                        const currentYear = new Date().getFullYear();
                        if (currentYear < yearVal || yearVal < 1800) {
                            obj.setState({
                                failedData: true,
                                futureDateVal: yearVal
                            });
                        } else {
                            obj.setState({ failedData: false, futureDateVal: null });
                        }
                    }
                }, 100);
            }

        } catch (ex) { }

        return false;
    }

    offset(el) {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        return {
            top: rect.top + scrollTop,
            left: rect.left + scrollLeft,
            width: rect.width,
            height: rect.height
        }
    }

    focusedTextField = (event) => {
        try {
            event.target.select();
        } catch (ex) { }

        try {
            //#prj_abstract_validation_fixes
            this.props.latestFocusedField(event.target.getAttribute("id"));

            this.props.focused(event);

            this.props.storeToGlobal(null);//clear stored everything

            var divOffset = this.offset(event.target);

            let iWidth = parseInt(event.target.style.width) + 20;//
            let iHeight = parseInt(event.target.style.height);

            this.setState({ iLeftPos: divOffset.left, iTopPos: divOffset.top + iHeight, iWidthLen: iWidth });

        } catch (ex) { }

        return false;
    }

    focusedTextAreaField = (event) => {
        try {
            //#prj_abstract_validation_fixes
            this.props.latestFocusedField(event.target.getAttribute("id"));

            var bkVal = event.target.value;
            event.target.value = "";
            event.target.focus();
            event.target.value = bkVal;

            // clear expander class for other fields
            var textPadForm = document.getElementById("diagnosisTextForm");
            if (textPadForm && textPadForm.querySelectorAll("textarea")) {
                textPadForm.querySelectorAll("textarea").forEach((ta, index) => {
                    ta.getAttribute("class").indexOf("expanderCls") !== -1 ? ta.setAttribute("class", "") : "";
                });
            }

            var currentField = event.target.name;
            var minimizeHeight = 50;
            var textFields = [
                'dxComments', 'primSiteText', 'histologyText', 'placeDxText', 'phyExamText',
                'xrayScanText', 'scopesText', 'labsText', 'opText', 'pathText', 'surgeryText',
                'beamRadText', 'othRadText', 'chemoText', 'hormText', 'immunoText', 'hemaText',
                'stageText', 'ajccStagingText', 'folComments', 'transferText', 'importedText'
            ];
            for (var i = 0; i < textFields.length; i++) {
                var textField = textFields[i];
                if (textField != currentField) {
                    var minHeight = minimizeHeight;
                    if (textField == 'primSiteText' || textField == 'histologyText') { minHeight = 40 };
                    if (textField == 'placeDxText') { minHeight = 30 };

                    var textPadForm = document.getElementById("diagnosisTextForm");
                    if (textPadForm) {
                        textPadForm.querySelector("#" + textField + "_TextPadLbl").style.transition = 'height 0.3s';
                        textPadForm.querySelector("#" + textField + "_TextPadLbl").style.height = minHeight + 'px';
                    } else {
                        document.getElementById(textField + "_TextPadLbl").style.transition = 'height 0.3s';
                        document.getElementById(textField + "_TextPadLbl").style.height = minHeight + 'px';
                    }
                }
            }

            var focusingField = event.target;

            var currHeight = focusingField.style.height.replace(/[^0-9]/gi, '');
            var expandHeight = 320;
            var minimizeHeight = 320;

            if (currHeight >= expandHeight) {
                focusingField.style.height = minimizeHeight + 'px';
            } else {
                focusingField.style.height = expandHeight + 'px';
            }
            focusingField.setAttribute("class", "expanderCls");

        } catch (ex) { }

        try {
            this.props.focused(event);
        } catch (ex) { }

        return false;
    }

    hideConfirmationMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        var ip = this.props.field;
        setTimeout(function () {
            document.getElementById("textEditorPopup_" + ip) ? document.getElementById("textEditorPopup_" + ip).focus() : "";
            return false;
        }, 0);

        this.setState({ isShowConfirmationMsg: false });
        return false;
    }

    handleOnFocus = (event) => {
        event.preventDefault();

        const currentValue = event.target.value;
        const currentLength = currentValue.length;
        let maxLength = this.props.maxlen ? this.props.maxlen : "0";

        let isOverLength = false;
        if (currentLength > maxLength) {
            isOverLength = true;
        }

        this.setState({
            currentLength: currentLength,
            maxLength: maxLength,
            warning: isOverLength,
        });
        return false;
    }

    handleFormChange = (event) => {
        event.preventDefault();

        const currentValue = event.target.value;
        const currentLength = currentValue.length;
        let maxLength = this.props.maxlen ? this.props.maxlen : "0";

        let isOverLength = false;
        if (currentLength > maxLength) {
            isOverLength = true;
        }

        this.setState({
            textVal: currentValue,
            currentLength: currentLength,
            maxLength: maxLength,
            warning: isOverLength,
        });
        return false;
    }

    hideTextEditorPopup = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        const paVal = this.props.value;
        const textVal = this.state.textVal;
        if (this.props.hasDoubleClickEvent === "warning" && paVal !== textVal) {
            this.props.setChildrenOpening(true);//@#!
            this.setState({ isShowConfirmationMsg: true });
        } else {
            this.setState({ showTextEditorPopup: false, textEditorSetup: false });

            this.props.setChildrenOpening(false);//@#!

            var ip = this.props.field;
            setTimeout(function () {
                document.getElementById(ip) ? document.getElementById(ip).focus() : "";
                return false;
            }, 0);
        }

        return false;
    }

    exitTextEditor = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showTextEditorPopup: false, textEditorSetup: false, isShowConfirmationMsg: false });
        this.props.setChildrenOpening(false);

        var ip = this.props.field;
        setTimeout(function () {
            document.getElementById(ip) ? document.getElementById(ip).focus() : "";
            return false;
        }, 0);
        return false;
    }

    saveTextEditor = (event) => {
        event.preventDefault();

        var textVal = this.state.textVal;
        this.props.changed({ target: { name: this.props.field, value: textVal } });
        this.setState({ showTextEditorPopup: false, textEditorSetup: false, isShowConfirmationMsg: false });
        this.props.setChildrenOpening(false);

        var ip = this.props.field;
        setTimeout(function () {
            document.getElementById(ip) ? document.getElementById(ip).focus() : "";
            return false;
        }, 0);

        return false;
    }

    hideValidationError = (event, obj) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            isShowValidationError: true, futureDateVal: null, failedData: false
        });

        // @TODO: comment out this line if "Validation Error" can't moving
        let childrenOpening = this.props.childrenOpening;
        console.log("childrenOpening=" + childrenOpening);
        this.props.setChildrenOpening(false);

        //
        if (this.props.replacementVal) {
            const code = this.props.replacementVal === "EMPTY" ? "" : this.props.replacementVal;
            this.props.changed({ target: { name: this.props.field, value: code } });

            this.setState({ isShowValidationError: false });

            // set focus to selected field
            var aliasField = this.props.keyId ? this.props.keyId : this.props.field;
            const input = document.getElementById(aliasField);

            setTimeout(function () {
                input.value = "";
                input.focus();
                input.value = code;
            }, 100);
        } else {
            // set focus to selected field
            var aliasField = this.props.keyId ? this.props.keyId : this.props.field;
            var index = this.props.rowIndex ? this.props.rowIndex : "0";

            var input;
            if (this.props.type === "date") {
                var isValidInput = true;

                var dField = "Month";//check on first MONTH item
                if (document.getElementById(aliasField + dField + index).value != '99'
                    && !(1 <= document.getElementById(aliasField + dField + index).value && document.getElementById(aliasField + dField + index).value <= 12)) {
                    //dField = "Month";
                    isValidInput = false;
                } else {
                    dField = "Day";//check on second DAY item
                }

                if (isValidInput) {
                    if (document.getElementById(aliasField + dField + index).value != '99'
                        && !(1 <= document.getElementById(aliasField + dField + index).value && document.getElementById(aliasField + dField + index).value <= 31)) {
                        //dField = "Day";
                        isValidInput = false;
                    } else {
                        dField = "Year";
                    }
                }

                input = document.getElementById(aliasField + dField + index);
            } else {
                input = document.getElementById(aliasField);
            }

            var _this = this;
            setTimeout(function () {
                input ? input.focus() : "";

                if (input && input.value == "Invalid date") {
                    input.value = "";
                    _this.props.changed({ target: { name: _this.props.field, value: "" } });
                }
            }, 100);
        }

        return false;
    }

    openCodeDialog = (event) => {
        event.preventDefault();
        this.setState({ isDialogVisible: true, research: true });
        this.props.setChildrenOpening(true);

        if (this.state.calledFrom == "input") {
            event.target.setAttribute("value", "input");
            this.setState({ searchByKey: "-1" });
        } else {
            event.target.setAttribute("value", "button");
            this.setState({ calledFrom: "", searchByKey: "" });
        }

        //--
        var _focusingField = event.target.previousSibling.name;
        var _index = event.target.previousSibling && event.target.previousSibling.getAttribute("data-index") ? event.target.previousSibling.getAttribute("data-index") : "0";
        if (_index != "0") {
            //_focusingField += _index;
        }

        var keyCode = event.keyCode ? event.keyCode : -1;
        var _this = this;
        if (_this.props.shownType == "dropdown" && (keyCode == -1 || keyCode == 40)) {//Arrow Down
            _this.props.changed;

            //first set focusing field into global store...
            _this.props.storeToGlobal(_focusingField + _index);

            // 
            var divOffset = this.offset(event.target.previousSibling);
            const iHeight = 26;
            const iWidth = parseInt(event.target.previousSibling.style.width) + 20;
            this.setState({ iLeftPos: divOffset.left, iTopPos: divOffset.top + iHeight, iWidthLen: iWidth });
            return false;
        }
        //--
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            isDialogVisible: false,
            searchByKey: "",
            //research: false
        });
        this.props.setChildrenOpening(false);

        // set focus to selected field
        var aliasField = this.props.keyId ? this.props.keyId : this.props.field;
        const input = document.getElementById(aliasField);

        setTimeout(function () {
            input ? input.focus() : "";
        }, 100);

        return false;
    }

    // #PR00518 ->START
    openPrmSumDialog = (event) => {
        event.preventDefault();
        this.setState({ isPrmSumDialogVisible: true, research: true });
        this.props.setChildrenOpening(true);

        //--
        var _focusingField = event.target.previousSibling.name;
        var _index = event.target.previousSibling && event.target.previousSibling.getAttribute("data-index") ? event.target.previousSibling.getAttribute("data-index") : "0";
        if (_index != "0") {
            //_focusingField += _index;
        }

        //
        var _params = { patientId: this.props.patientId, exactly: 1, nlp: 1, accessToken: localStorage.getItem('token') };
        axios.post(ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetPrimarySummaryList", _params)
            .then(res => {
                //update new accessToken again
                var newAccessToken = res.data && res.data.accessToken ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    this.setState({
                        primaries: res.data.primarySummaryList,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        var keyCode = event.keyCode ? event.keyCode : -1;
        var _this = this;
        if (_this.props.shownType == "dropdown" && (keyCode == -1 || keyCode == 40)) {//Arrow Down
            _this.props.changed;

            //first set focusing field into global store...
            _this.props.storeToGlobal(_focusingField + _index);

            // 
            var divOffset = this.offset(event.target.previousSibling);
            const iHeight = 26;
            const iWidth = parseInt(event.target.previousSibling.style.width) + 20;
            this.setState({ iLeftPos: divOffset.left, iTopPos: divOffset.top + iHeight, iWidthLen: iWidth });

            return false;
        }
        //--

        return false;
    }
    closePrmSumDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            isPrmSumDialogVisible: false,
        });
        this.props.setChildrenOpening(false);

        // set focus to selected field
        var aliasField = this.props.keyId ? this.props.keyId : this.props.field;
        const input = document.getElementById(aliasField);

        setTimeout(function () {
            input ? input.focus() : "";
        }, 100);

        return false;
    }

    setPrmSumData = (event, idx) => {
        var ps = this.state.primaries[idx];

        this.props.changed({
            target2: {
                ["cid"]: ps["cid"],//!Important
                ["siteCode"]: ps["siteCode"],
                ["siteDesc"]: ps["siteDesc"],//
                ["seqPrim"]: ps["seqPrim"],
                ["hosp"]: ps["hosp"],
                ["hospName"]: ps["hospName"],//
                ["hospState"]: ps["hospState"],//
                ["medRecNbr"]: ps["medRecNbr"],
                //["stateRptFlag"]: ps["stateRptFlag"]
            },
        });

        this.closePrmSumDialog();
        return false;
    }
    //<-END

    IsNumeric(val) {
        return !isNaN(parseFloat(val)) || !isNaN(parseFloat(val.replace(/[^\d\.]*/g, '')));
    }

    //
    setAllRecord = (item) => {
        this.props.changed({ target: item, target2: item });
        this.closeCodeDialog();

        this.setState({ isShowValidationError: true, failedData: false });

        // set focus to selected field
        var aliasField = this.props.keyId ? this.props.keyId : this.props.field;
        const input = document.getElementById(aliasField);
        const name = item.name;
        setTimeout(function () {
            input.value = "";
            input.focus();
            input.value = name;

            document.getElementsByClassName("validationErr_btnOk_Cls")[0] ? document.getElementsByClassName("validationErr_btnOk_Cls")[0].focus() : "";
        }, 100);

        return false;
    }

    // The parent component's form handler (the function passed in as the changed prop here) expects the 'event' object and uses its target name + value
    // Since 'code' based inputs set the code via a Dialog component, instead of directly triggering the inputs onChange
    // We programmatically provide an object matching the required shape/data to set the code value on the parent form through the Dialog
    setCode = (code, name, id = 0) => {
        //
        if (this.props.setNameAlias) {
            if (name == "codeOnly" && this.props.dataSource === "x_Merge_Options") {
                this.props.changed({
                    target: { name: this.props.field, value: code, selectedById: id },
                    target2: { name: this.props.field, value: code, selectedById: id }
                });
            } else {
                this.props.changed({ target: { name: this.props.field, value: code }, target2: { name: this.props.fieldExt2, value: name } });
            }
            /*
            if (this.props.setNameAlias == "code+name") {
                this.props.changed({ target: { name: this.props.field, value: code }, target2: { name: this.props.fieldExt2, value: name } });
            } else if (this.props.setNameAlias == "name+code") {
                this.props.changed({ target: { name: this.props.field, value: name }, target2: { name: this.props.fieldExt2, value: code } });
            }
            */
        } else if (name == "codeOnly") {
            if (this.props.dataSource === "x_Merge_Options") {
                this.props.changed({
                    target: { name: this.props.field, value: code, selectedById: id },
                    target2: { name: this.props.field, value: code, selectedById: id }
                });
            } else {
                this.props.changed({ target: { name: this.props.field, value: code } });
            }
        } else {
            if (this.props.dataSource == "Templates_FollowUp_Order" && id != 0) {
                this.props.changed({
                    target: { name: this.props.field, value: code, selectedById: id },
                    target2: { name: this.props.field + "Name", value: name }
                });
            } else if ((this.props.dataSource == "Letters_2" && document.getElementById("lettersMaintMainPage"))
                || (this.props.dataSource == "Letters" && document.getElementById("customFormsMaintMainPage")) && id != 0) {
                this.props.changed({
                    target: { name: this.props.field, value: code, selectedById: id },
                    target2: { name: this.props.field + "Name", value: name }
                });
            } else {
                if (this.props.isMultiSelection && this.props.concatAllValues) {
                    let oldVal = this.props.value ? this.props.value : "";
                    if (oldVal.indexOf(",") == -1) {
                        oldVal = code + ",";
                    } else {
                        if (oldVal.lastIndexOf(",") == oldVal.length - 1) {
                            var _oldVal = "";
                            for (var i = 0; i <= oldVal.split(",").length - 2; i++) {
                                _oldVal += oldVal.split(",")[i] + ",";
                            }
                            oldVal = _oldVal + code + ",";
                        } else {
                            var _oldVal = "";
                            for (var i = 0; i <= oldVal.split(",").length - 2; i++) {
                                _oldVal += oldVal.split(",")[i] + ",";
                            }
                            oldVal = _oldVal + code + ",";
                        }
                    }

                    this.props.changed({
                        target: { name: this.props.field, value: oldVal },
                        target2: {
                            name: this.props.field + "Name",
                            value: name
                        }
                    });
                } else {
                    this.props.changed({
                        target: { name: this.props.field, value: code },
                        target2: {
                            name: this.props.field + "Name",
                            value: name
                        }
                    });
                }
            }
        }

        this.closeCodeDialog();

        // check if whether returned value is a ranges?
        if (code && code.indexOf("-") >= 0 && this.IsNumeric(code.replace(/-/g, ""))) {
            this.setState({ isShowValidationError: false, failedData: true });
        } else {
            this.setState({ isShowValidationError: true, failedData: false });
        }

        // set focus to selected field
        var aliasField = this.props.keyId ? this.props.keyId : this.props.field;
        const input = document.getElementById(aliasField);

        setTimeout(function () {
            input.value = "";
            input.focus();
            input.value = code;

            document.getElementsByClassName("validationErr_btnOk_Cls")[0] ? document.getElementsByClassName("validationErr_btnOk_Cls")[0].focus() : "";
        }, 100);

        return false;
    }

    setColor = (color, bgColor, rapidAbst, fmt) => {
        const rapid = this.props.rapidAbstract;
        const menuAbs = (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract");

        if (!menuAbs) return false;

        let isRapidAbstract = (menuAbs && rapidAbst === '1' ? true : false);
        if (menuAbs && rapid) {
            this.setState({
                lblColor: !isRapidAbstract ? "#A5A5A5" : color,
                bgColor: !isRapidAbstract ? "#E6E6E6" : bgColor,
                isRapidAbstract: isRapidAbstract,
                formatData: fmt,
                setRapidMode: true
            });
        } else {
            this.setState({
                lblColor: color,
                bgColor: bgColor,
                isRapidAbstract: false,
                formatData: fmt,
                setColor: true
            });
        }

        if (menuAbs && rapid) {
            // set focus to initial field
            var aliasField = this.props.keyId ? this.props.keyId : this.props.field;
            const input = document.getElementById(aliasField);
            if (this.props.autoFocus && input && input.getAttribute("data-index") === "0") {
                let code = input ? input.value : "";
                setTimeout(function () {
                    input.value = "";
                    input.focus();
                    input.value = code;
                    return false;
                }, 0);
                return false;
            }
        }

        return false;
    }

    componentDidMount() {
        const rapidMode = this.props.rapidAbstract;
        const menuAbs = (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract");

        if (!rapidMode && !this.state.setColor && this.props.commonColors != null && this.props.field) {
            var commonColors = this.props.commonColors;
            var fieldName = this.props.helpNaming ? this.props.helpNaming : this.props.field;
            var fieldItemNbr = this.props.itemNbr;

            Object.entries(commonColors).map((t, k) => {
                const colName = t[1].code ? t[1].code.replace(/[_\-\]\[]/g, "") : "";
                const itemNbr = t[1].itemNbr;
                let headerName = "";
                if (this.props.type === "gridHeader") {
                    headerName = t[1].code ? t[1].code : "";
                }

                if (fieldItemNbr == itemNbr && (colName.toUpperCase() === fieldName.toUpperCase() || headerName.toUpperCase() === fieldName.toUpperCase())) {
                    this.setColor(t[1].color, t[1].bgColor, t[1].rapidAbst, t[1].formatData);
                } else if ((!fieldItemNbr || !itemNbr) && (colName.toUpperCase() === fieldName.toUpperCase() || headerName.toUpperCase() === fieldName.toUpperCase())) {
                    //console.log("Set color for item has no itemNbr [" + fieldName + "].");
                    this.setColor(t[1].color, t[1].bgColor, t[1].rapidAbst, t[1].formatData);
                }
            });

        } else if (menuAbs && rapidMode && !this.state.setRapidMode && this.state.isRapidAbstract !== this.props.rapidAbstract && this.props.commonColors != null && this.props.field) {
            var commonColors = this.props.commonColors;
            var fieldName = this.props.helpNaming ? this.props.helpNaming : this.props.field;
            var fieldItemNbr = this.props.itemNbr;

            Object.entries(commonColors).map((t, k) => {
                const colName = t[1].code ? t[1].code.replace(/[_\-\]\[]/g, "") : "";
                const itemNbr = t[1].itemNbr;
                let headerName = "";
                if (this.props.type === "gridHeader") {
                    headerName = t[1].code ? t[1].code : "";
                }

                if (fieldItemNbr == itemNbr && (colName.toUpperCase() === fieldName.toUpperCase() || headerName.toUpperCase() === fieldName.toUpperCase())) {
                    this.setColor(t[1].color, t[1].bgColor, t[1].rapidAbst, t[1].formatData);
                } else if ((!fieldItemNbr || !itemNbr) && (colName.toUpperCase() === fieldName.toUpperCase() || headerName.toUpperCase() === fieldName.toUpperCase())) {
                    //console.log("Set color for item has no itemNbr [" + fieldName + "]..");
                    this.setColor(t[1].color, t[1].bgColor, t[1].rapidAbst, t[1].formatData);
                }
            });

        } else if (this.props.helpNaming && this.state.setColor && this.props.commonColors != null
            && this.props.helpNaming != this.props.field && !this.state.reDrawWithDynamicFields) {
            var commonColors = this.props.commonColors;
            var fieldName = this.props.helpNaming ? this.props.helpNaming.replace(/_/g, "") : this.props.field;
            var fieldItemNbr = this.props.itemNbr;

            Object.entries(commonColors).map((t, k) => {
                const colName = t[1].code ? t[1].code.replace(/[_\-\]\[]/g, "") : "";
                const itemNbr = t[1].itemNbr;
                let headerName = "";
                if (this.props.type === "gridHeader") {
                    headerName = t[1].code ? t[1].code : "";
                }

                if (fieldItemNbr == itemNbr && (colName.toUpperCase() === fieldName.toUpperCase() || headerName.toUpperCase() === fieldName.toUpperCase())) {
                    this.setColor(t[1].color, t[1].bgColor, t[1].rapidAbst, t[1].formatData);
                } else if ((!fieldItemNbr || !itemNbr) && (colName.toUpperCase() === fieldName.toUpperCase() || headerName.toUpperCase() === fieldName.toUpperCase())) {
                    //console.log("Set color for item has no itemNbr [" + fieldName + "]...");
                    this.setColor(t[1].color, t[1].bgColor, t[1].rapidAbst, t[1].formatData);
                }
            });

            this.setState({ reDrawWithDynamicFields: true });
        }
    }

    componentDidUpdate(prevProps) {
        const rapidMode = this.props.rapidAbstract;
        const menuAbs = (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract");

        if (!rapidMode && !this.state.setColor && this.props.commonColors != null && this.props.field) {
            var commonColors = this.props.commonColors;
            var fieldName = this.props.helpNaming ? this.props.helpNaming : this.props.field;
            var fieldItemNbr = this.props.itemNbr;

            Object.entries(commonColors).map((t, k) => {
                const colName = t[1].code ? t[1].code.replace(/[_\-\]\[]/g, "") : "";
                const itemNbr = t[1].itemNbr;
                let headerName = "";
                if (this.props.type === "gridHeader") {
                    headerName = t[1].code ? t[1].code : "";
                }

                if (fieldItemNbr == itemNbr && (colName.toUpperCase() === fieldName.toUpperCase() || headerName.toUpperCase() === fieldName.toUpperCase())) {
                    this.setColor(t[1].color, t[1].bgColor, t[1].rapidAbst, t[1].formatData);
                } else if ((!fieldItemNbr || !itemNbr) && (colName.toUpperCase() === fieldName.toUpperCase() || headerName.toUpperCase() === fieldName.toUpperCase())) {
                    //console.log("Set color for item has no itemNbr [" + fieldName + "]....");
                    this.setColor(t[1].color, t[1].bgColor, t[1].rapidAbst, t[1].formatData);
                }
            });

        } else if (menuAbs && rapidMode && !this.state.setRapidMode && this.state.isRapidAbstract !== this.props.rapidAbstract && this.props.commonColors != null && this.props.field) {
            var commonColors = this.props.commonColors;
            var fieldName = this.props.helpNaming ? this.props.helpNaming : this.props.field;
            var fieldItemNbr = this.props.itemNbr;

            Object.entries(commonColors).map((t, k) => {
                const colName = t[1].code ? t[1].code.replace(/[_\-\]\[]/g, "") : "";
                const itemNbr = t[1].itemNbr;
                let headerName = "";
                if (this.props.type === "gridHeader") {
                    headerName = t[1].code ? t[1].code : "";
                }

                if (fieldItemNbr == itemNbr && (colName.toUpperCase() === fieldName.toUpperCase() || headerName.toUpperCase() === fieldName.toUpperCase())) {
                    this.setColor(t[1].color, t[1].bgColor, t[1].rapidAbst, t[1].formatData);
                } else if ((!fieldItemNbr || !itemNbr) && (colName.toUpperCase() === fieldName.toUpperCase() || headerName.toUpperCase() === fieldName.toUpperCase())) {
                    //console.log("Set color for item has no itemNbr [" + fieldName + "].....");
                    this.setColor(t[1].color, t[1].bgColor, t[1].rapidAbst, t[1].formatData);
                }
            });

        } else if (this.props.helpNaming && this.state.setColor && this.props.commonColors != null
            && this.props.helpNaming != this.props.field && !this.state.reDrawWithDynamicFields) {
            var commonColors = this.props.commonColors;
            var fieldName = this.props.helpNaming ? this.props.helpNaming.replace(/_/g, "") : this.props.field;
            var fieldItemNbr = this.props.itemNbr;

            Object.entries(commonColors).map((t, k) => {
                const colName = t[1].code ? t[1].code.replace(/[_\-\]\[]/g, "") : "";
                const itemNbr = t[1].itemNbr;
                let headerName = "";
                if (this.props.type === "gridHeader") {
                    headerName = t[1].code ? t[1].code : "";
                }

                if (fieldItemNbr == itemNbr && (colName.toUpperCase() === fieldName.toUpperCase() || headerName.toUpperCase() === fieldName.toUpperCase())) {
                    this.setColor(t[1].color, t[1].bgColor, t[1].rapidAbst, t[1].formatData);
                } else if ((!fieldItemNbr || !itemNbr) && (colName.toUpperCase() === fieldName.toUpperCase() || headerName.toUpperCase() === fieldName.toUpperCase())) {
                    //console.log("Set color for item has no itemNbr [" + fieldName + "]......");
                    this.setColor(t[1].color, t[1].bgColor, t[1].rapidAbst, t[1].formatData);
                }
            });

            this.setState({ reDrawWithDynamicFields: true });
        }

        if (!this.state.textEditorSetup) {
            const fval = this.props.value;
            const fmax = this.props.maxlen ? this.props.maxlen : "0";
            this.setState({
                textVal: fval,
                currentLength: fval ? fval.length : 0,
                maxLength: fmax,
                textEditorSetup: true
            });
        }

        if (!this.state.addEventListener && this.props.shownType == "dropdown") {
            this.init();
            this.setState({ addEventListener: true });
        }
    }

    doubleClickHandler = (event) => {
        event.preventDefault();

        if (this.props.normalTaField) return false;

        if (this.props.textPadFields && event.target.nodeName == "TEXTAREA") {
            var focusingFieldClass = event.target.getAttribute("class");
            var expanderCls = false;
            if (focusingFieldClass.indexOf("expanderCls") != -1) {
                focusingFieldClass = focusingFieldClass.replace("expanderCls", "");
                expanderCls = false;
            } else {
                focusingFieldClass += "expanderCls";
                expanderCls = true;
            }
            event.target.setAttribute("class", focusingFieldClass);

            //
            var currentField = event.target.name;
            if (this.props.textPadFields) {
                if (!expanderCls) {
                    var minimizeHeight = 50;
                    var textFields = [
                        'dxComments', 'primSiteText', 'histologyText', 'placeDxText', 'phyExamText',
                        'xrayScanText', 'scopesText', 'labsText', 'opText', 'pathText', 'surgeryText',
                        'beamRadText', 'othRadText', 'chemoText', 'hormText', 'immunoText', 'hemaText',
                        'stageText', 'ajccStagingText', 'folComments', 'transferText', 'importedText'
                    ];
                    for (var i = 0; i < textFields.length; i++) {
                        var textField = textFields[i];
                        if (textField == currentField) {
                            var minHeight = minimizeHeight;
                            if (textField == 'primSiteText' || textField == 'histologyText') { minHeight = 40 };
                            if (textField == 'placeDxText') { minHeight = 30 };

                            var textPadForm = document.getElementById("diagnosisTextForm");
                            if (textPadForm) {
                                textPadForm.querySelector("#" + textField + "_TextPadLbl").style.transition = 'height 0.3s';
                                textPadForm.querySelector("#" + textField + "_TextPadLbl").style.height = minHeight + 'px';
                            } else {
                                document.getElementById(textField + "_TextPadLbl").style.transition = 'height 0.3s';
                                document.getElementById(textField + "_TextPadLbl").style.height = minHeight + 'px';
                            }
                        }
                    }
                } else {
                    var focusingField = event.target;

                    var currHeight = focusingField.style.height.replace(/[^0-9]/gi, '');
                    var expandHeight = 320;
                    var minimizeHeight = 320;

                    if (currHeight >= expandHeight) {
                        focusingField.style.height = minimizeHeight + 'px';
                    } else {
                        focusingField.style.height = expandHeight + 'px';
                    }
                }
            }
            return false;
        }

        this.setState({ showTextEditorPopup: true, textEditorSetup: false });

        var ip = this;
        setTimeout(function () {
            var curVal = document.getElementById("textEditorPopup_" + ip.props.field).value;
            document.getElementById("textEditorPopup_" + ip.props.field).value = "";
            document.getElementById("textEditorPopup_" + ip.props.field).focus();
            document.getElementById("textEditorPopup_" + ip.props.field).value = curVal;
        }, 500);

        return false;
    }

    keydownHandler = (event) => {
        //console.log("Focusing item: " + event.target.name + ", nodeName: " + event.target.nodeName);
        // F1 launches GOOGLE HELP
        if (event.keyCode == 112) {
            try { event.preventDefault(); } catch (e) { }
            var helpDiv = document.getElementsByClassName("helpInfo");
            if (helpDiv && helpDiv.length > 0) {
                setTimeout(function () {
                    helpDiv[0].firstElementChild.click();
                    return false;
                }, 0);
            } else {
                helpDiv = document.getElementsByClassName("helpInfo0");
                if (helpDiv && helpDiv.length > 0) {
                    setTimeout(function () {
                        helpDiv[0].firstElementChild.click();
                        return false;
                    }, 0);
                }
            }
            return false;

        } else if (event.keyCode == 40) {// down arrow
            this.setState({ isDialogVisible: true });
            return false;

            //Removing below comments out if you want to show dropdown when arrow key has clicked
            /*
            this.setState({ isDialogVisible: true, research: true });
            this.props.setChildrenOpening(true);

            var _focusingField = event.target.name;
            var focusedField = this.props.focusingField ? this.props.focusingField.name : this.props.field;

            var dd = document.getElementById("commonLookup_" + _focusingField);

            if (this.props.shownType == "dropdown" && focusedField == _focusingField && !dd) {
                event.target.nextSibling.click();
            }
            */
        } else if (this.props.shownType !== "dropdown"
            && (event.key === "Tab" || (event.key === "Shift" && event.keyCode == 9) || (event.key === "Tab" && event.shiftKey))
        ) {// shift + tab
            var _input = this;

            // @TODO: fixing #PR00483
            this.props.storeToGlobal(null);//clear stored everything

            //
            if (event.target.getAttribute("class") === "textEditorOnPopupCls") {
                var textEditor = document.getElementById("textEditorForm");
                setTimeout(function () {
                    textEditor.querySelectorAll("button")[0] ? textEditor.querySelectorAll("button")[0].focus() : "";
                }, 0);
            } else if (event.target.form && event.target.form.id === "patientForm") {
                var currentInput = event.target.name;
                var currentTabIndex = event.target.getAttribute("tabindex");

                if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                    //Focus previous input
                    if (currentInput === "suspenseRemarks") {
                        setTimeout(function () {
                            document.getElementById("linkOfContactsTab").click();//#2
                        }, 0);
                    } else if (currentInput === "contFirstName" && event.target.id === "contFirstName0") {
                        setTimeout(function () {
                            document.getElementById("linkOfPatientTab").click();//#3
                        }, 0);
                    } else if (currentInput === "race") {
                        setTimeout(function () {
                            document.getElementById("sex").focus();//#1
                        }, 0);
                    } else if (currentInput === "deletedDiagnosisBtn") {
                        setTimeout(function () {
                            document.getElementById("linkOfRemarksTab").click();//#4
                        }, 0);
                    } else if (currentInput === "abstSunaFlag" && event.target.id === "abstSunaFlag0") {
                        setTimeout(function () {
                            if (document.getElementById("deletedDiagnosisBtn0")) {
                                document.getElementById("deletedDiagnosisBtn0").focus();//#4
                            } else {
                                document.getElementById("linkOfRemarksTab").click();//#4
                            }
                        }, 0);
                    }
                } else if (event.key === "Tab" && event.keyCode == 9) {
                    //Focus next input
                    if (currentInput === "sex") {
                        document.getElementById("linkOfPatientTab").click();//#1
                    } else if (currentInput === "comments" && event.target.id === "comments") {
                        document.getElementById("linkOfContactsTab").click();//#2
                    } else if (currentInput === "btnDelLastCont") {
                        document.getElementById("linkOfRemarksTab").click();//#3
                    } else if (currentInput === "__contRelation__") {
                        var addBtn = document.getElementsByClassName("contactBtnArea1");
                        if (addBtn && addBtn.length > 0) {
                            var nextTabIndex = addBtn[0].firstElementChild.getAttribute("tabindex");
                            if (eval(currentTabIndex) + 1 == nextTabIndex) {
                                //document.getElementById("linkOfRemarksTab").click();//#3
                            }
                        } else {
                            document.getElementById("linkOfRemarksTab").click();//#3
                        }
                    } else if (currentInput === "suspenseRemarks") {
                        setTimeout(function () {
                            if (document.getElementById("deletedDiagnosisBtn0") && document.getElementById("deletedDiagnosisBtn0").style.display != "none") {
                                document.getElementById("deletedDiagnosisBtn0").focus();//#4
                            } else {
                                document.getElementById("abstSunaFlag0").focus();//#4
                            }
                        }, 0);

                        // set focus for all fields on Primary Summary grid
                    } else if (currentInput === "abstSunaFlag") {
                        var findex = event.target.getAttribute("data-index");
                        var currentCell = event.target.parentElement.parentElement;
                        event.preventDefault();
                        setTimeout(function () {
                            if (document.getElementById("stateRpt" + findex).getAttribute("disabled") === null) {
                                document.getElementById("stateRpt" + findex).focus();
                            } else {
                                _input.setTabInRapidMode(currentCell);
                            }
                            return false;
                        }, 0);
                        return false;
                    } else if (currentInput === "stateRpt") {
                        var findex = event.target.getAttribute("data-index");
                        var currentCell = event.target.parentElement.parentElement;
                        event.preventDefault();
                        setTimeout(function () {
                            if (document.getElementById("siteCode" + findex).getAttribute("disabled") === null) {
                                document.getElementById("siteCode" + findex).focus();
                            } else {
                                _input.setTabInRapidMode(currentCell);
                            }
                            return false;
                        }, 0);
                        return false;
                    } else if (currentInput === "siteCode") {
                        var findex = event.target.getAttribute("data-index");
                        var currentCell = event.target.parentElement.parentElement;
                        event.preventDefault();
                        setTimeout(function () {
                            if (document.getElementById("seqPrim" + findex).getAttribute("disabled") === null) {
                                document.getElementById("seqPrim" + findex).focus();
                            } else {
                                _input.setTabInRapidMode(currentCell);
                            }
                            return false;
                        }, 0);
                        return false;
                    } else if (currentInput === "seqPrim") {
                        var findex = event.target.getAttribute("data-index");
                        var currentCell = event.target.parentElement.parentElement;
                        event.preventDefault();
                        setTimeout(function () {
                            if (document.getElementById("hosp" + findex).getAttribute("disabled") === null) {
                                document.getElementById("hosp" + findex).focus();
                            } else {
                                _input.setTabInRapidMode(currentCell);
                            }
                            return false;
                        }, 0);
                        return false;
                    } else if (currentInput === "hosp") {
                        var findex = event.target.getAttribute("data-index");
                        var currentCell = event.target.parentElement.parentElement;
                        event.preventDefault();
                        setTimeout(function () {
                            if (document.getElementById("class" + findex).getAttribute("disabled") === null) {
                                document.getElementById("class" + findex).focus();
                            } else {
                                _input.setTabInRapidMode(currentCell);
                            }
                            return false;
                        }, 0);
                        return false;
                    } else if (currentInput === "class") {
                        var findex = event.target.getAttribute("data-index");
                        var currentCell = event.target.parentElement.parentElement;
                        event.preventDefault();
                        setTimeout(function () {
                            if (document.getElementById("diagnosisDateMonth" + findex).getAttribute("disabled") === null) {
                                document.getElementById("diagnosisDateMonth" + findex).focus();
                            } else {
                                _input.setTabInRapidMode(currentCell);
                            }
                            return false;
                        }, 0);
                        return false;
                    } else if (currentInput === "diagnosisDateMonth") {
                        var findex = event.target.getAttribute("data-index");
                        var currentCell = event.target.parentElement.parentElement;
                        event.preventDefault();
                        setTimeout(function () {
                            document.getElementById("diagnosisDateDay" + findex).focus();
                            return false;
                        }, 0);
                        return false;
                    } else if (currentInput === "diagnosisDateDay") {
                        var findex = event.target.getAttribute("data-index");
                        var currentCell = event.target.parentElement.parentElement;
                        event.preventDefault();
                        setTimeout(function () {
                            document.getElementById("diagnosisDateYear" + findex).focus();
                            return false;
                        }, 0);
                        return false;
                    } else if (currentInput === "diagnosisDateYear") {
                        var findex = event.target.getAttribute("data-index");
                        var currentCell = event.target.parentElement.parentElement;
                        event.preventDefault();
                        setTimeout(function () {
                            if (document.getElementById("accNbr" + findex).getAttribute("disabled") === null) {
                                document.getElementById("accNbr" + findex).focus();
                            } else {
                                _input.setTabInRapidMode(currentCell);
                            }
                            return false;
                        }, 0);
                        return false;
                    } else if (currentInput === "accNbr") {
                        var findex = event.target.getAttribute("data-index");
                        var currentCell = event.target.parentElement.parentElement;
                        event.preventDefault();
                        setTimeout(function () {
                            if (document.getElementById("medRecNbr" + findex).getAttribute("disabled") === null) {
                                document.getElementById("medRecNbr" + findex).focus();
                            } else {
                                _input.setTabInRapidMode(currentCell);
                            }
                            return false;
                        }, 0);
                        return false;

                    } else if (currentInput === "medRecNbr") {
                        var findex = event.target.getAttribute("data-index");
                        event.preventDefault();
                        setTimeout(function () {
                            if (document.getElementById("selectedDiagnosisOnGridBtn" + findex)) {
                                document.getElementById("selectedDiagnosisOnGridBtn" + findex).focus();
                            } else {
                                findex = parseInt(findex) + 1;
                                if (document.getElementById("deletedDiagnosisBtn" + findex)) {
                                    document.getElementById("deletedDiagnosisBtn" + findex).focus();
                                } else if (document.getElementById("abstSunaFlag" + findex)) {
                                    document.getElementById("abstSunaFlag" + findex).focus();
                                } else {
                                    document.getElementById("pformAddNewPrimary") ? document.getElementById("pformAddNewPrimary").focus() : "";
                                }
                            }
                            return false;
                        }, 0);
                        return false;

                    } else if (currentInput === "navTabOnPatientMaint") {
                        document.getElementById("linkOfPatientTab").click();//#1
                    }
                }

            } else if (event.target.form && event.target.form.id === "diagnosisForm") {
                var currentInput = event.target.name;
                if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                    //Focus previous input
                    if (currentInput === "accYr") {
                        setTimeout(function () {
                            document.getElementById("inpatientStatus").focus();
                        }, 0);
                    }
                } else if (event.key === "Tab" && event.keyCode == 9) {
                    //Focus next input
                    if (currentInput === "inpatientStatus") {
                        setTimeout(function () {
                            document.getElementById("accYr").focus();
                        }, 0);
                    }
                }

            } else if (event.target.form && event.target.form.id === "stagingForm") {
                var currentInput = event.target.name;
                if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                    if (currentInput === "ajccBasis") {
                        document.getElementById("linkOfSiteSpecificTab").click();//#Site-Specific Data Items tab
                    } else if (currentInput === "SSDI_USER_1" || currentInput === "csSsf1") {
                        document.getElementById("linkOfStagingTab").click();//#Staging tab
                    }
                } else if (event.key === "Tab" && event.keyCode == 9) {
                    //Focus next input
                    if (currentInput === "stagingUser2") {
                        document.getElementById("linkOfSiteSpecificTab").click();//#Site-Specific Data Items tab
                    } else if (currentInput === "SSDI_USER_21") {
                        document.getElementById("linkOfAjccTnmTab").click();//#AJCC TNM Staging tab
                    } else if (currentInput.indexOf("csSsf") !== -1) {
                        var userNumber = currentInput.replace('csSsf', '');//currentInput.substr(currentInput.length - 1);
                        userNumber = Number(userNumber) + 1;

                        if (userNumber <= 21) {
                            var parentDiv = document.getElementById('SSDI_USER_' + userNumber).parentElement;
                            if (parentDiv.style.visibility == 'hidden'
                                || (parentDiv.hasAttribute('class') && parentDiv.getAttribute('class').indexOf('hidden') !== -1)) {
                                setTimeout(function () {
                                    document.getElementById("linkOfAjccTnmTab").click();
                                }, 100);
                            }
                        } else {
                            setTimeout(function () {
                                document.getElementById("linkOfAjccTnmTab").click();
                            }, 100);
                        }
                    }
                }

            } else if (event.target.form && event.target.form.id === "stagingPre2018Form") {
                var currentInput = event.target.name;

                if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                    //Focus previous input
                    if (currentInput === "stagingUser1") {
                        // determine the fields have disabled to ignore set focus for it
                        var currentItemIndex = 25;
                        for (var idx = currentItemIndex; idx > 0; idx--) {
                            if (document.getElementById("CS_SSF_" + idx) &&
                                document.getElementById("CS_SSF_" + idx).getAttribute("disabled") === null) {
                                currentItemIndex = idx;
                                break;
                            }
                        }
                        setTimeout(function () {
                            document.getElementById("CS_SSF_" + (currentItemIndex)) ? document.getElementById("CS_SSF_" + (currentItemIndex)).focus() : "";
                        }, 0);
                    } else if (currentInput.indexOf("csSsf") !== -1) {
                        var currentItemIndex = currentInput.replace("csSsf", "");
                        currentItemIndex = eval(currentItemIndex);

                        if (1 < currentItemIndex && currentItemIndex <= 25) {
                            setTimeout(function () {
                                document.getElementById("CS_SSF_" + (currentItemIndex - 1)) ? document.getElementById("CS_SSF_" + (currentItemIndex - 1)).focus() : "";
                            }, 0);
                        } else {
                            var fnEnablessf = document.getElementById("fnEnablessf").value;
                            if (fnEnablessf !== "1") {
                                setTimeout(function () {
                                    document.getElementById("fnEnablessf").focus();
                                }, 0);
                            } else {
                                setTimeout(function () {
                                    document.getElementById("metsDxOther").focus();
                                }, 0);
                            }
                        }
                    }
                } else if (event.key === "Tab" && event.keyCode == 9) {
                    //Focus next input
                    if (currentInput === "metsDxOther") {
                        var fnEnablessf = document.getElementById("fnEnablessf").value;
                        if (fnEnablessf !== "1") {
                            setTimeout(function () {
                                document.getElementById("fnEnablessf").focus();
                            }, 0);
                        } else {
                            setTimeout(function () {
                                document.getElementById("CS_SSF_1").focus();
                            }, 0);
                        }
                    } else if (currentInput.indexOf("csSsf") !== -1) {
                        var currentItemIndex = currentInput.replace("csSsf", "");
                        currentItemIndex = eval(currentItemIndex) + 1;

                        for (var idx = currentItemIndex; idx <= 25; idx++) {
                            if (document.getElementById("CS_SSF_" + idx) &&
                                document.getElementById("CS_SSF_" + idx).getAttribute("disabled") === null) {//don't be disabled
                                currentItemIndex = idx;
                            } else {
                                currentItemIndex = 26;
                            }
                            break;
                        }

                        if (0 < currentItemIndex && currentItemIndex <= 25) {
                            setTimeout(function () {
                                document.getElementById("CS_SSF_" + currentItemIndex) ? document.getElementById("CS_SSF_" + currentItemIndex).focus() : "";//#1
                            }, 0);
                        } else {
                            setTimeout(function () {
                                document.getElementById("stagingUser1").focus();//#2
                            }, 0);
                        }
                    }
                }

            } else if (event.target.form && event.target.form.id === "cocForm") {
                var currentInput = event.target.name;
                if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                    //Focus previous input
                    if (currentInput === "ccDateMonth" && event.target.id === "ccDateMonth0") {
                        document.getElementById("linkOfCCServicesTab").click();
                    } else if (currentInput === "ptNavProcess") {
                        document.getElementById("linkOfCOCChartAuditTab").click();
                    }
                } else if (event.key === "Tab" && !event.shiftKey) {
                    //Focus next input
                    if (currentInput === "cpmText") {
                        document.getElementById("linkOfCCClinicalTrialsTab").click();
                    } else if (currentInput === "cpmAuditNotes") {
                        document.getElementById("linkOfCCServicesTab").click();
                    }
                }

            } else if (event.target.form && event.target.form.id === "rectalProgramForm") {
                var currentInput = event.target.name;
                if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                    //Focus previous input
                    if (currentInput === "rpmConfDxDocumentedMr") {
                        document.getElementById("linkOfRPMChartAuditTab").click();
                    }
                } else if (event.key === "Tab" && !event.shiftKey) {
                    //Focus next input
                    if (currentInput === "rpmAuditNotes") {
                        document.getElementById("linkOfRPMServicesTab").click();
                    }
                }

            } else if (event.target.form && event.target.form.id === "napbcForm") {
                var currentInput = event.target.name;
                if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                    //Focus previous input
                    if (currentInput === "mammoDate") {
                        document.getElementById("linkOfBPMChartAuditTab2").click();
                    } else if (currentInput === "surgIncludeErPrHer2Bpm") {
                        document.getElementById("linkOfBPMChartAuditTab").click();
                    }
                } else if (event.key === "Tab" && !event.shiftKey) {
                    //Focus next input
                    if (currentInput === "radOncoGuidlinesFollowedBpm") {
                        document.getElementById("linkOfBPMChartAuditTab2").click();
                    } else if (currentInput === "bpmAuditNotes") {
                        document.getElementById("linkOfBPMServicesTab").click();
                    }
                }

            } else if (event.target.form && event.target.form.id === "userDefinedForm") {
                var currentInput = event.target.name;
                if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                    //Focus previous input
                    if (currentInput === "udef46") {
                        document.getElementById("linkOfUserDefined1Tab").click();
                    }
                } else if (event.key === "Tab" && !event.shiftKey) {
                    //Focus next input
                    if (currentInput === "udef45") {
                        document.getElementById("linkOfUserDefined2Tab").click();
                    }
                }


            } else if (event.target.form && event.target.form.id === "__diagnosisTextForm__") {
                if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                    if (event.target.nodeName === "BUTTON") {
                        if (event.target.nextSibling) {
                            setTimeout(function () {
                                document.getElementById('importedText') ? document.getElementById('importedText').focus() : "";
                            }, 0);
                        }
                        return false;
                    }
                } else if (event.key === "Tab" && event.keyCode == 9) {
                    if (event.target.nodeName === "BUTTON") {
                        if (!event.target.nextSibling || (event.target.nextSibling && event.target.nextSibling.disabled)) {
                            setTimeout(function () {
                                document.getElementById('primSiteText') ? document.getElementById('primSiteText').focus() : "";
                            }, 0);
                        }
                        return false;
                    }
                }

            } else if (event.target.form && event.target.form.id === "retiredForm") {
                var currentInput = event.target.name;
                if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                    //Focus previous input
                    if (currentInput === "ssnSuffix") {
                        document.getElementById("linkOfPStageDescTab").click();
                    } else if (currentInput === "pStageDesc") {
                        document.getElementById("linkOfCStageDescTab").click();
                    } else if (currentInput === "cStageDesc") {
                        document.getElementById("linkOfOStageDescTab").click();
                    } else if (currentInput === "oStageDesc") {
                        setTimeout(function () {
                            document.getElementById("othStg").focus();
                        }, 0);
                    } else if (currentInput === "dateEnteredMonth" && event.target.id === "dateEnteredMonth0") {
                        setTimeout(function () {
                            document.getElementById("dxIndustry").focus();
                        }, 0);
                    } else if (currentInput === "recurDateMonth" && event.target.id === "recurDate0") {
                        setTimeout(function () {
                            document.getElementById("recurOther").focus();
                        }, 0);
                    } else if (currentInput === "drgCode" && event.target.id === "drgCode0") {
                        document.getElementById("linkOfRetiredTab1").click();
                    }
                } else if (event.key === "Tab" && event.keyCode == 9) {
                    //Focus next input
                    if (currentInput === "othStg") {
                        document.getElementById("linkOfOStageDescTab").click();
                    } else if (currentInput === "oStageDesc") {
                        document.getElementById("linkOfCStageDescTab").click();
                    } else if (currentInput === "cStageDesc") {
                        document.getElementById("linkOfPStageDescTab").click();
                    } else if (currentInput === "pStageDesc") {
                        setTimeout(function () {
                            document.getElementById("ssnSuffix").focus();
                        }, 0);
                    } else if (currentInput === "dxIndustry") {
                        setTimeout(function () {
                            document.getElementById("dateEnteredMonth0").focus();
                        }, 0);
                    } else if (currentInput === "recurOther") {
                        setTimeout(function () {
                            document.getElementById("recurDate0").focus();
                        }, 0);

                    } else if (currentInput === "__recurOther__") {
                        document.getElementById("linkOfRetiredTab2").click();
                    }
                }

                //Configure Two Factor Authentication
            } else if (event.target.form && event.target.form.id === "__securityTwoFactorAuthForm__") {
                var currentInput = event.target.name;
                if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                    //Focus previous input
                    if (currentInput === "securityCode") {
                        setTimeout(function () {
                            document.getElementById("btnIgnoreTfaConfig") ? document.getElementById("btnIgnoreTfaConfig").focus() : "";
                        }, 100);
                    }
                    event.preventDefault();
                    return false;
                } else if (event.key === "Tab" && event.keyCode == 9 && !event.shiftKey) {
                    //Focus next input
                    if (currentInput === "securityCode") {
                        setTimeout(function () {
                            document.getElementById("btnScannedQrCode") ? document.getElementById("btnScannedQrCode").focus() : "";
                        }, 100);
                    }
                    event.preventDefault();
                    return false;
                }

                // Hospital Form
            } else if (event.target.form && event.target.form.id === "dialogForm"
                && event.target.form.getAttribute("class") && event.target.form.getAttribute("class").indexOf("hospital_maint_form") != -1) {
                // press Shift+Tab on "Hospital Number" field
                var currentInput = event.target.name;
                if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                    //Focus previous input
                    if (currentInput === "hospNbrDsp") {
                        setTimeout(function () {
                            document.getElementById("btnDeleteItem_Fake") && !document.getElementById("btnDeleteItem_Fake").disabled ? document.getElementById("btnDeleteItem_Fake").focus() : document.getElementById("btnDeleteItem_Fake").previousSibling.focus();//set focus to Exit button
                        }, 100);
                    } else if (currentInput === "counties" && event.target.getAttribute("id").indexOf("counties0") != -1) {
                        setTimeout(function () {
                            document.getElementById("linkOfAliasesTab") ? document.getElementById("linkOfAliasesTab").click() : "";
                        }, 10);
                    }
                } else if (event.key === "Tab" && event.keyCode == 9 && !event.shiftKey) {
                    //Focus next input
                    if (currentInput === "textPopup") {
                        setTimeout(function () {
                            document.getElementById("linkOfAliasesTab") ? document.getElementById("linkOfAliasesTab").click() : "";
                        }, 10);
                    } else if (currentInput === "rxHospAliases0" && event.target.getAttribute("class").indexOf("latestItem") != -1) {
                        setTimeout(function () {
                            document.getElementById("linkOfCountiesTab") ? document.getElementById("linkOfCountiesTab").click() : "";
                        }, 10);
                    }
                }

                // Physicians Form
            } else if (event.target.form && event.target.form.id === "dialogForm") {
                // press Shift+Tab on "Physicians Id" field
                var currentInput = event.target.name;
                if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                    //Focus previous input
                    if (currentInput === "physiciansIdDsp") {
                        event.preventDefault();
                        setTimeout(function () {
                            document.getElementById("btnDeleteItem_Fake") && !document.getElementById("btnDeleteItem_Fake").disabled ? document.getElementById("btnDeleteItem_Fake").focus() : document.getElementById("btnDeleteItem_Fake").previousSibling.focus();//set focus to Exit button
                        }, 100);
                        return false;
                    }
                }

                // Registrars Form
            } else if (event.target.form && event.target.form.id === "dialogForm") {
                // press Shift+Tab on "Initials" field
                var currentInput = event.target.name;
                if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                    //Focus previous input
                    if (currentInput === "initials") {
                        event.preventDefault();
                        setTimeout(function () {
                            document.getElementById("btnDeleteItem_Fake") && !document.getElementById("btnDeleteItem_Fake").disabled ? document.getElementById("btnDeleteItem_Fake").focus() : document.getElementById("btnDeleteItem_Fake").previousSibling.focus();//set focus to Exit button
                        }, 100);
                        return false;
                    }
                }

            } else if (event.target.form && event.target.form.id === "v21CompatibilityFeatureForm") {
                var currentInput = event.target.name;
                if ((event.key === "Tab" && event.shiftKey) || (event.key === "Shift" && event.keyCode == 9)) {
                    //Focus previous input
                    if (currentInput === "v21SchemaDiscrim1"
                        || (currentInput === "v21CsSsf1") && document.getElementById("v21SchemaDiscrim1")
                        && document.getElementById("v21SchemaDiscrim1").parentNode
                        && document.getElementById("v21SchemaDiscrim1").parentNode.parentNode
                        && document.getElementById("v21SchemaDiscrim1").parentNode.parentNode.style.display == "none") {
                        setTimeout(function () {
                            document.getElementById("linkOfV21CompatibilityFeatureTab1") ? document.getElementById("linkOfV21CompatibilityFeatureTab1").click() : "";
                        }, 0);
                    }
                } else if (event.key === "Tab" && event.keyCode == 9) {
                    //Focus next input
                    if (currentInput === "v21AjccPostStageGroup") {
                        setTimeout(function () {
                            document.getElementById("linkOfV21CompatibilityFeatureTab2") ? document.getElementById("linkOfV21CompatibilityFeatureTab2").click() : "";
                        }, 0);
                    }
                }
            }

        } else if (event.keyCode == 13 && event.target.nodeName !== "TEXTAREA") {
            event.preventDefault();
            return false;

            // 
        } else {
            if (this.props.shownType == "dropdown"
                && (event.key === "Tab"
                    || (event.key === "Shift" && event.keyCode == 9)
                    || (event.key === "Tab" && event.shiftKey)
                    || event.keyCode == 13
                )
            ) {
                // @TODO: fixing #PR00483
                this.props.storeToGlobal(null);//clear stored everything
            }

            return false;
        }
    }

    handlerLostFocusEventOnDateField = (event, obj) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        var currentDateInputField = event.target;
        setTimeout(function () {
            var value = currentDateInputField.value;
            var name = currentDateInputField.name;
            var field = currentDateInputField.getAttribute("data-field");
            var index = currentDateInputField.getAttribute("data-index");

            var monthVal, dayVal, yearVal;
            monthVal = document.getElementById(field + "Month" + index) ? document.getElementById(field + "Month" + index).value : "";
            dayVal = document.getElementById(field + "Day" + index) ? document.getElementById(field + "Day" + index).value : "";

            if (name.indexOf("Year") !== -1 && value.length == 2) {
                let currentYear = (new Date().getFullYear() + "");
                let yyVal = currentYear.substr(2, 2);
                if (yyVal < value) {
                    value = (currentYear.substr(0, 2) - 1) + value;
                } else {
                    value = currentYear.substr(0, 2) + value;
                }

                yearVal = value;

                setTimeout(function () {
                    if (document.getElementById(field + "Year" + index)) {
                        document.getElementById(field + "Year" + index).value = value;
                        obj.props.changed({ target: { name: field + "Year", value: value } });
                    }
                }, 0);
            } else {
                yearVal = document.getElementById(field + "Year" + index) ? document.getElementById(field + "Year" + index).value : "";
            }

            obj.props.lostfocused({ target: { name: name, value: value } });

            //
            if (monthVal && dayVal && yearVal && monthVal != "99" && dayVal != "99" && yearVal != "9999") {
                if (monthVal > 12) {
                    obj.setState({
                        failedData: true,
                        futureDateVal: monthVal
                    });
                    return false;
                } else if (dayVal > 31) {
                    obj.setState({
                        failedData: true,
                        futureDateVal: dayVal
                    });
                    return false;
                } else if (yearVal < 1800 || yearVal > new Date().getFullYear()) {
                    obj.setState({
                        failedData: true,
                        futureDateVal: yearVal
                    });
                    return false;
                }

                var currentMDY = new Date();
                var mdyInput = new Date(yearVal, monthVal - 1, dayVal);
                if (currentMDY < mdyInput) {
                    if (mdyInput == undefined || mdyInput == "Invalid Date" || mdyInput.getFullYear() > 9999 || (mdyInput.getFullYear() > currentMDY.getFullYear() && mdyInput.getFullYear() != 9999)) {
                        obj.setState({
                            failedData: true,
                            futureDateVal: monthVal + "/" + dayVal + "/" + yearVal
                        });
                    } else if (mdyInput.getFullYear() == 9999 && (monthVal != 99 || dayVal != 99)) {
                        obj.setState({
                            failedData: true,
                            futureDateVal: monthVal + "/" + dayVal + "/" + yearVal
                        });
                    } else {
                        obj.setState({
                            failedData: true,
                            futureDateVal: monthVal + "/" + dayVal + "/" + yearVal
                        });
                    }
                } else if (mdyInput == undefined || mdyInput == "Invalid Date" || mdyInput.getFullYear() > 9999 || (mdyInput.getFullYear() > currentMDY.getFullYear() && mdyInput.getFullYear() != 9999)) {
                    obj.setState({
                        failedData: true,
                        futureDateVal: monthVal + "/" + dayVal + "/" + yearVal
                    });
                } else if (mdyInput.getFullYear() == 9999 && (monthVal != 99 || dayVal != 99)) {
                    obj.setState({
                        failedData: true,
                        futureDateVal: monthVal + "/" + dayVal + "/" + yearVal
                    });
                } else {
                    if (monthVal > 12) {
                        obj.setState({
                            failedData: true,
                            futureDateVal: monthVal
                        });
                    } else if (dayVal > 31) {
                        obj.setState({
                            failedData: true,
                            futureDateVal: dayVal
                        });
                    } else if (yearVal > currentMDY.getFullYear() && yearVal != 9999) {
                        obj.setState({
                            failedData: true,
                            futureDateVal: yearVal
                        });
                    } else {
                        obj.setState({ failedData: false, futureDateVal: null });
                    }
                }
                return false;
            } else if (monthVal && dayVal && yearVal && monthVal == "99" && dayVal == "99" /*&& (yearVal == "9999" || yearVal <= new Date().getFullYear())*/) {
                if (yearVal != "9999" && (yearVal < 1800 || yearVal > new Date().getFullYear())) {
                    obj.setState({
                        failedData: true,
                        futureDateVal: yearVal,//monthVal + "/" + dayVal + "/" + yearVal
                    });
                } else {
                    obj.setState({ failedData: false, futureDateVal: null });
                }
            } else {
                if (monthVal && monthVal != "99" && (parseInt(monthVal) == 0 || parseInt(monthVal) > 12)) {
                    obj.setState({
                        failedData: true,
                        futureDateVal: monthVal
                    });
                } else if (dayVal && dayVal != "99" && (parseInt(dayVal) == 0 || parseInt(dayVal) > 31)) {
                    obj.setState({
                        failedData: true,
                        futureDateVal: dayVal
                    });
                } else if (yearVal && yearVal != "9999" && (parseInt(yearVal) == 0 || parseInt(yearVal) < 1800 || parseInt(yearVal) > new Date().getFullYear())) {
                    obj.setState({
                        failedData: true,
                        futureDateVal: yearVal
                    });
                } else {
                    var mdyInput = new Date(yearVal, monthVal, dayVal);
                    var currentMDY = new Date();
                    if (mdyInput == undefined || mdyInput == "Invalid Date" || mdyInput.getFullYear() > 9999 || (mdyInput.getFullYear() > currentMDY.getFullYear() && mdyInput.getFullYear() != 9999)) {
                        obj.setState({
                            failedData: true,
                            futureDateVal: monthVal + "/" + dayVal + "/" + yearVal
                        });
                    } else if (mdyInput.getFullYear() == 9999 && (monthVal != 99 || dayVal != 99)) {
                        obj.setState({
                            failedData: true,
                            futureDateVal: monthVal + "/" + dayVal + "/" + yearVal
                        });
                    } else {
                        obj.setState({ failedData: false, futureDateVal: null });
                    }
                }
            }
        }, 0);

        return false;
    }

    handlerKeyPressedOnDateField = (event, obj) => {
        var currentDateInputField = event.target;
        setTimeout(function () {
            var value = null;
            if (currentDateInputField.getAttribute("data-isdatefield") && currentDateInputField.getAttribute("data-isdatefield") != "") {
                value = currentDateInputField.value ? currentDateInputField.value : "";
            } else {
                value = obj.props.value ? obj.props.value : "";
            }

            var name = currentDateInputField.name;
            var field = currentDateInputField.getAttribute("data-field");
            var index = currentDateInputField.getAttribute("data-index");

            //mm/yyyy
            if (field === "mm/yyyy") {
                field = name.replace("Month" + index, "");
            }

            // MUST check valid of mm / dd before move to next field
            if (name.indexOf("Month") !== -1 && value.length == 2) {
                if (value != '99' && !(1 <= value && value <= 12)) {
                    //obj.setState({ failedData: true, futureDateVal: value });
                    return false;
                } else {
                    obj.setState({ failedData: false, futureDateVal: null });
                }

                setTimeout(function () {
                    document.getElementById(field + "Day" + index) ? document.getElementById(field + "Day" + index).focus()
                        : (document.getElementById(field + "Year" + index) ? document.getElementById(field + "Year" + index).focus() : "");
                }, 0);
            } else if (name.indexOf("Day") !== -1 && value.length == 2) {
                if (value != '99' && !(1 <= value && value <= 31)) {
                    //obj.setState({ failedData: true, futureDateVal: value });
                    return false;
                } else {
                    obj.setState({ failedData: false, futureDateVal: null });
                }

                setTimeout(function () {
                    document.getElementById(field + "Year" + index) ? document.getElementById(field + "Year" + index).focus() : "";
                }, 0);

                // Fixing #PR00281 -> ::event.keyCode //is not available
            } else if (name.indexOf("Year") !== -1 && value.length == 2 && event.keyCode == 9) {
                let currentYear = (new Date().getFullYear() + "");
                let yyVal = currentYear.substr(2, 2);
                if (yyVal < value) {
                    value = (currentYear.substr(0, 2) - 1) + value;
                } else {
                    value = currentYear.substr(0, 2) + value;
                }

                setTimeout(function () {
                    obj.props.changed({ target: { name: name, value: value } });
                    if (document.getElementById(field + "Year" + index)) {
                        document.getElementById(field + "Year" + index).value = value;
                    }
                }, 0);
                //return false;

            } else if (name.indexOf("Year") !== -1) {
                setTimeout(function () {
                    obj.props.changed({ target: { name: name, value: value } });
                    if (document.getElementById(field + "Year" + index)) {
                        document.getElementById(field + "Year" + index).value = value;
                    }
                }, 0);
                //return false;
            }

            //return false;
        }, 0);

        //return false;
    }

    setTabInRapidMode(currentCell) {
        if (!currentCell.nextSibling) {
            return false;
        } else if (currentCell.nextSibling.querySelector("input") && currentCell.nextSibling.querySelector("input").getAttribute("disabled") === null) {
            currentCell.nextSibling.querySelector("input").focus();
            return false;
        } else {
            currentCell = currentCell.nextSibling;
            for (; ;) {
                if (currentCell.nextSibling) {
                    if (currentCell.nextSibling.querySelector("input") && currentCell.nextSibling.querySelector("input").getAttribute("disabled") === null) {
                        currentCell.nextSibling.querySelector("input").focus();
                        return;
                    } else {
                        currentCell = currentCell.nextSibling;
                    }
                } else {
                    return;
                }
            }
            return false;
        }
    }

    setFieldName = (name) => {
        name = name.replace(/(([A-Z0-9])+[0-9]|[A-Z0-9])/g, "_$1");
        return name.toUpperCase();
    }

    //
    init() {
        var _this = this;
        if (_this.props.shownType == "dropdown") {
            /*
            document.addEventListener('keydown', function (e) {
                console.log("addEventListener::keydown");
                if (e.keyCode == 9 && e.target.name != _this.props.field) {//tab
                    _this.setState({ isDialogVisible: false });
                }
                return false;
            });

            //
            document.addEventListener('click', function (event) {
                if (!event.parentNode.querySelectorAll(".dragableDialogCls") || event.parentNode.querySelectorAll(".dragableDialogCls").length == 0) {
                    _this.setState({ isDialogVisible: false });
                }
                return false;
            });
            */
        }
    }

    initFnc = (obj, fnc) => {
        return fnc(obj);
    }

    render() {
        var drawVal = this.props.value ? this.props.value : (this.state.futureDateVal ? this.state.futureDateVal : "");

        // Adding format data for field
        var value = "";
        var _this = this;
        if (this.state.formatData && this.state.formatData != "") {
            var fmt = this.state.formatData;
            try {
                value = eval(fmt.replaceAll("dataEntered", "\"" + drawVal + "\""));
                /*
                if (!_this.state.initialSetVal && _this.props.changed) {
                    _this.initFnc({ target: { name: _this.props.field, value: value, notUpdatedForm: true } }, _this.props.changed);
                    _this.setState({ initialSetVal: true });
                }
                */
            } catch (exceptionJs) {
                alert("[Global_Dir::format_data] " + fmt + ".\n[Javascript Error] " + exceptionJs);
            }
        } else {
            value = drawVal;
        }

        //#20210125 - START
        let dayVal = this.props.dayVal ? this.props.dayVal : "";
        let monthVal = this.props.monthVal ? this.props.monthVal : "";
        let yearVal = this.props.yearVal ? this.props.yearVal : "";

        let inValid = ((this.props.inValid && this.props.inValid === "IN_VALID")
            || (this.props.inValidDay && this.props.inValidDay === "IN_VALID")
            || (this.props.inValidMonth && this.props.inValidMonth === "IN_VALID")
            || (this.props.inValidYear && this.props.inValidYear === "IN_VALID" || this.props.inValidYear === "ON_WARN")
            || this.state.failedData);

        let inValidField = this.props.field;
        if (this.props.inValidDay && this.props.inValidDay === "IN_VALID") {
            inValidField += "Day";
            value = this.props.dayVal;
        } else if (this.props.inValidMonth && this.props.inValidMonth === "IN_VALID") {
            inValidField += "Month";
            value = this.props.monthVal;
        } else if (this.props.inValidYear && this.props.inValidYear === "IN_VALID" || this.props.inValidYear === "ON_WARN") {
            inValidField += "Year";
            value = this.props.yearVal;
        } else if (value && value != "" && this.state.futureDateVal && !this.props.mmYyyy /*&& new Date(this.state.futureDateVal) == "Invalid Date"*/) {
            value = this.state.futureDateVal;
        } else if (value && value != "" && this.state.futureDateVal && this.state.futureDateVal > new Date()) {
            value = moment(new Date(this.state.futureDateVal)).format("MM/DD/YYYY");
        } else if (value && value.toString().indexOf("T00:00:00") != -1 && this.state.futureDateVal) {
            value = this.state.futureDateVal;
        }
        //#20210125 - END

        //this.props.changed({ target: { name: this.props.field, value: value } });

        let textEditorPopup;
        {
            textEditorPopup = this.state.showTextEditorPopup ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Text Editor</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideTextEditorPopup(e) }}></span>
                                    </a>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <label>Field Name: </label>
                                    <input type="text" disabled="true" value={this.setFieldName(this.props.field)} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <textarea name={this.props.field}
                                        id={"textEditorPopup_" + this.props.field}
                                        value={this.state.textVal}
                                        onChange={this.handleFormChange}
                                        onFocus={this.handleOnFocus}
                                        rows={this.props.rows ? this.props.rows : 8}
                                        style={{ width: "100%", height: "100%", resize: "none" }}
                                        autoFocus
                                        onKeyDown={this.keydownHandler}
                                        className="textEditorOnPopupCls"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-4">
                                    <input type="text" disabled="true" value={this.props.mainLabelText ? this.props.mainLabelText : this.props.labelText} />
                                </div>
                                <div className="col-sm-4 right">
                                    Current Length: <span style={{ "background": this.state.warning ? "rgb(162, 0, 0)" : "", "color": this.state.warning ? "white" : "" }}> {this.state.currentLength} </span>
                                </div>
                                <div className="col-sm-4">
                                    Max Length: {this.state.maxLength}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button className="ErsGrBlueButton" onClick={this.saveTextEditor}>OK</button>
                                    <button onClick={this.hideTextEditorPopup} className="btnOnTextEditorPopup" id="btnHideTextEditorPopup">Exit</button>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div >
                : null
        }

        let confirmationMsg = (
            this.state.isShowConfirmationMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Warning</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmationMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Changes have not been saved. Exit without saving?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button className="ErsGrBlueButton" onClick={(e) => { this.exitTextEditor(e) }} value="yes" autoFocus>OK</button>
                                <button className="ErsGrBlueButton" onClick={(e) => { this.hideConfirmationMsg(e) }} value="no">Cancel</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        );

        let validationError;
        {
            validationError = !this.props.inquiringMode && inValid && !this.state.isShowValidationError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className={this.props.globalMsg && this.props.globalMsg !== "" ? "dragableWindowCls" : "dragableSmallWindowCls"}>
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">
                                    {this.props.alertTitle ? this.props.alertTitle : "Validation Error"}
                                </div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideValidationError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            {
                                /*
                                <div className="container" style={{ display: "none" }}>
                                    <label style={{ width: "auto !important" }}>{inValidField} - "{value}" is an invalid value</label>
                                </div>
                                */
                            }
                            <div className="container">
                                <label className="invalidMsgErrorCls">
                                    {
                                        this.props.globalMsg && this.props.globalMsg !== ""
                                            ? this.props.globalMsg.split("\n").map(function (msg) {
                                                return (
                                                    <span>
                                                        {msg}
                                                        <br />
                                                    </span>
                                                )
                                            })
                                            : ("\"" + value + "\" " + (this.props.errMsgType ? this.props.errMsgType : "is an invalid value"))
                                    }
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideValidationError(e, this) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let style = {};
        if (this.props.labelWidth) {
            style["width"] = this.props.labelWidth + 'px';
        } else {
            style["width"] = '100px';
        }
        if (this.state.lblColor != '') {
            style["color"] = this.state.lblColor;
        } else {
            style["color"] = '#000';
        }

        // #PR00212 Rapid Abstract feature
        let disabled = false;
        if (this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract")) {
            disabled = !this.state.isRapidAbstract && !this.props.ignoreRapid;
        }

        let inputElement;
        switch (this.props.type) {
            case "gridHeader":
                inputElement = null;
                break;
            case "label":
                inputElement = <span id={this.props.keyId ? this.props.keyId : this.props.field}
                    name={this.props.field} className={this.props.labelMainStyle ? this.props.labelMainStyle : ""}
                    style={{
                        //width: this.props.width ? this.props.width : "", /*height: "26px",*/
                        //backgroundColor: this.state.bgColor,
                        width: this.props.rapidAbstract && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "auto" : this.props.width ? this.props.width : "auto",
                        backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : this.state.bgColor,
                    }}>{value}</span>;
                break;
            case "select_option_bk":
                inputElement = (
                    <React.Fragment>
                        <select id={this.props.keyId ? this.props.keyId : this.props.field}
                            name={this.props.field} value={this.props.defaultVal ? this.props.defaultVal : value}
                            onChange={(e) => { this.changedFnc(e, this.props.changed) }}
                            onBlur={(e) => { this.lostfocused(e) }}
                            onFocus={(e) => { this.focusedTextField(e) }}
                            style={{
                                width: this.props.width ? this.props.width : "",
                                height: "26px",
                                backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                            }}
                            className={(this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : " ") + (this.props.latestItem ? " latestItem" : " ") + (this.props.className ? this.props.className : " ")}
                            placeholder={this.props.placeholder ? this.props.placeholder : ""}
                            autoFocus={this.props.autoFocus}
                            onKeyDown={this.keydownHandler}
                            tabIndex={this.props.tabIndex}
                            data-index={this.props.rowIndex ? this.props.rowIndex : "0"}
                            autoComplete="off"
                            disabled={disabled ? disabled : (this.props.disabled ? this.props.disabled : false)}
                        >
                            {
                                this.props.options.map((opt, _idx) => (
                                    <option value={opt}>{opt}</option>
                                ))
                            }
                        </select>
                    </React.Fragment>
                );
                break;
            case "selectoption":
                inputElement = (
                    <React.Fragment>
                        {/*}
                        <select id={this.props.keyId ? this.props.keyId : this.props.field}
                            name={this.props.field} value={this.props.defaultVal ? this.props.defaultVal : value}
                            onChange={(e) => { this.changedFnc(e, this.props.changed) }}
                            onBlur={(e) => { this.lostfocused(e) }}
                            onFocus={(e) => { this.focusedTextField(e) }}
                            style={{
                                width: this.props.width ? this.props.width : "",
                                height: "26px",
                                backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                            }}
                            className={(this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : " ") + (this.props.latestItem ? " latestItem" : " ") + (this.props.className ? this.props.className : " ")}
                            placeholder={this.props.placeholder ? this.props.placeholder : ""}
                            autoFocus={this.props.autoFocus}
                            onKeyDown={this.keydownHandler}
                            tabIndex={this.props.tabIndex}
                            data-index={this.props.rowIndex ? this.props.rowIndex : "0"}
                            autoComplete="off"
                            disabled={disabled ? disabled : (this.props.disabled ? this.props.disabled : false)}
                        >
                            {
                                this.props.options.map((opt, _idx) => (
                                    <option value={opt}>{opt}</option>
                                ))
                            }
                        </select>
                        {*/}
                        <input type="text" name={this.props.field} value={value}
                            id={this.props.keyId ? this.props.keyId : this.props.field}
                            //data-table={this.props.dataSource}
                            //data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}
                            //data-flag={this.props.flag !== null ? this.props.flag : ""}
                            data-index={this.props.rowIndex ? this.props.rowIndex : "0"}
                            onFocus={(e) => { this.focusedTextField(e) }}
                            style={{
                                width: this.props.width ? this.props.width : "50px",
                                height: "26px",
                                backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                            }}

                            onChange={(e) => { this.changedCodeSelectFnc(e, this.props.changed) }}
                            options={this.props.options}

                            onBlur={(e) => { this.lostfocused(e) }}
                            className={this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : ""}
                            data-maxlength={this.props.maxLength ? this.props.maxLength : ""}
                            autoFocus={this.props.autoFocus}
                            onKeyDown={this.keydownHandler}
                            tabIndex={this.props.tabIndex}
                            //data-staging-pre2018={this.props.stagingPre2018}
                            autoComplete="off"
                            disabled={disabled || this.props.disabled}
                        //data-help-id={this.props.itemNbr}
                        //data-help-title={this.props.titleDialog}
                        //data-help-naming={this.props.helpNaming}
                        //data-codelength={this.props.codeLength}
                        />
                        <button disabled={disabled || this.props.disabled} style={{ padding: "1px", zIndex: 1 }} onClick={(e) => { this.openCodeDialog(e) }} className="codeSelectIcon" tabIndex="-1">&nbsp;</button>
                    </React.Fragment>
                );
                break;
            case "input":
                inputElement = (
                    <React.Fragment>
                        <input id={this.props.keyId ? this.props.keyId : this.props.field}
                            name={this.props.field} value={this.props.defaultVal ? this.props.defaultVal : value} type="text"
                            data-table={this.props.dataSource}

                            onChange={(e) => { this.changedFnc(e, this.props.changed) }}//onChange={this.props.changed}

                            onBlur={(e) => { this.lostfocused(e) }}//onBlur={this.props.lostfocused}
                            onFocus={(e) => { this.focusedTextField(e) }}//onFocus={this.props.focused}
                            data-maxlength={this.props.maxLength ? this.props.maxLength : ""}
                            style={{
                                width: this.props.width ? this.props.width : "",
                                height: "26px",
                                backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                            }}
                            className={(this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : "") + (this.props.latestItem ? " latestItem" : "")}
                            placeholder={this.props.placeholder ? this.props.placeholder : (this.props.bubbleMsg ? this.props.bubbleMsg : "")}
                            autoFocus={this.props.autoFocus}
                            onKeyDown={this.keydownHandler}
                            onDoubleClick={this.props.hasDoubleClickEvent ? this.doubleClickHandler : null}
                            tabIndex={this.props.tabIndex}
                            data-index={this.props.rowIndex ? this.props.rowIndex : "0"}
                            autoComplete="off"
                            disabled={disabled ? disabled : (this.props.disabled ? this.props.disabled : false)}

                            onKeyPress={(e) => { this.handlerKeyPressedOnDateField(e, this) }}
                            data-field={this.props.mmYyyy ? "mm/yyyy" : ""}
                            data-help-id={this.props.itemNbr}
                            data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}
                        />
                        {
                            this.props.suffixText ? <label>{this.props.suffixText}</label> : "" //<button style={{ padding: "1px", visibility: "hidden", /*position: "absolute"*/ }} tabIndex="-1">x</button>
                        }
                    </React.Fragment>
                );
                break;
            case "textarea":
                inputElement = <textarea name={this.props.field}

                    //htmlFor={this.props.type == "textarea" && this.props.labelStyle == "TextPadLbl" ? this.props.field + "_" + this.props.labelStyle : this.props.field}
                    id={this.props.labelStyle == "TextPadLbl" ? this.props.field + "_" + this.props.labelStyle : (this.props.keyId ? this.props.keyId : this.props.field)}

                    value={value} onChange={this.props.changed}
                    onFocus={(e) => { this.focusedTextAreaField(e) }}//onFocus={this.props.focused}
                    rows={this.props.rows ? this.props.rows : 4}
                    style={{
                        width: this.props.width ? this.props.width : "100%",
                        height: this.props.height ? this.props.height : "100%",
                        resize: this.props.textPadFields || this.props.normalTaField ? "both" : "none",
                        backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                    }}
                    className={this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : "" /*(this.props.taClsNm ? this.props.taClsNm : "")*/}
                    data-maxlength={this.props.maxLength ? this.props.maxLength : ""}
                    autoFocus={this.props.autoFocus}
                    onKeyDown={this.keydownHandler}
                    tabIndex={this.props.tabIndex}
                    data-maxlen={this.props.maxlen}

                    //onDoubleClick={this.props.hasDoubleClickEvent ? this.doubleClickHandler : null}
                    onDoubleClick={this.doubleClickHandler}

                    onBlur={(e) => { this.lostfocused(e) }}//onBlur={this.props.lostfocused}
                    disabled={disabled}
                    data-help-id={this.props.itemNbr}
                    data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}

                    title={this.props.title ? this.props.title : ""}
                />;
                break;
            case "codeSelect":
                inputElement = (
                    <React.Fragment>
                        <input name={this.props.field} value={value}
                            id={this.props.keyId ? this.props.keyId : this.props.field}
                            data-table={this.props.dataSource} type="text"
                            data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}
                            data-flag={this.props.flag !== null ? this.props.flag : ""}
                            data-index={this.props.rowIndex ? this.props.rowIndex : "0"}
                            onFocus={(e) => { this.focusedTextField(e) }}//onFocus={this.props.focused}
                            style={{
                                width: this.props.width ? this.props.width : "50px",
                                height: "26px",
                                backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                            }}

                            onChange={(e) => { this.changedCodeSelectFnc(e, this.props.changed) }}//onChange={this.props.changed}

                            onBlur={(e) => { this.lostfocused(e) }}//onBlur={this.props.lostfocused}

                            //prj_v24
                            //className={this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : ""}
                            className={this.props.pathchart ? this.props.clazz : (this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : "")}

                            data-maxlength={this.props.maxLength ? this.props.maxLength : ""}
                            autoFocus={this.props.autoFocus}
                            onKeyDown={this.keydownHandler}
                            tabIndex={this.props.tabIndex}
                            data-staging-pre2018={this.props.stagingPre2018}
                            autoComplete="off"
                            disabled={disabled || this.props.disabled}
                            data-help-id={this.props.itemNbr}
                            data-help-title={this.props.titleDialog}
                            data-help-naming={this.props.helpNaming}
                            data-codelength={this.props.codeLength}
                            data-filter-by={this.props.filterBy}
                        />
                        <button disabled={disabled || this.props.disabled}
                            style={{ padding: "1px", zIndex: 1, "display": this.props.autocompleted ? "none" : "" }}
                            onClick={(e) => { this.openCodeDialog(e) }}
                            className="codeSelectIcon" tabIndex="-1">&nbsp;</button>
                    </React.Fragment>
                );
                break;
            case "codeMultiSelect":
                inputElement = (
                    <React.Fragment>
                        <input name={this.props.field} value={value}
                            id={this.props.keyId ? this.props.keyId : this.props.field}
                            data-table={this.props.dataSource} type="text"
                            data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}
                            data-flag={this.props.flag !== null ? this.props.flag : ""}
                            data-index={this.props.rowIndex ? this.props.rowIndex : "0"}
                            onFocus={(e) => { this.focusedTextField(e) }}//onFocus={this.props.focused}
                            style={{
                                width: this.props.width ? this.props.width : "50px",
                                height: "26px",
                                backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                            }}
                            onChange={this.props.changed}
                            onBlur={(e) => { this.lostfocused(e) }}//onBlur={this.props.lostfocused}
                            className={this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : ""}
                            data-maxlength={this.props.maxLength ? this.props.maxLength : ""}
                            autoFocus={this.props.autoFocus}
                            onKeyDown={this.keydownHandler}
                            tabIndex={this.props.tabIndex}
                            data-staging-pre2018={this.props.stagingPre2018}
                            autoComplete="off"
                            disabled={disabled}
                            data-help-id={this.props.itemNbr}
                            data-codelength={this.props.codeLength}
                        />
                        <button disabled={disabled} style={{ padding: "1px", zIndex: 1 }} onClick={(e) => { this.openCodeDialog(e) }} className="codeSelectIcon" tabIndex="-1">&nbsp;</button>
                    </React.Fragment>
                );
                break;

            case "codeTnmHtml":
                inputElement = (
                    <React.Fragment>
                        <input name={this.props.field} value={value}
                            id={this.props.keyId ? this.props.keyId : this.props.field}
                            data-table={this.props.dataSource} type="text"
                            data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}
                            data-flag={this.props.flag !== null ? this.props.flag : ""}
                            data-pcy={this.props.pcy !== null ? this.props.pcy : ""}
                            data-index={this.props.rowIndex ? this.props.rowIndex : "0"}
                            onFocus={(e) => { this.focusedTextField(e) }}//onFocus={this.props.focused}
                            style={{
                                width: this.props.width ? this.props.width : "50px",
                                height: "26px",
                                backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                            }}
                            onChange={this.props.changed}
                            onBlur={(e) => { this.lostfocused(e) }}//onBlur={this.props.lostfocused}
                            className={this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : ""}
                            data-maxlength={this.props.maxLength ? this.props.maxLength : ""}
                            autoFocus={this.props.autoFocus}
                            onKeyDown={this.keydownHandler}
                            tabIndex={this.props.tabIndex}
                            data-staging-pre2018={this.props.stagingPre2018}
                            autoComplete="off"
                            disabled={disabled}
                            data-help-id={this.props.itemNbr}
                        />
                        <button disabled={disabled} style={{ padding: "1px", zIndex: 1 }} onClick={(e) => { this.openCodeDialog(e) }} className="codeSelectIcon" tabIndex="-1">&nbsp;</button>
                    </React.Fragment>
                );
                break;

            case "Physician":
                inputElement = (
                    <React.Fragment>
                        <input name={this.props.field} value={value}
                            id={this.props.keyId ? this.props.keyId : this.props.field}
                            style={{
                                width: this.props.width ? this.props.width : "50px",
                                height: "26px",
                                backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                            }}
                            data-table={this.props.dataSource} type="text"
                            data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}
                            data-index={this.props.rowIndex ? this.props.rowIndex : "0"}
                            onChange={this.props.changed}
                            onFocus={(e) => { this.focusedTextField(e) }}//onFocus={this.props.focused}
                            onBlur={(e) => { this.lostfocused(e) }}//onBlur={this.props.lostfocused}
                            className={this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : ""}
                            data-maxlength={this.props.maxLength ? this.props.maxLength : ""}
                            autoFocus={this.props.autoFocus}
                            onKeyDown={this.keydownHandler}
                            tabIndex={this.props.tabIndex}
                            disabled={disabled}
                            data-help-id={this.props.itemNbr}
                        />
                        <button disabled={disabled} style={{ padding: "1px" }} onClick={(e) => { this.openCodeDialog(e) }} className="codeSelectIcon" tabIndex="-1">&nbsp;</button>
                    </React.Fragment>
                );
                break;
            case "Hospital":
                inputElement = (
                    <React.Fragment>
                        <input name={this.props.field} value={value}
                            id={this.props.keyId ? this.props.keyId : this.props.field}
                            style={{
                                width: this.props.width ? this.props.width : "50px",
                                height: "26px",
                                backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                            }}
                            data-table={this.props.dataSource} type="text"
                            data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}
                            data-index={this.props.rowIndex ? this.props.rowIndex : "0"}
                            onChange={this.props.changed}
                            onFocus={(e) => { this.focusedTextField(e) }}//onFocus={this.props.focused}
                            onBlur={(e) => { this.lostfocused(e) }}//onBlur={this.props.lostfocused}
                            className={this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : ""}
                            data-maxlength={this.props.maxLength ? this.props.maxLength : ""}
                            autoFocus={this.props.autoFocus}
                            onKeyDown={this.keydownHandler}
                            tabIndex={this.props.tabIndex}
                            disabled={disabled}
                            data-help-id={this.props.itemNbr}
                            data-codelength={this.props.codeLength}
                        />
                        <button disabled={disabled} style={{ padding: "1px" }} onClick={(e) => { this.openCodeDialog(e) }} className="codeSelectIcon" tabIndex="-1">&nbsp;</button>
                    </React.Fragment>
                );
                break;

            case "Patients":
                inputElement = (
                    <React.Fragment>
                        <input name={this.props.field} value={value}
                            id={this.props.keyId ? this.props.keyId : this.props.field}
                            style={{
                                width: this.props.width ? this.props.width : "50px",
                                height: "26px",
                                backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                            }}
                            data-table={this.props.dataSource} type="text"
                            data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}
                            data-index={this.props.rowIndex ? this.props.rowIndex : "0"}
                            onChange={this.props.changed}
                            onFocus={(e) => { this.focusedTextField(e) }}//onFocus={this.props.focused}
                            onBlur={(e) => { this.lostfocused(e) }}//onBlur={this.props.lostfocused}
                            className={this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : ""}
                            data-maxlength={this.props.maxLength ? this.props.maxLength : ""}
                            autoFocus={this.props.autoFocus}
                            onKeyDown={this.keydownHandler}
                            tabIndex={this.props.tabIndex}
                            disabled={disabled}
                            data-help-id={this.props.itemNbr}
                        />
                        <button disabled={disabled} style={{ padding: "1px" }} onClick={(e) => { this.openCodeDialog(e) }} className="codeSelectIcon" tabIndex="-1">&nbsp;</button>
                    </React.Fragment>
                );
                break;

            case "Primary_Summary":
                inputElement = (
                    <React.Fragment>
                        <input name={this.props.field} value={value}
                            id={this.props.keyId ? this.props.keyId : this.props.field}
                            style={{
                                width: this.props.width ? this.props.width : "50px",
                                height: "26px",
                                backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                            }}
                            data-table={this.props.dataSource} type="text"
                            data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}
                            data-index={this.props.rowIndex ? this.props.rowIndex : "0"}
                            onChange={this.props.changed}
                            onFocus={(e) => { this.focusedTextField(e) }}
                            onBlur={(e) => { this.lostfocused(e) }}
                            className={this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : ""}
                            data-maxlength={this.props.maxLength ? this.props.maxLength : ""}
                            autoFocus={this.props.autoFocus}
                            onKeyDown={this.keydownHandler}
                            tabIndex={this.props.tabIndex}
                            disabled={this.props.disabled}
                            data-help-id={this.props.itemNbr}
                        />
                        <button disabled={this.props.disabled} style={{ padding: "1px" }} onClick={(e) => { this.openPrmSumDialog(e) }} className="codeSelectIcon" tabIndex="-1">&nbsp;</button>
                    </React.Fragment>
                );
                break;

            case "LabelPopup":
                inputElement = (
                    <React.Fragment>
                        <input name={this.props.field} value={value}
                            id={this.props.keyId ? this.props.keyId : this.props.field}
                            style={{
                                width: this.props.width ? this.props.width : "50px",
                                height: "26px",
                                backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                            }}
                            data-table={this.props.dataSource} type="text"
                            data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}
                            data-index={this.props.rowIndex ? this.props.rowIndex : "0"}
                            onChange={this.props.changed}
                            onFocus={(e) => { this.focusedTextField(e) }}//onFocus={this.props.focused}
                            onBlur={(e) => { this.lostfocused(e) }}//onBlur={this.props.lostfocused}
                            className={this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : ""}
                            data-maxlength={this.props.maxLength ? this.props.maxLength : ""}
                            autoFocus={this.props.autoFocus}
                            onKeyDown={this.keydownHandler}
                            tabIndex={this.props.tabIndex}
                            disabled={disabled}
                            data-help-id={this.props.itemNbr}
                        />
                        <button disabled={disabled} style={{ padding: "1px" }} onClick={(e) => { this.openCodeDialog(e) }} className="codeSelectIcon" tabIndex="-1">&nbsp;</button>
                    </React.Fragment>
                );
                break;

            case "ReportLabels":
                inputElement = (
                    <React.Fragment>
                        <input name={this.props.field} value={value}
                            id={this.props.keyId ? this.props.keyId : this.props.field}
                            style={{
                                width: this.props.width ? this.props.width : "50px",
                                height: "26px",
                                backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                            }}
                            data-table={this.props.dataSource} type="text"
                            data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}
                            data-index={this.props.rowIndex ? this.props.rowIndex : "0"}
                            onChange={this.props.changed}
                            onFocus={(e) => { this.focusedTextField(e) }}//onFocus={this.props.focused}
                            onBlur={(e) => { this.lostfocused(e) }}//onBlur={this.props.lostfocused}
                            className={this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : ""}
                            data-maxLength={this.props.maxLength ? this.props.maxLength : ""}
                            autoFocus={this.props.autoFocus}
                            onKeyDown={this.keydownHandler}
                            tabIndex={this.props.tabIndex}
                            disabled={disabled}
                            data-help-id={this.props.itemNbr}
                        />
                        <button disabled={disabled} style={{ padding: "1px" }} onClick={(e) => { this.openCodeDialog(e) }} className="codeSelectIcon" tabIndex="-1">&nbsp;</button>
                    </React.Fragment>
                );
                break;

            case "datePicker":// Not available in current system
                inputElement = <DatePicker name={this.props.field}
                    id={this.props.keyId ? this.props.keyId : this.props.field}
                    value={value !== "" && value !== undefined ? new Date(value) : ""}
                    onFocus={this.props.focused} onChange={this.props.changed}
                    onBlur={(e) => { this.lostfocused(e) }}//onBlur={this.props.lostfocused}
                    width={this.props.width} type="text"
                    className={this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : ""}
                    autoFocus={this.props.autoFocus}
                    onKeyDown={this.keydownHandler}
                    tabIndex={this.props.tabIndex}
                    disabled={disabled}
                    data-help-id={this.props.itemNbr}
                    data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}
                />;
                break;

            case "date":
                inputElement = (
                    <React.Fragment>
                        <input
                            onFocus={(e) => { this.focusedTextField(e) }}//onFocus={this.props.focused}
                            id={this.props.keyId ? this.props.keyId : this.props.field + "Month" + (this.props.rowIndex ? this.props.rowIndex : "0")}
                            name={this.props.field + "Month"} value={monthVal}
                            onChange={this.props.changed} type="text"
                            onBlur={(e) => { this.handlerLostFocusEventOnDateField(e, this) }}//onBlur={this.props.lostfocused}
                            onKeyPress={(e) => { this.handlerKeyPressedOnDateField(e, this) }}
                            style={{
                                width: this.props.monthWidth ? this.props.monthWidth : "31px",
                                maxWidth: "31px",
                                height: "26px",
                                backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                            }}
                            data-isdatefield="month"
                            data-field={this.props.field}
                            className={this.props.inValidMonth === "IN_VALID" || this.state.failedData ? "error" : ""}
                            maxLength="2" placeholder={this.props.placeholdMM ? this.props.placeholdMM : "mm"}
                            autoFocus={this.props.autoFocus}
                            onKeyDown={this.keydownHandler}
                            tabIndex={this.props.tabIndex}
                            data-index={this.props.rowIndex ? this.props.rowIndex : "0"}
                            disabled={disabled || this.props.disabled}
                            data-help-id={this.props.itemNbr}
                            data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}
                        />&nbsp;/&nbsp;<input
                            onFocus={(e) => { this.focusedTextField(e) }}//onFocus={this.props.focused}
                            id={this.props.keyId ? this.props.keyId : this.props.field + "Day" + (this.props.rowIndex ? this.props.rowIndex : "0")}
                            name={this.props.field + "Day"} value={dayVal}
                            onChange={this.props.changed} type="text"
                            onBlur={(e) => { this.handlerLostFocusEventOnDateField(e, this) }}//onBlur={this.props.lostfocused}
                            onKeyPress={(e) => { this.handlerKeyPressedOnDateField(e, this) }}
                            style={{
                                width: this.props.dayWidth ? this.props.dayWidth : "31px",
                                maxWidth: "31px",
                                height: "26px",
                                backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                            }}
                            data-isdatefield="day"
                            data-field={this.props.field}
                            className={this.props.inValidDay === "IN_VALID" || this.state.failedData ? "error" : ""}
                            maxLength="2" placeholder={this.props.placeholdDD ? this.props.placeholdDD : "dd"}
                            onKeyDown={this.keydownHandler}
                            tabIndex={this.props.tabIndex}
                            data-index={this.props.rowIndex ? this.props.rowIndex : "0"}
                            disabled={disabled || this.props.disabled}
                            data-help-id={this.props.itemNbr}
                            data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}
                        />&nbsp;/&nbsp;<input type="text"
                            onFocus={(e) => { this.focusedTextField(e) }}//onFocus={this.props.focused}
                            id={this.props.keyId ? this.props.keyId : this.props.field + "Year" + (this.props.rowIndex ? this.props.rowIndex : "0")}
                            name={this.props.field + "Year"} value={yearVal}

                            onChange={this.props.changed}

                            onBlur={(e) => { this.handlerLostFocusEventOnDateField(e, this) }}
                            //onBlur={(e) => { this.lostfocused(e) }}//onBlur={this.props.lostfocused}

                            onKeyPress={(e) => { this.handlerKeyPressedOnDateField(e, this) }}
                            style={{
                                width: this.props.yearWidth ? this.props.yearWidth : "41px",
                                maxWidth: "41px",
                                height: "26px",
                                backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                            }}
                            data-isdatefield="year"
                            data-field={this.props.field}
                            className={this.props.inValidYear === "IN_VALID" || this.state.failedData ? "error" : ""}
                            maxLength="4" placeholder={this.props.placeholdYYYY ? this.props.placeholdYYYY : "yyyy"}
                            onKeyDown={this.keydownHandler}
                            tabIndex={this.props.tabIndex}
                            data-index={this.props.rowIndex ? this.props.rowIndex : "0"}
                            disabled={disabled || this.props.disabled}
                            data-help-id={this.props.itemNbr}
                            data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}
                        />
                        <input name={this.props.field} data-type="combineDate" value={value} type="hidden" style={{ width: "0px" }} />
                    </React.Fragment>
                );
                break;

            case "password":
                inputElement = (
                    <React.Fragment>
                        <input
                            onFocus={(e) => { this.focusedTextField(e) }}//onFocus={this.props.focused}
                            id={this.props.keyId ? this.props.keyId : this.props.field}
                            name={this.props.field} value={value}
                            onChange={this.props.changed} type={this.props.type}
                            style={{
                                width: this.props.width ? this.props.width : "",
                                height: "26px",
                                backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                            }}
                            onClick={this.props.clicked}
                            checked={this.props.checked ? true : false}
                            className={this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : ""}
                            autoFocus={this.props.autoFocus}
                            onKeyDown={this.keydownHandler}
                            onBlur={(e) => { this.lostfocused(e) }}//onBlur={this.props.lostfocused}
                            tabIndex={this.props.tabIndex}
                            disabled={disabled}
                            data-help-id={this.props.itemNbr}
                            data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}
                        />
                        {/*<button style={{ padding: "1px", visibility: "hidden" }} tabIndex="-1">x</button>*/}
                    </React.Fragment>
                );
                break;

            case "file":
                inputElement = <div>
                    <input type={this.props.type}
                        id={this.props.keyId ? this.props.keyId : this.props.field}
                        name={this.props.field} value={value}
                        onChange={this.props.changed}//onChange={this.onFileChange}
                        style={{
                            width: this.props.width ? this.props.width : "",
                            backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                        }}
                        onClick={this.props.clicked}
                        checked={this.props.checked}
                        className={this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : ""}
                        autoFocus={this.props.autoFocus} onFocus={this.props.focused}
                        onKeyDown={this.keydownHandler}
                        tabIndex={this.props.tabIndex}
                        disabled={disabled || this.props.disabled}
                        data-help-id={this.props.itemNbr}
                        data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}
                        title={this.props.title ? this.props.title : ""}
                        placeholder="Click button to select file..."
                    />
                </div>;
                break;

            // case "radio", "checkbox":
            default:
                inputElement = <input onFocus={this.props.focused}
                    id={this.props.keyId ? this.props.keyId : this.props.field}
                    name={this.props.field} value={value}
                    onChange={this.props.changed} type={this.props.type}
                    style={{
                        width: this.props.width ? this.props.width : "",
                        backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && !this.props.ignoreRapid ? "#E6E6E6" : ""
                    }}
                    onClick={this.props.clicked}
                    checked={this.props.checked}
                    //defaultChecked={this.props.checked}
                    className={this.props.inValid === "IN_VALID" || this.state.failedData ? "error" : ""}
                    autoFocus={this.props.autoFocus}
                    onKeyDown={this.keydownHandler}
                    tabIndex={this.props.tabIndex}
                    disabled={disabled || this.props.disabled}
                    data-help-id={this.props.itemNbr}
                    data-key={this.props.aliasNo !== null ? this.props.aliasNo : ""}
                    title={this.props.title ? this.props.title : ""}

                    onMouseOver={this.props.mouseOver}
                    onMouseOut={this.props.mouseOut}
                />;
                break;
        }

        //---
        let _styleAttr = {};
        if (this.props.shownType == "dropdown") {
            _styleAttr = {
                left: this.state.iLeftPos ? this.state.iLeftPos : "auto",
                top: this.state.iTopPos ? this.state.iTopPos : "auto",
                maxWidth: "350px !important",//this.state.iWidthLen ? this.state.iWidthLen : "auto",
                minWidth: this.state.iWidthLen ? this.state.iWidthLen : "auto",
            };
        }

        let primarySummary;
        {
            primarySummary = this.state.isPrmSumDialogVisible ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls ErsGrBlueDDWinBody" style={{ _styleAttr }}>
                            <div className="k-window-titlebar k-dialog-titlebar k-header hidden">
                                <div className="k-window-title k-dialog-title hidden"></div>
                                <div className="k-window-actions k-dialog-actions hidden">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.closePrmSumDialog(e) }}></span>
                                    </a>
                                </div>
                            </div>

                            <table className="container primarySummaryDialogCls">
                                <thead>
                                    <tr className="ErsGrBlueGridHeader">
                                        <th style={{ width: "43px" }}>Abst/Susp Flag</th>
                                        <th style={{ width: "47px" }}>Site Code</th>
                                        <th style={{ width: "32px" }}>Seq Prim</th>
                                        <th style={{ width: "32px" }}>Hosp</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.primaries !== null && this.state.primaries.length > 0 ? this.state.primaries.map((psItem, _idx) => (
                                        <tr key={"data-key-" + _idx} onClick={(e) => { this.setPrmSumData(e, _idx) }}>
                                            <td>{psItem["abstSunaFlag"]}</td>
                                            <td>{psItem["siteCode"]}</td>
                                            <td>{psItem["seqPrim"]}</td>
                                            <td>{psItem["hosp"]}</td>
                                        </tr>
                                    )) : <tr><td colSpan="4">No records in query</td></tr>
                                    }
                                </tbody>
                            </table>

                            <div className="emptyLine"></div>

                            <div className="container center">
                                <button className="ErsGrBlueButton" onClick={this.closePrmSumDialog} value="yes" autoFocus>Cancel</button>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        return (
            <React.Fragment>
                <React.Fragment>
                    {primarySummary}
                </React.Fragment>

                <React.Fragment>
                    {validationError}
                </React.Fragment>

                <React.Fragment>
                    {textEditorPopup}
                </React.Fragment>

                <React.Fragment>
                    {confirmationMsg}
                </React.Fragment>

                <div className={this.props.lineStyle ? this.props.lineStyle : ""}>
                    <label
                        id={"LBL-" + (this.props.keyId ? this.props.keyId : this.props.field)}
                        style={{
                            width: this.props.labelWidth ? this.props.labelWidth : "100px",//(this.props.labelText !== "" ? "100px" : "0px"),
                            color: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && this.props.labelText !== "" && !this.props.ignoreRapid ? "#A5A5A5" : this.state.lblColor,
                            backgroundColor: this.props.rapidAbstract && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && !this.state.isRapidAbstract && this.props.labelText !== "" && !this.props.ignoreRapid ? "#E6E6E6" : (this.props.labelText !== "" ? this.state.bgColor : ""),
                            marginBottom: this.props.type === "gridHeader" ? "inherit" : ""
                        }}
                        className={"lblGeneralCls " + (this.props.labelStyle ? this.props.labelStyle : "") + (this.props.type == "textarea" ? " lblOfTextAreaCls" : "")}

                        htmlFor={this.props.type == "textarea" && this.props.labelStyle == "TextPadLbl" ? this.props.field + "_" + this.props.labelStyle : this.props.field}
                        //htmlFor={this.props.keyId ? this.props.keyId : this.props.field}

                        dangerouslySetInnerHTML={{
                            __html: (this.props.labelText === "" || this.props.labelText === "undefined" ? "" : this.props.labelText) + (this.props.type === "gridHeader" || this.props.type === "checkbox" || this.props.type === "radio" || this.props.labelText === "" || this.props.labelText === "undefined" ? "" : ":")
                            //__html: (this.state.labelText === "" || this.state.labelText === "undefined" ? "" : this.state.labelText) + (this.props.type === "gridHeader" || this.props.type === "checkbox" || this.props.type === "radio" || this.props.labelText === "" || this.props.labelText === "undefined" ? "" : ":")
                        }}

                        title={this.props.title && this.props.type == "checkbox" ? this.props.title : ""}
                        onMouseOver={this.props.mouseOver}
                        onMouseOut={this.props.mouseOut}
                    ></label>

                    {this.props.noSpaceChar ? "" : " "}{inputElement}
                    {
                        this.props.isRequired ? <span style={{ color: "red", fontWeight: "bolder" }}>&nbsp;*</span> : null
                    }

                    {this.props.noBrTag ? "" : <br />}

                    {this.props.type === "codeSelect" && this.state.isDialogVisible
                        && (this.props.shownType != "dropdown" //Comment out this line if you want to show dropdown when arrow key has clicked
                            || ((this.props.rowIndex && (this.props.focusingFieldInGlobalStore + this.props.rowIndex) == this.props.keyId) || this.props.focusingFieldInGlobalStore == this.props.field || this.props.focusingFieldInGlobalStore == this.props.keyId)
                        ) &&
                        <CommonLookup field={this.props.field} closeCode={this.closeCodeDialog}
                            setAllRecord={this.setAllRecord}
                            setCode={this.setCode} dataSource={this.props.dataSource} aliasNo={this.props.aliasNo}
                            flag={this.props.flag} titleDialog={this.props.titleDialog}
                            pcy={this.props.pcy} pc2={this.props.pc2}
                            topoCode={this.props.topoCode} histCode={this.props.histCode} primKey={this.props.primKey}
                            stagingPre2018={this.props.stagingPre2018}
                            multiSelection={this.props.isMultiSelection}
                            values={this.props.value}
                            setNameAlias={this.props.setNameAlias ? this.props.setNameAlias : null}
                            shownType={this.props.shownType}
                            iLeftPos={this.state.iLeftPos}
                            iTopPos={this.state.iTopPos}
                            iWidthLen={this.state.iWidthLen}
                            research={this.state.research}
                            stopResearch={this.stopResearchFnc}
                            displayingCodeOnly={this.props.displayingCodeOnly}
                            limitByWidth={this.props.limitByWidth} hideFilter={this.props.hideFilter}
                            codeLength={this.props.codeLength}
                            codeLabel={this.props.codeLabel}
                            descriptionLabel={this.props.descriptionLabel}
                            schemaId={this.props.schemaId} t={this.props.t} n={this.props.n} m={this.props.m}
                            options={this.props.options}
                            autocompleted={this.props.autocompleted}
                            title1={this.props.title1}
                            acceptedDataSource={this.props.acceptedDataSource}
                            searchByKey={this.state.searchByKey}
                            filterBy={this.props.filterBy}
                        />}
                    {this.props.type === "codeMultiSelect" && this.state.isDialogVisible && <CommonLookup2 field={this.props.field} closeCode={this.closeCodeDialog} setCode={this.setCode} dataSource={this.props.dataSource} aliasNo={this.props.aliasNo} flag={this.props.flag} titleDialog={this.props.titleDialog} pcy={this.props.pcy} pc2={this.props.pc2} topoCode={this.props.topoCode} histCode={this.props.histCode} primKey={this.props.primKey} stagingPre2018={this.props.stagingPre2018} multiSelection={this.props.isMultiSelection} values={this.props.value} codeLength={this.props.codeLength} />}
                    {this.props.type === "codeTnmHtml" && this.state.isDialogVisible && <CommonLookup3 field={this.props.field} closeCode={this.closeCodeDialog} setCode={this.setCode} dataSource={this.props.dataSource} aliasNo={this.props.aliasNo} flag={this.props.flag} titleDialog={this.props.titleDialog} pcy={this.props.pcy} pc2={this.props.pc2} topoCode={this.props.topoCode} histCode={this.props.histCode} primKey={this.props.primKey} stagingPre2018={this.props.stagingPre2018} />}
                    {this.props.type === "Physician" && this.state.isDialogVisible && <Physician field={this.props.field} closeCode={this.closeCodeDialog} setCode={this.setCode} dataSource={this.props.dataSource} aliasNo={this.props.aliasNo} addNew={this.props.addNew} titleDialog={this.props.titleDialog} />}

                    {this.props.type === "Hospital" && this.state.isDialogVisible &&
                        <Hospital field={this.props.field} closeCode={this.closeCodeDialog}
                            setCode={this.setCode} dataSource={this.props.dataSource} aliasNo={this.props.aliasNo}
                            addNew={this.props.addNew} titleDialog={this.props.titleDialog}
                            codeLength={this.props.codeLength}
                            limitedByHospNbr={this.props.hospNbr} shownType={this.props.shownType}
                            iLeftPos={this.state.iLeftPos}
                            iTopPos={this.state.iTopPos}
                        />}

                    {this.props.type === "LabelPopup" && this.state.isDialogVisible && <LabelPopup field={this.props.field} closeCode={this.closeCodeDialog} setCode={this.setCode} dataSource={this.props.dataSource} aliasNo={this.props.aliasNo} titleDialog={this.props.titleDialog} />}
                    {this.props.type === "ReportLabels" && this.state.isDialogVisible && <ReportLabels field={this.props.field} closeCode={this.closeCodeDialog} setCode={this.setCode} dataSource={this.props.dataSource} aliasNo={this.props.aliasNo} titleDialog={this.props.titleDialog} />}
                    {this.props.type === "Patient" && this.state.isDialogVisible && <Patient field={this.props.field} closeCode={this.closeCodeDialog} setCode={this.setCode} titleDialog={this.props.titleDialog} />}
                </div>
            </React.Fragment>
        );
    }
}

Input.propTypes = {
    type: PropTypes.string.isRequired,
    //labelText: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    //changed: PropTypes.func.isRequired,
    //focused: PropTypes.func.isRequired
    //dataSource: PropTypes.string.isRequired
}

const mapStateToProps = state => {
    return {
        commonColors: state.patientAbstract.commonColors,
        childrenOpening: state.patientAbstract.childrenOpening,

        alphaUrl: window.location ? window.location.pathname : "",
        inquiringMode: state.auth.inquiringMode,
        rapidAbstract: state.patientAbstract.rapidAbstract,
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        focusingFieldInGlobalStore: state.patientAbstract.focusingFieldInGlobalStore,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        latestFocusedField: (fId) => dispatch(actionCreators.latestFocusedField(fId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Input);