/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import "./SidebarOnRight.css";
import WebLinks from "./QuickLinks/WebLinks";
//import Ssdi from "./QuickLinks/Ssdi";

//import PrintPatientAbstract from "./QuickLinks/PrintPatientAbstract";
import PrintPatientAbstract from '../../components/AbstractManagement/PrintForms/PrintAbstractsMaint';

//import RunGenEdits from "./QuickLinks/RunGenEdits";
import GeneditsCheck from "./QuickLinks/GeneditsCheck";
import PrintDemandLetters from "./QuickLinks/PrintDemandLetters";
import axios from "../../axios-instance";
import { TabContent, TabPane, Nav, NavLink } from "reactstrap";
import classnames from "classnames";
import { actionCreators } from "../../store/PatientAbstract";

class QuickLinks extends Component {
    state = {
        sidebarOpen: false,
        // all windows
        showWebLinks: false,
        showSsdi: false,
        showPrintPatientAbstract: false,
        showRunGenEdits: false,
        showPrintDemandLetters: false,
        activeManualTab: "1",
    };
    handleViewSidebar = () =>
        this.setState({ sidebarOpen: !this.state.sidebarOpen });

    launchSSID() {
        var pid = this.props.patientId;//this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.pid : "0";
        if (pid === null || pid === undefined || pid === "0" || pid === "") return false;
        var ssNum = "";
        //var link = "http://www.genealogybank.com/gbnk/ssdi/?lname=&fname=&minit=&birthy_0=&birthy_1=&deathy_0=&deathy_1=&citylast=&cntylast=&statelast=&ziplast=&nonusloc=&ssstate=&ssnum=" + ssNum + "&group=";
        var link = "http://www.genealogybank.com/gbnk/ssdi/?lname=&fname=&minit=&birthy_0=&birthy_1=&deathy_0=&deathy_1=&citylast=&cntylast=&statelast=&ziplast=&nonusloc=&ssstate=&group=";

        var ql = this;

        //axios.get(this.props.clientgroup + "/GetPatient?patientId=" + pid)
        let url = this.props.clientgroup + "/GetPatient";
        let params = { patientId: pid, accessToken: localStorage.getItem('token') };

        axios.post( url, params )
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    //TRANSFORM(remspecial(ssNum), "@R 999-99-9999")
                    ssNum = res.data.patient && res.data.patient.socSecNbr !== null ? res.data.patient.socSecNbr : "";
                }

                // Remove dashes from SSN #A1720
                // if (ssNum != "") {
                     ssNum = ssNum.replace(/-/g, '');
                //     if (ssNum.length >= 4 && ssNum.length <= 5) {
                //         ssNum = ssNum.substr(0, 3) + "-" + ssNum.substr(3, 2);
                //     } else {
                //         ssNum = ssNum.substr(0, 3) + "-" + ssNum.substr(3, 2) + "-" + ssNum.substr(5, 4);
                //     }
                // }

                link += "&ssnum=" + ssNum;

                ql.openPopup(link, '', 1000, 800);
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;

            });

    }

    openPopup(url, title, w, h) {
        var left = (window.width / 2) - (w / 2);
        var top = (window.height / 2) - (h / 2);
        return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
    }

    openWebLinks = (event) => {
        let link = event.target.parentNode.getAttribute("title");
        switch (link) {
            case "Web Links":
                this.setState({ showWebLinks: true });
                break;
            case "SSDI":
                this.setState({ showSsdi: true });
                this.launchSSID();//#
                break;
            case "Print Patient Abstract":
                this.setState({ showPrintPatientAbstract: true });

                this.props.loadPatientAbsInfo(0);
                //this.props.setInitialPageVisible(true);
                //this.props.setAlphaUrl("/PrintForms");

                break;
            case "Run GenEdits":
                this.setState({ showRunGenEdits: true });
                break;
            case "Print Demand Letters":
                this.setState({ showPrintDemandLetters: true });
                break;
        }
    }

    hideWindows = (link) => {
        switch (link) {
            case "Web Links":
                this.setState({ showWebLinks: false });
                break;
            case "SSDI":
                this.setState({ showSsdi: false });
                break;
            case "Print Patient Abstract":
                this.setState({ showPrintPatientAbstract: false });
                break;
            case "Run GenEdits":
                this.setState({ showRunGenEdits: false });
                break;
            case "Print Demand Letters":
                this.setState({ showPrintDemandLetters: false });
                break;
        }
        return false;
    }

    init() {
        setInterval(function () {
            if (!document.getElementById("Definition_Contains_Fn_ajcc_id_name")) {
                if (document.getElementById("Ajcc_Manual_Content")) {
                    var content = document.getElementById("Ajcc_Manual_Content");
                    content.setAttribute("class", "hidden");
                }
            } else {
                if (document.getElementById("Ajcc_Manual_Content")) {
                    var content = document.getElementById("Ajcc_Manual_Content");
                    content.setAttribute("class", "");
                }
            }
        }, 1000);
    }

    componentDidMount() {
        this.init();
    }

    toggleManualTab = (event, tab) => {
        this.setState({ activeManualTab: tab });
        return false;
    }

    render() {

        let webLinks = null;
        {
            webLinks = this.state.showWebLinks ? <WebLinks closeWindow={e => { this.hideWindows("Web Links") }} /> : null
        }

        let printPatientAbstract = null;
        {
            printPatientAbstract = this.state.showPrintPatientAbstract ?
                <PrintPatientAbstract closeWindow={e => { this.hideWindows("Print Patient Abstract") }} alphaUrl="/PrintAbstractsMaint" onRightMenu={true} />
                : null
        }

        let runGenEdits = null;
        {
            runGenEdits = this.state.showRunGenEdits ?
                <GeneditsCheck closeWindow={e => { this.hideWindows("Run GenEdits") }} />//<RunGenEdits closeWindow={e => { this.hideWindows("Run GenEdits") }} alphaUrl="/QuickLinksOnPanel" />
                : null
        }

        let printDemandLetters = null;
        {
            printDemandLetters = this.state.showPrintDemandLetters ?
                <PrintDemandLetters closeWindow={e => { this.hideWindows("Print Demand Letters") }} alphaUrl="/GenDemandLetters" />
                : null
        }

        return (
            <div id="quick_links_menu" style={{ display: (this.props.alphaUrl == "/patient-abstract" || this.props.alphaUrl == "/rapid-abstract") ? "" : "none" }}>
                <div id="RIGHTPANEL_QUICKLINKS_MENU" className="RightPanelQuickLinks">
                    <div className="A5CWLayout">
                        <button type="button" onClick={(e) => { this.openWebLinks(e) }} className="QuickLinksButton" title="Web Links">
                            <img id="PATIENTMAINT_QUICKLINKS_ZUIJ.V.R1.BUTTON_6.ICON" src="https://crstar.ers-can.com/WebLinks.png" />
                        </button>
                    </div>
                    <div className="A5CWLayout">
                        <button type="button" onClick={(e) => { this.openWebLinks(e) }} className="QuickLinksButton" title="SSDI">
                            <img id="PATIENTMAINT_QUICKLINKS_ZUIJ.V.R1.BUTTON_2.ICON" src="https://crstar.ers-can.com/SSDI.png" />
                        </button>
                    </div>
                    <div className="A5CWLayout">
                        <button type="button" onClick={(e) => { this.openWebLinks(e) }} className="QuickLinksButton" title="Print Patient Abstract">
                            <img id="PATIENTMAINT_QUICKLINKS_ZUIJ.V.R1.BUTTON_4.ICON" src="https://crstar.ers-can.com/PrintAbstract.png" />
                        </button>
                    </div>
                    <div className="A5CWLayout">
                        <button type="button" onClick={(e) => { this.openWebLinks(e) }} className="QuickLinksButton QuickLinksButtonDisabled" title="Run GenEdits" disabled={ this.props.selectedPrimarySummary && this.props.selectedPrimarySummary.cid != "" ? false : true }>
                            <img id="PATIENTMAINT_QUICKLINKS_ZUIJ.V.R1.BUTTON_5.ICON" src="https://crstar.ers-can.com/EditCheck.png" />
                        </button>
                    </div>
                    <div className="A5CWLayout">
                        <button type="button" onClick={(e) => { this.openWebLinks(e) }} className="QuickLinksButton QuickLinksButtonDisabled" title="Print Demand Letters" disabled={this.props.selectedPrimarySummary && this.props.selectedPrimarySummary.cid != "" ? false : true}>
                            <img id="PATIENTMAINT_QUICKLINKS_ZUIJ.V.R1.BUTTON_8.ICON" src="https://crstar.ers-can.com/DL.png" />
                        </button>
                    </div>
                </div>

                {/* PR00530: AJCC Manual Functionality */}
                <div id="Ajcc_Manual_Content" className="hidden">
                    <Nav tabs tabIndex={0} autoFocus name="navTabOnAjccManualContent">
                        <NavLink className={classnames({ active: this.state.activeManualTab === "1" })} onClick={(event) => { this.toggleManualTab(event, "1") }} href="#" id="linkOfClinicalManualTab">
                            Clinical</NavLink>
                        <NavLink className={classnames({ active: this.state.activeManualTab === "2" })} onClick={(event) => { this.toggleManualTab(event, "2") }} href="#" id="linkOfPathologicalManualTab">
                            Pathological</NavLink>
                        <NavLink className={classnames({ active: this.state.activeManualTab === "3" })} onClick={(event) => { this.toggleManualTab(event, "3") }} href="#" id="linkOfPrognosticFactorsManualTab">
                            Prognostic Factors</NavLink>
                        <NavLink className={classnames({ active: this.state.activeManualTab === "4" })} onClick={(event) => { this.toggleManualTab(event, "4") }} href="#" id="linkOfDefinitionsManualTab">
                            Definitions</NavLink>
                    </Nav>
                    <TabContent activeTab={this.state.activeManualTab} >
                        <TabPane tabId="1">
                            <div className="row">
                                <div className="col-sm-12" id="tabClinical">
                                    <div className="frameInner">
                                        <div className="header Chapter_Title_Alias"></div>

                                        <div className="emptyLine"></div>

                                        <div className="row">
                                            <div className="col-sm-12" id="tabClinicalContent">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className="row">
                                <div className="col-sm-12" id="tabPathological">
                                    <div className="frameInner">
                                        <div className="header Chapter_Title_Alias"></div>

                                        <div className="emptyLine"></div>

                                        <div className="row">
                                            <div className="col-sm-12" id="tabPathologicalContent">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="3">
                            <div className="row">
                                <div className="col-sm-12" id="tabPrognosticFactors">
                                    <div className="frameInner">
                                        <div className="header Chapter_Title_Alias"></div>

                                        <div className="emptyLine"></div>

                                        <div className="row">
                                            <div className="col-sm-12" id="tabPrognosticFactorsContent">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="4">
                            <div className="row">
                                <div className="col-sm-12" id="tabDefinitions">
                                    <div className="frameInner">
                                        <div className="header Chapter_Title_Alias"></div>

                                        <div className="emptyLine"></div>

                                        <div className="row">
                                            <div className="col-sm-12" id="tabDefinitionsContent">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                    </TabContent>
                </div>

                <div>{webLinks}</div>

                <div>{printPatientAbstract}</div>

                <div>{runGenEdits}</div>

                <div>{printDemandLetters}</div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
        patientId: state.patientAbstract.patientId,
        clientgroup: state.auth.clientgroup,
        alphaUrl: window.location ? window.location.pathname : "",
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadPatientAbsInfo: (pid) => dispatch(actionCreators.loadPatientAbsInfo(pid)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(QuickLinks);
