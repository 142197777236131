/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import "./UserMaintenance.css";
import Footer from "../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
import "../../../components/AbstractManagement/PatientAbstract/TreatmentMaintenance/TreatmentMaintenance.css";
import Help from "../../UI/Help";
import * as dialogTypes from "../../AbstractManagement/maintDialogs";
//import { Dialog } from "@progress/kendo-react-dialogs";
import Draggable from 'react-draggable';

class PasswordPolicyMaintenance extends Component {

    state = {
        uAllItemFields: null,
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        fieldInFocus: "attemptslimit",
        isHelpVisible: false,
        frequency2fa: "",
        enable2fa: false,
        isSaving: false,

        showingPatternBuilder: false,
        showingTestBuilder: false,
        showingTestResult: false,
        testMessage: null,

        patternForm: {
            minChar: "",
            maxChar: "",
            reqSpec: false,
            specChar: "",
            reqCap: false,
            reqLow: false,
            reqNbr: false,
            patternInput: ""
        },
        patternTest: "",
    }

    buildPattern = (event) => {
        //event.preventDefault();

        let _patternForm = this.state.patternForm;
        _patternForm = {
            ...this.state.patternForm,
            [event.target.name]: (event.target.type === "checkbox" ? event.target.checked : event.target.value),
        }

        const minChar = _patternForm.minChar;
        const maxChar = _patternForm.maxChar;
        const reqSpec = _patternForm.reqSpec;
        const specChar = _patternForm.specChar;
        const reqCap = _patternForm.reqCap;
        const reqLow = _patternForm.reqLow;
        const reqNbr = _patternForm.reqNbr;

        //var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        let pattern = "^";
        if (reqCap === "1" || reqCap === true) {
            pattern = pattern + "(?=.*[A-Z])";
        }

        if (reqLow === "1" || reqLow === true) {
            pattern = pattern + "(?=.*[a-z])";
        }

        if (reqNbr === "1" || reqNbr === true) {
            pattern = pattern + "(?=.*[0-9])";
        }

        if (reqSpec === "1" || reqSpec === true) {
            pattern = pattern + "(?=.*[" + specChar + "])";
        }

        if (minChar !== "" || maxChar !== "") {
            pattern = pattern + "(?=.{" + minChar + "," + maxChar + "})";
        }

        //https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
        if (pattern === "^") {
            pattern = "";
        } else {
            pattern = pattern;
        }

        _patternForm["patternInput"] = pattern;

        this.setState({
            patternForm: _patternForm
        });

        console.log(_patternForm);

        //return false;
    }

    testPatternBuilder = (event) => {
        event.preventDefault();

        const patternTest = this.state.patternTest;
        var confirmMsg = "";

        var passValidation = this.state.dialogForm.passValidation;
        var passValidationMsg = this.state.dialogForm.passValidationMsg;

        const regex = new RegExp(passValidation, 'g');
        //var combineRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        let m;

        if ((m = regex.exec(patternTest)) !== null) {
            // The result can be accessed through the `m`-variable.
            m.forEach((match, groupIndex) => {
                console.log(`Found match, group ${groupIndex}: ${match}`);
            });
            confirmMsg = "Password Valid";
        } else {
            confirmMsg = passValidationMsg;
        }

        this.setState({
            showingTestBuilder: false,
            showingTestResult: true,
            testMessage: confirmMsg
        });

        return false;
    }

    showPatternBuilder = (event) => {
        event.preventDefault();

        let pattern = {};
        let onlySpec = true;

        const passValidation = this.state.dialogForm.passValidation ? this.state.dialogForm.passValidation : "";
        let specCharStr = this.state.dialogForm.passValidation ? this.state.dialogForm.passValidation.substr(1) : "";

        pattern["patternInput"] = passValidation;

        // reqCap
        if (passValidation.indexOf("(?=.*[A-Z])") >= 0) {
            pattern["reqCap"] = true;
            specCharStr = specCharStr.replace("(?=.*[A-Z])", "");
            onlySpec = false;
        } else {
            pattern["reqCap"] = false;
        }

        // reqLow
        if (passValidation.indexOf("(?=.*[a-z])") >= 0) {
            pattern["reqLow"] = true;
            specCharStr = specCharStr.replace("(?=.*[a-z])", "");
            onlySpec = false;
        } else {
            pattern["reqLow"] = false;
        }

        // reqNbr
        if (passValidation.indexOf("(?=.*[0-9])") >= 0) {
            pattern["reqNbr"] = true;
            specCharStr = specCharStr.replace("(?=.*[0-9])", "");
            onlySpec = false;
        } else {
            pattern["reqNbr"] = false;
        }

        // specChar
        console.log(`specCharStr= ${specCharStr}`);//specCharStr= (?=.*[@$!%*?&])(?=.{8,20})
        if (specCharStr.indexOf("(?=.*[") >= 0) {
            pattern["reqSpec"] = true;
        } else {
            pattern["reqSpec"] = false;
        }

        if (!onlySpec && pattern["reqSpec"]) {
            pattern["specChar"] = specCharStr.replace("(?=.*[", "").split("])")[0];
        } else {
            pattern["specChar"] = "";
        }

        // minChar
        if (passValidation.indexOf("(?=.{") >= 0) {
            pattern["minChar"] = passValidation.split("{")[1].split(",")[0];
        } else {
            pattern["minChar"] = "";
        }

        // maxChar
        if (passValidation.indexOf("(?=.*[a-z])") >= 0) {
            pattern["maxChar"] = passValidation.split("{")[1].split(",")[1].replace("})", "");
        } else {
            pattern["maxChar"] = "";
        }

        this.setState({
            showingPatternBuilder: true,
            patternForm: pattern
        });

        return false;
    }

    hidePatternBuilder = (event) => {
        try {
            event.preventDefault();
        } catch (ex) { }

        this.setState({ showingPatternBuilder: false });

        //btnP2MEzBuilder
        setTimeout(function () {
            document.getElementById("btnP2MEzBuilder") ? document.getElementById("btnP2MEzBuilder").focus() : "";
        }, 100);

        return false;
    }
    savePatternBuilder = (event) => {
        event.preventDefault();

        let _patternForm = this.state.patternForm;
        var passValidation = _patternForm.patternInput;
        if (passValidation.indexOf('{') !== -1) {
            var res = passValidation.split('{');
            var lengthString = res[1].split('}');
            var minLen = lengthString[0].split(',')[0];
            var errMsg = "";
            if (minLen < 8) {
                errMsg = 'Minimum length must be 8 or greater.';
            }
            if (errMsg !== "") {
                this.setState({
                    showingTestResult: true,
                    testMessage: errMsg
                });
            }
        }

        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                ["passValidation"]: _patternForm.patternInput
            },
            //patternForm: _patternForm,
            isFormDirty: true,
            showingPatternBuilder: false
        });
        return false;
    }

    showTestBuilder = (event) => {
        event.preventDefault();
        this.setState({ showingTestBuilder: true, patternTest : "" });
        return false;
    }
    hideTestBuilder = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showingTestBuilder: false });

        //btnP2MTestPwd
        setTimeout(function () {
            document.getElementById("btnP2MTestPwd") ? document.getElementById("btnP2MTestPwd").focus() : "";
        }, 100);

        return false;
    }
    hideTestResult = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showingTestResult: false });

        //btnP2MTestPwd
        setTimeout(function () {
            document.getElementById("btnP2MTestPwd") ? document.getElementById("btnP2MTestPwd").focus() : "";
        }, 100);

        return false;
    }

    handleTestPassChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        return false;
    }

    componentDidUnMount() {
        this.setState({ reload: false });
    }

    componentDidMount() {
        if (!this.state.dialogForm && this.props.selectedPwdPolicyInfo) {
            this.setFormData();
        }
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.dialogForm && this.props.selectedPwdPolicyInfo)
            || (prevProps.selectedPwdPolicyInfo && this.props.selectedPwdPolicyInfo && prevProps.selectedPwdPolicyInfo.clientGroup !== this.props.selectedPwdPolicyInfo.clientGroup)
            || (this.props.selectedPwdPolicyInfo && this.props.selectedPwdPolicyInfo.clientGroup !== this.state.dialogForm.clientGroup)
            || (!prevProps.selectedPwdPolicyInfo && this.props.selectedPwdPolicyInfo && this.props.selectedPwdPolicyInfo.clientGroup === this.state.dialogForm.clientGroup)
            //|| !this.state.reload
        ) {
            this.setFormData();
        }

        if (this.state.isFormDirty && this.state.isSaving && prevProps.isFormDirty != this.props.isFormDirty) {
            this.setState({
                isFormDirty: this.props.isFormDirty,
                isSaving: this.props.isFormDirty,
            });
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.dialogForm && this.state.uAllItemFields
        ) {
            //console.log("[Password Policy Maintenance] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
            return false;
        }
    }

    setFormData = (initial) => {
        this.setState({
            initialDialogForm: { ...this.props.selectedPwdPolicyInfo },
            dialogForm: { ...this.props.selectedPwdPolicyInfo },
            reload: true
        });

        if ((!this.state.uAllItemFields && this.props.selectedPwdPolicyInfo) || initial) {
            let fields = {};
            let p2Info = this.props.selectedPwdPolicyInfo;
            Object.keys(p2Info).map((t) => {
                if (t === "enable2fa") {
                    this.setState({ enable2fa: p2Info[t] === "1" ? true : false });
                } else if (t === "frequency2fa") {
                    this.setState({ frequency2fa: p2Info[t] });
                } 
                fields[t] = "";
            });

            this.setState({ uAllItemFields: fields });
        };
        return false;
    }

    savePwdPolicyInfo = (event) => {
        event.preventDefault();

        let dialogFormData = this.state.dialogForm;

        // TODO - check form validation
        let isValid = true;

        /*
        Object.keys(dialogFormData).map((t, k) => {
            if ((t === "usrId" || t === "Userid" || t === "userid")
                && (dialogFormData[t] === null || dialogFormData[t] === "")) {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [t]: "IN_VALID" },
                });
                isValid = false;
            }
            if ((t === "passwordNet" || t === "PasswordNet" || t === "passwordnet")
                && (dialogFormData[t] === null || dialogFormData[t] === "")) {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [t]: "IN_VALID" },
                });
                isValid = false;
            }
            if ((t === "passwordNetConfirm" || t === "PasswordNetConfirm" || t === "passwordnetconfirm")
                && (dialogFormData[t] === null || dialogFormData[t] === "")) {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [t]: "IN_VALID" },
                });
                isValid = false;
            }
            if ((t === "userName" || t === "UserName" || t === "username")
                && (dialogFormData[t] === null || dialogFormData[t] === "")) {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [t]: "IN_VALID" },
                });
                isValid = false;
            }
            if ((t === "clientGroup" || t === "ClientGroup" || t === "clientgroup")
                && (dialogFormData[t] === null || dialogFormData[t] === "")) {
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [t]: "IN_VALID" },
                });
                isValid = false;
            }

            //
            if (dialogFormData["passwordNetConfirm"] !== dialogFormData["passwordNet"]) {
                const _t = "passwordNetConfirm";
                this.setState({
                    uAllItemFields: { ...this.state.uAllItemFields, [_t]: "IN_VALID" },
                });
                isValid = false;
            }
        });
        */

        const formItems = this.state.uAllItemFields;
        Object.keys(formItems).map((t) => {
            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid) {
            return false;
        } else {
            this.props.savePwdPolicyInfo(dialogFormData);

            this.setState({
                isSaving: true,
                isFormDirty: false,
            });
        }
    }

    resetPwdPolicyInfo = () => {
        this.setState({
            dialogForm: { ...this.state.initialDialogForm },
            isFormDirty: false,
            reload: false
        });
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name });
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ dialogForm: { ...this.state.dialogForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({ dialogForm: { ...this.state.dialogForm, [event.target.name]: event.target.value }, isFormDirty: true });
        }

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, -1);
        } else {
            this.setState({ uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "" }, isFormDirty: true });
        }

        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isHelpVisible: false });
        return false;
    }

    setChildDialog = (childDialog) => {
        this.props.setChildDialog(childDialog);
        this.props.setChildDialogVisible(true);
    }
    closeChildWindow = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.props.setChildDialogVisible(false);
        return false;
    }

    displayLocks = (event) => {
        event.preventDefault();

        this.props.showLockedUsers(this.props.clientgroup);
        this.setChildDialog(dialogTypes.LOCKED_USER);

        //
        this.props.showingHelpInfoFunc(true);
        this.props.setChildrenOpening(false);

        return false;
    }

    setFrequency2faOption = (event) => {
        this.setState({
            frequency2fa: event.target.value,
            dialogForm: { ...this.state.dialogForm, ["frequency2fa"]: event.target.value },
            isFormDirty: true
        });
    }

    setEnable2fa = (event) => {
        this.setState({
            enable2fa: event.target.checked,
            dialogForm: { ...this.state.dialogForm, ["enable2fa"]: event.target.checked ? "1" : "0" },
            isFormDirty: true
        });
    }

    render() {
        let resultTest;
        {
            resultTest = this.state.showingTestResult && this.state.testMessage !== null ?
                /*
                <Dialog title="Warning"
                    onClose={this.hideTestResult}
                    width={300} height={"auto"}>*/
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallDialogCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Warning</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideTestResult(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div className="_container">
                                    <p>{this.state.testMessage}</p>
                                </div>
                                <div className="_container center">
                                    <p>
                                        <button className="validationErr_btnOk_Cls" onClick={this.hideTestResult} autoFocus>OK</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/*</Dialog>*/}
                    </Draggable>
                </div>
                : null
        }

        let patternBuilder;
        {
            patternBuilder = this.state.showingPatternBuilder && this.state.dialogForm !== null ?
                /*
                <Dialog title="Pattern Builder"
                    onClose={this.hidePatternBuilder}
                    width={300} height={"auto"}>*/
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Pattern Builder</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hidePatternBuilder(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div id="patternBuilderEditForm">
                                <div className="container">
                                    <p><label>Must Contain:</label></p>
                                    <p>between <input type="input" id="minCharTextInput" name="minChar" value={this.state.patternForm.minChar} onChange={this.buildPattern} autoFocus /> and <input type="input" name="maxChar" value={this.state.patternForm.maxChar} onChange={this.buildPattern} /> characters</p>
                                    <p><input type="checkbox" id="reqSpec" name="reqSpec" checked={this.state.patternForm.reqSpec} value={this.state.patternForm.reqSpec} onClick={this.buildPattern} /><label htmlFor="reqSpec"> at least 1 special character</label> <input type="input" name="specChar" value={this.state.patternForm.specChar} onChange={this.buildPattern} /></p>
                                    <p><input type="checkbox" id="reqCap" name="reqCap" checked={this.state.patternForm.reqCap} value={this.state.patternForm.reqCap} onClick={this.buildPattern} /><label htmlFor="reqCap"> at least 1 upper case character</label></p>
                                    <p><input type="checkbox" id="reqLow" name="reqLow" checked={this.state.patternForm.reqLow} value={this.state.patternForm.reqLow} onClick={this.buildPattern} /><label htmlFor="reqLow"> at least 1 lower case character</label></p>
                                    <p><input type="checkbox" id="reqNbr" name="reqNbr" checked={this.state.patternForm.reqNbr} value={this.state.patternForm.reqNbr} onClick={this.buildPattern} /><label htmlFor="reqNbr"> at least 1 numeric character</label></p>
                                </div>
                                <div className="container">
                                    <p style={{ display: "flex" }}><label>Pattern </label><input type="input" style={{ width: "100%" }} name="patternInput" value={this.state.patternForm.patternInput} /></p>
                                </div>
                                <div className="container center">
                                    <p>
                                        <button id="btnSavePatternBuilder" onClick={this.savePatternBuilder}>OK</button>
                                        <button id="btnHidePatternBuilder" onClick={this.hidePatternBuilder}>Close</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/*</Dialog>*/}
                    </Draggable>
                </div>
                : null
        }

        let testBuilder;
        {
            testBuilder = this.state.showingTestBuilder && this.state.dialogForm !== null ?
                /*
                <Dialog title="Test Password Validation Pattern"
                    onClose={this.hideTestBuilder}
                    width={300} height={"auto"}>*/
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Test Password Validation Pattern</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideTestBuilder(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div id="testPasswordValidationPatternForm">
                                <div className="_container">
                                    <p style={{ display: "flex" }}><label>Password: </label><input type="input" id="patternTestTextInput" name="patternTest" style={{ width: "100%" }} value={this.state.patternTest} onChange={this.handleTestPassChange} autoFocus /></p>
                                </div>
                                <div className="container center">
                                    <p>
                                        <button id="btnTestPatternBuilder" onClick={this.testPatternBuilder}>Test</button>
                                        <button id="btnCloseTestBuilder" onClick={this.hideTestBuilder}>Close</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    {/*</Dialog>*/ }
                    </Draggable >
                </div >
                : null
        }

        const dialogFrm = this.state.dialogForm ?
            (
                <div className="container-fluid2">

                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} focusingField={this.state.focusingField} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.savePwdPolicyInfo} id="passwordPolicyForm" className="DialogForm_2">
                        <div className="emptyLine"></div>

                        <div style={{ display: "none" }}>
                            <input type="text" name="username" value="" />
                            <input type="password" name="password" value="" />
                            <input type="email" name="email" value="" />
                        </div>

                        <div className="row">
                            <div className="col-md-10">
                                <div className="frameInner" id="lockoutUserID">
                                    <div className="header">Lockout UserID</div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Input type="input" changed={this.handleFormChange}
                                                focused={this.handleOnFocus} autoFocus
                                                lostfocused={this.handleOnBlur}
                                                labelText="Lock UserID after" field="attemptslimit"
                                                value={this.state.dialogForm.attemptslimit}
                                                lineStyle="oneLine" width="50px" labelWidth="auto"
                                                inValid={this.state.uAllItemFields["attemptslimit"]}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <Input type="input" changed={this.handleFormChange}
                                                focused={this.handleOnFocus}
                                                labelText="failed attempts for" field="lockoutTime"
                                                value={this.state.dialogForm.lockoutTime}
                                                lineStyle="oneLine" width="50px" labelWidth="auto"
                                                inValid={this.state.uAllItemFields["lockoutTime"]}
                                            />
                                        </div>
                                        <div className="col-sm-1">
                                            <Input type="gridHeader" labelText="Minutes" />
                                        </div>
                                        <div className="col-sm-3">
                                            <button type="button" onClick={this.displayLocks} id="btnDisplayAllLockUsers">Display Locks</button>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <div className="frameInner" id="passwordRequirements">
                                    <div className="header">Password Requirements</div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Input type="input" changed={this.handleFormChange}
                                                focused={this.handleOnFocus}
                                                lostfocused={this.handleOnBlur}
                                                labelText="Require password change every" field="passChange"
                                                value={this.state.dialogForm.passChange}
                                                lineStyle="oneLine" labelWidth="250px"
                                                inValid={this.state.uAllItemFields["passChange"]}
                                            />
                                        </div>
                                        <div className="col-sm-1">
                                            <Input type="gridHeader" labelText="Days" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Input type="input" changed={this.handleFormChange}
                                                focused={this.handleOnFocus}
                                                labelText="Prevent Using the Previous" field="passMemory"
                                                value={this.state.dialogForm.passMemory}
                                                lineStyle="oneLine" labelWidth="250px" width="100%"
                                                inValid={this.state.uAllItemFields["passMemory"]}
                                            />
                                        </div>
                                        <div className="col-sm-1">
                                            <Input type="gridHeader" labelText="Passwords" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-7">
                                            <Input type="input" changed={this.handleFormChange}
                                                focused={this.handleOnFocus}
                                                labelText="Pass Validation Pattern" field="passValidation"
                                                value={this.state.dialogForm.passValidation}
                                                lineStyle="oneLine" labelWidth="250px"
                                                inValid={this.state.uAllItemFields["passValidation"]}
                                            />
                                        </div>
                                        <div className="col-sm-2">
                                            <button onClick={this.showPatternBuilder} id="btnP2MEzBuilder">EZ Builder</button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-7">
                                            <Input type="textarea" changed={this.handleFormChange}
                                                focused={this.handleOnFocus}
                                                labelText="Pass Validation Message" field="passValidationMsg"
                                                value={this.state.dialogForm.passValidationMsg}
                                                lineStyle="oneLine"
                                                inValid={this.state.uAllItemFields["passValidationMsg"]}
                                            />
                                        </div>
                                        <div className="col-sm-1">
                                            <button onClick={this.showTestBuilder} id="btnP2MTestPwd">Test</button>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="frameInner" id="twoFactorAuthentication">
                                    <div className="header">Two Factor Authentication</div>
                                    <div className="row">
                                        <div className="col-sm-10">
                                            <label>
                                                <input type="checkbox" onClick={this.setEnable2fa}
                                                    checked={this.state.enable2fa}
                                                    name="enable2fa" field="enable2fa"
                                                    //value={this.state.dialogForm.enable2fa}
                                                    style={{ marginRight: "10px" }} />
                                                Enabled
                                            </label>
                                        </div>
                                    </div>

                                    <div className={"requireAuthenticationCls" + (this.state.enable2fa ? "" : " hidden") }>
                                        <div className="row">
                                            <div className="col-sm-10">
                                                <Input type="gridHeader" labelWidth="auto" labelText="Require Authentication" />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-10">
                                                <label>
                                                    <input type="radio" onClick={this.setFrequency2faOption} checked={this.state.frequency2fa === "All"} name="frequency2fa" field="frequency2fa" value="All" style={{ marginRight: "10px" }} />
                                                    when a new session is established and each subsequent login
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-10">
                                                <label>
                                                    <input type="radio" onClick={this.setFrequency2faOption} checked={this.state.frequency2fa === "New"} name="frequency2fa" field="frequency2fa" value="New" style={{ marginRight: "10px" }} />
                                                    Only when a new session is established. Do not prompt subsequent logins
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </form>

                    <div className="emptyLine"></div>

                    <Footer closeWindow={this.props.closeWindow}
                        refreshForm={this.resetPwdPolicyInfo}
                        formId="passwordPolicyForm"
                        isFormDirty={this.state.isFormDirty}
                        //showBtnNew="PasswordPolicy"
                        //showBtnDelete="PasswordPolicy"
                        //isLookup={true}
                        className="Footer2"
                        menuIdx="2"
                        //ignoreExit={true}
                    />
                </div>
            )
            : <p>Password Policy data loading...</p>;

        return (
            <React.Fragment>

                <React.Fragment>
                    {resultTest}
                </React.Fragment>

                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>

                <React.Fragment>
                    {patternBuilder}
                </React.Fragment>

                <React.Fragment>
                    {testBuilder}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

PasswordPolicyMaintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
    //email: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
        selectedPwdPolicyInfo: state.patientAbstract.selectedPwdPolicyInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        clientgroup: state.auth.clientgroup
    }
}

const mapDispatchToProps = dispatch => {
    return {
        savePwdPolicyInfo: (dialogForm) => dispatch(actionCreators.savePwdPolicyInfo(dialogForm)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        checkValidInput: (field, value, table, key, index) => dispatch(actionCreators.checkValidInput(field, value, table, key, index)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        showLockedUsers: (group) => dispatch(actionCreators.showLockedUsers(group)),
        setChildrenOpening: (flg) => dispatch(actionCreators.setChildrenOpening(flg)),
        showingHelpInfoFunc: (value) => dispatch(actionCreators.showingHelpInfo(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordPolicyMaintenance);