/* eslint-disable */
import React, { Component } from "react";
import ConfigData from '../../store/config.json';
import { connect } from "react-redux";
import { actionCreators } from "../../store/PatientAbstract";
import Draggable from 'react-draggable';
import axios from "axios";

class CommonLookup3 extends Component {

    constructor(props) {
        super(props);
        this.onCellClickAction = this._onCellClickAction.bind(this);
        this.onCellMouseInAction = this._onCellMouseInAction.bind(this);
        this.onCellMouseOutAction = this._onCellMouseOutAction.bind(this);
    }

    _onCellClickAction(e) {
        var mainItem = e.target.closest(".tnm_html_main_rec");
        var _code = mainItem.querySelector("td:first-child").innerText;

        //this.props.setCode(_code.replace(" ", ""));
        this.props.setCode(_code.trim());

        this.setState({ isDialogVisible: true });

        this.props.setChildrenOpening(false);
    }
    _onCellMouseInAction(e) {
        if (e.target.closest(".tnm_html_main_rec")) {
            e.target.closest(".tnm_html_main_rec").setAttribute("class", "tnm_html_main_rec selected");
        } else {
            e.target.setAttribute("class", "selectedCell");
        }
    }
    _onCellMouseOutAction(e) {
        if (e.target.closest(".tnm_html_main_rec")) {
            e.target.closest(".tnm_html_main_rec").setAttribute("class", "tnm_html_main_rec");
        } else {
            e.target.setAttribute("class", "");
        }
    }

    _navigateOnRowAction(e) {
        if (e.target.closest(".tnm_html_main_rec")) {
            e.target.closest(".tnm_html_main_rec").setAttribute("class", "tnm_html_main_rec");
        } else {
            e.target.setAttribute("class", "");
        }
    }
    _onKeyDownAction(e) {
        if (e.target.closest(".tnm_html_main_rec")) {
            e.target.closest(".tnm_html_main_rec").setAttribute("class", "tnm_html_main_rec");
        } else {
            e.target.setAttribute("class", "");
        }
    }

    lastSelectedIndex = 0;
    state = {
        codes: [],
        isLoaded: false,
        titleDialog: null,
    }

    addEventListener = () => {
        var cl = this;
        var currentLookupModal = document.querySelector('#tnmHtmlLookup_' + cl.props.field);
        currentLookupModal.addEventListener('keydown', function (e) {
            if (e.keyCode == 40) {// down arrow /TAB
                let currentRow = cl.lastSelectedIndex;

                var tnmHtmlTable = document.querySelectorAll(".tnmHtmlContentCls>table>tbody>tr");
                if (currentRow + 1 >= tnmHtmlTable.length) return false;
                var rowNo = 0;
                tnmHtmlTable.forEach(function (tbl) {
                    if (rowNo == currentRow + 1) {
                        tbl.setAttribute("class", "tnm_html_main_rec selected");//set focus into first row
                        tbl.setAttribute("tabindex", "0");
                        tbl.focus();
                    } else {
                        tbl.setAttribute("tabindex", "0");
                        tbl.setAttribute("class", "tnm_html_main_rec");
                    }
                    rowNo++;
                });

                cl.lastSelectedIndex = currentRow + 1;
                return false;

            } else if (e.keyCode == 38) {// up arrow
                let currentRow = cl.lastSelectedIndex;

                var tnmHtmlTable = document.querySelectorAll(".tnmHtmlContentCls>table>tbody>tr");
                if (currentRow - 1 == 0) return false;
                var rowNo = 0;

                tnmHtmlTable.forEach(function (tbl) {
                    if (rowNo == currentRow - 1) {
                        tbl.setAttribute("class", "tnm_html_main_rec selected");//set focus into first row
                        tbl.setAttribute("tabindex", "0");
                        tbl.focus();
                    } else {
                        tbl.setAttribute("class", "tnm_html_main_rec");
                        tbl.setAttribute("tabindex", "0");
                    }
                    rowNo++;
                });

                cl.lastSelectedIndex = currentRow - 1;
                return false;

            } else if (e.key === "Tab" || (e.key === "Shift" && e.keyCode == 9) || (e.key === "Tab" && e.shiftKey)) {// shift + tab
                if (e.key === "Tab" && e.shiftKey) {
                    if (e.target.nodeName === "BUTTON") {
                        var tnmHtmlTable = document.querySelectorAll(".tnmHtmlContentCls>table>tbody>tr");
                        var rowNo = 0;
                        tnmHtmlTable.forEach(function (tbl) {
                            if (rowNo == 1) {
                                cl.lastSelectedIndex = rowNo;
                                tbl.setAttribute("class", "tnm_html_main_rec selected");//set focus into first row
                                tbl.setAttribute("tabindex", "0");
                                tbl.focus();
                            } else {
                                tbl.setAttribute("class", "tnm_html_main_rec");
                                tbl.setAttribute("tabindex", "0");
                            }
                            rowNo++;
                        });
                    } else {
                        document.querySelector(".ErsGrBlueButton.tnmHtml_btnCancel_Cls")[0] ? document.querySelector(".ErsGrBlueButton.tnmHtml_btnCancel_Cls")[0].focus() : "";
                    }
                    return false;
                } else {
                    if (e.target.nodeName === "BUTTON") {
                        var tnmHtmlTable = document.querySelectorAll(".tnmHtmlContentCls>table>tbody>tr");
                        var rowNo = 0;
                        tnmHtmlTable.forEach(function (tbl) {
                            if (rowNo == 0) {
                                cl.lastSelectedIndex = rowNo;
                                tbl.setAttribute("class", "tnm_html_main_rec selected");//set focus into first row
                                tbl.setAttribute("tabindex", "0");
                                tbl.focus();
                            } else {
                                tbl.setAttribute("class", "tnm_html_main_rec");
                                tbl.setAttribute("tabindex", "0");
                            }
                            rowNo++;
                        });
                        return false;
                    } else {
                        document.querySelector(".ErsGrBlueButton.tnmHtml_btnCancel_Cls")[0] ? document.querySelector(".ErsGrBlueButton.tnmHtml_btnCancel_Cls")[0].focus() : "";
                    }
                }

            } else if (e.keyCode == 13 && e.target.getAttribute("class") === "tnm_html_main_rec selected" /*&& e.target.firstChild.nodeName == "TD"*/) {//enter
                var tnmHtmlTable = document.querySelectorAll(".tnmHtmlContentCls>table>tbody>tr");
                let selectedRow = cl.lastSelectedIndex;
                var mainItem = tnmHtmlTable[selectedRow];
                if (mainItem.querySelector("td:first-child")) {
                    var _code = mainItem.querySelector("td:first-child").innerText;
                    cl.props.setCode(_code.replace(" ", ""));
                    cl.setState({ isDialogVisible: true });
                    cl.props.setChildrenOpening(false);
                }
                return false;
            }
        });
    }

    componentDidMount() {
        this.addEventListener();

        this.props.setPageLoaded(false);
        this.props.setChildrenOpening(true);

        let table = this.props.dataSource;
        if (table === undefined || table === "") {
            return false;
        }

        let tnm = this.props.aliasNo !== undefined ? this.props.aliasNo : "0";
        let pcy = this.props.pcy !== undefined ? this.props.pcy : "";
        let ajccDiseaseId = this.props.flag !== undefined && this.props.flag !== "" ? this.props.flag : "";

        let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetTnmHtmlLookupView";
        var _params = { table: table, tnm: tnm, pcy: pcy, ajccDiseaseId: ajccDiseaseId, accessToken: localStorage.getItem('token') };
        var _this = this;
        axios.post(url, _params)
            //.then(res => res.json())
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && res.data.StatusCode == "408") {
                    console.log(res.data.ErrorMessage);
                    _this.props.showSessionTimeoutMsg(true);
                }
                return res.commonLookupList ? _this.setState({ codes: res.commonLookupList }) : "";
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    }

    componentDidUpdate(prevProps) {
        let table = this.props.dataSource;
        if (table === undefined || table === "") {
            return false;
        }

        if (this.state.codes && this.state.codes.length > 0 && !this.state.isLoaded) {
            var cl3 = this;
            setTimeout(function () {
                var tnmHtmlTable = document.querySelectorAll(".tnmHtmlContentCls>table>tbody>tr");
                let rowNo = 0;
                tnmHtmlTable.forEach(function (tbl) {
                    if (rowNo == 1) {
                        cl3.lastSelectedIndex = rowNo;
                        tbl.setAttribute("class", "tnm_html_main_rec selected");//set focus into first row
                        tbl.setAttribute("tabindex", "0");
                        tbl.focus();
                    } else {
                        tbl.setAttribute("class", "tnm_html_main_rec");
                        tbl.setAttribute("tabindex", "0");
                    }
                    rowNo++;
                });

                //var tnmHtmlTd = document.querySelectorAll(".tnmHtmlContentCls>table>tbody>tr>td:first-child");
                var tnmHtmlTd = document.querySelectorAll(".tnmHtmlContentCls>table>tbody>tr>td");
                tnmHtmlTd.forEach(function (td) {
                    td.addEventListener("click", cl3.onCellClickAction);
                    td.addEventListener("mouseenter", cl3.onCellMouseInAction);
                    td.addEventListener("mouseleave", cl3.onCellMouseOutAction);
                });

                cl3.forceUpdate();
                cl3.setState({ isLoaded: true });
            }, 0);
        }

        return false;
    }

    closeCode = (e) => {
        try {
            e ? e.preventDefault() : "";
        } catch (e) { }

        this.props.closeCode();
        this.props.setChildrenOpening(false);

        return false;
    }

    render() {
        let commonLookup = null;
        {
            commonLookup = (
                <Draggable handle=".k-window-titlebar">
                    <div className="dragableLargestDialogCls tnmHtmlPopup" id={"tnmHtmlLookup_" + this.props.field}>
                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                            <div className="k-window-title k-dialog-title">
                                {this.state.titleDialog && this.state.titleDialog !== " " && this.state.titleDialog !== undefined ? this.state.titleDialog : (this.props.titleDialog ? this.props.titleDialog : " ")}
                            </div>
                            <div className="k-window-actions k-dialog-actions">
                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeCode(e) }}></span>
                                </a>
                            </div>
                        </div>

                        <div className="showingTnmHtmlContentCls">
                            {
                                (!this.state.codes || this.state.codes.length == 0) ? "No records available" :
                                    this.state.codes.map(function (code) {
                                        return (
                                            <div dangerouslySetInnerHTML={{ __html: code.html.replace("&nbsp;", "") }} className="tnmHtmlContentCls" />
                                        )
                                    })
                            }
                        </div>
                        
                        <div style={{ padding: "10px", textAlign: "center", backgroundColor: "#c5ddfe" }}>
                            <button className="ErsGrBlueButton tnmHtml_btnCancel_Cls" onClick={this.props.closeCode}>Cancel</button>
                        </div>
                    </div>
                </Draggable>
            );
        }

        return <div>
            <div className="fake_popup_cls"></div>
            {commonLookup}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        clientgroup: state.auth.clientgroup,
        pageLoaded: state.patientAbstract.pageLoaded,
        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(CommonLookup3);