/* eslint-disable */
import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';

export default withOktaAuth(class OktaAuthHome extends Component {

    state = {
        actived: false,
    }

    constructor(props) {
        super(props);
        this.oktaLogin = this.oktaLogin.bind(this);
        //this.oktaLogout = this.oktaLogout.bind(this);
    }

    async oktaLogin(e) {
        e.preventDefault();
        sessionStorage.removeItem('CrstarLogout');
        var thij = this;
        setTimeout(function () {
            try {
                sessionStorage.setItem('ContinueToLogin', true);
                thij.props.oktaAuth.signOut()
            } catch (ex) { }
        }, 0);
        return false;
    }
    
    /*async oktaLogout(e) {
        e.preventDefault();
        await this.props.oktaAuth.signOut();
    }*/

    render() {
        if (sessionStorage.getItem('ContinueToLogin')) {
            sessionStorage.removeItem('ContinueToLogin');
            this.props.oktaAuth.signInWithRedirect();
        }

        let body;

        const user = JSON.parse(sessionStorage.getItem('crstar_user_by_okta'));
        if (user) {
            this.setState({ actived: true });
            window.location.href = window.location.origin;
            return false;
        } else {
            var okta = localStorage.getItem('okta-token-storage');
            const oktaAuthFailed = sessionStorage.getItem('oktaAuthFailed');
            const crstarLoggedOut = sessionStorage.getItem('CrstarLogout');

            if ((this.props.authState?.isAuthenticated || okta) && !this.state.actived) {
                if (crstarLoggedOut || oktaAuthFailed) {
                    body = oktaAuthFailed == "1" ? null : (
                        <div className="Buttons">
                            <button onClick={this.oktaLogin}>Okta Login</button>
                        </div>
                    );
                } else {
                    if (!this.state.actived) {
                        this.setState({ actived: true });
                    }
                    sessionStorage.removeItem('CrstarLogout');
                }
            } else {
                body = (
                    <div className="Buttons">
                        <button onClick={this.oktaLogin}>Okta Login</button>
                    </div>
                );
            }
        }

        return (
            <>
                {body}
            </>
        );
    }
});