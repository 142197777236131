/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import Footer from "../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
import Draggable from 'react-draggable';
//import * as dialogTypes from "../../AbstractManagement/maintDialogs";
import "./PrintForms.css";
import Patient from "../../UI/Lookup/Patient";
import ConfigData from '../../../store/config.json';
import PrintOutputForm from "../../../components/AbstractManagement/PatientAbstract/Shared/PrintOutputForm";

class PrintTxSummary extends Component {

    state = {
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,

        pAllItemFields: null,
        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        showMessageError: false,
        hasError: false,
        savedForm: false,
        fieldInFocus: null,
        focusingField: null,
        isSaving: false,
        enabledDeletedBtn: false,
        loaded: false,

        metafilesMv: null,
        initialMetafilesMv: null,
        metafilesMvItemFields: null,
        metafilesMvMaxlengthInput: null,
        selectedItemRowIndex: 0,
        showPatientsLookup: false,
        isNewFlg: false,

        titleDialog: null,
        showClockTimer: false,
        startTimeFrom: null,
        currentTime: null,

        selectedOutputTypeOption: null,
        selectedPrintOption: null,
        selectionListLetterOpt: null,
    }
    initialPage = true;
    downloadFile = false;

    handleOnFocus = (event) => {
        event.preventDefault();

        this.props.storeToGlobal(null);

        var selectedRowIndex = this.state.selectedItemRowIndex;
        if (event.target.getAttribute("data-index") != "0") {
            selectedRowIndex = event.target.getAttribute("data-index");
        }

        this.setState({
            fieldInFocus: event.target.name,
            focusingField: event.target,
            selectedItemRowIndex: selectedRowIndex
        });

        return false;
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showMessageError: false
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector(".Footer2>#btnDeleteItem_Fake") ? document.querySelector(".Footer2>#btnDeleteItem_Fake").focus() : "";
        }, 0);

        return false;
    }

    hidePatientsLookup = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showPatientsLookup: false
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            //ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP
            document.querySelector("#siteCode") ? document.querySelector("#siteCode").focus() : "";
        }, 1000);

        return false;
    }

    componentWillUnmount() {
        this.setState({
            initialDialogForm: null,
            dialogForm: null,
            isFormDirty: false,
            reload: false,

            pAllItemFields: null,
            // set max-length for input fields
            tableStructureList: null,
            pageMaxlengthInput: null,
            hasSetMaxLength: false,

            showMessageError: false,
            hasError: false,
            savedForm: false,
            fieldInFocus: null,
            focusingField: null,
            isSaving: false,
            enabledDeletedBtn: false,
            loaded: false,

            metafilesMv: null,
            initialMetafilesMv: null,
            metafilesMvItemFields: null,
            metafilesMvMaxlengthInput: null,
            selectedItemRowIndex: 0,
            showPatientsLookup: false,
            isNewFlg: false,
        });
    }

    selectionOutputType = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedOutputTypeOption: event.target.value,
            dialogForm: dialogFormData,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: this.state.selectedOutputTypeOption != event.target.value ? true : this.state.isFormDirty,
        });

        this.props.storeToGlobal(null);
    }

    selectionPrintOption = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedPrintOption: event.target.value,
            dialogForm: dialogFormData,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: this.state.selectedPrintOption != event.target.value ? true : this.state.isFormDirty,
        });

        this.props.storeToGlobal(null);
    }

    selectionListLetterFnc = (event) => {
        this.setState({
            selectionListLetterOpt: event.target.value,
        });
    }

    getHospSecurity = () => {
        let url = this.props.clientgroup + "/GetUserByEmail";
        var _this = this;
        axios.post(url, { email: this.props.userId, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }
                const availableHospsRpt = res.data.user !== null && res.data.user.availableHospsRpt !== null ? res.data.user.availableHospsRpt : "";
                if (!res.data.error && res.data.user !== null
                    && availableHospsRpt !== null && availableHospsRpt !== "") {
                    var _availableHospsRptArr = availableHospsRpt.split('|');
                    _availableHospsRptArr = _availableHospsRptArr.filter((obj, idx) => {
                        return obj && obj != "";
                    });
                    _this.setState({ availableHosps: _availableHospsRptArr.join(',') });
                } else {
                    _this.setState({ availableHosps: ",All," });//#Full control
                }
                return false;
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
        return false;
    }

    initClock() {
        var _this = this;
        setInterval(function () {
            var today = new Date(),
                time = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            //d.toLocaleTimeString();
            _this.setState({ currentTime: time });

            return false;
        }, 1000);
    }

    componentWillMount() {
        this.props.setChildrenOpening(false);
        //this.setState({ availableHosps: null });
        this.initClock();
    }

    componentDidMount() {
        this.setState({ selectedItemRowIndex: 0 });

        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);
        /*
        if (!this.props.onRightMenu) {
            // Add all tabs in maintDialogs.js
            dialogTypes.printFormsTabsDisplay.map((dialog, index) => {
                this.props.addTabItems(dialog.name, dialog.id);
                this.props.setEnabledStatus(dialog.name);
            });
        }
        */
        this.setState({ isLoading: true, loaded: false });//#End

        if (!this.state.dialogForm && this.props.patientAbsInfo) {
            this.setFormData();
        }

        var childrenOpening = this.props.childrenOpening;
        this.props.setChildrenOpening(childrenOpening);

        setTimeout(function () {
            document.getElementById("btnAddNewItem0") ? document.getElementById("btnAddNewItem0").click() : null;
        }, 1000);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.patientAbsInfo && this.props.patientAbsInfo && (prevProps.patientAbsInfo.startTime !== this.props.patientAbsInfo.startTime || prevProps.patientAbsInfo.endTime !== this.props.patientAbsInfo.endTime)) {
            let dataForm = this.props.patientAbsInfo;
            if (dataForm.reportingDevice == "Screen" && dataForm.endTime) {
                this.drawingHtmlTable(dataForm);
                this.props.setChildrenOpening(false);
                return false;
            } else if (dataForm.reportingDevice == "Excel" && dataForm.urlOutputFile && !this.downloadFile) {
                var _this = this;
                let url = this.props.clientgroup + "/DownloadFile";

                axios.post(url, {
                    method: 'POST',
                    responseType: 'blob',//important
                    urlOutputFile: dataForm.urlOutputFile,
                    accessToken: localStorage.getItem('token')
                }).then((response) => {
                    // get file name from url
                    var filename = dataForm.urlOutputFile.substring(dataForm.urlOutputFile.lastIndexOf('\\') + 1);
                    console.log("filename=" + filename);

                    const link = document.createElement('a');

                    let excel;
                    if (dataForm.urlOutputFile.indexOf('/') != -1) {
                        excel = dataForm.urlOutputFile.substring(dataForm.urlOutputFile.lastIndexOf('/') + 1);
                    } else {
                        excel = dataForm.urlOutputFile.substring(dataForm.urlOutputFile.lastIndexOf('\\') + 1);
                    }
                    link.href = this.props.clientgroup + "/" + excel;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    _this.downloadFile = true;
                    return false;
                })
                .catch(function (error) {
                    console.log(error);
                });

                return false;
            }
        }

        if (this.props.errorMessage) {
            alert(this.props.errorMessage);

            this.setState({ showClockTimer: false, currentTime: null });

            this.props.clearErrorMessage();
            return false;
        }

        if (!this.state.getAvailableHosps) {
            this.getHospSecurity();
            this.setState({ getAvailableHosps: true });
        }

        if ((!this.state.dialogForm && this.props.patientAbsInfo)
            || (prevProps.patientAbsInfo && this.props.patientAbsInfo && prevProps.patientAbsInfo.pid !== this.props.patientAbsInfo.pid)
            || (prevProps.patientAbsInfo && this.props.patientAbsInfo && prevProps.patientAbsInfo.firstName !== this.props.patientAbsInfo.firstName)
            || (prevProps.patientAbsInfo && this.props.patientAbsInfo && prevProps.patientAbsInfo.lastName !== this.props.patientAbsInfo.lastName)
        ) {
            this.setFormData();

            this.initialPage = false;

        } else if (this.state.dialogForm && !this.props.patientAbsInfo && !this.state.reload) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    pid: "0",
                    firstName: "",
                    lastName: "",
                    isFormDirty: false,
                    isNewFlg: false,
                },
                reload: true,
            });
        }

        if ((this.state.dialogForm && !this.state.dialogForm.pid && this.props.selectedPatientOnPopup)
            || (this.state.dialogForm && this.state.dialogForm.pid && this.props.selectedPatientOnPopup && this.props.selectedPatientOnPopup.pid != this.state.dialogForm.pid)) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    pid: this.props.selectedPatientOnPopup.pid,
                    firstName: this.props.selectedPatientOnPopup.firstName,
                    lastName: this.props.selectedPatientOnPopup.lastName,
                    isFormDirty: true,
                    //Also clear all primary_summary details
                    ["cid"]: "0",
                    ["siteCode"]: "",
                    ["siteDesc"]: "",
                    ["siteCodeName"]: "",
                    ["seqPrim"]: "",
                    ["hosp"]: "",
                    ["hospName"]: "",
                    ["medRecNbr"]: "",
                },
                isFormDirty: true,
            });
        } else if (this.state.dialogForm && !this.props.selectedPatientOnPopup && !this.state.reload) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    pid: "0",
                    firstName: "",
                    lastName: "",
                    isFormDirty: false,
                    isNewFlg: false,
                },
                reload: true,
            });
        }

        //
        if (this.state.tableStructureList === null && !this.state.loadedTableStructure) {
            this.setState({ loadedTableStructure: true });
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        if (this.state.isFormDirty && (this.state.isSaving || this.props.deletedDataSuccess)
            && (prevProps.isFormDirty !== this.props.isFormDirty || this.state.isFormDirty !== this.props.isFormDirty)) {
            this.setState({
                isFormDirty: this.props.isFormDirty,
                isSaving: false,
            });
        }

        //
        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.dialogForm && this.state.pAllItemFields && !this.state.isNewFlg
        ) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "" },
                    dialogForm: {
                        ...this.state.dialogForm,
                        [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name,
                        [this.props.currentSelectedField + "State"]: this.props.commonLookupItem.state,
                    },
                });
            } else {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "" },
                    dialogForm: {
                        ...this.state.dialogForm,
                        [this.props.currentSelectedField + "Name"]: "",
                        [this.props.currentSelectedField + "State"]: "",
                    },
                });
            }
            this.props.clearCommonLookupItem();
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "Patient", accessToken: localStorage.getItem('token') };

        let res = await axios.post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    setFormData = () => {
        this.initialPage = false;

        var dataForm = this.props.patientAbsInfo;

        let fields = {};
        Object.keys(dataForm).map((t) => {
            if (t == "dob" || t == "dob2" || t == "recordLastModified" || t == "recordLockedTime") fields[t] = null;
            else if (t == "pid" || t == "pid2" || t == "cid") fields[t] = "0";
            else fields[t] = "";
        });

        this.setState({
            initialDialogForm: {
                ...fields,
                sortOrder: "Alphabetic by Last Name, First Name",
                reportingDevice: "Screen",
                templateLabel: "Treatment Summary",
                printOption: "Primary",
            },
            dialogForm: {
                ...dataForm,
                sortOrder: "Alphabetic by Last Name, First Name",
                reportingDevice: "Screen",
                templateLabel: "Treatment Summary",
                printOption: "Primary",
            },
            isFormDirty: false,
            reload: true,
            savedForm: false,
            hasError: false,
            loaded: true,
            isNewFlg: false,

            ["selectedOutputTypeOption"]: dataForm.reportingDevice ? dataForm.reportingDevice : "Screen",
            ["selectedPrintOption"]: dataForm.printOption ? dataForm.printOption : "Primary",
            ["selectionListLetterOpt"]: "Forms",

            showClockTimer: false,
            titleDialog: null,
        });

        if (!this.state.pAllItemFields && this.props.patientAbsInfo) {
            let _fields = {};
            Object.keys(this.props.patientAbsInfo).map((t) => {
                if (t == "dob") _fields[t] = null;
                else _fields[t] = "";
            });

            this.setState({ pAllItemFields: _fields, pageMaxlengthInput: _fields });
        };
        //
        setTimeout(function () {
            document.getElementById("ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP") ? document.getElementById("ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP").focus() : "";
        }, 500);
    }

    drawingHtmlTable(dataForm) {
        //
        this.setState({
            isDrawingHtmlTable: true,
            Fn_Display: dataForm.fnDisplay,
            Fn_Display_URL: dataForm.fnDisplayUrl,
            List_Display: dataForm.listDisplay,
            List_Display_URL: dataForm.listUrl,
            publicFolder: dataForm.publicFolder,
            printStyle: dataForm.printStyle,
            showClockTimer: false,//hide clock timer
            currentTime: dataForm.endTime,
            ["selectionListLetterOpt"]: "Forms",
        });

        if (document.querySelector('.statusBarOnFooterCls')) {
            var _startTime = "Start Time: " + dataForm.startTime;
            var space = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; End Time: ";
            document.querySelector('.statusBarOnFooterCls').innerHTML = _startTime + space + dataForm.endTime;
        }

        return false;
    }

    hideDrawingHtmlTable = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.setState({ isDrawingHtmlTable: false });

        //Deleting output file at public folder...
        const fileName = (this.state.publicFolder);
        var _this = this;
        axios.post(ConfigData.CRStarAPI_URL + _this.props.clientgroup + "/DeleteOutputFile", { file: fileName, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    _this.setState({ publicFolder: null });
                }
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });

        return false;
    }

    showOutputFile = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }
        const url = e.target.getAttribute('href');

        // read text from URL location
        var request = new XMLHttpRequest();
        request.open('GET', url, true);
        request.send(null);
        request.onreadystatechange = function () {
            if (request.readyState === 4 && request.status === 200) {
                var type = request.getResponseHeader('Content-Type');
                if (type.indexOf("text") !== 1) {
                    return request.responseText;
                }
            }
        }

        return false;
    }

    printTable = () => {
        var printStyle = document.getElementById('printStyle') ? document.getElementById('printStyle').innerHTML : this.state.printStyle;
        var printContent = document.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Print Tx Summary</title><style type="text/css">';
        html += printStyle + '</style></head><body><div id="printableContent">';

        html += (printContent ? printContent.innerHTML : "") + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
        return false;
    }

    handleFormChange = (event) => {
        if (event.target2 && event.target2["name"] != "populationLabelName"
            && event.target2["name"] != "formLabelName" && event.target2["name"] != "formLabel" && event.target2["name"] != "formHosp") {
            var pid = this.state.dialogForm.pid;
            var cid = event.target2["cid"];
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    ["cid"]: event.target2["cid"],//!Important
                    ["siteCode"]: event.target2["siteCode"],
                    ["siteDesc"]: event.target2["siteDesc"],//
                    ["seqPrim"]: event.target2["seqPrim"],
                    ["hosp"]: event.target2["hosp"],
                    ["hospName"]: event.target2["hospName"],//
                    ["medRecNbr"]: event.target2["medRecNbr"],
                },
                isFormDirty: true,
                isNewFlg: false
            });

            this.Lookup_Fields(pid, cid, event.target2["siteCode"], event.target2["hosp"]);

            //
            setTimeout(function () {
                document.getElementById("siteCode") ? document.getElementById("siteCode").focus() : "";
            }, 2000);
        } else if (event.target2 && event.target2["name"] == "formHosp") {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value,
                },
                isFormDirty: true,
                isNewFlg: false
            });
        } else {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                },
                isFormDirty: true,
                isNewFlg: false
            });
        }
    }

    async Lookup_Fields(pid, cid, siteCode, hospVal) {
        let name = "siteCode";
        let value = siteCode;
        let table = "Site_Codes";

        var _this = this;
        await setTimeout(function () {
            _this.props.checkValidInput(name, value, table);

            let name2 = "hosp";
            let value2 = hospVal;
            let table2 = "Hospital";
            setTimeout(function () {
                _this.props.checkValidInput(name2, value2, table2);
            }, 1000);
        }, 0);

        let name2 = "hosp";
        let value2 = hospVal;
        let table2 = "Hospital";
        await setTimeout(function () {
            _this.props.checkValidInput(name2, value2, table2);
        }, 1500);
    }

    handleOnBlur = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            let index = event.target.getAttribute('data-index') ? event.target.getAttribute('data-index') : "-1";

            var _this = this;
            setTimeout(function () {
                _this.props.checkValidInput(name, value, table, key, index, flag);
            }, 1000);
        } else {
            this.setState({ uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "" } });
        }

        this.props.storeToGlobal(null);
    }
    // --END


    //on before show event
    showEvent(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        var _this = this;
        setTimeout(function () {
            _this.Print_Tx_Summary();

            var today = new Date(),
                timeFrom = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            // set clock timer...
            _this.setState({ showClockTimer: true, startTimeFrom: timeFrom });

            // Also set start time to status bar
            if (document.querySelector('.statusBarOnFooterCls'))
                document.querySelector('.statusBarOnFooterCls').innerHTML = "Start Time: " + timeFrom;

            return false;
        }, 0);

        //
        var sInVal = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(sInVal);
            } else {
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
            }
        }, 750);

        return false;
    }

    Print_Tx_Summary = () => {
        var dialogFormData = this.state.dialogForm;

        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;
        dialogFormData.userHosp = this.props.selectedHospital ? this.props.selectedHospital : "01";

        //
        this.props.runPrintTxSummary(dialogFormData);

        this.downloadFile = false;//reset this flag to get download new file

        return false;
    }
    //--

    refreshFormFnc = () => {
        let fields = {};
        if (!this.state.pAllItemFields && this.props.patientAbsInfo) {
            Object.keys(this.props.patientAbsInfo).map((t) => {
                if (t == "dob") fields[t] = null;
                else fields[t] = "";
            });
        };

        this.setState({
            dialogForm: {
                ...this.state.initialDialogForm,
                ["firstName"]: "",
                ["lastName"]: "",
                ["siteDesc"]: "",
                ["siteCodeName"]: "",
                ["hospName"]: "",

                //
                sortOrder: "Alphabetic by Last Name, First Name",
                reportingDevice: "Screen",
                templateLabel: "Treatment Summary",
                printOption: "Primary",//Alpha: default value is "Print One Form Per Primary"
            },
            pAllItemFields: fields,
            isFormDirty: false,
            reload: false,
            isNewFlg: true,

            ["selectedOutputTypeOption"]: "Screen",
            ["selectedPrintOption"]: "Primary",
        });

        //
        setTimeout(function () {
            document.getElementById("siteDesc") ? (document.getElementById("siteDesc").value = "") : "";
            document.getElementById("ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP") ? document.getElementById("ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP").focus() : "";
            document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "" : null;
        }, 500);

        //
        setTimeout(function () {
            document.getElementById("siteDesc") ? (document.getElementById("siteDesc").value = "") : null;
        }, 1000);
    }

    closeWindow = (e) => {
        e.preventDefault();

        this.setState({
            browsedItem: "",
            browsedIndex: null,
            listFiles: null
        });

        if (this.props.onRightMenu) {
            this.props.closeWindow();
        }

        return false;
    }

    showPatientsLookup = (event) => {
        event.preventDefault();
        this.setState({
            showPatientsLookup: true
        });
        this.props.storeToGlobal(null);
        return false;
    }

    Check_Status = (e, _this) => {
        e.preventDefault();

        let url = this.props.clientgroup + "/CheckStatus";
        axios.get(url, { accessToken: localStorage.getItem('token') })
            .then(res => {
                if (res && res.data && !res.data.error) {
                    eval(res.data);
                }
            }).catch(error => {
                console.log(error);
            });

        return false;
    }

    Cancel_Report = (e, _this) => {
        e.preventDefault();

        _this.props.cancelSBSReport();

        setTimeout(function () {
            _this.setState({ showClockTimer: false });
            return false;
        }, 0);

        return false;
    }

    render() {
        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls" style={{ cursor: "wait", marginRight: "1px" }}>
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Creating Files - Please Wait&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTimeFrom}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Check_Status(e, this) }} className="ErsGrBlueButton" value="yes" autoFocus>Check Status</button>
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="ErsGrBlueButton" value="no">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">You do not have permission to run reports on this population.</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }


        let drawingHtmlTable;
        {
            const listLetterOption = this.state.selectionListLetterOpt;
            drawingHtmlTable = this.state.isDrawingHtmlTable && (this.state.Fn_Display || this.state.List_Display) ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableLargerWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">{"Custom Form " + this.state.dialogForm.formLabel}</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingHtmlTable(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>

                            <div className="container" id="printTxSummaryMainTable">
                                {/*Drawing table here*/}
                                <div id="useForPrintHtml">
                                    <div dangerouslySetInnerHTML={{ __html: listLetterOption == "Forms" ? this.state.Fn_Display : this.state.List_Display }} className="" />
                                </div>

                                {
                                    /*
                                    <iframe src={this.state.publicFolder ? this.state.publicFolder : ""} />
                                    this.state.Fn_Display && this.state.Fn_Display.indexOf("Sorry! Can't load this output file, it's too large.") != -1 ?
                                        <a href={this.state.Fn_Display_URL} target="_blank" rel="noreferrer" onClick={(e) => { this.showOutputFile(e) }}>Click here to open the output file in the new window.</a>
                                        : ""
                                    */
                                }
                            </div>

                            <div className="emptyLine"></div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <label style={{ width: "240px" }}>
                                        <input type="radio" onClick={(e) => { this.selectionListLetterFnc(e) }}
                                            checked={this.state.selectedPrintOption == "Forms" ? true : false}
                                            name="listLetter" field="listLetter" value="Forms"
                                            style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} itemNbr="" />Forms</label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    <label style={{ width: "240px" }}>
                                        <input type="radio" onClick={(e) => { this.selectionListLetterFnc(e) }}
                                            checked={this.state.selectedPrintOption == "List" ? true : false}
                                            name="listLetter" field="listLetter" value="List"
                                            style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} itemNbr="" />List
                                        </label>
                                </div>
                            </div>

                            <div className="emptyLine"></div>

                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-sm-12" style={{ display: "flex", margin: "15px" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ScreenButton ScreenButtonHover" title=""
                                            onClick={(e) => { this.printTable(e) }}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print</button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ScreenButton ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={(e) => { this.hideDrawingHtmlTable(e) }} value="exit" autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let loading = (
            !this.state.loaded || this.props.waitingFor ? <div className="col-sm-12 showLoadingOnTopPageCls">Working...</div> : ""
        );

        let showPatientsLookup;
        {
            showPatientsLookup = this.state.showPatientsLookup ? <Patient field="firstName" closeCode={this.hidePatientsLookup} titleDialog="Patient Lookup" /> : null;
        }

        let dialogFrm;
        if (this.props.onRightMenu) {
            dialogFrm = <Draggable handle=".k-window-titlebar">
                <div className="dragableLargestDialogCls" id="printAbstractsModal">
                    <div className="k-window-titlebar k-dialog-titlebar k-header">
                        <div className="k-window-title k-dialog-title">Print Tx Summary</div>
                        <div className="k-window-actions k-dialog-actions">
                            <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                <span className="k-icon k-i-x" onClick={(e) => { this.closeWindow(e) }}></span>
                            </a>
                        </div>
                    </div>
                    <div className="limitedInGridCls">
                        <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                            {loading}
                            <div className="parentWindow">
                                <div className="patientAbstractModal" id="printAbstractsBody" style={{ height: "600px" }}>
                                    {
                                        this.state.dialogForm ?
                                            <div className="container-fluid2">
                                                <form id="printTxSummaryMainForm" className="printAbstractsMainForm" onSubmit={(e) => { this.showEvent(e) }}>
                                                    <div id="inputAreaCls">
                                                        <div style={{ width: "1220px" }}>
                                                            <div className="frameInner">
                                                                <div className="header">Print Tx Summary</div>

                                                                <div className="emptyLine"></div>

                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <fieldset>
                                                                            <div className="emptyLine"></div>
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="oneLine">
                                                                                        <Input type="input" changed={this.handleFormChange}
                                                                                            focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                            labelText="Patient Name" field="firstName" disabled={true}
                                                                                            width="185px" labelWidth="115px"
                                                                                            value={this.state.dialogForm.firstName}
                                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["firstName"] : ""}
                                                                                            inValid={this.state.pAllItemFields["firstName"]} />
                                                                                        <Input type="input" changed={this.handleFormChange}
                                                                                            focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                            labelText="" field="lastName" disabled={true}
                                                                                            width="230px" labelWidth="5px"
                                                                                            value={this.state.dialogForm.lastName}
                                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["lastName"] : ""}
                                                                                            inValid={this.state.pAllItemFields["lastName"]} />&nbsp;&nbsp;
                                                                                        <button type="button" id="ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP"
                                                                                            className="ErsGrBlueButton _ScreenButton" title=""
                                                                                            style={{ _width: "100%", height: "26px", boxSizing: "border-box" }}
                                                                                            onClick={(e) => { this.showPatientsLookup(e) }}>
                                                                                            <img id="ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP-ICON" src="https://crstar.ers-can.com/images/$$edit.find.png.a5image" style={{ verticalAlign: "middle", border: "none" }}></img>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="oneLine">
                                                                                        <Input type="Primary_Summary" shownType="dropdown"
                                                                                            focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                                            labelText="Site" field="siteCode"
                                                                                            width="35px" labelWidth="115px" lostfocused={this.handleOnBlur}
                                                                                            value={this.state.dialogForm.siteCode}//SELECT Site_Code, Abst_Suna_Flag, Seq_Prim, Hosp, PID, CID FROM Primary_Summary
                                                                                            dataSource="Site_Codes" titleDialog="Primary Popup"//Site_Code<Site_code,Seq_Prim<Seq_prim,Hosp<Hosp
                                                                                            flag={this.state.dialogForm.pid}//PID (N)|Equals|Pid
                                                                                            disabled={this.state.dialogForm.pid == null || this.state.dialogForm.pid == "" || this.state.dialogForm.pid == undefined || this.state.dialogForm.pid == "0"}
                                                                                            inValid={this.state.pAllItemFields["siteCode"]} itemNbr="21" tabIndex="4"
                                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["siteCode"] : ""}
                                                                                            patientId={this.state.dialogForm.pid} />
                                                                                        <Input type="input" changed={this.handleFormChange}
                                                                                            focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                            labelText="" field="siteDesc" disabled={true}
                                                                                            width="280px" labelWidth="5px"
                                                                                            value={this.state.dialogForm.siteDesc ? this.state.dialogForm.siteDesc : this.state.dialogForm.siteCodeName}
                                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["siteDesc"] : ""}
                                                                                            inValid={this.state.pAllItemFields["siteDesc"]} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="oneLine">
                                                                                        <Input type="input" changed={this.handleFormChange}
                                                                                            focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                            labelText="Sequence" field="seqPrim" disabled={true}
                                                                                            width="35px" labelWidth="115px"
                                                                                            value={this.state.dialogForm.seqPrim}
                                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["seqPrim"] : ""}
                                                                                            inValid={this.state.pAllItemFields["seqPrim"]} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="oneLine">
                                                                                        <Input type="input" changed={this.handleFormChange}
                                                                                            focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                            labelText="Hospital" field="hosp" disabled={true}
                                                                                            dataSource="Hospital"
                                                                                            width="35px" labelWidth="115px"
                                                                                            value={this.state.dialogForm.hosp}
                                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hosp"] : ""}
                                                                                            inValid={this.state.pAllItemFields["hosp"]} />
                                                                                        <Input type="input" changed={this.handleFormChange}
                                                                                            focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                            labelText="" field="hospName" disabled={true}
                                                                                            width="300px" labelWidth="5px"
                                                                                            value={this.state.dialogForm.hospName}
                                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospName"] : ""}
                                                                                            inValid={this.state.pAllItemFields["hospName"]} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="oneLine">
                                                                                        <Input type="input" changed={this.handleFormChange}
                                                                                            focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                            labelText="Med Rec Nbr" field="medRecNbr" disabled={true}
                                                                                            width="115px" labelWidth="115px"
                                                                                            value={this.state.dialogForm.medRecNbr}
                                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["medRecNbr"] : ""}
                                                                                            inValid={this.state.pAllItemFields["medRecNbr"]} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </fieldset>

                                                                        <div className="emptyLine"></div>

                                                                        <div className="row" style={{ marginLeft: "-7px" }}>
                                                                            <div className="col-sm-12">
                                                                                <Input type="LabelPopup" focused={this.handleOnFocus}
                                                                                    changed={this.handleFormChange} width="327px"
                                                                                    labelText="Population Label"
                                                                                    field="populationLabel" lineStyle="oneLine"
                                                                                    value={this.state.dialogForm.populationLabel}
                                                                                    dataSource="Labels"
                                                                                    titleDialog="Window" labelWidth="120px"
                                                                                    lostfocused={this.handleOnBlur}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="row" style={{ marginLeft: "-7px", visibility: this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "" : "hidden" }}>
                                                                            <div className="col-sm-12">
                                                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                                                    changed={this.handleFormChange} width="247px"
                                                                                    labelText="Sort Order" keyId="sortOrder0"
                                                                                    field="sortOrder" lineStyle="oneLine"
                                                                                    value={this.state.dialogForm.sortOrder}
                                                                                    dataSource="SortOrder_StaticOptions" titleDialog="" labelWidth="120px"
                                                                                    lostfocused={this.handleOnBlur} shownType="dropdown" displayingCodeOnly={true}
                                                                                    focusingField={this.state.focusingField} rowIndex="0"
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="emptyLine"></div>

                                                                        <div className="row" style={{ marginLeft: "-25px", visibility: this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "" : "hidden" }}>
                                                                            <div className="col-sm-12">
                                                                                <label style={{ marginTop: "5px", height: "15px" }}>
                                                                                    <input type="radio" onClick={(e) => { this.selectionPrintOption(e) }}
                                                                                        checked={this.state.selectedPrintOption === "Patient"}
                                                                                        name="printOption" value="Patient" id="Print_One_Form_Per_Patient"
                                                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                        Print One Form Per Patient
                                                                        </label>
                                                                                <br />
                                                                                <label style={{ width: "240px", height: "15px" }}>
                                                                                    <input type="radio" onClick={(e) => { this.selectionPrintOption(e) }}
                                                                                        checked={this.state.selectedPrintOption === "Primary"}
                                                                                        name="printOption" value="Primary" id="Print_One_Form_Per_Primary"
                                                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                        Print One Form Per Primary
                                                                        </label>
                                                                            </div>
                                                                        </div>

                                                                        <br />

                                                                        <div className="row" style={{ marginLeft: "-7px" }}>
                                                                            <div className="col-sm-12">
                                                                                Send Form To
                                                                </div>
                                                                        </div>
                                                                        <div className="row" style={{ marginLeft: "-7px" }}>
                                                                            <div className="col-sm-2">
                                                                                <label style={{ width: "240px", height: "0px" }}>
                                                                                    <input type="radio" onClick={(e) => { this.selectionOutputType(e) }}
                                                                                        checked={this.state.selectedOutputTypeOption === "Screen"}
                                                                                        name="reportingDevice" field="reportingDevice" value="Screen"
                                                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                Screen
                                                                        </label>
                                                                                <br />
                                                                                <label style={{ width: "240px", height: "0px" }}>
                                                                                    <input type="radio" onClick={(e) => { this.selectionOutputType(e) }}
                                                                                        checked={this.state.selectedOutputTypeOption === "Excel"}
                                                                                        name="reportingDevice" field="reportingDevice" value="Excel"
                                                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                Excel
                                                                        </label>
                                                                            </div>

                                                                            <div className="col-sm-4">
                                                                                <div className={this.state.selectedOutputTypeOption === "Screen" ? "" : "hidden"}>
                                                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                                                        changed={this.handleFormChange} width="150px"
                                                                                        labelText="Form" keyId="formLabel0"
                                                                                        field="formLabel" lineStyle="oneLine"
                                                                                        value={this.state.dialogForm.formLabel}
                                                                                        dataSource="Letters" titleDialog="" labelWidth="70px"
                                                                                        lostfocused={this.handleOnBlur} shownType="dropdown" displayingCodeOnly={true}
                                                                                        focusingField={this.state.focusingField} rowIndex="0"
                                                                                        setNameAlias="code+name" fieldExt2="formHosp"
                                                                                    />
                                                                                </div>
                                                                                <br />
                                                                                <div className={this.state.selectedOutputTypeOption === "Excel" ? "" : "hidden"}>
                                                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                                                        changed={this.handleFormChange} width="150px"
                                                                                        labelText="Template" keyId="formTemplate" disabled={true}
                                                                                        field="formTemplate" lineStyle="oneLine"
                                                                                        value="Treatment Summary"
                                                                                        dataSource="Letters" titleDialog="" labelWidth="70px"
                                                                                        lostfocused={this.handleOnBlur} shownType="dropdown" displayingCodeOnly={true}
                                                                                        focusingField={this.state.focusingField}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div className="emptyLine"></div>

                                                <Footer closeWindow={this.props.closeWindow}
                                                    refreshForm={this.refreshFormFnc} formId="printTxSummaryMainForm"
                                                    isFormDirty={this.state.isFormDirty && this.state.dialogForm && (this.state.dialogForm.pid && this.state.dialogForm.pid != "" || (this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != ""))}
                                                    hideSaveBtn={true}
                                                    showBtnNew="PrintTxSummary"
                                                    showBtnDelete="PrintTxSummary"
                                                    btnDeleteText="Run"
                                                    extraFnc={this.showEvent}
                                                    className="Footer2"
                                                    initialPageVisible={true}
                                                    exitFnc={this.props.closeWindow}
                                                    focusingField={this.state.focusingField}
                                                    patientAbsData={this.state.dialogForm}
                                                    ignoreExit={true}
                                                    //(Population_Label <> '' Or CID <> '') And ((Reporting_Device='Screen' And Form_Label <> '' And Form_Hosp <> '') Or Reporting_Device='Excel')
                                                    enabledDeletedBtn={
                                                        this.state.isFormDirty && this.state.dialogForm
                                                        && (this.state.dialogForm.cid && this.state.dialogForm.cid != "0" || (this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != ""))
                                                        && ((this.state.selectedOutputTypeOption == "Screen" && this.state.dialogForm.formLabel && this.state.dialogForm.formLabel != "" && this.state.dialogForm.formHosp && this.state.dialogForm.formHosp != "") || this.state.selectedOutputTypeOption == "Excel")
                                                    }
                                                    enabledAlways={true}
                                                />

                                            </div>
                                            : <p>Print Tx Summary data loading...</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        } else {
                        dialogFrm = <div className="limitedInGridCls">
                            <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                                {loading}
                                <div className="parentWindow">
                                    <div className="patientAbstractGridView" id="printAbstractsMainPage" style={{ height: "600px" }}>
                                        {
                                            this.state.dialogForm ?
                                                <div className="container-fluid2">
                                                    <form id="printTxSummaryMainForm" className="printAbstractsMainForm" onSubmit={(e) => { this.showEvent(e) }}>
                                                        <div id="inputAreaCls">
                                                            <div style={{ width: "1250px" }}>
                                                                <div className="frameInner">
                                                                    <div className="header">Print Tx Summary</div>

                                                                    <div className="emptyLine"></div>

                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <fieldset>
                                                                                <div className="emptyLine"></div>
                                                                                <div className="row">
                                                                                    <div className="col-sm-12">
                                                                                        <div className="oneLine">
                                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                                labelText="Patient Name" field="firstName" disabled={true}
                                                                                                width="185px" labelWidth="115px"
                                                                                                value={this.state.dialogForm.firstName}
                                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["firstName"] : ""}
                                                                                                inValid={this.state.pAllItemFields["firstName"]} />
                                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                                labelText="" field="lastName" disabled={true}
                                                                                                width="230px" labelWidth="5px"
                                                                                                value={this.state.dialogForm.lastName}
                                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["lastName"] : ""}
                                                                                                inValid={this.state.pAllItemFields["lastName"]} />&nbsp;&nbsp;
                                                                                            <button type="button" id="ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP"
                                                                                                className="ErsGrBlueButton _ScreenButton" title=""
                                                                                                style={{ _width: "100%", height: "26px", boxSizing: "border-box" }}
                                                                                                onClick={(e) => { this.showPatientsLookup(e) }}>
                                                                                                <img id="ABSTRACTUT_DELETEPATI_HNNZ-ABSTRACT_LOOKUP-ICON" src="https://crstar.ers-can.com/images/$$edit.find.png.a5image" style={{ verticalAlign: "middle", border: "none" }}></img>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="col-sm-12">
                                                                                        <div className="oneLine">
                                                                                            <Input type="Primary_Summary" shownType="dropdown"
                                                                                                focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                                                labelText="Site" field="siteCode"
                                                                                                width="35px" labelWidth="115px" lostfocused={this.handleOnBlur}
                                                                                                value={this.state.dialogForm.siteCode}//SELECT Site_Code, Abst_Suna_Flag, Seq_Prim, Hosp, PID, CID FROM Primary_Summary
                                                                                                dataSource="Site_Codes" titleDialog="Primary Popup"//Site_Code<Site_code,Seq_Prim<Seq_prim,Hosp<Hosp
                                                                                                flag={this.state.dialogForm.pid}//PID (N)|Equals|Pid
                                                                                                disabled={this.state.dialogForm.pid == null || this.state.dialogForm.pid == "" || this.state.dialogForm.pid == undefined || this.state.dialogForm.pid == "0"}
                                                                                                inValid={this.state.pAllItemFields["siteCode"]} itemNbr="21" tabIndex="4"
                                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["siteCode"] : ""}
                                                                                                patientId={this.state.dialogForm.pid} />
                                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                                labelText="" field="siteDesc" disabled={true}
                                                                                                width="280px" labelWidth="5px"
                                                                                                value={this.state.dialogForm.siteDesc ? this.state.dialogForm.siteDesc : this.state.dialogForm.siteCodeName}
                                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["siteDesc"] : ""}
                                                                                                inValid={this.state.pAllItemFields["siteDesc"]} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="col-sm-12">
                                                                                        <div className="oneLine">
                                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                                labelText="Sequence" field="seqPrim" disabled={true}
                                                                                                width="35px" labelWidth="115px"
                                                                                                value={this.state.dialogForm.seqPrim}
                                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["seqPrim"] : ""}
                                                                                                inValid={this.state.pAllItemFields["seqPrim"]} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="col-sm-12">
                                                                                        <div className="oneLine">
                                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                                labelText="Hospital" field="hosp" disabled={true}
                                                                                                dataSource="Hospital"
                                                                                                width="35px" labelWidth="115px"
                                                                                                value={this.state.dialogForm.hosp}
                                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hosp"] : ""}
                                                                                                inValid={this.state.pAllItemFields["hosp"]} />
                                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                                labelText="" field="hospName" disabled={true}
                                                                                                width="300px" labelWidth="5px"
                                                                                                value={this.state.dialogForm.hospName}
                                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospName"] : ""}
                                                                                                inValid={this.state.pAllItemFields["hospName"]} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="col-sm-12">
                                                                                        <div className="oneLine">
                                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                                focused={this.handleOnFocus} lostfocused={this.handleOnBlur}
                                                                                                labelText="Med Rec Nbr" field="medRecNbr" disabled={true}
                                                                                                width="115px" labelWidth="115px"
                                                                                                value={this.state.dialogForm.medRecNbr}
                                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["medRecNbr"] : ""}
                                                                                                inValid={this.state.pAllItemFields["medRecNbr"]} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </fieldset>

                                                                            <div className="emptyLine"></div>

                                                                            <div className="row" style={{ marginLeft: "-7px" }}>
                                                                                <div className="col-sm-12">
                                                                                    <Input type="LabelPopup" focused={this.handleOnFocus}
                                                                                        changed={this.handleFormChange} width="327px"
                                                                                        labelText="Population Label"
                                                                                        field="populationLabel" lineStyle="oneLine"
                                                                                        value={this.state.dialogForm.populationLabel}
                                                                                        dataSource="Labels"
                                                                                        titleDialog="Window" labelWidth="120px"
                                                                                        lostfocused={this.handleOnBlur}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="row" style={{ marginLeft: "-7px", visibility: this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "" : "hidden" }}>
                                                                                <div className="col-sm-12">
                                                                                    <Input type="codeSelect" focused={this.handleOnFocus}
                                                                                        changed={this.handleFormChange} width="247px"
                                                                                        labelText="Sort Order" keyId="sortOrder0"
                                                                                        field="sortOrder" lineStyle="oneLine"
                                                                                        value={this.state.dialogForm.sortOrder}
                                                                                        dataSource="SortOrder_StaticOptions" titleDialog="" labelWidth="120px"
                                                                                        lostfocused={this.handleOnBlur} shownType="dropdown" displayingCodeOnly={true}
                                                                                        focusingField={this.state.focusingField} rowIndex="0"
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="emptyLine"></div>

                                                                            <div className="row" style={{ marginLeft: "-25px", visibility: this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "" : "hidden" }}>
                                                                                <div className="col-sm-12">
                                                                                    <label style={{ marginTop: "5px", height: "15px" }}>
                                                                                        <input type="radio" onClick={(e) => { this.selectionPrintOption(e) }}
                                                                                            checked={this.state.selectedPrintOption === "Patient"}
                                                                                            name="printOption" value="Patient" id="Print_One_Form_Per_Patient"
                                                                                            style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                        Print One Form Per Patient
                                                                        </label>
                                                                                    <br />
                                                                                    <label style={{ width: "240px", height: "15px" }}>
                                                                                        <input type="radio" onClick={(e) => { this.selectionPrintOption(e) }}
                                                                                            checked={this.state.selectedPrintOption === "Primary"}
                                                                                            name="printOption" value="Primary" id="Print_One_Form_Per_Primary"
                                                                                            style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                        Print One Form Per Primary
                                                                        </label>
                                                                                </div>
                                                                            </div>

                                                                            <br />

                                                                            <div className="row" style={{ marginLeft: "-7px" }}>
                                                                                <div className="col-sm-12">
                                                                                    Send Form To
                                                                </div>
                                                                            </div>
                                                                            <div className="row" style={{ marginLeft: "-7px" }}>
                                                                                <div className="col-sm-2">
                                                                                    <label style={{ width: "240px", height: "0px" }}>
                                                                                        <input type="radio" onClick={(e) => { this.selectionOutputType(e) }}
                                                                                            checked={this.state.selectedOutputTypeOption === "Screen"}
                                                                                            name="reportingDevice" field="reportingDevice" value="Screen"
                                                                                            style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                Screen
                                                                        </label>
                                                                                    <br />
                                                                                    <label style={{ width: "240px", height: "0px" }}>
                                                                                        <input type="radio" onClick={(e) => { this.selectionOutputType(e) }}
                                                                                            checked={this.state.selectedOutputTypeOption === "Excel"}
                                                                                            name="reportingDevice" field="reportingDevice" value="Excel"
                                                                                            style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                Excel
                                                                        </label>
                                                                                </div>

                                                                                <div className="col-sm-4">
                                                                                    <div className={this.state.selectedOutputTypeOption === "Screen" ? "" : "hidden"}>
                                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                                            changed={this.handleFormChange} width="150px"
                                                                                            labelText="Form" keyId="formLabel0"
                                                                                            field="formLabel" lineStyle="oneLine"
                                                                                            value={this.state.dialogForm.formLabel}
                                                                                            dataSource="Letters" titleDialog="" labelWidth="70px"
                                                                                            lostfocused={this.handleOnBlur} shownType="dropdown" displayingCodeOnly={true}
                                                                                            focusingField={this.state.focusingField} rowIndex="0"
                                                                                            setNameAlias="code+name" fieldExt2="formHosp"
                                                                                        />
                                                                                    </div>
                                                                                    <br />
                                                                                    <div className={this.state.selectedOutputTypeOption === "Excel" ? "" : "hidden"}>
                                                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                                                            changed={this.handleFormChange} width="150px"
                                                                                            labelText="Template" keyId="formTemplate" disabled={true}
                                                                                            field="formTemplate" lineStyle="oneLine"
                                                                                            value="Treatment Summary"
                                                                                            dataSource="Letters" titleDialog="" labelWidth="70px"
                                                                                            lostfocused={this.handleOnBlur} shownType="dropdown" displayingCodeOnly={true}
                                                                                            focusingField={this.state.focusingField}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>

                                                    <div className="emptyLine"></div>

                                                    <Footer closeWindow={this.props.closeWindow}
                                                        refreshForm={this.refreshFormFnc} formId="printTxSummaryMainForm"
                                                        isFormDirty={this.state.isFormDirty && this.state.dialogForm && (this.state.dialogForm.pid && this.state.dialogForm.pid != "" || (this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != ""))}
                                                        hideSaveBtn={true}
                                                        showBtnNew="PrintTxSummary"
                                                        showBtnDelete="PrintTxSummary"
                                                        btnDeleteText="Run"
                                                        extraFnc={this.showEvent}
                                                        className="Footer2"
                                                        initialPageVisible={true}
                                                        exitFnc={this.props.closeWindow}
                                                        focusingField={this.state.focusingField}
                                                        patientAbsData={this.state.dialogForm}
                                                        ignoreExit={true}
                                                        //(Population_Label <> '' Or CID <> '') And ((Reporting_Device='Screen' And Form_Label <> '' And Form_Hosp <> '') Or Reporting_Device='Excel')
                                                        enabledDeletedBtn={
                                                            this.state.isFormDirty && this.state.dialogForm
                                                            && (this.state.dialogForm.cid && this.state.dialogForm.cid != "0" || (this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != ""))
                                                            && ((this.state.selectedOutputTypeOption == "Screen" && this.state.dialogForm.formLabel && this.state.dialogForm.formLabel != "" && this.state.dialogForm.formHosp && this.state.dialogForm.formHosp != "") || this.state.selectedOutputTypeOption == "Excel")
                                                        }
                                                        enabledAlways={true}
                                                    />

                                                </div>
                                                : <p>Print Tx Summary data loading...</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    };

        return (
            <React.Fragment>
                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {showMessageError}
                </React.Fragment>

                <React.Fragment>
                    {/*}
                    {drawingHtmlTable}
                    {*/}
                    {
                        this.state.isDrawingHtmlTable && <PrintOutputForm
                            pTitle={"Custom Form " + this.state.dialogForm.formLabel}
                            patientAbsInfo={this.props.patientAbsInfo}
                            closeWindow={this.hideDrawingHtmlTable}
                            isTxSum={true}
                            loaded={this.state.isDrawingHtmlTable}
                        />
                    }
                </React.Fragment>

                <React.Fragment>
                    {showPatientsLookup}
                </React.Fragment>

                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

PrintTxSummary.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        patientAbsInfo: state.patientAbstract.patientAbsInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        deletedDataSuccess: state.patientAbstract.deletedDataSuccess,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        waitingFor: state.patientAbstract.waitingFor,
        userId: state.auth.userId,
        selectedHospital: state.patientAbstract.selectedHospital,
        selectedPatientOnPopup: state.patientAbstract.selectedPatientOnGrid,
        selectedLabelPopInfo: state.patientAbstract.selectedLabelPopInfo,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        resetDeletedDataSuccess: (value) => dispatch(actionCreators.resetDeletedDataSuccess(value)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        setHandlerControlItem: (item) => dispatch(actionCreators.setHandlerControlItem(item)),
        showPageLoading: (flag) => dispatch(actionCreators.showPageLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),

        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),

        runPrintTxSummary: (dialogForm) => dispatch(actionCreators.runPrintTxSummary(dialogForm)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintTxSummary);