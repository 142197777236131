/* eslint-disable */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';

export default withOktaAuth(class Logout extends Component {
    componentDidMount() {
        sessionStorage.clear();
        localStorage.clear();
        sessionStorage.setItem('CrstarLogout', true);
        this.props.oktaAuth.signOut();
        return false;
    }

    render() {
        return <Redirect to="/logout" />;
    }
});