/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Input from "../../../UI/Input";
import axios from "../../../../axios-instance";
import Draggable from 'react-draggable';
import moment from "moment";
import "../AdhocReportsStyle.css";
import ConfigData from '../../../../store/config.json';
import * as actions from '../../../../store/actions/index';
import * as dialogTypes from "../../../AbstractManagement/maintDialogs";
import PopLabel from "../../PopLabel/PopulationLabel";
import "../../SelectAPop/SelectAPop.css";

class ListPop extends Component {

    downloadFile = false;
    populationLabel = null;
    state = {
        uAllItemFields: null,
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        fieldInFocus: "createPopulationLabel",
        focusingField: null,
        isHelpVisible: false,
        loaded: false,
        selectedOutputDevice: null,
        isCheckedOutputDevice: false,

        // ReportLabelsMv
        reportLabelsMv: null,
        reportLabelsMvItemFields: null,
        reportLabelsMvLoading: false,
        selectedReportLabelsMvRowIndex: 0,
        reportLabelsMvGridFocusing: false,
        setEventHandlerForReportLabelsMvGrid: false,
        reportLabelsMvMaxlengthInput: null,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        isShowConfirmDeleteMsg: false,
        isShowConfirmDeleteLabelMsg: false,
        confirmed: null,
        monitoring: null,

        allowSaveAnyway: true,

        //
        titleDialog: null,
        showClockTimer: false,
        startTimeFrom: null,
        currentTime: null,
        showMessageError: false,
        selectedCaseOption: "Screen",
        setEventHandlerForGrid: false,

        //Registrars -> Initials
        userInitials: "",
        allowSaveAnyway: true,
        availableHosps: null,
        getAvailableHosps: false,
    }

    toggleChecked = (event) => {
        const name = event.target.name;
        var dialogFormData = this.state.dialogForm;

        if (name == "includeColumnHeaders") {
            const checked = this.state.isCheckedIncludeColumnHeaders;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                isCheckedIncludeColumnHeaders: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        } else {
            const checked = this.state.isCheckedIncludeTextQualifier;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                isCheckedIncludeTextQualifier: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        }
    }

    keydownHandler = (event) => {
        if (event.target.getAttribute("class") === "copyRowBtnOnGrid") {
            if (event.key === "Tab" && !event.shiftKey) {
                if (event.target.parentNode.parentNode.nextSibling !== null) {
                    let rindex = event.target.getAttribute("data-index");
                    rindex = parseInt(rindex) + 1;
                    setTimeout(function () {
                        document.getElementById("selectColumn" + rindex) ? document.getElementById("selectColumn" + rindex).focus() : "";
                    }, 100);
                } else {
                    setTimeout(function () {
                        document.getElementById("btnAddNewLatestReportLabelsMv") ? document.getElementById("btnAddNewLatestReportLabelsMv").focus() : "";
                    }, 100);
                }
            }
        }
    }

    selectionCases = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedCaseOption: event.target.value,
            dialogForm: dialogFormData,
            isCheckedIncludeColumnHeaders: event.target.value == "Excel" ? true : this.state.isCheckedIncludeColumnHeaders
        });
    }

    //Report_Labels_Mv
    copyRowReportLabelsMvGrid = (event, selectedRow) => {
        event.preventDefault();

        var reportLabelsMvList = [...this.state.reportLabelsMv];
        if (selectedRow == reportLabelsMvList.length - 1) {
            this.addNewReportLabelsMv(event);
            return false;
        }

        this.setState(prevState => {
            const newItems = [...this.state.reportLabelsMv];
            const selectedItem = newItems[selectedRow];

            const newLabels = {};
            Object.keys(selectedItem).map((t, k) => {
                if (t === "reportLabelMvId" || t === "reportLabelId") {
                    newLabels[t] = 0;
                } else {
                    newLabels[t] = selectedItem[t];
                }
            });
            newItems.splice(selectedRow + 1, 1);
            newItems.splice(selectedRow + 1, 0, newLabels);

            // 
            const newFields = [...this.state.reportLabelsMvItemFields];
            const selectedFields = newFields[selectedRow];

            const newField = {};
            Object.keys(selectedFields).map((t, k) => {
                if (t === "reportLabelMvId" || t === "reportLabelId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.splice(selectedRow + 1, 1);
            newFields.splice(selectedRow + 1, 0, newField);

            return {
                reportLabelsMv: newItems,
                reportLabelsMvItemFields: newFields,
                isFormDirty: true,
            };
        });

        return false;
    }

    deleteReportLabelsMvGrid = (flg) => {
        let selectedRow = this.state.selectedReportLabelsMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.reportLabelsMv.length) {
            selectedRow = this.state.reportLabelsMv.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.reportLabelsMv];
            if (newItems.length === 1) {
                const _newItems = [...this.state.reportLabelsMv];
                const firstItem = _newItems[0];

                const newItem = {};
                Object.keys(firstItem).map((t, k) => {
                    if (t === "reportLabelId" || t === "reportLabelMvId") {
                        //newItem[t] = 0;
                    } else {
                        newItem[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newItem);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.reportLabelsMvItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.reportLabelsMvItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return {
                reportLabelsMv: newItems,
                reportLabelsMvItemFields: newFields,
                selectedReportLabelsMvRowIndex: selectedRow,
                isFormDirty: flg == "1" ? true : this.state.isFormDirty
            };
        });

        flg === "1" ? this.setState({ isFormDirty: true }) : "";
    }

    hideConfirmDeleteMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isShowConfirmDeleteMsg: false });

        if (event.target.value === "yes" && this.state.monitoring) {
            switch (this.state.monitoring) {
                case "reportLabelsMv":
                    this.deleteReportLabelsMvGrid("1");
                    break;
                default:
                    break;
            }
        }
        return false;
    }

    hideConfirmDeleteLabelMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        if (event.target.value === "yes") {
            if (this.state.deleteLabelType == 'rpt')
                this.deleteLabel();
        }

        this.setState({ isShowConfirmDeleteLabelMsg: false, deleteLabelType: null });

        return false;
    }

    insertNewReportLabelsMvGrid = (insertedRow) => {
        this.setState(prevState => {
            const newItems = [...this.state.reportLabelsMv];
            const firstItems = newItems[0];

            const newLabels = {};
            Object.keys(firstItems).map((t, k) => {
                if (t === "reportLabelMvId" || t === "reportLabelId" /*|| t === "globalItemNumber" || t === "displayLength"*/) {
                    newLabels[t] = 0;
                } else {
                    newLabels[t] = "";
                }
            });
            newItems.splice(insertedRow, 0, newLabels);

            // 
            const newFields = [...this.state.reportLabelsMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "reportLabelMvId" || t === "reportLabelId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.splice(insertedRow, 0, newField);

            return {
                reportLabelsMv: newItems,
                reportLabelsMvItemFields: newFields,
            };
        });
        return false;
    }

    getHospSecurity = () => {
        let url = this.props.clientgroup + "/GetUserByEmail";
        var _this = this;
        axios.post(url, { email: this.props.userId, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }
                const availableHospsRpt = res.data.user !== null && res.data.user.availableHospsRpt !== null ? res.data.user.availableHospsRpt : "";
                if (!res.data.error && res.data.user !== null
                    && availableHospsRpt !== null && availableHospsRpt !== "") {
                    var _availableHospsRptArr = availableHospsRpt.split('|');
                    _availableHospsRptArr = _availableHospsRptArr.filter((obj, idx) => {
                        return obj && obj != "";
                    });
                    _this.setState({ availableHosps: _availableHospsRptArr.join(',') });
                } else {
                    _this.setState({ availableHosps: ",All," });//#Full control
                }
                return false;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
        return false;
    }

    addEventListenerFnc = (om) => {
        var reportLabelsMvGrid = document.getElementById('reportLabelsMvListArea');
        reportLabelsMvGrid.addEventListener('keydown', function (e) {
            if (e.keyCode == 45 && e.target.getAttribute("data-index")) {// Insert key
                var insertedRowIdx = om.state.selectedReportLabelsMvRowIndex;
                om.insertNewReportLabelsMvGrid(insertedRowIdx);
                setTimeout(function () {
                    var selectedIndex = e.target.getAttribute("data-index");
                    document.getElementById('globalItemNumber' + selectedIndex) ? document.getElementById('globalItemNumber' + selectedIndex).focus() : "";//e.target.focus();
                    return false;
                }, 10);
                return false;
            } else if (e.keyCode == 46 && e.target.getAttribute("data-index")) {// Delete key
                om.deleteSelectedReportLabelsMv();//btnDeleteSelectedReportLabelsMv
                return false;
            } else if (e.key === "Tab" && !e.shiftKey && e.target.name == "limitingValues") {
                let rindex = e.target.getAttribute("data-index");
                var currentRow = e.target.parentNode && e.target.parentNode.parentNode && e.target.parentNode.parentNode.parentNode ? e.target.parentNode.parentNode.parentNode : null;
                var globalItemNumber = currentRow && currentRow.childNodes[1] && currentRow.childNodes[1].querySelector("#globalItemNumber" + rindex)
                    && currentRow.childNodes[1].querySelector("#globalItemNumber" + rindex).value ? currentRow.childNodes[1].querySelector("#globalItemNumber" + rindex).value : "";
                if (e.target.name == "limitingValues" && globalItemNumber != "" && currentRow && !currentRow.nextSibling) {
                    document.getElementById("btnAddNewLatestReportLabelsMv") ? document.getElementById("btnAddNewLatestReportLabelsMv").click() : "";
                    rindex = parseInt(rindex) + 1;
                    setTimeout(function () {
                        document.getElementById("globalItemNumber" + rindex) ? document.getElementById("globalItemNumber" + rindex).focus() : "";
                    }, 100);
                } else if (e.target.name == "limitingValues" && currentRow && !currentRow.nextSibling) {
                    setTimeout(function () {
                        document.getElementById("btnAddNewLatestReportLabelsMv") ? document.getElementById("btnAddNewLatestReportLabelsMv").focus() : "";
                    }, 100);
                }
            }
            return false;
        });

        return false;
    }

    // ReportLabelsMv:: Selection Creteria grid
    handleReportLabelsMvLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value ? event.target.value : "";

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";

            //this.props.checkValidInput(name, value, table, key, index);
        } else {
            this.setState(prevState => {
                const newItems = [...prevState.reportLabelsMvItemFields];
                newItems[index][name] = "";
                return { reportLabelsMvItemFields: newItems };
            });
        }

        let currentValue = value;
        if (name == "breakSortList" && currentValue == "") {
            this.setState(prevState => {
                const newItems = [...prevState.reportLabelsMv];
                newItems[index][name] = "L";
                return { reportLabelsMv: newItems };
            });
        } else if (name == "codeDescription" && currentValue == "") {
            this.setState(prevState => {
                const newItems = [...prevState.reportLabelsMv];
                newItems[index][name] = "C";
                return { reportLabelsMv: newItems };
            });
        } else if (name == "displayLength" && currentValue == "") {
            this.setState(prevState => {
                const newItems = [...prevState.reportLabelsMv];
                newItems[index][name] = "20";
                return { reportLabelsMv: newItems };
            });

            //Fill_Item_Desc
        } else if (name == "globalItemNumber") {
            /*
            if (value && value != "") {
                var isIntVal = false;
                try {
                    parseInt(value);
                    isIntVal = true;
                } catch (ex) {
                }
                //Fill_Item_Desc
                if (isIntVal) {
                    let url = this.props.clientgroup + "/FillItemDesc?itemNbr=" + value;
                    var _this = this;
                    axios.get(url)
                        .then(res => {
                            const globalDir = res.data.globalDir;
                            if (!res.data.error && globalDir && globalDir.itemNbr) {
                                //
                                _this.setState(prevState => {
                                    const newItems = [...prevState.reportLabelsMv];
                                    newItems[index]["itemDescription"] = globalDir.itemDesc;
                                    newItems[index]["fieldName"] = globalDir.columnName;
                                    newItems[index]["fileName"] = globalDir.fileName;
                                    newItems[index]["dataType"] = globalDir.dataType;

                                    return { reportLabelsMv: newItems };
                                });
                            } else {
                                //clear all set values
                                _this.setState(prevState => {
                                    const newItems = [...prevState.reportLabelsMv];
                                    newItems[index]["itemDescription"] = "";
                                    newItems[index]["fieldName"] = "";
                                    newItems[index]["fileName"] = "";
                                    newItems[index]["dataType"] = "";

                                    return { reportLabelsMv: newItems };
                                });
                            }
                            return false;
                        });
                    return false;
                }

            } else {
                //clear all set values
                _this.setState(prevState => {
                    const newItems = [...prevState.reportLabelsMv];
                    newItems[index]["itemDescription"] = "";
                    newItems[index]["fieldName"] = "";
                    newItems[index]["fileName"] = "";
                    newItems[index]["dataType"] = "";

                    return { reportLabelsMv: newItems };
                });
            }
            */
            let table = "Global_Dir";
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";

            this.props.checkValidInput(name, value, table, key, index);
        } else {
            //
        }
        return false;
    }

    handleReportLabelsMvGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        let name2 = null;
        let value2 = null;
        if (event.target2) {
            name2 = event.target2.name;
            value2 = event.target2.value;
        }

        this.setState(prevState => {
            const newItems = [...prevState.reportLabelsMv];
            newItems[index][name] = value;

            if (name2 && value2) {
                newItems[index][name2] = value2;

                if (name2 == "globalColumnName") newItems[index]["dataType"] = value2;
            }

            return { reportLabelsMv: newItems, isFormDirty: true };
        });

        /*
        //Fill_Item_Desc (Ref to Fill_Dictionary_Name)
        if (name == "globalItemNumber") {
            if (value && value != "") {
                var isIntVal = false;
                try {
                    parseInt(value);
                    isIntVal = true;
                } catch (ex) {
                }

                if (isIntVal) {
                    let url = this.props.clientgroup + "/FillItemDesc?itemNbr=" + value;
                    var _this = this;
                    axios.get(url)
                        .then(res => {
                            const globalDir = res.data.globalDir;
                            if (!res.data.error && globalDir && globalDir.itemNbr) {
                                //
                                _this.setState(prevState => {
                                    const newItems = [...prevState.reportLabelsMv];
                                    newItems[index]["itemDescription"] = globalDir.itemDesc;
                                    newItems[index]["fieldName"] = globalDir.columnName;
                                    newItems[index]["fileName"] = globalDir.fileName;
                                    newItems[index]["dataType"] = globalDir.dataType;

                                    return { reportLabelsMv: newItems };
                                });
                            } else {
                                //clear all set values
                                _this.setState(prevState => {
                                    const newItems = [...prevState.reportLabelsMv];
                                    newItems[index]["itemDescription"] = "";
                                    newItems[index]["fieldName"] = "";
                                    newItems[index]["fileName"] = "";
                                    newItems[index]["dataType"] = "";

                                    return { reportLabelsMv: newItems };
                                });
                            }
                            return false;
                        });
                    return false;
                }

            } else {
                //clear all set values
                this.setState(prevState => {
                    const newItems = [...prevState.reportLabelsMv];
                    newItems[index]["itemDescription"] = "";
                    newItems[index]["fieldName"] = "";
                    newItems[index]["fileName"] = "";
                    newItems[index]["dataType"] = "";

                    return { reportLabelsMv: newItems };
                });
            }
        } else {
            //
        }
        */

        return false;
    }

    addNewReportLabelsMv = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.reportLabelsMv];
            const firstLabels = newItems[0];

            const newLabels = {};
            Object.keys(firstLabels).map((t, k) => {
                if (t === "reportLabelMvId" || t === "reportLabelId") {
                    newLabels[t] = 0;
                } else {
                    newLabels[t] = "";
                }
            });
            newItems.push(newLabels);

            // 
            const newFields = [...this.state.reportLabelsMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "reportLabelMvId" || t === "reportLabelId") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { reportLabelsMv: newItems, reportLabelsMvItemFields: newFields };
        });
    }

    deleteSelectedReportLabelsMv = (event) => {
        event ? event.preventDefault() : null;

        let selectedRow = this.state.selectedReportLabelsMvRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.reportLabelsMv.length) {
            selectedRow = this.state.reportLabelsMv.length - 1;
        }

        if (this.state.reportLabelsMv[selectedRow] && this.state.reportLabelsMv[selectedRow]["reportLabelMvId"] !== undefined && this.state.reportLabelsMv[selectedRow]["reportLabelMvId"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "reportLabelsMv" });
        } else {
            this.deleteReportLabelsMvGrid("0");
        }
        return false;
    }

    setSelectionReportLabelsMvRow = (event, index) => {
        this.setState({ selectedReportLabelsMvRowIndex: index });
    }

    initClock() {
        var _this = this;
        setInterval(function () {
            /*
            var today = new Date(),
                time = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");
            */
            //d.toLocaleTimeString();

            var startTime = _this.state.endTime ? _this.state.endTime : (_this.state.startTimeFrom ? _this.state.startTimeFrom : "");
            var time = "";
            if (startTime && startTime.split(" ").length > 0) {
                var hms = startTime.split(" ")[0];
                var ap = startTime.split(" ")[1];

                var hour = parseInt(hms.split(":")[0]);
                var min = parseInt(hms.split(":")[1]);
                var sec = parseInt(hms.split(":")[2]);
                sec = 1 + sec;
                if (sec == 60) {
                    sec = 0;
                    min = 1 + min;
                }

                if (min == 60) {
                    min = 0;
                    hour = 1 + hour;
                }

                if (hour == 12) {
                    hour = 0;
                    ap == "PM" ? ap = "AM" : ap = "PM";
                }

                time = (hour < 12 ? hour : hour - 12)
                    + ':' + (min < 10 ? "0" + min : min)
                    + ':' + (sec < 10 ? "0" + sec : sec)
                    + " " + ap;
            }

            _this.setState({ currentTime: time, endTime: time });

            return false;
        }, 1000);
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showMessageError: false,
            hasError: false,
            confirmed: true
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector("#BUTTON_RUN_REPORT") ? document.querySelector("#BUTTON_RUN_REPORT").focus() : "";
        }, 0);

        return false;
    }

    componentDidMount() {
        this.setState({ isLoading: true, isDrawingHtmlTable: false, loadedPage: false });

        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);

        // Add all tabs in maintDialogs.js
        dialogTypes.adhocReportsTabsDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog.name, dialog.id);
            this.props.setEnabledStatus(dialog.name);
        });

        if (!this.state.dialogForm && this.props.selectedReportLabelsInfo) {
            this.setFormData(this.props.selectedReportLabelsInfo);
        }
        /*
        // Loading form data...
        this.props.selectReportLabels();
        */
        this.props.selectAPopLabel("");
    }

    componentDidUnmount() {
        this.props.selectReportLabels("");

        this.setState({
            isDrawingHtmlTable: false,
            Fn_Html_Display_URL: null,
            publicFolder: null,
            showClockTimer: false,

            reload: true,
            // ReportLabelsMv
            reportLabelsMv: null,
            reportLabelsMvItemFields: null,
            reportLabelsMvLoading: false,
            selectedReportLabelsMvRowIndex: 0,
            reportLabelsMvGridFocusing: false,
            reportLabelsMvMaxlengthInput: null,
            isShowConfirmDeleteMsg: false,
            isShowConfirmDeleteLabelMsg: false,
            confirmed: null,
            monitoring: null,
        });
    }

    componentWillMount() {
        this.props.setChildrenOpening(false);
        this.setState({ setEventHandlerForGrid: false, availableHosps: null, isDrawingHtmlTable: false, loadedPage: false });

        this.initClock();
    }

    componentDidUpdate(prevProps) {
        if (this.props.error && this.props.errorMessage) {
            this.setState({
                showClockTimer: false,
                showMessageError: true,
                dialogForm: {
                    ...this.state.dialogForm,
                    startTime: this.props.selectedReportLabelsInfo ? (this.props.selectedReportLabelsInfo.startTime ? this.props.selectedReportLabelsInfo.startTime : this.props.selectedReportLabelsInfo.startTimeSAP) : "",
                    endTime: this.props.selectedReportLabelsInfo ? (this.props.selectedReportLabelsInfo.endTime ? this.props.selectedReportLabelsInfo.endTime : this.props.selectedReportLabelsInfo.endTimeSAP) : "",
                    numberOfRecords: this.props.selectedReportLabelsInfo ? this.props.selectedReportLabelsInfo.numberOfRecords : "",
                },
                errorMessage: this.props.errorMessage,
            });

            document.querySelector('#BUTTON_RUN_REPORT') ? document.querySelector('#BUTTON_RUN_REPORT').focus() : null;

            this.props.clearErrorMessage();
            return false;
        }

        /*---*/
        if (!this.state.setEventHandlerForGrid) {
            if (document.getElementById('reportLabelsMvListArea')) {
                this.addEventListenerFnc(this);
                this.setState({ setEventHandlerForGrid: true });
            }
        }
        /*---*/

        if (!this.state.getAvailableHosps) {
            this.getHospSecurity();
            this.setState({ getAvailableHosps: true });
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.dialogForm && this.state.uAllItemFields
        ) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                // updating the grid
                if (this.props.currentSelectedIndex !== -1) {
                    // ReportLabelsMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.reportLabelsMv];
                        if (newItems[this.props.currentSelectedIndex] && this.props.currentSelectedField == "globalItemNumber" && this.props.commonLookupItem.name) {
                            newItems[this.props.currentSelectedIndex]["globalItemNumber"] = this.props.commonLookupItem.code;
                            newItems[this.props.currentSelectedIndex]["globalItemDescription"] = this.props.commonLookupItem.name;
                        }

                        const newFields = [...prevState.reportLabelsMvItemFields];
                        if (newFields[this.props.currentSelectedIndex]) {
                            newFields[this.props.currentSelectedIndex][this.props.currentSelectedField] = "";
                        }

                        return { reportLabelsMv: newItems, reportLabelsMvItemFields: newFields };
                    });
                } else {
                    this.setState({
                        uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "" },
                        // And also update name again
                        dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                    });
                }
            } else {
                /*
                // updating the grid
                if (this.props.currentSelectedIndex != -1) {
                    // ReportLabelsMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.reportLabelsMvItemFields];

                        if (newItems && newItems[this.props.currentSelectedIndex] && this.props.currentSelectedField == "selectColumn") {
                            newItems[this.props.currentSelectedIndex]["dataType"] = "";
                        }

                        if (newItems && newItems[this.props.currentSelectedIndex]) {
                            //newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "IN_VALID";
                        }
                        return { reportLabelsMvItemFields: newItems };
                    });
                } else {
                    this.setState({
                        //uAllItemFields: { ...this.state.uAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        //dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                    });
                }
                */
            }
            this.props.clearCommonLookupItem();
        }

        if ((!this.state.dialogForm && this.props.selectedReportLabelsInfo)
            || (prevProps.selectedReportLabelsInfo && this.props.selectedReportLabelsInfo && (prevProps.selectedReportLabelsInfo.reportLabelId !== this.props.selectedReportLabelsInfo.reportLabelId || prevProps.selectedReportLabelsInfo.reportLabel !== this.props.selectedReportLabelsInfo.reportLabel))
            || (prevProps.selectedReportLabelsInfo && this.props.selectedReportLabelsInfo && (prevProps.selectedReportLabelsInfo.startTime !== this.props.selectedReportLabelsInfo.startTime || prevProps.selectedReportLabelsInfo.endTime !== this.props.selectedReportLabelsInfo.endTime || (this.props.selectedReportLabelsInfo.endTime && this.state.endingTime !== this.props.selectedReportLabelsInfo.endTime)))
            || (this.state.reload && this.props.selectedReportLabelsInfo)
            || (this.state.dialogForm && this.props.selectedReportLabelsInfo && this.props.selectedReportLabelsInfo.reportLabel != this.state.dialogForm.reportLabel && !this.state.reportLabelHasChanged)
        ) {
            this.setFormData(this.props.selectedReportLabelsInfo);
        }

        //
        if (this.state.userInitials === "") {
            var logedOnUser = this.props.userId;
            var _this = this;
            //axios.get(this.props.clientgroup + "/GetUserInitials?uid=" + logedOnUser)
            let url = this.props.clientgroup + "/GetUserInitials";
            let params = { uid: logedOnUser, accessToken: localStorage.getItem('token') };

            axios.post(url, params)
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        let userInitials = "";
                        if (res.data.message !== null && res.data.message !== "") {
                            userInitials = res.data.message;
                        } else {
                            userInitials = logedOnUser.substr(0, 20);
                        }
                        _this.setState({ userInitials: userInitials });
                    } else {
                        let userInitials = logedOnUser.substr(0, 20);
                        _this.setState({ userInitials: userInitials });
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;
                });
        }

        //
        if (this.props.selectedLabelPopInfo && this.props.selectedLabelPopInfo.populationLabel && this.populationLabel != this.props.selectedLabelPopInfo.populationLabel) {
            this.populationLabel = this.props.selectedLabelPopInfo.populationLabel;
            //this.setFormDataOnTop(this.props.selectedLabelPopInfo);
        }

        // ReportLabelsMv List
        if (this.props.selectedReportLabelsInfo && !this.state.reportLabelsMv && !this.state.reportLabelsMvLoading) {
            this.setState({ reportLabelsMvLoading: true });
            var _this = this;
            //axios.get(this.props.clientgroup + "/GetReportLabelsMvList?reportLabelId=" + this.props.selectedReportLabelsInfo.reportLabelId)
            axios.post(this.props.clientgroup + "/GetReportLabelsMvList",
                { reportLabelId: this.props.selectedReportLabelsInfo.reportLabelId, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        var reportLabelsMvList = res.data.reportLabelsMvList;//ReportLabelsMvList
                        let reportLabelsMvArr = [];
                        //
                        let maxLengthItem = [];//#2
                        reportLabelsMvList.map((obj) => {
                            let fields = {};
                            Object.keys(obj).map((t) => {
                                fields[t] = "";
                            });
                            reportLabelsMvArr.push(fields);
                            maxLengthItem.length == 0 ? maxLengthItem.push(fields) : null;//#2
                        });

                        // get maxlength for Report_Labels_Mv grid
                        //axios.get(_this.props.clientgroup + "/GetTableStructure?table=Report_Labels_Mv")

                        let url = _this.props.clientgroup + "/GetTableStructure";
                        let params = { table: "Report_Labels_Mv", accessToken: localStorage.getItem('token') };

                        axios.post(url, params)
                            .then(res => {
                                //update new accessToken again
                                var newAccessToken = res && res.data ? res.data.accessToken : null;
                                if (newAccessToken) {
                                    localStorage.setItem('token', newAccessToken);
                                }

                                if (res.data !== null && res.data.result !== "") {
                                    let maxLengthValue = res.data.result;

                                    maxLengthValue = maxLengthValue.split(',');
                                    for (var i = 0; i < maxLengthValue.length; i++) {
                                        let obj = maxLengthValue[i].split(':');
                                        maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                    }
                                }
                            })
                            .catch(function (error) {
                                console.log(error);

                                var btnLogout = document.getElementById("logoutButtonArea");
                                if (btnLogout) {
                                    btnLogout.querySelector("a").click();
                                }
                                return false;
                            });

                        _this.setState({
                            reportLabelsMv: reportLabelsMvList,
                            reportLabelsMvItemFields: reportLabelsMvArr,
                            reportLabelsMvMaxlengthInput: { ...maxLengthItem },
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;
                });
        }

        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
        //<--
    }

    async getTableStructure() {
        //let url = this.props.clientgroup + "/GetTableStructure?table=Report_Labels";
        let url = this.props.clientgroup + "/GetTableStructure";
        let params = { table: "Report_Labels", accessToken: localStorage.getItem('token') };

        let res = await axios
            .post(url, params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        let newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        };

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    drawingHtmlTable(dataForm) {
        this.setState({
            isDrawingHtmlTable: true,
            Fn_Html_Display_URL: dataForm.htmlDisplay,//fnHtmlDisplayUrl
            publicFolder: dataForm.publicFolder,
            showClockTimer: false,//hide clock timer
            loadedPage: true,
        });
        return false;
    }

    hideDrawingHtmlTable = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.setState({ isDrawingHtmlTable: false, loadedPage: false });

        //Deleting output file at public folder...
        const fileName = (this.state.publicFolder);
        var _this = this;
        axios.post(ConfigData.CRStarAPI_URL + _this.props.clientgroup + "/DeleteOutputFile", { file: fileName, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    _this.setState({ publicFolder: null });
                }
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        return false;
    }

    printTable = () => {
        var printStyle = this.state.printStyle;

        var printContent = document.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Adhoc Report ' + (this.state.dialogForm ? this.state.dialogForm.reportLabel : "") + ' for ' + (this.state.dialogForm ? this.state.dialogForm.populationLabel : "") + '</title><style type="text/css">';
        html += printStyle + '</style></head><body><div id="printableContent">';

        html += (printContent ? printContent.innerHTML : "") + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
        return false;
    }

    setFormData = (selectedReportLabelsInfo) => {
        var selectedLabelPopInfo = this.props.selectedLabelPopInfo ? this.props.selectedLabelPopInfo : selectedReportLabelsInfo;

        var populationLabel = this.populationLabel ? this.populationLabel : (selectedLabelPopInfo ? selectedLabelPopInfo.populationLabel : "");
        if (!populationLabel) {
            populationLabel = document.getElementById("populationLabel") ? document.getElementById("populationLabel").value : "";
        }

        if (!populationLabel && this.props.selectedReportLabelsInfo && this.props.selectedReportLabelsInfo.populationLabel) {
            populationLabel = this.props.selectedReportLabelsInfo.populationLabel;
        }

        this.setState({
            initialDialogForm: {
                ...selectedReportLabelsInfo,
                ["requestDate"]: (selectedReportLabelsInfo != null && selectedReportLabelsInfo.requestDate !== null && selectedReportLabelsInfo.requestDate !== "" ? moment(new Date(selectedReportLabelsInfo.requestDate)).format("MM/DD/YYYY") : ""),
                ["requestDateSAP"]: (selectedReportLabelsInfo != null && selectedReportLabelsInfo.requestDateSAP !== null && selectedReportLabelsInfo.requestDateSAP !== "" ? moment(new Date(selectedReportLabelsInfo.requestDateSAP)).format("MM/DD/YYYY") : ""),
                populationLabel: populationLabel,
                fieldDelimiter: selectedReportLabelsInfo != null && selectedReportLabelsInfo.fieldDelimiter !== null && selectedReportLabelsInfo.fieldDelimiter !== "" ? selectedReportLabelsInfo.fieldDelimiter : ",",
            },
            dialogForm: {
                ...selectedReportLabelsInfo,
                ["requestDate"]: (selectedReportLabelsInfo != null && selectedReportLabelsInfo.requestDate !== null && selectedReportLabelsInfo.requestDate !== "" ? moment(new Date(selectedReportLabelsInfo.requestDate)).format("MM/DD/YYYY") : ""),
                ["requestDateSAP"]: (selectedReportLabelsInfo != null && selectedReportLabelsInfo.requestDateSAP !== null && selectedReportLabelsInfo.requestDateSAP !== "" ? moment(new Date(selectedReportLabelsInfo.requestDateSAP)).format("MM/DD/YYYY") : ""),
                populationLabel: populationLabel,
                fieldDelimiter: selectedReportLabelsInfo != null && selectedReportLabelsInfo.fieldDelimiter !== null && selectedReportLabelsInfo.fieldDelimiter !== "" ? selectedReportLabelsInfo.fieldDelimiter : ",",
            },
            isFormDirty: this.props.isFormDirty,
            reload: false,
            changed: true,
            loaded: true,

            showClockTimer: false,
            titleDialog: null,

            ["selectedCaseOption"]: selectedReportLabelsInfo && selectedReportLabelsInfo.outputDevice ? selectedReportLabelsInfo.outputDevice : "Screen",//default value
            endingTime: selectedReportLabelsInfo.endTime,
        });

        if ((!this.state.uAllItemFields && selectedReportLabelsInfo)) {
            let fields = {};
            Object.keys(selectedReportLabelsInfo).map((t) => {
                fields[t] = "";
            });

            this.setState({ uAllItemFields: fields, pageMaxlengthInput: fields });
        };

        // Processing output...
        if (selectedReportLabelsInfo.outputDevice == "Screen" && selectedReportLabelsInfo.endTime
            && selectedReportLabelsInfo.fnHtmlDisplayUrl && this.props.showReport) {
            this.drawingHtmlTable(selectedReportLabelsInfo);
        } else if (!this.downloadFile && selectedReportLabelsInfo && selectedReportLabelsInfo.outputDevice &&
            ((selectedReportLabelsInfo.outputDevice == "Excel" && selectedReportLabelsInfo.fnExcelPath) ||
                (selectedReportLabelsInfo.outputDevice == "File CSV" && selectedReportLabelsInfo.fnCsvUrl) ||
                (selectedReportLabelsInfo.outputDevice == "File Fixed-Width" && selectedReportLabelsInfo.fnFixedWPath) ||
                (selectedReportLabelsInfo.outputDevice == "Xml" && selectedReportLabelsInfo.fnXmlDisplayUrl))
            && this.props.isDownloadFile
        ) {
            this.downloadFileOutput(selectedReportLabelsInfo, selectedReportLabelsInfo.outputDevice);

            if ((selectedReportLabelsInfo.outputDevice == "File CSV" && selectedReportLabelsInfo.fnCsvUrl) ||
                (selectedReportLabelsInfo.outputDevice == "File Fixed-Width" && selectedReportLabelsInfo.fnFixedWPath)) {
                this.setState({
                    Fn_CSV_Layout_URL: selectedReportLabelsInfo.fnCsvLayoutUrl,
                    Fn_FixedW_Layout_Path: selectedReportLabelsInfo.fnFixedWLayoutPath,
                });
            }
        } else {
            this.setState({
                Fn_CSV_Layout_URL: selectedReportLabelsInfo.fnCsvLayoutUrl,
                Fn_FixedW_Layout_Path: selectedReportLabelsInfo.fnFixedWLayoutPath,
                Fn_Xml_Display_URL: selectedReportLabelsInfo.fnXmlDisplayUrl,
            });
        }

        return false;
    }

    downloadFileLayout(selectedReportLabelsInfo, type) {
        var fileUrl = (type == "File CSV" ? selectedReportLabelsInfo.fnCsvLayoutUrl : selectedReportLabelsInfo.fnFixedWLayoutPath);
        if (!fileUrl || fileUrl == "null") fileUrl = "";

        var url = this.props.clientgroup + "/DownloadFile";
        var _this = this;
        axios.post(url, {
            urlOutputFile: fileUrl,
            format: "Layout",
            method: 'POST',
            responseType: 'blob',//important
            accessToken: localStorage.getItem('token')
        }).then((response) => {
            const _url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = _url;

            // get file name from url
            var filename = fileUrl.substring(fileUrl.lastIndexOf('\\') + 1);
            console.log("filename=" + filename);

            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            _this.downloadFile = true;
            return false;
        })
            .catch(function (error) {
                console.log(error);
            });

        return false;
    }

    // declare the function
    downloadAsDataURL(url) {
        return new Promise((resolve, reject) => {
            fetch(url)
                .then(res => res.blob())
                .then(blob => {
                    const reader = new FileReader()
                    reader.readAsDataURL(blob)
                    reader.onloadend = () => resolve(reader.result)
                    reader.onerror = err => reject(err)
                })
                .catch(err => reject(err))
        })
    }

    downloadFileOutput(selectedReportLabelsInfo, type) {
        var fileUrl = (type == "File CSV" ? selectedReportLabelsInfo.fnCsvUrl
            : (type == "File Fixed-Width" ? selectedReportLabelsInfo.fnFixedWPath
                : (type == "Xml" ? selectedReportLabelsInfo.fnXmlDisplayUrl : selectedReportLabelsInfo.fnExcelPath)));

        if (!fileUrl || fileUrl == "null") fileUrl = "";

        var url = this.props.clientgroup + "/DownloadFile";
        var _this = this;
        axios.post(url, {
            urlOutputFile: fileUrl,
            method: 'POST',
            responseType: 'blob',//important
            accessToken: localStorage.getItem('token')
        }).then((response) => {
            var filename = fileUrl.substring(fileUrl.lastIndexOf('\\') + 1);
            const link = document.createElement('a');

            //
            if (type == "Excel" && selectedReportLabelsInfo.fnExcelPath) {
                let excel;
                if (selectedReportLabelsInfo.fnExcelPath.indexOf('/') != -1) {
                    excel = selectedReportLabelsInfo.fnExcelPath.substring(selectedReportLabelsInfo.fnExcelPath.lastIndexOf('/') + 1);
                } else {
                    excel = selectedReportLabelsInfo.fnExcelPath.substring(selectedReportLabelsInfo.fnExcelPath.lastIndexOf('\\') + 1);
                }
                link.href = this.props.clientgroup + "/" + excel;

                // simply use it like this
                _this.downloadAsDataURL(link.href)
                    .then((res) => {
                        console.log(res)
                    })
                    .catch((err) => {
                        console.error(err)
                    });
            } else if (type == "File CSV" && selectedReportLabelsInfo.fnCsvUrl) {
                let csv;
                if (selectedReportLabelsInfo.fnCsvUrl.indexOf('/') != -1) {
                    csv = selectedReportLabelsInfo.fnCsvUrl.substring(selectedReportLabelsInfo.fnCsvUrl.lastIndexOf('/') + 1);
                } else {
                    csv = selectedReportLabelsInfo.fnCsvUrl.substring(selectedReportLabelsInfo.fnCsvUrl.lastIndexOf('\\') + 1);
                }
                link.href = this.props.clientgroup + "/" + csv;

                // simply use it like this
                _this.downloadAsDataURL(link.href)
                    .then((res) => {
                        console.log(res)
                    })
                    .catch((err) => {
                        console.error(err)
                    });
            } else {
                const _url = window.URL.createObjectURL(new Blob([response.data]));
                link.href = _url;
            }

            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();

            _this.downloadFile = true;
            return false;
        })
            .catch(function (error) {
                console.log(error);
            });

        return false;
    }

    //save form
    saveReportLabelsInfo = (event) => {
        event.preventDefault();

        var popLabelComponent = document.getElementById("btnCallToParentFunc");
        if (popLabelComponent) {
            popLabelComponent.click();
        }

        this.props.setChildrenOpening(false);

        let dialogFormData = this.state.dialogForm;
        var populationLabel = document.getElementById("populationLabel") ? document.getElementById("populationLabel").value : "";
        dialogFormData.populationLabel = populationLabel;
        if (dialogFormData.populationLabel == "") {
            return false;
        }
        console.log("populationLabel = " + populationLabel, dialogFormData.populationLabel);

        const allowSaveAnyway = this.state.allowSaveAnyway;

        // TODO - check form validation
        let isValid = true;
        Object.keys(dialogFormData).map((t) => {
            if (dialogFormData[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let reportLabelsMv = this.state.reportLabelsMv;
            dialogFormData.reportLabelsMv = reportLabelsMv;

            dialogFormData.actionType = "Save_Only";
            dialogFormData.reportingDevice = this.state.selectedCaseOption ? this.state.selectedCaseOption : "Screen";

            dialogFormData.userId = this.props.userId;
            dialogFormData.clientAlias = this.props.clientgroup;

            this.props.saveReportLabelsInfo(dialogFormData);//{dialog.Object}.submit('Save_Only');
            return false;
        }
    }

    handleOnFocus = (event, index, alias) => {
        this.props.storeToGlobal(null);
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
        if (alias) {
            switch (alias) {
                case "selectedReportLabelsMvRowIndex":
                    this.setState({ selectedReportLabelsMvRowIndex: index });
                    break;
            }
        }
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true,
                Label_RESERVED: event.target.value,//the original value is population label
            });

            // load all related infos after clicked
            if (event.target.name === "reportLabel") {
                var label = event.target.value;
                var _this = this;
                //let url = this.props.clientgroup + "/CheckReportLabelsHasExisted?label=" + label;
                let url = this.props.clientgroup + "/CheckReportLabelsHasExisted";
                axios.post(url,
                    { label: label, accessToken: localStorage.getItem('token') })
                    .then(res => {
                        //update new accessToken again
                        var newAccessToken = res && res.data ? res.data.accessToken : null;
                        if (newAccessToken) {
                            localStorage.setItem('token', newAccessToken);
                        }

                        if (!res.data.error) {
                            setTimeout(function () {
                                _this.Get_Data(label);
                            }, 0);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);

                        var btnLogout = document.getElementById("logoutButtonArea");
                        if (btnLogout) {
                            btnLogout.querySelector("a").click();
                        }
                        return false;

                    });

                return false;
            }

        } else {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value
                },
                isFormDirty: true,
                reportLabelHasChanged: true,//event.target.name == "reportLabel" ? true : false,
            });
        }
    }

    async Get_Data(reportLabel) {
        var _this = this;
        //Labels
        await _this.props.selectReportLabels(reportLabel);

        if (_this.props.selectedReportLabelsInfo) {
            setTimeout(function () {
                _this.setState({
                    reload: true,

                    // ReportLabelsMv
                    reportLabelsMv: null,
                    reportLabelsMvItemFields: null,
                    reportLabelsMvLoading: false,
                    selectedReportLabelsMvRowIndex: 0,
                    reportLabelsMvGridFocusing: false,
                    reportLabelsMvMaxlengthInput: null,

                    isShowConfirmDeleteMsg: false,
                    isShowConfirmDeleteLabelMsg: false,
                    confirmed: null,
                    monitoring: null,
                });
            }, 1000);
        } else {
            _this.setState({
                reload: true,
                // ReportLabelsMv
                reportLabelsMv: null,
                reportLabelsMvItemFields: null,
                reportLabelsMvLoading: false,
                selectedReportLabelsMvRowIndex: 0,
                reportLabelsMvGridFocusing: false,
                reportLabelsMvMaxlengthInput: null,
                isShowConfirmDeleteMsg: false,
                isShowConfirmDeleteLabelMsg: false,
                confirmed: null,
                monitoring: null,
            });
        }

        return false;
    }

    User_Defined_Date_Format(myDate) {
        //This code tries to fill in slashes and the two digit century when a user doesn't enter them
        var fmtDate = "";

        myDate = myDate.replace(/[-]/gi, '/');

        if (myDate.length > 10) {
            myDate = myDate.substr(0, 10);
        }

        if ((myDate.length == 6 || myDate.length == 7 || myDate.length == 8 || myDate.length == 9) && myDate.split('/').length == 3) {
            var mm = myDate.split('/')[0];
            var dd = myDate.split('/')[1];
            var yy = myDate.split('/')[2];

            myDate = ('00' + mm).substr(mm.length, 2) + '/' + ('00' + dd).substr(dd.length, 2) + '/' + yy;
        }

        myDate = myDate.replace(/[^0-9-/]/gi, '');

        if (myDate.length == 8 && myDate.indexOf('/') == -1) {
            fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/" + myDate.substr(4, 4);
        } else if (myDate.length == 8 && myDate.indexOf('/') >= 1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(6, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 6) + "19" + myDate.substr(6, 2);
            } else {
                fmtDate = myDate.substr(0, 6) + "20" + myDate.substr(6, 2);
            }
        } else if (myDate.length == 6 && myDate.indexOf('/') == -1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(4, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/19" + myDate.substr(4, 2);
            } else {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/20" + myDate.substr(4, 2);
            }
        } else {
            fmtDate = myDate;
        }

        return fmtDate;
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== ""
            && event.target.name === "hospitalCode") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";

            this.props.checkValidInput(name, value, table, key, -1, flag);
        } else {
            this.setState({ uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "" } });
        }

        // Original processes
        if (event.target.name === "reportLabel") {
            var _this = this;
            var label = event.target.value;

            var fName = event.target.name;
            //Alpha sources:
            var Report_Label = event.target.value;
            var Reset_Report_Label = 'false';
            if (Report_Label.indexOf('\\') > -1) {
                Report_Label = Report_Label.replace(/\\/g, ''); //Remove backslash
                Reset_Report_Label = 'true';
            }
            if (Report_Label.indexOf('/') > -1) {
                Report_Label = Report_Label.split('/').join(''); //Remove forward slash
                Reset_Report_Label = 'true';
            }
            if (Report_Label.indexOf('"') > -1) {
                Report_Label = Report_Label.split('"').join(''); //Remove double quotes
                Reset_Report_Label = 'true';
            }
            if (Report_Label.indexOf("'") > -1) {
                Report_Label = Report_Label.split("'").join(''); //Remove single quotes
                Reset_Report_Label = 'true';
            }
            if (Report_Label.indexOf('&') > -1) {
                Report_Label = Report_Label.replace(/&/g, ""); //Remove ampersand
                Reset_Report_Label = 'true';
            }

            if (Reset_Report_Label == 'true') {
                //{ dialog.Object }.setValue('Report_Label', Report_Label, false);
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        [fName]: Report_Label,
                    }
                });
                label = Report_Label;
            }

            var _labelOrg = this.state.initialDialogForm["reportLabel"];

            //
            let url = this.props.clientgroup + "/CheckReportLabelsHasExisted";
            axios.post(url,
                { label: label, accessToken: localStorage.getItem('token') })
                .then(res => {
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error && _labelOrg != label) {
                        setTimeout(function () {
                            _this.Get_Data(label);
                        }, 0);
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;

                });
            return false;

        } else if ((event.target.name === "requestedBy") && event.target.value === "") {
            //
        } else if (event.target.name === "requestDate") {
            //Check_Date_Format
            var fName = event.target.name;
            let thisValue = event.target.value;
            let thisDate = this.User_Defined_Date_Format(thisValue.replace(/[^0-9-/]/gi, ''));
            if (thisDate != thisValue) {
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        [fName]: thisDate,
                    }
                });
            }
        }
    }
    // --END

    //Delete_Report_Label - call to server
    deleteLabel() {
        var dialogFormData = this.state.dialogForm;
        if (!dialogFormData.reportLabel || dialogFormData.reportLabel == "") {
            return false;
        }
        this.props.deleteReportLabels(dialogFormData);//alert('Deletion Complete');

        // clear this flag to refresh page after delete label successful
        this.setState({
            reload: true,
            isShowConfirmDeleteMsg: false,
            isShowConfirmDeleteLabelMsg: false,
            confirmed: null,
            monitoring: null,
            reportLabelsMv: null,
            reportLabelsMvLoading: false,
        });

        return false;
    }

    //Save_Run - call to server
    Save_Run() {
        var popLabelComponent = document.getElementById("btnCallToParentFunc");
        if (popLabelComponent) {
            popLabelComponent.click();
        }

        var dialogFormData = this.state.dialogForm;
        var populationLabel = document.getElementById("populationLabel") ? document.getElementById("populationLabel").value : "";
        dialogFormData.populationLabel = populationLabel;
        if (dialogFormData.populationLabel == "") {
            return false;
        }
        console.log("populationLabel = " + populationLabel, dialogFormData.populationLabel);

        let reportLabelsMv = this.state.reportLabelsMv;
        dialogFormData.reportLabelsMv = reportLabelsMv;

        dialogFormData.actionType = "Save_Run";//->Run_Report_File
        dialogFormData.reportingDevice = this.state.selectedCaseOption ? this.state.selectedCaseOption : "Screen";

        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;

        var createPopulationLabel = document.getElementById("createPopulationLabel") ? document.getElementById("createPopulationLabel").value : "0";
        dialogFormData.createPopulationLabel = createPopulationLabel;

        var includeColumnHeaders = document.getElementById("includeColumnHeaders");
        if (includeColumnHeaders && includeColumnHeaders.checked) {
            dialogFormData.includeColumnHeaders = "1";
        } else {
            dialogFormData.includeColumnHeaders = "0";
        }

        //
        this.props.saveReportLabelsInfo(dialogFormData);//{dialog.Object}.submit('Save_Run');

        this.downloadFile = false;//reset this flag to get download new file

        return false;
    }

    deleteLabelFnc(e, alias) {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.setState({ isShowConfirmDeleteLabelMsg: true, deleteLabelType: alias });
        return false;
    }

    //on before show event
    showEvent(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        var popLabelComponent = document.getElementById("btnCallToParentFunc");
        if (popLabelComponent) {
            popLabelComponent.click();
        }

        var dialogFormData = this.state.dialogForm;
        var populationLabel = document.getElementById("populationLabel") ? document.getElementById("populationLabel").value : "";
        dialogFormData.populationLabel = populationLabel;
        if (dialogFormData.populationLabel == "") {
            return false;
        }
        console.log("populationLabel = " + populationLabel, dialogFormData.populationLabel);

        var _this = this;
        setTimeout(function () {
            _this.Save_Run();

            var today = new Date(),
                timeFrom = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            // set clock timer...
            _this.setState({ showClockTimer: true, startTimeFrom: timeFrom, endTime: today.toLocaleTimeString() });

            return false;
        }, 200);

        //
        var sInVal = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(sInVal);
            } else {
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
            }
        }, 750);

        return false;
    }

    Check_Status = (e, _this) => {
        e.preventDefault();

        let url = this.props.clientgroup + "/CheckStatus";
        axios.post(url, { accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    eval(res.data.result);
                }
            }).catch(error => {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        return false;
    }

    Cancel_Report = (e, _this) => {
        e.preventDefault();

        _this.props.cancelSBSReport();

        setTimeout(function () {
            _this.setState({ showClockTimer: false });
            return false;
        }, 0);

        return false;
    }

    newRecord(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                populationLabel: "",
            }
        });
        this.populationLabel = null;

        this.Get_Data("");
        this.props.selectAPopLabel("");

        var saveBtn = document.getElementById("BUTTON_SUBMIT");
        if (saveBtn) {
            saveBtn.disabled = true;
            saveBtn.setAttribute("class", "ErsGrBlueButton ScreenButtonDisabled");//
        }
        return false;
    }

    downloadLayoutFile = (e, flg) => {
        try {
            e.preventDefault();
        } catch (ex) { }

        if (flg == "csv") {
            //Send CSV Layout to an Iframe to initiate download
            var LayoutURL = this.state.Fn_CSV_Layout_URL;//{ dialog.Object }.getValue('Fn_CSV_Layout_URL');
            var downloadType = LayoutURL.substr(-3, 3);

            if (downloadType == "csv") {
                this.downloadFileLayout(this.props.selectedReportLabelsInfo, "File CSV");
            }
        } else if (flg == "fwd") {
            //Download_txt_Layout
            this.downloadFileLayout(this.props.selectedReportLabelsInfo, "File Fixed-Width");
        }
        return false;
    }

    updatePopLabelBackToParent = (plabel) => {
        var form = this.state.dialogForm;
        form.populationLabel = plabel;
        this.setState({
            dialogForm: form,
        });
        return false;
    }

    updateToParent = (plForm) => {
        var reportingForm = this.state.dialogForm;
        if (plForm) {
            Object.keys(plForm).map((t, k) => {
                !reportingForm[t] ? reportingForm[t] = plForm[t] : null;
            });

            let labelsSiteMvSap = plForm.labelsSiteMvSap;
            let labelsMv = plForm.labelsMv;
            let textareaEditSelect = plForm.textareaEditSelect;

            reportingForm.labelsSiteMvSap = labelsSiteMvSap;
            reportingForm.labelsMv = labelsMv;
            reportingForm.textareaEditSelect = textareaEditSelect;
        }

        this.setState({
            dialogForm: reportingForm,
        });
        return false;
    }

    javascript_void_fnc(event) {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        return false;
    }

    render() {
        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">{this.state.errorMessage && this.state.errorMessage.indexOf("You do not have permission to run reports on this population.") != -1 ? "Access Denied" : "Error"}</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">{this.state.errorMessage ? this.state.errorMessage : this.props.errorMessage}</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTimeFrom}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.endTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Check_Status(e, this) }} className="ErsGrBlueButton" value="yes" autoFocus>Check Status</button>
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="ErsGrBlueButton" value="no">Cancel</button>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let confirmDeleteMsg;
        {
            confirmDeleteMsg = this.state.isShowConfirmDeleteMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Delete Row</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>OK to delete row?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let confirmDeleteLabelMsg;
        {
            confirmDeleteLabelMsg = this.state.isShowConfirmDeleteLabelMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Confirm Deletion</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteLabelMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Are you sure you want to Delete this {this.state.deleteLabelType == 'rpt' ? "Report" : ""} Label?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteLabelMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteLabelMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let drawingHtmlTable;
        {
            drawingHtmlTable = this.state.dialogForm && this.state.dialogForm.populationLabel && this.state.isDrawingHtmlTable && this.props.showReport && this.state.Fn_Html_Display_URL && this.props.selectedReportLabelsInfo && this.props.selectedReportLabelsInfo.endTime ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableLargerWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Adhoc Report {this.state.dialogForm && this.state.dialogForm.reportLabel ? this.state.dialogForm.reportLabel : " "} for {this.state.dialogForm && this.state.dialogForm.populationLabel ? this.state.dialogForm.populationLabel : " "}</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingHtmlTable(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>

                            <div className="container printOnQaForm" id="printAbtractsMainTable" style={{ overflowY: this.state.publicFolder && this.state.publicFolder != "" ? "hidden" : "auto" }}>
                                {/*Drawing table here*/}

                                <div id="useForPrintHtml" className={this.state.publicFolder && this.state.publicFolder != "" ? "_hidden" : ""}>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.Fn_Html_Display_URL }} className="" />
                                </div>

                                <iframe src={this.state.publicFolder ? (this.state.publicFolder + "?alias=" + this.props.clientgroup) : this.state.Fn_Html_Display_URL} className={this.state.publicFolder && this.state.publicFolder != "" ? "hidden" : "hidden"} />

                                {
                                    /*
                                    this.state.Fn_Display && this.state.Fn_Display.indexOf("Sorry! Can't load this output file, it's too large.") != -1 ?
                                        <a href={this.state.Fn_Display_URL} target="_blank" rel="noreferrer" onClick={(e) => { this.showOutputFile(e) }}>Click here to open the output file in the new window.</a>
                                        : ""
                                    */
                                }
                            </div>

                            <div className="emptyLine"></div>
                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-sm-12" style={{ display: "flex", margin: "15px" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ScreenButton ScreenButtonHover" title=""
                                            onClick={this.printTable}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print</button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ScreenButton ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={this.hideDrawingHtmlTable} value="exit" autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const dialogForm = this.state.dialogForm && this.state.loaded && this.props.initialPageVisible ?
            (
                <div className="container-fluid2">

                    {/* Adding "Population Label" component on top this screen */}
                    <PopLabel plUpdFnc={this.updatePopLabelBackToParent} showToggleBtn={true} updateToParent={this.updateToParent} />

                    <form onSubmit={this.saveReportLabelsInfo} id="listForAPopForm" className={this.props.isPopup ? "DialogForm2" : ""}>
                        <div className="emptyLine"></div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="frameInner" style={{ marginRight: "15px" }}>
                                    <fieldset style={{ padding: "0", border: "none", borderBottom: "1px solid #ccc" }}>
                                        <legend></legend>
                                        <div className="row">
                                            <div className="col-sm-10">
                                                <div className="row">
                                                    <div className="col-sm-5">
                                                        <Input type="ReportLabels" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} width="350px"
                                                            labelText="Report Label"
                                                            field="reportLabel" lineStyle="oneLine"
                                                            value={this.state.dialogForm.reportLabel}
                                                            dataSource="Report_Labels"
                                                            titleDialog="Report Label Lookup" labelWidth="120px"
                                                            inValid={this.state.uAllItemFields["reportLabel"]}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["reportLabel"] : ""}
                                                            lostfocused={this.handleLostFocus}
                                                        />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                            changed={this.handleFormChange} labelText="Created By" labelWidth="100px" width="145px"
                                                            field="requestedBy" value={this.state.dialogForm.requestedBy ? this.state.dialogForm.requestedBy : "CRStar User"} itemNbr=""
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["requestedBy"] : ""}
                                                            inValid={this.state.uAllItemFields["requestedBy"]}
                                                        />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Requested On" field="requestDate"
                                                            value={this.state.dialogForm.requestDate}
                                                            maxLength="10" labelWidth="100px" width="115px"
                                                            lineStyle="oneLine" itemNbr="" labelStyle=""
                                                            lostfocused={this.handleLostFocus}
                                                            placeholder="mm/dd/yyyy"
                                                            inValid={this.state.uAllItemFields["requestDate"]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-10">
                                                <div style={{ display: "flex" }}>
                                                    <div className="frameInner">
                                                        <div className="header">Choose the Items for Your Report</div>
                                                        <div>
                                                            <table className="RF_Labels_Select_Mv">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: "18px" }}>&nbsp;</th>
                                                                        <th style={{ width: "80px" }}>Item Nbr</th>
                                                                        <th style={{ width: "240px" }}>Item Description</th>
                                                                        <th style={{ width: "80px" }}>B/S/L</th>
                                                                        <th style={{ width: "80px" }}>C/D</th>
                                                                        <th style={{ width: "100px" }}>Display Length</th>
                                                                        <th style={{ width: "190px" }}>Limiting Values</th>
                                                                        <th style={{ width: "auto" }}>&nbsp;</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody></tbody>
                                                            </table>
                                                        </div>
                                                        <div id="reportLabelsMvListArea" className="reportLabelsMvListArea">
                                                            <div className="reportLabelsMvChildrenArea innerBody" style={{ height: "288px", maxHeight: "288px", overflow: "auto" }}>
                                                                <table className="Report_Labels_Mv">
                                                                    <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                        <tr>
                                                                            <th style={{ width: "18px" }}>&nbsp;</th>
                                                                            <th style={{ width: "80px" }}>Item Nbr</th>
                                                                            <th style={{ width: "240px" }}>Item Description</th>
                                                                            <th style={{ width: "80px" }}>B/S/L</th>
                                                                            <th style={{ width: "80px" }}>C/D</th>
                                                                            <th style={{ width: "100px" }}>Display Length</th>
                                                                            <th style={{ width: "190px" }}>Limiting Values</th>
                                                                            <th style={{ width: "auto" }}>&nbsp;</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.reportLabelsMvItemFields
                                                                            && this.state.reportLabelsMv !== null && this.state.reportLabelsMv.length > 0
                                                                            ? this.state.reportLabelsMv.map((mv, index) => (
                                                                                <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionReportLabelsMvRow(e, index) }} className={this.state.selectedReportLabelsMvRowIndex === index ? "selectedRowCls" : ""}>
                                                                                    <td style={{ width: "18px", textAlign: "center" }}>
                                                                                        {index + 1}
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="input" focused={(e) => { this.handleOnFocus(e, index, "selectedReportLabelsMvRowIndex") }}
                                                                                            changed={(e) => { this.handleReportLabelsMvGridChange(e, index) }}
                                                                                            labelText="" field="globalItemNumber"
                                                                                            value={mv.globalItemNumber} lineStyle="oneLine"
                                                                                            width="99%" labelWidth="0px"
                                                                                            inValid={this.state.reportLabelsMvItemFields[index]["globalItemNumber"]}
                                                                                            maxLength={this.state.reportLabelsMvMaxlengthInput ? this.state.reportLabelsMvMaxlengthInput["globalItemNumber"] : ""}
                                                                                            lostfocused={(e) => { this.handleReportLabelsMvLostfocus(e, index) }}
                                                                                            keyId={"globalItemNumber" + index}
                                                                                            rowIndex={index}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedReportLabelsMvRowIndex") }}
                                                                                            changed={(e) => { this.handleReportLabelsMvGridChange(e, index) }}
                                                                                            labelText="" field="globalItemDescription"
                                                                                            value={mv.globalItemDescription} lineStyle="oneLine"
                                                                                            dataSource="Global_Dir" flag={this.state.dialogForm.globalItemDescription} titleDialog="Global Lookup"
                                                                                            width="99%" labelWidth="0px"
                                                                                            inValid={this.state.reportLabelsMvItemFields[index]["globalItemDescription"]}
                                                                                            maxLength={this.state.reportLabelsMvMaxlengthInput ? this.state.reportLabelsMvMaxlengthInput["globalItemDescription"] : ""}
                                                                                            lostfocused={(e) => { this.handleReportLabelsMvLostfocus(e, index) }}
                                                                                            keyId={"globalItemDescription" + index} flag={this.state.dialogForm.globalItemDescription}
                                                                                            rowIndex={index} shownType="dropdown"
                                                                                            //displayingCodeOnly={true}
                                                                                            //limitByWidth={true}
                                                                                            setNameAlias="name+code" fieldExt2="globalItemNumber"
                                                                                            codeLabel="Item Nbr"
                                                                                            descriptionLabel="Item Description"
                                                                                            hideFilter={true}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedReportLabelsMvRowIndex") }}
                                                                                            changed={(e) => { this.handleReportLabelsMvGridChange(e, index) }}
                                                                                            labelText="" field="breakSortList"
                                                                                            value={mv.breakSortList} lineStyle="oneLine"
                                                                                            dataSource="Lookup_Mv" titleDialog="Break Sort List" aliasNo="3"//search: Starts with
                                                                                            width="99%" labelWidth="0px"
                                                                                            inValid={this.state.reportLabelsMvItemFields[index]["breakSortList"]}
                                                                                            maxLength={this.state.reportLabelsMvMaxlengthInput ? this.state.reportLabelsMvMaxlengthInput["breakSortList"] : ""}
                                                                                            lostfocused={(e) => { this.handleReportLabelsMvLostfocus(e, index) }}
                                                                                            keyId={"breakSortList" + index}
                                                                                            rowIndex={index} limitByWidth={true} hideFilter={true}
                                                                                            shownType="dropdown" codeLabel="Code Description"
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="codeSelect" focused={(e) => { this.handleOnFocus(e, index, "selectedReportLabelsMvRowIndex") }}
                                                                                            changed={(e) => { this.handleReportLabelsMvGridChange(e, index) }}
                                                                                            labelText="" field="codeDescription"
                                                                                            value={mv.codeDescription} lineStyle="oneLine" aliasNo="4"
                                                                                            dataSource="Lookup_Mv" titleDialog="Code or Desc"
                                                                                            width="99%" labelWidth="0px"
                                                                                            inValid={this.state.reportLabelsMvItemFields[index]["codeDescription"]}
                                                                                            maxLength={this.state.reportLabelsMvMaxlengthInput ? this.state.reportLabelsMvMaxlengthInput["codeDescription"] : ""}
                                                                                            lostfocused={(e) => { this.handleReportLabelsMvLostfocus(e, index) }}
                                                                                            keyId={"codeDescription" + index}
                                                                                            rowIndex={index} limitByWidth={true} hideFilter={true}
                                                                                            shownType="dropdown" descriptionLabel="Code Description"
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="input" focused={(e) => { this.handleOnFocus(e, index, "selectedReportLabelsMvRowIndex") }}
                                                                                            changed={(e) => { this.handleReportLabelsMvGridChange(e, index) }}
                                                                                            labelText="" field="displayLength"
                                                                                            value={mv.displayLength} lineStyle="oneLine"
                                                                                            width="99%" labelWidth="0px"
                                                                                            inValid={this.state.reportLabelsMvItemFields[index]["displayLength"]}
                                                                                            maxLength={this.state.reportLabelsMvMaxlengthInput ? this.state.reportLabelsMvMaxlengthInput["displayLength"] : ""}
                                                                                            lostfocused={(e) => { this.handleReportLabelsMvLostfocus(e, index) }}
                                                                                            keyId={"displayLength" + index}
                                                                                            rowIndex={index}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Input type="input" focused={(e) => { this.handleOnFocus(e, index, "selectedReportLabelsMvRowIndex") }}
                                                                                            changed={(e) => { this.handleReportLabelsMvGridChange(e, index) }}
                                                                                            labelText="" field="limitingValues"
                                                                                            value={mv.limitingValues} lineStyle="oneLine"
                                                                                            width="99%" labelWidth="0px"
                                                                                            inValid={this.state.reportLabelsMvItemFields[index]["limitingValues"]}
                                                                                            maxLength={this.state.reportLabelsMvMaxlengthInput ? this.state.reportLabelsMvMaxlengthInput["limitingValues"] : ""}
                                                                                            lostfocused={(e) => { this.handleReportLabelsMvLostfocus(e, index) }}
                                                                                            keyId={"limitingValues" + index}
                                                                                            rowIndex={index}
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{ visibility: "hidden" }}>
                                                                                        <button className="copyRowBtnOnGrid"
                                                                                            style={{ width: "40px", top: "0px", position: "relative", padding: "1px" }}
                                                                                            id={"copyRowBtnOnGrid" + index} name={"copyRowBtnOnGrid" + index}
                                                                                            onKeyDown={this.keydownHandler} data-index={index}
                                                                                            onClick={(event) => { this.insertNewReportLabelsMvGrid(event, index) }}>Insert</button>
                                                                                    </td>
                                                                                </tr>
                                                                            )) : <tr><td colSpan="8">No records available</td></tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="contactBtnArea right">
                                                                <button id="btnAddNewLatestReportLabelsMv" onClick={this.addNewReportLabelsMv}>Add</button>
                                                                <button id="btnDeleteSelectedReportLabelsMv" onClick={this.deleteSelectedReportLabelsMv}>Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6"></div>
                                        </div>

                                        <div className="emptyLine"></div>

                                        <div className="row">
                                            <div className="col-sm-5">
                                                <div className="row">
                                                    <div className="col-sm-8">
                                                        <Input type="input" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText="Heading" labelWidth="80px" width="270px"
                                                            field="heading" lineStyle="oneLine" keyId="heading0"
                                                            value={this.state.dialogForm.heading}
                                                            inValid={this.state.uAllItemFields["heading"]}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["heading"] : ""}
                                                            lostfocused={this.handleLostFocus} rowIndex="0"
                                                        />
                                                    </div>
                                                    <div className="col-sm-3 checkboxTypeCls">
                                                        <Input type="checkbox" lineStyle="oneLine"
                                                            labelText="Include Column Headers" field="includeColumnHeaders"
                                                            labelWidth="200px"
                                                            labelStyle="lblOnCheckbox" width="50px"
                                                            value={(this.state.isCheckedIncludeColumnHeaders || this.state.selectedCaseOption == "Screen") && this.state.selectedCaseOption != "Xml" ? "1" : "0"}
                                                            checked={(this.state.isCheckedIncludeColumnHeaders || this.state.selectedCaseOption == "Screen") && this.state.selectedCaseOption != "Xml" ? true : false}
                                                            clicked={this.toggleChecked}
                                                            style={{ cursor: "pointer", fontWeight: "bold" }}
                                                            disabled={this.state.selectedCaseOption == "Screen" || this.state.selectedCaseOption == "Xml"}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-8">
                                                        <Input type="input" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText="File Name" labelWidth="80px" width="270px"
                                                            field="fileNameOfDownload" lineStyle="oneLine" keyId="fileNameOfDownload0"
                                                            value={this.state.dialogForm.fileNameOfDownload}
                                                            inValid={this.state.uAllItemFields["fileNameOfDownload"]}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["fileNameOfDownload"] : ""}
                                                            lostfocused={this.handleLostFocus} rowIndex="0"
                                                        />
                                                    </div>
                                                    <div className="col-sm-3 hidden">&nbsp;</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <label>Reporting Device</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <label style={{ width: "240px", height: "0px" }}>
                                                                    <input type="radio" onClick={this.selectionCases} checked={this.state.selectedCaseOption === "Excel"}
                                                                        name="outputDevice" field="outputDevice" value="Excel"
                                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                Excel</label>
                                                                <br />

                                                                <label style={{ width: "240px", height: "0px" }}>
                                                                    <input type="radio" onClick={this.selectionCases} checked={this.state.selectedCaseOption === "File CSV"}
                                                                        name="outputDevice" field="outputDevice" value="File CSV" width="240px"
                                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                File Delimited</label>
                                                                <br />

                                                                <label style={{ width: "240px", height: "0px" }}>
                                                                    <input type="radio" onClick={this.selectionCases} checked={this.state.selectedCaseOption === "File Fixed-Width"}
                                                                        name="outputDevice" field="outputDevice" value="File Fixed-Width" width="240px"
                                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                File Fixed-Width</label>
                                                                <br />

                                                                <label style={{ width: "240px", height: "0px" }}>
                                                                    <input type="radio" onClick={this.selectionCases} checked={this.state.selectedCaseOption === "Screen"}
                                                                        name="outputDevice" field="outputDevice" value="Screen" width="240px"
                                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                Screen</label>
                                                                <br />

                                                                <label style={{ width: "240px", height: "0px" }}>
                                                                    <input type="radio" onClick={this.selectionCases} checked={this.state.selectedCaseOption === "Xml"}
                                                                        name="outputDevice" field="outputDevice" value="Xml" width="240px"
                                                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                Xml</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-5">
                                                <div className="row">
                                                    <div className="col-sm-12">&nbsp;</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">&nbsp;</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <button id="BUTTON_CSV_LAYOUT_DOWNLOAD" type="button"
                                                            className={this.state.selectedCaseOption === "File CSV" && this.state.Fn_CSV_Layout_URL && this.state.Fn_CSV_Layout_URL != "" ? "ScreenButton" : "hidden"}
                                                            onClick={(e) => { this.downloadLayoutFile(e, "csv") }}>
                                                            <img id="IMAGE_CSV_LAYOUT_DOWNLOAD" src="https://crstar.ers-can.com/images/$$web.download.png.a5image" className="" />
                                                            Download Layout</button>
                                                    </div>
                                                    <div className="col-sm-4" style={{ display: this.state.selectedCaseOption === "File CSV" ? "" : "none" }}>
                                                        <Input type="input" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText="Field Delimiter" labelWidth="95px" width="35px"
                                                            field="fieldDelimiter" lineStyle="oneLine" keyId="fieldDelimiter0"
                                                            value={this.state.dialogForm.fieldDelimiter}
                                                            inValid={this.state.uAllItemFields["fieldDelimiter"]}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["fieldDelimiter"] : ""}
                                                            lostfocused={this.handleLostFocus} rowIndex="0"
                                                        />
                                                    </div>
                                                    <div className="col-sm-4 IncludeTextQualifierCls" style={{ display: this.state.selectedCaseOption === "File CSV" ? "" : "none" }}>
                                                        <Input type="checkbox" lineStyle="oneLine"
                                                            labelText="Include Text Qualifier" field="textQualifier"
                                                            labelWidth="150px"
                                                            labelStyle="lblOnCheckbox" width="auto"
                                                            value={this.state.isCheckedIncludeTextQualifier ? "1" : "0"}
                                                            checked={this.state.isCheckedIncludeTextQualifier}
                                                            clicked={this.toggleChecked}
                                                            style={{ cursor: "pointer", fontWeight: "bold" }}
                                                            title="Checking this box will include double quotes as text qualifiers around each field"//Alpha:~:Bubble Help
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <button id="BUTTON_FIXEDWIDTH_LAYOUT_DOWNLOAD" type="button"
                                                            className={this.state.selectedCaseOption === "File Fixed-Width" && this.state.Fn_FixedW_Layout_Path && this.state.Fn_FixedW_Layout_Path != "" ? "ScreenButton" : "hidden"}
                                                            onClick={(e) => { this.downloadLayoutFile(e, "fwd") }}>
                                                            <img id="IMAGE_CSV_LAYOUT_DOWNLOAD" src="https://crstar.ers-can.com/images/$$web.download.png.a5image" className="" />
                                                            Download Layout</button>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">&nbsp;</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="emptyLine"></div>
                                    </fieldset>

                                    <div className="emptyLine"></div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", marginLeft: "15px", paddingTop: "2px" }}
                                                labelText="Start Time" field="startTime" width="auto"
                                                value={this.state.dialogForm.startTime ? this.state.dialogForm.startTime : "-"}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", paddingTop: "2px" }}
                                                labelText="End Time" field="endTime" width="auto"
                                                value={this.state.dialogForm.endTime ? this.state.dialogForm.endTime : "-"}
                                            />
                                        </div>
                                        <div className="col-sm-6 hidden">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", borderStyle: "none", paddingTop: "2px" }}
                                                labelText="Records Processed" field="numberOfRecords" width="auto"
                                                value={this.state.dialogForm.numberOfRecords ? this.state.dialogForm.numberOfRecords : "0"}
                                            />
                                        </div>
                                    </div>

                                    <fieldset className="bottomAreaButtonCls">
                                        <legend></legend>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_RUN_REPORT"
                                                        className={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.showEvent(e) }}
                                                        disabled={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? false : true}
                                                    >
                                                        <img id="BUTTON_RUN_REPORT_ICON"
                                                            src={this.state.dialogForm.populationLabel && this.state.dialogForm.populationLabel != "" ? "https://crstar.ers-can.com/images/$$code.action.run.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$code.action.run.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Run
                                                    </button>
                                                </div>

                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_NEWRECORD"
                                                        className={(this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" || this.state.isFormDirty) ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.newRecord(e) }}
                                                    >
                                                        <img id="BUTTON_NEWRECORD_ICON"
                                                            src={(this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" || this.state.isFormDirty) ? "https://crstar.ers-can.com/images/$$navigate.new.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$navigate.new.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        New
                                                    </button>
                                                </div>

                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_DELETE"
                                                        className={this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.deleteLabelFnc(e, 'rpt') }}
                                                        disabled={this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" ? false : true}
                                                    >
                                                        <img id="BUTTON_DELETE_ICON"
                                                            src={this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" ? "https://crstar.ers-can.com/images/$$edit.delete.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$edit.delete.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Delete
                                                    </button>
                                                </div>

                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_SUBMIT"
                                                        className={this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" && this.state.isFormDirty ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.saveReportLabelsInfo(e) }}
                                                        disabled={this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" && this.state.isFormDirty ? false : true}
                                                    >
                                                        <img id="BUTTON_SUBMIT_ICON"
                                                            src={this.state.dialogForm.reportLabel && this.state.dialogForm.reportLabel != "" && this.state.isFormDirty ? "https://crstar.ers-can.com/images/$$file.save.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$file.save.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Save
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="emptyLine"></div>
                </div>
            )
            : <p className="">List For A Pop data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>

                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {confirmDeleteMsg}
                </React.Fragment>

                <React.Fragment>
                    {confirmDeleteLabelMsg}
                </React.Fragment>

                <React.Fragment>
                    <div className={this.props.initialPageVisible ? "" : "hidden"}>
                        {dialogForm}
                    </div>
                </React.Fragment>

                <React.Fragment>
                    {drawingHtmlTable}
                </React.Fragment>

            </React.Fragment>
        );
    }
}

ListPop.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        selectedReportLabelsInfo: state.patientAbstract.selectedReportLabelsInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        selectedLabelPopInfo: state.patientAbstract.selectedLabelPopInfo,
        showReport: state.patientAbstract.showReport,
        isDownloadFile: state.patientAbstract.isDownloadFile,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveReportLabelsInfo: (dialogForm) => dispatch(actionCreators.saveReportLabels(dialogForm)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearAddNewUserFlag: () => dispatch(actionCreators.clearAddNewUserFlag()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        selectReportLabels: (label) => dispatch(actionCreators.selectReportLabels(label)),
        deleteReportLabels: (dialogForm) => dispatch(actionCreators.deleteReportLabels(dialogForm)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),

        addNewItem: (number) => dispatch(actionCreators.selectHospitalRecord(number)),
        closeAddNewDialog: () => dispatch(actionCreators.closeAddNewDialog()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
        selectAPopLabel: (label) => dispatch(actionCreators.selectAPopLabel(label)),
        deleteAPopLabel: (dialogForm) => dispatch(actionCreators.deleteAPopLabel(dialogForm)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPop);