/* eslint-disable */
import React, { Component } from "react";
import Draggable from 'react-draggable';
import { connect } from "react-redux";
import axios from "../../../axios-instance";
import { actionCreators } from "../../../store/PatientAbstract";
import * as dialogTypes from "../../AbstractManagement/maintDialogs"; // Getting navigation tabs - 03/02/2023
import Input from "../../UI/Input";
import PopLabel from "../PopLabel/PopulationLabel"; //Import the Editable Pop Label 03/01/2023
import "./CrossTab.css";



class CrossTab extends Component {
    clockInterval = "";
    downloadFile = false;
    state = {

        label: "", //Default value is for Testing Only //SITE BY CO AT DX
        crossTabReportLabel: "",

        heading: "",
        displayPercentages: true,

        itemNbr: "",
        rowItemNumber: "",
        rowItemDescription: "",
        rowCodesDescriptions: "Codes",
        rowLimitValue: "5",
        rowValues: "",
        rowDescriptions: "",

        columnItemNumber: "",
        columnItemDescription: "",
        columnCodesDescriptions: "Codes",
        columnLimitValue: "5",
        columnValues: "",
        columnDescriptions: "",

        outputType: "Screen",
        reportType: "Table",

        crossTabRowMv: [
            { rowValue: "", rowDescription: "" },
            { rowValue: "", rowDescription: "" },
            { rowValue: "", rowDescription: "" },
            { rowValue: "", rowDescription: "" },
            { rowValue: "", rowDescription: "" },
        ],
        selectedMvRowIndex: 0,

        crossTabColumnMv: [
            { colValue: "", colDescription: "" },
            { colValue: "", colDescription: "" },
            { colValue: "", colDescription: "" },
            { colValue: "", colDescription: "" },
            { colValue: "", colDescription: "" },
        ],
        selectedMvColumnIndex: 0,

        htmlOutput: null,
        startTime: null,
        endTime: null,
        statusBar: "Start Time: ",

        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        loaded: true,
        titleDialog: null,
        showClockTimer: false,
        isShowConfirmDeleteLabelMsg: false,
    }


    setStateCrossTabReportLabel = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state[name];
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;

        this.setState({
            crossTabReportLabel: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,  //04/23/2024 - Enabling this line, so that changes to the report label will get saved without requiring subsequent changes to the screen and avoid a SQL error from running a non-existing report
        });
    }

    Cross_Tab_Label_Check = (event) => {
        var reportLabel = this.state['crossTabReportLabel'];

        if (reportLabel.substring(0, 10).toUpperCase() == 'COMPARISON') {
            var userID = this.props.userId;
            var userName = userID.split('@')[0].trim();
            var newLabel = userName + ' ' + reportLabel.substring(10).trim();
            this.setState({
                crossTabReportLabel: newLabel,
            });
        }
    }


    setStateHeading = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state[name];
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;

        this.setState({
            heading: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });

        if (oldValue != value) { this.Cross_Tab_Label_Check(event); }
    }



    setStateOutputType = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.outputType;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;

        this.setState({
            outputType: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });

        //if (oldValue != value) { this.Cross_Tab_Label_Check(event); }
    }


    setStateReportType = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.reportType;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;

        this.setState({
            reportType: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }


    setStateRowCodesDescriptions = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.rowCodesDescriptions;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;

        this.setState({
            rowCodesDescriptions: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });

        if (oldValue != value) { this.Cross_Tab_Label_Check(event); }
    }

    setStateColumnCodesDescriptions = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.columnCodesDescriptions;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;

        this.setState({
            columnCodesDescriptions: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });

        if (oldValue != value) { this.Cross_Tab_Label_Check(event); }
    }

    setStateRowLimitValue = (event) => {
        this.setState({ rowLimitValue: event.target.value, isFormDirty: true })
        this.Cross_Tab_Label_Check(event);
    }

    setStateColumnLimitValue = (event) => {
        this.setState({ columnLimitValue: event.target.value, isFormDirty: true })
        this.Cross_Tab_Label_Check(event);
    }

    setStateSelectedRowIndex = (event, index) => {
        this.setState({ selectedMvRowIndex: index });
    }

    setStateSelectedColumnIndex = (event, index) => {
        this.setState({ selectedMvColumnIndex: index });
    }


    updatePopLabelBackToParent = (plabel) => {
        var form = this.state.dialogForm;
        form.populationLabel = plabel;
        this.setState({
            dialogForm: form,
        });
        return false;
    }

    updateToParent = (plForm) => {
        var reportingForm = this.state.dialogForm;
        if (plForm) {
            Object.keys(plForm).map((t, k) => {
                !reportingForm[t] ? reportingForm[t] = plForm[t] : null;
            });

            let labelsSiteMvSap = plForm.labelsSiteMvSap;
            let labelsMv = plForm.labelsMv;

            reportingForm.labelsSiteMvSap = labelsSiteMvSap;
            reportingForm.labelsMv = labelsMv;
        }

        this.setState({
            dialogForm: reportingForm,
        });
        return false;
    }

    componentDidMount() {
        // Add all navigation tabs in maintDialogs.js - 03/02/2023
        dialogTypes.comparisonReportsTabsDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog.name, dialog.id);
            this.props.setEnabledStatus(dialog.name);
        });

        var _this = this;
        _this.props.generateCrossTab();

        if (!this.state.dialogForm) {
            this.setFormData(this.props.crossTabInfo);
        }
        this.setState({ isLoading: true });

        this.props.selectAPopLabel("");
    }

    componentWillMount() {

    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.currentSelectedField != null && this.props.commonLookupItem && this.state.dialogForm) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                if (this.props.currentSelectedField == "rowItemNumber" && this.props.commonLookupItem.name
                    && this.props.commonLookupItem.name !== this.state.rowItemDescription) {
                    this.setState({ rowItemDescription: this.props.commonLookupItem.name });
                    //this.Cross_Tab_Label_Check();
                } else if (this.props.currentSelectedField == "columnItemNumber" && this.props.commonLookupItem.name
                    && this.props.commonLookupItem.name !== this.state.columnItemDescription) {//columnItemNumber
                    this.setState({ columnItemDescription: this.props.commonLookupItem.name });
                    //this.Cross_Tab_Label_Check();
                }
            }
            this.props.clearCommonLookupItem();
        }


        if (!this.state.dialogForm) {
            this.setFormData(this.props.crossTabInfo);
        }

        var crossTabInfo = this.props.crossTabInfo;

        if (this.state.dialogForm && (/*prevProps.crossTabInfo &&*/ this.props.crossTabInfo && crossTabInfo.load_Data == "true") && !this.state.loaded) {
            //debugger
            //This should load the data, but it isn't working properly
            this.Load_Data(crossTabInfo);
        }

        if (this.state.dialogForm && (prevProps.crossTabInfo && this.props.crossTabInfo && crossTabInfo.load_Data == "false" && (prevProps.crossTabInfo.startTime !== this.props.crossTabInfo.startTime || prevProps.crossTabInfo.endTime !== this.props.crossTabInfo.endTime))) {
            clearInterval(this.clockInterval); //If the report has finished, clear the clock interval
            this.setState({
                showClockTimer: false, //If the report has finished, hide the clock
                endTime: new Date().toLocaleTimeString(),
            });

            if (crossTabInfo.output_Type == "Screen" && crossTabInfo.htmlOutput) //If the report has finished, it was sent to the screen, and we have html data returned, display the report to the screen
                this.displayReport(crossTabInfo);
            else if (crossTabInfo.output_Type == "Excel" && crossTabInfo.urlOutputFile && !this.downloadFile) { //If the report has finished, it was sent to Excel, and we have a url/path to the Excel file returned by the report, download the file
                var fileURL = this.props.clientgroup + "/DownloadFile";
                this.downloadxlsx(fileURL, crossTabInfo.urlOutputFile); //Download the Excel file produced by the report
                this.downloadFile = true;
            }
        }
    }

    downloadxlsx(fileURL, urlOutputFile) {

        axios.post(fileURL, {
            method: 'POST',
            responseType: 'blob',//important
            urlOutputFile: urlOutputFile,
            accessToken: localStorage.getItem("token")
        }).then((response) => {
            var fileName = urlOutputFile.substring(urlOutputFile.lastIndexOf('\\') + 1);
            const link = document.createElement('a');


            let excel;
            if (urlOutputFile.indexOf('/') != -1) {
                excel = urlOutputFile.substring(urlOutputFile.lastIndexOf('/') + 1);
            } else {
                excel = urlOutputFile.substring(urlOutputFile.lastIndexOf('\\') + 1);
            }
            link.href = this.props.clientgroup + "/" + excel;

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();

            return false;
        })
            .catch(function (error) {
                console.log(error);
            });

        return false;
    }

    setFormData = (crossTabInfo) => {
        this.setState({
            initialDialogForm: { ...crossTabInfo },
            dialogForm: { ...crossTabInfo },

            //["outputType"]: requestLogInfo && requestLogInfo.sendReportTo ? requestLogInfo.sendReportTo : "Screen",
            reload: false,
            changed: true,
            showClockTimer: false,
            titleDialog: null,
            isDrawingCharts: false,
        });

        return false;
    }

    handleOnFocus = (event) => {

    }

    handleRowOnFocus = (event, index) => {

    }

    handleColumnOnFocus = (event, index) => {

    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true,
            });
        } else {
            this.setState({
                //dialogForm: {
                //    ...this.state.dialogForm,
                //    [event.target.name]: event.target.value
                //},
                [event.target.name]: event.target.value, //11/07/2022 - Switching to this per REA to resolve the issue of typing in the hospital code directly
                isFormDirty: true,
            });

            // check if whether existed label then load all related data...
            if (event.target.name == "crossTabReportLabel") {
                //To Do: Load Data
                this.setState({

                });
            }
        }
    }

    //
    handleItemNumberFieldsLostfocus = (event) => {
        try {
            event.preventDefault();
        } catch (ex) { }

        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";

            this.props.checkValidInput(name, value, table, key, 0);
        }

        return false;
    }

    handleRowItemNumberChange = (event) => {
        const itemNbr = event.target.value
        var itemDesc = "";
        if (event.target2) {
            itemDesc = event.target2.value ? event.target2.value : "";
        }

        if (itemNbr !== this.state.rowItemNumber || itemDesc !== this.state.rowItemDescription) {
            this.setState({
                rowItemNumber: itemNbr,
                rowItemDescription: itemDesc,
                isFormDirty: true
            })

            this.Cross_Tab_Label_Check(event);
        }
        return false;
    }

    handleRowItemDescriptionChange = (event) => {
        const itemDesc = event.target.value
        var itemNbr = "";
        if (event.target2) {
            itemNbr = event.target2.value ? event.target2.value : "";
        }

        if (itemDesc !== this.state.rowItemDescription) {
            this.setState({
                rowItemNumber: itemNbr,
                rowItemDescription: itemDesc,
                isFormDirty: true
            })

            this.Cross_Tab_Label_Check(event);
        }
        return false;
    }

    handleColumnItemNumberChange = (event) => {
        const itemNbr = event.target.value
        var itemDesc = "";
        if (event.target2) {
            itemDesc = event.target2.value ? event.target2.value : "";
        }

        if (itemNbr !== this.state.columnItemNumber || itemDesc !== this.state.columnItemDescription) {
            this.setState({
                columnItemNumber: itemNbr,
                columnItemDescription: itemDesc,
                isFormDirty: true
            })

            this.Cross_Tab_Label_Check(event);
        }
        return false;
    }

    handleColumnItemDescriptionChange = (event) => {
        const itemDesc = event.target.value
        var itemNbr = "";
        if (event.target2) {
            itemNbr = event.target2.value ? event.target2.value : "";
        }

        if (itemDesc !== this.state.rowItemDescription) {
            this.setState({
                columnItemNumber: itemNbr,
                columnItemDescription: itemDesc,
                isFormDirty: true
            })

            this.Cross_Tab_Label_Check(event);
        }
        return false;
    }

    hideConfirmDeleteLabelMsg = (event) => {
        try { event ? event.preventDefault() : ""; } catch (ex) { }
        this.setState({ isShowConfirmDeleteLabelMsg: false });

        if (event.target.value === "yes") {
            this.deleteLabel();
        }
        return false;
    }

    //Delete_Report_Label - call to server
    deleteLabel() {
        var dialogFormData = this.state.dialogForm;
        //debugger
        dialogFormData.cross_Tab_Report_Label = this.state.crossTabReportLabel;
        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;

        var dialogFormData = this.state.dialogForm;
        if (!this.state.crossTabReportLabel || this.state.crossTabReportLabel == "") {
            return false;
        }
        this.props.deleteReportLabel(dialogFormData);//alert('Deletion Complete');

        // Click New button to clear the screen after the deletion is complete
        //var newButton = document.getElementById("BUTTON_CROSS_TAB_NEWRECORD");
        //if (newButton) { newButton.click(); }
        setTimeout(function () {
            var refreshButton = document.getElementById("BUTTON_REFRESH");
            if (refreshButton) { refreshButton.click(); }
        }, 1);

        return false;
    }

    handleLostFocus = (event) => {
        //debugger
        //alert('Trigger Lost Focus: ' + event.target.value);
        if (event.target.name === "crossTabReportLabel" || event.target.name === "label") {
            var dialogFormData = this.state.dialogForm;

            dialogFormData.cross_Tab_Report_Label = event.target.value;//this.state.crossTabReportLabel;
            //dialogFormData.load_Data = "true";
            dialogFormData.userId = this.props.userId;
            dialogFormData.clientAlias = this.props.clientgroup;

            var dialogFormData = this.state.dialogForm;
            if (/*!this.state.crossTabReportLabel || this.state.crossTabReportLabel == ""*/ event.target.value == "") {
                return false;
            }
            //alert('Check Loaded State: ' + this.state.loaded);
            //await 
            this.props.loadCrossTabData(dialogFormData);

            //var crossTabInfo = this.props.crossTabInfo;
            this.setState({ loaded: false });

            //setTimeout(function () {
            //this.Load_Data(crossTabInfo)
            //}, 1);

        }
        return false;
    }

    Load_Data(crossTabInfo) {
        //Receive previously saved report data and load it to the screen
        var crossTabData = crossTabInfo.cross_Tab_Data ? JSON.parse(crossTabInfo.cross_Tab_Data).Cross_Tab_Data[0] : [];
        var crossTabRowMvData = crossTabInfo.cross_Tab_Row_Mv_Data ? JSON.parse(crossTabInfo.cross_Tab_Row_Mv_Data).Cross_Tab_Row_Mv_Data : [];
        var crossTabColMvData = crossTabInfo.cross_Tab_Col_Mv_Data ? JSON.parse(crossTabInfo.cross_Tab_Col_Mv_Data).Cross_Tab_Col_Mv_Data : [];
        //var crossTabColMvData = crossTabInfo.cross_Tab_Col_Mv_Data;
        console.log(crossTabInfo);

        //var rowMv = null;
        //var colMv = null;
        //if (crossTabRowMvData) {
        //    rowMv = JSON.parse(crossTabRowMvData);
        //}
        //if (crossTabColMvData) {
        //    colMv = JSON.parse(crossTabColMvData);
        //}

        //debugger
        console.log("Parsed JSON crossTabData Array: " + crossTabData);
        //console.log(crossTabData.Row_Item_Number);
        //console.log(rowMv ? rowMv.Cross_Tab_Row_Mv_Data : []);
        //console.log(colMv ? colMv.Cross_Tab_Col_Mv_Data : []);

        this.setState({
            heading: crossTabData.Heading ? crossTabData.Heading : "",
            outputType: crossTabData.Output_Device ? crossTabData.Output_Device : "Screen",
            reportType: crossTabData.Report_Type ? crossTabData.Report_Type : "Table",

            rowItemNumber: crossTabData.Row_Item_Number ? crossTabData.Row_Item_Number : "",
            rowItemDescription: crossTabData.Row_Item_Description ? crossTabData.Row_Item_Description : "",
            rowCodesDescriptions: crossTabData.Row_Display_As ? crossTabData.Row_Display_As : "Codes",
            rowLimitValue: crossTabData.Row_Limit_Value ? crossTabData.Row_Limit_Value : "",

            crossTabRowMv: crossTabRowMvData.length > 0 ? crossTabRowMvData : [
                { rowValue: "", rowDescription: "" },
                { rowValue: "", rowDescription: "" },
                { rowValue: "", rowDescription: "" },
                { rowValue: "", rowDescription: "" },
                { rowValue: "", rowDescription: "" },
            ],

            columnItemNumber: crossTabData.Column_Item_Number ? crossTabData.Column_Item_Number : "",
            columnItemDescription: crossTabData.Column_Item_Description ? crossTabData.Column_Item_Description : "",
            columnCodesDescriptions: crossTabData.Column_Display_As ? crossTabData.Column_Display_As : "Codes",
            columnLimitValue: crossTabData.Column_Limit_Value ? crossTabData.Column_Limit_Value : "",

            //crossTabColumnMv: colMv && colMv.Cross_Tab_Col_Mv_Data ? colMv.Cross_Tab_Col_Mv_Data : [
            crossTabColumnMv: crossTabColMvData.length > 0 ? crossTabColMvData : [
                { colValue: "", colDescription: "" },
                { colValue: "", colDescription: "" },
                { colValue: "", colDescription: "" },
                { colValue: "", colDescription: "" },
                { colValue: "", colDescription: "" },
            ],

            loaded: true
        });
    }

    onChangeRowValueMv = (event, index) => {
        var thisGrid = this.state.crossTabRowMv;

        if (this.state.crossTabRowMv[index].rowValue != event.target.value) {
            thisGrid[index].rowValue = event.target.value;
            this.setState({
                crossTabRowMv: thisGrid,
                isFormDirty: true,
            });
            this.Cross_Tab_Label_Check(event);
        }

        console.log("Changing Row Mv Value 'This' values of crossTabRowMv: " + JSON.stringify(this.state.crossTabRowMv, null, 4));
        console.log("Changing Row Mv Value 'This' values of dialogForm: " + JSON.stringify(this.state.dialogForm, null, 4));
        //console.log("Changing Row Mv Value 'This' values of: " + JSON.stringify(this.state.crossTabRowMv[2], null, 4));
        //console.log("Changing Row Mv Value 'This' values of: " + JSON.stringify(thisComponent, null, 4));
    }

    onChangeRowDescriptionMv = (event, index) => {
        var thisGrid = this.state.crossTabRowMv;
        /*        alert(this.state.crossTabRowMv[index].rowDescription + ' -- ' + event.target.value)*/
        if (this.state.crossTabRowMv[index].rowDescription != event.target.value) {
            thisGrid[index].rowDescription = event.target.value;
            this.setState({
                crossTabRowMv: thisGrid,
                isFormDirty: true,
            });
            this.Cross_Tab_Label_Check(event);
        }
    }

    onChangeColumnValueMv = (event, index) => {
        var thisGrid = this.state.crossTabColumnMv;
        if (this.state.crossTabColumnMv[index].colValue != event.target.value) {
            thisGrid[index].colValue = event.target.value;
            this.setState({
                crossTabColumnMv: thisGrid,
                isFormDirty: true,
            });
            this.Cross_Tab_Label_Check(event);
        }
    }

    onChangeColumnDescriptionMv = (event, index) => {
        var thisGrid = this.state.crossTabColumnMv;
        if (this.state.crossTabColumnMv[index].colDescription != event.target.value) {
            thisGrid[index].colDescription = event.target.value;
            this.setState({
                crossTabColumnMv: thisGrid,
                isFormDirty: true,
            });
            this.Cross_Tab_Label_Check(event);
        }
    }

    toggleChecked = (event) => {
        const name = event.target.name;
        var dialogFormData = this.state.dialogForm;

        if (name == "displayPercentages") {
            const checked = this.state.displayPercentages;
            dialogFormData[name] = !checked ? "1" : "0";
            this.setState({
                displayPercentages: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        }
    }


    addRowCriteriaRow = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.crossTabRowMv];
            const firstItems = newItems[0];

            const newItem = {};
            Object.keys(firstItems).map((t, k) => {
                newItem[t] = "";
            });
            newItems.push(newItem);

            return { crossTabRowMv: newItems };
        });
    }


    addColumnCriteriaRow = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.crossTabColumnMv];
            const firstItems = newItems[0];

            const newItem = {};
            Object.keys(firstItems).map((t, k) => {
                newItem[t] = "";
            });
            newItems.push(newItem);

            return { crossTabColumnMv: newItems };
        });
    }


    deleteRowCriteriaRow = (event) => {
        event ? event.preventDefault() : null;

        let keepArray = this.state.crossTabRowMv;
        let rows = keepArray.length;

        //alert(rows + " -- " + keepArray);
        //keepArray.splice(rows-1, 1);

        if (rows > 1) {
            keepArray = keepArray.slice(0, rows - 1);
        } else {
            keepArray = [
            { rowValue: "", rowDescription: "" },
            { rowValue: "", rowDescription: "" },
            { rowValue: "", rowDescription: "" },
            { rowValue: "", rowDescription: "" },
            { rowValue: "", rowDescription: "" },
            ]
        }
        
        //alert(keepArray.length + " -- " + keepArray);

        this.setState({
            crossTabRowMv: keepArray,
        });

        return false;
    }


    deleteColumnCriteriaRow = (event) => {
        event ? event.preventDefault() : null;

        let keepArray = this.state.crossTabColumnMv;
        let rows = keepArray.length;

        if (rows > 1) {
            keepArray = keepArray.slice(0, rows - 1);
        } else {
            keepArray = [
                { colValue: "", colDescription: "" },
                { colValue: "", colDescription: "" },
                { colValue: "", colDescription: "" },
                { colValue: "", colDescription: "" },
                { colValue: "", colDescription: "" },
            ]
        }

        this.setState({
            crossTabColumnMv: keepArray,
        });

        return false;
    }


    Run_Cross_Tab = () => {
        //Collate all values needed by the report into one object, dialogFormData, and pass it to the report.
        var dialogFormData = this.state.dialogForm;

        dialogFormData.populationLabel = document.getElementById("populationLabel").value ? document.getElementById("populationLabel").value : "";
        //To Do - Add the variables
        dialogFormData.cross_Tab_Report_Label = this.state.crossTabReportLabel; //document.getElementById("label0").value ? document.getElementById("label0").value : "";//this.state.reportLabel; //this.state.reportLabel;
        dialogFormData.heading = this.state.heading;
        dialogFormData.output_Type = this.state.outputType;
        dialogFormData.report_Type = this.state.reportType;
        dialogFormData.display_Percentages = this.state.displayPercentages;

        dialogFormData.row_Item_Number = this.state.rowItemNumber;
        dialogFormData.row_Item_Description = this.state.rowItemDescription;
        dialogFormData.row_Code_Descriptions = this.state.rowCodesDescriptions;
        dialogFormData.row_Limit_Values = this.state.rowLimitValue;

        dialogFormData.column_Item_Number = this.state.columnItemNumber;
        dialogFormData.column_Item_Description = this.state.columnItemDescription;
        dialogFormData.column_Code_Descriptions = this.state.columnCodesDescriptions;
        dialogFormData.column_Limit_Values = this.state.columnLimitValue;

        let crossTabRowMvReduced = this.state.crossTabRowMv.filter(e => (e.rowValue != "" || e.rowDescription != ""));
        var rowMvData = crossTabRowMvReduced.map(e => e.rowValue + "','" + e.rowDescription).join("'),(@Report_ID,'");
        dialogFormData.row_Values = rowMvData != "" ? "(@Report_ID,'" + rowMvData + "')" : "";

        let crossTabColumnMvReduced = this.state.crossTabColumnMv.filter(e => (e.colValue != "" || e.colDescription != ""));
        var columnMvData = crossTabColumnMvReduced.map(e => e.colValue + "','" + e.colDescription).join("'),(@Report_ID,'");
        dialogFormData.column_Values = columnMvData != "" ? "(@Report_ID,'" + columnMvData + "')" : "";

        dialogFormData.save_Data = this.state.isFormDirty;
        //dialogFormData.load_Data = "false";
        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;


        //alert("Running Request Log with 'This dialog' values of: " + JSON.stringify(this.state.dialogForm, null, 4))
        console.log("Running Cross Tab with 'This' values of: " + JSON.stringify(this.state.dialogForm, null, 4));

        this.props.runCrossTab(dialogFormData);

        this.downloadFile = false;//reset this flag to get download new file

        return false;
    }

    //on before show event
    showClockEvent(e) {
        try {
            e.preventDefault();// This function prevents the form from being auto-submitted
        } catch (ex) { }

        var popLabelComponent = document.getElementById("btnCallToParentFunc");
        if (popLabelComponent) {
            popLabelComponent.click();
        }


        var _this = this;
        _this.setState({ showClockTimer: true, startTime: new Date().toLocaleTimeString() }); // set clock timer...

        this.Run_Cross_Tab();

        this.clockInterval = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(this.clockInterval);
            } else {
                console.log("Timer Interval Running");
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
                _this.setState({ currentTime: new Date() ? new Date().toLocaleTimeString() : " " });
            }
        }, 1000);

        return false; // Returns false to stop everything after this event has been completed

    }

    Cancel_Report = (e, _this) => {
        _this.setState({ showClockTimer: false });
        clearInterval(this.clockInterval);
        return false;
    }


    displayReport = (data) => {
        this.setState({
            showClockTimer: false,//hide clock timer
            endTime: new Date().toLocaleTimeString(),
            htmlOutput: data.htmlOutput, //Commenting out because I think this already gets set in the data tunnel, but will have to test
            isDrawingCharts: true
        });

        var reportDisplay = document.getElementById("displayReportIframe");
        if (reportDisplay) { //This function runs twice and in one instance this component doesn't exist, so this check needs to be there to prevent the entire screen from erroring out
            var reportContent = reportDisplay.contentDocument;
            reportContent.write(data.htmlOutput);
        }

        return false;
    }


    hideDrawingCharts = (e) => {
        this.setState({ isDrawingCharts: false });
        return false;
    }


    newRecord(e) {
        try {
            e.preventDefault();  //Added to prevent the entire window from refreshing when reload is set to "true" below

            this.props.selectAPopLabel("");  //Added to clear population label

            this.setState({
                crossTabReportLabel: "",
                heading: "",
                displayPercentages: true,

                rowItemNumber: "",
                rowItemDescription: "",
                rowCodesDescriptions: "Codes",
                rowLimitValue: "5",
                rowValues: "",
                rowDescriptions: "",

                columnItemNumber: "",
                columnItemDescription: "",
                columnCodesDescriptions: "Codes",
                columnLimitValue: "5",
                columnValues: "",
                columnDescriptions: "",

                crossTabRowMv: [
                    { rowValue: "", rowDescription: "" },
                    { rowValue: "", rowDescription: "" },
                    { rowValue: "", rowDescription: "" },
                    { rowValue: "", rowDescription: "" },
                    { rowValue: "", rowDescription: "" },
                ],
                selectedMvRowIndex: 0,

                crossTabColumnMv: [
                    { colValue: "", colDescription: "" },
                    { colValue: "", colDescription: "" },
                    { colValue: "", colDescription: "" },
                    { colValue: "", colDescription: "" },
                    { colValue: "", colDescription: "" },
                ],
                selectedMvColumnIndex: 0,

                outputType: "Screen",
                reportType: "Table",

                htmlOutput: null,
                startTime: null,
                endTime: null,
                statusBar: "",

                reload: true,  //Added to refresh population label criteria
                isFormDirty: false,
            })

        } catch (ex) { }
    }

    deleteRecord(e) {
        try { e.preventDefault(); } catch (ex) { }
        this.setState({ isShowConfirmDeleteLabelMsg: true });
        return false;
    }

    refreshScreen(e) {
        //setTimeout(function () {
        var newButton = document.getElementById("BUTTON_CROSS_TAB_NEWRECORD");
        if (newButton) { newButton.click(); }
        //}, 10);

        //this.setState({
        //    statusBar: "Record Deleted",
        //})

        return false;
    }

    printReport = (e) => {
        var doc = document.getElementById("displayReportIframe").contentDocument;

        var printStyle = doc.getElementById('PrintStyle');
        var printContent = doc.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Cross Tab</title><style type="text/css">';
        html += printStyle.innerHTML + '</style></head><body><div id="printableContent">';
        html += printContent.innerHTML + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }


    render() {
        let confirmDeleteLabelMsg;
        {
            confirmDeleteLabelMsg = this.state.isShowConfirmDeleteLabelMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Confirm Deletion</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteLabelMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Are you sure you want to Delete this Label?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteLabelMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteLabelMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window" style={{ cursor: "progress" }}>
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait <div className="waitingWaves"><div className="wave"></div><div className="wave"></div><div className="wave"></div><div className="wave"></div><div className="wave"></div><div className="wave"></div><div className="wave"></div><div className="wave"></div><div className="wave"></div><div className="wave"></div></div></div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTime}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let drawingCharts;
        {
            drawingCharts = this.state.isDrawingCharts ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="CrossTabReportDisplay" className="dragableLargestWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Cross Tab </div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingCharts(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="container" id="CrossTabShowingCharts">
                                <iframe id="displayReportIframe" />
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12" style={{ display: "flex" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ErsGrBlueButton" title=""
                                            onClick={(e) => { this.printReport(e) }}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print
                                        </button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ErsGrBlueButton" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={(e) => { this.hideDrawingCharts(e) }} value="exit" autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let dialogForm = <div className="reportDialogForm">
            <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                <div className="reportDialogFormContent">
                    <div id="popLabel">
                        {/*Adding modular "Population Label" component on top of this screen*/}
                        <PopLabel plUpdFnc={this.updatePopLabelBackToParent} showToggleBtn={true} updateToParent={this.updateToParent} />
                    </div>

                    <div className="reportScreenTitle">Cross Tab Reports</div>
                    {this.state.dialogForm || this.state.loaded ?

                        <form onSubmit={false} id="CrossTabForm" className="">
                            <div className="frameInner">

                                <div id="topContainer">
                                    <div className="reportLabelContainer">
                                        <div className="col-sm-7"></div>
                                        <Input type="codeSelect" name="crossTabReportLabel"
                                            //changed={this.handleFormChange}
                                            changed={this.setStateCrossTabReportLabel}
                                            width="280px"
                                            labelText="Report Label" autoFocus
                                            field="label" lineStyle="oneLine" keyId="label0"
                                            value={this.state.crossTabReportLabel}
                                            dataSource="Xtab_Labels"
                                            titleDialog="Cross Tab Reports" labelWidth="80px"
                                            lostfocused={this.handleLostFocus}

                                            // set these properties to display popup as dropdown control...
                                            shownType="dropdown" rowIndex="0"
                                            setNameAlias={true} displayingCodeOnly={true} codeLabel="Cross Tab Name" limitByWidth={true}
                                        />


                                        <div id="headingDiv">
                                            <label title="Enter a custom heading if desired, otherwise, the Report Label will be used in the heading of the report." >Heading:</label>
                                            <input type="text" name="heading" field="heading" value={this.state.heading}
                                                onChange={this.setStateHeading}
                                            //onChange={(e) => this.setState({ heading: e.target.value })}
                                            //onBlur={this.setStateHeading}
                                            />
                                        </div>
                                    </div>

                                    <div className="outputDeviceRadio">
                                        <div id="outputDeviceLabel">Send Report To:</div>
                                        <label>
                                            <input type="radio" name="outputType" field="outputType" value="Excel"
                                                onClick={this.setStateOutputType}
                                                checked={this.state.outputType === "Excel"} />
                                            Excel
                                        </label>
                                        <br />
                                        <label>
                                            <input type="radio" name="outputType" field="outputType" value="Screen"
                                                onClick={this.setStateOutputType}
                                                checked={this.state.outputType === "Screen"} />
                                            Screen
                                        </label>
                                    </div>

                                    <div className="reportTypeRadio">
                                        <div id="reportTypeLabel">Report Type:</div>
                                        <label>
                                            <input type="radio" name="reportType" field="reportType" value="Table"
                                                onClick={this.setStateReportType}
                                                checked={this.state.reportType === "Table"} />
                                            Data Table
                                        </label>
                                        <br />
                                        <label>
                                            <input type="radio" name="reportType" field="reportType" value="Bar"
                                                onClick={this.setStateReportType}
                                                checked={this.state.reportType === "Bar"} />
                                            Bar Chart
                                        </label>
                                        <br />
                                        <label>
                                            <input type="radio" name="reportType" field="reportType" value="Column"
                                                onClick={this.setStateReportType}
                                                checked={this.state.reportType === "Column"} />
                                            Column Chart
                                        </label>
                                    </div>

                                    <input type="checkbox" id="displayPercentages" className={this.state.reportType != "Table" ? "hidden" : "checkbox"}
                                        name="displayPercentages"
                                        value={this.state.displayPercentages ? "1" : "0"}
                                        checked={this.state.displayPercentages}
                                        onChange={this.toggleChecked} />
                                    <label for="displayPercentages" className={this.state.reportType != "Table" ? "hidden" : "checkboxLabel"}> Display Percentages </label>
                                </div>

                                <div className="rowCriteriaSection" style={{ padding: "0px" }}>
                                    <div className="variableSectionTitle">Row</div>

                                    <div className="rowCriteria">
                                        <div className="oneLine">
                                            <Input type="codeSelect" labelText="Variable" field="rowItemNumber" value={this.state.rowItemNumber} fieldExt2="rowItemDescription" titleDialog="Item Numbers"//filter: Column_Name <> "" | Display: Item_Nbr,Item_Desc | Order: Item_Nbr
                                                //Item_Desc<Item_Desc,Column_Name<Column_Name,File_Name<File_Name,Code_File<Code_File,Data_Type<Data_Type
                                                width="80px" labelWidth="80px" setNameAlias="code+name" lineStyle="oneLine"
                                                dataSource="Global_Dir"
                                                //shownType="dropdown"
                                                //focused={this.handleOnFocus}
                                                changed={this.handleRowItemNumberChange}
                                                //lostfocused={this.handleRowItemNumberChange}
                                                //lostfocused={(e) => { this.handleLostfocus(e) }}//Fill_Dictionary_Name

                                                lostfocused={(e) => { this.handleItemNumberFieldsLostfocus(e) }}

                                                // set these properties to display popup as dropdown control...
                                                shownType="dropdown" rowIndex="0" hideFilter={true} keyId="rowItemNumber0" codeLabel="Item Nbr"
                                            />

                                            {/*<div id="rowItemDescription"> {this.state.rowItemDescription}</div>*/}
                                            {/*<input type="text"  name="rowItemDescription" field="itemDesc" value={this.state.rowItemDescription}/>*/}


                                            <Input type="codeSelect" labelText="" field="rowItemDescription" value={this.state.rowItemDescription} fieldExt2="rowItemNumber" titleDialog="Item Descriptions"//filter: Column_Name <> "" | Item_Desc (C)|Contains any of|Item_Desc
                                                width="200px" labelWidth="0px" setNameAlias="name+code" lineStyle="oneLine"
                                                dataSource="Global_Dir"
                                                //shownType="dropdown"
                                                //focused={this.handleOnFocus}
                                                changed={(e) => { this.handleRowItemDescriptionChange(e) }}
                                                //lostfocused={(e) => { this.handleLostfocus(e) }}

                                                // set these properties to display popup as dropdown control...
                                                shownType="dropdown" rowIndex="0" hideFilter={false} keyId="rowItemDescription0"
                                            />
                                        </div>

                                        <div className="codesDescriptionsRadio">
                                            <div id="rowCodesDescriptionsLabel">Display As:</div>
                                            <label>
                                                <input type="radio" name="rowCodesDescriptions" field="rowCodesDescriptions" value="Codes"
                                                    onClick={this.setStateRowCodesDescriptions}
                                                    checked={this.state.rowCodesDescriptions === "Codes"} />
                                                Codes
                                            </label>
                                            <br />
                                            <label>
                                                <input type="radio" name="rowCodesDescriptions" field="rowCodesDescriptions" value="Descriptions"
                                                    onClick={this.setStateRowCodesDescriptions}
                                                    checked={this.state.rowCodesDescriptions === "Descriptions"} />
                                                Descriptions
                                            </label>
                                        </div>

                                        <div id="rowLimitValueDiv">
                                            <label>Limit Values:</label>
                                            <input type="text" name="rowLimitValue" field="rowLimitValue" value={this.state.rowLimitValue}
                                                onChange={this.setStateRowLimitValue}
                                            //onChange={(e) => this.setState({ rowLimitValue: e.target.value }) }
                                            />
                                        </div>
                                        <br /><br />
                                        <div className="tableHeader"><div id="valuesLabel">Values</div><div id="descriptionsLabel">Descriptions</div></div>

                                        <div className="rowValuesContainer">
                                            <table id="rowValuesTable">
                                                <thead>
                                                    <tr className="tableHeaderRow">
                                                        <th style={{ width: "30px" }}></th>
                                                        <th style={{ width: "200px" }}></th>
                                                        <th style={{ width: "300px" }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.dialogForm
                                                        && this.state.crossTabRowMv !== null && this.state.crossTabRowMv.length > 0
                                                        ? this.state.crossTabRowMv.map((mv, index) => (
                                                            <tr key={"data-key-" + index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <Input type="codeSelect"
                                                                        width="180px" labelWidth="0px"
                                                                        changed={(e) => { this.onChangeRowValueMv(e, index) }}
                                                                        labelText=""
                                                                        //field="rowValuesMv"
                                                                        field="rowValue"
                                                                        value={mv.rowValue}
                                                                        //value={mv.rowValue ? mv.rowValue : mv.Row_Values}
                                                                        //value={mv.rowValuesMv}
                                                                        //value={this.value}
                                                                        lineStyle="oneLine"
                                                                        dataSource="Global_Dir_Mv" titleDialog="Row Value Lookup"
                                                                        aliasNo={this.state.rowItemNumber} flag={this.state.rowItemNumber}
                                                                        keyId={"rowValue" + index}
                                                                        rowIndex={index} //shownType="dropdown"
                                                                    />

                                                                    {/*<input type="text" name="rowValue" field="rowValue" onChange={(e) => { this.onChangeRowValueMv(e,index) }} />*/}
                                                                </td>
                                                                <td className="rowDescription">
                                                                    <Input type="text" name="rowDescription" field="rowDescription" labelText="" labelWidth="0px"
                                                                        value={mv.rowDescription ? mv.rowDescription : mv.Row_Descriptions}
                                                                        changed={(e) => { this.onChangeRowDescriptionMv(e, index) }}
                                                                        //onKeyDown={(e) => { this.onChangeRowDescriptionMv(e, index) }}
                                                                        keyId={"rowDescription" + index}
                                                                        rowIndex={index}
                                                                    />
                                                                </td>
                                                            </tr>

                                                        )) : <div>
                                                            <tr><td>1</td><td><input type="text" name="rowValue" field="rowValue" value="Testing - Did Not Load Table" /></td><td><input type="text" name="rowDescription" field="rowDescription" /></td></tr>
                                                            <tr><td>2</td><td><input type="text" name="rowValue" field="rowValue" /></td><td><input type="text" name="rowDescription" field="rowDescription" /></td></tr>
                                                            <tr><td>3</td><td><input type="text" name="rowValue" field="rowValue" /></td><td><input type="text" name="rowDescription" field="rowDescription" /></td></tr>
                                                            <tr><td>4</td><td><input type="text" name="rowValue" field="rowValue" /></td><td><input type="text" name="rowDescription" field="rowDescription" /></td></tr>
                                                            <tr><td>5</td><td><input type="text" name="rowValue" field="rowValue" /></td><td><input type="text" name="rowDescription" field="rowDescription" /></td></tr>
                                                        </div>
                                                    }
                                                </tbody>
                                            </table>

                                        </div>
                                        <div className="rowCriteriaGridButtons">
                                            <button id="btnAddRowToRowCriteria" title="Add Row" onClick={this.addRowCriteriaRow}><img id="BUTTON_ADD_ROW_ICON" src={"https://crstar.ers-can.com/images/$$generic.add.png.a5image"}></img></button>
                                            <button id="btnDeleteRowFromRowCriteria" title="Delete Row" onClick={this.deleteRowCriteriaRow}><img id="BUTTON_DELETE_ROW_ICON" src={"https://crstar.ers-can.com/images/$$edit.delete.png.a5image"}></img></button>
                                        </div>
                                    </div>
                                </div>

                                <div className="columnCriteriaSection" style={{ padding: "0px" }}>
                                    <div className="variableSectionTitle">Column</div>
                                    <div className="columnCriteria">

                                        <div className="oneLine">
                                            <Input type="codeSelect" labelText="Variable" field="columnItemNumber" value={this.state.columnItemNumber} fieldExt2="columnItemDescription" titleDialog="Item Numbers"
                                                width="80px" labelWidth="80px" setNameAlias="code+name" lineStyle="oneLine"
                                                dataSource="Global_Dir"
                                                //shownType="dropdown"
                                                //focused={this.handleOnFocus}
                                                //onKeyUp={(e) => { this.handleFormChange(e) }}
                                                changed={this.handleColumnItemNumberChange}
                                                //lostfocused={(e) => { this.handleLostfocus(e) }}//Fill_Dictionary_Name
                                                //

                                                lostfocused={(e) => { this.handleItemNumberFieldsLostfocus(e) }}

                                                // set these properties to display popup as dropdown control...
                                                shownType="dropdown" rowIndex="0" hideFilter={true} keyId="columnItemNumber0"
                                            />

                                            {/*<div id="columnItemDescription"> {this.state.columnItemDescription}</div>*/}

                                            <Input type="codeSelect" labelText="" field="columnItemDescription" value={this.state.columnItemDescription} fieldExt2="columnItemNumber" titleDialog="Item Descriptions"//filter: Column_Name <> "" | Item_Desc (C)|Contains any of|Item_Desc
                                                width="200px" labelWidth="0px" setNameAlias="name+code" lineStyle="oneLine"
                                                dataSource="Global_Dir"
                                                //shownType="dropdown"
                                                //focused={this.handleOnFocus}
                                                changed={(e) => { this.handleColumnItemDescriptionChange(e) }}
                                                //lostfocused={(e) => { this.handleLostfocus(e) }}

                                                // set these properties to display popup as dropdown control...
                                                shownType="dropdown" rowIndex="0" hideFilter={false} keyId="columnItemDescription0"
                                            />
                                        </div>

                                        <div className="codesDescriptionsRadio">
                                            <div id="columnCodesDescriptionsLabel">Display As:</div>
                                            <label>
                                                <input type="radio" name="columnCodesDescriptions" field="columnCodesDescriptions" value="Codes"
                                                    onClick={this.setStateColumnCodesDescriptions}
                                                    checked={this.state.columnCodesDescriptions === "Codes"} />
                                                Codes
                                            </label>
                                            <br />
                                            <label>
                                                <input type="radio" name="columnCodesDescriptions" field="columnCodesDescriptions" value="Descriptions"
                                                    onClick={this.setStateColumnCodesDescriptions}
                                                    checked={this.state.columnCodesDescriptions === "Descriptions"} />
                                                Descriptions
                                            </label>
                                        </div>

                                        <div id="columnLimitValueDiv">
                                            <label>Limit Values:</label>
                                            <input type="text" name="columnLimitValue" field="columnLimitValue" value={this.state.columnLimitValue}
                                                onChange={this.setStateColumnLimitValue}
                                            //onChange={(e) => this.setState({ columnLimitValue: e.target.value })}
                                            />
                                        </div>
                                        <br /><br />
                                        <div className="tableHeader"><div id="valuesLabel">Dep Values</div><div id="descriptionsLabel">Dep Descriptions</div></div>

                                        <div className="columnValuesContainer">
                                            <table id="columnvaluesTable">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "30px" }}></th>
                                                        <th style={{ width: "200px" }}></th>
                                                        <th style={{ width: "300px" }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.dialogForm
                                                        && this.state.crossTabColumnMv !== null && this.state.crossTabColumnMv.length > 0
                                                        ? this.state.crossTabColumnMv.map((mv, index) => (
                                                            <tr key={"data-key-" + index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <Input type="codeSelect" focused={(e) => { this.handleColumnOnFocus(e, index) }}
                                                                        width="180px" labelWidth="0px"
                                                                        changed={(e) => { this.onChangeColumnValueMv(e, index) }}
                                                                        labelText="" field="columnValues"
                                                                        value={mv.colValue}
                                                                        //value={mv.colValue ? mv.colValue : mv.Col_Values}
                                                                        lineStyle="oneLine"
                                                                        dataSource="Global_Dir_Mv" titleDialog="Column Value Lookup"
                                                                        aliasNo={this.state.columnItemNumber} flag={this.state.columnItemNumber}
                                                                        keyId={"colValue" + index}
                                                                        rowIndex={index} //shownType="dropdown"
                                                                    />
                                                                </td>
                                                                <td className="columnDescription">
                                                                    <Input type="text" name="colDescription" field="colDescription" labelText="" labelWidth="0px"
                                                                        value={mv.colDescription ? mv.colDescription : mv.Col_Descriptions}
                                                                        changed={(e) => { this.onChangeColumnDescriptionMv(e, index) }}
                                                                        //onKeyDown={(e) => { this.onChangeColumnDescriptionMv(e, index) }}
                                                                        keyId={"colDescription" + index}
                                                                        rowIndex={index}
                                                                    />
                                                                </td>
                                                            </tr>

                                                        )) : <div>
                                                            <tr><td>1</td><td><input type="text" name="colValue" field="colValue" value="Testing - Did Not Load Table" /></td><td><input type="text" name="colDescription" field="colDescription" /></td></tr>
                                                            <tr><td>2</td><td><input type="text" name="colValue" field="colValue" /></td><td><input type="text" name="colDescription" field="colDescription" /></td></tr>
                                                            <tr><td>3</td><td><input type="text" name="colValue" field="colValue" /></td><td><input type="text" name="colDescription" field="colDescription" /></td></tr>
                                                            <tr><td>4</td><td><input type="text" name="colValue" field="colValue" /></td><td><input type="text" name="colDescription" field="colDescription" /></td></tr>
                                                            <tr><td>5</td><td><input type="text" name="colValue" field="colValue" /></td><td><input type="text" name="colDescription" field="colDescription" /></td></tr>
                                                        </div>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="columnCriteriaGridButtons">
                                            <button id="btnAddRowToColumnCriteria" title="Add Row" onClick={this.addColumnCriteriaRow}><img id="BUTTON_ADD_ROW_ICON" src={"https://crstar.ers-can.com/images/$$generic.add.png.a5image"}></img></button>
                                            <button id="btnDeleteRowFromColumnCriteria" title="Delete Row" onClick={this.deleteColumnCriteriaRow}><img id="BUTTON_DELETE_ROW_ICON" src={"https://crstar.ers-can.com/images/$$edit.delete.png.a5image"}></img></button>
                                        </div>
                                    </div>
                                </div>

                                <div id="CONTAINER_JS_CLOCK" className="hidden">
                                    <label>Selecting Cases</label>
                                    <div id="IMAGE_WAIT" style={{ color: "black", fontWeight: "bold" }}>
                                        Selecting Cases - Please Wait ...
                                    </div>
                                </div>
                            </div>

                            <div className="reportScreenStatusBar">
                                Start Time: {this.state.startTime} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; End Time: {this.state.endTime}
                                {/*{this.state.statusBar}*/}
                            </div>

                            <fieldset className="reportDialogFormButtonBar">
                                <button id="BUTTON_RUN"
                                    className="ErsGrBlueButton"
                                    onClick={(e) => { this.showClockEvent(e) }}
                                    disabled={this.state.crossTabReportLabel && this.state.crossTabReportLabel != "" ? false : true}
                                >
                                    <img id="BUTTON_RUN_ICON" src={this.state.crossTabReportLabel && this.state.crossTabReportLabel != "" ? "https://crstar.ers-can.com/images/$$code.action.run.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$code.action.run.png.a5image"}></img>
                                    Run
                                </button>

                                <button id="BUTTON_CROSS_TAB_NEWRECORD"
                                    className="ErsGrBlueButton"
                                    onClick={this.newRecord.bind(this)}
                                >
                                    <img id="BUTTON_NEWRECORD_ICON" src={"https://crstar.ers-can.com/images/$$navigate.new.png.a5image"}></img>
                                    New
                                </button>

                                <button id="BUTTON_DELETE_RECORD" title="Delete Comparison Report" 
                                    className="ErsGrBlueButton"
                                    onClick={this.deleteRecord.bind(this)}
                                    disabled={this.state.crossTabReportLabel && this.state.crossTabReportLabel != "" ? false : true}
                                >
                                    <img id="BUTTON_DELETE_RECORD_ICON" src={this.state.crossTabReportLabel && this.state.crossTabReportLabel != "" ? "https://crstar.ers-can.com/images/$$edit.delete.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$edit.delete.png.a5image"}></img>
                                    Delete
                                </button>

                                <button type="button" id="BUTTON_REFRESH" className="hidden" title=""
                                    onClick={(e) => { this.refreshScreen(e) }} value="refresh" autoFocus>
                                    Refresh
                                </button>
                            </fieldset>
                        </form>
                        : <p>Cross Tab report screen encountered an issue and cannot load ...</p>}
                </div>
            </div>
        </div>

        return (
            <React.Fragment>
                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {drawingCharts}
                </React.Fragment>

                <React.Fragment>
                    {dialogForm}
                </React.Fragment>

                <React.Fragment>
                    {confirmDeleteLabelMsg}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        crossTabInfo: state.patientAbstract.crossTabInfo,           //This holds information passed from the C# report back to the screen
        clientgroup: state.auth.clientgroup,                            //Passed to C# report to get path
        userId: state.auth.userId,                                      //Passed to C# report to get path

        initialPageVisible: state.patientAbstract.initialPageVisible,   //Controls if security restrictions are set to prevent page from loading
        isDialogLoading: state.patientAbstract.isDialogLoading,
        waitingFor: state.patientAbstract.waitingFor,

        //Trying to get hospital control to work//
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        isAddNewUser: state.patientAbstract.isAddNewUser,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
        generateCrossTab: (flag) => dispatch(actionCreators.generateCrossTab(flag)),
        runCrossTab: (dialogForm) => dispatch(actionCreators.runCrossTab(dialogForm)),

        // Tabs view 03/02/2023
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),

        //Trying to get hospital control to work//
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearAddNewUserFlag: () => dispatch(actionCreators.clearAddNewUserFlag()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
        selectAPopLabel: (label) => dispatch(actionCreators.selectAPopLabel(label)),
        loadCrossTabData: (dialogForm) => dispatch(actionCreators.loadCrossTabData(dialogForm)),
        deleteReportLabel: (dialogForm) => dispatch(actionCreators.deleteCrossTabLabel(dialogForm)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CrossTab);
