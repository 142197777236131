/* eslint-disable */
import React, { Component } from 'react';
import { Nav, NavItem, NavLink, Navbar, Collapse } from "reactstrap";
import { Route, Switch, Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "./Dashboard.css";
import { connect } from "react-redux";
import * as dialogTypes from "../../components/AbstractManagement/maintDialogs";
import { actionCreators } from "../../store/PatientAbstract";
import * as actions from '../../store/actions/index';
import Login from '../../components/UI/Login/Login';
import Auth from '../../containers/Auth/Auth';
import ForgotPassword from '../../components/UI/ForgotPassword/ForgotPassword';
import leftArrow from "../../assets/images/PanelLeftCollapse.png";
import rightArrow from "../../assets/images/PanelLeftExpand.png";
import rapidAbstract from "../../assets/images/Rapid.png";
import inquiringMode from "../../assets/images/sys_warning.png";
import SelectAPop from "../Panel/Shortcuts/SelectAPop";
import PrintPatientAbstract from '../../components/AbstractManagement/PrintForms/PrintAbstractsMaint';
import GenCustomForms from '../../components/AbstractManagement/PrintForms/PrintTxSummary';
import DeletePatientAbstactMaint from '../../components/AbstractManagement/AbstractUtils/DeleteAbstract/DeletePatientAbstactMaint';
import CopyAbstract from '../../components/AbstractManagement/AbstractUtils/CopyAbstract/CopyAbstract';
import PrintSCPForm from '../../components/AbstractManagement/PrintForms/PrintSCPForm';
import PrintDemandLetters from "../Panel/QuickLinks/PrintDemandLetters";
import WebLinks from "../Panel/QuickLinks/WebLinks";
import Draggable from 'react-draggable';

class Dashboard extends Component {

    // isXOpen is used to open/collapse top level menu items
    state = {
        // Alpha System
        isAlphaMenuChildsDashboard: false,
        isAlphaMenuChildsAbsMng: false,
        isAlphaMenuChildsFollowup: false,
        isAlphaMenuChildsReporting: false,
        isCrstarMenuChildsReporting: false,
        isAlphaMenuChildsSysAdm: false,
        isAlphaMenuChildsSysMng: false,
        isAlphaMenuChildsSysConf: false,
        isAlphaMenuChildsHelp: false,
        isNlpWorkQueueMenuChilds: false,

        isNewMenu: false,
        isNewPatientAbstract: false,
        isLogin: false,

        // shortcuts windows
        showSelectAPop: false,
        showPrintPatientAbstract: false,
        showGenCustomForms: false,//GenCustomForms - Print Tx Summary
        showDeletePatientAbstactMaint: false,
        showCopyAbstract: false,
        showPrintDemandLetters: false,
        showPrintSCPForm: false,
        showWebLinks: false,
        updatedMenu: false,
    }

    componentDidMount() {
        this.props.getAvailableItems();

        //if (this.props.authRedirectPath === '/alpha-dashboard') {
        this.props.closeOtherTabItems();

        const tabItem = dialogTypes.ALPHA_MENU;
        this.props.addTabItems(tabItem);
        this.props.routingPage(tabItem);
        this.props.setTab(tabItem);

        this.props.setPatientMenu(true);//set this to show on Tabs

        this.props.setTabName('Dashboard');
        this.props.setAlphaUrl(this.props.authRedirectPath);

        this.props.getTokenOnSession(this.props.userId);
        //}

        setTimeout(function () {
            document.getElementById("alphaDashboardMenu") && document.getElementById("alphaDashboardMenu").getAttribute("class").indexOf("hide") == -1 ? document.getElementById("alphaDashboardMenu").click() : null;
        }, 1000);

        // Adding javascript funtions
        const head = document.querySelector("head");
        const script = document.createElement("script");
        script.setAttribute(
            "src",
            "js/reporting_script_v1.js"
        );
        head.appendChild(script);
    }

    componentDidUpdate() {
        if (!this.state.updatedMenu && this.props.availableMenuGroups) {
            this.updateMenuGroup();
            this.setState({ updatedMenu: true });
        }

        if (!this.state.oktaLogin) {
            const crstarLoggedOut = sessionStorage.getItem('CrstarLogout');
            const user = JSON.parse(localStorage.getItem('crstar_user_by_okta'));
            if ((user || this.props.authState?.isAuthenticated) && !crstarLoggedOut) {
                this.props.oktaLoginSuccess(user);
                this.setState({ oktaLogin: true });
            }
        }
    }

    updateMenuGroup = () => {
        var availableMenuGroups = this.props.availableMenuGroups;
        document.querySelectorAll(".NavItemTopExt").forEach((nav, index) => {
            const ncls = nav.getAttribute("class");
            availableMenuGroups.indexOf(nav.getAttribute("data-alias")) !== -1 ? ncls : nav.setAttribute("class", ncls + " hide");
        });
    }

    clickMe = (event) => {
        // clear all storing data
        this.props.clearAllScreenDataOnTab();

        // Fixing #PR00555 - clear all checking common before move to other tabs
        this.props.clearCommonLookupItem();
        this.props.clearCommonLookupItem2();

        //
        if (event.target.outerText === "Support Portal") {
            window.open("https://ers.myportallogin.com/");
            return false;
        } else if (event.target.outerText === "Resource Page") {
            var clientgroup = this.props.clientgroup;
            var token = this.props.alphaToken;

            var url = "https://www.mycrstar.com/r08122022p/?str=" + clientgroup + "&tk=" + token;
            window.open(url, "_blank");

            return false;
        }

        if (this.props.alphaUrl === '/logout' || event.target.outerText === "Logout") {
            this.setState({ isLogin: true });
            this.props.setTab(dialogTypes.LOGIN);
            this.props.setTwoFactorAuthDialogVisible(true);
            //
            var uid = this.props.userId ? this.props.userId : event.target.attributes["datakey"].value;
            var cg = this.props.clientgroup;
            this.props.onLogout(uid, cg);

            // clear all previous session
            this.props.resetAllStoringOfUser();
            sessionStorage.clear();
            localStorage.clear();

            const iframe = document.createElement("iframe");
            iframe.src = this.props.alphaSystemUrlBaseUrl + "/Logout_noredirect.a5w";
            iframe.style.display = "none";
            iframe.class = "hidden";
            iframe.width = "0px";
            iframe.height = "0px";

            document.body.appendChild(iframe);
        } else if (this.props.alphaUrl.indexOf('.a5w') !== -1
            || this.props.alphaUrl.indexOf('Launch') !== -1
            || (event.target.className.indexOf('alpha-system-text') !== -1 && event.target.innerText !== "Patient Abstract" && event.target.innerText !== "Rapid Abstract")
        ) {
            this.props.closeOtherTabItems();

            const tabItem = dialogTypes.ALPHA_MENU;
            this.props.addTabItems(tabItem);
            this.props.routingPage(tabItem);
            this.props.setTab(tabItem);
            this.props.setTabName(event.target.innerText);
            this.props.getTokenOnSession(this.props.userId);

            if (event.target.getAttribute("class").indexOf("userSecurityEnable") != -1) {
                this.props.closeOtherTabItems();
                var thjs = this;
                setTimeout(function () {
                    var availableItems = thjs.props.availableItems;
                    if (!availableItems || availableItems.length === 0) {
                        thjs.props.getAvailableItems();
                    }
                }, 0);
            }
        } else {
            const tabItem = dialogTypes.LOOKUP;
            this.props.addTabItems(tabItem);
            this.props.routingPage(tabItem);
            this.props.setTab(tabItem);
        }

        // clear alphaUrlInnerTab
        this.props.setAlphaUrl(null);
    }

    // Gets the id of the clicked-on top level element
    // In order to compute the correct object property to set in the state
    toggleCollapse = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        if (event.target.getAttribute("class") && event.target.getAttribute("class").indexOf("NavItemTopExt") != -1) {
            //this.props.setTabName("ON_LEFT_MENU");
        }

        var itemToCollapseSts = null;
        // Fixing #PR00217: Opening one menu should collapse previous menu choices
        this.setState({
            isAlphaMenuChildsDashboard: false,
            isAlphaMenuChildsAbsMng: false,
            isAlphaMenuChildsFollowup: false,
            isAlphaMenuChildsReporting: false,
            isCrstarMenuChildsReporting: false,
            isAlphaMenuChildsSysAdm: false,
            isAlphaMenuChildsSysMng: false,
            isAlphaMenuChildsSysConf: false,
            isAlphaMenuChildsHelp: false,
            isNlpWorkQueueMenuChilds: false,
        });

        // Set padding for left menu if it has scroll...
        //document.getElementById("tbi_MENU").querySelector(".navbar").style.padding = "12px";

        let itemToCollapse;
        switch (event.target.id) {
            case "alphaDashboardMenu":
                itemToCollapse = "isAlphaMenuChildsDashboard";
                itemToCollapseSts = this.state.isAlphaMenuChildsDashboard;
                break;
            case "alphaAbsMngMenu":
                itemToCollapse = "isAlphaMenuChildsAbsMng";
                itemToCollapseSts = this.state.isAlphaMenuChildsAbsMng;
                break;
            case "alphaFollowupMenu":
                itemToCollapse = "isAlphaMenuChildsFollowup";
                itemToCollapseSts = this.state.isAlphaMenuChildsFollowup;
                break;
            case "alphaReportingMenu":
                itemToCollapse = "isAlphaMenuChildsReporting";
                itemToCollapseSts = this.state.isAlphaMenuChildsReporting;
                break;
            case "crstarReportingMenu":
                itemToCollapse = "isCrstarMenuChildsReporting";
                itemToCollapseSts = this.state.isCrstarMenuChildsReporting;

                //!itemToCollapseSts ? document.getElementById("tbi_MENU").querySelector(".navbar").style.padding = "2px" : "";
                break;
            case "alphaSysAdmMenu":
                itemToCollapse = "isAlphaMenuChildsSysAdm";
                itemToCollapseSts = this.state.isAlphaMenuChildsSysAdm;
                break;
            case "alphaSysMngMenu":
                itemToCollapse = "isAlphaMenuChildsSysMng";
                itemToCollapseSts = this.state.isAlphaMenuChildsSysMng;
                break;
            case "alphaSysConfMenu":
                itemToCollapse = "isAlphaMenuChildsSysConf";
                itemToCollapseSts = this.state.isAlphaMenuChildsSysConf;
                break;
            case "alphaHelpMenu":
                itemToCollapse = "isAlphaMenuChildsHelp";
                itemToCollapseSts = this.state.isAlphaMenuChildsHelp;
                break;
            case "nlpWorkQueueMenu":
                itemToCollapse = "isNlpWorkQueueMenuChilds";
                itemToCollapseSts = this.state.isNlpWorkQueueMenuChilds;
                break;
            default:
                itemToCollapseSts = null;
                break;
        }

        //this.setState(prevState => ({ [itemToCollapse]: !prevState[itemToCollapse] }));
        this.setState(prevState => ({ [itemToCollapse]: !itemToCollapseSts }));
        return false;
    }

    openShortcuts = (event) => {
        // clear all storing data
        this.props.clearAllScreenDataOnTab();

        // Fixing #PR00555 - clear all checking common before move to other tabs
        this.props.clearCommonLookupItem();
        this.props.clearCommonLookupItem2();

        let alias = event.target.getAttribute("data-alias");
        var showOnTab = false;
        switch (alias) {
            case " Patient Lookup ":
                /*
                setTimeout(function () {
                    document.getElementById("alphaAbsMngMenu") && document.getElementById("alphaAbsMngMenu").nextSibling.querySelector(".collapse") && document.getElementById("alphaAbsMngMenu").nextSibling.querySelector(".collapse").getAttribute("class").indexOf("show") == -1 ? document.getElementById("alphaAbsMngMenu").click() : null;
                }, 0);
                */
                this.props.setTabName(alias);
                document.getElementById("linkToPatientLookupMenu").click();
                return false;
            case " Select A Pop ":
                //this.setState({ showSelectAPop: true });
                document.getElementById("linkToSelectAPopMenu").click();
                return false;
            case "Print Patient Abstract":
                //this.setState({ showPrintPatientAbstract: true });
                //this.props.loadPatientAbsInfo(0);//#1-Print Abstract
                document.getElementById("linkToPrintFormsAndAbstract").click();
                return false;
            case "Print Tx Summary":
                //this.setState({ showGenCustomForms: true });
                //this.props.loadPatientAbsInfo(0);//#1-Print Tx
                document.getElementById("linkToPrintFormsAndTxSum").click();
                return false;
            case "Delete Patient Abstact Maint":
                //this.setState({ showDeletePatientAbstactMaint: true });
                //this.props.loadPatientAbsInfo(0);//#1-Delete Abstract
                document.getElementById("linkToAbsUtilAndDelete").click();
                return false;
            case "Copy Abstract":
                //this.setState({ showCopyAbstract: true });
                //this.props.loadPatientAbsInfo(0);//#1-Copy Abstract
                document.getElementById("linkToAbsUtilAndCopy").click();
                return false;
            case "Print Demand Letters":
                this.setState({ showPrintDemandLetters: true });
                break;
            case "Print SCP Form":
                //this.setState({ showPrintSCPForm: true });
                //this.props.loadPatientAbsInfo(0);//#1-Print SCP
                document.getElementById("linkToPrintFormsAndSCP").click();
                return false;
            case "Web Links":
                this.setState({ showWebLinks: true });
                break;
        }

        /*
        if (showOnTab && event.target.className.indexOf('alpha-system-text') !== -1) {
            this.props.closeOtherTabItems();

            const tabItem = dialogTypes.ALPHA_MENU;
            this.props.addTabItems(tabItem);
            this.props.routingPage(tabItem);
            this.props.setTab(tabItem);

            this.props.setTabName(event.target.innerText);

            this.props.getTokenOnSession(this.props.userId);
        } else*/ if (showOnTab) {
            if (document.getElementById("linkToPatientLookupMenu")) {
                document.getElementById("linkToPatientLookupMenu").click();
            } else {
                this.props.setAlphaUrl('/patient-abstract');

                const tabItem = dialogTypes.LOOKUP;
                this.props.addTabItems(tabItem);
                this.props.routingPage(tabItem);
                this.props.setTab(tabItem);
            }
        }
    }

    hideShortcuts = (alias) => {
        switch (alias) {
            case "Select A Pop":
                this.setState({ showSelectAPop: false });
                break;
            case "Print Patient Abstract":
                this.setState({ showPrintPatientAbstract: false });
                break;
            case "Print Tx Summary":
                this.setState({ showGenCustomForms: false });
                break;
            case "Delete Patient Abstact Maint":
                this.setState({ showDeletePatientAbstactMaint: false });
                break;
            case "Copy Abstract":
                this.setState({ showCopyAbstract: false });
                break;
            case "Print Demand Letters":
                this.setState({ showPrintDemandLetters: false });
                break;
            case "Print SCP Form":
                this.setState({ showPrintSCPForm: false });
                break;
            case "Web Links":
                this.setState({ showWebLinks: false });
                break;
        }
        return false;
    }

    showOrHide = (index) => {
        const btnName = dialogTypes.menuNamesOnLeftPanel[index];
        var availableMenuGroups = this.props.availableMenuGroups ? this.props.availableMenuGroups : null;
        var customizations = this.props.customizations ? this.props.customizations : null;

        if (availableMenuGroups === null || availableMenuGroups.length === 0 || availableMenuGroups.indexOf(btnName) != -1
            //#PR00542 - Want to enable the new reporting button, for clients when the "customizations" column contains the word REPORTING
            //If the word REPORTING is not in the customization the button should not be visible.
            || (btnName == "MENU_CRSTAR_REPORTING" && customizations.indexOf("REPORTING") != -1)
            || (btnName == "MENU_NLP_WORK_QUEUE" && customizations.indexOf("WORK_QUEUE") != -1)
        ) {
            //This does not appear to be based upon whether or not a value is in the "customizations" column in the clientgroup table.  The customizations column is currently NULL, yet the button is still visible and should not be.
            if (btnName == "MENU_CRSTAR_REPORTING" && (customizations == null || customizations.indexOf("REPORTING") == -1)) {
                return "hide";
            } else if (btnName == "MENU_NLP_WORK_QUEUE" && (customizations == null || customizations.indexOf("WORK_QUEUE") == -1)) {
                return "hide";
            }
            return "show";
        } else {
            return "hide";
        }
    }

    //
    hideMsgOfAbstracts = (event, obj) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.props.clearErrorMsgOfAbstracts();

        let invalidField = this.props.invalidField ? this.props.invalidField : this.props.latestFocusedField;
        setTimeout(function () {
            let input = document.getElementById(invalidField);
            input && !document.getElementById("linkOfStagingTab") ? input.focus() : null;
        }, 100);

        return false;
    }

    render() {

        let selectAPop = null;
        {
            selectAPop = this.state.showSelectAPop ? <SelectAPop closeWindow={e => { this.hideShortcuts("Select A Pop") }} alphaUrl="/SelectAPop" /> : null
        }

        let printPatientAbstract = null;
        {
            printPatientAbstract = this.state.showPrintPatientAbstract ?
                <PrintPatientAbstract closeWindow={e => { this.hideShortcuts("Print Patient Abstract") }} alphaUrl="/PrintAbstractsMaint" onRightMenu={true} />
                : null
        }

        let genCustomForms = null;
        {
            genCustomForms = this.state.showGenCustomForms ?
                <GenCustomForms closeWindow={e => { this.hideShortcuts("Print Tx Summary") }} alphaUrl="/GenCustomForms" onRightMenu={true} />
                : null
        }

        let deletePatientAbstactMaint = null;
        {
            deletePatientAbstactMaint = this.state.showDeletePatientAbstactMaint ?
                <DeletePatientAbstactMaint closeWindow={e => { this.hideShortcuts("Delete Patient Abstact Maint") }} alphaUrl="/DeletePatientAbstactMaint" onRightMenu={true} />
                : null
        }

        let copyAbstract = null;
        {
            copyAbstract = this.state.showCopyAbstract ?
                <CopyAbstract closeWindow={e => { this.hideShortcuts("Copy Abstract") }} alphaUrl="/CopyAbstract" onRightMenu={true} />
                : null
        }

        let printDemandLetters = null;
        {
            printDemandLetters = this.state.showPrintDemandLetters ?
                <PrintDemandLetters closeWindow={e => { this.hideShortcuts("Print Demand Letters") }} alphaUrl="/GenDemandLetters" />
                : null
        }

        let printSCPForm = null;
        {
            printSCPForm = this.state.showPrintSCPForm ?
                <PrintSCPForm closeWindow={e => { this.hideShortcuts("Print SCP Form") }} alphaUrl="/PrintSCPForm" onRightMenu={true} />
                : null
        }

        let webLinks = null;
        {
            webLinks = this.state.showWebLinks ? <WebLinks closeWindow={e => { this.hideShortcuts("Web Links") }} /> : null
        }

        //
        let errorMsgOfAbstractScreens = null;
        {
            errorMsgOfAbstractScreens = this.props.errorMsgOfAbstracts ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Validation Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMsgOfAbstracts(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">{this.props.showMsgOfAbstracts}</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMsgOfAbstracts(e, this) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        var isPatientMainPage = false;
        if (document.getElementById("patientForm") && document.getElementById("patientForm").length > 0) {
            isPatientMainPage = true;
        }

        var sidebarClass = this.props.isOpen ? "sidebar open" : "sidebar";
        return (
            this.state.isLogin ?
                <Login>
                    <Switch>
                        <Route path="/login" component={Auth} />
                        <Route path="/forgot-password" component={ForgotPassword} />
                        <Redirect to="/login" />
                    </Switch>
                </Login>
                :

                <div id="MAIN_MENU_ON_LEFT">
                    {
                        <div style={{ position: "inherit", height: "20px" }} id="inquiryModeNotification">
                            <div className={this.props.rapidAbstract ? "rapidAbstract" : "hidden"} style={{ float: "left" }}>
                                <img src={rapidAbstract} alt="" />
                            </div>
                            <div id="inquiryModeMsg" className={
                                (
                                    this.props.inquiringMode
                                    //|| (this.props.availableHospsData && this.props.availableHospsData.indexOf(this.props.selectedHospital) < 0)
                                    || (!isPatientMainPage && this.props.availableHospsData != null && this.props.selectedPrimarySummary != null && this.props.availableHospsData.indexOf(this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.hosp : "01") < 0)
                                ) && (this.props.alphaUrl === "/patient-abstract" || this.props.alphaUrl === "/rapid-abstract") && this.props.currentDialog !== dialogTypes.LOOKUP ? "inquiringCls" : "hidden"
                            }>
                                <img src={inquiringMode} alt="" data-dump={this.props.availableHospsData + this.props.selectedHospital} />
                                <span className="InquiryModeStatusLine">Inquiry mode. Changes will not be saved</span>
                            </div>
                        </div>
                    }

                    <div id="Sidebar" className={sidebarClass}>
                        <div className="ErsGrBlueGridHeader">
                            <button onClick={this.props.toggleSidebar} className="sidebar-toggle">
                                <img src={this.props.isOpen ? leftArrow : rightArrow} alt="arrow" style={{ verticalAlign: "text-top" }} />
                            </button>
                        </div>
                        <div id="tbi_MENU">
                            <Navbar>
                                <div onClick={this.toggleCollapse} className={"NavItemTop NavItemTopExt " + this.showOrHide(0)} id="alphaDashboardMenu" data-alias="MENU_DASHBOARD">DASHBOARD</div>
                                <Nav vertical>
                                    <Collapse isOpen={this.state.isAlphaMenuChildsDashboard}>
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text" id="linkToMainDashboardMenu" to="/alpha-dashboard"
                                                onClick={(e) => this.clickMe(e)}>Dashboard</NavLink>
                                        </NavItem>
                                    </Collapse>
                                </Nav>
                                <div onClick={this.toggleCollapse} className={"NavItemTopExt " + this.showOrHide(1)} id="alphaAbsMngMenu" data-alias="MENU_ABSTRACT_MANAGEMENT">ABSTRACT MANAGEMENT</div>
                                <Nav vertical>
                                    <Collapse isOpen={this.state.isAlphaMenuChildsAbsMng}>
                                        <NavItem className={this.showOrHide(10)} id="MENU_NLP_WORK_QUEUE">
                                            <NavLink tag={Link} className="alpha-system-text" to="/NlpWorkQueue"
                                                onClick={(e) => this.clickMe(e)}>NLP Work Queue</NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text" id="linkToPatientLookupMenu" to="/patient-abstract"
                                                onClick={(e) => this.clickMe(e)}>Patient Abstract</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text" to="/rapid-abstract"
                                                onClick={(e) => this.clickMe(e)}>Rapid Abstract</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            {/*}
                                            <NavLink tag={Link} className="alpha-system-text" to="/AbstractPrintNav"
                                                onClick={(e) => this.clickMe(e)}>Print Forms</NavLink>
                                            {*/}
                                            <NavLink tag={Link} className="alpha-system-text userSecurityEnable" to="/PrintForms"
                                                onClick={(e) => this.clickMe(e)}>Print Forms</NavLink>
                                        </NavItem>

                                        <NavItem className="hidden">
                                            <NavLink tag={Link} className="alpha-system-text" to="/PrintForms"
                                                id="linkToPrintFormsAndAbstract"
                                                onClick={(e) => this.clickMe(e)}>Abstract_From_CRStar_Shortcuts</NavLink>
                                        </NavItem>
                                        <NavItem className="hidden">
                                            <NavLink tag={Link} className="alpha-system-text" to="/PrintForms"
                                                id="linkToPrintFormsAndTxSum"
                                                onClick={(e) => this.clickMe(e)}>TxSum_From_CRStar_Shortcuts</NavLink>
                                        </NavItem>
                                        <NavItem className="hidden">
                                            <NavLink tag={Link} className="alpha-system-text" to="/PrintForms"
                                                id="linkToPrintFormsAndSCP"
                                                onClick={(e) => this.clickMe(e)}>SCP_From_CRStar_Shortcuts</NavLink>
                                        </NavItem>
                                        <NavItem className="hidden">
                                            <NavLink tag={Link} className="alpha-system-text" to="/AbstractUtils"
                                                id="linkToAbsUtilAndDelete"
                                                onClick={(e) => this.clickMe(e)}>Delete_From_CRStar_Shortcuts</NavLink>
                                        </NavItem>
                                        <NavItem className="hidden">
                                            <NavLink tag={Link} className="alpha-system-text" to="/AbstractUtils"
                                                id="linkToAbsUtilAndCopy"
                                                onClick={(e) => this.clickMe(e)}>Copy_From_CRStar_Shortcuts</NavLink>
                                        </NavItem>

                                        <NavItem>
                                            {/*}
                                            <NavLink tag={Link} className="alpha-system-text" to="/AbstractUtilityNavLaunch"
                                                onClick={(e) => this.clickMe(e)}>Abstract Utilities</NavLink>
                                            {*/}
                                            <NavLink tag={Link} className="alpha-system-text userSecurityEnable" to="/AbstractUtils"
                                                onClick={(e) => this.clickMe(e)}>Abstract Utilities</NavLink>
                                        </NavItem>
                                        {/*}
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text" to="/utilityP"
                                                onClick={(e) => this.clickMe(e)}>Utility Page</NavLink>
                                        </NavItem>
                                        {*/}
                                    </Collapse>
                                </Nav>
                                <div onClick={this.toggleCollapse} className={"NavItemTop NavItemTopExt " + this.showOrHide(2)} id="alphaFollowupMenu" data-alias="MENU_FOLLOW_UP_MANAGEMENT">FOLLOW UP MANAGEMENT</div>
                                <Nav vertical>
                                    <Collapse isOpen={this.state.isAlphaMenuChildsFollowup}>
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text" to="/FollowupNavLaunch"
                                                onClick={(e) => this.clickMe(e)}>Followup</NavLink>
                                        </NavItem>
                                    </Collapse>
                                </Nav>

                                <div onClick={this.toggleCollapse} className={"NavItemTopExt " + this.showOrHide(8)} id="crstarReportingMenu" data-alias="MENU_CRSTAR_REPORTING">ENHANCED REPORTING</div>
                                <Nav vertical>
                                    <Collapse isOpen={this.state.isCrstarMenuChildsReporting}>
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text userSecurityEnable" id="linkToSelectAPopMenu"
                                                to="/ReportToSelectAPop"
                                                rel="/ReportSelectPopNavLaunch"
                                                onClick={(e) => this.clickMe(e)}> Select a Population </NavLink>
                                        </NavItem>
                                        {/*}
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text"
                                                to="/ReportToGraphAnItem"
                                                rel="/ReportAdhocNavLaunch"
                                                onClick={(e) => this.clickMe(e)}>Graph An Item</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text"
                                                to="/ReportToSurvivalByStage"
                                                rel="/ReportCannedNavLaunch"
                                                onClick={(e) => this.clickMe(e)}>Survival By Stage</NavLink>
                                        </NavItem>
                                        {*/}
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text userSecurityEnable"
                                                to="/ReaCannedReports"
                                                onClick={(e) => this.clickMe(e)}>Canned Reports</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text"
                                                to="/ReaRegistryMgmtReports"
                                                onClick={(e) => this.clickMe(e)}>Registry Mgmt Reports</NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text userSecurityEnable"
                                                to="/ReaAdminReports"
                                                onClick={(e) => this.clickMe(e)}>Admin</NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text"
                                                to="/ReaAccreditationReports"
                                                onClick={(e) => this.clickMe(e)}>Accreditation Reports</NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text"
                                                to="/ReaComparisonReports"
                                                onClick={(e) => this.clickMe(e)}>Comparison Reports</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text userSecurityEnable"
                                                to="/ReaAdhocReports"
                                                onClick={(e) => this.clickMe(e)}>Adhoc Reports</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text"
                                                to="/ReaMiscellaneousReports"
                                                onClick={(e) => this.clickMe(e)}>Miscellaneous Reports</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text userSecurityEnable"
                                                to="/ReaDataExports"
                                                onClick={(e) => this.clickMe(e)}>Data Exports</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text userSecurityEnable"
                                                to="/ReportUtility"
                                                onClick={(e) => this.clickMe(e)}>Reporting Utilities</NavLink>
                                        </NavItem>
                                        {/* <div id="makeHidden">
                                            <NavItem>
                                                <NavLink tag={Link} className="alpha-system-text"
                                                    to="/ReaAccreditationReports"
                                                    onClick={(e) => this.clickMe(e)}>Accreditation Reports</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="alpha-system-text"
                                                    to="/ReaComparisonReports"
                                                    onClick={(e) => this.clickMe(e)}>Comparison Reports</NavLink>
                                            </NavItem>
                                        </div> */}

                                    </Collapse>
                                </Nav>
                                <div onClick={this.toggleCollapse} className={"NavItemTopExt " + this.showOrHide(4)} id="alphaSysAdmMenu" data-alias="MENU_SYSTEM_ADMINISTRATION">SYSTEM ADMINISTRATION</div>
                                <Nav vertical>
                                    <Collapse isOpen={this.state.isAlphaMenuChildsSysAdm}>
                                        {
                                            this.props.securityGroup && this.props.securityGroup.indexOf("Admin") != -1 ?
                                                <NavItem>
                                                    <NavLink tag={Link} className="alpha-system-text userSecurityEnable"
                                                        to="/SysAdmSecurityMaint"
                                                        rel="/SysAdmSecurityNavLaunch"
                                                        onClick={(e) => this.clickMe(e)}>Security Maintenance</NavLink>
                                                </NavItem>
                                                : null
                                        }
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text"
                                                //to="/SysAdmDataNavLaunch" 
                                                to="/DataManagment"
                                                onClick={(e) => this.clickMe(e)}>Data Management</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text" to="/SysAdmUtility"
                                                onClick={(e) => this.clickMe(e)}>Utilities</NavLink>
                                        </NavItem>
                                    </Collapse>
                                </Nav>
                                <div onClick={this.toggleCollapse} className={"NavItemTopExt " + this.showOrHide(5)} id="alphaSysMngMenu" data-alias="MENU_SYSTEM_MANAGEMENT">SYSTEM MANAGEMENT</div>
                                <Nav vertical>
                                    <Collapse isOpen={this.state.isAlphaMenuChildsSysMng}>
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text userSecurityEnable"
                                                //to="/HybridSysManNavLaunch"
                                                to="/ReaSystemMgmt"
                                                rel="/HybridSysManNavLaunch"
                                                onClick={(e) => this.clickMe(e)}>System Management</NavLink>
                                        </NavItem>
                                        {/*}
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text" to="/HybridSysManCodesNavLaunch"
                                                onClick={(e) => this.clickMe(e)}>Codes Maintenance</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text" to="/ZipCodesMaint"
                                                //id="ZipCodesMaintenance"
                                                onClick={(e) => this.clickMe(e)}>Zip Codes</NavLink>
                                        </NavItem>
                                        {*/}
                                    </Collapse>
                                </Nav>
                                <div onClick={this.toggleCollapse} className={"NavItemTopExt " + this.showOrHide(6)} id="alphaSysConfMenu" data-alias="MENU_PREFERENCES">PREFERENCES</div>
                                <Nav vertical>
                                    <Collapse isOpen={this.state.isAlphaMenuChildsSysConf}>
                                        {/*}
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text" to="/SysConNavLaunch"
                                                onClick={(e) => this.clickMe(e)}>System Configuration</NavLink>
                                        </NavItem>
                                        {*/}
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text userSecurityEnable" to="/GenEditsSettings"
                                                onClick={(e) => this.clickMe(e)}>System Configuration</NavLink>
                                        </NavItem>
                                    </Collapse>
                                </Nav>
                                <div onClick={this.toggleCollapse} className={"NavItemTopExt " + this.showOrHide(3)} id="alphaReportingMenu" data-alias="MENU_REPORTING">REPORTING</div>
                                <Nav vertical>
                                    <Collapse isOpen={this.state.isAlphaMenuChildsReporting}>
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text" to="/ReportSelectPopNavLaunch"
                                                onClick={(e) => this.clickMe(e)}>Select a Population</NavLink>
                                        </NavItem>
                                        {/* <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text" to="/ReportCannedNavLaunch"
                                                onClick={(e) => this.clickMe(e)}>Canned Reports</NavLink>
                                        </NavItem> */}
                                        {/* <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text" to="/ReportAcosNavLaunch"
                                                onClick={(e) => this.clickMe(e)}>CPM Reports</NavLink>
                                        </NavItem> */}
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text" to="/ReportAdhocNavLaunch"
                                                onClick={(e) => this.clickMe(e)}>Adhoc Reports</NavLink>
                                        </NavItem>
                                        {/*<NavItem>
                                            <NavLink tag={Link} className="alpha-system-text" to="/ReportMiscNavLaunch"
                                                onClick={(e) => this.clickMe(e)}>Miscellaneous Reports</NavLink>
                                        </NavItem> */}
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text" to="/ReportDataNavLaunch"
                                                onClick={(e) => this.clickMe(e)}>Data Exports</NavLink>
                                        </NavItem>
                                        {/*<NavItem>
                                            <NavLink tag={Link} className="alpha-system-text" to="/ReportUtilityNavLaunch"
                                                onClick={(e) => this.clickMe(e)}>Reporting Utilities</NavLink>
                                        </NavItem> */}
                                    </Collapse>
                                </Nav>

                                <div onClick={this.toggleCollapse} className={"NavItemTopExt " + this.showOrHide(7)} id="alphaHelpMenu" data-alias="MENU_HELP">HELP</div>
                                <Nav vertical>
                                    <Collapse isOpen={this.state.isAlphaMenuChildsHelp}>
                                        <NavItem>
                                            <NavLink tag={Link} className="alpha-system-text" to="/alpha-support-portal"
                                                onClick={(e) => this.clickMe(e)}>Support Portal</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link}
                                                className="alpha-system-text"
                                                to="#"
                                                //to={{ pathname: "https://reainc.entrytracking.ca/test_resource.htm?str=" + this.props.clientgroup + "&tk=" + this.props.userId }}
                                                //target="_blank"
                                                style={{ width: "1.5in" }}
                                                onClick={(e) => this.clickMe(e)}
                                            >
                                                Resource Page
                                            </NavLink>
                                        </NavItem>
                                    </Collapse>
                                </Nav>

                                <div onClick={this.toggleCollapse} className="NavItemTop" id="logoutLink"> </div>
                                <div style={{ marginLeft: "30px" }} id="logoutButtonArea">
                                    <div className="emptyLine"></div>
                                    <NavLink tag={Link} className="alpha-system-text" to="/logout"
                                        datakey={this.props.userId}
                                        style={{ width: "1.5in" }}
                                        onClick={(e) => this.clickMe(e)}>Logout</NavLink>
                                </div>

                            </Navbar>

                            <div className="emptyLine"></div>
                            { /* CRStar Shortcuts - START */}
                            <div id="tb1FRAME_1" className={"A5container NavItemTopExt " + this.showOrHide(9)} style={{ width: "222px", left: "7px", position: "fixed" }} data-alias="MENU_CRSTAR_SHORTCUTS">
                                <div className="ErsGrBlueAccordionButtonSelected" style={{ textAlign: "center" }}>CRStar Shortcuts</div>
                                <div className="ErsGrBlueAccordionPane">
                                    <div className="A5container">
                                        <div id="SPACER_5" style={{ height: "1px", width: "5px", font: "0pt Arial" }}>&nbsp;</div>
                                    </div>
                                    <div className="A5container">
                                        <button type="button" id="BTN_SCPATIENTLOOKUP" className="A5WCLayout"
                                            style={{ backgroundImage: "url(https://crstar.ers-can.com/images/SC_PatientLookup.png)", width: "68px", height: "68px", color: "transparent" }}
                                            title=""
                                            to="/patient-abstract"
                                            data-alias=" Patient Lookup "
                                            onClick={(e) => this.openShortcuts(e)}>Lookup</button>
                                    </div>
                                    <div className="A5container" style={{ width: ".002in", font: "0pt" }}>&nbsp;</div>
                                    <div className="A5container">
                                        <button type="button" id="BTN_SCSELECTAPOP" className="A5WCLayout _alpha-system-text"
                                            style={{
                                                backgroundImage: "url(https://crstar.ers-can.com/images/SC_SelectAPop.png)", width: "68px", height: "68px", color: "transparent"
                                            }}
                                            title=""
                                            to="/ReportSelectPopNavLaunch"
                                            data-alias=" Select A Pop "
                                            onClick={(e) => this.openShortcuts(e)}>Select Pop</button>
                                    </div>
                                    <div className="A5container" style={{ width: ".002in", font: "0pt" }}>&nbsp;</div>
                                    <div className="A5container">
                                        <button type="button" id="BTN_SCPRINTABSTRACT" className="A5WCLayout _alpha-system-text"
                                            style={{
                                                backgroundImage: "url(https://crstar.ers-can.com/images/SC_PrintAbstract.png)", width: "68px", height: "68px", color: "transparent"
                                            }}
                                            title=""
                                            to="/PrintAbstractsMaint"
                                            data-alias="Print Patient Abstract"
                                            onClick={(e) => this.openShortcuts(e)}>Abstract</button>
                                    </div>
                                    <div className="A5container" style={{ width: ".002in", font: "0pt" }}>&nbsp;</div>
                                    <div className="A5container">
                                        <div id="SPACER_6" style={{ height: "1px", width: "5px", font: "0pt Arial" }}>&nbsp;</div>
                                    </div>
                                    <div className="A5container">
                                        <button type="button" id="BTN_SCPRINTTXSUMMARY" className="A5WCLayout _alpha-system-text"
                                            style={{
                                                backgroundImage: "url(https://crstar.ers-can.com/images/SC_PrintTxSummary.png)", width: "68px", height: "68px", color: "transparent"
                                            }}
                                            title=""
                                            to="/PrintAbstractsMaint"
                                            data-alias="Print Tx Summary"
                                            onClick={(e) => this.openShortcuts(e)}>Tx Summary/Custom</button>
                                    </div>
                                    <div className="A5container" style={{ width: ".002in", font: "0pt" }}>&nbsp;</div>
                                    <div className="A5container">
                                        <button type="button" id="BTN_SCDELETEANABSTRACT" className="A5WCLayout _alpha-system-text"
                                            style={{
                                                backgroundImage: "url(https://crstar.ers-can.com/images/SC_DeleteAbstract.png)", width: "68px", height: "68px", color: "transparent"
                                            }}
                                            title=""
                                            data-alias="Delete Patient Abstact Maint"
                                            onClick={(e) => this.openShortcuts(e)}>Delete Abstract</button>
                                    </div>
                                    <div className="A5container" style={{ width: ".002in", font: "0pt" }}>&nbsp;</div>
                                    <div className="A5container">
                                        <button type="button" id="BTN_SCCOPYABSTRACT" className="A5WCLayout _alpha-system-text"
                                            style={{
                                                backgroundImage: "url(https://crstar.ers-can.com/images/SC_CopyAbstract.png)", width: "68px", height: "68px", color: "transparent"
                                            }}
                                            title=""
                                            data-alias="Copy Abstract"
                                            onClick={(e) => this.openShortcuts(e)}>Copy Abstract</button>
                                    </div>
                                    <div className="A5container" style={{ width: ".002in", font: "0pt" }}>&nbsp;</div>
                                    <div className="A5container">
                                        <div id="SPACER_7" style={{ height: "1px", width: "5px", font: "0pt Arial" }}>&nbsp;</div>
                                    </div>
                                    <div className="A5container">
                                        <button type="button" id="BTN_SCPRINTDEMANDLETTER" className="A5WCLayout"
                                            style={{
                                                backgroundImage: "url(https://crstar.ers-can.com/images/SC_PrintDemandLetter.png)", width: "68px", height: "68px", color: "transparent"
                                            }}
                                            title=""
                                            data-alias="Print Demand Letters"
                                            onClick={(e) => this.openShortcuts(e)}>On Demand</button>
                                    </div>
                                    <div className="A5container" style={{ width: ".002in", font: "0pt" }}>&nbsp;</div>
                                    <div className="A5container">
                                        <button type="button" id="BTN_SCPRINTSCP" className="A5WCLayout _alpha-system-text"
                                            style={{
                                                backgroundImage: "url(https://crstar.ers-can.com/images/SC_PrintSCP.png)", width: "68px", height: "68px", color: "transparent"
                                            }}
                                            title=""
                                            to="/PrintSCPForm"
                                            data-alias="Print SCP Form"
                                            onClick={(e) => this.openShortcuts(e)}>SCP</button>
                                    </div>
                                    <div className="A5container" style={{ width: ".002in", font: "0pt" }}>&nbsp;</div>
                                    <div className="A5container">
                                        <button type="button" id="BTN_SCWEBLINKS" className="A5WCLayout"
                                            style={{
                                                backgroundImage: "url(https://crstar.ers-can.com/images/SC_RegistryResources.png)", width: "68px", height: "68px"
                                            }}
                                            title=""
                                            data-alias="Web Links"
                                            onClick={(e) => this.openShortcuts(e)}>&nbsp; </button>
                                    </div>
                                    <div style={{ font: "0pt arial", clear: "both" }}></div>
                                </div>
                            </div>
                            { /* CRStar Shortcuts - END */}

                            <br /><br /><br /><br /><br /><br /><br /><br /><br />
                        </div>

                        <div>{selectAPop}</div>

                        <div>{printPatientAbstract}</div>

                        <div>{genCustomForms}</div>

                        <div>{deletePatientAbstactMaint}</div>

                        <div>{copyAbstract}</div>

                        <div>{printDemandLetters}</div>

                        <div>{printSCPForm}</div>

                        <div>{webLinks}</div>

                        <div>{errorMsgOfAbstractScreens}</div>

                    </div>
                </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        alphaUrl: window.location ? window.location.pathname : "",
        routingPage: state.patientAbstract.routingPage,
        securityGroup: state.auth.securityGroup,
        clientgroup: state.auth.clientgroup,
        alphaToken: state.patientAbstract.tokenOnSession,
        inquiringMode: state.auth.inquiringMode,
        rapidAbstract: state.patientAbstract.rapidAbstract,
        currentDialog: state.patientAbstract.currentDialog,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
        patientId: state.patientAbstract.patientId,
        alphaSystemUrlBaseUrl: state.patientAbstract.alphaSystemUrlBaseUrl,
        availableMenuGroups: state.auth.availableMenuGroups,
        //Fixing #PR00526
        selectedHospital: state.patientAbstract.selectedHospital,
        availableHospsData: state.auth.availableHospsData,
        customizations: state.auth.customizations,
        availableItems: state.patientAbstract.availableItems,
        showMsgOfAbstracts: state.patientAbstract.showMsgOfAbstracts,
        errorMsgOfAbstracts: state.patientAbstract.errorMsgOfAbstracts,
        invalidField: state.patientAbstract.invalidField,
        latestFocusedField: state.patientAbstract.latestFocusedField,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        setTabName: (name) => dispatch(actionCreators.setTabName(name)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        closeOtherTabItems: () => dispatch(actionCreators.closeOtherTabItems()),
        getAvailableItems: () => dispatch(actionCreators.getAvailableItems()),
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        getTokenOnSession: (uid) => dispatch(actionCreators.getTokenOnSession(uid)),
        onLogout: (uid, cg) => dispatch(actions.logout(uid, cg)),
        setAlphaUrl: (url) => dispatch(actionCreators.setAlphaUrl(url)),
        setTwoFactorAuthDialogVisible: (flg) => dispatch(actionCreators.setTwoFactorAuthDialogVisible(flg)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        resetAllStoringOfUser: () => dispatch(actionCreators.resetAllStoringOfUser()),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearCommonLookupItem2: () => dispatch(actionCreators.clearCommonLookupItem2()),
        loadPatientAbsInfo: (pid) => dispatch(actionCreators.loadPatientAbsInfo(pid)),
        clearAllScreenDataOnTab: () => dispatch(actionCreators.clearAllScreenDataOnTab()),
        clearErrorMsgOfAbstracts: () => dispatch(actionCreators.clearErrorMsgOfAbstracts()),
        oktaLoginSuccess: (userInfo) => dispatch(actions.oktaLoginSuccess(userInfo)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);