/* eslint-disable */
import React from "react";
import { connect } from "react-redux";

const AbstractUtils = props => {
    return (
        <div className="AbstractUtils">
            <div>
                <button>Delete</button>
            </div>
            <div>
                <button>Copy</button>
            </div>
            <div>
                <button>Clone</button>
            </div>
        </div>
    );
}

export default AbstractUtils;