/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import Footer from "../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
//import * as Global from "../../../store/Global";
import Draggable from 'react-draggable';
import "./FollowUpOrder.css";

class FollowUpOrder extends Component {

    autoGeneralName = true;
    state = {
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,

        pAllItemFields: null,
        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        showMessageError: false,
        hasError: false,
        savedForm: false,
        fieldInFocus: null,
        focusingField: null,
        isSaving: false,
        selectedItemRowIndex: 1,
        enabledDeletedBtn: false,
        loaded: false,
        confirmed: false,
    }

    handleOnFocus = (event) => {
        event.preventDefault();

        var selectedRowIndex = this.state.selectedItemRowIndex;
        if (event.target.getAttribute("data-index") != "0") {
            selectedRowIndex = event.target.getAttribute("data-index");
        }

        this.setState({
            fieldInFocus: event.target.name,
            focusingField: event.target,
            selectedItemRowIndex: selectedRowIndex
        });

        return false;
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showMessageError: false,
            hasError: false,
            confirmed: true
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector(".Footer2>#btnSubmitForm_Fake") ? document.querySelector(".Footer2>#btnSubmitForm_Fake").focus() : "";
        }, 0);

        return false;
    }

    componentDidMount() {
        this.props.clearCommonLookupItem();
        this.setState({ selectedItemRowIndex: 1 });

        if (!this.state.dialogForm && this.props.followUpOrderInfo) {
            this.setFormData();
        }

        var childrenOpening = this.props.childrenOpening;
        this.props.setChildrenOpening(childrenOpening);
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.dialogForm && this.props.followUpOrderInfo)
            || (prevProps.followUpOrderInfo && this.props.followUpOrderInfo && prevProps.followUpOrderInfo.templateId !== this.props.followUpOrderInfo.templateId)
            || (prevProps.followUpOrderInfo && this.props.followUpOrderInfo && prevProps.followUpOrderInfo.templateName !== this.props.followUpOrderInfo.templateName)
        ) {
            this.setFormData();
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem) {
            var dialogForm = this.state.dialogForm;
            if (this.props.followUpOrderInfo && this.props.followUpOrderInfo.templateId != "0" && this.state.reload) {
                dialogForm = this.props.followUpOrderInfo;
            }

            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                const rIdx = this.props.currentSelectedIndex;
                if (this.props.currentSelectedField.indexOf("contPhys") != -1 && rIdx > 0 && this.autoGeneralName) {
                    const fName = this.props.commonLookupItem.firstName;
                    const lName = this.props.commonLookupItem.lastName;
                    this.setState({
                        pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "" },
                        // And also update name again
                        dialogForm: {
                            ...dialogForm,
                            [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name,
                            ["firstName" + rIdx]: fName,
                            ["lastName" + rIdx]: lName,
                        },
                        reload: false,
                    });
                } else {
                    this.setState({
                        pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "" },
                        // And also update name again
                        dialogForm: { ...dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                        reload: false,
                    });
                }
            } else {
                const rIdx = this.props.currentSelectedIndex;
                if (this.props.currentSelectedField.indexOf("contPhys") != -1 && rIdx > 0 && this.autoGeneralName) {
                    this.setState({
                        //pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        dialogForm: {
                            ...dialogForm,
                            [this.props.currentSelectedField + "Name"]: "",
                            ["firstName" + rIdx]: "",
                            ["lastName" + rIdx]: "",
                        },
                        reload: false,
                    });
                } else {
                    this.setState({
                        //pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        dialogForm: { ...dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                        reload: false,
                    });
                }
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null && !this.state.loadedTableStructure) {
            this.setState({ loadedTableStructure: true });
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        if (this.state.savedForm && !this.props.isDialogLoading && ((prevProps.isFormDirty != this.props.isFormDirty || this.props.error) || (prevProps.error != this.props.error || !this.state.isFormDirty))) {
            if (this.props.isFormDirty && !this.state.confirmed) {
                if (!this.state.hasError && this.state.hasError != this.props.error) {
                    this.setState({ showMessageError: true, hasError: true, isFormDirty: true });
                    this.props.setChildrenOpening(true);
                    this.props.clearErrorMessage();
                }
            } else {
                if (this.state.isFormDirty && this.state.isFormDirty != this.props.isFormDirty) {
                    this.setState({ isFormDirty: this.props.isFormDirty, hasError: false });
                }
            }

            /*
            if (this.props.followUpOrderInfo && this.props.followUpOrderInfo.templateId) {
                document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "Record Saved" : null;
            }
            */
        }

        if (this.state.isFormDirty && (this.state.isSaving || this.props.deletedDataSuccess)
            && (prevProps.isFormDirty !== this.props.isFormDirty || this.state.isFormDirty !== this.props.isFormDirty)) {
            this.setState({
                isFormDirty: this.props.isFormDirty,
                isSaving: false,//this.props.isFormDirty,
            });
        }
    }

    /*async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure?table=Templates_FollowUp_Order";
        let res = await axios
            .get(url)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data : null
        });
    }*/

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "Templates_FollowUp_Order", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    setFormData = () => {
        let dataForm = this.props.followUpOrderInfo;

        let fields = {};
        Object.keys(dataForm).map((t) => {
            fields[t] = "";
        });

        this.setState({
            initialDialogForm: { ...fields },
            dialogForm: { ...dataForm },
            isFormDirty: false,
            reload: false,
            hasError: this.props.error,
            savedForm: false,
            hasError: false,
            loaded: true,
        });

        if (!this.state.pAllItemFields && this.props.followUpOrderInfo) {
            let _fields = {};
            Object.keys(this.props.followUpOrderInfo).map((t) => {
                _fields[t] = "";
            });

            this.setState({ pAllItemFields: _fields, pageMaxlengthInput: _fields });
        };

        //
        setTimeout(function () {
            document.getElementById("templateName") ? document.getElementById("templateName").focus() : "";
        }, 500);
    }

    saveFollowUpOrder = (event) => {
        event.preventDefault();

        let dialogFormData = this.state.dialogForm;
        if (!dialogFormData["templateId"]  || dialogFormData["templateId"] == "") {
            dialogFormData["templateId"] = "0";
        }

        this.props.saveFollowUpOrder(dialogFormData);

        this.setState({ isSaving: true, savedForm: true, isFormDirty: false, confirmed: false });
        return false;
    }

    handleFormChange = (event) => {
        this.props.resetDeletedDataSuccess(false);
        var enabledDeletedBtn = this.state.enabledDeletedBtn;

        var selectedById = this.state.dialogForm && this.state.dialogForm.templateId ? this.state.dialogForm.templateId : 0;
        if (event.target.selectedById) {
            enabledDeletedBtn = true;
            selectedById = event.target.selectedById;

            // reload form by selected id
            this.props.loadFollowUpOrder(selectedById);

            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    ["templateId"]: selectedById
                },
                isFormDirty: true,
                isSaving: false,
                enabledDeletedBtn: enabledDeletedBtn,
                reload: true
            });
        } else {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    //["templateId"]: selectedById
                },
                isFormDirty: true,
                isSaving: false,
                enabledDeletedBtn: enabledDeletedBtn,
                reload: false
            });
        }        
    }

    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            let index = event.target.getAttribute('data-index') ? event.target.getAttribute('data-index') : "-1";

            var _this = this;
            setTimeout(function () {
                _this.props.checkValidInput(name, value, table, key, index, flag);
            }, 1000);
        } else {
            this.setState({ uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "" } });
        }

        var _this = this;
        if (event.target.name.indexOf("contPhys") != -1) {
            this.autoGeneralName = true;
            const rIdx = event.target.getAttribute("data-index");
            let val = event.target.value;
            this.Fill_Phys_Names(val, rIdx);
            return false;
        }
    }
        
    Fill_Phys_Names(thisContact, thisRow) {
        if (thisContact.toUpperCase() == '9999PAT' || thisContact.substr(0, 3).toUpperCase() == 'PAT') {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    ["firstName" + thisRow]: 'Patient',
                    ["lastName" + thisRow]: 'Patient',
                }
            });
            this.autoGeneralName = false;
        }

        if (thisContact.substr(0, 7).toUpperCase() == '9999CON' || thisContact.substr(0, 3).toUpperCase() == 'CON') {
            if (thisContact.substr(-1, 1) == '2' || thisContact.substr(-1, 1) == '3' || thisContact.substr(-1, 1) == '4') {
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        ["firstName" + thisRow]: 'Contact',
                        ["lastName" + thisRow]: 'Secondary Contact ' + thisContact.substr(-1, 1),
                    }
                });
                this.autoGeneralName = false;
            } else {
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        ["firstName" + thisRow]: 'Contact',
                        ["lastName" + thisRow]: 'Secondary Contact 1',
                    }
                });
                this.autoGeneralName = false;
            }
        }

        if (thisContact.toUpperCase() == '9999PRI' || thisContact.substr(0, 3).toUpperCase() == 'PRI') {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    ["firstName" + thisRow]: 'Prim. Surg',
                    ["lastName" + thisRow]: 'Primary Surgeon',
                }
            });
            this.autoGeneralName = false;
        }

        if (thisContact.toUpperCase() == '9999MNG' || thisContact.substr(0, 3).toUpperCase() == 'MNG' || thisContact.substr(0, 5).toUpperCase() == 'MANAG') {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    ["firstName" + thisRow]: 'Mng. Phys',
                    ["lastName" + thisRow]: 'Managing Physician',
                }
            });
            this.autoGeneralName = false;
        }

        if (thisContact.toUpperCase() == '9999FOL' || thisContact.substr(0, 3).toUpperCase() == 'FOL') {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    ["firstName" + thisRow]: 'Fol. Phys',
                    ["lastName" + thisRow]: 'Following Physician',
                }
            });
            this.autoGeneralName = false;
        }

        if (thisContact.toUpperCase() == '9999RAD' || thisContact.substr(0, 3).toUpperCase() == 'RAD') {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    ["firstName" + thisRow]: 'Rad Onc',
                    ["lastName" + thisRow]: 'Radiation Oncologist',
                }
            });
            this.autoGeneralName = false;
        }

        if (thisContact.toUpperCase() == '9999MED' || thisContact.substr(0, 3).toUpperCase() == 'MED') {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    ["firstName" + thisRow]: 'Med Onc',
                    ["lastName" + thisRow]: 'Medical Oncologist',
                }
            });
            this.autoGeneralName = false;
        }

        if (thisContact.substr(0, 6).toUpperCase() == '9999QA' || thisContact.substr(0, 2).toUpperCase() == 'QA' || thisContact.substr(0, 7).toUpperCase() == 'QUALITY') {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    ["firstName" + thisRow]: 'QA Phys',
                    ["lastName" + thisRow]: 'Quality Assurance Physician',
                }
            });
            this.autoGeneralName = false;
        }

        if (thisContact.substr(0, 7).toUpperCase() == '9999OTH' || thisContact.substr(0, 3).toUpperCase() == 'OTH') {
            if (thisContact.substr(-1, 1) == '2' || thisContact.substr(-1, 1) == '3' || thisContact.substr(-1, 1) == '4') {
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        ["firstName" + thisRow]: 'Other Phys',
                        ["lastName" + thisRow]: 'Other Physician ' + thisContact.substr(-1, 1),
                    }
                });
                this.autoGeneralName = false;
            } else {
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        ["firstName" + thisRow]: 'Other Phys',
                        ["lastName" + thisRow]: 'Other Physician 1',
                    }
                });
                this.autoGeneralName = false;
            }
        }
    }

    refreshFormFnc = () => {
        let fields = {};
        if (!this.state.pAllItemFields && this.props.followUpOrderInfo) {
            Object.keys(this.props.followUpOrderInfo).map((t) => {
                fields[t] = "";
            });
        };

        this.setState({
            dialogForm: { ...this.state.initialDialogForm },
            pAllItemFields: fields,
            //pageMaxlengthInput: fields,
            isFormDirty: false,
            reload: false
        });

        //
        setTimeout(function () {
            document.getElementById("templateName") ? document.getElementById("templateName").focus() : "";
        }, 500);
    }

    setSelectionRow = (event, index) => {
        this.setState({ selectedItemRowIndex: index });
    }

    moveUp = () => {
        var thisRow = this.state.selectedItemRowIndex ? parseInt(this.state.selectedItemRowIndex) : 1;
        var nextRow = thisRow - 1;
        if (thisRow > 1) {
            var _this = this;
            setTimeout(function () {
                const newItems = _this.state.dialogForm;

                var thisContPhys = newItems["contPhys" + thisRow];
                var thisFirstName = newItems["firstName" + thisRow];
                var thisLastName = newItems["lastName" + thisRow];
                var thisLetterName = newItems["letterName" + thisRow];

                var nextContPhys = newItems["contPhys" + nextRow];
                var nextFirstName = newItems["firstName" + nextRow];
                var nextLastName = newItems["lastName" + nextRow];
                var nextLetterName = newItems["letterName" + nextRow];

                _this.setState({
                    dialogForm: {
                        ..._this.state.dialogForm,
                        ["contPhys" + thisRow]: nextContPhys,
                        ["firstName" + thisRow]: nextFirstName,
                        ["lastName" + thisRow]: nextLastName,
                        ["letterName" + thisRow]: nextLetterName,

                        ["contPhys" + nextRow]: thisContPhys,
                        ["firstName" + nextRow]: thisFirstName,
                        ["lastName" + nextRow]: thisLastName,
                        ["letterName" + nextRow]: thisLetterName,
                    },
                    selectedItemRowIndex: nextRow,
                    isFormDirty: true,
                    reload: false
                });

                document.getElementById("contPhys" + nextRow) ? document.getElementById("contPhys" + nextRow).focus() : null;
            }, 500);
        } else {
            this.setState({
                reload: false
            });
        }
    }
    moveDown = () => {
        var thisRow = this.state.selectedItemRowIndex ? parseInt(this.state.selectedItemRowIndex) : 1;
        var nextRow = thisRow + 1;
        if (nextRow <= 4) {
            var _this = this;
            setTimeout(function () {
                const newItems = _this.state.dialogForm;

                var thisContPhys = newItems["contPhys" + thisRow];
                var thisFirstName = newItems["firstName" + thisRow];
                var thisLastName = newItems["lastName" + thisRow];
                var thisLetterName = newItems["letterName" + thisRow];

                var nextContPhys = newItems["contPhys" + nextRow];
                var nextFirstName = newItems["firstName" + nextRow];
                var nextLastName = newItems["lastName" + nextRow];
                var nextLetterName = newItems["letterName" + nextRow];

                _this.setState({
                    dialogForm: {
                        ..._this.state.dialogForm,
                        ["contPhys" + thisRow]: nextContPhys,
                        ["firstName" + thisRow]: nextFirstName,
                        ["lastName" + thisRow]: nextLastName,
                        ["letterName" + thisRow]: nextLetterName,

                        ["contPhys" + nextRow]: thisContPhys,
                        ["firstName" + nextRow]: thisFirstName,
                        ["lastName" + nextRow]: thisLastName,
                        ["letterName" + nextRow]: thisLetterName,
                    },
                    selectedItemRowIndex: nextRow,
                    isFormDirty: true,
                    reload: false
                });

                document.getElementById("contPhys" + nextRow) ? document.getElementById("contPhys" + nextRow).focus() : null;
            }, 500);
        } else {
            this.setState({
                reload: false
            });
        }
    }

    render() {
        var lineNumbers = [1, 2, 3, 4];

        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">Can't save data.</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let loading = (
            !this.state.loaded || this.props.waitingFor ? <div className="col-sm-12 showLoadingOnTopPageCls">Working...</div> : ""
        );

        const dialogFrm = <div className="limitedInGridCls">
            <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                {loading}
                <div className="parentWindow">
                    <div className="patientAbstractGridView" id="followUpOrderMainPage" style={{ height: "580px" }}>
                        {
                            this.state.dialogForm ?
                                <div className="container-fluid2">
                                    <form onSubmit={this.saveFollowUpOrder} id="dialogForm" className="DialogForm2 followUpOrderMaintForm">

                                        <div id="inputAreaCls">
                                            <div>
                                                <div className="frameInner">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Follow Up Order Template" field="templateName" lineStyle="oneLine"
                                                                value={this.state.dialogForm.templateName}
                                                                dataSource="Templates_FollowUp_Order" titleDialog="Select a Follow-Up Order Template" labelStyle="normalStyleCls"
                                                                inValid={this.state.pAllItemFields["templateName"]}
                                                                labelWidth="180px" width="300px" itemNbr=""
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["templateName"] : ""}
                                                                lostfocused={this.handleLostFocus}
                                                                keyId="templateName"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12" style={{ display: "flex" }}>
                                                            <Input type="Hospital" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Hospital Code" field="templateHosp" lostfocused={this.handleLostFocus}
                                                                value={this.state.dialogForm.templateHosp}
                                                                dataSource="Hospital" titleDialog="Hospital Lookup" labelWidth="100px" width="65px"
                                                                inValid={this.state.pAllItemFields["templateHosp"]} lineStyle="oneLine" itemNbr=""
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["templateHosp"] : ""}
                                                            />
                                                            <Input type="label" changed={this.handleFormChange}
                                                                labelText="" field="templateHospName"
                                                                value={this.state.dialogForm.templateHospName}
                                                                width="400px" labelWidth="0px" lineStyle="oneLine"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="emptyLine"></div>

                                                    <div className="row">
                                                        <div className="col-sm-11">
                                                            <fieldset style={{ height: "178px", width: "750px" }}>
                                                                <legend>Follow Up Order</legend>
                                                                <div>
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ width: "50px" }}>&nbsp;</th>
                                                                                <th style={{ width: "180px" }}>Contact/Physician</th>
                                                                                <th style={{ width: "120px" }}>First Name</th>
                                                                                <th style={{ width: "140px" }}>Last Name</th>
                                                                                <th style={{ width: "220px" }}>Letter</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody></tbody>
                                                                    </table>
                                                                </div>
                                                                <div style={{ display: "flex" }}>
                                                                    <table>
                                                                        <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                            <tr>
                                                                                <th style={{ width: "20px" }}>&nbsp;</th>
                                                                                <th style={{ width: "180px" }}>Contact/Physician</th>
                                                                                <th style={{ width: "120px" }}>First Name</th>
                                                                                <th style={{ width: "140px" }}>Last Name</th>
                                                                                <th style={{ width: "220px" }}>Letter</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                lineNumbers.map((index) => (
                                                                                    <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionRow(e, index) }} className={this.state.selectedItemRowIndex == index ? "selectedRowCls" : ""}>
                                                                                        <td style={{ width: "20px", textAlign: "right", paddingRight: "2px" }}>
                                                                                            {index}
                                                                                        </td>
                                                                                        <td>
                                                                                            <Input type="Physician" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                                                labelText="" field={"contPhys" + index} lostfocused={this.handleLostFocus}
                                                                                                value={this.state.dialogForm["contPhys" + index]}
                                                                                                dataSource="Physicians" titleDialog="Choose Physician" width="150px" labelWidth="0px"
                                                                                                inValid={this.state.pAllItemFields["contPhys" + index]} lineStyle="oneLine"
                                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["contPhys" + index] : ""}
                                                                                                keyId={"contPhys" + index}
                                                                                                rowIndex={index}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <Input type="label" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                                labelText="" field={"firstName" + index}
                                                                                                value={this.state.dialogForm["firstName" + index]} labelMainStyle="cellOnGrid"
                                                                                                width="120px" labelWidth="0px" />
                                                                                        </td>
                                                                                        <td>
                                                                                            <Input type="label" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                                labelText="" field={"lastName" + index}
                                                                                                value={this.state.dialogForm["lastName" + index]} labelMainStyle="cellOnGrid"
                                                                                                width="140px" labelWidth="0px" />
                                                                                        </td>
                                                                                        <td>
                                                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                                                labelText="" field={"letterName" + index} lostfocused={this.handleLostFocus}
                                                                                                value={this.state.dialogForm["letterName" + index]}
                                                                                                dataSource="Letters" titleDialog="Choose Follow-Up Letter" width="200px" labelWidth="0px"
                                                                                                inValid={this.state.pAllItemFields["letterName" + index]} lineStyle="oneLine"
                                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["letterName" + index] : ""}
                                                                                                keyId={"letterName" + index}
                                                                                                rowIndex={index}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>

                                                                    <div className="resorterCls">
                                                                        <img src="https://crstar.ers-can.com/images/$$navigate.move.up.png.a5image" onClick={this.moveUp} id="btnMoveUpLetter" /><br />
                                                                        <img src="https://crstar.ers-can.com/images/$$navigate.move.down.png.a5image" onClick={this.moveDown} id="btnMoveDownLetter" />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="emptyLine"></div>

                                    <Footer closeWindow={this.props.closeWindow}
                                        refreshForm={this.refreshFormFnc} formId="dialogForm"
                                        isFormDirty={this.state.isFormDirty}
                                        showBtnNew="FollowUpOrder"
                                        showBtnDelete="FollowUpOrder"
                                        className="Footer2"
                                        initialPageVisible={true}
                                        exitFnc={this.props.closeWindow}
                                        focusingField={this.state.focusingField}
                                        inquiringMode={this.props.inquiringMode}
                                        enabledDeletedBtn={this.state.dialogForm && this.state.dialogForm.templateName && this.state.dialogForm.templateName != null && this.state.dialogForm.templateName != "" && (this.state.enabledDeletedBtn || this.state.isFormDirty || this.state.savedForm)}
                                        enabledAlways={true}
                                    />
                                </div>
                                : <p>Maintain Follow-up Order data loading...</p>
                        }
                    </div>
                </div>
            </div>
        </div>;

        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>
                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

FollowUpOrder.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        followUpOrderInfo: state.patientAbstract.followUpOrderInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        deletedDataSuccess: state.patientAbstract.deletedDataSuccess,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        waitingFor: state.patientAbstract.waitingFor,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveFollowUpOrder: (dialogForm) => dispatch(actionCreators.saveFollowUpOrder(dialogForm)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        resetDeletedDataSuccess: (value) => dispatch(actionCreators.resetDeletedDataSuccess(value)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        setHandlerControlItem: (item) => dispatch(actionCreators.setHandlerControlItem(item)),
        showPageLoading: (flag) => dispatch(actionCreators.showPageLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        loadFollowUpOrder: (val) => dispatch(actionCreators.loadFollowUpOrder(val)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpOrder);