/* eslint-disable */
import React, { Component } from "react";
import Iframe from 'react-iframe';
import Draggable from 'react-draggable';
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import "../QuickLinks/ShowOrHideBtnCancel.css";

class SelectAPop extends Component {

    state = {
        loaded: false,
    }

    closeWindow = (e) => {
        try {
            e ? e.preventDefault() : "";
        } catch (e) { }

        this.props.showingHelpInfoFunc(false);

        this.props.closeWindow();
        return false;
    }

    componentDidMount() {
        this.props.showingHelpInfoFunc(true);
        /*
        setTimeout(function () {
            if (document.getElementById("GENDEMANDL_V_R1_BUTTON_EXIT"))
                document.getElementById("GENDEMANDL_V_R1_BUTTON_EXIT").style.display = 'block';
        }, 5000);
        */
    }

    componentDidUnMount() {
        this.props.showingHelpInfoFunc(false);
    }

    mouseEnterEvent(event) {
        event.target.className = "A5CWLayout showBtnExit";
    }
    mouseLeaveEvent(event) {
        if (event.target.nodeName == "BUTTON") {
            event.target.parentNode.className = "A5CWLayout";
        } else {
            event.target.className = "A5CWLayout";
        }
    }

    render() {
        let selectAPop = null;
        {
            selectAPop = <Draggable handle=".dragableAlphaWindowCls">
                <div className="dragableAlphaWindowCls" id="SelectAPopModal">
                    <div className="k-window-titlebar k-dialog-titlebar k-header">
                        <div className="k-window-title k-dialog-title">Select a Population</div>
                        <div className="k-window-actions k-dialog-actions">
                            <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                <span className="k-icon k-i-x" onClick={(e) => { this.closeWindow(e) }}></span>
                            </a>
                        </div>
                    </div>

                    <div>
                        {
                            <Iframe
                                url={this.props.alphaSystemUrlBaseUrl + "/sso.a5w?tk=" + this.props.alphaToken + "&r=" + this.props.alphaUrl + ".a5w"}
                                id="iFrameToAlphaSystemOnModal"
                                className="iFrameOnModalCls"
                                display="initial"
                                position="relative"
                                frameborder="0"
                                frameBorder="0"
                                target="_self"
                            />
                        }
                    </div>
                    <div className="A5CWLayout" style={{ width: "100px", position: "absolute" }} id="GENDEMANDL_DIV_HANDLE_BUTTON_EXIT_4"  onMouseEnter={this.mouseEnterEvent} onMouseLeave={this.mouseLeaveEvent}>
                        <button type="button" id="GENDEMANDL_V_R1_BUTTON_EXIT" className="__ScreenButton" title=""
                            style={{ width: "100%", height: "27px", boxSizing: "border-box", position: "initial", left: "489px", bottom: "77px", borderRadius: "5px", display: "none" }}
                            onClick={(e) => { this.closeWindow(e) }} onMouseLeave={this.mouseLeaveEvent}>
                            <img id="GENDEMANDL.V.R1.BUTTON_EXIT.ICON" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" style={{ verticalAlign: "middle", border: "none" }} /> Exit
                        </button>
                    </div>

                </div>
            </Draggable>
        }

        return (
            <div>
                <div className="fake_popup_cls"></div>
                {selectAPop}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        clientgroup: state.auth.clientgroup,
        alphaSystemUrlBaseUrl: state.patientAbstract.alphaSystemUrlBaseUrl,
        //alphaToken: state.auth.token,
        alphaToken: state.patientAbstract.tokenOnSession,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        showingHelpInfoFunc: (value) => dispatch(actionCreators.showingHelpInfo(value)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectAPop);