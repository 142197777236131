/* eslint-disable */
import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import "./Shared.css";
import { connect } from "react-redux";
import * as dialogTypes from "../../maintDialogs";
import * as actions from '../../../../store/actions/index';
import { actionCreators } from "../../../../store/PatientAbstract";
import Draggable from 'react-draggable';
import axios from "../../../../axios-instance";

class Footer extends Component {

    state = {
        isGotoVisible: false,
        activeBtnDelete: false,
        isShowDeletionMsg: false,
        isShowConfirmationMsg: false,
        menuIndex: "0",
        redirectAction: null,
        hasNotificationSaveMsg: false,
        //Fixing #PR00274
        focusedInFieldShowingName: null,
        focusedInFieldShowingValue: null,
        focusedInFieldDataIndex: null,
        setItemNbrCompleted: false,
        showDeletionMessageConfirm: false,
        showAccNbrsDeletionMessage: false,
        showFollowUpOrderDeletionMessage: false,
        showDeletePatientAbsDeletionMessage: false,
        //Print Forms
        titleDialog: null,
        showClockTimer: false,
        startTimeFrom: null,
        currentTime: null,
    }

    initClock() {
        var _this = this;
        setInterval(function () {
            var today = new Date(),
                time = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            //d.toLocaleTimeString();
            _this.setState({ currentTime: time });

            return false;
        }, 1000);
    }

    componentWillMount() {
        this.props.setChildrenOpening(false);
        //this.setState({ availableHosps: null });
        this.initClock();
    }

    componentDidUpdate(prevProps) {
        if (this.state.redirectAction != null
            && (this.props.isPatientSaving || !this.props.isFormDirty || this.props.inquiringMode || (this.props.availableHospsData != null && this.props.availableHospsData.indexOf(this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.hosp : "01") == -1))) {
            const actionType = this.state.redirectAction;
            var _this = this;
            if (actionType === 'previous') {
                setTimeout(function () {
                    _this.navigatePrevious();
                }, 1000);
            } else if (actionType === 'next') {
                setTimeout(function () {
                    _this.navigateNext();
                }, 1000);
            }
            return false;
        }

        //Fixing #PR00274
        if (!this.state.focusedInFieldShowingName && this.props.focusingField && this.props.focusingField.name) {
            if (this.props.focusingField.getAttribute("data-table") !== null && this.props.focusingField.getAttribute("data-table") !== "") {
                if (this.props.focusingField.value !== null && this.props.focusingField.value !== "") {
                    this.inquiringFieldShowingText(this.props.focusingField);
                } else {
                    this.setState({
                        focusedInFieldShowingName: this.props.focusingField.name,
                        focusedInFieldShowingValue: this.props.focusingField.value,
                        focusedInFieldDataIndex: this.props.focusingField && this.props.focusingField.getAttribute("data-index") ? this.props.focusingField.getAttribute("data-index") : "0"
                    });
                }
            } else {
                this.setState({
                    focusedInFieldShowingName: this.props.focusingField.name,
                    focusedInFieldShowingValue: this.props.focusingField.value,
                    focusedInFieldDataIndex: this.props.focusingField && this.props.focusingField.getAttribute("data-index") ? this.props.focusingField.getAttribute("data-index") : "0"
                });
            }

            var itemNbr = this.props.focusingField.getAttribute("data-help-id");
            if (itemNbr == null || itemNbr == undefined || itemNbr == "") {
                itemNbr = this.props.focusingField.getAttribute("data-key");
            }

            //
            if (this.props.focusingField.getAttribute("id") != "v21SchemaDiscrim2") {
                var _this = this;
                setTimeout(function () {
                    _this.props.setItemNbr(itemNbr ? itemNbr : "0");
                    return false;
                }, 1000);
            } else {
                this.setState({
                    focusedInFieldShowingName: "v21SchemaDiscrim2",
                    focusedInFieldShowingValue: " ",
                    focusedInFieldDataIndex: "0",
                    setItemNbrCompleted: true,
                });
            }

        } else if (this.state.focusedInFieldShowingName && this.props.focusingField && this.props.focusingField.name != "v21SchemaDiscrim2"
            && (this.state.focusedInFieldShowingName !== this.props.focusingField.name
                || (this.state.focusedInFieldDataIndex && this.props.focusingField.getAttribute("data-index")
                    && this.state.focusedInFieldDataIndex !== this.props.focusingField.getAttribute("data-index")))) {
            if (this.props.focusingField && this.props.focusingField.getAttribute("data-table") !== null && this.props.focusingField.getAttribute("data-table") !== "") {
                if (this.props.focusingField.value !== null && this.props.focusingField.value !== "") {
                    this.inquiringFieldShowingText(this.props.focusingField);
                } else {
                    this.setState({
                        focusedInFieldShowingName: this.props.focusingField.name,
                        focusedInFieldShowingValue: this.props.focusingField.value,
                        focusedInFieldDataIndex: this.props.focusingField && this.props.focusingField.getAttribute("data-index") ? this.props.focusingField.getAttribute("data-index") : "0"
                    });
                }
            } else {
                this.setState({
                    focusedInFieldShowingName: this.props.focusingField.name,
                    focusedInFieldShowingValue: this.props.focusingField.value,
                    focusedInFieldDataIndex: this.props.focusingField && this.props.focusingField.getAttribute("data-index") ? this.props.focusingField.getAttribute("data-index") : "0"
                });
            }

            var itemNbr = this.props.focusingField.getAttribute("data-help-id");
            if (itemNbr == null || itemNbr == undefined || itemNbr == "") {
                itemNbr = this.props.focusingField.getAttribute("data-key");
            }

            //
            if (this.props.focusingField.getAttribute("id") != "v21SchemaDiscrim2") {
                var _this = this;
                setTimeout(function () {
                    _this.props.setItemNbr(itemNbr ? itemNbr : "0");
                    return false;
                }, 1000);
            } else {
                this.setState({
                    focusedInFieldShowingName: "v21SchemaDiscrim2",
                    focusedInFieldShowingValue: " ",
                    focusedInFieldDataIndex: "0",
                    setItemNbrCompleted: true,
                });
            }
        }

        if (prevProps.patientAbsInfo && this.props.patientAbsInfo && (prevProps.patientAbsInfo.startTime !== this.props.patientAbsInfo.startTime || prevProps.patientAbsInfo.endTime !== this.props.patientAbsInfo.endTime)) {
            var dataForm = this.props.patientAbsInfo;
            if (dataForm.outputDevice == "Screen" && dataForm.endTime) {
                this.setState({ showClockTimer: false, currentTime: dataForm.endTime });

                if (document.querySelector('.statusBarOnFooterCls')) {
                    var _startTime = "Start Time: " + dataForm.startTime;
                    document.querySelector('.statusBarOnFooterCls').innerHTML = _startTime + "&nbsp;&nbsp;&nbsp;&nbsp;End Time: " + dataForm.endTime;
                }

                return false;
            }
        }
        if (this.props.errorMessage && this.props.errorMessage != "Duplicated Initials") {
            alert(this.props.errorMessage);

            this.setState({ showClockTimer: false, currentTime: null });

            this.props.clearErrorMessage();
            return false;
        }

        //Fixing issues have related to the field's focus at CPM screen
        if ((document.getElementById("linkOfCOCChartAuditTab") && document.getElementById("linkOfCOCChartAuditTab").getAttribute("class").indexOf("active") != -1)
            || (document.getElementById("linkOfBPMChartAuditTab") && document.getElementById("linkOfBPMChartAuditTab").getAttribute("class").indexOf("active") != -1)
            || (document.getElementById("linkOfBPMChartAuditTab2") && document.getElementById("linkOfBPMChartAuditTab2").getAttribute("class").indexOf("active") != -1)
            || (document.getElementById("linkOfRPMChartAuditTab") && document.getElementById("linkOfRPMChartAuditTab").getAttribute("class").indexOf("active") != -1)
            || (document.getElementById("Tabid_Dashboard_") && document.getElementById("Tabid_Dashboard_").getAttribute("class").indexOf("active") != -1)
        ) {
            if (this.state.focusedInFieldShowingName && this.props.focusingField && this.props.focusingField.name
                && this.state.focusedInFieldShowingName == this.props.focusingField.name
                && this.state.focusedInFieldShowingValue != this.props.focusingField.value) {
                this.setState({
                    focusedInFieldShowingName: this.props.focusingField.name,
                    focusedInFieldShowingValue: this.props.focusingField.value,
                    focusedInFieldDataIndex: this.props.focusingField && this.props.focusingField.getAttribute("data-index") ? this.props.focusingField.getAttribute("data-index") : "0"
                });
            }
        }

        return false;
    }

    setTextStatusBar(res, name, value, index) {
        let focusedInFieldShowingText = value;
        if (!res.data.error) {
            if (res.data.commonLookupItem && res.data.commonLookupItem.code && res.data.commonLookupItem.code !== "") {
                focusedInFieldShowingText = res.data.commonLookupItem.name !== null && res.data.commonLookupItem.name !== "" ? res.data.commonLookupItem.name : res.data.commonLookupItem.code;

                this.setState({
                    focusedInFieldShowingName: name,
                    focusedInFieldShowingValue: focusedInFieldShowingText ? focusedInFieldShowingText : " ",
                    focusedInFieldDataIndex: index ? index : "0"
                });
            } else {
                this.setState({
                    focusedInFieldShowingName: name,
                    focusedInFieldShowingValue: value,
                    focusedInFieldDataIndex: index ? index : "0"
                });
            }
        } else {
            this.setState({
                focusedInFieldShowingName: name,
                focusedInFieldShowingValue: value,
                focusedInFieldDataIndex: index ? index : "0"
            });
        }
    }

    inquiringFieldShowingText(field) {
        let name = field.name;
        let value = field.value;
        let table = field.getAttribute('data-table');
        let key = field.getAttribute('data-key') ? field.getAttribute('data-key') : "0";

        let flag = field.getAttribute('data-flag') ? field.getAttribute('data-flag') : "0";
        let filterBy = field.getAttribute('data-filter-by') ? field.getAttribute('data-filter-by') : "";

        //Fixing #PR00365
        if (table == "County_Codes") {
            if (document.getElementById("ReferStateOfSelectedHospital")) {
                flag = document.getElementById("ReferStateOfSelectedHospital").value;
            }

            //ZipCodes screen
            if (document.getElementById("Tabid_Zip_Codes") && document.getElementById("Tabid_Zip_Codes").getAttribute("class").indexOf("active") != -1) {
                flag = field.getAttribute('data-flag') ? field.getAttribute('data-flag') : "0";
            }
        }

        let index = field.getAttribute('data-index') ? field.getAttribute('data-index') : "0";

        let cid = this.props.selectedMedicalRecord ? this.props.selectedMedicalRecord.cid : "0";

        var foo = this;

        if (table === "CstageLookupView") {
            let url = this.props.clientgroup + "/GetCstageLookupItem";
            axios.post(url, { idValue: value, code: key, primarySummaryId: cid, matched: 1, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    foo.setTextStatusBar(res, name, value, index);
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }

        // Staging screens
        else if (table === "Tnm_Lookup8th_Mv" || table === "Tnm_Html") {
            let edition = document.getElementById("tnmEdNbr") && document.getElementById("tnmEdNbr").value != "" ? document.getElementById("tnmEdNbr").value : "08";
            let url = this.props.clientgroup + "/GetCommonLookupItem2";
            axios.post(url, { idValue: value, edition: edition, key: key, flag: flag, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    foo.setTextStatusBar(res, name, value, index);
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }

        // Retired Maitenance screen
        else if (table === "SiteApprCodesPopup") {
            let url = this.props.clientgroup + "/GetSiteApprCodesItem";
            axios.post(url, { idValue: value, code: key, primarySummaryId: cid, matched: 1, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    foo.setTextStatusBar(res, name, value, index);
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });

        } else {
            let url = (table === "Client_Group" ? "ers" : this.props.clientgroup) + "/GetCommonLookupItem";
            axios.post(url, { idValue: value, table: table, key: key, flag: flag, matched: 1, filterBy: filterBy, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    foo.setTextStatusBar(res, name, value, index);
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }
    }

    openGoto = () => {
        this.setState({ isGotoVisible: true });
    }

    closeGoto = () => {
        this.setState({ isGotoVisible: false });
    }

    hideNotificationSaveMsg = (event) => {
        try {
            event.preventDefault();
        } catch (ex) { }
        this.setState({ hasNotificationSaveMsg: false });
        return false;
    }

    hideDeletionMessageConfirm = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showDeletionMessageConfirm: false });
        this.props.setChildrenOpening(false);

        //
        if (this.props.showBtnDelete == "ZipCodes") {
            alert("The action has been cancelled");
        }

        //
        setTimeout(function () {
            document.getElementById('btnDeleteItem_Fake') ? document.getElementById('btnDeleteItem_Fake').focus() : "";
            return false;
        }, 100);

        return false;
    }

    hideAccNbrsDeletionMessage = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showAccNbrsDeletionMessage: false });
        this.props.setChildrenOpening(false);

        //
        setTimeout(function () {
            document.getElementById('btnDeleteItem_Fake') ? document.getElementById('btnDeleteItem_Fake').focus() : "";
            return false;
        }, 100);

        return false;
    }

    hideFollowUpOrderDeletionMessage = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showFollowUpOrderDeletionMessage: false });
        this.props.setChildrenOpening(false);

        //
        setTimeout(function () {
            document.getElementById('btnDeleteItem_Fake') ? document.getElementById('btnDeleteItem_Fake').focus() : "";
            return false;
        }, 100);

        return false;
    }

    hideDeletePatientAbsDeletionMessage = (event) => {        
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showDeletePatientAbsDeletionMessage: false });
        this.props.setChildrenOpening(false);

        //
        setTimeout(function () {
            document.getElementById('btnDeleteItem_Fake') ? document.getElementById('btnDeleteItem_Fake').focus() : "";
            return false;
        }, 100);

        return false;
    }

    deleteSelectedItem = (event) => {
        //The action has been cancelled???
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showDeletionMessageConfirm: false,
            showAccNbrsDeletionMessage: false,
            showFollowUpOrderDeletionMessage: false,
            showDeletePatientAbsDeletionMessage: false,
            showImportConfigDeletionMessage: false,
        });

        var _this = this;

        var itemAlias = this.props.showBtnDelete;
        if (itemAlias == "Hospital")
            this.props.deleteItemInput(itemAlias, this.props.selectedHospital ? this.props.selectedHospital.hospNbr : "");
        else if (itemAlias == "Registrar")
            this.props.deleteItemInput(itemAlias, this.props.selectedRegistrar ? this.props.selectedRegistrar.registrarsId : "");
        else if (itemAlias == "Physician")
            this.props.deleteItemInput(itemAlias, this.props.selectedPhysician ? this.props.selectedPhysician.physiciansId : "");

        else if (itemAlias == "AccNbrs")
            this.props.deleteItemInput(itemAlias, this.props.accNbrsMaintInfo ? this.props.accNbrsData.id : "0");
        else if (itemAlias == "FollowUpOrder")
            this.props.deleteItemInput(itemAlias, this.props.followUpOrderInfo ? this.props.followUpOrderInfo.templateId : "0");

        else if (itemAlias == "GlobalDir")
            this.props.deleteItemInput(itemAlias, this.props.selectedGlobalDir ? this.props.selectedGlobalDir.itemNbr : "");

        else if (itemAlias == "DeletePatientAbs") {
            if (this.props.patientAbsData && this.props.patientAbsData.hospState == "CA") {
                if ((this.props.patientAbsData.transmitText == null || this.props.patientAbsData.transmitText == undefined || this.props.patientAbsData.transmitText == "")
                    && (this.props.patientAbsData.stateRptFlag == "T" || this.props.patientAbsData.stateRptFlag == "C")
                ) {
                    document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "California requires that a reason be given for deleting cases" : null;
                    return false;
                } else {
                    // Get_CA_Records
                    let url = this.props.clientgroup + "/GetCaRecords?pid=" + this.props.patientAbsData.pid + "&cid=" + this.props.patientAbsData.cid + "&transmitText=" + (this.props.patientAbsData && this.props.patientAbsData.transmitText ? this.props.patientAbsData.transmitText : "");
                    axios.post(url, { pid: this.props.patientAbsData.pid, cid: this.props.patientAbsData.cid, transmitText: (this.props.patientAbsData && this.props.patientAbsData.transmitText ? this.props.patientAbsData.transmitText : ""), accessToken: localStorage.getItem('token') })
                        .then(res => {
                            //update new accessToken again
                            var newAccessToken = res && res.data ? res.data.accessToken : null;
                            if (newAccessToken) {
                                localStorage.setItem('token', newAccessToken);
                            }

                            console.log("Processing [Get_CA_Records] function is completed.");
                        })
                        .catch(function (error) {
                            console.log(error);
                            /*
                            var btnLogout = document.getElementById("logoutButtonArea");
                            if (btnLogout) {
                                btnLogout.querySelector("a").click();
                            }
                            */
                            //@TODO:...

                            return false;
                        });
                }
            }

            //
            _this.props.deleteItemInput(itemAlias, _this.props.patientAbsData ? _this.props.patientAbsData.pid : "0", _this.props.patientAbsData ? _this.props.patientAbsData.cid : "0");
        }

        else if (itemAlias == "LettersMaint")
            this.props.deleteItemInput(itemAlias, this.props.lettersData ? this.props.lettersData.id : "0");
        else if (itemAlias == "CustomFormsMaint")
            this.props.deleteItemInput(itemAlias, this.props.formsData ? this.props.formsData.id : "0");
        else if (itemAlias == "CRStarImportOption") {
            this.props.deleteItemInput(itemAlias, this.props.importOptionDatas ? this.props.importOptionDatas.mergeOptionsId : "0");
            this.props.reloadPageFnc();
        }
        else if (itemAlias == "ZipCodes")//selectedZipCodes
            this.props.deleteItemInput(itemAlias, this.props.dataForm ? this.props.dataForm.zipCodesId : "0");

        this.props.setChildrenOpening(false);

        // Reset focusing into first field        
        setTimeout(function () {
            if (itemAlias == "Hospital")
                document.getElementById('hospNbrDsp') ? document.getElementById('hospNbrDsp').focus() : "";
            else if (itemAlias == "Registrar")
                document.getElementById('initials') ? document.getElementById('initials').focus() : "";
            else if (itemAlias == "Physician")
                document.getElementById('physiciansIdDsp') ? document.getElementById('physiciansIdDsp').focus() : "";

            else if (itemAlias == "AccNbrs") {
                _this.props.refreshForm();
                document.getElementById('hosp') ? document.getElementById('hosp').focus() : "";
            }
            else if (itemAlias == "FollowUpOrder") {
                _this.props.refreshForm();
                document.getElementById('tmplateName') ? document.getElementById('tmplateName').focus() : "";
                document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "Template Deleted" : null;
            } else if (itemAlias == "GlobalDir")
                document.getElementById('itemNbr') ? document.getElementById('itemNbr').focus() : "";

            //Delete Patient Abstract ???
            else if (itemAlias == "DeletePatientAbs") {
                _this.props.refreshForm();
                if (_this.props.patientAbsData && (_this.props.patientAbsData.pid == null || _this.props.patientAbsData.pid == "0" || _this.props.patientAbsData.pid == "")) {
                    document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "A valid Patient must be selected" : null;
                    return false;
                } else if (_this.props.patientAbsData && _this.props.patientAbsData.hospState == "CA"
                    && (_this.props.patientAbsData.transmitText == null || _this.props.patientAbsData.transmitText == undefined || _this.props.patientAbsData.transmitText == "")
                    && (_this.props.patientAbsData.stateRptFlag == "T" || _this.props.patientAbsData.stateRptFlag == "C")
                ) {
                    document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "California requires that a reason be given for deleting cases" : null;
                    return false;
                }

                if (_this.props.patientAbsData && (_this.props.patientAbsData.cid == null || _this.props.patientAbsData.cid == "0" || _this.props.patientAbsData.cid == "")) {
                    document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "Patient Deleted" : null;
                } else {
                    document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "Primary Deleted" : null;
                }
            }

            else if (itemAlias == "LettersMaint") {
                _this.props.refreshForm();
                document.getElementById('letter0') ? document.getElementById('letter0').focus() : "";
                document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "Letter Deleted" : null;
            }
            else if (itemAlias == "CustomFormsMaint") {
                _this.props.refreshForm();
                document.getElementById('letter0') ? document.getElementById('letter0').focus() : "";
                document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "Form Deleted" : null;
            }
            else if (itemAlias == "CRStarImportOption") {
                _this.props.refreshForm();
                document.getElementById('importLabel0') ? document.getElementById('importLabel0').focus() : "";
                //document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "Import Option Deleted" : null;
            }

            else if (itemAlias == "ZipCodes")
                document.getElementById('zipCode') ? document.getElementById('zipCode').focus() : "";

            return false;
        }, 1000);

        return false;
    }

    //
    hideImportConfigDeletionMessage = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showImportConfigDeletionMessage: false });
        this.props.setChildrenOpening(false);

        //
        setTimeout(function () {
            document.getElementById('btnDeleteItem_Fake') ? document.getElementById('btnDeleteItem_Fake').focus() : "";
            return false;
        }, 100);

        return false;
    }
    
    //copy abstract
    copySelectedItem = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        var _this = this;
        var itemAlias = this.props.showBtnDelete;
        if (this.props.patientAbsData && this.props.patientAbsData.hosp != null && this.props.patientAbsData.hosp != ""
            && this.props.patientAbsData.newHosp != null && this.props.patientAbsData.newHosp != ""
            && this.props.patientAbsData.hosp == this.props.patientAbsData.newHosp) {
            document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "This primary already exists for hospital 01" : null;
            return false;
        }
        var newHosp = this.props.patientAbsData ? this.props.patientAbsData.newHosp : "";
        _this.props.copyItemInput(itemAlias,
            {
                pid: _this.props.patientAbsData && _this.props.patientAbsData.pid ? _this.props.patientAbsData.pid : "0",
                cid: _this.props.patientAbsData && _this.props.patientAbsData.cid ? _this.props.patientAbsData.cid : "0",
                siteCode: _this.props.patientAbsData && _this.props.patientAbsData.siteCode ? _this.props.patientAbsData.siteCode : "0",
                seqPrim: _this.props.patientAbsData && _this.props.patientAbsData.seqPrim ? _this.props.patientAbsData.seqPrim : "0",
                newHosp: _this.props.patientAbsData && _this.props.patientAbsData.newHosp ? _this.props.patientAbsData.newHosp : "",
            }
        );

        this.props.setChildrenOpening(false);

        // Reset focusing into first field        
        setTimeout(function () {
            if (_this.props.patientAbsData && (_this.props.patientAbsData.cid == null || _this.props.patientAbsData.cid == "0" || _this.props.patientAbsData.cid == "")) {
                document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "An error occurred while copying this case" : null;
            } else {
                //document.querySelector('.statusBarOnFooterCls') ? (document.querySelector('.statusBarOnFooterCls').innerHTML = "Abstract copied to Hospital " + newHosp) : null;
            }

            return false;
        }, 1000);

        return false;
    }

    navigatePrevious = async () => {
        var index = this.getIndexOfCurrentDialog();

        // Fixing #PR00434: check if whether previous tab is enabled/disabled
        if (index == 0) return false;
        try {
            for (let pi = index; pi > 0; pi--) {
                let _isHidden = false;
                const btnName = dialogTypes.buttonNamesOnTab[pi - 1];

                var availabelItems = this.props.availableItems;
                if (availabelItems === null || availabelItems.length === 0 || availabelItems.indexOf(btnName) != -1) {
                    _isHidden = false;
                } else {
                    _isHidden = true;
                }

                if (!this.props.tabStatuses[pi - 1] || _isHidden) {
                    index--;
                } else {
                    break;
                }
            }
        } catch (ex) { }

        if (index !== -1 && index !== 0) {
            const dialog = dialogTypes.dialogDisplays[index - 1];
            this.props.setDialog(dialog);

            var label = dialog;

            var _this = this;
            setTimeout(function () {
                document.querySelector('.loader') ? document.querySelector('.loader').setAttribute('class', 'hidden') : null;

                if (_this.props.inquiringMode) {
                    document.querySelector("#inquiryModeMsg") ? document.querySelector("#inquiryModeMsg").setAttribute("class", "inquiringCls") : null;
                } else if ((!_this.props.inquiringMode && label == "Patient")
                    || (!_this.props.inquiringMode && document.querySelector("#Tabid_Patient") && document.querySelector("#Tabid_Patient").getAttribute("class").indexOf("tab-list-active") !== -1)
                    || (!_this.props.inquiringMode && _this.props.availableHospsData != null && _this.props.availableHospsData.indexOf(_this.props.selectedPrimarySummary ? _this.props.selectedPrimarySummary.hosp : "01") != -1)
                ) {
                    document.querySelector("#inquiryModeMsg") ? document.querySelector("#inquiryModeMsg").setAttribute("class", "inquiringCls hidden") : null;
                } else if ((!_this.props.inquiringMode && label != "Patient" &&
                    (document.querySelector("#Tabid_Patient") && document.querySelector("#Tabid_Patient").getAttribute("class").indexOf("tab-list-active") == -1) &&
                    (!_this.props.inquiringMode && _this.props.availableHospsData != null && _this.props.availableHospsData.indexOf(_this.props.selectedPrimarySummary ? _this.props.selectedPrimarySummary.hosp : "01") == -1))
                ) {
                    document.querySelector("#inquiryModeMsg") ? document.querySelector("#inquiryModeMsg").setAttribute("class", "inquiringCls") : null;
                } else {
                    document.querySelector("#inquiryModeMsg") ? document.querySelector("#inquiryModeMsg").setAttribute("class", "inquiringCls hidden") : null;
                }
            }, 1500);

            // @TODO: Tabs component...
            this.props.routingPage(dialog);
            this.props.selectTabItems(dialog);

            await setTimeout(function () {
                // 
                if (dialog === "Patient")
                    _this.props.selectPatient(_this.props.patientId, false, true);
                else if (dialog === "Staging")
                    _this.props.selectStagingRecord(_this.props.selectedPrimarySummary);
                else if (dialog === "Treatment")
                    _this.props.selectTreatmentRecord(_this.props.selectedPrimarySummary);
                else if (dialog === "Outcomes")
                    _this.props.selectOutcomesRecord(_this.props.selectedPrimarySummary);
                else if (dialog === "CPM")
                    _this.props.selectCocRecord(_this.props.selectedPrimarySummary);
                else if (dialog === "BPM")
                    _this.props.selectNapbcRecord(_this.props.selectedPrimarySummary);
                else if (dialog === "RPM")
                    _this.props.selectRectalProgramRecord(_this.props.selectedPrimarySummary);
                else if (dialog === "Case Admin")
                    _this.props.selectCaseAdminRecord(_this.props.selectedPrimarySummary, _this.props.username);
                else if (dialog === "Clinical")
                    _this.props.selectClinicalRecord(_this.props.selectedPrimarySummary);
                else if (dialog === "Research")
                    _this.props.selectResearchRecord(_this.props.selectedPrimarySummary);
                else if (dialog === "User Defined")
                    _this.props.selectUserDefinedRecord(_this.props.selectedPrimarySummary);
                else if (dialog === "State Specific") {
                    //check if whether current page is NM State Screen? ...to get data correctly
                    const state = _this.props.currentShowingState;
                    if (state === "NM") {
                        _this.props.selectStateFieldsRecord(_this.props.selectedPrimarySummary, "Nm");
                    } else {
                        _this.props.selectStateFieldsRecord(_this.props.selectedPrimarySummary, "");
                    }
                }
                else if (dialog === "Retired")
                    _this.props.selectRetiredRecord(_this.props.selectedPrimarySummary);

                //
                _this.props.clearAndResetDataWhenPageLoading(false);
            }, 1000);
        }

        this.setState({ redirectAction: null });
    }

    navigateNext = async () => {
        var index = this.getIndexOfCurrentDialog();

        // Fixing #PR00434: check if whether next tab is enabled/disabled
        if (index == dialogTypes.dialogDisplays.length) return false;
        try {
            for (let pi = index; pi < dialogTypes.dialogDisplays.length; pi++) {
                let _isHidden = false;
                const btnName = dialogTypes.buttonNamesOnTab[pi + 1];

                var availabelItems = this.props.availableItems;
                if (availabelItems === null || availabelItems.length === 0 || availabelItems.indexOf(btnName) != -1) {
                    _isHidden = false;
                } else {
                    _isHidden = true;
                }

                if (!this.props.tabStatuses[pi + 1] || _isHidden) {
                    index++;
                } else {
                    break;
                }
            }
        } catch (ex) { }

        if (index !== -1 && index !== dialogTypes.dialogDisplays.length - 1) {
            const dialog = dialogTypes.dialogDisplays[index + 1];
            this.props.setDialog(dialog);

            var label = dialog;

            var _this = this;
            setTimeout(function () {
                document.querySelector('.loader') ? document.querySelector('.loader').setAttribute('class', 'hidden') : null;

                if (_this.props.inquiringMode) {
                    document.querySelector("#inquiryModeMsg") ? document.querySelector("#inquiryModeMsg").setAttribute("class", "inquiringCls") : null;
                } else if ((!_this.props.inquiringMode && label == "Patient")
                    || (!_this.props.inquiringMode && document.querySelector("#Tabid_Patient") && document.querySelector("#Tabid_Patient").getAttribute("class").indexOf("tab-list-active") !== -1)
                    || (!_this.props.inquiringMode && _this.props.availableHospsData != null && _this.props.availableHospsData.indexOf(_this.props.selectedPrimarySummary ? _this.props.selectedPrimarySummary.hosp : "01") != -1)
                ) {
                    document.querySelector("#inquiryModeMsg") ? document.querySelector("#inquiryModeMsg").setAttribute("class", "inquiringCls hidden") : null;
                } else if ((!_this.props.inquiringMode && label != "Patient" &&
                    (document.querySelector("#Tabid_Patient") && document.querySelector("#Tabid_Patient").getAttribute("class").indexOf("tab-list-active") == -1) &&
                    (!_this.props.inquiringMode && _this.props.availableHospsData != null && _this.props.availableHospsData.indexOf(_this.props.selectedPrimarySummary ? _this.props.selectedPrimarySummary.hosp : "01") == -1))
                ) {
                    document.querySelector("#inquiryModeMsg") ? document.querySelector("#inquiryModeMsg").setAttribute("class", "inquiringCls") : null;
                } else {
                    document.querySelector("#inquiryModeMsg") ? document.querySelector("#inquiryModeMsg").setAttribute("class", "inquiringCls hidden") : null;
                }
            }, 1500);

            // @TODO: Tabs component...
            this.props.routingPage(dialog);
            this.props.selectTabItems(dialog, index);

            await setTimeout(function () {
                // 
                if (dialog === "Patient")
                    _this.props.selectPatient(_this.props.patientId, false, true);
                else if (dialog === "Staging")
                    _this.props.selectStagingRecord(_this.props.selectedPrimarySummary);
                else if (dialog === "Treatment")
                    _this.props.selectTreatmentRecord(_this.props.selectedPrimarySummary);
                else if (dialog === "Outcomes")
                    _this.props.selectOutcomesRecord(_this.props.selectedPrimarySummary);
                else if (dialog === "CPM")
                    _this.props.selectCocRecord(_this.props.selectedPrimarySummary);
                else if (dialog === "BPM")
                    _this.props.selectNapbcRecord(_this.props.selectedPrimarySummary);
                else if (dialog === "RPM")
                    _this.props.selectRectalProgramRecord(_this.props.selectedPrimarySummary);
                else if (dialog === "Case Admin")
                    _this.props.selectCaseAdminRecord(_this.props.selectedPrimarySummary, _this.props.username);
                else if (dialog === "Clinical")
                    _this.props.selectClinicalRecord(_this.props.selectedPrimarySummary);
                else if (dialog === "Research")
                    _this.props.selectResearchRecord(_this.props.selectedPrimarySummary);
                else if (dialog === "User Defined")
                    _this.props.selectUserDefinedRecord(_this.props.selectedPrimarySummary);
                else if (dialog === "State Specific") {
                    //check if whether current page is NM State Screen? ...to get data correctly
                    const state = _this.props.currentShowingState;
                    if (state === "NM") {
                        _this.props.selectStateFieldsRecord(_this.props.selectedPrimarySummary, "Nm");
                    } else {
                        _this.props.selectStateFieldsRecord(_this.props.selectedPrimarySummary, "");
                    }
                }
                else if (dialog === "Retired")
                    _this.props.selectRetiredRecord(_this.props.selectedPrimarySummary);

                //
                _this.props.clearAndResetDataWhenPageLoading(false);
            }, 1000);
        }

        this.setState({ redirectAction: null });
    }

    setAllowSaveAnyway = (event) => {
        const actionType = this.state.redirectAction;
        if (actionType && this.props.callbackToParent) {
            this.props.callbackToParent(true);//always set save anyway flag is true
        }

        if (!this.props.isFormDirty) {
            try {
                event.preventDefault();
            } catch (e) { }
            return false;
        }

        return true;
    }

    doAction = async (event, type) => {
        event.preventDefault();

        //#region:: Abstract Date that need validation adjustments.
        /*
        Diagnosis - CoV-2 Pos Date
        Outcomes - Date Last Contact + Date Cancer Status Last Changed
        CPM - Date SCP Generated/Delivered + Date QA Performed + Screening Date
        BPM - Mammogram Date + Ultrasound Date + Mri Date + Plastic Surg Referral Date + Screening Date + Date SCP Generated/Delivered
        RPM - Date of Pretreatment MRI + Date of PET/CT + Date of Initial Eval at RCP + Date Post Surg Tx Outcome Discussed + Date Post Surg Tx Outcome Provided
        User Defined - Udef 41 -> Udef 50
        Case Admin - Date Abstract
        */
        var badDateId = null;
        if (document.getElementById("diagnosisForm")) {
            console.log('==diagnosisForm==');
            let dId = "ncdbSarsCov2PositiveDate";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

        } else if (document.getElementById("outcomesForm")) {
            console.log('==outcomesForm==');
            let dId = "dateLastCancerStatus";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
            
            let gLen = document.getElementById("outcomesMvListArea").querySelectorAll("table>tbody>tr").length;
            for (var gi = 0; gi < gLen; gi++) {
                let gId = "dtLstCont" + gi;
                let gVl = document.getElementById(gId) ? document.getElementById(gId).value : null;
                if (gVl && !moment(gVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = gId;
                    break;
                }
            }

        } else if (document.getElementById("cocForm")) {
            console.log('==cocForm==');
            let dId = "dateScpGenerated";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

            if (!badDateId) {
                dId = "dateQaPerformed";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "screenDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (badDateId) {
                document.getElementById("linkOfCCServicesTab") ? document.getElementById("linkOfCCServicesTab").click() : null;
            }

        } else if (document.getElementById("napbcForm")) {
            console.log('==napbcForm==');
            let dId = "mammoDate";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

            if (!badDateId) {
                dId = "ultrasoundDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "mriDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "plasticSurgRefDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "screeningDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "dateScpGeneratedBpm";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

        } else if (document.getElementById("rectalProgramForm")) {
            console.log('==rectalProgramForm==');
            let dId = "rpmMriDate";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

            if (!badDateId) {
                dId = "rpmPetCtDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "rpmDateInitEval";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "rpmDateTxOutcomeDiscussed";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

            if (!badDateId) {
                dId = "rpmDateTxOutcomeProvided";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }

        } else if (document.getElementById("userDefinedForm")) {
            console.log('==userDefinedForm==');
            let dId = "udef";
            let iBadNo = 0;
            for (var si = 41; si <= 50; si++) {
                let gId = dId + si;
                let gVl = document.getElementById(gId) ? document.getElementById(gId).value : null;
                if (gVl && !moment(gVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = gId;
                    iBadNo = si;
                    break;
                }
            }

            if (iBadNo != 0 && iBadNo <= 45) {
                document.getElementById("linkOfUserDefined1Tab") ? document.getElementById("linkOfUserDefined1Tab").click() : null;
            } else if (iBadNo != 0 && iBadNo > 45) {
                document.getElementById("linkOfUserDefined2Tab") ? document.getElementById("linkOfUserDefined2Tab").click() : null;
            }

        } else if (document.getElementById("caseAdminForm")) {
            console.log('==caseAdminForm==');
            let dId = "dateAbst";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

        } else if (document.getElementById("dashboardSettingsForm")) {
            console.log('==dashboardSettingsForm==');
            let dId = "casesCompletedBeginDate";
            let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }

            if (!badDateId) {
                dId = "casesCompletedEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "casesAbstractedBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "casesAbstractedEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "followUpEnteredBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "followUpEnteredEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "lastFollowUpEnteredBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "lastFollowUpEnteredEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            /*
             * Disabled input fields so don't need to check valid input
            if (!badDateId) {
                dId = "followUpCalcsBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && dVl != "N/A" && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "followUpCalcsEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && dVl != "N/A" && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            */
            if (!badDateId) {
                dId = "rqrsBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "rqrsEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "stagedByBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "stagedByEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "suspenseBySiteBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "suspenseBySiteEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "timelinessBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "timelinessEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "top5SitesBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "top5SitesEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "referredFromBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "referredFromEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "referredToBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "referredToEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "classBySiteBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "classBySiteEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "countyBySiteBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "countyBySiteEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "inOutMigrationBeginDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
            if (!badDateId) {
                dId = "inOutMigrationEndDate";
                dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
                if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                    badDateId = dId;
                }
            }
        }

        //
        if (!this.props.inquiringMode && badDateId) {
            document.getElementById(badDateId) ? document.getElementById(badDateId).focus() : null;
            alert('Please input a valid date');
            document.getElementById(badDateId) ? document.getElementById(badDateId).focus() : null;
            setTimeout(function () {
                document.getElementById(badDateId) ? document.getElementById(badDateId).focus() : null;
                return false;
            }, 1000);
            return false;
        }
        // --End #region

        var parentElementBtn = event.target.parentElement;

        //
        var stopThisProcess = false;
        var diagnosisForm = document.getElementById("diagnosisForm");
        if (diagnosisForm) {
            var Topo = document.getElementById("topoCode") ? document.getElementById("topoCode").value : "";
            var Hist = document.getElementById("histCode") ? document.getElementById("histCode").value : "";

            var Dx_Year = this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.diagnosisDateYear : "";
            var Previous_Topo = this.props.selectedDiagnosisRecord ? this.props.selectedDiagnosisRecord["topoCode"] : "";
            var Previous_Hist = this.props.selectedDiagnosisRecord ? this.props.selectedDiagnosisRecord["histCode"] : "";
            if (((Previous_Topo != '' && Previous_Topo != Topo) || (Previous_Hist != '' && Previous_Hist != Hist)) && Dx_Year >= 2018) {
                stopThisProcess = true;
            }
        }

        if (!stopThisProcess) {
            this.setState({ redirectAction: type });
            await this.props.showPageLoading(true);
        }

        // check if whether is using "Inquire Mode", NOT ALLOW "SAVE" any data...
        if ((this.props.inquiringMode && this.props.alphaUrl !== "/patient-abstract" && this.props.alphaUrl !== "/rapid-abstract") ||
            (!this.props.inquiringMode && (!document.querySelector("#Tabid_Patient") || document.querySelector("#Tabid_Patient").length == 0)) ||
            (!this.props.inquiringMode && document.querySelector("#Tabid_Patient") && document.querySelector("#Tabid_Patient").getAttribute("class").indexOf("tab-list-active") != -1) ||
            (!this.props.inquiringMode && this.props.isFormDirty && (!this.props.availableHospsData
            || (this.props.availableHospsData != null && this.props.availableHospsData.indexOf(this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.hosp : "01") !== -1
                && document.querySelector("#Tabid_Patient") && document.querySelector("#Tabid_Patient").getAttribute("class").indexOf("tab-list-active") == -1)))
        ) {
            var saveBtn = parentElementBtn.firstElementChild;

            //Fixing #PR00575 - START
            if (saveBtn.form && saveBtn.form.getAttribute("class") && saveBtn.form.getAttribute("class").indexOf("physicians_maint_form") != -1) {
                var dataForm = this.props.dataForm;
                if (!saveBtn.form.querySelector("#physiciansIdDsp") || saveBtn.form.querySelector("#physiciansIdDsp").value == ""
                    || (dataForm && (!dataForm.physiciansIdDsp || dataForm.physiciansIdDsp == ""))) {
                    var phyPopup = document.getElementById("identify_main_view_area");
                    if (phyPopup && phyPopup.querySelector(".loader")) {
                        phyPopup.querySelector(".loader").style.display = "none";
                        phyPopup.querySelector(".loader").setAttribute("class", "");

                        this.props.showPageLoading(false);
                        saveBtn.form.querySelector("#physiciansIdDsp").focus();
                    }
                    return false;
                }
            } else if (saveBtn.form && saveBtn.form.getAttribute("class") && saveBtn.form.getAttribute("class").indexOf("hospital_maint_form") != -1) {
                var dataForm = this.props.dataForm;
                if (!saveBtn.form.querySelector("#hospNbrDsp") || saveBtn.form.querySelector("#hospNbrDsp").value == ""
                    || (dataForm && (!dataForm.hospNbrDsp || dataForm.hospNbrDsp == ""))) {
                    var hosPopup = document.getElementById("identify_main_view_area");
                    if (hosPopup && hosPopup.querySelector(".loader")) {
                        hosPopup.querySelector(".loader").style.display = "none";
                        hosPopup.querySelector(".loader").setAttribute("class", "");

                        this.props.showPageLoading(false);
                        saveBtn.form.querySelector("#hospNbrDsp").focus();
                    }
                    return false;
                }
            } else if (saveBtn.form && saveBtn.form.getAttribute("class") && saveBtn.form.getAttribute("class").indexOf("registrars_maint_form") != -1) {
                var dataForm = this.props.dataForm;
                if (!saveBtn.form.querySelector("#initials") || saveBtn.form.querySelector("#initials").value == ""
                    || (dataForm && (!dataForm.initials || dataForm.initials == ""))) {
                    var regPopup = document.getElementById("identify_main_view_area");
                    if (regPopup && regPopup.querySelector(".loader")) {
                        regPopup.querySelector(".loader").style.display = "none";
                        regPopup.querySelector(".loader").setAttribute("class", "");

                        this.props.showPageLoading(false);
                        saveBtn.form.querySelector("#initials").focus();
                    }
                    return false;
                }
            }
            //Fixing #PR00575 - END

            var _thisObj = this;
            await setTimeout(function () {
                event.preventDefault();

                _thisObj.props.clearCommonLookupItem();
                // call submit form action via click event of submitted button (it's hidden)
                saveBtn ? saveBtn.click() : "";

                _thisObj.setState({ redirectAction: type });
                return false;
            }, 0);
        }

        if (stopThisProcess && !this.props.inquiringMode && (!this.props.availableHospsData || this.props.availableHospsData != null && this.props.availableHospsData.indexOf(this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.hosp : "01") !== -1)) {
            //await this.props.setHandlerControlItem(tabId);
            return false;
        }

        var _this = this;
        await setTimeout(function () {
            _this.setState({ redirectAction: type });
            _this.props.showPageLoading(false);

            if (type != "submit") {
                _this.props.clearAndResetDataWhenPageLoading(true);
                var elList = document.querySelectorAll('.data_loading_page, .specificFieldsTitleCls');
                elList.forEach(el => el.innerHTML = "");
            } else {
                _this.props.clearAndResetDataWhenPageLoading(false);
            }

        }, 1000);

        return false;
    }

    getIndexOfCurrentDialog = () => {
        return dialogTypes.dialogDisplays.findIndex(el => {
            return el.toLowerCase() === this.props.currentDialog.toLowerCase();
        });
    }

    extraAction = (event, alias, index) => {
        event.preventDefault();

        switch (alias) {
            case "v21CompatibilityFeature":
                let cidValue = this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.cid : 0;
                if (cidValue == 0 && this.props.selectedMedicalRecord) {
                    cidValue = this.props.selectedMedicalRecord.cid;
                }
                this.props.v21CompatibilityFeature(cidValue);
                break;
            case "setDefaultDiagnosis":
                this.props.fncNonAnalyticDefault();
                break;
            case "showDiagnosisText":
                let cidNo = this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.cid : 0;
                if (cidNo == 0 && this.props.selectedMedicalRecord) {
                    cidNo = this.props.selectedMedicalRecord.cid;
                }
                this.showDiagnosisText(cidNo);
                break;
            case "New":
                if (index === "Hospital") {
                    this.props.clearHospitalFormInput("");
                } else if (index === "Physician") {
                    this.props.clearPhysicianFormInput("");
                } else if (index === "Registrar") {
                    this.props.clearRegistrarFormInput("");
                } else if (index === "UserInfo") {
                    let cligrp = this.props.clientgroup ? this.props.clientgroup : "";
                    this.props.clearUserInfoFormInput("", cligrp);
                } else if (index === "AccNbrs") {
                    this.props.refreshForm();
                } else if (index === "FollowUpOrder") {
                    this.props.refreshForm();
                } else if (index == "DeletePatientAbs") {
                    this.props.refreshForm();
                    this.props.loadPatientAbsInfo(0);
                    this.props.selectedPatientOnGrid(null);

                    this.props.clearCommonLookupItem();
                    //
                    setTimeout(function () {
                        document.getElementById("siteDesc") ? (document.getElementById("siteDesc").value = "") : null;
                    }, 1500);
                } else if (index == "CopyPatientAbs") {
                    this.props.refreshForm();
                    this.props.loadPatientAbsInfo(0);
                    this.props.selectedPatientOnGrid(null);

                    this.props.clearCommonLookupItem();
                    //
                    setTimeout(function () {
                        document.getElementById("siteDesc") ? (document.getElementById("siteDesc").value = "") : null;
                    }, 1500);
                } else if (index == "MergePatientAbs") {
                    this.props.refreshForm();
                    this.props.loadPatientAbsInfo(0);
                    this.props.selectedPatientOnGrid(null);

                } else if (index == "PrintAbstracts" || index == "PrintTxSummary" || index == "PrintQAForm" || index == "PrintSCPForm") {
                    this.props.refreshForm();
                    this.props.loadPatientAbsInfo(0);
                    this.props.selectedPatientOnGrid(null);

                    this.props.clearCommonLookupItem();
                    //
                    setTimeout(function () {
                        document.getElementById("siteDesc") ? (document.getElementById("siteDesc").value = "") : null;
                    }, 1500);
                } else if (index == "LettersMaint") {
                    this.props.refreshForm();
                    this.props.loadLettersMaint();
                    this.props.clearCommonLookupItem();
                } else if (index == "CustomFormsMaint") {
                    this.props.refreshForm();
                    this.props.loadCustomFormsMaint();
                    this.props.clearCommonLookupItem();

                } else if (index == "CRStarImportOption") {
                    this.props.loadImportConfig("");

                    this.props.clearCommonLookupItem();
                    this.props.reloadPageFnc();
                    //
                    setTimeout(function () {
                        document.getElementById("importLabel0") ? (document.getElementById("importLabel0").value = "") : null;
                    }, 1500);
                } else if (index == "ZipCodes") {
                    this.props.refreshForm();
                    this.props.selectZipCodesRecord("");
                }
                break;

            case "Delete":
                if (index === "Hospital") {
                    //this.props.deleteItemInput(index, this.props.selectedHospital ? this.props.selectedHospital.hospNbr : "");
                    this.setState({ showDeletionMessageConfirm: true });
                } else if (index === "Physician") {
                    //this.props.deleteItemInput(index, this.props.selectedPhysician ? this.props.selectedPhysician.physiciansId : "");
                    this.setState({ showDeletionMessageConfirm: true });
                } else if (index === "Registrar") {
                    //this.props.deleteItemInput(index, this.props.selectedRegistrar ? this.props.selectedRegistrar.registrarsId : "");
                    this.setState({ showDeletionMessageConfirm: true });
                } else if (index === "UserInfo") {
                    this.setState({ isShowDeletionMsg: true });
                } else if (index === "AccNbrs") {
                    this.setState({ showAccNbrsDeletionMessage: true });
                } else if (index === "FollowUpOrder") {
                    this.setState({ showFollowUpOrderDeletionMessage: true });
                } else if (index === "DeletePatientAbs") {
                    this.setState({ showDeletePatientAbsDeletionMessage: true });
                } else if (index === "LettersMaint" || index === "CustomFormsMaint") {
                    this.setState({ showDeletionMessageConfirm: true });
                } else if (index === "CRStarImportOption") {
                    this.setState({ showImportConfigDeletionMessage: true });
                } else if (index === "ZipCodes") {
                    this.setState({ showDeletionMessageConfirm: true });
                }
                this.setState({ activeBtnDelete: false });
                this.props.setChildrenOpening(true);
                break;

            case "Copy":
            case "CopyPatientAbs":
                this.copySelectedItem();

                this.setState({ activeBtnDelete: false });
                this.props.setChildrenOpening(true);
                break;

            case "CopyCRStarImportOption":
                this.props.copyFnc ? this.props.copyFnc() : null;
                break;

            case "showStateMetafileInfos":
                this.browseToStateMetafile(event);
                break;

            case "Run":
                if (index === "PrintAbstracts" || index === "PrintTxSummary" || index === "PrintQAForm" || index === "PrintSCPForm") {
                    /*
                    var parentElementBtn = event.target.parentElement;
                    var saveBtn = parentElementBtn.firstElementChild;

                    setTimeout(function () {
                        event.preventDefault();
                        saveBtn ? saveBtn.click() : "";
                        return false;
                    }, 0);
                    */

                    this.props.storeToGlobal(null);
                    this.showEvent(event, this.props.patientAbsData, index);

                    return false;
                }
                break;

            case "Preview":
                this.props.previewFnc ? this.props.previewFnc() : null;
                break;
            case "Copy Letter":
            case "Copy Form":
                this.props.copyFnc ? this.props.copyFnc() : null;
                break;

            case "uploadSampleFile":
                this.props.uploadSampleFileFnc();
                break;
            case "previewDataImportConfig":
                this.props.previewFnc();
                break;
            case "resetImportConfiguration":
                let iName = document.getElementById("importLabel0").value;
                let iId = document.getElementById("mergeOptionsId").value;
                this.setState({ resetImportConfigurationMsg: true, importName: iName, importId: iId });
                break;
		
            default:
                break;
        }
    }

    async resetImportOption(event) {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ resetImportConfigurationMsg: false });

        if (event.target.value == "yes") {
            //
            let url = this.props.clientgroup + "/ResetImportOptions";
            let res = await axios
                .post(url, { id: this.state.importId, name: this.state.importName, accessToken: localStorage.getItem('token')})
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });

            //update new accessToken again
            var newAccessToken = res && res.data ? res.data.accessToken : null;
            if (newAccessToken) {
                localStorage.setItem('token', newAccessToken);
            }

            //
            this.setState({
                listFiles: res.data ? res.data.listFiles : null
            });

            // reload parent page again
            let iLabel = this.state.importName ? this.state.importName : (document.getElementById("importLabel0") ? document.getElementById("importLabel0").value : "");
            this.props.loadImportConfig(iLabel);

            this.props.clearCommonLookupItem();
            this.props.reloadPageFnc();
            //
            setTimeout(function () {
                document.getElementById("importLabel0") ? document.getElementById("importLabel0").focus() : null;
            }, 1000);
        }

        return false;
    }

    async browseToStateMetafile(event) {
        event.preventDefault();

        let url = this.props.clientgroup + "/GetListFilesByFilter";
        let res = await axios
            .post(url, { filter: "", ext: 1, accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        //
        this.setState({
            listFiles: res.data ? res.data.listFiles : null
        });

        return false;
    }
    setHighlight = (e, flag) => {
        if (flag) {
            e.target.setAttribute("class", "ErsGrBlueDialogRSRowHover");
        } else {
            e.target.setAttribute("class", "");
        }
    }
    closeStateMetafileWindow = (e) => {
        e.preventDefault();

        this.setState({
            listFiles: null
        });

        return false;
    }

    mouseOverFnc = (e) => {
        /*
        e.preventDefault();
        this.props.mouseOverFnc ? this.props.mouseOverFnc : null;
        return false;
        */
        try {
            this.props.mouseOverFnc();
        } catch (ex) { }
    }

    // -Print Forms-
    //on before show event
    showEvent(e, dialogFormData, screenType) {
        try {
            e.preventDefault();
        } catch (ex) { }
        /*
        if (dialogFormData.populationLabel == "") {
            return false;
        }
        */
        var _this = this;
        setTimeout(function () {
            if (screenType == "PrintAbstracts")
                _this.Print_Abstract(dialogFormData);
            else if (screenType == "PrintTxSummary")
                _this.Print_Tx_Summary(dialogFormData);
            else if (screenType == "PrintQAForm")
                _this.Print_QA_Form(dialogFormData);
            else if (screenType == "PrintSCPForm")
                _this.Gen_Survivorship_Care_Plan(dialogFormData);

            var today = new Date(),
                timeFrom = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            // set clock timer...
            _this.setState({ showClockTimer: true, startTimeFrom: timeFrom });

            // Also set start time to status bar
            if (document.querySelector('.statusBarOnFooterCls'))
                document.querySelector('.statusBarOnFooterCls').innerHTML = "Start Time: " + timeFrom;

            return false;
        }, 0);

        //
        var sInVal = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(sInVal);
            } else {
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
            }
        }, 750);

        return false;
    }

    Print_Abstract = (dialogFormData) => {
        dialogFormData.userId = this.props.username;
        dialogFormData.clientAlias = this.props.clientgroup;
        dialogFormData.userHosp = this.props.selectedHospital ? this.props.selectedHospital : "01";
        if (!dialogFormData.cid || dialogFormData.cid == "") dialogFormData.cid = "0";
        //
        this.props.runPrintAbstract(dialogFormData);
        return false;
    }

    Print_Tx_Summary = (dialogFormData) => {
        dialogFormData.userId = this.props.username;
        dialogFormData.clientAlias = this.props.clientgroup;
        dialogFormData.userHosp = this.props.selectedHospital ? this.props.selectedHospital : "01";
        //
        this.props.runPrintTxSummary(dialogFormData);
        return false;
    }

    Print_QA_Form = (dialogFormData) => {
        dialogFormData.userId = this.props.username;
        dialogFormData.clientAlias = this.props.clientgroup;
        dialogFormData.userHosp = this.props.selectedHospital ? this.props.selectedHospital : "01";
        //
        this.props.runPrintQAForm(dialogFormData);
        return false;
    }

    Gen_Survivorship_Care_Plan = (dialogFormData) => {
        dialogFormData.userId = this.props.username;
        dialogFormData.clientAlias = this.props.clientgroup;
        dialogFormData.userHosp = this.props.selectedHospital ? this.props.selectedHospital : "01";
        //
        this.props.runGenSurvivorshipCarePlan(dialogFormData);
        return false;
    }


    //--

    Check_Status = (e, _this) => {
        e.preventDefault();

        let url = this.props.clientgroup + "/CheckStatus";
        axios.post(url, { accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    eval(res.data);
                }
            }).catch(error => {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        return false;
    }

    Cancel_Report = (e, _this) => {
        e.preventDefault();

        _this.props.cancelSBSReport();

        setTimeout(function () {
            _this.setState({ showClockTimer: false });
            return false;
        }, 0);

        return false;
    }

    // -End-

    showDiagnosisText = (cId) => {
        this.props.showTextDialog(cId);
    }

    showConfirmationMsgOrContinue = (event, _menuIndex=0) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        console.log("_menuIndex=" + _menuIndex);

        const menuIndex = event.target.getAttribute('menu-index') ? event.target.getAttribute('menu-index') : "0";

        if (this.props.isFormDirty && !this.props.inquiringMode && (!this.props.availableHospsData || this.props.availableHospsData != null && this.props.availableHospsData.indexOf(this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.hosp : "") !== -1)) {
            if (event.target.value === "yes") {
                if (this.props.isChildren) {
                    this.props.setChildrenWindowVisible(false);
                } else if (this.props.isLookup) {
                    this.props.closeAddNewDialog(false);
                } else if (this.props.isV21CompatibilityVisible) {
                    this.setState({ isShowConfirmationMsg: false, menuIndex: "0" });
                    this.props.closeV21CompatibilityFeature();
                    return false;
                } else if (this.props.initialPageVisible) {
                    this.setState({ isShowConfirmationMsg: false, menuIndex: "0" });
                    this.props.setInitialPageVisible(false);
                    return false;
                } else {
                    this.props.setParentWindowVisible(false);

                    this.props.removeTabItems(this.props.currentDialog, menuIndex);//#comment out this line
                }
                this.setState({ isShowConfirmationMsg: false, menuIndex: "0" });
                this.props.setChildrenOpening(false);
            } else {
                this.setState({ isShowConfirmationMsg: true, menuIndex: menuIndex });
                this.props.setChildrenOpening(true);
            }
        } else {
            if (this.props.isChildren) {
                this.props.setChildrenWindowVisible(false);
            } else if (this.props.isLookup) {
                this.props.closeAddNewDialog(false);
            } else if (this.props.isV21CompatibilityVisible) {
                this.props.closeV21CompatibilityFeature();
                return false;
            } else if (this.props.initialPageVisible) {
                this.props.setInitialPageVisible(false);
                this.props.loadAccNbrsMaintInit();
                return false;
            } else {
                this.props.setParentWindowVisible(false);
                this.props.removeTabItems(this.props.currentDialog, menuIndex);
            }
            this.props.setChildrenOpening(false);
        }

        return false;
    }

    hideConfirmationMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        if (event.target.value === "yes") {
            if (this.props.isChildren) {
                this.props.setChildrenWindowVisible(false);
            } else {
                this.props.setParentWindowVisible(false);
            }
        }

        this.setState({ isShowConfirmationMsg: false, menuIndex: "0" });

        this.props.setChildrenOpening(false);

        //
        setTimeout(function () {
            document.getElementById('btnExitForm_Fake') ? document.getElementById('btnExitForm_Fake').focus() : "";
            return false;
        }, 100);

        return false;
    }

    hideDeletionMsg = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowDeletionMsg: false });
        this.props.setChildrenOpening(false);

        //
        setTimeout(function () {
            document.getElementById('btnDeleteItem_Fake') ? document.getElementById('btnDeleteItem_Fake').focus() : "";
            return false;
        }, 100);

        return false;
    }

    deleteSecurityUser = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowDeletionMsg: false });
        this.props.deleteItemInput("UserInfo", this.props.selectedUserInfo ? this.props.selectedUserInfo.userid : "0");
        this.props.setChildrenOpening(false);

        //
        setTimeout(function () {
            document.getElementById('Userid') ? document.getElementById('Userid').focus() : "";
            return false;
        }, 1000);

        return false;
    }

    javascript_void_fnc(event) {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        return false;
    }

    render() {

        var clockJs;
        {
            clockJs = this.state.showClockTimer && this.props.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls" style={{ cursor: "wait", marginRight: "1px" }}>
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Creating Files - Please Wait&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTimeFrom}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>

                            {
                                this.props.showBtnNew == "PrintTxSummary" ?
                                    <div className="row">
                                        <div className="col-sm-12 center">
                                            <button onClick={(e) => { this.Check_Status(e, this) }} className="ErsGrBlueButton" value="yes" autoFocus>Check Status</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <button onClick={(e) => { this.Cancel_Report(e, this) }} className="ErsGrBlueButton" value="no">Cancel</button>
                                        </div>
                                    </div>
                                    : null
                            }

                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let hasNotificationSaveMsg;
        {
            hasNotificationSaveMsg = this.state.hasNotificationSaveMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Notification</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} /*href="javascript:void(0)" role="button" aria-label="Close"*/ className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideNotificationSaveMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>
                                    Saving this change may result in a different schema classification.<br />
                                    Please visit the staging screen and ensure applicable SSDI fields have been completed.
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={this.hideNotificationSaveMsg} value="ok" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let confirmationMsg = (
            this.state.isShowConfirmationMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Confirmation</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} /*href="javascript:void(0)" role="button" aria-label="Close"*/ className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmationMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Changes have not been saved. Exit without saving?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.showConfirmationMsgOrContinue(e, this.props.menuIdx) }} value="yes" menu-index={this.props.menuIdx} autoFocus>OK</button>
                                <button onClick={(e) => { this.hideConfirmationMsg(e) }} value="no">Cancel</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        );

        let resetImportConfigurationMsg = (
            this.state.resetImportConfigurationMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Confirmation</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.resetImportOption(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Are you sure you want to Reset the {this.state.importName} Settings?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.resetImportOption(e) }} value="yes" autoFocus>OK</button>
                                <button onClick={(e) => { this.resetImportOption(e) }} value="no">Cancel</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        );

        // get current index of navigation buttons...
        var indexOfCurrentTab = this.getIndexOfCurrentDialog();
        // then check next Tabs is enabled or disabled/hidden to set status correctly
        // NEED ONLY check with Next button (Prev button will be ignored)
        var foundNextItem = false;
        if (indexOfCurrentTab == dialogTypes.dialogDisplays.length) return false;

        try {
            for (let pi = indexOfCurrentTab; pi < dialogTypes.dialogDisplays.length; pi++) {
                let _isHidden = false;
                const btnName = dialogTypes.buttonNamesOnTab[pi + 1];

                var availabelItems = this.props.availableItems;
                if (availabelItems === null || availabelItems.length === 0 || availabelItems.indexOf(btnName) != -1) {
                    _isHidden = false;
                    foundNextItem = true;
                } else {
                    _isHidden = true;
                }

                if (!this.props.tabStatuses[pi + 1] || _isHidden) {
                    continue;//indexOfCurrentTab++;
                } else {
                    if (foundNextItem) break;
                    else continue;
                }
            }
        } catch (ex) { }

        const navigationButtons = (
            <React.Fragment>
                <button id="btnNavigatePrevious" onClick={(event) => { this.doAction(event, 'previous') }}
                    disabled={this.props.isDisabledOnChildren}>Previous</button>

                <button id="btnNavigateNext" onClick={(event) => { this.doAction(event, 'next') }}
                    disabled={this.props.isDisabledOnChildren || this.props.currentDialog === dialogTypes.dialogDisplays[dialogTypes.dialogDisplays.length - 1] || !foundNextItem}>Next</button>

                <button id="btnNavigateGoto" onClick={this.openGoto} disabled={this.props.isDisabledOnChildren} style={{ display: "none" }}>Goto</button>
            </React.Fragment>
        );

        const _currentShowingHospitalVersion = this.props.currentShowingHospitalVersion;
        //console.log('currentShowingHospitalVersion=' + _currentShowingHospitalVersion);
        let showExtras = (
            this.props.showExtra ?
                this.props.showExtra.map((btn, index) => (
                    btn.name == "v21 Compatibility Fields" ? (_currentShowingHospitalVersion == "21" ?
                        <button key={"extra_btn_" + index} id={"extra_btn_" + index} onClick={(event) => { this.extraAction(event, btn.action, index) }}>{btn.name}</button>
                        :
                        <button key={"extra_btn_" + index} id={"extra_btn_" + index} onClick={(event) => { this.extraAction(event, btn.action, index) }} style={{ display: "none" }}>{btn.name}</button>
                    ) : <button key={"extra_btn_" + index} id={"extra_btn_" + index} onClick={(event) => { this.extraAction(event, btn.action, index) }} title={btn.title ? btn.title : ""} disabled={btn.enabled == undefined ? false : !btn.enabled}> { btn.name }</button >
                ))
                : ""
        );

        let loading = (
            this.props.isPatientSaving || this.props.isPatientLoading || this.props.waitingFor ? <div id="waitingForLoadingPage" className={!this.props.noShowLoader ? "loader" : ""}></div> : ""
        );

        // ADD - 2021/09/15 - START
        let deletionMsg = (
            this.state.isShowDeletionMsg ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Delete Security</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} /*href="javascript:void(0)" role="button" aria-label="Close"*/ className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDeletionMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>Delete Security For This User?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.deleteSecurityUser(e) }} value="yes" autoFocus>Yes</button>
                                <button onClick={(e) => { this.hideDeletionMsg(e) }} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        );
        // ADD - 2021/09/15 - END

        let statusBarText = "";
        //
        if (this.props.focusingField && this.props.focusingField.getAttribute("data-help-naming") && this.props.focusingField.getAttribute("data-help-naming") != undefined) {
            let value = this.state.focusedInFieldShowingValue ? this.state.focusedInFieldShowingValue : " ";
            statusBarText = this.props.focusingField.getAttribute("data-help-naming") + " - " + value;
        } else if (this.props.focusingField && this.props.focusingField.name) {
            let name = this.state.focusedInFieldShowingName ? this.state.focusedInFieldShowingName : " ";
            var value = this.state.focusedInFieldShowingValue ? this.state.focusedInFieldShowingValue : " ";
            name = name.replace(/(([A-Z0-9])+[0-9]|[A-Z0-9])/g, "_$1");
            if (name.toUpperCase() === "PHYSICIANS_ID_DSP") {
                name = "PHYSICIANS_ID";
            }

            /*/ ADD - 2022/07/08
            if (this.props.focusingField && this.props.focusingField.getAttribute("type") == "checkbox") {
                value = !eval(value);
            }
            */

            statusBarText = name.toUpperCase() + " - " + value;
        }

        let deletionMessageConfirm = (
            this.state.showDeletionMessageConfirm ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">{this.props.showBtnDelete == "LettersMaint" || this.props.showBtnDelete == "CustomFormsMaint" ? "Confirmation" : "Deletion"}</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} /*href="javascript:void(0)" role="button" aria-label="Close"*/ className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDeletionMessageConfirm(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container" style={{ textAlign: "left" }}>{this.props.showBtnDelete == "LettersMaint" ? "Are you sure you want to Delete this " + (this.props.showBtnDelete == "LettersMaint" ? "Letter" : (this.props.showBtnDelete == "CustomFormsMaint" ? "Custom Form" : "item")) + "?" : "Deleting current record."}</div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.deleteSelectedItem(e) }} value="yes" autoFocus>OK</button>
                                <button onClick={(e) => { this.hideDeletionMessageConfirm(e) }} value="no">Cancel</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        );

        var btnDeleteEnabled = false;
        var isPrintForms = false;
        var isImportOptionForm = false;
        if (this.props.showBtnDelete == "Hospital" && this.props.selectedHospital && this.props.selectedHospital.hospitalId != 0) {
            btnDeleteEnabled = true;
        } else if (this.props.showBtnDelete == "Registrar" && this.props.selectedRegistrar && this.props.selectedRegistrar.registrarsId && this.props.selectedRegistrar.registrarsId != "") {
            btnDeleteEnabled = true;
        } else if (this.props.showBtnDelete == "Physician" && this.props.selectedPhysician && this.props.selectedPhysician.physiciansId && this.props.selectedPhysician.physiciansId != "") {
            btnDeleteEnabled = true;
        } else if (this.props.showBtnDelete == "AccNbrs" && this.props.accNbrsMaintInfo && this.props.accNbrsData.hosp && this.props.accNbrsData.accYear && this.props.accNbrsData.nextAccNbr) {
            btnDeleteEnabled = true;
        } else if (this.props.showBtnDelete == "FollowUpOrder" && this.props.followUpOrderInfo && this.props.enabledDeletedBtn) {
            btnDeleteEnabled = true;
        } else if (this.props.showBtnDelete == "DeletePatientAbs" && this.props.patientAbsData && (this.props.patientAbsData.firstName != "" || this.props.patientAbsData.lastName != "") && this.props.enabledDeletedBtn) {
            btnDeleteEnabled = true;
        } else if (this.props.showBtnDelete == "CopyPatientAbs" && this.props.patientAbsInfo && this.props.enabledDeletedBtn) {
            btnDeleteEnabled = true;
        } else if (this.props.showBtnDelete == "UserInfo" && this.props.userInfo && this.props.userInfo.userid /*&& this.props.enabledDeletedBtn*/) {
            btnDeleteEnabled = true;
        } else if ((this.props.showBtnDelete == "PrintAbstracts" || this.props.showBtnDelete == "PrintTxSummary" || this.props.showBtnDelete == "PrintQAForm" || this.props.showBtnDelete == "PrintSCPForm") && this.props.patientAbsData && this.props.enabledDeletedBtn) {
            isPrintForms = true;
            btnDeleteEnabled = this.props.enabledDeletedBtn;
        } else if ((this.props.showBtnDelete == "LettersMaint" || this.props.showBtnDelete == "CustomFormsMaint") && this.props.enabledDeletedBtn) {
            isPrintForms = true;
            btnDeleteEnabled = true;

        } else if (this.props.showBtnDelete == "GlobalDir" && this.props.selectedGlobalDir && this.props.selectedGlobalDir.itemNbr != 0) {
            btnDeleteEnabled = true;
        } else if (this.props.showBtnDelete == "CRStarImportOption" && this.props.importOptionDatas && this.props.enabledDeletedBtn) {
            isImportOptionForm = true;
            btnDeleteEnabled = this.props.enabledDeletedBtn;
        } else if (this.props.showBtnDelete == "ZipCodes" && this.props.selectedZipCodes && this.props.selectedZipCodes.zipCodesId && this.props.selectedZipCodes.zipCodesId != "") {
            btnDeleteEnabled = true;
        }

        //
        let accNbrsDeletionMessage = (
            this.state.showAccNbrsDeletionMessage ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Deletion</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} /*href="javascript:void(0)" role="button" aria-label="Close"*/ className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideAccNbrsDeletionMessage(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container" style={{ textAlign: "left" }}>
                                Are you sure you want to delete the Acc Nbr that has these attributes:<br />
                                Hosp: {this.props.accNbrsData.hosp}<br />
                                Acc Year: {this.props.accNbrsData.accYear} <br />
                                Next Acc Nbr: {this.props.accNbrsData.nextAccNbr}
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.deleteSelectedItem(e) }} value="yes" autoFocus>OK</button>
                                <button onClick={(e) => { this.hideAccNbrsDeletionMessage(e) }} value="no">Cancel</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        );

        //
        let followUpOrderDeletionMessage = (
            this.state.showFollowUpOrderDeletionMessage ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Confirm Deletion</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} /*href="javascript:void(0)" role="button" aria-label="Close"*/ className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideFollowUpOrderDeletionMessage(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container" style={{ textAlign: "left" }}>
                                Are you sure you want to Delete this template?
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.deleteSelectedItem(e) }} value="yes" autoFocus>OK</button>
                                <button onClick={(e) => { this.hideFollowUpOrderDeletionMessage(e) }} value="no">Cancel</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        );

        //
        var hasPs = this.props.patientAbsData && this.props.patientAbsData.cid != null && this.props.patientAbsData.cid != undefined && this.props.patientAbsData.cid != "" ? true : false;
        var delMsg = "";
        if (this.props.patientAbsData) {
            if (hasPs) {
                delMsg = "Are you sure you want to delete Abstract site: " + this.props.patientAbsData.siteCode + ", Seq: " + this.props.patientAbsData.seqPrim + ", Hosp: " + this.props.patientAbsData.hosp + " from this patient?";
            } else {
                delMsg = "Are you sure you want to delete the entire patient record for " + this.props.patientAbsData.firstName + " " + this.props.patientAbsData.lastName + " and any associated primaries?";
            }
        }
        let deletePatientAbsDeletionMessage = (
            this.state.showDeletePatientAbsDeletionMessage ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Confirm Deletion</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} /*href="javascript:void(0)" role="button" aria-label="Close"*/ className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDeletePatientAbsDeletionMessage(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container" style={{ textAlign: "left" }}>
                                {delMsg}
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.deleteSelectedItem(e) }} value="yes" autoFocus>Yes</button>
                                <button onClick={(e) => { this.hideDeletePatientAbsDeletionMessage(e) }} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        );

        //

        let listFiles;
        {
            listFiles = this.state.listFiles ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Display Metafile Dates</div>
                                <div className="k-window-actions k-dialog-actions">
                                <a href="#" onClick={this.javascript_void_fnc} /*href="javascript:void(0)" role="button" aria-label="Close"*/ className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x hidden" onClick={(e) => { this.closeStateMetafileWindow(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container listMetafilesArea">
                                {
                                    this.state.listFiles.map((f, index) => (
                                        <div className={index % 2 == 0 ? "row oddRowCls" : "row evenRowCls"}>
                                            <div className="col-sm-12">
                                                <div className="" onClick={e => { this.closeStateMetafileWindow(e, index) }}
                                                    onMouseOver={e => { this.setHighlight(e, true) }}
                                                    onMouseOut={e => { this.setHighlight(e, false) }}>
                                                    {f}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="emptyLine"></div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={(e) => { this.closeStateMetafileWindow(e) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        //
        let crstarImportConfigDeletionMessage = (
            this.state.showImportConfigDeletionMessage ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Confirm Deletion</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} /*href="javascript:void(0)" role="button" aria-label="Close"*/ className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideImportConfigDeletionMessage(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container" style={{ textAlign: "left" }}>
                                Are you sure you want to Delete these import settings?
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.deleteSelectedItem(e) }} value="yes" autoFocus>OK</button>
                                <button onClick={(e) => { this.hideImportConfigDeletionMessage(e) }} value="no">Cancel</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        );

        return (
            <div className="footerNamingCls">
                <div style={{ display: "none" }}>
                    <input id="isFormDirtyAlias_TextHiddenField" value={this.props.isFormDirty ? "1" : ""} readOnly />
                </div>
                <div className="statusBarOnFooterCls" style={{ display: "" }}>{statusBarText}</div>

                <div>{this.props.formId == "v21CompatibilityFeatureForm" ? "This case underwent NAACCR conversion: " + (this.props.v22NaaccrConversionPoints ? this.props.v22NaaccrConversionPoints : "") : ""}</div>

                <div className={this.props.isChildren ? "Footer2" : (this.props.className ? this.props.className : "Footer")}>

                    <input type="submit" style={{ visibility: "hidden" }}
                        value={this.props.saveText ? this.props.saveText : "Save"}
                        form={this.props.formId}
                        disabled={!this.props.isFormDirty}
                        id="btnSubmitFormIsHidden"
                        onClick={(e) => { this.setAllowSaveAnyway(e) }}
                    />

                    {
                        this.props.showBtnNew ? <button id="btnAddNewItem0" onClick={(event) => { this.extraAction(event, "New", this.props.showBtnNew) }} title="Start entering a new record" disabled={this.props.disabledOnInitialForm}>New</button>
                            : "" /*(this.props.refreshForm ?
                                <button onClick={this.props.refreshForm} disabled={!this.props.isFormDirty}>Refresh</button> : ""
                                )*/
                    }

                    <button id="btnSubmitForm_Fake"
                        disabled={
                            this.props.inquiringMode && document.querySelector("#Tabid_Patient") && document.querySelector("#Tabid_Patient").getAttribute("class").indexOf("tab-list-active") == -1
                            || (this.props.inquiringMode && (this.props.alphaUrl == "/patient-abstract" || this.props.alphaUrl == "/rapid-abstract"))
                            || !this.props.isFormDirty
                            || (this.props.availableHospsData != null && this.props.availableHospsData.indexOf(this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.hosp : "01") == -1
                                && document.querySelector("#Tabid_Patient") && document.querySelector("#Tabid_Patient").getAttribute("class").indexOf("tab-list-active") == -1)
                        }
                        onMouseOver={this.mouseOverFnc}
                        onClick={(event) => { this.doAction(event, 'submit') }} title="Save the form" className={this.props.hideSaveBtn ? "hidden" : ""}>{this.props.saveText ? this.props.saveText : "Save"}</button>

                    {this.props.showNavigation ? navigationButtons : null}

                    {
                        this.props.showBtnPreview ? <button id="btnPreview" onClick={(event) => { this.extraAction(event, "Preview") }}
                            title={"Preview"} disabled={this.props.disabledOnInitialForm}>Preview</button> : ""
                    }

                    {
                        this.props.showBtnCopy ? <button id="btnCopy" name="btnCopyItem_Fake" onClick={(event) => { this.extraAction(event, this.props.btnCopyText ? this.props.btnCopyText : "Copy", this.props.showBtnCopy) }}
                            disabled={!this.props.enabledCopyBtn}
                            title={this.props.btnCopyText && this.props.btnCopyText != "CopyCRStarImportOption" ? this.props.btnCopyText : "Copy record"}>{/*this.props.btnCopyText ? this.props.btnCopyText : */"Copy"}</button> : ""
                    }

                    <button id="btnExitForm_Fake" onClick={(e) => { this.showConfirmationMsgOrContinue(e, this.props.menuIdx) }} className={this.props.ignoreExit ? "hidden" : ""} menu-index={this.props.menuIdx} title="Exit the form">{this.props.exitText ? this.props.exitText : "Exit"}</button>

                    {
                        this.props.showBtnDelete ? <button id="btnDeleteItem_Fake" onClick={(event) => { this.extraAction(event, this.props.btnDeleteText ? this.props.btnDeleteText : "Delete", this.props.showBtnDelete) }}
                            disabled={this.props.enabledDeletedBtn && (isPrintForms || isImportOptionForm) ? false : (this.props.inquiringMode || !btnDeleteEnabled || (!this.props.isFormDirty && !this.props.enabledAlways))}
                            title={this.props.btnDeleteText ? (this.props.btnDeleteText + " record") : "Delete record"}>{this.props.btnDeleteText ? this.props.btnDeleteText : "Delete"}</button> : ""
                    }

                    <div style={{ float: "right" }}>
                        {showExtras}
                    </div>

                    <React.Fragment>
                        {confirmationMsg}
                    </React.Fragment>

                    <React.Fragment>
                        {resetImportConfigurationMsg}
                    </React.Fragment>                    

                    <React.Fragment>
                        {this.props.isChildren ? null : loading}
                    </React.Fragment>

                    <React.Fragment>
                        {hasNotificationSaveMsg}
                    </React.Fragment>

                    <React.Fragment>
                        {deletionMsg}
                    </React.Fragment>

                    <React.Fragment>
                        {deletionMessageConfirm}
                    </React.Fragment>

                    <React.Fragment>
                        {accNbrsDeletionMessage}
                    </React.Fragment>
		    
                    <React.Fragment>
                        {clockJs}
                    </React.Fragment>

                    <React.Fragment>
                        {listFiles}
                    </React.Fragment>

                    <React.Fragment>{followUpOrderDeletionMessage}</React.Fragment>

                    <React.Fragment>{deletePatientAbsDeletionMessage}</React.Fragment>

                    <React.Fragment>{crstarImportConfigDeletionMessage}</React.Fragment>
                    
                </div>
            </div>
        );
    }
}

Footer.propTypes = {
    // This function must be passed in so that the footer's Exit button can close the window containing its parent Maintenance component
    //closeWindow: PropTypes.func.isRequired,
    // This should correspond to the id on the form within the footer's parent Maintenance component so that the Save button can refer to the form for submission
    formId: PropTypes.string,
    // Determines if the 'Next, Previous, Goto' buttons render
    showNavigation: PropTypes.bool
}

const mapStateToProps = state => {
    return {
        authRedirectPath: state.auth.authRedirectPath,
        routingPage: state.patientAbstract.routingPage,
        currentDialog: state.patientAbstract.currentDialog,
        selectedHospital: state.patientAbstract.selectedHospitalOnModal,
        selectedPhysician: state.patientAbstract.selectedPhysicianOnModal,
        selectedRegistrar: state.patientAbstract.selectedRegistrarOnModal,
        selectedGlobalDir: state.patientAbstract.selectedGlobalDirOnModal,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedDiagnosisRecord: state.patientAbstract.selectedDiagnosisRecord,

        isPatientLoading: state.patientAbstract.isPatientLoading,
        isPatientSaving: state.patientAbstract.isPatientSaving,
        isParentWindowVisible: state.patientAbstract.isParentWindowVisible,
        tabItems: state.patientAbstract.tabItems,
        tabStatuses: state.patientAbstract.tabStatuses,
        patientId: state.patientAbstract.patientId,
        userId: state.patientAbstract.userId,
        clientgroup: state.auth.clientgroup,
        username: state.auth.userId,

        alphaUrlInnerTab: state.patientAbstract.alphaUrl,
        alphaUrl: window.location ? window.location.pathname : "",
        showingTabName: state.patientAbstract.showingTabName,
        availableItems: state.patientAbstract.availableItems,
        loggedOnUid: state.auth.userId,
        selectedUserOnGrid: state.patientAbstract.selectedUserOnGrid,

        selectedUserInfo: state.patientAbstract.selectedUserInfo,
        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
        selectedDiagnosisRecord: state.patientAbstract.selectedDiagnosisRecord,
        clientgroup: state.auth.clientgroup,
        crstarSessionTimeout: state.auth.expiresIn,
        currentShowingState: state.patientAbstract.currentShowingState,
        currentShowingHospitalVersion: state.patientAbstract.currentShowingHospitalVersion,
        isV21CompatibilityVisible: state.patientAbstract.isV21CompatibilityVisible,

        inquiringMode: state.auth.inquiringMode,
        availableHospsData: state.auth.availableHospsData,
        rapidAbstract: state.patientAbstract.rapidAbstract,
        waitingFor: state.patientAbstract.waitingFor,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        itemNbr: state.patientAbstract.itemNbr,
        accNbrsMaintInfo: state.patientAbstract.accNbrsMaintInfo,
        followUpOrderInfo: state.patientAbstract.followUpOrderInfo,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        patientAbsInfo: state.patientAbstract.patientAbsInfo,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        showClockTimer: state.patientAbstract.showClockTimer,
        selectedZipCodes: state.patientAbstract.selectedZipCodesOnModal,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: (uid, cg) => dispatch(actions.logout(uid, cg)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        selectUser: (uId) => dispatch(actionCreators.selectUser(uId)),
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),

        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        selectTabItems: (dialogType) => dispatch(actionCreators.selectTabItems(dialogType)),
        removeTabItems: (dialogType) => dispatch(actionCreators.removeTabItems(dialogType)),
        clearHospitalFormInput: (number) => dispatch(actionCreators.selectHospitalRecord(number)),
        clearPhysicianFormInput: (number) => dispatch(actionCreators.selectPhysicianRecord(number)),
        clearRegistrarFormInput: (number) => dispatch(actionCreators.selectRegistrarRecord(number)),
        deleteItemInput: (formId, idNo, cid) => dispatch(actionCreators.deleteItemInput(formId, idNo, cid)),
        clearGlobalDirFormInput: (number) => dispatch(actionCreators.selectGlobalDirRecord(number)),
        showTextDialog: (cId) => dispatch(actionCreators.showTextDialog(cId)),
        v21CompatibilityFeature: (cId) => dispatch(actionCreators.v21CompatibilityFeature(cId)),
        selectPatient: (patientId, isAddNew, onTab) => dispatch(actionCreators.selectPatient(patientId, isAddNew, onTab)),
        selectStagingRecord: (medial) => dispatch(actionCreators.selectStagingRecord(medial)),
        selectTreatmentRecord: (medial) => dispatch(actionCreators.selectTreatmentRecord(medial)),
        selectOutcomesRecord: (medial) => dispatch(actionCreators.selectOutcomesRecord(medial)),
        selectCocRecord: (medial) => dispatch(actionCreators.selectCocRecord(medial)),
        selectNapbcRecord: (medial) => dispatch(actionCreators.selectNapbcRecord(medial)),
        selectRectalProgramRecord: (medial) => dispatch(actionCreators.selectRectalProgramRecord(medial)),
        selectCaseAdminRecord: (medial, uid) => dispatch(actionCreators.selectCaseAdminRecord(medial, uid)),
        selectClinicalRecord: (medial) => dispatch(actionCreators.selectClinicalRecord(medial)),
        selectResearchRecord: (medial) => dispatch(actionCreators.selectResearchRecord(medial)),
        selectUserDefinedRecord: (medial) => dispatch(actionCreators.selectUserDefinedRecord(medial)),
        selectStateFieldsRecord: (medial, nm) => dispatch(actionCreators.selectStateFieldsRecord(medial, nm)),
        closeOtherTabItems: (flag) => dispatch(actionCreators.closeOtherTabItems(flag)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        selectRetiredRecord: (medial) => dispatch(actionCreators.selectedRetiredRecord(medial)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        setChildrenWindowVisible: (flag) => dispatch(actionCreators.setChildDialogVisible(flag)),
        // Hospital and Physician lookup
        closeAddNewDialog: (flag) => dispatch(actionCreators.closeAddNewDialog(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        selectTabItems: (dialogType) => dispatch(actionCreators.selectTabItems(dialogType)),
        removeTabItems: (dialogType, menuIdx) => dispatch(actionCreators.removeTabItems(dialogType, menuIdx)),
        //
        clearUserInfoFormInput: (email, cligrp) => dispatch(actionCreators.selectUserInfoRecord(email, cligrp)),
        setChangePwdDialogVisible: (flg) => dispatch(actionCreators.setChangePwdDialogVisible(flg)),
        setAllowSaveAnyway: (flg) => dispatch(actionCreators.setAllowSaveAnyway(flg)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        showPageLoading: (value) => dispatch(actionCreators.showPageLoading(value)),
        setItemNbr: (value) => dispatch(actionCreators.setItemNbr(value)),

        selectUserInfoRecord: (email, cligrp) => dispatch(actionCreators.selectUserInfoRecord(email, cligrp)),
        loadPwdPolicy: (uid) => dispatch(actionCreators.loadPwdPolicy(uid)),
        showLockedUsers: (group) => dispatch(actionCreators.showLockedUsers(group)),
        setAlphaUrl: (url) => dispatch(actionCreators.setAlphaUrl(url)),
        resetCurrentSelectedIndex: (idx) => dispatch(actionCreators.resetCurrentSelectedIndex(idx)),
        setRapidAbstract: (value) => dispatch(actionCreators.setRapidAbstract(value)),
        clearAndResetDataWhenPageLoading: (value) => dispatch(actionCreators.clearAndResetDataWhenPageLoading(value)),
        //
        resetAllStoringOfUser: () => dispatch(actionCreators.resetAllStoringOfUser()),
        setHandlerControlItem: (item) => dispatch(actionCreators.setHandlerControlItem(item)),
        closeV21CompatibilityFeature: () => dispatch(actionCreators.closeV21CompatibilityFeature()),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
        loadAccNbrsMaintInit: () => dispatch(actionCreators.loadAccNbrsMaintInit()),
        //
        loadPatientAbsInfo: (pid) => dispatch(actionCreators.loadPatientAbsInfo(pid)),
        selectedPatientOnGrid: (obj) => dispatch(actionCreators.selectedPatientOnGrid(obj)),
        copyItemInput: (formId, item) => dispatch(actionCreators.copyItemInput(formId, item)),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),

        runPrintAbstract: (dialogForm) => dispatch(actionCreators.runPrintAbstract(dialogForm)),
        runPrintTxSummary: (dialogForm) => dispatch(actionCreators.runPrintTxSummary(dialogForm)),
        runPrintQAForm: (dialogForm) => dispatch(actionCreators.runPrintQAForm(dialogForm)),
        runGenSurvivorshipCarePlan: (dialogForm) => dispatch(actionCreators.runGenSurvivorshipCarePlan(dialogForm)),

        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),
        loadLettersMaint: (name) => dispatch(actionCreators.loadLettersMaint(name)),
        previewLettersMaint: (form) => dispatch(actionCreators.previewLettersMaint(form)),
        loadCustomFormsMaint: (name) => dispatch(actionCreators.loadCustomFormsMaint(name)),
        loadImportConfig: (label) => dispatch(actionCreators.loadImportConfigInfo(label)),
        selectZipCodesRecord: (code) => dispatch(actionCreators.selectZipCodesRecord(code)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);