/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Footer from "../Shared/Footer";
import { TabContent, TabPane, Nav, NavLink } from "reactstrap";
import classnames from "classnames";
import Input from "../../../UI/Input";
import "../PatientMaintenance/PatientMaintenance.css";
import "./UserDefinedMaintenance.css";
import moment from "moment";
import TextForm from "../DiagnosisMaintenance/TextForm";
import Help from "../../../UI/Help";
import axios from "../../../../axios-instance";
import Draggable from 'react-draggable';
import * as TextPopup from "../TitleOfTextPopup";

class UserDefinedMaintenance extends Component {

    state = {
        activeTab: "1",
        dAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'userDefinedForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty userDefinedForm back to what it was on load
        initialUserDefinedForm: null,
        userDefinedForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        fieldInFocus: "udef1",
        isHelpVisible: false,
        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,
        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,
        allLabelList: null,
        hasSetLabelList: false,
        focusingField: null,
    }

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: flag });
    }

    toggleTab = (event, tab) => {
        this.setState({ activeTab: tab });

        if (tab === "1") {
            setTimeout(function () {
                document.getElementById("udef1") ? document.getElementById("udef1").focus() : '';
            }, 0);
        } else if (tab === "2") {
            setTimeout(function () {
                document.getElementById("udef46") ? document.getElementById("udef46").focus() : '';
            }, 0);
        }

        return false;
    }

    showTextDialog = () => {
        this.setState({ isShowTextVisible: true });
        return false;
    }

    closeTextDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowTextVisible: false });
        this.props.closeTextDialog(false);

        return false;
    }

    componentDidMount() {
        if (!this.state.userDefinedForm && this.props.selectedUserDefinedRecord) {
            this.setFormData('Mount');
        }

        /*
        if (this.state.userDefinedForm) {
            if (this.state.allLabelList === null) {
                this.getAllLabelsOfUserDefined();
            } else {
                if (this.state.hasSetLabelList && this.state.allLabelList && this.state.allLabelList.length > 0) {
                    let labels = this.state.allLabelList.split("~|~");
                    var _userDefinedForm = {
                        ...this.state.userDefinedForm
                    };
                    for (var i = 0; i < labels.length; i++) {
                        let obj = labels[i].split(':');
                        _userDefinedForm[obj[0].replace("udef", "udefLabel")] = obj[1];
                    }

                    this.setState({
                        userDefinedForm: { ..._userDefinedForm },
                        hasSetLabelList: false,
                    });
                }
            }
        }
        */
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.userDefinedForm && this.props.selectedUserDefinedRecord)
            || (prevProps.selectedUserDefinedRecord && prevProps.selectedUserDefinedRecord !== this.props.selectedUserDefinedRecord)
            || (this.props.initialAbstractPages && this.props.selectedUserDefinedRecord)
        ) {
            this.setFormData('Update');
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.userDefinedForm && this.state.dAllItemFields
        ) {
            console.log("[User Defined] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    userDefinedForm: { ...this.state.userDefinedForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                /*
                //#PR00424
                var fName = this.props.currentSelectedField;
                if (fName && this.state.userDefinedForm[fName.replace("udef", "udefLabel")]) {
                    //Value should only be validated if codes are listed in the global directory.
                    this.setState({
                        dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        userDefinedForm: { ...this.state.userDefinedForm, [this.props.currentSelectedField + "Name"]: "" },
                    });
                }
                */
                this.setState({
                    dAllItemFields: { ...this.state.dAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    userDefinedForm: { ...this.state.userDefinedForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        //
        if (this.state.userDefinedForm) {
            if (this.state.allLabelList === null) {
                this.getAllLabelsOfUserDefined();
            } else {
                if (this.state.hasSetLabelList && this.state.allLabelList && this.state.allLabelList.length > 0) {
                    let labels = this.state.allLabelList.split("~|~");
                    var _userDefinedForm = {
                        ...this.state.userDefinedForm
                    };
                    for (var i = 0; i < labels.length; i++) {
                        let obj = labels[i].split(':');
                        _userDefinedForm[obj[0].replace("udef", "udefLabel")] = obj[1];
                    }

                    this.setState({
                        userDefinedForm: { ..._userDefinedForm },
                        hasSetLabelList: false,
                    });
                }
            }
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "User_Defined", accessToken: localStorage.getItem('token')})
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res.data ? res.data.result : null
        });
    }

    async getAllLabelsOfUserDefined() {
        let url = this.props.clientgroup + "/GetAllLabelsOfUserDefined";
        let res = await axios
            .post(url, { accessToken: localStorage.getItem('token')})
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetLabelList: true,
            allLabelList: res.data ? res.data.code : null
        });
    }
    
    setFormData = (from) => {
        //console.log("=== setFormData >> " + from);
        var _userDefinedForm = this.props.selectedUserDefinedRecord;
        if (this.state.allLabelList && this.state.allLabelList.length > 0) {
            let labels = this.state.allLabelList.split("~|~");
            for (var i = 0; i < labels.length; i++) {
                let obj = labels[i].split(':');
                _userDefinedForm[obj[0].replace("udef", "udefLabel")] = obj[1];
            }
        }

        this.setState({
            userDefinedForm: {
                ..._userDefinedForm,
                ["udef41"]: (this.props.selectedUserDefinedRecord != null && this.props.selectedUserDefinedRecord.udef41 !== null && this.props.selectedUserDefinedRecord.udef41 !== "" ? moment(new Date(this.props.selectedUserDefinedRecord.udef41)).format("MM/DD/YYYY") : ""),
                ["udef42"]: (this.props.selectedUserDefinedRecord != null && this.props.selectedUserDefinedRecord.udef42 !== null && this.props.selectedUserDefinedRecord.udef42 !== "" ? moment(new Date(this.props.selectedUserDefinedRecord.udef42)).format("MM/DD/YYYY") : ""),
                ["udef43"]: (this.props.selectedUserDefinedRecord != null && this.props.selectedUserDefinedRecord.udef43 !== null && this.props.selectedUserDefinedRecord.udef43 !== "" ? moment(new Date(this.props.selectedUserDefinedRecord.udef43)).format("MM/DD/YYYY") : ""),
                ["udef44"]: (this.props.selectedUserDefinedRecord != null && this.props.selectedUserDefinedRecord.udef44 !== null && this.props.selectedUserDefinedRecord.udef44 !== "" ? moment(new Date(this.props.selectedUserDefinedRecord.udef44)).format("MM/DD/YYYY") : ""),
                ["udef45"]: (this.props.selectedUserDefinedRecord != null && this.props.selectedUserDefinedRecord.udef45 !== null && this.props.selectedUserDefinedRecord.udef45 !== "" ? moment(new Date(this.props.selectedUserDefinedRecord.udef45)).format("MM/DD/YYYY") : ""),
                ["udef46"]: (this.props.selectedUserDefinedRecord != null && this.props.selectedUserDefinedRecord.udef46 !== null && this.props.selectedUserDefinedRecord.udef46 !== "" ? moment(new Date(this.props.selectedUserDefinedRecord.udef46)).format("MM/DD/YYYY") : ""),
                ["udef47"]: (this.props.selectedUserDefinedRecord != null && this.props.selectedUserDefinedRecord.udef47 !== null && this.props.selectedUserDefinedRecord.udef47 !== "" ? moment(new Date(this.props.selectedUserDefinedRecord.udef47)).format("MM/DD/YYYY") : ""),
                ["udef48"]: (this.props.selectedUserDefinedRecord != null && this.props.selectedUserDefinedRecord.udef48 !== null && this.props.selectedUserDefinedRecord.udef48 !== "" ? moment(new Date(this.props.selectedUserDefinedRecord.udef48)).format("MM/DD/YYYY") : ""),
                ["udef49"]: (this.props.selectedUserDefinedRecord != null && this.props.selectedUserDefinedRecord.udef49 !== null && this.props.selectedUserDefinedRecord.udef49 !== "" ? moment(new Date(this.props.selectedUserDefinedRecord.udef49)).format("MM/DD/YYYY") : ""),
                ["udef50"]: (this.props.selectedUserDefinedRecord != null && this.props.selectedUserDefinedRecord.udef50 !== null && this.props.selectedUserDefinedRecord.udef50 !== "" ? moment(new Date(this.props.selectedUserDefinedRecord.udef50)).format("MM/DD/YYYY") : ""),
            },
            isFormDirty: this.props.isFormDirty
        });

        console.log("[userDefinedForm]:", _userDefinedForm);

        if (this.props.selectedPrimarySummary) {
            console.log("=== setFormData >> [4]");
            this.setState({
                primTopo: this.props.selectedMedicalRecord ? this.props.selectedMedicalRecord.topoCode : "",
                primSite: this.props.selectedMedicalRecord.topoCode ? this.props.selectedMedicalRecord.topoCode.substring(2) : "",
            });
        }

        if (!this.state.dAllItemFields && this.props.selectedUserDefinedRecord) {
            let fields = {};
            Object.keys(this.props.selectedUserDefinedRecord).map((t) => {
                fields[t] = "";
            });
            console.log("=== setFormData >> [5]");
            this.setState({ dAllItemFields: fields, pageMaxlengthInput: fields });
        };

        this.props.setInitialAbstractPages(false);

        return false;
    }

    saveUserDefined = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        const formItems = this.state.dAllItemFields;
        Object.keys(formItems).map((t) => {
            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let userDefinedFormData = this.state.userDefinedForm;
            userDefinedFormData.cid = this.props.selectedPrimarySummary.cid;
            this.props.saveUserDefined(userDefinedFormData);
            return false;
        }
    }

    resetUserDefined = () => {
        let fields = {};
        Object.keys(this.state.dAllItemFields).map((t) => {
            fields[t] = "";
        });
        this.setState({
            userDefinedForm: { ...this.state.initialUserDefinedForm },
            dAllItemFields: fields,
            isFormDirty: false,
        });
        return false;
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, -1);
        } else {
            this.setState({ dAllItemFields: { ...this.state.dAllItemFields, [event.target.name]: "" } });
        }

        let fName = event.target.name;
        if (fName === "udef41" || fName === "udef42" || fName === "udef43" || fName === "udef44" || fName === "udef45"
            || fName === "udef46" || fName === "udef47" || fName === "udef48" || fName === "udef49" || fName === "udef50") {
            let thisValue = event.target.value;
            let thisDate = this.User_Defined_Date_Format(thisValue.replace(/[^0-9-/]/gi, ''));
            if (thisDate != thisValue) {
                this.setState({
                    userDefinedForm: {
                        ...this.state.userDefinedForm,
                        [fName]: thisDate,//moment(new Date(thisDate)).format("MM/DD/YYYY")
                    }
                });
            }
        }
    }
    // --END

    User_Defined_Date_Format(myDate) {
        //This code tries to fill in slashes and the two digit century when a user doesn't enter them
        var fmtDate = "";

        myDate = myDate.replace(/[-]/gi, '/');

        // adding rather
        if (myDate.length > 10) {
            myDate = myDate.substr(0, 10);
        }
        //

        if ((myDate.length == 6 || myDate.length == 7 || myDate.length == 8 || myDate.length == 9) && myDate.split('/').length == 3) {
            var mm = myDate.split('/')[0];
            var dd = myDate.split('/')[1];
            var yy = myDate.split('/')[2];

            myDate = ('00' + mm).substr(mm.length, 2) + '/' + ('00' + dd).substr(dd.length, 2) + '/' + yy;
        }

        myDate = myDate.replace(/[^0-9-/]/gi, '');

        if (myDate.length == 8 && myDate.indexOf('/') == -1) {
            fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/" + myDate.substr(4, 4);
        } else if (myDate.length == 8 && myDate.indexOf('/') >= 1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(6, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 6) + "19" + myDate.substr(6, 2);
            } else {
                fmtDate = myDate.substr(0, 6) + "20" + myDate.substr(6, 2);
            }
        } else if (myDate.length == 6 && myDate.indexOf('/') == -1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(4, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/19" + myDate.substr(4, 2);
            } else {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/20" + myDate.substr(4, 2);
            }
        } else {
            fmtDate = myDate;
        }

        return fmtDate;
    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({ userDefinedForm: { ...this.state.userDefinedForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            this.setState({ userDefinedForm: { ...this.state.userDefinedForm, [event.target.name]: event.target.value }, isFormDirty: true });
        }
    }

    helpFocusingField = (field) => {
        this.setState({ fieldInFocus: field });
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isHelpVisible: false });
        return false;
    }

    render() {
        const userDefinedMaintenance = this.state.userDefinedForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">

                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} focusingField={this.state.focusingField} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.saveUserDefined} id="userDefinedForm" className="DialogForm TabParentPage">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq Prim: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo">
                                    <button type="button" onClick={this.getHelp} name="btnHelp">
                                        <img src="https://crstar.ers-can.com/images/$sys_question.png.a5image" />
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {
                                        this.props.selectedPatient.dobMonth + "/" + this.props.selectedPatient.dobDay + "/" + this.props.selectedPatient.dobYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {
                                        this.props.selectedPrimarySummary.diagnosisDateMonth + "/" + this.props.selectedPrimarySummary.diagnosisDateDay + "/" + this.props.selectedPrimarySummary.diagnosisDateYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>
                        <div className="emptyLine"></div>
                        <div className="row" id="frmUserDefinedMaintenance">
                            <div className="col-sm-12">
                                <div style={{ border: "1px solid #eee", marginBottom: "15px" }}>
                                    <Nav tabs>
                                        <NavLink className={classnames({ active: this.state.activeTab === "1" })} onClick={(e) => { this.toggleTab(e, "1") }} href="#" id="linkOfUserDefined1Tab">
                                            User Def 1
                                        </NavLink>
                                        <NavLink className={classnames({ active: this.state.activeTab === "2" })} onClick={(e) => { this.toggleTab(e, "2") }} href="#" id="linkOfUserDefined2Tab">
                                            User Def 2
                                        </NavLink>
                                    </Nav>
                                    <TabContent activeTab={this.state.activeTab}>
                                        <TabPane tabId="1">
                                            <div className="_row" style={{ display: "flex" }}>
                                                <div className="_col-md-3">
                                                    <div className="frameInner">
                                                        <div className="header">&nbsp;</div>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel1 ? this.state.userDefinedForm.udefLabel1 : "Udef 1"}
                                                            field="udef1" itemNbr="46"
                                                            value={this.state.userDefinedForm.Udef1 ? this.state.userDefinedForm.Udef1 : this.state.userDefinedForm.udef1}
                                                            dataSource="Global_Dir_Mv" aliasNo="46" titleDialog="Udef 1"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine onTopFrame"
                                                            inValid={this.state.dAllItemFields["udef1"]}
                                                            lostfocused={this.handleLostFocus} autoFocus
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef1"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel2 ? this.state.userDefinedForm.udefLabel2 : "Udef 2"}
                                                            field="udef2" itemNbr="47"
                                                            value={this.state.userDefinedForm.Udef2 ? this.state.userDefinedForm.Udef2 : this.state.userDefinedForm.udef2}
                                                            dataSource="Global_Dir_Mv" aliasNo="47" titleDialog="Udef 2"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef2"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef2"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel3 ? this.state.userDefinedForm.udefLabel3 : "Udef 3"}
                                                            field="udef3" itemNbr="48"
                                                            value={this.state.userDefinedForm.Udef3 ? this.state.userDefinedForm.Udef3 : this.state.userDefinedForm.udef3}
                                                            dataSource="Global_Dir_Mv" aliasNo="48" titleDialog="Udef 3"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef3"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef3"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel4 ? this.state.userDefinedForm.udefLabel4 : "Udef 4"}
                                                            field="udef4" itemNbr="49"
                                                            value={this.state.userDefinedForm.Udef4 ? this.state.userDefinedForm.Udef4 : this.state.userDefinedForm.udef4}
                                                            dataSource="Global_Dir_Mv" aliasNo="49" titleDialog="Udef 4"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef4"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef4"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel5 ? this.state.userDefinedForm.udefLabel5 : "Udef 5"}
                                                            field="udef5" itemNbr="50"
                                                            value={this.state.userDefinedForm.Udef5 ? this.state.userDefinedForm.Udef5 : this.state.userDefinedForm.udef5}
                                                            dataSource="Global_Dir_Mv" aliasNo="50" titleDialog="Udef 5"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef5"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef5"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel6 ? this.state.userDefinedForm.udefLabel6 : "Udef 6"}
                                                            field="udef6" itemNbr="377"
                                                            value={this.state.userDefinedForm.Udef6 ? this.state.userDefinedForm.Udef6 : this.state.userDefinedForm.udef6}
                                                            dataSource="Global_Dir_Mv" aliasNo="377" titleDialog="Udef 6"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef6"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef6"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel7 ? this.state.userDefinedForm.udefLabel7 : "Udef 7"}
                                                            field="udef7" itemNbr="378"
                                                            value={this.state.userDefinedForm.Udef7 ? this.state.userDefinedForm.Udef7 : this.state.userDefinedForm.udef7}
                                                            dataSource="Global_Dir_Mv" aliasNo="378" titleDialog="Udef 7"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef7"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef7"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel8 ? this.state.userDefinedForm.udefLabel8 : "Udef 8"}
                                                            field="udef8" itemNbr="379"
                                                            value={this.state.userDefinedForm.Udef8 ? this.state.userDefinedForm.Udef8 : this.state.userDefinedForm.udef8}
                                                            dataSource="Global_Dir_Mv" aliasNo="379" titleDialog="Udef 8"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef8"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef8"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel9 ? this.state.userDefinedForm.udefLabel9 : "Udef 9"}
                                                            field="udef9" itemNbr="380"
                                                            value={this.state.userDefinedForm.Udef9 ? this.state.userDefinedForm.Udef9 : this.state.userDefinedForm.udef9}
                                                            dataSource="Global_Dir_Mv" aliasNo="380" titleDialog="Udef 9"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef9"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef9"] : ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="_col-md-2 _extendDiv">
                                                    <div className="frameInner">
                                                        <div className="header">&nbsp;</div>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel10 ? this.state.userDefinedForm.udefLabel10 : "Udef 10"}
                                                            field="udef10" itemNbr="381"
                                                            value={this.state.userDefinedForm.Udef10 ? this.state.userDefinedForm.Udef10 : this.state.userDefinedForm.udef10}
                                                            dataSource="Global_Dir_Mv" aliasNo="381" titleDialog="Udef 10"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine onTopFrame"
                                                            inValid={this.state.dAllItemFields["udef10"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef10"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel11 ? this.state.userDefinedForm.udefLabel11 : "Udef 11"}
                                                            field="udef11" itemNbr="382"
                                                            value={this.state.userDefinedForm.Udef11 ? this.state.userDefinedForm.Udef11 : this.state.userDefinedForm.udef11}
                                                            dataSource="Global_Dir_Mv" aliasNo="382" titleDialog="Udef 11"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef11"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef11"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel12 ? this.state.userDefinedForm.udefLabel12 : "Udef 12"}
                                                            field="udef12" itemNbr="383"
                                                            value={this.state.userDefinedForm.Udef12 ? this.state.userDefinedForm.Udef12 : this.state.userDefinedForm.udef12}
                                                            dataSource="Global_Dir_Mv" aliasNo="383" titleDialog="Udef 12"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef12"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef12"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel13 ? this.state.userDefinedForm.udefLabel13 : "Udef 13"}
                                                            field="udef13" itemNbr="384"
                                                            value={this.state.userDefinedForm.Udef13 ? this.state.userDefinedForm.Udef13 : this.state.userDefinedForm.udef13}
                                                            dataSource="Global_Dir_Mv" aliasNo="384" titleDialog="Udef 13"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef13"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef13"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel14 ? this.state.userDefinedForm.udefLabel14 : "Udef 14"}
                                                            field="udef14" itemNbr="385"
                                                            value={this.state.userDefinedForm.Udef14 ? this.state.userDefinedForm.Udef14 : this.state.userDefinedForm.udef14}
                                                            dataSource="Global_Dir_Mv" aliasNo="385" titleDialog="Udef 14"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef14"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef14"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel15 ? this.state.userDefinedForm.udefLabel15 : "Udef 15"}
                                                            field="udef15" itemNbr="386"
                                                            value={this.state.userDefinedForm.Udef15 ? this.state.userDefinedForm.Udef15 : this.state.userDefinedForm.udef15}
                                                            dataSource="Global_Dir_Mv" aliasNo="386" titleDialog="Udef 10"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef15"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef15"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel16 ? this.state.userDefinedForm.udefLabel16 : "Udef 16"}
                                                            field="udef16" itemNbr="1782"
                                                            value={this.state.userDefinedForm.Udef16 ? this.state.userDefinedForm.Udef16 : this.state.userDefinedForm.udef16}
                                                            dataSource="Global_Dir_Mv" aliasNo="1782" titleDialog="Udef 16"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef16"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef16"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel17 ? this.state.userDefinedForm.udefLabel17 : "Udef 17"}
                                                            field="udef17" itemNbr="1783"
                                                            value={this.state.userDefinedForm.Udef17 ? this.state.userDefinedForm.Udef17 : this.state.userDefinedForm.udef17}
                                                            dataSource="Global_Dir_Mv" aliasNo="1783" titleDialog="Udef 17"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef17"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef17"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel18 ? this.state.userDefinedForm.udefLabel18 : "Udef 18"}
                                                            field="udef18" itemNbr="1784"
                                                            value={this.state.userDefinedForm.Udef18 ? this.state.userDefinedForm.Udef18 : this.state.userDefinedForm.udef18}
                                                            dataSource="Global_Dir_Mv" aliasNo="1784" titleDialog="Udef 18"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef18"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef18"] : ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="_col-md-2 _extendRightDiv">
                                                    <div className="frameInner">
                                                        <div className="header">&nbsp;</div>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel19 ? this.state.userDefinedForm.udefLabel19 : "Udef 19"}
                                                            field="udef19" itemNbr="1785"
                                                            value={this.state.userDefinedForm.Udef19 ? this.state.userDefinedForm.Udef19 : this.state.userDefinedForm.udef19}
                                                            dataSource="Global_Dir_Mv" aliasNo="1785" titleDialog="Udef 19"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine onTopFrame"
                                                            inValid={this.state.dAllItemFields["udef19"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef19"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel20 ? this.state.userDefinedForm.udefLabel20 : "Udef 20"}
                                                            field="udef20" itemNbr="1786"
                                                            value={this.state.userDefinedForm.Udef20 ? this.state.userDefinedForm.Udef20 : this.state.userDefinedForm.udef20}
                                                            dataSource="Global_Dir_Mv" aliasNo="1786" titleDialog="Udef 20"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef20"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef20"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel21 ? this.state.userDefinedForm.udefLabel21 : "Udef 21"}
                                                            field="udef21" itemNbr="1787"
                                                            value={this.state.userDefinedForm.Udef21 ? this.state.userDefinedForm.Udef21 : this.state.userDefinedForm.udef21}
                                                            dataSource="Global_Dir_Mv" aliasNo="1787" titleDialog="Udef 21"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef21"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef21"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel22 ? this.state.userDefinedForm.udefLabel22 : "Udef 22"}
                                                            field="udef22" itemNbr="1788"
                                                            value={this.state.userDefinedForm.Udef22 ? this.state.userDefinedForm.Udef22 : this.state.userDefinedForm.udef22}
                                                            dataSource="Global_Dir_Mv" aliasNo="1788" titleDialog="Udef 22"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef22"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef22"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel23 ? this.state.userDefinedForm.udefLabel23 : "Udef 23"}
                                                            field="udef23" itemNbr="1789"
                                                            value={this.state.userDefinedForm.Udef23 ? this.state.userDefinedForm.Udef23 : this.state.userDefinedForm.udef23}
                                                            dataSource="Global_Dir_Mv" aliasNo="1789" titleDialog="Udef 23"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef23"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef23"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel24 ? this.state.userDefinedForm.udefLabel24 : "Udef 24"}
                                                            field="udef24" itemNbr="1790"
                                                            value={this.state.userDefinedForm.Udef24 ? this.state.userDefinedForm.Udef24 : this.state.userDefinedForm.udef24}
                                                            dataSource="Global_Dir_Mv" aliasNo="1790" titleDialog="Udef 24"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef24"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef24"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel25 ? this.state.userDefinedForm.udefLabel25 : "Udef 25"}
                                                            field="udef25" itemNbr="1791"
                                                            value={this.state.userDefinedForm.Udef25 ? this.state.userDefinedForm.Udef25 : this.state.userDefinedForm.udef25}
                                                            dataSource="Global_Dir_Mv" aliasNo="1791" titleDialog="Udef 25"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef25"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef25"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel26 ? this.state.userDefinedForm.udefLabel26 : "Udef 26"}
                                                            field="udef26" itemNbr="1792"
                                                            value={this.state.userDefinedForm.Udef26 ? this.state.userDefinedForm.Udef26 : this.state.userDefinedForm.udef26}
                                                            dataSource="Global_Dir_Mv" aliasNo="1792" titleDialog="Udef 26"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef26"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef26"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel27 ? this.state.userDefinedForm.udefLabel27 : "Udef 27"}
                                                            field="udef27" itemNbr="1793"
                                                            value={this.state.userDefinedForm.Udef27 ? this.state.userDefinedForm.Udef27 : this.state.userDefinedForm.udef27}
                                                            dataSource="Global_Dir_Mv" aliasNo="1793" titleDialog="Udef 27"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef27"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef27"] : ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="_col-md-2 _extendRightDiv">
                                                    <div className="frameInner">
                                                        <div className="header">&nbsp;</div>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel28 ? this.state.userDefinedForm.udefLabel28 : "Udef 28"}
                                                            field="udef28" itemNbr="1794"
                                                            value={this.state.userDefinedForm.Udef28 ? this.state.userDefinedForm.Udef28 : this.state.userDefinedForm.udef28}
                                                            dataSource="Global_Dir_Mv" aliasNo="1794" titleDialog="Udef 28"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine onTopFrame"
                                                            inValid={this.state.dAllItemFields["udef28"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef28"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel29 ? this.state.userDefinedForm.udefLabel29 : "Udef 29"}
                                                            field="udef29" itemNbr="1795"
                                                            value={this.state.userDefinedForm.Udef29 ? this.state.userDefinedForm.Udef29 : this.state.userDefinedForm.udef29}
                                                            dataSource="Global_Dir_Mv" aliasNo="1795" titleDialog="Udef 29"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef29"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef29"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel30 ? this.state.userDefinedForm.udefLabel30 : "Udef 30"}
                                                            field="udef30" itemNbr="1796"
                                                            value={this.state.userDefinedForm.Udef30 ? this.state.userDefinedForm.Udef30 : this.state.userDefinedForm.udef30}
                                                            dataSource="Global_Dir_Mv" aliasNo="1796" titleDialog="Udef 30"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef30"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef30"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel31 ? this.state.userDefinedForm.udefLabel31 : "Udef 31"}
                                                            field="udef31" itemNbr="1797"
                                                            value={this.state.userDefinedForm.Udef31 ? this.state.userDefinedForm.Udef31 : this.state.userDefinedForm.udef31}
                                                            dataSource="Global_Dir_Mv" aliasNo="1797" titleDialog="Udef 31"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef31"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef31"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel32 ? this.state.userDefinedForm.udefLabel32 : "Udef 32"}
                                                            field="udef32" itemNbr="1798"
                                                            value={this.state.userDefinedForm.Udef32 ? this.state.userDefinedForm.Udef32 : this.state.userDefinedForm.udef32}
                                                            dataSource="Global_Dir_Mv" aliasNo="1798" titleDialog="Udef 32"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef32"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef32"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel33 ? this.state.userDefinedForm.udefLabel33 : "Udef 33"}
                                                            field="udef33" itemNbr="1799"
                                                            value={this.state.userDefinedForm.Udef33 ? this.state.userDefinedForm.Udef33 : this.state.userDefinedForm.udef33}
                                                            dataSource="Global_Dir_Mv" aliasNo="1799" titleDialog="Udef 33"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef33"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef33"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel34 ? this.state.userDefinedForm.udefLabel34 : "Udef 34"}
                                                            field="udef34" itemNbr="1800"
                                                            value={this.state.userDefinedForm.Udef34 ? this.state.userDefinedForm.Udef34 : this.state.userDefinedForm.udef34}
                                                            dataSource="Global_Dir_Mv" aliasNo="1800" titleDialog="Udef 34"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef34"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef34"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel35 ? this.state.userDefinedForm.udefLabel35 : "Udef 35"}
                                                            field="udef35" itemNbr="1801"
                                                            value={this.state.userDefinedForm.Udef35 ? this.state.userDefinedForm.Udef35 : this.state.userDefinedForm.udef35}
                                                            dataSource="Global_Dir_Mv" aliasNo="1801" titleDialog="Udef 35"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef35"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef35"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel36 ? this.state.userDefinedForm.udefLabel36 : "Udef 36"}
                                                            field="udef36" itemNbr="1802"
                                                            value={this.state.userDefinedForm.Udef36 ? this.state.userDefinedForm.Udef36 : this.state.userDefinedForm.udef36}
                                                            dataSource="Global_Dir_Mv" aliasNo="1802" titleDialog="Udef 36"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef36"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef36"] : ""}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="_col-md-3">
                                                    <div className="frameInner">
                                                        <div className="header">&nbsp;</div>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel37 ? this.state.userDefinedForm.udefLabel37 : "Udef 37"}
                                                            field="udef37" itemNbr="1803"
                                                            value={this.state.userDefinedForm.Udef37 ? this.state.userDefinedForm.Udef37 : this.state.userDefinedForm.udef37}
                                                            dataSource="Global_Dir_Mv" aliasNo="1803" titleDialog="Udef 37"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine onTopFrame"
                                                            inValid={this.state.dAllItemFields["udef37"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef37"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel38 ? this.state.userDefinedForm.udefLabel38 : "Udef 38"}
                                                            field="udef38" itemNbr="1804"
                                                            value={this.state.userDefinedForm.Udef38 ? this.state.userDefinedForm.Udef38 : this.state.userDefinedForm.udef38}
                                                            dataSource="Global_Dir_Mv" aliasNo="1804" titleDialog="Udef 38"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef38"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef38"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel39 ? this.state.userDefinedForm.udefLabel39 : "Udef 39"}
                                                            field="udef39" itemNbr="1805"
                                                            value={this.state.userDefinedForm.Udef39 ? this.state.userDefinedForm.Udef39 : this.state.userDefinedForm.udef39}
                                                            dataSource="Global_Dir_Mv" aliasNo="1805" titleDialog="Udef 39"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef39"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef39"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel40 ? this.state.userDefinedForm.udefLabel40 : "Udef 40"}
                                                            field="udef40" itemNbr="1806"
                                                            value={this.state.userDefinedForm.Udef40 ? this.state.userDefinedForm.Udef40 : this.state.userDefinedForm.udef40}
                                                            dataSource="Global_Dir_Mv" aliasNo="1806" titleDialog="Udef 40"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef40"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef40"] : ""}
                                                        />

                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel41 ? this.state.userDefinedForm.udefLabel41 : "Udef 41"}
                                                            field="udef41"
                                                            value={this.state.userDefinedForm.udef41}
                                                            maxLength="10" labelWidth="120px" width="95px"
                                                            lineStyle="oneLine" aliasNo="1807" itemNbr="1807"
                                                            lostfocused={this.handleLostFocus}
                                                            placeholder="mm/dd/yyyy" acceptedFutureDate={true}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel42 ? this.state.userDefinedForm.udefLabel42 : "Udef 42"}
                                                            field="udef42"
                                                            value={this.state.userDefinedForm.udef42}
                                                            maxLength="10" labelWidth="120px" width="95px"
                                                            lineStyle="oneLine" aliasNo="1808" itemNbr="1808"
                                                            lostfocused={this.handleLostFocus}
                                                            placeholder="mm/dd/yyyy" acceptedFutureDate={true}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel43 ? this.state.userDefinedForm.udefLabel43 : "Udef 43"}
                                                            field="udef43"
                                                            value={this.state.userDefinedForm.udef43}
                                                            maxLength="10" labelWidth="120px" width="95px"
                                                            lineStyle="oneLine" aliasNo="1809" itemNbr="1809"
                                                            lostfocused={this.handleLostFocus}
                                                            placeholder="mm/dd/yyyy" acceptedFutureDate={true}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel44 ? this.state.userDefinedForm.udefLabel44 : "Udef 44"}
                                                            field="udef44"
                                                            value={this.state.userDefinedForm.udef44}
                                                            maxLength="10" labelWidth="120px" width="95px"
                                                            lineStyle="oneLine" aliasNo="1810" itemNbr="1810"
                                                            lostfocused={this.handleLostFocus}
                                                            placeholder="mm/dd/yyyy" acceptedFutureDate={true}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel45 ? this.state.userDefinedForm.udefLabel45 : "Udef 45"}
                                                            field="udef45"
                                                            value={this.state.userDefinedForm.udef45}
                                                            maxLength="10" labelWidth="120px" width="95px"
                                                            lineStyle="oneLine" aliasNo="1811" itemNbr="1811"
                                                            lostfocused={this.handleLostFocus}
                                                            placeholder="mm/dd/yyyy" acceptedFutureDate={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="frameInner">
                                                        <div className="header">&nbsp;</div>
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel46 ? this.state.userDefinedForm.udefLabel46 : "Udef 46"}
                                                            field="udef46"
                                                            value={this.state.userDefinedForm.udef46}
                                                            maxLength="10" labelWidth="120px" width="100px"
                                                            lineStyle="oneLine onTopFrame" aliasNo="1812" itemNbr="1812"
                                                            lostfocused={this.handleLostFocus}
                                                            placeholder="mm/dd/yyyy" acceptedFutureDate={true}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel47 ? this.state.userDefinedForm.udefLabel47 : "Udef 47"}
                                                            field="udef47"
                                                            value={this.state.userDefinedForm.udef47}
                                                            maxLength="10" labelWidth="120px" width="100px"
                                                            lineStyle="oneLine" aliasNo="1813" itemNbr="1813"
                                                            lostfocused={this.handleLostFocus}
                                                            placeholder="mm/dd/yyyy" acceptedFutureDate={true}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel48 ? this.state.userDefinedForm.udefLabel48 : "Udef 48"}
                                                            field="udef48"
                                                            value={this.state.userDefinedForm.udef48}
                                                            maxLength="10" labelWidth="120px" width="100px"
                                                            lineStyle="oneLine" aliasNo="1814" itemNbr="1814"
                                                            lostfocused={this.handleLostFocus}
                                                            placeholder="mm/dd/yyyy" acceptedFutureDate={true}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel49 ? this.state.userDefinedForm.udefLabel49 : "Udef 49"}
                                                            field="udef49"
                                                            value={this.state.userDefinedForm.udef49}
                                                            maxLength="10" labelWidth="120px" width="100px"
                                                            lineStyle="oneLine" aliasNo="1815" itemNbr="1815"
                                                            lostfocused={this.handleLostFocus}
                                                            placeholder="mm/dd/yyyy" acceptedFutureDate={true}
                                                        />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel50 ? this.state.userDefinedForm.udefLabel50 : "Udef 50"}
                                                            field="udef50"
                                                            value={this.state.userDefinedForm.udef50}
                                                            maxLength="10" labelWidth="120px" width="100px"
                                                            lineStyle="oneLine" aliasNo="1816" itemNbr="1816"
                                                            lostfocused={this.handleLostFocus}
                                                            placeholder="mm/dd/yyyy" acceptedFutureDate={true}
                                                        />

                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel51 ? this.state.userDefinedForm.udefLabel51 : "Udef 51"}
                                                            field="udef51" itemNbr="3106"
                                                            value={this.state.userDefinedForm.Udef51 ? this.state.userDefinedForm.Udef51 : this.state.userDefinedForm.udef51}
                                                            dataSource="Global_Dir_Mv" aliasNo="3106" titleDialog="Udef 51"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef51"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef51"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel52 ? this.state.userDefinedForm.udefLabel52 : "Udef 52"}
                                                            field="udef52" itemNbr="3107"
                                                            value={this.state.userDefinedForm.Udef52 ? this.state.userDefinedForm.Udef52 : this.state.userDefinedForm.udef52}
                                                            dataSource="Global_Dir_Mv" aliasNo="3107" titleDialog="Udef 52"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef52"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef52"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel53 ? this.state.userDefinedForm.udefLabel53 : "Udef 53"}
                                                            field="udef53" itemNbr="3108"
                                                            value={this.state.userDefinedForm.Udef53 ? this.state.userDefinedForm.Udef53 : this.state.userDefinedForm.udef53}
                                                            dataSource="Global_Dir_Mv" aliasNo="3108" titleDialog="Udef 53"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef53"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef53"] : ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 extendDiv">
                                                    <div className="frameInner">
                                                        <div className="header">&nbsp;</div>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel54 ? this.state.userDefinedForm.udefLabel54 : "Udef 54"}
                                                            field="udef54" itemNbr="3109"
                                                            value={this.state.userDefinedForm.Udef54 ? this.state.userDefinedForm.Udef54 : this.state.userDefinedForm.udef54}
                                                            dataSource="Global_Dir_Mv" aliasNo="3109" titleDialog="Udef 54"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine onTopFrame"
                                                            inValid={this.state.dAllItemFields["udef54"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef54"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel55 ? this.state.userDefinedForm.udefLabel55 : "Udef 55"}
                                                            field="udef55" itemNbr="3110"
                                                            value={this.state.userDefinedForm.Udef55 ? this.state.userDefinedForm.Udef55 : this.state.userDefinedForm.udef55}
                                                            dataSource="Global_Dir_Mv" aliasNo="3110" titleDialog="Udef 55"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef55"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef55"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel56 ? this.state.userDefinedForm.udefLabel56 : "Udef 56"}
                                                            field="udef56" itemNbr="3111"
                                                            value={this.state.userDefinedForm.Udef56 ? this.state.userDefinedForm.Udef56 : this.state.userDefinedForm.udef56}
                                                            dataSource="Global_Dir_Mv" aliasNo="3111" titleDialog="Udef 56"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef56"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef56"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel57 ? this.state.userDefinedForm.udefLabel57 : "Udef 57"}
                                                            field="udef57" itemNbr="3112"
                                                            value={this.state.userDefinedForm.Udef57 ? this.state.userDefinedForm.Udef57 : this.state.userDefinedForm.udef57}
                                                            dataSource="Global_Dir_Mv" aliasNo="3112" titleDialog="Udef 57"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef57"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef57"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel58 ? this.state.userDefinedForm.udefLabel58 : "Udef 58"}
                                                            field="udef58" itemNbr="3113"
                                                            value={this.state.userDefinedForm.Udef58 ? this.state.userDefinedForm.Udef58 : this.state.userDefinedForm.udef58}
                                                            dataSource="Global_Dir_Mv" aliasNo="3113" titleDialog="Udef 58"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef58"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef58"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel59 ? this.state.userDefinedForm.udefLabel59 : "Udef 59"}
                                                            field="udef59" itemNbr="3114"
                                                            value={this.state.userDefinedForm.Udef59 ? this.state.userDefinedForm.Udef59 : this.state.userDefinedForm.udef59}
                                                            dataSource="Global_Dir_Mv" aliasNo="3114" titleDialog="Udef 59"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef59"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef59"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel60 ? this.state.userDefinedForm.udefLabel60 : "Udef 60"}
                                                            field="udef60" itemNbr="3115"
                                                            value={this.state.userDefinedForm.Udef60 ? this.state.userDefinedForm.Udef60 : this.state.userDefinedForm.udef60}
                                                            dataSource="Global_Dir_Mv" aliasNo="3115" titleDialog="Udef 60"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef60"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef60"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel61 ? this.state.userDefinedForm.udefLabel61 : "Udef 61"}
                                                            field="udef61" itemNbr="3116"
                                                            value={this.state.userDefinedForm.Udef61 ? this.state.userDefinedForm.Udef61 : this.state.userDefinedForm.udef61}
                                                            dataSource="Global_Dir_Mv" aliasNo="3116" titleDialog="Udef 61"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef61"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef61"] : ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 extendRightDiv">
                                                    <div className="frameInner">
                                                        <div className="header">&nbsp;</div>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel62 ? this.state.userDefinedForm.udefLabel62 : "Udef 62"}
                                                            field="udef62" itemNbr="3117"
                                                            value={this.state.userDefinedForm.Udef62 ? this.state.userDefinedForm.Udef62 : this.state.userDefinedForm.udef62}
                                                            dataSource="Global_Dir_Mv" aliasNo="3117" titleDialog="Udef 62"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine onTopFrame"
                                                            inValid={this.state.dAllItemFields["udef62"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef62"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel63 ? this.state.userDefinedForm.udefLabel63 : "Udef 63"}
                                                            field="udef63" itemNbr="3118"
                                                            value={this.state.userDefinedForm.Udef63 ? this.state.userDefinedForm.Udef63 : this.state.userDefinedForm.udef63}
                                                            dataSource="Global_Dir_Mv" aliasNo="3118" titleDialog="Udef 63"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef63"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef63"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel64 ? this.state.userDefinedForm.udefLabel64 : "Udef 64"}
                                                            field="udef64" itemNbr="3119"
                                                            value={this.state.userDefinedForm.Udef64 ? this.state.userDefinedForm.Udef64 : this.state.userDefinedForm.udef64}
                                                            dataSource="Global_Dir_Mv" aliasNo="3119" titleDialog="Udef 64"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef64"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef64"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel65 ? this.state.userDefinedForm.udefLabel65 : "Udef 65"}
                                                            field="udef65" itemNbr="3120"
                                                            value={this.state.userDefinedForm.Udef65 ? this.state.userDefinedForm.Udef65 : this.state.userDefinedForm.udef65}
                                                            dataSource="Global_Dir_Mv" aliasNo="3120" titleDialog="Udef 65"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef65"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef65"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel66 ? this.state.userDefinedForm.udefLabel66 : "Udef 66"}
                                                            field="udef66" itemNbr="3121"
                                                            value={this.state.userDefinedForm.Udef66 ? this.state.userDefinedForm.Udef66 : this.state.userDefinedForm.udef66}
                                                            dataSource="Global_Dir_Mv" aliasNo="3121" titleDialog="Udef 66"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef66"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef66"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel67 ? this.state.userDefinedForm.udefLabel67 : "Udef 67"}
                                                            field="udef67" itemNbr="3122"
                                                            value={this.state.userDefinedForm.Udef67 ? this.state.userDefinedForm.Udef67 : this.state.userDefinedForm.udef67}
                                                            dataSource="Global_Dir_Mv" aliasNo="3122" titleDialog="Udef 67"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef67"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef67"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel68 ? this.state.userDefinedForm.udefLabel68 : "Udef 68"}
                                                            field="udef68" itemNbr="3123"
                                                            value={this.state.userDefinedForm.Udef68 ? this.state.userDefinedForm.Udef68 : this.state.userDefinedForm.udef68}
                                                            dataSource="Global_Dir_Mv" aliasNo="3123" titleDialog="Udef 68"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef68"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef68"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel69 ? this.state.userDefinedForm.udefLabel69 : "Udef 69"}
                                                            field="udef69" itemNbr="3124"
                                                            value={this.state.userDefinedForm.Udef69 ? this.state.userDefinedForm.Udef69 : this.state.userDefinedForm.udef69}
                                                            dataSource="Global_Dir_Mv" aliasNo="3124" titleDialog="Udef 69"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef69"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef69"] : ""}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="frameInner">
                                                        <div className="header">&nbsp;</div>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel70 ? this.state.userDefinedForm.udefLabel70 : "Udef 70"}
                                                            field="udef70" itemNbr="3125"
                                                            value={this.state.userDefinedForm.Udef70 ? this.state.userDefinedForm.Udef70 : this.state.userDefinedForm.udef70}
                                                            dataSource="Global_Dir_Mv" aliasNo="3125" titleDialog="Udef 70"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine onTopFrame"
                                                            inValid={this.state.dAllItemFields["udef70"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef70"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel71 ? this.state.userDefinedForm.udefLabel71 : "Udef 71"}
                                                            field="udef71" itemNbr="3126"
                                                            value={this.state.userDefinedForm.Udef71 ? this.state.userDefinedForm.Udef71 : this.state.userDefinedForm.udef71}
                                                            dataSource="Global_Dir_Mv" aliasNo="3126" titleDialog="Udef 71"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef71"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef71"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel72 ? this.state.userDefinedForm.udefLabel72 : "Udef 72"}
                                                            field="udef72" itemNbr="3127"
                                                            value={this.state.userDefinedForm.Udef72 ? this.state.userDefinedForm.Udef72 : this.state.userDefinedForm.udef72}
                                                            dataSource="Global_Dir_Mv" aliasNo="3127" titleDialog="Udef 72"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef72"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef72"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel73 ? this.state.userDefinedForm.udefLabel73 : "Udef 73"}
                                                            field="udef73" itemNbr="3128"
                                                            value={this.state.userDefinedForm.Udef73 ? this.state.userDefinedForm.Udef73 : this.state.userDefinedForm.udef73}
                                                            dataSource="Global_Dir_Mv" aliasNo="3128" titleDialog="Udef 73"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef73"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef73"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel74 ? this.state.userDefinedForm.udefLabel74 : "Udef 74"}
                                                            field="udef74" itemNbr="3129"
                                                            value={this.state.userDefinedForm.Udef74 ? this.state.userDefinedForm.Udef74 : this.state.userDefinedForm.udef74}
                                                            dataSource="Global_Dir_Mv" aliasNo="3129" titleDialog="Udef 74"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef74"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef74"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.userDefinedForm.udefLabel75 ? this.state.userDefinedForm.udefLabel75 : "Udef 75"}
                                                            field="udef75" itemNbr="3130"
                                                            value={this.state.userDefinedForm.Udef75 ? this.state.userDefinedForm.Udef75 : this.state.userDefinedForm.udef75}
                                                            dataSource="Global_Dir_Mv" aliasNo="3130" titleDialog="Udef 75"
                                                            labelWidth="120px" width="80px" lineStyle="oneLine"
                                                            inValid={this.state.dAllItemFields["udef75"]}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["udef75"] : ""}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </div>
                        </div>
                    </form>

                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetUserDefined} formId="userDefinedForm"
                        isFormDirty={this.state.isFormDirty}
                        showExtra={[{ name: "Text", action: "showDiagnosisText" }]}
                        callbackToParent={this.updateSaveFlagToProcess}
                        focusingField={this.state.focusingField}
                    />

                </div>
            )
            : <p className="data_loading_page">User Defined data loading...</p>;
        return (
            <React.Fragment>
                {userDefinedMaintenance}

                <div id="textareaEditorForItemsCls">
                    {
                        this.props.isShowTextDialogVisible ?
                            /*
                            <Dialog
                                title="Diagnosis Text &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Treatment &amp; Other Text"
                                onClose={this.props.closeTextDialog}
                                width={950} height={"auto"}
                                className={classes.title}>*/
                            <div className="CRStar_Window">
                                <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar">
                                    <div className="dragableWindowCls">
                                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                                            <div className="k-window-title k-dialog-title">
                                                <pre className="textPopupPreTitleCls">{TextPopup.Title}</pre>
                                            </div>
                                            <div className="k-window-actions k-dialog-actions">
                                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeTextDialog(e) }}></span>
                                                </a>
                                            </div>
                                        </div>
                                        <TextForm closeWindow={this.closeTextDialog} setFocusingField={this.helpFocusingField} />
                                    </div>
                                    {/*</Dialog>*/}
                                </Draggable>
                            </div>
                            : null
                    }
                </div>

            </React.Fragment>
        );
    }
}

UserDefinedMaintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        isPatientLoading: state.patientAbstract.isPatientLoading,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
        selectedPatient: state.patientAbstract.selectedPatient,
        isShowTextDialogVisible: state.patientAbstract.isShowTextDialogVisible,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        selectedUserDefinedRecord: state.patientAbstract.selectedUserDefinedRecord,
        //
        clientgroup: state.auth.clientgroup,
        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,

        initialAbstractPages: state.patientAbstract.initialAbstractPages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveUserDefined: (formData) => dispatch(actionCreators.saveUserDefined(formData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index) => dispatch(actionCreators.checkValidInput(field, value, table, key, index)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),

        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDefinedMaintenance);