import React, { Component } from "react";
import { connect } from "react-redux";
import AdhocReport from "./Tabs/ListPop";
import "./AdhocReportsStyle.css";
import { actionCreators } from "../../../store/PatientAbstract";

class ReaAdhocReports extends Component {

    componentDidMount() {
        this.props.selectAPopLabel("");
    }

    render() {
        let combineTwoScreen = <div id="mainViewReporting" className={this.props.initialPageVisible ? "" : "hidden"}>
            {/*}
                                    <div id="selectAPopView">
                                        <SelectAPop showToggleBtn={true} />
                                    </div>
            {*/}
            <div id="graphAnItemView">
                <AdhocReport />
            </div>
        </div>;
        return (
            <React.Fragment>
                {combineTwoScreen}
            </React.Fragment>
        );
    }
}

ReaAdhocReports.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        initialPageVisible: state.patientAbstract.initialPageVisible,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // Crstar Reporting Menu
        selectAPopLabel: (label) => dispatch(actionCreators.selectAPopLabel(label)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReaAdhocReports);