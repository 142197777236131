/* eslint-disable */
import React from "react";
import "../../../assets/css/default-ocean-blue.css";

import { EditorTools, EditorToolsSettings } from "@progress/kendo-react-editor";

const fontSizeToolSettings = {
    ...EditorToolsSettings.fontSize,
    defaultItem: {
        text: '2 (10pt)',
        value: '10pt',
    },
    items: [
        {
            text: '1 (8pt)',
            value: '8pt',
            style: { fontSize: "8pt" }
        },
        {
            text: '2 (10pt)',
            value: '10pt',
            style: { fontSize: "10pt" }
        },
        {
            text: '3 (12pt)',
            value: '12pt',
            style: { fontSize: "12pt" }
        },
        {
            text: '4 (14pt)',
            value: '14pt',
            style: { fontSize: "14pt" }
        },
        {
            text: '5 (18pt)',
            value: '18pt',
            style: { fontSize: "18pt" }
        },
        {
            text: '6 (24pt)',
            value: '24pt',
            style: { fontSize: "24pt" }
        },
        {
            text: '7 (36pt)',
            value: '36pt',
            style: { fontSize: "36pt" }
        }
    ],
};

const CustomFontSize = EditorTools.createStyleDropDownList(fontSizeToolSettings);

const FontSize = (props) => <CustomFontSize {...props} />;

export default FontSize;