/* eslint-disable */
import React, { Component } from "react";
import Draggable from 'react-draggable';
import { connect } from "react-redux";
import Input from "../../../components/UI/Input";
import axios from "../../../axios-instance";
import "./ShowOrHideBtnCancel.css";
import ConfigData from '../../../store/config.json';
import * as actions from '../../../store/actions/index';
import { actionCreators } from "../../../store/PatientAbstract";

class GeneditsCheck extends Component {

    state = {
        loaded: false,
        state: null,
        fieldInFocus: "gcState0",
        focusingField: null,
        selectedMetafile: "stateMetafile",
        iframeIndex: 0,
    }

    closeWindow = async (e) => {
        try {
            e ? e.preventDefault() : "";
        } catch (e) { }
        this.props.closeWindow();

        this.props.setInitialAbstractPages(true);

        var _this = this;
        await setTimeout(function () {
            //var tab = _this.props.showingTabName;
            var tab = document.getElementsByClassName("tab-list-item tab-list-active") ? document.getElementsByClassName("tab-list-item tab-list-active")[0].innerHTML : "";

            // Starting all the Abstarct pages
            if (tab === "Patient") {
                _this.props.selectPatient(_this.props.patientId, false, true);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "Diagnosis") {
                _this.props.selectMedicalRecord(_this.props.selectedPrimarySummary, _this.props.patientId);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "Staging") {
                _this.props.selectStagingRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "Treatment") {
                _this.props.selectTreatmentRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "Outcomes") {
                _this.props.selectOutcomesRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "CPM") {
                _this.props.selectCocRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "BPM") {
                _this.props.selectNapbcRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "RPM") {
                _this.props.selectRectalProgramRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "Case Admin") {
                _this.props.selectCaseAdminRecord(_this.props.selectedPrimarySummary, _this.props.loggedOnUid);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "Clinical") {
                _this.props.selectClinicalRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "Research") {
                _this.props.selectResearchRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "User Defined") {
                _this.props.selectUserDefinedRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "State Specific") {
                //check if whether current page is NM State Screen? ...to get data correctly
                const state = _this.props.currentShowingState;
                if (state === "NM") {
                    _this.props.selectStateFieldsRecord(_this.props.selectedPrimarySummary, "Nm");
                } else {
                    _this.props.selectStateFieldsRecord(_this.props.selectedPrimarySummary, "");
                }
                _this.props.setInitialAbstractPages(true);
            } else if (tab === "Retired") {
                _this.props.selectRetiredRecord(_this.props.selectedPrimarySummary);
                _this.props.setInitialAbstractPages(true);
            }/* Ending of Abstract pages list */

            /*
            // clear all styling invalid input...
            var allInputFields = document.getElementsByClassName("error");
            if (allInputFields) {
                for (var i = 0; i < allInputFields.length; i++) {
                    allInputFields[i].className = "";
                }
            }
            */
        });

        return false;
    }

    componentDidMount() {
        if (document.getElementById("ReferStateOfSelectedHospital")) {
            var _state = document.querySelector(".hospitalListOpt") ? document.querySelector(".hospitalListOpt").selectedOptions[0].getAttribute("data-state") : "";
            var _this = this;
            setTimeout(function () {
                if (document.getElementById("gcState0")) {
                    document.getElementById("gcState0").value = _state;
                    _this.setState({ state: _state, checkingReport: true });
                }
            }, 0);
        }
    }

    handleFormChange = (event) => {
        const value = event.target.value;
        this.setState({ state: value });
        //let url = this.props.clientgroup + "/UpdateUserSettings?usrid=" + this.props.userId + "&state=" + value;
        let url = this.props.clientgroup + "/UpdateUserSettings";
        let params = { usrid: this.props.userId, state: value, accessToken: localStorage.getItem('token') };

        var _this = this;
        axios.post(url, params)
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) { }
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;

            });
    }
    handleOnFocus = (event) => {
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }
    handleLostFocus = (event) => {
    }

    selectionMetafile = (event) => {
        this.setState({ selectedMetafile: event.target.value });
    }

    async printReport(e, obj) {
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var url = window.location.origin + "/" + obj.props.clientgroup + "/" + obj.state.outputFilename + ".html";
        let html = await axios
            .get(url)
            .then(function (response) {
                return response ? response.data : "";
            })
            .catch(function (error) {
                console.log(error);
            });

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }

    downloadReport = (event) => {
        event.preventDefault();

        /*
        if (!this.state.outputFilename && this.state.fnDownload != "1") {
            alert("File to download is not found");
            return false;
        }
        */

        var filename = this.state.outputFilename + ".rtf";

        const link = document.createElement('a');
        link.href = this.props.clientgroup + "/" + filename;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();

        return false;
    }

    hideGeneditPopup = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }

        this.setState({ showGeneditPopup: false, paramsInAspxPage: null, linkToEditsFile: null });

        /*
        //Also stopping the process of current genedits
        var _this = this;
        let url = ConfigData.CRStarAPI_URL + _this.props.clientgroup + "/StopRunEdits";
        axios.get(url)
            .then(res => {
                _this.setState({ showGeneditPopup: false });
            }).catch(error => {
                console.log(error);
            });
        */
        return false;
    }

    //Copying from alpha's sources
    insertIeControls(iframeDoc, dObj) {
        var reactEnv = "QUICKLINKS";//{ dialog.Object }.getParentObject().dialogId;
        var scriptElement = iframeDoc.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.id = 'MyScript';

        var scriptSection = "function ChangeColorClick(tableRow, highLight){ ";
        scriptSection = scriptSection + "var ele=document.getElementById('MyScript'); ";
        scriptSection = scriptSection + "	var highlightedColor = 'rgb(220, 250, 201)'; ";
        scriptSection = scriptSection + "	var defaultColor = 'rgb(245, 245, 245)'; ";
        scriptSection = scriptSection + "	var currentColor = tableRow.style.backgroundColor; ";
        scriptSection = scriptSection + "    if (currentColor == highlightedColor) { ";
        scriptSection = scriptSection + "      tableRow.style.backgroundColor = defaultColor; ";
        scriptSection = scriptSection + "    } ";
        scriptSection = scriptSection + "    else { ";
        scriptSection = scriptSection + "     tableRow.style.backgroundColor = highlightedColor; ";
        scriptSection = scriptSection + "    } ";
        scriptSection = scriptSection + "  } ";

        scriptSection = scriptSection + "function ChangeColor(tableRow, highLight) {";
        scriptSection = scriptSection + "	var highlightedColor = 'rgb(220, 250, 201)'; ";
        scriptSection = scriptSection + "	var onColor = 'rgb(245, 245, 245)'; ";
        scriptSection = scriptSection + "	var defaultColor = 'white'; ";
        scriptSection = scriptSection + "	var currentColor = tableRow.style.backgroundColor; ";
        scriptSection = scriptSection + "	if (currentColor !== highlightedColor) { ";
        scriptSection = scriptSection + "		if (currentColor == onColor ) { ";
        scriptSection = scriptSection + "			tableRow.style.backgroundColor = defaultColor; ";
        scriptSection = scriptSection + "		} ";
        scriptSection = scriptSection + "		else { ";
        scriptSection = scriptSection + "			tableRow.style.backgroundColor = onColor; ";
        scriptSection = scriptSection + "		} ";
        scriptSection = scriptSection + "    } ";
        scriptSection = scriptSection + "  } ";

        scriptSection = scriptSection + "function GotoClick(thisObj) {";
        scriptSection = scriptSection + "var screenNameArray = thisObj.innerHTML.split(':');	 ";

        scriptSection = scriptSection + "if (screenNameArray.length == 1) { ";
        scriptSection = scriptSection + "    screenNameArray =  thisObj.getAttribute('dataVal').split(':');";
        scriptSection = scriptSection + "} ";

        //scriptSection = scriptSection + "	parent.document.getElementById('GENEDITSMAINT.V.R1.FN_IESCREENJUMP').value = screenNameArray[1]; ";
        //scriptSection = scriptSection + "	parent.document.getElementById('GENEDITSMAINT.V.R1.FN_IESCREENJEMPCONTROL').value = thisObj.value; ";
        //scriptSection = scriptSection + "	parent.document.getElementById('GENEDITSMAINT.V.R1.BUTTON_5').click(); ";

        scriptSection = scriptSection + "	this.FN_IESCREENJUMP = screenNameArray[1]; ";
        scriptSection = scriptSection + "	this.FN_IESCREENJEMPCONTROL = thisObj.value; ";

        scriptSection = scriptSection + "  setTimeout(function () { ";
        scriptSection = scriptSection + "var screenNameMoveTo = screenNameArray[1] ? screenNameArray[1] : '';";
        scriptSection = scriptSection + "  if (screenNameMoveTo.indexOf('Patient') != -1) { ";
        scriptSection = scriptSection + "	   window.parent.document.getElementById('Tabid_Patient').click();";//PatientMaint
        scriptSection = scriptSection + "  } else if (screenNameMoveTo.indexOf('Diagnosis') != -1) { ";
        scriptSection = scriptSection + "	   window.parent.document.getElementById('Tabid_Diagnosis').click();";//DiagnosisMaint
        scriptSection = scriptSection + "  } else if (screenNameMoveTo.indexOf('Staging') != -1) { ";
        scriptSection = scriptSection + "	   window.parent.document.getElementById('Tabid_Staging').click();";//StagingMaint
        scriptSection = scriptSection + "  } else if (screenNameMoveTo.indexOf('Treatment') != -1 || screenNameMoveTo.indexOf('RxSummary') != -1) { ";
        scriptSection = scriptSection + "	   window.parent.document.getElementById('Tabid_Treatment').click();";//RxSummaryMaint
        scriptSection = scriptSection + "  } else if (screenNameMoveTo.indexOf('Outcomes') != -1) { ";
        scriptSection = scriptSection + "	   window.parent.document.getElementById('Tabid_Outcomes').click();";//OutcomesMaint
        scriptSection = scriptSection + "  } else if (screenNameMoveTo.indexOf('StateFieldsMaint') != -1) { ";
        scriptSection = scriptSection + "	   window.parent.document.getElementById('Tabid_State_Specific').click();";//StateFieldsMaint
        scriptSection = scriptSection + "  } else if (screenNameMoveTo.indexOf('CocMaint') != -1) { ";
        scriptSection = scriptSection + "	   window.parent.document.getElementById('Tabid_CPM').click();";//CocMaint
        scriptSection = scriptSection + "  } else if (screenNameMoveTo.indexOf('BPM') != -1) { ";
        scriptSection = scriptSection + "	   window.parent.document.getElementById('Tabid_BPM').click();";//#!
        scriptSection = scriptSection + "  } else if (screenNameMoveTo.indexOf('RPM') != -1) { ";
        scriptSection = scriptSection + "	   window.parent.document.getElementById('Tabid_RPM').click();";//#!
        scriptSection = scriptSection + "  } else if (screenNameMoveTo.indexOf('UserDefined') != -1) { ";
        scriptSection = scriptSection + "	   window.parent.document.getElementById('Tabid_User_Defined').click();";//#!
        scriptSection = scriptSection + "  } else if (screenNameMoveTo.indexOf('CaseAdminMaint') != -1) { ";
        scriptSection = scriptSection + "	   window.parent.document.getElementById('Tabid_Case_Admin').click();";//CaseAdminMaint
        scriptSection = scriptSection + "  } else if (screenNameMoveTo.indexOf('Clinical') != -1) { ";
        scriptSection = scriptSection + "	   window.parent.document.getElementById('Tabid_Clinical').click();";//#!
        scriptSection = scriptSection + "  } else if (screenNameMoveTo.indexOf('Research') != -1) { ";
        scriptSection = scriptSection + "	   window.parent.document.getElementById('Tabid_Research').click();";//#!
        scriptSection = scriptSection + "  } else if (screenNameMoveTo.indexOf('RetiredMaint') != -1) { ";
        scriptSection = scriptSection + "	   window.parent.document.getElementById('Tabid_Retired').click();";//RetiredMaint
        scriptSection = scriptSection + "  } ";
        scriptSection = scriptSection + "  }, 0); ";

        scriptSection = scriptSection + "  setTimeout(function () { ";
        scriptSection = scriptSection + "	window.parent.document.getElementById('TBI_REPORTADHO_BUTTON_EXIT').click(); ";
        scriptSection = scriptSection + "  }, 20); ";

        scriptSection = scriptSection + "  setTimeout(function () { ";
        scriptSection = scriptSection + "	window.parent.document.getElementById('GENDEMANDL_V_R1_BUTTON_CANCEL').click(); ";
        scriptSection = scriptSection + "  }, 10); ";

        scriptSection = scriptSection + "} ";

        scriptElement.innerHTML = scriptSection;
        iframeDoc.head.appendChild(scriptElement);

        var iEfieldControls = this.state.iEfieldControls;//dObj.getValue('FN_IEITEMNBRS');
        var iElookupControls = this.state.iElookupControls;//dObj.getValue('FN_IELISTNBRS');
        var iEnaaccrNumbers = this.state.iEnaaccrNumbers;//dObj.getValue('FN_IENAACCRNBRS');
        var iEscreenNames = this.state.iEscreenNames;//dObj.getValue('FN_IESCRNJMPNAMES');
        var iEscreenControls = this.state.iEscreenControls;//dObj.getValue('FN_ieScrnJmpControls');
        var jmpNbrsArray = iEnaaccrNumbers.split(';');
        var jmpNames = iEscreenNames.split(';');
        var jmpControls = iEscreenControls.split(';');

        console.clear();
        console.log("iEfieldControls=" + iEfieldControls);
        console.log("iElookupControls=" + iElookupControls);
        console.log("iEnaaccrNumbers=" + iEnaaccrNumbers);

        var newListNbrs = '(';
        var newItemNbrs = '(';
        var editsDetails = iframeDoc.getElementsByClassName('editDetails');
        for (var i = 0; i < editsDetails.length; i++) {
            var currEditsDetails = editsDetails[i];
            var rows = currEditsDetails.getElementsByTagName('tr');
            for (var r = 0; r < rows.length; r++) {
                var cells = rows[r].getElementsByTagName('td');
                rows[r].setAttribute("style", "table-layout:fixed;");
                rows[r].setAttribute("onmouseover", "ChangeColor(this,true);");
                rows[r].setAttribute("onmouseout", "ChangeColor(this,true);");
                var ieWorkCell = rows[r].getElementsByClassName('ieWork');
                var naaccrItemNbr = cells[0].innerHTML.substring(cells[0].innerHTML.indexOf('#') + 1, cells[0].innerHTML.length);

                console.log("===>");
                console.log("       naaccrItemNbr=" + naaccrItemNbr);

                var idNames = cells[3].getElementsByClassName('hiddenData');
                cells[2].setAttribute("style", "width:240px; overflow:hidden;");
                cells[3].setAttribute("style", "width:1px;");
                cells[4].setAttribute("style", "width:1px;");
                var idNameF = idNames[0].id.substring(0, idNames[0].id.indexOf('k')) + 'F' + idNames[0].id.substring(idNames[0].id.indexOf('k') + 1, idNames[0].id.length);
                var idNameL = idNames[0].id.substring(0, idNames[0].id.indexOf('k')) + 'L' + idNames[0].id.substring(idNames[0].id.indexOf('k') + 1, idNames[0].id.length);
                var idNameE = idNames[0].id.substring(0, idNames[0].id.indexOf('k')) + 'E' + idNames[0].id.substring(idNames[0].id.indexOf('k') + 1, idNames[0].id.length);

                if (iEfieldControls.indexOf(';' + naaccrItemNbr + ';') > -1) {

                    if (iElookupControls.indexOf(';' + naaccrItemNbr + ';') > -1) {
                        newListNbrs = newListNbrs + "'" + naaccrItemNbr + "',";
                        newItemNbrs = newItemNbrs + "'" + naaccrItemNbr + "',";
                        input = document.createElement('select');
                        input.id = idNameL;
                        input.class = 'dropdownClass';
                        input.setAttribute("style", "width:198px; border:#1581B3; border-style:solid; border-width:1px;");
                        var option = document.createElement('option');
                        option.text = '--Current Value--';
                        input.add(option);
                        ieWorkCell[0].appendChild(input);
                    } else {
                        newItemNbrs = newItemNbrs + "'" + naaccrItemNbr + "',";
                        var input = document.createElement('input');
                        input.id = idNameF;
                        input.class = 'fieldClass';
                        input.setAttribute("style", "border:#1581B3; border-style:solid; border-width:1px;");
                        ieWorkCell[0].appendChild(input);

                        var td = document.createElement('td');
                        td.id = idNameE
                        td.class = 'errorMsgClass';
                        td.setAttribute("style", "color:red;");
                        ieWorkCell[0].appendChild(td);
                    }
                } else {
                    if (iEnaaccrNumbers.indexOf(';' + naaccrItemNbr + ';') > -1) {
                        var jmpName = '';
                        var jmpControl = '';
                        for (var n = 0; n < jmpNbrsArray.length; n++) {
                            if (naaccrItemNbr == jmpNbrsArray[n]) {
                                jmpName = jmpNames[n];
                                jmpControl = jmpControls[n];
                            }
                        }

                        var btn = document.createElement('BUTTON');
                        btn.innerHTML = 'GoTo:' + jmpName;
                        btn.value = jmpControl;
                        /*
                        if (reactEnv.substring(0, 10) == 'QUICKLINKS') {
                            btn = document.createElement('td');
                            btn.innerHTML = 'Located on ' + jmpName;
                            btn.setAttribute('dataVal', 'GoTo:' + jmpName);
                        }
                        */
                        btn.setAttribute("onclick", "GotoClick(this);");
                        ieWorkCell[0].appendChild(btn);
                    }
                }
            }
        }
        newListNbrs = newListNbrs + "'end')";
        newItemNbrs = newItemNbrs + "'end')";

        //dObj.setValue('FN_IEITEMNBRS', newItemNbrs);
        //dObj.setValue('FN_IELISTNBRS', newListNbrs);
        this.FN_IEITEMNBRS = newItemNbrs;
        this.FN_IELISTNBRS = newListNbrs;

        //Reactjs -> also call addIeDropDowns
        var ieListCodes = this.state.ieListCodes;
        this.addIeDropDowns(iframeDoc, null, ieListCodes);
    }

    FN_IESCREENJUMP = null;
    FN_IESCREENJEMPCONTROL = null;

    FN_SaveString = "";
    FN_IEITEMNBRS = "";
    FN_IELISTNBRS = "";
    FN_SHOWBUTTONS = "";

    async modifyReport(event, obj) {
        var url = obj.props.clientgroup + "/GetReportContentOfRunEdits";
        let bodyContentHtml = await axios
            .post(url, { outputFilename: obj.state.outputFilename, accessToken: localStorage.getItem('token') })
            .then(function (response) {
                //update new accessToken again
                var newAccessToken = response && response.data ? response.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                return response && response.data ? response.data.bodyContent : "";
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        var reportDisplayFrame = document.getElementById("bodyContentOfEdits");
        if (reportDisplayFrame && bodyContentHtml && bodyContentHtml != "") {
            reportDisplayFrame.contentDocument.body.innerHTML = "";
            reportDisplayFrame.contentDocument.write(bodyContentHtml);
            //reportDisplayFrame.style.display = "";

            //document.getElementById("bodyContentOfEdits_aspx") ? document.getElementById("bodyContentOfEdits_aspx").style.display = "none" : null;

            obj.setState({
                fnDownload: true,
                linkToEditsFile: obj.state.outputFilename + ".html",
                checkingReport: false,
            });

            obj.FN_showButtons = "";

            obj.setState({ fnSaveAndRerun: true });

            setTimeout(function () {
                var ifrm = document.getElementById('bodyContentOfEdits');
                var iframeDoc = ifrm.contentDocument;

                obj.insertIeControls(iframeDoc, obj);
            }, 500);            
        }
    }

    fnSaveString = (event) => {
        //var ifrm = document.getElementById('{dialog.componentname}.V.R1.IFRAME_1');
        var ifrm = document.getElementById('bodyContentOfEdits');
        var iframeDoc = ifrm.contentDocument;

        var iEfieldControls = this.state.iEfieldControls;//{dialog.Object}.getValue('FN_IEITEMNBRS');
        var iEFileName = this.state.iEFileName;//{dialog.Object}.getValue('FN_IEFILENAME');
        var iEColumnName = this.state.iEColumnName;//{dialog.Object}.getValue('FN_IECOLUMNNAME');
        var iElookupControls = this.state.iElookupControls;//{dialog.Object}.getValue('FN_IELISTNBRS');

        var iEItemNbrs = this.state.iEItemNbrs;//{dialog.Object}.getValue('FN_IELISTNBRS');
        var iELengths = this.state.iELengths;//{dialog.Object}.getValue('FN_IELENGTH');
        var iETypes = this.state.iETypes;//{dialog.Object}.getValue('FN_IETYPE');

        var saveString = this.createSaveItems(iframeDoc, iEItemNbrs /*iEfieldControls*/, iElookupControls, iEFileName, iEColumnName);

        //{dialog.Object}.setValue('FN_SaveString', saveString);
        this.FN_SaveString = saveString;
        console.log("FN_SaveString", saveString);

        this.setState({
            fnSaveAndRerun: false,
            paramsInAspxPage: null
        });

        var _url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/SaveAndReRunEdits";//?sql=" + encodeURIComponent(saveString);
        var _this = this;
        axios.post(_url, { sql: saveString, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data) {
                    _this.NAACCR_Export(); return false;

                    var selectedReportLabelsInfo = res.data.printForm;
                    _this.setState({
                        //#PR00636: Interactive Edits
                        iEfieldControls: selectedReportLabelsInfo.fnIeItemNbrs ? selectedReportLabelsInfo.fnIeItemNbrs : "",
                        iElookupControls: selectedReportLabelsInfo.fnIeListNbrs ? selectedReportLabelsInfo.fnIeListNbrs : "",//#0
                        iEnaaccrNumbers: selectedReportLabelsInfo.fnIeNaaccrNbrs ? selectedReportLabelsInfo.fnIeNaaccrNbrs : "",
                        iEscreenNames: selectedReportLabelsInfo.fnIeScrnJmpNames ? selectedReportLabelsInfo.fnIeScrnJmpNames : "",
                        iEscreenControls: selectedReportLabelsInfo.fnIeScrnJmpControls ? selectedReportLabelsInfo.fnIeScrnJmpControls : "",

                        //js = js + "{dialog.Object}.setValue('FN_IELISTCODES','"+js_escape(ieListCodes)+"');"
                        fnIeListCodes: selectedReportLabelsInfo.fnIeListCodes ? selectedReportLabelsInfo.fnIeListCodes : "",
                        ieListCodes: selectedReportLabelsInfo.fnIeListCodes ? selectedReportLabelsInfo.fnIeListCodes : "",

                        //FN_ieItemNbrs->onChange()::#!
                        iEFileName: selectedReportLabelsInfo.fnIeFileName ? selectedReportLabelsInfo.fnIeFileName : "",
                        iEColumnName: selectedReportLabelsInfo.fnIeColumnName ? selectedReportLabelsInfo.fnIeColumnName : "",
                        iEItemNbrs: selectedReportLabelsInfo.fnIeItemNbrs2 ? selectedReportLabelsInfo.fnIeItemNbrs2 : "",//#02
                        iELengths: selectedReportLabelsInfo.fnIeLength ? selectedReportLabelsInfo.fnIeLength : "",
                        iETypes: selectedReportLabelsInfo.fnIeType ? selectedReportLabelsInfo.fnIeType : "",

                        //fnShowButtons: selectedReportLabelsInfo.fnShowButtons == "1",
                        //fnSaveAndRerun: false,
                        //fnDownload: selectedReportLabelsInfo.fnDownload,
                        //completed: false,
                    });

                    setTimeout(function () {
                        let datafileName = _this.state.dataFileName ? _this.state.dataFileName : "";
                        if (datafileName.lastIndexOf(".") != -1) {
                            datafileName = _this.state.dataFileName.substring(0, _this.state.dataFileName.lastIndexOf('.'));
                        }
                        _url = ConfigData.CRStarAPI_URL + _this.props.clientgroup + "/RunEdits?Edits_Path=" + encodeURIComponent(_this.state.editsPath ? _this.state.editsPath : "")
                            + "?MetaFile=" + (_this.state.MetaFile ? _this.state.MetaFile : "")
                            + "?Datafile_Name=" + encodeURIComponent(datafileName)
                            + ".txt?Output_Path=" + encodeURIComponent(_this.state.outputPath + "\\") + "?Output_File_Name=" + encodeURIComponent(_this.state.outputFilename)
                            + "?State=" + encodeURIComponent(_this.state.hospState ? _this.state.hospState : _this.props.currentShowingState) + "?GeneditsCheck=1";

                        return false;
                    }, 1000);

                    return false;
                }
            }).catch(error => {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
        return false;
    }

    //
    createSaveItems(iframeDoc, iEfieldControls, iElookupControls, iEFileName, iEColumnName) {
        var saveString = ';';
        var editsDetails = iframeDoc.getElementsByClassName('editDetails');
        var itemNbrArray = iEfieldControls.split(';');
        var fileNameArray = iEFileName.split(';');
        var columnNameArray = iEColumnName.split(';');

        //
        console.log("itemNbrArray=" + itemNbrArray);
        console.log("fileNameArray=" + fileNameArray);
        console.log("columnNameArray=" + columnNameArray);

        for (var i = 0; i < editsDetails.length; i++) {
            var fileName = '';
            var columnName = '';
            var currEditsDetails = editsDetails[i];
            var rows = currEditsDetails.getElementsByTagName('tr');
            for (var r = 0; r < rows.length; r++) {
                var cells = rows[r].getElementsByTagName('td');
                var ieWorkCell = rows[r].getElementsByClassName('ieWork');
                var naaccrItemNbr = cells[4].getElementsByClassName('hiddenData')[0].value;

                for (var n = 0; n < itemNbrArray.length; n++) {
                    if (naaccrItemNbr == itemNbrArray[n]) {
                        fileName = fileNameArray[n];
                        columnName = columnNameArray[n];
                    }
                }
                var idNames = cells[3].getElementsByClassName('hiddenData');
                var idNameF = idNames[0].id.substring(0, idNames[0].id.indexOf('k')) + 'F' + idNames[0].id.substring(idNames[0].id.indexOf('k') + 1, idNames[0].id.length);
                var lenMsg = '';
                if (iEfieldControls.indexOf(";" + naaccrItemNbr + ";") > -1) {
                    try {
                        var fieldValue = ieWorkCell[0].getElementsByTagName('input')[0].value;
                    }
                    catch (err) {
                        var fieldValue = ieWorkCell[0].getElementsByTagName('select')[0].value;
                        fieldValue.substring(0, fieldValue.indexOf('-') - 1);
                    }
                    if (fieldValue !== '' && fieldValue !== '--Current Value--') {
                        var cidKey = cells[3].getElementsByClassName('hiddenData')[0].value;
                        var NewSaveString = "UPDATE " + fileName + " SET " + columnName + " = '" + fieldValue + "' WHERE CID = '" + cidKey + "';";
                        if (fileName == 'PATIENT' || fileName == 'Patient') {
                            NewSaveString = "UPDATE " + fileName + " SET " + columnName + " = '" + fieldValue + "' WHERE PID IN (SELECT PID from PRIMARY_SUMMARY WHERE  CID = '" + cidKey + "');";
                        }

                        if (NewSaveString.length + saveString.length >= 65500) {
                            lenMsg = 'Save string exceeds maximum length';
                        } else {
                            saveString = saveString + NewSaveString;
                        }
                    }
                }
            }
        }
        return saveString;
    }
    addIeDropDowns(iframeDoc, iElookupControls, ieListCodes) {
        var editsDetails = iframeDoc.getElementsByClassName('editDetails');
        ieListCodes = ieListCodes.replace(/'/g, '');
        ieListCodes = ieListCodes.replace(/\(|\)/g, '');

        var ieListCodesArray = ieListCodes.split('!');
        for (var i = 0; i < editsDetails.length; i++) {
            var currEditsDetails = editsDetails[i];
            var rows = currEditsDetails.getElementsByTagName('tr');

            for (var r = 0; r < rows.length; r++) {
                var cells = rows[r].getElementsByTagName('td');
                var ieWorkCell = rows[r].getElementsByClassName('ieWork');
                var naaccrItemNbr = cells[4].getElementsByClassName('hiddenData')[0].value;
                if (ieListCodes.indexOf('!' + naaccrItemNbr + '|') > -1) {
                    for (var l = 0; l < ieListCodesArray.length; l++) {
                        var curListCodesRow = ieListCodesArray[l];
                        try {
                            var curListCodesArray = curListCodesRow.split('|');
                            var curNaaccrItemNbr = curListCodesArray[0];
                            if (curNaaccrItemNbr == naaccrItemNbr) {
                                var selObj = ieWorkCell[0].getElementsByTagName('select')[0];
                                var opt = document.createElement('option');
                                opt.text = curListCodesArray[1] + ' - ' + curListCodesArray[2];
                                opt.value = curListCodesArray[1];
                                selObj.appendChild(opt);
                            }
                        }
                        catch (err) {
                        }
                    }
                }
            }
        }
    }
    validateSaveItems(iframeDoc, ieItemNbrs, ieLengths, ieTypes) {
        var saveString = ';';
        var editsDetails = iframeDoc.getElementsByClassName('editDetails');
        var itemNbrArray = ieItemNbrs.split(';');
        var lengthArray = ieLengths.split(';');
        var typesArray = ieTypes.split(';');

        for (var i = 0; i < editsDetails.length; i++) {
            var validLength = '';
            var validType = '';
            var currEditsDetails = editsDetails[i];
            var rows = currEditsDetails.getElementsByTagName('tr');
            for (var r = 0; r < rows.length; r++) {
                var cells = rows[r].getElementsByTagName('td');
                var ieWorkCell = rows[r].getElementsByClassName('ieWork');
                var naaccrItemNbr = cells[4].getElementsByClassName('hiddenData')[0].value;

                for (var n = 0; n < itemNbrArray.length; n++) {
                    if (naaccrItemNbr == itemNbrArray[n]) {
                        validLength = lengthArray[n];
                        validType = typesArray[n];
                    }
                }
                var idNames = cells[3].getElementsByClassName('hiddenData');
                var idNameF = idNames[0].id.substring(0, idNames[0].id.indexOf('k')) + 'F' + idNames[0].id.substring(idNames[0].id.indexOf('k') + 1, idNames[0].id.length);
                var idNameN = idNames[0].id.substring(0, idNames[0].id.indexOf('k')) + 'N' + idNames[0].id.substring(idNames[0].id.indexOf('k') + 1, idNames[0].id.length);
                var idNameE = idNames[0].id.substring(0, idNames[0].id.indexOf('k')) + 'E' + idNames[0].id.substring(idNames[0].id.indexOf('k') + 1, idNames[0].id.length);

                var dataEntered = getElementById(idNameF).value;

                getElementById(idNameE).value = 'value entered is ' + dataEntered + '.  Length should be ' + validLength + '. Data Type should be ' + validType;
            }
        }
        return saveString;
    }

    NAACCR_Export(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        const currentShowingHospitalVersion = this.props.currentShowingHospitalVersion;

        var dialogFormData = {};
        dialogFormData.naaccrVersion = currentShowingHospitalVersion ? currentShowingHospitalVersion : "22";
        dialogFormData.editsMetafile = this.state.selectedMetafile ? this.state.selectedMetafile : "stateMetafile";

        dialogFormData.userHosp = this.props.selectedHospital;
        dialogFormData.hospState = this.state.state;

        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;

        //
        var CurrentUser = this.props.userId;
        dialogFormData.populationLabel = "SingleCaseEditCheck_" + CurrentUser;
        dialogFormData.filename = "SingleCaseExport";
        dialogFormData.runGenEdits = "1";//
        dialogFormData.updateFlagDate = "0";//
        dialogFormData.fromPage = "Genedits_Check";

        var _this = this;
        let url = this.props.clientgroup + "/RunGeneditCheck";
        axios.post(url, { printForm: dialogFormData, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error && res.data.printForm) {
                    _this.showGeneditPopup(res.data.printForm);
                    return false;
                }
            }).catch(error => {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        return false;
    }

    showGeneditPopup = (selectedReportLabelsInfo) => {
        let datafileName = selectedReportLabelsInfo.csFilename ? selectedReportLabelsInfo.csFilename : "";
        if (datafileName.lastIndexOf(".") != -1) {
            datafileName = selectedReportLabelsInfo.csFilename.substring(0, selectedReportLabelsInfo.csFilename.lastIndexOf('.'));
        }

        let paramsInAspxPage = selectedReportLabelsInfo.genEditsAspxPageUrl
            + "?Edits_Path=" + (selectedReportLabelsInfo.editsPath ? selectedReportLabelsInfo.editsPath : "")
            + "?MetaFile=" + (selectedReportLabelsInfo.metaFile ? selectedReportLabelsInfo.metaFile : "")
            + "?Record_Layout=" + (selectedReportLabelsInfo.recordLayout ? selectedReportLabelsInfo.recordLayout : "")
            + "?Edit_Set=" + (selectedReportLabelsInfo.editSet ? selectedReportLabelsInfo.editSet : "")
            + "?Datafile_Name=" + (datafileName)
            + ".txt?Output_Path=" + (selectedReportLabelsInfo.folder + "\\")
            + "?Output_File_Name=" + (selectedReportLabelsInfo.outputFilename)
            + "?State=" + (selectedReportLabelsInfo.hospState ? selectedReportLabelsInfo.hospState : this.props.currentShowingState);
        
        this.setState({
            editsUrl: selectedReportLabelsInfo.editsUrl,
            editsPath: selectedReportLabelsInfo.editsPath,
            dataFileName: selectedReportLabelsInfo.csFilename,
            outputPath: selectedReportLabelsInfo.folder,
            outputFilename: selectedReportLabelsInfo.outputFilename,
            showGeneditPopup: selectedReportLabelsInfo.runGenEdits == "1" && selectedReportLabelsInfo.fnGenEditsStartFile ? true : false,
            fnGenEditsStartFile: selectedReportLabelsInfo.fnGenEditsStartFile && selectedReportLabelsInfo.fnGenEditsStartFile != "" ? selectedReportLabelsInfo.fnGenEditsStartFile : null,
            MetaFile: selectedReportLabelsInfo.metaFile,
            IIS_Path: selectedReportLabelsInfo.iisPath,
            hospState: selectedReportLabelsInfo.hospState,
            //
            isDrawingHtmlTable: selectedReportLabelsInfo.fnDisplayURL1 && selectedReportLabelsInfo.fnDisplayURL1 != "",
            selectedStateExport: "State Export",
            fnDisplayURL1: selectedReportLabelsInfo.fnDisplayURL1,
            fnDisplayURL2: selectedReportLabelsInfo.fnDisplayURL2,
            fnDisplayURL3: selectedReportLabelsInfo.fnDisplayURL3,
            fnDisplayURL4: selectedReportLabelsInfo.fnDisplayURL4,
            fnDownloadFile1: selectedReportLabelsInfo.fnDownloadFile1,
            fnDownloadFile2: selectedReportLabelsInfo.fnDownloadFile2,
            fnDownloadFile3: selectedReportLabelsInfo.fnDownloadFile3,
            fnDownloadFile4: selectedReportLabelsInfo.fnDownloadFile4,

            //
            fnHtmlDisplayURL1: selectedReportLabelsInfo.fnHtmlDisplayURL1,
            fnHtmlDisplayURL2: selectedReportLabelsInfo.fnHtmlDisplayURL2,
            fnHtmlDisplayURL3: selectedReportLabelsInfo.fnHtmlDisplayURL3,
            fnHtmlDisplayURL4: selectedReportLabelsInfo.fnHtmlDisplayURL4,

            //#PR00636: Interactive Edits
            iEfieldControls: selectedReportLabelsInfo.fnIeItemNbrs ? selectedReportLabelsInfo.fnIeItemNbrs : "",
            iElookupControls: selectedReportLabelsInfo.fnIeListNbrs ? selectedReportLabelsInfo.fnIeListNbrs : "",//#0
            iEnaaccrNumbers: selectedReportLabelsInfo.fnIeNaaccrNbrs ? selectedReportLabelsInfo.fnIeNaaccrNbrs : "",
            iEscreenNames: selectedReportLabelsInfo.fnIeScrnJmpNames ? selectedReportLabelsInfo.fnIeScrnJmpNames : "",
            iEscreenControls: selectedReportLabelsInfo.fnIeScrnJmpControls ? selectedReportLabelsInfo.fnIeScrnJmpControls : "",

            //js = js + "{dialog.Object}.setValue('FN_IELISTCODES','"+js_escape(ieListCodes)+"');"
            fnIeListCodes: selectedReportLabelsInfo.fnIeListCodes ? selectedReportLabelsInfo.fnIeListCodes : "",
            ieListCodes: selectedReportLabelsInfo.fnIeListCodes ? selectedReportLabelsInfo.fnIeListCodes : "",

            //FN_ieItemNbrs->onChange()::#!
            iEFileName: selectedReportLabelsInfo.fnIeFileName ? selectedReportLabelsInfo.fnIeFileName : "",
            iEColumnName: selectedReportLabelsInfo.fnIeColumnName ? selectedReportLabelsInfo.fnIeColumnName : "",
            iEItemNbrs: selectedReportLabelsInfo.fnIeItemNbrs2 ? selectedReportLabelsInfo.fnIeItemNbrs2 : "",//#02
            iELengths: selectedReportLabelsInfo.fnIeLength ? selectedReportLabelsInfo.fnIeLength : "",
            iETypes: selectedReportLabelsInfo.fnIeType ? selectedReportLabelsInfo.fnIeType : "",

            fnShowButtons: selectedReportLabelsInfo.fnShowButtons == "1",
            fnSaveAndRerun: false,
            fnDownload: selectedReportLabelsInfo.fnDownload,
            completed: false,

            linkToEditsFile: null,

            paramsInAspxPage: paramsInAspxPage,
        });

        return false;
    }

    render() {
        let geneditsCheck = null;
        {
            geneditsCheck = <Draggable handle=".k-window-titlebar">
                <div className="dragableDialogCls" id="geneditsCheckModal">
                    <div className="k-window-titlebar k-dialog-titlebar k-header">
                        <div className="k-window-title k-dialog-title">Genedits Check</div>
                        <div className="k-window-actions k-dialog-actions">
                            <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                <span className="k-icon k-i-x" onClick={(e) => { this.closeWindow(e) }}></span>
                            </a>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                    labelText="State" field="gcState" lostfocused={this.handleLostFocus}
                                    value={this.state.state} lineStyle="onTopFrame"
                                    dataSource="user_settings_state_metafiles_Mv" titleDialog="" labelWidth="50px" width="40px"
                                    flag={this.props.userId}
                                    keyId="gcState0" focusingField={this.state.focusingField}
                                    shownType="dropdown" rowIndex="0" //displayingCodeOnly={true}
                                    hideFilter={true} //limitByWidth={true}
                                    setNameAlias="codeOnly"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12" style={{ marginLeft: "-15px" }}>
                                <label style={{ width: "240px", height: "0px" }}>
                                    <input type="radio" onClick={this.selectionMetafile} checked={this.state.selectedMetafile === "stateMetafile"}
                                        name="metafile" field="metafile" value="stateMetafile"
                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                    State Metafile
                                </label>
                                <br />
                                <label style={{ width: "240px", height: "0px" }}>
                                    <input type="radio" onClick={this.selectionMetafile} checked={this.state.selectedMetafile === "ncdbMetafile"}
                                        name="metafile" field="metafile" value="ncdbMetafile" width="240px"
                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                    NCDB Metafile
                                </label>
                                <br />
                                <label style={{ width: "240px", height: "0px" }}>
                                    <input type="radio" onClick={this.selectionMetafile} checked={this.state.selectedMetafile === "clinicalMetafile"}
                                        name="metafile" field="metafile" value="clinicalMetafile" width="240px"
                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                    RCRS Metafile
                                </label>
                                <br />
                                <label style={{ width: "240px", height: "0px" }}>
                                    <input type="radio" onClick={this.selectionMetafile} checked={this.state.selectedMetafile === "additionalMetafile"}
                                        name="metafile" field="metafile" value="additionalMetafile" width="240px"
                                        style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                    Additional Metafile
                                </label>
                            </div>
                        </div>
                        <br />

                        <div className="row">
                            <div className="col-sm-12">
                                <button type="button" id="GENDEMANDL_V_R1_BUTTON_RUN" className="" title=""
                                    onClick={(e) => { this.NAACCR_Export(e) }}>Run
                                </button>&nbsp;&nbsp;
                                <button type="button" id="GENDEMANDL_V_R1_BUTTON_CANCEL" className="" title=""
                                    onClick={(e) => { this.closeWindow(e) }}>Cancel
                                </button>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </Draggable>
        }

        let showGeneditPopup;
        {
            showGeneditPopup = this.state.showGeneditPopup && this.state.fnGenEditsStartFile ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableAlphaWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">GenEdits Report</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideGeneditPopup(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>

                            <div className="container printOnQaForm" id="printAbtractsMainTable_GenEdits" style={{ padding: "0px !important", overflow: "hidden !important" }}>
                                <iframe id="bodyContentOfEdits_aspx" src={this.state.paramsInAspxPage ? this.state.paramsInAspxPage : "loading.html"}
                                    style={{ display: this.state.fnSaveAndRerun ? "none" : "" }} />
                                <iframe id="bodyContentOfEdits" style={{ display: this.state.fnSaveAndRerun ? "" : "none" }} />
                            </div>

                            <div className="emptyLine"></div>
                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-sm-8" style={{ display: "flex", margin: "15px -5px" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ScreenButton _ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={(e) => { this.printReport(e, this) }} value="print" autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$print.png.a5image" className="" />
                                            Print</button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_DOWNLOAD" className="ScreenButton _ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px" }} //disabled={this.state.fnDownload != "1"}
                                            onClick={this.downloadReport} value="download">
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$application.ms.word.png.a5image" className="" />
                                            Download</button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ScreenButton _ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={this.hideGeneditPopup} value="exit">
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit</button>
                                    </div>
                                    <div className="col-sm-4" style={{ display: "flex", margin: "15px -5px" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_MODIFY" className="ScreenButton _ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px", display: this.state.fnShowButtons ? "" : "none" }}
                                            disabled={this.state.fnSaveAndRerun}
                                            onClick={(e) => { this.modifyReport(e, this) }} value="modify">Modify</button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_SAVE_AND_RERUN" className="ScreenButton _ScreenButtonHover" title=""
                                            style={{ marginLeft: "15px", display: this.state.fnSaveAndRerun ? "" : "none" }}
                                            onClick={this.fnSaveString} value="save_and_rerun">Save & Rerun</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        return (
            <React.Fragment>
                <div>
                    <div className="fake_popup_cls"></div>
                    {geneditsCheck}
                </div>

                <React.Fragment>
                    {showGeneditPopup}
                </React.Fragment>
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        clientgroup: state.auth.clientgroup,
        alphaSystemUrlBaseUrl: state.patientAbstract.alphaSystemUrlBaseUrl,
        alphaToken: state.patientAbstract.tokenOnSession,
        //
        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        patientId: state.patientAbstract.patientId,
        userId: state.auth.userId,

        selectedHospital: state.patientAbstract.selectedHospital,
        currentShowingState: state.patientAbstract.currentShowingState,
        currentShowingHospitalVersion: state.patientAbstract.currentShowingHospitalVersion,

        showingTabName: state.patientAbstract.showingTabName,

        authRedirectPath: state.auth.authRedirectPath,
        routingPage: state.patientAbstract.routingPage,
        currentDialog: state.patientAbstract.currentDialog,
        isParentWindowVisible: state.patientAbstract.isParentWindowVisible,
        tabItems: state.patientAbstract.tabItems,
        tabStatuses: state.patientAbstract.tabStatuses,
        alphaUrlInnerTab: state.patientAbstract.alphaUrl,
        alphaUrl: window.location ? window.location.pathname : "",
        availableItems: state.patientAbstract.availableItems,
        loggedOnUid: state.auth.userId,
        selectedUserOnGrid: state.patientAbstract.selectedUserOnGrid,
        selectedUserInfo: state.patientAbstract.selectedUserInfo,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
        selectedDiagnosisRecord: state.patientAbstract.selectedDiagnosisRecord,
        crstarSessionTimeout: state.auth.expiresIn,
        inquiringMode: state.auth.inquiringMode,
        rapidAbstract: state.patientAbstract.rapidAbstract,
        showSessionTimeoutMsg: state.patientAbstract.showSessionTimeoutMsg,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        selectedLabelPopInfo: state.patientAbstract.selectedLabelPopInfo,
        availableHospsData: state.auth.availableHospsData,
        controlName: state.patientAbstract.controlName,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: (uid, cg, sval, msg) => dispatch(actions.logout(uid, cg, sval, msg)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        selectUser: (uId) => dispatch(actionCreators.selectUser(uId)),
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        selectTabItems: (dialogType) => dispatch(actionCreators.selectTabItems(dialogType)),
        removeTabItems: (dialogType) => dispatch(actionCreators.removeTabItems(dialogType)),
        selectPatient: (patientId, isAddNew, onTab) => dispatch(actionCreators.selectPatient(patientId, isAddNew, onTab)),
        selectStagingRecord: (medial) => dispatch(actionCreators.selectStagingRecord(medial)),
        selectTreatmentRecord: (medial) => dispatch(actionCreators.selectTreatmentRecord(medial)),
        selectOutcomesRecord: (medial) => dispatch(actionCreators.selectOutcomesRecord(medial)),
        selectCocRecord: (medial) => dispatch(actionCreators.selectCocRecord(medial)),
        selectNapbcRecord: (medial) => dispatch(actionCreators.selectNapbcRecord(medial)),
        selectRectalProgramRecord: (medial) => dispatch(actionCreators.selectRectalProgramRecord(medial)),
        selectCaseAdminRecord: (medial, uid) => dispatch(actionCreators.selectCaseAdminRecord(medial, uid)),
        selectClinicalRecord: (medial) => dispatch(actionCreators.selectClinicalRecord(medial)),
        selectResearchRecord: (medial) => dispatch(actionCreators.selectResearchRecord(medial)),
        selectUserDefinedRecord: (medial) => dispatch(actionCreators.selectUserDefinedRecord(medial)),
        selectStateFieldsRecord: (medial, nm) => dispatch(actionCreators.selectStateFieldsRecord(medial, nm)),
        closeOtherTabItems: (flag) => dispatch(actionCreators.closeOtherTabItems(flag)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        selectRetiredRecord: (medial) => dispatch(actionCreators.selectedRetiredRecord(medial)),
        selectUserInfoRecord: (email, cligrp) => dispatch(actionCreators.selectUserInfoRecord(email, cligrp)),
        selectUserSecurityRecord: (email) => dispatch(actionCreators.selectUserSecurityRecord(email)),
        loadPwdPolicy: (uid) => dispatch(actionCreators.loadPwdPolicy(uid)),
        showLockedUsers: (group) => dispatch(actionCreators.showLockedUsers(group)),
        setAlphaUrl: (url) => dispatch(actionCreators.setAlphaUrl(url)),
        resetCurrentSelectedIndex: (idx) => dispatch(actionCreators.resetCurrentSelectedIndex(idx)),
        setRapidAbstract: (value) => dispatch(actionCreators.setRapidAbstract(value)),
        showPageLoading: (value) => dispatch(actionCreators.showPageLoading(value)),
        //
        resetAllStoringOfUser: () => dispatch(actionCreators.resetAllStoringOfUser()),
        setHandlerControlItem: (item) => dispatch(actionCreators.setHandlerControlItem(item)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
        // Crstar Reporting Menu
        selectAPopLabel: (label) => dispatch(actionCreators.selectAPopLabel(label)),
        //System Management Menu
        generatePhysiciansRoster: (flag) => dispatch(actionCreators.generatePhysiciansRoster(flag)),
        loadAccNbrsMaint: (val) => dispatch(actionCreators.loadAccNbrsMaint(val)),
        loadFollowUpOrder: (val) => dispatch(actionCreators.loadFollowUpOrder(val)),
        loadAccNbrsMaintInit: () => dispatch(actionCreators.loadAccNbrsMaintInit()),

        //
        loadGenEditsInfo: (name) => dispatch(actionCreators.loadGenEditsInfo(name)),
        loadGenEditsOnInitial: () => dispatch(actionCreators.loadGenEditsOnInitial()),
        loadDashboardSettingsInfo: (name, hosp) => dispatch(actionCreators.loadDashboardSettingsInfo(name, hosp)),

        //
        loadPatientAbsInfo: (pid) => dispatch(actionCreators.loadPatientAbsInfo(pid)),
        setTabName: (name) => dispatch(actionCreators.setTabName(name)),
        setControlName: (name) => dispatch(actionCreators.setControlName(name)),

        selectRfLabels: (label) => dispatch(actionCreators.selectRfLabels(label)),
        selectReportLabels: (label) => dispatch(actionCreators.selectReportLabels(label)),

        loadLettersMaint: (name) => dispatch(actionCreators.loadLettersMaint(name)),
        loadCustomFormsMaint: (name) => dispatch(actionCreators.loadCustomFormsMaint(name)),//
        loadImportConfig: (label) => dispatch(actionCreators.loadImportConfigInfo(label)),
        databaseValidationInfo: () => dispatch(actionCreators.databaseValidationInfo()),
        massDeleteInfo: () => dispatch(actionCreators.massDeleteInfo()),

        selectMedicalRecord: (medicalRecord, patientId) => dispatch(actionCreators.selectMedicalRecord(medicalRecord, patientId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneditsCheck);