/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import "../PatientMaintenance/PatientMaintenance.css";
import "./StagingMaintenance.css";
import "../DiagnosisMaintenance/DiagnosisMaintenance.css";
import StagingPre2018Maint from "./StagingPre2018Maintenance";
import Staging2018Maint from "./Staging2018Maintenance";

class StagingMaintenance extends Component {
    render() {
        console.log("diagnosisDateYear=" + this.props.selectedPrimarySummary.diagnosisDateYear);

        const stagingMaintenance = this.props.selectedPrimarySummary.diagnosisDateYear < 2018 ? <StagingPre2018Maint /> : <Staging2018Maint />;
        return (
            <React.Fragment>
                {stagingMaintenance}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
    }
}

export default connect(mapStateToProps, null)(StagingMaintenance);