/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import Footer from "../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
import * as Global from "../../../store/Global";
import Draggable from 'react-draggable';
import "./DashboardSettings.css";
import moment from "moment";

class DashboardSettings extends Component {

    state = {
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,

        pAllItemFields: null,
        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        showMessageError: false,
        hasError: false,
        savedForm: false,
        fieldInFocus: null,
        focusingField: null,
        isSaving: false,
        enabledDeletedBtn: false,
        loaded: false,

        metafilesMv: null,
        initialMetafilesMv: null,
        metafilesMvItemFields: null,
        metafilesMvMaxlengthInput: null,
        selectedItemRowIndex: 0,
    }

    handleOnFocus = (event) => {
        event.preventDefault();

        var selectedRowIndex = this.state.selectedItemRowIndex;
        if (event.target.getAttribute("data-index") != "0") {
            selectedRowIndex = event.target.getAttribute("data-index");
        }

        this.setState({
            fieldInFocus: event.target.name,
            focusingField: event.target,
            selectedItemRowIndex: selectedRowIndex
        });

        return false;
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showMessageError: false
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector(".Footer2>#btnSubmitForm_Fake") ? document.querySelector(".Footer2>#btnSubmitForm_Fake").focus() : "";
        }, 0);

        return false;
    }

    componentDidMount() {
        this.setState({ selectedItemRowIndex: 0 });

        if (!this.state.dialogForm && this.props.dashboardSettingsInfo) {
            this.setFormData();
        }

        var childrenOpening = this.props.childrenOpening;
        this.props.setChildrenOpening(childrenOpening);
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.dialogForm && this.props.dashboardSettingsInfo)
            || (prevProps.dashboardSettingsInfo && this.props.dashboardSettingsInfo && prevProps.dashboardSettingsInfo.hospCode !== this.props.dashboardSettingsInfo.hospCode)
            || (prevProps.dashboardSettingsInfo && this.props.dashboardSettingsInfo && prevProps.dashboardSettingsInfo.userName !== this.props.dashboardSettingsInfo.userName)
            || (this.state.dialogForm && this.props.dashboardSettingsInfo && this.state.dialogForm.lastAccessTime !== this.props.dashboardSettingsInfo.lastAccessTime)
        ) {
            this.setFormData();
        }

        /*
        if (this.props.currentSelectedField != null && this.props.commonLookupItem) {
            var dialogForm = this.state.dialogForm;
            if (this.props.dashboardSettingsInfo && this.props.dashboardSettingsInfo.templateId != "0" && this.state.reload) {
                dialogForm = this.props.dashboardSettingsInfo;
            }

            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                const rIdx = this.props.currentSelectedIndex;
                if (this.props.currentSelectedField.indexOf("contPhys") != -1 && rIdx > 0 && this.autoGeneralName) {
                    const fName = this.props.commonLookupItem.firstName;
                    const lName = this.props.commonLookupItem.lastName;
                    this.setState({
                        pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "" },
                        // And also update name again
                        dialogForm: {
                            ...dialogForm,
                            [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name,
                            ["firstName" + rIdx]: fName,
                            ["lastName" + rIdx]: lName,
                        },
                        reload: false,
                    });
                } else {
                    this.setState({
                        pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "" },
                        // And also update name again
                        dialogForm: { ...dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                        reload: false,
                    });
                }
            } else {
                const rIdx = this.props.currentSelectedIndex;
                if (this.props.currentSelectedField.indexOf("contPhys") != -1 && rIdx > 0 && this.autoGeneralName) {
                    this.setState({
                        //pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        dialogForm: {
                            ...dialogForm,
                            [this.props.currentSelectedField + "Name"]: "",
                            ["firstName" + rIdx]: "",
                            ["lastName" + rIdx]: "",
                        },
                        reload: false,
                    });
                } else {
                    this.setState({
                        //pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        dialogForm: { ...dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                        reload: false,
                    });
                }
            }
            this.props.clearCommonLookupItem();
        }
        */

        //
        if (this.state.tableStructureList === null && !this.state.loadedTableStructure) {
            this.setState({ loadedTableStructure: true });
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        if (this.state.savedForm && !this.props.isDialogLoading && ((prevProps.isFormDirty != this.props.isFormDirty || this.props.error) || (prevProps.error != this.props.error || !this.state.isFormDirty))) {
            if (this.props.isFormDirty) {
                if (!this.state.hasError && this.state.hasError != this.props.error) {
                    this.setState({ showMessageError: true, hasError: true, isFormDirty: true });
                    this.props.setChildrenOpening(true);
                }
            } else {
                if (this.state.isFormDirty && this.state.isFormDirty != this.props.isFormDirty) {
                    this.setState({ isFormDirty: this.props.isFormDirty, hasError: false });
                }
            }

            /*
            if (this.props.dashboardSettingsInfo && this.props.dashboardSettingsInfo.templateId) {
                document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "Record Saved" : null;
            }
            */
        }

        if (this.state.isFormDirty && (this.state.isSaving || this.props.deletedDataSuccess)
            && (prevProps.isFormDirty !== this.props.isFormDirty || this.state.isFormDirty !== this.props.isFormDirty)) {
            this.setState({
                isFormDirty: this.props.isFormDirty,
                isSaving: false,
            });
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";

        let res = await axios
            .post(url, { table: "x_Dashboard_Settings", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res.data ? res.data.result : null
        });
    }

    setFormData = () => {
        let dataForm = this.props.dashboardSettingsInfo;

        //
        var hospitalOnTop = document.querySelector(".hospitalListOpt") ? document.querySelector(".hospitalListOpt").selectedOptions[0] : null;
        if (hospitalOnTop) {
            dataForm["hospCode"] = hospitalOnTop.value;
            dataForm["hospName"] = (hospitalOnTop.text && hospitalOnTop.text.indexOf(" - ") != -1 ? hospitalOnTop.text.split(" - ")[1] : "");
        }

        let fields = {};
        Object.keys(dataForm).map((t) => {
            fields[t] = "";
        });

        this.setState({
            initialDialogForm: { ...fields },
            dialogForm: {
                ...dataForm,
                ["casesCompletedBeginDate"]: (dataForm != null && dataForm.casesCompletedBeginDate !== null && dataForm.casesCompletedBeginDate !== "" ? moment(new Date(dataForm.casesCompletedBeginDate)).format("MM/DD/YYYY") : ""),
                ["casesCompletedEndDate"]: (dataForm != null && dataForm.casesCompletedEndDate !== null && dataForm.casesCompletedEndDate !== "" ? moment(new Date(dataForm.casesCompletedEndDate)).format("MM/DD/YYYY") : ""),
                ["casesAbstractedBeginDate"]: (dataForm != null && dataForm.casesAbstractedBeginDate !== null && dataForm.casesAbstractedBeginDate !== "" ? moment(new Date(dataForm.casesAbstractedBeginDate)).format("MM/DD/YYYY") : ""),
                ["casesAbstractedEndDate"]: (dataForm != null && dataForm.casesAbstractedEndDate !== null && dataForm.casesAbstractedEndDate !== "" ? moment(new Date(dataForm.casesAbstractedEndDate)).format("MM/DD/YYYY") : ""),
                ["followUpEnteredBeginDate"]: (dataForm != null && dataForm.followUpEnteredBeginDate !== null && dataForm.followUpEnteredBeginDate !== "" ? moment(new Date(dataForm.followUpEnteredBeginDate)).format("MM/DD/YYYY") : ""),
                ["followUpEnteredEndDate"]: (dataForm != null && dataForm.followUpEnteredEndDate !== null && dataForm.followUpEnteredEndDate !== "" ? moment(new Date(dataForm.followUpEnteredEndDate)).format("MM/DD/YYYY") : ""),
                ["lastFollowUpEnteredBeginDate"]: (dataForm != null && dataForm.lastFollowUpEnteredBeginDate !== null && dataForm.lastFollowUpEnteredBeginDate !== "" ? moment(new Date(dataForm.lastFollowUpEnteredBeginDate)).format("MM/DD/YYYY") : ""),
                ["lastFollowUpEnteredEndDate"]: (dataForm != null && dataForm.lastFollowUpEnteredEndDate !== null && dataForm.lastFollowUpEnteredEndDate !== "" ? moment(new Date(dataForm.lastFollowUpEnteredEndDate)).format("MM/DD/YYYY") : ""),
                ["followUpCalcsBeginDate"]: (dataForm != null && dataForm.followUpCalcsBeginDate !== null && dataForm.followUpCalcsBeginDate !== "" ? moment(new Date(dataForm.followUpCalcsBeginDate)).format("MM/DD/YYYY") : ""),
                ["followUpCalcsEndDate"]: (dataForm != null && dataForm.followUpCalcsEndDate !== null && dataForm.followUpCalcsEndDate !== "" ? moment(new Date(dataForm.followUpCalcsEndDate)).format("MM/DD/YYYY") : ""),
                ["rqrsBeginDate"]: (dataForm != null && dataForm.rqrsBeginDate !== null && dataForm.rqrsBeginDate !== "" ? moment(new Date(dataForm.rqrsBeginDate)).format("MM/DD/YYYY") : ""),
                ["rqrsEndDate"]: (dataForm != null && dataForm.rqrsEndDate !== null && dataForm.rqrsEndDate !== "" ? moment(new Date(dataForm.rqrsEndDate)).format("MM/DD/YYYY") : ""),
                ["stagedByBeginDate"]: (dataForm != null && dataForm.stagedByBeginDate !== null && dataForm.stagedByBeginDate !== "" ? moment(new Date(dataForm.stagedByBeginDate)).format("MM/DD/YYYY") : ""),
                ["stagedByEndDate"]: (dataForm != null && dataForm.stagedByEndDate !== null && dataForm.stagedByEndDate !== "" ? moment(new Date(dataForm.stagedByEndDate)).format("MM/DD/YYYY") : ""),
                ["suspenseBySiteBeginDate"]: (dataForm != null && dataForm.suspenseBySiteBeginDate !== null && dataForm.suspenseBySiteBeginDate !== "" ? moment(new Date(dataForm.suspenseBySiteBeginDate)).format("MM/DD/YYYY") : ""),
                ["suspenseBySiteEndDate"]: (dataForm != null && dataForm.suspenseBySiteEndDate !== null && dataForm.suspenseBySiteEndDate !== "" ? moment(new Date(dataForm.suspenseBySiteEndDate)).format("MM/DD/YYYY") : ""),
                ["timelinessBeginDate"]: (dataForm != null && dataForm.timelinessBeginDate !== null && dataForm.timelinessBeginDate !== "" ? moment(new Date(dataForm.timelinessBeginDate)).format("MM/DD/YYYY") : ""),
                ["timelinessEndDate"]: (dataForm != null && dataForm.timelinessEndDate !== null && dataForm.timelinessEndDate !== "" ? moment(new Date(dataForm.timelinessEndDate)).format("MM/DD/YYYY") : ""),
                ["top5SitesBeginDate"]: (dataForm != null && dataForm.top5SitesBeginDate !== null && dataForm.top5SitesBeginDate !== "" ? moment(new Date(dataForm.top5SitesBeginDate)).format("MM/DD/YYYY") : ""),
                ["top5SitesEndDate"]: (dataForm != null && dataForm.top5SitesEndDate !== null && dataForm.top5SitesEndDate !== "" ? moment(new Date(dataForm.top5SitesEndDate)).format("MM/DD/YYYY") : ""),
                ["referredFromBeginDate"]: (dataForm != null && dataForm.referredFromBeginDate !== null && dataForm.referredFromBeginDate !== "" ? moment(new Date(dataForm.referredFromBeginDate)).format("MM/DD/YYYY") : ""),
                ["referredFromEndDate"]: (dataForm != null && dataForm.referredFromEndDate !== null && dataForm.referredFromEndDate !== "" ? moment(new Date(dataForm.referredFromEndDate)).format("MM/DD/YYYY") : ""),
                ["referredToBeginDate"]: (dataForm != null && dataForm.referredToBeginDate !== null && dataForm.referredToBeginDate !== "" ? moment(new Date(dataForm.referredToBeginDate)).format("MM/DD/YYYY") : ""),
                ["referredToEndDate"]: (dataForm != null && dataForm.referredToEndDate !== null && dataForm.referredToEndDate !== "" ? moment(new Date(dataForm.referredToEndDate)).format("MM/DD/YYYY") : ""),
                ["classBySiteBeginDate"]: (dataForm != null && dataForm.classBySiteBeginDate !== null && dataForm.classBySiteBeginDate !== "" ? moment(new Date(dataForm.classBySiteBeginDate)).format("MM/DD/YYYY") : ""),
                ["classBySiteEndDate"]: (dataForm != null && dataForm.classBySiteEndDate !== null && dataForm.classBySiteEndDate !== "" ? moment(new Date(dataForm.classBySiteEndDate)).format("MM/DD/YYYY") : ""),
                ["countyBySiteBeginDate"]: (dataForm != null && dataForm.countyBySiteBeginDate !== null && dataForm.countyBySiteBeginDate !== "" ? moment(new Date(dataForm.countyBySiteBeginDate)).format("MM/DD/YYYY") : ""),
                ["countyBySiteEndDate"]: (dataForm != null && dataForm.countyBySiteEndDate !== null && dataForm.countyBySiteEndDate !== "" ? moment(new Date(dataForm.countyBySiteEndDate)).format("MM/DD/YYYY") : ""),
                ["inOutMigrationBeginDate"]: (dataForm != null && dataForm.inOutMigrationBeginDate !== null && dataForm.inOutMigrationBeginDate !== "" ? moment(new Date(dataForm.inOutMigrationBeginDate)).format("MM/DD/YYYY") : ""),
                ["inOutMigrationEndDate"]: (dataForm != null && dataForm.inOutMigrationEndDate !== null && dataForm.inOutMigrationEndDate !== "" ? moment(new Date(dataForm.inOutMigrationEndDate)).format("MM/DD/YYYY") : ""),
            },
            isFormDirty: false,
            reload: false,
            savedForm: false,
            hasError: false,
            loaded: true,
        });

        if (!this.state.pAllItemFields && this.props.dashboardSettingsInfo) {
            let _fields = {};
            Object.keys(this.props.dashboardSettingsInfo).map((t) => {
                _fields[t] = "";
            });

            this.setState({ pAllItemFields: _fields, pageMaxlengthInput: _fields });
        };

        //
        setTimeout(function () {
            document.getElementById("referenceYear") ? document.getElementById("referenceYear").focus() : "";
        }, 500);
    }

    saveDashboardSettings = (event) => {
        event.preventDefault();

        let dialogFormData = this.state.dialogForm;

        //dialogFormData.userName = this.props.user_id;
        dialogFormData.userName = 'MasterAdmin@ers-can.com';
        //dialogFormData.userHosp = this.props.selectedHospital;

        //#region:: Abstract Date that need validation adjustments.
        var badDateId = null;
        let dId = "casesCompletedBeginDate";
        let dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
        if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
            badDateId = dId;
        }

        if (!badDateId) {
            dId = "casesCompletedEndDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "casesAbstractedBeginDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "casesAbstractedEndDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "followUpEnteredBeginDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "followUpEnteredEndDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "lastFollowUpEnteredBeginDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "lastFollowUpEnteredEndDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        /*
         * Disabled input fields so don't need to check valid input
        if (!badDateId) {
            dId = "followUpCalcsBeginDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && dVl != "N/A" && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "followUpCalcsEndDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && dVl != "N/A" && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        */
        if (!badDateId) {
            dId = "rqrsBeginDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "rqrsEndDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "stagedByBeginDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "stagedByEndDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "suspenseBySiteBeginDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "suspenseBySiteEndDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "timelinessBeginDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "timelinessEndDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "top5SitesBeginDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "top5SitesEndDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "referredFromBeginDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "referredFromEndDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "referredToBeginDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "referredToEndDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "classBySiteBeginDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "classBySiteEndDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "countyBySiteBeginDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "countyBySiteEndDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "inOutMigrationBeginDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }
        if (!badDateId) {
            dId = "inOutMigrationEndDate";
            dVl = document.getElementById(dId) ? document.getElementById(dId).value : null;
            if (dVl && !moment(dVl, "MM/DD/YYYY", true).isValid()) {
                badDateId = dId;
            }
        }

        if (badDateId) {
            alert('Please input a valid date');
            document.getElementById(badDateId) ? document.getElementById(badDateId).focus() : null;
            return false;
        }
        // --End #region

        this.props.saveDashboardSettings(dialogFormData);

        this.setState({ isSaving: true, savedForm: true, isFormDirty: false, hasError: false });
        return false;
    }

    handleFormChange = (event) => {
        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                [event.target.name]: event.target.value,
            },
            isFormDirty: true,
        });
    }

    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            let index = event.target.getAttribute('data-index') ? event.target.getAttribute('data-index') : "-1";

            var _this = this;
            setTimeout(function () {
                _this.props.checkValidInput(name, value, table, key, index, flag);
            }, 1000);
        } else {
            this.setState({ uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "" } });
        }

        //
        var fName = event.target.name;
        var fVal = event.target.value;
        if (fName.indexOf("BeginDate") != -1 || fName.indexOf("EndDate") != -1) {
            let thisValue = fVal;
            let thisDate = this.Dashboard_Settings_Date_Format(thisValue.replace(/[^0-9-/]/gi, ''));
            if (thisDate != thisValue) {
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        [fName]: moment(new Date(thisDate)).format("MM/DD/YYYY")
                    }
                });
            }
        }
    }

    refreshFormFnc = () => {
        let fields = {};
        if (!this.state.pAllItemFields && this.props.dashboardSettingsInfo) {
            Object.keys(this.props.dashboardSettingsInfo).map((t) => {
                fields[t] = "";
            });
        };

        this.setState({
            dialogForm: { ...this.state.initialDialogForm },
            pAllItemFields: fields,
            isFormDirty: false,
            reload: false
        });

        //
        setTimeout(function () {
            document.getElementById("referenceYear") ? document.getElementById("referenceYear").focus() : "";
        }, 500);
    }

    closeWindow = (e) => {
        e.preventDefault();

        this.setState({
            browsedItem: "",
            browsedIndex: null,
            listFiles: null
        });

        return false;
    }

    toggleChecked = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            dialogForm: { ...this.state.dialogForm, [name]: (value == "false" ? "true" : "false") },
            isFormDirty: true,
        });
    }

    Dashboard_Settings_Date_Format = (myDate) => {
        //This code tries to fill in slashes and the two digit century when a user doesn't enter them
        var fmtDate = "";

        if (myDate.length == 8 && myDate.indexOf('/') == -1) {
            fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/" + myDate.substr(4, 4);
        } else if (myDate.length == 8 && myDate.indexOf('/') >= 1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(6, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 6) + "19" + myDate.substr(6, 2);
            } else {
                fmtDate = myDate.substr(0, 6) + "20" + myDate.substr(6, 2);
            }
        } else if (myDate.length == 6 && myDate.indexOf('/') == -1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(4, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/19" + myDate.substr(4, 2);
            } else {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/20" + myDate.substr(4, 2);
            }
        } else {
            fmtDate = myDate;
        }

        return fmtDate;
    }

    render() {
        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">Can't save data.</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let loading = (
            !this.state.loaded || this.props.waitingFor ? <div className="col-sm-12 showLoadingOnTopPageCls">Working...</div> : ""
        );

        const dialogFrm = <div className="limitedInGridCls">
            <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                {loading}
                <div className="parentWindow">
                    <div className="patientAbstractGridView" id="dashboardSettingsMainPage" style={{ height: "580px" }}>
                        {
                            this.state.dialogForm ?
                                <div className="container-fluid2">
                                    <form onSubmit={this.saveDashboardSettings} id="dashboardSettingsForm" className="dashboardSettingsForm">

                                        <div id="inputAreaCls">
                                            <div style={{ width: "initial" }}>
                                                <div className="frameInner">
                                                    <div className="header">Dashboard Configuration</div>

                                                    <div className="row">
                                                        <div className="col-sm-12 hospitalOnTopHospitalConfig">
                                                            <Input type="label" labelText="Hospital"
                                                                labelWidth={Global.LabelWidth2} width="60px"
                                                                field="hospCode" value={this.state.dialogForm.hospCode} />
                                                            <Input type="label" labelText=""
                                                                labelWidth="5px" width="300px"
                                                                field="hospName" value={this.state.dialogForm.hospName} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <Input type="input" changed={this.handleFormChange}
                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                labelText="Reference Year" field="referenceYear"
                                                                labelWidth={Global.LabelWidth2} width="55px" tabIndex="1" autoFocus
                                                                value={this.state.dialogForm.referenceYear}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["referenceYear"] : ""}
                                                                inValid={this.state.pAllItemFields["referenceYear"]} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div style={{ display: "flex", position: "relative", top: "12px" }} className="dashboardConfigChbCls">
                                                                <Input type="checkbox"
                                                                    focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                    labelText="Pediatric Facility" field="pediatricFacility"
                                                                    labelWidth="220px" width="30px" tabIndex="2"
                                                                    value={this.state.dialogForm.pediatricFacility && this.state.dialogForm.pediatricFacility == "true" ? "true" : "false"}
                                                                    checked={this.state.dialogForm.pediatricFacility && this.state.dialogForm.pediatricFacility == "true" ? true : false}
                                                                    clicked={this.toggleChecked} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <br /><br />

                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <table style={{ width: "initial" }} className="dashboardConfigMainTable">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: "150px" }}>&nbsp;</th>
                                                                        <th style={{ width: "90px" }}>Begin Date</th>
                                                                        <th style={{ width: "90px" }}>End Date</th>
                                                                        <th style={{ width: "120px" }}>Hosp List</th>
                                                                        <th style={{ width: "80px" }}>Admin Only</th>
                                                                        <th style={{ width: "50px" }}>&nbsp;</th>
                                                                        <th style={{ width: "125px" }}>&nbsp;</th>
                                                                        <th style={{ width: "90px" }}>Begin Date</th>
                                                                        <th style={{ width: "90px" }}>End Date</th>
                                                                        <th style={{ width: "120px" }}>Hosp List</th>
                                                                        <th style={{ width: "80px" }}>Admin Only</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {/* Line 1 */}
                                                                    <tr>
                                                                        <td>Cases Abstracted:</td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="casesAbstractedBeginDate"
                                                                                width="90px" labelWidth="0px" tabIndex="3"
                                                                                value={this.state.dialogForm.casesAbstractedBeginDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["casesAbstractedBeginDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["casesAbstractedBeginDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="casesAbstractedEndDate"
                                                                                width="90px" labelWidth="0px" tabIndex="4"
                                                                                value={this.state.dialogForm.casesAbstractedEndDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["casesAbstractedEndDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["casesAbstractedEndDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="casesAbstractedHospList"
                                                                                width="120px" labelWidth="0px" tabIndex="5"
                                                                                value={this.state.dialogForm.casesAbstractedHospList}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["casesAbstractedHospList"] : ""}
                                                                                inValid={this.state.pAllItemFields["casesAbstractedHospList"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="checkbox"
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="casesAbstractedAdminOnly"
                                                                                width="14px" labelWidth="0px" tabIndex="6"
                                                                                value={this.state.dialogForm.casesAbstractedAdminOnly && this.state.dialogForm.casesAbstractedAdminOnly == "true" ? "true" : "false"}
                                                                                checked={this.state.dialogForm.casesAbstractedAdminOnly && this.state.dialogForm.casesAbstractedAdminOnly == "true" ? true : false}
                                                                                clicked={this.toggleChecked} />
                                                                        </td>
                                                                        <td>&nbsp;</td>
                                                                        <td>Stage by Physician:</td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="stagedByBeginDate"
                                                                                width="90px" labelWidth="0px" tabIndex="47"
                                                                                value={this.state.dialogForm.stagedByBeginDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["stagedByBeginDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["stagedByBeginDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="stagedByEndDate"
                                                                                width="90px" labelWidth="0px" tabIndex="48"
                                                                                value={this.state.dialogForm.stagedByEndDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["stagedByEndDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["stagedByEndDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="stagedByHospList"
                                                                                width="120px" labelWidth="0px" tabIndex="49"
                                                                                value={this.state.dialogForm.stagedByHospList}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["stagedByHospList"] : ""}
                                                                                inValid={this.state.pAllItemFields["stagedByHospList"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="checkbox"
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="stagedByAdminOnly"
                                                                                width="14px" labelWidth="0px" tabIndex="50"
                                                                                value={this.state.dialogForm.stagedByAdminOnly && this.state.dialogForm.stagedByAdminOnly == "true" ? "true" : "false"}
                                                                                checked={this.state.dialogForm.stagedByAdminOnly && this.state.dialogForm.stagedByAdminOnly == "true" ? true : false}
                                                                                clicked={this.toggleChecked} />
                                                                        </td>
                                                                    </tr>
                                                                    {/* Line 2 */}
                                                                    <tr>
                                                                        <td>Cases Completed:</td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="casesCompletedBeginDate"
                                                                                width="90px" labelWidth="0px" tabIndex="7"
                                                                                value={this.state.dialogForm.casesCompletedBeginDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["casesCompletedBeginDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["casesCompletedBeginDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="casesCompletedEndDate"
                                                                                width="90px" labelWidth="0px" tabIndex="8"
                                                                                value={this.state.dialogForm.casesCompletedEndDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["casesCompletedEndDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["casesCompletedEndDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="casesCompletedHospList"
                                                                                width="120px" labelWidth="0px" tabIndex="9"
                                                                                value={this.state.dialogForm.casesCompletedHospList}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["casesCompletedHospList"] : ""}
                                                                                inValid={this.state.pAllItemFields["casesCompletedHospList"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="checkbox"
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="casesCompletedAdminOnly"
                                                                                width="14px" labelWidth="0px" tabIndex="10"
                                                                                value={this.state.dialogForm.casesCompletedAdminOnly && this.state.dialogForm.casesCompletedAdminOnly == "true" ? "true" : "false"}
                                                                                checked={this.state.dialogForm.casesCompletedAdminOnly && this.state.dialogForm.casesCompletedAdminOnly == "true" ? true : false}
                                                                                clicked={this.toggleChecked} />
                                                                        </td>
                                                                        <td>&nbsp;</td>
                                                                        <td>Suspense by Site:</td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="suspenseBySiteBeginDate"
                                                                                width="90px" labelWidth="0px" tabIndex="51"
                                                                                value={this.state.dialogForm.suspenseBySiteBeginDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["suspenseBySiteBeginDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["suspenseBySiteBeginDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="suspenseBySiteEndDate"
                                                                                width="90px" labelWidth="0px" tabIndex="52"
                                                                                value={this.state.dialogForm.suspenseBySiteEndDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["suspenseBySiteEndDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["suspenseBySiteEndDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="suspenseBySiteHospList"
                                                                                width="120px" labelWidth="0px" tabIndex="53"
                                                                                value={this.state.dialogForm.suspenseBySiteHospList}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["suspenseBySiteHospList"] : ""}
                                                                                inValid={this.state.pAllItemFields["suspenseBySiteHospList"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="checkbox"
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="suspenseBySiteAdminOnly"
                                                                                width="14px" labelWidth="0px" tabIndex="54"
                                                                                value={this.state.dialogForm.suspenseBySiteAdminOnly && this.state.dialogForm.suspenseBySiteAdminOnly == "true" ? "true" : "false"}
                                                                                checked={this.state.dialogForm.suspenseBySiteAdminOnly && this.state.dialogForm.suspenseBySiteAdminOnly == "true" ? true : false}
                                                                                clicked={this.toggleChecked} />
                                                                        </td>
                                                                    </tr>
                                                                    {/* Line 3 */}
                                                                    <tr>
                                                                        <td>Class by Site:</td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="classBySiteBeginDate"
                                                                                width="90px" labelWidth="0px" tabIndex="11"
                                                                                value={this.state.dialogForm.classBySiteBeginDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["classBySiteBeginDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["classBySiteBeginDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="classBySiteEndDate"
                                                                                width="90px" labelWidth="0px" tabIndex="12"
                                                                                value={this.state.dialogForm.classBySiteEndDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["classBySiteEndDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["classBySiteEndDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="classBySiteHospList"
                                                                                width="120px" labelWidth="0px" tabIndex="13"
                                                                                value={this.state.dialogForm.classBySiteHospList}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["classBySiteHospList"] : ""}
                                                                                inValid={this.state.pAllItemFields["classBySiteHospList"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="checkbox"
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="classBySiteAdminOnly"
                                                                                width="14px" labelWidth="0px" tabIndex="14"
                                                                                value={this.state.dialogForm.classBySiteAdminOnly && this.state.dialogForm.classBySiteAdminOnly == "true" ? "true" : "false"}
                                                                                checked={this.state.dialogForm.classBySiteAdminOnly && this.state.dialogForm.classBySiteAdminOnly == "true" ? true : false}
                                                                                clicked={this.toggleChecked} />
                                                                        </td>
                                                                        <td>&nbsp;</td>
                                                                        <td>Timeliness:</td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="timelinessBeginDate"
                                                                                width="90px" labelWidth="0px" tabIndex="55"
                                                                                value={this.state.dialogForm.timelinessBeginDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["timelinessBeginDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["timelinessBeginDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="timelinessEndDate"
                                                                                width="90px" labelWidth="0px" tabIndex="56"
                                                                                value={this.state.dialogForm.timelinessEndDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["timelinessEndDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["timelinessEndDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="timelinessHospList"
                                                                                width="120px" labelWidth="0px" tabIndex="57"
                                                                                value={this.state.dialogForm.timelinessHospList}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["timelinessHospList"] : ""}
                                                                                inValid={this.state.pAllItemFields["timelinessHospList"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="checkbox"
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="timelinessAdminOnly"
                                                                                width="14px" labelWidth="0px" tabIndex="58"
                                                                                value={this.state.dialogForm.timelinessAdminOnly && this.state.dialogForm.timelinessAdminOnly == "true" ? "true" : "false"}
                                                                                checked={this.state.dialogForm.timelinessAdminOnly && this.state.dialogForm.timelinessAdminOnly == "true" ? true : false}
                                                                                clicked={this.toggleChecked} />
                                                                        </td>
                                                                    </tr>
                                                                    {/* Line 4 */}
                                                                    <tr>
                                                                        <td>County by Site:</td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="countyBySiteBeginDate"
                                                                                width="90px" labelWidth="0px" tabIndex="15"
                                                                                value={this.state.dialogForm.countyBySiteBeginDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["countyBySiteBeginDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["countyBySiteBeginDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="countyBySiteEndDate"
                                                                                width="90px" labelWidth="0px" tabIndex="16"
                                                                                value={this.state.dialogForm.countyBySiteEndDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["countyBySiteEndDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["countyBySiteEndDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="countyBySiteHospList"
                                                                                width="120px" labelWidth="0px" tabIndex="17"
                                                                                value={this.state.dialogForm.countyBySiteHospList}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["countyBySiteHospList"] : ""}
                                                                                inValid={this.state.pAllItemFields["countyBySiteHospList"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="checkbox"
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="countyBySiteAdminOnly"
                                                                                width="14px" labelWidth="0px" tabIndex="18"
                                                                                value={this.state.dialogForm.countyBySiteAdminOnly && this.state.dialogForm.countyBySiteAdminOnly == "true" ? "true" : "false"}
                                                                                checked={this.state.dialogForm.countyBySiteAdminOnly && this.state.dialogForm.countyBySiteAdminOnly == "true" ? true : false}
                                                                                clicked={this.toggleChecked} />
                                                                        </td>
                                                                        <td>&nbsp;</td>
                                                                        <td>Top 5 Sites:</td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="top5SitesBeginDate"
                                                                                width="90px" labelWidth="0px" tabIndex="59"
                                                                                value={this.state.dialogForm.top5SitesBeginDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["top5SitesBeginDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["top5SitesBeginDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="top5SitesEndDate"
                                                                                width="90px" labelWidth="0px" tabIndex="60"
                                                                                value={this.state.dialogForm.top5SitesEndDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["top5SitesEndDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["top5SitesEndDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="top5SitesHospList"
                                                                                width="120px" labelWidth="0px" tabIndex="61"
                                                                                value={this.state.dialogForm.top5SitesHospList}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["top5SitesHospList"] : ""}
                                                                                inValid={this.state.pAllItemFields["top5SitesHospList"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="checkbox"
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="top5SitesAdminOnly"
                                                                                width="14px" labelWidth="0px" tabIndex="62"
                                                                                value={this.state.dialogForm.top5SitesAdminOnly && this.state.dialogForm.top5SitesAdminOnly == "true" ? "true" : "false"}
                                                                                checked={this.state.dialogForm.top5SitesAdminOnly && this.state.dialogForm.top5SitesAdminOnly == "true" ? true : false}
                                                                                clicked={this.toggleChecked} />
                                                                        </td>
                                                                    </tr>

                                                                    {/* Line 5-11: have left-side only */}
                                                                    <tr>
                                                                        <td>In/Out Migration:</td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="inOutMigrationBeginDate"
                                                                                width="90px" labelWidth="0px" tabIndex="19"
                                                                                value={this.state.dialogForm.inOutMigrationBeginDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["inOutMigrationBeginDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["inOutMigrationBeginDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="inOutMigrationEndDate"
                                                                                width="90px" labelWidth="0px" tabIndex="20"
                                                                                value={this.state.dialogForm.inOutMigrationEndDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["inOutMigrationEndDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["inOutMigrationEndDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="inOutMigrationHospList"
                                                                                width="120px" labelWidth="0px" tabIndex="21"
                                                                                value={this.state.dialogForm.inOutMigrationHospList}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["inOutMigrationHospList"] : ""}
                                                                                inValid={this.state.pAllItemFields["inOutMigrationHospList"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="checkbox"
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="inOutMigrationAdminOnly"
                                                                                width="14px" labelWidth="0px" tabIndex="22"
                                                                                value={this.state.dialogForm.inOutMigrationAdminOnly && this.state.dialogForm.inOutMigrationAdminOnly == "true" ? "true" : "false"}
                                                                                checked={this.state.dialogForm.inOutMigrationAdminOnly && this.state.dialogForm.inOutMigrationAdminOnly == "true" ? true : false}
                                                                                clicked={this.toggleChecked} />
                                                                        </td>
                                                                        <td colSpan="6">&nbsp;</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Follow Up Calcs:</td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="followUpCalcsBeginDate"
                                                                                width="90px" labelWidth="0px" tabIndex="23"
                                                                                value={this.state.dialogForm.followUpCalcsBeginDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["followUpCalcsBeginDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["followUpCalcsBeginDate"]}
                                                                                defaultVal="N/A" disabled={true}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="followUpCalcsEndDate"
                                                                                width="90px" labelWidth="0px" tabIndex="24"
                                                                                value={this.state.dialogForm.followUpCalcsEndDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["followUpCalcsEndDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["followUpCalcsEndDate"]}
                                                                                defaultVal="N/A" disabled={true}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="followUpCalcsHospList"
                                                                                width="120px" labelWidth="0px" tabIndex="25"
                                                                                value={this.state.dialogForm.followUpCalcsHospList}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["followUpCalcsHospList"] : ""}
                                                                                inValid={this.state.pAllItemFields["followUpCalcsHospList"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="checkbox"
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="followUpCalcsAdminOnly"
                                                                                width="14px" labelWidth="0px" tabIndex="26"
                                                                                value={this.state.dialogForm.followUpCalcsAdminOnly && this.state.dialogForm.followUpCalcsAdminOnly == "true" ? "true" : "false"}
                                                                                checked={this.state.dialogForm.followUpCalcsAdminOnly && this.state.dialogForm.followUpCalcsAdminOnly == "true" ? true : false}
                                                                                clicked={this.toggleChecked} />
                                                                        </td>
                                                                        <td colSpan="6">&nbsp;</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Follow-Up Entered:</td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="followUpEnteredBeginDate"
                                                                                width="90px" labelWidth="0px" tabIndex="27"
                                                                                value={this.state.dialogForm.followUpEnteredBeginDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["followUpEnteredBeginDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["followUpEnteredBeginDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="followUpEnteredEndDate"
                                                                                width="90px" labelWidth="0px" tabIndex="28"
                                                                                value={this.state.dialogForm.followUpEnteredEndDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["followUpEnteredEndDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["followUpEnteredEndDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="followUpEnteredHospList"
                                                                                width="120px" labelWidth="0px" tabIndex="29"
                                                                                value={this.state.dialogForm.followUpEnteredHospList}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["followUpEnteredHospList"] : ""}
                                                                                inValid={this.state.pAllItemFields["followUpEnteredHospList"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="checkbox"
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="followUpEnteredAdminOnly"
                                                                                width="14px" labelWidth="0px" tabIndex="30"
                                                                                value={this.state.dialogForm.followUpEnteredAdminOnly && this.state.dialogForm.followUpEnteredAdminOnly == "true" ? "true" : "false"}
                                                                                checked={this.state.dialogForm.followUpEnteredAdminOnly && this.state.dialogForm.followUpEnteredAdminOnly == "true" ? true : false}
                                                                                clicked={this.toggleChecked} />
                                                                        </td>
                                                                        <td colSpan="6">&nbsp;</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Last Follow-Up Entered:</td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="lastFollowUpEnteredBeginDate"
                                                                                width="90px" labelWidth="0px" tabIndex="31"
                                                                                value={this.state.dialogForm.lastFollowUpEnteredBeginDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["lastFollowUpEnteredBeginDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["lastFollowUpEnteredBeginDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="lastFollowUpEnteredEndDate"
                                                                                width="90px" labelWidth="0px" tabIndex="32"
                                                                                value={this.state.dialogForm.lastFollowUpEnteredEndDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["lastFollowUpEnteredEndDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["lastFollowUpEnteredEndDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="lastFollowUpEnteredHospList"
                                                                                width="120px" labelWidth="0px" tabIndex="33"
                                                                                value={this.state.dialogForm.lastFollowUpEnteredHospList}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["lastFollowUpEnteredHospList"] : ""}
                                                                                inValid={this.state.pAllItemFields["lastFollowUpEnteredHospList"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="checkbox"
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="lastFollowUpEnteredAdminOnly"
                                                                                width="14px" labelWidth="0px" tabIndex="34"
                                                                                value={this.state.dialogForm.lastFollowUpEnteredAdminOnly && this.state.dialogForm.lastFollowUpEnteredAdminOnly == "true" ? "true" : "false"}
                                                                                checked={this.state.dialogForm.lastFollowUpEnteredAdminOnly && this.state.dialogForm.lastFollowUpEnteredAdminOnly == "true" ? true : false}
                                                                                clicked={this.toggleChecked} />
                                                                        </td>
                                                                        <td colSpan="6">&nbsp;</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Quality Measures:</td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="rqrsBeginDate"
                                                                                width="90px" labelWidth="0px" tabIndex="35"
                                                                                value={this.state.dialogForm.rqrsBeginDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rqrsBeginDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["rqrsBeginDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="rqrsEndDate"
                                                                                width="90px" labelWidth="0px" tabIndex="36"
                                                                                value={this.state.dialogForm.rqrsEndDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rqrsEndDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["rqrsEndDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="rqrsHospList"
                                                                                width="120px" labelWidth="0px" tabIndex="37"
                                                                                value={this.state.dialogForm.rqrsHospList}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rqrsHospList"] : ""}
                                                                                inValid={this.state.pAllItemFields["rqrsHospList"]}
                                                                                defaultVal="N/A" disabled={true}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="checkbox"
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="rqrsAdminOnly"
                                                                                width="14px" labelWidth="0px" tabIndex="38"
                                                                                value={this.state.dialogForm.rqrsAdminOnly && this.state.dialogForm.rqrsAdminOnly == "true" ? "true" : "false"}
                                                                                checked={this.state.dialogForm.rqrsAdminOnly && this.state.dialogForm.rqrsAdminOnly == "true" ? true : false}
                                                                                clicked={this.toggleChecked} />
                                                                        </td>
                                                                        <td colSpan="6">&nbsp;</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Referred From:</td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="referredFromBeginDate"
                                                                                width="90px" labelWidth="0px" tabIndex="39"
                                                                                value={this.state.dialogForm.referredFromBeginDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["referredFromBeginDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["referredFromBeginDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="referredFromEndDate"
                                                                                width="90px" labelWidth="0px" tabIndex="40"
                                                                                value={this.state.dialogForm.referredFromEndDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["referredFromEndDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["referredFromEndDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="referredFromHospList"
                                                                                width="120px" labelWidth="0px" tabIndex="41"
                                                                                value={this.state.dialogForm.referredFromHospList}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["referredFromHospList"] : ""}
                                                                                inValid={this.state.pAllItemFields["referredFromHospList"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="checkbox"
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="referredFromAdminOnly"
                                                                                width="14px" labelWidth="0px" tabIndex="42"
                                                                                value={this.state.dialogForm.referredFromAdminOnly && this.state.dialogForm.referredFromAdminOnly == "true" ? "true" : "false"}
                                                                                checked={this.state.dialogForm.referredFromAdminOnly && this.state.dialogForm.referredFromAdminOnly == "true" ? true : false}
                                                                                clicked={this.toggleChecked} />
                                                                        </td>
                                                                        <td colSpan="6">&nbsp;</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Referred To:</td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="referredToBeginDate"
                                                                                width="90px" labelWidth="0px" tabIndex="43"
                                                                                value={this.state.dialogForm.referredToBeginDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["referredToBeginDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["referredToBeginDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="referredToEndDate"
                                                                                width="90px" labelWidth="0px" tabIndex="44"
                                                                                value={this.state.dialogForm.referredToEndDate}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["referredToEndDate"] : ""}
                                                                                inValid={this.state.pAllItemFields["referredToEndDate"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="input" changed={this.handleFormChange}
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="referredToHospList"
                                                                                width="120px" labelWidth="0px" tabIndex="45"
                                                                                value={this.state.dialogForm.referredToHospList}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["referredToHospList"] : ""}
                                                                                inValid={this.state.pAllItemFields["referredToHospList"]} />
                                                                        </td>
                                                                        <td>
                                                                            <Input type="checkbox"
                                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                                labelText="" field="referredToAdminOnly"
                                                                                width="14px" labelWidth="0px" tabIndex="46"
                                                                                value={this.state.dialogForm.referredToAdminOnly && this.state.dialogForm.referredToAdminOnly == "true" ? "true" : "false"}
                                                                                checked={this.state.dialogForm.referredToAdminOnly && this.state.dialogForm.referredToAdminOnly == "true" ? true : false}
                                                                                clicked={this.toggleChecked} />
                                                                        </td>
                                                                        <td colSpan="6">&nbsp;</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className="emptyLine"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="emptyLine"></div>

                                    <Footer closeWindow={this.props.closeWindow}
                                        refreshForm={this.refreshFormFnc} formId="dashboardSettingsForm"
                                        isFormDirty={this.state.isFormDirty}
                                        showBtnNew={false}
                                        showBtnDelete={false}
                                        className="Footer2"
                                        initialPageVisible={true}
                                        exitFnc={this.props.closeWindow}
                                        focusingField={this.state.focusingField}
                                        ignoreExit={true}
                                    />

                                </div>
                                : <p>Dashboard Configuration data loading...</p>
                        }
                    </div>
                </div>
            </div>
        </div>;

        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>
                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

DashboardSettings.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        dashboardSettingsInfo: state.patientAbstract.dashboardSettingsInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        deletedDataSuccess: state.patientAbstract.deletedDataSuccess,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        waitingFor: state.patientAbstract.waitingFor,

        //
        selectedHospital: state.patientAbstract.selectedHospital,
        user_id: state.auth.userId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveDashboardSettings: (dialogForm) => dispatch(actionCreators.saveDashboardSettings(dialogForm)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        resetDeletedDataSuccess: (value) => dispatch(actionCreators.resetDeletedDataSuccess(value)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        setHandlerControlItem: (item) => dispatch(actionCreators.setHandlerControlItem(item)),
        showPageLoading: (flag) => dispatch(actionCreators.showPageLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        loadDashboardSettings: (val) => dispatch(actionCreators.loadDashboardSettings(val)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSettings);