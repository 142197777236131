import React from 'react';

import crstarLogo from '../../../assets/images/ERS-Logo-Full-Color-Stacked.png';
import './Logo.css';

const logo = (props) => (
    <div className="Logo" style={{height: props.height}}>
        <img src={crstarLogo} alt="CRStar Logo"/>
    </div>
);

export default logo;