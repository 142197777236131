/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import "./SelectAPop.css";
import Draggable from 'react-draggable';
import PopLabel from "../PopLabel/PopulationLabel";
import * as dialogTypes from "../../AbstractManagement/maintDialogs";

class SelectAPop extends Component {

    state = {
        showMessageError: false,
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showMessageError: false,
            hasError: false,
            confirmed: true,
            //currentTime: null,
        });

        this.props.setChildrenOpening(false);
        this.props.clearErrorMessage();

        setTimeout(function () {
            document.querySelector("#BUTTON_SELECT_POPULATION") ? document.querySelector("#BUTTON_SELECT_POPULATION").focus() : "";
        }, 0);

        return false;
    }
    componentDidMount() {
        if (!this.props.onRightMenu) {
            // Add all tabs in maintDialogs.js
            var thjs = this;
            dialogTypes.reportingSelectAPopulationTabsDisplay.map((dialog, index) => {
                thjs.props.addTabItems(dialog.name, dialog.id);
                thjs.props.setEnabledStatus(dialog.name);
                thjs.props.setControlName(dialog.id);
                //thjs.props.setCurrentDialog(dialog.id);
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.error) {
            this.setState({ showClockTimer: false, showMessageError: true, errorMessage: this.props.errorMessage });

            //document.querySelector('#BUTTON_SELECT_POPULATION') ? document.querySelector('#BUTTON_SELECT_POPULATION').focus() : null;
            this.props.clearErrorMessage();
            return false;
        }
    }

    Cancel_Report = (e, _this) => {
        e.preventDefault();

        _this.props.cancelSBSReport();

        setTimeout(function () {
            _this.setState({
                showClockTimer: false,
                //currentTime: null
            });
            return false;
        }, 0);

        return false;
    }

    javascript_void_fnc(event) {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        return false;
    }

    render() {

        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">{this.state.errorMessage ? this.state.errorMessage : "Can't update this label."}</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>

                <React.Fragment>
                    <PopLabel isSelectAPop={true} showToggleBtn={false} />
                </React.Fragment>
            </React.Fragment>
        );
    }
}

SelectAPop.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        selectedLabelPopInfo: state.patientAbstract.selectedLabelPopInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        isAddNewUser: state.patientAbstract.isAddNewUser,
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        savePopLabelInfo: (dialogForm) => dispatch(actionCreators.saveAPopLabel(dialogForm)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearAddNewUserFlag: () => dispatch(actionCreators.clearAddNewUserFlag()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        selectAPopLabel: (label) => dispatch(actionCreators.selectAPopLabel(label)),
        deleteAPopLabel: (dialogForm) => dispatch(actionCreators.deleteAPopLabel(dialogForm)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),
        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setCurrentDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setTabName: (name) => dispatch(actionCreators.setTabName(name)),
        setControlName: (name) => dispatch(actionCreators.setControlName(name)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectAPop);